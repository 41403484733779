import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UngroupMutationVariables = Types.Exact<{
  memberId: Types.Scalars['String'];
  notes: Types.Scalars['String'];
}>;


export type UngroupMutation = { __typename?: 'MutationModel', projectRequests?: { __typename?: 'ProjectRequestMutationModel', ungroup?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const UngroupDocument = gql`
    mutation Ungroup($memberId: String!, $notes: String!) {
  projectRequests {
    ungroup(memberId: $memberId, notes: $notes) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type UngroupMutationFn = Apollo.MutationFunction<UngroupMutation, UngroupMutationVariables>;

/**
 * __useUngroupMutation__
 *
 * To run a mutation, you first call `useUngroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUngroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ungroupMutation, { data, loading, error }] = useUngroupMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUngroupMutation(baseOptions?: Apollo.MutationHookOptions<UngroupMutation, UngroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UngroupMutation, UngroupMutationVariables>(UngroupDocument, options);
      }
export type UngroupMutationHookResult = ReturnType<typeof useUngroupMutation>;
export type UngroupMutationResult = Apollo.MutationResult<UngroupMutation>;
export type UngroupMutationOptions = Apollo.BaseMutationOptions<UngroupMutation, UngroupMutationVariables>;