import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import {
  activeStates,
  EmployeeStatusPicker,
} from "~/filterProviders/EmployeeStatusPicker";
import { createSimpleProvider } from "./createSimpleProvider";

export function useEmployeeStatusProvider(
  props: FilterProviderProps
): FilterProvider {
  return createSimpleProvider({
    documentationText: props.documentationText,
    type: "status",
    label: "Status",
    render: () => (
      <EmployeeStatusPicker formField="status" label="Status" helperText="" />
    ),
    suggestText: "sta",
    options: activeStates,
  });
}
