import React from "react";
import "./Spinner.scss";
import { Dialog } from "~/dialog";
import List, { ListItem } from "@material/react-list";

export type SpinnerProps = {
  open: boolean;
};

const Spinner: React.FC<SpinnerProps> = ({ open }: SpinnerProps) => {
  return (
    <Dialog
      open={open}
      className={"spinner"}
      data-testid={"spinner"}
      data-open={open}
      portalize
    >
      <List nonInteractive>
        <ListItem
          className="spinner-container"
          onFocus={(evt) => evt.target.blur()}
        >
          <div className="loading-spinner" />
        </ListItem>
      </List>
    </Dialog>
  );
};

export default Spinner;
