import React from "react";
import MobileAllowanceForm from "~/visuals/organisms/Expenses/ExpenseForm/MobileAllowanceTypeForm";
import { useWorkDayApi } from "~/WorkDayApi";
import {
  MobileAllowanceExpense,
  MobileAllowanceExpenseRate,
} from "~/gql/types";
import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";
import { ApiCallback } from "~/WorkDayApi/WorkDayApiType";

type NewMobileAllowanceProps = BaseExpenseFormProps & {
  expenseRate: MobileAllowanceExpenseRate;
  expenseEntry?: MobileAllowanceExpense;
};

const NewMobileAllowanceExpense: React.FC<NewMobileAllowanceProps> = (
  props
) => {
  const { expenseEntry, date } = props;

  const { createMobileAllowanceExpense } = useWorkDayApi();

  const uniqueInitVals: UniqueInitVals = [
    { location: expenseEntry?.location ?? null },
    { location: null },
  ];

  const createExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc,
    callback?: ApiCallback
  ) => {
    const res = await createMobileAllowanceExpense(
      {
        ...defaultVals(values),
        date,
        state: values?.location?.state,
        county: values?.location?.county,
      },
      callback
    );
    return res;
  };

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        handleSubmit: createExpense,
        expenseType: "Mobile Allowance",
        uniqueInitVals,
        formType: expenseEntry ? "Clone" : "Create",
      }}
    >
      <MobileAllowanceForm {...props} />
    </ExpensePageWrapper>
  );
};

export default NewMobileAllowanceExpense;
