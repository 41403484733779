import React from "react";
import { InlineTextField } from "~/inline-text-field";
import { InlineProgress } from "~/inline-progress";

export type InlineTextDisplayProps = {
  errorMessage: string;
  name: string;
  suggestionText: string;
  selectedText: string | null;
  onSuggestionTextChanged: React.Dispatch<string>;
  onFocus?: React.Dispatch<React.FocusEvent<HTMLInputElement>>;
  onBlur?: React.Dispatch<React.FocusEvent<HTMLInputElement>>;
  onKeyDown?: React.Dispatch<React.KeyboardEvent<HTMLInputElement>>;
  loading?: boolean;
  disabled?: boolean;
  required?: boolean;
};

export const InlineTextDisplay: React.FC<InlineTextDisplayProps> = (props) => {
  const {
    suggestionText,
    onSuggestionTextChanged,
    errorMessage,
    name,
    selectedText,
    onKeyDown,
    onFocus,
    onBlur,
    loading,
    disabled,
    required,
  } = props;

  const isSelected = selectedText != null;
  const computedSuggestionText = isSelected ? selectedText : suggestionText;

  const focusHandler = onFocus;

  return (
    <section className={"inline-text-display"}>
      <InlineTextField
        value={computedSuggestionText}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
          onSuggestionTextChanged(evt.target.value)
        }
        onFocus={focusHandler}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        name={name}
        autoComplete="new-password"
        disabled={disabled}
        errorMessage={errorMessage}
        required={required}
      />
      {loading && <InlineProgress />}
    </section>
  );
};
