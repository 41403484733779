import React from "react";
import { HelperText } from "@material/react-text-field";

interface HelperTextLineProps {
  isValid: boolean;
  message: string;
  persistent?: boolean;
}
const HelperTextLine: React.FC<HelperTextLineProps> = ({
  isValid,
  message,
  persistent,
}) => (
  <HelperText
    isValidationMessage={!isValid || persistent}
    persistent={!isValid || persistent}
    className={
      isValid && !persistent
        ? undefined
        : "mdc-text-field-helper-text--validation-msg"
    }
  >
    {message}
  </HelperText>
);

export default HelperTextLine;
