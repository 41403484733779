import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditExpenseRateSheetMutationVariables = Types.Exact<{
  newName: Types.Scalars['String'];
  oldName: Types.Scalars['String'];
  allowAssignment: Types.Scalars['Boolean'];
  endDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
}>;


export type EditExpenseRateSheetMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', editRateSheet?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const EditExpenseRateSheetDocument = gql`
    mutation EditExpenseRateSheet($newName: String!, $oldName: String!, $allowAssignment: Boolean!, $endDate: LocalDate) {
  expenseRates {
    editRateSheet(
      oldName: $oldName
      newName: $newName
      allowAssignment: $allowAssignment
      endDate: $endDate
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditExpenseRateSheetMutationFn = Apollo.MutationFunction<EditExpenseRateSheetMutation, EditExpenseRateSheetMutationVariables>;

/**
 * __useEditExpenseRateSheetMutation__
 *
 * To run a mutation, you first call `useEditExpenseRateSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExpenseRateSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExpenseRateSheetMutation, { data, loading, error }] = useEditExpenseRateSheetMutation({
 *   variables: {
 *      newName: // value for 'newName'
 *      oldName: // value for 'oldName'
 *      allowAssignment: // value for 'allowAssignment'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useEditExpenseRateSheetMutation(baseOptions?: Apollo.MutationHookOptions<EditExpenseRateSheetMutation, EditExpenseRateSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditExpenseRateSheetMutation, EditExpenseRateSheetMutationVariables>(EditExpenseRateSheetDocument, options);
      }
export type EditExpenseRateSheetMutationHookResult = ReturnType<typeof useEditExpenseRateSheetMutation>;
export type EditExpenseRateSheetMutationResult = Apollo.MutationResult<EditExpenseRateSheetMutation>;
export type EditExpenseRateSheetMutationOptions = Apollo.BaseMutationOptions<EditExpenseRateSheetMutation, EditExpenseRateSheetMutationVariables>;