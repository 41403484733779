import { CircularProgress } from "rmwc";
import React from "react";
import "./InlineProgress.scss";

export type InlineProgressProps = {
  size?: "xsmall" | "small" | "medium" | "large" | "xlarge" | number;
};

export const InlineProgress: React.FC<InlineProgressProps> = ({ size }) => (
  <CircularProgress size={size} />
);
