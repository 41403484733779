import React from "react";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import ClickSwallower from "~/click-swallower";
import "./BulkActionWrapper.scss";

type BulkActionWrapperProps = {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  title: string;
  className: string;
};

export const BulkActionWrapper: React.FC<BulkActionWrapperProps> = ({
  open,
  setOpen,
  className,
  title,
  children,
}) => {
  return (
    <ClickSwallower className="bulk-actions-container">
      <Dialog
        className={"bulk-actions-wrapper" + ` ${className}`}
        open={open}
        onClose={() => setOpen(false)}
        data-open={open}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{open && children}</DialogContent>
      </Dialog>
    </ClickSwallower>
  );
};
