import React from "react";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import Card from "@material/react-card";
import { Body1 } from "@material/react-typography";
import logo from "./logo.png";
import "./NoAuthTopoCard.scss";

const NoAuthTopoCard: React.FC = ({ children }) => (
  <Grid className="login-page">
    <Row>
      <Cell columns={12}>
        <Card>
          <Body1>
            <img src={logo} alt="Topograpic Logo" />
          </Body1>
          {children}
        </Card>
      </Cell>
    </Row>
  </Grid>
);

export default NoAuthTopoCard;
