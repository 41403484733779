import _ from "lodash";
import React from "react";
import { InlineTypeahead, InlineTypeaheadProps } from "~/inline-typeahead";
import StateCounty from "~/graphql/StateCounty";
import { useStateCountiesAsync } from "~/refdata2";

type InlineStateCountyPickerProps = {
  name: string;
  value: StateCounty;
  onChange: React.Dispatch<StateCounty | null>;
  onBlur?: React.FocusEventHandler<any>;
  submitError?: string;
  required?: boolean;
};

export const InlineStateCountyPicker: React.FC<InlineStateCountyPickerProps> = (
  props
) => {
  const format = (item: StateCounty) => `${item.state}-${item.county}`;

  const getLocations = useStateCountiesAsync();

  const getData = async (searchText: string) => {
    if (!searchText) {
      return [];
    }

    const ucText = searchText.toUpperCase();

    return (
      _.chain(await getLocations())
        .map(({ state, county }) => ({
          state,
          county,
          text: `${state}-${county}`,
        }))
        .filter((x) => x.text.startsWith(ucText) || x.county.startsWith(ucText))
        .orderBy((x) => x.text)
        .map(({ state, county }) => ({ state, county }))
        .value() ?? []
    );
  };

  const args = {
    ...props,
    getData,
    format,
  } as InlineTypeaheadProps<StateCounty>;

  return (
    <div className="inline-field-office-picker">
      <InlineTypeahead {...args} />
    </div>
  );
};
