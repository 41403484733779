import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getExpiration } from "~/Auth/jwtUtils";
import { Action, State } from "../stateMachine";

export type NearExpireManagerProps = {
  jwt: string;
  state: State;
  dispatch: React.Dispatch<Action>;
  isActive: boolean;
};

export const NearExpireManager: React.FC<NearExpireManagerProps> = ({
  jwt,
  state,
  dispatch,
  isActive,
}) => {
  const [searchParams] = useSearchParams();

  const nearExpireThreshold = parseInt(
    searchParams.get("nearExpireThreshold") || "600"
  );

  useEffect(() => {
    const interval = window.setInterval(() => {
      const expireTime = getExpiration(jwt!).getTime();
      const nowTime = new Date().getTime();
      const expiresInSecs = (expireTime - nowTime) / 1000;

      const nearExpire = expiresInSecs < nearExpireThreshold;

      if (state.tag === "Authenticated" && nearExpire) {
        dispatch({ tag: isActive ? "ActiveNearExpire" : "InactiveNearExpire" });
      }

      if (["BigPopup", "IframeOpen"].includes(state.tag) && !nearExpire) {
        dispatch({ tag: "TokenSuccess" });
      }
    }, 1000);

    return () => {
      window.clearInterval(interval);
    };
  }, [jwt, nearExpireThreshold, isActive, state.tag, dispatch]);

  return <></>;
};
