import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditFuelExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  oldName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  billable: Types.Scalars['Boolean'];
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EditFuelExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', editFuelRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditReceiptValueExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  oldName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  requiresLocation: Types.Scalars['Boolean'];
  category: Types.ReceiptValuedCategory;
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  billable: Types.Scalars['Boolean'];
}>;


export type EditReceiptValueExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', editReceiptValuedRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditPerDiemExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  oldName: Types.Scalars['String'];
  billableOverride?: Types.InputMaybe<Types.Scalars['Decimal']>;
  kind: Types.PerDiemKind;
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  quantity: Types.Scalars['Int'];
}>;


export type EditPerDiemExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', editPerDiemRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditMobileAllowanceExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  oldName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  billableAmount: Types.Scalars['Decimal'];
  reimbursableAmount: Types.Scalars['Decimal'];
  quantity: Types.Scalars['Int'];
}>;


export type EditMobileAllowanceExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', editMobileAllowanceRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditTcpAllowanceExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  oldName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  reimbursableAmount: Types.Scalars['Decimal'];
}>;


export type EditTcpAllowanceExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', editTcpAllowanceRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditMileageExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  oldName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  reimbursableAmountPerMile: Types.Scalars['Decimal'];
  billableAmountPerMile: Types.Scalars['Decimal'];
}>;


export type EditMileageExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', editMileageRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditAtvExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  oldName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  reimbursableAmount: Types.Scalars['Decimal'];
  billableAmount: Types.Scalars['Decimal'];
}>;


export type EditAtvExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', editAtvRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditAdHocExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  oldName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  rate?: Types.InputMaybe<Types.Scalars['Decimal']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EditAdHocExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', editAdHocRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type DeleteExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  name: Types.Scalars['String'];
}>;


export type DeleteExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', deleteRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const EditFuelExpenseRateDocument = gql`
    mutation editFuelExpenseRate($rateSheetName: String!, $oldName: String!, $name: String!, $invoiceLabel: String, $expenseAccount: String, $billable: Boolean!, $glAccount: String) {
  expenseRates {
    editFuelRate(
      rateSheetName: $rateSheetName
      oldName: $oldName
      name: $name
      invoiceLabel: $invoiceLabel
      expenseAccount: $expenseAccount
      glAccount: $glAccount
      billable: $billable
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditFuelExpenseRateMutationFn = Apollo.MutationFunction<EditFuelExpenseRateMutation, EditFuelExpenseRateMutationVariables>;

/**
 * __useEditFuelExpenseRateMutation__
 *
 * To run a mutation, you first call `useEditFuelExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFuelExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFuelExpenseRateMutation, { data, loading, error }] = useEditFuelExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      oldName: // value for 'oldName'
 *      name: // value for 'name'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      billable: // value for 'billable'
 *      glAccount: // value for 'glAccount'
 *   },
 * });
 */
export function useEditFuelExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<EditFuelExpenseRateMutation, EditFuelExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditFuelExpenseRateMutation, EditFuelExpenseRateMutationVariables>(EditFuelExpenseRateDocument, options);
      }
export type EditFuelExpenseRateMutationHookResult = ReturnType<typeof useEditFuelExpenseRateMutation>;
export type EditFuelExpenseRateMutationResult = Apollo.MutationResult<EditFuelExpenseRateMutation>;
export type EditFuelExpenseRateMutationOptions = Apollo.BaseMutationOptions<EditFuelExpenseRateMutation, EditFuelExpenseRateMutationVariables>;
export const EditReceiptValueExpenseRateDocument = gql`
    mutation editReceiptValueExpenseRate($rateSheetName: String!, $oldName: String!, $name: String!, $requiresLocation: Boolean!, $category: ReceiptValuedCategory!, $invoiceLabel: String, $expenseAccount: String, $glAccount: String, $billable: Boolean!) {
  expenseRates {
    editReceiptValuedRate(
      rateSheetName: $rateSheetName
      oldName: $oldName
      name: $name
      requiresLocation: $requiresLocation
      category: $category
      invoiceLabel: $invoiceLabel
      expenseAccount: $expenseAccount
      glAccount: $glAccount
      billable: $billable
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditReceiptValueExpenseRateMutationFn = Apollo.MutationFunction<EditReceiptValueExpenseRateMutation, EditReceiptValueExpenseRateMutationVariables>;

/**
 * __useEditReceiptValueExpenseRateMutation__
 *
 * To run a mutation, you first call `useEditReceiptValueExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReceiptValueExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReceiptValueExpenseRateMutation, { data, loading, error }] = useEditReceiptValueExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      oldName: // value for 'oldName'
 *      name: // value for 'name'
 *      requiresLocation: // value for 'requiresLocation'
 *      category: // value for 'category'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      glAccount: // value for 'glAccount'
 *      billable: // value for 'billable'
 *   },
 * });
 */
export function useEditReceiptValueExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<EditReceiptValueExpenseRateMutation, EditReceiptValueExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditReceiptValueExpenseRateMutation, EditReceiptValueExpenseRateMutationVariables>(EditReceiptValueExpenseRateDocument, options);
      }
export type EditReceiptValueExpenseRateMutationHookResult = ReturnType<typeof useEditReceiptValueExpenseRateMutation>;
export type EditReceiptValueExpenseRateMutationResult = Apollo.MutationResult<EditReceiptValueExpenseRateMutation>;
export type EditReceiptValueExpenseRateMutationOptions = Apollo.BaseMutationOptions<EditReceiptValueExpenseRateMutation, EditReceiptValueExpenseRateMutationVariables>;
export const EditPerDiemExpenseRateDocument = gql`
    mutation editPerDiemExpenseRate($rateSheetName: String!, $name: String!, $oldName: String!, $billableOverride: Decimal, $kind: PerDiemKind!, $invoiceLabel: String, $expenseAccount: String, $glAccount: String, $quantity: Int!) {
  expenseRates {
    editPerDiemRate(
      rateSheetName: $rateSheetName
      name: $name
      oldName: $oldName
      billableOverride: $billableOverride
      kind: $kind
      invoiceLabel: $invoiceLabel
      expenseAccount: $expenseAccount
      glAccount: $glAccount
      quantity: $quantity
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditPerDiemExpenseRateMutationFn = Apollo.MutationFunction<EditPerDiemExpenseRateMutation, EditPerDiemExpenseRateMutationVariables>;

/**
 * __useEditPerDiemExpenseRateMutation__
 *
 * To run a mutation, you first call `useEditPerDiemExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPerDiemExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPerDiemExpenseRateMutation, { data, loading, error }] = useEditPerDiemExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      name: // value for 'name'
 *      oldName: // value for 'oldName'
 *      billableOverride: // value for 'billableOverride'
 *      kind: // value for 'kind'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      glAccount: // value for 'glAccount'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useEditPerDiemExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<EditPerDiemExpenseRateMutation, EditPerDiemExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPerDiemExpenseRateMutation, EditPerDiemExpenseRateMutationVariables>(EditPerDiemExpenseRateDocument, options);
      }
export type EditPerDiemExpenseRateMutationHookResult = ReturnType<typeof useEditPerDiemExpenseRateMutation>;
export type EditPerDiemExpenseRateMutationResult = Apollo.MutationResult<EditPerDiemExpenseRateMutation>;
export type EditPerDiemExpenseRateMutationOptions = Apollo.BaseMutationOptions<EditPerDiemExpenseRateMutation, EditPerDiemExpenseRateMutationVariables>;
export const EditMobileAllowanceExpenseRateDocument = gql`
    mutation editMobileAllowanceExpenseRate($rateSheetName: String!, $oldName: String!, $name: String!, $invoiceLabel: String, $expenseAccount: String, $glAccount: String, $billableAmount: Decimal!, $reimbursableAmount: Decimal!, $quantity: Int!) {
  expenseRates {
    editMobileAllowanceRate(
      rateSheetName: $rateSheetName
      oldName: $oldName
      name: $name
      invoiceLabel: $invoiceLabel
      billableAmount: $billableAmount
      reimbursableAmount: $reimbursableAmount
      expenseAccount: $expenseAccount
      glAccount: $glAccount
      quantity: $quantity
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditMobileAllowanceExpenseRateMutationFn = Apollo.MutationFunction<EditMobileAllowanceExpenseRateMutation, EditMobileAllowanceExpenseRateMutationVariables>;

/**
 * __useEditMobileAllowanceExpenseRateMutation__
 *
 * To run a mutation, you first call `useEditMobileAllowanceExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMobileAllowanceExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMobileAllowanceExpenseRateMutation, { data, loading, error }] = useEditMobileAllowanceExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      oldName: // value for 'oldName'
 *      name: // value for 'name'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      glAccount: // value for 'glAccount'
 *      billableAmount: // value for 'billableAmount'
 *      reimbursableAmount: // value for 'reimbursableAmount'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useEditMobileAllowanceExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<EditMobileAllowanceExpenseRateMutation, EditMobileAllowanceExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMobileAllowanceExpenseRateMutation, EditMobileAllowanceExpenseRateMutationVariables>(EditMobileAllowanceExpenseRateDocument, options);
      }
export type EditMobileAllowanceExpenseRateMutationHookResult = ReturnType<typeof useEditMobileAllowanceExpenseRateMutation>;
export type EditMobileAllowanceExpenseRateMutationResult = Apollo.MutationResult<EditMobileAllowanceExpenseRateMutation>;
export type EditMobileAllowanceExpenseRateMutationOptions = Apollo.BaseMutationOptions<EditMobileAllowanceExpenseRateMutation, EditMobileAllowanceExpenseRateMutationVariables>;
export const EditTcpAllowanceExpenseRateDocument = gql`
    mutation editTcpAllowanceExpenseRate($rateSheetName: String!, $oldName: String!, $name: String!, $invoiceLabel: String, $expenseAccount: String, $glAccount: String, $reimbursableAmount: Decimal!) {
  expenseRates {
    editTcpAllowanceRate(
      rateSheetName: $rateSheetName
      oldName: $oldName
      name: $name
      invoiceLabel: $invoiceLabel
      reimbursableAmount: $reimbursableAmount
      expenseAccount: $expenseAccount
      glAccount: $glAccount
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditTcpAllowanceExpenseRateMutationFn = Apollo.MutationFunction<EditTcpAllowanceExpenseRateMutation, EditTcpAllowanceExpenseRateMutationVariables>;

/**
 * __useEditTcpAllowanceExpenseRateMutation__
 *
 * To run a mutation, you first call `useEditTcpAllowanceExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTcpAllowanceExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTcpAllowanceExpenseRateMutation, { data, loading, error }] = useEditTcpAllowanceExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      oldName: // value for 'oldName'
 *      name: // value for 'name'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      glAccount: // value for 'glAccount'
 *      reimbursableAmount: // value for 'reimbursableAmount'
 *   },
 * });
 */
export function useEditTcpAllowanceExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<EditTcpAllowanceExpenseRateMutation, EditTcpAllowanceExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTcpAllowanceExpenseRateMutation, EditTcpAllowanceExpenseRateMutationVariables>(EditTcpAllowanceExpenseRateDocument, options);
      }
export type EditTcpAllowanceExpenseRateMutationHookResult = ReturnType<typeof useEditTcpAllowanceExpenseRateMutation>;
export type EditTcpAllowanceExpenseRateMutationResult = Apollo.MutationResult<EditTcpAllowanceExpenseRateMutation>;
export type EditTcpAllowanceExpenseRateMutationOptions = Apollo.BaseMutationOptions<EditTcpAllowanceExpenseRateMutation, EditTcpAllowanceExpenseRateMutationVariables>;
export const EditMileageExpenseRateDocument = gql`
    mutation editMileageExpenseRate($rateSheetName: String!, $oldName: String!, $name: String!, $invoiceLabel: String, $expenseAccount: String, $glAccount: String, $reimbursableAmountPerMile: Decimal!, $billableAmountPerMile: Decimal!) {
  expenseRates {
    editMileageRate(
      rateSheetName: $rateSheetName
      oldName: $oldName
      name: $name
      invoiceLabel: $invoiceLabel
      reimbursableAmountPerMile: $reimbursableAmountPerMile
      billableAmountPerMile: $billableAmountPerMile
      expenseAccount: $expenseAccount
      glAccount: $glAccount
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditMileageExpenseRateMutationFn = Apollo.MutationFunction<EditMileageExpenseRateMutation, EditMileageExpenseRateMutationVariables>;

/**
 * __useEditMileageExpenseRateMutation__
 *
 * To run a mutation, you first call `useEditMileageExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMileageExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMileageExpenseRateMutation, { data, loading, error }] = useEditMileageExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      oldName: // value for 'oldName'
 *      name: // value for 'name'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      glAccount: // value for 'glAccount'
 *      reimbursableAmountPerMile: // value for 'reimbursableAmountPerMile'
 *      billableAmountPerMile: // value for 'billableAmountPerMile'
 *   },
 * });
 */
export function useEditMileageExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<EditMileageExpenseRateMutation, EditMileageExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMileageExpenseRateMutation, EditMileageExpenseRateMutationVariables>(EditMileageExpenseRateDocument, options);
      }
export type EditMileageExpenseRateMutationHookResult = ReturnType<typeof useEditMileageExpenseRateMutation>;
export type EditMileageExpenseRateMutationResult = Apollo.MutationResult<EditMileageExpenseRateMutation>;
export type EditMileageExpenseRateMutationOptions = Apollo.BaseMutationOptions<EditMileageExpenseRateMutation, EditMileageExpenseRateMutationVariables>;
export const EditAtvExpenseRateDocument = gql`
    mutation editAtvExpenseRate($rateSheetName: String!, $oldName: String!, $name: String!, $invoiceLabel: String, $expenseAccount: String, $glAccount: String, $reimbursableAmount: Decimal!, $billableAmount: Decimal!) {
  expenseRates {
    editAtvRate(
      rateSheetName: $rateSheetName
      oldName: $oldName
      name: $name
      invoiceLabel: $invoiceLabel
      expenseAccount: $expenseAccount
      glAccount: $glAccount
      reimbursableAmount: $reimbursableAmount
      billableAmount: $billableAmount
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditAtvExpenseRateMutationFn = Apollo.MutationFunction<EditAtvExpenseRateMutation, EditAtvExpenseRateMutationVariables>;

/**
 * __useEditAtvExpenseRateMutation__
 *
 * To run a mutation, you first call `useEditAtvExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAtvExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAtvExpenseRateMutation, { data, loading, error }] = useEditAtvExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      oldName: // value for 'oldName'
 *      name: // value for 'name'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      glAccount: // value for 'glAccount'
 *      reimbursableAmount: // value for 'reimbursableAmount'
 *      billableAmount: // value for 'billableAmount'
 *   },
 * });
 */
export function useEditAtvExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<EditAtvExpenseRateMutation, EditAtvExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAtvExpenseRateMutation, EditAtvExpenseRateMutationVariables>(EditAtvExpenseRateDocument, options);
      }
export type EditAtvExpenseRateMutationHookResult = ReturnType<typeof useEditAtvExpenseRateMutation>;
export type EditAtvExpenseRateMutationResult = Apollo.MutationResult<EditAtvExpenseRateMutation>;
export type EditAtvExpenseRateMutationOptions = Apollo.BaseMutationOptions<EditAtvExpenseRateMutation, EditAtvExpenseRateMutationVariables>;
export const EditAdHocExpenseRateDocument = gql`
    mutation editAdHocExpenseRate($rateSheetName: String!, $oldName: String!, $name: String!, $invoiceLabel: String, $expenseAccount: String, $rate: Decimal, $glAccount: String) {
  expenseRates {
    editAdHocRate(
      rateSheetName: $rateSheetName
      oldName: $oldName
      name: $name
      invoiceLabel: $invoiceLabel
      expenseAccount: $expenseAccount
      glAccount: $glAccount
      rate: $rate
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditAdHocExpenseRateMutationFn = Apollo.MutationFunction<EditAdHocExpenseRateMutation, EditAdHocExpenseRateMutationVariables>;

/**
 * __useEditAdHocExpenseRateMutation__
 *
 * To run a mutation, you first call `useEditAdHocExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAdHocExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAdHocExpenseRateMutation, { data, loading, error }] = useEditAdHocExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      oldName: // value for 'oldName'
 *      name: // value for 'name'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      rate: // value for 'rate'
 *      glAccount: // value for 'glAccount'
 *   },
 * });
 */
export function useEditAdHocExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<EditAdHocExpenseRateMutation, EditAdHocExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAdHocExpenseRateMutation, EditAdHocExpenseRateMutationVariables>(EditAdHocExpenseRateDocument, options);
      }
export type EditAdHocExpenseRateMutationHookResult = ReturnType<typeof useEditAdHocExpenseRateMutation>;
export type EditAdHocExpenseRateMutationResult = Apollo.MutationResult<EditAdHocExpenseRateMutation>;
export type EditAdHocExpenseRateMutationOptions = Apollo.BaseMutationOptions<EditAdHocExpenseRateMutation, EditAdHocExpenseRateMutationVariables>;
export const DeleteExpenseRateDocument = gql`
    mutation deleteExpenseRate($rateSheetName: String!, $name: String!) {
  expenseRates {
    deleteRate(rateSheetName: $rateSheetName, name: $name) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type DeleteExpenseRateMutationFn = Apollo.MutationFunction<DeleteExpenseRateMutation, DeleteExpenseRateMutationVariables>;

/**
 * __useDeleteExpenseRateMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseRateMutation, { data, loading, error }] = useDeleteExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDeleteExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteExpenseRateMutation, DeleteExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteExpenseRateMutation, DeleteExpenseRateMutationVariables>(DeleteExpenseRateDocument, options);
      }
export type DeleteExpenseRateMutationHookResult = ReturnType<typeof useDeleteExpenseRateMutation>;
export type DeleteExpenseRateMutationResult = Apollo.MutationResult<DeleteExpenseRateMutation>;
export type DeleteExpenseRateMutationOptions = Apollo.BaseMutationOptions<DeleteExpenseRateMutation, DeleteExpenseRateMutationVariables>;