import React from "react";
import Button from "~/button";
import { ProjectRequest, ProjectRequestState } from "~/gql/types";
import { useCheckout } from "./useCheckout";
import Spinner from "~/spinner";

type CheckoutButtonProps = {
  projectRequest: ProjectRequest;
  refetch: React.DispatchWithoutAction;
};

export const CheckoutButton: React.FC<CheckoutButtonProps> = (props) => {
  const { projectRequest, refetch } = props;

  const { checkout, loading, isCheckerOuter } = useCheckout({ projectRequest });

  const canCheckout =
    projectRequest.state == ProjectRequestState.Pending &&
    (!projectRequest.checkedOutBy || !isCheckerOuter);

  if (!canCheckout) {
    return null;
  }
  return (
    <>
      <Button onClick={() => checkout(refetch)}>Checkout</Button>
      <Spinner open={loading} />
    </>
  );
};
