import React from "react";
import { Navigate, useParams } from "react-router-dom";
import EditProjectTimeEntry from "./EditProjectTime";
import { useWorkDayApi } from "~/WorkDayApi";

const EditProjectTime: React.FC = () => {
  const { projectTimeId, date, projectNumber, projectName } = useParams<{
    projectTimeId: string;
    date: string;
    projectNumber?: string;
    projectName?: string;
  }>();
  const workDayApi = useWorkDayApi();

  const projectTime = workDayApi.workDay.projectTime?.find(
    (entry) => entry!.id === projectTimeId
  );

  const returnPath = projectNumber && projectName ? "../../../../" : "../../";

  if (!projectTime) {
    return <Navigate to={returnPath} />;
  }

  return <EditProjectTimeEntry {...{ date: date!, projectTime }} />;
};

export default EditProjectTime;
