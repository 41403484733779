import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateThirdPartyInvoiceMutationVariables = Types.Exact<{
  date: Types.Scalars['LocalDate'];
  amount: Types.Scalars['Decimal'];
  projectId: Types.Scalars['Int'];
  documentImageId: Types.Scalars['String'];
  crewCode: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateThirdPartyInvoiceMutation = { __typename?: 'MutationModel', thirdPartyInvoices?: { __typename?: 'ThirdPartyInvoicesMutationModel', create?: { __typename?: 'CreateThirdPartyInvoiceResponse', invoiceNumber?: string | null, errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const CreateThirdPartyInvoiceDocument = gql`
    mutation CreateThirdPartyInvoice($date: LocalDate!, $amount: Decimal!, $projectId: Int!, $documentImageId: String!, $crewCode: String!, $notes: String) {
  thirdPartyInvoices {
    create(
      date: $date
      amount: $amount
      projectId: $projectId
      documentImageId: $documentImageId
      crewCode: $crewCode
      notes: $notes
    ) {
      invoiceNumber
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type CreateThirdPartyInvoiceMutationFn = Apollo.MutationFunction<CreateThirdPartyInvoiceMutation, CreateThirdPartyInvoiceMutationVariables>;

/**
 * __useCreateThirdPartyInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateThirdPartyInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThirdPartyInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThirdPartyInvoiceMutation, { data, loading, error }] = useCreateThirdPartyInvoiceMutation({
 *   variables: {
 *      date: // value for 'date'
 *      amount: // value for 'amount'
 *      projectId: // value for 'projectId'
 *      documentImageId: // value for 'documentImageId'
 *      crewCode: // value for 'crewCode'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useCreateThirdPartyInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateThirdPartyInvoiceMutation, CreateThirdPartyInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateThirdPartyInvoiceMutation, CreateThirdPartyInvoiceMutationVariables>(CreateThirdPartyInvoiceDocument, options);
      }
export type CreateThirdPartyInvoiceMutationHookResult = ReturnType<typeof useCreateThirdPartyInvoiceMutation>;
export type CreateThirdPartyInvoiceMutationResult = Apollo.MutationResult<CreateThirdPartyInvoiceMutation>;
export type CreateThirdPartyInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateThirdPartyInvoiceMutation, CreateThirdPartyInvoiceMutationVariables>;