import React from "react";
import TableCell from "~/old-table-cell";
import TableContent from "~/old-table-content";
import TableHeader from "~/old-table-header";
import Table from "~/old-table";
import Card from "@material/react-card";
import "./InvoiceSideBar.scss";
import cn from "classnames";
import LineBreaker from "~/line-breaker";
import Button from "~/button";
import { Invoice, InvoiceState, CustomRollupInvoice } from "~/gql/types";

type InvoiceSideBarProps = {
  invoice: Invoice;
  setEditInvoiceOpen: React.Dispatch<boolean>;
};

type CustomRollupInvoiceSideBarProps = {
  invoice: CustomRollupInvoice;
  setEditInvoiceOpen: React.Dispatch<boolean>;
};

type SideBarItemProps = {
  text: string;
  item: string | null;
};

const SideBarItem: React.FC<SideBarItemProps> = ({ text, item, children }) => {
  if (item === null) {
    return null;
  }

  return (
    <Table>
      <TableHeader>
        <TableCell text={text} />
      </TableHeader>
      <TableContent expandable>
        <TableCell>
          <LineBreaker text={item} />
          {children}
        </TableCell>
      </TableContent>
    </Table>
  );
};

export const InvoiceSideBar: React.FC<InvoiceSideBarProps> = ({
  invoice,
  setEditInvoiceOpen,
}) => {
  const className = cn("invoice-sidebar", {
    "not-draft": invoice.state !== InvoiceState.Draft,
  });

  const afe = invoice.afe ?? null;
  const po = invoice.po ?? null;
  const wo = invoice.wo ?? null;
  const costCenter = invoice.costCenter ?? null;
  const internalNotes = invoice.project?.internalNotes ?? null;
  return (
    <Card className={className}>
      <SideBarItem {...{ text: "Notes", item: invoice.notes ?? "" }}>
        {invoice.state != InvoiceState.Cancelled && (
          <Button
            className="edit-notes-button"
            icon="edit"
            onClick={() => setEditInvoiceOpen(true)}
          />
        )}
      </SideBarItem>
      <SideBarItem {...{ text: "AFE", item: afe! }} />
      <SideBarItem {...{ text: "WO", item: wo! }} />
      <SideBarItem {...{ text: "PO", item: po! }} />
      <SideBarItem {...{ text: "Cost Center", item: costCenter! }} />
      <SideBarItem {...{ text: "Internal Notes", item: internalNotes }} />
    </Card>
  );
};

export const RollupInvoiceSideBar: React.FC<
  CustomRollupInvoiceSideBarProps
> = ({ invoice, setEditInvoiceOpen }) => {
  const className = cn("invoice-sidebar", "rollup-invoice");

  return (
    <Card className={className}>
      <SideBarItem {...{ text: "Notes", item: invoice.notes ?? "" }}>
        {invoice.state != InvoiceState.Cancelled && (
          <Button
            className="edit-notes-button"
            icon="edit"
            onClick={() => setEditInvoiceOpen(true)}
          />
        )}
      </SideBarItem>
    </Card>
  );
};
