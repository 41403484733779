import React from "react";
import { ReviewWrapper, useReviewItemsContext } from "~/review-items";
import { Project } from "~/gql/types";
import ProjectRouter from "~/visuals/pages/Project/ProjectRouter";

export const PROJECT_FILTERS_KEY = "projects-filters";

type ProjectsReviewProps = {
  RouterComponent?: typeof ProjectRouter;
};

export const ProjectsReview: React.FC<ProjectsReviewProps> = ({
  RouterComponent,
}) => {
  const { selectedItems } = useReviewItemsContext();

  const Router = RouterComponent ?? ProjectRouter;

  return (
    <ReviewWrapper
      {...{
        type: "projects",
        path: "/projects",
        filtersKey: PROJECT_FILTERS_KEY,
        navFunc: (x: Project) => `/projects/review/${x.number}`,
        selectedItems: selectedItems as Project[],
      }}
    >
      <Router />
    </ReviewWrapper>
  );
};
