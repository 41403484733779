import React from "react";
import { useField } from "react-final-form";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { CurrencyFieldDisplay } from "~/currency-formfield-display";
import "./CurrencyFormField.scss";

export type CurrencyProps = FormFieldProps;

const CurrencyFormField: React.FC<CurrencyProps> = (props: CurrencyProps) => {
  const {
    formField,
    label,
    helperText,
    required = false,
    disabled = false,
  } = props;

  const checkRequired = (value: string) => {
    const isValidNum =
      value &&
      value.toString().trim().length > 0 &&
      !Number.isNaN(Number(value));

    if (isValidNum || !required || disabled) {
      return;
    }

    return `Please provide a valid ${label}`;
  };

  const {
    meta: { invalid, error, submitError, touched },
    input,
  } = useField(formField, { validate: checkRequired });

  const hasError = (error || invalid || submitError) && touched;

  return (
    <CurrencyFieldDisplay
      name={input.name}
      value={input.value}
      onChange={input.onChange}
      isValid={!hasError}
      disabled={disabled}
      helperText={hasError ? error || submitError : helperText}
      id={input.id}
      label={label}
      testid={`${input.name}-container`}
      required={required}
      onBlur={input.onBlur}
    />
  );
};

export default CurrencyFormField;
