import React, { useState } from "react";
import Button from "~/button";
import { Dialog, DialogContent } from "~/dialog";
import ClickSwallower from "~/click-swallower";
import { Body1 } from "@material/react-typography";
import "./SaveAnd.scss";
import { useForm } from "react-final-form";
import FormBottomRow from "~/form-bottom-row";

export type SaveAndItem = {
  onClick: () => void;
  description: string;
  text: string;
};

export type SaveAndProps = {
  items: SaveAndItem[];
};

export const SaveAnd: React.FC<SaveAndProps> = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <ClickSwallower>
      <SaveAndButton {...{ setOpen }} />
      <SaveAndMenu {...{ ...props, open, setOpen }} />
    </ClickSwallower>
  );
};

export const SaveAndButton: React.FC<{ setOpen: React.Dispatch<boolean> }> = ({
  setOpen,
}) => {
  const form = useForm();

  const onClick = () => {
    if (!form.getState().valid) {
      const errors = form.getState().errors;
      Object.keys(errors as Record<string, any>).forEach((field) => {
        form.getFieldState(field)?.focus();
        form.getFieldState(field)?.blur();
      });

      return;
    }

    setOpen(true);
  };
  return (
    <Button type="button" onClick={onClick}>
      Save and...
    </Button>
  );
};

type SaveAndMenuProps = SaveAndProps & {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
};

export const SaveAndMenu: React.FC<SaveAndMenuProps> = (props) => {
  const { items, open, setOpen } = props;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      data-open={open}
      className="save-and-menu"
    >
      <DialogContent>
        <ul className="save-and-items">
          {items.map((i, idx) => (
            <li key={idx} className="save-and-item">
              <Button
                type="button"
                primary
                onClick={() => {
                  i.onClick();
                  setOpen(false);
                }}
              >
                {i.text}
              </Button>
              <Body1>{i.description}</Body1>
            </li>
          ))}
        </ul>
        <FormBottomRow
          errorMessage={null}
          buttonText={""}
          onCancel={() => setOpen(false)}
        />
      </DialogContent>
    </Dialog>
  );
};
