import React from "react";
import _ from "lodash";
import { ProjectRequest } from "~/gql/types";
import { Route, Routes, useParams } from "react-router-dom";
import Spinner from "~/spinner";
import { useSingleGroupQuery } from "./singleGroup.generated";
import { GroupParams } from "./types";
import { SingleRequestReview } from "./SingleRequestReview";
import { ApproveProjectRequest } from "../ApproveRequest";
import {
  GroupReviewContext,
  useGroupReviewContext,
} from "./groupReviewContext";

export const ReviewPageRoutes: React.FC = () => {
  const { projectRequests } = useGroupReviewContext();
  return (
    <Routes>
      <Route
        path="approve"
        element={<ApproveProjectRequest projectRequest={projectRequests[0]} />}
      />
      <Route path=":index" element={<SingleRequestReview />} />
    </Routes>
  );
};

export const GroupReview: React.FC = () => {
  const params = useParams<GroupParams>();

  const { data, loading, refetch } = useSingleGroupQuery({
    variables: { groupId: params!.groupId! },
  });

  const projectRequests = (data?.projectRequests?.singleGroup?.requests ??
    []) as ProjectRequest[];

  return (
    <GroupReviewContext.Provider value={{ projectRequests, refetch }}>
      {!loading && <ReviewPageRoutes />}
      <Spinner open={loading} />
    </GroupReviewContext.Provider>
  );
};
