import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignSuccessorExpenseRateSheetMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  effectiveDate: Types.Scalars['LocalDate'];
  projectNumbers: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type AssignSuccessorExpenseRateSheetMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', assignRateSheetToProject?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const AssignSuccessorExpenseRateSheetDocument = gql`
    mutation AssignSuccessorExpenseRateSheet($rateSheetName: String!, $effectiveDate: LocalDate!, $projectNumbers: [Int!]!) {
  expenseRates {
    assignRateSheetToProject(
      rateSheetName: $rateSheetName
      effectiveDate: $effectiveDate
      projectNumbers: $projectNumbers
    ) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type AssignSuccessorExpenseRateSheetMutationFn = Apollo.MutationFunction<AssignSuccessorExpenseRateSheetMutation, AssignSuccessorExpenseRateSheetMutationVariables>;

/**
 * __useAssignSuccessorExpenseRateSheetMutation__
 *
 * To run a mutation, you first call `useAssignSuccessorExpenseRateSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignSuccessorExpenseRateSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignSuccessorExpenseRateSheetMutation, { data, loading, error }] = useAssignSuccessorExpenseRateSheetMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      effectiveDate: // value for 'effectiveDate'
 *      projectNumbers: // value for 'projectNumbers'
 *   },
 * });
 */
export function useAssignSuccessorExpenseRateSheetMutation(baseOptions?: Apollo.MutationHookOptions<AssignSuccessorExpenseRateSheetMutation, AssignSuccessorExpenseRateSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignSuccessorExpenseRateSheetMutation, AssignSuccessorExpenseRateSheetMutationVariables>(AssignSuccessorExpenseRateSheetDocument, options);
      }
export type AssignSuccessorExpenseRateSheetMutationHookResult = ReturnType<typeof useAssignSuccessorExpenseRateSheetMutation>;
export type AssignSuccessorExpenseRateSheetMutationResult = Apollo.MutationResult<AssignSuccessorExpenseRateSheetMutation>;
export type AssignSuccessorExpenseRateSheetMutationOptions = Apollo.BaseMutationOptions<AssignSuccessorExpenseRateSheetMutation, AssignSuccessorExpenseRateSheetMutationVariables>;