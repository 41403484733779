import React from "react";
import { useNavigate } from "react-router-dom";
import { expenseRateSheets } from "~/routes";
import ExpenseRateSheetForm from "~/visuals/organisms/ExpenseRateSheetForm";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { useRateSheetsContext } from "~/visuals/organisms/RateSheets/rateSheetsContext";

type FormData = {
  name: string | null;
};

const NewExpenseRateSheet: React.FC = () => {
  const navigate = useNavigate();

  const { reload, create, mutationLoading } = useRateSheetsContext();

  const createExpenseRateSheet = async (values: FormData) => {
    const result = await create(values.name!);

    reload();

    return result?.data?.expenseRates?.createRateSheet;
  };

  return (
    <MutationForm
      runMutation={createExpenseRateSheet}
      onSuccess={() => navigate(expenseRateSheets.path)}
      initialValues={{ name: null }}
    >
      <ErrorRenderer
        render={(submitError) => (
          <ExpenseRateSheetForm
            errorMessage={submitError}
            loading={mutationLoading}
          />
        )}
      />
    </MutationForm>
  );
};

export default NewExpenseRateSheet;
