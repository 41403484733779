import React, { useState } from "react";
import { Dialog, DialogTitle } from "~/dialog";
import { UpdateCrewCodeAction, UpdateCrewCodeFormValues } from "./types";
import TextFormField from "~/text-form-field";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import FormBottomRow from "~/form-bottom-row";
import { useSnackBar } from "~/snackbar";
import { CrewCodePickerFormField } from "~/visuals/organisms/CrewCodePickerFormField";
import Button from "~/button";

type CrewCodeAddDialogProps = {
  refetch: () => void;
  runMutation: UpdateCrewCodeAction;
};

export const CrewCodeAddDialog: React.FC<CrewCodeAddDialogProps> = (props) => {
  const { refetch, runMutation } = props;

  const addAlert = useSnackBar();
  const [open, setOpen] = useState(false);

  const initialValues: UpdateCrewCodeFormValues = {
    crewCode: null,
    overrideEmployeeId: null,
  };

  const onSuccess = async ({ values }) => {
    addAlert({
      message: `${values.crewCode.crewCode} updated`,
      isSuccess: true,
      key: `${Math.random()}`,
    });

    void refetch();

    setOpen(false);
  };

  return (
    <>
      <Button
        icon="add"
        className="open-add-override"
        onClick={() => setOpen(true)}
      >
        Add
      </Button>
      <Dialog
        open={open}
        data-open={open}
        className="add-override-crewcode-dialog"
      >
        <DialogTitle>Add Override to Crew Code</DialogTitle>
        <MutationForm
          initialValues={initialValues}
          runMutation={runMutation}
          onSuccess={onSuccess}
          allowPristineSubmit
        >
          <CrewCodePickerFormField
            formField={"crewCode"}
            label={"Crew Code"}
            helperText={""}
            required
          />
          <TextFormField
            formField="overrideEmployeeId"
            label="Override Employee Id"
            helperText=""
            className="override-empid-text-field"
          />

          <ErrorRenderer
            render={(error) => (
              <FormBottomRow
                buttonText="Save"
                onCancel={() => setOpen(false)}
                errorMessage={error}
              />
            )}
          />
        </MutationForm>
      </Dialog>
    </>
  );
};
