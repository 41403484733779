import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveExpenseRateSheetMutationVariables = Types.Exact<{
  effectiveDate: Types.Scalars['LocalDate'];
  rateSheetName: Types.Scalars['String'];
  projectNumbers: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type RemoveExpenseRateSheetMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', removeRateSheetFromProject?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const RemoveExpenseRateSheetDocument = gql`
    mutation RemoveExpenseRateSheet($effectiveDate: LocalDate!, $rateSheetName: String!, $projectNumbers: [Int!]!) {
  expenseRates {
    removeRateSheetFromProject(
      effectiveDate: $effectiveDate
      rateSheetName: $rateSheetName
      projectNumbers: $projectNumbers
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type RemoveExpenseRateSheetMutationFn = Apollo.MutationFunction<RemoveExpenseRateSheetMutation, RemoveExpenseRateSheetMutationVariables>;

/**
 * __useRemoveExpenseRateSheetMutation__
 *
 * To run a mutation, you first call `useRemoveExpenseRateSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExpenseRateSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExpenseRateSheetMutation, { data, loading, error }] = useRemoveExpenseRateSheetMutation({
 *   variables: {
 *      effectiveDate: // value for 'effectiveDate'
 *      rateSheetName: // value for 'rateSheetName'
 *      projectNumbers: // value for 'projectNumbers'
 *   },
 * });
 */
export function useRemoveExpenseRateSheetMutation(baseOptions?: Apollo.MutationHookOptions<RemoveExpenseRateSheetMutation, RemoveExpenseRateSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveExpenseRateSheetMutation, RemoveExpenseRateSheetMutationVariables>(RemoveExpenseRateSheetDocument, options);
      }
export type RemoveExpenseRateSheetMutationHookResult = ReturnType<typeof useRemoveExpenseRateSheetMutation>;
export type RemoveExpenseRateSheetMutationResult = Apollo.MutationResult<RemoveExpenseRateSheetMutation>;
export type RemoveExpenseRateSheetMutationOptions = Apollo.BaseMutationOptions<RemoveExpenseRateSheetMutation, RemoveExpenseRateSheetMutationVariables>;