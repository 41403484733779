// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I\\+Z8QzF0YPLQK3p4gedH\\+g\\=\\={margin-top:1px;height:56px;padding-left:8px;padding-right:8px;min-width:unset}.I\\+Z8QzF0YPLQK3p4gedH\\+g\\=\\=>.material-icons{margin-left:0}", "",{"version":3,"sources":["webpack://src/product-picker/CreateProductButton.module.scss"],"names":[],"mappings":"AAAA,8BACE,cAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA,CACA,eAAA,CAEA,8CACE,aAAA","sourcesContent":[".AddButton {\n  margin-top: 1px;\n  height: 56px;\n  padding-left: 8px;\n  padding-right: 8px;\n  min-width: unset;\n\n  & > :global(.material-icons) {\n    margin-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddButton": "I+Z8QzF0YPLQK3p4gedH+g=="
};
export default ___CSS_LOADER_EXPORT___;
