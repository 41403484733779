import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ThirdPartyInvoiceSearchQueryVariables = Types.Exact<{
  customerNumber?: Types.InputMaybe<Types.Scalars['String']>;
  projectNumber?: Types.InputMaybe<Types.Scalars['Int']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
  state?: Types.InputMaybe<Types.ThirdPartyInvoiceFilterState>;
  sortBy?: Types.InputMaybe<Types.ThirdPartyInvoiceSortBy>;
  dateBefore?: Types.InputMaybe<Types.Scalars['String']>;
  dateAfter?: Types.InputMaybe<Types.Scalars['String']>;
  dateEqual?: Types.InputMaybe<Types.Scalars['String']>;
  token?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ThirdPartyInvoiceSearchQuery = { __typename?: 'QueryModel', thirdPartyInvoices?: { __typename?: 'ThirdPartyInvoicesQueryModel', search?: { __typename?: 'ConnectionOfThirdPartyInvoice', token?: string | null, records?: Array<{ __typename?: 'ThirdPartyInvoice', date: string, documentUrl: string, documentId: string, state: Types.ThirdPartyInvoiceState, invoiceNumber: string, amount: number, projectId: number, notes?: string | null, crewCode: string, project?: { __typename?: 'Project', name: string, number: number, customer?: { __typename?: 'Customer', number?: string | null, name: string } | null } | null } | null> | null } | null } | null };


export const ThirdPartyInvoiceSearchDocument = gql`
    query ThirdPartyInvoiceSearch($customerNumber: String, $projectNumber: Int, $searchText: String, $state: ThirdPartyInvoiceFilterState, $sortBy: ThirdPartyInvoiceSortBy, $dateBefore: String, $dateAfter: String, $dateEqual: String, $token: String) {
  thirdPartyInvoices {
    search(
      customerNumber: $customerNumber
      projectNumber: $projectNumber
      searchText: $searchText
      state: $state
      sortBy: $sortBy
      dateBefore: $dateBefore
      dateAfter: $dateAfter
      dateEqual: $dateEqual
      token: $token
    ) {
      token
      records {
        date
        project {
          name
          number
          customer {
            number
            name
          }
        }
        documentUrl
        documentId
        state
        invoiceNumber
        amount
        projectId
        notes
        crewCode
      }
    }
  }
}
    `;

/**
 * __useThirdPartyInvoiceSearchQuery__
 *
 * To run a query within a React component, call `useThirdPartyInvoiceSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useThirdPartyInvoiceSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThirdPartyInvoiceSearchQuery({
 *   variables: {
 *      customerNumber: // value for 'customerNumber'
 *      projectNumber: // value for 'projectNumber'
 *      searchText: // value for 'searchText'
 *      state: // value for 'state'
 *      sortBy: // value for 'sortBy'
 *      dateBefore: // value for 'dateBefore'
 *      dateAfter: // value for 'dateAfter'
 *      dateEqual: // value for 'dateEqual'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useThirdPartyInvoiceSearchQuery(baseOptions?: Apollo.QueryHookOptions<ThirdPartyInvoiceSearchQuery, ThirdPartyInvoiceSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThirdPartyInvoiceSearchQuery, ThirdPartyInvoiceSearchQueryVariables>(ThirdPartyInvoiceSearchDocument, options);
      }
export function useThirdPartyInvoiceSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThirdPartyInvoiceSearchQuery, ThirdPartyInvoiceSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThirdPartyInvoiceSearchQuery, ThirdPartyInvoiceSearchQueryVariables>(ThirdPartyInvoiceSearchDocument, options);
        }
export type ThirdPartyInvoiceSearchQueryHookResult = ReturnType<typeof useThirdPartyInvoiceSearchQuery>;
export type ThirdPartyInvoiceSearchLazyQueryHookResult = ReturnType<typeof useThirdPartyInvoiceSearchLazyQuery>;
export type ThirdPartyInvoiceSearchQueryResult = Apollo.QueryResult<ThirdPartyInvoiceSearchQuery, ThirdPartyInvoiceSearchQueryVariables>;