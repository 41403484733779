import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import _ from "lodash";
import { IExpenseRate } from "~/gql/types";
import ExpenseTaskMruFormField from "../ExpenseTaskMruPicker";

export type ExpenseMruPickerProps = FormFieldProps & {
  expenseRates: IExpenseRate[];
  rateSheetName: string;
};

export const RECENT_EXPENSES_KEY = "recent-expenses";

const ExpenseMruFormField: React.FC<ExpenseMruPickerProps> = (props) => {
  const { expenseRates } = props;

  return (
    <ExpenseTaskMruFormField
      {...{
        ...props,
        rates: expenseRates as (IExpenseRate & { __typename: string })[],
        className: "expense-picker",
        storageKey: RECENT_EXPENSES_KEY,
        menuClass: "expense-picker-menuitem",
      }}
    />
  );
};

export default ExpenseMruFormField;
