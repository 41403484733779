import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetThirdPartyInvoiceConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetThirdPartyInvoiceConfigQuery = { __typename?: 'QueryModel', thirdPartyInvoices?: { __typename?: 'ThirdPartyInvoicesQueryModel', config?: { __typename?: 'ThirdPartyInvoiceConfig', glAccount: string } | null } | null };


export const GetThirdPartyInvoiceConfigDocument = gql`
    query GetThirdPartyInvoiceConfig {
  thirdPartyInvoices {
    config {
      glAccount
    }
  }
}
    `;

/**
 * __useGetThirdPartyInvoiceConfigQuery__
 *
 * To run a query within a React component, call `useGetThirdPartyInvoiceConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThirdPartyInvoiceConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThirdPartyInvoiceConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetThirdPartyInvoiceConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetThirdPartyInvoiceConfigQuery, GetThirdPartyInvoiceConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetThirdPartyInvoiceConfigQuery, GetThirdPartyInvoiceConfigQueryVariables>(GetThirdPartyInvoiceConfigDocument, options);
      }
export function useGetThirdPartyInvoiceConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetThirdPartyInvoiceConfigQuery, GetThirdPartyInvoiceConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetThirdPartyInvoiceConfigQuery, GetThirdPartyInvoiceConfigQueryVariables>(GetThirdPartyInvoiceConfigDocument, options);
        }
export type GetThirdPartyInvoiceConfigQueryHookResult = ReturnType<typeof useGetThirdPartyInvoiceConfigQuery>;
export type GetThirdPartyInvoiceConfigLazyQueryHookResult = ReturnType<typeof useGetThirdPartyInvoiceConfigLazyQuery>;
export type GetThirdPartyInvoiceConfigQueryResult = Apollo.QueryResult<GetThirdPartyInvoiceConfigQuery, GetThirdPartyInvoiceConfigQueryVariables>;