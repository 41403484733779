import React from "react";
import { useForm, useFormState } from "react-final-form";
import { ChargeFormData } from "../types";

export const executeQuery = async (
  func: () => Promise<void>,
  setLoading: React.Dispatch<boolean>
): Promise<void> => {
  try {
    setLoading(true);
    await func();
  } finally {
    setLoading(false);
  }
};

export type ChargeFormField = {
  value: any;
  onChange: (event: any) => void;
  initial: any;
};

export function useProjectChargeFormField<TFormData extends string>(
  field: TFormData
): ChargeFormField {
  const formApi = useForm();
  const formState = useFormState();

  const value = formState.values[field];
  const initial = formState.initialValues[field];

  const onChange = (v) => formApi.change(field, v);

  const ret = { value, onChange, initial };

  return ret;
}

export function useChargeFormField(
  field: keyof ChargeFormData
): ChargeFormField {
  return useProjectChargeFormField(field);
}
