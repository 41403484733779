import React from "react";
import TextFormField from "~/text-form-field";
import { useWorkDayApi } from "~/WorkDayApi";

export const AdminNotes: React.FC = () => {
  const { isAdmin } = useWorkDayApi();
  return isAdmin ? (
    <TextFormField
      label="Admin Notes"
      helperText="Enter the reason for the change"
      formField="adminNotes"
      textarea
      required
    />
  ) : null;
};
