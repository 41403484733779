import React from "react";
import { useEditPerDiemExpenseRateMutation } from "./queries.generated";
import { MutationForm } from "~/forms/MutationForm";
import { FormProps, FormData } from "./types";
import {
  PerDiemExpenseRate,
  PerDiemKind,
  ValidationResponse,
} from "~/gql/types";
import { parseNum } from ".";

export const EditPerDiemExpenseRate: React.FC<
  FormProps & { original: PerDiemExpenseRate }
> = (props) => {
  const { setLoading, original, rateSheet, onSuccess, children } = props;

  const [rawPerDiemMutation] = useEditPerDiemExpenseRateMutation();

  const initialValues = {
    name: original.name,
    kind: original.kind,
    billableOverride: original.billableOverride?.toString() || null,
    invoiceLabel: original.invoiceLabel,
    expenseAccount: original.expenseAccount,
    glAccount: original.glAccount,
    quantity: original.quantity.toString(),
  };

  const handleSubmit = async (values: FormData) => {
    const variables = {
      oldName: original?.name!,
      name: values?.name!,
      rateSheetName: rateSheet.name,
      invoiceLabel: values?.invoiceLabel || null,
      expenseAccount: values?.expenseAccount || null,
      glAccount: values?.glAccount || null,
      billableOverride: parseNum(values?.billableOverride),
      kind: values?.kind as PerDiemKind,
      quantity: parseNum(values?.quantity)!,
    };

    const result = await rawPerDiemMutation({ variables });

    return result?.data?.expenseRates?.editPerDiemRate as ValidationResponse;
  };

  return (
    <MutationForm
      setLoading={setLoading}
      initialValues={initialValues}
      runMutation={handleSubmit}
      onSuccess={onSuccess}
      allowPristineSubmit
    >
      {children}
    </MutationForm>
  );
};
