import React from "react";
import { RateSheet } from "./types";
import moment from "moment";
import Button from "~/button";
import Spinner from "~/spinner";
import { useSnackBar } from "~/snackbar";
import { useRateSheetsContext } from "./rateSheetsContext";

export type ArchiveProps = {
  sheet: RateSheet;
};

export const ArchiveRateSheet: React.FC<ArchiveProps> = (props) => {
  const { sheet } = props;

  const { lastUsed, name } = sheet;

  const { archive, reload, mutationLoading } = useRateSheetsContext();

  const addAlert = useSnackBar();

  const today = moment();
  const yearFromLastUsed = lastUsed
    ? moment(lastUsed, "YYYY-MM-DD").add(1, "year").format("YYYY-MM-DD")
    : null;

  const canArchive = !lastUsed || today.isSameOrAfter(yearFromLastUsed);

  if (!canArchive) {
    return null;
  }

  const doArchive = async () => {
    const errors = await archive(name);

    if (errors.length) {
      addAlert({
        isSuccess: false,
        message: errors.map((e) => e.message).join(" "),
        key: `${Math.random()}`,
      });

      return;
    }

    addAlert({
      isSuccess: true,
      message: `Rate sheet ${name} archived`,
      key: `${Math.random()}`,
    });
    await reload();
  };

  return (
    <>
      <Button onClick={doArchive} outlined>
        Archive
      </Button>
      <Spinner open={mutationLoading} />
    </>
  );
};
