import React, { useState } from "react";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { FilterOptions } from "./FilterForm";
import { useApolloClient } from "@apollo/client";
import { Project } from "~/gql/types";
import { formatDate } from "~/utils/formatDate";
import { AllFilteredProjectsDocument } from "./allProjects.generated";
import { useProjectSearchStats } from "./ProjectSearchStatsProvider";
import { LoadAllButton } from "~/load-all-button";

type LoadAllProjectsProps = {
  state: State<Project, FilterOptions>;
  dispatch: React.Dispatch<Action<Project, FilterOptions>>;
};

export const LoadAllProjects: React.FC<LoadAllProjectsProps> = ({
  state,
  dispatch,
}) => {
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const { stats } = useProjectSearchStats();

  const {
    searchText,
    billingAdmin,
    projectManager,
    projectSupervisor,
    officeCode,
    projectType,
    division,
    corpLocation,
    customer,
    dateInEqual,
    dateInBefore,
    dateInAfter,
    locationState,
    afeWoPo,
    status,
    stateCounty,
    clientContactName,
    projectGroup,
    sortBy,
    certifiedPayroll,
  } = state.filterOptions;

  const allSelected =
    state.selectedItems.length === state.items.length && state.items.length > 0;

  const loadAll = async () => {
    setLoading(true);

    try {
      const variables = {
        searchText: searchText || null,
        billingAdmin: billingAdmin?.userPrincipalName || null,
        projectManager: projectManager?.userPrincipalName || null,
        projectSupervisor: projectSupervisor?.userPrincipalName || null,
        officeCode: officeCode?.officeCode ?? null,
        projectType: projectType ?? null,
        division: division?.id ?? null,
        corpLocation: corpLocation?.id ?? null,
        customerNumber: customer?.number ?? null,
        dateInEqual: formatDate(dateInEqual),
        dateInBefore: formatDate(dateInBefore),
        dateInAfter: formatDate(dateInAfter),
        state: stateCounty ? stateCounty.state : locationState || null,
        afeWoPo: afeWoPo || null,
        status: status || null,
        county: stateCounty?.county || null,
        clientContactName: clientContactName || null,
        sortBy,
        projectGroupArgs: projectGroup
          ? {
              groupName: projectGroup.name,
              customerNumber: projectGroup.customer!.number,
            }
          : null,
        certifiedPayroll: certifiedPayroll === "Yes",
      };

      const result = await client.query({
        query: AllFilteredProjectsDocument,
        variables,
      });

      const allProjects = result?.data?.projects?.allFilteredProjects ?? [];

      dispatch({ tag: "SelectAllQueriedItems", items: allProjects });
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadAllButton
      {...{
        allSelected,
        loadAll,
        selectedCount: state.selectedItems.length,
        totalCount: stats?.projectCount ?? 0,
        loading,
        type: "projects",
      }}
    />
  );
};
