import React from "react";
import { Body1 } from "@material/react-typography";
import { Dialog, DialogContent, DialogButton, DialogTitle } from "~/dialog";
import { DialogFooter } from "~/dialog-footer";
import { Action, State } from "../stateMachine";

type ReauthDialogProps = {
  logout: () => void;
  state: State;
  dispatch: React.Dispatch<Action>;
};

export const ReauthDialog: React.FC<ReauthDialogProps> = ({
  logout,
  state,
  dispatch,
}) => {
  const dialogOpen =
    state.tag === "InactivePrompt" || state.tag === "HavingProblem";
  const isOpenStr = `${dialogOpen}`;

  const onClose = (evt) => {
    if (evt.target.action === "confirm") {
      dispatch({ tag: "ConfirmPrompt" });
    }
  };

  const refreshExpired = state.tag === "HavingProblem";

  const confirmButtonText = refreshExpired ? "Renew" : "Continue Working";

  const continueWorkingText =
    "Your login session is about to expire. Please log out if you are finished working\n" +
    "otherwise, click 'Continue Working'. Thanks for helping keep our systems secure!";

  const problemText =
    "We're having trouble automatically renewing your authentication information. Click 'Renew' below to authenticate.";

  const bodyText =
    state.tag !== "HavingProblem" ? continueWorkingText : problemText;

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      data-open={isOpenStr}
      className="reauth-prompt"
    >
      <DialogTitle>Login Info Expiring</DialogTitle>
      <DialogContent>
        <Body1>{bodyText}</Body1>
      </DialogContent>
      <DialogFooter>
        <DialogButton action="cancel" onClick={() => logout()}>
          Log Out
        </DialogButton>
        <DialogButton action="confirm" isDefaultAction>
          {confirmButtonText}
        </DialogButton>
      </DialogFooter>
    </Dialog>
  );
};
