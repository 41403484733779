import React from "react";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";
import { customer as customerRoute, project as projectRoute } from "~/routes";
import FormCard from "~/form-card";
import ExpenseRateSheetPickerFormField from "~/visuals/organisms/ExpenseRateSheetPickerFormField";
import TaskRateSheetPickerFormField from "~/visuals/organisms/TaskRateSheetPickerFormField";
import PageTitleRow from "~/page-title-row";
import Spinner from "~/spinner";
import "./AssignRateSheet.scss";
import FormBottomRow from "~/form-bottom-row";
import { Moment } from "moment";

export type AssignRateSheetProps = {
  customerNumber?: string;
  projectNumber?: number;
  effectiveDate: Moment | null;
  errorMessage?: string;
  loading: boolean;
  type: "Expense" | "Task";
};

const AssignRateSheet: React.FC<AssignRateSheetProps> = ({
  customerNumber,
  projectNumber,
  effectiveDate,
  errorMessage,
  loading,
  type,
}) => {
  let route;

  if (customerNumber) {
    route = customerRoute.toRoute(customerNumber);
  } else if (projectNumber) {
    route = projectRoute.toRoute(projectNumber);
  }

  return (
    <div className="assign-rate-sheet-form">
      <PageTitleRow
        title={
          `Assign ${type} Rate Sheet to ` +
          `${
            customerNumber
              ? `Customer ${customerNumber}`
              : `Project ${projectNumber}`
          }`
        }
      />
      <Spinner open={loading} />
      <FormCard>
        <DatePickFormField
          label="Effective Date"
          formField="effectiveDate"
          helperText="The date on which the rate sheet will go into effect."
          required
          maxDate={new Date(8640000000000000)}
        />

        {type === "Expense" && (
          <ExpenseRateSheetPickerFormField
            label="Rate Sheet"
            formField="rateSheetName"
            helperText={`Pick a Rate Sheet to assign to ${
              customerNumber || projectNumber
            }`}
            required
            effectiveDate={effectiveDate}
          />
        )}

        {type === "Task" && (
          <TaskRateSheetPickerFormField
            label="Rate Sheet"
            formField="rateSheetName"
            helperText={`Pick a Rate Sheet to assign to ${
              customerNumber || projectNumber
            }`}
            required
            effectiveDate={effectiveDate}
          />
        )}

        <FormBottomRow
          errorMessage={errorMessage || null}
          buttonText="Save"
          route={route}
        />
      </FormCard>
    </div>
  );
};

export default AssignRateSheet;
