import React from "react";
import "./BadDebtWarning.scss";
import { Customer } from "~/gql/types";
import WarningMessage from "~/warning-message";

type BadDebtWarningProps = {
  customer?: Customer | null;
};
export const BadDebtWarning: React.FC<BadDebtWarningProps> = ({ customer }) => {
  if (
    !customer ||
    !customer.badDebtWriteOffs ||
    customer.badDebtWriteOffs.length === 0
  ) {
    return null;
  }

  return (
    <WarningMessage
      {...{
        icon: "warning",
        message: `Customer ${customer.number} has previous bad debt`,
        className: "bad-debt-warning",
      }}
    />
  );
};
