import React from "react";
import Button from "~/button";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useErrorReporter } from "~/error-handler/useErrorReporter";
import "./RouterErrorCatcher.scss";
import { Body1 } from "@material/react-typography";

const ErrorFallBack: React.FC<FallbackProps> = ({ resetErrorBoundary }) => (
  <div className="route-error-catcher">
    <Body1>Whoops! Something went wrong.</Body1>
    <Button
      outlined
      onClick={() => {
        location.reload();
        resetErrorBoundary();
      }}
    >
      Try again
    </Button>
  </div>
);

export const Catcher: React.FC = ({ children }) => {
  const reporter = useErrorReporter();
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallBack}
      onError={(e) => reporter(e)}
    >
      {children}
    </ErrorBoundary>
  );
};
