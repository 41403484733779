import React from "react";
import { useEditMileageExpenseRateMutation } from "./queries.generated";
import { MutationForm } from "~/forms/MutationForm";
import { FormProps, FormData } from "./types";
import { MileageExpenseRate, ValidationResponse } from "~/gql/types";
import { parseNum } from ".";

export const EditMileageExpenseRate: React.FC<
  FormProps & { original: MileageExpenseRate }
> = (props) => {
  const { setLoading, original, rateSheet, onSuccess, children } = props;

  const [rawMileageMutation] = useEditMileageExpenseRateMutation();

  const initialValues = {
    name: original.name,
    invoiceLabel: original.invoiceLabel,
    expenseAccount: original.expenseAccount,
    glAccount: original.glAccount,
    billableAmountPerMile: original.billableAmountPerMile.toString(),
    reimbursableAmountPerMile: original.reimbursableAmountPerMile.toString(),
  };

  const handleSubmit = async (values: FormData) => {
    const variables = {
      oldName: original?.name!,
      name: values?.name!,
      rateSheetName: rateSheet.name,
      invoiceLabel: values?.invoiceLabel || null,
      expenseAccount: values?.expenseAccount || null,
      glAccount: values?.glAccount || null,
      billableAmountPerMile: parseNum(values?.billableAmountPerMile)!,
      reimbursableAmountPerMile: parseNum(values?.reimbursableAmountPerMile)!,
    };

    const result = await rawMileageMutation({ variables });

    return result?.data?.expenseRates?.editMileageRate as ValidationResponse;
  };

  return (
    <MutationForm
      setLoading={setLoading}
      initialValues={initialValues}
      runMutation={handleSubmit}
      onSuccess={onSuccess}
      allowPristineSubmit
    >
      {children}
    </MutationForm>
  );
};
