import { Body1, Body2 } from "@material/react-typography";
import React, { useEffect, useState } from "react";
import Button from "~/button";
import { Chevron } from "~/chevron";
import cn from "classnames";
import "./RoleAccordion.scss";
import _ from "lodash";

export type CompanyRole =
  | "Accounting"
  | "Billing Admin"
  | "Billing Supervisor"
  | "Corporate Payroll"
  | "Payroll Admin"
  | "Project Manager"
  | "Project Supervisor"
  | "Supervisor"
  | "System Administrator";

type Employee = {
  firstName: string;
  lastName: string;
  userPrincipalName: string;
};

export type RoleAssignment = {
  roleName: CompanyRole;
  employees: Employee[];
};

type RoleAccordionProps = {
  role: RoleAssignment;
  removeRole: (role: CompanyRole, userPrincipalName: string) => Promise<void>;
  AddEmployeeComponent: React.FC<{ role: RoleAssignment }>;
};

export const RoleAccordion: React.FC<RoleAccordionProps> = (props) => {
  const { role, removeRole, AddEmployeeComponent } = props;
  const [expanded, setExpanded] = useState(false);
  const [addEmployee, setAddEmployee] = useState(false);

  const { roleName, employees } = role;

  const sortedEmployees = _.sortBy(employees, [
    (x) => x.lastName,
    (x) => x.firstName,
  ]);

  const handleExpand = () => {
    setAddEmployee(false);
    setExpanded(!expanded);
  };

  useEffect(() => {
    setAddEmployee(false);
  }, [role]);

  return (
    <div className="role-accordion">
      <div className="role-title" onClick={handleExpand}>
        <Body1>
          {roleName}

          <span>({employees.length})</span>
        </Body1>
        <Chevron {...{ expanded }} />
      </div>

      <div
        className={cn("employees", { expanded })}
        style={{
          maxHeight: `${
            expanded ? (Math.min(employees.length, 6) + 1) * 48 : 0
          }px`,
        }}
      >
        {sortedEmployees.map((emp, idx) => (
          <div key={idx} className="employee">
            <div>
              <Body2>
                {emp.firstName} {emp.lastName}
              </Body2>
            </div>

            <Button
              icon="delete"
              onClick={() => removeRole(roleName, emp.userPrincipalName)}
            />
          </div>
        ))}
        {!addEmployee && (
          <Button icon="add" onClick={() => setAddEmployee(true)}>
            Add Employee
          </Button>
        )}
      </div>
      {addEmployee && <AddEmployeeComponent {...{ role }} />}
    </div>
  );
};
