import moment, { Duration } from "moment";

export function parseTime(value: string): Duration | null {
  if (!value.match(/^[0-9]+(:[0-9]+)?([ap]m?)?$/)) {
    return null;
  }

  const split = splitComponents(value);
  let { hours } = split;
  const { minutes, isPm, isAm } = split;

  if (hours <= 0 || hours >= 24 || minutes > 59) {
    return null;
  }

  if (isPm) {
    if (hours > 12) {
      return null;
    }
    if (hours < 12) {
      hours += 12;
    }
  }

  if (isAm) {
    if (hours > 12) {
      return null;
    }
    if (hours === 12) {
      hours = 0;
    }
  }

  return moment.duration(`PT${hours}H${minutes}M`);
}

function splitComponents(value: string) {
  const { left, right } = splitLeftRight(value);

  const isPm = right.match(/pm?$/);
  const isAm = right.match(/am?$/);
  return { ...splitHoursMinutes(left), isPm, isAm };
}

function splitLeftRight(value) {
  const matched = value.match(/[ap]m?/);
  const splitIndex = matched?.index ?? value.length;
  return {
    left: value.substring(0, splitIndex),
    right: value.substring(splitIndex),
  };
}

function splitHoursMinutes(value) {
  if (value.match(/^[0-9]+$/)) {
    const hoursMinutes = value.match(/([0-9][0-9]?)([0-9]{2})/);

    if (hoursMinutes) {
      return { hours: parseInt(hoursMinutes[1]), minutes: hoursMinutes[2] };
    }

    return { hours: parseInt(value), minutes: 0 };
  }

  const matched = value.match(/([0-9]+):([0-9]+)/);
  return { hours: parseInt(matched[1]), minutes: matched[2] };
}
