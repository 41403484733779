import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArchiveExpenseRateSheetMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
}>;


export type ArchiveExpenseRateSheetMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', archiveRateSheet?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const ArchiveExpenseRateSheetDocument = gql`
    mutation ArchiveExpenseRateSheet($rateSheetName: String!) {
  expenseRates {
    archiveRateSheet(rateSheetName: $rateSheetName) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type ArchiveExpenseRateSheetMutationFn = Apollo.MutationFunction<ArchiveExpenseRateSheetMutation, ArchiveExpenseRateSheetMutationVariables>;

/**
 * __useArchiveExpenseRateSheetMutation__
 *
 * To run a mutation, you first call `useArchiveExpenseRateSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveExpenseRateSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveExpenseRateSheetMutation, { data, loading, error }] = useArchiveExpenseRateSheetMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *   },
 * });
 */
export function useArchiveExpenseRateSheetMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveExpenseRateSheetMutation, ArchiveExpenseRateSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveExpenseRateSheetMutation, ArchiveExpenseRateSheetMutationVariables>(ArchiveExpenseRateSheetDocument, options);
      }
export type ArchiveExpenseRateSheetMutationHookResult = ReturnType<typeof useArchiveExpenseRateSheetMutation>;
export type ArchiveExpenseRateSheetMutationResult = Apollo.MutationResult<ArchiveExpenseRateSheetMutation>;
export type ArchiveExpenseRateSheetMutationOptions = Apollo.BaseMutationOptions<ArchiveExpenseRateSheetMutation, ArchiveExpenseRateSheetMutationVariables>;