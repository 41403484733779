import React, { useEffect, useReducer, useState } from "react";
import PageTitleRow from "~/page-title-row";
import Card from "@material/react-card";
import Table from "~/old-table";
import TableCell from "~/old-table-cell";
import TableContent from "~/old-table-content";
import TableHeader from "~/old-table-header";
import "./AtvExpense.scss";
import { Action, AtvTotal, reducer, State } from "./stateMachine";
import moment from "moment";
import { currencyFormatter } from "~/currencyFormatter";
import Button from "~/button";
import Spinner from "~/spinner";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import { AtvExpenseTotalsDocument } from "./query.generated";
import { Employee } from "~/gql/types";
import { EmployeeSelect } from "./EmployeeSelect";
import { useApolloClient } from "@apollo/client";

const LoadData: React.FC<{
  state: State;
  dispatch: React.Dispatch<Action>;
  userPrincipalName?: string;
}> = ({ state, dispatch, userPrincipalName }) => {
  const client = useApolloClient();

  const variables = {
    token: state.token ?? null,
    userPrincipalName: userPrincipalName ?? null,
  };

  useEffect(() => {
    void (async () => {
      const response = await client.query({
        query: AtvExpenseTotalsDocument,
        variables,
      });

      const newToken = response?.data.timesheets?.atvExpenseTotals?.token as
        | string
        | null;
      const newData = response?.data.timesheets?.atvExpenseTotals
        ?.records as AtvTotal[];
      dispatch({ tag: "DataLoaded", data: newData, token: newToken });
    })();
  }, []);

  return <></>;
};

export const AtvExpenseTotals: React.FC = () => {
  const initialState = {
    tag: "Loading",
    loading: true,
    totals: [],
    token: null,
  } as State;

  const [state, dispatch] = useReducer(
    (s: State, a: Action) => reducer(s, a),
    initialState
  );

  const getPdf = useReportFetcher();
  const [invoiceLoading, setInvoiceLoading] = useState(false);

  const [employee, setEmployee] = useState<Employee | null>(null);

  const getInvoice = async (date: string) => {
    setInvoiceLoading(true);
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const body = {
      date: formattedDate,
      userPrincipalName: employee?.userPrincipalName ?? null,
    };

    setInvoiceLoading(true);
    void getPdf({
      setLoading: setInvoiceLoading,
      path: "atv-invoice",
      fileName: `atv-invoice-${formattedDate}`,
      body,
    });
  };

  return (
    <div className="atv-expense-totals">
      <Spinner open={state.loading || invoiceLoading} />
      <PageTitleRow
        title="ATV Expenses"
        actions={<EmployeeSelect {...{ setEmployee, dispatch }} />}
      />
      <Card className="atv-expense-totals-card">
        <Table compact>
          <TableHeader>
            <TableCell text="Month/Year" />
            <TableCell text="Total" />
            <TableCell text="Invoice" />
          </TableHeader>
          {state.totals.map((total, idx) => (
            <TableContent
              key={idx}
              trailingSeparator={idx !== state.totals.length - 1}
              className="atv-expense-total"
            >
              <TableCell text={moment(total.month).format("MM/YYYY")} />
              <TableCell text={currencyFormatter.format(total.total)} />
              <TableCell>
                <Button onClick={() => getInvoice(total.month)}>
                  Download
                </Button>
              </TableCell>
            </TableContent>
          ))}
        </Table>
        <Button onClick={() => dispatch({ tag: "LoadData" })}>Load More</Button>
      </Card>
      {state.tag === "Loading" && (
        <LoadData
          {...{
            state,
            dispatch,
            userPrincipalName: employee?.userPrincipalName,
          }}
        />
      )}
    </div>
  );
};
