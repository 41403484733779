import React from "react";
import { Chip } from "@material/react-chips";
import MaterialIcon from "@material/react-material-icon";
import "./PickedChip.scss";

export type PickedChipProps = {
  onClick: React.DispatchWithoutAction;
  label: string;
  className?: string;
};

export const PickedChip: React.FC<PickedChipProps> = ({
  onClick,
  label,
  className,
}) => {
  const classes = [className, "picker--picked-chip"]
    .filter((x) => !!x)
    .join(" ");

  return (
    <Chip
      className={classes}
      label={label}
      selected
      trailingIcon={<MaterialIcon icon="cancel" onClick={onClick} />}
    />
  );
};
