import React, { useState } from "react";
import {
  useRefreshRollupInvoice,
  useRollupInvoice,
} from "./RollupInvoiceContext";
import PageTitleRow from "~/page-title-row";
import { Dialog, DialogButton, DialogContent, DialogTitle } from "~/dialog";
import { Body2 } from "@material/react-typography";
import { InvoiceTable } from "./RollupInvoiceTable";
import "./RollupInvoicePage.scss";
import { RollupInvoiceSideBar } from "../../visuals/pages/Invoice/InvoiceSideBar";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { customRollupInvoices } from "~/routes";
import { CancelRollupInvoice } from "./CancelRollupInvoice";
import { BillRollupInvoice } from "./BillRollupInvoice";
import { useEditCriNotesMutation } from "./editNotes.generated";
import EditInvoiceNotes from "~/edit-invoice-notes/EditInvoiceNotes";
import moment from "moment";
import Spinner from "~/spinner";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import { ValidationResponse } from "~/gql/types";
import Button from "~/button";
import { DialogFooter } from "~/dialog-footer";
import BinaryPicker from "~/binary-picker";
type FormValues = {
  notes: string;
};

type BundleDownloadButtonProps = {
  invoiceNumber: string;
  setLoading: React.Dispatch<boolean>;
};

const BundleDownloadButton: React.FC<BundleDownloadButtonProps> = ({
  invoiceNumber,
  setLoading,
}) => {
  const [state, setState] = useState({
    dialogOpen: false,
    includeThirdPartyInvoices: true,
    includeBackupReports: true,
  });
  const download = useReportFetcher();
  const downloadBundle = async () => {
    setLoading(true);
    const { includeBackupReports, includeThirdPartyInvoices } = state;

    await download({
      setLoading: setLoading,
      fileName: `${invoiceNumber}-bundle`,
      method: "post",
      fullPath: "/api/custom-rollup-invoice-bundle",
      body: {
        invoiceNumber,
        includeBackupReports,
        includeThirdPartyInvoices,
      },
    });

    setState({
      dialogOpen: false,
      includeThirdPartyInvoices: true,
      includeBackupReports: true,
    });
  };

  const setDialogOpen = (dialogOpen) =>
    setState((st) => ({ ...st, dialogOpen }));
  const closeDialog = () => setState((st) => ({ ...st, dialogOpen: false }));

  return (
    <>
      <Dialog open={state.dialogOpen} onClose={closeDialog}>
        <DialogTitle>Download Bundle</DialogTitle>
        <DialogContent>
          <BinaryPicker
            checked={state.includeBackupReports}
            label="Include backup reports"
            onChange={(includeBackupReports) =>
              setState((st) => ({ ...st, includeBackupReports }))
            }
          />
          <BinaryPicker
            checked={state.includeThirdPartyInvoices}
            label="Include third party invoices"
            onChange={(includeThirdPartyInvoices) =>
              setState((st) => ({ ...st, includeThirdPartyInvoices }))
            }
          />
        </DialogContent>
        <DialogFooter>
          <Button onClick={downloadBundle} primary>
            Download Bundle
          </Button>
          <DialogButton action="cancel" onClick={closeDialog}>
            Cancel
          </DialogButton>
        </DialogFooter>
      </Dialog>
      <Button onClick={() => setDialogOpen(true)}>Download Bundle...</Button>
    </>
  );
};

export const RollupInvoicePage: React.FC = () => {
  const invoice = useRollupInvoice();
  const [editInvoiceOpen, setEditInvoiceOpen] = useState<boolean>(false);
  const [doMutation, { loading }] = useEditCriNotesMutation();
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const download = useReportFetcher();
  const refetch = useRefreshRollupInvoice();

  const invoiceNumber = invoice?.invoiceNumber;
  const invoiceDate = invoice.invoiceDate
    ? ` - ${moment(invoice.invoiceDate).format("M/DD/YYYY")}`
    : "";
  useBreadcrumbs(
    [
      { text: "Custom Rollup Invoices", to: customRollupInvoices.path },
      { text: invoice?.invoiceNumber ?? "" },
    ],
    [invoice]
  );

  const projectGroup = invoice?.projectGroup;
  const customer = projectGroup?.customer!;
  const title = (
    <>{`Custom Rollup Invoice ${invoice.invoiceNumber}${invoiceDate} - ${invoice.state}`}</>
  );

  const downloadInvoice = () => {
    setPdfLoading(true);
    void download({
      setLoading: setPdfLoading,
      fileName: invoiceNumber,
      method: "get",
      fullPath: invoice.pdfDownloadUrl,
    });
  };

  const editNotes = async (value: FormValues) => {
    const result = await doMutation({
      variables: { invoiceNumber: invoice.invoiceNumber, notes: value.notes },
    });
    return result?.data?.customRollupInvoices?.edit as ValidationResponse;
  };

  const actions = (
    <div className="actions">
      {customer.canManageCri && (
        <>
          <BillRollupInvoice />
          <CancelRollupInvoice />
        </>
      )}

      <Button onClick={downloadInvoice}>Download PDF</Button>
      <BundleDownloadButton
        invoiceNumber={invoice.invoiceNumber}
        setLoading={setPdfLoading}
      />
    </div>
  );
  return (
    <>
      <div className="invoice-page">
        <PageTitleRow title={title} actions={actions} />
        <div className="header-links">
          <Body2>{`Customer ${customer.number}: ${customer.name}`}</Body2>
        </div>
        <Spinner open={pdfLoading} />
        <div className="invoice-content">
          <InvoiceTable {...{ rollupInvoice: invoice, refetch }} />
          {customer.canManageCri && (
            <EditInvoiceNotes
              {...{
                invoice,
                setOpen: setEditInvoiceOpen,
                open: editInvoiceOpen,
                refetch,
                editNotes: editNotes,
                loading,
              }}
            />
          )}
          <RollupInvoiceSideBar {...{ invoice, setEditInvoiceOpen }} />
        </div>
      </div>
    </>
  );
};
