import React from "react";
import styles from "./BulkActionsPopup.module.scss";
import cn from "classnames";
import { Body1 } from "@material/react-typography";
import Button from "~/button";

export type BulkItem = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
};

type BulkActionsPopupProps = {
  selectedItems: any[];
  onClear: React.Dispatch<any>;
  type: string;
  buttonItems: BulkItem[];
};

export const BulkActionsPopup: React.FC<BulkActionsPopupProps> = ({
  selectedItems,
  onClear,
  buttonItems,
  type,
}) => {
  const count = selectedItems.length;
  const style = cn(styles.BulkActionsPopup, { visible: count > 0 });
  const text = `${count} ${type}${count === 1 ? "" : "s"} Selected`;

  return (
    <div className={style}>
      <div className={styles.Container}>
        <Body1 className={styles.BulkPopupText}>{text}</Body1>
      </div>
      <div className={styles.Container}>
        {buttonItems.map((item, idx) => (
          <Button
            key={idx}
            onClick={item.onClick}
            disabled={item.disabled}
            className={styles.ActionButton}
          >
            {item.label}
          </Button>
        ))}
      </div>
      <div className={styles.Container}>
        <Button className={styles.BulkPopupCancel} onClick={onClear} outlined>
          Clear
        </Button>
      </div>
    </div>
  );
};
