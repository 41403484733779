import React from "react";
import _ from "lodash";
import { UltraFilter } from "~/ultra-filter";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import { useEmployeeStatusProvider } from "~/filterProviders/useEmployeeStatusProvider";
import { useEmployeeSortProvider } from "~/filterProviders/useEmployeeSortProvider";
import { useWorkLocationProvider } from "~/filterProviders/useWorkLocationProvider";
import { EmployeeSortBy, EmployeeStatus } from "~/gql/types";

export type FilterOptions = {
  workLocation: string | null;
  status: EmployeeStatus | null;
  searchText: string | null;
  sortBy: EmployeeSortBy | null;
};

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<FilterOptions>;
};

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const providers = [
    useWorkLocationProvider({
      documentationText: "The employee's work location.",
    }),
    useEmployeeStatusProvider({
      documentationText: "The employee's employment status.",
    }),
    useEmployeeSortProvider({ documentationText: "Sort employees by option." }),
  ] as FilterProvider[];

  const onChanged = (filters: ActiveFilter[], searchText: string) => {
    const newFilters = {
      workLocation:
        filters.find((f) => f.type === "workLocation")?.value || null,
      status: filters.find((f) => f.type === "status")?.value || null,
      searchText: searchText || null,
      sortBy: filters.find((f) => f.type === "sortBy")?.value || "LastName",
    };

    onFiltersChanged(newFilters);
  };

  return (
    <div className="filter-form">
      <UltraFilter
        {...{
          providers,
          dialogTitle: "Employees Search",
          label: "Type to search employees",
          onFiltersChanged: onChanged,
        }}
      />
    </div>
  );
};
