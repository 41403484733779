import React from "react";
import TcpAllowanceForm from "~/visuals/organisms/Expenses/ExpenseForm/TcpAllowanceTypeForm";
import { useWorkDayApi } from "~/WorkDayApi";
import { TcpAllowanceExpense, TcpAllowanceExpenseRate } from "~/gql/types";
import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";

type EditTcpAllowanceProps = BaseExpenseFormProps & {
  expenseRate: TcpAllowanceExpenseRate;
  expenseEntry: TcpAllowanceExpense;
};

const EditTcpAllowanceExpense: React.FC<EditTcpAllowanceProps> = (props) => {
  const { expenseEntry, project } = props;

  const { editTcpAllowanceExpense } = useWorkDayApi();

  const editExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc
  ) => {
    const res = await editTcpAllowanceExpense({
      ...defaultVals(values),
      expenseId: expenseEntry.id,
      state: project?.location?.state!,
      county: project?.location?.county!,
      payableOverride: values?.payableOverride || null,
    });

    return res;
  };

  const uniqueInitVals: UniqueInitVals = [{}];

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        uniqueInitVals,
        handleSubmit: editExpense,
        expenseType: "TCP Allowance",
        formType: "Edit",
      }}
    >
      <TcpAllowanceForm {...props} />
    </ExpensePageWrapper>
  );
};

export default EditTcpAllowanceExpense;
