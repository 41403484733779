import React, { useState } from "react";
import TimeEntryForm, { FormData } from "~/visuals/organisms/TimeEntryForm";
import moment from "moment";
import { useWorkDayApi } from "~/WorkDayApi";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { useTimeCallback } from "~/visuals/pages/NewTimeEntry/useTimeCallback";

const NewTimeEntry: React.FC = () => {
  const {
    createTimeEntry,
    workDay: { date },
    breadcrumbs,
  } = useWorkDayApi();
  const [loading, setLoading] = useState(false);

  useBreadcrumbs(
    [...breadcrumbs, { text: "Create New Time Entry" }],
    [breadcrumbs]
  );

  const durationFormat = (d) => {
    if (!d) {
      return null;
    }
    const m = moment(d.asMilliseconds()).startOf("day").add(d);

    return m.format("HH:mm:ss");
  };

  const getCallback = useTimeCallback();

  const addTimeEntry = async (values: FormData) => {
    const callback = getCallback(values);

    setLoading(true);
    const res = await createTimeEntry(
      {
        workDate: date,
        timeIn: durationFormat(values?.timeIn),
        timeOut: durationFormat(values.timeOut),
        hoursWorked: durationFormat(values.hoursWorked),
        lunchStart: durationFormat(values.lunchStart),
        lunchEnd: durationFormat(values.lunchEnd),
        lunchHours: durationFormat(values.lunchHours),
        state: values.stateCounty?.state!,
        county: values.stateCounty?.county!,
        crewCode: values.crewCode?.crewCode!,
        adminNotes: values.adminNotes || null,
      },
      callback
    );
    setLoading(false);

    return res;
  };

  return (
    <TimeEntryForm
      type="Create"
      handleSubmit={addTimeEntry}
      date={date}
      loading={loading}
    />
  );
};

export default NewTimeEntry;
