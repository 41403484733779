import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProjectGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProjectGroupsQuery = { __typename?: 'QueryModel', projectGroups?: { __typename?: 'ProjectGroupQueryModel', all?: Array<{ __typename?: 'ProjectGroup', name: string, customer?: { __typename?: 'Customer', name: string, number?: string | null } | null } | null> | null } | null };


export const GetProjectGroupsDocument = gql`
    query GetProjectGroups {
  projectGroups {
    all {
      name
      customer {
        name
        number
      }
    }
  }
}
    `;

/**
 * __useGetProjectGroupsQuery__
 *
 * To run a query within a React component, call `useGetProjectGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>(GetProjectGroupsDocument, options);
      }
export function useGetProjectGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>(GetProjectGroupsDocument, options);
        }
export type GetProjectGroupsQueryHookResult = ReturnType<typeof useGetProjectGroupsQuery>;
export type GetProjectGroupsLazyQueryHookResult = ReturnType<typeof useGetProjectGroupsLazyQuery>;
export type GetProjectGroupsQueryResult = Apollo.QueryResult<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>;