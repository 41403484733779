import React from "react";
import Button from "~/button";
import { useConfirmationPrompt } from "~/confirmation-prompt";
import { Customer, Employee, ValidationResponse } from "~/gql/types";
import Spinner from "~/spinner";
import { toErrorMessage } from "~/validation-response-utils";
import { useSnackBar } from "~/snackbar";
import { useUnassignCustomerBaMutation } from "./unassignCustBa.generated";
import { useReload } from "../CustomerContext";

type RemoveBillingAdminProps = {
  customer: Customer;
  billingAdmin: Employee;
};

export const RemoveBillingAdmin: React.FC<RemoveBillingAdminProps> = (
  props
) => {
  const { customer, billingAdmin } = props;

  const prompt = useConfirmationPrompt();
  const addAlert = useSnackBar();
  const reload = useReload();

  const [doMutation, { loading }] = useUnassignCustomerBaMutation();

  const openPrompt = async () => {
    const promptResult = await prompt({
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes, remove",
      message: "Are you sure you want to remove this billing admin",
      title: `Unassign ${billingAdmin.firstName} ${billingAdmin.lastName}`,
    });

    if (promptResult !== "Confirm") {
      return;
    }

    const variables = {
      customerNumber: customer.number!,
      userPrincipalName: billingAdmin.userPrincipalName,
    };

    const response = await doMutation({ variables });
    const result = response?.data?.customers?.unassignBa as ValidationResponse;

    const errorMessage = toErrorMessage(result);

    if (errorMessage) {
      addAlert({
        message: errorMessage,
        key: `${Math.random()}`,
        isSuccess: false,
      });

      return;
    }

    addAlert({
      isSuccess: true,
      key: `${Math.random()}`,
      message: `Billing admin ${billingAdmin.userPrincipalName} unassigned`,
    });
    reload();
  };

  return (
    <>
      <Button icon="delete" onClick={openPrompt} />
      <Spinner open={loading} />
    </>
  );
};
