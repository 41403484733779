import React, { useEffect, useState } from "react";
import { Timesheet, TimesheetAction, TimesheetState } from "./types";
import { useTimesheetContext } from "./TimesheetContext";
import moment from "moment";
import { generateFutureTimesheet } from "./futureTimesheetGenerator";
import Button from "~/button";
import { useUpdateSearchParam } from "~/useUpdateSearchParam";

export type NextWeekDisplayerProps = {
  row: Timesheet;
  state: TimesheetState;
  dispatch: TimesheetAction;
};

export const NextWeekDisplayer: React.FC<NextWeekDisplayerProps> = (props) => {
  const [showFuture, setShowFuture] = useState(false);

  const updateSearchParam = useUpdateSearchParam();

  const { row, state, dispatch } = props;

  const { mode } = useTimesheetContext();

  const thisWeekBeginning = moment().startOf("week");

  const isCurrentWeek = moment(row.weekBeginning, "YYYY-MM-DD").isSame(
    thisWeekBeginning
  );

  const hasNextWeek =
    state.items &&
    state.items.some((x) =>
      moment(x.weekBeginning).isSame(moment(thisWeekBeginning).add(1, "week"))
    );

  const useShowFutureFilter = () => {
    setShowFuture(true);
    updateSearchParam({ param: "showFuture", value: "Yes" });
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: { ...state.filterOptions, showFuture: "Yes" },
    });
  };

  useEffect(() => {
    if (state.tag === "Loaded" && showFuture && !hasNextWeek) {
      const nextWeek = generateFutureTimesheet(row);
      dispatch({
        tag: "AddItem",
        item: nextWeek,
        index: 0,
      });
    }
  }, [state.tag]);

  if (!isCurrentWeek || hasNextWeek || mode !== "MyTimesheets") {
    return null;
  }

  return (
    <Button outlined onClick={useShowFutureFilter}>
      Show Timesheet for Next Week
    </Button>
  );
};
