import React, { createContext, useContext, useState } from "react";
import cn from "classnames";
import Button from "~/button";
import "./InlineFieldsForm.scss";
import { useSnackBar } from "~/snackbar";
import { FormStateContext } from "./FormStateContext";
import { FieldState, FormContextType, FormState } from "./types";

export type InlineFieldsFormSubmitElement = React.FC<{
  onFormSubmit: (onSubmit: (state: FormState) => Promise<any>) => Promise<void>;
  formState: FormState;
}>;

export const FormContext = createContext<FormContextType>({
  formState: { edited: false, fields: {} },
  setFormState: () => undefined,
  setError: () => undefined,
  updateValue: () => undefined,
});

type InlineFieldsFormProps = {
  initialFieldState: FieldState[];
  handleSubmit?: (state: FormState) => Promise<any>;
  updater?: Record<string, (formState: FormState, value: any) => FormState>;
  SubmitElement?: InlineFieldsFormSubmitElement;
};

const getFormData = (state: FieldState[]) => {
  const fields = {};
  const initialFormVals = {};

  state.forEach((fs) => {
    fields[fs.name] = fs;
    initialFormVals[fs.name] = fs.value;
  });

  return { fields, initialFormVals };
};

export const InlineFieldsForm: React.FC<InlineFieldsFormProps> = (props) => {
  const { initialFieldState, handleSubmit, updater, SubmitElement, children } =
    props;

  const addAlert = useSnackBar();

  const { fields, initialFormVals } = getFormData(initialFieldState);

  const [formState, setFormState] = useState<FormState>({
    fields,
    edited: false,
  });
  const [initialFormValues, setInitialFormValues] =
    useState<Record<string, any>>(initialFormVals);

  const setError = (name: string, error: string) => {
    setFormState({
      ...formState,
      fields: {
        ...formState.fields,
        [name]: {
          ...formState.fields[name],
          error,
        },
      },
    });
  };

  const updateValue = (name: string, value: any) => {
    let newState = {
      fields: {
        ...formState.fields,
        [name]: {
          ...formState.fields[name],
          error: "",
          value,
        },
      },
      edited: true,
    };

    if (updater && updater[name]) {
      const state = { ...newState };
      newState = updater[name](state, value);
    }

    setFormState(newState);
  };

  const missingValue = (value: any) =>
    value === null || value === undefined || value === "";

  const reset = () => {
    const newFields = { ...formState.fields };
    for (const [key, value] of Object.entries(initialFormValues)) {
      newFields[key] = { ...formState.fields[key], value, error: "" };
    }
    setFormState({ fields: newFields, edited: false });
  };

  const onFormSubmit = async (onSubmit: (state: FormState) => Promise<any>) => {
    const stateValues = Object.values(formState.fields);

    const hasError = stateValues.some((x) => !!x.error);

    const requiredUntouched = stateValues.filter(
      (x) => x.required && missingValue(x.value) && !x.error
    );
    const hasRequiredUntouched = requiredUntouched.length > 0;

    if (hasRequiredUntouched) {
      const newFields = { ...formState.fields };
      requiredUntouched.forEach(({ name }) => {
        newFields[name] = {
          ...formState.fields[name],
          error: "Required",
        };
      });
      setFormState({ ...formState, fields: newFields });
    }

    if (!hasError && !hasRequiredUntouched) {
      await onSubmit(formState);

      setFormState({ ...formState, edited: false });

      const newValues = {};
      Object.entries(formState.fields).forEach(([key, value]) => {
        newValues[key] = value.value;
      });
      setInitialFormValues(newValues);
      return;
    }

    addAlert({
      isSuccess: false,
      key: `${Math.random()}`,
      message: "Please correct the errors highlighted in red",
    });
  };

  const popupClass = cn("inline-fields-form-popup", { open: formState.edited });

  return (
    <>
      <FormContext.Provider
        value={{ formState, setFormState, setError, updateValue }}
      >
        <FormStateContext.Provider value={formState.fields}>
          {children}
        </FormStateContext.Provider>
      </FormContext.Provider>

      {!SubmitElement && (
        <div className={popupClass}>
          <Button
            primary
            onClick={() =>
              onFormSubmit(handleSubmit ?? (() => Promise.resolve()))
            }
          >
            Save Changes
          </Button>
          <Button outlined onClick={reset}>
            Abandon Changes
          </Button>
        </div>
      )}

      {SubmitElement && <SubmitElement {...{ onFormSubmit, formState }} />}
    </>
  );
};

type FieldContext = {
  value: any;
  required: boolean;
  setError: (name: string, error: string) => void;
  updateValue: (name: string, value: any) => void;
  error?: string;
  disabled?: boolean;
};

export function useInlineFormField(name: string): FieldContext {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error(
      "You must use useInlineFieldsForm inside an InlineFieldsForm"
    );
  }

  const { formState, setError, updateValue } = context;

  const field = formState.fields[name];

  return {
    value: field?.value ?? null,
    error: field?.error ?? "",
    disabled: field?.disabled ?? false,
    setError,
    updateValue,
    required: field?.required ?? false,
  };
}

export function useInlineFormState(): FormState {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error(
      "You must use useInlineFormState inside an InlineFieldsForm"
    );
  }

  return context.formState;
}

type FormStateRendererProps = {
  render: (fields: Record<string, FieldState>) => React.ReactElement;
};

export const FormStateRenderer: React.FC<FormStateRendererProps> = ({
  render,
}) => {
  const fields = useContext(FormStateContext);

  return render(fields);
};
