import React from "react";
import { Form, FormSpy } from "react-final-form";
import _ from "lodash";
import Button from "~/button";
import PageTitleRow from "~/page-title-row";
import "./PerDiemList.scss";
import TextFormField from "~/text-form-field";
import { useDebounce } from "@react-hook/debounce";
import PerDiemState from "~/visuals/organisms/PerDiemState";
import { perDiemRateWizard } from "~/routes";
import Spinner from "~/spinner";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { useGetPerDiemStatsQuery } from "./query.generated";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";
import { PerDiemStateStats } from "~/gql/types";

type StateCountySearchProps = {
  setSearch: React.Dispatch<React.SetStateAction<string>>;
};

const StateCountySearch: React.FC<StateCountySearchProps> = ({ setSearch }) => {
  return (
    <Form
      onSubmit={() => undefined}
      initialValues={{ stateCounty: null }}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className="inline">
            <TextFormField
              label="Search"
              formField="searchText"
              helperText=""
            />
            <FormSpy
              onChange={(props) => {
                setSearch(props.values.searchText);
              }}
            />
          </form>
        );
      }}
    />
  );
};

const PerDiemList: React.FC = () => {
  useBreadcrumbs([{ text: "Per Diem Rates" }], []);

  const [search, setSearch] = useDebounce("", 500);

  const { data, loading } = useGetPerDiemStatsQuery({
    variables: { filterText: search ? search.toUpperCase() : "" },
  });

  const perDiemStats = ((data && data.perDiemRates?.stats) ??
    []) as PerDiemStateStats[];

  const sortedStats = _.chain(perDiemStats)
    .orderBy((x) => x!.state)
    .value();

  const actions = (
    <section className="actions">
      <StateCountySearch setSearch={setSearch} />
      <Button primary route={perDiemRateWizard}>
        Set New Rates
      </Button>
    </section>
  );

  return (
    <SearchPageWrapper className="per-diem">
      <PageTitleRow title="Per Diem Rates" actions={actions} />
      <SearchListContent loading={false}>
        {sortedStats.map((stat) => (
          <PerDiemState
            key={stat.state}
            state={stat.state}
            filteredText={search}
            recordCount={stat.records}
            totalRecords={stat.totalRecords}
          />
        ))}
      </SearchListContent>
      <Spinner open={loading} />
    </SearchPageWrapper>
  );
};

export default PerDiemList;
