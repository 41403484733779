import React from "react";
import { useCreateMileageExpenseRateMutation } from "./queries.generated";
import { MutationForm } from "~/forms/MutationForm";
import { CreateExpenseRateFormData, CreateExpenseRateFormProps } from "./types";
import { parseNum } from ".";
import { ValidationResponse } from "~/gql/types";

export const NewMileageExpenseRate: React.FC<CreateExpenseRateFormProps> = (
  props
) => {
  const { setLoading, onSuccess, initialValues, rateSheetName, children } =
    props;

  const [rawMileageMutation] = useCreateMileageExpenseRateMutation();

  const handleSubmit = async (values: CreateExpenseRateFormData) => {
    const variables = {
      name: values?.name!,
      invoiceLabel: values?.invoiceLabel || null,
      expenseAccount: values?.expenseAccount || null,
      glAccount: values?.glAccount || null,
      rateSheetName: rateSheetName,
      billableAmountPerMile: parseNum(values?.billableAmountPerMile)!,
      reimbursableAmountPerMile: parseNum(values?.reimbursableAmountPerMile)!,
    };

    const result = await rawMileageMutation({ variables });

    return result?.data?.expenseRates?.addMileageRate as ValidationResponse;
  };

  return (
    <MutationForm
      setLoading={setLoading}
      initialValues={initialValues}
      runMutation={handleSubmit}
      onSuccess={onSuccess}
      allowPristineSubmit
    >
      {children}
    </MutationForm>
  );
};
