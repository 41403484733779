import React from "react";
import TitledCard from "~/titled-card";
import classnames from "classnames";
import { Body1 } from "@material/react-typography";
import "./WorkdayCard.scss";

type WorkdayCardProps<T> = {
  actions: React.ReactChild;
  title: string;
  placeholderText: string;
  editable: boolean;
  className: string;
  data: T[];
  warnMessage?: string;
  subtitle?: string;
  children: React.ReactNode;
};

export function WorkdayCard<T>(props: WorkdayCardProps<T>): React.ReactElement {
  const {
    actions,
    title,
    className,
    data,
    children,
    warnMessage,
    subtitle: propSubtitle,
    editable,
    placeholderText,
  } = props;
  if (data.length === 0) {
    return (
      <div className="empty-placeholder">
        {editable && actions}
        {!editable && (
          <Body1 className="placeholder-text">{placeholderText}</Body1>
        )}
      </div>
    );
  }

  const subtitle = warnMessage ?? propSubtitle;
  const subtitleClassName = warnMessage ? "warning" : undefined;

  const cn = classnames(className, "workday-card");

  return (
    <TitledCard
      {...{ title, className: cn, subtitle, subtitleClassName }}
      actions={actions}
    >
      {children}
    </TitledCard>
  );
}
