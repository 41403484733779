import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DraftInvoicesQueryQueryVariables = Types.Exact<{
  projectNumber: Types.Scalars['String'];
}>;


export type DraftInvoicesQueryQuery = { __typename?: 'QueryModel', invoices?: { __typename?: 'InvoicesQueryModel', draftInvoices?: Array<{ __typename?: 'Invoice', invoiceNumber: string, total: number, lineItems?: Array<{ __typename?: 'InvoiceLineItem', label: string } | null> | null } | null> | null } | null };


export const DraftInvoicesQueryDocument = gql`
    query DraftInvoicesQuery($projectNumber: String!) {
  invoices {
    draftInvoices(projectNumber: $projectNumber) {
      invoiceNumber
      total
      lineItems {
        label
      }
    }
  }
}
    `;

/**
 * __useDraftInvoicesQueryQuery__
 *
 * To run a query within a React component, call `useDraftInvoicesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftInvoicesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftInvoicesQueryQuery({
 *   variables: {
 *      projectNumber: // value for 'projectNumber'
 *   },
 * });
 */
export function useDraftInvoicesQueryQuery(baseOptions: Apollo.QueryHookOptions<DraftInvoicesQueryQuery, DraftInvoicesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DraftInvoicesQueryQuery, DraftInvoicesQueryQueryVariables>(DraftInvoicesQueryDocument, options);
      }
export function useDraftInvoicesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DraftInvoicesQueryQuery, DraftInvoicesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DraftInvoicesQueryQuery, DraftInvoicesQueryQueryVariables>(DraftInvoicesQueryDocument, options);
        }
export type DraftInvoicesQueryQueryHookResult = ReturnType<typeof useDraftInvoicesQueryQuery>;
export type DraftInvoicesQueryLazyQueryHookResult = ReturnType<typeof useDraftInvoicesQueryLazyQuery>;
export type DraftInvoicesQueryQueryResult = Apollo.QueryResult<DraftInvoicesQueryQuery, DraftInvoicesQueryQueryVariables>;