import React, { useContext } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import MyWorkDay from "~/WorkData/MyWorkDay";
import NewTimeEntry from "~/visuals/pages/NewTimeEntry";
import NewTimeOffEntry from "~/visuals/pages/NewTimeOffEntry";
import NewProjectTimeEntry from "~/visuals/pages/NewProjectTimeEntry";
import NewExpenseEntry from "~/visuals/pages/NewExpenseEntry";
import EditTimeOffEntry from "~/visuals/pages/EditTimeOffEntry";
import EditTimeEntry from "~/visuals/pages/EditTimeEntry";
import EditProjectTimeEntry from "~/visuals/pages/EditProjectTimeEntry";
import EditExpenseEntry from "~/visuals/pages/EditExpenseEntry";
import WorkDayApiProvider from "~/WorkDayApi";
import ReviewWorkDay from "./ReviewWorkDay";
import { WorkDay } from "~/WorkDayApi/WorkDayApiType";
import Spinner from "~/spinner";
import CloneProjectTimeEntry from "~/visuals/pages/CloneProjectTimeEntry";
import {
  cloneProjectTimeEntry,
  createProjectTimeEntry,
  editProjectTimeEntry,
} from "~/visuals/organisms/WorkDay/ProjectTimeCard";
import {
  createTimeOffEntry,
  editTimeOffEntry,
} from "~/visuals/organisms/WorkDay/TimeOffCard";
import {
  createTimeEntry,
  editTimeEntry,
} from "~/visuals/organisms/WorkDay/TimeCard";
import { useWorkDayQueryQuery } from "./query.generated";
import CloneExpense from "~/WorkData/WorkDayRouter/CloneExpenseEntry";
import {
  cloneExpenseEntry,
  createExpenseEntry,
  editExpenseEntry,
} from "~/visuals/organisms/Expenses/expenseRoutes";
import { WorkDayCopyProvider } from "~/WorkDayApi/WorkDayCopyProvider";
import { WorkDayContext } from "./WorkDataContext";

export type MyWorkDayRouteData = {
  date: string;
  userPrincipalName: string;
  timeEntryId?: string;
  timeOffEntryId?: string;
  projectTimeId?: string;
  expenseId?: string;
};

const useReload = (): (() => Promise<unknown>) => {
  const api = useContext(WorkDayContext);

  if (!api) {
    throw new Error(
      "Do not use WorkDayRouter.useReload outside of a WorkDayRouter."
    );
  }

  return api.reload;
};

export type RouteProps = {
  workDay: WorkDay;
  upn: string;
  type: "review" | "my";
};

const DayRouter: React.FC<{ type: string }> = ({ type }) => {
  if (type === "review") {
    return <ReviewWorkDay />;
  }
  return <MyWorkDay />;
};

const PageRoutes: React.FC<RouteProps> = ({ workDay, upn, type }) => (
  <WorkDayApiProvider {...{ workDay, useReload, upn }}>
    <WorkDayCopyProvider>
      <Routes>
        <Route path={`${createTimeOffEntry}/*`} element={<NewTimeOffEntry />} />

        <Route path={`${editTimeOffEntry}/*`}>
          <Route path=":timeOffEntryId" element={<EditTimeOffEntry />} />
        </Route>

        <Route path={`${createTimeEntry}/*`} element={<NewTimeEntry />} />

        <Route path={`${editTimeEntry}/*`}>
          <Route path={":timeEntryId"} element={<EditTimeEntry />} />
        </Route>

        <Route path={`${createExpenseEntry}/*`} element={<NewExpenseEntry />} />

        <Route path={`${editExpenseEntry}/*`}>
          <Route path={":expenseId/*"} element={<EditExpenseEntry />} />
        </Route>

        <Route path={`${cloneExpenseEntry}/*`}>
          <Route path={":expenseId/*"} element={<CloneExpense />} />
        </Route>

        <Route path={`${createProjectTimeEntry}/*`}>
          <Route
            path={":projectNumber/:projectName"}
            element={<NewProjectTimeEntry />}
          />
          <Route
            path={":projectNumber/:projectName/:taskName"}
            element={<NewProjectTimeEntry />}
          />
          <Route path={"*"} element={<NewProjectTimeEntry />} />
        </Route>

        <Route path={`${editProjectTimeEntry}/*`}>
          <Route path={":projectTimeId"} element={<EditProjectTimeEntry />} />
        </Route>

        <Route path={`${cloneProjectTimeEntry}/*`}>
          <Route path={":projectTimeId"} element={<CloneProjectTimeEntry />} />
        </Route>

        <Route path={"*"} element={<DayRouter type={type} />} />
      </Routes>
    </WorkDayCopyProvider>
  </WorkDayApiProvider>
);

type WorkDayRouterProps = {
  RoutesComponent?: React.FC<RouteProps>;
};

const WorkDayRouter: React.FC<WorkDayRouterProps> = ({
  RoutesComponent = PageRoutes,
}) => {
  const params = useParams<MyWorkDayRouteData>();
  const location = useLocation();

  const type = location.pathname.includes("review") ? "review" : "my";

  const date = params?.date!;
  const userPrincipalName = params?.userPrincipalName!;

  const variables = { date, userPrincipalName };

  const {
    data,
    refetch: reload,
    loading,
  } = useWorkDayQueryQuery({ variables });

  const workDay = data?.timesheets?.workDay as WorkDay;

  if (workDay && !loading) {
    const api = { reload };

    return (
      <WorkDayContext.Provider value={api}>
        <RoutesComponent {...{ workDay, type, upn: userPrincipalName }} />
      </WorkDayContext.Provider>
    );
  }

  return <Spinner open={true} />;
};

export default WorkDayRouter;

export const MockWorkDayContextProvider: React.FC<{ reload?: () => void }> = ({
  reload,
  children,
}) => (
  <WorkDayContext.Provider value={{ reload: reload ?? (() => undefined) }}>
    {children}
  </WorkDayContext.Provider>
);
