import React, { useEffect, useState } from "react";
import { InlineProgress } from "~/inline-progress";
import CurrencyFormField from "~/visuals/organisms/CurrencyFormField";
import { useSetRateAmount } from "../useSetRateAmount";
import { useChargeFormField } from "./fieldUtils";

type RateLoaderProps = {
  setRateAmount: React.Dispatch<string | null>;
  setLoading: React.Dispatch<boolean>;
};

const RateAmountSetter: React.FC<RateLoaderProps> = ({
  setRateAmount,
  setLoading,
}) => {
  const date = useChargeFormField("date").value;
  const project = useChargeFormField("project").value;
  const type = useChargeFormField("type").value;
  const employee = useChargeFormField("employee").value;
  const { value: rateValue, initial: initialRate } = useChargeFormField("rate");
  const { value: sheetValue, initial: initialSheet } =
    useChargeFormField("rateSheet");

  const setAmount = useSetRateAmount();

  useEffect(() => {
    if (
      !date ||
      !project ||
      !rateValue ||
      (rateValue === initialRate && sheetValue === initialSheet)
    ) {
      return;
    }

    void setAmount({
      project,
      date,
      rate: rateValue,
      type,
      employee,
      setRateAmount,
      setLoading,
    });
  }, [rateValue, employee]);

  return <></>;
};

export const RateAmountField: React.FC = () => {
  const date = useChargeFormField("date").value;
  const project = useChargeFormField("project").value;
  const rateSheet = useChargeFormField("rateSheet").value;
  const useOtherLabel = useChargeFormField("useOtherLabel").value;
  const { onChange, value } = useChargeFormField("rateAmount");

  const [loading, setLoading] = useState(false);
  const [rateAmount, setRateAmount] = useState<string | null>(value);

  const disabled = !date || !project || (!rateSheet && !useOtherLabel);

  useEffect(() => {
    onChange(rateAmount);
  }, [rateAmount]);

  useEffect(() => {
    if (value !== rateAmount) {
      setRateAmount(value);
    }
  }, [value]);

  return (
    <>
      <CurrencyFormField
        label="Rate Amount"
        helperText=""
        formField="rateAmount"
        required
        disabled={disabled}
      />
      {loading && !useOtherLabel && <InlineProgress />}
      <RateAmountSetter {...{ setRateAmount, setLoading }} />
    </>
  );
};
