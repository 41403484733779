import moment from "moment";
import { Timesheet, WorkDaySummary } from "./types";
import { TimesheetStatus } from "~/gql/types";

export function generateFutureTimesheet(lastTimesheet: Timesheet): Timesheet {
  const lastStart = moment(lastTimesheet.weekBeginning);
  const newStart = moment(lastStart).add(1, "week").format("YYYY-MM-DD");

  const ultiProId = lastTimesheet.timesheetId.split(".")[2];

  const newTimesheetId = ["W", newStart, ultiProId].join(".");

  const emptyDay = {
    companyPaid: 0,
    expensesBillable: 0,
    expensesReimbursable: 0,
    hasReceipts: false,
    mileage: 0,
    payroll: 0,
    projectTime: "0:00:00",
    time: "0:00:00",
    timeOff: "0:00:00",
    totalExpensesPayable: 0,
  } as WorkDaySummary;

  const workDays = lastTimesheet.daySummaries?.map((d) => ({
    ...emptyDay,
    date: moment(d?.date).add(1, "week").format("YYYY-MM-DD"),
  }));

  return {
    timesheetId: newTimesheetId,
    weekBeginning: newStart,
    employee: lastTimesheet.employee,
    canBeApproved: false,
    canBeRejected: false,
    canBeSubmitted: false,
    canBeReset: false,
    canUpdateFinalPayroll: false,
    companyPaid: 0,
    daySummaries: workDays,
    expensesReimbursable: 0,
    payroll: 0,
    projectsWithDailies: [],
    status: TimesheetStatus.Open,
    totalExpensesPayable: 0,
    totalMileage: 0,
  };
}
