import React from "react";
import Button from "~/button";
import { useSnackBar } from "~/snackbar";
import { ValidationError } from "~/gql/types";
import { useConfirmationPrompt } from "~/confirmation-prompt";

type InvoiceRemoveLineItemProps = {
  refetch: React.DispatchWithoutAction;
  remove: () => Promise<any>;
  className: string;
};

export const InvoiceRemoveLineItem: React.FC<InvoiceRemoveLineItemProps> = (
  props
) => {
  const { remove, refetch, className } = props;
  const lineItemMessage = `line item${
    className == "remove-invoice-button" ? " invoice" : ""
  }`;
  const addAlert = useSnackBar();
  const prompt = useConfirmationPrompt();
  const openDeleteConfirmation = async () => {
    const promptResult = await prompt({
      cancelButtonText: "Cancel",
      confirmButtonText: `Yes, remove ${lineItemMessage}`,
      message: `Are you sure you want to remove this ${lineItemMessage}?`,
      title: `Remove ${lineItemMessage}`,
    });

    if (promptResult !== "Confirm") {
      return;
    }

    await removeLineItem();
  };

  const removeLineItem = async () => {
    const { data, errors } = await remove();

    const responseErrors =
      className == "remove-invoice-button"
        ? errors ?? data?.customRollupInvoices?.removeLineItem?.errors ?? []
        : ((errors ??
            data?.invoices?.removeCharge?.errors ??
            []) as ValidationError[]);

    if (responseErrors.length > 0) {
      const errors: string[] = [];
      responseErrors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          errors.push(`${argumentName}: ${message}`);
        } else if (message) {
          errors.push(message);
        }
      });

      addAlert({
        key: `${Math.random()}`,
        message: errors.join(" "),
        isSuccess: false,
      });
    } else {
      const message = `Removed ${lineItemMessage}`;
      addAlert({
        isSuccess: true,
        message,
        key: `${Math.random()}`,
      });
    }
    refetch();
  };
  return (
    <>
      <Button
        onClick={openDeleteConfirmation}
        icon="delete"
        className={className}
      />
    </>
  );
};
