import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopyWorkDayMutationVariables = Types.Exact<{
  idToCopy: Types.Scalars['String'];
  id: Types.Scalars['String'];
  includeExpenses: Types.Scalars['Boolean'];
  includeTimeOff: Types.Scalars['Boolean'];
  includeTimeEntries: Types.Scalars['Boolean'];
  includeProjectTime: Types.Scalars['Boolean'];
}>;


export type CopyWorkDayMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', copyWorkDay?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CopyWorkDayDocument = gql`
    mutation CopyWorkDay($idToCopy: String!, $id: String!, $includeExpenses: Boolean!, $includeTimeOff: Boolean!, $includeTimeEntries: Boolean!, $includeProjectTime: Boolean!) {
  timesheets {
    copyWorkDay(
      idToCopy: $idToCopy
      includeExpenses: $includeExpenses
      includeProjectTime: $includeProjectTime
      includeTimeEntries: $includeTimeEntries
      includeTimeOff: $includeTimeOff
      id: $id
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CopyWorkDayMutationFn = Apollo.MutationFunction<CopyWorkDayMutation, CopyWorkDayMutationVariables>;

/**
 * __useCopyWorkDayMutation__
 *
 * To run a mutation, you first call `useCopyWorkDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyWorkDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyWorkDayMutation, { data, loading, error }] = useCopyWorkDayMutation({
 *   variables: {
 *      idToCopy: // value for 'idToCopy'
 *      id: // value for 'id'
 *      includeExpenses: // value for 'includeExpenses'
 *      includeTimeOff: // value for 'includeTimeOff'
 *      includeTimeEntries: // value for 'includeTimeEntries'
 *      includeProjectTime: // value for 'includeProjectTime'
 *   },
 * });
 */
export function useCopyWorkDayMutation(baseOptions?: Apollo.MutationHookOptions<CopyWorkDayMutation, CopyWorkDayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyWorkDayMutation, CopyWorkDayMutationVariables>(CopyWorkDayDocument, options);
      }
export type CopyWorkDayMutationHookResult = ReturnType<typeof useCopyWorkDayMutation>;
export type CopyWorkDayMutationResult = Apollo.MutationResult<CopyWorkDayMutation>;
export type CopyWorkDayMutationOptions = Apollo.BaseMutationOptions<CopyWorkDayMutation, CopyWorkDayMutationVariables>;