import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllCrewCodesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllCrewCodesQuery = { __typename?: 'QueryModel', allCrewCodes?: Array<{ __typename?: 'CrewCodeRecord', crewCode: string, state: string, overrideEmployeeId?: string | null, isActive: boolean, employee?: { __typename?: 'Employee', firstName: string, lastName: string, userPrincipalName: string, ultiProId: string, isMobileEmployee: boolean, isTerminated: boolean } | null } | null> | null };


export const GetAllCrewCodesDocument = gql`
    query GetAllCrewCodes {
  allCrewCodes {
    crewCode
    state
    employee {
      firstName
      lastName
      userPrincipalName
      ultiProId
      isMobileEmployee
      isTerminated
    }
    overrideEmployeeId
    isActive
  }
}
    `;

/**
 * __useGetAllCrewCodesQuery__
 *
 * To run a query within a React component, call `useGetAllCrewCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCrewCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCrewCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCrewCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCrewCodesQuery, GetAllCrewCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCrewCodesQuery, GetAllCrewCodesQueryVariables>(GetAllCrewCodesDocument, options);
      }
export function useGetAllCrewCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCrewCodesQuery, GetAllCrewCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCrewCodesQuery, GetAllCrewCodesQueryVariables>(GetAllCrewCodesDocument, options);
        }
export type GetAllCrewCodesQueryHookResult = ReturnType<typeof useGetAllCrewCodesQuery>;
export type GetAllCrewCodesLazyQueryHookResult = ReturnType<typeof useGetAllCrewCodesLazyQuery>;
export type GetAllCrewCodesQueryResult = Apollo.QueryResult<GetAllCrewCodesQuery, GetAllCrewCodesQueryVariables>;