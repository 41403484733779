import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StateCountiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StateCountiesQuery = { __typename?: 'QueryModel', stateCounties?: Array<{ __typename?: 'StateCounty', state: string, county: string } | null> | null };


export const StateCountiesDocument = gql`
    query StateCounties {
  stateCounties {
    state
    county
  }
}
    `;

/**
 * __useStateCountiesQuery__
 *
 * To run a query within a React component, call `useStateCountiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStateCountiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStateCountiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStateCountiesQuery(baseOptions?: Apollo.QueryHookOptions<StateCountiesQuery, StateCountiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StateCountiesQuery, StateCountiesQueryVariables>(StateCountiesDocument, options);
      }
export function useStateCountiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StateCountiesQuery, StateCountiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StateCountiesQuery, StateCountiesQueryVariables>(StateCountiesDocument, options);
        }
export type StateCountiesQueryHookResult = ReturnType<typeof useStateCountiesQuery>;
export type StateCountiesLazyQueryHookResult = ReturnType<typeof useStateCountiesLazyQuery>;
export type StateCountiesQueryResult = Apollo.QueryResult<StateCountiesQuery, StateCountiesQueryVariables>;