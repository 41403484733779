import React, { useState } from "react";
import TitledCard from "~/titled-card";
import _ from "lodash";
import { ChargesTable } from "~/charges-table";
import { ScrollTo } from "~/scrollTo";
import { Body2 } from "@material/react-typography";
import { currencyFormatter } from "~/currencyFormatter";
import cn from "classnames";
import { useResetSearchItems } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import { Project } from "~/visuals/pages/Project/ProjectDetails/types";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import ClickSwallower from "~/click-swallower";
import { ProjectChargeForm } from "~/project-charge-form";
import { SingleChargeLoader } from "~/single-charge-loader";
import { AddChargeButton } from "./AddChargeButton";
import { organizeCharges } from "./organizeCharges";
import { ToolTip } from "~/tooltip";
import { ProjectCharge, ProjectStatus } from "~/gql/types";
import { Charge } from "~/charges-table/types";
import { ProjectChargesFilter } from "./types";
import { ProjectLinkButton } from "~/project-link-button";
import "./ProjectChargesCard.scss";

type ProjectChargesCardProps = {
  project: Project;
  charges: ProjectCharge[];
  state: State<ProjectCharge, ProjectChargesFilter>;
  dispatch: React.Dispatch<Action<ProjectCharge, ProjectChargesFilter>>;
  _TableComponent?: typeof ChargesTable;
  _FormComponent?: typeof AddChargeToProject;
};

export type AddChargeToProjectProps = {
  project: Project;
  addCharge: (charge: ProjectCharge) => void;
  open: boolean;
  setOpen: React.Dispatch<boolean>;
};

export const AddChargeToProject: React.FC<AddChargeToProjectProps> = ({
  project,
  addCharge,
  open,
  setOpen,
}) => {
  const [chargeId, setChargeId] = useState<number | null>(null);

  return (
    <>
      <ClickSwallower>
        <ProjectChargeForm
          {...{
            project,
            open,
            setOpen,
            mutation: "createProjectCharge",
            onSuccessFn: (id: number | null) => setChargeId(id),
            totalSelected: 1,
          }}
        />
      </ClickSwallower>
      {chargeId && (
        <SingleChargeLoader
          {...{
            dependency: !!chargeId,
            id: chargeId!,
            callback: (charge: ProjectCharge) => {
              addCharge(charge);
              setChargeId(null);
            },
          }}
        />
      )}
    </>
  );
};

export const ProjectChargesCard: React.FC<ProjectChargesCardProps> = (
  props
) => {
  const { project, charges, state, dispatch, _TableComponent, _FormComponent } =
    props;
  const chargeProps: Charge[] = organizeCharges(charges);
  const [formOpen, setFormOpen] = useState(false);

  const reset = useResetSearchItems();

  const addCharge = (charge: ProjectCharge) =>
    dispatch({ tag: "AddItem", item: charge });

  const cardTitle = (
    <ToolTip text={project.name}>
      <span className="charges-card-title">
        <span className="project-number">{project.number}</span>
        <span className="project-name">
          {project.name}
          <ProjectLinkButton {...{ project }} />
        </span>
        <AddChargeButton {...{ setOpen: setFormOpen }} />
        <span className="customer-name">{project.customer!.name}</span>
        <span className="state">{project.location!.state}</span>
      </span>
    </ToolTip>
  );
  const actions = (
    <div className="charge-card-actions">
      <StackedBarStats {...{ project }} />

      <Badges {...{ project }} />
    </div>
  );

  const ChargeTableComponent = _TableComponent ?? ChargesTable;
  const AddChargeComponent = _FormComponent ?? AddChargeToProject;

  const cardStyle = cn("project-charges-search-card", {
    closed: project.status === ProjectStatus.Closed,
  });

  return (
    <ScrollTo search="lastProject" value={`${project.number}`}>
      <TitledCard title={cardTitle} className={cardStyle} actions={actions}>
        {chargeProps.length > 0 ? (
          <ChargeTableComponent
            {...{
              charges: chargeProps,
              showHeader: true,
              refetch: reset,
              state,
              dispatch,
              addCharge,
              updateCharge: (charge: ProjectCharge) =>
                dispatch({ tag: "UpdateItem", item: charge }),
            }}
          />
        ) : (
          <Body2>No charges found</Body2>
        )}
        <AddChargeComponent
          {...{ project, addCharge, open: formOpen, setOpen: setFormOpen }}
        />
      </TitledCard>
    </ScrollTo>
  );
};

export const StackedBarStats: React.FC<{ project: Project }> = ({
  project,
}) => {
  const {
    totalPendingApproval,
    totalPostedNotBilled,
    totalPostPending,
    totalBilled,
    totalNonBillable,
  } = project.stats!;

  const chargeTotals = [
    {
      amount: totalPendingApproval,
      color: "rgb(57,146,131)",
      title: "Needs Approval",
    },
    {
      amount: totalPostPending,
      color: "rgb(125,172,34)",
      title: "Post Pending",
    },
    {
      amount: totalPostedNotBilled,
      color: "rgb(102,114,187)",
      title: "Posted Not Billed",
    },
    { amount: totalBilled, color: "rgb(2,183,46)", title: "Billed" },
    {
      amount: totalNonBillable,
      color: "rgb(210,100,33)",
      title: "Non-Billable",
    },
  ];

  const total = _.sum(
    chargeTotals.filter((x) => x.title !== "Non-Billable").map((x) => x.amount)
  );

  const Bars = () => (
    <div className="bars">
      {chargeTotals
        .filter((x) => x.amount > 0)
        .map(({ amount, color, title }, idx) => (
          <div
            className={title.toLowerCase().replace(/ /g, "-")}
            key={idx}
            style={{
              width: `${(amount / total) * 100}%`,
              minWidth: "12%",
              backgroundColor: color,
            }}
          >
            <Body2>{currencyFormatter.format(amount)}</Body2>
          </div>
        ))}
    </div>
  );

  const Info = () => (
    <div className="info">
      {chargeTotals.map(({ amount, color, title }, idx) => (
        <div key={idx}>
          <span style={{ backgroundColor: color }} />
          <Body2>{`${title}: ${currencyFormatter.format(amount)}`}</Body2>
        </div>
      ))}
    </div>
  );

  const percent = project.budget ? (total / project.budget) * 100 : 0;
  const percentText = project.budget
    ? `${percent.toFixed(2)}% of ${currencyFormatter.format(project.budget)}`
    : "";
  const percentClass = cn("stacked-bar-percent", {
    "over-budget": percent > 100,
  });

  return (
    <div className="charges-stacked-bar">
      <Info />
      <Bars />
      <Body2 className={percentClass}>{percentText}</Body2>
    </div>
  );
};

type BadgeType = {
  title: string;
  value?: string | null;
};
export const Badges: React.FC<{ project: Project }> = ({ project }) => {
  const afeWoPo: BadgeType[] = [
    { title: "AFE", value: project.afe },
    { title: "WO", value: project.wo },
    { title: "PO", value: project.po },
    { title: "CC", value: project.costCenter },
  ];
  return (
    <div className="badge">
      {afeWoPo.map((x) => {
        if (!x.value) {
          return null;
        }
        return (
          <div key={x.title} className="badge-content-container">
            <Body2 className="badge-label">{x.title}</Body2>
            <Body2 className="popup">{x.value}</Body2>
          </div>
        );
      })}
    </div>
  );
};
