import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SplitChargeMutationVariables = Types.Exact<{
  label: Types.Scalars['String'];
  quantity: Types.Scalars['Decimal'];
  notes: Types.Scalars['String'];
  adminNotes: Types.Scalars['String'];
  taxable: Types.Scalars['Boolean'];
  splitChargeArgs?: Types.InputMaybe<Array<Types.SplitChargeArgsInput> | Types.SplitChargeArgsInput>;
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  id: Types.Scalars['Int'];
}>;


export type SplitChargeMutation = { __typename?: 'MutationModel', projectCharges?: { __typename?: 'ProjectChargesMutationModel', splitCharge?: { __typename?: 'SplitChargeResponse', splitChargeIds?: Array<number> | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const SplitChargeDocument = gql`
    mutation SplitCharge($label: String!, $quantity: Decimal!, $notes: String!, $adminNotes: String!, $taxable: Boolean!, $splitChargeArgs: [SplitChargeArgsInput!], $projectId: Int, $id: Int!) {
  projectCharges {
    splitCharge(
      label: $label
      quantity: $quantity
      notes: $notes
      adminNotes: $adminNotes
      taxable: $taxable
      projectId: $projectId
      splitChargeArgs: $splitChargeArgs
      id: $id
    ) {
      errors {
        argumentName
        message
      }
      splitChargeIds
    }
  }
}
    `;
export type SplitChargeMutationFn = Apollo.MutationFunction<SplitChargeMutation, SplitChargeMutationVariables>;

/**
 * __useSplitChargeMutation__
 *
 * To run a mutation, you first call `useSplitChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSplitChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [splitChargeMutation, { data, loading, error }] = useSplitChargeMutation({
 *   variables: {
 *      label: // value for 'label'
 *      quantity: // value for 'quantity'
 *      notes: // value for 'notes'
 *      adminNotes: // value for 'adminNotes'
 *      taxable: // value for 'taxable'
 *      splitChargeArgs: // value for 'splitChargeArgs'
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSplitChargeMutation(baseOptions?: Apollo.MutationHookOptions<SplitChargeMutation, SplitChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SplitChargeMutation, SplitChargeMutationVariables>(SplitChargeDocument, options);
      }
export type SplitChargeMutationHookResult = ReturnType<typeof useSplitChargeMutation>;
export type SplitChargeMutationResult = Apollo.MutationResult<SplitChargeMutation>;
export type SplitChargeMutationOptions = Apollo.BaseMutationOptions<SplitChargeMutation, SplitChargeMutationVariables>;