import React from "react";
import FormCard from "~/form-card";
import PageTitleRow from "~/page-title-row";
import Spinner from "~/spinner";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { ValidationResponse } from "~/gql/types";
import { useUpdateThirdPartyInvoiceConfigMutation } from "./updateConfig.generated";
import { useGetThirdPartyInvoiceConfigQuery } from "./getConfig.generated";
import { useSnackBar } from "~/snackbar";
import TextFormField from "~/text-form-field";
import FormBottomRow from "~/form-bottom-row";
import { useNavigate } from "react-router-dom";
import { thirdPartyInvoices } from "~/routes";

export const ThirdPartyInvoicesConfig: React.FC = () => {
  const [doUpdate, { loading: mutationLoading }] =
    useUpdateThirdPartyInvoiceConfigMutation();
  const {
    data,
    refetch,
    loading: queryLoading,
  } = useGetThirdPartyInvoiceConfigQuery();
  const addAlert = useSnackBar();
  const navigate = useNavigate();

  const config = data?.thirdPartyInvoices?.config;

  const initialValues = {
    glAccount: config?.glAccount ?? null,
  };

  const onSuccess = () => {
    addAlert({
      message: "Third party invoice configuration updated",
      isSuccess: true,
      key: `${Math.random()}`,
    });
    void refetch();
  };

  const runMutation = async (values: { glAccount: string | null }) => {
    const variables = {
      glAccount: values.glAccount!,
    };

    const result = await doUpdate({ variables });

    return result?.data?.thirdPartyInvoices?.updateConfig as ValidationResponse;
  };

  return (
    <div>
      <PageTitleRow title="Third Party Invoice Configuration" />
      <Spinner open={queryLoading || mutationLoading} />
      <FormCard>
        <MutationForm
          {...{
            initialValues,
            onSuccess,
            runMutation,
          }}
        >
          <TextFormField
            formField="glAccount"
            label="GL Account"
            helperText=""
            required
          />

          <ErrorRenderer
            render={(errorMessage) => (
              <FormBottomRow
                {...{
                  errorMessage,
                  buttonText: "Save",
                  onCancel: () => navigate(thirdPartyInvoices.path),
                }}
              ></FormBottomRow>
            )}
          />
        </MutationForm>
      </FormCard>
    </div>
  );
};
