import React from "react";
import "./SearchPageWrapper.scss";
import classnames from "classnames";

export const SearchPageWrapper: React.FC<{
  className?: string;
  withSort?: boolean;
  withPadding?: boolean;
  withFilters?: boolean;
}> = ({ children, className, withSort, withPadding, withFilters = true }) => {
  const searchPageStyle = classnames("search-page-wrapper", className, {
    "with-sort": withSort,
    "with-padding": withPadding,
    "with-filters": withFilters,
  });

  return <div className={searchPageStyle}>{children}</div>;
};

export * from "./search-list-content";
