import _ from "lodash";
import React, { useState } from "react";
import Button from "~/button";
import { Product, ValidationResponse } from "~/gql/types2";
import { Dialog, DialogTitle, DialogContent } from "~/dialog";
import FormBottomRow from "~/form-bottom-row";
import {
  ErrorRenderer,
  MutationForm,
  OnSuccessProps,
} from "~/forms/MutationForm";
import { useCreateProductMutation } from "./CreateProduct.hotchoc.generated";
import TextFormField from "~/text-form-field";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import styles from "./CreateProductButton.module.scss";

type CreateProductButtonProps = {
  onCreated: React.Dispatch<Product>;
};

const emptyFormValues = Object.freeze({
  name: "",
  isVariableRate: false,
});

export const CreateProductButton: React.FC<CreateProductButtonProps> = ({
  onCreated,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [doMutation] = useCreateProductMutation({
    context: { clientName: "graphql2" },
  });

  const onSuccess = (
    vals: OnSuccessProps<ValidationResponse, typeof emptyFormValues>
  ) => {
    onCreated(vals.values as Product);
    setDialogOpen(false);
  };

  const runMutation = async (formVals): Promise<ValidationResponse> => {
    const variables = {
      input: {
        ...formVals,
      },
    };

    const result = await doMutation({ variables });

    return result.data?.products.create! as any as ValidationResponse;
  };

  const openDialogClick = (evt) => {
    setDialogOpen(true);
    evt.preventDefault();
    return false;
  };

  return (
    <>
      <Button
        icon="add"
        outlined
        className={styles.AddButton}
        onClick={openDialogClick}
      />
      <Dialog portalize onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <DialogTitle>Add New Product</DialogTitle>
        <DialogContent>
          <MutationForm
            initialValues={emptyFormValues}
            onSuccess={onSuccess}
            runMutation={runMutation}
          >
            <TextFormField
              formField="name"
              helperText="The name of the product"
              label="Name"
              required
            />
            <BinaryPickerFormField
              formField="isVariableRate"
              label="Variable Rate?"
              helperText="Whether or not the product is variable rate (e.g. pass-through billing items, percentage fees)"
              required
            />
            <ErrorRenderer
              render={(submitError) => (
                <FormBottomRow
                  buttonText="Create"
                  errorMessage={submitError}
                  onCancel={() => setDialogOpen(false)}
                />
              )}
            />
          </MutationForm>
        </DialogContent>
      </Dialog>
    </>
  );
};
