import React, { useEffect, useState } from "react";
import ExpensePickerFormField from "~/visuals/organisms/ExpensePickerFormField";
import TaskPickerFormField from "~/visuals/organisms/TaskPickerFormField";
import { ExpenseRate } from "~/graphql/ExpenseRateSheet";
import { IExpenseRate, TaskRate } from "~/gql/types";
import { useChargeFormField } from "./fieldUtils";
import TextFormField from "~/text-form-field";
import { InlineProgress } from "~/inline-progress";
import { useFormState } from "react-final-form";
import { useLoadRates } from "../useLoadRates";

type RateInfo = {
  rateSheet: string | null;
  rates: ExpenseRate[] | TaskRate[];
};

type RateSheetLoaderProps = {
  setRateInfo: React.Dispatch<RateInfo>;
  setLoading: React.Dispatch<boolean>;
};

const RateSheetLoader: React.FC<RateSheetLoaderProps> = ({
  setRateInfo,
  setLoading,
}) => {
  const loadRates = useLoadRates();

  const useOtherLabel = useChargeFormField("useOtherLabel").value;
  const type = useChargeFormField("type");
  const date = useChargeFormField("date").value;
  const project = useChargeFormField("project").value;
  const formState = useFormState();
  const rateSheet = useChargeFormField("rateSheet").value;

  const { onChange: changeRate } = useChargeFormField("rate");

  const clearRateInfo = () => {
    setRateInfo({ rateSheet: null, rates: [] });
    changeRate(null);
  };

  useEffect(() => {
    if (useOtherLabel) {
      return;
    }

    if (!formState.pristine) {
      clearRateInfo();
    }

    if (!type.value || !date || !project) {
      return;
    }

    void (async () => {
      await loadRates({
        date,
        projectNumber: project.number,
        setLoading,
        callback: (args) => setRateInfo(args),
        type: type.value,
      });
    })();
  }, [type.value, project, useOtherLabel]);

  useEffect(() => {
    if (useOtherLabel) {
      return;
    }

    if (!type.value || !date || !project || formState.pristine) {
      return;
    }

    void (async () => {
      await loadRates({
        date,
        projectNumber: project.number,
        setLoading,
        callback: (args) => {
          if (args.rateSheet != rateSheet) {
            changeRate(null);
          }
          setRateInfo(args);
        },
        type: type.value,
      });
    })();
  }, [date]);

  return <></>;
};

export const RateField: React.FC = () => {
  const useOtherLabel = useChargeFormField("useOtherLabel").value;
  const type = useChargeFormField("type").value;
  const date = useChargeFormField("date").value;
  const project = useChargeFormField("project");
  const rateSheet = useChargeFormField("rateSheet").value;

  const [loading, setLoading] = useState(false);
  const [rateInfo, setRateInfo] = useState<RateInfo>({ rateSheet, rates: [] });

  const { onChange: rateSheetOnChange } = useChargeFormField("rateSheet");

  useEffect(() => {
    rateSheetOnChange(rateInfo.rateSheet);
  }, [rateInfo]);

  const disabled =
    !date || !project.value || (!rateInfo.rates.length && !useOtherLabel);

  return (
    <>
      {useOtherLabel && (
        <TextFormField
          label="Override label"
          helperText=""
          formField="otherLabel"
          required
        />
      )}
      {!useOtherLabel && type === "Task" && (
        <TaskPickerFormField
          formField="rate"
          label="Rate"
          helperText={""}
          taskRates={(rateInfo.rates ?? []) as TaskRate[]}
          disabled={disabled}
          required
        />
      )}
      {!useOtherLabel && type === "Expense" && (
        <ExpensePickerFormField
          formField="rate"
          label="Rate"
          helperText={""}
          expenseRates={(rateInfo.rates ?? []) as IExpenseRate[]}
          showInvoiceLabel={true}
          disabled={disabled}
          required
        />
      )}
      {loading && !useOtherLabel && <InlineProgress />}
      <RateSheetLoader {...{ setRateInfo, setLoading }} />
    </>
  );
};
