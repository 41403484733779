import React, { useState, useEffect } from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { TextHighlighter, createSearchRegex } from "~/text-highlighter";
import _ from "lodash";
import { Moment } from "moment";
import {
  TaskRateSheetsQuery,
  useTaskRateSheetsQuery,
} from "../TaskRateSheetPickerFormField/query.generated";
import {
  GetExpenseRateSheetsQuery,
  useGetExpenseRateSheetsQuery,
} from "../ExpenseRateSheetPickerFormField/query.generated";

export type RateSheetPickerProps = FormFieldProps & {
  effectiveDate: Moment | null;
  type: "expense" | "task";
};

const RateSheetPickerFormField: React.FC<RateSheetPickerProps> = (props) => {
  const { effectiveDate, type } = props;

  const [rateSheets, setRateSheets] = useState<string[] | null>(null);

  const format = (item: string, searchText: string): React.ReactNode => {
    const searchRegex = createSearchRegex(searchText);

    return (
      <div className={`${type}-ratesheet-picker-menuitem`}>
        <TextHighlighter text={item} search={searchRegex} />
      </div>
    );
  };

  const chipFormat = (item: string) => item;

  const getData = async (searchText: string) => {
    if (!searchText) {
      return rateSheets ?? [];
    }
    const result = rateSheets?.filter((t) =>
      t.toLowerCase().includes(searchText.toLowerCase())
    );
    return result ?? [];
  };

  const args = {
    ...props,
    format,
    chipFormat,
    getData,
    disabled: !effectiveDate,
  } as TypeaheadProps<string>;

  return (
    <div className={`${type}-ratesheet-picker`}>
      <TypeaheadFormField {...args} />
      {effectiveDate && <Loader {...{ effectiveDate, setRateSheets, type }} />}
    </div>
  );
};

type LoaderProps = {
  effectiveDate: Moment;
  setRateSheets: React.Dispatch<string[] | null>;
  type: "expense" | "task";
};

const Loader: React.FC<LoaderProps> = ({
  effectiveDate,
  setRateSheets,
  type,
}) => {
  const variables = { effectiveDate: effectiveDate.format("YYYY-MM-DD") };

  const queryHook =
    type === "expense" ? useGetExpenseRateSheetsQuery : useTaskRateSheetsQuery;

  const { data } = queryHook({ variables });

  useEffect(() => {
    if (data) {
      const selector =
        type === "expense"
          ? (data as GetExpenseRateSheetsQuery)?.expenseRates?.rateSheets
          : (data as TaskRateSheetsQuery)?.taskRates?.sheets;
      setRateSheets((selector?.map((x) => x.name) ?? []) as string[]);
    }
  }, [data]);

  return <></>;
};

export default RateSheetPickerFormField;
