// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Cbo\\+p9\\+sGznywaWKOvrmxw\\=\\={max-width:100vw}.Cbo\\+p9\\+sGznywaWKOvrmxw\\=\\= .mdc-dialog__container{max-width:96vw}.Cbo\\+p9\\+sGznywaWKOvrmxw\\=\\= .mdc-dialog__container .mdc-dialog__surface{max-width:100%}.Cbo\\+p9\\+sGznywaWKOvrmxw\\=\\=._1vbB8Db9FrE81AzcDBXCtA\\=\\={z-index:6}.BRbKvfGwsNA8q7Vs6hnJdQ\\=\\={padding:15px 20px 15px 0;display:flex;justify-content:end}", "",{"version":3,"sources":["webpack://src/charges-table/ChargeHistoryModal.module.scss"],"names":[],"mappings":"AAAA,8BACE,eAAA,CAEA,qDACE,cAAA,CAEA,0EACE,cAAA,CASJ,0DACE,SAAA,CAIJ,4BACE,wBAAA,CACA,YAAA,CACA,mBAAA","sourcesContent":[".ChargeHistoryModal {\n  max-width: 100vw;\n\n  :global(.mdc-dialog__container) {\n    max-width: 96vw;\n\n    :global(.mdc-dialog__surface) {\n      max-width: 100%;\n    }\n  }\n\n  /*\n    when the split form is open, the z-indexing gets borked up because the\n    split form appears before the history dialog in the DOM.\n    This corrects for that.\n  */\n  &.SplitFormOpen {\n    z-index: 6;\n  }\n}\n\n.BottomRow {\n  padding: 15px 20px 15px 0;\n  display: flex;\n  justify-content: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChargeHistoryModal": "Cbo+p9+sGznywaWKOvrmxw==",
	"SplitFormOpen": "_1vbB8Db9FrE81AzcDBXCtA==",
	"BottomRow": "BRbKvfGwsNA8q7Vs6hnJdQ=="
};
export default ___CSS_LOADER_EXPORT___;
