import React, { useState } from "react";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { useSearchStats } from "./SearchStatsProvider";
import { useApolloClient } from "@apollo/client";
import { GetAllFilteredChargesDocument } from "./allCharges.generated";
import { LoadAllButton } from "~/load-all-button";
import { ProjectChargesFilter } from "./types";
import { ProjectCharge } from "~/gql/types";
import { formattedDate } from "./utils";

type LoadAllChargesProps = {
  state: State<ProjectCharge, ProjectChargesFilter>;
  dispatch: React.Dispatch<Action<ProjectCharge, ProjectChargesFilter>>;
};

export const LoadAllCharges: React.FC<LoadAllChargesProps> = ({
  state,
  dispatch,
}) => {
  const { stats } = useSearchStats();
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const {
    employee,
    billingAdmin,
    projectManager,
    projectSupervisor,
    searchText,
    project,
    customer,
    state: chargeState,
    billable,
    dateAfter,
    dateBefore,
    dateEqual,
    afeWoPo,
  } = state.filterOptions;

  const allSelected =
    state.selectedItems.length === state.items.length && state.items.length > 0;

  const loadAll = async () => {
    setLoading(true);

    try {
      const variables = {
        employee: employee?.userPrincipalName ?? null,
        billingAdmin: billingAdmin?.userPrincipalName ?? null,
        projectSupervisor: projectSupervisor?.userPrincipalName ?? null,
        projectManager: projectManager?.userPrincipalName ?? null,
        searchText: searchText || null,
        projectNumber: project?.number || null,
        customerNumber: customer?.number || null,
        state: chargeState || null,
        billable: billable || null,
        dateEqual: formattedDate(dateEqual),
        dateBefore: formattedDate(dateBefore),
        dateAfter: formattedDate(dateAfter),
        afeWoPo: afeWoPo || null,
      };

      const result = await client.query({
        query: GetAllFilteredChargesDocument,
        variables,
      });

      const allCharges = result?.data?.projectCharges?.allFilteredCharges ?? [];

      dispatch({ tag: "SelectAllQueriedItems", items: allCharges });
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadAllButton
      {...{
        allSelected,
        loadAll,
        selectedCount: state.selectedItems.length,
        totalCount: stats?.chargeTotal ?? 0,
        loading,
        type: "charges",
      }}
    />
  );
};
