import React from "react";
import { useUser } from "~/Auth/useUser";
import { useDownloadFile } from "~/file-loading/useDownloadFile";
import { useRawJwt } from "~/Auth/AuthenticationManager";

export type ReportFetcherProps = {
  setLoading: React.Dispatch<boolean>;
  fileName: string;
  path?: string;
  body?: Record<string, any>;
  type?: string;
  method?: "post" | "get";
  fullPath?: string;
};

export function useReportFetcher(): (
  props: ReportFetcherProps
) => Promise<void> {
  const token = useRawJwt();
  const user = useUser();
  const download = useDownloadFile();
  return async ({
    setLoading,
    path,
    fileName,
    body,
    type = "application/pdf",
    method,
    fullPath,
  }: ReportFetcherProps) => {
    setLoading(true);

    void window
      .fetch(fullPath ?? `/api/${path}`, {
        method: method ?? "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${token}` : "",
          impersonation: user.isImpersonating ? user.email : "",
        },
        body: body ? JSON.stringify(body) : undefined,
      })
      .then(async (res) => {
        setLoading(false);
        if (res.status === 200) {
          const blob = await res.blob();
          const file = new Blob([blob], { type });
          const fileURL = URL.createObjectURL(file);
          download({ linkHref: fileURL, filename: fileName });
        } else {
          throw new Error("There was a problem downloading the file");
        }
      });
  };
}
