import React from "react";
import { InlineTypeahead, InlineTypeaheadProps } from "~/inline-typeahead";
import { Customer } from "~/gql/types";
import { useCustomerSearch } from "~/refdata/useCustomerSearch";

const format = (item: Customer) =>
  `${item.name} (${item.number ?? item.status})`;

export type InlineCustomerPickerProps = {
  value: Customer | null;
  name: string;
  onChange: (value: Customer | null) => void;
  onBlur?: React.FocusEventHandler<any>;
  required?: boolean;
  submitError?: string;
  includePending?: boolean;
};

export const InlineCustomerPicker: React.FC<InlineCustomerPickerProps> = (
  props
) => {
  const getCustomers = useCustomerSearch();

  const getData = async (searchText: string) => {
    if (!searchText) {
      return [];
    }
    const result = await getCustomers({
      searchText,
      includePending: props.includePending,
    });
    return result?.records ?? [];
  };

  const args = {
    ...props,
    getData,
    format,
  } as InlineTypeaheadProps<Customer>;

  return <InlineTypeahead {...args} />;
};
