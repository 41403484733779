import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExpenseRateSheetQueryVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type ExpenseRateSheetQuery = { __typename?: 'QueryModel', expenseRates?: { __typename?: 'ExpenseRatesQueryModel', rateSheet?: { __typename?: 'ExpenseRateSheet', name: string, allowAssignment: boolean, endDate?: string | null, archived: boolean, expenseRates?: Array<{ __typename?: 'AdHocExpenseRate', rate?: number | null, name: string, invoiceLabel?: string | null, expenseAccount?: string | null, glAccount?: string | null } | { __typename?: 'AtvExpenseRate', billableAmount: number, reimbursableAmount: number, name: string, invoiceLabel?: string | null, expenseAccount?: string | null, glAccount?: string | null } | { __typename?: 'FuelExpenseRate', billable: boolean, name: string, invoiceLabel?: string | null, expenseAccount?: string | null, glAccount?: string | null } | { __typename?: 'MileageExpenseRate', billableAmountPerMile: number, reimbursableAmountPerMile: number, name: string, invoiceLabel?: string | null, expenseAccount?: string | null, glAccount?: string | null } | { __typename?: 'MobileAllowanceExpenseRate', billableAmount: number, reimbursableAmount: number, quantity: number, name: string, invoiceLabel?: string | null, expenseAccount?: string | null, glAccount?: string | null } | { __typename?: 'PerDiemExpenseRate', kind: Types.PerDiemKind, billableOverride?: number | null, quantity: number, name: string, invoiceLabel?: string | null, expenseAccount?: string | null, glAccount?: string | null } | { __typename?: 'ReceiptValuedExpenseRate', category: Types.ReceiptValuedCategory, requiresLocation: boolean, billable: boolean, name: string, invoiceLabel?: string | null, expenseAccount?: string | null, glAccount?: string | null } | { __typename?: 'TcpAllowanceExpenseRate', reimbursableAmount: number, name: string, invoiceLabel?: string | null, expenseAccount?: string | null, glAccount?: string | null } | null> | null } | null } | null };


export const ExpenseRateSheetDocument = gql`
    query ExpenseRateSheet($name: String!) {
  expenseRates {
    rateSheet(name: $name) {
      name
      allowAssignment
      endDate
      expenseRates {
        name
        invoiceLabel
        expenseAccount
        glAccount
        ... on ReceiptValuedExpenseRate {
          category
          requiresLocation
          billable
        }
        ... on FuelExpenseRate {
          billable
        }
        ... on PerDiemExpenseRate {
          kind
          billableOverride
          quantity
        }
        ... on MobileAllowanceExpenseRate {
          billableAmount
          reimbursableAmount
          quantity
        }
        ... on TcpAllowanceExpenseRate {
          reimbursableAmount
        }
        ... on MileageExpenseRate {
          billableAmountPerMile
          reimbursableAmountPerMile
        }
        ... on AtvExpenseRate {
          billableAmount
          reimbursableAmount
        }
        ... on AdHocExpenseRate {
          rate
        }
      }
      archived
    }
  }
}
    `;

/**
 * __useExpenseRateSheetQuery__
 *
 * To run a query within a React component, call `useExpenseRateSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseRateSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseRateSheetQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useExpenseRateSheetQuery(baseOptions: Apollo.QueryHookOptions<ExpenseRateSheetQuery, ExpenseRateSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpenseRateSheetQuery, ExpenseRateSheetQueryVariables>(ExpenseRateSheetDocument, options);
      }
export function useExpenseRateSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpenseRateSheetQuery, ExpenseRateSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpenseRateSheetQuery, ExpenseRateSheetQueryVariables>(ExpenseRateSheetDocument, options);
        }
export type ExpenseRateSheetQueryHookResult = ReturnType<typeof useExpenseRateSheetQuery>;
export type ExpenseRateSheetLazyQueryHookResult = ReturnType<typeof useExpenseRateSheetLazyQuery>;
export type ExpenseRateSheetQueryResult = Apollo.QueryResult<ExpenseRateSheetQuery, ExpenseRateSheetQueryVariables>;