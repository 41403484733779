import React, { useEffect, useReducer } from "react";
import ProjectTimeEntryForm from "~/visuals/organisms/ProjectTimeForm";
import moment from "moment";
import { useWorkDayApi } from "~/WorkDayApi";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import {
  State,
  Action,
  reducer,
  FormData,
} from "~/visuals/organisms/ProjectTimeForm/stateMachine";
import { useEffectiveTaskRateSheetLazyQuery } from "./query.generated";
import { useCrewCodes } from "~/refdata2/crewCodes";
import {
  EditProjectTimeDailyCommandHoursInput,
  ProjectTimeEntry,
  TaskRate,
  TaskRateType,
} from "~/gql/types";
import { toDailyHoursArgs } from "~/visuals/organisms/ProjectTimeForm/toDailyHoursArgs";
import { toPopulatedDailyValues } from "~/visuals/organisms/ProjectTimeForm/toPopulatedDailyValues";

type EditProjectTimeEntryProps = {
  projectTime: ProjectTimeEntry;
  date: string;
};

const EditProjectTimeEntry: React.FC<EditProjectTimeEntryProps> = ({
  projectTime,
  date,
}) => {
  const {
    editProjectTime,
    deleteProjectTime,
    breadcrumbs,
    workDay: { employee },
  } = useWorkDayApi();

  useBreadcrumbs(
    [
      ...breadcrumbs,
      { text: `Edit Project Time Entry ${projectTime.id ?? ""}` },
    ],
    [breadcrumbs, projectTime]
  );

  const [getSheet, { data: rateSheetData }] =
    useEffectiveTaskRateSheetLazyQuery();

  const crewCodes = useCrewCodes();
  const selectedCrewCode =
    crewCodes?.find((x) => x.crewCode === projectTime.crewCode) ?? null;

  const rateSheet = rateSheetData?.projects?.effectiveTaskRateSheet;
  const taskRates = (rateSheet?.rates || []) as TaskRate[];

  const initialState = {
    crewCode: selectedCrewCode,
    hours: moment.duration(projectTime.hours),
    project: projectTime.project,
    taskName: projectTime.taskName,
    taskRate: null,
    notes: projectTime.notes,
    myRole: projectTime.myRole,
    otherCrewOne: projectTime.otherCrew1 ?? null,
    otherCrewTwo: projectTime.otherCrew2 ?? null,
    otherRole1: projectTime.otherRole1 ?? null,
    otherRole2: projectTime.otherRole2 ?? null,
    crewSize: projectTime.crewSize.toString(),
    loading: true,
    taskRates,
    location: projectTime.location,
    adminNotes: null,
    rateSheetName: projectTime.rateSheetName,
    entry: projectTime,
    formErrors: null,
    employee,
    ...toPopulatedDailyValues(projectTime.dailyHours ?? null),
  };

  const [state, dispatch] = useReducer(
    (a: State, b: Action) => reducer(a, b),
    initialState as State
  );

  useEffect(() => {
    if (state.project) {
      dispatch({ tag: "LoadData" });
      void getSheet({
        variables: {
          asOf: date,
          projectNumber: state.project.number,
        },
      });
    }
  }, [state.project]);

  useEffect(() => {
    if (rateSheetData) {
      dispatch({
        tag: "TaskRatesLoaded",
        taskRates,
        rateSheetName: rateSheet?.name ?? "",
      });
    }
  }, [rateSheetData]);

  useEffect(() => {
    if (selectedCrewCode) {
      dispatch({ tag: "UpdateCrewCode", crewCode: selectedCrewCode });
    }
  }, [selectedCrewCode]);

  const durationFormat = (d) => {
    if (!d) {
      return null;
    }
    const m = moment(d.asMilliseconds()).startOf("day").add(d);

    return m.format("HH:mm:ss");
  };

  const editProjectTimeEntry = async (values: FormData) => {
    if (state.formErrors) {
      return state.formErrors;
    }

    dispatch({ tag: "LoadData" });
    const rateType =
      state.taskRates?.find((x) => x.name == state.taskName)?.rateType ?? null;
    const includeCrew = rateType === TaskRateType.Field;

    const toCrewValue = (val: any) => (includeCrew ? val ?? null : null);

    const res = await editProjectTime({
      projectTimeId: projectTime.id,
      hours: durationFormat(values.hours)!,
      projectNumber: values.project?.number!,
      crewCode: values.crewCode?.crewCode!,
      taskName: values.taskName!,
      notes: values.notes!,
      myRole: toCrewValue(values.myRole),
      otherCrewUpn1: toCrewValue(values?.otherCrewOne?.userPrincipalName),
      otherCrewUpn2: toCrewValue(values?.otherCrewTwo?.userPrincipalName),
      otherRole1: toCrewValue(values?.otherRole1),
      otherRole2: toCrewValue(values?.otherRole2),
      state: values?.location!.state!,
      county: values?.location!.county!,
      adminNotes: values?.adminNotes || null,
      dailyHours: toDailyHoursArgs<EditProjectTimeDailyCommandHoursInput>(
        state.dailyHours
      ),
    });

    dispatch({ tag: "Submitted" });

    return res;
  };

  const deleteTime = async () => {
    dispatch({ tag: "LoadData" });
    const res = await deleteProjectTime({
      projectTimeId: projectTime.id,
    });
    dispatch({ tag: "Submitted" });

    return res;
  };

  return (
    <>
      <ProjectTimeEntryForm
        type="Edit"
        handleSubmit={editProjectTimeEntry}
        date={date}
        onDelete={deleteTime}
        state={state}
        dispatch={dispatch}
        id={projectTime.id}
      />
    </>
  );
};

export default EditProjectTimeEntry;
