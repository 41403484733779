import MdcTextField, { HelperText, Input } from "@material/react-text-field";
import React, { useState } from "react";
import { Duration } from "moment";
import { useField } from "react-final-form";
import classnames from "classnames";
import "./TimeInput.scss";

export type TimeInputProps = {
  formField: string;
  label: string;
  helperText: string;
  maxInputLength: number;
  parseInputVal: (val: string) => Duration | null;
  durationDisplay: (d: Duration | null) => string;
  focusedDurationDisplay: (d: Duration | null) => string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
};

export const TimeInput: React.FC<TimeInputProps> = (props) => {
  const {
    formField,
    label,
    helperText,
    required = false,
    disabled,
    parseInputVal,
    focusedDurationDisplay,
    durationDisplay,
    className,
    maxInputLength,
  } = props;

  const [textValue, setTextValue] = useState<string>("");
  const [focused, setFocused] = useState<boolean>(false);

  const checkRequired = (value: Duration | null) => {
    const require =
      value || !required || disabled
        ? undefined
        : `Please provide a valid ${label}`;
    const isZero =
      value && value.asSeconds() === 0 ? `${label} cannot be zero` : undefined;

    return require || isZero;
  };

  const {
    meta: { error, submitError, touched },
    input: { value, onChange, onBlur, onFocus },
  } = useField(formField, { validate: checkRequired });

  const errorMessage = error || submitError;
  const isValid = !touched || !errorMessage;

  const handleChange = (input: string) => {
    if (input.length <= maxInputLength) {
      setTextValue(input);
      parseInput(input);
    }
  };

  const parseInput = (inputVal: string) => {
    if (!inputVal) {
      onChange(null);
      return;
    }

    const parsed = parseInputVal(inputVal);
    onChange(parsed);
  };

  const helperTextClass = classnames({
    "mdc-text-field-helper-text--validation-msg": !isValid,
  });

  const inputClass = classnames("durationpicker-form-field", className);

  return (
    <section className={inputClass}>
      <MdcTextField
        label={label + `${required ? " *" : ""}`}
        helperText={
          <HelperText
            isValidationMessage={!isValid}
            persistent={!isValid}
            className={helperTextClass}
          >
            {!isValid && errorMessage ? errorMessage : helperText}
          </HelperText>
        }
        onBlur={(event) => (onBlur ? onBlur(event) : undefined)}
      >
        <Input
          name={formField}
          id={formField}
          value={
            focused
              ? textValue
                ? textValue
                : focusedDurationDisplay(value)
              : durationDisplay(value)
          }
          isValid={isValid}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(evt.target.value)
          }
          aria-label={label}
          autoComplete="new-password"
          disabled={disabled}
          onFocus={() => {
            setFocused(true);
            onFocus();
          }}
          onBlur={() => {
            setFocused(false);
            setTextValue(focusedDurationDisplay(value));
            onBlur();
          }}
        />
      </MdcTextField>
    </section>
  );
};
