import {
  Route,
  RouteTemplate,
  RouteTemplate2,
  RouteNumberTemplate,
} from "~/routeTypes";

export const customerManagement = new Route("/customers");

export const customer = new RouteTemplate(
  "/customer/:customerNumber",
  (x) => `/customer/${x}`
);

export const newCustomer = customerManagement.childRoute("new");

export const pendingCustomers = customerManagement.childRoute("pending");

export const fieldOffices = new Route("/field-offices");

export const perDiemRates = new Route("/per-diem-rates");

export const perDiemRateWizard = new Route("/set-per-diem-rate");

export const approveCustomer = new RouteTemplate(
  "/customers/pending/approve/:createCustomerRequestId",
  (x) => `/customers/pending/approve/${x}`
);

export const rejectCustomer = new RouteTemplate(
  "/customers/pending/reject/:createCustomerRequestId",
  (x) => `/customers/pending/reject/${x}`
);

export const home = new Route("/");

export const editTimeEntry = new RouteTemplate2(
  "/my-work-day/:date/edit-time-entry/:timeEntryId",
  (x, y) => `/my-work-day/${x}/edit-time-entry/${y}`
);

export const myWork = new RouteTemplate(
  "/my-work/:userPrincipalName",
  (x) => `/my-work/${x}`
);

export const myWorkReview = new Route("/my-work-review");

export const myWorkDay = new RouteTemplate2(
  "/my-work-day/:userPrincipalName/:date",
  (x, y) => `/my-work-day/${y}/${x}`
);

export const myWorkDayNow = new Route("/my-work-day");

export const reviewWorkData = new RouteTemplate(
  "/review-work-data/:userPrincipalName",
  (x) => `/review-work-data/${x}`
);

export const reviewWorkDay = new RouteTemplate2(
  "/review-work-day/:userPrincipalName/:date",
  (x, y) => `/review-work-day/${y}/${x}`
);

export const newTimeEntry = myWorkDay.childRoute("create-time-entry");

export const newTimeOffEntry = myWorkDay.childRoute("create-time-off-entry");

export const newProjectTimeEntry = myWorkDay.childRoute(
  "create-project-time-entry"
);

export const newExpenseEntry = myWorkDay.childRoute("create-expense-entry");
export const newAtvExpense = myWorkDay.childRoute("create-expense-entry/atv");
export const newFuelExpense = myWorkDay.childRoute("create-expense-entry/fuel");
export const newMileageExpense = myWorkDay.childRoute(
  "create-expense-entry/mileage"
);
export const newMobileAllowanceExpense = myWorkDay.childRoute(
  "create-expense-entry/mobile-allowance"
);
export const newPerDiemExpense = myWorkDay.childRoute(
  "create-expense-entry/per-diem"
);
export const newReceiptValuedExpense = myWorkDay.childRoute(
  "create-expense-entry/receipt-valued"
);
export const newTcpAllowanceExpense = myWorkDay.childRoute(
  "create-expense-entry/tcp-allowance"
);

export const editExpenseEntry = new RouteTemplate2(
  "/my-work-day/:date/edit-expense-entry/:expenseId",
  (x, y) => `/my-work-day/${x}/edit-expense-entry/${y}`
);

export const editAtvExpense = new RouteTemplate2(
  "/my-work-day/:date/edit-expense-entry/:expenseId/atv",
  (x, y) => `/my-work-day/${x}/edit-expense-entry/${y}/atv`
);

export const editFuelExpense = new RouteTemplate2(
  "/my-work-day/:date/edit-expense-entry/:expenseId/fuel",
  (x, y) => `/my-work-day/${x}/edit-expense-entry/${y}/fuel`
);

export const editMileageExpense = new RouteTemplate2(
  "/my-work-day/:date/edit-expense-entry/:expenseId/mileage",
  (x, y) => `/my-work-day/${x}/edit-expense-entry/${y}/mileage`
);

export const editMobileAllowanceExpense = new RouteTemplate2(
  "/my-work-day/:date/edit-expense-entry/:expenseId/mobile-allowance",
  (x, y) => `/my-work-day/${x}/edit-expense-entry/${y}/mobile-allowance`
);

export const editPerDiemExpense = new RouteTemplate2(
  "/my-work-day/:date/edit-expense-entry/:expenseId/per-diem",
  (x, y) => `/my-work-day/${x}/edit-expense-entry/${y}/per-diem`
);

export const editReceiptValuedExpense = new RouteTemplate2(
  "/my-work-day/:date/edit-expense-entry/:expenseId/receipt-valued",
  (x, y) => `/my-work-day/${x}/edit-expense-entry/${y}/receipt-valued`
);

export const editTcpAllowanceExpense = new RouteTemplate2(
  "/my-work-day/:date/edit-expense-entry/:expenseId/tcp-allowance",
  (x, y) => `/my-work-day/${x}/edit-expense-entry/${y}/tcp-allowance`
);

export const editTimeOffEntry = new RouteTemplate2(
  "/my-work-day/:date/edit-time-off-entry/:timeOffEntryId",
  (x, y) => `/my-work-day/${x}/edit-time-off-entry/${y}`
);

export const editProjectTimeEntry = new RouteTemplate2(
  "/my-work-day/:date/edit-project-time-entry/:projectTimeId",
  (x, y) => `/my-work-day/${x}/edit-project-time-entry/${y}`
);

export const editProjectTimeDaily = new RouteTemplate2(
  "/my-work-day/:date/edit-project-time-daily/:projectTimeId",
  (x, y) => `/my-work-day/${x}/edit-project-time-daily/${y}`
);

export const invoices = new Route("/invoices");
export const invoice = new RouteTemplate(
  "/invoices/:invoiceNumber/*",
  (x) => `/invoices/${x}`
);

export const googleAuthCallback = new Route("/google-auth-callback");

export const adAuthCallback = new Route("/ad-auth-callback");
export const popupAuthRefresh = new Route("/popup-auth-refresh");
export const silentPopupAuthRefresh = new Route("/silent-popup-auth-refresh");
export const testAuth = new Route("/test-auth");

export const project = new RouteNumberTemplate(
  "/projects/:projectNumber",
  (x) => `/projects/${x}`
);

export const expenseRateSheets = new Route("/expense-rate-sheets");

export const createExpenseRateSheet = expenseRateSheets.childRoute(
  "create-expense-rate-sheet"
);

export const cloneExpenseRateSheet = new RouteTemplate(
  "/expense-rate-sheets/clone-expense-rate-sheet/:name",
  (x) => `/expense-rate-sheets/clone-expense-rate-sheet/${x}`,
  "/clone-expense-rate-sheet"
);

export const viewExpenseRateSheet = new RouteTemplate(
  "/view-expense-rate-sheet/:name",
  (x) => `/view-expense-rate-sheet/${x}`
);

export const editExpenseRateSheet = viewExpenseRateSheet.childRoute("edit");
export const createExpenseRate = viewExpenseRateSheet.childRoute(
  "create-expense-rate"
);

export const assignSuccessorExpenseRateSheet =
  viewExpenseRateSheet.childRoute("assign-successor");

export const editExpenseRate = new RouteTemplate2(
  "/view-expense-rate-sheet/:name/edit-expense-rate/:rateName",
  (x, y) => `/view-expense-rate-sheet/${x}/edit-expense-rate/${y}`,
  ":name/edit-expense-rate/:rateName"
);
export const assignCustomerExpenseRateSheet = customer.childRoute(
  "assign-customer-expense-rate-sheet"
);

export const assignCustomerTaskRateSheet = customer.childRoute(
  "assign-customer-task-rate-sheet"
);

export const assignProjectExpenseRateSheet = project.childRoute(
  "assign-project-expense-rate-sheet"
);

export const assignProjectTaskRateSheet = project.childRoute(
  "assign-project-task-rate-sheet"
);

export const taskRateSheets = new Route("/task-rate-sheets");

export const viewTaskRateSheet = new RouteTemplate(
  "/view-task-rate-sheet/:name",
  (x) => `/view-task-rate-sheet/${x}`
);

export const editTaskRateSheet = viewTaskRateSheet.childRoute("edit");
export const createTaskRateSheet = taskRateSheets.childRoute(
  "create-task-rate-sheet"
);

export const assignSuccessorTaskRateSheet =
  viewTaskRateSheet.childRoute("assign-successor");

export const cloneTaskRateSheet = new RouteTemplate(
  "/task-rate-sheets/clone-task-rate-sheet/:name",
  (x) => `/task-rate-sheets/clone-task-rate-sheet/${x}`,
  "/clone-task-rate-sheet"
);

export const createTaskRate = viewTaskRateSheet.childRoute("create-task-rate");

export const editTaskRate = new RouteTemplate2(
  "/view-task-rate-sheet/:name/edit-task-rate/:rateName",
  (x, y) => `/view-task-rate-sheet/${x}/edit-task-rate/${y}`
);

export const employees = new Route("/employees");
export const projects = new Route("/projects");
export const projectCharges = new Route("/project-charges");

export const timesheetReview = new Route("/timesheet-review");
export const expensesSearch = new Route("/expenses");
export const myExpenses = new Route("/my-expenses");
export const atvExpenses = new Route("/atv-expenses");

export const salesTaxReport = new Route("/sales-tax-report");

export const employeeRates = new Route("/employee-rates");
export const overheadCrewCodes = new Route("/overhead-crewcodes");

export const serviceDescriptions = new Route("/service-descriptions");

export const corpLocations = new Route("/corporate-locations");

export const divisions = new Route("/divisions");

const projectRequestsPath = window?.config?.projectRequestsRoute;

export const projectRequests = new Route(projectRequestsPath);

export const createProjectRequest = projectRequests.childRoute("create");

export const editProjectRequest = new RouteTemplate(
  `${projectRequestsPath}/:requestId/edit`,
  (x) => `${projectRequestsPath}/${x}/edit`,
  "edit"
);

export const approveProjectRequest = new RouteTemplate(
  `${projectRequestsPath}/:requestId/edit`,
  (x) => `${projectRequestsPath}/${x}/approve`,
  "approve"
);

export const rejectProjectRequest = new RouteTemplate(
  `${projectRequestsPath}/:requestId/reject`,
  (x) => `${projectRequestsPath}/${x}/reject`,
  "reject"
);

export const projectRequest = new RouteTemplate(
  `${projectRequestsPath}/:requestId`,
  (x) => `${projectRequestsPath}/${x}`
);

export const weeklyProjectTasks = new RouteTemplate2(
  "/weekly-project-tasks/:userPrincipalName/:weekStart",
  (x, y) => `/weekly-project-tasks/${x}/${y}`
);

export const createProject = projects.childRoute("create");

export const roles = new Route("roles");

export const projectStatus = new Route("project-status");

export const crewCodeOverrides = new Route("crew-code-overrides");

export const officeBillingReport = new Route("office-billing-report");

export const customRollupInvoices = new Route("custom-rollup-invoices");
export const customRollupInvoice = new RouteTemplate(
  "/custom-rollup-invoices/:invoiceNumber/*",
  (x) => `/custom-rollup-invoices/${x}`
);

export const performanceStats = new Route("performance-stats");
export const crewCodesSearch = new Route("crew-codes");
export const thirdPartyInvoices = new Route("/third-party-invoices");
export const thirdPartyInvoice = new RouteTemplate(
  "/third-party-invoices/:invoiceNumber/*",
  (x) => `/third-party-invoices/${x}`
);
export const editThirdPartyInvoice = new RouteTemplate(
  "/third-party-invoices/:invoiceNumber/edit",
  (x) => `/third-party-invoices/${x}/edit`,
  "edit"
);

export const revenueReporting = new Route("revenue-reporting");
