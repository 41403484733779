import React, { useState } from "react";
import TableCell from "~/old-table-cell";
import { TableRowActions, MenuOption } from "~/table";
import { CopyToDatesProps, showCopyToDates } from "./CopyToDates";
import { ItemToDateProps } from "./ItemToDate";

type WorkDayItemActionsProps = {
  editable: boolean;
  onDelete: React.DispatchWithoutAction;
  onEdit: React.DispatchWithoutAction;
  className?: string;
  buttonsClassName?: string;
  id: string;
  date: string;
  CopyToDate?: React.FC<ItemToDateProps>;
  MoveToDate?: React.FC<ItemToDateProps>;
  CopyToDates?: React.FC<CopyToDatesProps>;
  otherOptions?: (MenuOption | null)[];
};

export const WorkDayItemActions: React.FC<WorkDayItemActionsProps> = ({
  editable,
  onDelete,
  onEdit,
  className,
  buttonsClassName,
  id,
  date,
  CopyToDate,
  MoveToDate,
  CopyToDates,
  otherOptions,
}) => {
  const [copyOpen, setCopyOpen] = useState<string | null>(null);
  const [moveToDateOpen, setMoveToDateOpen] = useState<string | null>(null);
  const [copyToDatesOpen, setCopyToDatesOpen] = useState<string | null>(null);

  const options = [
    editable
      ? {
          text: "Edit",
          onClick: onEdit,
        }
      : null,

    ...(otherOptions ?? []),

    editable && MoveToDate
      ? {
          text: "Move to a different date",
          onClick: () => setMoveToDateOpen(id),
        }
      : null,

    editable && CopyToDate
      ? {
          text: "Copy to a different date",
          onClick: () => setCopyOpen(id),
        }
      : null,

    editable && showCopyToDates(date) && CopyToDates
      ? {
          text: "Copy to multiple dates...",
          onClick: () => setCopyToDatesOpen(id),
        }
      : null,

    editable
      ? {
          text: "Delete",
          onClick: onDelete,
        }
      : null,
  ].filter((x) => x) as MenuOption[];

  return (
    <TableCell className={className}>
      {options.length > 0 && (
        <TableRowActions className={buttonsClassName} options={options} />
      )}
      {MoveToDate && (
        <MoveToDate
          open={moveToDateOpen === id}
          onClosed={() => setMoveToDateOpen(null)}
          oldDate={date}
          id={id}
        />
      )}
      {CopyToDate && (
        <CopyToDate
          open={copyOpen === id}
          onClosed={() => setCopyOpen(null)}
          oldDate={date}
          id={id}
        />
      )}
      {CopyToDates && (
        <CopyToDates
          open={copyToDatesOpen === id}
          onClosed={() => setCopyToDatesOpen(null)}
          oldDate={date}
          id={id}
        />
      )}
    </TableCell>
  );
};
