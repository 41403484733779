import React, { useState } from "react";
import { Project } from "~/gql/types";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import {
  BulkActionsPopup,
  BulkItem,
} from "~/search-page-wrapper/BulkActionsPopup";
import {
  BulkAssignRateSheet,
  RateSheetAssignee,
} from "~/visuals/organisms/BulkAssignRateSheet";
import { FilterOptions } from "../FilterForm";
import {
  EditCorpLocation,
  EditDivision,
  EditProjectBillingAdmin,
  EditProjectClientContact,
  EditProjectManager,
  EditProjectOfficeCode,
  EditProjectSupervisor,
  EditServiceDescription,
  EditStatus,
} from "./BulkProjectComponents";
import { PROJECT_FILTERS_KEY } from "../ProjectsReview";
import { useRelativeNav } from "~/relative-nav";

type BulkProjectActionsProps = {
  state: State<Project, FilterOptions>;
  dispatch: React.Dispatch<Action<Project, FilterOptions>>;
};

type ComponentKey =
  | "officeCode"
  | "clientContactName"
  | "billingAdmin"
  | "projectManager"
  | "projectSupervisor"
  | "corpLocation"
  | "division"
  | "serviceDescription"
  | "status";

export const BulkProjectsActions: React.FC<BulkProjectActionsProps> = ({
  state,
  dispatch,
}) => {
  const [assignExpenseSheetOpen, setAssignExpenseSheetOpen] = useState(false);
  const [assignTaskSheetOpen, setAssignTaskSheetOpen] = useState(false);
  const [editComponent, setEditComponent] = useState<ComponentKey | null>(null);

  const assignableProjects = state.selectedItems as RateSheetAssignee[];

  const nav = useRelativeNav();

  const defaultEditProps = {
    setOpen: () => setEditComponent(null),
    selectedProjects: state.selectedItems,
  };

  const buttonItems = [
    assignableProjects.length > 0
      ? {
          label: "Assign Expense Rate Sheet",
          onClick: () => setAssignExpenseSheetOpen(true),
        }
      : null,
    assignableProjects.length > 0
      ? {
          label: "Assign Task Rate Sheet",
          onClick: () => setAssignTaskSheetOpen(true),
        }
      : null,
    {
      label: "Edit Office Code",
      onClick: () => setEditComponent("officeCode"),
    },
    {
      label: "Edit Client Contact Name",
      onClick: () => setEditComponent("clientContactName"),
    },
    {
      label: "Edit BA",
      onClick: () => setEditComponent("billingAdmin"),
    },
    {
      label: "Edit PM",
      onClick: () => setEditComponent("projectManager"),
    },
    {
      label: "Edit PS",
      onClick: () => setEditComponent("projectSupervisor"),
    },
    {
      label: "Edit Location",
      onClick: () => setEditComponent("corpLocation"),
    },
    {
      label: "Edit Division",
      onClick: () => setEditComponent("division"),
    },
    {
      label: "Edit Service Description",
      onClick: () => setEditComponent("serviceDescription"),
    },
    {
      label: "Edit Status",
      onClick: () => setEditComponent("status"),
    },
    state && state.selectedItems!.length
      ? {
          label: "Review",
          onClick: () => {
            window.sessionStorage.setItem(PROJECT_FILTERS_KEY, location.search);
            nav(`review/${state.selectedItems![0].number}`);
          },
        }
      : null,
  ].filter((x) => x) as BulkItem[];

  return (
    <>
      <BulkActionsPopup
        {...{
          selectedItems: state.selectedItems,
          onClear: () => dispatch({ tag: "ClearSelected" }),
          type: "Project",
          buttonItems,
        }}
      />
      <BulkAssignRateSheet
        {...{
          selectedItems: state.selectedItems,
          filteredItems: assignableProjects,
          type: "Expense",
          itemType: "projects",
          open: assignExpenseSheetOpen,
          setOpen: setAssignExpenseSheetOpen,
        }}
      />
      <BulkAssignRateSheet
        {...{
          selectedItems: state.selectedItems,
          filteredItems: assignableProjects,
          type: "Task",
          itemType: "projects",
          open: assignTaskSheetOpen,
          setOpen: setAssignTaskSheetOpen,
        }}
      />
      <EditProjectOfficeCode
        {...{
          ...defaultEditProps,
          open: editComponent === "officeCode",
        }}
      />
      <EditProjectClientContact
        {...{
          ...defaultEditProps,
          open: editComponent === "clientContactName",
        }}
      />
      <EditProjectBillingAdmin
        {...{
          ...defaultEditProps,
          open: editComponent === "billingAdmin",
        }}
      />
      <EditProjectManager
        {...{
          ...defaultEditProps,
          open: editComponent === "projectManager",
        }}
      />
      <EditProjectSupervisor
        {...{
          ...defaultEditProps,
          open: editComponent === "projectSupervisor",
        }}
      />
      <EditCorpLocation
        {...{
          ...defaultEditProps,
          open: editComponent === "corpLocation",
        }}
      />
      <EditDivision
        {...{
          ...defaultEditProps,
          open: editComponent === "division",
        }}
      />
      <EditServiceDescription
        {...{
          ...defaultEditProps,
          open: editComponent === "serviceDescription",
        }}
      />
      <EditStatus
        {...{
          ...defaultEditProps,
          open: editComponent === "status",
        }}
      />
    </>
  );
};
