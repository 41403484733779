import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { createSearchRegex } from "~/text-highlighter/createSearchRegex";
import { TextHighlighter } from "~/text-highlighter";
import { Invoice, InvoiceState } from "~/gql/types";
import { currencyFormatter } from "~/currencyFormatter";
import { useBillAndDraftInvoiceQueryQuery } from "./query.generated";

const format = (invoice: Invoice, searchText: string): React.ReactNode => {
  const searchRegex = createSearchRegex(searchText);
  const textValue = `(${invoice.state}) ${
    invoice.invoiceNumber
  } - ${currencyFormatter.format(invoice.total)}`;
  return (
    <div className="invoice-picker-menuitem">
      <TextHighlighter text={textValue} search={searchRegex} />
    </div>
  );
};

const chipFormat = (invoice: Invoice) => invoice.invoiceNumber;

type InvoicePickerFormFieldProps = FormFieldProps & {
  projectNumber: number;
};

export const LineItemInvoicePicker: React.FC<InvoicePickerFormFieldProps> = (
  props
) => {
  const { data } = useBillAndDraftInvoiceQueryQuery({
    variables: { projectNumber: props.projectNumber },
  });

  const getData = async (searchText: string) => {
    const searchData = (data?.invoices?.search?.records || []) as Invoice[];
    const result = searchData
      .filter((i) => i.state != "Cancelled")
      .filter(
        (i) =>
          !i.customRollupInvoice ||
          i.customRollupInvoice.state == InvoiceState.Cancelled
      );
    if (!searchText) {
      return result;
    }

    return result.filter(
      (invoice) =>
        invoice.invoiceNumber
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        `${invoice.total}` == searchText ||
        `${invoice.state}`.toLowerCase() == searchText.toLowerCase()
    );
  };

  const args = {
    ...props,
    getData,
    format,
    chipFormat,
  } as TypeaheadProps<Invoice>;

  return <TypeaheadFormField {...args} />;
};
