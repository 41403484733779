import React from "react";
import { createContext, useContext } from "react";

export type ReviewItemsContextType = {
  selectedItems: unknown[];
  setSelectedItems: React.Dispatch<unknown[]>;
};

export const ReviewItemsContext = createContext<ReviewItemsContextType>({
  selectedItems: [],
  setSelectedItems: () => undefined,
});

export function useReviewItemsContext(): ReviewItemsContextType {
  const context = useContext(ReviewItemsContext);
  return context;
}
