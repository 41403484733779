import React, { ReactElement } from "react";
import { Checkbox } from "rmwc";
import { useField } from "react-final-form";
import { Body1 } from "@material/react-typography";
import { HelperText } from "@material/react-text-field";
import "./BinaryPickerFormField.scss";
import { FormFieldProps } from "~/forms/FormFieldProps";
import cn from "classnames";

export type BinaryPickerProps = FormFieldProps;

export default function BinaryPickerFormField(
  props: BinaryPickerProps
): ReactElement {
  const {
    helperText,
    label,
    formField,
    required,
    disabled,
    className,
    ...otherProps
  } = props;

  const checkRequired = (value: boolean) =>
    [true, false].includes(value) || !required || disabled
      ? undefined
      : `Please provide a valid ${label}`;

  const {
    meta: { invalid, error, submitError, touched },
    input: { value, onChange },
  } = useField(formField, { validate: checkRequired });

  const hasError = (error || invalid || submitError) && touched;

  return (
    <section className="binary-picker form-field">
      <Body1 className={disabled ? "disabled" : ""}>
        {label + `${required ? " *" : ""}`}
      </Body1>
      <Checkbox
        {...otherProps}
        className={cn("checkbox", className)}
        checked={value}
        disabled={disabled}
        onChange={() => onChange(!value)}
      />
      {helperText && (
        <HelperText isValid={!hasError} persistent={true}>
          {hasError ? error || submitError : helperText}
        </HelperText>
      )}
    </section>
  );
}
