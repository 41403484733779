import StateCounty from "~/graphql/StateCounty";
import { Moment } from "moment";
import _ from "lodash";

export type Page = "Rates" | "Locations" | "Confirm";

export type State = {
  selectedLocations: StateCounty[];
  allLocations: StateCounty[];
  effectiveDate: Moment | null;
  mealsReimbursable: number;
  lodgingReimbursable: number;
  travelReimbursable: number;
  mealsBillable: number;
  lodgingBillable: number;
  travelBillable: number;
  page: Page;
  errorMessage?: string;
  create: () => void;
  submitting: boolean;
};

export function createInitialState(allLocations: StateCounty[]): State {
  return {
    allLocations,
    selectedLocations: [],
    effectiveDate: null,
    mealsReimbursable: 0,
    lodgingReimbursable: 0,
    travelReimbursable: 0,
    mealsBillable: 0,
    lodgingBillable: 0,
    travelBillable: 0,
    page: "Rates",
    create: () => undefined,
    submitting: false,
  };
}

type TaggedAction<T extends string> = { tag: T };
type RateAction = TaggedAction<"UpdateRate"> & {
  mealsReimbursable?: number;
  lodgingReimbursable?: number;
  travelReimbursable?: number;
  mealsBillable?: number;
  lodgingBillable?: number;
  travelBillable?: number;
};

export type Action =
  | TaggedAction<"NextPage">
  | TaggedAction<"PrevPage">
  | (TaggedAction<"Submit"> & { create: () => void })
  | RateAction
  | (TaggedAction<"SelectState"> & { state: string })
  | (TaggedAction<"SelectCounty"> & { location: StateCounty })
  | (TaggedAction<"DeselectState"> & { state: string })
  | (TaggedAction<"DeselectCounty"> & { location: StateCounty })
  | (TaggedAction<"SetEffectiveDate"> & { effectiveDate: Moment })
  | (TaggedAction<"Submitted"> & { message: string });

const nextPageValidator: Record<Page, (state: State) => string | undefined> = {
  Rates: () => undefined,
  Locations: (st) =>
    st.selectedLocations.length > 0
      ? undefined
      : "You must select at least one location",
  Confirm: () => undefined,
};
const nextPageMap: Record<Page, Page> = {
  Rates: "Locations",
  Locations: "Confirm",
  Confirm: "Confirm",
};
const prevPageMap: Record<Page, Page> = {
  Rates: "Rates",
  Locations: "Rates",
  Confirm: "Locations",
};

export function isLocationSelected(
  state: State,
  location: StateCounty
): boolean {
  const found = state.selectedLocations.find(
    (x) => x.county == location.county && x.state === location.state
  );

  return !!found;
}

function sortLocations(locations: StateCounty[]) {
  return _.sortBy(
    locations,
    (x) => x.state,
    (x) => x.county
  );
}

export function reducer(state: State, action: Action): State {
  // for some reason the submit action was getting reduced twice
  if ((action as any).submitted) {
    return state;
  }

  state = {
    ...state,
    errorMessage: undefined,
  };

  if (action.tag === "Submit") {
    if (state.effectiveDate) {
      (action as any).submitted = true;
      action.create();
      return {
        ...state,
        submitting: true,
      };
    }

    return {
      ...state,
      errorMessage: "You must choose an effective date",
    };
  }

  if (action.tag === "Submitted") {
    return {
      ...state,
      errorMessage: action.message,
    };
  }

  if (action.tag === "NextPage") {
    const errorMessage = nextPageValidator[state.page](state);
    if (errorMessage) {
      return {
        ...state,
        errorMessage,
      };
    }
    return {
      ...state,
      page: nextPageMap[state.page],
    };
  }
  if (action.tag === "PrevPage") {
    return {
      ...state,
      page: prevPageMap[state.page],
    };
  }
  if (action.tag === "UpdateRate") {
    const { tag, ...rates } = action;
    tag; // unused
    return {
      ...state,
      ...rates,
    };
  }
  if (action.tag === "SelectCounty") {
    if (isLocationSelected(state, action.location)) {
      return state;
    }
    return {
      ...state,
      selectedLocations: sortLocations([
        ...state.selectedLocations,
        action.location,
      ]),
    };
  }
  if (action.tag === "DeselectCounty") {
    return {
      ...state,
      selectedLocations: state.selectedLocations.filter(
        (loc) =>
          loc.state !== action.location.state ||
          loc.county !== action.location.county
      ),
    };
  }
  if (action.tag === "SelectState") {
    return {
      ...state,
      selectedLocations: sortLocations([
        ...state.allLocations.filter((x) => x.state === action.state),
        ...state.selectedLocations.filter((x) => x.state !== action.state),
      ]),
    };
  }

  if (action.tag === "DeselectState") {
    return {
      ...state,
      selectedLocations: state.selectedLocations.filter(
        (x) => x.state !== action.state
      ),
    };
  }

  if (action.tag === "SetEffectiveDate") {
    return {
      ...state,
      effectiveDate: action.effectiveDate,
    };
  }

  return state;
}
