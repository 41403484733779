import React from "react";
import { currencyFormatter } from "~/currencyFormatter";
import { Project } from "~/visuals/pages/Project/ProjectDetails/types";
import cn from "classnames";

type ProjectStatsDisplayProps = {
  project: Project;
};

const chargeAmount = (total: number, budget: number | null) => {
  const totalAmount = currencyFormatter.format(total);
  const percent = budget ? ` (${((total / budget) * 100).toFixed(2)}%)` : "";
  return `${totalAmount}${percent}`;
};

export const Totals: React.FC<{ total: number; budget: number | null }> = ({
  total,
  budget,
}) => {
  const percentBilled = budget ? (total / budget) * 100 : 0;

  const totalsStyle = cn({ "over-budget": percentBilled > 100 });

  return (
    <>
      <dt>Total All Charges</dt>
      <dd className={totalsStyle}>{chargeAmount(total, budget)}</dd>
    </>
  );
};

export const ProjectStatsDisplay: React.FC<ProjectStatsDisplayProps> = ({
  project,
}) => {
  const { stats, legacyBilled, budget } = project;

  const {
    totalPrPending,
    totalBilled,
    totalBilledWithoutTax,
    totalPostPending,
    totalPmPending,
    totalPsPending,
    totalBaPending,
    totalNonBillable,
    totalPostedNotBilled,
  } = stats!;

  const total =
    totalPrPending +
    totalPmPending +
    totalPsPending +
    totalBaPending +
    totalPostPending +
    totalPostedNotBilled +
    totalBilled;

  return (
    <>
      <dt>PR Pending</dt>
      <dd>{chargeAmount(totalPrPending, budget!)}</dd>

      <dt>PM Pending</dt>
      <dd>{chargeAmount(totalPmPending, budget!)}</dd>

      <dt>PS Pending</dt>
      <dd>{chargeAmount(totalPsPending, budget!)}</dd>

      <dt>BA Pending</dt>
      <dd>{chargeAmount(totalBaPending, budget!)}</dd>

      <dt>Post Pending</dt>
      <dd>{chargeAmount(totalPostPending, budget!)}</dd>

      <dt>Post Not Billed</dt>
      <dd>{chargeAmount(totalPostedNotBilled, budget!)}</dd>

      <dt>Billed With Tax</dt>
      <dd>{chargeAmount(totalBilled, budget!)}</dd>

      <dt>Billed Without Tax</dt>
      <dd>{chargeAmount(totalBilledWithoutTax, budget!)}</dd>

      <dt>Legacy Billed With Tax</dt>
      <dd>{chargeAmount(legacyBilled, budget!)}</dd>

      <dt>Non-billable</dt>
      <dd>{currencyFormatter.format(totalNonBillable)}</dd>

      <Totals {...{ total, budget: budget! }} />
    </>
  );
};
