import { Dialog, DialogTitle, DialogContent } from "~/dialog";
import React from "react";
import { useSnackBar } from "~/snackbar";
import { useRouteHistory } from "~/route-history";
import FormBottomRow from "~/form-bottom-row";
import Spinner from "~/spinner";
import TextFormField from "~/text-form-field";
import { useSendHelpRequestMutation } from "./SendHelpRequest.generated";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { ValidationResponse } from "~/gql/types";
import { Body1 } from "@material/react-typography";

type HelpWidgetFormProps = {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
};

type FormValues = {
  issue: string | null;
};

const HelpWidgetForm: React.FC<HelpWidgetFormProps> = ({ open, setOpen }) => {
  const paths = useRouteHistory();
  const recentPaths = paths.slice(0, 5);
  const addAlert = useSnackBar();
  const [doMutation, { loading }] = useSendHelpRequestMutation();

  const handleSubmit = async (values: FormValues) => {
    const result = await doMutation({
      variables: {
        issue: values.issue!,
        paths: recentPaths,
      },
    });

    return result?.data?.helpRequest?.sendRequest as ValidationResponse;
  };

  const onSuccess = ({ values }) => {
    addAlert({
      key: values.issue,
      message: "Your help request has been submitted",
      isSuccess: true,
    });
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      data-open={open}
      className="help-widget-form"
    >
      <DialogTitle>Need help? Submit your comment/question.</DialogTitle>
      <DialogContent>
        <Spinner open={loading} />
        <Body1>
          Please include as much detail as you can. This will help us help you
          as quickly as possible.
        </Body1>
        <MutationForm
          runMutation={handleSubmit}
          initialValues={{ issue: null }}
          onSuccess={onSuccess}
        >
          <TextFormField
            formField="issue"
            label="Issue"
            helperText=""
            textarea
            required
          />

          <ErrorRenderer
            render={(submitError) => (
              <FormBottomRow
                errorMessage={submitError}
                buttonText="Submit"
                onCancel={() => setOpen(false)}
              />
            )}
          />
        </MutationForm>
      </DialogContent>
    </Dialog>
  );
};

export default HelpWidgetForm;
