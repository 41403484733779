import React, { useState } from "react";
import "./ExpenseForm.scss";
import { FormProps } from "./CommonTypes";
import {
  FuelExpense,
  ReceiptValuedExpense,
  PerDiemExpense,
  AdHocExpense,
} from "~/gql/types";
import { ExpenseFormWrapper } from "./ExpenseFormWrapper";
import { ImageUploadWrapper } from "~/ImageUploadWrapper";

type ExpenseImageUploadWrapperProps = FormProps & {
  setImageId: React.Dispatch<string | null>;
  expenseEntry?:
    | ReceiptValuedExpense
    | FuelExpense
    | PerDiemExpense
    | AdHocExpense;
  typeName:
    | "Receipt Valued Expense"
    | "Fuel Expense"
    | "Per Diem Expense"
    | "AdHoc Expense";
  required?: boolean;
};

const ExpenseImageUploadWrapper: React.FC<ExpenseImageUploadWrapperProps> = (
  props
) => {
  const { setImageId, expenseEntry, required, children } = props;

  const [imageUrl, setImageUrl] = useState<string | null>(
    expenseEntry?.receiptImageUrl ?? null
  );

  return (
    <ExpenseFormWrapper {...props}>
      {children}
      <ImageUploadWrapper
        {...{
          setImageId,
          label: "receipt",
          formField: "receiptImage",
          imageUrl,
          setImageUrl,
          required,
        }}
      />
    </ExpenseFormWrapper>
  );
};

export default ExpenseImageUploadWrapper;
