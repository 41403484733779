import React, { ReactElement } from "react";
import CustomerForm from "~/visuals/organisms/CustomerForm";
import { NewCustomerFormValues } from "~/visuals/organisms/CustomerForm/types";

type NewCustomerProps = {
  initialValues?: NewCustomerFormValues;
};

export default function NewCustomer({
  initialValues,
}: NewCustomerProps): ReactElement {
  return <CustomerForm {...{ initialValues }} />;
}
