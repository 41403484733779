import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MoveExpenseToDateMutationVariables = Types.Exact<{
  expenseId: Types.Scalars['String'];
  newDate: Types.Scalars['LocalDate'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type MoveExpenseToDateMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', moveExpenseToDate?: { __typename?: 'CreateExpenseResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type MoveProjectTimeToDateMutationVariables = Types.Exact<{
  projectTimeId: Types.Scalars['String'];
  newDate: Types.Scalars['LocalDate'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type MoveProjectTimeToDateMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', moveProjectTimeToDate?: { __typename?: 'CreateProjectTimeResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type MoveTimeEntryToDateMutationVariables = Types.Exact<{
  timeEntryId: Types.Scalars['String'];
  newDate: Types.Scalars['LocalDate'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type MoveTimeEntryToDateMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', moveTimeEntryToDate?: { __typename?: 'CreateTimeEntryResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type MoveTimeOffToDateMutationVariables = Types.Exact<{
  timeOffEntryId: Types.Scalars['String'];
  newDate: Types.Scalars['LocalDate'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type MoveTimeOffToDateMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', moveTimeOffToDate?: { __typename?: 'CreateTimeOffEntryResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const MoveExpenseToDateDocument = gql`
    mutation MoveExpenseToDate($expenseId: String!, $newDate: LocalDate!, $adminNotes: String) {
  timesheets {
    moveExpenseToDate(
      expenseId: $expenseId
      newDate: $newDate
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type MoveExpenseToDateMutationFn = Apollo.MutationFunction<MoveExpenseToDateMutation, MoveExpenseToDateMutationVariables>;

/**
 * __useMoveExpenseToDateMutation__
 *
 * To run a mutation, you first call `useMoveExpenseToDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveExpenseToDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveExpenseToDateMutation, { data, loading, error }] = useMoveExpenseToDateMutation({
 *   variables: {
 *      expenseId: // value for 'expenseId'
 *      newDate: // value for 'newDate'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useMoveExpenseToDateMutation(baseOptions?: Apollo.MutationHookOptions<MoveExpenseToDateMutation, MoveExpenseToDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveExpenseToDateMutation, MoveExpenseToDateMutationVariables>(MoveExpenseToDateDocument, options);
      }
export type MoveExpenseToDateMutationHookResult = ReturnType<typeof useMoveExpenseToDateMutation>;
export type MoveExpenseToDateMutationResult = Apollo.MutationResult<MoveExpenseToDateMutation>;
export type MoveExpenseToDateMutationOptions = Apollo.BaseMutationOptions<MoveExpenseToDateMutation, MoveExpenseToDateMutationVariables>;
export const MoveProjectTimeToDateDocument = gql`
    mutation MoveProjectTimeToDate($projectTimeId: String!, $newDate: LocalDate!, $adminNotes: String) {
  timesheets {
    moveProjectTimeToDate(
      projectTimeId: $projectTimeId
      newDate: $newDate
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type MoveProjectTimeToDateMutationFn = Apollo.MutationFunction<MoveProjectTimeToDateMutation, MoveProjectTimeToDateMutationVariables>;

/**
 * __useMoveProjectTimeToDateMutation__
 *
 * To run a mutation, you first call `useMoveProjectTimeToDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveProjectTimeToDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveProjectTimeToDateMutation, { data, loading, error }] = useMoveProjectTimeToDateMutation({
 *   variables: {
 *      projectTimeId: // value for 'projectTimeId'
 *      newDate: // value for 'newDate'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useMoveProjectTimeToDateMutation(baseOptions?: Apollo.MutationHookOptions<MoveProjectTimeToDateMutation, MoveProjectTimeToDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveProjectTimeToDateMutation, MoveProjectTimeToDateMutationVariables>(MoveProjectTimeToDateDocument, options);
      }
export type MoveProjectTimeToDateMutationHookResult = ReturnType<typeof useMoveProjectTimeToDateMutation>;
export type MoveProjectTimeToDateMutationResult = Apollo.MutationResult<MoveProjectTimeToDateMutation>;
export type MoveProjectTimeToDateMutationOptions = Apollo.BaseMutationOptions<MoveProjectTimeToDateMutation, MoveProjectTimeToDateMutationVariables>;
export const MoveTimeEntryToDateDocument = gql`
    mutation MoveTimeEntryToDate($timeEntryId: String!, $newDate: LocalDate!, $adminNotes: String) {
  timesheets {
    moveTimeEntryToDate(
      timeEntryId: $timeEntryId
      newDate: $newDate
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type MoveTimeEntryToDateMutationFn = Apollo.MutationFunction<MoveTimeEntryToDateMutation, MoveTimeEntryToDateMutationVariables>;

/**
 * __useMoveTimeEntryToDateMutation__
 *
 * To run a mutation, you first call `useMoveTimeEntryToDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveTimeEntryToDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveTimeEntryToDateMutation, { data, loading, error }] = useMoveTimeEntryToDateMutation({
 *   variables: {
 *      timeEntryId: // value for 'timeEntryId'
 *      newDate: // value for 'newDate'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useMoveTimeEntryToDateMutation(baseOptions?: Apollo.MutationHookOptions<MoveTimeEntryToDateMutation, MoveTimeEntryToDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveTimeEntryToDateMutation, MoveTimeEntryToDateMutationVariables>(MoveTimeEntryToDateDocument, options);
      }
export type MoveTimeEntryToDateMutationHookResult = ReturnType<typeof useMoveTimeEntryToDateMutation>;
export type MoveTimeEntryToDateMutationResult = Apollo.MutationResult<MoveTimeEntryToDateMutation>;
export type MoveTimeEntryToDateMutationOptions = Apollo.BaseMutationOptions<MoveTimeEntryToDateMutation, MoveTimeEntryToDateMutationVariables>;
export const MoveTimeOffToDateDocument = gql`
    mutation MoveTimeOffToDate($timeOffEntryId: String!, $newDate: LocalDate!, $adminNotes: String) {
  timesheets {
    moveTimeOffToDate(
      timeOffEntryId: $timeOffEntryId
      newDate: $newDate
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type MoveTimeOffToDateMutationFn = Apollo.MutationFunction<MoveTimeOffToDateMutation, MoveTimeOffToDateMutationVariables>;

/**
 * __useMoveTimeOffToDateMutation__
 *
 * To run a mutation, you first call `useMoveTimeOffToDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveTimeOffToDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveTimeOffToDateMutation, { data, loading, error }] = useMoveTimeOffToDateMutation({
 *   variables: {
 *      timeOffEntryId: // value for 'timeOffEntryId'
 *      newDate: // value for 'newDate'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useMoveTimeOffToDateMutation(baseOptions?: Apollo.MutationHookOptions<MoveTimeOffToDateMutation, MoveTimeOffToDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveTimeOffToDateMutation, MoveTimeOffToDateMutationVariables>(MoveTimeOffToDateDocument, options);
      }
export type MoveTimeOffToDateMutationHookResult = ReturnType<typeof useMoveTimeOffToDateMutation>;
export type MoveTimeOffToDateMutationResult = Apollo.MutationResult<MoveTimeOffToDateMutation>;
export type MoveTimeOffToDateMutationOptions = Apollo.BaseMutationOptions<MoveTimeOffToDateMutation, MoveTimeOffToDateMutationVariables>;