import React, { useState } from "react";
import Drawer from "./main-layout/Drawer";
import TopBar from "./main-layout/TopBar";
import AppRouter from "./router";
import Apollo from "./Apollo";
import Auth from "./Auth";
import "./App.scss";
import { ApolloClient } from "@apollo/client";
import { SnackBarProvider } from "~/snackbar";
import ReferenceDataProvider from "~/refdata";
import BreadcrumbProvider from "~/main-layout/BreadcrumbProvider";
import { Portal } from "~/portal";
import { ConfirmationPromptProvider } from "./confirmation-prompt";
import { RouteHistoryProvider } from "./route-history";
import { GlobalErrorHandler } from "./error-handler";
import HelpWidget from "./visuals/organisms/HelpWidget";
import { ErrorGenerator } from "./ErrorGenerator";
import { RefDataProvider } from "./refdata2";
import { StateHelperProvider } from "~/state-helper";
import { GetAuthorizationsAsync } from "./Auth/types";
import { SessionDataLogger } from "./Auth/SessionDataLogger";
import { VersionCheck } from "./version-check";

type AppProps = {
  getAuthorizationsAsync: GetAuthorizationsAsync;
  apolloClient?: ApolloClient<any>;
};

const App: React.FC<AppProps> = ({ getAuthorizationsAsync, apolloClient }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ConfirmationPromptProvider>
      <Auth getAuthorizations={getAuthorizationsAsync}>
        <Apollo overrideClient={apolloClient}>
          <div className="root">
            <RouteHistoryProvider>
              <BreadcrumbProvider>
                <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
                  <TopBar isDrawerOpen={isOpen} setIsDrawerOpen={setIsOpen}>
                    <ReferenceDataProvider>
                      <RefDataProvider>
                        <SnackBarProvider>
                          <StateHelperProvider>
                            <AppRouter />
                            <Portal />
                            <GlobalErrorHandler />
                            <HelpWidget />
                            <ErrorGenerator />
                            <VersionCheck />
                          </StateHelperProvider>
                        </SnackBarProvider>
                      </RefDataProvider>
                    </ReferenceDataProvider>
                  </TopBar>
                </Drawer>
              </BreadcrumbProvider>
            </RouteHistoryProvider>
          </div>
          <SessionDataLogger />
        </Apollo>
      </Auth>
    </ConfirmationPromptProvider>
  );
};

export default App;
