import React, { useEffect } from "react";
import TableCell from "~/old-table-cell";
import TableContent from "~/old-table-content";
import TableHeader from "~/old-table-header";
import Table from "~/old-table";
import moment from "moment";
import { Placeholder } from "./Placeholder";
import {
  FilterForm,
  FilterFormProps,
  FilterOptions,
} from "./InvoiceFilterForm";
import cn from "classnames";
import { InlineProgress } from "~/inline-progress";
import useInfiniteScroll from "~/search-page-wrapper/infinite-scroll/useInfiniteScroll";
import { Action } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { VisibilityListener } from "~/visibility-listener";
import CardTopRow from "~/card-top-row";
import { invoice as invoiceRoute } from "~/routes";
import { useNavigate } from "react-router-dom";
import "./Invoices.scss";
import { CreateInvoice } from "./CreateInvoice";
import { Project } from "./types";
import { useInvoicesSearchQuery } from "~/invoice-search/query.generated";
import { Invoice, InvoiceFilterState } from "~/gql/types";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

type RowProps = {
  entry: Invoice;
};

export const Row: React.FC<RowProps> = ({ entry }) => {
  const navigate = useNavigate();
  const invoiceDate = entry.invoiceDate
    ? moment(entry.invoiceDate).format("MM/DD/YYYY")
    : "";

  return (
    <>
      <TableContent
        className="invoice-row"
        onClick={() => navigate(invoiceRoute.toRoute(entry.invoiceNumber).path)}
      >
        <TableCell text={entry.invoiceNumber} />
        <TableCell text={invoiceDate} />
        <TableCell text={formatter.format(entry.total)} />
      </TableContent>
    </>
  );
};

export type InvoicesDisplayProps = {
  showSpinner: boolean;
  invoices: Invoice[] | null;
  setFilterOptions: React.Dispatch<FilterOptions>;
  FilterFormComponent: React.FC<FilterFormProps>;
  project: Project;
  dispatch: React.Dispatch<Action<Invoice, InvoiceFilter>>;
};

export const InvoicesDisplay: React.FC<InvoicesDisplayProps> = ({
  FilterFormComponent,
  showSpinner,
  invoices,
  setFilterOptions,
  project,
  dispatch,
}) => {
  const interceptFilterOptions = (opts) => {
    setFilterOptions(opts);
  };

  const reloadInvoices = () =>
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: { state: "Draft" as InvoiceFilterState },
    });

  return (
    <div className="invoices-view">
      <CardTopRow>
        <FilterFormComponent onFiltersChanged={interceptFilterOptions} />
        {project.canCreateInvoice && (
          <CreateInvoice
            {...{ projectNumber: project.number, reloadInvoices }}
          />
        )}
      </CardTopRow>
      <div className={cn("loading", { visible: showSpinner })}>
        {showSpinner && <InlineProgress />}
      </div>
      {invoices && invoices.length > 0 && (
        <Table>
          <TableHeader>
            <TableCell text="Invoice Number" />
            <TableCell text="Date" />
            <TableCell text="Total" />
          </TableHeader>
          {invoices?.map((entry, i) => (
            <Row key={entry!.invoiceNumber ?? i} {...{ entry }} />
          ))}
        </Table>
      )}
      {!invoices ||
        (invoices.length === 0 && <Placeholder>(no invoices)</Placeholder>)}
    </div>
  );
};

type InvoicesTabProps = {
  FilterFormComponent?: typeof FilterForm;
  project: Project;
};

type InvoiceFilter = {
  state: InvoiceFilterState;
};

type LoadInvoicesProps = {
  dispatch: React.Dispatch<Action<Invoice, InvoiceFilter>>;
  tag: "DataLoaded" | "MoreDataLoaded";
  invoiceState: InvoiceFilterState;
  token: string | null;
  projectNumber: number;
};

const LoadInvoices = ({
  dispatch,
  tag,
  invoiceState,
  token,
  projectNumber,
}: LoadInvoicesProps) => {
  const variables = {
    token,
    state: invoiceState,
    projectNumber,
  };

  const { data } = useInvoicesSearchQuery({ variables });

  useEffect(() => {
    void (async () => {
      if (data) {
        const newToken = data?.invoices?.search?.token as string | null;
        const newInvoices = data?.invoices?.search?.records as Invoice[];
        dispatch({ tag, items: newInvoices, searchToken: newToken });
      }
    })();
  }, [data]);

  return <></>;
};

export const InvoicesTab: React.FC<InvoicesTabProps> = (props) => {
  const { project, FilterFormComponent } = props;

  const initialState = {
    tag: "Loading",
    items: [],
    showVisibility: false,
    loading: true,
    filterOptions: { state: "Draft" as InvoiceFilterState },
    searchToken: null,
  };

  const { state, dispatch } = useInfiniteScroll<Invoice, InvoiceFilter>(
    initialState
  );

  const FilterComponent = FilterFormComponent || FilterForm;

  const setFilterOptions = (opts: FilterOptions) =>
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: opts,
    });

  return (
    <>
      {state.tag === "Loading" && (
        <LoadInvoices
          {...{
            dispatch,
            tag: "DataLoaded",
            invoiceState: state.filterOptions.state,
            token: null,
            projectNumber: project.number,
          }}
        />
      )}
      {state.tag === "LoadingMore" && (
        <LoadInvoices
          {...{
            dispatch,
            tag: "MoreDataLoaded",
            invoiceState: state.filterOptions.state,
            token: state.searchToken,
            projectNumber: project.number,
          }}
        />
      )}
      <InvoicesDisplay
        {...{
          FilterFormComponent: FilterComponent,
          invoices: state.items,
          showSpinner: state.loading,
          setFilterOptions,
          project,
          dispatch,
        }}
      />
      {state.showVisibility && (
        <VisibilityListener
          onShown={() => dispatch({ tag: "ScrolledToBottom" })}
        />
      )}
    </>
  );
};
