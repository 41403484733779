// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uTM\\+92dLtO28ZvUrzwV03w\\=\\={display:inline-block;margin-right:8px;top:1px}.uTM\\+92dLtO28ZvUrzwV03w\\=\\= .Sk47iOVN-2ToNHcmLiqZ8g\\=\\= .y8zLfVSptHE6UF3QHPEnyQ\\=\\={top:5px}.uTM\\+92dLtO28ZvUrzwV03w\\=\\= .Sk47iOVN-2ToNHcmLiqZ8g\\=\\= .y8zLfVSptHE6UF3QHPEnyQ\\=\\= svg{position:relative;bottom:8px}", "",{"version":3,"sources":["webpack://src/search-page-wrapper/BulkCheckboxes/CardSelectCheckbox.module.scss"],"names":[],"mappings":"AAAA,6BACE,oBAAA,CACA,gBAAA,CACA,OAAA,CAEE,qFACE,OAAA,CACA,yFACE,iBAAA,CACA,UAAA","sourcesContent":[".CardSelectCheckbox {\n  display: inline-block;\n  margin-right: 8px;\n  top: 1px;\n  .CheckboxOuter {\n    .CheckboxInner {\n      top: 5px;\n      svg {\n        position: relative;\n        bottom: 8px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CardSelectCheckbox": "uTM+92dLtO28ZvUrzwV03w==",
	"CheckboxOuter": "Sk47iOVN-2ToNHcmLiqZ8g==",
	"CheckboxInner": "y8zLfVSptHE6UF3QHPEnyQ=="
};
export default ___CSS_LOADER_EXPORT___;
