import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkDraftRollupInvoicesQueryVariables = Types.Exact<{
  groupIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type BulkDraftRollupInvoicesQuery = { __typename?: 'QueryModel', customRollupInvoices?: { __typename?: 'CustomRollupInvoiceQueryModel', draftRollupInvoices?: Array<{ __typename?: 'CustomRollupInvoice', invoiceNumber: string, total: number, lineItems?: Array<{ __typename?: 'CustomRollupInvoiceLineItem', total: number, invoice?: { __typename?: 'Invoice', invoiceNumber: string } | null } | null> | null, projectGroup?: { __typename?: 'ProjectGroup', id: number } | null } | null> | null } | null };


export const BulkDraftRollupInvoicesDocument = gql`
    query BulkDraftRollupInvoices($groupIds: [Int!]!) {
  customRollupInvoices {
    draftRollupInvoices(groupIds: $groupIds) {
      invoiceNumber
      lineItems {
        total
        invoice {
          invoiceNumber
        }
      }
      projectGroup {
        id
      }
      total
    }
  }
}
    `;

/**
 * __useBulkDraftRollupInvoicesQuery__
 *
 * To run a query within a React component, call `useBulkDraftRollupInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkDraftRollupInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkDraftRollupInvoicesQuery({
 *   variables: {
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useBulkDraftRollupInvoicesQuery(baseOptions: Apollo.QueryHookOptions<BulkDraftRollupInvoicesQuery, BulkDraftRollupInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkDraftRollupInvoicesQuery, BulkDraftRollupInvoicesQueryVariables>(BulkDraftRollupInvoicesDocument, options);
      }
export function useBulkDraftRollupInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkDraftRollupInvoicesQuery, BulkDraftRollupInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkDraftRollupInvoicesQuery, BulkDraftRollupInvoicesQueryVariables>(BulkDraftRollupInvoicesDocument, options);
        }
export type BulkDraftRollupInvoicesQueryHookResult = ReturnType<typeof useBulkDraftRollupInvoicesQuery>;
export type BulkDraftRollupInvoicesLazyQueryHookResult = ReturnType<typeof useBulkDraftRollupInvoicesLazyQuery>;
export type BulkDraftRollupInvoicesQueryResult = Apollo.QueryResult<BulkDraftRollupInvoicesQuery, BulkDraftRollupInvoicesQueryVariables>;