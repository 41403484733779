import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditTaskRateSheetMutationMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  allowAssignment: Types.Scalars['Boolean'];
  endDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
}>;


export type EditTaskRateSheetMutationMutation = { __typename?: 'MutationModel', taskRates?: { __typename?: 'TaskRatesMutationModel', editTaskRateSheet?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const EditTaskRateSheetMutationDocument = gql`
    mutation EditTaskRateSheetMutation($name: String!, $allowAssignment: Boolean!, $endDate: LocalDate) {
  taskRates {
    editTaskRateSheet(
      name: $name
      allowAssignment: $allowAssignment
      endDate: $endDate
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditTaskRateSheetMutationMutationFn = Apollo.MutationFunction<EditTaskRateSheetMutationMutation, EditTaskRateSheetMutationMutationVariables>;

/**
 * __useEditTaskRateSheetMutationMutation__
 *
 * To run a mutation, you first call `useEditTaskRateSheetMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskRateSheetMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskRateSheetMutationMutation, { data, loading, error }] = useEditTaskRateSheetMutationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      allowAssignment: // value for 'allowAssignment'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useEditTaskRateSheetMutationMutation(baseOptions?: Apollo.MutationHookOptions<EditTaskRateSheetMutationMutation, EditTaskRateSheetMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTaskRateSheetMutationMutation, EditTaskRateSheetMutationMutationVariables>(EditTaskRateSheetMutationDocument, options);
      }
export type EditTaskRateSheetMutationMutationHookResult = ReturnType<typeof useEditTaskRateSheetMutationMutation>;
export type EditTaskRateSheetMutationMutationResult = Apollo.MutationResult<EditTaskRateSheetMutationMutation>;
export type EditTaskRateSheetMutationMutationOptions = Apollo.BaseMutationOptions<EditTaskRateSheetMutationMutation, EditTaskRateSheetMutationMutationVariables>;