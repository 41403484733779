import React from "react";
import { TimeSheetDisplay } from "~/visuals/organisms/TimesheetDisplay";
import { FilterForm } from "../MyWorkWeeks/FilterForm";
import { FilterFormProps } from "~/visuals/organisms/TimesheetDisplay/types";

type TimesheetReviewProps = {
  FilterFormComponent?: React.FC<FilterFormProps>;
};
export const ReviewWorkWeeks: React.FC<TimesheetReviewProps> = (props) => (
  <TimeSheetDisplay
    FilterFormComponent={props.FilterFormComponent ?? FilterForm}
    mode={"ReviewTimesheets"}
  />
);
