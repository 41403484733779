import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "~/filterProviders/types";
import { createSimpleProvider } from "~/filterProviders/createSimpleProvider";
import {
  CompanyRolePicker,
  companyRoleValues,
} from "~/roles/CompanyRolePicker";

export function useCompanyRoleProvider(
  props: FilterProviderProps
): FilterProvider {
  return createSimpleProvider({
    documentationText: props.documentationText,
    type: "role",
    label: "Role",
    render: () => (
      <CompanyRolePicker formField="role" label="Role" helperText="" />
    ),
    suggestText: "role",
    options: companyRoleValues,
  });
}
