import React, { useContext } from "react";

type ValuesRendererProps = {
  render: (values: Record<string, any>) => React.ReactElement;
};

export const ValueContext = React.createContext({});

export const ValueRenderer = (props: ValuesRendererProps) => {
  const { render } = props;

  const values = useContext(ValueContext);

  return render(values);
};
