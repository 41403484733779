import React, { useEffect } from "react";
import { useChargeFormField } from "./fieldUtils";

export const GlAccountField: React.FC = () => {
  const { value: rateValue, initial: initialRate } = useChargeFormField("rate");
  const { value: sheetValue, initial: initialSheet } =
    useChargeFormField("rateSheet");

  const { onChange } = useChargeFormField("glAccount");

  useEffect(() => {
    if (
      !(rateValue === initialRate && sheetValue === initialSheet) &&
      rateValue
    ) {
      onChange(rateValue.glAccount);
    }
  }, [rateValue]);

  return <></>;
};
