import { ProjectCharge } from "~/gql/types";

export const rateFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 6,
  minimumFractionDigits: 2,
});

export const formatChargeRate = (charge: ProjectCharge): string => {
  const units = {
    Each: " each",
    Day: " /day",
    Hourly: " /hr",
    Foot: " /ft",
    Mile: " /mi",
    Gallon: " /gal",
  };

  const unit = charge.unit ? units[charge.unit] : "";
  return `$${rateFormatter.format(charge.rate)}${unit}`;
};
