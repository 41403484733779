import React from "react";
import Button from "~/button";
import "./FormBottomRow.scss";
import { Route } from "~/routeTypes";
import ErrorMessage from "~/error-message";
import cn from "classnames";

type FormBottomRowProps = {
  errorMessage: string | null;
  route?: Route;
  buttonText: string;
  cancelButtonText?: string;
  onCancel?: () => void;
  onSubmit?: (e?: any) => void;
  cancelButtonClass?: string;
};

const FormBottomRow: React.FC<FormBottomRowProps> = ({
  errorMessage,
  route,
  buttonText,
  onCancel,
  onSubmit,
  cancelButtonText,
  children,
  cancelButtonClass,
}) => {
  return (
    <div className={"form-bottom-row"}>
      <ErrorMessage errorMessage={errorMessage || null} />
      <section className="actions">
        {buttonText != "" && (
          <Button
            primary
            data-testid="submit-button"
            className="form-bottom-submit"
            onClick={onSubmit}
          >
            {buttonText}
          </Button>
        )}
        {children}
        <Button
          type="button"
          route={route}
          className={cn("form-bottom-cancel", cancelButtonClass)}
          onClick={onCancel}
        >
          {cancelButtonText || "Cancel"}
        </Button>
      </section>
    </div>
  );
};

export default FormBottomRow;
