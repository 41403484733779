import { ApiCallback } from "~/WorkDayApi/WorkDayApiType";
import { SaveAndAction } from "~/WorkDayApi/SaveAndHandler";
import { toCallback } from "~/WorkDayApi/utils";
import { useTimeOffCopy } from "~/WorkDayApi/WorkDayCopyProvider";
import { CreateTimeOffEntryResponse } from "~/WorkDayApi/createTimeOffEntryApi";

export function useTimeOffCallback(): (
  values: Record<string, any>
) => ApiCallback | undefined {
  const { setCopyToDate, setCopyToDates } = useTimeOffCopy();

  return (values: Record<string, any>) => {
    const copyToDateCallback = (response: CreateTimeOffEntryResponse) =>
      Promise.resolve(setCopyToDate(response.timeOffEntryId));

    const copyToDatesCallback = (response: CreateTimeOffEntryResponse) =>
      Promise.resolve(setCopyToDates(response.timeOffEntryId));

    const callbackMap: Partial<Record<SaveAndAction, ApiCallback>> = {
      copyToDate: copyToDateCallback,
      copyToDates: copyToDatesCallback,
    };

    return toCallback(callbackMap, values);
  };
}
