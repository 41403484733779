import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditExpensePayableMutationVariables = Types.Exact<{
  expenseId: Types.Scalars['String'];
  notes: Types.Scalars['String'];
  payableAmount: Types.Scalars['Decimal'];
}>;


export type EditExpensePayableMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', editExpensePayableAmount?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const EditExpensePayableDocument = gql`
    mutation EditExpensePayable($expenseId: String!, $notes: String!, $payableAmount: Decimal!) {
  timesheets {
    editExpensePayableAmount(
      expenseId: $expenseId
      notes: $notes
      payableAmount: $payableAmount
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditExpensePayableMutationFn = Apollo.MutationFunction<EditExpensePayableMutation, EditExpensePayableMutationVariables>;

/**
 * __useEditExpensePayableMutation__
 *
 * To run a mutation, you first call `useEditExpensePayableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExpensePayableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExpensePayableMutation, { data, loading, error }] = useEditExpensePayableMutation({
 *   variables: {
 *      expenseId: // value for 'expenseId'
 *      notes: // value for 'notes'
 *      payableAmount: // value for 'payableAmount'
 *   },
 * });
 */
export function useEditExpensePayableMutation(baseOptions?: Apollo.MutationHookOptions<EditExpensePayableMutation, EditExpensePayableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditExpensePayableMutation, EditExpensePayableMutationVariables>(EditExpensePayableDocument, options);
      }
export type EditExpensePayableMutationHookResult = ReturnType<typeof useEditExpensePayableMutation>;
export type EditExpensePayableMutationResult = Apollo.MutationResult<EditExpensePayableMutation>;
export type EditExpensePayableMutationOptions = Apollo.BaseMutationOptions<EditExpensePayableMutation, EditExpensePayableMutationVariables>;