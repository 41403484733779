import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveTaskRateSheetMutationVariables = Types.Exact<{
  effectiveDate: Types.Scalars['LocalDate'];
  rateSheetName: Types.Scalars['String'];
  projectNumbers: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type RemoveTaskRateSheetMutation = { __typename?: 'MutationModel', taskRates?: { __typename?: 'TaskRatesMutationModel', removeRateSheetFromProject?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const RemoveTaskRateSheetDocument = gql`
    mutation RemoveTaskRateSheet($effectiveDate: LocalDate!, $rateSheetName: String!, $projectNumbers: [Int!]!) {
  taskRates {
    removeRateSheetFromProject(
      effectiveDate: $effectiveDate
      rateSheetName: $rateSheetName
      projectNumbers: $projectNumbers
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type RemoveTaskRateSheetMutationFn = Apollo.MutationFunction<RemoveTaskRateSheetMutation, RemoveTaskRateSheetMutationVariables>;

/**
 * __useRemoveTaskRateSheetMutation__
 *
 * To run a mutation, you first call `useRemoveTaskRateSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTaskRateSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTaskRateSheetMutation, { data, loading, error }] = useRemoveTaskRateSheetMutation({
 *   variables: {
 *      effectiveDate: // value for 'effectiveDate'
 *      rateSheetName: // value for 'rateSheetName'
 *      projectNumbers: // value for 'projectNumbers'
 *   },
 * });
 */
export function useRemoveTaskRateSheetMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTaskRateSheetMutation, RemoveTaskRateSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTaskRateSheetMutation, RemoveTaskRateSheetMutationVariables>(RemoveTaskRateSheetDocument, options);
      }
export type RemoveTaskRateSheetMutationHookResult = ReturnType<typeof useRemoveTaskRateSheetMutation>;
export type RemoveTaskRateSheetMutationResult = Apollo.MutationResult<RemoveTaskRateSheetMutation>;
export type RemoveTaskRateSheetMutationOptions = Apollo.BaseMutationOptions<RemoveTaskRateSheetMutation, RemoveTaskRateSheetMutationVariables>;