import React, { createContext, useContext } from "react";

type ContextType = {
  reset: () => void;
};

export const ResetSearchItemsContext = createContext<ContextType>({
  reset: () => undefined,
});

export function useResetSearchItems(): () => void {
  const context = useContext(ResetSearchItemsContext);
  return context.reset;
}

export const ResetSearchItemsProvider: React.FC<{ reset: () => void }> = ({
  reset,
  children,
}) => {
  return (
    <ResetSearchItemsContext.Provider value={{ reset }}>
      {children}
    </ResetSearchItemsContext.Provider>
  );
};
