import { Moment } from "moment";
import React from "react";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import FormBottomRow from "~/form-bottom-row";
import { Body1 } from "@material/react-typography";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";
import ExpenseRateSheetPickerFormField from "~/visuals/organisms/ExpenseRateSheetPickerFormField";
import TaskRateSheetPickerFormField from "~/visuals/organisms/TaskRateSheetPickerFormField";
import "./BulkAssignRateSheet.scss";
import { useResetSearchItems } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import { useSnackBar } from "~/snackbar";
import { useExpenseRatesAssignRateSheetToProjectMutation } from "./expenseRates.generated";
import {
  TaskRatesAssignRateSheetToProjectMutationVariables,
  useTaskRatesAssignRateSheetToProjectMutation,
} from "./taskRates.generated";
import Spinner from "~/spinner";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { ValueRenderer } from "~/forms/ValueContext";

export type RateSheetAssignee = {
  expenseRateSheets: { effectiveDate: string }[];
  taskRateSheets: { effectiveDate: string }[];
  number: number | string;
};

type BulkAssignRateSheetProps = {
  type: "Expense" | "Task";
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  selectedItems: any[];
  filteredItems: RateSheetAssignee[];
  itemType: "projects" | "customers";
};

type RateSheet = {
  effectiveDate: string;
};

type FormData = {
  rateSheetName: string | null;
  effectiveDate: Moment | null;
};

export const BulkAssignRateSheet: React.FC<BulkAssignRateSheetProps> = ({
  type,
  open,
  setOpen,
  selectedItems,
  filteredItems,
  itemType,
}) => {
  const titleType = "Projects";
  const title = `Assign ${type} Rate Sheet to Selected ${titleType}`;
  const helperText = `Pick a Rate Sheet to assign to selected ${itemType}`;
  const isExpenseType = type === "Expense";
  const reloadItems = useResetSearchItems();
  const addAlert = useSnackBar();
  const mutationHook = isExpenseType
    ? useExpenseRatesAssignRateSheetToProjectMutation
    : useTaskRatesAssignRateSheetToProjectMutation;
  const [doMutation, { loading }] = mutationHook();

  const initialValues = {
    rateSheetName: null,
    effectiveDate: null,
  };

  const getAssignableItems = (effectiveDate: Moment) => {
    const formattedDate = effectiveDate.format("YYYY-MM-DD");
    const rateSheets = isExpenseType ? "expenseRateSheets" : "taskRateSheets";
    const assignableItems = filteredItems.filter((x) =>
      (x[rateSheets] as RateSheet[]).every(
        (x) => x.effectiveDate != formattedDate
      )
    );

    return assignableItems;
  };

  const displayMessage = (effectiveDate: Moment | null) => {
    if (!effectiveDate) {
      return "";
    }

    const assignableItems = getAssignableItems(effectiveDate);

    return `${assignableItems.length} of ${selectedItems.length} selected ${itemType} can have this rate sheet assigned`;
  };

  const mutationModel = isExpenseType ? "expenseRates" : "taskRates";

  const handleSubmit = async (values: FormData) => {
    const assignableItems = getAssignableItems(values.effectiveDate!).map(
      (x) => x.number
    );
    const variables = {
      rateSheetName: values.rateSheetName!,
      effectiveDate: values.effectiveDate!.format("YYYY-MM-DD"),
      projectNumbers: assignableItems,
    } as TaskRatesAssignRateSheetToProjectMutationVariables;
    const result = await doMutation({
      variables,
    });

    return result?.data?.[mutationModel]?.assignRateSheetToProject;
  };

  const onSuccess = ({ values }) => {
    const assignableItems = getAssignableItems(values.effectiveDate!).map(
      (x) => x.number
    );

    setOpen(false);
    addAlert({
      key: `${Math.random()}`,
      message: `Rate sheet assigned to ${assignableItems.length} ${itemType}`,
      isSuccess: true,
    });
    reloadItems();
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      data-open={open}
      className="bulk-assign-rate-sheet"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {open && (
          <MutationForm
            runMutation={handleSubmit}
            initialValues={initialValues}
            onSuccess={onSuccess}
          >
            <ValueRenderer
              render={(values) => (
                <>
                  <DatePickFormField
                    label="Effective Date"
                    formField="effectiveDate"
                    helperText="The date on which the rate sheet will go into effect."
                    required
                    maxDate={new Date(8640000000000000)}
                  />
                  {type === "Expense" && (
                    <ExpenseRateSheetPickerFormField
                      label="Rate Sheet"
                      formField="rateSheetName"
                      helperText={helperText}
                      required
                      effectiveDate={values.effectiveDate}
                    />
                  )}
                  {type === "Task" && (
                    <TaskRateSheetPickerFormField
                      label="Rate Sheet"
                      formField="rateSheetName"
                      helperText={helperText}
                      required
                      effectiveDate={values.effectiveDate}
                    />
                  )}
                  <Body1 className="assign-display-message">
                    {displayMessage(values.effectiveDate)}
                  </Body1>

                  <ErrorRenderer
                    render={(submitError) => (
                      <FormBottomRow
                        errorMessage={submitError}
                        buttonText={values.effectiveDate ? "Confirm" : ""}
                        cancelButtonText="Cancel"
                        onCancel={() => setOpen(false)}
                        onSubmit={() => undefined}
                      />
                    )}
                  />
                </>
              )}
            />
          </MutationForm>
        )}
      </DialogContent>
      <Spinner open={loading} />
    </Dialog>
  );
};
