import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCrewCodeMutationVariables = Types.Exact<{
  crewCode: Types.Scalars['String'];
  overrideEmployeeId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateCrewCodeMutation = { __typename?: 'MutationModel', crewCodes?: { __typename?: 'CrewCodeMutationModel', updateCrewCode?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const UpdateCrewCodeDocument = gql`
    mutation UpdateCrewCode($crewCode: String!, $overrideEmployeeId: String) {
  crewCodes {
    updateCrewCode(crewCode: $crewCode, overrideEmployeeId: $overrideEmployeeId) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type UpdateCrewCodeMutationFn = Apollo.MutationFunction<UpdateCrewCodeMutation, UpdateCrewCodeMutationVariables>;

/**
 * __useUpdateCrewCodeMutation__
 *
 * To run a mutation, you first call `useUpdateCrewCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrewCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrewCodeMutation, { data, loading, error }] = useUpdateCrewCodeMutation({
 *   variables: {
 *      crewCode: // value for 'crewCode'
 *      overrideEmployeeId: // value for 'overrideEmployeeId'
 *   },
 * });
 */
export function useUpdateCrewCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCrewCodeMutation, UpdateCrewCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCrewCodeMutation, UpdateCrewCodeMutationVariables>(UpdateCrewCodeDocument, options);
      }
export type UpdateCrewCodeMutationHookResult = ReturnType<typeof useUpdateCrewCodeMutation>;
export type UpdateCrewCodeMutationResult = Apollo.MutationResult<UpdateCrewCodeMutation>;
export type UpdateCrewCodeMutationOptions = Apollo.BaseMutationOptions<UpdateCrewCodeMutation, UpdateCrewCodeMutationVariables>;