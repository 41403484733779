import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkEditServiceDescriptionMutationVariables = Types.Exact<{
  serviceDescription: Types.Scalars['String'];
  projectNumbers: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type BulkEditServiceDescriptionMutation = { __typename?: 'MutationModel', projects?: { __typename?: 'ProjectsMutationModel', editServiceDescription?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const BulkEditServiceDescriptionDocument = gql`
    mutation BulkEditServiceDescription($serviceDescription: String!, $projectNumbers: [Int!]!) {
  projects {
    editServiceDescription(
      serviceDescription: $serviceDescription
      projectNumbers: $projectNumbers
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type BulkEditServiceDescriptionMutationFn = Apollo.MutationFunction<BulkEditServiceDescriptionMutation, BulkEditServiceDescriptionMutationVariables>;

/**
 * __useBulkEditServiceDescriptionMutation__
 *
 * To run a mutation, you first call `useBulkEditServiceDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditServiceDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditServiceDescriptionMutation, { data, loading, error }] = useBulkEditServiceDescriptionMutation({
 *   variables: {
 *      serviceDescription: // value for 'serviceDescription'
 *      projectNumbers: // value for 'projectNumbers'
 *   },
 * });
 */
export function useBulkEditServiceDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<BulkEditServiceDescriptionMutation, BulkEditServiceDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkEditServiceDescriptionMutation, BulkEditServiceDescriptionMutationVariables>(BulkEditServiceDescriptionDocument, options);
      }
export type BulkEditServiceDescriptionMutationHookResult = ReturnType<typeof useBulkEditServiceDescriptionMutation>;
export type BulkEditServiceDescriptionMutationResult = Apollo.MutationResult<BulkEditServiceDescriptionMutation>;
export type BulkEditServiceDescriptionMutationOptions = Apollo.BaseMutationOptions<BulkEditServiceDescriptionMutation, BulkEditServiceDescriptionMutationVariables>;