import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditGroupOfficeMutationVariables = Types.Exact<{
  officeCode: Types.Scalars['String'];
  notes: Types.Scalars['String'];
  memberId: Types.Scalars['String'];
}>;


export type EditGroupOfficeMutation = { __typename?: 'MutationModel', projectRequests?: { __typename?: 'ProjectRequestMutationModel', editGroupOffice?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const EditGroupOfficeDocument = gql`
    mutation editGroupOffice($officeCode: String!, $notes: String!, $memberId: String!) {
  projectRequests {
    editGroupOffice(notes: $notes, memberId: $memberId, officeCode: $officeCode) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditGroupOfficeMutationFn = Apollo.MutationFunction<EditGroupOfficeMutation, EditGroupOfficeMutationVariables>;

/**
 * __useEditGroupOfficeMutation__
 *
 * To run a mutation, you first call `useEditGroupOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGroupOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGroupOfficeMutation, { data, loading, error }] = useEditGroupOfficeMutation({
 *   variables: {
 *      officeCode: // value for 'officeCode'
 *      notes: // value for 'notes'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useEditGroupOfficeMutation(baseOptions?: Apollo.MutationHookOptions<EditGroupOfficeMutation, EditGroupOfficeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditGroupOfficeMutation, EditGroupOfficeMutationVariables>(EditGroupOfficeDocument, options);
      }
export type EditGroupOfficeMutationHookResult = ReturnType<typeof useEditGroupOfficeMutation>;
export type EditGroupOfficeMutationResult = Apollo.MutationResult<EditGroupOfficeMutation>;
export type EditGroupOfficeMutationOptions = Apollo.BaseMutationOptions<EditGroupOfficeMutation, EditGroupOfficeMutationVariables>;