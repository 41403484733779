import React from "react";
import Card, { CardPrimaryContent } from "@material/react-card";
import { Cell, Row } from "@material/react-layout-grid";
import { Headline4 } from "@material/react-typography";
import MaterialIcon from "@material/react-material-icon";
import { viewExpenseRateSheet, expenseRateSheets } from "~/routes";
import "./ButtonsPage.scss";
import { ExpenseRateFormPage, FormProps } from "../ExpenseRateFormRouter";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";

type RateTypeButtonProps = {
  text: string;
  icon: string;
  onClick: () => void;
};

const RateTypeButton: React.FC<RateTypeButtonProps> = ({
  text,
  icon,
  onClick,
}) => (
  <Cell columns={3} phoneColumns={3}>
    <Card className="expense-rate-button-card" onClick={onClick}>
      <CardPrimaryContent>
        <Headline4 className="mdc-theme--secondary">
          <MaterialIcon icon={icon} />
          {text}
        </Headline4>
      </CardPrimaryContent>
    </Card>
  </Cell>
);

type ButtonsPageProps = FormProps & {
  setPage: React.Dispatch<ExpenseRateFormPage>;
};

const ButtonsPage: React.FC<ButtonsPageProps> = ({
  setPage,
  rateSheetName,
  type,
}) => {
  useBreadcrumbs(
    [
      { text: "Expense Rate Sheets", to: expenseRateSheets.path },
      {
        text: rateSheetName,
        to: viewExpenseRateSheet.toRoute(rateSheetName).path,
      },
      {
        text: `${
          type === "Create" ? "Create Expense Rate" : `Edit ${rateSheetName}`
        }`,
      },
    ],
    [rateSheetName, type]
  );

  return (
    <Row className="expense-rate-buttons">
      <RateTypeButton
        text="Per Diem Expense Rate"
        icon="attach_money"
        onClick={() => setPage("PerDiem")}
      />
      <RateTypeButton
        text="Receipt Value Expense Rate"
        icon="receipt"
        onClick={() => setPage("ReceiptValue")}
      />
      <RateTypeButton
        text="Fuel Expense Rate"
        icon="local_gas_station"
        onClick={() => setPage("Fuel")}
      />
      <RateTypeButton
        text="Mobile Allowance Expense Rate"
        icon="smartphone"
        onClick={() => setPage("MobileAllowance")}
      />
      <RateTypeButton
        text="TCP Allowance Expense Rate"
        icon="laptop"
        onClick={() => setPage("TcpAllowance")}
      />
      <RateTypeButton
        text="Mileage Expense Rate"
        icon="drive_eta"
        onClick={() => setPage("Mileage")}
      />
      <RateTypeButton
        text="ATV Expense Rate"
        icon="two_wheeler"
        onClick={() => setPage("Atv")}
      />
      <RateTypeButton
        text="AdHoc Expense Rate"
        icon="grain"
        onClick={() => setPage("AdHoc")}
      />
    </Row>
  );
};

export default ButtonsPage;
