import React, { useEffect, useReducer } from "react";
import * as States from "./states";
import * as Actions from "./actions";
import { reducer } from "./stateMachine";
import { FormFieldProps } from "~/forms/FormFieldProps";
import TypeaheadDisplay from "./TypeaheadDisplay";
import { useField } from "react-final-form";
import { useDebounce } from "@react-hook/debounce";
import { useAct } from "~/async-actor";

export type TypeaheadProps<TItem> = FormFieldProps & {
  getData: (searchText: string) => Promise<TItem[]>;
  format: (item: TItem, searchText: string) => React.ReactNode;
  chipFormat?: (item: TItem) => string;
  emptyResultsMessage?: string;
  showAllOnEmpty?: boolean;
  onUserSelect?: (value: TItem) => void;
  validator?: (
    val: TItem,
    allValues: Record<string, any>
  ) => string | undefined;
};

export const TypeaheadFormField = <TItem,>({
  getData,
  format,
  label,
  helperText,
  formField,
  required,
  emptyResultsMessage,
  chipFormat,
  disabled = false,
  onUserSelect,
  validator,
}: TypeaheadProps<TItem>): React.ReactElement => {
  const validate = (val, allVals) => {
    if (val || !required) {
      return validator ? validator(val, allVals) : undefined;
    }

    return `Please provide a valid ${label}`;
  };

  const {
    input: { onBlur, onChange, onFocus, value },
    meta: { error, submitError, touched },
  } = useField(formField, { validate, allowNull: true });

  const initialState = {
    tag: "Unfocused",
    text: "",
    hasInputFocus: false,
    hasMenuMouse: false,
    emptyResultsMessage,
    onUserSelect,
  } as States.State<TItem>;

  const [state, rawDispatch] = useReducer(
    (a: States.State<TItem>, b: Actions.Action<TItem>) => reducer(a, b),
    initialState
  );
  const [searchInputText, setSearchInputText] = useDebounce<string>("", 500);
  const act = useAct();

  useEffect(() => {
    if (value === null) {
      rawDispatch({ tag: "ClearSelection" });
      onChange(null);
    } else {
      rawDispatch({ tag: "Select", selection: value });
      onChange(value);
    }
  }, [value]);

  useEffect(() => {
    setSearchInputText(state.text);
  }, [state.text]);

  useEffect(() => {
    rawDispatch({ tag: "LoadSuggestions" });
  }, [searchInputText]);

  useEffect(() => {
    if (States.isSuggestionsLoading(state)) {
      void (async () => {
        const data = await getData(searchInputText);

        act(() => rawDispatch({ tag: "SuggestionsLoaded", data: data ?? [] }));
      })();
    }
  }, [state.tag]);

  const isEmpty = States.isNotFound(state);
  const emptyMessage = isEmpty ? state.errorMessage : "";
  const errorMessage = emptyMessage || error || submitError;
  const isValid = !touched || !errorMessage;

  label += required ? " *" : "";

  return (
    <div className="typeahead">
      <TypeaheadDisplay
        {...{
          state,
          dispatch: rawDispatch,
          format,
          label,
          helperText,
          loading: state.loading,
          onBlur,
          onFocus,
          isValid,
          errorMessage,
          isEmpty,
          chipFormat,
          disabled,
          onChange,
        }}
      />
    </div>
  );
};
