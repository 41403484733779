import React from "react";
import { useField } from "react-final-form";
import { TextFieldDisplay } from "~/textfield-display";
import { FormFieldProps } from "~/forms/FormFieldProps";

export type TextFieldProps = FormFieldProps & { textarea?: boolean };

const TextFormField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const {
    formField,
    label,
    helperText,
    required = false,
    disabled = false,
    textarea = false,
    type = "text",
    autoFocus,
    className,
  } = props;

  const checkRequired = (value: string) => {
    if (value || !required || disabled) {
      return;
    }
    return `Please provide a valid ${label}`;
  };

  const {
    meta: { error, submitError, touched },
    input,
  } = useField(formField, { validate: checkRequired });

  const errorMessage = error || submitError;
  const isValid = !touched || !errorMessage;

  return (
    <TextFieldDisplay
      name={input.name}
      value={input.value}
      onChange={input.onChange}
      isValid={isValid}
      disabled={disabled}
      helperText={!isValid && errorMessage ? errorMessage : helperText}
      id={input.id}
      label={label}
      testid={`${input.name}-container`}
      required={required}
      onBlur={input.onBlur}
      textarea={textarea}
      type={type}
      autoFocus={autoFocus}
      className={className}
    />
  );
};

export default TextFormField;
