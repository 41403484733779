import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTpiByProjectChargeIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetTpiByProjectChargeIdQuery = { __typename?: 'QueryModel', thirdPartyInvoices?: { __typename?: 'ThirdPartyInvoicesQueryModel', single?: { __typename?: 'ThirdPartyInvoice', invoiceNumber: string } | null } | null };


export const GetTpiByProjectChargeIdDocument = gql`
    query GetTpiByProjectChargeId($id: Int!) {
  thirdPartyInvoices {
    single(projectChargeId: $id) {
      invoiceNumber
    }
  }
}
    `;

/**
 * __useGetTpiByProjectChargeIdQuery__
 *
 * To run a query within a React component, call `useGetTpiByProjectChargeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTpiByProjectChargeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTpiByProjectChargeIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTpiByProjectChargeIdQuery(baseOptions: Apollo.QueryHookOptions<GetTpiByProjectChargeIdQuery, GetTpiByProjectChargeIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTpiByProjectChargeIdQuery, GetTpiByProjectChargeIdQueryVariables>(GetTpiByProjectChargeIdDocument, options);
      }
export function useGetTpiByProjectChargeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTpiByProjectChargeIdQuery, GetTpiByProjectChargeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTpiByProjectChargeIdQuery, GetTpiByProjectChargeIdQueryVariables>(GetTpiByProjectChargeIdDocument, options);
        }
export type GetTpiByProjectChargeIdQueryHookResult = ReturnType<typeof useGetTpiByProjectChargeIdQuery>;
export type GetTpiByProjectChargeIdLazyQueryHookResult = ReturnType<typeof useGetTpiByProjectChargeIdLazyQuery>;
export type GetTpiByProjectChargeIdQueryResult = Apollo.QueryResult<GetTpiByProjectChargeIdQuery, GetTpiByProjectChargeIdQueryVariables>;