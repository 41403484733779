import React from "react";
import { useCancelInvoiceMutation } from "./cancelInvoice.generated";
import { Invoice, ValidationError } from "~/gql/types";
import { CancelInvoice as CancelInvoiceComponent } from "~/cancel-invoice";

type CancelInvoiceProps = {
  invoice: Invoice;
  promptFn?: () => void;
  refetch: React.DispatchWithoutAction;
};

const CancelInvoice: React.FC<CancelInvoiceProps> = ({ invoice, refetch }) => {
  const [doMutation, { loading }] = useCancelInvoiceMutation();

  if (!invoice.canCancel) {
    return null;
  }

  const cancel = async (notes: string) => {
    const result = await doMutation({
      variables: { invoiceNumber: invoice.invoiceNumber, notes },
    });

    return result?.data?.invoices?.cancel?.errors as ValidationError[];
  };

  return (
    <CancelInvoiceComponent
      {...{
        cancel,
        invoiceNumber: invoice.invoiceNumber,
        loading,
        type: "Invoice",
        reload: refetch,
      }}
    />
  );
};

export default CancelInvoice;
