import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RecallInvoiceMutationVariables = Types.Exact<{
  invoiceNumber: Types.Scalars['String'];
}>;


export type RecallInvoiceMutation = { __typename?: 'MutationModel', invoices?: { __typename?: 'InvoiceMutationModel', recall?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const RecallInvoiceDocument = gql`
    mutation RecallInvoice($invoiceNumber: String!) {
  invoices {
    recall(invoiceNumber: $invoiceNumber) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type RecallInvoiceMutationFn = Apollo.MutationFunction<RecallInvoiceMutation, RecallInvoiceMutationVariables>;

/**
 * __useRecallInvoiceMutation__
 *
 * To run a mutation, you first call `useRecallInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecallInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recallInvoiceMutation, { data, loading, error }] = useRecallInvoiceMutation({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *   },
 * });
 */
export function useRecallInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<RecallInvoiceMutation, RecallInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecallInvoiceMutation, RecallInvoiceMutationVariables>(RecallInvoiceDocument, options);
      }
export type RecallInvoiceMutationHookResult = ReturnType<typeof useRecallInvoiceMutation>;
export type RecallInvoiceMutationResult = Apollo.MutationResult<RecallInvoiceMutation>;
export type RecallInvoiceMutationOptions = Apollo.BaseMutationOptions<RecallInvoiceMutation, RecallInvoiceMutationVariables>;