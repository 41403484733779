import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import Button from "~/button";
import FormCard from "~/form-card";
import PageTitleRow from "~/page-title-row";
import Spinner from "~/spinner";
import TextFormField from "~/text-form-field";
import { TypeaheadFormField } from "~/typeahead";

type FormValues = {
  date: string;
  state: string;
};

export const SalesTaxReport: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const states = [
    "AL",
    "AK",
    "AS",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FM",
    "FL",
    "GA",
    "GU",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MH",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "MP",
    "OH",
    "OK",
    "OR",
    "PW",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VI",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];
  const downloadCsv = useReportFetcher();

  const initialValues = {
    date: null,
    state: null,
  };

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    void downloadCsv({
      setLoading,
      body: {
        date: moment(values.date, "YYYY-MM").format("YYYY-MM-DD"),
        state: values.state,
      },
      fileName: `${values.state} ${values.date}`,
      path: "sales-tax-report",
      type: "text/csv;charset=utf-8;",
    });
  };

  const getData = async (searchText: string) => {
    const lcText = searchText ? searchText.toLowerCase() : "";
    return states.filter((x) => x.toLowerCase().startsWith(lcText));
  };

  return (
    <div>
      <PageTitleRow title="Sales Tax Report" />
      <Spinner open={loading} />
      <FormCard>
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={({ date }) => {
            const errors: Record<string, any> = {};
            if (date && !date.match(/^[0-9]{4}-([1-9]|[0][1-9]|[1][0-2])$/)) {
              errors.date = "Please use format YYYY-MM";
            }
            return errors;
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextFormField
                formField="date"
                label="Year-Month"
                helperText="(YYYY-MM)"
                required
              />
              <TypeaheadFormField
                formField="state"
                label="State"
                helperText="Enter state abbreviation"
                getData={getData}
                format={(x) => x}
                required
              />
              <Button>Download Report</Button>
            </form>
          )}
        />
      </FormCard>
    </div>
  );
};
