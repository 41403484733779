import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import React from "react";
import ProjectTypePickerFormField from "~/visuals/organisms/ProjectTypePickerFormField";
import { FilterProviderProps } from "./types";
import { useServiceDescriptionsAsync } from "~/refdata2/serviceDescriptions";
import _ from "lodash";

function useProjectTypeFromParam(): (name: string) => Promise<string | null> {
  const getServiceDescriptions = useServiceDescriptionsAsync();

  return async (name: string) => {
    const result = await getServiceDescriptions();
    const unique = _.uniqBy(result, "projectType");
    const type = unique?.find((x) => x.projectType === name)?.projectType;

    return type ?? null;
  };
}

export function useProjectTypeProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText: origDocText } = props;
  const projectTypeFromParam = useProjectTypeFromParam();
  const getProjectTypes = useServiceDescriptionsAsync();

  const documentationText = `${origDocText} Use "type" for quick filtering.`;
  const type = "projectType";
  const label = "Project Type";

  return {
    render: () => (
      <ProjectTypePickerFormField
        formField={type}
        label={label}
        helperText=""
      />
    ),
    type,
    label,
    documentationText,
    getSuggestions: async (text: string) => {
      if (!text) {
        return [];
      }
      const result = await getProjectTypes();
      const unique = _.uniqBy(result, "projectType");

      if (!unique || unique.length === 0) {
        return [];
      }
      return unique
        .filter((t) => t.projectType.toLowerCase().includes(text.toLowerCase()))
        .map((t) => ({ text: t.projectType, value: t.projectType }));
    },
    shouldSuggest: (text: string) => text.toLowerCase().startsWith("type"),
    toFilter: (value: string) => ({
      label: `${label}: ${value}`,
      type,
      value: value,
    }),
    filterFromParams: async (params: URLSearchParams) => {
      const paramProjectType = params.get(type) || null;
      const projectType = paramProjectType
        ? await projectTypeFromParam(paramProjectType)
        : null;

      return projectType
        ? {
            label: `${label}: ${projectType}`,
            value: projectType,
            type,
          }
        : null;
    },
    toParam: (filter: ActiveFilter) => filter.value ?? "",
  };
}
