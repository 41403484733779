import React from "react";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import { useSnackBar } from "~/snackbar";
import TextFormField from "~/text-form-field";
import FormBottomRow from "~/form-bottom-row";
import Button from "~/button";
import { useEditProjectGroupMutation } from "./mutation.generated";
import { useReload } from "../CustomerContext";
import { ValidationResponse } from "~/gql/types";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";

type EditProjectGroupProps = {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  customerNumber: string;
  projectGroupName: string;
};

type EditProjectGroupFormValues = {
  name: string | null;
};

type EditProjectGroupFormProps = Omit<EditProjectGroupProps, "open">;

export const EditProjectGroup: React.FC<EditProjectGroupProps> = (props) => {
  const { open, setOpen } = props;
  return (
    <>
      <Button icon="edit" onClick={() => setOpen(true)} />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="edit-project-group"
      >
        <DialogTitle>Edit Project Group</DialogTitle>
        <DialogContent>
          {open && <EditProjectGroupForm {...props} />}
        </DialogContent>
      </Dialog>
    </>
  );
};

export const EditProjectGroupForm: React.FC<EditProjectGroupFormProps> = ({
  setOpen,
  customerNumber,
  projectGroupName,
}) => {
  const [doMutation] = useEditProjectGroupMutation();

  const addAlert = useSnackBar();
  const reload = useReload();

  const onSuccess = () => {
    const message = "Project Group edited";
    addAlert({
      isSuccess: true,
      message,
      key: `${Math.random()}`,
    });
    setOpen(false);
    reload();
  };
  const handleSubmit = async (values: EditProjectGroupFormValues) => {
    const variables = {
      customerNumber: customerNumber,
      oldName: projectGroupName,
      newName: values.name!,
    };
    const result = await doMutation({ variables });
    return result?.data?.projectGroups?.edit as ValidationResponse;
  };

  const initialValues = {
    name: projectGroupName,
  };
  return (
    <MutationForm
      initialValues={initialValues}
      onSuccess={onSuccess}
      runMutation={handleSubmit}
    >
      <TextFormField
        formField={"name"}
        label={"Name"}
        helperText={""}
        required
      />
      <ErrorRenderer
        render={(formError) => (
          <FormBottomRow
            errorMessage={formError}
            buttonText="Save"
            onCancel={() => setOpen(false)}
          />
        )}
      />
    </MutationForm>
  );
};
