import React from "react";
import MileageForm, {
  calculateMileage,
} from "~/visuals/organisms/Expenses/ExpenseForm/MileageTypeForm";
import { useWorkDayApi } from "~/WorkDayApi";
import { MileageExpense, MileageExpenseRate } from "~/gql/types";
import { ValueRenderer } from "~/forms/ValueContext";
import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";
import { ApiCallback } from "~/WorkDayApi/WorkDayApiType";

type NewMileageProps = BaseExpenseFormProps & {
  expenseRate: MileageExpenseRate;
  expenseEntry?: MileageExpense;
};

const NewMileageExpense: React.FC<NewMileageProps> = (props) => {
  const { project, expenseEntry, date } = props;

  const { createMileageExpense } = useWorkDayApi();

  const uniqueInitVals: UniqueInitVals = [
    {
      beginningOdometer: expenseEntry?.beginningOdometer,
      endingOdometer: expenseEntry?.endingOdometer,
      vehicleNumber: expenseEntry?.vehicleNumber,
      companyVehicle: expenseEntry?.companyVehicle ?? false,
      location: expenseEntry?.location ?? null,
    },
    {
      beginningOdometer: null,
      endingOdometer: null,
      vehicleNumber: null,
      companyVehicle: false,
      location: null,
    },
  ];

  const parseNum = (val: any) => (val ? parseFloat(val) : null);

  const createExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc,
    callback?: ApiCallback
  ) => {
    const res = await createMileageExpense(
      {
        ...defaultVals(values),
        date,
        beginningOdometer: parseNum(values?.beginningOdometer)!,
        endingOdometer: parseNum(values?.endingOdometer)!,
        vehicleNumber: values?.vehicleNumber,
        companyVehicle: values?.companyVehicle,
        state: project?.location?.state!,
        county: project?.location?.county!,
      },
      callback
    );
    return res;
  };

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        handleSubmit: createExpense,
        expenseType: "Mileage",
        uniqueInitVals,
        formType: expenseEntry ? "Clone" : "Create",
      }}
    >
      <ValueRenderer
        render={(values) => (
          <MileageForm {...props} mileage={calculateMileage(values)} />
        )}
      />
    </ExpensePageWrapper>
  );
};

export default NewMileageExpense;
