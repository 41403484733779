import React, { useEffect, useState } from "react";
import _ from "lodash";
import FullPageFilterForm from "./full-page-filter-form";
import DropdownFormField, {
  OptionProps,
} from "~/visuals/organisms/DropdownFormField";
import SingleFilterWrapper from "./SingleFilterWrapper";
import { InvoiceFilterState } from "~/gql/types";
import { NotifierForm } from "~/forms/NotifierForm";

export type FilterOptions = {
  state: InvoiceFilterState;
};

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<FilterOptions>;
};

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const [formState, setFormState] = useState<FilterOptions>();

  useEffect(() => {
    if (formState) {
      onFiltersChanged({ ...formState });
    }
  }, [formState]);

  const stateOptions: OptionProps[] = [
    { label: "Draft", value: "Draft" },
    { label: "Billed", value: "Billed" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Any", value: "Any" },
  ];

  const onChange = (values: { state: InvoiceFilterState }) => {
    const { state } = values;
    setFormState({ state });
  };

  return (
    <NotifierForm
      onChange={onChange}
      values={{ state: "Draft" as InvoiceFilterState }}
    >
      <SingleFilterWrapper>
        <FullPageFilterForm>
          <DropdownFormField
            label="Invoice State"
            formField="state"
            helperText=""
            options={stateOptions}
          />
        </FullPageFilterForm>
      </SingleFilterWrapper>
    </NotifierForm>
  );
};
