import { useSnackBar } from "~/snackbar";
import { Employee, ValidationError } from "~/gql/types";
import { useResetSearchItems } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import { useAddEmployeeRoleMutation } from "./mutations.generated";

type UseAddRoleReturn = {
  addRole: (employee: Employee) => Promise<void>;
  loading: boolean;
};

export function useAddRole(roleName: string): UseAddRoleReturn {
  const addAlert = useSnackBar();
  const [add, { loading }] = useAddEmployeeRoleMutation();
  const reset = useResetSearchItems();

  const addRole = async (employee: Employee) => {
    const variables = {
      role: roleName,
      userPrincipalName: employee.userPrincipalName,
    };
    const response = await add({ variables });

    const errors = (response?.errors ??
      response?.data?.employees?.addRole?.errors ??
      []) as ValidationError[];

    if (errors.length > 0) {
      addAlert({
        isSuccess: false,
        message: errors.map((x) => x.message).join(" "),
        key: `${Math.random()}`,
      });
    } else {
      addAlert({
        isSuccess: true,
        message: `Role ${roleName} added to ${employee.userPrincipalName}`,
        key: `${Math.random()}`,
      });

      reset();
    }
  };

  return { addRole, loading };
}
