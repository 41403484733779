import React from "react";
import { Dialog, DialogTitle } from "~/dialog";
import {
  EditedCrewCode,
  UpdateCrewCodeAction,
  UpdateCrewCodeFormValues,
} from "./types";
import TextFormField from "~/text-form-field";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import FormBottomRow from "~/form-bottom-row";
import { useSnackBar } from "~/snackbar";
import { CrewCodeRecord } from "~/gql/types";

type CrewCodeUpdateDialogProps = {
  crewCodes: CrewCodeRecord[];
  editedCrewCode: EditedCrewCode | null;
  setEditedCrewCode: React.Dispatch<
    React.SetStateAction<EditedCrewCode | null>
  >;
  refetch: () => void;
  runMutation: UpdateCrewCodeAction;
};

export const CrewCodeUpdateDialog: React.FC<CrewCodeUpdateDialogProps> = (
  props
) => {
  const { crewCodes, editedCrewCode, setEditedCrewCode, refetch, runMutation } =
    props;

  const addAlert = useSnackBar();

  const crewCode = crewCodes.find(
    (x) => x.crewCode === editedCrewCode?.crewCode
  );

  const initialValues: UpdateCrewCodeFormValues = {
    crewCode: crewCode ?? null,
    overrideEmployeeId: editedCrewCode?.overrideEmployeeId ?? null,
  };

  const onSuccess = async ({ values }) => {
    addAlert({
      message: `${values.crewCode.crewCode} updated`,
      isSuccess: true,
      key: `${Math.random()}`,
    });

    void refetch();

    setEditedCrewCode(null);
  };

  const dialogOpen = !!editedCrewCode && !editedCrewCode.dialogClosing;

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setEditedCrewCode(null)}
        data-open={dialogOpen}
        className="update-crewcode-dialog"
      >
        <DialogTitle>
          Update Crew Code {editedCrewCode?.crewCode ?? ""}
        </DialogTitle>
        <MutationForm
          initialValues={initialValues}
          runMutation={runMutation}
          onSuccess={onSuccess}
          allowPristineSubmit
        >
          <TextFormField
            formField="overrideEmployeeId"
            label="Override Employee Id"
            helperText=""
            className="override-empid-text-field"
          />

          <ErrorRenderer
            render={(error) => (
              <FormBottomRow
                buttonText="Save"
                onCancel={() =>
                  setEditedCrewCode((x: EditedCrewCode | null) => ({
                    ...x!,
                    dialogClosing: true,
                  }))
                }
                errorMessage={error}
              />
            )}
          />
        </MutationForm>
      </Dialog>
    </>
  );
};
