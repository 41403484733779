import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddLineItemMutationVariables = Types.Exact<{
  customRollupInvoiceNumber: Types.Scalars['String'];
  invoiceNumber: Types.Scalars['String'];
}>;


export type AddLineItemMutation = { __typename?: 'MutationModel', customRollupInvoices?: { __typename?: 'CustomRollupInvoiceMutationModel', addLineItem?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const AddLineItemDocument = gql`
    mutation AddLineItem($customRollupInvoiceNumber: String!, $invoiceNumber: String!) {
  customRollupInvoices {
    addLineItem(
      customRollupInvoiceNumber: $customRollupInvoiceNumber
      invoiceNumber: $invoiceNumber
    ) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type AddLineItemMutationFn = Apollo.MutationFunction<AddLineItemMutation, AddLineItemMutationVariables>;

/**
 * __useAddLineItemMutation__
 *
 * To run a mutation, you first call `useAddLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLineItemMutation, { data, loading, error }] = useAddLineItemMutation({
 *   variables: {
 *      customRollupInvoiceNumber: // value for 'customRollupInvoiceNumber'
 *      invoiceNumber: // value for 'invoiceNumber'
 *   },
 * });
 */
export function useAddLineItemMutation(baseOptions?: Apollo.MutationHookOptions<AddLineItemMutation, AddLineItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLineItemMutation, AddLineItemMutationVariables>(AddLineItemDocument, options);
      }
export type AddLineItemMutationHookResult = ReturnType<typeof useAddLineItemMutation>;
export type AddLineItemMutationResult = Apollo.MutationResult<AddLineItemMutation>;
export type AddLineItemMutationOptions = Apollo.BaseMutationOptions<AddLineItemMutation, AddLineItemMutationVariables>;