import React, { useEffect, useMemo } from "react";
import {
  delayedReauthState,
  pkceVerifierKey,
  popupReauthState,
  silentReauthState,
} from "../authClient";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  dispatchLogout,
  loginChannelName,
  logoutChannelName,
  AuthenticationManagerInnerProps,
} from "./common";
import { tokenStorageKey } from "./common";
import { adAuthCallback, googleAuthCallback } from "~/routes";
import { createBroadcastChannel } from "~/broadcast-channel/createBroadcastChannel";
import { Action, originatorKey } from "./stateMachine";
import { GetAuthorizationsAsync } from "../types";

function handleToken(usr, dispatch, searchParams) {
  const path = searchParams.get("state") ?? "/";
  const tokenDelay = path === delayedReauthState ? 60000 : 0;

  setTimeout(() => {
    dispatch({
      tag: "Token",
      token: usr.data.id_token,
    });
  }, tokenDelay);
}

function handleCallback(auth, dispatch, location, searchParams) {
  const search = location.search.replace(/^\?/, "");

  auth.code
    .getToken(window.location.origin + location.pathname + `?${search}`, {
      body: {
        code_verifier: sessionStorage.getItem(pkceVerifierKey)!,
      },
    })
    .then((usr) => handleToken(usr, dispatch, searchParams))
    .catch((x) => {
      console.error(x);
      dispatch({ tag: "Problem" });
    });
}

function handleLocalValidation(token, dispatch, getAuthorizations) {
  getAuthorizations(token)
    .then((authorizations) => {
      if (authorizations === null) {
        dispatchLogout(dispatch);
        return;
      }
      dispatch({ tag: "Authorized", authorizations });
    })
    .catch((x) => {
      console.error(x);
      dispatch({ tag: "Problem" });
    });
}

type AuthenticationManagerReactorProps = AuthenticationManagerInnerProps & {
  getAuthorizations: GetAuthorizationsAsync;
};

function shouldForceRedirect(location: Location) {
  return (
    location.pathname === googleAuthCallback.path ||
    location.pathname === adAuthCallback.path
  );
}

export const AuthenticationManagerReactor: React.FC<
  AuthenticationManagerReactorProps
> = ({
  state,
  dispatch,
  createAzureAuth,
  createGoogleAuth,
  getAuthorizations,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const loginChannel = useMemo(
    () => createBroadcastChannel(loginChannelName),
    []
  );
  const logoutChannel = useMemo(
    () => createBroadcastChannel(logoutChannelName),
    []
  );

  useEffect(() => {
    loginChannel.onmessage = (msg) => {
      const payload = msg.data || msg;

      if (payload.originator === originatorKey) {
        return;
      }

      dispatch(payload as Action);
    };
    logoutChannel.onmessage = (msg) => {
      const payload = msg.data || msg;

      dispatch(payload as Action);
    };

    return () => {
      loginChannel.close();
      logoutChannel.close();
    };
  }, [loginChannel, logoutChannel]);

  useEffect(() => {
    if (state.tag === "Unauthenticated") {
      localStorage.removeItem(tokenStorageKey);
    }

    if (state.tag === "ValidatingGoogle") {
      const auth = createGoogleAuth();
      handleCallback(auth, dispatch, location, searchParams);
    }

    if (state.tag === "ValidatingAzure") {
      const auth = createAzureAuth();
      handleCallback(auth, dispatch, location, searchParams);
    }

    if (state.tag === "ValidatingLocal") {
      handleLocalValidation(state.token, dispatch, getAuthorizations);
    }

    if (state.tag === "Authenticated") {
      localStorage.setItem(tokenStorageKey, state.token);
      if (shouldForceRedirect(location)) {
        const path = searchParams.get("state") ?? "/";
        if (
          path === popupReauthState ||
          path === silentReauthState ||
          path === delayedReauthState
        ) {
          window.close();
        } else {
          navigate(path);
        }
      }

      if (originatorKey === state.originator) {
        const message: Action = {
          tag: "OtherWindowLoggedIn",
          token: state.token,
          authorizations: state.authorizations,
          originator: originatorKey,
        };
        loginChannel.postMessage(message);
      }
    }
  }, [state]);
  return null;
};
