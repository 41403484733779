import Menu, {
  MenuList,
  MenuListItem,
  MenuListItemText,
  MenuListItemGraphic,
} from "@material/react-menu";
import MaterialIcon from "@material/react-material-icon";
import React from "react";
import { useUser } from "~/Auth/useUser";
import { useNavigate } from "react-router-dom";
import "./TopBarActionMenu.scss";

type TopBarActionMenuProps = {
  open: boolean;
  coordinates: {
    x: number;
    y: number;
  };
};

const TopBarActionMenu: React.FC<TopBarActionMenuProps> = ({
  open,
  coordinates,
}) => {
  const user = useUser();

  const navigate = useNavigate();
  const returnToNormal = () => {
    user.stopImpersonating();
    navigate("/");
  };

  return (
    <Menu
      open={open}
      coordinates={coordinates}
      className="top-nav-action-item-list"
    >
      <MenuList>
        <MenuListItem onClick={returnToNormal}>
          <MenuListItemGraphic graphic={<MaterialIcon icon="warning" />} />
          <MenuListItemText
            primaryText={`Impersonating: ${user.name} (click to stop)`}
          />
        </MenuListItem>
      </MenuList>
    </Menu>
  );
};

export default TopBarActionMenu;
