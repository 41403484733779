import React from "react";
import moment from "moment";
import { Project } from "~/gql/types";
export type { Project } from "~/gql/types";

export type Page =
  | "project-info"
  | "rate-sheets"
  | "time"
  | "charges"
  | "invoices";

export type ProjectDetailsProps = {
  project: Project;
  page?: Page;
  TimeComponent?: React.FC<{ project: Project }>;
};

export const DateDisplay: React.FC<{ date: string | null }> = ({ date }) => {
  if (!date) {
    return null;
  }

  const m = moment(date);

  return (
    <>
      {m.format("MMM D, YYYY")}&nbsp; ({m.fromNow()})
    </>
  );
};
