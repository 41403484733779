import React, { useEffect } from "react";
import { ProjectCharge } from "~/gql/types";
import { State } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { useUpdateSearchParam } from "~/useUpdateSearchParam";
import { ProjectChargesFilter } from "./types";

type ProjectCountReactorProps = {
  state: State<ProjectCharge, ProjectChargesFilter>;
  _setSearchParams?: (args: Record<string, any>) => void;
};

export const ProjectCountReactor: React.FC<ProjectCountReactorProps> = ({
  state,
  _setSearchParams,
}) => {
  const updateParam = useUpdateSearchParam();

  useEffect(() => {
    if (state.tag === "Loaded" || state.tag === "EndOfData") {
      updateParam({
        param: "projectCount",
        value: `${new Set(state.items.map((x) => x.projectId)).size}`,
        _setSearchParams,
      });
    }
  }, [state.tag]);

  return <></>;
};
