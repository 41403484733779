import React from "react";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import { UltraFilter } from "~/ultra-filter";
import { useProjectProvider } from "~/filterProviders/useProjectProvider";
import { CustomersFilter } from "./types";

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<CustomersFilter>;
};

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const providers = [
    useProjectProvider({
      documentationText: "A project assigned to the customer.",
    }),
  ] as FilterProvider[];

  const onChanged = (filters: ActiveFilter[], searchText: string) => {
    searchText;

    const newFilters = {
      project: filters.find((f) => f.type === "project")?.value || null,
      searchText: searchText || null,
    };

    onFiltersChanged(newFilters);
  };

  return (
    <div className="filter-form">
      <UltraFilter
        {...{
          providers,
          dialogTitle: "Customers Search",
          label: "Type to search customers",
          onFiltersChanged: onChanged,
        }}
      />
    </div>
  );
};
