import React from "react";
import { Body1 } from "@material/react-typography";
import Button from "~/button";
import MaterialIcon from "@material/react-material-icon";
import TitledCard from "~/titled-card";
import "./EmployeeCard.scss";
import classnames from "classnames";
import { TextHighlighter } from "~/text-highlighter";
import { useUser } from "~/Auth/useUser";
import { reviewWorkDataRoute } from "~/WorkData/routes";
import { EmployeeRoles } from "./EmployeeRoles";
import { EmployeeFinalPayroll } from "~/visuals/organisms/EmployeeFinalPayroll";
import { useResetSearchItems } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import { Route } from "~/routeTypes";
import { Employee } from "~/gql/types";
import EmployeeModel from "~/graphql/Employee";
import { EmployeeFieldOffice } from "./EmployeeFieldOffice";

const PersonDetailCell: React.FC = ({ children }) => {
  return <div className="person-detail-cell">{children}</div>;
};

type EmployeeCardProps = {
  employee: Employee;
};

export const EmployeeCard: React.FC<EmployeeCardProps> = ({ employee }) => {
  const {
    email,
    firstName,
    lastName,
    middleInitial,
    workLocation,
    timeEntryMethod,
    isMobileEmployee,
    isTerminated,
    userPrincipalName,
    ultiProId,
  } = employee;
  const middle =
    middleInitial && middleInitial.length ? ` ${middleInitial}. ` : " ";

  const user = useUser();

  const cn = classnames("employee-card", { terminated: isTerminated });

  const Highlight = ({ text }) => <TextHighlighter {...{ text }} />;

  const reset = useResetSearchItems();

  return (
    <TitledCard
      title={<Highlight text={`${firstName}${middle}${lastName}`} />}
      subtitle={<Highlight text={`${email} (${ultiProId})`} />}
      className={cn}
      actions={
        <>
          {user.authorizations.updateRoles && (
            <EmployeeRoles employee={employee as Employee} />
          )}
          {user.authorizations.reviewWorkData && (
            <Button
              icon="date_range"
              route={new Route(reviewWorkDataRoute.toRoute(userPrincipalName))}
            >
              Review Work Data
            </Button>
          )}
          {user.authorizations.impersonate && (
            <Button
              icon="supervisor_account"
              onClick={() => user.impersonate(employee as EmployeeModel)}
            >
              Impersonate
            </Button>
          )}
          {user.authorizations.updateFinalPayroll && (
            <EmployeeFinalPayroll
              {...{ employee: employee as Employee, reset }}
            />
          )}
        </>
      }
    >
      <div className="card-content">
        <PersonDetailCell>
          <Body1 title="Is Active">
            <MaterialIcon icon={isTerminated ? "close" : "done"} />
            {isTerminated ? "Terminated" : "Active"}
          </Body1>
        </PersonDetailCell>
        <PersonDetailCell>
          <Body1 title="Work Location">
            <MaterialIcon icon="place" />
            {workLocation}
          </Body1>
        </PersonDetailCell>
        <PersonDetailCell>
          <Body1 title="Time Entry Method">
            <MaterialIcon icon="pending_actions" />
            {timeEntryMethod}
          </Body1>
        </PersonDetailCell>
        <PersonDetailCell>
          <Body1 title="Mobile Employee">
            <MaterialIcon
              icon={isMobileEmployee ? "directions_car" : "apartment"}
            />
            {isMobileEmployee ? "Mobile" : "Not mobile"}
          </Body1>
        </PersonDetailCell>
        <PersonDetailCell>
          <Body1 title="Office Code">
            <EmployeeFieldOffice
              {...{ employee: employee as Employee, reset }}
            />
          </Body1>
        </PersonDetailCell>
      </div>
    </TitledCard>
  );
};
