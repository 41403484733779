import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import "./MruPicker.scss";
export type MruPickerProps<T> = FormFieldProps & {
  format: (
    item: T & { recent?: boolean },
    searchText: string
  ) => React.ReactNode;

  fetchData: (
    updateItems: (result: T[]) => T[]
  ) => (searchText: string) => Promise<T[]>;

  className: string;

  fieldsEqual: (storedItem: T, dataItem: T) => boolean;

  chipFormat: (item: T) => string;

  storageKey: string;

  keepInCache?: (storedItem: T) => boolean;
};

const MAX_RECENT = 5;

export function getRecentItems<T>(storageKey: string): T[] {
  const storedItems = window.localStorage.getItem(storageKey) ?? "[]";
  let convertedItems: T[] = [];

  try {
    convertedItems = JSON.parse(storedItems);
  } catch {
    convertedItems = [];
  }

  return (
    Array.isArray(convertedItems) ? convertedItems : [convertedItems]
  ).filter((x) => x);
}

export const MruPicker = <T,>(props: MruPickerProps<T>): React.ReactElement => {
  const {
    format,
    fetchData,
    className,
    fieldsEqual,
    chipFormat,
    storageKey,
    keepInCache,
  } = props;

  const setRecentItems = (items: string) =>
    window.localStorage.setItem(storageKey, items);

  const updateRecentItems = (result: T[]) => {
    const recentStorage = getRecentItems<T>(storageKey);

    const recentItems = recentStorage.map((item) =>
      result.find(
        (x) => fieldsEqual(x, item) || (keepInCache && keepInCache(x))
      )
    );

    const itemsToKeep = keepInCache ? recentStorage.filter(keepInCache) : [];

    const converted = [...recentItems, ...itemsToKeep]
      .filter((x) => x)
      .map((x) => ({ ...x, recent: true })) as T[];

    setRecentItems(JSON.stringify(converted));

    return converted;
  };

  const onUserSelect = (item: T) => {
    const recent = getRecentItems<T>(storageKey);

    const newItems = [
      { ...item, recent: true },
      ...recent
        .filter((x) => !fieldsEqual(x, item) || (keepInCache && keepInCache(x)))
        .slice(0, MAX_RECENT - 1),
    ].filter((x) => x);

    setRecentItems(JSON.stringify(newItems));
  };

  const getData = fetchData(updateRecentItems);

  const args = {
    ...props,
    getData,
    format,
    chipFormat,
    onUserSelect,
  } as TypeaheadProps<T>;

  return (
    <div className={"mru-picker " + className}>
      <TypeaheadFormField {...args} />
    </div>
  );
};
