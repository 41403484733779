import { ValidationError, ValidationResponse } from "~/gql/types";

export const toErrorMessage = (result: ValidationResponse): string | null => {
  const errors = (result?.errors ?? []) as ValidationError[];

  if (errors.length === 0) {
    return null;
  }

  const messages: string[] = [];
  errors.forEach(({ argumentName, message }) => {
    if (argumentName) {
      messages.push(`${argumentName}: ${message}`);
    } else if (message) {
      messages.push(message);
    }
  });

  return messages.join(" ");
};
