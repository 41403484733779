import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { InvoiceFilterState } from "~/gql/types";
import { Suggestion } from "~/ultra-filter/types";

export const invoiceStates = [
  { value: "Any", text: "Any" },
  { value: "Draft", text: "Draft" },
  { value: "Billed", text: "Billed" },
  { value: "Cancelled", text: "Cancelled" },
] as Suggestion[];

const format = (item: InvoiceFilterState) =>
  invoiceStates.find((s) => s.value === item)?.text;

export const InvoiceStatePicker: React.FC<FormFieldProps> = (props) => {
  const getData = async (searchText: string) => {
    if (!searchText) {
      return invoiceStates.map((s) => s.value);
    }
    return invoiceStates
      .filter(
        (s) =>
          s.value.toLowerCase().startsWith(searchText.toLowerCase()) ||
          s.text.toLowerCase().startsWith(searchText.toLowerCase())
      )
      .map((s) => s.value);
  };

  const args = {
    ...props,
    getData,
    format,
  } as TypeaheadProps<InvoiceFilterState>;

  return <TypeaheadFormField {...args} />;
};
