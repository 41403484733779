import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkEffectiveRateSheetsQueryVariables = Types.Exact<{
  projectNumber: Types.Scalars['Int'];
  dates: Array<Types.Scalars['LocalDate']> | Types.Scalars['LocalDate'];
}>;


export type BulkEffectiveRateSheetsQuery = { __typename?: 'QueryModel', projects?: { __typename?: 'ProjectsQueryModel', bulkEffectiveRateSheets?: Array<{ __typename?: 'EffectiveRateSheetsAssignment', projectNumber: number, asOf: string, taskRateSheet?: { __typename?: 'TaskRateSheet', name: string, rates?: Array<{ __typename?: 'TaskRate', name: string, rateType: Types.TaskRateType, crewSize: number } | null> | null } | null, expenseRateSheet?: { __typename?: 'ExpenseRateSheet', name: string, allowAssignment: boolean, expenseRates?: Array<{ __typename?: 'AdHocExpenseRate', name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | { __typename?: 'AtvExpenseRate', billableAmount: number, reimbursableAmount: number, name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | { __typename?: 'FuelExpenseRate', name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | { __typename?: 'MileageExpenseRate', billableAmountPerMile: number, reimbursableAmountPerMile: number, name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | { __typename?: 'MobileAllowanceExpenseRate', billableAmount: number, reimbursableAmount: number, name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | { __typename?: 'PerDiemExpenseRate', kind: Types.PerDiemKind, billableOverride?: number | null, name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | { __typename?: 'ReceiptValuedExpenseRate', requiresLocation: boolean, category: Types.ReceiptValuedCategory, name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | { __typename?: 'TcpAllowanceExpenseRate', reimbursableAmount: number, name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | null> | null } | null } | null> | null } | null };


export const BulkEffectiveRateSheetsDocument = gql`
    query BulkEffectiveRateSheets($projectNumber: Int!, $dates: [LocalDate!]!) {
  projects {
    bulkEffectiveRateSheets(projectNumber: $projectNumber, dates: $dates) {
      projectNumber
      asOf
      taskRateSheet {
        name
        rates {
          name
          rateType
          crewSize
        }
      }
      expenseRateSheet {
        name
        allowAssignment
        expenseRates {
          name
          invoiceLabel
          expenseAccount
          ... on PerDiemExpenseRate {
            kind
            billableOverride
          }
          ... on ReceiptValuedExpenseRate {
            requiresLocation
            category
          }
          ... on MobileAllowanceExpenseRate {
            billableAmount
            reimbursableAmount
          }
          ... on TcpAllowanceExpenseRate {
            reimbursableAmount
          }
          ... on MileageExpenseRate {
            billableAmountPerMile
            reimbursableAmountPerMile
          }
          ... on AtvExpenseRate {
            billableAmount
            reimbursableAmount
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBulkEffectiveRateSheetsQuery__
 *
 * To run a query within a React component, call `useBulkEffectiveRateSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkEffectiveRateSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkEffectiveRateSheetsQuery({
 *   variables: {
 *      projectNumber: // value for 'projectNumber'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useBulkEffectiveRateSheetsQuery(baseOptions: Apollo.QueryHookOptions<BulkEffectiveRateSheetsQuery, BulkEffectiveRateSheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkEffectiveRateSheetsQuery, BulkEffectiveRateSheetsQueryVariables>(BulkEffectiveRateSheetsDocument, options);
      }
export function useBulkEffectiveRateSheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkEffectiveRateSheetsQuery, BulkEffectiveRateSheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkEffectiveRateSheetsQuery, BulkEffectiveRateSheetsQueryVariables>(BulkEffectiveRateSheetsDocument, options);
        }
export type BulkEffectiveRateSheetsQueryHookResult = ReturnType<typeof useBulkEffectiveRateSheetsQuery>;
export type BulkEffectiveRateSheetsLazyQueryHookResult = ReturnType<typeof useBulkEffectiveRateSheetsLazyQuery>;
export type BulkEffectiveRateSheetsQueryResult = Apollo.QueryResult<BulkEffectiveRateSheetsQuery, BulkEffectiveRateSheetsQueryVariables>;