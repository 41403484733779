import React from "react";
import NoAuthTopoCard from "~/components/common/NoAuthTopoCard";
import Button from "~/button";
import { Body1 } from "@material/react-typography";

type NoAuthProps = {
  problem?: boolean;
  doGoogleRedirect: () => void;
  doAzureRedirect: () => void;
};

export const UnauthenticatedMessage =
  "This system is the property of Topographic Land Surveyors. " +
  "Unauthorized access is prohibited.";

export const ProblemMessage =
  "There was a problem with your login information.";

export const MaintenanceMessage =
  "The system is under maintenance and currently unavailable.";

export const Unauthenticated: React.FC<NoAuthProps> = ({
  problem,
  doGoogleRedirect,
  doAzureRedirect,
}) => {
  const isMaintenance = window.config.featureFlags.maintenance;

  return (
    <NoAuthTopoCard>
      <Body1>{UnauthenticatedMessage}</Body1>
      {!isMaintenance && (
        <>
          <Body1>
            <Button onClick={() => doAzureRedirect()} primary>
              Log in with Topographic Credentials
            </Button>
          </Body1>
          <Body1>
            <Button onClick={() => doGoogleRedirect()}>
              Administrative Log in
            </Button>
          </Body1>
        </>
      )}

      {!isMaintenance && problem && (
        <Body1 style={{ color: "#cf0000" }}>
          There was a problem with your login information.
        </Body1>
      )}
      {isMaintenance && (
        <Body1 style={{ color: "#cf0000" }}>{MaintenanceMessage}</Body1>
      )}
    </NoAuthTopoCard>
  );
};
