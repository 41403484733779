import { useEffect, useRef } from "react";

export default function useIsMounted(): () => boolean {
  const ref = useRef(true);

  useEffect(() => {
    return () => {
      ref.current = false;
    };
  }, []);

  return () => ref.current;
}
