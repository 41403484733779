import React from "react";
import MdcTextField, { Input } from "@material/react-text-field";
import HelperTextLine from "~/HelperTextLine";
import "./TextField.scss";

export type TextFieldProps = {
  label: string;
  name: string;
  value: string;
  helperText: string;
  disabled?: boolean;
  onChange: (string) => void;
  required?: boolean;
  testid?: string;
  isValid?: boolean;
  id: string;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  textarea?: boolean;
  type?: string;
  autoFocus?: boolean;
  className?: string;
};

export const TextFieldDisplay: React.FC<TextFieldProps> = (
  props: TextFieldProps
) => {
  const {
    label,
    id,
    name,
    value,
    helperText,
    disabled,
    onChange,
    required,
    isValid = true,
    testid,
    onBlur,
    textarea,
    type,
    autoFocus,
    className,
  } = props;

  return (
    <section className={"form-field"} data-testid={testid}>
      <MdcTextField
        label={label + `${required ? " *" : ""}`}
        helperText={<HelperTextLine message={helperText} isValid={isValid} />}
        onBlur={(event) => (onBlur ? onBlur(event) : undefined)}
        textarea={textarea}
      >
        <Input
          name={name}
          id={id}
          value={value}
          isValid={isValid}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            onChange(evt.target.value)
          }
          aria-label={label}
          autoComplete="new-password"
          disabled={disabled}
          type={type}
          autoFocus={autoFocus}
          className={className}
        />
      </MdcTextField>
    </section>
  );
};
