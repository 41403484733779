import { useApolloClient } from "@apollo/client";
import React, { useEffect } from "react";
import { GetSingleChargeDocument } from "~/charges-table/singleChargeQuery.generated";
import { ProjectCharge } from "~/gql/types";

type SingleChargeLoaderProps = {
  dependency: boolean;
  callback: (charge: ProjectCharge) => void;
  id: number;
};

export const SingleChargeLoader: React.FC<SingleChargeLoaderProps> = ({
  dependency,
  callback,
  id,
}) => {
  const client = useApolloClient();

  useEffect(() => {
    if (!dependency) {
      return;
    }

    void (async () => {
      const result = await client.query({
        query: GetSingleChargeDocument,
        variables: { id },
      });
      const updatedCharge = result?.data?.projectCharges
        ?.single as ProjectCharge;
      callback(updatedCharge);
    })();
  }, [dependency]);
  return <></>;
};
