import React from "react";
import { useCreateTcpAllowanceExpenseRateMutation } from "./queries.generated";
import { MutationForm } from "~/forms/MutationForm";
import { CreateExpenseRateFormData, CreateExpenseRateFormProps } from "./types";
import { parseNum } from ".";
import { ValidationResponse } from "~/gql/types";

export const NewTcpAllowanceExpenseRate: React.FC<
  CreateExpenseRateFormProps
> = (props) => {
  const { setLoading, onSuccess, initialValues, rateSheetName, children } =
    props;

  const [rawTcpMutation] = useCreateTcpAllowanceExpenseRateMutation();

  const handleSubmit = async (values: CreateExpenseRateFormData) => {
    const variables = {
      name: values?.name!,
      invoiceLabel: values?.invoiceLabel || null,
      expenseAccount: values?.expenseAccount || null,
      glAccount: values?.glAccount || null,
      rateSheetName: rateSheetName,
      reimbursableAmount: parseNum(values?.reimbursableAmount)!,
    };

    const result = await rawTcpMutation({ variables });

    return result?.data?.expenseRates
      ?.addTcpAllowanceRate as ValidationResponse;
  };

  return (
    <MutationForm
      setLoading={setLoading}
      initialValues={initialValues}
      runMutation={handleSubmit}
      onSuccess={onSuccess}
      allowPristineSubmit
    >
      {children}
    </MutationForm>
  );
};
