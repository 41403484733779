import React, { useState } from "react";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { FilterOptions } from "./types";
import { useApolloClient } from "@apollo/client";

import { AllFilteredInvoicesQueryDocument } from "./allInvoices.generated";
import { useInvoiceSearchStats } from "./InvoiceSearchStatsProvider";
import { LoadAllButton } from "~/load-all-button";
import { Invoice } from "~/gql/types";
import { formattedDate } from "./utils";

type LoadAllChargesProps = {
  state: State<Invoice, FilterOptions>;
  dispatch: React.Dispatch<Action<Invoice, FilterOptions>>;
};

export const LoadAllInvoices: React.FC<LoadAllChargesProps> = ({
  state,
  dispatch,
}) => {
  const { stats } = useInvoiceSearchStats();
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const {
    searchText,
    billingAdmin,
    project,
    projectManager,
    projectSupervisor,
    customer,
    state: invoiceState,
    actualDate,
    beforeDate,
    afterDate,
    actualCancelledDate,
    beforeCancelledDate,
    afterCancelledDate,
    amount,
    officeCode,
    locationState,
    afeWoPo,
    sortBy,
    projectGroup,
  } = state.filterOptions;

  const allSelected =
    state.selectedItems.length === state.items.length && state.items.length > 0;

  const loadAll = async () => {
    setLoading(true);

    try {
      const variables = {
        searchText: searchText || null,
        state: invoiceState || null,
        billingAdmin: billingAdmin?.userPrincipalName || null,
        projectManager: projectManager?.userPrincipalName || null,
        projectSupervisor: projectSupervisor?.userPrincipalName || null,
        customerNumber: customer?.number || null,
        projectNumber: project?.number || null,
        startDate: formattedDate(afterDate),
        endDate: formattedDate(beforeDate),
        actualDate: formattedDate(actualDate),
        startCancelledDate: formattedDate(afterCancelledDate),
        endCancelledDate: formattedDate(beforeCancelledDate),
        actualCancelledDate: formattedDate(actualCancelledDate),
        amount: amount ? parseFloat(amount) : null,
        officeCode: officeCode?.officeCode || null,
        locationState: locationState || null,
        afeWoPo: afeWoPo || null,
        sortBy,
        groupName: projectGroup?.name || null,
        groupCustomerNumber: projectGroup?.customer?.number || null,
      };

      const result = await client.query({
        query: AllFilteredInvoicesQueryDocument,
        variables,
      });

      const allInvoices = result?.data?.invoices?.allFilteredInvoices ?? [];

      dispatch({ tag: "SelectAllQueriedItems", items: allInvoices });
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadAllButton
      {...{
        allSelected,
        loadAll,
        selectedCount: state.selectedItems.length,
        totalCount: stats?.invoiceCount ?? 0,
        loading,
        type: "invoices",
      }}
    />
  );
};
