import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMyPendingProjectRequestsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMyPendingProjectRequestsQuery = { __typename?: 'QueryModel', projectRequests?: { __typename?: 'ProjectRequestQueryModel', myPendingRequests?: Array<{ __typename?: 'MyPendingRequests', officeCode: string, requests: number, maxAge: any } | null> | null } | null };


export const GetMyPendingProjectRequestsDocument = gql`
    query GetMyPendingProjectRequests {
  projectRequests {
    myPendingRequests {
      officeCode
      requests
      maxAge
    }
  }
}
    `;

/**
 * __useGetMyPendingProjectRequestsQuery__
 *
 * To run a query within a React component, call `useGetMyPendingProjectRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPendingProjectRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPendingProjectRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPendingProjectRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyPendingProjectRequestsQuery, GetMyPendingProjectRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyPendingProjectRequestsQuery, GetMyPendingProjectRequestsQueryVariables>(GetMyPendingProjectRequestsDocument, options);
      }
export function useGetMyPendingProjectRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyPendingProjectRequestsQuery, GetMyPendingProjectRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyPendingProjectRequestsQuery, GetMyPendingProjectRequestsQueryVariables>(GetMyPendingProjectRequestsDocument, options);
        }
export type GetMyPendingProjectRequestsQueryHookResult = ReturnType<typeof useGetMyPendingProjectRequestsQuery>;
export type GetMyPendingProjectRequestsLazyQueryHookResult = ReturnType<typeof useGetMyPendingProjectRequestsLazyQuery>;
export type GetMyPendingProjectRequestsQueryResult = Apollo.QueryResult<GetMyPendingProjectRequestsQuery, GetMyPendingProjectRequestsQueryVariables>;