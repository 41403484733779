import React, { createContext, ReactElement, useContext } from "react";
import PendingCustomersView from "~/visuals/pages/PendingCustomersRouter/PendingCustomers";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { Route, Routes } from "react-router-dom";
import { ApproveOrRejectRouter } from "~/visuals/pages/PendingCustomersRouter/ApproveOrRejectRouter";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import { PendingCustomer } from "~/gql/types";
import { usePendingCustomersQuery } from "./query.generated";

type RouteProps = {
  customers: PendingCustomer[];
};

const Loading = () => (
  <Grid>
    <Row>
      <Cell columns={12}>loading...</Cell>
    </Row>
  </Grid>
);

const useReload = (): React.DispatchWithoutAction => {
  const api = useContext(PendingCustomersContext);

  if (!api) {
    throw new Error(
      "Do not use PendingCustomersRouter.useReload outside of a PendingCustomersRouter."
    );
  }

  return api.reload;
};

const PageRoutes: React.FC<RouteProps> = ({ customers }) => {
  return (
    <Routes>
      <Route path="/approve">
        <Route
          path={":createCustomerRequestId"}
          element={<ApproveOrRejectRouter {...{ useReload, customers }} />}
        />
      </Route>
      <Route path="/reject">
        <Route
          path={":createCustomerRequestId"}
          element={<ApproveOrRejectRouter {...{ useReload, customers }} />}
        />
      </Route>
      <Route
        path={"*"}
        element={<PendingCustomersView customers={customers} />}
      />
    </Routes>
  );
};

type PendingCustomersContextApi = {
  reload: () => void;
};

const PendingCustomersContext =
  createContext<PendingCustomersContextApi | null>(null);

export default function PendingCustomers(): ReactElement {
  const { data, loading, refetch } = usePendingCustomersQuery();

  useBreadcrumbs([{ text: "Pending Customers" }], []);

  const customers = (data && data?.customers?.pendingCustomers) ?? [];

  if (data && customers && !loading) {
    const api = {
      reload: refetch,
    };

    return (
      <PendingCustomersContext.Provider value={api}>
        <PageRoutes customers={customers as PendingCustomer[]} />
      </PendingCustomersContext.Provider>
    );
  }

  return <Loading />;
}
