// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BSUHBcMFLJfqPKa-bgqF9Q\\=\\=>div{display:flex}.BSUHBcMFLJfqPKa-bgqF9Q\\=\\=>div button{height:unset;font-size:13px;margin-left:18px}", "",{"version":3,"sources":["webpack://src/search-page-wrapper/BulkCheckboxes/SelectAllCheckbox.module.scss"],"names":[],"mappings":"AACE,gCACE,YAAA,CAEA,uCACE,YAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".SelectAllCheckbox {\n  & > div {\n    display: flex;\n\n    button {\n      height: unset;\n      font-size: 13px;\n      margin-left: 18px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectAllCheckbox": "BSUHBcMFLJfqPKa-bgqF9Q=="
};
export default ___CSS_LOADER_EXPORT___;
