import React from "react";
import { CurrencyFieldDisplay } from "~/currency-formfield-display";
import { Cell, Row } from "@material/react-layout-grid";
import { State, Action } from "./stateMachine";
import { WizardPage } from "./base";

export const RatesPage: React.FC<{
  state: State;
  dispatch: React.Dispatch<Action>;
}> = ({ state, dispatch }) => {
  const action: Action = { tag: "NextPage" };

  return (
    <WizardPage buttonAction={action} dispatch={dispatch} state={state}>
      <div>
        <Row>
          <Cell columns={6}>
            <CurrencyFieldDisplay
              label="Meals - Reimbursable"
              helperText=""
              required
              value={"" + state.mealsReimbursable}
              id="meals-reimbursable"
              name="meals-reimbursable"
              onChange={(val) =>
                dispatch({ tag: "UpdateRate", mealsReimbursable: val })
              }
            />
          </Cell>
          <Cell columns={6}>
            <CurrencyFieldDisplay
              label="Meals - Billable"
              helperText=""
              required
              value={"" + state.mealsBillable}
              id="meals-billable"
              name="meals-billable"
              onChange={(val) =>
                dispatch({ tag: "UpdateRate", mealsBillable: val })
              }
            />
          </Cell>
        </Row>
        <Row>
          <Cell columns={6}>
            <CurrencyFieldDisplay
              label="Lodging - Reimbursable"
              helperText=""
              required
              value={"" + state.lodgingReimbursable}
              id="lodging-reimbursable"
              name="lodging-reimbursable"
              onChange={(val) =>
                dispatch({ tag: "UpdateRate", lodgingReimbursable: val })
              }
            />
          </Cell>
          <Cell columns={6}>
            <CurrencyFieldDisplay
              label="Lodging - Billable"
              helperText=""
              required
              value={"" + state.lodgingBillable}
              id="lodging-billable"
              name="lodging-billable"
              onChange={(val) =>
                dispatch({ tag: "UpdateRate", lodgingBillable: val })
              }
            />
          </Cell>
        </Row>
        <Row>
          <Cell columns={6}>
            <CurrencyFieldDisplay
              label="Travel - Reimbursable"
              helperText=""
              required
              value={"" + state.travelReimbursable}
              id="travel-reimbursable"
              name="travel-reimbursable"
              onChange={(val) =>
                dispatch({ tag: "UpdateRate", travelReimbursable: val })
              }
            />
          </Cell>
          <Cell columns={6}>
            <CurrencyFieldDisplay
              label="Travel - Billable"
              helperText=""
              required
              value={"" + state.travelBillable}
              id="travel-billable"
              name="travel-billable"
              onChange={(val) =>
                dispatch({ tag: "UpdateRate", travelBillable: val })
              }
            />
          </Cell>
        </Row>
      </div>
    </WizardPage>
  );
};
