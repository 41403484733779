import React from "react";
import moment from "moment";
import { parseTime } from "./timeParser";
import { TimeInput } from "~/visuals/molecules/TimeInput";

export type TimePickerProps = {
  formField: string;
  label: string;
  helperText: string;
  required?: boolean;
  disabled?: boolean;
};

const TimePickerFormField: React.FC<TimePickerProps> = (props) => {
  const durationDisplay = (d) => {
    if (!d) {
      return "";
    }
    const m = moment(d.asMilliseconds()).startOf("day").add(d);

    return m.format("hh:mm a");
  };

  const focusedDurationDisplay = (d) => {
    if (!d) {
      return "";
    }
    const m = moment(d.asMilliseconds()).startOf("day").add(d);

    return m.format("hhmma");
  };

  return (
    <TimeInput
      {...{
        ...props,
        className: "time-pick-form-field",
        focusedDurationDisplay,
        durationDisplay,
        parseInputVal: parseTime,
        maxInputLength: 8,
      }}
    />
  );
};

export default TimePickerFormField;
