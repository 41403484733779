import React from "react";
import { ImpersonationManager } from "./ImpersonationManager";
import { LogoutOnExpire } from "./LogoutOnExpire";
import { ReauthPrompt } from "./ReauthPrompt";
import { AuthenticationManager } from "./AuthenticationManager";
import { GetAuthorizationsAsync } from "./types";
import { PopupRefresh } from "./PopupRefresh";
import { useLocation } from "react-router-dom";
import { silentPopupAuthRefresh } from "~/routes";
export { getEmail } from "./jwtUtils";
export { tokenStorageKey } from "./AuthenticationManager";

type AuthProps = {
  getAuthorizations: GetAuthorizationsAsync;
};

const _Auth: React.FC<AuthProps> = ({ getAuthorizations, children }) => {
  return (
    <AuthenticationManager {...{ getAuthorizations }}>
      <LogoutOnExpire>
        <ImpersonationManager {...{ getAuthorizations }}>
          {children}
        </ImpersonationManager>
        <ReauthPrompt />
      </LogoutOnExpire>
    </AuthenticationManager>
  );
};

const Auth: React.FC<AuthProps> = (props) => {
  const location = useLocation();

  if (location.pathname === silentPopupAuthRefresh.path) {
    const { children: _, ...otherProps } = props;
    return (
      <_Auth {...otherProps}>
        <PopupRefresh silent={true} />
      </_Auth>
    );
  }
  return <_Auth {...props} />;
};

export default Auth;
