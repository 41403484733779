import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePerDiemRateMutationVariables = Types.Exact<{
  mealsReimbursable: Types.Scalars['Decimal'];
  mealsBillable: Types.Scalars['Decimal'];
  lodgingReimbursable: Types.Scalars['Decimal'];
  lodgingBillable: Types.Scalars['Decimal'];
  travelReimbursable: Types.Scalars['Decimal'];
  travelBillable: Types.Scalars['Decimal'];
  effectiveDate: Types.Scalars['LocalDate'];
  locations: Array<Types.StateCountyInput> | Types.StateCountyInput;
}>;


export type CreatePerDiemRateMutation = { __typename?: 'MutationModel', perDiemRates?: { __typename?: 'PerDiemRatesMutationModel', create?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const CreatePerDiemRateDocument = gql`
    mutation CreatePerDiemRate($mealsReimbursable: Decimal!, $mealsBillable: Decimal!, $lodgingReimbursable: Decimal!, $lodgingBillable: Decimal!, $travelReimbursable: Decimal!, $travelBillable: Decimal!, $effectiveDate: LocalDate!, $locations: [StateCountyInput!]!) {
  perDiemRates {
    create(
      mealsReimbursable: $mealsReimbursable
      mealsBillable: $mealsBillable
      lodgingReimbursable: $lodgingReimbursable
      lodgingBillable: $lodgingBillable
      travelReimbursable: $travelReimbursable
      travelBillable: $travelBillable
      effectiveDate: $effectiveDate
      locations: $locations
    ) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type CreatePerDiemRateMutationFn = Apollo.MutationFunction<CreatePerDiemRateMutation, CreatePerDiemRateMutationVariables>;

/**
 * __useCreatePerDiemRateMutation__
 *
 * To run a mutation, you first call `useCreatePerDiemRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePerDiemRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPerDiemRateMutation, { data, loading, error }] = useCreatePerDiemRateMutation({
 *   variables: {
 *      mealsReimbursable: // value for 'mealsReimbursable'
 *      mealsBillable: // value for 'mealsBillable'
 *      lodgingReimbursable: // value for 'lodgingReimbursable'
 *      lodgingBillable: // value for 'lodgingBillable'
 *      travelReimbursable: // value for 'travelReimbursable'
 *      travelBillable: // value for 'travelBillable'
 *      effectiveDate: // value for 'effectiveDate'
 *      locations: // value for 'locations'
 *   },
 * });
 */
export function useCreatePerDiemRateMutation(baseOptions?: Apollo.MutationHookOptions<CreatePerDiemRateMutation, CreatePerDiemRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePerDiemRateMutation, CreatePerDiemRateMutationVariables>(CreatePerDiemRateDocument, options);
      }
export type CreatePerDiemRateMutationHookResult = ReturnType<typeof useCreatePerDiemRateMutation>;
export type CreatePerDiemRateMutationResult = Apollo.MutationResult<CreatePerDiemRateMutation>;
export type CreatePerDiemRateMutationOptions = Apollo.BaseMutationOptions<CreatePerDiemRateMutation, CreatePerDiemRateMutationVariables>;