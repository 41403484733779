import React from "react";
import { Route, Routes } from "react-router-dom";
import { CustomRollupInvoicesPage } from "./SearchPage";
import { SearchRestoreRouter } from "~/search-restorer";
import { CustomRollupInvoicePage } from "~/custom-rollup-invoices/SinglePage";
import { CriReview } from "./CriReview";
import { ReviewItemsProvider } from "~/review-items";

export const CustomRollupInvoicesRouter: React.FC = () => {
  return (
    <ReviewItemsProvider>
      <SearchRestoreRouter
        searchPage={<CustomRollupInvoicesPage />}
        nestedPages={
          <Routes>
            <Route path="review/*">
              <Route path=":invoiceNumber" element={<CriReview />} />
            </Route>
            <Route
              path=":invoiceNumber/*"
              element={<CustomRollupInvoicePage />}
            />
          </Routes>
        }
      />
    </ReviewItemsProvider>
  );
};
