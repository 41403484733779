import { CustomerStatus, ProjectRequestCustomerArgsInput } from "~/gql/types";
import { CustomerFormData } from "./types";

export const toCustomerValues = (
  values: CustomerFormData
): ProjectRequestCustomerArgsInput => {
  const customer = values.customer;
  const customerNumber =
    !values.requestNewCustomer &&
    customer?.status == CustomerStatus.Approved &&
    customer?.number
      ? customer?.number
      : null;

  const customerRequestId =
    !values.requestNewCustomer && customer?.status == CustomerStatus.Pending
      ? customer.createCustomerRequestId
      : null;

  return {
    existingNumber: customerNumber,
    newCustomer: values.requestNewCustomer
      ? {
          name: values.customerName!,
          phone: values.customerPhone!,
          fax: values.customerFax || "",
          address1: values.customerAddress1!,
          address2: values.customerAddress2 || "",
          zip: !values.isInternational ? values.customerZip || "" : "",
          city: values.customerCity!,
          state: !values.isInternational ? values.customerState || "" : "",
          country: values.isInternational ? values.customerCountry || "" : "",
          notes: values.customerNotes || "",
          adminNotes: values.customerAdminNotes || null,
          attn: values.customerAttn || null,
        }
      : null,
    pendingRequestId: customerRequestId,
  };
};
