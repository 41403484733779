import React, { useMemo, useState } from "react";
import Button from "~/button";
import { Route } from "~/routeTypes";
import * as invoiceRoutes from "../invoiceRoutes";
import { PopupMenuList } from "~/popup-menu-list";
import "./RebillInvoiceButton.scss";
import { LinkClickHandler } from "~/link-click-handler";
import { InvoiceState } from "~/gql/types";
import { useInvoice } from "../InvoiceContext";

export const RebillInvoiceButton: React.FC = () => {
  const invoice = useInvoice();
  const [open, setOpen] = useState(false);

  const sameProjectRoute = useMemo(
    () =>
      new Route(
        invoiceRoutes.selectInvoice(
          invoice.invoiceNumber,
          `${invoice.projectNumber}`
        )
      ),
    [invoice]
  );

  const diffProjectRoute = useMemo(
    () => new Route(invoiceRoutes.selectProject(invoice.invoiceNumber)),
    [invoice]
  );

  if (invoice.state !== InvoiceState.Cancelled) {
    return null;
  }

  if (!invoice.lineItems!.some((x) => !x?.rebill?.rebilledOnInvoice)) {
    return null;
  }

  const links = [
    <LinkClickHandler key={sameProjectRoute.path} path={sameProjectRoute.path}>
      Rebill to same project
    </LinkClickHandler>,
    <LinkClickHandler key={diffProjectRoute.path} path={diffProjectRoute.path}>
      Rebill to different project
    </LinkClickHandler>,
  ];

  const linkItems = links.map((link, index) => ({
    key: index,
    label: link,
    onClick: () => undefined,
  }));

  return (
    <div className="invoice-rebill-button">
      <Button primary {...{ onClick: () => setOpen(true) }}>
        Rebill
      </Button>
      <PopupMenuList
        {...{ visible: open, items: linkItems }}
        onMenuMouseEnter={() => undefined}
        onMenuMouseLeave={() => undefined}
        onClose={() => setOpen(false)}
        focusOnOpen={false}
        selectedIndex={0}
        anchorCorner="bottomRight"
      />
    </div>
  );
};
