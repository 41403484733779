import React from "react";
import { useSnackBar } from "~/snackbar";
import { CrewCodeRecord, ValidationResponse } from "~/gql/types";
import { useUpdateOfficeOverheadCrewCodeMutation } from "./updateOfficeOhCc.generated";
import { useUpdateStateOverheadCrewCodeMutation } from "./updateStateOhCc.generated";

type UpdateProps = {
  itemKey: "state" | "fieldOffice";
  valueSelector: (values: Record<string, any>) => any;
  type: "updated" | "added";
  refetch: React.DispatchWithoutAction;
  setOpen: React.Dispatch<boolean>;
};

type UpdateReturn = {
  loading: boolean;
  handleSubmit: (args: any) => Promise<ValidationResponse>;
  onSuccess: () => void;
};

type OfficeValues = {
  crewCode: CrewCodeRecord;
  officeCode: string;
};

type StateValues = {
  crewCode: CrewCodeRecord;
  state: string;
};

export function useUpdateOverheadCrewCode(props: UpdateProps): UpdateReturn {
  const { itemKey, valueSelector, type, refetch, setOpen } = props;
  const addAlert = useSnackBar();

  const [updateOfficeType, { loading: officeTypeLoading }] =
    useUpdateOfficeOverheadCrewCodeMutation();
  const [updateStateType, { loading: stateTypeLoading }] =
    useUpdateStateOverheadCrewCodeMutation();

  const itemType = itemKey === "fieldOffice" ? "office" : "state";

  const handleOfficeType = async (values: OfficeValues) => {
    const variables = {
      crewCode: values.crewCode.crewCode,
      officeCode: valueSelector(values),
    };

    const { data } = await updateOfficeType({ variables });

    refetch();

    return data?.crewCodes?.updateOfficeOverheadCrewCode as ValidationResponse;
  };

  const handleStateType = async (values: StateValues) => {
    const variables = {
      crewCode: values.crewCode.crewCode,
      state: valueSelector(values),
    };

    const { data } = await updateStateType({ variables });

    refetch();

    return data?.crewCodes?.updateStateOverheadCrewCode as ValidationResponse;
  };

  const onSuccess = ({ values }) => {
    addAlert({
      key: valueSelector(values),
      isSuccess: true,
      message: `Crew code for ${itemType} ${valueSelector(values)} ${type}`,
    });
    setOpen(false);
  };
  return {
    loading: officeTypeLoading || stateTypeLoading,
    handleSubmit:
      itemKey === "fieldOffice" ? handleOfficeType : handleStateType,
    onSuccess: () => onSuccess,
  };
}
