import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { Suggestion } from "~/ultra-filter/types";

type SimplePickerProps = FormFieldProps & {
  options: Suggestion[];
};

export const SimplePicker = (props: SimplePickerProps) => {
  const { options } = props;
  const format = (item: any) => options.find((s) => s.value === item)?.text;

  const getData = async (searchText: string) => {
    if (!searchText) {
      return options.map((s) => s.value);
    }
    return options
      .filter(
        (s) =>
          s.value.toLowerCase().startsWith(searchText.toLowerCase()) ||
          s.text.toLowerCase().startsWith(searchText.toLowerCase())
      )
      .map((s) => s.value);
  };

  const args = {
    ...props,
    getData,
    format,
  } as TypeaheadProps<any>;

  return <TypeaheadFormField {...args} />;
};
