import "./Tabs.scss";

export type {
  TabBarContextT,
  TabBarOnActivateEventT,
  TabBarProps,
  TabOnInteractionEventT,
  TabProps,
} from "rmwc";

export {
  Tab,
  TabBar,
  TabBarContext,
  TabBarRoot,
  TabIndicator,
  TabScroller,
  TabScrollerRoot,
  TabScrollerScrollArea,
  TabScrollerScrollContent,
  withTabBarContext,
} from "rmwc";
