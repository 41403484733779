import React, { useState } from "react";
import { FormValues, ThirdPartyInvoiceForm } from "../ThirdPartyInvoiceForm";
import { useCreateThirdPartyInvoiceMutation } from "./create.generated";

import { ValidationResponse } from "~/gql/types";
import { useNavigate } from "react-router-dom";
import { thirdPartyInvoice } from "~/routes";
import { useSnackBar } from "~/snackbar";

type CreateThirdPartyInvoiceProps = {
  initialValues?: FormValues;
};
export const CreateThirdPartyInvoice: React.FC<
  CreateThirdPartyInvoiceProps
> = ({ initialValues }) => {
  const navigate = useNavigate();
  const addAlert = useSnackBar();

  const [doMutation] = useCreateThirdPartyInvoiceMutation();
  const [imageId, setImageId] = useState<string | null>(
    initialValues?.documentImageId ?? null
  );

  const handleSubmit = async (values: FormValues) => {
    const variables = {
      date: values.date!.format("YYYY-MM-DD"),
      amount: parseFloat(values.amount!),
      projectId: values.project.number!,
      documentImageId: imageId!,
      crewCode: values.crewCode!.crewCode,
      notes: values.notes || null,
    };
    const result = await doMutation({ variables });

    return result?.data?.thirdPartyInvoices?.create as ValidationResponse;
  };

  const onSuccess = ({ response }) => {
    addAlert({
      message: "Third party invoice created",
      isSuccess: true,
      key: `${Math.random()}`,
    });
    navigate(thirdPartyInvoice.toRoute(response.invoiceNumber!).path);
  };

  return (
    <ThirdPartyInvoiceForm
      type="Create"
      setImageId={setImageId}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      initialValues={initialValues}
    />
  );
};
