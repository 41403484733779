import React, { useContext } from "react";
import { Invoice } from "~/gql/types";

type InvoiceContextData = {
  invoice: Invoice;
  reload: React.DispatchWithoutAction;
};

export const InvoiceContext = React.createContext<InvoiceContextData>(null!);

export const useInvoice = (): Invoice => {
  const data = useInvoiceContextData();

  return data.invoice;
};

export const useRefreshInvoice = (): React.DispatchWithoutAction => {
  const data = useInvoiceContextData();

  return data.reload;
};

export function useInvoiceContextData(): InvoiceContextData {
  const data = useContext(InvoiceContext);

  if (!data) {
    throw new Error("Cannot use useInvoice outside an InvoiceRouter");
  }
  return data;
}

export type MockInvoiceProviderProps = {
  invoice: Invoice;
  reload: React.DispatchWithoutAction;
};

export const MockInvoiceProvider: React.FC<MockInvoiceProviderProps> = ({
  invoice,
  reload,
  children,
}) => (
  <InvoiceContext.Provider value={{ invoice, reload }}>
    {children}
  </InvoiceContext.Provider>
);
