import React, { useState } from "react";
import PageTitleRow from "~/page-title-row";
import FormCard from "~/form-card";
import { Cell, Row } from "@material/react-layout-grid";
import { OnChangeForm } from "~/forms/OnChangeForm";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import "./OfficeBillingReport.scss";
import moment from "moment";
import DropdownFormField from "~/visuals/organisms/DropdownFormField";
import _ from "lodash";
import { NotifierForm } from "~/forms/NotifierForm";
import Spinner from "~/spinner";
import Button from "~/button";

const firstYear = 2022;

type OfficeBillingReportProps = {
  downloadReport: (
    setLoading: React.Dispatch<boolean>,
    year: number,
    offices: string[]
  ) => Promise<void>;
  offices: string[];
};

export const OfficeBillingReport: React.FC<OfficeBillingReportProps> = (
  props
) => {
  const { downloadReport, offices } = props;

  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState<string[]>([]);

  const currentYear = moment().local().year();

  const [year, setYear] = useState<number>(moment().local().year());

  const onChange = (office: string) => {
    setSelected((selectedOffices) => {
      if (selectedOffices.includes(office)) {
        return selectedOffices.filter((x) => x != office);
      }

      return [...selectedOffices, office];
    });
  };

  const yearOptions = [...new Array(currentYear - firstYear + 1)].map(
    (_, idx) => ({ label: `${firstYear + idx}`, value: `${firstYear + idx}` })
  );

  const orderedOptions = _.orderBy(yearOptions, "value", "desc");

  const onYearChange = (vals: { year: string }) => {
    setYear(+vals.year);
  };

  const download = () => downloadReport(setLoading, year, selected);

  const onSelectAllClick = () => {
    const allSelected = selected.length === offices.length;

    setSelected(allSelected ? [] : offices);
  };

  return (
    <div className="office-billing-report">
      <PageTitleRow title={"Office Billing Report"} />
      <FormCard>
        <Row>
          <Cell className="offices-selection" columns={6}>
            <OnChangeForm
              values={{ selectAll: selected.length === offices.length }}
              onChange={onSelectAllClick}
            >
              <BinaryPickerFormField
                formField={"selectAll"}
                label={"Select all"}
                helperText={""}
              />
            </OnChangeForm>

            {offices.map((o, i) => (
              <OnChangeForm
                key={i}
                values={{ office: selected.includes(o) }}
                onChange={() => onChange(o)}
              >
                <BinaryPickerFormField
                  formField={"office"}
                  label={o}
                  helperText={""}
                />
              </OnChangeForm>
            ))}
          </Cell>
          <Cell columns={6}>
            <NotifierForm values={{ year: `${year}` }} onChange={onYearChange}>
              <DropdownFormField
                label={"Year"}
                formField={"year"}
                helperText={""}
                options={orderedOptions}
              />
            </NotifierForm>

            <Button primary onClick={download}>
              Download Report
            </Button>
          </Cell>
        </Row>
      </FormCard>
      <Spinner open={loading} />
    </div>
  );
};
