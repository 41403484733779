import React, { useEffect, useState } from "react";
import { FilterForm } from "./FilterForm";
import { InvoiceCard } from "./InvoiceCard";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { VisibilityListener } from "~/visibility-listener";
import useInfiniteScroll from "~/search-page-wrapper/infinite-scroll/useInfiniteScroll";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { TextHighlighterProvider } from "~/text-highlighter/TextHighlighterProvider";
import { SearchFilterContainer } from "~/search-filter-container";
import { InvoiceBulkOptions } from "./InvoiceBulkOptions";
import {
  InvoiceSearchStatsProvider,
  useInvoiceSearchStats,
} from "./InvoiceSearchStatsProvider";
import { Invoice, InvoiceSortBy } from "~/gql/types";
import {
  useInvoicesSearchQuery,
  useInvoicesSearchWithStatsQuery,
} from "./query.generated";
import { SearchTopActions } from "~/search-top-actions";
import { SelectAllCheckbox } from "~/search-page-wrapper/BulkCheckboxes/SelectAllCheckbox";
import { LoadAllInvoices } from "./LoadAllInvoices";
import { useSearchParams } from "react-router-dom";
import { SearchSortBy } from "~/search-sort-by";
import { useReviewItemsContext } from "~/review-items";
import { formattedDate } from "./utils";
import { FilterOptions } from "./types";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";

const Breadcrumbs: React.FC = () => {
  useBreadcrumbs([{ text: "Invoices" }], []);

  return <></>;
};

type InvoicesPageProps = {
  FilterFormComponent?: typeof FilterForm;
};

export type InvoicesPageDisplayProps = {
  showSpinner: boolean;
  loadingMore: boolean;
  invoices: Invoice[] | null;
  dispatch: React.Dispatch<Action<Invoice, FilterOptions>>;
  state: State<Invoice, FilterOptions>;
};

export const InvoicesPageDisplay: React.FC<InvoicesPageDisplayProps> = ({
  showSpinner,
  invoices,
  loadingMore,
  state,
  dispatch,
}) => {
  return (
    <SearchListContent
      loading={showSpinner}
      loadingMore={loadingMore}
      actions={
        <SearchTopActions>
          <SelectAllCheckbox {...{ state, dispatch }}>
            <LoadAllInvoices {...{ state, dispatch }} />
          </SelectAllCheckbox>
          <InvoiceBulkOptions {...{ state, dispatch }} />
        </SearchTopActions>
      }
    >
      <Breadcrumbs />

      {invoices &&
        invoices.map((e, idx) => (
          <InvoiceCard invoice={e as any} key={idx} {...{ state, dispatch }} />
        ))}
      {state.showVisibility && (
        <VisibilityListener
          onShown={() => dispatch({ tag: "ScrolledToBottom" })}
        />
      )}
    </SearchListContent>
  );
};

type LoadInvoicesProps = {
  dispatch: React.Dispatch<Action<Invoice, FilterOptions>>;
  tag: "DataLoaded" | "MoreDataLoaded";
  token: string | null;
  filterOptions: FilterOptions;
};

const LoadInvoices = ({
  dispatch,
  tag,
  filterOptions,
  token,
}: LoadInvoicesProps) => {
  const {
    searchText,
    billingAdmin,
    project,
    projectManager,
    projectSupervisor,
    customer,
    state,
    actualDate,
    beforeDate,
    afterDate,
    actualCancelledDate,
    beforeCancelledDate,
    afterCancelledDate,
    amount,
    officeCode,
    locationState,
    afeWoPo,
    sortBy,
    projectGroup,
  } = filterOptions;

  const { setStats } = useInvoiceSearchStats();

  const queryHook = token
    ? useInvoicesSearchQuery
    : useInvoicesSearchWithStatsQuery;

  const variables = {
    searchText: searchText || null,
    state: state || null,
    billingAdmin: billingAdmin?.userPrincipalName || null,
    projectManager: projectManager?.userPrincipalName || null,
    projectSupervisor: projectSupervisor?.userPrincipalName || null,
    customerNumber: customer?.number || null,
    projectNumber: project?.number || null,
    startDate: formattedDate(afterDate),
    endDate: formattedDate(beforeDate),
    actualDate: formattedDate(actualDate),
    startCancelledDate: formattedDate(afterCancelledDate),
    endCancelledDate: formattedDate(beforeCancelledDate),
    actualCancelledDate: formattedDate(actualCancelledDate),
    amount: amount ? parseFloat(amount) : null,
    officeCode: officeCode?.officeCode || null,
    locationState: locationState || null,
    afeWoPo: afeWoPo || null,
    token,
    sortBy: sortBy as InvoiceSortBy,
    projectGroupArgs: projectGroup
      ? {
          groupName: projectGroup.name,
          customerNumber: projectGroup.customer!.number!,
        }
      : null,
  };

  const { data } = queryHook({ variables });

  useEffect(() => {
    void (async () => {
      if (data) {
        const newToken = data?.invoices?.search?.token as string | null;
        const newInvoices = data?.invoices?.search?.records as Invoice[];
        const stats = data?.invoices?.["stats"];

        if (stats) {
          setStats(stats);
        }
        dispatch({ tag, items: newInvoices, searchToken: newToken });
      }
    })();
  }, [data]);

  return <></>;
};

export const InvoicesPage: React.FC<InvoicesPageProps> = (props) => {
  const [filters, setFilters] = useState<FilterOptions | null>(null);

  const FilterFormComponent = props.FilterFormComponent || FilterForm;

  const { setSelectedItems } = useReviewItemsContext();

  const setFilterOptions = (opts: FilterOptions) => setFilters(opts);

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <InvoiceSearchStatsProvider>
      <SearchPageWrapper withSort>
        <SearchFilterContainer>
          <FilterFormComponent onFiltersChanged={setFilterOptions} />
          <SearchSortBy
            {...{
              filters,
              setFilters,
              searchParams,
              setSearchParams,
              type: "InvoiceSortBy",
            }}
          />
        </SearchFilterContainer>
        {filters && (
          <InvoicesResults {...{ ...props, filters, setSelectedItems }} />
        )}
      </SearchPageWrapper>
    </InvoiceSearchStatsProvider>
  );
};

type InvoicesResultsProps = InvoicesPageProps & {
  filters: FilterOptions;
};

export const InvoicesResults: React.FC<InvoicesResultsProps> = (props) => {
  const { filters } = props;
  const { setState } = useInvoiceSearchStats();
  const { setSelectedItems } = useReviewItemsContext();

  const initialState = {
    tag: "Loading",
    items: [],
    showVisibility: false,
    loading: true,
    filterOptions: filters,
    searchToken: null,
    selectedItems: [],
    selectIdKey: "invoiceNumber",
  };

  const { state, dispatch } = useInfiniteScroll<Invoice, FilterOptions>(
    initialState
  );

  useEffect(() => {
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: filters,
    });
  }, [filters]);

  useEffect(() => {
    setState(state);
  }, [state.items]);

  useEffect(() => {
    setSelectedItems(state.selectedItems);
  }, [state.selectedItems]);

  return (
    <TextHighlighterProvider searchText={state.filterOptions.searchText}>
      {state.tag === "Loading" && (
        <LoadInvoices
          {...{
            dispatch,
            tag: "DataLoaded",
            filterOptions: state.filterOptions,
            token: null,
          }}
        />
      )}
      {state.tag === "LoadingMore" && (
        <LoadInvoices
          {...{
            dispatch,
            tag: "MoreDataLoaded",
            token: state.searchToken,
            filterOptions: state.filterOptions,
          }}
        />
      )}
      <InvoicesPageDisplay
        {...{
          invoices: state.items,
          loadingMore: state.tag === "LoadingMore",
          showSpinner: state.loading,
          state,
          dispatch,
        }}
      />
      <InvoiceBulkOptions
        {...{
          bulkSelect: true,
          state,
          dispatch,
        }}
      />
    </TextHighlighterProvider>
  );
};
