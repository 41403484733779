import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RejectProjectRequestMutationVariables = Types.Exact<{
  requestId: Types.Scalars['String'];
  reason: Types.Scalars['String'];
}>;


export type RejectProjectRequestMutation = { __typename?: 'MutationModel', projectRequests?: { __typename?: 'ProjectRequestMutationModel', reject?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const RejectProjectRequestDocument = gql`
    mutation RejectProjectRequest($requestId: String!, $reason: String!) {
  projectRequests {
    reject(requestId: $requestId, reason: $reason) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type RejectProjectRequestMutationFn = Apollo.MutationFunction<RejectProjectRequestMutation, RejectProjectRequestMutationVariables>;

/**
 * __useRejectProjectRequestMutation__
 *
 * To run a mutation, you first call `useRejectProjectRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectProjectRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectProjectRequestMutation, { data, loading, error }] = useRejectProjectRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useRejectProjectRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectProjectRequestMutation, RejectProjectRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectProjectRequestMutation, RejectProjectRequestMutationVariables>(RejectProjectRequestDocument, options);
      }
export type RejectProjectRequestMutationHookResult = ReturnType<typeof useRejectProjectRequestMutation>;
export type RejectProjectRequestMutationResult = Apollo.MutationResult<RejectProjectRequestMutation>;
export type RejectProjectRequestMutationOptions = Apollo.BaseMutationOptions<RejectProjectRequestMutation, RejectProjectRequestMutationVariables>;