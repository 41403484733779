import React from "react";
import { useTimesheetContext } from "./TimesheetContext";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { timesheetReview } from "~/visuals/pages/TimesheetReview/routes";

export const EmployeeBreadcrumbs: React.FC = () => {
  const { mode, employee } = useTimesheetContext();

  const breadcrumbHeading =
    mode !== "MyTimesheets" ? "Timesheet Review" : "My Timesheets";

  const selectedBreadcrumbs =
    mode === "MyTimesheets"
      ? [
          {
            text: `${breadcrumbHeading}: ${employee?.firstName} ${employee?.lastName}`,
          },
        ]
      : [
          { text: "Timesheet Review", to: timesheetReview },
          {
            text: `${breadcrumbHeading}: ${employee?.firstName} ${employee?.lastName}`,
          },
        ];

  const breadcrumbs =
    employee === null ? [{ text: "(loading...)" }] : selectedBreadcrumbs;

  useBreadcrumbs(breadcrumbs, [employee]);

  return <></>;
};
