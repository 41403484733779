import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRevenueReportQueryVariables = Types.Exact<{
  filtering: Types.RevenueReportFilteringArgsInput;
  grouping: Types.RevenueReportGroupingArgsInput;
}>;


export type GetRevenueReportQuery = { __typename?: 'QueryModel', invoices?: { __typename?: 'InvoicesQueryModel', revenueReport?: Array<{ __typename?: 'RevenueReportRow', total: number, industry?: string | null, officeCode?: string | null, projectType?: string | null, customer?: { __typename?: 'Customer', name: string, number?: string | null } | null, project?: { __typename?: 'Project', name: string, number: number } | null, projectManager?: { __typename?: 'Employee', firstName: string, lastName: string, email: string } | null, serviceDescription?: { __typename?: 'ServiceDescription', industry: string, projectType: string, description: string, displayName: string } | null } | null> | null } | null };


export const GetRevenueReportDocument = gql`
    query GetRevenueReport($filtering: RevenueReportFilteringArgsInput!, $grouping: RevenueReportGroupingArgsInput!) {
  invoices {
    revenueReport(filtering: $filtering, grouping: $grouping) {
      total
      industry
      officeCode
      customer {
        name
        number
      }
      project {
        name
        number
      }
      projectType
      projectManager {
        firstName
        lastName
        email
      }
      serviceDescription {
        industry
        projectType
        description
        displayName
      }
    }
  }
}
    `;

/**
 * __useGetRevenueReportQuery__
 *
 * To run a query within a React component, call `useGetRevenueReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevenueReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevenueReportQuery({
 *   variables: {
 *      filtering: // value for 'filtering'
 *      grouping: // value for 'grouping'
 *   },
 * });
 */
export function useGetRevenueReportQuery(baseOptions: Apollo.QueryHookOptions<GetRevenueReportQuery, GetRevenueReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRevenueReportQuery, GetRevenueReportQueryVariables>(GetRevenueReportDocument, options);
      }
export function useGetRevenueReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRevenueReportQuery, GetRevenueReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRevenueReportQuery, GetRevenueReportQueryVariables>(GetRevenueReportDocument, options);
        }
export type GetRevenueReportQueryHookResult = ReturnType<typeof useGetRevenueReportQuery>;
export type GetRevenueReportLazyQueryHookResult = ReturnType<typeof useGetRevenueReportLazyQuery>;
export type GetRevenueReportQueryResult = Apollo.QueryResult<GetRevenueReportQuery, GetRevenueReportQueryVariables>;