import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CloneTaskRateSheetMutationVariables = Types.Exact<{
  newName: Types.Scalars['String'];
  oldName: Types.Scalars['String'];
}>;


export type CloneTaskRateSheetMutation = { __typename?: 'MutationModel', taskRates?: { __typename?: 'TaskRatesMutationModel', cloneTaskRateSheet?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CloneTaskRateSheetDocument = gql`
    mutation CloneTaskRateSheet($newName: String!, $oldName: String!) {
  taskRates {
    cloneTaskRateSheet(newName: $newName, oldName: $oldName) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CloneTaskRateSheetMutationFn = Apollo.MutationFunction<CloneTaskRateSheetMutation, CloneTaskRateSheetMutationVariables>;

/**
 * __useCloneTaskRateSheetMutation__
 *
 * To run a mutation, you first call `useCloneTaskRateSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneTaskRateSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneTaskRateSheetMutation, { data, loading, error }] = useCloneTaskRateSheetMutation({
 *   variables: {
 *      newName: // value for 'newName'
 *      oldName: // value for 'oldName'
 *   },
 * });
 */
export function useCloneTaskRateSheetMutation(baseOptions?: Apollo.MutationHookOptions<CloneTaskRateSheetMutation, CloneTaskRateSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneTaskRateSheetMutation, CloneTaskRateSheetMutationVariables>(CloneTaskRateSheetDocument, options);
      }
export type CloneTaskRateSheetMutationHookResult = ReturnType<typeof useCloneTaskRateSheetMutation>;
export type CloneTaskRateSheetMutationResult = Apollo.MutationResult<CloneTaskRateSheetMutation>;
export type CloneTaskRateSheetMutationOptions = Apollo.BaseMutationOptions<CloneTaskRateSheetMutation, CloneTaskRateSheetMutationVariables>;