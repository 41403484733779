type GroupingField = {
  key: string;
  label: string;
};

export const fields: GroupingField[] = [
  { key: "customer", label: "Customer" },
  { key: "project", label: "Project" },
  { key: "projectType", label: "Project Type" },
  { key: "projectManager", label: "Project Manager" },
  { key: "industry", label: "Industry" },
  { key: "officeCode", label: "Office" },
  { key: "serviceDescription", label: "Service Description" },
];
