import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExpenseRatesAssignRateSheetToProjectMutationVariables = Types.Exact<{
  projectNumbers?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
  rateSheetName: Types.Scalars['String'];
  effectiveDate: Types.Scalars['LocalDate'];
}>;


export type ExpenseRatesAssignRateSheetToProjectMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', assignRateSheetToProject?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const ExpenseRatesAssignRateSheetToProjectDocument = gql`
    mutation ExpenseRatesAssignRateSheetToProject($projectNumbers: [Int!], $rateSheetName: String!, $effectiveDate: LocalDate!) {
  expenseRates {
    assignRateSheetToProject(
      projectNumbers: $projectNumbers
      rateSheetName: $rateSheetName
      effectiveDate: $effectiveDate
    ) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type ExpenseRatesAssignRateSheetToProjectMutationFn = Apollo.MutationFunction<ExpenseRatesAssignRateSheetToProjectMutation, ExpenseRatesAssignRateSheetToProjectMutationVariables>;

/**
 * __useExpenseRatesAssignRateSheetToProjectMutation__
 *
 * To run a mutation, you first call `useExpenseRatesAssignRateSheetToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpenseRatesAssignRateSheetToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expenseRatesAssignRateSheetToProjectMutation, { data, loading, error }] = useExpenseRatesAssignRateSheetToProjectMutation({
 *   variables: {
 *      projectNumbers: // value for 'projectNumbers'
 *      rateSheetName: // value for 'rateSheetName'
 *      effectiveDate: // value for 'effectiveDate'
 *   },
 * });
 */
export function useExpenseRatesAssignRateSheetToProjectMutation(baseOptions?: Apollo.MutationHookOptions<ExpenseRatesAssignRateSheetToProjectMutation, ExpenseRatesAssignRateSheetToProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExpenseRatesAssignRateSheetToProjectMutation, ExpenseRatesAssignRateSheetToProjectMutationVariables>(ExpenseRatesAssignRateSheetToProjectDocument, options);
      }
export type ExpenseRatesAssignRateSheetToProjectMutationHookResult = ReturnType<typeof useExpenseRatesAssignRateSheetToProjectMutation>;
export type ExpenseRatesAssignRateSheetToProjectMutationResult = Apollo.MutationResult<ExpenseRatesAssignRateSheetToProjectMutation>;
export type ExpenseRatesAssignRateSheetToProjectMutationOptions = Apollo.BaseMutationOptions<ExpenseRatesAssignRateSheetToProjectMutation, ExpenseRatesAssignRateSheetToProjectMutationVariables>;