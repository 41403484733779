import { Alert } from "~/snackbar";

type BaseHandleProps = {
  addAlert: React.Dispatch<Alert>;
  operationName: string;
};

type HandleErrorsProps = BaseHandleProps & {
  data: any;
  submitErrors?: string[];
};

type HandleSuccessProps = BaseHandleProps & {
  resetCharges: () => Promise<void>;
  message: string;
  itemCount: number;
};

type HandleResponseProps = HandleErrorsProps & HandleSuccessProps;

export const handleErrors = (props: HandleErrorsProps) => {
  const { data, addAlert, operationName, submitErrors } = props;
  const errors: string[] = submitErrors ?? [];

  if (!submitErrors) {
    data.errors.forEach(({ argumentName, message }) => {
      if (argumentName) {
        errors.push(`${argumentName}: ${message}`);
      } else if (message) {
        errors.push(message);
      }
    });
  }

  addAlert({
    key: operationName,
    message: errors.join(" "),
    isSuccess: false,
  });
};

export const handleSuccess = (props: HandleSuccessProps) => {
  const { addAlert, operationName, itemCount, message, resetCharges } = props;
  addAlert({
    key: operationName,
    message: `${itemCount} project charge${
      itemCount === 1 ? "" : "s"
    } ${message}`,
    isSuccess: true,
  });
  void resetCharges();
};

export const handleResponse = (props: HandleResponseProps) => {
  const { data, addAlert, resetCharges, operationName, itemCount, message } =
    props;

  if (data && data.errors.length > 0) {
    handleErrors(props);
  } else {
    handleSuccess({
      addAlert,
      itemCount,
      operationName,
      message,
      resetCharges,
    });
  }
};
