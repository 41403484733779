import { TimeOffType, AtvExpense } from "~/graphql/MyWork";
import Employee from "~/graphql/Employee";
import Project from "~/graphql/Project";
import { mockDaily } from "~/mockDaily";
import { project } from "~/visuals/pages/Project/ProjectTestData";
import { expenseRateSheets } from "~/mockExpenseRateSheets";
import { AtvExpenseRate } from "~/graphql/ExpenseRateSheet";
import { WorkDay } from "~/WorkDayApi/WorkDayApiType";
import { ProjectTimeEntry } from "~/gql/types";

const expenseRate = expenseRateSheets[0].expenseRates[9] as AtvExpenseRate;

const employee = {
  firstName: "Joe",
  isMobileEmployee: true,
  isTerminated: false,
  timeEntryMethod: "Workflow",
  lastName: "Bob",
  ultiProId: "ABC123",
  userPrincipalName: "joe@topographic.com",
} as Employee;

const timeEntries = [
  {
    location: {
      state: "TX",
      county: "DALLAS",
    },
    timeIn: "09:00:00",
    timeOut: "17:00:00",
    hoursWorked: "07:30:00",
    lunchHours: "00:30:00",
    lunchStart: "12:00:00",
    lunchEnd: "12:30:00",
    crewCode: "TX5456",
    id: "abc123",
    date: "2021-01-10",
  },
];

const timeOff = [
  {
    crewCode: "23613-NM",
    hours: "0:45:00",
    timeOffType: "S" as TimeOffType,
    id: "def456",
    date: "2021-01-10",
  },
];

const projectOne = {
  name: "Walmart Project",
  number: 34411,
  customer: {
    number: "WM001",
  },
} as Project;

const projectTwo = {
  name: "Target Project",
  number: 783452,
  customer: {
    number: "TG001",
  },
} as Project;

export const workDataProjects = [project, projectOne, projectTwo] as Project[];

const expenses = [
  {
    projectId: "456bca",
    project: project,
    id: "a1b2c3",
    rateSheetName: "Sheet One",
    notes: "Some notes",
    crewCode: "OK1234",
    payable: "Reimbursement",
    rateName: expenseRate.name,
    billableAmount: expenseRate.billableAmount,
    payableAmount: expenseRate.reimbursableAmount,
    __typename: "AtvExpense",
    location: { state: "OK", county: "TULSA" },
    invoiceLabel: expenseRate.invoiceLabel,
    expenseAccount: expenseRate.expenseAccount,
    date: "2021-01-10",
    payableOverridden: false,
  } as AtvExpense,
];

export const projectTime = [
  {
    project: projectOne,
    crewCode: "TX5456",
    hours: "3:00:00",
    taskName: "Excavation",
    notes: "These are test notes",
    id: "xyz987",
    rate: 100.0,
    rateSheetName: "Sheet One",
    daily: null,
    location: { state: "OK", county: "TULSA" },
    crewSize: 1,
    date: "2021-01-10",
  },
  {
    project: projectTwo,
    crewCode: "TX5456",
    hours: "3:45:00",
    taskName: "Surveying",
    notes: "These are test notes",
    id: "zyx789",
    rate: 75.0,
    rateSheetName: "Sheet One",
    daily: mockDaily,
    location: { state: "OK", county: "TULSA" },
    crewSize: 1,
    date: "2021-01-10",
  },
] as ProjectTimeEntry[];

export const myWorkDayData: WorkDay = {
  timeEntries,
  timeOff,
  projectTime,
  expenses,
  date: "2021-01-10",
  employee: employee,
  workDayId: "1234",
  editable: true,
  editableMessage: "",
};

export const emptyMyWorkDayData = {
  timeEntries: null,
  timeOff: null,
  expenses: null,
  date: "2021-01-10",
  projectTime: null,
  employee: null,
};
