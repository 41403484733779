import React from "react";
import { projects } from "~/routes";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { ProjectDetails } from "~/visuals/pages/Project/ProjectDetails";
import { Project as ProjectModel } from "~/gql/types";

const Project: React.FC<{ project: ProjectModel }> = ({ project }) => {
  useBreadcrumbs(
    [
      { text: "Projects", to: projects.path },
      { text: `Project ${project?.number}: ${project?.name}` },
    ],
    [project]
  );

  return <ProjectDetails {...{ project }} />;
};

export default Project;
