import React from "react";
import { Moment } from "moment";
import { UltraFilter } from "~/ultra-filter";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import { useWeekStatusProvider } from "~/filterProviders/useWeekStatusProvider";
import { useDateProvider } from "~/filterProviders/useDateProvider";
import {
  Employee,
  PayPeriod,
  TimesheetSearchReimbursementOption,
  TimesheetStatus,
} from "~/gql/types";
import { ShowFutureFilter } from "~/visuals/organisms/TimesheetDisplay/types";
import { useShowFutureTimesheetsProvider } from "~/filterProviders/yesNoProviders";

export type FilterOptions = {
  searchText: string | null;
  weekStart: Moment | null;
  dateBefore: Moment | null;
  dateAfter: Moment | null;
  workLocation: string | null;
  weekStatus: TimesheetStatus | null;
  reimbursement: TimesheetSearchReimbursementOption | null;
  payrollAdmin: Employee | null;
  payPeriod: PayPeriod | null;
  showFuture: ShowFutureFilter;
};

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<FilterOptions>;
  dialogTitle: string;
  label: string;
};

export const FilterForm: React.FC<FilterFormProps> = ({
  onFiltersChanged,
  dialogTitle,
  label,
}) => {
  const providers = [
    useDateProvider({
      type: "weekStart",
      label: "Timesheet Week",
      documentationText: "The date of the timesheet week",
      suggestText: "date",
    }),
    useDateProvider({
      type: "dateBefore",
      label: "Date Before",
      documentationText:
        "Search for timesheets beginning before this date (exclusive).",
      suggestText: "dbf",
    }),
    useDateProvider({
      type: "dateAfter",
      label: "Date After",
      documentationText:
        "Search for timesheets beginning after this date (exclusive).",
      suggestText: "daft",
    }),
    useWeekStatusProvider({ documentationText: "The timesheet status." }),
    useShowFutureTimesheetsProvider({
      documentationText: "Show next week's timesheet",
    }),
  ] as FilterProvider[];

  const onChanged = (filters: ActiveFilter[], searchText: string) => {
    searchText;

    const newFilters = {
      searchText: null,
      weekStart: filters.find((f) => f.type === "weekStart")?.value || null,
      dateBefore: filters.find((f) => f.type === "dateBefore")?.value || null,
      dateAfter: filters.find((f) => f.type === "dateAfter")?.value || null,
      weekStatus: filters.find((f) => f.type === "weekStatus")?.value || null,
      showFuture: filters.find((f) => f.type === "showFuture")?.value || null,
      workLocation: null,
      reimbursement: null,
      payrollAdmin: null,
      payPeriod: null,
    };

    onFiltersChanged(newFilters as FilterOptions);
  };

  return (
    <div className="filter-form">
      <UltraFilter
        {...{
          providers,
          dialogTitle,
          label,
          onFiltersChanged: onChanged,
        }}
      />
    </div>
  );
};
