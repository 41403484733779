// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EPVYFOuOUa5h83ynbPOfYg\\=\\={display:grid;grid-template-columns:1fr max-content;grid-template-rows:1fr;margin-bottom:-30px}.EPVYFOuOUa5h83ynbPOfYg\\=\\=>.product-picker{margin-top:-15px}", "",{"version":3,"sources":["webpack://src/product-picker/ProductPicker.module.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,qCAAA,CACA,sBAAA,CACA,mBAAA,CAEA,4CACE,gBAAA","sourcesContent":[".ProductPicker {\n  display: grid;\n  grid-template-columns: 1fr max-content;\n  grid-template-rows: 1fr;\n  margin-bottom: -30px;\n\n  & > :global(.product-picker) {\n    margin-top: -15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProductPicker": "EPVYFOuOUa5h83ynbPOfYg=="
};
export default ___CSS_LOADER_EXPORT___;
