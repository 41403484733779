import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PendingCustomersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PendingCustomersQuery = { __typename?: 'QueryModel', customers?: { __typename?: 'CustomersQueryModel', pendingCustomers?: Array<{ __typename?: 'PendingCustomer', name: string, address1: string, address2: string, city: string, createCustomerRequestId: string, state: string, zip: string, country: string, phone: string, subAccountOf?: string | null, adminNotes?: string | null, nmTaxable: boolean, requester?: { __typename?: 'Employee', firstName: string, lastName: string, userPrincipalName: string } | null } | null> | null } | null };


export const PendingCustomersDocument = gql`
    query PendingCustomers {
  customers {
    pendingCustomers {
      name
      address1
      address2
      city
      createCustomerRequestId
      state
      zip
      country
      phone
      subAccountOf
      requester {
        firstName
        lastName
        userPrincipalName
      }
      adminNotes
      nmTaxable
    }
  }
}
    `;

/**
 * __usePendingCustomersQuery__
 *
 * To run a query within a React component, call `usePendingCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingCustomersQuery(baseOptions?: Apollo.QueryHookOptions<PendingCustomersQuery, PendingCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PendingCustomersQuery, PendingCustomersQueryVariables>(PendingCustomersDocument, options);
      }
export function usePendingCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingCustomersQuery, PendingCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PendingCustomersQuery, PendingCustomersQueryVariables>(PendingCustomersDocument, options);
        }
export type PendingCustomersQueryHookResult = ReturnType<typeof usePendingCustomersQuery>;
export type PendingCustomersLazyQueryHookResult = ReturnType<typeof usePendingCustomersLazyQuery>;
export type PendingCustomersQueryResult = Apollo.QueryResult<PendingCustomersQuery, PendingCustomersQueryVariables>;