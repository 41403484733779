import {
  Customer,
  ProjectRequest,
  ProjectRequestNewCustomer,
} from "~/gql/types";

type RequestCustomerInfo = {
  customer: Customer | ProjectRequestNewCustomer;
  customerNumber: string;
  buttonText: string;
};

export const getCustomerInfo = (
  projectRequest: ProjectRequest
): RequestCustomerInfo => {
  const customer =
    projectRequest.customer ??
    projectRequest.pendingCustomer ??
    projectRequest.requestedCustomer;

  const customerNumber =
    projectRequest?.customer?.number ??
    `(${
      projectRequest.pendingCustomer
        ? "Pending"
        : projectRequest.requestedCustomer
        ? "New Request"
        : ""
    })`;

  const buttonText = projectRequest.customer
    ? "Approve"
    : projectRequest.pendingCustomer
    ? "Approve With Pending Customer"
    : "Approve With Customer Request";

  return {
    customer: customer as Customer | ProjectRequestNewCustomer,
    customerNumber,
    buttonText,
  };
};
