import React, { ReactElement } from "react";
import { Checkbox, CheckboxProps } from "rmwc";
import { Body1 } from "@material/react-typography";
import HelperTextLine from "~/HelperTextLine";
import "./BinaryPicker.scss";

export interface BinaryPickerProps extends CheckboxProps {
  label: string;
  onChange: React.Dispatch<boolean>;
  helperText?: string;
  disabled?: boolean;
}

export default function BinaryPicker(props: BinaryPickerProps): ReactElement {
  const { helperText, label, checked, disabled, onChange, ...otherProps } =
    props;

  return (
    <section className="binary-picker form-field">
      <Body1 className={disabled ? "disabled" : ""}>{label}</Body1>
      <Checkbox
        className="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={() => onChange(!checked)}
        {...otherProps}
      />
      {helperText && <HelperTextLine isValid={true} message={helperText} />}
    </section>
  );
}
