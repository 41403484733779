import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProjectRequestsPage } from "./PendingRequests";
import { ProjectRequestForm } from "./ProjectRequestForm";
import { ProjectRequestRouter } from "./SingleRequest";
import { GroupReview } from "./GroupReview";

export const ProjectRequestsRouter: React.FC = () => (
  <Routes>
    <Route path="create" element={<ProjectRequestForm type="Create" />} />
    <Route path="review/*">
      <Route path=":groupId/*" element={<GroupReview />} />
    </Route>
    <Route path=":requestId/*" element={<ProjectRequestRouter />} />
    <Route index element={<ProjectRequestsPage />} />
  </Routes>
);
