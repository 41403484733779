import React, { useState } from "react";
import { TableCell, TableRowActions } from "~/table";
import moment from "moment";
import Card from "@material/react-card";
import { ScrollTo } from "~/scrollTo";
import { currencyFormatter } from "~/currencyFormatter";
import "./EmployeeRatesTable.scss";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { VisibilityListener } from "~/visibility-listener";
import { TextHighlighter } from "~/text-highlighter";
import { Body2 } from "@material/react-typography";
import { EditEmployeeRate } from "./EditEmployeeRate";
import { useDeleteEmployeeRate } from "./useDeleteEmployeeRate";
import { useResetSearchItems } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import Spinner from "~/spinner";
import { EmployeeRate } from "~/gql/types";
import { EmployeeRatesFilter } from "./types";

type EmployeeRatesTableProps = {
  employeeRates: EmployeeRate[] | null;
  state: State<EmployeeRate, EmployeeRatesFilter>;
  dispatch: React.Dispatch<Action<EmployeeRate, EmployeeRatesFilter>>;
};

export const EmployeeRateRow: React.FC<{ rate: EmployeeRate }> = ({ rate }) => {
  const Highlight = ({ text }) => (
    <Body2>
      <TextHighlighter {...{ text }} />
    </Body2>
  );
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const { deleteRate, loading } = useDeleteEmployeeRate();
  const resetRates = useResetSearchItems();

  return (
    <div className="row">
      <Spinner open={loading} />
      <TableCell>
        <Highlight text={rate.employee?.lastName} />
      </TableCell>
      <TableCell>
        <Highlight text={rate.employee?.firstName} />
      </TableCell>
      <TableCell text={moment(rate.effectiveDate).format("MM/DD/YYYY")} />
      <TableCell text={currencyFormatter.format(rate.rate)} />
      <TableRowActions
        options={[
          { text: "Edit", onClick: () => setEditOpen(true) },
          {
            text: "Delete",
            onClick: () => deleteRate({ reset: resetRates, rate }),
          },
        ]}
      />
      <EditEmployeeRate {...{ rate, open: editOpen, setOpen: setEditOpen }} />
    </div>
  );
};

export const EmployeeRatesTable: React.FC<EmployeeRatesTableProps> = ({
  employeeRates,
  state,
  dispatch,
}) => {
  return (
    <>
      <Card className="employee-rates-table">
        <div className="row header">
          <TableCell text="Last Name" />
          <TableCell text="First Name" />
          <TableCell text="Effective Date" />
          <TableCell text="Rate" />
          <TableCell text="Actions" />
        </div>
        {employeeRates?.map((rate, idx) => (
          <ScrollTo key={idx} value={rate.employee!.lastName} search="lastName">
            <EmployeeRateRow {...{ rate }} />
          </ScrollTo>
        ))}
        {state.showVisibility && (
          <VisibilityListener
            onShown={() => dispatch({ tag: "ScrolledToBottom" })}
          />
        )}
      </Card>
    </>
  );
};
