import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddEmployeeRoleMutationVariables = Types.Exact<{
  role: Types.Scalars['String'];
  userPrincipalName: Types.Scalars['String'];
}>;


export type AddEmployeeRoleMutation = { __typename?: 'MutationModel', employees?: { __typename?: 'EmployeeMutationModel', addRole?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type RemoveEmployeeRoleMutationVariables = Types.Exact<{
  role: Types.Scalars['String'];
  userPrincipalName: Types.Scalars['String'];
}>;


export type RemoveEmployeeRoleMutation = { __typename?: 'MutationModel', employees?: { __typename?: 'EmployeeMutationModel', removeRole?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const AddEmployeeRoleDocument = gql`
    mutation AddEmployeeRole($role: String!, $userPrincipalName: String!) {
  employees {
    addRole(role: $role, userPrincipalName: $userPrincipalName) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type AddEmployeeRoleMutationFn = Apollo.MutationFunction<AddEmployeeRoleMutation, AddEmployeeRoleMutationVariables>;

/**
 * __useAddEmployeeRoleMutation__
 *
 * To run a mutation, you first call `useAddEmployeeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmployeeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmployeeRoleMutation, { data, loading, error }] = useAddEmployeeRoleMutation({
 *   variables: {
 *      role: // value for 'role'
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useAddEmployeeRoleMutation(baseOptions?: Apollo.MutationHookOptions<AddEmployeeRoleMutation, AddEmployeeRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEmployeeRoleMutation, AddEmployeeRoleMutationVariables>(AddEmployeeRoleDocument, options);
      }
export type AddEmployeeRoleMutationHookResult = ReturnType<typeof useAddEmployeeRoleMutation>;
export type AddEmployeeRoleMutationResult = Apollo.MutationResult<AddEmployeeRoleMutation>;
export type AddEmployeeRoleMutationOptions = Apollo.BaseMutationOptions<AddEmployeeRoleMutation, AddEmployeeRoleMutationVariables>;
export const RemoveEmployeeRoleDocument = gql`
    mutation RemoveEmployeeRole($role: String!, $userPrincipalName: String!) {
  employees {
    removeRole(role: $role, userPrincipalName: $userPrincipalName) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type RemoveEmployeeRoleMutationFn = Apollo.MutationFunction<RemoveEmployeeRoleMutation, RemoveEmployeeRoleMutationVariables>;

/**
 * __useRemoveEmployeeRoleMutation__
 *
 * To run a mutation, you first call `useRemoveEmployeeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEmployeeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEmployeeRoleMutation, { data, loading, error }] = useRemoveEmployeeRoleMutation({
 *   variables: {
 *      role: // value for 'role'
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useRemoveEmployeeRoleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEmployeeRoleMutation, RemoveEmployeeRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEmployeeRoleMutation, RemoveEmployeeRoleMutationVariables>(RemoveEmployeeRoleDocument, options);
      }
export type RemoveEmployeeRoleMutationHookResult = ReturnType<typeof useRemoveEmployeeRoleMutation>;
export type RemoveEmployeeRoleMutationResult = Apollo.MutationResult<RemoveEmployeeRoleMutation>;
export type RemoveEmployeeRoleMutationOptions = Apollo.BaseMutationOptions<RemoveEmployeeRoleMutation, RemoveEmployeeRoleMutationVariables>;