import React from "react";
import { invoices } from "~/routes";
import { Invoice } from "~/gql/types";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";

export const InvoiceBreadcrumbs: React.FC<{ invoice: Invoice }> = ({
  invoice,
}) => {
  const breadcrumbs = invoice
    ? [
        {
          text: "Invoices",
          to: invoices.path,
        },
        {
          text: `Invoice ${invoice.invoiceNumber}`,
        },
      ]
    : [{ text: "Loading..." }];

  useBreadcrumbs(breadcrumbs, [invoice]);

  return <></>;
};
