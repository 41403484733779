import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEffectiveTaskRateSheetQueryVariables = Types.Exact<{
  projectNumber: Types.Scalars['Int'];
  asOf: Types.Scalars['LocalDate'];
}>;


export type GetEffectiveTaskRateSheetQuery = { __typename?: 'QueryModel', projects?: { __typename?: 'ProjectsQueryModel', effectiveTaskRateSheet?: { __typename?: 'TaskRateSheet', name: string, rates?: Array<{ __typename?: 'TaskRate', name: string, rateType: Types.TaskRateType, crewSize: number, requiresDaily: boolean } | null> | null } | null } | null };


export const GetEffectiveTaskRateSheetDocument = gql`
    query GetEffectiveTaskRateSheet($projectNumber: Int!, $asOf: LocalDate!) {
  projects {
    effectiveTaskRateSheet(asOf: $asOf, projectNumber: $projectNumber) {
      name
      rates {
        name
        rateType
        crewSize
        requiresDaily
      }
    }
  }
}
    `;

/**
 * __useGetEffectiveTaskRateSheetQuery__
 *
 * To run a query within a React component, call `useGetEffectiveTaskRateSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEffectiveTaskRateSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEffectiveTaskRateSheetQuery({
 *   variables: {
 *      projectNumber: // value for 'projectNumber'
 *      asOf: // value for 'asOf'
 *   },
 * });
 */
export function useGetEffectiveTaskRateSheetQuery(baseOptions: Apollo.QueryHookOptions<GetEffectiveTaskRateSheetQuery, GetEffectiveTaskRateSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEffectiveTaskRateSheetQuery, GetEffectiveTaskRateSheetQueryVariables>(GetEffectiveTaskRateSheetDocument, options);
      }
export function useGetEffectiveTaskRateSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEffectiveTaskRateSheetQuery, GetEffectiveTaskRateSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEffectiveTaskRateSheetQuery, GetEffectiveTaskRateSheetQueryVariables>(GetEffectiveTaskRateSheetDocument, options);
        }
export type GetEffectiveTaskRateSheetQueryHookResult = ReturnType<typeof useGetEffectiveTaskRateSheetQuery>;
export type GetEffectiveTaskRateSheetLazyQueryHookResult = ReturnType<typeof useGetEffectiveTaskRateSheetLazyQuery>;
export type GetEffectiveTaskRateSheetQueryResult = Apollo.QueryResult<GetEffectiveTaskRateSheetQuery, GetEffectiveTaskRateSheetQueryVariables>;