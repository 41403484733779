import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { SimplePicker } from "~/visuals/organisms/SimplePicker";
import { Suggestion } from "~/ultra-filter/types";

export const payPeriodOptions = [
  { text: "Weekly", value: "Weekly" },
  { text: "Biweekly", value: "Biweekly" },
] as Suggestion[];

export const PayPeriodPicker: React.FC<FormFieldProps> = (props) => (
  <SimplePicker {...{ ...props, options: payPeriodOptions }} />
);
