import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import ClickSwallower from "~/click-swallower";
import Spinner from "~/spinner";
import { useSnackBar } from "~/snackbar";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import FormBottomRow from "~/form-bottom-row";
import { Employee, FieldOffice, ValidationResponse } from "~/gql/types";
import { useUpdateFieldOfficeMutation } from "./updateFieldOffice.generated";
import FieldOfficePickerFormField from "~/visuals/organisms/FieldOfficePickerFormField";
import "./EmployeeFieldOffice.scss";
import { Body1 } from "@material/react-typography";
import MaterialIcon from "@material/react-material-icon";
import { useUser } from "~/Auth/useUser";

export type EmployeeFieldOfficeProps = {
  employee: Employee;
  reset: () => void;
};

export const EmployeeFieldOffice: React.FC<EmployeeFieldOfficeProps> = ({
  employee,
  reset,
}) => {
  const [open, setOpen] = useState(false);
  const alert = useSnackBar();
  const user = useUser();

  const employeeName = `${employee.firstName} ${employee.lastName}`;

  const initialValues = {
    fieldOffice: employee.fieldOffice ?? null,
  };

  const onSuccess = () => {
    alert({
      message: `${employeeName} field office updated`,
      isSuccess: true,
      key: `${Math.random()}`,
    });
    reset();
    setOpen(false);
  };

  const [doMutation, { loading: mutationLoading }] =
    useUpdateFieldOfficeMutation();

  const runMutation = async (values: { fieldOffice: FieldOffice | null }) => {
    const variables = {
      userPrincipalName: employee.userPrincipalName,
      fieldOffice: values?.fieldOffice?.officeCode ?? null,
    };

    const result = await doMutation({ variables });

    return result?.data?.employees?.updateFieldOffice as ValidationResponse;
  };

  const isAuthorized = user.authorizations.updateEmployeeFieldOffice;

  const onClick = () => {
    if (isAuthorized) {
      setOpen(true);
    }
  };

  return (
    <>
      <div
        role="button"
        className="employee-field-office-button"
        onClick={onClick}
      >
        <Body1 title="Office Code">
          <MaterialIcon icon={"business"} />
          Office Code: {employee?.fieldOffice?.officeCode ?? "(unset)"}
        </Body1>
      </div>
      <ClickSwallower>
        <Dialog
          className="employee-field-office"
          open={open}
          onClose={() => setOpen(false)}
          data-open={open}
          portalize
        >
          <DialogTitle>{`${employeeName} Office Code`}</DialogTitle>
          <DialogContent>
            {open && (
              <MutationForm
                initialValues={initialValues}
                runMutation={runMutation}
                allowPristineSubmit
                onSuccess={onSuccess}
              >
                <ErrorRenderer
                  render={(error) => (
                    <>
                      <FieldOfficePickerFormField
                        formField="fieldOffice"
                        label="Office Code"
                        helperText="Select an office code"
                      />
                      <FormBottomRow
                        errorMessage={error}
                        buttonText="Save"
                        onCancel={() => setOpen(false)}
                      />
                    </>
                  )}
                />
              </MutationForm>
            )}
          </DialogContent>
        </Dialog>
      </ClickSwallower>
      <Spinner open={mutationLoading} />
    </>
  );
};
