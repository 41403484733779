import React from "react";
import { MileageExpenseRate } from "~/gql/types";
import MileageForm, {
  calculateMileage,
} from "~/visuals/organisms/Expenses/ExpenseForm/MileageTypeForm";
import { MileageExpense } from "~/gql/types";
import { useWorkDayApi } from "~/WorkDayApi";
import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";
import { ValueRenderer } from "~/forms/ValueContext";

type EditMileageProps = BaseExpenseFormProps & {
  expenseRate: MileageExpenseRate;
  expenseEntry: MileageExpense;
};

const EditMileageExpense: React.FC<EditMileageProps> = (props) => {
  const { expenseEntry, project } = props;

  const { editMileageExpense } = useWorkDayApi();

  const parseNum = (val: any) => (val ? parseFloat(val) : null);

  const editExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc
  ) => {
    const res = await editMileageExpense({
      ...defaultVals(values),
      beginningOdometer: parseNum(values.beginningOdometer)!,
      endingOdometer: parseNum(values.endingOdometer)!,
      vehicleNumber: values.vehicleNumber,
      companyVehicle: values.companyVehicle ?? false,
      expenseId: expenseEntry.id,
      state: project?.location?.state!,
      county: project?.location?.county!,
      payableOverride: values?.payableOverride || null,
    });

    return res;
  };

  const uniqueInitVals: UniqueInitVals = [
    {
      beginningOdometer: expenseEntry.beginningOdometer,
      endingOdometer: expenseEntry.endingOdometer,
      vehicleNumber: expenseEntry.vehicleNumber,
      companyVehicle: expenseEntry.companyVehicle ?? false,
      location: expenseEntry.location ?? null,
    },
  ];

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        uniqueInitVals,
        handleSubmit: editExpense,
        expenseType: "Mileage",
        formType: "Edit",
      }}
    >
      <ValueRenderer
        render={(values) => (
          <MileageForm {...props} mileage={calculateMileage(values)} />
        )}
      />
    </ExpensePageWrapper>
  );
};

export default EditMileageExpense;
