import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveProjectRequestReviewerMutationVariables = Types.Exact<{
  userPrincipalName: Types.Scalars['String'];
  officeCode: Types.Scalars['String'];
}>;


export type RemoveProjectRequestReviewerMutation = { __typename?: 'MutationModel', fieldOffices?: { __typename?: 'FieldOfficeMutationModel', removeProjectRequestReviewer?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const RemoveProjectRequestReviewerDocument = gql`
    mutation RemoveProjectRequestReviewer($userPrincipalName: String!, $officeCode: String!) {
  fieldOffices {
    removeProjectRequestReviewer(
      userPrincipalName: $userPrincipalName
      officeCode: $officeCode
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type RemoveProjectRequestReviewerMutationFn = Apollo.MutationFunction<RemoveProjectRequestReviewerMutation, RemoveProjectRequestReviewerMutationVariables>;

/**
 * __useRemoveProjectRequestReviewerMutation__
 *
 * To run a mutation, you first call `useRemoveProjectRequestReviewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectRequestReviewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectRequestReviewerMutation, { data, loading, error }] = useRemoveProjectRequestReviewerMutation({
 *   variables: {
 *      userPrincipalName: // value for 'userPrincipalName'
 *      officeCode: // value for 'officeCode'
 *   },
 * });
 */
export function useRemoveProjectRequestReviewerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProjectRequestReviewerMutation, RemoveProjectRequestReviewerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveProjectRequestReviewerMutation, RemoveProjectRequestReviewerMutationVariables>(RemoveProjectRequestReviewerDocument, options);
      }
export type RemoveProjectRequestReviewerMutationHookResult = ReturnType<typeof useRemoveProjectRequestReviewerMutation>;
export type RemoveProjectRequestReviewerMutationResult = Apollo.MutationResult<RemoveProjectRequestReviewerMutation>;
export type RemoveProjectRequestReviewerMutationOptions = Apollo.BaseMutationOptions<RemoveProjectRequestReviewerMutation, RemoveProjectRequestReviewerMutationVariables>;