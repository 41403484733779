import React from "react";
import Button from "~/button";
import { useConfirmationPrompt } from "~/confirmation-prompt";

type WorkWeekActionProps = {
  action: React.DispatchWithoutAction;
  label: string;
  primary?: boolean;
  outlined?: boolean;
};

export const WorkWeekAction: React.FC<WorkWeekActionProps> = (props) => {
  const { action, label, primary, outlined } = props;

  const prompt = useConfirmationPrompt();
  const lcLabel = label.toLowerCase();

  const showPrompt = async () => {
    const promptResult = await prompt({
      cancelButtonText: "Cancel",
      confirmButtonText: `Yes, ${lcLabel} work week`,
      message: `Are you sure you want to ${lcLabel} this work week?`,
      title: `${label} work week`,
    });

    if (promptResult !== "Confirm") {
      return;
    }

    await action();
  };

  return (
    <Button primary={primary} outlined={outlined} onClick={showPrompt}>
      {label}
    </Button>
  );
};
