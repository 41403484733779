import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

export type SearchRestoreRouterProps = {
  searchPage: JSX.Element;
  nestedPages: JSX.Element;
};

const SearchRestorer = ({ children, lastSearch, setLastSearch }) => {
  const location = useLocation();
  const [prevPath, setPrevPath] = useState("");

  useEffect(() => {
    setLastSearch(location.search);
    setPrevPath(location.pathname);
  }, [location.search, location.pathname, setLastSearch]);

  if (
    location.search === "" &&
    lastSearch !== "" &&
    location.pathname !== prevPath
  ) {
    return <Navigate to={`${location.pathname}${lastSearch}`} />;
  }

  return <>{children}</>;
};

export const SearchRestoreRouter: React.FC<SearchRestoreRouterProps> = ({
  searchPage,
  nestedPages,
}) => {
  const location = useLocation();
  const [lastSearch, setLastSearch] = useState(location.search);

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <SearchRestorer {...{ lastSearch, setLastSearch }}>
              {searchPage}
            </SearchRestorer>
          }
        />
        <Route path="*" element={nestedPages} />
      </Routes>
    </>
  );
};
