import React, { createContext, useContext, useState } from "react";
import { State } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import _ from "lodash";
import { ProjectCharge } from "~/gql/types";
import { FilterOptions, SearchStats } from "./types";

type ContextType = {
  state: State<ProjectCharge, FilterOptions> | null;
  setState: React.Dispatch<State<ProjectCharge, FilterOptions> | null>;
  stats: SearchStats | null;
  setStats: React.Dispatch<SearchStats | null>;
  statsText: string;
};

export const SearchStatsContext = createContext<ContextType>({
  state: null,
  setState: () => null,
  stats: null,
  setStats: () => null,
  statsText: "",
});

export const SearchStatsProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<State<
    ProjectCharge,
    FilterOptions
  > | null>(null);
  const [stats, setStats] = useState<SearchStats | null>(null);

  let statsText = "";

  if (state && stats) {
    const projectsLoaded = _.uniqBy(state?.items ?? [], "projectId").length;
    const chargesLoaded = state?.items.length ?? 0;
    statsText = `${projectsLoaded} of ${stats.projectTotal} projects (${chargesLoaded} of ${stats.chargeTotal} charges) loaded`;
  }

  return (
    <SearchStatsContext.Provider
      value={{ state, setState, stats, setStats, statsText }}
    >
      {children}
    </SearchStatsContext.Provider>
  );
};

export function useSearchStats() {
  const context = useContext(SearchStatsContext);
  return context;
}
