import { ProjectRequest } from "~/gql/types";
import { getTimeSince } from "~/utils/getTimeSince";

export const getCheckedOutBy = (request: ProjectRequest): string => {
  if (!request.checkedOutBy) {
    return "";
  }

  return `${request.checkedOutBy!.firstName} ${
    request.checkedOutBy!.lastName
  } (${getTimeSince(request.lastCheckoutTime).elapsed})`;
};
