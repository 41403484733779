import { Invoice, ProjectCharge } from "~/gql/types";
import { DraftInvoice, LoadedDraftInvoice } from "./types";

export type ItemGroup<T> = {
  groupId: string | number;
  items: T[];
  draftInvoices: DraftInvoice[];
  selectedInvoice?: string | null;
};

export type CoreState<T> = {
  itemGroups: ItemGroup<T>[];
  tag: string;
  loading: boolean;
};

export type LoadingDraftInvoices<T> = CoreState<T> & {
  tag: "LoadingDraftInvoices";
};

export type PromptingForConfirmation<T> = CoreState<T> & {
  tag: "PromptingForConfirmation";
};

export type SelectingGroupInvoices<T> = CoreState<T> & {
  tag: "SelectingGroupInvoices";
  currentGroupId: string | number;
};

export type ReadyForFinalSubmit<T> = CoreState<T> & {
  tag: "ReadyForFinalSubmit";
};

export type FinalSubmitting<T> = CoreState<T> & {
  tag: "FinalSubmitting";
};

export type AllInvoicesPosted<T> = CoreState<T> & {
  tag: "AllInvoicesPosted";
};

export type PostToInvoiceState<T> =
  | LoadingDraftInvoices<T>
  | PromptingForConfirmation<T>
  | SelectingGroupInvoices<T>
  | ReadyForFinalSubmit<T>
  | FinalSubmitting<T>
  | AllInvoicesPosted<T>;

export type DraftInvoicesLoaded = {
  tag: "DraftInvoicesLoaded";
  draftInvoices: LoadedDraftInvoice[];
};

export type PromptConfirmed = { tag: "PromptConfirmed" };

export type GroupInvoiceSelected = {
  tag: "GroupInvoiceSelected";
  groupId: string | number;
  invoiceNumber: string | null;
};

export type SendingFinalSubmission = {
  tag: "SendingFinalSubmission";
};

export type FinalSubmissionCompleted = {
  tag: "FinalSubmissionCompleted";
};

export type Reset = {
  tag: "Reset";
};

export type PostToInvoiceAction =
  | DraftInvoicesLoaded
  | PromptConfirmed
  | GroupInvoiceSelected
  | SendingFinalSubmission
  | FinalSubmissionCompleted;

const reduceLoadingDraftInvoices = <T>(
  state: LoadingDraftInvoices<T>,
  action: PostToInvoiceAction
): PostToInvoiceState<T> => {
  if (action.tag === "DraftInvoicesLoaded") {
    const newGroups = state.itemGroups.map((g) => ({
      ...g,
      draftInvoices: action.draftInvoices
        .filter((x) => x.groupId === g.groupId)
        .map((x) => x.invoice),
    }));

    const groupsWithNoDraftInvoices = newGroups
      .filter((x) => x.draftInvoices.length === 0)
      .map((x) => ({ ...x, selectedInvoice: null }));

    const groupsWithExistingDraftInvoices = newGroups.filter(
      (x) => x.draftInvoices.length > 0
    );

    const newState = {
      ...state,
      itemGroups: [
        ...groupsWithNoDraftInvoices,
        ...groupsWithExistingDraftInvoices,
      ],
    };

    return {
      ...newState,
      tag: "PromptingForConfirmation",
      loading: false,
    };
  }

  return state;
};

const reducePromptingForConfirmation = <T>(
  state: PromptingForConfirmation<T>,
  action: PostToInvoiceAction
): PostToInvoiceState<T> => {
  if (action.tag === "PromptConfirmed") {
    const itemsWithExistingDrafts = state.itemGroups.filter(
      (x) => x.draftInvoices.length > 0
    );

    if (itemsWithExistingDrafts.length > 0) {
      return {
        ...state,
        tag: "SelectingGroupInvoices",
        currentGroupId: itemsWithExistingDrafts[0].groupId,
      };
    }

    return {
      ...state,
      tag: "ReadyForFinalSubmit",
    };
  }

  return state;
};

const reduceSelectingGroupInvoices = <T>(
  state: SelectingGroupInvoices<T>,
  action: PostToInvoiceAction
): PostToInvoiceState<T> => {
  if (action.tag === "GroupInvoiceSelected") {
    const updatedGroups = [
      ...state.itemGroups.filter((x) => x.groupId !== action.groupId),
      {
        ...state.itemGroups.find((x) => x.groupId === action.groupId)!,
        selectedInvoice: action.invoiceNumber,
      },
    ];

    const newState = {
      ...state,
      itemGroups: updatedGroups,
    };

    const nextGroupToHandle = updatedGroups.find(
      (x) => x.selectedInvoice === undefined
    );

    if (nextGroupToHandle) {
      return {
        ...newState,
        tag: "SelectingGroupInvoices",
        itemGroups: updatedGroups,
        currentGroupId: nextGroupToHandle.groupId,
        loading: false,
      };
    }

    return {
      ...newState,
      tag: "ReadyForFinalSubmit",
      loading: false,
    };
  }

  return state;
};

const reduceReadyForFinalSubmit = <T>(
  state: ReadyForFinalSubmit<T>,
  action: PostToInvoiceAction
): PostToInvoiceState<T> => {
  if (action.tag === "SendingFinalSubmission") {
    return {
      ...state,
      tag: "FinalSubmitting",
      loading: true,
    };
  }

  return state;
};

const reduceFinalSubmitting = <T>(
  state: FinalSubmitting<T>,
  action: PostToInvoiceAction
): PostToInvoiceState<T> => {
  if (action.tag === "FinalSubmissionCompleted") {
    return {
      ...state,
      tag: "AllInvoicesPosted",
      loading: false,
    };
  }

  return state;
};

export const reducer = <T>(
  state: PostToInvoiceState<T>,
  action: PostToInvoiceAction
): PostToInvoiceState<T> => {
  if (state.tag === "LoadingDraftInvoices") {
    return reduceLoadingDraftInvoices(state, action);
  }
  if (state.tag === "SelectingGroupInvoices") {
    return reduceSelectingGroupInvoices(state, action);
  }
  if (state.tag === "ReadyForFinalSubmit") {
    return reduceReadyForFinalSubmit(state, action);
  }
  if (state.tag === "PromptingForConfirmation") {
    return reducePromptingForConfirmation(state, action);
  }
  if (state.tag === "FinalSubmitting") {
    return reduceFinalSubmitting(state, action);
  }

  return state;
};

export type BulkPostToInvoiceState = PostToInvoiceState<
  ProjectCharge | Invoice
>;
