import React, { useEffect, useState } from "react";
import { PostToInvoiceAction, PostToInvoiceState } from "./stateMachine";
import { PostToInvoicesArgs } from "./types";
import { toErrorMessage } from "~/validation-response-utils";
import { useSnackBar } from "~/snackbar";
import { ValidationResponse } from "~/gql/types";
import { Dialog, DialogContent } from "~/dialog";
import { Body2 } from "@material/react-typography";
import Button from "~/button";
import "./FinalSubmitHandler.scss";

type FinalSubmitHandlerProps<T, TResponse> = {
  state: PostToInvoiceState<T>;
  postToInvoices: (args: PostToInvoicesArgs<T>[]) => Promise<TResponse>;
  onSuccess: (response: TResponse) => Promise<string>;
  onClose: () => void;
  dispatch: React.Dispatch<PostToInvoiceAction>;
};

export const FinalSubmitHandler = <T, TResponse>(
  props: FinalSubmitHandlerProps<T, TResponse>
): React.ReactElement => {
  const { state, postToInvoices, onSuccess, dispatch, onClose } = props;
  const [message, setMessage] = useState<string | null>(null);

  const addAlert = useSnackBar();

  const onDialogClose = () => {
    setMessage(null);
    onClose();
  };

  useEffect(() => {
    if (state.tag === "ReadyForFinalSubmit") {
      void (async () => {
        dispatch({ tag: "SendingFinalSubmission" });
        const result = await postToInvoices(
          state.itemGroups.map((x) => ({
            invoiceNumber: x.selectedInvoice as string | null,
            items: x.items,
          }))
        );

        dispatch({ tag: "FinalSubmissionCompleted" });

        const errorMessage = toErrorMessage(result as ValidationResponse);

        if (errorMessage) {
          addAlert({
            key: `${Math.random()}`,
            message: errorMessage,
            isSuccess: false,
          });

          return;
        }

        const message = await onSuccess(result);

        setMessage(message);
      })();
    }
  }, [state.tag]);

  return (
    <Dialog
      className={"bulk-post-message"}
      open={!!message}
      onClose={onDialogClose}
      data-open={!!message}
    >
      <DialogContent>
        {message &&
          message.split(/\n/).map((x, i) => <Body2 key={i}>{x}</Body2>)}
        <Button className="close-button" onClick={() => onDialogClose()}>
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
};
