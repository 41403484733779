import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetChargeStatsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetChargeStatsQuery = { __typename?: 'QueryModel', projectCharges?: { __typename?: 'ProjectChargesQueryModel', roleStats?: { __typename?: 'ProjectChargeRoleStats', myPmApproval: number, myPmApprovalProjects: number, myPsApproval: number, myPsApprovalProjects: number, myBaApproval: number, myBaApprovalProjects: number, allPmApproval?: number | null, allPmApprovalProjects?: number | null, allPsApproval?: number | null, allPsApprovalProjects?: number | null, allBaApproval?: number | null, allBaApprovalProjects?: number | null, showIfEmpty: boolean } | null } | null };


export const GetChargeStatsDocument = gql`
    query GetChargeStats {
  projectCharges {
    roleStats {
      myPmApproval
      myPmApprovalProjects
      myPsApproval
      myPsApprovalProjects
      myBaApproval
      myBaApprovalProjects
      allPmApproval
      allPmApprovalProjects
      allPsApproval
      allPsApprovalProjects
      allBaApproval
      allBaApprovalProjects
      showIfEmpty
    }
  }
}
    `;

/**
 * __useGetChargeStatsQuery__
 *
 * To run a query within a React component, call `useGetChargeStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargeStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargeStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChargeStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetChargeStatsQuery, GetChargeStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChargeStatsQuery, GetChargeStatsQueryVariables>(GetChargeStatsDocument, options);
      }
export function useGetChargeStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChargeStatsQuery, GetChargeStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChargeStatsQuery, GetChargeStatsQueryVariables>(GetChargeStatsDocument, options);
        }
export type GetChargeStatsQueryHookResult = ReturnType<typeof useGetChargeStatsQuery>;
export type GetChargeStatsLazyQueryHookResult = ReturnType<typeof useGetChargeStatsLazyQuery>;
export type GetChargeStatsQueryResult = Apollo.QueryResult<GetChargeStatsQuery, GetChargeStatsQueryVariables>;