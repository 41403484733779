import React, { useState } from "react";
import moment, { Moment } from "moment";
import { State } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { FilterOptions } from "./FilterForm";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import { Project } from "~/gql/types";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import Spinner from "~/spinner";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";
import FormBottomRow from "~/form-bottom-row";
import Button from "~/button";
import { ClientForm } from "~/forms/ClientForm";

export type ProjectCsvExporterProps = {
  state: State<Project, FilterOptions>;
  setLoading: React.Dispatch<boolean>;
};

type FormValues = {
  rateSheetDate: Moment | null;
};

export const ProjectCsvExporter: React.FC<{
  state: State<Project, FilterOptions>;
}> = ({ state }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const downloadReport = useReportFetcher();

  const submit = async (values: FormValues) => {
    const {
      searchText,
      billingAdmin,
      projectManager,
      projectSupervisor,
      officeCode,
      projectType,
      division,
      corpLocation,
      customer,
      dateInEqual,
      dateInBefore,
      dateInAfter,
      locationState,
      afeWoPo,
      status,
      stateCounty,
      clientContactName,
      projectGroup,
      sortBy,
      certifiedPayroll,
    } = state.filterOptions;

    const formattedDate = (date: Moment | null) =>
      date ? date.format("YYYY-MM-DD") : null;

    const filterBody = {
      searchText: searchText || null,
      billingAdmin: billingAdmin?.userPrincipalName || null,
      projectManager: projectManager?.userPrincipalName || null,
      projectSupervisor: projectSupervisor?.userPrincipalName || null,
      officeCode: officeCode?.officeCode ?? null,
      projectType: projectType ?? null,
      division: division?.id ?? null,
      corpLocation: corpLocation?.id ?? null,
      customerNumber: customer?.number ?? null,
      dateInEqual: formattedDate(dateInEqual),
      dateInBefore: formattedDate(dateInBefore),
      dateInAfter: formattedDate(dateInAfter),
      state: stateCounty ? stateCounty.state : locationState || null,
      afeWoPo: afeWoPo || null,
      status: status || null,
      county: stateCounty?.county || null,
      clientContactName: clientContactName || null,
      asOf: values.rateSheetDate
        ? values.rateSheetDate.format("YYYY-MM-DD")
        : null,
      groupName: projectGroup?.name ?? null,
      groupCustomerNumber: projectGroup?.customer?.number || null,
      sortBy,
      certifiedPayroll: certifiedPayroll === "Yes",
    };

    await downloadReport({
      setLoading,
      path: "projects-csv",
      fileName: `projects ${moment().format("YYYY-MM-DD")}`,
      body: filterBody,
      type: "text/csv;charset=utf-8;",
    });

    setOpen(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>Export Excel...</Button>
      <Dialog
        className="project-csv-dialog"
        open={open}
        onClose={() => setOpen(false)}
        data-open={open}
      >
        <DialogTitle>Select Effective Date for Project Report</DialogTitle>
        <DialogContent>
          {open && (
            <ClientForm
              onSuccess={submit}
              initialValues={{ rateSheetDate: moment() }}
              allowPristineSubmit={true}
            >
              <DatePickFormField
                formField="rateSheetDate"
                label="Effective Date"
                helperText=""
              />
              <FormBottomRow
                errorMessage={""}
                buttonText="Export"
                onCancel={() => setOpen(false)}
              />
            </ClientForm>
          )}
        </DialogContent>
      </Dialog>
      <Spinner open={loading} />
    </>
  );
};
