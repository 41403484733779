import React from "react";
import { Button } from "rmwc";
import { useTextPrompt } from "~/confirmation-prompt";
import { ValidationError } from "~/gql/types";
import { useSnackBar } from "~/snackbar";
import { useRejectThirdPartyInvoiceMutation } from "./reject.generated";
import { useUser } from "~/Auth/useUser";
type ButtonProps = {
  invoiceNumber: string;
  refetch: () => void;
};
export const RejectButton: React.FC<ButtonProps> = ({
  invoiceNumber,
  refetch,
}) => {
  const user = useUser();

  const [doMutation] = useRejectThirdPartyInvoiceMutation();

  const textPrompt = useTextPrompt();
  const addAlert = useSnackBar();

  const handleCancel = async (reason: string) => {
    const result = await doMutation({
      variables: {
        invoiceNumber,
        reason,
      },
    });

    const res = result?.data?.thirdPartyInvoices?.reject;
    if (res?.errors && res.errors.length > 0) {
      const errors = res.errors as ValidationError[];
      const formErrors: string[] = [];
      errors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          formErrors.push(`${argumentName}: ${message}`);
        } else if (message) {
          formErrors.push(message);
        }
      });
      addAlert({
        key: `${invoiceNumber}`,
        message: formErrors.join(" "),
        isSuccess: false,
      });
    } else {
      addAlert({
        key: `${invoiceNumber}`,
        message: `${invoiceNumber} rejected`,
        isSuccess: true,
      });

      refetch();
    }
  };

  const openPrompt = async () => {
    const promptResult = await textPrompt({
      cancelButtonText: "Don't Reject",
      confirmButtonText: "Yes, Reject",
      message: "Are you sure you want to reject this?",
      title: `Third Party Invoice ${invoiceNumber}`,
      fieldName: "Reason",
      helperText: "Explain the reason for rejecting this third party invoice",
    });

    if (promptResult == "Cancel") {
      return;
    }

    await handleCancel(promptResult.message);
  };
  if (!user.authorizations.approveThirdPartyInvoices) {
    return null;
  }
  return <Button onClick={openPrompt}>Reject</Button>;
};
