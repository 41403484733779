import React from "react";
import Card, { CardPrimaryContent } from "@material/react-card";
import { Headline6, Body1 } from "@material/react-typography";
import classnames from "classnames";
import "./TitledCard.scss";

export type TitledCardProps = {
  title: React.ReactChild;
  actions?: React.ReactChild;
  className?: string;
  emptyMessage?: string;
  children?: React.ReactNode;
  isEmpty?: boolean;
  collapsed?: boolean;
  subtitle?: React.ReactChild;
  subtitleClassName?: string;
  handleClick?: () => void;
};

const TitledCard: React.FC<TitledCardProps> = ({
  title,
  actions,
  children,
  className,
  emptyMessage,
  isEmpty,
  subtitle,
  subtitleClassName,
  collapsed,
  handleClick,
}) => {
  const cn = classnames("titled-card", className, { collapsed: collapsed });
  const subtitleCn = classnames("subtitle", subtitleClassName);

  const displayEmpty = isEmpty === true || React.Children.count(children) === 0;

  const content = (
    <>
      <Headline6 className="titled-card--title">
        <span className="actions">{actions}</span>
        {title}
        {subtitle && <span className={subtitleCn}>{subtitle}</span>}
      </Headline6>

      {displayEmpty && (
        <Body1 className="titled-card--empty-text">
          {emptyMessage || "(empty)"}
        </Body1>
      )}

      {!displayEmpty && children}
    </>
  );

  return (
    <>
      {handleClick ? (
        <Card className={cn}>
          <CardPrimaryContent onClick={handleClick}>
            {content}
          </CardPrimaryContent>
        </Card>
      ) : (
        <Card className={cn}>{content}</Card>
      )}
    </>
  );
};

export default TitledCard;
