import { FORM_ERROR } from "final-form";

type HandleProps = {
  data: any;
  onSuccess: (data?: any) => any;
};

export const handleFormResult = ({
  data,
  onSuccess,
}: HandleProps): Record<any, any> | void => {
  if (data && data.errors.length > 0) {
    const errors = {};
    const messages: string[] = [];
    data.errors.forEach(({ argumentName, message }) => {
      if (argumentName) {
        errors[argumentName] = message;
      } else if (message) {
        messages.push(message);
        errors[FORM_ERROR] = messages.join(" ");
      }
    });
    return errors;
  } else {
    onSuccess(data);
  }
};
