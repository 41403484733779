import React from "react";
import { Link } from "react-router-dom";
import { viewExpenseRateSheet, viewTaskRateSheet } from "~/routes";
import { DateDisplay } from "~/visuals/pages/Project/ProjectDetails/types";
import { Project } from "~/gql/types";

export const RateSheetInfo: React.FC<{ project: Project }> = ({ project }) => {
  const taskRateSheets = project.taskRateSheets!.map((x) => ({
    effectiveDate: x!.effectiveDate,
    name: x!.rateSheet!.name,
  }));
  const expenseRateSheets = project.expenseRateSheets!.map((x) => ({
    effectiveDate: x!.effectiveDate,
    name: x!.rateSheet!.name,
  }));

  const taskRateElems =
    taskRateSheets.length === 0 ? (
      <>
        <dt>Task Rate Sheets</dt>
        <dd>(none assigned)</dd>
      </>
    ) : (
      taskRateSheets.map((sheet, idx) => (
        <React.Fragment key={idx}>
          <dt>{idx === 0 && "Task Rate Sheets"}</dt>
          <dd>
            <Link to={viewTaskRateSheet.toRoute(sheet.name).path}>
              {sheet.name}{" "}
              <span className="tiny">
                (effective <DateDisplay date={sheet.effectiveDate} />)
              </span>
            </Link>
          </dd>
        </React.Fragment>
      ))
    );
  const expenseRateElems =
    expenseRateSheets.length === 0 ? (
      <>
        <dt>Expense Rate Sheets</dt>
        <dd>(none assigned)</dd>
      </>
    ) : (
      expenseRateSheets.map((sheet, idx) => (
        <React.Fragment key={idx}>
          <dt>{idx === 0 && "Expense Rate Sheets"}</dt>
          <dd>
            <Link to={viewExpenseRateSheet.toRoute(sheet.name).path}>
              {sheet.name}{" "}
              <span className="tiny">
                (effective <DateDisplay date={sheet.effectiveDate} />)
              </span>
            </Link>
          </dd>
        </React.Fragment>
      ))
    );

  return (
    <>
      {taskRateElems}
      {expenseRateElems}
    </>
  );
};
