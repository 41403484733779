import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useWorkDayApi } from "~/WorkDayApi";
import { Expense } from "~/WorkDayApi/expenseTypes";
import { useUpdateExpense } from "~/visuals/pages/EditExpenseEntry/useUpdateExpense";
import {
  ExpenseRouter,
  ExpenseRouterProps,
} from "~/visuals/organisms/Expenses/ExpenseRouter";

type CloneExpenseEntryProps = {
  date: string;
  expenseEntry: Expense;
};

const CloneExpenseEntry: React.FC<CloneExpenseEntryProps> = ({
  date,
  expenseEntry,
}) => {
  const props = useUpdateExpense({
    date,
    expenseEntry,
    formType: "Clone",
  }) as ExpenseRouterProps;
  return <ExpenseRouter {...props} />;
};

const CloneExpense: React.FC = () => {
  const { expenseId, date } = useParams<{ expenseId: string; date: string }>();
  const { workDay } = useWorkDayApi();

  const expenseEntry = workDay.expenses?.find(
    (entry) => entry?.id === expenseId
  );

  if (!expenseEntry) {
    return <Navigate to="../../" />;
  }

  return (
    <CloneExpenseEntry date={date!} expenseEntry={expenseEntry as Expense} />
  );
};

export default CloneExpense;
