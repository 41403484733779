export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Decimal: number;
  Duration: string;
  Instant: any;
  LocalDate: string;
  LocalTime: string;
};

export type AdHocExpense = IExpense & {
  __typename?: 'AdHocExpense';
  billableAmount: Scalars['Decimal'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  expenseAccount?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<ExpenseHistoryItem>>>;
  id: Scalars['String'];
  importEdited: Scalars['Boolean'];
  invoiceLabel?: Maybe<Scalars['String']>;
  lastAdminNotes?: Maybe<Scalars['String']>;
  location?: Maybe<StateCounty>;
  notes?: Maybe<Scalars['String']>;
  payable: Payable;
  payableAmount: Scalars['Decimal'];
  payableOverridden: Scalars['Boolean'];
  project?: Maybe<Project>;
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  rateName: Scalars['String'];
  rateSheetName: Scalars['String'];
  receiptImageId?: Maybe<Scalars['String']>;
  receiptImageUrl?: Maybe<Scalars['String']>;
};

export type AdHocExpenseRate = IExpenseRate & {
  __typename?: 'AdHocExpenseRate';
  expenseAccount?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  invoiceLabel?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rate?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<RateUnit>;
};

export type AddChargesResponse = {
  __typename?: 'AddChargesResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  invoiceNumber?: Maybe<Scalars['String']>;
};

export type AddChargesResult = {
  __typename?: 'AddChargesResult';
  chargeCount: Scalars['Int'];
  invoiceNumber: Scalars['String'];
  projectId: Scalars['Int'];
};

export type AddChargesToInvoiceArgsInput = {
  charges?: InputMaybe<Array<Scalars['Int']>>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
};

export type AddInvoicesToRollupResult = {
  __typename?: 'AddInvoicesToRollupResult';
  invoiceCount: Scalars['Int'];
  invoiceNumber: Scalars['String'];
  projectGroup: Scalars['String'];
};

export type AppNotification = {
  __typename?: 'AppNotification';
  createdOn: Scalars['Instant'];
  id: Scalars['ID'];
  readOn?: Maybe<Scalars['Instant']>;
  recipient?: Maybe<Employee>;
  subjectId: Scalars['String'];
  subjectType: SubjectType;
};

export type AppNotificationMutationModel = {
  __typename?: 'AppNotificationMutationModel';
  markAllRead?: Maybe<ValidationResponse>;
  markRead?: Maybe<ValidationResponse>;
};


export type AppNotificationMutationModelMarkReadArgs = {
  notificationId: Scalars['String'];
};

export type AppNotificationQueryModel = {
  __typename?: 'AppNotificationQueryModel';
  getNotifications?: Maybe<NotificationsResult>;
};

export type ApproveableTimesheetCountResponse = {
  __typename?: 'ApproveableTimesheetCountResponse';
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type AssignRateSheetArgsInput = {
  effectiveDate: Scalars['LocalDate'];
  rateSheetName: Scalars['String'];
};

export type AtvExpense = IExpense & {
  __typename?: 'AtvExpense';
  billableAmount: Scalars['Decimal'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  expenseAccount?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<ExpenseHistoryItem>>>;
  id: Scalars['String'];
  importEdited: Scalars['Boolean'];
  invoiceLabel?: Maybe<Scalars['String']>;
  lastAdminNotes?: Maybe<Scalars['String']>;
  location?: Maybe<StateCounty>;
  notes?: Maybe<Scalars['String']>;
  payable: Payable;
  payableAmount: Scalars['Decimal'];
  payableOverridden: Scalars['Boolean'];
  project?: Maybe<Project>;
  rateName: Scalars['String'];
  rateSheetName: Scalars['String'];
};

export type AtvExpenseRate = IExpenseRate & {
  __typename?: 'AtvExpenseRate';
  billableAmount: Scalars['Decimal'];
  expenseAccount?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  invoiceLabel?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reimbursableAmount: Scalars['Decimal'];
  unit?: Maybe<RateUnit>;
};

export type AtvExpenseTotal = {
  __typename?: 'AtvExpenseTotal';
  month: Scalars['LocalDate'];
  total: Scalars['Decimal'];
};

export type BadDebtWriteOff = {
  __typename?: 'BadDebtWriteOff';
  amount: Scalars['Decimal'];
  date: Scalars['LocalDate'];
};

export enum BillableFilter {
  Any = 'Any',
  No = 'No',
  Yes = 'Yes'
}

export enum BillingFrequencyType {
  Biweekly = 'Biweekly',
  Monthly = 'Monthly',
  UponCompletion = 'UponCompletion',
  Weekly = 'Weekly'
}

export type BulkAddChargesResponse = {
  __typename?: 'BulkAddChargesResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  results?: Maybe<Array<Maybe<AddChargesResult>>>;
};

export type BulkAddInvoicesResponse = {
  __typename?: 'BulkAddInvoicesResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  results?: Maybe<Array<Maybe<AddInvoicesToRollupResult>>>;
};

export type BulkAddRollupLineItemsArgsInput = {
  customRollupInvoiceNumber?: InputMaybe<Scalars['String']>;
  invoiceNumbers?: InputMaybe<Array<Scalars['String']>>;
};

export enum ChargeType {
  Clerical = 'Clerical',
  Expense = 'Expense',
  Task = 'Task',
  Tax = 'Tax'
}

export type ComputedProjectRateSheetQueryInput = {
  date: Scalars['LocalDate'];
  projectNumber: Scalars['Int'];
};

export type ConnectionOfAtvExpenseTotal = {
  __typename?: 'ConnectionOfAtvExpenseTotal';
  records?: Maybe<Array<Maybe<AtvExpenseTotal>>>;
  token?: Maybe<Scalars['String']>;
};

export type ConnectionOfCustomRollupInvoice = {
  __typename?: 'ConnectionOfCustomRollupInvoice';
  records?: Maybe<Array<Maybe<CustomRollupInvoice>>>;
  token?: Maybe<Scalars['String']>;
};

export type ConnectionOfCustomer = {
  __typename?: 'ConnectionOfCustomer';
  records?: Maybe<Array<Maybe<Customer>>>;
  token?: Maybe<Scalars['String']>;
};

export type ConnectionOfEmployee = {
  __typename?: 'ConnectionOfEmployee';
  records?: Maybe<Array<Maybe<Employee>>>;
  token?: Maybe<Scalars['String']>;
};

export type ConnectionOfEmployeeRate = {
  __typename?: 'ConnectionOfEmployeeRate';
  records?: Maybe<Array<Maybe<EmployeeRate>>>;
  token?: Maybe<Scalars['String']>;
};

export type ConnectionOfExpenseSearchResult = {
  __typename?: 'ConnectionOfExpenseSearchResult';
  records?: Maybe<Array<Maybe<ExpenseSearchResult>>>;
  token?: Maybe<Scalars['String']>;
};

export type ConnectionOfInvoice = {
  __typename?: 'ConnectionOfInvoice';
  records?: Maybe<Array<Maybe<Invoice>>>;
  token?: Maybe<Scalars['String']>;
};

export type ConnectionOfProject = {
  __typename?: 'ConnectionOfProject';
  records?: Maybe<Array<Maybe<Project>>>;
  token?: Maybe<Scalars['String']>;
};

export type ConnectionOfProjectCharge = {
  __typename?: 'ConnectionOfProjectCharge';
  records?: Maybe<Array<Maybe<ProjectCharge>>>;
  token?: Maybe<Scalars['String']>;
};

export type ConnectionOfProjectRequestGroup = {
  __typename?: 'ConnectionOfProjectRequestGroup';
  records?: Maybe<Array<Maybe<ProjectRequestGroup>>>;
  token?: Maybe<Scalars['String']>;
};

export type ConnectionOfRoleAssignment = {
  __typename?: 'ConnectionOfRoleAssignment';
  records?: Maybe<Array<Maybe<RoleAssignment>>>;
  token?: Maybe<Scalars['String']>;
};

export type ConnectionOfThirdPartyInvoice = {
  __typename?: 'ConnectionOfThirdPartyInvoice';
  records?: Maybe<Array<Maybe<ThirdPartyInvoice>>>;
  token?: Maybe<Scalars['String']>;
};

export type ConnectionOfTimesheet = {
  __typename?: 'ConnectionOfTimesheet';
  records?: Maybe<Array<Maybe<Timesheet>>>;
  token?: Maybe<Scalars['String']>;
};

export type CorpLocation = {
  __typename?: 'CorpLocation';
  companyName: Scalars['String'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CorpLocationMutationModel = {
  __typename?: 'CorpLocationMutationModel';
  update?: Maybe<ValidationResponse>;
};


export type CorpLocationMutationModelUpdateArgs = {
  companyName: Scalars['String'];
  corpLocationId: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateBulkChargeResponse = {
  __typename?: 'CreateBulkChargeResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  skippedInvoices?: Maybe<Array<Scalars['String']>>;
};

export type CreateCustomerResponse = {
  __typename?: 'CreateCustomerResponse';
  createCustomerRequestId: Scalars['ID'];
  errors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type CreateExpenseResponse = {
  __typename?: 'CreateExpenseResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  expenseId?: Maybe<Scalars['String']>;
};

export type CreateInvoiceResponse = {
  __typename?: 'CreateInvoiceResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  invoiceNumber?: Maybe<Scalars['String']>;
};

export type CreateProjectChargeResponse = {
  __typename?: 'CreateProjectChargeResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  projectChargeId?: Maybe<Scalars['Int']>;
};

export type CreateProjectRequestResponse = {
  __typename?: 'CreateProjectRequestResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  requestId?: Maybe<Scalars['ID']>;
};

export type CreateProjectResponse = {
  __typename?: 'CreateProjectResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  projectNumber?: Maybe<Scalars['Int']>;
};

export type CreateProjectTimeCommandDailyHoursInput = {
  asBuilt: Scalars['Duration'];
  boundary: Scalars['Duration'];
  clStake: Scalars['Duration'];
  constRestake: Scalars['Duration'];
  constStake: Scalars['Duration'];
  employeeUpn: Scalars['String'];
  locate: Scalars['Duration'];
  rainoutOther: Scalars['Duration'];
  rerouteStake: Scalars['Duration'];
  role: ProjectTimeDailyRole;
  travelHours: Scalars['Duration'];
};

export type CreateProjectTimeResponse = {
  __typename?: 'CreateProjectTimeResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  projectTimeId?: Maybe<Scalars['String']>;
};

export type CreateRollupInvoiceResponse = {
  __typename?: 'CreateRollupInvoiceResponse';
  customRollupInvoiceNumber: Scalars['String'];
  errors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type CreateThirdPartyInvoiceResponse = {
  __typename?: 'CreateThirdPartyInvoiceResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  invoiceNumber?: Maybe<Scalars['String']>;
};

export type CreateTimeEntryResponse = {
  __typename?: 'CreateTimeEntryResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  timeEntryId?: Maybe<Scalars['String']>;
};

export type CreateTimeOffEntryResponse = {
  __typename?: 'CreateTimeOffEntryResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  timeOffEntryId: Scalars['String'];
};

export type CrewCodeMutationModel = {
  __typename?: 'CrewCodeMutationModel';
  crewCodeConsumer?: Maybe<ValidationResponse>;
  updateActive?: Maybe<ValidationResponse>;
  updateCrewCode?: Maybe<ValidationResponse>;
  updateOfficeOverheadCrewCode?: Maybe<ValidationResponse>;
  updateStateOverheadCrewCode?: Maybe<ValidationResponse>;
};


export type CrewCodeMutationModelCrewCodeConsumerArgs = {
  code: Scalars['String'];
  employeeId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};


export type CrewCodeMutationModelUpdateActiveArgs = {
  crewCode: Scalars['String'];
  isActive: Scalars['Boolean'];
};


export type CrewCodeMutationModelUpdateCrewCodeArgs = {
  crewCode: Scalars['String'];
  overrideEmployeeId?: InputMaybe<Scalars['String']>;
};


export type CrewCodeMutationModelUpdateOfficeOverheadCrewCodeArgs = {
  crewCode: Scalars['String'];
  officeCode: Scalars['String'];
};


export type CrewCodeMutationModelUpdateStateOverheadCrewCodeArgs = {
  crewCode: Scalars['String'];
  state: Scalars['String'];
};

export type CrewCodeRecord = {
  __typename?: 'CrewCodeRecord';
  crewCode: Scalars['String'];
  employee?: Maybe<Employee>;
  isActive: Scalars['Boolean'];
  overrideEmployeeId?: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

export enum CrewRole {
  CrewChief = 'CrewChief',
  IMan = 'IMan',
  Rodman = 'Rodman'
}

export type CustomRollupInvoice = {
  __typename?: 'CustomRollupInvoice';
  cancelledDate?: Maybe<Scalars['LocalDate']>;
  invoiceDate?: Maybe<Scalars['LocalDate']>;
  invoiceNumber: Scalars['String'];
  lineItems?: Maybe<Array<Maybe<CustomRollupInvoiceLineItem>>>;
  notes?: Maybe<Scalars['String']>;
  pdfDownloadUrl: Scalars['String'];
  projectGroup?: Maybe<ProjectGroup>;
  projectGroupName: Scalars['String'];
  state: InvoiceState;
  total: Scalars['Decimal'];
};

export type CustomRollupInvoiceLineItem = {
  __typename?: 'CustomRollupInvoiceLineItem';
  invoice?: Maybe<Invoice>;
  total: Scalars['Decimal'];
};

export type CustomRollupInvoiceMutationModel = {
  __typename?: 'CustomRollupInvoiceMutationModel';
  addLineItem?: Maybe<ValidationResponse>;
  bill?: Maybe<ValidationResponse>;
  bulkAddLineItems?: Maybe<BulkAddInvoicesResponse>;
  cancel?: Maybe<ValidationResponse>;
  create?: Maybe<CreateRollupInvoiceResponse>;
  edit?: Maybe<ValidationResponse>;
  removeLineItem?: Maybe<ValidationResponse>;
};


export type CustomRollupInvoiceMutationModelAddLineItemArgs = {
  customRollupInvoiceNumber: Scalars['String'];
  invoiceNumber: Scalars['String'];
};


export type CustomRollupInvoiceMutationModelBillArgs = {
  invoiceNumber: Scalars['String'];
};


export type CustomRollupInvoiceMutationModelBulkAddLineItemsArgs = {
  addInvoicesArgs?: InputMaybe<Array<BulkAddRollupLineItemsArgsInput>>;
};


export type CustomRollupInvoiceMutationModelCancelArgs = {
  invoiceNumber: Scalars['String'];
  notes: Scalars['String'];
};


export type CustomRollupInvoiceMutationModelCreateArgs = {
  customerNumber: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  projectGroupName: Scalars['String'];
};


export type CustomRollupInvoiceMutationModelEditArgs = {
  invoiceNumber: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
};


export type CustomRollupInvoiceMutationModelRemoveLineItemArgs = {
  customRollupInvoiceNumber: Scalars['String'];
  invoiceNumber: Scalars['String'];
};

export type CustomRollupInvoiceQueryModel = {
  __typename?: 'CustomRollupInvoiceQueryModel';
  draftRollupInvoices?: Maybe<Array<Maybe<CustomRollupInvoice>>>;
  search?: Maybe<ConnectionOfCustomRollupInvoice>;
  single?: Maybe<CustomRollupInvoice>;
};


export type CustomRollupInvoiceQueryModelDraftRollupInvoicesArgs = {
  groupIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type CustomRollupInvoiceQueryModelSearchArgs = {
  amount?: InputMaybe<Scalars['Decimal']>;
  billingAdmin?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  invoiceNumbers?: InputMaybe<Array<Scalars['String']>>;
  projectGroupArgs?: InputMaybe<ProjectGroupArgsInput>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<CustomRollupInvoiceSortBy>;
  state?: InputMaybe<InvoiceFilterState>;
  token?: InputMaybe<Scalars['String']>;
};


export type CustomRollupInvoiceQueryModelSingleArgs = {
  invoiceNumber: Scalars['String'];
};

export enum CustomRollupInvoiceSortBy {
  /** Customer Name */
  CustomerName = 'CustomerName',
  /** Invoice Number */
  InvoiceNumber = 'InvoiceNumber'
}

export type Customer = {
  __typename?: 'Customer';
  address1: Scalars['String'];
  address2: Scalars['String'];
  adminNotes?: Maybe<Scalars['String']>;
  attn?: Maybe<Scalars['String']>;
  badDebtWriteOffs?: Maybe<Array<Maybe<BadDebtWriteOff>>>;
  billingAdmins?: Maybe<Array<Maybe<Employee>>>;
  canManageCri: Scalars['Boolean'];
  city: Scalars['String'];
  country: Scalars['String'];
  createCustomerRequestId: Scalars['ID'];
  electronicInvoicingSystem?: Maybe<ElectronicInvoicingSystem>;
  fax: Scalars['String'];
  name: Scalars['String'];
  nmTaxable: Scalars['Boolean'];
  notes: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  projectGroups?: Maybe<Array<Maybe<ProjectGroup>>>;
  requester?: Maybe<Employee>;
  simplifiedName: Scalars['String'];
  state: Scalars['String'];
  stats?: Maybe<CustomerStats>;
  status: CustomerStatus;
  subAccountOf?: Maybe<Scalars['String']>;
  txTaxable: Scalars['Boolean'];
  zip: Scalars['String'];
};

export type CustomerMutationModel = {
  __typename?: 'CustomerMutationModel';
  addBadDebtWriteOff?: Maybe<ValidationResponse>;
  approveCustomer?: Maybe<ValidationResponse>;
  assignBa?: Maybe<ValidationResponse>;
  createCustomer?: Maybe<CreateCustomerResponse>;
  editCustomer?: Maybe<ValidationResponse>;
  editElectronicInvoicing?: Maybe<ValidationResponse>;
  rejectCustomer?: Maybe<ValidationResponse>;
  unassignBa?: Maybe<ValidationResponse>;
};


export type CustomerMutationModelAddBadDebtWriteOffArgs = {
  amount: Scalars['Decimal'];
  customerNumber: Scalars['String'];
  date: Scalars['LocalDate'];
};


export type CustomerMutationModelApproveCustomerArgs = {
  createCustomerRequestId: Scalars['String'];
  number: Scalars['String'];
  subNumber: Scalars['String'];
};


export type CustomerMutationModelAssignBaArgs = {
  customerNumber: Scalars['String'];
  userPrincipalName: Scalars['String'];
};


export type CustomerMutationModelCreateCustomerArgs = {
  address1: Scalars['String'];
  address2: Scalars['String'];
  adminNotes?: InputMaybe<Scalars['String']>;
  attn?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  electronicInvoicingSystem?: InputMaybe<ElectronicInvoicingSystem>;
  fax: Scalars['String'];
  name: Scalars['String'];
  nmTaxable: Scalars['Boolean'];
  notes: Scalars['String'];
  phone: Scalars['String'];
  state: Scalars['String'];
  subAccountOf?: InputMaybe<Scalars['String']>;
  txTaxable: Scalars['Boolean'];
  zip: Scalars['String'];
};


export type CustomerMutationModelEditCustomerArgs = {
  address1: Scalars['String'];
  address2: Scalars['String'];
  adminNotes?: InputMaybe<Scalars['String']>;
  attn?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  electronicInvoicingSystem?: InputMaybe<ElectronicInvoicingSystem>;
  fax: Scalars['String'];
  mainNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nmTaxable: Scalars['Boolean'];
  notes: Scalars['String'];
  number: Scalars['String'];
  phone: Scalars['String'];
  state: Scalars['String'];
  subNumber?: InputMaybe<Scalars['String']>;
  txTaxable: Scalars['Boolean'];
  zip: Scalars['String'];
};


export type CustomerMutationModelEditElectronicInvoicingArgs = {
  customerNumber: Scalars['String'];
  electronicInvoicingSystem?: InputMaybe<ElectronicInvoicingSystem>;
};


export type CustomerMutationModelRejectCustomerArgs = {
  createCustomerRequestId: Scalars['String'];
  reason: Scalars['String'];
};


export type CustomerMutationModelUnassignBaArgs = {
  customerNumber: Scalars['String'];
  userPrincipalName: Scalars['String'];
};

export type CustomerStats = {
  __typename?: 'CustomerStats';
  projectCount: Scalars['Int'];
};

export enum CustomerStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type CustomersQueryModel = {
  __typename?: 'CustomersQueryModel';
  pendingCustomers?: Maybe<Array<Maybe<PendingCustomer>>>;
  search?: Maybe<ConnectionOfCustomer>;
  single?: Maybe<Customer>;
};


export type CustomersQueryModelSearchArgs = {
  includePending?: InputMaybe<Scalars['Boolean']>;
  projectNumber?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};


export type CustomersQueryModelSingleArgs = {
  customerNumber: Scalars['String'];
};

export type Division = {
  __typename?: 'Division';
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type DivisionMutationModel = {
  __typename?: 'DivisionMutationModel';
  update?: Maybe<ValidationResponse>;
};


export type DivisionMutationModelUpdateArgs = {
  divisionId: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type DivisionQueryModel = {
  __typename?: 'DivisionQueryModel';
  all?: Maybe<Array<Maybe<Division>>>;
};

export type EditProjectTimeCommandDailyHoursInput = {
  asBuilt: Scalars['Duration'];
  boundary: Scalars['Duration'];
  clStake: Scalars['Duration'];
  constRestake: Scalars['Duration'];
  constStake: Scalars['Duration'];
  employeeUpn: Scalars['String'];
  locate: Scalars['Duration'];
  rainoutOther: Scalars['Duration'];
  rerouteStake: Scalars['Duration'];
  role: ProjectTimeDailyRole;
  travelHours: Scalars['Duration'];
};

export type EditProjectTimeDailyCommandHoursInput = {
  asBuilt: Scalars['Duration'];
  boundary: Scalars['Duration'];
  clStake: Scalars['Duration'];
  constRestake: Scalars['Duration'];
  constStake: Scalars['Duration'];
  employeeUpn: Scalars['String'];
  locate: Scalars['Duration'];
  rainoutOther: Scalars['Duration'];
  rerouteStake: Scalars['Duration'];
  role: ProjectTimeDailyRole;
  travelHours: Scalars['Duration'];
};

export type EffectiveRateSheetsAssignment = {
  __typename?: 'EffectiveRateSheetsAssignment';
  asOf: Scalars['LocalDate'];
  expenseRateSheet?: Maybe<ExpenseRateSheet>;
  projectNumber: Scalars['Int'];
  taskRateSheet?: Maybe<TaskRateSheet>;
};

export type EffectiveTaskRateSheetAssignment = {
  __typename?: 'EffectiveTaskRateSheetAssignment';
  projectNumber: Scalars['Int'];
  taskRateSheet?: Maybe<TaskRateSheet>;
};

export enum ElectronicInvoicingSystem {
  Actian = 'Actian',
  Ariba = 'Ariba',
  OpenInvoice = 'OpenInvoice',
  SmartGep = 'SmartGEP'
}

export type Employee = {
  __typename?: 'Employee';
  address1: Scalars['String'];
  address2: Scalars['String'];
  city: Scalars['String'];
  email: Scalars['String'];
  fieldOffice?: Maybe<FieldOffice>;
  finalPayroll?: Maybe<Scalars['LocalDate']>;
  firstName: Scalars['String'];
  fullPath?: Maybe<Scalars['String']>;
  isMobileEmployee: Scalars['Boolean'];
  isTerminated: Scalars['Boolean'];
  lastName: Scalars['String'];
  middleInitial: Scalars['String'];
  payPeriod: PayPeriod;
  payrollAdmin?: Maybe<Employee>;
  phone: Scalars['String'];
  roles?: Maybe<Array<Maybe<EmployeeRole>>>;
  state: Scalars['String'];
  timeEntryMethod: Scalars['String'];
  ultiProId: Scalars['String'];
  userPrincipalName: Scalars['String'];
  workLocation: Scalars['String'];
  zip: Scalars['String'];
};

export type EmployeeMutationModel = {
  __typename?: 'EmployeeMutationModel';
  addRole?: Maybe<ValidationResponse>;
  generateTimesheets?: Maybe<GenerateTimesheetsResponse>;
  removeRole?: Maybe<ValidationResponse>;
  updateFieldOffice?: Maybe<ValidationResponse>;
  updateFinalPayroll?: Maybe<ValidationResponse>;
};


export type EmployeeMutationModelAddRoleArgs = {
  role: Scalars['String'];
  userPrincipalName: Scalars['String'];
};


export type EmployeeMutationModelGenerateTimesheetsArgs = {
  date: Scalars['LocalDate'];
};


export type EmployeeMutationModelRemoveRoleArgs = {
  role: Scalars['String'];
  userPrincipalName: Scalars['String'];
};


export type EmployeeMutationModelUpdateFieldOfficeArgs = {
  fieldOffice?: InputMaybe<Scalars['String']>;
  userPrincipalName: Scalars['String'];
};


export type EmployeeMutationModelUpdateFinalPayrollArgs = {
  finalPayroll?: InputMaybe<Scalars['LocalDate']>;
  userPrincipalName: Scalars['String'];
};

export type EmployeeRate = {
  __typename?: 'EmployeeRate';
  effectiveDate: Scalars['LocalDate'];
  employee?: Maybe<Employee>;
  rate: Scalars['Decimal'];
};

export type EmployeeRatesMutationModel = {
  __typename?: 'EmployeeRatesMutationModel';
  createRate?: Maybe<ValidationResponse>;
  deleteRate?: Maybe<ValidationResponse>;
  editRate?: Maybe<ValidationResponse>;
};


export type EmployeeRatesMutationModelCreateRateArgs = {
  effectiveDate: Scalars['LocalDate'];
  rate: Scalars['Decimal'];
  ultiProId: Scalars['String'];
};


export type EmployeeRatesMutationModelDeleteRateArgs = {
  effectiveDate: Scalars['LocalDate'];
  ultiProId: Scalars['String'];
};


export type EmployeeRatesMutationModelEditRateArgs = {
  effectiveDate: Scalars['LocalDate'];
  originalEffectiveDate: Scalars['LocalDate'];
  rate: Scalars['Decimal'];
  ultiProId: Scalars['String'];
};

export type EmployeeRatesQueryModel = {
  __typename?: 'EmployeeRatesQueryModel';
  search?: Maybe<ConnectionOfEmployeeRate>;
  single?: Maybe<EmployeeRate>;
};


export type EmployeeRatesQueryModelSearchArgs = {
  searchText?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  workLocation?: InputMaybe<Scalars['String']>;
};


export type EmployeeRatesQueryModelSingleArgs = {
  effectiveDate: Scalars['LocalDate'];
  userPrincipalName: Scalars['String'];
};

export type EmployeeRole = {
  __typename?: 'EmployeeRole';
  roleName: Scalars['String'];
};

export enum EmployeeSortBy {
  FirstName = 'FirstName',
  IsTerminated = 'IsTerminated',
  LastName = 'LastName',
  WorkLocation = 'WorkLocation'
}

export enum EmployeeStatus {
  Active = 'Active',
  All = 'All',
  Terminated = 'Terminated'
}

export type EmployeesQueryModel = {
  __typename?: 'EmployeesQueryModel';
  all?: Maybe<Array<Maybe<Employee>>>;
  payrollAdminInfo?: Maybe<PayrollAdminData>;
  roleSearch?: Maybe<ConnectionOfRoleAssignment>;
  roles?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<ConnectionOfEmployee>;
  single?: Maybe<Employee>;
  workLocations?: Maybe<Array<Scalars['String']>>;
};


export type EmployeesQueryModelAllArgs = {
  employeeStatus?: InputMaybe<EmployeeStatus>;
  roleName?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  workLocation?: InputMaybe<Scalars['String']>;
};


export type EmployeesQueryModelRoleSearchArgs = {
  roleName?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};


export type EmployeesQueryModelSearchArgs = {
  activePayrollDate?: InputMaybe<Scalars['String']>;
  roleName?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<EmployeeSortBy>;
  status?: InputMaybe<EmployeeStatus>;
  token?: InputMaybe<Scalars['String']>;
  workLocation?: InputMaybe<Scalars['String']>;
};


export type EmployeesQueryModelSingleArgs = {
  userPrincipalName: Scalars['String'];
};

export type ExpenseHistoryItem = {
  __typename?: 'ExpenseHistoryItem';
  createdBy: Scalars['String'];
  createdOn: Scalars['Instant'];
  details?: Maybe<Array<Scalars['String']>>;
  eventType: Scalars['String'];
  expenseId: Scalars['String'];
};

export type ExpenseRateSheet = {
  __typename?: 'ExpenseRateSheet';
  allowAssignment: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  endDate?: Maybe<Scalars['LocalDate']>;
  expenseRates?: Maybe<Array<Maybe<IExpenseRate>>>;
  history?: Maybe<Array<Maybe<ExpenseRateSheetHistoryItem>>>;
  lastUsed?: Maybe<Scalars['LocalDate']>;
  name: Scalars['String'];
};

export type ExpenseRateSheetAssignment = {
  __typename?: 'ExpenseRateSheetAssignment';
  effectiveDate: Scalars['LocalDate'];
  rateSheet?: Maybe<ExpenseRateSheet>;
};

export type ExpenseRateSheetHistoryItem = {
  __typename?: 'ExpenseRateSheetHistoryItem';
  changedBy: Scalars['String'];
  changedOn: Scalars['Instant'];
  details?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

export type ExpenseRatesMutationModel = {
  __typename?: 'ExpenseRatesMutationModel';
  addAdHocRate?: Maybe<ValidationResponse>;
  addAtvRate?: Maybe<ValidationResponse>;
  addFuelRate?: Maybe<ValidationResponse>;
  addMileageRate?: Maybe<ValidationResponse>;
  addMobileAllowanceRate?: Maybe<ValidationResponse>;
  addPerDiemRate?: Maybe<ValidationResponse>;
  addReceiptValuedRate?: Maybe<ValidationResponse>;
  addTcpAllowanceRate?: Maybe<ValidationResponse>;
  archiveRateSheet?: Maybe<ValidationResponse>;
  assignRateSheetToProject?: Maybe<ValidationResponse>;
  cloneRateSheet?: Maybe<ValidationResponse>;
  createRateSheet?: Maybe<ValidationResponse>;
  deleteRate?: Maybe<ValidationResponse>;
  editAdHocRate?: Maybe<ValidationResponse>;
  editAtvRate?: Maybe<ValidationResponse>;
  editFuelRate?: Maybe<ValidationResponse>;
  editMileageRate?: Maybe<ValidationResponse>;
  editMobileAllowanceRate?: Maybe<ValidationResponse>;
  editPerDiemRate?: Maybe<ValidationResponse>;
  editRateSheet?: Maybe<ValidationResponse>;
  editReceiptValuedRate?: Maybe<ValidationResponse>;
  editTcpAllowanceRate?: Maybe<ValidationResponse>;
  removeRateSheetFromProject?: Maybe<ValidationResponse>;
};


export type ExpenseRatesMutationModelAddAdHocRateArgs = {
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  rate?: InputMaybe<Scalars['Decimal']>;
  rateSheetName: Scalars['String'];
};


export type ExpenseRatesMutationModelAddAtvRateArgs = {
  billableAmount: Scalars['Decimal'];
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  rateSheetName: Scalars['String'];
  reimbursableAmount: Scalars['Decimal'];
};


export type ExpenseRatesMutationModelAddFuelRateArgs = {
  billable: Scalars['Boolean'];
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  rateSheetName: Scalars['String'];
};


export type ExpenseRatesMutationModelAddMileageRateArgs = {
  billableAmountPerMile: Scalars['Decimal'];
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  rateSheetName: Scalars['String'];
  reimbursableAmountPerMile: Scalars['Decimal'];
};


export type ExpenseRatesMutationModelAddMobileAllowanceRateArgs = {
  billableAmount: Scalars['Decimal'];
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  quantity: Scalars['Int'];
  rateSheetName: Scalars['String'];
  reimbursableAmount: Scalars['Decimal'];
};


export type ExpenseRatesMutationModelAddPerDiemRateArgs = {
  billableOverride?: InputMaybe<Scalars['Decimal']>;
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  kind: PerDiemKind;
  name: Scalars['String'];
  quantity: Scalars['Int'];
  rateSheetName: Scalars['String'];
};


export type ExpenseRatesMutationModelAddReceiptValuedRateArgs = {
  billable: Scalars['Boolean'];
  category: ReceiptValuedCategory;
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  rateSheetName: Scalars['String'];
  requiresLocation: Scalars['Boolean'];
};


export type ExpenseRatesMutationModelAddTcpAllowanceRateArgs = {
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  rateSheetName: Scalars['String'];
  reimbursableAmount: Scalars['Decimal'];
};


export type ExpenseRatesMutationModelArchiveRateSheetArgs = {
  rateSheetName: Scalars['String'];
};


export type ExpenseRatesMutationModelAssignRateSheetToProjectArgs = {
  effectiveDate: Scalars['LocalDate'];
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
  rateSheetName: Scalars['String'];
};


export type ExpenseRatesMutationModelCloneRateSheetArgs = {
  newName: Scalars['String'];
  oldName: Scalars['String'];
};


export type ExpenseRatesMutationModelCreateRateSheetArgs = {
  name: Scalars['String'];
};


export type ExpenseRatesMutationModelDeleteRateArgs = {
  name: Scalars['String'];
  rateSheetName: Scalars['String'];
};


export type ExpenseRatesMutationModelEditAdHocRateArgs = {
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  oldName: Scalars['String'];
  rate?: InputMaybe<Scalars['Decimal']>;
  rateSheetName: Scalars['String'];
};


export type ExpenseRatesMutationModelEditAtvRateArgs = {
  billableAmount: Scalars['Decimal'];
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  oldName: Scalars['String'];
  rateSheetName: Scalars['String'];
  reimbursableAmount: Scalars['Decimal'];
};


export type ExpenseRatesMutationModelEditFuelRateArgs = {
  billable: Scalars['Boolean'];
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  oldName: Scalars['String'];
  rateSheetName: Scalars['String'];
};


export type ExpenseRatesMutationModelEditMileageRateArgs = {
  billableAmountPerMile: Scalars['Decimal'];
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  oldName: Scalars['String'];
  rateSheetName: Scalars['String'];
  reimbursableAmountPerMile: Scalars['Decimal'];
};


export type ExpenseRatesMutationModelEditMobileAllowanceRateArgs = {
  billableAmount: Scalars['Decimal'];
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  oldName: Scalars['String'];
  quantity: Scalars['Int'];
  rateSheetName: Scalars['String'];
  reimbursableAmount: Scalars['Decimal'];
};


export type ExpenseRatesMutationModelEditPerDiemRateArgs = {
  billableOverride?: InputMaybe<Scalars['Decimal']>;
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  kind: PerDiemKind;
  name: Scalars['String'];
  oldName: Scalars['String'];
  quantity: Scalars['Int'];
  rateSheetName: Scalars['String'];
};


export type ExpenseRatesMutationModelEditRateSheetArgs = {
  allowAssignment: Scalars['Boolean'];
  endDate?: InputMaybe<Scalars['LocalDate']>;
  newName: Scalars['String'];
  oldName: Scalars['String'];
};


export type ExpenseRatesMutationModelEditReceiptValuedRateArgs = {
  billable: Scalars['Boolean'];
  category: ReceiptValuedCategory;
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  oldName: Scalars['String'];
  rateSheetName: Scalars['String'];
  requiresLocation: Scalars['Boolean'];
};


export type ExpenseRatesMutationModelEditTcpAllowanceRateArgs = {
  expenseAccount?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoiceLabel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  oldName: Scalars['String'];
  rateSheetName: Scalars['String'];
  reimbursableAmount: Scalars['Decimal'];
};


export type ExpenseRatesMutationModelRemoveRateSheetFromProjectArgs = {
  effectiveDate: Scalars['LocalDate'];
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
  rateSheetName: Scalars['String'];
};

export type ExpenseRatesQueryModel = {
  __typename?: 'ExpenseRatesQueryModel';
  expenseRate?: Maybe<IExpenseRate>;
  rateSheet?: Maybe<ExpenseRateSheet>;
  rateSheets?: Maybe<Array<Maybe<ExpenseRateSheet>>>;
};


export type ExpenseRatesQueryModelExpenseRateArgs = {
  rateName: Scalars['String'];
  rateSheetName: Scalars['String'];
};


export type ExpenseRatesQueryModelRateSheetArgs = {
  computedProjectRateSheet?: InputMaybe<ComputedProjectRateSheetQueryInput>;
  name?: InputMaybe<Scalars['String']>;
};


export type ExpenseRatesQueryModelRateSheetsArgs = {
  allowAssignment?: InputMaybe<Scalars['Boolean']>;
  effectiveDate?: InputMaybe<Scalars['LocalDate']>;
  projectToExclude?: InputMaybe<Scalars['Int']>;
};

export type ExpenseSearchResult = {
  __typename?: 'ExpenseSearchResult';
  employee?: Maybe<Employee>;
  expense?: Maybe<IExpense>;
};

export type FieldOffice = {
  __typename?: 'FieldOffice';
  allowsProjects: Scalars['Boolean'];
  officeCode: Scalars['String'];
  projectRequestReviewers?: Maybe<Array<Maybe<Employee>>>;
};

export type FieldOfficeMutationModel = {
  __typename?: 'FieldOfficeMutationModel';
  addProjectRequestReviewer?: Maybe<ValidationResponse>;
  removeProjectRequestReviewer?: Maybe<ValidationResponse>;
  updateProjectAffinity?: Maybe<ValidationResponse>;
};


export type FieldOfficeMutationModelAddProjectRequestReviewerArgs = {
  officeCode: Scalars['String'];
  userPrincipalName: Scalars['String'];
};


export type FieldOfficeMutationModelRemoveProjectRequestReviewerArgs = {
  officeCode: Scalars['String'];
  userPrincipalName: Scalars['String'];
};


export type FieldOfficeMutationModelUpdateProjectAffinityArgs = {
  allowsProjects: Scalars['Boolean'];
  officeCode: Scalars['String'];
};

export type FieldOfficesQueryModel = {
  __typename?: 'FieldOfficesQueryModel';
  all?: Maybe<Array<Maybe<FieldOffice>>>;
  officeCodes?: Maybe<Array<Scalars['String']>>;
};

export type FuelExpense = IExpense & {
  __typename?: 'FuelExpense';
  billableAmount: Scalars['Decimal'];
  companyVehicle: Scalars['Boolean'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  expenseAccount?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<ExpenseHistoryItem>>>;
  id: Scalars['String'];
  importEdited: Scalars['Boolean'];
  invoiceLabel?: Maybe<Scalars['String']>;
  lastAdminNotes?: Maybe<Scalars['String']>;
  location?: Maybe<StateCounty>;
  notes?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['Decimal']>;
  payable: Payable;
  payableAmount: Scalars['Decimal'];
  payableOverridden: Scalars['Boolean'];
  payerInformation: PayerInformation;
  project?: Maybe<Project>;
  quantity: Scalars['Decimal'];
  rateName: Scalars['String'];
  rateSheetName: Scalars['String'];
  receiptImageId?: Maybe<Scalars['String']>;
  receiptImageUrl?: Maybe<Scalars['String']>;
  receiptTotal: Scalars['Decimal'];
  vehicleNumber?: Maybe<Scalars['String']>;
};

export type FuelExpenseRate = IExpenseRate & {
  __typename?: 'FuelExpenseRate';
  billable: Scalars['Boolean'];
  expenseAccount?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  invoiceLabel?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  unit?: Maybe<RateUnit>;
};

export type GenerateTimesheetsResponse = {
  __typename?: 'GenerateTimesheetsResponse';
  count: Scalars['Int'];
  errors?: Maybe<Array<Maybe<ValidationError>>>;
};

export enum GovernmentFundingType {
  City = 'City',
  County = 'County',
  Federal = 'Federal',
  None = 'None',
  State = 'State'
}

export enum GroupType {
  Automated = 'Automated',
  Manual = 'Manual'
}

export type HelpRequestMutationModel = {
  __typename?: 'HelpRequestMutationModel';
  sendRequest?: Maybe<ValidationResponse>;
};


export type HelpRequestMutationModelSendRequestArgs = {
  issue: Scalars['String'];
  paths?: InputMaybe<Array<Scalars['String']>>;
};

export type IExpense = {
  billableAmount: Scalars['Decimal'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  expenseAccount?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<ExpenseHistoryItem>>>;
  id: Scalars['String'];
  invoiceLabel?: Maybe<Scalars['String']>;
  lastAdminNotes?: Maybe<Scalars['String']>;
  location?: Maybe<StateCounty>;
  notes?: Maybe<Scalars['String']>;
  payable: Payable;
  payableAmount: Scalars['Decimal'];
  payableOverridden: Scalars['Boolean'];
  rateName: Scalars['String'];
  rateSheetName: Scalars['String'];
};

export type IExpenseRate = {
  expenseAccount?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  invoiceLabel?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  unit?: Maybe<RateUnit>;
};

export type IProjectChargeHistoryItem = {
  changedBy: Scalars['String'];
  changedOn: Scalars['Instant'];
  event: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  actualEndDate?: Maybe<Scalars['LocalDate']>;
  afe?: Maybe<Scalars['String']>;
  billedBy?: Maybe<Employee>;
  billingAdmin?: Maybe<Employee>;
  budget?: Maybe<Scalars['Decimal']>;
  canCancel: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
  canPostToRollup: Scalars['Boolean'];
  canRecall: Scalars['Boolean'];
  cancelledDate?: Maybe<Scalars['LocalDate']>;
  clientContactName: Scalars['String'];
  corpLocationId: Scalars['String'];
  costCenter?: Maybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  customRollupInvoice?: Maybe<CustomRollupInvoice>;
  customerName: Scalars['String'];
  customerNumber: Scalars['String'];
  divisionId: Scalars['String'];
  invoiceDate?: Maybe<Scalars['LocalDate']>;
  invoiceNumber: Scalars['String'];
  lineItems?: Maybe<Array<Maybe<InvoiceLineItem>>>;
  locationState: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  officeCode: Scalars['String'];
  pdfDownloadUrl: Scalars['String'];
  po?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  projectManager?: Maybe<Employee>;
  projectName: Scalars['String'];
  projectNumber: Scalars['Int'];
  state: InvoiceState;
  taxDescription: Scalars['String'];
  taxRate: Scalars['Decimal'];
  total: Scalars['Decimal'];
  totalTax: Scalars['Decimal'];
  wo?: Maybe<Scalars['String']>;
};

export enum InvoiceFilterState {
  Any = 'Any',
  Billed = 'Billed',
  Cancelled = 'Cancelled',
  Draft = 'Draft'
}

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  billable: Scalars['Boolean'];
  date: Scalars['LocalDate'];
  invoiceNumber: Scalars['String'];
  label: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  projectCharge?: Maybe<ProjectCharge>;
  projectChargeId: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  rebill?: Maybe<LineItemRebillInfo>;
  taxable: Scalars['Boolean'];
};

export type InvoiceMutationModel = {
  __typename?: 'InvoiceMutationModel';
  addCharges?: Maybe<AddChargesResponse>;
  bill?: Maybe<ValidationResponse>;
  bulkAddCharges?: Maybe<BulkAddChargesResponse>;
  cancel?: Maybe<ValidationResponse>;
  create?: Maybe<CreateInvoiceResponse>;
  createChargeForInvoices?: Maybe<CreateBulkChargeResponse>;
  editNotes?: Maybe<ValidationResponse>;
  postChargesToDraftInvoices?: Maybe<BulkAddChargesResponse>;
  recall?: Maybe<ValidationResponse>;
  removeCharge?: Maybe<ValidationResponse>;
};


export type InvoiceMutationModelAddChargesArgs = {
  charges?: InputMaybe<Array<Scalars['Int']>>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  projectNumber?: InputMaybe<Scalars['Int']>;
};


export type InvoiceMutationModelBillArgs = {
  invoiceDate?: InputMaybe<Scalars['LocalDate']>;
  invoiceNumber: Scalars['String'];
};


export type InvoiceMutationModelBulkAddChargesArgs = {
  addChargesArgs?: InputMaybe<Array<AddChargesToInvoiceArgsInput>>;
};


export type InvoiceMutationModelCancelArgs = {
  invoiceNumber: Scalars['String'];
  notes: Scalars['String'];
};


export type InvoiceMutationModelCreateArgs = {
  projectNumber: Scalars['Int'];
};


export type InvoiceMutationModelCreateChargeForInvoicesArgs = {
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  employee?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  invoices?: InputMaybe<Array<Scalars['String']>>;
  label: Scalars['String'];
  notes: Scalars['String'];
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  rateSheetName?: InputMaybe<Scalars['String']>;
  taxable?: InputMaybe<Scalars['Boolean']>;
  type: ChargeType;
  unit?: InputMaybe<RateUnit>;
};


export type InvoiceMutationModelEditNotesArgs = {
  invoiceNumber: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
};


export type InvoiceMutationModelPostChargesToDraftInvoicesArgs = {
  charges?: InputMaybe<Array<Scalars['Int']>>;
};


export type InvoiceMutationModelRecallArgs = {
  invoiceNumber: Scalars['String'];
};


export type InvoiceMutationModelRemoveChargeArgs = {
  chargeId: Scalars['Int'];
  invoiceNumber: Scalars['String'];
};

export type InvoiceSearchStats = {
  __typename?: 'InvoiceSearchStats';
  invoiceCount: Scalars['Int'];
};

export enum InvoiceSortBy {
  /** Customer Name */
  CustomerName = 'CustomerName',
  /** Invoice Number */
  InvoiceNumber = 'InvoiceNumber',
  /** Project ID */
  ProjectId = 'ProjectId',
  /** Project Name */
  ProjectName = 'ProjectName'
}

export enum InvoiceState {
  Billed = 'Billed',
  Cancelled = 'Cancelled',
  Draft = 'Draft'
}

export type InvoicesQueryModel = {
  __typename?: 'InvoicesQueryModel';
  allFilteredInvoices?: Maybe<Array<Maybe<Invoice>>>;
  bulkDraftInvoices?: Maybe<Array<Maybe<Invoice>>>;
  draftInvoices?: Maybe<Array<Maybe<Invoice>>>;
  invoice?: Maybe<Invoice>;
  projectsWithNoDraftInvoice?: Maybe<Array<Scalars['Int']>>;
  revenueReport?: Maybe<Array<Maybe<RevenueReportRow>>>;
  search?: Maybe<ConnectionOfInvoice>;
  stats?: Maybe<InvoiceSearchStats>;
};


export type InvoicesQueryModelAllFilteredInvoicesArgs = {
  actualCancelledDate?: InputMaybe<Scalars['String']>;
  actualDate?: InputMaybe<Scalars['String']>;
  afeWoPo?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Decimal']>;
  billingAdmin?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  endCancelledDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  groupCustomerNumber?: InputMaybe<Scalars['String']>;
  groupName?: InputMaybe<Scalars['String']>;
  invoiceNumbers?: InputMaybe<Array<Scalars['String']>>;
  locationState?: InputMaybe<Scalars['String']>;
  officeCode?: InputMaybe<Scalars['String']>;
  projectManager?: InputMaybe<Scalars['String']>;
  projectNumber?: InputMaybe<Scalars['Int']>;
  projectSupervisor?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<InvoiceSortBy>;
  startCancelledDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<InvoiceFilterState>;
};


export type InvoicesQueryModelBulkDraftInvoicesArgs = {
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
};


export type InvoicesQueryModelDraftInvoicesArgs = {
  projectNumber: Scalars['String'];
};


export type InvoicesQueryModelInvoiceArgs = {
  invoiceNumber: Scalars['String'];
};


export type InvoicesQueryModelProjectsWithNoDraftInvoiceArgs = {
  projects?: InputMaybe<Array<Scalars['Int']>>;
};


export type InvoicesQueryModelRevenueReportArgs = {
  filtering?: InputMaybe<RevenueReportFilteringArgsInput>;
  grouping?: InputMaybe<RevenueReportGroupingArgsInput>;
};


export type InvoicesQueryModelSearchArgs = {
  actualCancelledDate?: InputMaybe<Scalars['LocalDate']>;
  actualDate?: InputMaybe<Scalars['LocalDate']>;
  afeWoPo?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Decimal']>;
  billingAdmin?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  endCancelledDate?: InputMaybe<Scalars['LocalDate']>;
  endDate?: InputMaybe<Scalars['LocalDate']>;
  invoiceNumbers?: InputMaybe<Array<Scalars['String']>>;
  locationState?: InputMaybe<Scalars['String']>;
  officeCode?: InputMaybe<Scalars['String']>;
  projectGroupArgs?: InputMaybe<ProjectGroupArgsInput>;
  projectManager?: InputMaybe<Scalars['String']>;
  projectNumber?: InputMaybe<Scalars['Int']>;
  projectSupervisor?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<InvoiceSortBy>;
  startCancelledDate?: InputMaybe<Scalars['LocalDate']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  state?: InputMaybe<InvoiceFilterState>;
  token?: InputMaybe<Scalars['String']>;
};


export type InvoicesQueryModelStatsArgs = {
  actualCancelledDate?: InputMaybe<Scalars['LocalDate']>;
  actualDate?: InputMaybe<Scalars['LocalDate']>;
  afeWoPo?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Decimal']>;
  billingAdmin?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  endCancelledDate?: InputMaybe<Scalars['LocalDate']>;
  endDate?: InputMaybe<Scalars['LocalDate']>;
  invoiceNumbers?: InputMaybe<Array<Scalars['String']>>;
  locationState?: InputMaybe<Scalars['String']>;
  officeCode?: InputMaybe<Scalars['String']>;
  projectGroupArgs?: InputMaybe<ProjectGroupArgsInput>;
  projectManager?: InputMaybe<Scalars['String']>;
  projectNumber?: InputMaybe<Scalars['Int']>;
  projectSupervisor?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<InvoiceSortBy>;
  startCancelledDate?: InputMaybe<Scalars['LocalDate']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  state?: InputMaybe<InvoiceFilterState>;
  token?: InputMaybe<Scalars['String']>;
};

export type LineItemRebillInfo = {
  __typename?: 'LineItemRebillInfo';
  rebilledFromInvoice?: Maybe<Scalars['String']>;
  rebilledOnInvoice?: Maybe<Scalars['String']>;
};

export type MileageExpense = IExpense & {
  __typename?: 'MileageExpense';
  beginningOdometer: Scalars['Decimal'];
  billableAmount: Scalars['Decimal'];
  companyVehicle: Scalars['Boolean'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  endingOdometer: Scalars['Decimal'];
  expenseAccount?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<ExpenseHistoryItem>>>;
  id: Scalars['String'];
  importEdited: Scalars['Boolean'];
  invoiceLabel?: Maybe<Scalars['String']>;
  lastAdminNotes?: Maybe<Scalars['String']>;
  location?: Maybe<StateCounty>;
  mileage: Scalars['Decimal'];
  notes?: Maybe<Scalars['String']>;
  payable: Payable;
  payableAmount: Scalars['Decimal'];
  payableOverridden: Scalars['Boolean'];
  project?: Maybe<Project>;
  rate: Scalars['Decimal'];
  rateName: Scalars['String'];
  rateSheetName: Scalars['String'];
  vehicleNumber?: Maybe<Scalars['String']>;
};

export type MileageExpenseRate = IExpenseRate & {
  __typename?: 'MileageExpenseRate';
  billableAmountPerMile: Scalars['Decimal'];
  expenseAccount?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  invoiceLabel?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reimbursableAmountPerMile: Scalars['Decimal'];
  unit?: Maybe<RateUnit>;
};

export type MobileAllowanceExpense = IExpense & {
  __typename?: 'MobileAllowanceExpense';
  billableAmount: Scalars['Decimal'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  expenseAccount?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<ExpenseHistoryItem>>>;
  id: Scalars['String'];
  importEdited: Scalars['Boolean'];
  invoiceLabel?: Maybe<Scalars['String']>;
  lastAdminNotes?: Maybe<Scalars['String']>;
  location?: Maybe<StateCounty>;
  notes?: Maybe<Scalars['String']>;
  payable: Payable;
  payableAmount: Scalars['Decimal'];
  payableOverridden: Scalars['Boolean'];
  project?: Maybe<Project>;
  quantity: Scalars['Int'];
  rateName: Scalars['String'];
  rateSheetName: Scalars['String'];
};

export type MobileAllowanceExpenseRate = IExpenseRate & {
  __typename?: 'MobileAllowanceExpenseRate';
  billableAmount: Scalars['Decimal'];
  expenseAccount?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  invoiceLabel?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  quantity: Scalars['Int'];
  reimbursableAmount: Scalars['Decimal'];
  unit?: Maybe<RateUnit>;
};

export type MutationModel = {
  __typename?: 'MutationModel';
  corpLocations?: Maybe<CorpLocationMutationModel>;
  crewCodes?: Maybe<CrewCodeMutationModel>;
  customRollupInvoices?: Maybe<CustomRollupInvoiceMutationModel>;
  customers?: Maybe<CustomerMutationModel>;
  divisions?: Maybe<DivisionMutationModel>;
  employeeRates?: Maybe<EmployeeRatesMutationModel>;
  employees?: Maybe<EmployeeMutationModel>;
  expenseRates?: Maybe<ExpenseRatesMutationModel>;
  fieldOffices?: Maybe<FieldOfficeMutationModel>;
  helpRequest?: Maybe<HelpRequestMutationModel>;
  invoices?: Maybe<InvoiceMutationModel>;
  notifications?: Maybe<AppNotificationMutationModel>;
  perDiemRates?: Maybe<PerDiemRatesMutationModel>;
  projectCharges?: Maybe<ProjectChargesMutationModel>;
  projectGroups?: Maybe<ProjectGroupMutationModel>;
  projectRequests?: Maybe<ProjectRequestMutationModel>;
  projects?: Maybe<ProjectsMutationModel>;
  taskRates?: Maybe<TaskRatesMutationModel>;
  thirdPartyInvoices?: Maybe<ThirdPartyInvoicesMutationModel>;
  timesheets?: Maybe<TimesheetsMutationModel>;
  users?: Maybe<UsersMutationModel>;
};

export type MyPendingRequests = {
  __typename?: 'MyPendingRequests';
  maxAge: Scalars['Instant'];
  officeCode: Scalars['String'];
  requests: Scalars['Int'];
};

export type NewCustomerArgsInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  adminNotes?: InputMaybe<Scalars['String']>;
  attn?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type NotificationsResult = {
  __typename?: 'NotificationsResult';
  count: Scalars['Int'];
  notifications?: Maybe<Array<Maybe<AppNotification>>>;
};

export type OfficeCodeEmployeeCount = {
  __typename?: 'OfficeCodeEmployeeCount';
  employeeCount: Scalars['Int'];
  officeCode: Scalars['String'];
};

export type OfficeOverheadCrewCode = {
  __typename?: 'OfficeOverheadCrewCode';
  crewCode: Scalars['String'];
  fieldOffice: Scalars['String'];
};

export type OverheadCrewCodeQueryModel = {
  __typename?: 'OverheadCrewCodeQueryModel';
  officeCrewCodes?: Maybe<Array<Maybe<OfficeOverheadCrewCode>>>;
  stateCrewCodes?: Maybe<Array<Maybe<StateOverheadCrewCode>>>;
};

export enum OwnerInformation {
  Company = 'Company',
  Personal = 'Personal'
}

export enum PayPeriod {
  Biweekly = 'Biweekly',
  Weekly = 'Weekly'
}

export enum Payable {
  Invoice = 'Invoice',
  None = 'None',
  Payroll = 'Payroll',
  Reimbursement = 'Reimbursement'
}

export enum PayerInformation {
  Company = 'Company',
  Employee = 'Employee'
}

export type PayrollAdminData = {
  __typename?: 'PayrollAdminData';
  officeCodeData?: Maybe<Array<Maybe<OfficeCodeEmployeeCount>>>;
};

export type PendingCustomer = {
  __typename?: 'PendingCustomer';
  address1: Scalars['String'];
  address2: Scalars['String'];
  adminNotes?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  createCustomerRequestId: Scalars['ID'];
  name: Scalars['String'];
  nmTaxable: Scalars['Boolean'];
  phone: Scalars['String'];
  requester?: Maybe<Employee>;
  state: Scalars['String'];
  subAccountOf?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type PerDiemExpense = IExpense & {
  __typename?: 'PerDiemExpense';
  billableAmount: Scalars['Decimal'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  expenseAccount?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<ExpenseHistoryItem>>>;
  id: Scalars['String'];
  importEdited: Scalars['Boolean'];
  invoiceLabel?: Maybe<Scalars['String']>;
  kind: PerDiemKind;
  lastAdminNotes?: Maybe<Scalars['String']>;
  location?: Maybe<StateCounty>;
  notes?: Maybe<Scalars['String']>;
  payable: Payable;
  payableAmount: Scalars['Decimal'];
  payableOverridden: Scalars['Boolean'];
  project?: Maybe<Project>;
  quantity: Scalars['Int'];
  rateName: Scalars['String'];
  rateSheetName: Scalars['String'];
  receiptImageId?: Maybe<Scalars['String']>;
  receiptImageUrl?: Maybe<Scalars['String']>;
};

export type PerDiemExpenseRate = IExpenseRate & {
  __typename?: 'PerDiemExpenseRate';
  billableOverride?: Maybe<Scalars['Decimal']>;
  expenseAccount?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  invoiceLabel?: Maybe<Scalars['String']>;
  kind: PerDiemKind;
  name: Scalars['String'];
  quantity: Scalars['Int'];
  unit?: Maybe<RateUnit>;
};

export enum PerDiemKind {
  Lodging = 'Lodging',
  Meals = 'Meals',
  Travel = 'Travel',
  TravelMeals = 'TravelMeals'
}

export type PerDiemRate = {
  __typename?: 'PerDiemRate';
  county: Scalars['String'];
  effectiveDate: Scalars['LocalDate'];
  lodgingBillable: Scalars['Decimal'];
  lodgingReimbursable: Scalars['Decimal'];
  mealsBillable: Scalars['Decimal'];
  mealsReimbursable: Scalars['Decimal'];
  state: Scalars['String'];
  travelBillable: Scalars['Decimal'];
  travelReimbursable: Scalars['Decimal'];
};

export type PerDiemRatesMutationModel = {
  __typename?: 'PerDiemRatesMutationModel';
  create?: Maybe<ValidationResponse>;
  delete?: Maybe<ValidationResponse>;
};


export type PerDiemRatesMutationModelCreateArgs = {
  effectiveDate: Scalars['LocalDate'];
  locations?: InputMaybe<Array<StateCountyInput>>;
  lodgingBillable: Scalars['Decimal'];
  lodgingReimbursable: Scalars['Decimal'];
  mealsBillable: Scalars['Decimal'];
  mealsReimbursable: Scalars['Decimal'];
  travelBillable: Scalars['Decimal'];
  travelReimbursable: Scalars['Decimal'];
};


export type PerDiemRatesMutationModelDeleteArgs = {
  effectiveDate: Scalars['LocalDate'];
  locations?: InputMaybe<Array<StateCountyInput>>;
  lodgingBillable: Scalars['Decimal'];
  lodgingReimbursable: Scalars['Decimal'];
  mealsBillable: Scalars['Decimal'];
  mealsReimbursable: Scalars['Decimal'];
  travelBillable: Scalars['Decimal'];
  travelReimbursable: Scalars['Decimal'];
};

export type PerDiemRatesQueryModel = {
  __typename?: 'PerDiemRatesQueryModel';
  rate?: Maybe<PerDiemRate>;
  ratesByEffectiveDate?: Maybe<Array<Maybe<PerDiemRate>>>;
  ratesByState?: Maybe<Array<Maybe<PerDiemRate>>>;
  stats?: Maybe<Array<Maybe<PerDiemStateStats>>>;
};


export type PerDiemRatesQueryModelRateArgs = {
  county: Scalars['String'];
  effectiveDate: Scalars['LocalDate'];
  state: Scalars['String'];
};


export type PerDiemRatesQueryModelRatesByEffectiveDateArgs = {
  effectiveDate: Scalars['LocalDate'];
};


export type PerDiemRatesQueryModelRatesByStateArgs = {
  state: Scalars['String'];
};


export type PerDiemRatesQueryModelStatsArgs = {
  filterText?: InputMaybe<Scalars['String']>;
};

export type PerDiemStateStats = {
  __typename?: 'PerDiemStateStats';
  counties: Scalars['Int'];
  records: Scalars['Int'];
  state: Scalars['String'];
  totalRecords: Scalars['Int'];
};

export type PrevWorkDay = {
  __typename?: 'PrevWorkDay';
  date?: Maybe<Scalars['LocalDate']>;
  expenseCount: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  projectTimeCount: Scalars['Int'];
  timeEntryCount: Scalars['Int'];
  timeOffCount: Scalars['Int'];
};

export type PreviousNameHistoryItem = {
  __typename?: 'PreviousNameHistoryItem';
  changedBy: Scalars['String'];
  changedOn?: Maybe<Scalars['LocalDate']>;
  name: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  actualEndDate?: Maybe<Scalars['LocalDate']>;
  actualStartDate?: Maybe<Scalars['LocalDate']>;
  afe?: Maybe<Scalars['String']>;
  allowThirdPartyInvoice: Scalars['Boolean'];
  billingAdmin?: Maybe<Employee>;
  billingFrequency: BillingFrequencyType;
  block?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Decimal']>;
  callerName: Scalars['String'];
  canAddCharge: Scalars['Boolean'];
  canCreateInvoice: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
  canViewInvoices: Scalars['Boolean'];
  certifiedPayroll: Scalars['Boolean'];
  changedOn?: Maybe<Scalars['Instant']>;
  charges?: Maybe<ConnectionOfProjectCharge>;
  clientContactName: Scalars['String'];
  contractNumber?: Maybe<Scalars['String']>;
  corpLocation?: Maybe<CorpLocation>;
  costCenter?: Maybe<Scalars['String']>;
  createdOn: Scalars['LocalDate'];
  customer?: Maybe<Customer>;
  dateIn: Scalars['LocalDate'];
  description: Scalars['String'];
  division?: Maybe<Division>;
  estimatedEndDate?: Maybe<Scalars['LocalDate']>;
  estimatedStartDate?: Maybe<Scalars['LocalDate']>;
  expenseRateSheets?: Maybe<Array<Maybe<ExpenseRateSheetAssignment>>>;
  geographicLocation: Scalars['String'];
  glCode?: Maybe<Scalars['String']>;
  governmentFunding: GovernmentFundingType;
  internalNotes: Scalars['String'];
  invoiceNotes: Scalars['String'];
  legacy: Scalars['Boolean'];
  legacyBilled: Scalars['Decimal'];
  location?: Maybe<StateCounty>;
  name: Scalars['String'];
  networkPath?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  officeCode: Scalars['String'];
  originalName: Scalars['String'];
  overheadCrewCode?: Maybe<CrewCodeRecord>;
  pendingTimeEntries?: Maybe<Array<Maybe<ProjectTimeEntry>>>;
  po?: Maybe<Scalars['String']>;
  previousNames?: Maybe<Array<Maybe<PreviousNameHistoryItem>>>;
  projectGroup?: Maybe<ProjectGroup>;
  projectManager?: Maybe<Employee>;
  projectOrigination?: Maybe<ProjectOrigination>;
  projectOriginationName?: Maybe<Scalars['String']>;
  projectSupervisor?: Maybe<Employee>;
  publishDate?: Maybe<Scalars['Instant']>;
  range?: Maybe<Scalars['String']>;
  requisitioner?: Maybe<Scalars['String']>;
  scope: Scalars['String'];
  section?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<ServiceDescription>;
  simplifiedName: Scalars['String'];
  stats?: Maybe<ProjectStats>;
  status: ProjectStatus;
  survey?: Maybe<Scalars['String']>;
  taskRateSheets?: Maybe<Array<Maybe<TaskRateSheetAssignment>>>;
  township?: Maybe<Scalars['String']>;
  wo?: Maybe<Scalars['String']>;
};


export type ProjectChargesArgs = {
  allBillable?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<ProjectChargeFilterState>;
  token?: InputMaybe<Scalars['String']>;
};

export type ProjectChangedHistoryItem = IProjectChargeHistoryItem & {
  __typename?: 'ProjectChangedHistoryItem';
  changedBy: Scalars['String'];
  changedOn: Scalars['Instant'];
  editNotes: Scalars['String'];
  event: Scalars['String'];
  newProjectId: Scalars['Int'];
  oldProjectId: Scalars['Int'];
};

export type ProjectCharge = {
  __typename?: 'ProjectCharge';
  billable: Scalars['Boolean'];
  canAdjust: Scalars['Boolean'];
  canBaApprove: Scalars['Boolean'];
  canEditBillable: Scalars['Boolean'];
  canEditDate: Scalars['Boolean'];
  canEditTaxable: Scalars['Boolean'];
  canMoveProject: Scalars['Boolean'];
  canPmApprove: Scalars['Boolean'];
  canPostToInvoice: Scalars['Boolean'];
  canPsApprove: Scalars['Boolean'];
  canSplit: Scalars['Boolean'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  employee?: Maybe<Employee>;
  expense?: Maybe<IExpense>;
  expenseId: Scalars['String'];
  glAccount?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<IProjectChargeHistoryItem>>>;
  id: Scalars['Int'];
  isThirdParty: Scalars['Boolean'];
  label: Scalars['String'];
  lastEditNotes?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  projectId: Scalars['Int'];
  projectTime?: Maybe<ProjectTimeEntry>;
  projectTimeId: Scalars['String'];
  quantity?: Maybe<Scalars['Decimal']>;
  rate: Scalars['Decimal'];
  rateSheetName?: Maybe<Scalars['String']>;
  state: ProjectChargeState;
  taxable: Scalars['Boolean'];
  timesheetQuantity?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  type: ChargeType;
  unit?: Maybe<RateUnit>;
};

export type ProjectChargeBillableHistoryItem = IProjectChargeHistoryItem & {
  __typename?: 'ProjectChargeBillableHistoryItem';
  billable: Scalars['Boolean'];
  changedBy: Scalars['String'];
  changedOn: Scalars['Instant'];
  editNotes: Scalars['String'];
  event: Scalars['String'];
};

export type ProjectChargeCrewCodeHistoryItem = IProjectChargeHistoryItem & {
  __typename?: 'ProjectChargeCrewCodeHistoryItem';
  changedBy: Scalars['String'];
  changedOn: Scalars['Instant'];
  crewCode: Scalars['String'];
  editNotes: Scalars['String'];
  event: Scalars['String'];
};

export type ProjectChargeEditedHistoryItem = IProjectChargeHistoryItem & {
  __typename?: 'ProjectChargeEditedHistoryItem';
  billable: Scalars['Boolean'];
  changedBy: Scalars['String'];
  changedOn: Scalars['Instant'];
  crewCode: Scalars['String'];
  date?: Maybe<Scalars['LocalDate']>;
  editNotes: Scalars['String'];
  employee: Scalars['String'];
  event: Scalars['String'];
  glAccount: Scalars['String'];
  label: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Decimal']>;
  rate: Scalars['Decimal'];
  unit?: Maybe<RateUnit>;
};

export enum ProjectChargeFilterState {
  Billed = 'Billed',
  PendingBilling = 'PendingBilling',
  Unapproved = 'Unapproved',
  Unbilled = 'Unbilled'
}

export type ProjectChargeHistoryItem = IProjectChargeHistoryItem & {
  __typename?: 'ProjectChargeHistoryItem';
  changedBy: Scalars['String'];
  changedOn: Scalars['Instant'];
  event: Scalars['String'];
};

export type ProjectChargeHistoryItemDetailed = IProjectChargeHistoryItem & {
  __typename?: 'ProjectChargeHistoryItemDetailed';
  billable: Scalars['Boolean'];
  changedBy: Scalars['String'];
  changedOn: Scalars['Instant'];
  crewCode: Scalars['String'];
  date?: Maybe<Scalars['LocalDate']>;
  employee: Scalars['String'];
  event: Scalars['String'];
  glAccount: Scalars['String'];
  label: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Decimal']>;
  rate: Scalars['Decimal'];
  unit?: Maybe<RateUnit>;
};

export type ProjectChargeQuantityHistoryItem = IProjectChargeHistoryItem & {
  __typename?: 'ProjectChargeQuantityHistoryItem';
  changedBy: Scalars['String'];
  changedOn: Scalars['Instant'];
  event: Scalars['String'];
  quantity: Scalars['Decimal'];
};

export type ProjectChargeRoleStats = {
  __typename?: 'ProjectChargeRoleStats';
  allBaApproval?: Maybe<Scalars['Int']>;
  allBaApprovalProjects?: Maybe<Scalars['Int']>;
  allPmApproval?: Maybe<Scalars['Int']>;
  allPmApprovalProjects?: Maybe<Scalars['Int']>;
  allPsApproval?: Maybe<Scalars['Int']>;
  allPsApprovalProjects?: Maybe<Scalars['Int']>;
  myBaApproval: Scalars['Int'];
  myBaApprovalProjects: Scalars['Int'];
  myPmApproval: Scalars['Int'];
  myPmApprovalProjects: Scalars['Int'];
  myPsApproval: Scalars['Int'];
  myPsApprovalProjects: Scalars['Int'];
  showIfEmpty: Scalars['Boolean'];
};

export enum ProjectChargeSearchFilterState {
  Any = 'Any',
  Archived = 'Archived',
  BaPending = 'BaPending',
  Billed = 'Billed',
  PmPending = 'PmPending',
  PostPending = 'PostPending',
  PostedNotBilled = 'PostedNotBilled',
  PrPending = 'PrPending',
  PsPending = 'PsPending',
  Unbilled = 'Unbilled'
}

export type ProjectChargeSearchStats = {
  __typename?: 'ProjectChargeSearchStats';
  chargeTotal: Scalars['Decimal'];
  projectTotal: Scalars['Decimal'];
};

export enum ProjectChargeState {
  Archived = 'Archived',
  BaPending = 'BaPending',
  Billed = 'Billed',
  Cancelled = 'Cancelled',
  PmPending = 'PmPending',
  PostPending = 'PostPending',
  PostedNotBilled = 'PostedNotBilled',
  PrPending = 'PrPending',
  PsPending = 'PsPending'
}

export type ProjectChargeTaxableHistoryItem = IProjectChargeHistoryItem & {
  __typename?: 'ProjectChargeTaxableHistoryItem';
  changedBy: Scalars['String'];
  changedOn: Scalars['Instant'];
  event: Scalars['String'];
  taxable: Scalars['Boolean'];
};

export type ProjectChargesMutationModel = {
  __typename?: 'ProjectChargesMutationModel';
  baApprove?: Maybe<ValidationResponse>;
  changeProject?: Maybe<ValidationResponse>;
  createInvoiceCharge?: Maybe<CreateProjectChargeResponse>;
  createProjectCharge?: Maybe<CreateProjectChargeResponse>;
  editCrewCode?: Maybe<ValidationResponse>;
  editProjectCharge?: Maybe<ValidationResponse>;
  markAllReviewed?: Maybe<ValidationResponse>;
  markBillable?: Maybe<ValidationResponse>;
  markTaxable?: Maybe<ValidationResponse>;
  pmApprove?: Maybe<ValidationResponse>;
  psApprove?: Maybe<ValidationResponse>;
  splitCharge?: Maybe<SplitChargeResponse>;
};


export type ProjectChargesMutationModelBaApproveArgs = {
  projectCharges?: InputMaybe<Array<Scalars['Int']>>;
};


export type ProjectChargesMutationModelChangeProjectArgs = {
  editNotes: Scalars['String'];
  newProjectId: Scalars['Int'];
  projectCharges?: InputMaybe<Array<Scalars['Int']>>;
};


export type ProjectChargesMutationModelCreateInvoiceChargeArgs = {
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  employee?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  notes: Scalars['String'];
  projectNumber: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  rateSheetName?: InputMaybe<Scalars['String']>;
  taxable?: InputMaybe<Scalars['Boolean']>;
  type: ChargeType;
  unit?: InputMaybe<RateUnit>;
};


export type ProjectChargesMutationModelCreateProjectChargeArgs = {
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  employee?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  notes: Scalars['String'];
  projectNumber: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  rateSheetName?: InputMaybe<Scalars['String']>;
  taxable?: InputMaybe<Scalars['Boolean']>;
  type: ChargeType;
  unit?: InputMaybe<RateUnit>;
};


export type ProjectChargesMutationModelEditCrewCodeArgs = {
  crewCode: Scalars['String'];
  editNotes: Scalars['String'];
  projectCharges?: InputMaybe<Array<Scalars['Int']>>;
};


export type ProjectChargesMutationModelEditProjectChargeArgs = {
  billable: Scalars['Boolean'];
  crewCode: Scalars['String'];
  date?: InputMaybe<Scalars['LocalDate']>;
  editNotes: Scalars['String'];
  employee?: InputMaybe<Scalars['String']>;
  glAccount?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  label: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['Int']>;
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  rateSheetName?: InputMaybe<Scalars['String']>;
  taxable?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<RateUnit>;
};


export type ProjectChargesMutationModelMarkAllReviewedArgs = {
  projectNumber: Scalars['Int'];
};


export type ProjectChargesMutationModelMarkBillableArgs = {
  billable: Scalars['Boolean'];
  editNotes: Scalars['String'];
  projectCharges?: InputMaybe<Array<Scalars['Int']>>;
};


export type ProjectChargesMutationModelMarkTaxableArgs = {
  projectCharges?: InputMaybe<Array<Scalars['Int']>>;
  taxable: Scalars['Boolean'];
};


export type ProjectChargesMutationModelPmApproveArgs = {
  projectCharges?: InputMaybe<Array<Scalars['Int']>>;
};


export type ProjectChargesMutationModelPsApproveArgs = {
  projectCharges?: InputMaybe<Array<Scalars['Int']>>;
};


export type ProjectChargesMutationModelSplitChargeArgs = {
  adminNotes: Scalars['String'];
  id: Scalars['Int'];
  label: Scalars['String'];
  notes: Scalars['String'];
  projectId?: InputMaybe<Scalars['Int']>;
  quantity: Scalars['Decimal'];
  splitChargeArgs?: InputMaybe<Array<SplitChargeArgsInput>>;
  taxable: Scalars['Boolean'];
};

export type ProjectChargesQueryModel = {
  __typename?: 'ProjectChargesQueryModel';
  allFilteredCharges?: Maybe<Array<Maybe<ProjectCharge>>>;
  charges?: Maybe<Array<Maybe<ProjectCharge>>>;
  roleStats?: Maybe<ProjectChargeRoleStats>;
  search?: Maybe<ConnectionOfProjectCharge>;
  single?: Maybe<ProjectCharge>;
  stats?: Maybe<ProjectChargeSearchStats>;
};


export type ProjectChargesQueryModelAllFilteredChargesArgs = {
  afeWoPo?: InputMaybe<Scalars['String']>;
  billable?: InputMaybe<BillableFilter>;
  billingAdmin?: InputMaybe<Scalars['String']>;
  chargeIds?: InputMaybe<Array<Scalars['Int']>>;
  customerNumber?: InputMaybe<Scalars['String']>;
  dateAfter?: InputMaybe<Scalars['String']>;
  dateBefore?: InputMaybe<Scalars['String']>;
  dateEqual?: InputMaybe<Scalars['String']>;
  employee?: InputMaybe<Scalars['String']>;
  officeCode?: InputMaybe<Scalars['String']>;
  projectManager?: InputMaybe<Scalars['String']>;
  projectNumber?: InputMaybe<Scalars['Int']>;
  projectSupervisor?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<ProjectChargeSearchFilterState>;
};


export type ProjectChargesQueryModelChargesArgs = {
  projectCharges?: InputMaybe<Array<Scalars['Int']>>;
};


export type ProjectChargesQueryModelSearchArgs = {
  afeWoPo?: InputMaybe<Scalars['String']>;
  billable?: InputMaybe<BillableFilter>;
  billingAdmin?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  dateAfter?: InputMaybe<Scalars['String']>;
  dateBefore?: InputMaybe<Scalars['String']>;
  dateEqual?: InputMaybe<Scalars['String']>;
  employee?: InputMaybe<Scalars['String']>;
  officeCode?: InputMaybe<Scalars['String']>;
  projectManager?: InputMaybe<Scalars['String']>;
  projectNumber?: InputMaybe<Scalars['Int']>;
  projectSupervisor?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ProjectSortBy>;
  state?: InputMaybe<ProjectChargeSearchFilterState>;
  token?: InputMaybe<Scalars['String']>;
};


export type ProjectChargesQueryModelSingleArgs = {
  id: Scalars['Int'];
};


export type ProjectChargesQueryModelStatsArgs = {
  afeWoPo?: InputMaybe<Scalars['String']>;
  billable?: InputMaybe<BillableFilter>;
  billingAdmin?: InputMaybe<Scalars['String']>;
  chargeIds?: InputMaybe<Array<Scalars['Int']>>;
  customerNumber?: InputMaybe<Scalars['String']>;
  dateAfter?: InputMaybe<Scalars['String']>;
  dateBefore?: InputMaybe<Scalars['String']>;
  dateEqual?: InputMaybe<Scalars['String']>;
  employee?: InputMaybe<Scalars['String']>;
  officeCode?: InputMaybe<Scalars['String']>;
  projectManager?: InputMaybe<Scalars['String']>;
  projectNumber?: InputMaybe<Scalars['Int']>;
  projectSupervisor?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<ProjectChargeSearchFilterState>;
};

export type ProjectGroup = {
  __typename?: 'ProjectGroup';
  customer?: Maybe<Customer>;
  id: Scalars['Int'];
  name: Scalars['String'];
  projects?: Maybe<Array<Maybe<Project>>>;
};

export type ProjectGroupArgsInput = {
  customerNumber: Scalars['String'];
  groupName: Scalars['String'];
};

export type ProjectGroupMutationModel = {
  __typename?: 'ProjectGroupMutationModel';
  create?: Maybe<ValidationResponse>;
  edit?: Maybe<ValidationResponse>;
};


export type ProjectGroupMutationModelCreateArgs = {
  customerNumber: Scalars['String'];
  name: Scalars['String'];
};


export type ProjectGroupMutationModelEditArgs = {
  customerNumber: Scalars['String'];
  newName: Scalars['String'];
  oldName: Scalars['String'];
};

export type ProjectGroupQueryModel = {
  __typename?: 'ProjectGroupQueryModel';
  all?: Maybe<Array<Maybe<ProjectGroup>>>;
};

export enum ProjectOrigination {
  /** BD - Lueders */
  BdLueders = 'BdLueders',
  /** BD - McBee */
  BdMcBee = 'BdMcBee',
  /** BD - Shablo */
  BdShablo = 'BdShablo',
  /** BD - Storozyszyn */
  BdStorozyszyn = 'BdStorozyszyn',
  /** Bid Publication Service */
  BidPublicationService = 'BidPublicationService',
  /** Civil Survey Department */
  CivilSurveyDepartment = 'CivilSurveyDepartment',
  /** Construction Department */
  ConstructionDepartment = 'ConstructionDepartment',
  /** Engineering Department */
  EngineeringDepartment = 'EngineeringDepartment',
  /** Environmental Department */
  EnvironmentalDepartment = 'EnvironmentalDepartment',
  /** Event */
  Event = 'Event',
  /** Existing Customer */
  ExistingCustomer = 'ExistingCustomer',
  /** Facebook */
  Facebook = 'Facebook',
  /** Geospatial Department */
  GeospatialDepartment = 'GeospatialDepartment',
  /** Google */
  Google = 'Google',
  /** Instagram */
  Instragram = 'Instragram',
  /** Referral */
  Referral = 'Referral',
  /** Survey Department */
  SurveyDepartment = 'SurveyDepartment',
  /** Topographic Website */
  TopographicWebsite = 'TopographicWebsite',
  /** Unset */
  Unset = 'Unset'
}

export type ProjectRequest = {
  __typename?: 'ProjectRequest';
  additionalComments?: Maybe<Scalars['String']>;
  afe?: Maybe<Scalars['String']>;
  approver?: Maybe<Employee>;
  block?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Decimal']>;
  certifiedPayroll: Scalars['Boolean'];
  checkedOutBy?: Maybe<Employee>;
  clientContactName: Scalars['String'];
  coGo?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  costCenter?: Maybe<Scalars['String']>;
  createCustomerRequestId?: Maybe<Scalars['ID']>;
  createdDate: Scalars['LocalDate'];
  createdOn: Scalars['Instant'];
  customer?: Maybe<Customer>;
  fieldContact?: Maybe<Scalars['String']>;
  fieldInstructions?: Maybe<Scalars['String']>;
  fieldMeetInfo?: Maybe<Scalars['String']>;
  geographicLocation?: Maybe<Scalars['String']>;
  glCode?: Maybe<Scalars['String']>;
  governmentFunding: GovernmentFundingType;
  invoiceContact?: Maybe<Scalars['String']>;
  invoiceSubmitEmail?: Maybe<Scalars['String']>;
  jobsFolderRequired: Scalars['Boolean'];
  lastCheckoutTime?: Maybe<Scalars['Instant']>;
  location?: Maybe<StateCounty>;
  name: Scalars['String'];
  officeCode: Scalars['String'];
  officeInstructions?: Maybe<Scalars['String']>;
  pendingCustomer?: Maybe<Customer>;
  po?: Maybe<Scalars['String']>;
  projectInstructions?: Maybe<Scalars['String']>;
  projectManager?: Maybe<Employee>;
  projectOrigination?: Maybe<ProjectOrigination>;
  projectOriginationName?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['String']>;
  rejectedReason?: Maybe<Scalars['String']>;
  requestId: Scalars['ID'];
  requestedCustomer?: Maybe<ProjectRequestNewCustomer>;
  requester?: Maybe<Employee>;
  requisitioner?: Maybe<Scalars['String']>;
  scope: Scalars['String'];
  section?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<ServiceDescription>;
  simplifiedName: Scalars['String'];
  state: ProjectRequestState;
  statusMessage?: Maybe<Scalars['String']>;
  survey?: Maybe<Scalars['String']>;
  taxableServices: Scalars['Boolean'];
  township?: Maybe<Scalars['String']>;
  wo?: Maybe<Scalars['String']>;
};

export type ProjectRequestCustomerArgsInput = {
  existingNumber?: InputMaybe<Scalars['String']>;
  newCustomer?: InputMaybe<NewCustomerArgsInput>;
  pendingRequestId?: InputMaybe<Scalars['String']>;
};

export type ProjectRequestGroup = {
  __typename?: 'ProjectRequestGroup';
  groupId: Scalars['ID'];
  requests?: Maybe<Array<Maybe<ProjectRequest>>>;
  type: GroupType;
};

export type ProjectRequestMutationModel = {
  __typename?: 'ProjectRequestMutationModel';
  approveGroup?: Maybe<ValidationResponse>;
  checkout?: Maybe<ValidationResponse>;
  create?: Maybe<CreateProjectRequestResponse>;
  edit?: Maybe<ValidationResponse>;
  editGroupCustomer?: Maybe<ValidationResponse>;
  editGroupOffice?: Maybe<ValidationResponse>;
  reject?: Maybe<ValidationResponse>;
  ungroup?: Maybe<ValidationResponse>;
};


export type ProjectRequestMutationModelApproveGroupArgs = {
  assignExpenseRateSheet?: InputMaybe<AssignRateSheetArgsInput>;
  assignTaskRateSheet?: InputMaybe<AssignRateSheetArgsInput>;
  billingAdmin: Scalars['String'];
  billingFrequency: BillingFrequencyType;
  corpLocation: Scalars['String'];
  customerSubAccountOf?: InputMaybe<Scalars['String']>;
  division: Scalars['String'];
  invoiceNotes?: InputMaybe<Scalars['String']>;
  memberId: Scalars['String'];
  projectSupervisor: Scalars['String'];
};


export type ProjectRequestMutationModelCheckoutArgs = {
  overrideCheckout: Scalars['Boolean'];
  requestId: Scalars['String'];
};


export type ProjectRequestMutationModelCreateArgs = {
  additionalComments?: InputMaybe<Scalars['String']>;
  afe?: InputMaybe<Scalars['String']>;
  block?: InputMaybe<Scalars['String']>;
  budget?: InputMaybe<Scalars['Decimal']>;
  certifiedPayroll?: InputMaybe<Scalars['Boolean']>;
  clientContactName: Scalars['String'];
  coGo?: InputMaybe<Scalars['String']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  costCenter?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  customer?: InputMaybe<ProjectRequestCustomerArgsInput>;
  fieldContact?: InputMaybe<Scalars['String']>;
  fieldInstructions?: InputMaybe<Scalars['String']>;
  fieldMeetInfo?: InputMaybe<Scalars['String']>;
  geographicLocation?: InputMaybe<Scalars['String']>;
  glCode?: InputMaybe<Scalars['String']>;
  governmentFunding: GovernmentFundingType;
  invoiceContact?: InputMaybe<Scalars['String']>;
  invoiceSubmitEmail?: InputMaybe<Scalars['String']>;
  jobsFolderRequired: Scalars['Boolean'];
  name: Scalars['String'];
  officeCode: Scalars['String'];
  officeInstructions?: InputMaybe<Scalars['String']>;
  po?: InputMaybe<Scalars['String']>;
  projectInstructions?: InputMaybe<Scalars['String']>;
  projectManager: Scalars['String'];
  projectOrigination: ProjectOrigination;
  projectOriginationName?: InputMaybe<Scalars['String']>;
  range?: InputMaybe<Scalars['String']>;
  requisitioner?: InputMaybe<Scalars['String']>;
  scope: Scalars['String'];
  section?: InputMaybe<Scalars['String']>;
  serviceDescription: Scalars['String'];
  state: Scalars['String'];
  survey?: InputMaybe<Scalars['String']>;
  taxableServices: Scalars['Boolean'];
  township?: InputMaybe<Scalars['String']>;
  wo?: InputMaybe<Scalars['String']>;
};


export type ProjectRequestMutationModelEditArgs = {
  additionalComments?: InputMaybe<Scalars['String']>;
  afe?: InputMaybe<Scalars['String']>;
  block?: InputMaybe<Scalars['String']>;
  budget?: InputMaybe<Scalars['Decimal']>;
  certifiedPayroll?: InputMaybe<Scalars['Boolean']>;
  clientContactName: Scalars['String'];
  coGo?: InputMaybe<Scalars['String']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  costCenter?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  customer?: InputMaybe<ProjectRequestCustomerArgsInput>;
  fieldContact?: InputMaybe<Scalars['String']>;
  fieldInstructions?: InputMaybe<Scalars['String']>;
  fieldMeetInfo?: InputMaybe<Scalars['String']>;
  geographicLocation?: InputMaybe<Scalars['String']>;
  glCode?: InputMaybe<Scalars['String']>;
  governmentFunding: GovernmentFundingType;
  invoiceContact?: InputMaybe<Scalars['String']>;
  invoiceSubmitEmail?: InputMaybe<Scalars['String']>;
  jobsFolderRequired: Scalars['Boolean'];
  name: Scalars['String'];
  notes: Scalars['String'];
  officeCode: Scalars['String'];
  officeInstructions?: InputMaybe<Scalars['String']>;
  po?: InputMaybe<Scalars['String']>;
  projectInstructions?: InputMaybe<Scalars['String']>;
  projectManager: Scalars['String'];
  projectOrigination: ProjectOrigination;
  projectOriginationName?: InputMaybe<Scalars['String']>;
  range?: InputMaybe<Scalars['String']>;
  requestId: Scalars['String'];
  requisitioner?: InputMaybe<Scalars['String']>;
  scope: Scalars['String'];
  section?: InputMaybe<Scalars['String']>;
  serviceDescription: Scalars['String'];
  state: Scalars['String'];
  survey?: InputMaybe<Scalars['String']>;
  taxableServices: Scalars['Boolean'];
  township?: InputMaybe<Scalars['String']>;
  wo?: InputMaybe<Scalars['String']>;
};


export type ProjectRequestMutationModelEditGroupCustomerArgs = {
  customer?: InputMaybe<ProjectRequestCustomerArgsInput>;
  memberId: Scalars['String'];
  notes: Scalars['String'];
};


export type ProjectRequestMutationModelEditGroupOfficeArgs = {
  memberId: Scalars['String'];
  notes: Scalars['String'];
  officeCode: Scalars['String'];
};


export type ProjectRequestMutationModelRejectArgs = {
  reason: Scalars['String'];
  requestId: Scalars['String'];
};


export type ProjectRequestMutationModelUngroupArgs = {
  memberId: Scalars['String'];
  notes: Scalars['String'];
};

export type ProjectRequestNewCustomer = {
  __typename?: 'ProjectRequestNewCustomer';
  address1: Scalars['String'];
  address2: Scalars['String'];
  adminNotes?: Maybe<Scalars['String']>;
  attn?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  fax: Scalars['String'];
  name: Scalars['String'];
  notes: Scalars['String'];
  phone: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type ProjectRequestQueryModel = {
  __typename?: 'ProjectRequestQueryModel';
  groupSearch?: Maybe<ConnectionOfProjectRequestGroup>;
  myPendingRequests?: Maybe<Array<Maybe<MyPendingRequests>>>;
  single?: Maybe<ProjectRequest>;
  singleGroup?: Maybe<ProjectRequestGroup>;
};


export type ProjectRequestQueryModelGroupSearchArgs = {
  approver?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['LocalDate']>;
  locationState?: InputMaybe<Scalars['String']>;
  officeCode?: InputMaybe<Scalars['String']>;
  projectManager?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<ProjectRequestsFilterState>;
  token?: InputMaybe<Scalars['String']>;
};


export type ProjectRequestQueryModelSingleArgs = {
  requestId: Scalars['String'];
};


export type ProjectRequestQueryModelSingleGroupArgs = {
  groupId: Scalars['String'];
};

export enum ProjectRequestState {
  Approved = 'Approved',
  Pending = 'Pending',
  PendingCustomerApproved = 'PendingCustomerApproved',
  Rejected = 'Rejected'
}

export enum ProjectRequestsFilterState {
  Any = 'Any',
  Approved = 'Approved',
  Pending = 'Pending',
  PendingCustomerApproved = 'PendingCustomerApproved',
  Rejected = 'Rejected'
}

export enum ProjectSearchKind {
  All = 'All',
  Mine = 'Mine',
  NeedsReview = 'NeedsReview'
}

export type ProjectSearchStats = {
  __typename?: 'ProjectSearchStats';
  projectCount: Scalars['Int'];
};

export enum ProjectSearchStatus {
  Active = 'Active',
  All = 'All',
  Closed = 'Closed'
}

export enum ProjectSortBy {
  /** Customer Name */
  CustomerName = 'CustomerName',
  /** Project ID */
  ProjectId = 'ProjectId',
  /** Project Name */
  ProjectName = 'ProjectName'
}

export type ProjectStats = {
  __typename?: 'ProjectStats';
  chargesAwaitingApproval: Scalars['Int'];
  chargesBilled: Scalars['Int'];
  chargesPendingBilling: Scalars['Int'];
  chargesUnbilled: Scalars['Int'];
  chargesUnreviewed: Scalars['Int'];
  lastChargeDate?: Maybe<Scalars['LocalDate']>;
  lastInvoiceDate?: Maybe<Scalars['LocalDate']>;
  totalBaPending: Scalars['Decimal'];
  totalBilled: Scalars['Decimal'];
  totalBilledWithoutTax: Scalars['Decimal'];
  totalChargesUnapproved: Scalars['Int'];
  totalNonBillable: Scalars['Decimal'];
  totalPendingApproval: Scalars['Decimal'];
  totalPendingBilling: Scalars['Decimal'];
  totalPendingHours: Scalars['Decimal'];
  totalPmPending: Scalars['Decimal'];
  totalPostPending: Scalars['Decimal'];
  totalPostedNotBilled: Scalars['Decimal'];
  totalPrPending: Scalars['Decimal'];
  totalPsPending: Scalars['Decimal'];
  totalUnapproved: Scalars['Decimal'];
  totalUnbilled: Scalars['Decimal'];
  totalUnbilledHours: Scalars['Decimal'];
};

export enum ProjectStatus {
  Active = 'Active',
  Closed = 'Closed'
}

export type ProjectStatusItem = {
  __typename?: 'ProjectStatusItem';
  billingAdmin?: Maybe<Employee>;
  projectManager?: Maybe<Employee>;
  projectNumber: Scalars['Int'];
  unbilledCharges: Scalars['Int'];
};

export type ProjectTimeDaily = {
  __typename?: 'ProjectTimeDaily';
  description: Scalars['String'];
  hours?: Maybe<Array<Maybe<ProjectTimeDailyHours>>>;
  notes: Scalars['String'];
  pdfDownloadUrl: Scalars['String'];
};

export type ProjectTimeDailyHours = {
  __typename?: 'ProjectTimeDailyHours';
  asBuilt: Scalars['Duration'];
  boundary: Scalars['Duration'];
  clStake: Scalars['Duration'];
  constRestake: Scalars['Duration'];
  constStake: Scalars['Duration'];
  employee?: Maybe<Employee>;
  employeeId: Scalars['Int'];
  locate: Scalars['Duration'];
  rainoutOther: Scalars['Duration'];
  rerouteStake: Scalars['Duration'];
  role: ProjectTimeDailyRole;
  travelHours: Scalars['Duration'];
};

export enum ProjectTimeDailyRole {
  FieldSupervisor = 'FieldSupervisor',
  InstrumentTech = 'InstrumentTech',
  LeadPartyChief = 'LeadPartyChief',
  PartyChief = 'PartyChief',
  RodMan = 'RodMan'
}

export type ProjectTimeEntry = {
  __typename?: 'ProjectTimeEntry';
  certifiedPayroll: Scalars['Boolean'];
  crewCode: Scalars['String'];
  crewSize: Scalars['Int'];
  daily?: Maybe<ProjectTimeDaily>;
  dailyHours?: Maybe<Array<Maybe<ProjectTimeDailyHours>>>;
  date: Scalars['LocalDate'];
  employee?: Maybe<Employee>;
  glAccount: Scalars['String'];
  hours: Scalars['Duration'];
  id: Scalars['String'];
  lastAdminNotes?: Maybe<Scalars['String']>;
  location?: Maybe<StateCounty>;
  myRole?: Maybe<CrewRole>;
  notes: Scalars['String'];
  otherCrew1?: Maybe<Employee>;
  otherCrew2?: Maybe<Employee>;
  otherRole1?: Maybe<CrewRole>;
  otherRole2?: Maybe<CrewRole>;
  pdfDownloadUrl: Scalars['String'];
  project?: Maybe<Project>;
  rate: Scalars['Decimal'];
  rateSheetName: Scalars['String'];
  taskName: Scalars['String'];
  taskType: TaskRateType;
  taskUnit: TaskRateUnit;
};

export type ProjectWithDailies = {
  __typename?: 'ProjectWithDailies';
  name: Scalars['String'];
  number: Scalars['Int'];
};

export type ProjectsMutationModel = {
  __typename?: 'ProjectsMutationModel';
  createProject?: Maybe<CreateProjectResponse>;
  editActualEndDate?: Maybe<ValidationResponse>;
  editActualStartDate?: Maybe<ValidationResponse>;
  editAfe?: Maybe<ValidationResponse>;
  editAllowThirdPartyInvoice?: Maybe<ValidationResponse>;
  editBillingAdmin?: Maybe<ValidationResponse>;
  editBillingFrequency?: Maybe<ValidationResponse>;
  editBlock?: Maybe<ValidationResponse>;
  editBudget?: Maybe<ValidationResponse>;
  editCertifiedPayroll?: Maybe<ValidationResponse>;
  editClientContactName?: Maybe<ValidationResponse>;
  editContractNumber?: Maybe<ValidationResponse>;
  editCorpLocation?: Maybe<ValidationResponse>;
  editCostCenter?: Maybe<ValidationResponse>;
  editCustomer?: Maybe<ValidationResponse>;
  editDateIn?: Maybe<ValidationResponse>;
  editDescription?: Maybe<ValidationResponse>;
  editDivision?: Maybe<ValidationResponse>;
  editEstimatedEndDate?: Maybe<ValidationResponse>;
  editEstimatedStartDate?: Maybe<ValidationResponse>;
  editGeographicLocation?: Maybe<ValidationResponse>;
  editGlCode?: Maybe<ValidationResponse>;
  editGovernmentFunding?: Maybe<ValidationResponse>;
  editInternalNotes?: Maybe<ValidationResponse>;
  editInvoiceNotes?: Maybe<ValidationResponse>;
  editLocation?: Maybe<ValidationResponse>;
  editNetworkPath?: Maybe<ValidationResponse>;
  editOfficeCode?: Maybe<ValidationResponse>;
  editPo?: Maybe<ValidationResponse>;
  editProjectGroup?: Maybe<ValidationResponse>;
  editProjectManager?: Maybe<ValidationResponse>;
  editProjectName?: Maybe<ValidationResponse>;
  editProjectOrigination?: Maybe<ValidationResponse>;
  editProjectSupervisor?: Maybe<ValidationResponse>;
  editRange?: Maybe<ValidationResponse>;
  editRequisitioner?: Maybe<ValidationResponse>;
  editScope?: Maybe<ValidationResponse>;
  editSection?: Maybe<ValidationResponse>;
  editServiceDescription?: Maybe<ValidationResponse>;
  editStatus?: Maybe<ValidationResponse>;
  editSurvey?: Maybe<ValidationResponse>;
  editTownship?: Maybe<ValidationResponse>;
  editWo?: Maybe<ValidationResponse>;
};


export type ProjectsMutationModelCreateProjectArgs = {
  actualEndDate?: InputMaybe<Scalars['LocalDate']>;
  actualStartDate?: InputMaybe<Scalars['LocalDate']>;
  afe?: InputMaybe<Scalars['String']>;
  allowThirdPartyInvoice: Scalars['Boolean'];
  billingAdmin: Scalars['String'];
  billingFrequency: BillingFrequencyType;
  block?: InputMaybe<Scalars['String']>;
  budget?: InputMaybe<Scalars['Decimal']>;
  certifiedPayroll: Scalars['Boolean'];
  clientContactName: Scalars['String'];
  contractNumber?: InputMaybe<Scalars['String']>;
  corpLocation: Scalars['String'];
  costCenter?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  customerNumber: Scalars['String'];
  dateIn?: InputMaybe<Scalars['LocalDate']>;
  description: Scalars['String'];
  division: Scalars['String'];
  estimatedEndDate?: InputMaybe<Scalars['LocalDate']>;
  estimatedStartDate?: InputMaybe<Scalars['LocalDate']>;
  geographicLocation: Scalars['String'];
  glCode?: InputMaybe<Scalars['String']>;
  governmentFunding: GovernmentFundingType;
  internalNotes: Scalars['String'];
  invoiceNotes: Scalars['String'];
  name: Scalars['String'];
  networkPath?: InputMaybe<Scalars['String']>;
  officeCode: Scalars['String'];
  po?: InputMaybe<Scalars['String']>;
  projectGroupName?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectManager: Scalars['String'];
  projectOrigination: ProjectOrigination;
  projectOriginationName?: InputMaybe<Scalars['String']>;
  projectSupervisor: Scalars['String'];
  range?: InputMaybe<Scalars['String']>;
  requisitioner?: InputMaybe<Scalars['String']>;
  scope: Scalars['String'];
  section?: InputMaybe<Scalars['String']>;
  serviceDescription: Scalars['String'];
  state: Scalars['String'];
  survey?: InputMaybe<Scalars['String']>;
  township?: InputMaybe<Scalars['String']>;
  wo?: InputMaybe<Scalars['String']>;
};


export type ProjectsMutationModelEditActualEndDateArgs = {
  actualEndDate?: InputMaybe<Scalars['LocalDate']>;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditActualStartDateArgs = {
  actualStartDate?: InputMaybe<Scalars['LocalDate']>;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditAfeArgs = {
  afe?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditAllowThirdPartyInvoiceArgs = {
  allowThirdPartyInvoice: Scalars['Boolean'];
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditBillingAdminArgs = {
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
  userPrincipalName: Scalars['String'];
};


export type ProjectsMutationModelEditBillingFrequencyArgs = {
  billingFrequency: BillingFrequencyType;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditBlockArgs = {
  block?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditBudgetArgs = {
  budget?: InputMaybe<Scalars['Decimal']>;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditCertifiedPayrollArgs = {
  certifiedPayroll: Scalars['Boolean'];
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditClientContactNameArgs = {
  clientContactName: Scalars['String'];
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
};


export type ProjectsMutationModelEditContractNumberArgs = {
  contractNumber?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditCorpLocationArgs = {
  corpLocationId: Scalars['String'];
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
};


export type ProjectsMutationModelEditCostCenterArgs = {
  costCenter?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditCustomerArgs = {
  customerNumber: Scalars['String'];
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditDateInArgs = {
  dateIn: Scalars['LocalDate'];
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditDescriptionArgs = {
  description: Scalars['String'];
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditDivisionArgs = {
  divisionId: Scalars['String'];
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
};


export type ProjectsMutationModelEditEstimatedEndDateArgs = {
  estimatedEndDate?: InputMaybe<Scalars['LocalDate']>;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditEstimatedStartDateArgs = {
  estimatedStartDate?: InputMaybe<Scalars['LocalDate']>;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditGeographicLocationArgs = {
  geographicLocation: Scalars['String'];
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditGlCodeArgs = {
  glCode?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditGovernmentFundingArgs = {
  governmentFunding: GovernmentFundingType;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditInternalNotesArgs = {
  internalNotes: Scalars['String'];
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditInvoiceNotesArgs = {
  invoiceNotes: Scalars['String'];
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditLocationArgs = {
  county: Scalars['String'];
  projectNumber: Scalars['Int'];
  state: Scalars['String'];
};


export type ProjectsMutationModelEditNetworkPathArgs = {
  networkPath?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditOfficeCodeArgs = {
  officeCode: Scalars['String'];
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
};


export type ProjectsMutationModelEditPoArgs = {
  po?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditProjectGroupArgs = {
  projectGroupName?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditProjectManagerArgs = {
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
  userPrincipalName: Scalars['String'];
};


export type ProjectsMutationModelEditProjectNameArgs = {
  name: Scalars['String'];
  projectNumber: Scalars['Int'];
};


export type ProjectsMutationModelEditProjectOriginationArgs = {
  name?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
  projectOrigination: ProjectOrigination;
};


export type ProjectsMutationModelEditProjectSupervisorArgs = {
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
  userPrincipalName: Scalars['String'];
};


export type ProjectsMutationModelEditRangeArgs = {
  projectNumber: Scalars['Int'];
  range?: InputMaybe<Scalars['String']>;
};


export type ProjectsMutationModelEditRequisitionerArgs = {
  projectNumber: Scalars['Int'];
  requisitioner?: InputMaybe<Scalars['String']>;
};


export type ProjectsMutationModelEditScopeArgs = {
  projectNumber: Scalars['Int'];
  scope?: InputMaybe<Scalars['String']>;
};


export type ProjectsMutationModelEditSectionArgs = {
  projectNumber: Scalars['Int'];
  section?: InputMaybe<Scalars['String']>;
};


export type ProjectsMutationModelEditServiceDescriptionArgs = {
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
  serviceDescription: Scalars['String'];
};


export type ProjectsMutationModelEditStatusArgs = {
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
  status: ProjectStatus;
};


export type ProjectsMutationModelEditSurveyArgs = {
  projectNumber: Scalars['Int'];
  survey?: InputMaybe<Scalars['String']>;
};


export type ProjectsMutationModelEditTownshipArgs = {
  projectNumber: Scalars['Int'];
  township?: InputMaybe<Scalars['String']>;
};


export type ProjectsMutationModelEditWoArgs = {
  projectNumber: Scalars['Int'];
  wo?: InputMaybe<Scalars['String']>;
};

export type ProjectsQueryModel = {
  __typename?: 'ProjectsQueryModel';
  activeProjects?: Maybe<Array<Maybe<Project>>>;
  allFilteredProjects?: Maybe<Array<Maybe<Project>>>;
  bulkEffectiveRateSheets?: Maybe<Array<Maybe<EffectiveRateSheetsAssignment>>>;
  effectiveExpenseRateSheet?: Maybe<ExpenseRateSheet>;
  effectiveTaskRateSheet?: Maybe<TaskRateSheet>;
  effectiveTaskRateSheets?: Maybe<Array<Maybe<EffectiveTaskRateSheetAssignment>>>;
  project?: Maybe<Project>;
  projectStatus?: Maybe<Array<Maybe<ProjectStatusItem>>>;
  projectTypes?: Maybe<Array<Scalars['String']>>;
  projects?: Maybe<Array<Maybe<Project>>>;
  search?: Maybe<ConnectionOfProject>;
  searchNumbers?: Maybe<Array<Scalars['Int']>>;
  stats?: Maybe<ProjectSearchStats>;
};


export type ProjectsQueryModelActiveProjectsArgs = {
  asOf: Scalars['LocalDate'];
};


export type ProjectsQueryModelAllFilteredProjectsArgs = {
  afeWoPo?: InputMaybe<Scalars['String']>;
  asOf?: InputMaybe<Scalars['LocalDate']>;
  billingAdmin?: InputMaybe<Scalars['String']>;
  certifiedPayroll?: InputMaybe<Scalars['Boolean']>;
  clientContactName?: InputMaybe<Scalars['String']>;
  corpLocation?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  dateInAfter?: InputMaybe<Scalars['LocalDate']>;
  dateInBefore?: InputMaybe<Scalars['LocalDate']>;
  dateInEqual?: InputMaybe<Scalars['LocalDate']>;
  division?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<ProjectSearchKind>;
  officeCode?: InputMaybe<Scalars['String']>;
  projectGroupArgs?: InputMaybe<ProjectGroupArgsInput>;
  projectManager?: InputMaybe<Scalars['String']>;
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
  projectSupervisor?: InputMaybe<Scalars['String']>;
  projectType?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  serviceDescription?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ProjectSortBy>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProjectSearchStatus>;
  successorArgs?: InputMaybe<RateSheetSuccessorArgsInput>;
};


export type ProjectsQueryModelBulkEffectiveRateSheetsArgs = {
  dates?: InputMaybe<Array<Scalars['LocalDate']>>;
  projectNumber: Scalars['Int'];
};


export type ProjectsQueryModelEffectiveExpenseRateSheetArgs = {
  asOf: Scalars['LocalDate'];
  projectNumber: Scalars['Int'];
};


export type ProjectsQueryModelEffectiveTaskRateSheetArgs = {
  asOf: Scalars['LocalDate'];
  projectNumber: Scalars['Int'];
};


export type ProjectsQueryModelEffectiveTaskRateSheetsArgs = {
  asOf: Scalars['LocalDate'];
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
};


export type ProjectsQueryModelProjectArgs = {
  projectNumber: Scalars['Int'];
};


export type ProjectsQueryModelProjectStatusArgs = {
  billingAdmin?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['LocalDate']>;
  projectManager?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};


export type ProjectsQueryModelProjectsArgs = {
  customerNumber: Scalars['String'];
};


export type ProjectsQueryModelSearchArgs = {
  afeWoPo?: InputMaybe<Scalars['String']>;
  asOf?: InputMaybe<Scalars['LocalDate']>;
  billingAdmin?: InputMaybe<Scalars['String']>;
  certifiedPayroll?: InputMaybe<Scalars['Boolean']>;
  clientContactName?: InputMaybe<Scalars['String']>;
  corpLocation?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  dateInAfter?: InputMaybe<Scalars['LocalDate']>;
  dateInBefore?: InputMaybe<Scalars['LocalDate']>;
  dateInEqual?: InputMaybe<Scalars['LocalDate']>;
  division?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<ProjectSearchKind>;
  officeCode?: InputMaybe<Scalars['String']>;
  projectGroupArgs?: InputMaybe<ProjectGroupArgsInput>;
  projectManager?: InputMaybe<Scalars['String']>;
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
  projectSupervisor?: InputMaybe<Scalars['String']>;
  projectType?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  serviceDescription?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ProjectSortBy>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProjectSearchStatus>;
  successorArgs?: InputMaybe<RateSheetSuccessorArgsInput>;
  token?: InputMaybe<Scalars['String']>;
};


export type ProjectsQueryModelSearchNumbersArgs = {
  afeWoPo?: InputMaybe<Scalars['String']>;
  asOf?: InputMaybe<Scalars['LocalDate']>;
  billingAdmin?: InputMaybe<Scalars['String']>;
  certifiedPayroll?: InputMaybe<Scalars['Boolean']>;
  clientContactName?: InputMaybe<Scalars['String']>;
  corpLocation?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  dateInAfter?: InputMaybe<Scalars['LocalDate']>;
  dateInBefore?: InputMaybe<Scalars['LocalDate']>;
  dateInEqual?: InputMaybe<Scalars['LocalDate']>;
  division?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<ProjectSearchKind>;
  officeCode?: InputMaybe<Scalars['String']>;
  projectGroupArgs?: InputMaybe<ProjectGroupArgsInput>;
  projectManager?: InputMaybe<Scalars['String']>;
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
  projectSupervisor?: InputMaybe<Scalars['String']>;
  projectType?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  serviceDescription?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ProjectSortBy>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProjectSearchStatus>;
  successorArgs?: InputMaybe<RateSheetSuccessorArgsInput>;
};


export type ProjectsQueryModelStatsArgs = {
  afeWoPo?: InputMaybe<Scalars['String']>;
  asOf?: InputMaybe<Scalars['LocalDate']>;
  billingAdmin?: InputMaybe<Scalars['String']>;
  certifiedPayroll?: InputMaybe<Scalars['Boolean']>;
  clientContactName?: InputMaybe<Scalars['String']>;
  corpLocation?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  dateInAfter?: InputMaybe<Scalars['LocalDate']>;
  dateInBefore?: InputMaybe<Scalars['LocalDate']>;
  dateInEqual?: InputMaybe<Scalars['LocalDate']>;
  division?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<ProjectSearchKind>;
  officeCode?: InputMaybe<Scalars['String']>;
  projectGroupArgs?: InputMaybe<ProjectGroupArgsInput>;
  projectManager?: InputMaybe<Scalars['String']>;
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
  projectSupervisor?: InputMaybe<Scalars['String']>;
  projectType?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  serviceDescription?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ProjectSortBy>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProjectSearchStatus>;
  successorArgs?: InputMaybe<RateSheetSuccessorArgsInput>;
  token?: InputMaybe<Scalars['String']>;
};

export type QueryModel = {
  __typename?: 'QueryModel';
  allCrewCodes?: Maybe<Array<Maybe<CrewCodeRecord>>>;
  crewCodes?: Maybe<Array<Maybe<CrewCodeRecord>>>;
  customRollupInvoices?: Maybe<CustomRollupInvoiceQueryModel>;
  customers?: Maybe<CustomersQueryModel>;
  divisions?: Maybe<DivisionQueryModel>;
  employeeRates?: Maybe<EmployeeRatesQueryModel>;
  employees?: Maybe<EmployeesQueryModel>;
  expenseRates?: Maybe<ExpenseRatesQueryModel>;
  fieldOffices?: Maybe<FieldOfficesQueryModel>;
  images?: Maybe<UploadedImagesQueryModel>;
  invoices?: Maybe<InvoicesQueryModel>;
  notifications?: Maybe<AppNotificationQueryModel>;
  overheadCrewCodes?: Maybe<OverheadCrewCodeQueryModel>;
  perDiemRates?: Maybe<PerDiemRatesQueryModel>;
  projectCharges?: Maybe<ProjectChargesQueryModel>;
  projectGroups?: Maybe<ProjectGroupQueryModel>;
  projectRequests?: Maybe<ProjectRequestQueryModel>;
  projects?: Maybe<ProjectsQueryModel>;
  stateCounties?: Maybe<Array<Maybe<StateCounty>>>;
  taskRates?: Maybe<TaskRatesQueryModel>;
  thirdPartyInvoices?: Maybe<ThirdPartyInvoicesQueryModel>;
  timesheets?: Maybe<TimesheetsQueryModel>;
};


export type QueryModelCrewCodesArgs = {
  employeeId: Scalars['String'];
};

export type RateSheetSuccessorArgsInput = {
  effectiveDate: Scalars['LocalDate'];
  expenseRateSheet?: InputMaybe<Scalars['String']>;
  taskRateSheet?: InputMaybe<Scalars['String']>;
};

export enum RateUnit {
  Day = 'Day',
  Each = 'Each',
  Foot = 'Foot',
  Gallon = 'Gallon',
  Hourly = 'Hourly',
  Mile = 'Mile'
}

export enum ReceiptValuedCategory {
  Automotive = 'Automotive',
  Lodging = 'Lodging',
  Meals = 'Meals',
  None = 'None'
}

export type ReceiptValuedExpense = IExpense & {
  __typename?: 'ReceiptValuedExpense';
  billableAmount: Scalars['Decimal'];
  category: ReceiptValuedCategory;
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  expenseAccount?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<ExpenseHistoryItem>>>;
  id: Scalars['String'];
  importEdited: Scalars['Boolean'];
  invoiceLabel?: Maybe<Scalars['String']>;
  lastAdminNotes?: Maybe<Scalars['String']>;
  location?: Maybe<StateCounty>;
  notes?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['Decimal']>;
  payable: Payable;
  payableAmount: Scalars['Decimal'];
  payableOverridden: Scalars['Boolean'];
  payerInformation: PayerInformation;
  project?: Maybe<Project>;
  rateName: Scalars['String'];
  rateSheetName: Scalars['String'];
  receiptImageId?: Maybe<Scalars['String']>;
  receiptImageUrl?: Maybe<Scalars['String']>;
  receiptTotal: Scalars['Decimal'];
  vehicleNumber?: Maybe<Scalars['String']>;
};

export type ReceiptValuedExpenseRate = IExpenseRate & {
  __typename?: 'ReceiptValuedExpenseRate';
  billable: Scalars['Boolean'];
  category: ReceiptValuedCategory;
  expenseAccount?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  invoiceLabel?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  requiresLocation: Scalars['Boolean'];
  unit?: Maybe<RateUnit>;
};

export type RevenueReportFilteringArgsInput = {
  after?: InputMaybe<Scalars['LocalDate']>;
  before?: InputMaybe<Scalars['LocalDate']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  officeCode?: InputMaybe<Scalars['String']>;
  projectManagerEmail?: InputMaybe<Scalars['String']>;
  projectNumber?: InputMaybe<Scalars['String']>;
  projectType?: InputMaybe<Scalars['String']>;
  serviceDescriptionId?: InputMaybe<Scalars['String']>;
};

export type RevenueReportGroupingArgsInput = {
  customer?: InputMaybe<Scalars['Boolean']>;
  industry?: InputMaybe<Scalars['Boolean']>;
  officeCode?: InputMaybe<Scalars['Boolean']>;
  project?: InputMaybe<Scalars['Boolean']>;
  projectManager?: InputMaybe<Scalars['Boolean']>;
  projectType?: InputMaybe<Scalars['Boolean']>;
  serviceDescription?: InputMaybe<Scalars['Boolean']>;
};

export type RevenueReportRow = {
  __typename?: 'RevenueReportRow';
  customer?: Maybe<Customer>;
  industry?: Maybe<Scalars['String']>;
  officeCode?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  projectManager?: Maybe<Employee>;
  projectType?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<ServiceDescription>;
  total: Scalars['Decimal'];
};

export type RoleAssignment = {
  __typename?: 'RoleAssignment';
  employees?: Maybe<Array<Maybe<Employee>>>;
  roleName: Scalars['String'];
};

export type ServiceDescription = {
  __typename?: 'ServiceDescription';
  description: Scalars['String'];
  displayName: Scalars['String'];
  industry: Scalars['String'];
  isActive: Scalars['Boolean'];
  isOverhead: Scalars['Boolean'];
  legacyWorkCode: Scalars['String'];
  projectType: Scalars['String'];
};

export type SplitChargeArgsInput = {
  label: Scalars['String'];
  notes: Scalars['String'];
  projectNumber: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  rateSheetName: Scalars['String'];
  taxable: Scalars['Boolean'];
};

export type SplitChargeResponse = {
  __typename?: 'SplitChargeResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  splitChargeIds?: Maybe<Array<Scalars['Int']>>;
};

export type StateCounty = {
  __typename?: 'StateCounty';
  county: Scalars['String'];
  state: Scalars['String'];
};

export type StateCountyInput = {
  county: Scalars['String'];
  state: Scalars['String'];
};

export type StateOverheadCrewCode = {
  __typename?: 'StateOverheadCrewCode';
  crewCode: Scalars['String'];
  state: Scalars['String'];
};

export enum SubjectType {
  BaAssigned = 'BaAssigned',
  CustomerRequestRejected = 'CustomerRequestRejected',
  NewCustomer = 'NewCustomer',
  ProjectRequestCreated = 'ProjectRequestCreated',
  ProjectRequestCreatedWithBadDebt = 'ProjectRequestCreatedWithBadDebt',
  ThirdPartyInvoiceCreated = 'ThirdPartyInvoiceCreated'
}

export type TaskRate = {
  __typename?: 'TaskRate';
  billableAmount?: Maybe<Scalars['Decimal']>;
  certifiedPayroll: Scalars['Boolean'];
  crewSize: Scalars['Int'];
  glAccount?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rateType: TaskRateType;
  requiresDaily: Scalars['Boolean'];
  unit: TaskRateUnit;
  usesEmployeeRate: Scalars['Boolean'];
};

export type TaskRateSheet = {
  __typename?: 'TaskRateSheet';
  allowAssignment: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  endDate?: Maybe<Scalars['LocalDate']>;
  history?: Maybe<Array<Maybe<TaskRateSheetHistoryItem>>>;
  lastUsed?: Maybe<Scalars['LocalDate']>;
  name: Scalars['String'];
  rates?: Maybe<Array<Maybe<TaskRate>>>;
};

export type TaskRateSheetAssignment = {
  __typename?: 'TaskRateSheetAssignment';
  effectiveDate: Scalars['LocalDate'];
  rateSheet?: Maybe<TaskRateSheet>;
  rateSheetId: Scalars['Int'];
};

export type TaskRateSheetHistoryItem = {
  __typename?: 'TaskRateSheetHistoryItem';
  changedBy: Scalars['String'];
  changedOn: Scalars['Instant'];
  details?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

export enum TaskRateType {
  Field = 'Field',
  Office = 'Office'
}

export enum TaskRateUnit {
  Day = 'Day',
  Each = 'Each',
  Foot = 'Foot',
  Hourly = 'Hourly'
}

export type TaskRatesMutationModel = {
  __typename?: 'TaskRatesMutationModel';
  addRate?: Maybe<ValidationResponse>;
  archiveRateSheet?: Maybe<ValidationResponse>;
  assignRateSheetToProject?: Maybe<ValidationResponse>;
  cloneTaskRateSheet?: Maybe<ValidationResponse>;
  createTaskRateSheet?: Maybe<ValidationResponse>;
  deleteRate?: Maybe<ValidationResponse>;
  editRate?: Maybe<ValidationResponse>;
  editTaskRateSheet?: Maybe<ValidationResponse>;
  removeRateSheetFromProject?: Maybe<ValidationResponse>;
};


export type TaskRatesMutationModelAddRateArgs = {
  billableAmount?: InputMaybe<Scalars['Decimal']>;
  certifiedPayroll: Scalars['Boolean'];
  crewSize: Scalars['Int'];
  glAccount?: InputMaybe<Scalars['String']>;
  rateName: Scalars['String'];
  rateSheetName: Scalars['String'];
  rateType: TaskRateType;
  requiresDaily: Scalars['Boolean'];
  unit: TaskRateUnit;
  usesEmployeeRate: Scalars['Boolean'];
};


export type TaskRatesMutationModelArchiveRateSheetArgs = {
  rateSheetName: Scalars['String'];
};


export type TaskRatesMutationModelAssignRateSheetToProjectArgs = {
  effectiveDate: Scalars['LocalDate'];
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
  rateSheetName: Scalars['String'];
};


export type TaskRatesMutationModelCloneTaskRateSheetArgs = {
  newName: Scalars['String'];
  oldName: Scalars['String'];
};


export type TaskRatesMutationModelCreateTaskRateSheetArgs = {
  name: Scalars['String'];
};


export type TaskRatesMutationModelDeleteRateArgs = {
  rateName: Scalars['String'];
  rateSheetName: Scalars['String'];
};


export type TaskRatesMutationModelEditRateArgs = {
  billableAmount?: InputMaybe<Scalars['Decimal']>;
  certifiedPayroll: Scalars['Boolean'];
  crewSize: Scalars['Int'];
  glAccount?: InputMaybe<Scalars['String']>;
  newName: Scalars['String'];
  oldName: Scalars['String'];
  rateSheetName: Scalars['String'];
  rateType: TaskRateType;
  requiresDaily: Scalars['Boolean'];
  unit: TaskRateUnit;
  usesEmployeeRate: Scalars['Boolean'];
};


export type TaskRatesMutationModelEditTaskRateSheetArgs = {
  allowAssignment: Scalars['Boolean'];
  endDate?: InputMaybe<Scalars['LocalDate']>;
  name: Scalars['String'];
};


export type TaskRatesMutationModelRemoveRateSheetFromProjectArgs = {
  effectiveDate: Scalars['LocalDate'];
  projectNumbers?: InputMaybe<Array<Scalars['Int']>>;
  rateSheetName: Scalars['String'];
};

export type TaskRatesQueryModel = {
  __typename?: 'TaskRatesQueryModel';
  sheet?: Maybe<TaskRateSheet>;
  sheets?: Maybe<Array<Maybe<TaskRateSheet>>>;
};


export type TaskRatesQueryModelSheetArgs = {
  name: Scalars['String'];
};


export type TaskRatesQueryModelSheetsArgs = {
  allowAssignment?: InputMaybe<Scalars['Boolean']>;
  effectiveDate?: InputMaybe<Scalars['LocalDate']>;
};

export type TcpAllowanceExpense = IExpense & {
  __typename?: 'TcpAllowanceExpense';
  billableAmount: Scalars['Decimal'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  expenseAccount?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<ExpenseHistoryItem>>>;
  id: Scalars['String'];
  importEdited: Scalars['Boolean'];
  invoiceLabel?: Maybe<Scalars['String']>;
  lastAdminNotes?: Maybe<Scalars['String']>;
  location?: Maybe<StateCounty>;
  notes?: Maybe<Scalars['String']>;
  payable: Payable;
  payableAmount: Scalars['Decimal'];
  payableOverridden: Scalars['Boolean'];
  project?: Maybe<Project>;
  rateName: Scalars['String'];
  rateSheetName: Scalars['String'];
};

export type TcpAllowanceExpenseRate = IExpenseRate & {
  __typename?: 'TcpAllowanceExpenseRate';
  expenseAccount?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['String']>;
  invoiceLabel?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reimbursableAmount: Scalars['Decimal'];
  unit?: Maybe<RateUnit>;
};

export type ThirdPartyInvoice = {
  __typename?: 'ThirdPartyInvoice';
  amount: Scalars['Decimal'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  documentId: Scalars['String'];
  documentUrl: Scalars['String'];
  invoiceNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  projectId: Scalars['Int'];
  state: ThirdPartyInvoiceState;
};

export type ThirdPartyInvoiceConfig = {
  __typename?: 'ThirdPartyInvoiceConfig';
  glAccount: Scalars['String'];
};

export enum ThirdPartyInvoiceFilterState {
  Any = 'Any',
  Approved = 'Approved',
  Billed = 'Billed',
  Rejected = 'Rejected',
  Unreviewed = 'Unreviewed'
}

export enum ThirdPartyInvoiceSortBy {
  Date = 'Date',
  Project = 'Project'
}

export enum ThirdPartyInvoiceState {
  Approved = 'Approved',
  Billed = 'Billed',
  Rejected = 'Rejected',
  Unreviewed = 'Unreviewed'
}

export type ThirdPartyInvoiceUnreviewedCount = {
  __typename?: 'ThirdPartyInvoiceUnreviewedCount';
  count: Scalars['Int'];
};

export type ThirdPartyInvoicesMutationModel = {
  __typename?: 'ThirdPartyInvoicesMutationModel';
  approve?: Maybe<ValidationResponse>;
  create?: Maybe<CreateThirdPartyInvoiceResponse>;
  edit?: Maybe<ValidationResponse>;
  reject?: Maybe<ValidationResponse>;
  updateConfig?: Maybe<ValidationResponse>;
};


export type ThirdPartyInvoicesMutationModelApproveArgs = {
  invoiceNumber: Scalars['String'];
};


export type ThirdPartyInvoicesMutationModelCreateArgs = {
  amount: Scalars['Decimal'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  documentImageId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  projectId: Scalars['Int'];
};


export type ThirdPartyInvoicesMutationModelEditArgs = {
  amount: Scalars['Decimal'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  documentImageId: Scalars['String'];
  invoiceNumber: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  projectId: Scalars['Int'];
};


export type ThirdPartyInvoicesMutationModelRejectArgs = {
  invoiceNumber: Scalars['String'];
  reason: Scalars['String'];
};


export type ThirdPartyInvoicesMutationModelUpdateConfigArgs = {
  glAccount: Scalars['String'];
};

export type ThirdPartyInvoicesQueryModel = {
  __typename?: 'ThirdPartyInvoicesQueryModel';
  config?: Maybe<ThirdPartyInvoiceConfig>;
  search?: Maybe<ConnectionOfThirdPartyInvoice>;
  single?: Maybe<ThirdPartyInvoice>;
  unreviewedCount?: Maybe<ThirdPartyInvoiceUnreviewedCount>;
};


export type ThirdPartyInvoicesQueryModelSearchArgs = {
  customerNumber?: InputMaybe<Scalars['String']>;
  dateAfter?: InputMaybe<Scalars['String']>;
  dateBefore?: InputMaybe<Scalars['String']>;
  dateEqual?: InputMaybe<Scalars['String']>;
  projectNumber?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ThirdPartyInvoiceSortBy>;
  state?: InputMaybe<ThirdPartyInvoiceFilterState>;
  token?: InputMaybe<Scalars['String']>;
};


export type ThirdPartyInvoicesQueryModelSingleArgs = {
  invoiceNumber?: InputMaybe<Scalars['String']>;
  projectChargeId?: InputMaybe<Scalars['Int']>;
};

export type TimeEntry = {
  __typename?: 'TimeEntry';
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  hoursWorked: Scalars['Duration'];
  id: Scalars['String'];
  lastAdminNotes?: Maybe<Scalars['String']>;
  location?: Maybe<StateCounty>;
  lunchEnd?: Maybe<Scalars['LocalTime']>;
  lunchHours?: Maybe<Scalars['Duration']>;
  lunchStart?: Maybe<Scalars['LocalTime']>;
  timeIn: Scalars['LocalTime'];
  timeOut: Scalars['LocalTime'];
};

export type TimeOffEntry = {
  __typename?: 'TimeOffEntry';
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  hours: Scalars['Duration'];
  id: Scalars['String'];
  lastAdminNotes?: Maybe<Scalars['String']>;
  timeOffType: TimeOffType;
};

export enum TimeOffType {
  /** Bereavement */
  B = 'B',
  /** COVID FMLA */
  Cfmla = 'CFMLA',
  /** COVID Vaccine */
  Covac = 'COVAC',
  /** COVID Personal/Caregiver Leave */
  Cpcl = 'CPCL',
  /** Furlough - NP */
  F = 'F',
  /** FMLA - NP */
  Fmla = 'FMLA',
  /** Holiday - Float */
  H = 'H',
  /** Holiday */
  Holpd = 'HOLPD',
  /** Jury Duty */
  Jd = 'JD',
  /** No Job - NP */
  Nj = 'NJ',
  /** Personal - NP */
  P = 'P',
  /** Reg 1 PTO */
  R = 'R',
  /** Sick - NP */
  S = 'S',
  /** Seminar - CEU */
  Sceu = 'SCEU',
  /** Sick - P */
  Sp = 'SP',
  /** Seminar - Required */
  Sr = 'SR',
  /** Short Term Disability - NP */
  Stdnp = 'STDNP',
  /** Short Term Disability - P */
  Stdp = 'STDP',
  /** Seminar - Voluntary */
  Sv = 'SV',
  /** Vacation - NP */
  V = 'V',
  /** Weather - NP */
  W = 'W',
  /** Workers Comp - NP */
  Wc = 'WC'
}

export type Timesheet = {
  __typename?: 'Timesheet';
  canBeApproved: Scalars['Boolean'];
  canBeRejected: Scalars['Boolean'];
  canBeReset: Scalars['Boolean'];
  canBeSubmitted: Scalars['Boolean'];
  canUpdateFinalPayroll: Scalars['Boolean'];
  companyPaid: Scalars['Decimal'];
  daySummaries?: Maybe<Array<Maybe<WorkDaySummary>>>;
  employee?: Maybe<Employee>;
  expensesReimbursable: Scalars['Decimal'];
  payroll: Scalars['Decimal'];
  projectsWithDailies?: Maybe<Array<Maybe<ProjectWithDailies>>>;
  status: TimesheetStatus;
  timesheetId: Scalars['String'];
  totalExpensesPayable: Scalars['Decimal'];
  totalMileage: Scalars['Decimal'];
  weekBeginning: Scalars['LocalDate'];
};

export enum TimesheetSearchReimbursementOption {
  All = 'All',
  NoReimbursements = 'NoReimbursements',
  ReimbursementsOnly = 'ReimbursementsOnly'
}

export enum TimesheetStatus {
  Open = 'Open',
  PrApproved = 'PrApproved',
  PrPending = 'PrPending',
  Rejected = 'Rejected'
}

export type TimesheetsMutationModel = {
  __typename?: 'TimesheetsMutationModel';
  approveAllWorkWeeks?: Maybe<ValidationResponse>;
  approveWorkWeek?: Maybe<ValidationResponse>;
  copyExpenseToDate?: Maybe<CreateExpenseResponse>;
  copyExpenseToDates?: Maybe<ValidationResponse>;
  copyProjectTime?: Maybe<ValidationResponse>;
  copyProjectTimeToDate?: Maybe<CreateProjectTimeResponse>;
  copyProjectTimeToDates?: Maybe<ValidationResponse>;
  copyTimeEntryToDate?: Maybe<CreateTimeEntryResponse>;
  copyTimeEntryToDates?: Maybe<ValidationResponse>;
  copyTimeOffToDate?: Maybe<CreateTimeOffEntryResponse>;
  copyTimeOffToDates?: Maybe<ValidationResponse>;
  copyWorkDay?: Maybe<ValidationResponse>;
  createAdHocExpense?: Maybe<CreateExpenseResponse>;
  createAtvExpense?: Maybe<CreateExpenseResponse>;
  createFuelExpense?: Maybe<CreateExpenseResponse>;
  createMileageExpense?: Maybe<CreateExpenseResponse>;
  createMobileAllowanceExpense?: Maybe<CreateExpenseResponse>;
  createPerDiemExpense?: Maybe<CreateExpenseResponse>;
  createProjectTime?: Maybe<CreateProjectTimeResponse>;
  createReceiptValuedExpense?: Maybe<CreateExpenseResponse>;
  createTcpAllowanceExpense?: Maybe<CreateExpenseResponse>;
  createTimeEntry?: Maybe<CreateTimeEntryResponse>;
  createTimeOffEntry?: Maybe<CreateTimeOffEntryResponse>;
  deleteExpense?: Maybe<ValidationResponse>;
  deleteProjectTime?: Maybe<ValidationResponse>;
  deleteTimeEntry?: Maybe<ValidationResponse>;
  deleteTimeOffEntry?: Maybe<ValidationResponse>;
  editAdHocExpense?: Maybe<ValidationResponse>;
  editAtvExpense?: Maybe<ValidationResponse>;
  editExpensePayableAmount?: Maybe<ValidationResponse>;
  editFuelExpense?: Maybe<ValidationResponse>;
  editMileageExpense?: Maybe<ValidationResponse>;
  editMobileAllowanceExpense?: Maybe<ValidationResponse>;
  editPerDiemExpense?: Maybe<ValidationResponse>;
  editProjectTime?: Maybe<ValidationResponse>;
  editProjectTimeDaily?: Maybe<ValidationResponse>;
  editReceiptValuedExpense?: Maybe<ValidationResponse>;
  editTcpAllowanceExpense?: Maybe<ValidationResponse>;
  editTimeEntry?: Maybe<ValidationResponse>;
  editTimeOffEntry?: Maybe<ValidationResponse>;
  importFuelExpense?: Maybe<ValidationResponse>;
  importMileageExpense?: Maybe<ValidationResponse>;
  importReceiptValuedExpense?: Maybe<ValidationResponse>;
  moveExpenseToDate?: Maybe<CreateExpenseResponse>;
  moveProjectTimeToDate?: Maybe<CreateProjectTimeResponse>;
  moveTimeEntryToDate?: Maybe<CreateTimeEntryResponse>;
  moveTimeOffToDate?: Maybe<CreateTimeOffEntryResponse>;
  rejectWorkWeek?: Maybe<ValidationResponse>;
  resetWorkWeek?: Maybe<ValidationResponse>;
  submitWorkWeek?: Maybe<ValidationResponse>;
};


export type TimesheetsMutationModelApproveAllWorkWeeksArgs = {
  dateAfter?: InputMaybe<Scalars['String']>;
  dateBefore?: InputMaybe<Scalars['String']>;
  officeCode?: InputMaybe<Scalars['String']>;
  payPeriod?: InputMaybe<PayPeriod>;
  payrollAdmin?: InputMaybe<Scalars['String']>;
  reimbursement?: InputMaybe<TimesheetSearchReimbursementOption>;
  searchText?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TimesheetStatus>;
  userPrincipalName?: InputMaybe<Scalars['String']>;
  weekStart: Scalars['String'];
  workLocation?: InputMaybe<Scalars['String']>;
};


export type TimesheetsMutationModelApproveWorkWeekArgs = {
  userPrincipalName: Scalars['String'];
  weekBeginning: Scalars['LocalDate'];
};


export type TimesheetsMutationModelCopyExpenseToDateArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  expenseId: Scalars['String'];
  newDate: Scalars['LocalDate'];
};


export type TimesheetsMutationModelCopyExpenseToDatesArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  dates?: InputMaybe<Array<Scalars['LocalDate']>>;
  expenseId: Scalars['String'];
};


export type TimesheetsMutationModelCopyProjectTimeArgs = {
  id: Scalars['String'];
};


export type TimesheetsMutationModelCopyProjectTimeToDateArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  newDate: Scalars['LocalDate'];
  projectTimeId: Scalars['String'];
};


export type TimesheetsMutationModelCopyProjectTimeToDatesArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  dates?: InputMaybe<Array<Scalars['LocalDate']>>;
  projectTimeId: Scalars['String'];
};


export type TimesheetsMutationModelCopyTimeEntryToDateArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  newDate: Scalars['LocalDate'];
  timeEntryId: Scalars['String'];
};


export type TimesheetsMutationModelCopyTimeEntryToDatesArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  dates?: InputMaybe<Array<Scalars['LocalDate']>>;
  timeEntryId: Scalars['String'];
};


export type TimesheetsMutationModelCopyTimeOffToDateArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  newDate: Scalars['LocalDate'];
  timeOffEntryId: Scalars['String'];
};


export type TimesheetsMutationModelCopyTimeOffToDatesArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  dates?: InputMaybe<Array<Scalars['LocalDate']>>;
  timeOffEntryId: Scalars['String'];
};


export type TimesheetsMutationModelCopyWorkDayArgs = {
  id: Scalars['String'];
  idToCopy: Scalars['String'];
  includeExpenses: Scalars['Boolean'];
  includeProjectTime: Scalars['Boolean'];
  includeTimeEntries: Scalars['Boolean'];
  includeTimeOff: Scalars['Boolean'];
};


export type TimesheetsMutationModelCreateAdHocExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  notes?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rateAmount?: InputMaybe<Scalars['Decimal']>;
  rateName: Scalars['String'];
  receiptImageId?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  userPrincipalName?: InputMaybe<Scalars['String']>;
};


export type TimesheetsMutationModelCreateAtvExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  notes?: InputMaybe<Scalars['String']>;
  ownerInformation: OwnerInformation;
  projectNumber: Scalars['Int'];
  rateName: Scalars['String'];
  state: Scalars['String'];
  userPrincipalName?: InputMaybe<Scalars['String']>;
};


export type TimesheetsMutationModelCreateFuelExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  companyVehicle: Scalars['Boolean'];
  county: Scalars['String'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  notes?: InputMaybe<Scalars['String']>;
  odometer: Scalars['Decimal'];
  payerInformation: PayerInformation;
  projectNumber: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rateName: Scalars['String'];
  receiptImageId?: InputMaybe<Scalars['String']>;
  receiptTotal: Scalars['Decimal'];
  state: Scalars['String'];
  userPrincipalName?: InputMaybe<Scalars['String']>;
  vehicleNumber: Scalars['String'];
};


export type TimesheetsMutationModelCreateMileageExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  beginningOdometer: Scalars['Decimal'];
  companyVehicle: Scalars['Boolean'];
  county: Scalars['String'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  endingOdometer: Scalars['Decimal'];
  notes?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
  rateName: Scalars['String'];
  state: Scalars['String'];
  userPrincipalName?: InputMaybe<Scalars['String']>;
  vehicleNumber: Scalars['String'];
};


export type TimesheetsMutationModelCreateMobileAllowanceExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  notes?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
  rateName: Scalars['String'];
  state: Scalars['String'];
  userPrincipalName?: InputMaybe<Scalars['String']>;
};


export type TimesheetsMutationModelCreatePerDiemExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  notes?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
  rateName: Scalars['String'];
  receiptImageId?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  userPrincipalName?: InputMaybe<Scalars['String']>;
};


export type TimesheetsMutationModelCreateProjectTimeArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  dailyHours?: InputMaybe<Array<CreateProjectTimeCommandDailyHoursInput>>;
  date: Scalars['LocalDate'];
  hours: Scalars['Duration'];
  myRole?: InputMaybe<CrewRole>;
  notes: Scalars['String'];
  otherCrewUpn1?: InputMaybe<Scalars['String']>;
  otherCrewUpn2?: InputMaybe<Scalars['String']>;
  otherRole1?: InputMaybe<CrewRole>;
  otherRole2?: InputMaybe<CrewRole>;
  projectNumber: Scalars['Int'];
  state: Scalars['String'];
  taskName: Scalars['String'];
  userPrincipalName?: InputMaybe<Scalars['String']>;
};


export type TimesheetsMutationModelCreateReceiptValuedExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  notes?: InputMaybe<Scalars['String']>;
  odometer?: InputMaybe<Scalars['Decimal']>;
  payerInformation: PayerInformation;
  projectNumber: Scalars['Int'];
  rateName: Scalars['String'];
  receiptImageId: Scalars['String'];
  receiptTotal: Scalars['Decimal'];
  state: Scalars['String'];
  userPrincipalName?: InputMaybe<Scalars['String']>;
  vehicleNumber?: InputMaybe<Scalars['String']>;
};


export type TimesheetsMutationModelCreateTcpAllowanceExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  notes?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
  rateName: Scalars['String'];
  state: Scalars['String'];
  userPrincipalName?: InputMaybe<Scalars['String']>;
};


export type TimesheetsMutationModelCreateTimeEntryArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  hoursWorked: Scalars['Duration'];
  lunchEnd?: InputMaybe<Scalars['LocalTime']>;
  lunchHours?: InputMaybe<Scalars['Duration']>;
  lunchStart?: InputMaybe<Scalars['LocalTime']>;
  state: Scalars['String'];
  timeIn: Scalars['LocalTime'];
  timeOut: Scalars['LocalTime'];
  userPrincipalName?: InputMaybe<Scalars['String']>;
  workDate: Scalars['LocalDate'];
};


export type TimesheetsMutationModelCreateTimeOffEntryArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  hours: Scalars['Duration'];
  timeOffType: TimeOffType;
  userPrincipalName?: InputMaybe<Scalars['String']>;
};


export type TimesheetsMutationModelDeleteExpenseArgs = {
  expenseId: Scalars['String'];
};


export type TimesheetsMutationModelDeleteProjectTimeArgs = {
  projectTimeId: Scalars['String'];
};


export type TimesheetsMutationModelDeleteTimeEntryArgs = {
  timeEntryId: Scalars['String'];
};


export type TimesheetsMutationModelDeleteTimeOffEntryArgs = {
  timeOffEntryId: Scalars['String'];
};


export type TimesheetsMutationModelEditAdHocExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  expenseId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  payableOverride?: InputMaybe<Scalars['Decimal']>;
  projectNumber: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rateAmount?: InputMaybe<Scalars['Decimal']>;
  rateName: Scalars['String'];
  receiptImageId?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
};


export type TimesheetsMutationModelEditAtvExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  expenseId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  ownerInformation: OwnerInformation;
  payableOverride?: InputMaybe<Scalars['Decimal']>;
  projectNumber: Scalars['Int'];
  rateName: Scalars['String'];
  state: Scalars['String'];
};


export type TimesheetsMutationModelEditExpensePayableAmountArgs = {
  expenseId: Scalars['String'];
  notes: Scalars['String'];
  payableAmount: Scalars['Decimal'];
};


export type TimesheetsMutationModelEditFuelExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  companyVehicle: Scalars['Boolean'];
  county: Scalars['String'];
  crewCode: Scalars['String'];
  expenseId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  odometer: Scalars['Decimal'];
  payableOverride?: InputMaybe<Scalars['Decimal']>;
  payerInformation: PayerInformation;
  projectNumber: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rateName: Scalars['String'];
  receiptImageId?: InputMaybe<Scalars['String']>;
  receiptTotal: Scalars['Decimal'];
  state: Scalars['String'];
  vehicleNumber: Scalars['String'];
};


export type TimesheetsMutationModelEditMileageExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  beginningOdometer: Scalars['Decimal'];
  companyVehicle: Scalars['Boolean'];
  county: Scalars['String'];
  crewCode: Scalars['String'];
  endingOdometer: Scalars['Decimal'];
  expenseId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  payableOverride?: InputMaybe<Scalars['Decimal']>;
  projectNumber: Scalars['Int'];
  rateName: Scalars['String'];
  state: Scalars['String'];
  vehicleNumber: Scalars['String'];
};


export type TimesheetsMutationModelEditMobileAllowanceExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  expenseId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  payableOverride?: InputMaybe<Scalars['Decimal']>;
  projectNumber: Scalars['Int'];
  rateName: Scalars['String'];
  state: Scalars['String'];
};


export type TimesheetsMutationModelEditPerDiemExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  expenseId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  payableOverride?: InputMaybe<Scalars['Decimal']>;
  projectNumber: Scalars['Int'];
  rateName: Scalars['String'];
  receiptImageId?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
};


export type TimesheetsMutationModelEditProjectTimeArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  dailyHours?: InputMaybe<Array<EditProjectTimeCommandDailyHoursInput>>;
  hours: Scalars['Duration'];
  myRole?: InputMaybe<CrewRole>;
  notes: Scalars['String'];
  otherCrewUpn1?: InputMaybe<Scalars['String']>;
  otherCrewUpn2?: InputMaybe<Scalars['String']>;
  otherRole1?: InputMaybe<CrewRole>;
  otherRole2?: InputMaybe<CrewRole>;
  projectNumber: Scalars['Int'];
  projectTimeId: Scalars['String'];
  state: Scalars['String'];
  taskName: Scalars['String'];
};


export type TimesheetsMutationModelEditProjectTimeDailyArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  hours?: InputMaybe<Array<EditProjectTimeDailyCommandHoursInput>>;
  notes?: InputMaybe<Scalars['String']>;
  projectTimeId: Scalars['String'];
};


export type TimesheetsMutationModelEditReceiptValuedExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  expenseId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  odometer?: InputMaybe<Scalars['Decimal']>;
  payableOverride?: InputMaybe<Scalars['Decimal']>;
  payerInformation: PayerInformation;
  projectNumber: Scalars['Int'];
  rateName: Scalars['String'];
  receiptImageId: Scalars['String'];
  receiptTotal: Scalars['Decimal'];
  state: Scalars['String'];
  vehicleNumber?: InputMaybe<Scalars['String']>;
};


export type TimesheetsMutationModelEditTcpAllowanceExpenseArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  expenseId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  payableOverride?: InputMaybe<Scalars['Decimal']>;
  projectNumber: Scalars['Int'];
  rateName: Scalars['String'];
  state: Scalars['String'];
};


export type TimesheetsMutationModelEditTimeEntryArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  county: Scalars['String'];
  crewCode: Scalars['String'];
  hoursWorked: Scalars['Duration'];
  lunchEnd?: InputMaybe<Scalars['LocalTime']>;
  lunchHours?: InputMaybe<Scalars['Duration']>;
  lunchStart?: InputMaybe<Scalars['LocalTime']>;
  state: Scalars['String'];
  timeEntryId: Scalars['String'];
  timeIn: Scalars['LocalTime'];
  timeOut: Scalars['LocalTime'];
};


export type TimesheetsMutationModelEditTimeOffEntryArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  crewCode: Scalars['String'];
  hours: Scalars['Duration'];
  timeOffEntryId: Scalars['String'];
  timeOffType: TimeOffType;
};


export type TimesheetsMutationModelImportFuelExpenseArgs = {
  companyVehicle: Scalars['Boolean'];
  county: Scalars['String'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  externalId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  odometer: Scalars['Decimal'];
  payerInformation: PayerInformation;
  projectNumber: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rateName: Scalars['String'];
  rateSheetName?: InputMaybe<Scalars['String']>;
  receiptImageId?: InputMaybe<Scalars['String']>;
  receiptTotal: Scalars['Decimal'];
  state: Scalars['String'];
  userPrincipalName?: InputMaybe<Scalars['String']>;
  vehicleNumber: Scalars['String'];
};


export type TimesheetsMutationModelImportMileageExpenseArgs = {
  beginningOdometer: Scalars['Decimal'];
  companyVehicle: Scalars['Boolean'];
  county: Scalars['String'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  endingOdometer: Scalars['Decimal'];
  externalId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  projectNumber: Scalars['Int'];
  rateName: Scalars['String'];
  rateSheetName?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  userPrincipalName?: InputMaybe<Scalars['String']>;
  vehicleNumber: Scalars['String'];
};


export type TimesheetsMutationModelImportReceiptValuedExpenseArgs = {
  county: Scalars['String'];
  crewCode: Scalars['String'];
  date: Scalars['LocalDate'];
  externalId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  odometer?: InputMaybe<Scalars['Decimal']>;
  payerInformation: PayerInformation;
  projectNumber: Scalars['Int'];
  rateName: Scalars['String'];
  rateSheetName?: InputMaybe<Scalars['String']>;
  receiptImageId: Scalars['String'];
  receiptTotal: Scalars['Decimal'];
  state: Scalars['String'];
  userPrincipalName?: InputMaybe<Scalars['String']>;
  vehicleNumber?: InputMaybe<Scalars['String']>;
};


export type TimesheetsMutationModelMoveExpenseToDateArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  expenseId: Scalars['String'];
  newDate: Scalars['LocalDate'];
};


export type TimesheetsMutationModelMoveProjectTimeToDateArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  newDate: Scalars['LocalDate'];
  projectTimeId: Scalars['String'];
};


export type TimesheetsMutationModelMoveTimeEntryToDateArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  newDate: Scalars['LocalDate'];
  timeEntryId: Scalars['String'];
};


export type TimesheetsMutationModelMoveTimeOffToDateArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  newDate: Scalars['LocalDate'];
  timeOffEntryId: Scalars['String'];
};


export type TimesheetsMutationModelRejectWorkWeekArgs = {
  reason: Scalars['String'];
  userPrincipalName: Scalars['String'];
  weekBeginning: Scalars['LocalDate'];
};


export type TimesheetsMutationModelResetWorkWeekArgs = {
  userPrincipalName: Scalars['String'];
  weekBeginning: Scalars['LocalDate'];
};


export type TimesheetsMutationModelSubmitWorkWeekArgs = {
  userPrincipalName: Scalars['String'];
  weekBeginning: Scalars['LocalDate'];
};

export type TimesheetsQueryModel = {
  __typename?: 'TimesheetsQueryModel';
  approveableTimesheetCount?: Maybe<ApproveableTimesheetCountResponse>;
  atvExpenseTotals?: Maybe<ConnectionOfAtvExpenseTotal>;
  expense?: Maybe<IExpense>;
  expenseSearch?: Maybe<ConnectionOfExpenseSearchResult>;
  prevWorkDay?: Maybe<PrevWorkDay>;
  timesheetSearch?: Maybe<ConnectionOfTimesheet>;
  weeklyProjectTasks?: Maybe<WeeklyProjectTasks>;
  workDay?: Maybe<WorkDay>;
};


export type TimesheetsQueryModelApproveableTimesheetCountArgs = {
  dateAfter?: InputMaybe<Scalars['String']>;
  dateBefore?: InputMaybe<Scalars['String']>;
  officeCode?: InputMaybe<Scalars['String']>;
  payPeriod?: InputMaybe<PayPeriod>;
  payrollAdmin?: InputMaybe<Scalars['String']>;
  reimbursement?: InputMaybe<TimesheetSearchReimbursementOption>;
  searchText?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TimesheetStatus>;
  userPrincipalName?: InputMaybe<Scalars['String']>;
  weekStart: Scalars['String'];
  workLocation?: InputMaybe<Scalars['String']>;
};


export type TimesheetsQueryModelAtvExpenseTotalsArgs = {
  token?: InputMaybe<Scalars['String']>;
  userPrincipalName?: InputMaybe<Scalars['String']>;
};


export type TimesheetsQueryModelExpenseArgs = {
  expenseId: Scalars['String'];
};


export type TimesheetsQueryModelExpenseSearchArgs = {
  dateAfter?: InputMaybe<Scalars['String']>;
  dateBefore?: InputMaybe<Scalars['String']>;
  payPeriod?: InputMaybe<PayPeriod>;
  payrollAdmin?: InputMaybe<Scalars['String']>;
  reimbursement?: InputMaybe<TimesheetSearchReimbursementOption>;
  searchText?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TimesheetStatus>;
  token?: InputMaybe<Scalars['String']>;
  userPrincipalName?: InputMaybe<Scalars['String']>;
  week?: InputMaybe<Scalars['LocalDate']>;
  workLocation?: InputMaybe<Scalars['String']>;
};


export type TimesheetsQueryModelPrevWorkDayArgs = {
  date: Scalars['LocalDate'];
  userPrincipalName: Scalars['String'];
};


export type TimesheetsQueryModelTimesheetSearchArgs = {
  certifiedPayroll?: InputMaybe<Scalars['Boolean']>;
  dateAfter?: InputMaybe<Scalars['LocalDate']>;
  dateBefore?: InputMaybe<Scalars['LocalDate']>;
  payPeriod?: InputMaybe<PayPeriod>;
  payrollAdmin?: InputMaybe<Scalars['String']>;
  reimbursement?: InputMaybe<TimesheetSearchReimbursementOption>;
  searchText?: InputMaybe<Scalars['String']>;
  showFuture?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<TimesheetStatus>;
  timesheets?: InputMaybe<Array<Scalars['String']>>;
  token?: InputMaybe<Scalars['String']>;
  userPrincipalName?: InputMaybe<Scalars['String']>;
  week?: InputMaybe<Scalars['LocalDate']>;
  workLocation?: InputMaybe<Scalars['String']>;
};


export type TimesheetsQueryModelWeeklyProjectTasksArgs = {
  userPrincipalName: Scalars['String'];
  weekBeginning: Scalars['LocalDate'];
};


export type TimesheetsQueryModelWorkDayArgs = {
  date: Scalars['LocalDate'];
  userPrincipalName: Scalars['String'];
};

export type UploadedImage = {
  __typename?: 'UploadedImage';
  contentType: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  imageId: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type UploadedImagesQueryModel = {
  __typename?: 'UploadedImagesQueryModel';
  image?: Maybe<UploadedImage>;
};


export type UploadedImagesQueryModelImageArgs = {
  externalId?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
};

export type UsersMutationModel = {
  __typename?: 'UsersMutationModel';
  logSessionData?: Maybe<ValidationResponse>;
};


export type UsersMutationModelLogSessionDataArgs = {
  expirationTimestamp: Scalars['String'];
  sessionLength: Scalars['Duration'];
  version: Scalars['String'];
};

export type ValidationError = {
  __typename?: 'ValidationError';
  argumentName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type ValidationResponse = {
  __typename?: 'ValidationResponse';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type WeeklyProjectTasks = {
  __typename?: 'WeeklyProjectTasks';
  editable: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  projectTime?: Maybe<Array<Maybe<ProjectTimeEntry>>>;
  timesheetId: Scalars['String'];
};

export type WorkDay = {
  __typename?: 'WorkDay';
  date: Scalars['LocalDate'];
  editable: Scalars['Boolean'];
  employee?: Maybe<Employee>;
  expenses?: Maybe<Array<Maybe<IExpense>>>;
  projectTime?: Maybe<Array<Maybe<ProjectTimeEntry>>>;
  timeEntries?: Maybe<Array<Maybe<TimeEntry>>>;
  timeOff?: Maybe<Array<Maybe<TimeOffEntry>>>;
  weekStatus: TimesheetStatus;
  workDayId: Scalars['String'];
};

export type WorkDaySummary = {
  __typename?: 'WorkDaySummary';
  companyPaid: Scalars['Decimal'];
  date: Scalars['LocalDate'];
  expensesBillable: Scalars['Decimal'];
  expensesReimbursable: Scalars['Decimal'];
  hasReceipts: Scalars['Boolean'];
  mileage: Scalars['Decimal'];
  payroll: Scalars['Decimal'];
  projectTime: Scalars['Duration'];
  time: Scalars['Duration'];
  timeOff: Scalars['Duration'];
  totalExpensesPayable: Scalars['Decimal'];
};
