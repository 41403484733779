import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApproveAllTimeSheetsMutationVariables = Types.Exact<{
  weekStart: Types.Scalars['String'];
  dateAfter?: Types.InputMaybe<Types.Scalars['String']>;
  status?: Types.InputMaybe<Types.TimesheetStatus>;
  dateBefore?: Types.InputMaybe<Types.Scalars['String']>;
  payPeriod?: Types.InputMaybe<Types.PayPeriod>;
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
  reimbursement?: Types.InputMaybe<Types.TimesheetSearchReimbursementOption>;
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
  workLocation?: Types.InputMaybe<Types.Scalars['String']>;
  payrollAdmin?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ApproveAllTimeSheetsMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', approveAllWorkWeeks?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const ApproveAllTimeSheetsDocument = gql`
    mutation ApproveAllTimeSheets($weekStart: String!, $dateAfter: String, $status: TimesheetStatus, $dateBefore: String, $payPeriod: PayPeriod, $searchText: String, $reimbursement: TimesheetSearchReimbursementOption, $userPrincipalName: String, $workLocation: String, $payrollAdmin: String) {
  timesheets {
    approveAllWorkWeeks(
      weekStart: $weekStart
      dateAfter: $dateAfter
      status: $status
      dateBefore: $dateBefore
      payPeriod: $payPeriod
      searchText: $searchText
      reimbursement: $reimbursement
      userPrincipalName: $userPrincipalName
      workLocation: $workLocation
      payrollAdmin: $payrollAdmin
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type ApproveAllTimeSheetsMutationFn = Apollo.MutationFunction<ApproveAllTimeSheetsMutation, ApproveAllTimeSheetsMutationVariables>;

/**
 * __useApproveAllTimeSheetsMutation__
 *
 * To run a mutation, you first call `useApproveAllTimeSheetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAllTimeSheetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAllTimeSheetsMutation, { data, loading, error }] = useApproveAllTimeSheetsMutation({
 *   variables: {
 *      weekStart: // value for 'weekStart'
 *      dateAfter: // value for 'dateAfter'
 *      status: // value for 'status'
 *      dateBefore: // value for 'dateBefore'
 *      payPeriod: // value for 'payPeriod'
 *      searchText: // value for 'searchText'
 *      reimbursement: // value for 'reimbursement'
 *      userPrincipalName: // value for 'userPrincipalName'
 *      workLocation: // value for 'workLocation'
 *      payrollAdmin: // value for 'payrollAdmin'
 *   },
 * });
 */
export function useApproveAllTimeSheetsMutation(baseOptions?: Apollo.MutationHookOptions<ApproveAllTimeSheetsMutation, ApproveAllTimeSheetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveAllTimeSheetsMutation, ApproveAllTimeSheetsMutationVariables>(ApproveAllTimeSheetsDocument, options);
      }
export type ApproveAllTimeSheetsMutationHookResult = ReturnType<typeof useApproveAllTimeSheetsMutation>;
export type ApproveAllTimeSheetsMutationResult = Apollo.MutationResult<ApproveAllTimeSheetsMutation>;
export type ApproveAllTimeSheetsMutationOptions = Apollo.BaseMutationOptions<ApproveAllTimeSheetsMutation, ApproveAllTimeSheetsMutationVariables>;