import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeeRatesQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
  workLocation?: Types.InputMaybe<Types.Scalars['String']>;
  token?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EmployeeRatesQuery = { __typename?: 'QueryModel', employeeRates?: { __typename?: 'EmployeeRatesQueryModel', search?: { __typename?: 'ConnectionOfEmployeeRate', token?: string | null, records?: Array<{ __typename?: 'EmployeeRate', effectiveDate: string, rate: number, employee?: { __typename?: 'Employee', firstName: string, lastName: string, ultiProId: string, userPrincipalName: string, workLocation: string } | null } | null> | null } | null } | null };


export const EmployeeRatesDocument = gql`
    query EmployeeRates($searchText: String, $workLocation: String, $token: String) {
  employeeRates {
    search(searchText: $searchText, workLocation: $workLocation, token: $token) {
      token
      records {
        effectiveDate
        rate
        employee {
          firstName
          lastName
          ultiProId
          userPrincipalName
          workLocation
        }
      }
    }
  }
}
    `;

/**
 * __useEmployeeRatesQuery__
 *
 * To run a query within a React component, call `useEmployeeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeRatesQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      workLocation: // value for 'workLocation'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useEmployeeRatesQuery(baseOptions?: Apollo.QueryHookOptions<EmployeeRatesQuery, EmployeeRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeRatesQuery, EmployeeRatesQueryVariables>(EmployeeRatesDocument, options);
      }
export function useEmployeeRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeRatesQuery, EmployeeRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeRatesQuery, EmployeeRatesQueryVariables>(EmployeeRatesDocument, options);
        }
export type EmployeeRatesQueryHookResult = ReturnType<typeof useEmployeeRatesQuery>;
export type EmployeeRatesLazyQueryHookResult = ReturnType<typeof useEmployeeRatesLazyQuery>;
export type EmployeeRatesQueryResult = Apollo.QueryResult<EmployeeRatesQuery, EmployeeRatesQueryVariables>;