import { ApolloClient } from "@apollo/client";
import {
  CreateTimeEntryDocument,
  DeleteTimeEntryDocument,
  EditTimeEntryDocument,
} from "./timeEntryQueries.generated";
import {
  CreateTimeEntryArgs,
  DeleteTimeEntryArgs,
  EditTimeEntryArgs,
} from "./timeEntryTypes";
import {
  MutationAndResponseFn,
  MutationFn,
  ValidationResponse,
} from "./WorkDayApiType";

export type RawTimeEntryApi = {
  createTimeEntry: MutationAndResponseFn<
    CreateTimeEntryArgs,
    CreateTimeEntryResponse
  >;
  editTimeEntry: MutationFn<EditTimeEntryArgs>;
  deleteTimeEntry: MutationFn<DeleteTimeEntryArgs>;
};

export type CreateTimeEntryResponse = ValidationResponse & {
  timeEntryId: string;
};

export function createTimeEntryApi(client: ApolloClient<any>): RawTimeEntryApi {
  return {
    createTimeEntry: async (args, callback) => {
      const result = await client.mutate({
        mutation: CreateTimeEntryDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.createTimeEntry as CreateTimeEntryResponse,
        callback,
      };
    },
    editTimeEntry: async (args, callback) => {
      const result = await client.mutate({
        mutation: EditTimeEntryDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets?.editTimeEntry as ValidationResponse,
        callback,
      };
    },
    deleteTimeEntry: async (args, callback) => {
      const result = await client.mutate({
        mutation: DeleteTimeEntryDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.deleteTimeEntry as ValidationResponse,
        callback,
      };
    },
  };
}
