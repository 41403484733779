import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkEditProjectSupervisorMutationVariables = Types.Exact<{
  userPrincipalName: Types.Scalars['String'];
  projectNumbers: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type BulkEditProjectSupervisorMutation = { __typename?: 'MutationModel', projects?: { __typename?: 'ProjectsMutationModel', editProjectSupervisor?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const BulkEditProjectSupervisorDocument = gql`
    mutation BulkEditProjectSupervisor($userPrincipalName: String!, $projectNumbers: [Int!]!) {
  projects {
    editProjectSupervisor(
      userPrincipalName: $userPrincipalName
      projectNumbers: $projectNumbers
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type BulkEditProjectSupervisorMutationFn = Apollo.MutationFunction<BulkEditProjectSupervisorMutation, BulkEditProjectSupervisorMutationVariables>;

/**
 * __useBulkEditProjectSupervisorMutation__
 *
 * To run a mutation, you first call `useBulkEditProjectSupervisorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditProjectSupervisorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditProjectSupervisorMutation, { data, loading, error }] = useBulkEditProjectSupervisorMutation({
 *   variables: {
 *      userPrincipalName: // value for 'userPrincipalName'
 *      projectNumbers: // value for 'projectNumbers'
 *   },
 * });
 */
export function useBulkEditProjectSupervisorMutation(baseOptions?: Apollo.MutationHookOptions<BulkEditProjectSupervisorMutation, BulkEditProjectSupervisorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkEditProjectSupervisorMutation, BulkEditProjectSupervisorMutationVariables>(BulkEditProjectSupervisorDocument, options);
      }
export type BulkEditProjectSupervisorMutationHookResult = ReturnType<typeof useBulkEditProjectSupervisorMutation>;
export type BulkEditProjectSupervisorMutationResult = Apollo.MutationResult<BulkEditProjectSupervisorMutation>;
export type BulkEditProjectSupervisorMutationOptions = Apollo.BaseMutationOptions<BulkEditProjectSupervisorMutation, BulkEditProjectSupervisorMutationVariables>;