import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApproveThirdPartyInvoiceMutationVariables = Types.Exact<{
  invoiceNumber: Types.Scalars['String'];
}>;


export type ApproveThirdPartyInvoiceMutation = { __typename?: 'MutationModel', thirdPartyInvoices?: { __typename?: 'ThirdPartyInvoicesMutationModel', approve?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const ApproveThirdPartyInvoiceDocument = gql`
    mutation ApproveThirdPartyInvoice($invoiceNumber: String!) {
  thirdPartyInvoices {
    approve(invoiceNumber: $invoiceNumber) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type ApproveThirdPartyInvoiceMutationFn = Apollo.MutationFunction<ApproveThirdPartyInvoiceMutation, ApproveThirdPartyInvoiceMutationVariables>;

/**
 * __useApproveThirdPartyInvoiceMutation__
 *
 * To run a mutation, you first call `useApproveThirdPartyInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveThirdPartyInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveThirdPartyInvoiceMutation, { data, loading, error }] = useApproveThirdPartyInvoiceMutation({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *   },
 * });
 */
export function useApproveThirdPartyInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<ApproveThirdPartyInvoiceMutation, ApproveThirdPartyInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveThirdPartyInvoiceMutation, ApproveThirdPartyInvoiceMutationVariables>(ApproveThirdPartyInvoiceDocument, options);
      }
export type ApproveThirdPartyInvoiceMutationHookResult = ReturnType<typeof useApproveThirdPartyInvoiceMutation>;
export type ApproveThirdPartyInvoiceMutationResult = Apollo.MutationResult<ApproveThirdPartyInvoiceMutation>;
export type ApproveThirdPartyInvoiceMutationOptions = Apollo.BaseMutationOptions<ApproveThirdPartyInvoiceMutation, ApproveThirdPartyInvoiceMutationVariables>;