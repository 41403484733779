import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useInvoiceRollupQueryQuery } from "./query.generated";
import { CustomRollupInvoice } from "~/gql/types";
import Spinner from "~/spinner";
import { RollupInvoiceContext } from "./RollupInvoiceContext";
import { RollupInvoicePage } from "./RollupInvoicePage";

type InvoiceRouteData = { invoiceNumber: string };

export const CustomRollupInvoicePage: React.FC = () => {
  const params = useParams<InvoiceRouteData>();

  const { data, loading, refetch } = useInvoiceRollupQueryQuery({
    variables: {
      invoiceNumber: params.invoiceNumber!,
    },
  });

  const contextData = useMemo(
    () => ({
      rollupInvoice: data?.customRollupInvoices?.single as CustomRollupInvoice,
      reload: refetch,
    }),
    [data, refetch]
  );

  return (
    <>
      <Spinner open={loading} />
      {data && (
        <RollupInvoiceContext.Provider value={contextData}>
          <RollupInvoicePage />
        </RollupInvoiceContext.Provider>
      )}
    </>
  );
};
