import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import { createProvider } from "./createFilterProvider";
import {
  useStateCountyFromParam,
  useStateCountiesAsync,
} from "~/refdata2/stateCounties";
import StateCounty from "~/graphql/StateCounty";
import _ from "lodash";
import { StateCountyPicker } from "~/visuals/organisms/StateCountyPicker";

type StateCountyProviderProps = FilterProviderProps & {
  suggestText: string;
  label: string;
};

export function useStateCountyProvider(
  props: StateCountyProviderProps
): FilterProvider {
  const { suggestText, label } = props;
  const stateCountyFromParam = useStateCountyFromParam();
  const getLocations = useStateCountiesAsync();

  const type = "stateCounty";

  return createProvider({
    render: () => (
      <StateCountyPicker formField={type} label={label} helperText="" />
    ),
    type,
    label,
    props,
    getItems: getLocations,
    itemFromParam: stateCountyFromParam,
    shouldSuggestText: suggestText,
    toSuggestions: (result, text) =>
      result
        .filter(
          (sc) =>
            sc.state.toLowerCase().includes(text.toLowerCase()) ||
            sc.county.toLowerCase().includes(text.toLowerCase())
        )
        .map((sc) => ({ text: `${sc.state}-${sc.county}`, value: sc })),
    toLabelValue: (sc: StateCounty) => `${sc.state}-${sc.county}`,
    toParamValue: (sc: StateCounty) => `${sc.state}-${sc.county}`,
  });
}
