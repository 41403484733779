import { useLocation, useNavigate } from "react-router-dom";

export function useRelativeNav(): (path: string) => void {
  const location = useLocation().pathname;
  const navigate = useNavigate();

  return (path: string) => {
    const fullPathArray = location.split("/").filter((x) => x);

    path
      .split("/")
      .forEach((x) =>
        x === ".." ? fullPathArray.pop() : fullPathArray.push(x)
      );

    navigate(`/${fullPathArray.join("/")}`);
  };
}
