import * as Actions from "./actions";
import * as States from "./states";
type Action<T> = Actions.Action<T>;
type State<T> = States.State<T>;

function reduceUnfocused<T>(
  state: States.Unfocused<T>,
  action: Action<T>
): State<T> {
  if (Actions.isGotFocus(action)) {
    return {
      ...state,
      tag: "SuggestionsLoading",
      loading: true,
      hasInputFocus: true,
    };
  }

  if (Actions.isSelect(action)) {
    if (action.userSelected && state.onUserSelect) {
      state.onUserSelect(action.selection);
    }

    return {
      ...state,
      tag: "Selected",
      selection: action.selection,
      loading: false,
    };
  }
  return state;
}

function reduceFocused<T>(
  state: States.Focused<T>,
  action: Action<T>
): State<T> {
  if (Actions.isLostFocus(action)) {
    return {
      ...state,
      tag: "Unfocused",
      hasInputFocus: false,
      hasMenuMouse: false,
    };
  }
  if (Actions.isTextChanged(action)) {
    return {
      ...state,
      text: action.text,
    };
  }
  if (Actions.isLoadSuggestions(action)) {
    return {
      ...state,
      tag: "SuggestionsLoading",
      loading: true,
    };
  }
  if (Actions.isSelect(action)) {
    if (action.userSelected && state.onUserSelect) {
      state.onUserSelect(action.selection);
    }

    return {
      ...state,
      tag: "Selected",
      selection: action.selection,
      loading: false,
    };
  }
  return state;
}

function reduceSuggestionsLoading<T>(
  state: States.SuggestionsLoading<T>,
  action: Action<T>
): State<T> {
  if (Actions.isSuggestionsLoaded(action)) {
    if ((!action.data || action.data.length === 0) && state.text) {
      return {
        ...state,
        tag: "NotFound",
        errorMessage: state.emptyResultsMessage || "No results found",
        loading: false,
        hasMenuMouse: false,
      };
    }
    if ((!action.data || action.data.length === 0) && !state.text) {
      return {
        ...state,
        loading: false,
        tag: "Focused",
        hasInputFocus: true,
      };
    }
    return {
      ...state,
      tag: "SuggestionsVisible",
      suggestionIndex: 0,
      suggestions: action.data,
      loading: false,
    };
  }
  if (Actions.isTextChanged(action)) {
    return {
      ...state,
      loading: false,
      tag: "Focused",
      text: action.text,
      hasInputFocus: true,
    };
  }
  if (Actions.isSelect(action)) {
    if (action.userSelected && state.onUserSelect) {
      state.onUserSelect(action.selection);
    }

    return {
      ...state,
      tag: "Selected",
      selection: action.selection,
      loading: false,
    };
  }
  return state;
}

function reduceNotFound<T>(
  state: States.NotFound<T>,
  action: Action<T>
): State<T> {
  if (Actions.isTextChanged(action)) {
    return {
      ...state,
      tag: "Focused",
      text: action.text,
    } as State<T>;
  }
  if (Actions.isGotFocus(action)) {
    return {
      ...state,
      tag: "Focused",
      hasInputFocus: true,
    };
  }
  if (Actions.isLostFocus(action)) {
    return {
      ...state,
      tag: "Unfocused",
      hasInputFocus: false,
      hasMenuMouse: false,
    };
  }
  return state;
}

function reduceSuggestionsVisible<T>(
  state: States.SuggestionsVisible<T>,
  action: Action<T>
): State<T> {
  if (
    Actions.isLostFocus(action) &&
    state.suggestions.length === 1 &&
    state.text
  ) {
    return {
      ...state,
      tag: "Selected",
      selection: state.suggestions[0],
    };
  }
  if (Actions.isLostFocus(action) && !state.hasMenuMouse) {
    return {
      ...state,
      tag: "Unfocused",
      hasInputFocus: false,
      hasMenuMouse: false,
    };
  }
  if (Actions.isTextChanged(action)) {
    return {
      ...state,
      tag: "Focused",
      text: action.text,
      hasInputFocus: true,
    };
  }
  if (Actions.isMoveHighlightUp(action) && state.suggestionIndex > 0) {
    return {
      ...state,
      suggestionIndex: state.suggestionIndex - 1,
    };
  }
  if (
    Actions.isMoveHighlightDown(action) &&
    state.suggestionIndex < state.suggestions.length - 1
  ) {
    return {
      ...state,
      suggestionIndex: state.suggestionIndex + 1,
    };
  }
  if (Actions.isGotMenuMouse(action)) {
    return { ...state, hasMenuMouse: true };
  }
  if (Actions.isLostMenuMouse(action)) {
    if (!state.hasInputFocus) {
      return {
        ...state,
        tag: "Unfocused",
        hasMenuMouse: false,
        hasInputFocus: false,
      };
    }
    return {
      ...state,
      hasMenuMouse: false,
    };
  }
  if (Actions.isSelect(action)) {
    if (action.userSelected && state.onUserSelect) {
      state.onUserSelect(action.selection);
    }

    return {
      ...state,
      tag: "Selected",
      selection: action.selection,
    };
  }

  return state;
}

function reduceSelected<T>(
  state: States.Selected<T>,
  action: Action<T>
): State<T> {
  if (Actions.isClearSelection(action)) {
    return {
      onUserSelect: state.onUserSelect,
      tag: "Unfocused",
      text: "",
      hasMenuMouse: false,
      hasInputFocus: false,
      loading: false,
    };
  }
  if (Actions.isSelect(action)) {
    if (action.userSelected && state.onUserSelect) {
      state.onUserSelect(action.selection);
    }

    return {
      ...state,
      tag: "Selected",
      selection: action.selection,
      loading: false,
    };
  }
  return state;
}

export function reducer<T>(state: State<T>, action: Action<T>): State<T> {
  if (States.isFocused(state)) {
    return reduceFocused(state, action);
  }
  if (States.isUnfocused(state)) {
    return reduceUnfocused(state, action);
  }
  if (States.isNotFound(state)) {
    return reduceNotFound(state, action);
  }
  if (States.isSuggestionsVisible(state)) {
    return reduceSuggestionsVisible(state, action);
  }
  if (States.isSuggestionsLoading(state)) {
    return reduceSuggestionsLoading(state, action);
  }
  if (States.isSelected(state)) {
    return reduceSelected(state, action);
  }
  return state;
}
