import { useEffect, useState } from "react";
import { ImageInfo, useLoadFile } from "../../../file-loading/useLoadFile";

export function useLazyLoadImage(imageUrl: string): () => Promise<ImageInfo> {
  const [pImage, setPImage] = useState<Promise<ImageInfo> | null>(null);
  const loadImage = useLoadFile();

  useEffect(() => {
    if (!pImage) {
      return;
    }

    return () => {
      void pImage.then((img) => img.dispose());
    };
  }, [pImage]);

  useEffect(() => {
    setPImage(null);
  }, [imageUrl]);

  return () => {
    if (pImage) {
      return pImage;
    }

    const result = loadImage({ imageUrl });
    setPImage(result);
    return result;
  };
}
