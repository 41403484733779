import React from "react";
import { Expense } from "~/WorkDayApi/expenseTypes";
import { useUpdateExpense } from "./useUpdateExpense";
import {
  ExpenseRouter,
  ExpenseRouterProps,
} from "~/visuals/organisms/Expenses/ExpenseRouter";

type EditExpenseEntryProps = {
  date: string;
  expenseEntry: Expense;
};

const EditExpenseEntry: React.FC<EditExpenseEntryProps> = ({
  date,
  expenseEntry,
}) => {
  const props = useUpdateExpense({
    date,
    expenseEntry,
    formType: "Edit",
  }) as ExpenseRouterProps;
  return <ExpenseRouter {...props} />;
};

export default EditExpenseEntry;
