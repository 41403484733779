import * as Types from '../../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type GetCorpLocationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCorpLocationsQuery = { __typename?: 'QueryModel', corpLocations: { __typename?: 'CorpLocationQueryModel', all: Array<{ __typename?: 'CorpLocation', name: string, id: string, companyName: string, isActive: boolean }> } };


export const GetCorpLocationsDocument = gql`
    query GetCorpLocations {
  corpLocations {
    all {
      name
      id
      companyName
      isActive
    }
  }
}
    `;

/**
 * __useGetCorpLocationsQuery__
 *
 * To run a query within a React component, call `useGetCorpLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorpLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorpLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCorpLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetCorpLocationsQuery, GetCorpLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCorpLocationsQuery, GetCorpLocationsQueryVariables>(GetCorpLocationsDocument, options);
      }
export function useGetCorpLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCorpLocationsQuery, GetCorpLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCorpLocationsQuery, GetCorpLocationsQueryVariables>(GetCorpLocationsDocument, options);
        }
export type GetCorpLocationsQueryHookResult = ReturnType<typeof useGetCorpLocationsQuery>;
export type GetCorpLocationsLazyQueryHookResult = ReturnType<typeof useGetCorpLocationsLazyQuery>;
export type GetCorpLocationsQueryResult = Apollo.QueryResult<GetCorpLocationsQuery, GetCorpLocationsQueryVariables>;