import React from "react";
import { CorpLocationRefdataContext } from "./corpLocations";
import { CrewCodeRefdataContext } from "./crewCodes";
import { DivisionRefdataContext } from "./divisions";
import { FieldOfficeRefdataContext } from "./fieldOffices";
import { ProjectTypeRefdataContext } from "./projectTypes";
import { ServiceDescriptionRefdataContext } from "./serviceDescriptions";
import { StateCountyRefdataContext } from "./stateCounties";
import { WorkLocationRefdataContext } from "./workLocations";
import { ProductRefdataContext } from "./products";

export * from "./stateCounties";
export * from "./fieldOffices";

export const RefDataProvider: React.FC = ({ children }) => (
  <StateCountyRefdataContext>
    <FieldOfficeRefdataContext>
      <ProjectTypeRefdataContext>
        <CorpLocationRefdataContext>
          <DivisionRefdataContext>
            <ServiceDescriptionRefdataContext>
              <WorkLocationRefdataContext>
                <ProductRefdataContext>
                  <CrewCodeRefdataContext>{children}</CrewCodeRefdataContext>
                </ProductRefdataContext>
              </WorkLocationRefdataContext>
            </ServiceDescriptionRefdataContext>
          </DivisionRefdataContext>
        </CorpLocationRefdataContext>
      </ProjectTypeRefdataContext>
    </FieldOfficeRefdataContext>
  </StateCountyRefdataContext>
);
