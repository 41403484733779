import List, { ListProps } from "@material/react-list";
import React from "react";
import classnames from "classnames";
import "./Table.scss";

type TableProps = ListProps & {
  compact?: boolean;
  selectedIndex?: number[];
};

const Table: React.FC<TableProps> = ({
  className = "",
  compact = false,
  selectedIndex,
  children,
  ...otherProps
}) => {
  const cn = classnames("wk-table", className, { compact });
  return (
    <List
      {...otherProps}
      className={cn}
      nonInteractive
      selectedIndex={selectedIndex}
    >
      {children}
    </List>
  );
};

export default Table;
