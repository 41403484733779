import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditInvoiceChargeMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  quantity: Types.Scalars['Decimal'];
  rate: Types.Scalars['Decimal'];
  label: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  editNotes: Types.Scalars['String'];
  billable: Types.Scalars['Boolean'];
  crewCode: Types.Scalars['String'];
  unit?: Types.InputMaybe<Types.RateUnit>;
  date?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  rateSheetName?: Types.InputMaybe<Types.Scalars['String']>;
  employee?: Types.InputMaybe<Types.Scalars['String']>;
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  taxable?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type EditInvoiceChargeMutation = { __typename?: 'MutationModel', projectCharges?: { __typename?: 'ProjectChargesMutationModel', editProjectCharge?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const EditInvoiceChargeDocument = gql`
    mutation EditInvoiceCharge($id: Int!, $quantity: Decimal!, $rate: Decimal!, $label: String!, $notes: String, $editNotes: String!, $billable: Boolean!, $crewCode: String!, $unit: RateUnit, $date: LocalDate, $rateSheetName: String, $employee: String, $projectId: Int, $glAccount: String, $taxable: Boolean) {
  projectCharges {
    editProjectCharge(
      id: $id
      quantity: $quantity
      rate: $rate
      label: $label
      notes: $notes
      editNotes: $editNotes
      billable: $billable
      crewCode: $crewCode
      unit: $unit
      date: $date
      rateSheetName: $rateSheetName
      employee: $employee
      projectId: $projectId
      glAccount: $glAccount
      taxable: $taxable
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditInvoiceChargeMutationFn = Apollo.MutationFunction<EditInvoiceChargeMutation, EditInvoiceChargeMutationVariables>;

/**
 * __useEditInvoiceChargeMutation__
 *
 * To run a mutation, you first call `useEditInvoiceChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInvoiceChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInvoiceChargeMutation, { data, loading, error }] = useEditInvoiceChargeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      quantity: // value for 'quantity'
 *      rate: // value for 'rate'
 *      label: // value for 'label'
 *      notes: // value for 'notes'
 *      editNotes: // value for 'editNotes'
 *      billable: // value for 'billable'
 *      crewCode: // value for 'crewCode'
 *      unit: // value for 'unit'
 *      date: // value for 'date'
 *      rateSheetName: // value for 'rateSheetName'
 *      employee: // value for 'employee'
 *      projectId: // value for 'projectId'
 *      glAccount: // value for 'glAccount'
 *      taxable: // value for 'taxable'
 *   },
 * });
 */
export function useEditInvoiceChargeMutation(baseOptions?: Apollo.MutationHookOptions<EditInvoiceChargeMutation, EditInvoiceChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditInvoiceChargeMutation, EditInvoiceChargeMutationVariables>(EditInvoiceChargeDocument, options);
      }
export type EditInvoiceChargeMutationHookResult = ReturnType<typeof useEditInvoiceChargeMutation>;
export type EditInvoiceChargeMutationResult = Apollo.MutationResult<EditInvoiceChargeMutation>;
export type EditInvoiceChargeMutationOptions = Apollo.BaseMutationOptions<EditInvoiceChargeMutation, EditInvoiceChargeMutationVariables>;