import { ApolloClient } from "@apollo/client";
import createRefdataApi from "../createRefdataApi";
import { GetDivisionsQuery, GetDivisionsDocument } from "./query.generated";

export type Division = {
  id: string;
  name: string;
  isActive: boolean;
};

async function divisionsFetcher(client: ApolloClient<unknown>) {
  const result = await client.query<GetDivisionsQuery>({
    query: GetDivisionsDocument,
  });

  return result?.data?.divisions?.all as Division[];
}

const api = createRefdataApi(divisionsFetcher, "division");

export const DivisionRefdataContext = api.Provider;
export const useDivisionsAsync = api.useDataAsync;
export const useDivisions = api.useData;

export function useDivisionFromParam(): (
  name: string
) => Promise<Division | null> {
  const getDivisions = useDivisionsAsync();

  return async (name: string) => {
    const result = await getDivisions();
    const division = result?.find((x) => x.name === name);

    return division ?? null;
  };
}
