import React from "react";
import Button from "~/button";
import { TopActionButtons } from "~/top-action-buttons";
import { ProjectRequest } from "~/gql/types";
import { useRelativeNav } from "~/relative-nav";
import { getCustomerInfo } from "../utils";
import { useCheckout } from "../useCheckout";
import { CheckoutButton } from "../CheckoutButton";

type RequestButtonsProps = {
  projectRequest: ProjectRequest;
  refetch: () => Promise<void>;
};

export const RequestButtons: React.FC<RequestButtonsProps> = ({
  projectRequest,
  refetch,
}) => {
  const { buttonText } = getCustomerInfo(projectRequest);

  const { isCheckerOuter } = useCheckout({ projectRequest });

  const canEdit = !!projectRequest.checkedOutBy && isCheckerOuter;

  const nav = useRelativeNav();

  return (
    <TopActionButtons>
      {canEdit && (
        <>
          <Button primary onClick={() => nav("approve")}>
            {buttonText}
          </Button>
          <Button onClick={() => nav("reject")}>Reject</Button>
          <Button onClick={() => nav("edit")}>Edit</Button>
        </>
      )}

      <CheckoutButton {...{ projectRequest, refetch }} />
    </TopActionButtons>
  );
};
