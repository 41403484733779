import { useSnackBar } from "~/snackbar";
import { ValidationResponse, Timesheet } from "~/gql/types";
import {
  useSubmitWorkWeekMutation,
  useApproveWorkWeekMutation,
  useRejectWorkWeekMutation,
  useResetWorkWeekMutation,
} from "~/queries/UpdateWorkweekStatus.generated";

type UpdateWorkWeekStatusProps = {
  week: Timesheet;
  refetch: () => void;
};

type UpdateWorkWeekStatus = {
  handleSubmit: () => void;
  handleReject: (reason: string) => Promise<void>;
  handleApprove: () => void;
  handleReset: () => void;
  loading: boolean;
};

export function useUpdateWorkWeekStatus({
  week,
  refetch,
}: UpdateWorkWeekStatusProps): UpdateWorkWeekStatus {
  const alert = useSnackBar();
  const [submitMutation, { loading: submitLoading }] =
    useSubmitWorkWeekMutation();
  const [approveMutation, { loading: approveLoading }] =
    useApproveWorkWeekMutation();
  const [resetMutation, { loading: resetLoading }] = useResetWorkWeekMutation();
  const [rejectMutation, { loading: rejectLoading }] =
    useRejectWorkWeekMutation();
  const defaualtVariables = {
    weekBeginning: week.weekBeginning,
    userPrincipalName: week.employee!.userPrincipalName,
  };

  const handleSubmit = async () => {
    const { data } = await submitMutation({ variables: defaualtVariables });

    handleResult(data?.timesheets?.submitWorkWeek, "submitted");
  };

  const handleReject = async (reason: string) => {
    const variables = {
      ...defaualtVariables,
      reason,
    };
    const { data } = await rejectMutation({ variables });

    handleResult(data?.timesheets?.rejectWorkWeek, "rejected");
  };

  const handleApprove = async () => {
    const { data } = await approveMutation({ variables: defaualtVariables });

    handleResult(data?.timesheets?.approveWorkWeek, "approved");
  };

  const handleReset = async () => {
    const { data } = await resetMutation({ variables: defaualtVariables });

    handleResult(data?.timesheets?.resetWorkWeek, "reset to 'PR Pending'");
  };

  const handleResult = (
    response: ValidationResponse | null | undefined,
    message
  ) => {
    if (response && response?.errors!.length > 0) {
      const messages = response
        .errors!.map((x) => x!)
        .map(({ argumentName, message }) =>
          argumentName ? `${argumentName}: ${message}` : message
        );

      alert({
        key: week.weekBeginning,
        message: messages.join(" "),
        isSuccess: false,
      });
    } else if (response && refetch) {
      alert({
        key: week.weekBeginning,
        message: `Week beginning ${week.weekBeginning} ${message}`,
        isSuccess: true,
      });
      refetch();
    }
  };

  const actualLoading =
    approveLoading || submitLoading || resetLoading || rejectLoading;

  return {
    handleSubmit,
    handleReject,
    handleApprove,
    handleReset,
    loading: actualLoading,
  };
}
