import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateOfficeOverheadCrewCodeMutationVariables = Types.Exact<{
  crewCode: Types.Scalars['String'];
  officeCode: Types.Scalars['String'];
}>;


export type UpdateOfficeOverheadCrewCodeMutation = { __typename?: 'MutationModel', crewCodes?: { __typename?: 'CrewCodeMutationModel', updateOfficeOverheadCrewCode?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const UpdateOfficeOverheadCrewCodeDocument = gql`
    mutation UpdateOfficeOverheadCrewCode($crewCode: String!, $officeCode: String!) {
  crewCodes {
    updateOfficeOverheadCrewCode(crewCode: $crewCode, officeCode: $officeCode) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type UpdateOfficeOverheadCrewCodeMutationFn = Apollo.MutationFunction<UpdateOfficeOverheadCrewCodeMutation, UpdateOfficeOverheadCrewCodeMutationVariables>;

/**
 * __useUpdateOfficeOverheadCrewCodeMutation__
 *
 * To run a mutation, you first call `useUpdateOfficeOverheadCrewCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfficeOverheadCrewCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfficeOverheadCrewCodeMutation, { data, loading, error }] = useUpdateOfficeOverheadCrewCodeMutation({
 *   variables: {
 *      crewCode: // value for 'crewCode'
 *      officeCode: // value for 'officeCode'
 *   },
 * });
 */
export function useUpdateOfficeOverheadCrewCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOfficeOverheadCrewCodeMutation, UpdateOfficeOverheadCrewCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOfficeOverheadCrewCodeMutation, UpdateOfficeOverheadCrewCodeMutationVariables>(UpdateOfficeOverheadCrewCodeDocument, options);
      }
export type UpdateOfficeOverheadCrewCodeMutationHookResult = ReturnType<typeof useUpdateOfficeOverheadCrewCodeMutation>;
export type UpdateOfficeOverheadCrewCodeMutationResult = Apollo.MutationResult<UpdateOfficeOverheadCrewCodeMutation>;
export type UpdateOfficeOverheadCrewCodeMutationOptions = Apollo.BaseMutationOptions<UpdateOfficeOverheadCrewCodeMutation, UpdateOfficeOverheadCrewCodeMutationVariables>;