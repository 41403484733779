import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LogSessionDataMutationVariables = Types.Exact<{
  expirationTimestamp: Types.Scalars['String'];
  sessionLength: Types.Scalars['Duration'];
  version: Types.Scalars['String'];
}>;


export type LogSessionDataMutation = { __typename?: 'MutationModel', users?: { __typename?: 'UsersMutationModel', logSessionData?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const LogSessionDataDocument = gql`
    mutation LogSessionData($expirationTimestamp: String!, $sessionLength: Duration!, $version: String!) {
  users {
    logSessionData(
      expirationTimestamp: $expirationTimestamp
      sessionLength: $sessionLength
      version: $version
    ) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type LogSessionDataMutationFn = Apollo.MutationFunction<LogSessionDataMutation, LogSessionDataMutationVariables>;

/**
 * __useLogSessionDataMutation__
 *
 * To run a mutation, you first call `useLogSessionDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogSessionDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logSessionDataMutation, { data, loading, error }] = useLogSessionDataMutation({
 *   variables: {
 *      expirationTimestamp: // value for 'expirationTimestamp'
 *      sessionLength: // value for 'sessionLength'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useLogSessionDataMutation(baseOptions?: Apollo.MutationHookOptions<LogSessionDataMutation, LogSessionDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogSessionDataMutation, LogSessionDataMutationVariables>(LogSessionDataDocument, options);
      }
export type LogSessionDataMutationHookResult = ReturnType<typeof useLogSessionDataMutation>;
export type LogSessionDataMutationResult = Apollo.MutationResult<LogSessionDataMutation>;
export type LogSessionDataMutationOptions = Apollo.BaseMutationOptions<LogSessionDataMutation, LogSessionDataMutationVariables>;