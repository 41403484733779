import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogButton, DialogTitle } from "~/dialog";
import { Body1 } from "@material/react-typography";
import { DialogFooter } from "~/dialog-footer";

export const ErrorGenerator: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const [errorText, setErrorText] = useState("Example error message");

  useEffect(() => {
    const listener = (evt: KeyboardEvent) => {
      if (!evt.altKey) {
        return;
      }

      if (evt.key === "x" || evt.code === "KeyX") {
        setOpen(true);
      }
    };
    const evtName = "keydown";
    window.addEventListener(evtName, listener);

    return () => window.removeEventListener(evtName, listener);
  }, []);

  const generateError = () => {
    window.setTimeout(() => {
      throw new Error(errorText);
    }, 0);
  };

  return (
    <Dialog open={isOpen} onClose={() => setOpen(false)}>
      <DialogTitle>Error Generator</DialogTitle>
      <DialogContent>
        <Body1>
          Click the &apos;Generate&apos; button to cause an error to be
          reported. Edit the error text as needed.
        </Body1>
        <input
          type="text"
          value={errorText}
          onChange={(evt) => setErrorText(evt.target.value)}
        />
      </DialogContent>

      <DialogFooter>
        <DialogButton action="confirm" onClick={generateError} isDefaultAction>
          Generate
        </DialogButton>
        <DialogButton action="cancel">Cancel</DialogButton>
      </DialogFooter>
    </Dialog>
  );
};
