import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "~/Auth/useUser";
import { projectCharges } from "~/routes";
import { Role } from "~/visuals/pages/Home/RoleStats";
import {
  AnyRoleSubMenuItem,
  AnySubMenuItem,
  MyRoleSubMenuItem,
  SubMenu,
  SubmenuListItem,
  SubmenuNavItem,
  roles,
  useSubmenuContext,
} from "./submenu";
import { AuthMenuItemProps, NavMenuProps } from "./types";

const searchFilter = (role: Role) => `chargeState=${role}Pending`;
const path = projectCharges.path;

export const MyChargesMenuItem: React.FC<{ role: Role }> = ({ role }) => {
  return (
    <MyRoleSubMenuItem
      {...{
        role,
        toText: (role: Role) => `Needs My Approval (${role.toUpperCase()})`,
        path,
        searchFilter,
        className: "my-approvals",
      }}
    />
  );
};

export const AnyChargesMenuItem: React.FC<{ role: Role }> = ({ role }) => {
  return (
    <AnyRoleSubMenuItem
      {...{
        role,
        toText: (role: Role) => `Any Needing ${role.toUpperCase()} Approval`,
        path,
        searchFilter,
        className: "any-approvals",
      }}
    />
  );
};

const ChargesSubMenu: React.FC = () => {
  const user = useUser();
  const showAny = user.authorizations.administerProjectCharges;

  const { closeNav } = useSubmenuContext();

  const auths = user.authorizations;
  const hasBaOrGreaterPrivileges =
    auths.administerProjectCharges || auths.viewOverheadCharges;

  return (
    <SubMenu multiplier={showAny ? 8 : 5}>
      <Link to={projectCharges.path} onClick={closeNav}>
        <SubmenuListItem text="All" className="charge-submenu-item" />
      </Link>

      {hasBaOrGreaterPrivileges && (
        <AnySubMenuItem
          {...{
            text: "Unbilled",
            path,
            searchFilter: `chargeState=Unbilled&billingAdmin=${user.email}`,
            className: "unbilled-link",
          }}
        />
      )}

      {roles.map((role, index) => (
        <MyChargesMenuItem {...{ role }} key={index} />
      ))}

      {showAny && (
        <>
          {roles.map((role, index) => (
            <AnyChargesMenuItem {...{ role }} key={index} />
          ))}
        </>
      )}
    </SubMenu>
  );
};

export const ProjectChargesNavItem: React.FC<
  AuthMenuItemProps & NavMenuProps
> = (props) => {
  if (!props.isVisible) {
    return null;
  }

  return (
    <SubmenuNavItem {...props}>
      <ChargesSubMenu />
    </SubmenuNavItem>
  );
};
