import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillRollupInvoiceMutationVariables = Types.Exact<{
  invoiceNumber: Types.Scalars['String'];
}>;


export type BillRollupInvoiceMutation = { __typename?: 'MutationModel', customRollupInvoices?: { __typename?: 'CustomRollupInvoiceMutationModel', bill?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const BillRollupInvoiceDocument = gql`
    mutation BillRollupInvoice($invoiceNumber: String!) {
  customRollupInvoices {
    bill(invoiceNumber: $invoiceNumber) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type BillRollupInvoiceMutationFn = Apollo.MutationFunction<BillRollupInvoiceMutation, BillRollupInvoiceMutationVariables>;

/**
 * __useBillRollupInvoiceMutation__
 *
 * To run a mutation, you first call `useBillRollupInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillRollupInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billRollupInvoiceMutation, { data, loading, error }] = useBillRollupInvoiceMutation({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *   },
 * });
 */
export function useBillRollupInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<BillRollupInvoiceMutation, BillRollupInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BillRollupInvoiceMutation, BillRollupInvoiceMutationVariables>(BillRollupInvoiceDocument, options);
      }
export type BillRollupInvoiceMutationHookResult = ReturnType<typeof useBillRollupInvoiceMutation>;
export type BillRollupInvoiceMutationResult = Apollo.MutationResult<BillRollupInvoiceMutation>;
export type BillRollupInvoiceMutationOptions = Apollo.BaseMutationOptions<BillRollupInvoiceMutation, BillRollupInvoiceMutationVariables>;