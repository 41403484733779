import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import ProjectGroupPicker from "~/visuals/organisms/ProjectGroupPicker";
import { ProjectGroup } from "~/gql/types";
import {
  useProjectGroupFromParam,
  useProjectGroups,
} from "~/visuals/organisms/ProjectGroupPicker/projectGroupsQueryApi";

const separator = "__";

export function useProjectGroupProvider(
  props: FilterProviderProps
): FilterProvider {
  const groupFromParam = useProjectGroupFromParam();
  const getProjectGroups = useProjectGroups();

  const type = "projectGroup";
  const label = "Project Group";

  const shouldSuggestText = "group";
  const { documentationText: origDocText } = props;
  const documentationText = `${origDocText} Use "${shouldSuggestText}" for quick filtering.`;

  return {
    render: () => (
      <ProjectGroupPicker formField={type} label={label} helperText="" />
    ),
    type,
    label,
    documentationText,
    getSuggestions: async (text: string) => {
      if (!text) {
        return [];
      }

      const result = await getProjectGroups();
      if (!result || result.length === 0) {
        return [];
      }

      const lcSearch = text.toLowerCase();

      return (result as ProjectGroup[])
        .filter(
          (g) =>
            g.name.toLowerCase().includes(lcSearch) ||
            g.customer!.name.toLowerCase().includes(lcSearch)
        )
        .map((g) => ({ text: `${g.customer!.name} - ${g.name}`, value: g }));
    },
    shouldSuggest: (text: string) =>
      text.toLowerCase().startsWith(shouldSuggestText.toLowerCase()),
    toFilter: (value: ProjectGroup) => ({
      label: `${label}: ${value.customer!.name} - ${value.name}`,
      type,
      value,
    }),
    filterFromParams: async (params: URLSearchParams) => {
      const paramItem = params.get(type) || null;

      const split = paramItem ? paramItem.split(separator) : null;
      const customerNumber = split ? split[0] : null;
      const groupName = split ? split[1] : null;

      const item =
        customerNumber && groupName
          ? await groupFromParam(groupName, customerNumber)
          : null;

      return item
        ? {
            label: `${label}: ${item.customer!.name} - ${item.name}`,
            value: item,
            type,
          }
        : null;
    },
    toParam: (filter: ActiveFilter) =>
      filter?.value
        ? `${filter.value.customer.number}${separator}${filter.value.name}`
        : "",
  };
}
