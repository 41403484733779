import decode from "jwt-decode";
import jwt_decode from "jwt-decode";

export function getExpiration(token: string): Date {
  const { exp } = decode<{ exp: number }>(token);
  return new Date(exp * 1000);
}

export function getName(token: string): string | undefined {
  const { name } = decode<{ name: string | undefined }>(token);
  return name;
}

export function getEmail(token: string): string | undefined {
  if (!token || token.length === 0) {
    return undefined;
  }

  const jwt = jwt_decode<any>(token);
  return jwt.email ?? jwt.upn;
}

export function isExpired(token: string): boolean {
  const expiration = getExpiration(token);
  const now = new Date();

  return expiration.getTime() < now.getTime();
}
