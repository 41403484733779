import moment from "moment";

type GetTimeSinceReturn = {
  dateTime: string;
  elapsed: string;
};

export const getTimeSince = (date: string): GetTimeSinceReturn => {
  const maxDate = moment.utc(date).local();

  const dateTime = `${maxDate.format("MM/DD/yyyy hh:mm a")}`;
  const elapsed = maxDate.fromNow();

  return { dateTime, elapsed };
};
