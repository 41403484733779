import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import { createSimpleProvider } from "./createSimpleProvider";
import {
  WeekStatusPicker,
  statusOptions,
} from "~/filterProviders/WeekStatusPicker";

export function useWeekStatusProvider(
  props: FilterProviderProps
): FilterProvider {
  return createSimpleProvider({
    documentationText: props.documentationText,
    type: "weekStatus",
    label: "Timesheet Status",
    render: () => (
      <WeekStatusPicker
        formField="weekStatus"
        label="Timesheet Status"
        helperText=""
      />
    ),
    suggestText: "sta",
    options: statusOptions,
  });
}
