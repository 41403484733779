import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { TextHighlighter, createSearchRegex } from "~/text-highlighter";
import _ from "lodash";
import { IExpenseRate, TaskRate } from "~/gql/types";
import { MruPicker, MruPickerProps } from "~/mru-picker";
import MaterialIcon from "@material/react-material-icon";
import cn from "classnames";

type StoredEntry<TEntry> = TEntry & {
  rateSheetName: string;
  __typename: string;
};

export type ExpenseTaskMruProps<TEntry extends TaskRate | IExpenseRate> =
  FormFieldProps & {
    rates: TEntry[];
    rateSheetName: string;
    className: string;
    storageKey: string;
    menuClass: string;
  };

type RateItem<TEntry> = TEntry & { recent?: boolean };

const ExpenseTaskMruFormField = <TEntry extends TaskRate | IExpenseRate>(
  props: ExpenseTaskMruProps<TEntry>
): React.ReactElement => {
  const { rates, rateSheetName, className, storageKey, menuClass } = props;

  const format = (
    item: RateItem<TEntry>,
    searchText: string
  ): React.ReactNode => {
    const searchRegex = createSearchRegex(searchText);

    return (
      <div className={cn(menuClass, "menuitem")}>
        <TextHighlighter text={item.name} search={searchRegex} />
        {item.recent && <MaterialIcon icon="history" />}
      </div>
    );
  };

  const chipFormat = (item: RateItem<TEntry>) => item.name;

  const fetchData = (
    updateItems: (result: RateItem<TEntry>[]) => RateItem<TEntry>[]
  ) => {
    return async (searchText: string) => {
      const rateData = rates.map((r) => ({ ...r, rateSheetName }));
      const converted = (
        updateItems(rateData as RateItem<TEntry>[]) as StoredEntry<TEntry>[]
      ).filter((x) => x.rateSheetName === rateSheetName && !!x.__typename);

      if (!searchText) {
        return [
          ...converted,
          ...rateData.filter((x) => !converted.find((c) => c.name === x.name)),
        ];
      }

      const result = rateData?.filter((t) =>
        t.name.toLowerCase().includes(searchText.toLowerCase())
      );
      return result ?? [];
    };
  };

  const args = {
    ...props,
    format,
    chipFormat,
    fetchData,
    fieldsEqual: (storedItem: RateItem<TEntry>, dataItem: RateItem<TEntry>) =>
      storedItem.name === dataItem.name &&
      storedItem["rateSheetName"] === rateSheetName,
    className,
    storageKey,
    keepInCache: (storedItem: RateItem<TEntry>) =>
      storedItem["rateSheetName"] !== rateSheetName,
  } as MruPickerProps<RateItem<TEntry>>;

  return <MruPicker {...args} />;
};

export default ExpenseTaskMruFormField;
