import React, { useEffect } from "react";
import { Action } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { useScrollTo } from ".";

type LoadUntilProps = {
  dispatch: React.Dispatch<Action<any, any>>;
  currentFilter: string;
  getIsLoaded: (items: any[]) => boolean;
  getResults: (
    token: string | null
  ) => Promise<{ newToken: string | null; newItems: any[] }>;
  getCountExceeded?: (items: any[]) => boolean;
};

export const LoadUntil = ({
  dispatch,
  currentFilter,
  getIsLoaded,
  getResults,
  getCountExceeded,
}: LoadUntilProps): JSX.Element => {
  const { setScrollTo, setIsVisible } = useScrollTo();

  useEffect(() => {
    void (async () => {
      let loadingComplete = false;
      let countExceeded = false;
      let items: any[] = [];
      let token: string | null = "";

      while (!loadingComplete && token !== null) {
        const { newToken, newItems } = await getResults(token);
        token = newToken;

        items = [...items, ...(newItems ?? [])];

        loadingComplete = getIsLoaded(items);
        countExceeded = getCountExceeded ? getCountExceeded(items) : false;
      }
      dispatch({ tag: "DataLoaded", items, searchToken: token });

      setScrollTo(currentFilter);
      setIsVisible(!countExceeded);
    })();
  }, []);

  return <></>;
};
