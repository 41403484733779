import React, { ReactElement } from "react";
import Button from "~/button";

export interface TableRowActionButtonProps {
  testId?: string;
  disabled?: boolean;
  onClick: () => void;
  label: string;
  iconName: string;
}

export const TableRowActionButton = (
  props: TableRowActionButtonProps
): ReactElement => {
  const { testId, disabled = false, onClick, label, iconName } = props;

  return (
    <Button
      data-testid={testId}
      disabled={disabled}
      onClick={onClick}
      icon={iconName}
      aria-label={label}
    />
  );
};
