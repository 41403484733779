import React from "react";
import PageGrid from "~/visuals/templates/PageGrid";
import TableCell from "~/old-table-cell";
import TableContent from "~/old-table-content";
import Table from "~/old-table";
import Button from "~/button";
import TitledCard from "~/titled-card";
import { Cell } from "@material/react-layout-grid";
import { approveCustomer, rejectCustomer } from "~/routes";
import "./PendingCustomers.scss";
import { EmptyPlaceholder } from "./empty-placeholder";
import { PendingCustomer } from "~/gql/types";

type CustomerRowData = {
  address: string;
  phone: string;
};

type CustomerCardProps = PendingCustomer;

export const CustomerRow: React.FC<CustomerRowData> = ({ address, phone }) => {
  return (
    <TableContent className={"row-data"} trailingSeparator={false}>
      <TableCell text={`${address}, ${phone}`} />
    </TableContent>
  );
};

const CustomerCard: React.FC<CustomerCardProps> = ({
  name,
  address1,
  address2,
  city,
  state,
  zip,
  country,
  createCustomerRequestId,
  phone,
  requester,
  adminNotes,
  subAccountOf,
}) => {
  const fullAddress =
    country?.length > 0
      ? `${address1}, ${address2 ? address2 + ", " : ""}${city}, ${country}`
      : `${address1}, ${
          address2 ? address2 + ", " : ""
        }${city}, ${state}, ${zip}`;

  return (
    <TitledCard title={name}>
      <Table>
        <CustomerRow address={fullAddress} phone={phone} />

        <TableContent trailingSeparator={false}>
          {requester && (
            <TableCell
              text={`Requester: ${requester.firstName} ${requester.lastName}`}
            />
          )}
        </TableContent>

        <TableContent trailingSeparator={false}>
          {adminNotes && <TableCell text={`Admin Notes: ${adminNotes}`} />}
        </TableContent>

        <TableContent trailingSeparator={false}>
          {subAccountOf && (
            <TableCell text={`Sub Account Of: ${subAccountOf}`} />
          )}
        </TableContent>
      </Table>
      <section className="actions">
        <Button
          primary
          route={approveCustomer.toRoute(createCustomerRequestId)}
        >
          Approve
        </Button>
        <Button route={rejectCustomer.toRoute(createCustomerRequestId)}>
          Reject
        </Button>
      </section>
    </TitledCard>
  );
};

export type PendingCustomersProps = {
  customers: PendingCustomer[];
};

const PendingCustomers: React.FC<PendingCustomersProps> = ({ customers }) => {
  return (
    <PageGrid title="Pending Customers" className="pending-customers">
      {customers.length === 0 && (
        <EmptyPlaceholder text="no pending customers" />
      )}

      {customers.map((customer) => (
        <Cell
          columns={8}
          className="customer-cell"
          key={customer.createCustomerRequestId}
        >
          <CustomerCard {...customer} />
        </Cell>
      ))}
    </PageGrid>
  );
};

export default PendingCustomers;
