import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RejectCustomerMutationVariables = Types.Exact<{
  reason: Types.Scalars['String'];
  createCustomerRequestId: Types.Scalars['String'];
}>;


export type RejectCustomerMutation = { __typename?: 'MutationModel', customers?: { __typename?: 'CustomerMutationModel', rejectCustomer?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const RejectCustomerDocument = gql`
    mutation RejectCustomer($reason: String!, $createCustomerRequestId: String!) {
  customers {
    rejectCustomer(
      reason: $reason
      createCustomerRequestId: $createCustomerRequestId
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type RejectCustomerMutationFn = Apollo.MutationFunction<RejectCustomerMutation, RejectCustomerMutationVariables>;

/**
 * __useRejectCustomerMutation__
 *
 * To run a mutation, you first call `useRejectCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectCustomerMutation, { data, loading, error }] = useRejectCustomerMutation({
 *   variables: {
 *      reason: // value for 'reason'
 *      createCustomerRequestId: // value for 'createCustomerRequestId'
 *   },
 * });
 */
export function useRejectCustomerMutation(baseOptions?: Apollo.MutationHookOptions<RejectCustomerMutation, RejectCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectCustomerMutation, RejectCustomerMutationVariables>(RejectCustomerDocument, options);
      }
export type RejectCustomerMutationHookResult = ReturnType<typeof useRejectCustomerMutation>;
export type RejectCustomerMutationResult = Apollo.MutationResult<RejectCustomerMutation>;
export type RejectCustomerMutationOptions = Apollo.BaseMutationOptions<RejectCustomerMutation, RejectCustomerMutationVariables>;