import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { expenseRateSheets } from "~/routes";
import ExpenseRateSheetForm from "~/visuals/organisms/ExpenseRateSheetForm";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { useRateSheetsContext } from "~/visuals/organisms/RateSheets/rateSheetsContext";
import { ValidationResponse } from "~/gql/types";

type FormData = {
  name: string | null;
};

const CloneExpenseRateSheet: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ name?: string }>();
  const oldSheetName = params?.name ?? "";

  const { reload, clone, mutationLoading } = useRateSheetsContext();

  const cloneExpenseRateSheet = async (values: FormData) => {
    const result = await clone(values.name!, oldSheetName);

    reload();

    const data = result?.data?.expenseRates?.cloneRateSheet;

    return data as ValidationResponse;
  };

  const onSuccess = () => {
    navigate(expenseRateSheets.path);
  };

  return (
    <MutationForm
      initialValues={{ name: null }}
      onSuccess={onSuccess}
      runMutation={cloneExpenseRateSheet}
    >
      <ErrorRenderer
        render={(submitError) => (
          <ExpenseRateSheetForm
            errorMessage={submitError}
            loading={mutationLoading}
            oldSheetName={oldSheetName}
          />
        )}
      />
    </MutationForm>
  );
};

export default CloneExpenseRateSheet;
