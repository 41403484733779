import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import React, { useState } from "react";
import ClickSwallower from "~/click-swallower";
import { useSnackBar } from "~/snackbar";
import FormBottomRow from "~/form-bottom-row";
import Spinner from "~/spinner";
import { Expense } from "~/WorkDayApi/expenseTypes";
import CurrencyFormField from "../CurrencyFormField";
import TextFormField from "~/text-form-field";
import MaterialIcon from "@material/react-material-icon";
import "./ExpensePayableAmount.scss";
import { useEditExpensePayableMutation } from "./mutation.generated";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import TableCell from "~/old-table-cell";
import { ValidationResponse } from "~/gql/types";

type FormValues = {
  notes: string | null;
  payableAmount: string;
};

type BaseProps = {
  expense: Expense;
  showAmount: boolean;
  canPmApproveWorkData: boolean;
};

export type ExpensePayableAmountProps = BaseProps & {
  refetch: React.DispatchWithoutAction;
};

type DisplayProps = BaseProps & {
  setOpen: React.Dispatch<boolean>;
};

export const PayableAmountDisplay: React.FC<DisplayProps> = ({
  expense,
  showAmount,
  canPmApproveWorkData,
  setOpen,
}) => {
  const amount = showAmount ? `$${expense.payableAmount.toFixed(2)}` : "$0.00";

  const canEdit = canPmApproveWorkData && showAmount;

  const onClick = canEdit ? () => setOpen(true) : undefined;

  return (
    <TableCell className="edit-expense-payable-display" onClick={onClick}>
      {amount}
      {canEdit && <MaterialIcon icon="edit" />}
    </TableCell>
  );
};

export const ExpensePayableAmount: React.FC<ExpensePayableAmountProps> = ({
  expense,
  showAmount,
  canPmApproveWorkData,
  refetch,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const addAlert = useSnackBar();

  const [doMutation, { loading }] = useEditExpensePayableMutation();

  const handleSubmit = async (values: FormValues) => {
    const variables = {
      expenseId: expense.id,
      notes: values.notes!,
      payableAmount: parseFloat(values.payableAmount),
    };

    const response = await doMutation({ variables });

    return response.data?.timesheets
      ?.editExpensePayableAmount as ValidationResponse;
  };

  const onSuccess = () => {
    refetch();
    setOpen(false);
    addAlert({
      key: expense.id,
      message: `Expense ${expense.rateName} payable amount edited`,
      isSuccess: true,
    });
  };

  return (
    <ClickSwallower>
      <PayableAmountDisplay
        {...{ expense, showAmount, canPmApproveWorkData, setOpen }}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        data-open={open}
        className="edit-expense-payable-amount"
        portalize
      >
        <DialogTitle>{"Edit Payable Amount"}</DialogTitle>
        <Spinner open={loading} />
        <DialogContent>
          {open && (
            <MutationForm
              runMutation={handleSubmit}
              onSuccess={onSuccess}
              initialValues={{
                notes: null,
                payableAmount: `${expense.payableAmount}`,
              }}
            >
              <CurrencyFormField
                formField="payableAmount"
                label="Payable Amount"
                helperText=""
                required
              />
              <TextFormField
                formField="notes"
                label="Notes"
                helperText="The reason for changing the payable amount"
                required
              />

              <ErrorRenderer
                render={(submitError) => (
                  <FormBottomRow
                    errorMessage={submitError}
                    buttonText="Save"
                    onCancel={() => {
                      setOpen(false);
                    }}
                  />
                )}
              />
            </MutationForm>
          )}
        </DialogContent>
      </Dialog>
    </ClickSwallower>
  );
};
