import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { Project } from "~/gql/types";
import { Body1, Body2 } from "@material/react-typography";
import { TextHighlighter, createSearchRegex } from "~/text-highlighter";
import _ from "lodash";
import "./ProjectPickerFormField.scss";
import { useProjectSearch } from "~/refdata/useProjectSearch";
import { MruPicker, MruPickerProps, getRecentItems } from "~/mru-picker";
import MaterialIcon from "@material/react-material-icon";
export type ProjectPickerProps = FormFieldProps & {
  date: string;
  validator?: (
    val: Project,
    allValues: Record<string, any>
  ) => string | undefined;
  filter?: (projects: Project) => boolean;
};

type ProjectItem = Project & { recent?: boolean };

const ProjectPickerFormField: React.FC<ProjectPickerProps> = (props) => {
  const { filter } = props;
  const format = (item: ProjectItem, searchText: string): React.ReactNode => {
    const searchRegex = createSearchRegex(searchText);

    const location = item.location
      ? `${item.location.state}-${item.location.county}`
      : "";

    return (
      <div className="menuitem">
        <Body1 className="row">
          <TextHighlighter text={item.customer!.name} search={searchRegex} />
          <span className="divider-arrow">&gt;</span>
          <TextHighlighter
            text={`${item.name} (${item.number})`}
            search={searchRegex}
          />
        </Body1>
        <Body2 className="row">
          <TextHighlighter text={location} search={searchRegex} />
        </Body2>
        {item.recent && <MaterialIcon icon="history" />}
      </div>
    );
  };

  const chipFormat = (item: ProjectItem) => `${item.name} (${item.number})`;

  const getProjects = useProjectSearch();

  const fetchData = (updateItems: (result: Project[]) => Project[]) => {
    return async (searchText: string) => {
      const recentProjects = getRecentItems<Project>("recent-projects");

      if (!searchText && !recentProjects?.length) {
        return [];
      }

      const recentResult = recentProjects?.length
        ? await getProjects({
            asOf: props.date || undefined,
            projectNumbers: recentProjects
              .map((x) => x.number)
              .filter((x) => x),
          })
        : { records: [] };

      const recentRecords = recentResult?.records ?? ([] as ProjectItem[]);
      const converted = updateItems(recentRecords);

      if (!searchText) {
        return converted.filter((x) => (filter ? filter(x) : true));
      }

      const searchResult = await getProjects({
        searchText,
        asOf: props.date || undefined,
      });

      const searchedRecords = searchResult?.records ?? ([] as ProjectItem[]);

      return searchedRecords.filter((x) => (filter ? filter(x) : true));
    };
  };

  const args = {
    ...props,
    fetchData,
    format,
    chipFormat,
    fieldsEqual: (storedItem: Project, dataItem: Project) =>
      storedItem.number === dataItem.number,
    className: "project-picker",
    storageKey: "recent-projects",
  } as MruPickerProps<Project>;

  return (
    <div className="project-picker">
      <MruPicker {...args} />
    </div>
  );
};

export default ProjectPickerFormField;
