import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkAddToRollupInvoiceMutationVariables = Types.Exact<{
  addInvoicesArgs: Array<Types.BulkAddRollupLineItemsArgsInput> | Types.BulkAddRollupLineItemsArgsInput;
}>;


export type BulkAddToRollupInvoiceMutation = { __typename?: 'MutationModel', customRollupInvoices?: { __typename?: 'CustomRollupInvoiceMutationModel', bulkAddLineItems?: { __typename?: 'BulkAddInvoicesResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null, results?: Array<{ __typename?: 'AddInvoicesToRollupResult', invoiceNumber: string, projectGroup: string, invoiceCount: number } | null> | null } | null } | null };


export const BulkAddToRollupInvoiceDocument = gql`
    mutation BulkAddToRollupInvoice($addInvoicesArgs: [BulkAddRollupLineItemsArgsInput!]!) {
  customRollupInvoices {
    bulkAddLineItems(addInvoicesArgs: $addInvoicesArgs) {
      errors {
        argumentName
        message
      }
      results {
        invoiceNumber
        projectGroup
        invoiceCount
      }
    }
  }
}
    `;
export type BulkAddToRollupInvoiceMutationFn = Apollo.MutationFunction<BulkAddToRollupInvoiceMutation, BulkAddToRollupInvoiceMutationVariables>;

/**
 * __useBulkAddToRollupInvoiceMutation__
 *
 * To run a mutation, you first call `useBulkAddToRollupInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAddToRollupInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAddToRollupInvoiceMutation, { data, loading, error }] = useBulkAddToRollupInvoiceMutation({
 *   variables: {
 *      addInvoicesArgs: // value for 'addInvoicesArgs'
 *   },
 * });
 */
export function useBulkAddToRollupInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<BulkAddToRollupInvoiceMutation, BulkAddToRollupInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkAddToRollupInvoiceMutation, BulkAddToRollupInvoiceMutationVariables>(BulkAddToRollupInvoiceDocument, options);
      }
export type BulkAddToRollupInvoiceMutationHookResult = ReturnType<typeof useBulkAddToRollupInvoiceMutation>;
export type BulkAddToRollupInvoiceMutationResult = Apollo.MutationResult<BulkAddToRollupInvoiceMutation>;
export type BulkAddToRollupInvoiceMutationOptions = Apollo.BaseMutationOptions<BulkAddToRollupInvoiceMutation, BulkAddToRollupInvoiceMutationVariables>;