import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "~/filterProviders/types";
import { createSimpleProvider } from "~/filterProviders/createSimpleProvider";
import {
  ProjectRequestStatePicker,
  requestStates,
} from "~/project-requests/PendingRequests/ProjectRequestStatePicker";

export function useProjectRequestStateProvider(
  props: FilterProviderProps
): FilterProvider {
  return createSimpleProvider({
    documentationText: props.documentationText,
    type: "state",
    label: "State",
    render: () => (
      <ProjectRequestStatePicker
        formField="state"
        label="State"
        helperText=""
      />
    ),
    suggestText: "sta",
    options: requestStates,
  });
}
