import React, { ReactChild } from "react";
import { Cell, Row } from "@material/react-layout-grid";
import { Headline4 } from "@material/react-typography";
import "./PageTitleRow.scss";

export interface PageTitleRowProps {
  title: string | ReactChild;
  actions?: React.ReactChild;
}

const PageTitleRow: React.FC<{
  title: string | ReactChild;
  actions?: React.ReactChild;
}> = ({ title, actions }) => (
  <Row className="page-title-row">
    <Cell desktopColumns={9} tabletColumns={5} phoneColumns={1}>
      <Headline4>{title}</Headline4>
    </Cell>
    <Cell
      desktopColumns={3}
      tabletColumns={3}
      phoneColumns={3}
      className="actions"
    >
      {actions}
    </Cell>
  </Row>
);

export default PageTitleRow;
