import React from "react";
import { useBillInvoiceMutation } from "./billInvoice.generated";
import { Invoice, ValidationError } from "~/gql/types";
import { BillInvoice as BillInvoiceComponent } from "~/bill-invoice";

type BillInvoiceProps = {
  invoice: Invoice;
  refetch: React.DispatchWithoutAction;
};

const BillInvoice: React.FC<BillInvoiceProps> = ({ invoice, refetch }) => {
  const [doMutation, { loading }] = useBillInvoiceMutation();

  if (!invoice.canEdit) {
    return null;
  }

  const bill = async () => {
    const result = await doMutation({
      variables: { invoiceNumber: invoice.invoiceNumber },
    });
    return result?.data?.invoices?.bill?.errors as ValidationError[];
  };

  return (
    <BillInvoiceComponent
      {...{
        bill,
        invoiceNumber: invoice.invoiceNumber,
        loading,
        type: "Invoice",
        reload: refetch,
      }}
    />
  );
};

export default BillInvoice;
