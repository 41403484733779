import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApproveCustomerMutationVariables = Types.Exact<{
  number: Types.Scalars['String'];
  subNumber: Types.Scalars['String'];
  createCustomerRequestId: Types.Scalars['String'];
}>;


export type ApproveCustomerMutation = { __typename?: 'MutationModel', customers?: { __typename?: 'CustomerMutationModel', approveCustomer?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const ApproveCustomerDocument = gql`
    mutation ApproveCustomer($number: String!, $subNumber: String!, $createCustomerRequestId: String!) {
  customers {
    approveCustomer(
      number: $number
      subNumber: $subNumber
      createCustomerRequestId: $createCustomerRequestId
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type ApproveCustomerMutationFn = Apollo.MutationFunction<ApproveCustomerMutation, ApproveCustomerMutationVariables>;

/**
 * __useApproveCustomerMutation__
 *
 * To run a mutation, you first call `useApproveCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveCustomerMutation, { data, loading, error }] = useApproveCustomerMutation({
 *   variables: {
 *      number: // value for 'number'
 *      subNumber: // value for 'subNumber'
 *      createCustomerRequestId: // value for 'createCustomerRequestId'
 *   },
 * });
 */
export function useApproveCustomerMutation(baseOptions?: Apollo.MutationHookOptions<ApproveCustomerMutation, ApproveCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveCustomerMutation, ApproveCustomerMutationVariables>(ApproveCustomerDocument, options);
      }
export type ApproveCustomerMutationHookResult = ReturnType<typeof useApproveCustomerMutation>;
export type ApproveCustomerMutationResult = Apollo.MutationResult<ApproveCustomerMutation>;
export type ApproveCustomerMutationOptions = Apollo.BaseMutationOptions<ApproveCustomerMutation, ApproveCustomerMutationVariables>;