import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { Suggestion } from "~/ultra-filter/types";

export const yesNoOptions = [
  { value: "Yes", text: "Yes" },
  { value: "No", text: "No" },
] as Suggestion[];

export type YesNoFilter = "Yes" | "No" | null;

const format = (item: string) =>
  yesNoOptions.find((s) => s.value === item)?.text;

export const YesNoPicker: React.FC<FormFieldProps> = (props) => {
  const getData = async (searchText: string) => {
    if (!searchText) {
      return yesNoOptions.map((s) => s.value);
    }
    return yesNoOptions
      .filter(
        (s) =>
          s.value.toLowerCase().startsWith(searchText.toLowerCase()) ||
          s.text.toLowerCase().startsWith(searchText.toLowerCase())
      )
      .map((s) => s.value);
  };

  const args = {
    ...props,
    getData,
    format,
  } as TypeaheadProps<string>;

  return <TypeaheadFormField {...args} />;
};
