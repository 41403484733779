import React from "react";
import { Navigate, useParams } from "react-router-dom";
import EditExpenseEntry from "./EditExpense";
import { useWorkDayApi } from "~/WorkDayApi";
import { Expense } from "~/WorkDayApi/expenseTypes";

const EditExpense: React.FC = () => {
  const { expenseId, date } = useParams<{ expenseId: string; date: string }>();
  const { workDay } = useWorkDayApi();

  const expenseEntry = workDay.expenses?.find(
    (entry) => entry?.id === expenseId
  );

  if (!expenseEntry) {
    return <Navigate to="../../" />;
  }

  return (
    <EditExpenseEntry date={date!} expenseEntry={expenseEntry as Expense} />
  );
};

export default EditExpense;
