import React, { createContext, useContext } from "react";
import { TaskRateSheet } from "~/graphql/TaskRates";
import {
  assignSuccessorTaskRateSheet,
  createTaskRate,
  editTaskRateSheet,
} from "~/routes";
import { Route, Routes, useParams } from "react-router-dom";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import Card from "@material/react-card";
import RateSheet from "~/visuals/pages/TaskRates/TaskRateSheet";
import NewTaskRate from "~/visuals/pages/TaskRates/NewTaskRate";
import EditTaskRate from "~/visuals/pages/TaskRates/EditTaskRate";
import { useTaskRateSheetQuery } from "./query.generated";
import { AssignSuccessorTaskRateSheet } from "~/visuals/pages/TaskRates/TaskRateSheetRouter/AssignSuccessor";
import { EditTaskRateSheet } from "~/visuals/pages/TaskRates/TaskRateSheetRouter/EditTaskRateSheet";

export type TaskRateSheetData = {
  taskRates: {
    sheet: TaskRateSheet;
  };
};

export type TaskRateSheetRouteData = {
  name: string;
  rateName?: string;
};

const Loading = () => (
  <Grid>
    <Row>
      <Cell columns={12}>
        <Card>loading...</Card>
      </Cell>
    </Row>
  </Grid>
);

const useReload = (): React.DispatchWithoutAction => {
  const api = useContext(TaskRateSheetContext);

  if (!api) {
    throw new Error(
      "Do not use TaskRateSheetRouter.useReload outside of a TaskRateSheetRouter."
    );
  }

  return api.reload;
};

type RouteProps = {
  rateSheet: TaskRateSheet;
};

const PageRoutes: React.FC<RouteProps> = ({ rateSheet }) => {
  return (
    <Routes>
      <Route
        path={createTaskRate.subPath}
        element={
          <NewTaskRate rateSheetName={rateSheet?.name} useReload={useReload} />
        }
      />
      <Route
        path={editTaskRateSheet.subPath}
        element={
          <EditTaskRateSheet rateSheet={rateSheet} useReload={useReload} />
        }
      />
      <Route
        path={assignSuccessorTaskRateSheet.subPath}
        element={
          <AssignSuccessorTaskRateSheet
            rateSheet={rateSheet}
            useReload={useReload}
          />
        }
      />
      <Route path="/edit-task-rate">
        <Route
          path={":rateName"}
          element={<EditTaskRate rateSheet={rateSheet} useReload={useReload} />}
        />
      </Route>
      <Route
        path={"*"}
        element={<RateSheet rateSheet={rateSheet} useReload={useReload} />}
      />
    </Routes>
  );
};

type TaskRateSheetContextApi = {
  reload: () => void;
};

const TaskRateSheetContext = createContext<TaskRateSheetContextApi | null>(
  null
);

const TaskRateSheetRouter: React.FC = () => {
  const params = useParams<TaskRateSheetRouteData>();

  const { data, loading, refetch } = useTaskRateSheetQuery({
    variables: { name: params.name! },
  });

  const taskRateSheet = data?.taskRates?.sheet;

  if (data && taskRateSheet && !loading) {
    const api = {
      reload: refetch,
    };

    return (
      <TaskRateSheetContext.Provider value={api}>
        <PageRoutes rateSheet={taskRateSheet} />
      </TaskRateSheetContext.Provider>
    );
  }

  return <Loading />;
};

export default TaskRateSheetRouter;
