import moment from "moment";
import { DailyHours } from "./stateMachine";

const durationFormat = (hours: number | undefined) => {
  if (!hours) {
    return "00:00:00";
  }

  const milliseconds = hours * 3600 * 1000;
  const m = moment(milliseconds).startOf("day").add(milliseconds);

  return m.format("HH:mm:ss");
};

export function toDailyHoursArgs<TInput>(
  dailyHours: DailyHours[] | null
): TInput[] | null {
  if (!dailyHours) {
    return null;
  }

  return dailyHours.map((hours) => ({
    asBuilt: durationFormat(hours.asBuilt),
    boundary: durationFormat(hours.boundary),
    clStake: durationFormat(hours.clStake),
    constStake: durationFormat(hours.constStake),
    constRestake: durationFormat(hours.constRestake),
    locate: durationFormat(hours.locate),
    rainoutOther: durationFormat(hours.rainoutOther),
    rerouteStake: durationFormat(hours.rerouteStake),
    travelHours: durationFormat(hours.travelHours),
    employeeUpn: hours.employee!.userPrincipalName,
    role: hours.role[0].toUpperCase() + hours.role.slice(1),
  })) as TInput[];
}
