import React, { useEffect } from "react";
import { PostToInvoiceAction, PostToInvoiceState } from "./stateMachine";
import { LoadedDraftInvoice } from "./types";

type DraftInvoicesLoaderProps<T> = {
  state: PostToInvoiceState<T>;
  dispatch: React.Dispatch<PostToInvoiceAction>;
  getDraftInvoices: (
    groupIds: (string | number)[]
  ) => Promise<LoadedDraftInvoice[]>;
};

export const DraftInvoicesLoader = <T,>(
  props: DraftInvoicesLoaderProps<T>
): React.ReactElement => {
  const { state, dispatch, getDraftInvoices } = props;

  useEffect(() => {
    if (state.tag === "LoadingDraftInvoices") {
      void (async () => {
        const draftInvoices = await getDraftInvoices(
          state.itemGroups.map((x) => x.groupId)
        );

        dispatch({ tag: "DraftInvoicesLoaded", draftInvoices });
      })();
    }
  }, [state.tag]);
  return <></>;
};
