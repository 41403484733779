import React, { useState } from "react";
import { Moment } from "moment";
import { useSnackBar } from "~/snackbar";
import Button from "~/button";
import { useConfirmationPrompt } from "~/confirmation-prompt";
import Spinner from "~/spinner";
import { useCreateInvoiceMutation } from "./createInvoice.generated";
import { CreateInvoiceResponse, ValidationError } from "~/gql/types";

type CreateInvoiceProps = {
  reloadInvoices: React.DispatchWithoutAction;
  projectNumber: number;
};

export type FormValues = {
  invoiceDate: Moment;
};

export const CreateInvoice: React.FC<CreateInvoiceProps> = ({
  reloadInvoices,
  projectNumber,
}) => {
  const addAlert = useSnackBar();
  const prompt = useConfirmationPrompt();
  const [loading, setLoading] = useState<boolean>(false);

  const [doMutation] = useCreateInvoiceMutation();

  const handleSubmit = async () => {
    setLoading(true);

    const variables = { projectNumber };
    const result = await doMutation({ variables });

    setLoading(false);
    const data = result?.data?.invoices?.create as CreateInvoiceResponse;

    const errors = data.errors as ValidationError[];

    if (data && errors && errors.length > 0) {
      const errorMessages: string[] = [];
      errors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          errorMessages.push(`${argumentName}: ${message}`);
        } else if (message) {
          errorMessages.push(message);
        }
      });

      addAlert({
        key: `${projectNumber}`,
        isSuccess: false,
        message: errorMessages.join(" "),
      });
    } else if (!data.invoiceNumber) {
      addAlert({
        key: `${projectNumber}`,
        isSuccess: false,
        message: "There was an error with creating the invoice",
      });
    } else {
      addAlert({
        key: `${data.invoiceNumber}`,
        message: `Invoice ${data.invoiceNumber} created`,
        isSuccess: true,
      });
      reloadInvoices();
    }
  };

  const openPrompt = async () => {
    const promptResult = await prompt({
      cancelButtonText: "Don't create",
      confirmButtonText: "Yes, create",
      message: "Are you sure you want to create an invoice?",
      title: "Create Invoice",
    });

    if (promptResult !== "Confirm") {
      return;
    }

    await handleSubmit();
  };

  return (
    <>
      <Spinner open={loading} />
      <Button primary onClick={openPrompt}>
        Create invoice
      </Button>
    </>
  );
};
