import React, { useState } from "react";
import cn from "classnames";
import "./FilteredSearchBox.scss";
import HelperTextLine from "~/HelperTextLine";
import { FilterChip } from "~/filter-chip";
import { PopupMenuList, PopupMenuListItem } from "~/popup-menu-list";
import { InlineProgress } from "~/inline-progress";
import Button from "~/button";
import { ActiveFilter, Suggestion } from "../types";
import { Action } from "./actions";
import { Body2 } from "@material/react-typography";

export type FilteredSearchBoxProps = {
  value: string;
  onChange: React.Dispatch<string>;
  label: string;
  helperTextMessage: string;
  filters: ActiveFilter[];
  filterPrompt: string | null;
  isError: boolean;
  loading: boolean;
  suggestions: Suggestion[];
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<boolean>;
  dispatch: React.Dispatch<Action>;
  selectedIndex: number;
  onSuggestionClick: (suggestion: Suggestion) => void;
  stats?: string;
};

export const FilteredSearchBox: React.FC<FilteredSearchBoxProps> = (props) => {
  const {
    label,
    value,
    onChange,
    helperTextMessage,
    filters,
    filterPrompt,
    isError,
    loading,
    suggestions,
    dialogOpen,
    setDialogOpen,
    dispatch,
    selectedIndex,
    onSuggestionClick,
    stats,
  } = props;
  const [hasTextFocus, setTextFocus] = useState(false);
  const [hasMenuFocus, setMenuFocus] = useState(false);

  const hasFocus = hasTextFocus || hasMenuFocus;
  const shouldLabelFloat = hasFocus || value.length || filters.length;

  const rootClassName = cn("mdc-text-field", "filtered-search-box", {
    "mdc-text-field--focused": hasFocus,
    "mdc-text-field--invalid": isError,
    loading,
  });
  const rippleClassName = cn("mdc-line-ripple", {
    "mdc-line-ripple--active": hasFocus,
  });

  const suggestionItems: PopupMenuListItem[] = suggestions.map((x) => ({
    key: x.text,
    label: x.text,
    onClick: () => onSuggestionClick(x),
  }));

  const showSuggestions = suggestions.length > 0 && hasFocus;

  return (
    <PopupMenuList
      visible={showSuggestions}
      items={suggestionItems}
      onMenuMouseEnter={() => dispatch({ tag: "got-menu-mouse" })}
      onMenuMouseLeave={() => dispatch({ tag: "lost-menu-mouse" })}
      onFocus={() => setMenuFocus(true)}
      onBlur={() => setMenuFocus(false)}
      focusOnOpen={false}
      selectedIndex={selectedIndex}
      anchorCorner="bottomLeft"
    >
      <div className={rootClassName}>
        <div className="mdc-text-field__input">
          {filters.map((f, idx) => (
            <FilterChip
              key={`${f.label}${idx}`}
              label={f.label}
              onClick={() => dispatch({ tag: "remove-filter", filter: f })}
            />
          ))}
          {filterPrompt && <div className="filter-prompt">{filterPrompt}:</div>}
          <input
            type="text"
            className={cn("search-text-filter", {
              "filter-value": filterPrompt,
            })}
            value={value}
            onFocus={() => {
              setTextFocus(true);
              dispatch({ tag: "focus" });
            }}
            onBlur={() => {
              setTextFocus(false);
              dispatch({ tag: "blur" });
            }}
            onChange={(evt) => onChange(evt.target.value)}
          />
          {loading && (
            <div className="loader">
              <InlineProgress size="xsmall" />
            </div>
          )}
          <Button
            className="dialog-button"
            icon="expand_more"
            onClick={() => setDialogOpen(!dialogOpen)}
          />
        </div>
        <label
          className={cn("mdc-floating-label", {
            "mdc-floating-label--float-above": shouldLabelFloat,
          })}
        >
          {label}
        </label>
        <div
          className={rippleClassName}
          style={{ transformOrigin: "60px center" }}
        ></div>
      </div>
      <div className="mdc-text-field-helper-line">
        <HelperTextLine message={helperTextMessage} isValid={!isError} />
        {stats && <Body2 className="filtered-search-stats">{stats}</Body2>}
      </div>
    </PopupMenuList>
  );
};
