import { Dialog, DialogTitle, DialogContent } from "~/dialog";
import React from "react";
import "./AddCharges.scss";
import AddCharges, { AddChargesProps } from "./AddCharges";
import "./AddChargesDialog.scss";

export const AddChargesDialog: React.FC<AddChargesProps> = (props) => {
  const { open, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-open={open}
      className="add-charges-dialog"
      portalize
    >
      <DialogTitle>Add Charges</DialogTitle>
      <DialogContent>
        <AddCharges {...props} />
      </DialogContent>
    </Dialog>
  );
};
