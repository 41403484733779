import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SingleEmployeeRateQueryQueryVariables = Types.Exact<{
  userPrincipalName: Types.Scalars['String'];
  effectiveDate: Types.Scalars['LocalDate'];
}>;


export type SingleEmployeeRateQueryQuery = { __typename?: 'QueryModel', employeeRates?: { __typename?: 'EmployeeRatesQueryModel', single?: { __typename?: 'EmployeeRate', rate: number } | null } | null };


export const SingleEmployeeRateQueryDocument = gql`
    query SingleEmployeeRateQuery($userPrincipalName: String!, $effectiveDate: LocalDate!) {
  employeeRates {
    single(userPrincipalName: $userPrincipalName, effectiveDate: $effectiveDate) {
      rate
    }
  }
}
    `;

/**
 * __useSingleEmployeeRateQueryQuery__
 *
 * To run a query within a React component, call `useSingleEmployeeRateQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleEmployeeRateQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleEmployeeRateQueryQuery({
 *   variables: {
 *      userPrincipalName: // value for 'userPrincipalName'
 *      effectiveDate: // value for 'effectiveDate'
 *   },
 * });
 */
export function useSingleEmployeeRateQueryQuery(baseOptions: Apollo.QueryHookOptions<SingleEmployeeRateQueryQuery, SingleEmployeeRateQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleEmployeeRateQueryQuery, SingleEmployeeRateQueryQueryVariables>(SingleEmployeeRateQueryDocument, options);
      }
export function useSingleEmployeeRateQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleEmployeeRateQueryQuery, SingleEmployeeRateQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleEmployeeRateQueryQuery, SingleEmployeeRateQueryQueryVariables>(SingleEmployeeRateQueryDocument, options);
        }
export type SingleEmployeeRateQueryQueryHookResult = ReturnType<typeof useSingleEmployeeRateQueryQuery>;
export type SingleEmployeeRateQueryLazyQueryHookResult = ReturnType<typeof useSingleEmployeeRateQueryLazyQuery>;
export type SingleEmployeeRateQueryQueryResult = Apollo.QueryResult<SingleEmployeeRateQueryQuery, SingleEmployeeRateQueryQueryVariables>;