import React, { useEffect, useState } from "react";
import { useCrewCodes, useInvalidateCrewCodes } from "~/refdata2/crewCodes";
import { CrewCodesFilter } from "./types";
import { Table } from "~/table";
import { CrewCodeRecord } from "~/gql/types";
import { FilterForm } from "./FilterForm";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";
import { SearchFilterContainer } from "~/search-filter-container";
import { TableRow, TableCell } from "~/table";
import Card from "@material/react-card";
import "./CrewCodesSearch.scss";
import { UpdateCrewCodeActive } from "./UpdateCrewCodeActive";
import _ from "lodash";

export type FilterCrewCodesProps = {
  setItems: React.Dispatch<CrewCodeRecord[]>;
  setLoading: React.Dispatch<boolean>;
  filters: CrewCodesFilter;
};

export const FilterCrewCodes: React.FC<FilterCrewCodesProps> = (props) => {
  const { setLoading, setItems, filters } = props;
  const stateFilter = filters.state;
  const employeeFilter = filters.employee;
  const lcText = filters.searchText?.toLowerCase() || null;

  const allCrewCodes = useCrewCodes();

  useEffect(() => {
    if (allCrewCodes) {
      const filtered = allCrewCodes
        .filter(
          (x) =>
            !stateFilter ||
            x.state!.toLowerCase().startsWith(stateFilter.toLowerCase())
        )
        .filter(
          (x) =>
            !employeeFilter ||
            x.employee?.userPrincipalName === employeeFilter.userPrincipalName
        )
        .filter(
          (x) =>
            !lcText ||
            x.crewCode.toLowerCase().startsWith(lcText) ||
            x.employee?.firstName?.toLowerCase()?.startsWith(lcText) ||
            x.employee?.lastName?.toLowerCase()?.startsWith(lcText)
        );

      setItems(_.orderBy(filtered, (x) => x.crewCode));
      setLoading(false);
    }
  }, [allCrewCodes, filters]);

  return <></>;
};

type CrewCodesDisplayProps = {
  loading: boolean;
  items: CrewCodeRecord[];
};

const CrewCodesDisplay: React.FC<CrewCodesDisplayProps> = (props) => {
  const { loading, items } = props;

  const refresh = useInvalidateCrewCodes();

  return (
    <SearchListContent
      {...{
        loading,
      }}
    >
      <Card className="crew-codes-search-table">
        <Table
          {...{
            columnCount: 5,
            columnWidths: "1fr 2fr .5fr .5fr .5fr",
          }}
        >
          <TableRow header>
            <TableCell text="Crew Code" />
            <TableCell text="Employee" />
            <TableCell text="State" />
            <TableCell text="Is Active" />
            <TableCell />
          </TableRow>
          {items.map((c, i) => (
            <TableRow key={i}>
              <TableCell text={c.crewCode} />
              <TableCell
                text={
                  c.employee
                    ? `${c.employee.firstName} ${c.employee.lastName}`
                    : ""
                }
              />
              <TableCell text={c.state} />
              <TableCell text={c.isActive ? "Yes" : "No"} />
              <TableCell>
                <UpdateCrewCodeActive {...{ crewCode: c, refresh }} />
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Card>
    </SearchListContent>
  );
};

export type CrewCodesResultsProps = {
  filters: CrewCodesFilter;
};

export const CrewCodesResults: React.FC<CrewCodesResultsProps> = (props) => {
  const { filters } = props;

  const [items, setItems] = useState<CrewCodeRecord[]>([]);
  const [loading, setLoading] = useState(true);

  const loadProps = {
    setItems,
    filters,
    setLoading,
  };

  return (
    <>
      <FilterCrewCodes {...loadProps} />
      <CrewCodesDisplay {...{ items, loading }} />
    </>
  );
};

export type CrewCodesSearchProps = {
  FilterFormComponent?: typeof FilterForm;
};

export const CrewCodesSearch: React.FC<CrewCodesSearchProps> = (props) => {
  const [filters, setFilters] = useState<CrewCodesFilter | null>(null);

  const setFilterOptions = (opts: CrewCodesFilter) => setFilters(opts);

  const FilterComponent = props.FilterFormComponent ?? FilterForm;

  return (
    <SearchPageWrapper withSort>
      <SearchFilterContainer>
        <FilterComponent onFiltersChanged={setFilterOptions} />
      </SearchFilterContainer>
      {filters && <CrewCodesResults {...{ filters }} />}
    </SearchPageWrapper>
  );
};
