import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TaskRatesAssignRateSheetToProjectMutationVariables = Types.Exact<{
  projectNumbers?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
  rateSheetName: Types.Scalars['String'];
  effectiveDate: Types.Scalars['LocalDate'];
}>;


export type TaskRatesAssignRateSheetToProjectMutation = { __typename?: 'MutationModel', taskRates?: { __typename?: 'TaskRatesMutationModel', assignRateSheetToProject?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const TaskRatesAssignRateSheetToProjectDocument = gql`
    mutation TaskRatesAssignRateSheetToProject($projectNumbers: [Int!], $rateSheetName: String!, $effectiveDate: LocalDate!) {
  taskRates {
    assignRateSheetToProject(
      projectNumbers: $projectNumbers
      rateSheetName: $rateSheetName
      effectiveDate: $effectiveDate
    ) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type TaskRatesAssignRateSheetToProjectMutationFn = Apollo.MutationFunction<TaskRatesAssignRateSheetToProjectMutation, TaskRatesAssignRateSheetToProjectMutationVariables>;

/**
 * __useTaskRatesAssignRateSheetToProjectMutation__
 *
 * To run a mutation, you first call `useTaskRatesAssignRateSheetToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskRatesAssignRateSheetToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskRatesAssignRateSheetToProjectMutation, { data, loading, error }] = useTaskRatesAssignRateSheetToProjectMutation({
 *   variables: {
 *      projectNumbers: // value for 'projectNumbers'
 *      rateSheetName: // value for 'rateSheetName'
 *      effectiveDate: // value for 'effectiveDate'
 *   },
 * });
 */
export function useTaskRatesAssignRateSheetToProjectMutation(baseOptions?: Apollo.MutationHookOptions<TaskRatesAssignRateSheetToProjectMutation, TaskRatesAssignRateSheetToProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskRatesAssignRateSheetToProjectMutation, TaskRatesAssignRateSheetToProjectMutationVariables>(TaskRatesAssignRateSheetToProjectDocument, options);
      }
export type TaskRatesAssignRateSheetToProjectMutationHookResult = ReturnType<typeof useTaskRatesAssignRateSheetToProjectMutation>;
export type TaskRatesAssignRateSheetToProjectMutationResult = Apollo.MutationResult<TaskRatesAssignRateSheetToProjectMutation>;
export type TaskRatesAssignRateSheetToProjectMutationOptions = Apollo.BaseMutationOptions<TaskRatesAssignRateSheetToProjectMutation, TaskRatesAssignRateSheetToProjectMutationVariables>;