import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkEditProjectOfficeCodeMutationVariables = Types.Exact<{
  officeCode: Types.Scalars['String'];
  projectNumbers: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type BulkEditProjectOfficeCodeMutation = { __typename?: 'MutationModel', projects?: { __typename?: 'ProjectsMutationModel', editOfficeCode?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const BulkEditProjectOfficeCodeDocument = gql`
    mutation BulkEditProjectOfficeCode($officeCode: String!, $projectNumbers: [Int!]!) {
  projects {
    editOfficeCode(officeCode: $officeCode, projectNumbers: $projectNumbers) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type BulkEditProjectOfficeCodeMutationFn = Apollo.MutationFunction<BulkEditProjectOfficeCodeMutation, BulkEditProjectOfficeCodeMutationVariables>;

/**
 * __useBulkEditProjectOfficeCodeMutation__
 *
 * To run a mutation, you first call `useBulkEditProjectOfficeCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditProjectOfficeCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditProjectOfficeCodeMutation, { data, loading, error }] = useBulkEditProjectOfficeCodeMutation({
 *   variables: {
 *      officeCode: // value for 'officeCode'
 *      projectNumbers: // value for 'projectNumbers'
 *   },
 * });
 */
export function useBulkEditProjectOfficeCodeMutation(baseOptions?: Apollo.MutationHookOptions<BulkEditProjectOfficeCodeMutation, BulkEditProjectOfficeCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkEditProjectOfficeCodeMutation, BulkEditProjectOfficeCodeMutationVariables>(BulkEditProjectOfficeCodeDocument, options);
      }
export type BulkEditProjectOfficeCodeMutationHookResult = ReturnType<typeof useBulkEditProjectOfficeCodeMutation>;
export type BulkEditProjectOfficeCodeMutationResult = Apollo.MutationResult<BulkEditProjectOfficeCodeMutation>;
export type BulkEditProjectOfficeCodeMutationOptions = Apollo.BaseMutationOptions<BulkEditProjectOfficeCodeMutation, BulkEditProjectOfficeCodeMutationVariables>;