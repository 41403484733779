import React, { useState } from "react";
import { OverheadCrewCodeForm } from "./OverheadCrewCodeForm";
import Button from "~/button";
import { useUpdateOverheadCrewCode } from "./useUpdateOverheadCrewCode";
import "./AddOverheadCrewCode.scss";

type AddOverheadCrewCodeProps = {
  refetch: React.DispatchWithoutAction;
  itemKey: "fieldOffice" | "state";
  FormField: React.ReactElement;
};

export const AddOverheadCrewCode: React.FC<AddOverheadCrewCodeProps> = (
  props
) => {
  const { refetch, itemKey, FormField } = props;
  const [open, setOpen] = useState(false);

  const itemType = itemKey === "fieldOffice" ? "office" : "state";

  const valueSelector = (values: Record<string, any>) =>
    itemType === "office" ? values.fieldOffice.officeCode : values.state;

  const initialValues = {
    [itemKey]: null,
    crewCode: null,
  };

  const { loading, handleSubmit, onSuccess } = useUpdateOverheadCrewCode({
    type: "added",
    itemKey,
    refetch,
    valueSelector,
    setOpen,
  });

  const uniqueClass = `add-overhead-crewcode add-${itemType}-overhead`;

  return (
    <div className={uniqueClass}>
      <Button
        className="add-overhead-button"
        icon="add"
        onClick={() => setOpen(true)}
      >
        Add
      </Button>
      <OverheadCrewCodeForm
        {...{
          handleSubmit,
          initialValues,
          title: "Add Overhead Crew Code",
          loading,
          open,
          setOpen,
          onSuccess,
        }}
      >
        {FormField}
      </OverheadCrewCodeForm>
    </div>
  );
};
