import React from "react";
import ProjectExpenseForm from "~/visuals/organisms/Expenses/ExpenseForm/ProjectExpenseForm";
import { Route, Routes } from "react-router-dom";
import { expenseRoutes } from "~/WorkData/routes";
import {
  BaseExpenseFormProps,
  RouterProps,
} from "./ExpenseForm/ExpensePageWrapper";
import { Expense } from "~/WorkDayApi/expenseTypes";
import { ExpenseFormRouter } from "./ExpenseFormRouter";

export type PageRoutesProps = BaseExpenseFormProps & {
  expenseEntry: Expense;
};

export type ExpenseRouterProps = RouterProps & { expenseEntry?: Expense };

const PageRoutes: React.FC<PageRoutesProps> = (props) => {
  const { expenseEntry, formType } = props;
  const {
    atv,
    adHoc,
    fuel,
    mileage,
    mobileAllowance,
    perDiem,
    receiptValued,
    tcpAllowance,
  } = expenseRoutes;

  return (
    <Routes>
      <Route
        path={receiptValued}
        element={
          <ExpenseFormRouter {...{ ...props, expenseType: "Receipt Valued" }} />
        }
      />
      <Route
        path={adHoc}
        element={<ExpenseFormRouter {...{ ...props, expenseType: "AdHoc" }} />}
      />
      <Route
        path={atv}
        element={<ExpenseFormRouter {...{ ...props, expenseType: "ATV" }} />}
      />
      <Route
        path={tcpAllowance}
        element={
          <ExpenseFormRouter {...{ ...props, expenseType: "TCP Allowance" }} />
        }
      />
      <Route
        path={perDiem}
        element={
          <ExpenseFormRouter {...{ ...props, expenseType: "Per Diem" }} />
        }
      />
      <Route
        path={mobileAllowance}
        element={
          <ExpenseFormRouter
            {...{ ...props, expenseType: "Mobile Allowance" }}
          />
        }
      />
      <Route
        path={mileage}
        element={
          <ExpenseFormRouter {...{ ...props, expenseType: "Mileage" }} />
        }
      />
      <Route
        path={fuel}
        element={<ExpenseFormRouter {...{ ...props, expenseType: "Fuel" }} />}
      />
      <Route
        path={"*"}
        element={
          <ProjectExpenseForm
            {...{ ...props }}
            type={formType}
            expenseEntry={expenseEntry}
          />
        }
      />
    </Routes>
  );
};

export const ExpenseRouter: React.FC<ExpenseRouterProps> = (props) => {
  const { project, expenseRate, expenseEntry, formType } = props;

  if (project === null || expenseRate === null) {
    return (
      <Routes>
        <Route
          path={"*"}
          element={
            <ProjectExpenseForm
              {...{ ...props }}
              type={formType}
              expenseEntry={expenseEntry}
            />
          }
        />
      </Routes>
    );
  }

  return <PageRoutes {...(props as PageRoutesProps)} />;
};
