import React, { useContext, useState } from "react";
import { useSnackBar } from "~/snackbar";
import Button from "~/button";
import Spinner from "~/spinner";
import { useWorkDayApi } from "~/WorkDayApi";
import moment from "moment";
import { InlineProgress } from "~/inline-progress";
import { usePreviousWorkDayQuery } from "./prevWorkDay.generated";
import { useCopyWorkDayMutation } from "./copyWorkDay.generated";
import ClickSwallower from "~/click-swallower";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import FormBottomRow from "~/form-bottom-row";
import BinaryPickerFormField from "../BinaryPickerFormField";
import { ListCheckBoxWrapper } from "./ListCheckBoxWrapper";
import { WorkDayContext } from "~/WorkData/WorkDayRouter/WorkDataContext";
import { PrevWorkDay, ValidationResponse } from "~/gql/types";

type CopyWorkDayProps = {
  hasData: boolean;
};

type CopyWorkdayFormValues = {
  includeExpenses: boolean;
  includeProjectTime: boolean;
  includeTimeEntries: boolean;
  includeTimeOff: boolean;
};

export const CopyWorkDay: React.FC<CopyWorkDayProps> = ({ hasData }) => {
  const { workDay } = useWorkDayApi();

  const variables = {
    userPrincipalName: workDay.employee!.userPrincipalName,
    date: workDay.date,
  };

  const { data, loading } = usePreviousWorkDayQuery({ variables });

  const result = data?.timesheets?.prevWorkDay;

  if (!result) {
    return loading ? <InlineProgress /> : null;
  }

  return (
    <CopyWorkDayDialog
      {...{
        result,
        loading,
        hasData,
      }}
    />
  );
};

type CopyWorkDayComponentProps = {
  result: PrevWorkDay;
  loading: boolean;
  hasData: boolean;
};

const CopyWorkDayDialog: React.FC<CopyWorkDayComponentProps> = (props) => {
  const { result, loading, hasData } = props;

  const [open, setOpen] = useState(false);

  const idResult = result.id;
  const dateResult = result.date;

  const addAlert = useSnackBar();

  const { workDay } = useWorkDayApi();

  const context = useContext(WorkDayContext);

  if (!context) {
    throw new Error("You must use CopyWorkDay inside a WorkDayContextProvider");
  }

  const reload = context.reload;

  const formattedDate = moment(dateResult).format("MM/DD/YYYY");

  const [doMutation, { loading: copyLoading }] = useCopyWorkDayMutation();

  const onSuccess = () => {
    addAlert({
      key: workDay.date,
      message: `Workday ${moment(workDay.date).format(
        "MM/DD/YYYY"
      )} copied from ${formattedDate}`,
      isSuccess: true,
    });
    void reload();
    setOpen(false);
  };

  const copyWorkDay = async (values: CopyWorkdayFormValues) => {
    const variables = {
      idToCopy: idResult!,
      id: workDay.workDayId,
      ...values,
    };

    const response = await doMutation({ variables });

    return response?.data?.timesheets?.copyWorkDay as ValidationResponse;
  };

  const ptCount = result.projectTimeCount;
  const expCount = result.expenseCount;
  const teCount = result.timeEntryCount;
  const toCount = result.timeOffCount;

  const projectTimeText = `Include ${ptCount} project time entr${
    ptCount === 1 ? "y" : "ies"
  }`;
  const expenseText = `Include ${expCount} expense${expCount === 1 ? "" : "s"}`;
  const timeEntryText = `Include ${teCount} time entr${
    teCount === 1 ? "y" : "ies"
  }`;
  const timeOffText = `Include ${toCount} time off entr${
    toCount === 1 ? "y" : "ies"
  }`;

  const initialValues: CopyWorkdayFormValues = {
    includeExpenses: expCount > 0,
    includeProjectTime: ptCount > 0,
    includeTimeEntries: teCount > 0,
    includeTimeOff: toCount > 0,
  };

  return (
    <>
      {!hasData && idResult && (
        <Button onClick={() => setOpen(true)} outlined>
          Copy Previous Workday
        </Button>
      )}
      <ClickSwallower>
        <Dialog
          className="copy-workday-dialog"
          open={open}
          onClose={() => setOpen(false)}
          data-open={open}
        >
          <DialogTitle>{`Copy Workday ${formattedDate}`}</DialogTitle>
          <DialogContent>
            {open && (
              <MutationForm
                onSuccess={onSuccess}
                runMutation={copyWorkDay}
                initialValues={initialValues}
                allowPristineSubmit={true}
              >
                <ErrorRenderer
                  render={(error) => (
                    <ListCheckBoxWrapper>
                      <CopyPicker
                        count={ptCount}
                        formField={"includeProjectTime"}
                        label={projectTimeText}
                      />
                      <CopyPicker
                        count={expCount}
                        formField={"includeExpenses"}
                        label={expenseText}
                      />
                      <CopyPicker
                        count={teCount}
                        formField={"includeTimeEntries"}
                        label={timeEntryText}
                      />
                      <CopyPicker
                        count={toCount}
                        formField={"includeTimeOff"}
                        label={timeOffText}
                      />

                      <FormBottomRow
                        errorMessage={error}
                        buttonText="Copy"
                        onCancel={() => setOpen(false)}
                      />
                    </ListCheckBoxWrapper>
                  )}
                />
              </MutationForm>
            )}
          </DialogContent>
        </Dialog>
        <Spinner open={loading || copyLoading} />
      </ClickSwallower>
    </>
  );
};

type CopyPickerProps = {
  count: number;
  formField: keyof CopyWorkdayFormValues;
  label: string;
};

const CopyPicker: React.FC<CopyPickerProps> = (props) => {
  const { count, formField, label } = props;

  if (count === 0) {
    return null;
  }

  return (
    <BinaryPickerFormField
      formField={formField}
      label={label}
      helperText={""}
    />
  );
};
