import React, { useState, useEffect } from "react";
import { InlineTextField } from "~/inline-text-field";
import { useInlineFormField } from "~/inline-fields-form";
import { parseDate } from "./dateParser";

export type InlineDatePickerProps = {
  name: string;
  onBlur?: React.FocusEventHandler<any>;
  required?: boolean;
  disabled?: boolean;
};
export const InlineDatePicker: React.FC<InlineDatePickerProps> = ({
  name,
  onBlur,
  required,
  disabled,
}) => {
  const { value, error, setError, updateValue } = useInlineFormField(name);

  const initialValue = value ? value.format("M/D/YYYY") : "";

  const [inputValue, setInputValue] = useState<string>(initialValue);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setInputValue(val);
    setError(name, "");
  };

  useEffect(() => {
    setInputValue(value ? value.format("M/D/YYYY") : null);
  }, [value]);

  const onBlurred = (e) => {
    if (!inputValue) {
      if (value) {
        updateValue(name, null);
      }
      if (required) {
        setError(name, "Required");
      }
    } else {
      const result = parseDate(inputValue);

      if (
        result &&
        result.format("YYYY-MM-DD") !== value?.format("YYYY-MM-DD")
      ) {
        updateValue(name, result);
      }
      if (!result) {
        setError(name, "Please provide a valid date with format m/d/yyyy");
      }
    }
    onBlur && onBlur(e);
  };

  return (
    <InlineTextField
      {...{
        disabled,
        name,
        value: inputValue ?? "",
        onChange: onInputChange,
        onBlur: onBlurred,
        errorMessage: error ?? "",
        required,
      }}
    />
  );
};
