import { createContext, useContext, useState } from "react";
import React from "react";
import { TimesheetMode } from "./types";
import { Employee } from "~/gql/types";

export type TimesheetContextType = {
  mode: TimesheetMode;
  employee: Employee | null;
  setEmployee: React.Dispatch<Employee | null>;
};

export const TimesheetContext = createContext<TimesheetContextType>({
  mode: "MyTimesheets",
  employee: null,
  setEmployee: () => null,
});

export const TimesheetContextProvider: React.FC<{ mode: TimesheetMode }> = ({
  mode,
  children,
}) => {
  const [employee, setEmployee] = useState<Employee | null>(null);

  return (
    <TimesheetContext.Provider value={{ mode, employee, setEmployee }}>
      {children}
    </TimesheetContext.Provider>
  );
};

export function useTimesheetContext() {
  return useContext(TimesheetContext);
}
