import React, { useEffect, useMemo } from "react";
import "./ExpenseForm.scss";
import Spinner from "~/spinner";
import FormBottomRow from "~/form-bottom-row";
import FormCard from "~/form-card";
import Updater from "~/forms/Updater";
import ExpenseTitle from "../ExpenseTitle";
import { useWorkDayApi } from "~/WorkDayApi";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { useRelativeNav } from "~/relative-nav";
import { expenseRoutes } from "~/WorkData/routes";
import ProjectPickerFormField from "~/visuals/organisms/ProjectPickerFormField";
import { InactiveProjectWarning } from "~/visuals/organisms/InactiveProjectWarning";
import { IExpenseRate, Project } from "~/gql/types";
import { Expense, ExpenseRate } from "~/WorkDayApi/expenseTypes";
import { ExpenseFormType } from "./CommonTypes";
import { ExpensePickerField } from "./ExpensePickerField";
import { ClientForm } from "~/forms/ClientForm";
import { ValueRenderer } from "~/forms/ValueContext";
import { ErrorRenderer } from "~/forms/MutationForm";

type ProjectExpenseProps = {
  date: string;
  expenseRate: IExpenseRate | null;
  expenseRates: IExpenseRate[] | null;
  loading: boolean;
  project: Project | null;
  setProject: React.Dispatch<Project | null>;
  setExpenseRate: React.Dispatch<IExpenseRate | null>;
  type: ExpenseFormType;
  expenseEntry?: Expense;
  rateSheetName?: string;
};

type FormData = {
  expense: IExpenseRate | null;
  project: Project | null;
};

export const getPathAdjustment = (pathname: string): string =>
  Object.values(expenseRoutes).some((x) => pathname.includes(x)) ? "../" : "";

const ProjectExpenseForm: React.FC<ProjectExpenseProps> = (props) => {
  const navigate = useRelativeNav();

  const {
    date,
    expenseRate,
    expenseRates,
    loading,
    project,
    setProject,
    setExpenseRate,
    type,
    expenseEntry,
    rateSheetName,
  } = props;

  const {
    atv,
    adHoc,
    fuel,
    mileage,
    mobileAllowance,
    perDiem,
    receiptValued,
    tcpAllowance,
  } = expenseRoutes;

  const { breadcrumbs } = useWorkDayApi();

  const initialFormValues = useMemo(
    () => ({
      project: project,
      expense: project ? expenseRate : null,
    }),
    []
  );

  const pathAdjustment = getPathAdjustment(location.pathname);

  const handleSubmit = (values: FormData) => {
    if (values.project && values.expense) {
      const rate = values.expense;
      setProject(values.project);
      setExpenseRate(rate);
      const route = formRoutes[(rate as ExpenseRate).__typename];

      navigate(`${pathAdjustment}${route}`);
    }
  };

  const formRoutes = {
    AtvExpenseRate: atv,
    AdHocExpenseRate: adHoc,
    FuelExpenseRate: fuel,
    MileageExpenseRate: mileage,
    MobileAllowanceExpenseRate: mobileAllowance,
    PerDiemExpenseRate: perDiem,
    ReceiptValuedExpenseRate: receiptValued,
    TcpAllowanceExpenseRate: tcpAllowance,
  };

  const titleMap = {
    Create: "Create New Expense",
    Edit: "Edit Expense",
    Clone: "Clone Expense",
  };

  useBreadcrumbs([...breadcrumbs, { text: titleMap[type] }], [breadcrumbs]);

  useEffect(() => {
    if (
      project &&
      !expenseRate &&
      expenseRates &&
      expenseRates?.length > 0 &&
      rateSheetName
    ) {
      const expensePicker = document.querySelector(
        ".expense-picker input"
      ) as HTMLInputElement;
      expensePicker!.focus();
    }
  }, [project, expenseRates, rateSheetName]);

  const cancelPath = pathAdjustment + (type === "Create" ? "../" : "../../");

  return (
    <ClientForm
      onSuccess={handleSubmit}
      initialValues={initialFormValues}
      allowPristineSubmit={true}
    >
      <ValueRenderer
        render={(values) => (
          <>
            <div className="expense-entry project-expense-form">
              <ExpenseTitle
                {...{ type }}
                expenseId={expenseEntry?.id}
                typeName="Expense Entry"
              />
              <Spinner open={loading} />
              <FormCard>
                <ProjectPickerFormField
                  formField={"project"}
                  label={"Project"}
                  helperText={""}
                  date={date}
                  required
                />
                <ExpensePickerField
                  {...{ ...props, projectNumber: project?.number ?? null }}
                />

                <InactiveProjectWarning {...{ project: values.project }} />

                <ErrorRenderer
                  render={(submitError) => (
                    <FormBottomRow
                      errorMessage={submitError}
                      buttonText="Next"
                      onCancel={() => navigate(cancelPath)}
                    />
                  )}
                />
              </FormCard>
            </div>
            <Updater
              value={values.project}
              callback={(val) => {
                setProject(val);
              }}
            />
          </>
        )}
      />
    </ClientForm>
  );
};

export default ProjectExpenseForm;
