import React from "react";
import DurationPickFormField from "../DurationPickFormField";
import { CrewCodePickerFormField } from "../CrewCodePickerFormField";
import EnumDropdownFormField from "~/visuals/organisms/EnumDropdownFormField";
import moment from "moment";
import "./TimeOffEntryForm.scss";
import Spinner from "~/spinner";
import FormBottomRow from "~/form-bottom-row";
import FormCard from "~/form-card";
import TitleDeleteOption from "~/title-delete-option";
import { AdminNotes } from "../AdminNotes";
import { useRelativeNav } from "~/relative-nav";
import { SaveAndHandler } from "~/WorkDayApi/SaveAndHandler";

export type TimeOffEntryFormProps = {
  type: "Create" | "Edit";
  errorMessage: string | null;
  date: string;
  loading: boolean;
  onDelete?: () => void;
};

const TimeOffEntryForm: React.FC<TimeOffEntryFormProps> = ({
  type,
  errorMessage,
  loading,
  onDelete,
}) => {
  const nav = useRelativeNav();
  const cancelPath = type === "Edit" ? "../../" : "../";

  return (
    <div className="time-off-entry">
      <TitleDeleteOption
        title={`${type === "Edit" ? "Edit" : "Create New"} Time Off Entry`}
        onDelete={onDelete}
      />
      <Spinner open={loading} />
      <FormCard>
        <DurationPickFormField
          formField="hours"
          label="Hours"
          helperText="The hours taken off."
          required
          maxTime={moment.duration("08:00")}
          minTime={moment.duration("00:00")}
        />
        <CrewCodePickerFormField
          label="Crew Code"
          formField="crewCode"
          helperText="The crew code applicable to this work. (use up/down arrows and enter key to select suggestions)"
          required
        />
        <EnumDropdownFormField
          label="Type"
          helperText="The type of time off taken. (use up/down arrows and enter key to select suggestions)"
          formField="timeOffType"
          enumType="TimeOffType"
          required
        />
        <AdminNotes />
        <FormBottomRow
          errorMessage={errorMessage}
          buttonText={type === "Create" ? "Create" : "Save"}
          onCancel={() => nav(cancelPath)}
        >
          {type !== "Edit" && <SaveAndHandler includeClone={false} />}
        </FormBottomRow>
      </FormCard>
    </div>
  );
};

export default TimeOffEntryForm;
