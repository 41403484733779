import React from "react";
import Button from "~/button";
import { useConfirmationPrompt } from "~/confirmation-prompt";
import { ValidationError } from "~/gql/types";
import { useSnackBar } from "~/snackbar";
import Spinner from "~/spinner";

type BillInvoiceType = "Invoice" | "Custom Rollup Invoice";

type BillInvoiceProps = {
  invoiceNumber: string;
  bill: () => Promise<ValidationError[]>;
  loading: boolean;
  type: BillInvoiceType;
  reload: React.DispatchWithoutAction;
};

export const BillInvoice: React.FC<BillInvoiceProps> = (props) => {
  const { invoiceNumber, bill, loading, type, reload } = props;

  const addAlert = useSnackBar();

  const prompt = useConfirmationPrompt();

  const billInvoice = async () => {
    const errors = await bill();

    if (errors.length > 0) {
      const formErrors: string[] = [];
      errors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          formErrors.push(`${argumentName}: ${message}`);
        } else if (message) {
          formErrors.push(message);
        }
      });

      addAlert({
        key: `${invoiceNumber}`,
        message: formErrors.join(" "),
        isSuccess: false,
      });
    } else {
      addAlert({
        key: `${invoiceNumber}`,
        message: `${type} ${invoiceNumber} billed`,
        isSuccess: true,
      });

      reload();
    }
  };

  const openPrompt = async () => {
    const promptResult = await prompt({
      cancelButtonText: "Don't bill",
      confirmButtonText: "Yes, bill",
      message: `Are you sure you want to set this ${type.toLowerCase()} to Billed?`,
      title: `Bill ${type} ${invoiceNumber}`,
    });

    if (promptResult !== "Confirm") {
      return;
    }

    await billInvoice();
  };

  return (
    <>
      <Button primary onClick={openPrompt}>
        Bill
      </Button>
      <Spinner open={loading} />
    </>
  );
};
