import React, { useMemo } from "react";
import { Validating } from "./Validating";
import { AuthenticationManagerInnerProps } from "./common";
import { pkceVerifierKey } from "../authClient";
import pkceChallenge from "pkce-challenge";
import { Action } from "./stateMachine";
import { Unauthenticated } from "./Unauthenticated";
import { Authorizations } from "../types";

export type AuthenticationManagerContextData = {
  jwt: string | null;
  authorizations: Authorizations | null;
  dispatch: React.Dispatch<Action>;
};
type AuthenticationManagerDisplayProps = Omit<
  AuthenticationManagerInnerProps,
  "getAuthorizations"
>;

export const AuthenticationManagerContext =
  React.createContext<AuthenticationManagerContextData | null>(null);

type UnauthDisplayProps = AuthenticationManagerDisplayProps & {
  problem?: boolean;
};
const UnauthDisplay: React.FC<UnauthDisplayProps> = ({
  createAzureAuth,
  createGoogleAuth,
  problem,
}) => {
  const doRedirect = (auth) => {
    const { code_challenge, code_verifier } = pkceChallenge();
    sessionStorage.setItem(pkceVerifierKey, code_verifier);
    const uri = auth.code.getUri({
      query: {
        code_challenge: code_challenge,
        code_challenge_method: "S256",
      },
      state: window.location.pathname,
    });
    window.location.assign(uri);
  };

  const doGoogleRedirect = () => doRedirect(createGoogleAuth());
  const doAzureRedirect = () => doRedirect(createAzureAuth());

  return (
    <>
      <Unauthenticated {...{ doGoogleRedirect, doAzureRedirect, problem }} />
    </>
  );
};

export const AuthenticationManagerDisplay: React.FC<
  AuthenticationManagerDisplayProps
> = ({ createAzureAuth, createGoogleAuth, state, dispatch, children }) => {
  const contextValue: AuthenticationManagerContextData = useMemo(() => {
    if (state.tag === "Authenticated") {
      return {
        jwt: state.token,
        authorizations: state.authorizations,
        dispatch,
      };
    }

    return { jwt: null, authorizations: null, dispatch };
  }, [state]);
  if (state.tag === "Authenticated") {
    return (
      <AuthenticationManagerContext.Provider value={contextValue}>
        {children}
      </AuthenticationManagerContext.Provider>
    );
  }

  if (state.tag === "ValidatingGoogle") {
    return <Validating />;
  }
  if (state.tag === "ValidatingAzure") {
    return <Validating />;
  }
  if (state.tag === "ValidatingLocal") {
    return <Validating />;
  }

  if (state.tag === "Unauthenticated") {
    return (
      <UnauthDisplay
        {...{ createAzureAuth, createGoogleAuth, state, dispatch }}
      />
    );
  }

  if (state.tag === "Error") {
    return (
      <UnauthDisplay
        {...{ createAzureAuth, createGoogleAuth, state, dispatch }}
        problem
      />
    );
  }

  return null;
};
