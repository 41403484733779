import { FilterProvider, Suggestion } from "~/ultra-filter/types";
import React, { ReactNode } from "react";
import { FilterProviderProps } from "./types";
import {
  invoiceStates,
  InvoiceStatePicker,
} from "~/filterProviders/InvoiceStatePickerFormField";
import {
  chargeStates,
  ProjectChargeStatePicker,
} from "~/filterProviders/ProjectChargeStatePickerFormField";
import { createProvider } from "./createFilterProvider";
import {
  ThirdPartyInvoiceStatePicker,
  thirdPartyInvoiceStates,
} from "./ThirdPartyInvoiceStatePicker";

type CreateItemStateProviderArgs = FilterProviderProps & {
  label: string;
  type: string;
  suggestions: Suggestion[];
  render: () => ReactNode;
};

const createItemStateProvider = (
  props: CreateItemStateProviderArgs
): FilterProvider => {
  const { type, label, suggestions, render } = props;
  const itemFromParam = (arg: string) =>
    Promise.resolve(suggestions.find((x) => x.value === arg)?.value ?? null);

  return createProvider({
    render,
    type,
    label,
    props,
    getItems: () => Promise.resolve(suggestions.map((s) => s.value)),
    itemFromParam,
    shouldSuggestText: "state",
    toSuggestions: (result, text) =>
      (result as string[])
        .filter((s) => s.toLowerCase().startsWith(text.toLowerCase()))
        .map((s) => ({
          text: suggestions.find((x) => x.value === s)?.text ?? "",
          value: s,
        })),
    toLabelValue: (value: string) =>
      suggestions.find((x) => x.value === value)?.text ?? "",
    toParamValue: (value: string) => value,
  });
};

export function useInvoiceStateProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText } = props;
  return createItemStateProvider({
    documentationText,
    type: "invoiceState",
    label: "State",
    suggestions: invoiceStates,
    render: () => (
      <InvoiceStatePicker
        formField="invoiceState"
        label="Invoice State"
        helperText=""
      />
    ),
  });
}

export function useChargeStateProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText } = props;
  return createItemStateProvider({
    documentationText,
    type: "chargeState",
    label: "State",
    suggestions: chargeStates,
    render: () => (
      <ProjectChargeStatePicker
        formField="chargeState"
        label="Charge State"
        helperText=""
      />
    ),
  });
}

export function useThirdPartyInvoiceStateProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText } = props;
  return createItemStateProvider({
    documentationText,
    type: "thirdPartyInvoiceState",
    label: "State",
    suggestions: thirdPartyInvoiceStates,
    render: () => (
      <ThirdPartyInvoiceStatePicker
        formField="thirdPartyInvoiceState"
        label="Third Party Invoice State"
        helperText=""
      />
    ),
  });
}
