import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { VisibilityListener } from "~/visibility-listener";

type ContextType = {
  scrollTo: string | null;
  setScrollTo: React.Dispatch<string | null>;
  isVisible: boolean;
  setIsVisible: React.Dispatch<boolean>;
};

export const ScrollToContext = createContext<ContextType>({
  scrollTo: null,
  setScrollTo: () => undefined,
  isVisible: false,
  setIsVisible: () => undefined,
});

export const ScrollToProvider: React.FC = ({ children }) => {
  const [scrollTo, setScrollTo] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <ScrollToContext.Provider
      value={{ scrollTo, setScrollTo, isVisible, setIsVisible }}
    >
      {children}
    </ScrollToContext.Provider>
  );
};

export function useScrollTo(): ContextType {
  const context = useContext(ScrollToContext);

  if (!context) {
    throw new Error("You must use useScrollTo within a ScrollToContext");
  }

  return context;
}

type ScrollToProps = {
  search: string;
  value: string;
  mockNavigate?: (path: string, options: Record<string, any>) => void;
};

export const getPathWithSearch = (
  href: string,
  search: string,
  value: string
): string => {
  const split = href.split("?");
  const path = split[0];
  const currentSearch = split[1];

  const searchItems =
    currentSearch?.split("&")?.filter((x) => x && !x.includes(search)) ?? [];
  const newSearchItems = [
    ...searchItems,
    `${search}=${encodeURIComponent(value)}`,
  ].join("&");

  return `${path}?${newSearchItems}`;
};

export const ScrollTo: React.FC<ScrollToProps> = ({
  search,
  value,
  mockNavigate,
  children,
}) => {
  const [doSetParams, setDoSetParams] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const { scrollTo, isVisible, setIsVisible } = useScrollTo();
  const navigate = useNavigate();

  const doNavigate = mockNavigate ?? navigate;

  const canSetUrlParams = value !== scrollTo && !isVisible;

  useEffect(() => {
    if (!doSetParams && value == scrollTo) {
      setIsVisible(false);
      return;
    }

    if (!doSetParams || !canSetUrlParams) {
      return;
    }

    const href = location.href.replace(location.origin, "");
    const path = getPathWithSearch(href, search, value);

    void doNavigate(path, { replace: true });
  }, [doSetParams]);

  useEffect(() => {
    if (ref.current && scrollTo === value) {
      ref.current.scrollIntoView();
    }
  }, [ref, scrollTo, value, isVisible]);

  return (
    <div ref={ref}>
      {children}
      <VisibilityListener
        onShown={() => {
          setDoSetParams(true);
        }}
        onHide={() => {
          setDoSetParams(false);
        }}
      />
    </div>
  );
};

type MockProviderProps = {
  scrollTo?: string | null;
  setScrollTo?: React.Dispatch<string | null>;
  isVisible?: boolean;
  setIsVisible?: React.Dispatch<boolean>;
};

export const MockScrollToProvider: React.FC<MockProviderProps> = ({
  scrollTo,
  setScrollTo,
  isVisible,
  setIsVisible,
  children,
}) => {
  return (
    <ScrollToContext.Provider
      value={{
        scrollTo: scrollTo ?? null,
        setScrollTo: setScrollTo ?? (() => undefined),
        isVisible: isVisible ?? false,
        setIsVisible: setIsVisible ?? (() => undefined),
      }}
    >
      {children}
    </ScrollToContext.Provider>
  );
};
