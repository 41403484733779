import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAtvExpenseMutationVariables = Types.Exact<{
  ownerInformation: Types.OwnerInformation;
  date: Types.Scalars['LocalDate'];
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateAtvExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', createAtvExpense?: { __typename?: 'CreateExpenseResponse', expenseId?: string | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditAtvExpenseMutationVariables = Types.Exact<{
  ownerInformation: Types.OwnerInformation;
  expenseId: Types.Scalars['String'];
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
  payableOverride?: Types.InputMaybe<Types.Scalars['Decimal']>;
}>;


export type EditAtvExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', editAtvExpense?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CreateFuelExpenseMutationVariables = Types.Exact<{
  odometer: Types.Scalars['Decimal'];
  vehicleNumber: Types.Scalars['String'];
  quantity: Types.Scalars['Decimal'];
  receiptTotal: Types.Scalars['Decimal'];
  payerInformation: Types.PayerInformation;
  companyVehicle: Types.Scalars['Boolean'];
  receiptImageId?: Types.InputMaybe<Types.Scalars['String']>;
  date: Types.Scalars['LocalDate'];
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateFuelExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', createFuelExpense?: { __typename?: 'CreateExpenseResponse', expenseId?: string | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditFuelExpenseMutationVariables = Types.Exact<{
  odometer: Types.Scalars['Decimal'];
  vehicleNumber: Types.Scalars['String'];
  quantity: Types.Scalars['Decimal'];
  receiptTotal: Types.Scalars['Decimal'];
  payerInformation: Types.PayerInformation;
  companyVehicle: Types.Scalars['Boolean'];
  receiptImageId?: Types.InputMaybe<Types.Scalars['String']>;
  expenseId: Types.Scalars['String'];
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
  payableOverride?: Types.InputMaybe<Types.Scalars['Decimal']>;
}>;


export type EditFuelExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', editFuelExpense?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CreateAdHocExpenseMutationVariables = Types.Exact<{
  quantity: Types.Scalars['Decimal'];
  rateAmount?: Types.InputMaybe<Types.Scalars['Decimal']>;
  date: Types.Scalars['LocalDate'];
  receiptImageId?: Types.InputMaybe<Types.Scalars['String']>;
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateAdHocExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', createAdHocExpense?: { __typename?: 'CreateExpenseResponse', expenseId?: string | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditAdHocExpenseMutationVariables = Types.Exact<{
  quantity: Types.Scalars['Decimal'];
  rateAmount?: Types.InputMaybe<Types.Scalars['Decimal']>;
  receiptImageId?: Types.InputMaybe<Types.Scalars['String']>;
  expenseId: Types.Scalars['String'];
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
  payableOverride?: Types.InputMaybe<Types.Scalars['Decimal']>;
}>;


export type EditAdHocExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', editAdHocExpense?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CreateMileageExpenseMutationVariables = Types.Exact<{
  beginningOdometer: Types.Scalars['Decimal'];
  endingOdometer: Types.Scalars['Decimal'];
  vehicleNumber: Types.Scalars['String'];
  companyVehicle: Types.Scalars['Boolean'];
  date: Types.Scalars['LocalDate'];
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateMileageExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', createMileageExpense?: { __typename?: 'CreateExpenseResponse', expenseId?: string | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditMileageExpenseMutationVariables = Types.Exact<{
  beginningOdometer: Types.Scalars['Decimal'];
  endingOdometer: Types.Scalars['Decimal'];
  vehicleNumber: Types.Scalars['String'];
  companyVehicle: Types.Scalars['Boolean'];
  expenseId: Types.Scalars['String'];
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
  payableOverride?: Types.InputMaybe<Types.Scalars['Decimal']>;
}>;


export type EditMileageExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', editMileageExpense?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CreateMobileAllowanceExpenseMutationVariables = Types.Exact<{
  date: Types.Scalars['LocalDate'];
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateMobileAllowanceExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', createMobileAllowanceExpense?: { __typename?: 'CreateExpenseResponse', expenseId?: string | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditMobileAllowanceExpenseMutationVariables = Types.Exact<{
  expenseId: Types.Scalars['String'];
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
  payableOverride?: Types.InputMaybe<Types.Scalars['Decimal']>;
}>;


export type EditMobileAllowanceExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', editMobileAllowanceExpense?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CreateReceiptValuedExpenseMutationVariables = Types.Exact<{
  payerInformation: Types.PayerInformation;
  odometer?: Types.InputMaybe<Types.Scalars['Decimal']>;
  vehicleNumber?: Types.InputMaybe<Types.Scalars['String']>;
  receiptTotal: Types.Scalars['Decimal'];
  receiptImageId: Types.Scalars['String'];
  date: Types.Scalars['LocalDate'];
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes: Types.Scalars['String'];
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateReceiptValuedExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', createReceiptValuedExpense?: { __typename?: 'CreateExpenseResponse', expenseId?: string | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditReceiptValuedExpenseMutationVariables = Types.Exact<{
  payerInformation: Types.PayerInformation;
  odometer?: Types.InputMaybe<Types.Scalars['Decimal']>;
  vehicleNumber?: Types.InputMaybe<Types.Scalars['String']>;
  receiptTotal: Types.Scalars['Decimal'];
  receiptImageId: Types.Scalars['String'];
  expenseId: Types.Scalars['String'];
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes: Types.Scalars['String'];
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
  payableOverride?: Types.InputMaybe<Types.Scalars['Decimal']>;
}>;


export type EditReceiptValuedExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', editReceiptValuedExpense?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CreatePerDiemExpenseMutationVariables = Types.Exact<{
  date: Types.Scalars['LocalDate'];
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
  receiptImageId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreatePerDiemExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', createPerDiemExpense?: { __typename?: 'CreateExpenseResponse', expenseId?: string | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditPerDiemExpenseMutationVariables = Types.Exact<{
  expenseId: Types.Scalars['String'];
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
  receiptImageId?: Types.InputMaybe<Types.Scalars['String']>;
  payableOverride?: Types.InputMaybe<Types.Scalars['Decimal']>;
}>;


export type EditPerDiemExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', editPerDiemExpense?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CreateTcpAllowanceExpenseMutationVariables = Types.Exact<{
  date: Types.Scalars['LocalDate'];
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateTcpAllowanceExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', createTcpAllowanceExpense?: { __typename?: 'CreateExpenseResponse', expenseId?: string | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditTcpAllowanceExpenseMutationVariables = Types.Exact<{
  expenseId: Types.Scalars['String'];
  projectNumber: Types.Scalars['Int'];
  crewCode: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
  payableOverride?: Types.InputMaybe<Types.Scalars['Decimal']>;
}>;


export type EditTcpAllowanceExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', editTcpAllowanceExpense?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type DeleteExpenseMutationVariables = Types.Exact<{
  expenseId: Types.Scalars['String'];
}>;


export type DeleteExpenseMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', deleteExpense?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const CreateAtvExpenseDocument = gql`
    mutation CreateAtvExpense($ownerInformation: OwnerInformation!, $date: LocalDate!, $userPrincipalName: String, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String, $state: String!, $county: String!, $adminNotes: String) {
  timesheets {
    createAtvExpense(
      ownerInformation: $ownerInformation
      date: $date
      userPrincipalName: $userPrincipalName
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
      expenseId
    }
  }
}
    `;
export type CreateAtvExpenseMutationFn = Apollo.MutationFunction<CreateAtvExpenseMutation, CreateAtvExpenseMutationVariables>;

/**
 * __useCreateAtvExpenseMutation__
 *
 * To run a mutation, you first call `useCreateAtvExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAtvExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAtvExpenseMutation, { data, loading, error }] = useCreateAtvExpenseMutation({
 *   variables: {
 *      ownerInformation: // value for 'ownerInformation'
 *      date: // value for 'date'
 *      userPrincipalName: // value for 'userPrincipalName'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCreateAtvExpenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateAtvExpenseMutation, CreateAtvExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAtvExpenseMutation, CreateAtvExpenseMutationVariables>(CreateAtvExpenseDocument, options);
      }
export type CreateAtvExpenseMutationHookResult = ReturnType<typeof useCreateAtvExpenseMutation>;
export type CreateAtvExpenseMutationResult = Apollo.MutationResult<CreateAtvExpenseMutation>;
export type CreateAtvExpenseMutationOptions = Apollo.BaseMutationOptions<CreateAtvExpenseMutation, CreateAtvExpenseMutationVariables>;
export const EditAtvExpenseDocument = gql`
    mutation EditAtvExpense($ownerInformation: OwnerInformation!, $expenseId: String!, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String, $state: String!, $county: String!, $adminNotes: String, $payableOverride: Decimal) {
  timesheets {
    editAtvExpense(
      ownerInformation: $ownerInformation
      expenseId: $expenseId
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
      payableOverride: $payableOverride
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditAtvExpenseMutationFn = Apollo.MutationFunction<EditAtvExpenseMutation, EditAtvExpenseMutationVariables>;

/**
 * __useEditAtvExpenseMutation__
 *
 * To run a mutation, you first call `useEditAtvExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAtvExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAtvExpenseMutation, { data, loading, error }] = useEditAtvExpenseMutation({
 *   variables: {
 *      ownerInformation: // value for 'ownerInformation'
 *      expenseId: // value for 'expenseId'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *      payableOverride: // value for 'payableOverride'
 *   },
 * });
 */
export function useEditAtvExpenseMutation(baseOptions?: Apollo.MutationHookOptions<EditAtvExpenseMutation, EditAtvExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAtvExpenseMutation, EditAtvExpenseMutationVariables>(EditAtvExpenseDocument, options);
      }
export type EditAtvExpenseMutationHookResult = ReturnType<typeof useEditAtvExpenseMutation>;
export type EditAtvExpenseMutationResult = Apollo.MutationResult<EditAtvExpenseMutation>;
export type EditAtvExpenseMutationOptions = Apollo.BaseMutationOptions<EditAtvExpenseMutation, EditAtvExpenseMutationVariables>;
export const CreateFuelExpenseDocument = gql`
    mutation CreateFuelExpense($odometer: Decimal!, $vehicleNumber: String!, $quantity: Decimal!, $receiptTotal: Decimal!, $payerInformation: PayerInformation!, $companyVehicle: Boolean!, $receiptImageId: String, $date: LocalDate!, $userPrincipalName: String, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String, $state: String!, $county: String!, $adminNotes: String) {
  timesheets {
    createFuelExpense(
      odometer: $odometer
      vehicleNumber: $vehicleNumber
      quantity: $quantity
      receiptTotal: $receiptTotal
      payerInformation: $payerInformation
      companyVehicle: $companyVehicle
      receiptImageId: $receiptImageId
      date: $date
      userPrincipalName: $userPrincipalName
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
      expenseId
    }
  }
}
    `;
export type CreateFuelExpenseMutationFn = Apollo.MutationFunction<CreateFuelExpenseMutation, CreateFuelExpenseMutationVariables>;

/**
 * __useCreateFuelExpenseMutation__
 *
 * To run a mutation, you first call `useCreateFuelExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFuelExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFuelExpenseMutation, { data, loading, error }] = useCreateFuelExpenseMutation({
 *   variables: {
 *      odometer: // value for 'odometer'
 *      vehicleNumber: // value for 'vehicleNumber'
 *      quantity: // value for 'quantity'
 *      receiptTotal: // value for 'receiptTotal'
 *      payerInformation: // value for 'payerInformation'
 *      companyVehicle: // value for 'companyVehicle'
 *      receiptImageId: // value for 'receiptImageId'
 *      date: // value for 'date'
 *      userPrincipalName: // value for 'userPrincipalName'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCreateFuelExpenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateFuelExpenseMutation, CreateFuelExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFuelExpenseMutation, CreateFuelExpenseMutationVariables>(CreateFuelExpenseDocument, options);
      }
export type CreateFuelExpenseMutationHookResult = ReturnType<typeof useCreateFuelExpenseMutation>;
export type CreateFuelExpenseMutationResult = Apollo.MutationResult<CreateFuelExpenseMutation>;
export type CreateFuelExpenseMutationOptions = Apollo.BaseMutationOptions<CreateFuelExpenseMutation, CreateFuelExpenseMutationVariables>;
export const EditFuelExpenseDocument = gql`
    mutation EditFuelExpense($odometer: Decimal!, $vehicleNumber: String!, $quantity: Decimal!, $receiptTotal: Decimal!, $payerInformation: PayerInformation!, $companyVehicle: Boolean!, $receiptImageId: String, $expenseId: String!, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String, $state: String!, $county: String!, $adminNotes: String, $payableOverride: Decimal) {
  timesheets {
    editFuelExpense(
      odometer: $odometer
      vehicleNumber: $vehicleNumber
      quantity: $quantity
      receiptTotal: $receiptTotal
      payerInformation: $payerInformation
      companyVehicle: $companyVehicle
      receiptImageId: $receiptImageId
      expenseId: $expenseId
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
      payableOverride: $payableOverride
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditFuelExpenseMutationFn = Apollo.MutationFunction<EditFuelExpenseMutation, EditFuelExpenseMutationVariables>;

/**
 * __useEditFuelExpenseMutation__
 *
 * To run a mutation, you first call `useEditFuelExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFuelExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFuelExpenseMutation, { data, loading, error }] = useEditFuelExpenseMutation({
 *   variables: {
 *      odometer: // value for 'odometer'
 *      vehicleNumber: // value for 'vehicleNumber'
 *      quantity: // value for 'quantity'
 *      receiptTotal: // value for 'receiptTotal'
 *      payerInformation: // value for 'payerInformation'
 *      companyVehicle: // value for 'companyVehicle'
 *      receiptImageId: // value for 'receiptImageId'
 *      expenseId: // value for 'expenseId'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *      payableOverride: // value for 'payableOverride'
 *   },
 * });
 */
export function useEditFuelExpenseMutation(baseOptions?: Apollo.MutationHookOptions<EditFuelExpenseMutation, EditFuelExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditFuelExpenseMutation, EditFuelExpenseMutationVariables>(EditFuelExpenseDocument, options);
      }
export type EditFuelExpenseMutationHookResult = ReturnType<typeof useEditFuelExpenseMutation>;
export type EditFuelExpenseMutationResult = Apollo.MutationResult<EditFuelExpenseMutation>;
export type EditFuelExpenseMutationOptions = Apollo.BaseMutationOptions<EditFuelExpenseMutation, EditFuelExpenseMutationVariables>;
export const CreateAdHocExpenseDocument = gql`
    mutation CreateAdHocExpense($quantity: Decimal!, $rateAmount: Decimal, $date: LocalDate!, $receiptImageId: String, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String, $state: String!, $county: String!, $adminNotes: String, $userPrincipalName: String) {
  timesheets {
    createAdHocExpense(
      quantity: $quantity
      rateAmount: $rateAmount
      date: $date
      projectNumber: $projectNumber
      receiptImageId: $receiptImageId
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
      userPrincipalName: $userPrincipalName
    ) {
      errors {
        argumentName
        message
      }
      expenseId
    }
  }
}
    `;
export type CreateAdHocExpenseMutationFn = Apollo.MutationFunction<CreateAdHocExpenseMutation, CreateAdHocExpenseMutationVariables>;

/**
 * __useCreateAdHocExpenseMutation__
 *
 * To run a mutation, you first call `useCreateAdHocExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdHocExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdHocExpenseMutation, { data, loading, error }] = useCreateAdHocExpenseMutation({
 *   variables: {
 *      quantity: // value for 'quantity'
 *      rateAmount: // value for 'rateAmount'
 *      date: // value for 'date'
 *      receiptImageId: // value for 'receiptImageId'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useCreateAdHocExpenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdHocExpenseMutation, CreateAdHocExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdHocExpenseMutation, CreateAdHocExpenseMutationVariables>(CreateAdHocExpenseDocument, options);
      }
export type CreateAdHocExpenseMutationHookResult = ReturnType<typeof useCreateAdHocExpenseMutation>;
export type CreateAdHocExpenseMutationResult = Apollo.MutationResult<CreateAdHocExpenseMutation>;
export type CreateAdHocExpenseMutationOptions = Apollo.BaseMutationOptions<CreateAdHocExpenseMutation, CreateAdHocExpenseMutationVariables>;
export const EditAdHocExpenseDocument = gql`
    mutation EditAdHocExpense($quantity: Decimal!, $rateAmount: Decimal, $receiptImageId: String, $expenseId: String!, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String, $state: String!, $county: String!, $adminNotes: String, $payableOverride: Decimal) {
  timesheets {
    editAdHocExpense(
      quantity: $quantity
      rateAmount: $rateAmount
      receiptImageId: $receiptImageId
      expenseId: $expenseId
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
      payableOverride: $payableOverride
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditAdHocExpenseMutationFn = Apollo.MutationFunction<EditAdHocExpenseMutation, EditAdHocExpenseMutationVariables>;

/**
 * __useEditAdHocExpenseMutation__
 *
 * To run a mutation, you first call `useEditAdHocExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAdHocExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAdHocExpenseMutation, { data, loading, error }] = useEditAdHocExpenseMutation({
 *   variables: {
 *      quantity: // value for 'quantity'
 *      rateAmount: // value for 'rateAmount'
 *      receiptImageId: // value for 'receiptImageId'
 *      expenseId: // value for 'expenseId'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *      payableOverride: // value for 'payableOverride'
 *   },
 * });
 */
export function useEditAdHocExpenseMutation(baseOptions?: Apollo.MutationHookOptions<EditAdHocExpenseMutation, EditAdHocExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAdHocExpenseMutation, EditAdHocExpenseMutationVariables>(EditAdHocExpenseDocument, options);
      }
export type EditAdHocExpenseMutationHookResult = ReturnType<typeof useEditAdHocExpenseMutation>;
export type EditAdHocExpenseMutationResult = Apollo.MutationResult<EditAdHocExpenseMutation>;
export type EditAdHocExpenseMutationOptions = Apollo.BaseMutationOptions<EditAdHocExpenseMutation, EditAdHocExpenseMutationVariables>;
export const CreateMileageExpenseDocument = gql`
    mutation CreateMileageExpense($beginningOdometer: Decimal!, $endingOdometer: Decimal!, $vehicleNumber: String!, $companyVehicle: Boolean!, $date: LocalDate!, $userPrincipalName: String, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String, $state: String!, $county: String!, $adminNotes: String) {
  timesheets {
    createMileageExpense(
      beginningOdometer: $beginningOdometer
      endingOdometer: $endingOdometer
      vehicleNumber: $vehicleNumber
      companyVehicle: $companyVehicle
      date: $date
      userPrincipalName: $userPrincipalName
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
      expenseId
    }
  }
}
    `;
export type CreateMileageExpenseMutationFn = Apollo.MutationFunction<CreateMileageExpenseMutation, CreateMileageExpenseMutationVariables>;

/**
 * __useCreateMileageExpenseMutation__
 *
 * To run a mutation, you first call `useCreateMileageExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMileageExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMileageExpenseMutation, { data, loading, error }] = useCreateMileageExpenseMutation({
 *   variables: {
 *      beginningOdometer: // value for 'beginningOdometer'
 *      endingOdometer: // value for 'endingOdometer'
 *      vehicleNumber: // value for 'vehicleNumber'
 *      companyVehicle: // value for 'companyVehicle'
 *      date: // value for 'date'
 *      userPrincipalName: // value for 'userPrincipalName'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCreateMileageExpenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateMileageExpenseMutation, CreateMileageExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMileageExpenseMutation, CreateMileageExpenseMutationVariables>(CreateMileageExpenseDocument, options);
      }
export type CreateMileageExpenseMutationHookResult = ReturnType<typeof useCreateMileageExpenseMutation>;
export type CreateMileageExpenseMutationResult = Apollo.MutationResult<CreateMileageExpenseMutation>;
export type CreateMileageExpenseMutationOptions = Apollo.BaseMutationOptions<CreateMileageExpenseMutation, CreateMileageExpenseMutationVariables>;
export const EditMileageExpenseDocument = gql`
    mutation EditMileageExpense($beginningOdometer: Decimal!, $endingOdometer: Decimal!, $vehicleNumber: String!, $companyVehicle: Boolean!, $expenseId: String!, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String, $state: String!, $county: String!, $adminNotes: String, $payableOverride: Decimal) {
  timesheets {
    editMileageExpense(
      beginningOdometer: $beginningOdometer
      endingOdometer: $endingOdometer
      vehicleNumber: $vehicleNumber
      companyVehicle: $companyVehicle
      expenseId: $expenseId
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
      payableOverride: $payableOverride
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditMileageExpenseMutationFn = Apollo.MutationFunction<EditMileageExpenseMutation, EditMileageExpenseMutationVariables>;

/**
 * __useEditMileageExpenseMutation__
 *
 * To run a mutation, you first call `useEditMileageExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMileageExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMileageExpenseMutation, { data, loading, error }] = useEditMileageExpenseMutation({
 *   variables: {
 *      beginningOdometer: // value for 'beginningOdometer'
 *      endingOdometer: // value for 'endingOdometer'
 *      vehicleNumber: // value for 'vehicleNumber'
 *      companyVehicle: // value for 'companyVehicle'
 *      expenseId: // value for 'expenseId'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *      payableOverride: // value for 'payableOverride'
 *   },
 * });
 */
export function useEditMileageExpenseMutation(baseOptions?: Apollo.MutationHookOptions<EditMileageExpenseMutation, EditMileageExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMileageExpenseMutation, EditMileageExpenseMutationVariables>(EditMileageExpenseDocument, options);
      }
export type EditMileageExpenseMutationHookResult = ReturnType<typeof useEditMileageExpenseMutation>;
export type EditMileageExpenseMutationResult = Apollo.MutationResult<EditMileageExpenseMutation>;
export type EditMileageExpenseMutationOptions = Apollo.BaseMutationOptions<EditMileageExpenseMutation, EditMileageExpenseMutationVariables>;
export const CreateMobileAllowanceExpenseDocument = gql`
    mutation CreateMobileAllowanceExpense($date: LocalDate!, $userPrincipalName: String, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String, $state: String!, $county: String!, $adminNotes: String) {
  timesheets {
    createMobileAllowanceExpense(
      date: $date
      userPrincipalName: $userPrincipalName
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
      expenseId
    }
  }
}
    `;
export type CreateMobileAllowanceExpenseMutationFn = Apollo.MutationFunction<CreateMobileAllowanceExpenseMutation, CreateMobileAllowanceExpenseMutationVariables>;

/**
 * __useCreateMobileAllowanceExpenseMutation__
 *
 * To run a mutation, you first call `useCreateMobileAllowanceExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMobileAllowanceExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMobileAllowanceExpenseMutation, { data, loading, error }] = useCreateMobileAllowanceExpenseMutation({
 *   variables: {
 *      date: // value for 'date'
 *      userPrincipalName: // value for 'userPrincipalName'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCreateMobileAllowanceExpenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateMobileAllowanceExpenseMutation, CreateMobileAllowanceExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMobileAllowanceExpenseMutation, CreateMobileAllowanceExpenseMutationVariables>(CreateMobileAllowanceExpenseDocument, options);
      }
export type CreateMobileAllowanceExpenseMutationHookResult = ReturnType<typeof useCreateMobileAllowanceExpenseMutation>;
export type CreateMobileAllowanceExpenseMutationResult = Apollo.MutationResult<CreateMobileAllowanceExpenseMutation>;
export type CreateMobileAllowanceExpenseMutationOptions = Apollo.BaseMutationOptions<CreateMobileAllowanceExpenseMutation, CreateMobileAllowanceExpenseMutationVariables>;
export const EditMobileAllowanceExpenseDocument = gql`
    mutation EditMobileAllowanceExpense($expenseId: String!, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String, $state: String!, $county: String!, $adminNotes: String, $payableOverride: Decimal) {
  timesheets {
    editMobileAllowanceExpense(
      expenseId: $expenseId
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
      payableOverride: $payableOverride
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditMobileAllowanceExpenseMutationFn = Apollo.MutationFunction<EditMobileAllowanceExpenseMutation, EditMobileAllowanceExpenseMutationVariables>;

/**
 * __useEditMobileAllowanceExpenseMutation__
 *
 * To run a mutation, you first call `useEditMobileAllowanceExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMobileAllowanceExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMobileAllowanceExpenseMutation, { data, loading, error }] = useEditMobileAllowanceExpenseMutation({
 *   variables: {
 *      expenseId: // value for 'expenseId'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *      payableOverride: // value for 'payableOverride'
 *   },
 * });
 */
export function useEditMobileAllowanceExpenseMutation(baseOptions?: Apollo.MutationHookOptions<EditMobileAllowanceExpenseMutation, EditMobileAllowanceExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMobileAllowanceExpenseMutation, EditMobileAllowanceExpenseMutationVariables>(EditMobileAllowanceExpenseDocument, options);
      }
export type EditMobileAllowanceExpenseMutationHookResult = ReturnType<typeof useEditMobileAllowanceExpenseMutation>;
export type EditMobileAllowanceExpenseMutationResult = Apollo.MutationResult<EditMobileAllowanceExpenseMutation>;
export type EditMobileAllowanceExpenseMutationOptions = Apollo.BaseMutationOptions<EditMobileAllowanceExpenseMutation, EditMobileAllowanceExpenseMutationVariables>;
export const CreateReceiptValuedExpenseDocument = gql`
    mutation CreateReceiptValuedExpense($payerInformation: PayerInformation!, $odometer: Decimal, $vehicleNumber: String, $receiptTotal: Decimal!, $receiptImageId: String!, $date: LocalDate!, $userPrincipalName: String, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String!, $state: String!, $county: String!, $adminNotes: String) {
  timesheets {
    createReceiptValuedExpense(
      payerInformation: $payerInformation
      odometer: $odometer
      vehicleNumber: $vehicleNumber
      receiptTotal: $receiptTotal
      receiptImageId: $receiptImageId
      date: $date
      userPrincipalName: $userPrincipalName
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
      expenseId
    }
  }
}
    `;
export type CreateReceiptValuedExpenseMutationFn = Apollo.MutationFunction<CreateReceiptValuedExpenseMutation, CreateReceiptValuedExpenseMutationVariables>;

/**
 * __useCreateReceiptValuedExpenseMutation__
 *
 * To run a mutation, you first call `useCreateReceiptValuedExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceiptValuedExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceiptValuedExpenseMutation, { data, loading, error }] = useCreateReceiptValuedExpenseMutation({
 *   variables: {
 *      payerInformation: // value for 'payerInformation'
 *      odometer: // value for 'odometer'
 *      vehicleNumber: // value for 'vehicleNumber'
 *      receiptTotal: // value for 'receiptTotal'
 *      receiptImageId: // value for 'receiptImageId'
 *      date: // value for 'date'
 *      userPrincipalName: // value for 'userPrincipalName'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCreateReceiptValuedExpenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateReceiptValuedExpenseMutation, CreateReceiptValuedExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReceiptValuedExpenseMutation, CreateReceiptValuedExpenseMutationVariables>(CreateReceiptValuedExpenseDocument, options);
      }
export type CreateReceiptValuedExpenseMutationHookResult = ReturnType<typeof useCreateReceiptValuedExpenseMutation>;
export type CreateReceiptValuedExpenseMutationResult = Apollo.MutationResult<CreateReceiptValuedExpenseMutation>;
export type CreateReceiptValuedExpenseMutationOptions = Apollo.BaseMutationOptions<CreateReceiptValuedExpenseMutation, CreateReceiptValuedExpenseMutationVariables>;
export const EditReceiptValuedExpenseDocument = gql`
    mutation EditReceiptValuedExpense($payerInformation: PayerInformation!, $odometer: Decimal, $vehicleNumber: String, $receiptTotal: Decimal!, $receiptImageId: String!, $expenseId: String!, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String!, $state: String!, $county: String!, $adminNotes: String, $payableOverride: Decimal) {
  timesheets {
    editReceiptValuedExpense(
      payerInformation: $payerInformation
      odometer: $odometer
      vehicleNumber: $vehicleNumber
      receiptTotal: $receiptTotal
      receiptImageId: $receiptImageId
      expenseId: $expenseId
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
      payableOverride: $payableOverride
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditReceiptValuedExpenseMutationFn = Apollo.MutationFunction<EditReceiptValuedExpenseMutation, EditReceiptValuedExpenseMutationVariables>;

/**
 * __useEditReceiptValuedExpenseMutation__
 *
 * To run a mutation, you first call `useEditReceiptValuedExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReceiptValuedExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReceiptValuedExpenseMutation, { data, loading, error }] = useEditReceiptValuedExpenseMutation({
 *   variables: {
 *      payerInformation: // value for 'payerInformation'
 *      odometer: // value for 'odometer'
 *      vehicleNumber: // value for 'vehicleNumber'
 *      receiptTotal: // value for 'receiptTotal'
 *      receiptImageId: // value for 'receiptImageId'
 *      expenseId: // value for 'expenseId'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *      payableOverride: // value for 'payableOverride'
 *   },
 * });
 */
export function useEditReceiptValuedExpenseMutation(baseOptions?: Apollo.MutationHookOptions<EditReceiptValuedExpenseMutation, EditReceiptValuedExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditReceiptValuedExpenseMutation, EditReceiptValuedExpenseMutationVariables>(EditReceiptValuedExpenseDocument, options);
      }
export type EditReceiptValuedExpenseMutationHookResult = ReturnType<typeof useEditReceiptValuedExpenseMutation>;
export type EditReceiptValuedExpenseMutationResult = Apollo.MutationResult<EditReceiptValuedExpenseMutation>;
export type EditReceiptValuedExpenseMutationOptions = Apollo.BaseMutationOptions<EditReceiptValuedExpenseMutation, EditReceiptValuedExpenseMutationVariables>;
export const CreatePerDiemExpenseDocument = gql`
    mutation CreatePerDiemExpense($date: LocalDate!, $userPrincipalName: String, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String, $state: String!, $county: String!, $adminNotes: String, $receiptImageId: String) {
  timesheets {
    createPerDiemExpense(
      date: $date
      userPrincipalName: $userPrincipalName
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
      receiptImageId: $receiptImageId
    ) {
      errors {
        argumentName
        message
      }
      expenseId
    }
  }
}
    `;
export type CreatePerDiemExpenseMutationFn = Apollo.MutationFunction<CreatePerDiemExpenseMutation, CreatePerDiemExpenseMutationVariables>;

/**
 * __useCreatePerDiemExpenseMutation__
 *
 * To run a mutation, you first call `useCreatePerDiemExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePerDiemExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPerDiemExpenseMutation, { data, loading, error }] = useCreatePerDiemExpenseMutation({
 *   variables: {
 *      date: // value for 'date'
 *      userPrincipalName: // value for 'userPrincipalName'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *      receiptImageId: // value for 'receiptImageId'
 *   },
 * });
 */
export function useCreatePerDiemExpenseMutation(baseOptions?: Apollo.MutationHookOptions<CreatePerDiemExpenseMutation, CreatePerDiemExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePerDiemExpenseMutation, CreatePerDiemExpenseMutationVariables>(CreatePerDiemExpenseDocument, options);
      }
export type CreatePerDiemExpenseMutationHookResult = ReturnType<typeof useCreatePerDiemExpenseMutation>;
export type CreatePerDiemExpenseMutationResult = Apollo.MutationResult<CreatePerDiemExpenseMutation>;
export type CreatePerDiemExpenseMutationOptions = Apollo.BaseMutationOptions<CreatePerDiemExpenseMutation, CreatePerDiemExpenseMutationVariables>;
export const EditPerDiemExpenseDocument = gql`
    mutation EditPerDiemExpense($expenseId: String!, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String, $state: String!, $county: String!, $adminNotes: String, $receiptImageId: String, $payableOverride: Decimal) {
  timesheets {
    editPerDiemExpense(
      expenseId: $expenseId
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
      receiptImageId: $receiptImageId
      payableOverride: $payableOverride
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditPerDiemExpenseMutationFn = Apollo.MutationFunction<EditPerDiemExpenseMutation, EditPerDiemExpenseMutationVariables>;

/**
 * __useEditPerDiemExpenseMutation__
 *
 * To run a mutation, you first call `useEditPerDiemExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPerDiemExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPerDiemExpenseMutation, { data, loading, error }] = useEditPerDiemExpenseMutation({
 *   variables: {
 *      expenseId: // value for 'expenseId'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *      receiptImageId: // value for 'receiptImageId'
 *      payableOverride: // value for 'payableOverride'
 *   },
 * });
 */
export function useEditPerDiemExpenseMutation(baseOptions?: Apollo.MutationHookOptions<EditPerDiemExpenseMutation, EditPerDiemExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPerDiemExpenseMutation, EditPerDiemExpenseMutationVariables>(EditPerDiemExpenseDocument, options);
      }
export type EditPerDiemExpenseMutationHookResult = ReturnType<typeof useEditPerDiemExpenseMutation>;
export type EditPerDiemExpenseMutationResult = Apollo.MutationResult<EditPerDiemExpenseMutation>;
export type EditPerDiemExpenseMutationOptions = Apollo.BaseMutationOptions<EditPerDiemExpenseMutation, EditPerDiemExpenseMutationVariables>;
export const CreateTcpAllowanceExpenseDocument = gql`
    mutation CreateTcpAllowanceExpense($date: LocalDate!, $userPrincipalName: String, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String, $state: String!, $county: String!, $adminNotes: String) {
  timesheets {
    createTcpAllowanceExpense(
      date: $date
      userPrincipalName: $userPrincipalName
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
      expenseId
    }
  }
}
    `;
export type CreateTcpAllowanceExpenseMutationFn = Apollo.MutationFunction<CreateTcpAllowanceExpenseMutation, CreateTcpAllowanceExpenseMutationVariables>;

/**
 * __useCreateTcpAllowanceExpenseMutation__
 *
 * To run a mutation, you first call `useCreateTcpAllowanceExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTcpAllowanceExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTcpAllowanceExpenseMutation, { data, loading, error }] = useCreateTcpAllowanceExpenseMutation({
 *   variables: {
 *      date: // value for 'date'
 *      userPrincipalName: // value for 'userPrincipalName'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCreateTcpAllowanceExpenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateTcpAllowanceExpenseMutation, CreateTcpAllowanceExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTcpAllowanceExpenseMutation, CreateTcpAllowanceExpenseMutationVariables>(CreateTcpAllowanceExpenseDocument, options);
      }
export type CreateTcpAllowanceExpenseMutationHookResult = ReturnType<typeof useCreateTcpAllowanceExpenseMutation>;
export type CreateTcpAllowanceExpenseMutationResult = Apollo.MutationResult<CreateTcpAllowanceExpenseMutation>;
export type CreateTcpAllowanceExpenseMutationOptions = Apollo.BaseMutationOptions<CreateTcpAllowanceExpenseMutation, CreateTcpAllowanceExpenseMutationVariables>;
export const EditTcpAllowanceExpenseDocument = gql`
    mutation EditTcpAllowanceExpense($expenseId: String!, $projectNumber: Int!, $crewCode: String!, $rateName: String!, $notes: String, $state: String!, $county: String!, $adminNotes: String, $payableOverride: Decimal) {
  timesheets {
    editTcpAllowanceExpense(
      expenseId: $expenseId
      projectNumber: $projectNumber
      crewCode: $crewCode
      rateName: $rateName
      notes: $notes
      state: $state
      county: $county
      adminNotes: $adminNotes
      payableOverride: $payableOverride
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditTcpAllowanceExpenseMutationFn = Apollo.MutationFunction<EditTcpAllowanceExpenseMutation, EditTcpAllowanceExpenseMutationVariables>;

/**
 * __useEditTcpAllowanceExpenseMutation__
 *
 * To run a mutation, you first call `useEditTcpAllowanceExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTcpAllowanceExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTcpAllowanceExpenseMutation, { data, loading, error }] = useEditTcpAllowanceExpenseMutation({
 *   variables: {
 *      expenseId: // value for 'expenseId'
 *      projectNumber: // value for 'projectNumber'
 *      crewCode: // value for 'crewCode'
 *      rateName: // value for 'rateName'
 *      notes: // value for 'notes'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *      payableOverride: // value for 'payableOverride'
 *   },
 * });
 */
export function useEditTcpAllowanceExpenseMutation(baseOptions?: Apollo.MutationHookOptions<EditTcpAllowanceExpenseMutation, EditTcpAllowanceExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTcpAllowanceExpenseMutation, EditTcpAllowanceExpenseMutationVariables>(EditTcpAllowanceExpenseDocument, options);
      }
export type EditTcpAllowanceExpenseMutationHookResult = ReturnType<typeof useEditTcpAllowanceExpenseMutation>;
export type EditTcpAllowanceExpenseMutationResult = Apollo.MutationResult<EditTcpAllowanceExpenseMutation>;
export type EditTcpAllowanceExpenseMutationOptions = Apollo.BaseMutationOptions<EditTcpAllowanceExpenseMutation, EditTcpAllowanceExpenseMutationVariables>;
export const DeleteExpenseDocument = gql`
    mutation DeleteExpense($expenseId: String!) {
  timesheets {
    deleteExpense(expenseId: $expenseId) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type DeleteExpenseMutationFn = Apollo.MutationFunction<DeleteExpenseMutation, DeleteExpenseMutationVariables>;

/**
 * __useDeleteExpenseMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseMutation, { data, loading, error }] = useDeleteExpenseMutation({
 *   variables: {
 *      expenseId: // value for 'expenseId'
 *   },
 * });
 */
export function useDeleteExpenseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteExpenseMutation, DeleteExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteExpenseMutation, DeleteExpenseMutationVariables>(DeleteExpenseDocument, options);
      }
export type DeleteExpenseMutationHookResult = ReturnType<typeof useDeleteExpenseMutation>;
export type DeleteExpenseMutationResult = Apollo.MutationResult<DeleteExpenseMutation>;
export type DeleteExpenseMutationOptions = Apollo.BaseMutationOptions<DeleteExpenseMutation, DeleteExpenseMutationVariables>;