import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditProjectGroupMutationVariables = Types.Exact<{
  oldName: Types.Scalars['String'];
  newName: Types.Scalars['String'];
  customerNumber: Types.Scalars['String'];
}>;


export type EditProjectGroupMutation = { __typename?: 'MutationModel', projectGroups?: { __typename?: 'ProjectGroupMutationModel', edit?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const EditProjectGroupDocument = gql`
    mutation EditProjectGroup($oldName: String!, $newName: String!, $customerNumber: String!) {
  projectGroups {
    edit(oldName: $oldName, newName: $newName, customerNumber: $customerNumber) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type EditProjectGroupMutationFn = Apollo.MutationFunction<EditProjectGroupMutation, EditProjectGroupMutationVariables>;

/**
 * __useEditProjectGroupMutation__
 *
 * To run a mutation, you first call `useEditProjectGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectGroupMutation, { data, loading, error }] = useEditProjectGroupMutation({
 *   variables: {
 *      oldName: // value for 'oldName'
 *      newName: // value for 'newName'
 *      customerNumber: // value for 'customerNumber'
 *   },
 * });
 */
export function useEditProjectGroupMutation(baseOptions?: Apollo.MutationHookOptions<EditProjectGroupMutation, EditProjectGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditProjectGroupMutation, EditProjectGroupMutationVariables>(EditProjectGroupDocument, options);
      }
export type EditProjectGroupMutationHookResult = ReturnType<typeof useEditProjectGroupMutation>;
export type EditProjectGroupMutationResult = Apollo.MutationResult<EditProjectGroupMutation>;
export type EditProjectGroupMutationOptions = Apollo.BaseMutationOptions<EditProjectGroupMutation, EditProjectGroupMutationVariables>;