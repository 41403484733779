import { gql } from "@apollo/client";

export const enumQuery = gql`
  query GetEnumType($enumType: String!) {
    __type(name: $enumType) {
      enumValues {
        name
        description
      }
    }
  }
`;
