import {
  BillingFrequencyType,
  Customer,
  GovernmentFundingType,
  Project,
} from "~/gql/types";

type ProjectRefData = {
  id: string;
  name: string;
};

const frequency = BillingFrequencyType.Weekly;
const funding = GovernmentFundingType.None;
const corpLocation: ProjectRefData = {
  id: "TYPE-1",
  name: "TCO-COLORADO-LAFAYETTE",
};
const division: ProjectRefData = { id: "DIV-1", name: "Surveying-Civil" };

const testCustomer: Customer = {
  name: "Company One",
  number: "54321",
  address1: "123 Main St.",
  address2: "",
  city: "Tulsa",
  state: "OK",
  zip: "74120",
};

export const employees = [
  {
    firstName: "Joe",
    isMobileEmployee: true,
    isTerminated: false,
    lastName: "Bob",
    ultiProId: "ABC123",
    userPrincipalName: "joe@topographic.com",
  },
  {
    firstName: "Sally",
    isMobileEmployee: true,
    isTerminated: false,
    lastName: "Field",
    ultiProId: "DEF456",
    userPrincipalName: "sally@topographic.com",
  },
  {
    firstName: "Ricky",
    isMobileEmployee: true,
    isTerminated: false,
    lastName: "Ricardo",
    ultiProId: "GHI789",
    userPrincipalName: "ricky@topographic.com",
  },
];

export const project: Project = {
  name: "Test Project",
  description: "Project description",
  number: 1234,
  customer: testCustomer,
  location: { state: "OK", county: "TULSA" },
  expenseRateSheets: [],
  taskRateSheets: [],
  clientContactName: "Test Person",
  survey: "Public",
  networkPath: "@//server/loc1/loc2",
  billingFrequency: frequency,
  governmentFunding: funding,
  billingAdmin: employees[0],
  projectManager: employees[1],
  projectSupervisor: employees[2],
  officeCode: "CE",
  corpLocation,
  budget: 4000,
  legacyBilled: 1000,
  division,
  stats: {
    totalUnbilled: 250,
    totalPendingApproval: 250,
    totalBilled: 1250,
    totalPrPending: 250,
    totalPmPending: 250,
    totalPendingBilling: 250,
    totalNonBillable: 500,
  },
  serviceDescription: {
    projectType: "GIS",
  },
};
