import React from "react";
import { Link } from "react-router-dom";
import "./BreadcrumbItem.scss";
import { Body2 } from "@material/react-typography";

export type BreadcrumbItemType = {
  text: string;
  to?: string;
};

const BreadcrumbItem: React.FC<BreadcrumbItemType> = (props) => {
  const { text, to } = props;

  const route = to || "";

  return (
    <div className="breadcrumb">
      {!to ? (
        <Body2>{text}</Body2>
      ) : (
        <>
          <Link to={route} className="breadcrumb-link">
            <Body2>{text}</Body2>
          </Link>
          <span className="divider-arrows">&raquo;</span>
        </>
      )}
    </div>
  );
};

export default BreadcrumbItem;
