import React from "react";
import { TaskRateType } from "~/gql/types";
import { EmployeePicker } from "../EmployeePickerFormField";
import EnumDropdownFormField from "../EnumDropdownFormField";

type CrewEntryProps = {
  rateType: TaskRateType | null;
  crewSize: string | null;
  date: string;
};

export const CrewEntry: React.FC<CrewEntryProps> = ({
  rateType,
  crewSize,
  date,
}) => {
  if (rateType !== TaskRateType.Field && (!crewSize || !(+crewSize > 0))) {
    return null;
  }

  return (
    <>
      {crewSize && +crewSize > 1 && (
        <EnumDropdownFormField
          label={"My Role"}
          helperText=""
          formField="myRole"
          enumType="CrewRole"
          required
        />
      )}
      {crewSize !== null && +crewSize! >= 2 && (
        <>
          <EmployeePicker
            formField="otherCrewOne"
            label="Crew Member Two"
            helperText="Select second crew member"
            activePayrollDate={date}
            required
          />
          <EnumDropdownFormField
            label={"Crew Member Two Role"}
            helperText="Select second crew member role"
            formField="otherRole1"
            enumType="CrewRole"
            required
          />
        </>
      )}
      {crewSize !== null && +crewSize == 3 && (
        <>
          <EmployeePicker
            formField="otherCrewTwo"
            label="Crew Member Three"
            helperText="Select third crew member"
            activePayrollDate={date}
            required
          />
          <EnumDropdownFormField
            label={"Crew Member Three Role"}
            helperText="Select third crew member role"
            formField="otherRole2"
            enumType="CrewRole"
            required
          />
        </>
      )}
    </>
  );
};
