import React, { createContext, useContext, useState } from "react";
import { State } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { FilterOptions } from "./FilterForm";
import _ from "lodash";
import { Project, ProjectSearchStats } from "~/gql/types";

type ContextType = {
  state: State<Project, FilterOptions> | null;
  setState: React.Dispatch<State<Project, FilterOptions> | null>;
  stats: ProjectSearchStats | null;
  setStats: React.Dispatch<ProjectSearchStats | null>;
  statsText: string;
};

export const ProjectSearchStatsContext = createContext<ContextType>({
  state: null,
  setState: () => null,
  stats: null,
  setStats: () => null,
  statsText: "",
});

export const ProjectSearchStatsProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<State<Project, FilterOptions> | null>(
    null
  );
  const [stats, setStats] = useState<ProjectSearchStats | null>(null);

  let statsText = "";

  if (state && stats) {
    const projectsLoaded = state?.items.length ?? 0;
    statsText = `${projectsLoaded} of ${stats.projectCount} projects loaded`;
  }

  return (
    <ProjectSearchStatsContext.Provider
      value={{ state, setState, stats, setStats, statsText }}
    >
      {children}
    </ProjectSearchStatsContext.Provider>
  );
};

export function useProjectSearchStats() {
  const context = useContext(ProjectSearchStatsContext);
  return context;
}
