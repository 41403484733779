import React from "react";
import { useLinkClickHandler } from "react-router-dom";

type LinkClickHandlerProps = {
  path: string;
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
};

export const LinkClickHandler: React.FC<LinkClickHandlerProps> = ({
  path,
  className,
  children,
  onClick,
  style,
}) => {
  const requestClick = useLinkClickHandler(path);

  return (
    <a
      href={path}
      onClick={(e) => {
        requestClick(e);
        onClick && onClick();
      }}
      className={className}
      style={style}
    >
      {children}
    </a>
  );
};
