import { createContext, useContext } from "react";
import { ProjectRequest } from "~/gql/types";

export type GroupReviewContextType = {
  refetch: React.DispatchWithoutAction;
  projectRequests: ProjectRequest[];
};

export const GroupReviewContext = createContext<GroupReviewContextType>({
  refetch: () => Promise.resolve(),
  projectRequests: [],
});

export function useGroupReviewContext(): GroupReviewContextType {
  const context = useContext(GroupReviewContext);

  if (!context) {
    throw new Error(
      "Do not use useGroupReviewContext outside of a GroupReviewContext.Provider"
    );
  }

  return context;
}
