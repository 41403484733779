import React from "react";
import { useRawJwt } from "~/Auth/AuthenticationManager";
import ImageLoader from "~/visuals/organisms/Expenses/ExpenseForm/ImageLoader";
import FileUploadFormField from "~/visuals/organisms/FileUploadFormField";

type ImageUploadWrapperProps = {
  imageUrl: string | null;
  setImageUrl: React.Dispatch<string | null>;
  setImageId: React.Dispatch<string | null>;
  label: string;
  formField: string;
  required?: boolean;
};
export const ImageUploadWrapper: React.FC<ImageUploadWrapperProps> = ({
  setImageId,
  label,
  formField,
  imageUrl,
  setImageUrl,
  required,
}) => {
  const token = useRawJwt();

  const uploadImage = (value: any) => {
    const formData = new FormData();
    formData.append("files", value);
    void window
      .fetch("/api/upload-image", {
        method: "post",
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
        body: formData,
      })
      .then(async (res) => {
        const result = await res.json();
        if (result.url && result.imageId) {
          setImageUrl(result.url);
          setImageId(result.imageId);
        }
      });
  };
  const handleImageChange = (file: any) => {
    if (file) {
      uploadImage(file);
    }
  };

  const handleImageRemove = () => {
    setImageUrl(null);
    setImageId(null);
  };
  return (
    <>
      <ImageLoader className={`${label}-image`} imageUrl={imageUrl} />
      <FileUploadFormField
        label={label}
        helperText={`Upload image of ${label}.`}
        formField={formField}
        validMessage="Please provide a valid file"
        handleChange={handleImageChange}
        handleRemove={handleImageRemove}
        required={required ?? false}
      />
    </>
  );
};
