import React from "react";
import { InvoiceRouter } from "~/visuals/pages/Invoice/InvoiceRouter";
import { ReviewWrapper, useReviewItemsContext } from "~/review-items";
import { Invoice } from "~/gql/types";

export const INVOICE_FILTERS_KEY = "invoices-filters";

type InvoiceReviewProps = {
  RouterComponent?: typeof InvoiceRouter;
};

export const InvoiceReview: React.FC<InvoiceReviewProps> = ({
  RouterComponent,
}) => {
  const { selectedItems } = useReviewItemsContext();

  const Router = RouterComponent ?? InvoiceRouter;

  return (
    <ReviewWrapper
      {...{
        type: "invoices",
        path: "/invoices",
        filtersKey: INVOICE_FILTERS_KEY,
        navFunc: (x: Invoice) => `/invoices/review/${x.invoiceNumber}`,
        selectedItems: selectedItems as Invoice[],
      }}
    >
      <Router />
    </ReviewWrapper>
  );
};
