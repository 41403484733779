import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePerDiemExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  billableOverride?: Types.InputMaybe<Types.Scalars['Decimal']>;
  kind: Types.PerDiemKind;
  quantity: Types.Scalars['Int'];
}>;


export type CreatePerDiemExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', addPerDiemRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CreateReceiptValuedExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  requiresLocation: Types.Scalars['Boolean'];
  category: Types.ReceiptValuedCategory;
  billable: Types.Scalars['Boolean'];
}>;


export type CreateReceiptValuedExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', addReceiptValuedRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CreateFuelExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  billable: Types.Scalars['Boolean'];
}>;


export type CreateFuelExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', addFuelRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CreateMobileAllowanceExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  billableAmount: Types.Scalars['Decimal'];
  reimbursableAmount: Types.Scalars['Decimal'];
  quantity: Types.Scalars['Int'];
}>;


export type CreateMobileAllowanceExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', addMobileAllowanceRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CreateTcpAllowanceExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  reimbursableAmount: Types.Scalars['Decimal'];
}>;


export type CreateTcpAllowanceExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', addTcpAllowanceRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CreateMileageExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  billableAmountPerMile: Types.Scalars['Decimal'];
  reimbursableAmountPerMile: Types.Scalars['Decimal'];
}>;


export type CreateMileageExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', addMileageRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CreateAtvExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  billableAmount: Types.Scalars['Decimal'];
  reimbursableAmount: Types.Scalars['Decimal'];
}>;


export type CreateAtvExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', addAtvRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CreateAdHocExpenseRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  name: Types.Scalars['String'];
  invoiceLabel?: Types.InputMaybe<Types.Scalars['String']>;
  expenseAccount?: Types.InputMaybe<Types.Scalars['String']>;
  rate?: Types.InputMaybe<Types.Scalars['Decimal']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateAdHocExpenseRateMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', addAdHocRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CreatePerDiemExpenseRateDocument = gql`
    mutation createPerDiemExpenseRate($rateSheetName: String!, $name: String!, $invoiceLabel: String, $expenseAccount: String, $glAccount: String, $billableOverride: Decimal, $kind: PerDiemKind!, $quantity: Int!) {
  expenseRates {
    addPerDiemRate(
      rateSheetName: $rateSheetName
      name: $name
      invoiceLabel: $invoiceLabel
      expenseAccount: $expenseAccount
      glAccount: $glAccount
      billableOverride: $billableOverride
      kind: $kind
      quantity: $quantity
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CreatePerDiemExpenseRateMutationFn = Apollo.MutationFunction<CreatePerDiemExpenseRateMutation, CreatePerDiemExpenseRateMutationVariables>;

/**
 * __useCreatePerDiemExpenseRateMutation__
 *
 * To run a mutation, you first call `useCreatePerDiemExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePerDiemExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPerDiemExpenseRateMutation, { data, loading, error }] = useCreatePerDiemExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      name: // value for 'name'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      glAccount: // value for 'glAccount'
 *      billableOverride: // value for 'billableOverride'
 *      kind: // value for 'kind'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useCreatePerDiemExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<CreatePerDiemExpenseRateMutation, CreatePerDiemExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePerDiemExpenseRateMutation, CreatePerDiemExpenseRateMutationVariables>(CreatePerDiemExpenseRateDocument, options);
      }
export type CreatePerDiemExpenseRateMutationHookResult = ReturnType<typeof useCreatePerDiemExpenseRateMutation>;
export type CreatePerDiemExpenseRateMutationResult = Apollo.MutationResult<CreatePerDiemExpenseRateMutation>;
export type CreatePerDiemExpenseRateMutationOptions = Apollo.BaseMutationOptions<CreatePerDiemExpenseRateMutation, CreatePerDiemExpenseRateMutationVariables>;
export const CreateReceiptValuedExpenseRateDocument = gql`
    mutation createReceiptValuedExpenseRate($rateSheetName: String!, $name: String!, $invoiceLabel: String, $expenseAccount: String, $glAccount: String, $requiresLocation: Boolean!, $category: ReceiptValuedCategory!, $billable: Boolean!) {
  expenseRates {
    addReceiptValuedRate(
      rateSheetName: $rateSheetName
      name: $name
      invoiceLabel: $invoiceLabel
      expenseAccount: $expenseAccount
      glAccount: $glAccount
      requiresLocation: $requiresLocation
      category: $category
      billable: $billable
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CreateReceiptValuedExpenseRateMutationFn = Apollo.MutationFunction<CreateReceiptValuedExpenseRateMutation, CreateReceiptValuedExpenseRateMutationVariables>;

/**
 * __useCreateReceiptValuedExpenseRateMutation__
 *
 * To run a mutation, you first call `useCreateReceiptValuedExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceiptValuedExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceiptValuedExpenseRateMutation, { data, loading, error }] = useCreateReceiptValuedExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      name: // value for 'name'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      glAccount: // value for 'glAccount'
 *      requiresLocation: // value for 'requiresLocation'
 *      category: // value for 'category'
 *      billable: // value for 'billable'
 *   },
 * });
 */
export function useCreateReceiptValuedExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateReceiptValuedExpenseRateMutation, CreateReceiptValuedExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReceiptValuedExpenseRateMutation, CreateReceiptValuedExpenseRateMutationVariables>(CreateReceiptValuedExpenseRateDocument, options);
      }
export type CreateReceiptValuedExpenseRateMutationHookResult = ReturnType<typeof useCreateReceiptValuedExpenseRateMutation>;
export type CreateReceiptValuedExpenseRateMutationResult = Apollo.MutationResult<CreateReceiptValuedExpenseRateMutation>;
export type CreateReceiptValuedExpenseRateMutationOptions = Apollo.BaseMutationOptions<CreateReceiptValuedExpenseRateMutation, CreateReceiptValuedExpenseRateMutationVariables>;
export const CreateFuelExpenseRateDocument = gql`
    mutation createFuelExpenseRate($rateSheetName: String!, $name: String!, $invoiceLabel: String, $expenseAccount: String, $glAccount: String, $billable: Boolean!) {
  expenseRates {
    addFuelRate(
      rateSheetName: $rateSheetName
      name: $name
      invoiceLabel: $invoiceLabel
      expenseAccount: $expenseAccount
      glAccount: $glAccount
      billable: $billable
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CreateFuelExpenseRateMutationFn = Apollo.MutationFunction<CreateFuelExpenseRateMutation, CreateFuelExpenseRateMutationVariables>;

/**
 * __useCreateFuelExpenseRateMutation__
 *
 * To run a mutation, you first call `useCreateFuelExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFuelExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFuelExpenseRateMutation, { data, loading, error }] = useCreateFuelExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      name: // value for 'name'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      glAccount: // value for 'glAccount'
 *      billable: // value for 'billable'
 *   },
 * });
 */
export function useCreateFuelExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateFuelExpenseRateMutation, CreateFuelExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFuelExpenseRateMutation, CreateFuelExpenseRateMutationVariables>(CreateFuelExpenseRateDocument, options);
      }
export type CreateFuelExpenseRateMutationHookResult = ReturnType<typeof useCreateFuelExpenseRateMutation>;
export type CreateFuelExpenseRateMutationResult = Apollo.MutationResult<CreateFuelExpenseRateMutation>;
export type CreateFuelExpenseRateMutationOptions = Apollo.BaseMutationOptions<CreateFuelExpenseRateMutation, CreateFuelExpenseRateMutationVariables>;
export const CreateMobileAllowanceExpenseRateDocument = gql`
    mutation createMobileAllowanceExpenseRate($rateSheetName: String!, $name: String!, $invoiceLabel: String, $expenseAccount: String, $glAccount: String, $billableAmount: Decimal!, $reimbursableAmount: Decimal!, $quantity: Int!) {
  expenseRates {
    addMobileAllowanceRate(
      rateSheetName: $rateSheetName
      name: $name
      invoiceLabel: $invoiceLabel
      expenseAccount: $expenseAccount
      glAccount: $glAccount
      billableAmount: $billableAmount
      reimbursableAmount: $reimbursableAmount
      quantity: $quantity
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CreateMobileAllowanceExpenseRateMutationFn = Apollo.MutationFunction<CreateMobileAllowanceExpenseRateMutation, CreateMobileAllowanceExpenseRateMutationVariables>;

/**
 * __useCreateMobileAllowanceExpenseRateMutation__
 *
 * To run a mutation, you first call `useCreateMobileAllowanceExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMobileAllowanceExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMobileAllowanceExpenseRateMutation, { data, loading, error }] = useCreateMobileAllowanceExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      name: // value for 'name'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      glAccount: // value for 'glAccount'
 *      billableAmount: // value for 'billableAmount'
 *      reimbursableAmount: // value for 'reimbursableAmount'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useCreateMobileAllowanceExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateMobileAllowanceExpenseRateMutation, CreateMobileAllowanceExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMobileAllowanceExpenseRateMutation, CreateMobileAllowanceExpenseRateMutationVariables>(CreateMobileAllowanceExpenseRateDocument, options);
      }
export type CreateMobileAllowanceExpenseRateMutationHookResult = ReturnType<typeof useCreateMobileAllowanceExpenseRateMutation>;
export type CreateMobileAllowanceExpenseRateMutationResult = Apollo.MutationResult<CreateMobileAllowanceExpenseRateMutation>;
export type CreateMobileAllowanceExpenseRateMutationOptions = Apollo.BaseMutationOptions<CreateMobileAllowanceExpenseRateMutation, CreateMobileAllowanceExpenseRateMutationVariables>;
export const CreateTcpAllowanceExpenseRateDocument = gql`
    mutation createTcpAllowanceExpenseRate($rateSheetName: String!, $name: String!, $invoiceLabel: String, $expenseAccount: String, $glAccount: String, $reimbursableAmount: Decimal!) {
  expenseRates {
    addTcpAllowanceRate(
      rateSheetName: $rateSheetName
      name: $name
      invoiceLabel: $invoiceLabel
      expenseAccount: $expenseAccount
      glAccount: $glAccount
      reimbursableAmount: $reimbursableAmount
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CreateTcpAllowanceExpenseRateMutationFn = Apollo.MutationFunction<CreateTcpAllowanceExpenseRateMutation, CreateTcpAllowanceExpenseRateMutationVariables>;

/**
 * __useCreateTcpAllowanceExpenseRateMutation__
 *
 * To run a mutation, you first call `useCreateTcpAllowanceExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTcpAllowanceExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTcpAllowanceExpenseRateMutation, { data, loading, error }] = useCreateTcpAllowanceExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      name: // value for 'name'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      glAccount: // value for 'glAccount'
 *      reimbursableAmount: // value for 'reimbursableAmount'
 *   },
 * });
 */
export function useCreateTcpAllowanceExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTcpAllowanceExpenseRateMutation, CreateTcpAllowanceExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTcpAllowanceExpenseRateMutation, CreateTcpAllowanceExpenseRateMutationVariables>(CreateTcpAllowanceExpenseRateDocument, options);
      }
export type CreateTcpAllowanceExpenseRateMutationHookResult = ReturnType<typeof useCreateTcpAllowanceExpenseRateMutation>;
export type CreateTcpAllowanceExpenseRateMutationResult = Apollo.MutationResult<CreateTcpAllowanceExpenseRateMutation>;
export type CreateTcpAllowanceExpenseRateMutationOptions = Apollo.BaseMutationOptions<CreateTcpAllowanceExpenseRateMutation, CreateTcpAllowanceExpenseRateMutationVariables>;
export const CreateMileageExpenseRateDocument = gql`
    mutation createMileageExpenseRate($rateSheetName: String!, $name: String!, $invoiceLabel: String, $expenseAccount: String, $glAccount: String, $billableAmountPerMile: Decimal!, $reimbursableAmountPerMile: Decimal!) {
  expenseRates {
    addMileageRate(
      rateSheetName: $rateSheetName
      name: $name
      invoiceLabel: $invoiceLabel
      expenseAccount: $expenseAccount
      glAccount: $glAccount
      billableAmountPerMile: $billableAmountPerMile
      reimbursableAmountPerMile: $reimbursableAmountPerMile
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CreateMileageExpenseRateMutationFn = Apollo.MutationFunction<CreateMileageExpenseRateMutation, CreateMileageExpenseRateMutationVariables>;

/**
 * __useCreateMileageExpenseRateMutation__
 *
 * To run a mutation, you first call `useCreateMileageExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMileageExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMileageExpenseRateMutation, { data, loading, error }] = useCreateMileageExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      name: // value for 'name'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      glAccount: // value for 'glAccount'
 *      billableAmountPerMile: // value for 'billableAmountPerMile'
 *      reimbursableAmountPerMile: // value for 'reimbursableAmountPerMile'
 *   },
 * });
 */
export function useCreateMileageExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateMileageExpenseRateMutation, CreateMileageExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMileageExpenseRateMutation, CreateMileageExpenseRateMutationVariables>(CreateMileageExpenseRateDocument, options);
      }
export type CreateMileageExpenseRateMutationHookResult = ReturnType<typeof useCreateMileageExpenseRateMutation>;
export type CreateMileageExpenseRateMutationResult = Apollo.MutationResult<CreateMileageExpenseRateMutation>;
export type CreateMileageExpenseRateMutationOptions = Apollo.BaseMutationOptions<CreateMileageExpenseRateMutation, CreateMileageExpenseRateMutationVariables>;
export const CreateAtvExpenseRateDocument = gql`
    mutation createAtvExpenseRate($rateSheetName: String!, $name: String!, $invoiceLabel: String, $expenseAccount: String, $glAccount: String, $billableAmount: Decimal!, $reimbursableAmount: Decimal!) {
  expenseRates {
    addAtvRate(
      rateSheetName: $rateSheetName
      name: $name
      expenseAccount: $expenseAccount
      glAccount: $glAccount
      invoiceLabel: $invoiceLabel
      billableAmount: $billableAmount
      reimbursableAmount: $reimbursableAmount
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CreateAtvExpenseRateMutationFn = Apollo.MutationFunction<CreateAtvExpenseRateMutation, CreateAtvExpenseRateMutationVariables>;

/**
 * __useCreateAtvExpenseRateMutation__
 *
 * To run a mutation, you first call `useCreateAtvExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAtvExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAtvExpenseRateMutation, { data, loading, error }] = useCreateAtvExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      name: // value for 'name'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      glAccount: // value for 'glAccount'
 *      billableAmount: // value for 'billableAmount'
 *      reimbursableAmount: // value for 'reimbursableAmount'
 *   },
 * });
 */
export function useCreateAtvExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateAtvExpenseRateMutation, CreateAtvExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAtvExpenseRateMutation, CreateAtvExpenseRateMutationVariables>(CreateAtvExpenseRateDocument, options);
      }
export type CreateAtvExpenseRateMutationHookResult = ReturnType<typeof useCreateAtvExpenseRateMutation>;
export type CreateAtvExpenseRateMutationResult = Apollo.MutationResult<CreateAtvExpenseRateMutation>;
export type CreateAtvExpenseRateMutationOptions = Apollo.BaseMutationOptions<CreateAtvExpenseRateMutation, CreateAtvExpenseRateMutationVariables>;
export const CreateAdHocExpenseRateDocument = gql`
    mutation createAdHocExpenseRate($rateSheetName: String!, $name: String!, $invoiceLabel: String, $expenseAccount: String, $rate: Decimal, $glAccount: String) {
  expenseRates {
    addAdHocRate(
      rateSheetName: $rateSheetName
      name: $name
      invoiceLabel: $invoiceLabel
      expenseAccount: $expenseAccount
      glAccount: $glAccount
      rate: $rate
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CreateAdHocExpenseRateMutationFn = Apollo.MutationFunction<CreateAdHocExpenseRateMutation, CreateAdHocExpenseRateMutationVariables>;

/**
 * __useCreateAdHocExpenseRateMutation__
 *
 * To run a mutation, you first call `useCreateAdHocExpenseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdHocExpenseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdHocExpenseRateMutation, { data, loading, error }] = useCreateAdHocExpenseRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      name: // value for 'name'
 *      invoiceLabel: // value for 'invoiceLabel'
 *      expenseAccount: // value for 'expenseAccount'
 *      rate: // value for 'rate'
 *      glAccount: // value for 'glAccount'
 *   },
 * });
 */
export function useCreateAdHocExpenseRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdHocExpenseRateMutation, CreateAdHocExpenseRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdHocExpenseRateMutation, CreateAdHocExpenseRateMutationVariables>(CreateAdHocExpenseRateDocument, options);
      }
export type CreateAdHocExpenseRateMutationHookResult = ReturnType<typeof useCreateAdHocExpenseRateMutation>;
export type CreateAdHocExpenseRateMutationResult = Apollo.MutationResult<CreateAdHocExpenseRateMutation>;
export type CreateAdHocExpenseRateMutationOptions = Apollo.BaseMutationOptions<CreateAdHocExpenseRateMutation, CreateAdHocExpenseRateMutationVariables>;