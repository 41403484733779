import { ActiveFilter, FilterProvider, Suggestion } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";

type CreateFilterProviderProps = {
  render: () => React.ReactNode;
  type: string;
  label: string;
  props: FilterProviderProps;
  getItems: (args?: any) => Promise<any>;
  toSuggestions: (result: any[], text: string) => Suggestion[];
  shouldSuggestText: string;
  toLabelValue: (value: any) => string;
  toParamValue: (value: any) => string;
  itemFromParam: (arg: string) => Promise<any | null>;
  toSearchArgs?: (text: string) => Record<string, any>;
  parseResult?: (result: any) => any;
};

export function createProvider(
  args: CreateFilterProviderProps
): FilterProvider {
  const {
    render,
    type,
    label,
    getItems,
    props,
    shouldSuggestText,
    toSuggestions,
    toLabelValue,
    toParamValue,
    itemFromParam,
    toSearchArgs,
    parseResult,
  } = args;

  const { documentationText: origDocText } = props;
  const documentationText = `${origDocText} Use "${shouldSuggestText}" for quick filtering.`;

  return {
    render,
    type,
    label,
    documentationText,
    getSuggestions: async (text: string) => {
      if (!text) {
        return [];
      }

      const args = toSearchArgs ? toSearchArgs(text) : undefined;

      const result = await getItems(args);
      const items = parseResult ? parseResult(result) : result;
      if (!items || items.length === 0) {
        return [];
      }
      return toSuggestions(items, text);
    },
    shouldSuggest: (text: string) =>
      text.toLowerCase().startsWith(shouldSuggestText.toLowerCase()),
    toFilter: (value: any) => ({
      label: `${label}: ${toLabelValue(value)}`,
      type,
      value,
    }),
    filterFromParams: async (params: URLSearchParams) => {
      const paramItem = params.get(type) || null;
      const item = paramItem ? await itemFromParam(paramItem) : null;

      return item
        ? {
            label: `${label}: ${toLabelValue(item)}`,
            value: item,
            type,
          }
        : null;
    },
    toParam: (filter: ActiveFilter) =>
      filter?.value ? toParamValue(filter.value) : "",
  };
}
