import React from "react";
import MaterialIcon from "@material/react-material-icon";
import cn from "classnames";
import "./Chevron.scss";

type ChevronProps = {
  expanded: boolean;
  className?: string;
};

export const Chevron: React.FC<ChevronProps> = ({ expanded, className }) => {
  const style = cn(
    ["mdc-button__icon chevron", className].filter((x) => x).join(" "),
    { expanded }
  );
  return <MaterialIcon icon="expand_more" className={style} title="Expand" />;
};
