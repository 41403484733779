import React from "react";
import AtvForm from "~/visuals/organisms/Expenses/ExpenseForm/AtvTypeForm";
import { useWorkDayApi } from "~/WorkDayApi";
import { AtvExpense, AtvExpenseRate } from "~/gql/types";
import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";

type EditAtvProps = BaseExpenseFormProps & {
  expenseRate: AtvExpenseRate;
  expenseEntry: AtvExpense;
};

const EditAtvExpense: React.FC<EditAtvProps> = (props) => {
  const { expenseEntry, project } = props;

  const { editAtvExpense } = useWorkDayApi();

  const editExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc
  ) => {
    const res = await editAtvExpense({
      ...defaultVals(values),
      state: project?.location?.state!,
      county: project?.location?.county!,
      expenseId: expenseEntry.id!,
      ownerInformation: values?.ownerInformation!,
      payableOverride: values?.payableOverride || null,
    });

    return res;
  };

  const uniqueInitVals: UniqueInitVals = [
    {
      ownerInformation:
        expenseEntry.payable === "Invoice" ? "Personal" : "Company",
    },
  ];

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        uniqueInitVals,
        handleSubmit: editExpense,
        expenseType: "ATV",
        formType: "Edit",
      }}
    >
      <AtvForm {...props} />
    </ExpensePageWrapper>
  );
};

export default EditAtvExpense;
