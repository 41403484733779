import React from "react";
import { FilterForm } from "./FilterForm";
import { TimeSheetDisplay } from "~/visuals/organisms/TimesheetDisplay";
import { FilterFormProps } from "~/visuals/organisms/TimesheetDisplay/types";
import { Route, Routes } from "react-router-dom";
import { ReviewWorkWeeks } from "~/WorkData/ReviewWorkWeeks";
import WorkDayRouter from "~/WorkData/WorkDayRouter";
import { weeklyProjectTasks } from "~/WorkData/routes";
import { WeeklyProjectTasks } from "../WeeklyProjectTasks";
import { SearchRestoreRouter } from "~/search-restorer";

type TimesheetReviewProps = {
  FilterFormComponent?: React.FC<FilterFormProps>;
  WeeklyProjectTasks?: JSX.Element;
  WorkDayRouter?: JSX.Element;
  ReviewWorkWeeks?: JSX.Element;
};

export const TimeSheetReview: React.FC<TimesheetReviewProps> = (props) => (
  <SearchRestoreRouter
    searchPage={
      <TimeSheetDisplay
        FilterFormComponent={props.FilterFormComponent ?? FilterForm}
        mode={"AllTimesheets"}
      />
    }
    nestedPages={
      <Routes>
        <Route path=":userPrincipalName">
          <Route path={weeklyProjectTasks}>
            <Route
              path=":weekStart"
              element={props.WeeklyProjectTasks ?? <WeeklyProjectTasks />}
            />
          </Route>
          <Route
            path=":date/*"
            element={props.WorkDayRouter ?? <WorkDayRouter />}
          />
          <Route index element={props.ReviewWorkWeeks ?? <ReviewWorkWeeks />} />
        </Route>
      </Routes>
    }
  />
);
