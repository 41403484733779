import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AtvExpenseTotalsQueryVariables = Types.Exact<{
  token?: Types.InputMaybe<Types.Scalars['String']>;
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AtvExpenseTotalsQuery = { __typename?: 'QueryModel', timesheets?: { __typename?: 'TimesheetsQueryModel', atvExpenseTotals?: { __typename?: 'ConnectionOfAtvExpenseTotal', token?: string | null, records?: Array<{ __typename?: 'AtvExpenseTotal', month: string, total: number } | null> | null } | null } | null };


export const AtvExpenseTotalsDocument = gql`
    query AtvExpenseTotals($token: String, $userPrincipalName: String) {
  timesheets {
    atvExpenseTotals(token: $token, userPrincipalName: $userPrincipalName) {
      records {
        month
        total
      }
      token
    }
  }
}
    `;

/**
 * __useAtvExpenseTotalsQuery__
 *
 * To run a query within a React component, call `useAtvExpenseTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtvExpenseTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtvExpenseTotalsQuery({
 *   variables: {
 *      token: // value for 'token'
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useAtvExpenseTotalsQuery(baseOptions?: Apollo.QueryHookOptions<AtvExpenseTotalsQuery, AtvExpenseTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AtvExpenseTotalsQuery, AtvExpenseTotalsQueryVariables>(AtvExpenseTotalsDocument, options);
      }
export function useAtvExpenseTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AtvExpenseTotalsQuery, AtvExpenseTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AtvExpenseTotalsQuery, AtvExpenseTotalsQueryVariables>(AtvExpenseTotalsDocument, options);
        }
export type AtvExpenseTotalsQueryHookResult = ReturnType<typeof useAtvExpenseTotalsQuery>;
export type AtvExpenseTotalsLazyQueryHookResult = ReturnType<typeof useAtvExpenseTotalsLazyQuery>;
export type AtvExpenseTotalsQueryResult = Apollo.QueryResult<AtvExpenseTotalsQuery, AtvExpenseTotalsQueryVariables>;