import React, { useEffect, useState } from "react";
import "./TimesheetReview.scss";
import moment, { Duration } from "moment";
import { State } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { SearchFilterContainer } from "~/search-filter-container";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import useInfiniteScroll from "~/search-page-wrapper/infinite-scroll/useInfiniteScroll";
import { Reports } from "./Reports";
import {
  TimesheetContextProvider,
  useTimesheetContext,
} from "./TimesheetContext";
import { EmployeeBreadcrumbs } from "./EmployeeBreadcrumbs";
import { EmployeeLoader } from "./EmployeeLoader";
import {
  FilterFormProps,
  FilterOptions,
  TimesheetMode,
  TimesheetState,
  Timesheet,
} from "./types";
import { LoadWeeks } from "./LoadWeeks";
import { TimesheetTable } from "./TimesheetTable";
import { SearchPageWrapper } from "~/search-page-wrapper";

export const formatDuration = (d: Duration) =>
  `${Math.floor(d.asHours())}:${(d.minutes() + "").padStart(2, "0")}`;
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
export const addDurations = (d1, d2) =>
  moment.duration(d1.asMilliseconds() + d2.asMilliseconds());

type TimesheetReviewProps = {
  FilterFormComponent: React.FC<FilterFormProps>;
  mode: TimesheetMode;
};

const AllTimesheetBreadcrumbs: React.FC = () => {
  useBreadcrumbs([{ text: "Timesheet Review" }], []);
  return <></>;
};

export const TimeSheetDisplay: React.FC<TimesheetReviewProps> = (props) => {
  const { mode } = props;
  const [filters, setFilters] = useState<FilterOptions | null>(null);
  const FilterFormComponent = props.FilterFormComponent;
  const [reportState, setReportState] = useState<TimesheetState | null>(null);

  const setFilterOptions = (opts: FilterOptions) => setFilters(opts);

  return (
    <TimesheetContextProvider {...{ mode }}>
      <SearchPageWrapper>
        <SearchFilterContainer>
          <FilterFormComponent
            onFiltersChanged={setFilterOptions}
            dialogTitle="Timesheet Search"
            label="Type to search timesheets"
            type="All"
          />
          {reportState && mode !== "MyTimesheets" && (
            <Reports {...{ state: reportState! }} />
          )}
        </SearchFilterContainer>
        {filters && (
          <TimeSheetReviewResults {...{ ...props, filters, setReportState }} />
        )}
      </SearchPageWrapper>
    </TimesheetContextProvider>
  );
};

export const TimeSheetReviewResults: React.FC<
  TimesheetReviewProps & {
    filters: FilterOptions;
    setReportState: React.Dispatch<TimesheetState | null>;
  }
> = (props) => {
  const { filters, setReportState } = props;
  const { mode, employee } = useTimesheetContext();

  const initialState = {
    tag: "Loading",
    items: [],
    showVisibility: false,
    loading: true,
    filterOptions: filters,
    searchToken: null,
    selectIdKey: "timesheetId",
    selectedItems: [],
  } as State<Timesheet, FilterOptions>;

  const { state, dispatch } = useInfiniteScroll<Timesheet, FilterOptions>(
    initialState
  );

  const loadProps = {
    dispatch,
    searchText: state.filterOptions.searchText,
    token: state.searchToken,
    weekStart: state.filterOptions.weekStart,
    dateBefore: state.filterOptions.dateBefore,
    dateAfter: state.filterOptions.dateAfter,
    workLocation: state.filterOptions.workLocation,
    weekStatus: state.filterOptions.weekStatus,
    reimbursement: state.filterOptions.reimbursement,
    payrollAdmin: state.filterOptions.payrollAdmin,
    payPeriod: state.filterOptions.payPeriod,
    showFuture: state.filterOptions.showFuture,
    certifiedPayroll: state.filterOptions.certifiedPayroll,
  };

  useEffect(() => {
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: filters,
    });
  }, [filters]);

  useEffect(() => {
    setReportState(state);
  }, [state]);

  return (
    <>
      {mode === "AllTimesheets" ? (
        <AllTimesheetBreadcrumbs />
      ) : (
        <EmployeeBreadcrumbs />
      )}
      {state.tag === "Loading" && mode !== "AllTimesheets" && employee && (
        <LoadWeeks
          {...{
            tag: "DataLoaded",
            ...loadProps,
            token: null,
          }}
        />
      )}
      {state.tag === "Loading" && mode === "AllTimesheets" && (
        <LoadWeeks
          {...{
            tag: "DataLoaded",
            ...loadProps,
            token: null,
          }}
        />
      )}
      {state.tag === "LoadingMore" && (
        <LoadWeeks
          {...{
            tag: "MoreDataLoaded",
            ...loadProps,
          }}
        />
      )}
      <TimesheetTable
        {...{
          state,
          dispatch,
        }}
      />
      {mode !== "MyTimesheets" && (
        <Reports {...{ state, dispatch, outlined: true }} />
      )}
      {mode !== "AllTimesheets" && !employee && <EmployeeLoader />}
    </>
  );
};
