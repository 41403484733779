import React from "react";
import List, { ListItem } from "@material/react-list";
import classnames from "classnames";

const TableHeader: React.FC<{
  className?: string;
  selectedIndex?: number[];
}> = ({ children, className, selectedIndex }) => {
  const cn = classnames("table-row", "table-header", className);

  return (
    <ListItem>
      <List className={cn} nonInteractive selectedIndex={selectedIndex}>
        {children}
        <div className="divider mdc-list-divider" />
      </List>
    </ListItem>
  );
};

export default TableHeader;
