import { ApolloClient } from "@apollo/client";
import {
  CreateTimeOffEntryDocument,
  DeleteTimeOffEntryDocument,
  EditTimeOffEntryDocument,
} from "./timeOffEntryQueries.generated";
import {
  CreateTimeOffEntryArgs,
  DeleteTimeOffEntryArgs,
  EditTimeOffEntryArgs,
} from "./timeOffEntryTypes";
import {
  MutationAndResponseFn,
  MutationFn,
  ValidationResponse,
} from "./WorkDayApiType";

export type RawTimeOffEntryApi = {
  createTimeOffEntry: MutationAndResponseFn<
    CreateTimeOffEntryArgs,
    CreateTimeOffEntryResponse
  >;
  editTimeOffEntry: MutationFn<EditTimeOffEntryArgs>;
  deleteTimeOffEntry: MutationFn<DeleteTimeOffEntryArgs>;
};

export type CreateTimeOffEntryResponse = ValidationResponse & {
  timeOffEntryId: string;
};

export function createTimeOffEntryApi(
  client: ApolloClient<any>
): RawTimeOffEntryApi {
  return {
    createTimeOffEntry: async (args, callback) => {
      const result = await client.mutate({
        mutation: CreateTimeOffEntryDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.createTimeOffEntry as CreateTimeOffEntryResponse,
        callback,
      };
    },
    editTimeOffEntry: async (args, callback) => {
      const result = await client.mutate({
        mutation: EditTimeOffEntryDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.editTimeOffEntry as ValidationResponse,
        callback,
      };
    },
    deleteTimeOffEntry: async (args, callback) => {
      const result = await client.mutate({
        mutation: DeleteTimeOffEntryDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.deleteTimeOffEntry as ValidationResponse,
        callback,
      };
    },
  };
}
