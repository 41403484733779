import React, { useState } from "react";
import {
  ListItem,
  ListItemGraphic,
  ListItemText,
  ListDivider,
} from "@material/react-list";
import { Action } from "./stateMachine";
import MaterialIcon from "@material/react-material-icon";
import _ from "lodash";
import "./ExpandableState.scss";

type ExpandableStateProps = {
  state: string;
  allCounties: Set<string>;
  selectedCounties: Set<string>;
  leadingSeparator?: boolean;
  removable?: boolean;
  showAllOption: boolean;
  dispatch: React.Dispatch<Action>;
  showSelected?: boolean;
};

export const ExpandableState: React.FC<ExpandableStateProps> = ({
  state,
  allCounties,
  selectedCounties,
  leadingSeparator,
  removable,
  showAllOption,
  dispatch,
  showSelected,
}) => {
  const [expanded, setExpanded] = useState(false);
  const selections = showSelected
    ? [...selectedCounties]
    : [...allCounties].filter((x) => !selectedCounties.has(x));
  const isAll = selections.length === allCounties.size;

  if (selections.length === 0) {
    return null;
  }

  const sortedSelections = _.sortBy(selections, (x) => x);

  const subtext = isAll
    ? `All ${allCounties.size} counties`
    : `${selections.length} of ${allCounties.size} counties`;

  const maxHeight = expanded ? 48 * (2 + sortedSelections.length) : 48;

  return (
    <>
      {leadingSeparator && <ListDivider />}
      <div className="expandable-state" style={{ maxHeight }}>
        <ListItem className="state" onClick={() => setExpanded(!expanded)}>
          <ListItemText primaryText={state} secondaryText={subtext} />
          <ListItemGraphic
            graphic={
              <MaterialIcon icon={expanded ? "expand_less" : "expand_more"} />
            }
          />
        </ListItem>
        {showAllOption && expanded && (
          <LocationCounty
            name={
              removable
                ? `(remove all ${state} counties)`
                : `(select all ${state} counties)`
            }
            removable={removable}
            state={state}
            dispatch={dispatch}
          />
        )}
        {expanded &&
          sortedSelections.map((county) => (
            <LocationCounty
              name={county}
              key={county}
              removable={removable}
              state={state}
              dispatch={dispatch}
            />
          ))}
      </div>
      <ListDivider />
    </>
  );
};

type LocationCountyProps = {
  name: string;
  state: string;
  removable?: boolean;
  dispatch: React.Dispatch<Action>;
};

const LocationCounty: React.FC<LocationCountyProps> = ({
  name,
  state,
  dispatch,
  removable,
}) => {
  const selectOrRemoveAll = (state: string) => ({
    [`(remove all ${state} counties)`]: {
      tag: "DeselectState",
      state,
    } as Action,
    [`(select all ${state} counties)`]: { tag: "SelectState", state } as Action,
  });

  return (
    <ListItem
      className="county"
      onClick={() =>
        !removable
          ? dispatch(
              selectOrRemoveAll(state)[name] || {
                tag: "SelectCounty",
                location: { state, county: name },
              }
            )
          : undefined
      }
    >
      <ListItemText primaryText={name} />
      {removable && (
        <ListItemGraphic
          graphic={
            <MaterialIcon
              onClick={() =>
                dispatch(
                  selectOrRemoveAll(state)[name] || {
                    tag: "DeselectCounty",
                    location: { state, county: name },
                  }
                )
              }
              icon="delete"
            />
          }
        />
      )}
    </ListItem>
  );
};
