export type FilterOptions<TFilter> = TFilter;

type CoreState<T, TFilter> = {
  items: T[];
  searchToken: string | null;
  filterOptions: TFilter;
  showVisibility: boolean;
  selectIdKey: string;
  selectedItems: T[];
};
type TaggedState<TTag extends string, T, TFilter> = { tag: TTag } & CoreState<
  T,
  TFilter
>;
type LoadingState<TTag extends string, T, TFilter> = TaggedState<
  TTag,
  T,
  TFilter
> & {
  loading: true;
  showVisibility: false;
};
type LoadedState<TTag extends string, T, TFilter> = TaggedState<
  TTag,
  T,
  TFilter
> & {
  loading: false;
};
export type Loading<T, TFilter> = LoadingState<"Loading", T, TFilter>;
export type LoadingMore<T, TFilter> = LoadingState<"LoadingMore", T, TFilter>;
export type Loaded<T, TFilter> = LoadedState<"Loaded", T, TFilter>;
export type EndOfData<T, TFilter> = LoadedState<"EndOfData", T, TFilter> & {
  showVisibility: false;
};

export type State<T, TFilter> =
  | Loading<T, TFilter>
  | LoadingMore<T, TFilter>
  | Loaded<T, TFilter>
  | EndOfData<T, TFilter>;

type TaggedAction<TTag extends string> = { tag: TTag };
type LoadedAction<TTag extends string, T> = TaggedAction<TTag> & {
  items: T[];
  searchToken: string | null;
};
export type DataLoaded<T> = LoadedAction<"DataLoaded", T>;
export type MoreDataLoaded<T> = LoadedAction<"MoreDataLoaded", T>;
export type SearchCriteriaChanged<TFilter> =
  TaggedAction<"SearchCriteriaChanged"> & {
    filterOptions: TFilter;
  };
export type ScrolledToBottom = TaggedAction<"ScrolledToBottom">;
export type Reset<TFilter> = TaggedAction<"Reset"> & {
  filterOptions: TFilter;
};

export type AddItem<T> = TaggedAction<"AddItem"> & { item: T; index?: number };
export type UpdateItem<T> = TaggedAction<"UpdateItem"> & { item: T };
export type UpdateItems<T> = TaggedAction<"UpdateItems"> & { items: T[] };

export type SelectItem<T> = TaggedAction<"SelectItem"> & {
  item: T;
};
export type SelectAllQueriedItems<T> = TaggedAction<"SelectAllQueriedItems"> & {
  items: T[];
};
export type UnselectItem = TaggedAction<"UnselectItem"> & {
  id: string | number;
};
export type ClearSelected = TaggedAction<"ClearSelected">;
export type SelectAll = TaggedAction<"SelectAll">;

export type Action<T, TFilter> =
  | DataLoaded<T>
  | MoreDataLoaded<T>
  | SearchCriteriaChanged<TFilter>
  | ScrolledToBottom
  | Reset<TFilter>
  | AddItem<T>
  | UpdateItem<T>
  | UpdateItems<T>
  | SelectItem<T>
  | SelectAllQueriedItems<T>
  | UnselectItem
  | ClearSelected
  | SelectAll;

function loadData<T, TFilter>(
  items: T[],
  searchToken: string | null,
  defaultFilter: TFilter,
  state: State<T, TFilter>
): State<T, TFilter> {
  const showVisibility = searchToken ? true : false;

  const newState = {
    ...state,
    items,
    searchToken,
    showVisibility,
    loading: false,
    filterOptions: defaultFilter,
  };

  return showVisibility
    ? ({ ...newState, tag: "Loaded" } as State<T, TFilter>)
    : ({ ...newState, tag: "EndOfData" } as State<T, TFilter>);
}

function isSelectAction<T, TFilter>(action: Action<T, TFilter>): boolean {
  return [
    "SelectItem",
    "UnselectItem",
    "SelectAll",
    "ClearSelected",
    "SelectAllQueriedItems",
  ].includes(action.tag);
}

function handleSelectAction<T, TFilter>(
  state: State<T, TFilter>,
  action: Action<T, TFilter>
): State<T, TFilter> {
  if (action.tag === "SelectItem") {
    const newState = {
      ...state,
      selectedItems: [...state.selectedItems, action.item],
    };
    return newState as State<T, TFilter>;
  }
  if (action.tag === "SelectAllQueriedItems") {
    const newState = {
      ...state,
      selectedItems: action.items,
    };
    return newState as State<T, TFilter>;
  }
  if (action.tag === "UnselectItem") {
    const newState = {
      ...state,
      selectedItems: state.selectedItems.filter(
        (x) => x[state.selectIdKey] !== action.id
      ),
    };
    return newState as State<T, TFilter>;
  }
  if (action.tag === "ClearSelected") {
    const newState = {
      ...state,
      selectedItems: [],
    };
    return newState as State<T, TFilter>;
  }
  if (action.tag === "SelectAll") {
    const newState = {
      ...state,
      selectedItems: state.items,
    };
    return newState as State<T, TFilter>;
  }
  return state;
}

function isItemsUpdateAction<T, TFilter>(action: Action<T, TFilter>): boolean {
  return ["AddItem", "UpdateItem", "UpdateItems"].includes(action.tag);
}

function handleItemsUpdate<T, TFilter>(
  state: State<T, TFilter>,
  action: Action<T, TFilter>
): State<T, TFilter> {
  if (action.tag === "UpdateItem") {
    return {
      ...state,
      items: [
        ...state.items.filter(
          (x) => x[state.selectIdKey] != action.item[state.selectIdKey]
        ),
        action.item,
      ],
    };
  }
  if (action.tag === "UpdateItems") {
    const ids = action.items.map((x) => x[state.selectIdKey]);

    return {
      ...state,
      items: [
        ...state.items.filter((x) => !ids.includes(x[state.selectIdKey])),
        ...action.items,
      ],
    };
  }
  if (action.tag === "AddItem") {
    if (action.index !== undefined) {
      return {
        ...state,
        items: [
          ...state.items.slice(0, action.index),
          action.item,
          ...state.items.slice(action.index),
        ],
      };
    }

    return {
      ...state,
      items: [...state.items, action.item],
    };
  }
  return state;
}

function reduceLoading<T, TFilter>(
  state: Loading<T, TFilter>,
  action: Action<T, TFilter>,
  defaultFilter: TFilter
): State<T, TFilter> {
  if (action.tag === "DataLoaded") {
    return loadData<T, TFilter>(
      action.items,
      action.searchToken,
      defaultFilter,
      state
    );
  }
  return state;
}

function reduceLoadingMore<T, TFilter>(
  state: LoadingMore<T, TFilter>,
  action: Action<T, TFilter>,
  defaultFilter: TFilter
): State<T, TFilter> {
  if (action.tag === "MoreDataLoaded") {
    return loadData<T, TFilter>(
      [...state.items, ...action.items],
      action.searchToken,
      defaultFilter,
      state
    );
  }
  return state;
}

function reduceLoaded<T, TFilter>(
  state: Loaded<T, TFilter>,
  action: Action<T, TFilter>
): State<T, TFilter> {
  if (action.tag === "SearchCriteriaChanged") {
    const newState = {
      ...state,
      tag: "Loading",
      loading: true,
      showVisibility: false,
      filterOptions: action.filterOptions,
      selectedItems: [],
    };
    return newState as State<T, TFilter>;
  }
  if (action.tag === "ScrolledToBottom") {
    const newState = {
      ...state,
      tag: "LoadingMore",
      loading: true,
      showVisibility: false,
    };
    return newState as State<T, TFilter>;
  }
  if (action.tag === "Reset") {
    const newState = {
      ...state,
      items: [],
      tag: "Loading",
      loading: true,
      filterOptions: action.filterOptions,
      selectedItems: [],
    };
    return newState as State<T, TFilter>;
  }

  if (isSelectAction(action)) {
    return handleSelectAction(state, action);
  }

  if (isItemsUpdateAction(action)) {
    return handleItemsUpdate(state, action);
  }

  return state;
}

function reduceEndOfData<T, TFilter>(
  state: EndOfData<T, TFilter>,
  action: Action<T, TFilter>
): State<T, TFilter> {
  if (action.tag === "SearchCriteriaChanged") {
    const newState = {
      ...state,
      tag: "Loading",
      loading: true,
      showVisibility: false,
      filterOptions: action.filterOptions,
      selectedItems: [],
    };
    return newState as State<T, TFilter>;
  }
  if (action.tag === "Reset") {
    const newState = {
      ...state,
      items: [],
      tag: "Loading",
      loading: true,
      filterOptions: action.filterOptions,
      selectedItems: [],
    };
    return newState as State<T, TFilter>;
  }

  if (isSelectAction(action)) {
    return handleSelectAction(state, action);
  }

  if (isItemsUpdateAction(action)) {
    return handleItemsUpdate(state, action);
  }

  return state;
}

export function reducer<T, TFilter>(
  state: State<T, TFilter>,
  action: Action<T, TFilter>
): State<T, TFilter> {
  if (state.tag === "Loading") {
    return reduceLoading<T, TFilter>(state, action, state.filterOptions);
  }
  if (state.tag === "LoadingMore") {
    return reduceLoadingMore<T, TFilter>(state, action, state.filterOptions);
  }
  if (state.tag === "Loaded") {
    return reduceLoaded<T, TFilter>(state, action);
  }
  if (state.tag === "EndOfData") {
    return reduceEndOfData<T, TFilter>(state, action);
  }
  return state;
}
