import React, { useState } from "react";
import Button from "~/button";
import Spinner from "~/spinner";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";

type ReceiptReportProps = {
  timesheetId: string;
  weekStart: string;
};

export const ReceiptReport: React.FC<ReceiptReportProps> = ({
  timesheetId,
  weekStart,
}) => {
  const [loading, setLoading] = useState(false);
  const downloadPdf = useReportFetcher();

  const body = {
    timesheets: [timesheetId],
  };

  const onClick = () => {
    setLoading(true);
    void downloadPdf({
      setLoading,
      path: "receipt-report",
      fileName: `${weekStart}-expense-receipts`,
      body,
    });
  };

  return (
    <>
      <Spinner open={loading} />
      <Button className="receipt-report-button" outlined onClick={onClick}>
        Receipts
      </Button>
    </>
  );
};
