import { FakeBroadcastChannel } from "~/broadcast-channel/fakeBroadCastChannel";

// This exists because BroadcastChannel doesn't exist in NodeJS where
// our tests run. In the browser we want to use the browser's

// implementation.
export function createBroadcastChannel(name: string): BroadcastChannel {
  if (window.BroadcastChannel) {
    // use browser implementation
    return new BroadcastChannel(name);
  }

  // use fake implementation
  const chan = new FakeBroadcastChannel(name);

  return chan as any as BroadcastChannel;
}
