import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNotificationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetNotificationQuery = { __typename?: 'QueryModel', notifications?: { __typename?: 'AppNotificationQueryModel', getNotifications?: { __typename?: 'NotificationsResult', count: number, notifications?: Array<{ __typename?: 'AppNotification', id: string, createdOn: any, subjectId: string, subjectType: Types.SubjectType, readOn?: any | null, recipient?: { __typename?: 'Employee', firstName: string, lastName: string, userPrincipalName: string } | null } | null> | null } | null } | null };


export const GetNotificationDocument = gql`
    query GetNotification {
  notifications {
    getNotifications {
      count
      notifications {
        id
        createdOn
        recipient {
          firstName
          lastName
          userPrincipalName
        }
        subjectId
        subjectType
        readOn
      }
    }
  }
}
    `;

/**
 * __useGetNotificationQuery__
 *
 * To run a query within a React component, call `useGetNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationQuery, GetNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationQuery, GetNotificationQueryVariables>(GetNotificationDocument, options);
      }
export function useGetNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationQuery, GetNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationQuery, GetNotificationQueryVariables>(GetNotificationDocument, options);
        }
export type GetNotificationQueryHookResult = ReturnType<typeof useGetNotificationQuery>;
export type GetNotificationLazyQueryHookResult = ReturnType<typeof useGetNotificationLazyQuery>;
export type GetNotificationQueryResult = Apollo.QueryResult<GetNotificationQuery, GetNotificationQueryVariables>;