import * as Types from '../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type ChangeServiceDescriptionActiveMutationVariables = Types.Exact<{
  input: Types.ChangeServiceDescriptionActiveInput;
}>;


export type ChangeServiceDescriptionActiveMutation = { __typename?: 'MutationModel', serviceDescriptions: { __typename?: 'ServiceDescriptionMutationModel', changeIsActive: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string }> } } };


export const ChangeServiceDescriptionActiveDocument = gql`
    mutation ChangeServiceDescriptionActive($input: ChangeServiceDescriptionActiveInput!) {
  serviceDescriptions {
    changeIsActive(input: $input) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type ChangeServiceDescriptionActiveMutationFn = Apollo.MutationFunction<ChangeServiceDescriptionActiveMutation, ChangeServiceDescriptionActiveMutationVariables>;

/**
 * __useChangeServiceDescriptionActiveMutation__
 *
 * To run a mutation, you first call `useChangeServiceDescriptionActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeServiceDescriptionActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeServiceDescriptionActiveMutation, { data, loading, error }] = useChangeServiceDescriptionActiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeServiceDescriptionActiveMutation(baseOptions?: Apollo.MutationHookOptions<ChangeServiceDescriptionActiveMutation, ChangeServiceDescriptionActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeServiceDescriptionActiveMutation, ChangeServiceDescriptionActiveMutationVariables>(ChangeServiceDescriptionActiveDocument, options);
      }
export type ChangeServiceDescriptionActiveMutationHookResult = ReturnType<typeof useChangeServiceDescriptionActiveMutation>;
export type ChangeServiceDescriptionActiveMutationResult = Apollo.MutationResult<ChangeServiceDescriptionActiveMutation>;
export type ChangeServiceDescriptionActiveMutationOptions = Apollo.BaseMutationOptions<ChangeServiceDescriptionActiveMutation, ChangeServiceDescriptionActiveMutationVariables>;