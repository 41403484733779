import {
  ExpenseApi,
  TimeEntryApi,
  ProjectTimeApi,
  TimeOffEntryApi,
  WorkDayApi,
  FormErrors,
  MutationsApi,
  WorkDay,
  MutationResponse,
  ValidationResponse,
} from "./WorkDayApiType";
import { createExpenseApi } from "./createExpenseApi";
import { createTimeEntryApi } from "./createTimeEntryApi";
import { createTimeOffEntryApi } from "./createTimeOffEntryApi";
import { createProjectTimeApi } from "./createProjectTimeApi";
import { createProjectTimeDailyApi } from "./editProjectTimeDailyApi";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Alert } from "~/snackbar";
import { PromptFn } from "~/confirmation-prompt";
import { NavigateFunction } from "react-router-dom";
import { Breadcrumb } from "~/main-layout/BreadcrumbProvider";
import { ApolloClient } from "@apollo/client";
import {
  myWorkDataRoute,
  myWorkDayRoute,
  reviewWorkDataRoute,
  reviewWorkDayRoute,
} from "~/WorkData/routes";
import { timesheetReview } from "~/visuals/pages/TimesheetReview/routes";
import { HandlePayableFn } from "./useOverridePayable";

type Depromise<T extends Promise<any>> = T extends Promise<infer TElem>
  ? TElem
  : never;
type MutationReturnType<T extends (any) => Promise<any>> = Depromise<
  ReturnType<T>
>;

type ValidatorsOf<T extends Record<string, (any) => Promise<any>>> = {
  [K in keyof T]?: (result: MutationReturnType<T[K]>) => boolean;
};

type Validators = ValidatorsOf<ExpenseApi> &
  ValidatorsOf<TimeEntryApi> &
  ValidatorsOf<ProjectTimeApi> &
  ValidatorsOf<TimeOffEntryApi>;

const expenseCreateApis: (keyof ExpenseApi)[] = [
  "createAtvExpense",
  "createFuelExpense",
  "createMileageExpense",
  "createMobileAllowanceExpense",
  "createPerDiemExpense",
  "createReceiptValuedExpense",
  "createTcpAllowanceExpense",
  "createAdHocExpense",
];

const expenseValidator = (x: FormErrors) => !!x.expenseId;

const toExpenseApiObjects = (apis: (keyof ExpenseApi)[], x: any) =>
  apis.reduce((o, k) => ({ ...o, [k]: x }), {});

const validators: Validators = {
  ...toExpenseApiObjects(expenseCreateApis, expenseValidator),
  createTimeEntry: (x) => !!x.timeEntryId,
  createTimeOffEntry: (x) => !!x.timeOffEntryId,
  createProjectTime: (x) => !!x.projectTimeId,
};

function wrapValidation<T>(
  api: T,
  wrap: (originalFn: T, validator: any) => any
): T {
  const wrapped = { ...api };

  [...Object.keys(api as Record<string, any>)].forEach((key) => {
    wrapped[key] = wrap(api[key], validators[key]);
  });

  return wrapped;
}

export function toFormErrors<TResponse extends ValidationResponse>(
  response: MutationResponse<TResponse>
): FormErrors {
  const errors = {};
  const responseErrors = response.response.errors ?? [];

  const arglessErrors = responseErrors
    .filter((x) => !x!.argumentName)
    .map((x) => x!.message)
    .join(" ");

  responseErrors.forEach((e) => {
    if (e!.argumentName) {
      errors[e!.argumentName] = e!.message;
    }
  });

  return {
    ...errors,
    ...(arglessErrors && { [FORM_ERROR]: arglessErrors }),
  };
}

function wrapWithFormErrors<
  TArgs extends Array<any>,
  TResponse extends ValidationResponse,
  TRet extends Promise<MutationResponse<TResponse>>
>(fn: (...args: TArgs) => TRet): (...args: TArgs) => Promise<FormErrors> {
  return async (...args) => {
    const response = await fn(...args);
    return toFormErrors(response);
  };
}

const wrapDelete = (fn, addAlert, prompt: PromptFn, name) => async (args) => {
  const promptResult = await prompt({
    cancelButtonText: "No, don't delete",
    confirmButtonText: "Yes, delete",
    message: `Are you sure you want to delete this ${name.toLowerCase()}?`,
    title: `Delete ${name}`,
  });

  if (promptResult !== "Confirm") {
    return { errors: [] };
  }

  const response = await fn(args);

  if (response.response.errors.length === 0) {
    return;
  }

  const messages = response.response.errors.map(({ argumentName, message }) =>
    argumentName ? `${argumentName}: ${message}` : message
  );

  addAlert({
    isSuccess: false,
    key: Object.values(args)[0] as string,
    message: messages.join(" "),
  });

  return response;
};

function injectUpn<TArgs extends Array<any>, TRet>(
  fn: (...args: TArgs) => TRet,
  upn
): (...args: TArgs) => TRet {
  if (!upn) {
    return fn;
  }
  return (...args: TArgs): TRet => {
    args[0].userPrincipalName = upn;
    return fn(...args);
  };
}

type CreateBreadcrumbsProps = {
  employee: { firstName: string; lastName: string };
  upn: string;
  date: string;
  mode: "Review" | "Mine";
};

export const createWorkDayBreadcrumbs = ({
  employee,
  upn,
  date,
  mode,
}: CreateBreadcrumbsProps): Breadcrumb[] => {
  const summaryText = employee
    ? `${employee.firstName} ${employee.lastName}`
    : "";

  const breadcrumbs =
    mode === "Review"
      ? [
          {
            text: "Timesheet Review",
            to: timesheetReview,
          },
          {
            text: `Timesheet Review: ${summaryText}`,
            to: reviewWorkDataRoute.toRoute(upn),
          },
          {
            text: `Work Day Review: ${date}`,
            to: reviewWorkDayRoute.toRoute(upn, date),
          },
        ]
      : [
          {
            text: `My Work Summary: ${summaryText}`,
            to: myWorkDataRoute.toRoute(upn),
          },
          {
            text: `My Work Day: ${date}`,
            to: myWorkDayRoute.toRoute(upn, date),
          },
        ];

  return breadcrumbs;
};

type WorkDayApiFunctions = {
  navigate: NavigateFunction;
  reload: () => Promise<unknown>;
  addAlert: React.Dispatch<Alert>;
  prompt: PromptFn;
  handlePayable: HandlePayableFn;
};

export const createWorkDayApi = (
  workDay: WorkDay,
  upn: string,
  mode: "Mine" | "Review",
  client: ApolloClient<any>,
  functions: WorkDayApiFunctions
): WorkDayApi => {
  const { reload, navigate, addAlert, prompt, handlePayable } = functions;

  function validateAndRedirect(rawFn, validateResult) {
    const workDayPath =
      mode === "Review"
        ? reviewWorkDayRoute.toRoute(upn, workDay.date)
        : myWorkDayRoute.toRoute(upn, workDay.date);

    return async (...args: Array<any>) => {
      const result = await rawFn(...args);

      const validated = validateResult ? validateResult(result.response) : true;

      if (result.response.errors.length === 0 && validated) {
        await reload();

        if (!result.callback) {
          navigate(workDayPath);
        } else {
          await result.callback(result.response);
        }
      }

      return result;
    };
  }

  const rawMutations = {
    ...wrapValidation(
      {
        ...createExpenseApi(client),
        ...createTimeEntryApi(client),
        ...createTimeOffEntryApi(client),
        ...createProjectTimeApi(client),
        ...createProjectTimeDailyApi(client),
      },
      validateAndRedirect
    ),
  };

  const mutations: MutationsApi = {
    ...rawMutations,
    deleteExpense: wrapDelete(
      rawMutations.deleteExpense,
      addAlert,
      prompt,
      "Expense"
    ),

    createAtvExpense: wrapWithFormErrors(
      injectUpn(rawMutations.createAtvExpense, upn)
    ),
    editAtvExpense: handlePayable(
      wrapWithFormErrors(rawMutations.editAtvExpense),
      workDay
    ),
    createFuelExpense: wrapWithFormErrors(
      injectUpn(rawMutations.createFuelExpense, upn)
    ),
    editFuelExpense: handlePayable(
      wrapWithFormErrors(rawMutations.editFuelExpense),
      workDay
    ),
    createMileageExpense: wrapWithFormErrors(
      injectUpn(rawMutations.createMileageExpense, upn)
    ),
    editMileageExpense: handlePayable(
      wrapWithFormErrors(rawMutations.editMileageExpense),
      workDay
    ),
    createMobileAllowanceExpense: wrapWithFormErrors(
      injectUpn(rawMutations.createMobileAllowanceExpense, upn)
    ),
    editMobileAllowanceExpense: handlePayable(
      wrapWithFormErrors(rawMutations.editMobileAllowanceExpense),
      workDay
    ),
    createPerDiemExpense: wrapWithFormErrors(
      injectUpn(rawMutations.createPerDiemExpense, upn)
    ),
    editPerDiemExpense: handlePayable(
      wrapWithFormErrors(rawMutations.editPerDiemExpense),
      workDay
    ),
    createReceiptValuedExpense: wrapWithFormErrors(
      injectUpn(rawMutations.createReceiptValuedExpense, upn)
    ),
    editReceiptValuedExpense: handlePayable(
      wrapWithFormErrors(rawMutations.editReceiptValuedExpense),
      workDay
    ),
    createTcpAllowanceExpense: wrapWithFormErrors(
      injectUpn(rawMutations.createTcpAllowanceExpense, upn)
    ),
    editTcpAllowanceExpense: handlePayable(
      wrapWithFormErrors(rawMutations.editTcpAllowanceExpense),
      workDay
    ),
    createAdHocExpense: wrapWithFormErrors(
      injectUpn(rawMutations.createAdHocExpense, upn)
    ),
    editAdHocExpense: handlePayable(
      wrapWithFormErrors(rawMutations.editAdHocExpense),
      workDay
    ),

    createTimeEntry: wrapWithFormErrors(
      injectUpn(rawMutations.createTimeEntry, upn)
    ),
    editTimeEntry: wrapWithFormErrors(rawMutations.editTimeEntry),
    deleteTimeEntry: wrapDelete(
      rawMutations.deleteTimeEntry,
      addAlert,
      prompt,
      "Time Entry"
    ),

    createProjectTime: wrapWithFormErrors(
      injectUpn(rawMutations.createProjectTime, upn)
    ),
    editProjectTime: wrapWithFormErrors(rawMutations.editProjectTime),
    deleteProjectTime: wrapDelete(
      rawMutations.deleteProjectTime,
      addAlert,
      prompt,
      "Project Time"
    ),

    createTimeOffEntry: wrapWithFormErrors(
      injectUpn(rawMutations.createTimeOffEntry, upn)
    ),
    editTimeOffEntry: wrapWithFormErrors(rawMutations.editTimeOffEntry),
    deleteTimeOffEntry: wrapDelete(
      rawMutations.deleteTimeOffEntry,
      addAlert,
      prompt,
      "Time Off Entry"
    ),
  };

  const { employee, date } = workDay;

  const breadcrumbs = createWorkDayBreadcrumbs({
    upn,
    employee: employee!,
    date,
    mode,
  });

  const api: WorkDayApi = {
    ...mutations,
    ...functions,
    workDay: workDay,
    breadcrumbs,
    upn,
    mode,
    isAdmin: mode === "Review",
  };

  return api;
};
