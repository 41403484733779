import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { useServiceDescriptionsAsync } from "~/refdata2/serviceDescriptions";
import { createSearchRegex } from "~/text-highlighter/createSearchRegex";
import { TextHighlighter } from "~/text-highlighter";
import _ from "lodash";

const format = (item: string, searchText: string): React.ReactNode => {
  const searchRegex = createSearchRegex(searchText);

  return (
    <div className="project-type-picker-menuitem">
      <TextHighlighter text={item} search={searchRegex} />
    </div>
  );
};

const chipFormat = (item: string) => item;

const ProjectTypePickerFormField: React.FC<FormFieldProps> = (props) => {
  const getItems = useServiceDescriptionsAsync();

  const getData = async (searchText: string) => {
    const result = (await getItems()) ?? [];

    const uniqueResults = _.uniqBy(result, "projectType");

    if (!searchText) {
      return uniqueResults.map((x) => x.projectType);
    }

    return uniqueResults
      .filter((t) =>
        t.projectType.toLowerCase().includes(searchText.toLowerCase())
      )
      .map((x) => x.projectType);
  };

  const args = {
    ...props,
    getData,
    format,
    chipFormat,
  } as TypeaheadProps<string>;

  return <TypeaheadFormField {...args} />;
};

export default ProjectTypePickerFormField;
