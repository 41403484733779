import React from "react";
import { Body1 } from "@material/react-typography";
import NoAuthTopoCard from "~/components/common/NoAuthTopoCard";
import { useLocation, useSearchParams } from "react-router-dom";
import { delayedReauthState, silentReauthState } from "../authClient";
import { silentPopupAuthRefresh } from "~/routes";

export const ValidatingMessage = "Validating login information...";

export const Validating: React.FC = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const path = searchParams.get("state") ?? "/";

  if (
    path === silentReauthState ||
    path === delayedReauthState ||
    location.pathname === silentPopupAuthRefresh.path
  ) {
    return <></>;
  }

  return (
    <NoAuthTopoCard>
      <Body1>{ValidatingMessage}</Body1>
    </NoAuthTopoCard>
  );
};
