import React, { useState } from "react";
import { Table, TableRow, TableCell } from "~/table";
import TitledCard from "~/titled-card";
import _ from "lodash";
import "./CrewCodeOverridesDisplay.scss";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { CrewCodeRecord } from "~/gql/types";
import { CrewCodeOverrideRow } from "./CrewCodeOverrideRow";
import { EditedCrewCode, UpdateCrewCodeAction } from "./types";
import { CrewCodeUpdateDialog } from "./CrewCodeUpdateDialog";
import { useDebounce } from "@react-hook/debounce";
import { NotifierForm } from "~/forms/NotifierForm";
import TextFormField from "~/text-form-field";
import { CrewCodeAddDialog } from "./CrewCodeAddDialog";

export type CrewCodeOverridesDisplayProps = {
  crewCodes: CrewCodeRecord[];
  refetch: () => void;
  runMutation: UpdateCrewCodeAction;
};

const SearchInput: React.FC<{
  setSearchText: React.Dispatch<string | null>;
}> = ({ setSearchText }) => {
  return (
    <NotifierForm
      values={{ search: null } as { search: string | null }}
      onChange={(vals) => setSearchText(vals.search)}
    >
      <TextFormField
        formField={"search"}
        label={"Search Crew Codes"}
        helperText={""}
      />
    </NotifierForm>
  );
};

export const CrewCodeOverridesDisplay: React.FC<
  CrewCodeOverridesDisplayProps
> = ({ crewCodes, refetch, runMutation }) => {
  useBreadcrumbs([{ text: "Intacct Crew Code Overrides" }], []);

  const [searchText, setSearchText] = useDebounce<string | null>("", 500);

  const matchesSearch = (x: string | null) => {
    if (!x) {
      return false;
    }

    return x!.toLowerCase().includes(searchText!.toLowerCase());
  };

  const filtered = searchText
    ? crewCodes.filter(
        (x) =>
          matchesSearch(x.crewCode) ||
          matchesSearch(x.overrideEmployeeId ?? null)
      )
    : crewCodes;

  const [editedCrewCode, setEditedCrewCode] = useState<EditedCrewCode | null>(
    null
  );

  const orderedCodes = _.chain(filtered)
    .orderBy((x) => x.crewCode)
    .value();

  const actions = (
    <>
      <CrewCodeAddDialog {...{ refetch, runMutation }} />
      <SearchInput {...{ setSearchText }} />
    </>
  );

  return (
    <div className="crewcode-overrides">
      <TitledCard title="Intacct Crew Code Overrides" actions={actions}>
        <Table columnCount={2} columnWidths="1fr 1fr">
          <TableRow header>
            <TableCell text="Crew Code" />
            <TableCell text="Override Employee Id" />
          </TableRow>
          {orderedCodes.map((crewCode, idx) => (
            <CrewCodeOverrideRow
              {...{
                crewCode,
                setEditedCrewCode,
                trailingSeperator: idx !== crewCodes.length - 1,
              }}
              key={idx}
            />
          ))}
        </Table>
      </TitledCard>
      <CrewCodeUpdateDialog
        {...{
          crewCodes,
          editedCrewCode,
          setEditedCrewCode,
          refetch,
          runMutation,
        }}
      />
    </div>
  );
};
