import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import React from "react";
import {
  useProjectFromParam,
  useProjectSearch,
} from "~/refdata/useProjectSearch";
import { Project } from "~/visuals/pages/Project/ProjectDetails/types";
import { FilterProviderProps } from "./types";
import ProjectPickerFormField from "~/visuals/organisms/ProjectPickerFormField";

export function useProjectProvider(props: FilterProviderProps): FilterProvider {
  const { documentationText: origDocText } = props;
  const projectFromParam = useProjectFromParam();
  const getProjects = useProjectSearch();

  const documentationText = `${origDocText} Use "proj" for quick filtering.`;

  return {
    render: () => (
      <ProjectPickerFormField
        formField="project"
        label="Project"
        helperText=""
        date=""
      />
    ),
    type: "project",
    label: "Project",
    documentationText,
    getSuggestions: async (text: string) => {
      if (!text) {
        return [];
      }
      const result = await getProjects({ searchText: text });
      if (!result?.records || result.records.length === 0) {
        return [];
      }
      return result.records.map((p) => ({
        text: `${p.name} (${p.number})`,
        value: p,
      }));
    },
    shouldSuggest: (text: string) => text.toLowerCase().startsWith("proj"),
    toFilter: (value: Project) => ({
      label: `Project: ${value.name} (${value.number})`,
      type: "project",
      value,
    }),
    filterFromParams: async (params: URLSearchParams) => {
      const paramProject = params.get("project") || null;

      const project = paramProject
        ? await projectFromParam(paramProject)
        : null;

      return project
        ? {
            label: `Project: ${project.name} (${project.number})`,
            value: project,
            type: "project",
          }
        : null;
    },
    toParam: (filter: ActiveFilter) => filter.value?.number ?? "",
  };
}
