import React from "react";
import { InlineTypeahead, InlineTypeaheadProps } from "~/inline-typeahead";
import { gql, useQuery } from "@apollo/client";

const enumTypeQuery = gql`
  query GetEnumType($enumType: String!) {
    __type(name: $enumType) {
      enumValues {
        name
        description
      }
    }
  }
`;

export type EnumType = {
  name: string;
  description: string;
};

const format = (item: EnumType) =>
  item?.description?.length > 0 ? item.description : item?.name;

export type InlineEnumPickerProps = {
  value: EnumType | null;
  name: string;
  onChange: (value: EnumType | null) => void;
  onBlur?: React.FocusEventHandler<any>;
  enumType: string;
  required?: boolean;
  submitError?: string;
  excluded?: string[];
};

export const InlineEnumPicker: React.FC<InlineEnumPickerProps> = (props) => {
  const { enumType, value, excluded, ...rest } = props;

  const { data } = useQuery(enumTypeQuery, { variables: { enumType } });
  const enumValues = (data?.__type?.enumValues ?? []).filter(
    (x) => !excluded || !excluded.includes(x.name)
  ) as EnumType[];

  const initialValue = value
    ? enumValues.find((x) => x.name === value.name) ?? null
    : null;

  const getData = async (searchText: string) => {
    if (!searchText) {
      return enumValues;
    }
    const lcText = searchText.toLowerCase();

    const result = Promise.resolve(
      enumValues.filter((x) => x.name.toLowerCase().startsWith(lcText))
    );
    return result;
  };

  const args = {
    ...rest,
    value: initialValue,
    getData,
    format,
  } as InlineTypeaheadProps<EnumType>;

  return <InlineTypeahead {...args} />;
};
