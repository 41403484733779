import React from "react";
import "./WarningMessage.scss";
import MaterialIcon from "@material/react-material-icon";
import cn from "classnames";

export type WarningMessageType = {
  message: string;
  icon: string;
  className?: string;
};

const WarningMessage: React.FC<WarningMessageType> = ({
  message,
  icon,
  className,
}) => (
  <span className={cn("warning-message mdc-typography", className)}>
    <MaterialIcon className={cn("warning-icon")} icon={icon} />
    {message}
  </span>
);

export default WarningMessage;
