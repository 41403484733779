import React, { useEffect } from "react";

const Updater: React.FC<{ value: any; callback: (val: any) => void }> = ({
  value,
  callback,
}) => {
  useEffect(() => {
    callback(value);
  }, [value]);

  return null;
};

export default Updater;
