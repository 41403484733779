import _ from "lodash";
import React from "react";
import "./TextHighlighter.scss";
import { useTextHighlighterRegexes } from "./TextHighlighterProvider";
export { createSearchRegex } from "./createSearchRegex";

export type TextHighlighterProps = {
  text: string;
  search?: RegExp | null;
};

export const TextHighlighter: React.FC<TextHighlighterProps> = ({
  text,
  search,
}) => {
  const propSearches = [search].map((x) => x as RegExp).filter((x) => x);
  const hookSearches = useTextHighlighterRegexes();
  const searches = [...propSearches, ...hookSearches];

  if (searches.length === 0) {
    return <>{text}</>;
  }

  let match: RegExpMatchArray | null = null;
  for (const expr of searches) {
    match = text.match(expr);

    if (match) {
      break;
    }
  }

  if (!match) {
    return <>{text}</>;
  }

  const startPos = match.index || 0;
  const matchLen = match[0].length;

  const pre = text.substr(0, startPos);
  const mid = text.substr(startPos, matchLen);
  const post = text.substr(startPos + matchLen);

  return (
    <>
      {pre}
      <span className="highlight">{mid}</span>
      {post}
    </>
  );
};
