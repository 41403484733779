type CoreState<T> = {
  text: string;
  loading: boolean;
  emptyResultsMessage?: string;
  onUserSelect?: (item: T) => void;
};

export type Unfocused<T> = CoreState<T> & {
  tag: "Unfocused";
  hasInputFocus: false;
  hasMenuMouse: false;
};

export type NotFound<T> = CoreState<T> & {
  tag: "NotFound";
  errorMessage: string;
  hasInputFocus: boolean;
  hasMenuMouse: boolean;
};

export type Focused<T> = CoreState<T> & {
  tag: "Focused";
  hasInputFocus: boolean;
  hasMenuMouse: boolean;
};

export type SuggestionsVisible<T> = CoreState<T> & {
  tag: "SuggestionsVisible";
  suggestionIndex: number;
  hasMenuMouse: boolean;
  hasInputFocus: boolean;
  suggestions: T[];
};

export type SuggestionsLoading<T> = CoreState<T> & {
  tag: "SuggestionsLoading";
  loading: true;
  hasMenuMouse: boolean;
  hasInputFocus: boolean;
};

export type Selected<T> = CoreState<T> & {
  tag: "Selected";
  selection: T;
};

export type State<T> =
  | Unfocused<T>
  | SuggestionsLoading<T>
  | Focused<T>
  | NotFound<T>
  | SuggestionsVisible<T>
  | Selected<T>;

export function isFocused<T>(x: State<T>): x is Focused<T> {
  return x.tag === "Focused";
}
export function isUnfocused<T>(x: State<T>): x is Unfocused<T> {
  return x.tag === "Unfocused";
}
export function isNotFound<T>(x: State<T>): x is NotFound<T> {
  return x.tag === "NotFound";
}
export function isSuggestionsVisible<T>(
  x: State<T>
): x is SuggestionsVisible<T> {
  return x.tag === "SuggestionsVisible";
}
export function isSuggestionsLoading<T>(
  x: State<T>
): x is SuggestionsVisible<T> {
  return x.tag === "SuggestionsLoading";
}
export function isSelected<T>(x: State<T>): x is Selected<T> {
  return x.tag === "Selected";
}
