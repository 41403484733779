import React from "react";
import { UltraFilter } from "~/ultra-filter";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import { useCustomerProvider } from "~/filterProviders/useCustomerProvider";
import { useInvoiceStateProvider } from "~/filterProviders/itemStateProviders";
import { useCurrencyProvider } from "~/filterProviders/useCurrencyProvider";
import { CustomRollupInvoiceSortBy } from "~/gql/types";
import { useSearchParams } from "react-router-dom";
import { CustomRollupInvoiceFilterOptions } from "./types";
import { useProjectGroupProvider } from "~/filterProviders/useProjectGroupProvider";
import { useBillingAdminProvider } from "~/filterProviders/employeeProviders";

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<CustomRollupInvoiceFilterOptions>;
};

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const [searchParams] = useSearchParams();

  const providers = [
    useCustomerProvider({
      documentationText: "The customer associated with the invoice's project.",
    }),
    useInvoiceStateProvider({
      documentationText: "The state that the invoice is in.",
    }),
    useCurrencyProvider({
      documentationText: "The invoice's total.",
      type: "amount",
      label: "Amount",
      suggestText: "amt",
    }),
    useProjectGroupProvider({
      documentationText: "The invoices's project group",
    }),
    useBillingAdminProvider({
      documentationText: "A billing admin assigned at the customer level",
    }),
  ] as FilterProvider[];

  const onChanged = (filters: ActiveFilter[], searchText: string) => {
    searchText;

    const newFilters = {
      searchText: searchText || null,
      customer: filters.find((f) => f.type === "customer")?.value || null,
      state: filters.find((f) => f.type === "invoiceState")?.value || null,
      amount: filters.find((f) => f.type === "amount")?.value || null,
      billingAdmin:
        filters.find((f) => f.type === "billingAdmin")?.value
          ?.userPrincipalName || null,
      sortBy: (searchParams.get("sortBy") ||
        "CustomerName") as CustomRollupInvoiceSortBy,
      projectGroup:
        filters.find((f) => f.type === "projectGroup")?.value || null,
    };

    onFiltersChanged(newFilters);
  };

  return (
    <div className="filter-form">
      <UltraFilter
        {...{
          providers,
          dialogTitle: "Custom Rollup Invoices Search",
          label: "Type to search invoices",
          onFiltersChanged: onChanged,
        }}
      />
    </div>
  );
};
