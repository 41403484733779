import MaterialIcon from "@material/react-material-icon";
import _ from "lodash";
import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { Product } from "~/gql/types2";
import { MruPicker, MruPickerProps } from "~/mru-picker";
import { useProductsAsync } from "~/refdata2/products";
import { createSearchRegex, TextHighlighter } from "~/text-highlighter";
import styles from "./ProductPicker.module.scss";
import { CreateProductButton } from "./CreateProductButton";
import { useForm } from "react-final-form";

export type ProductPickerProps = MruPickerProps<Product>;

type ProductMruItem = Product & { recent?: boolean };

const format = (item: ProductMruItem, searchText): React.ReactNode => {
  const searchRegex = createSearchRegex(searchText);

  return (
    <div className="menuitem">
      <TextHighlighter text={item.name} search={searchRegex} />
      {item.recent && <MaterialIcon icon="history" />}
    </div>
  );
};

type ProductPickerFormFieldProps = FormFieldProps & {
  CreateProductComponent?: typeof CreateProductButton;
};

export const ProductPickerFormField: React.FC<ProductPickerFormFieldProps> = (
  formFieldProps
) => {
  const getProducts = useProductsAsync();

  const fetchData = () => {
    return async (searchText: string) => {
      const allResults = (await getProducts()).filter((x) => x.isActive);

      if (!searchText) {
        return _.chain(allResults)
          .orderBy((x) => x.name)
          .take(20)
          .value();
      }

      const lcSearchText = searchText.toLowerCase();
      const filteredResults = _.chain(allResults)
        .filter((x) => x.name.toLowerCase().includes(lcSearchText))
        .orderBy((x) => x.name)
        .take(20)
        .value();

      return filteredResults;
    };
  };

  const form = useForm(formFieldProps.formField);

  const setFormValue = (product) => {
    form.change(formFieldProps.formField, product);
  };

  const CreateProductComponent =
    formFieldProps.CreateProductComponent ?? CreateProductButton;

  const props: ProductPickerProps = {
    chipFormat: (x) => x.name,
    fetchData,
    format,
    fieldsEqual: (a, b) => a.name === b.name,
    className: "product-picker",
    storageKey: "recent-products",
    ...formFieldProps,
  };

  return (
    <div className={styles.ProductPicker}>
      <MruPicker {...props} />
      <CreateProductComponent onCreated={setFormValue} />
    </div>
  );
};
