import { ApolloClient } from "@apollo/client";
import {
  CreateProjectTimeDocument,
  DeleteProjectTimeDocument,
  EditProjectTimeDocument,
} from "./projectTimeQueries.generated";
import {
  CreateProjectTimeArgs,
  DeleteProjectTimeArgs,
  EditProjectTimeArgs,
} from "./projectTimeTypes";
import { MutationFn, ValidationResponse } from "./WorkDayApiType";

export type RawProjectTimeApi = {
  createProjectTime: MutationFn<CreateProjectTimeArgs>;
  editProjectTime: MutationFn<EditProjectTimeArgs>;
  deleteProjectTime: MutationFn<DeleteProjectTimeArgs>;
};

export type CreateProjectTimeResponse = ValidationResponse & {
  projectTimeId: string;
};

export function createProjectTimeApi(
  client: ApolloClient<any>
): RawProjectTimeApi {
  return {
    createProjectTime: async (args, callback) => {
      const result = await client.mutate({
        mutation: CreateProjectTimeDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.createProjectTime as CreateProjectTimeResponse,
        callback,
      };
    },
    editProjectTime: async (args, callback) => {
      const result = await client.mutate({
        mutation: EditProjectTimeDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.editProjectTime as ValidationResponse,
        callback,
      };
    },
    deleteProjectTime: async (args, callback) => {
      const result = await client.mutate({
        mutation: DeleteProjectTimeDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.deleteProjectTime as ValidationResponse,
        callback,
      };
    },
  };
}
