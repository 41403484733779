import React from "react";
import Button from "~/button";
import FullPageCardLayout from "~/visuals/organisms/CustomerForm/FullPageCardLayout";
import { ErrorRenderer } from "~/forms/MutationForm";
import { Customer } from "~/gql/types";
import { useRelativeNav } from "~/relative-nav";
import BinaryPickerFormField from "../BinaryPickerFormField";
import { FormComponentProps } from "./types";
import { CustomerPicker } from "../CustomerPickerFormField";
import TextFormField from "~/text-form-field";
import BinaryPicker from "~/binary-picker";
import ErrorMessage from "~/error-message";
import EnumDropdownFormField from "../EnumDropdownFormField";

export const FormComponent: React.FC<
  FormComponentProps & { customer?: Customer }
> = (props) => {
  const { customer, isInternational, setIsInternational } = props;

  const title = customer
    ? `Edit Customer ${customer.number}`
    : "Create a New Customer";

  const navigate = useRelativeNav();

  return (
    <FullPageCardLayout className="new-customer" title={title}>
      <TextFormField
        label="Customer Name"
        helperText="The name of the customer. This will go on invoices."
        formField="name"
        required
      />
      <TextFormField
        label="Street Address 1"
        helperText="The street address"
        formField="address1"
        required
      />
      <TextFormField
        label="Street Address 2"
        helperText="The suite number or other secondary address information"
        formField="address2"
      />
      <TextFormField
        label={isInternational ? "City, Province/State, Postal Code" : "City"}
        helperText={
          isInternational
            ? "Format as shown in the customer's mailing address. Include city, province/state, and postal code as applicable"
            : ""
        }
        formField="city"
        required
      />
      <TextFormField label="Phone" helperText="" formField="phone" required />
      <TextFormField label="Fax" helperText="" formField="fax" />

      <BinaryPickerFormField
        label="Taxable in NM?"
        formField="nmTaxable"
        helperText=""
      />

      <BinaryPickerFormField
        label="Taxable in TX?"
        formField="txTaxable"
        helperText=""
      />

      <BinaryPicker
        label="Is International (Non-US)?"
        checked={isInternational}
        onChange={(x) => setIsInternational(x)}
      />
      {!isInternational && (
        <>
          <TextFormField
            label="State"
            helperText=""
            formField="state"
            required
          />
          <TextFormField label="ZIP" helperText="" formField="zip" required />
        </>
      )}
      {isInternational && (
        <TextFormField
          label="Country"
          helperText=""
          formField="country"
          required
        />
      )}

      <TextFormField label="Notes" helperText="" formField="notes" textarea />

      <TextFormField label="Attn" formField="attn" helperText="" />

      <EnumDropdownFormField
        label={"Electronic Invoicing System"}
        formField={"electronicInvoicingSystem"}
        helperText={""}
        enumType={"ElectronicInvoicingSystem"}
      />

      {customer && (
        <>
          <TextFormField
            label="Main Number"
            helperText=""
            formField="mainNumber"
            required
          />
          <TextFormField
            label="Sub Number"
            helperText=""
            formField="subNumber"
            required
          />
        </>
      )}

      {!customer && (
        <CustomerPicker
          label="Sub Account Of"
          helperText=""
          formField="subAccountOf"
        />
      )}

      <ErrorRenderer
        render={(error) => <ErrorMessage errorMessage={error} />}
      />

      <section className="actions">
        <Button primary data-testid="submit-button">
          {customer ? "Save" : "Create"}
        </Button>
        <Button onClick={() => navigate("../")}>Cancel</Button>
      </section>
    </FullPageCardLayout>
  );
};
