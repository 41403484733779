import { useSnackBar } from "~/snackbar";

export type DownloadFileProps = {
  linkHref: string;
  filename: string;
};

export function useDownloadFile(): (args: DownloadFileProps) => void {
  const alert = useSnackBar();

  return ({ linkHref, filename }: DownloadFileProps) => {
    const link = document.createElement("a");
    link.href = linkHref;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    alert({
      message: `${link.download} download complete`,
      key: link.download,
      isSuccess: true,
    });
  };
}
