import React, { useContext } from "react";
import { FieldState } from "./types";

type FormStateRendererProps = {
  render: (formState: Record<string, FieldState>) => React.ReactElement;
};

export const FormStateContext = React.createContext({});

export const FormStateRenderer = (props: FormStateRendererProps) => {
  const { render } = props;

  const formState = useContext(FormStateContext);
  return render(formState);
};
