import React from "react";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import ClickSwallower from "~/click-swallower";
import moment, { Moment } from "moment";
import { useSnackBar } from "~/snackbar";
import FormBottomRow from "~/form-bottom-row";
import { useWorkDayApi } from "~/WorkDayApi";
import Spinner from "~/spinner";
import { AdminNotes } from "../AdminNotes";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import {
  useCopyExpenseToDatesMutation,
  useCopyProjectTimeToDatesMutation,
  useCopyTimeEntryToDatesMutation,
  useCopyTimeOffToDatesMutation,
} from "./copyToDates.generated";
import BinaryPickerFormField from "../BinaryPickerFormField";
import { ListCheckBoxWrapper } from "./ListCheckBoxWrapper";
import { ValidationResponse } from "~/gql/types";

type CopyToDatesMutation =
  | "copyProjectTimeToDates"
  | "copyExpenseToDates"
  | "copyTimeEntryToDates"
  | "copyTimeOffToDates";

export type CopyToDatesProps = {
  open: boolean;
  onClosed: React.DispatchWithoutAction;
  oldDate: string;
  id: string;
  successFn?: () => void;
};

export const getDateStartAndDiff = (
  oldDate: string,
  today: Moment
): [Moment, number | null] => {
  const weekStart = moment(oldDate, "YYYY-MM-DD").startOf("week").local();
  const weekEnd = moment(weekStart).add(6, "days").local();

  const latest = today.isAfter(weekEnd)
    ? weekEnd
    : weekStart.isAfter(today)
    ? weekStart
    : today;

  const diff = latest.diff(weekStart, "day");

  const diffResult =
    today.format("YYYY-MM-DD") === oldDate && diff === 0 ? null : diff;
  return [weekStart, diffResult];
};

export const showCopyToDates = (oldDate: string): boolean => {
  const [_, diff] = getDateStartAndDiff(oldDate, moment().utc().local());

  return diff != null;
};

const getDatesFromVals = (values: Record<string, any>): string[] => {
  const dates: string[] = [];

  Object.entries(values).forEach(([k, v]) => {
    if (k !== "adminNotes" && v) {
      dates.push(k);
    }
  });

  return dates;
};

type CopyComponentProps = CopyToDatesProps & {
  handleSubmit: (values: Record<string, any>) => Promise<ValidationResponse>;
  loading: boolean;
  mutation: CopyToDatesMutation;
};

export const CopyProjectTimeToDates: React.FC<CopyToDatesProps> = (props) => {
  const { id } = props;

  const [copyProjectTime, { loading }] = useCopyProjectTimeToDatesMutation();

  const handleSubmit = async (values: Record<string, any>) => {
    const dates = getDatesFromVals(values);
    const variables = {
      dates,
      adminNotes: values.adminNotes || null,
      projectTimeId: id,
    };
    const result = await copyProjectTime({ variables });
    return result?.data?.timesheets
      ?.copyProjectTimeToDates as ValidationResponse;
  };

  return (
    <CopyComponent
      {...{
        ...props,
        handleSubmit,
        loading,
        mutation: "copyProjectTimeToDates",
      }}
    />
  );
};

export const CopyExpenseToDates: React.FC<CopyToDatesProps> = (props) => {
  const { id } = props;

  const [copyExpense, { loading }] = useCopyExpenseToDatesMutation();

  const handleSubmit = async (values: Record<string, any>) => {
    const dates = getDatesFromVals(values);
    const variables = {
      dates,
      adminNotes: values.adminNotes || null,
      expenseId: id,
    };
    const result = await copyExpense({ variables });
    return result?.data?.timesheets?.copyExpenseToDates as ValidationResponse;
  };

  return (
    <CopyComponent
      {...{
        ...props,
        handleSubmit,
        loading,
        mutation: "copyExpenseToDates",
      }}
    />
  );
};

export const CopyTimeEntryToDates: React.FC<CopyToDatesProps> = (props) => {
  const { id } = props;

  const [copyTime, { loading }] = useCopyTimeEntryToDatesMutation();

  const handleSubmit = async (values: Record<string, any>) => {
    const dates = getDatesFromVals(values);
    const variables = {
      dates,
      adminNotes: values.adminNotes || null,
      timeEntryId: id,
    };
    const result = await copyTime({ variables });
    return result?.data?.timesheets?.copyTimeEntryToDates as ValidationResponse;
  };

  return (
    <CopyComponent
      {...{
        ...props,
        handleSubmit,
        loading,
        mutation: "copyTimeEntryToDates",
      }}
    />
  );
};

export const CopyTimeOffToDates: React.FC<CopyToDatesProps> = (props) => {
  const { id } = props;

  const [copyTimeOff, { loading }] = useCopyTimeOffToDatesMutation();

  const handleSubmit = async (values: Record<string, any>) => {
    const dates = getDatesFromVals(values);
    const variables = {
      dates,
      adminNotes: values.adminNotes || null,
      timeOffEntryId: id,
    };
    const result = await copyTimeOff({ variables });
    return result?.data?.timesheets?.copyTimeOffToDates as ValidationResponse;
  };

  return (
    <CopyComponent
      {...{
        ...props,
        handleSubmit,
        loading,
        mutation: "copyTimeOffToDates",
      }}
    />
  );
};

const CopyComponent: React.FC<CopyComponentProps> = ({
  open,
  onClosed,
  oldDate,
  id,
  mutation,
  handleSubmit,
  loading,
  successFn,
}) => {
  const addAlert = useSnackBar();
  const { reload } = useWorkDayApi();

  const messageMap: Record<CopyToDatesMutation, string> = {
    copyProjectTimeToDates: "Project time entry",
    copyExpenseToDates: "Expense entry",
    copyTimeEntryToDates: "Time entry",
    copyTimeOffToDates: "Time off entry",
  };

  const [weekStart, diff] = getDateStartAndDiff(
    oldDate,
    moment().utc().local()
  );

  if (diff === null) {
    return null;
  }

  const selectableDates: Moment[] = [...new Array(diff + 1).keys()]
    .map((x) => moment(weekStart).add(x, "days"))
    .filter((x) => x.format("YYYY-MM-DD") !== oldDate);

  const onSuccess = ({ values }) => {
    const dates = getDatesFromVals(values);

    addAlert({
      key: `${id}`,
      message: `${messageMap[mutation]} ${id} copied to ${dates
        .map((x: string) => moment(x, "YYYY-MM-DD").format("MM/DD"))
        .join(", ")}`,
      isSuccess: true,
    });
    onClosed();
    if (reload) {
      void reload();
    }

    successFn && successFn();
  };

  const initialValues = {
    adminNotes: null,
  };

  selectableDates.forEach(
    (d) => (initialValues[d.format("YYYY-MM-DD")] = false)
  );
  const fields = selectableDates.map((x) => moment(x).format("YYYY-MM-DD"));

  return (
    <ClickSwallower>
      <Dialog
        className="move-item-to-date-dialog"
        open={open}
        onClose={onClosed}
        data-open={open}
        portalize
      >
        <DialogTitle>Copy To Dates</DialogTitle>
        <DialogContent>
          <Spinner open={loading} />
          {open && (
            <MutationForm
              onSuccess={onSuccess}
              runMutation={handleSubmit}
              initialValues={initialValues}
            >
              <ErrorRenderer
                render={(error) => (
                  <>
                    <ListCheckBoxWrapper>
                      {fields.map((x, idx) => (
                        <BinaryPickerFormField
                          formField={x}
                          label={moment(x, "YYYY-MM-DD").format(
                            "ddd MM/DD/YYYY"
                          )}
                          helperText={""}
                          key={idx}
                        />
                      ))}
                    </ListCheckBoxWrapper>
                    <AdminNotes />
                    <FormBottomRow
                      errorMessage={error}
                      buttonText="Save"
                      onCancel={onClosed}
                    />
                  </>
                )}
              />
            </MutationForm>
          )}
        </DialogContent>
      </Dialog>
    </ClickSwallower>
  );
};
