import {
  CreateProjectMutationVariables,
  useCreateProjectMutation,
} from "./create.generated";
import { useSnackBar } from "~/snackbar";
import { useNavigate } from "react-router-dom";
import { handleMutationResult } from "~/ProjectForm/handleMutationResult";
import { project } from "~/routes";
import { FormState } from "~/inline-fields-form/types";

type UseCreateProject = {
  create: (formState: FormState) => Promise<void>;
  loading: boolean;
};

export function useCreateProject(): UseCreateProject {
  const [doMutation, { loading }] = useCreateProjectMutation();

  const addAlert = useSnackBar();
  const navigate = useNavigate();

  const create = async (formState: FormState) => {
    const variables = Object.values(formState.fields).reduce(
      (a, c) => ({ ...a, ...c.toVariables!(c.value) }),
      {}
    ) as CreateProjectMutationVariables;

    const result = await doMutation({ variables });
    const data = result?.data?.projects?.createProject;

    handleMutationResult({
      data: result?.data?.projects?.createProject,
      response: result,
      onError: (errors) =>
        addAlert({
          message: errors.join(" "),
          isSuccess: false,
          key: `${Math.random()}`,
        }),
      onSuccess: () => {
        addAlert({
          message: `Project ${data!.projectNumber} created`,
          isSuccess: true,
          key: `${Math.random()}`,
        });

        navigate(project.toRoute(data!.projectNumber!).path);
      },
    });
  };

  return { create, loading };
}
