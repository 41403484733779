import React, { useState } from "react";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import moment from "moment";
import { ReportPopup } from "~/report-popup";
import { formattedDate } from "./utils";
import { FilterOptions } from "./types";

export const ChargesReports: React.FC<{ filters: FilterOptions | null }> = ({
  filters,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const downloadReport = useReportFetcher();

  const filterBody = {
    billingAdmin: filters?.billingAdmin?.userPrincipalName || null,
    projectSupervisor: filters?.projectSupervisor?.userPrincipalName || null,
    projectManager: filters?.projectManager?.userPrincipalName || null,
    employee: filters?.employee?.userPrincipalName || null,
    state: filters?.state?.toString() || null,
    projectNumber: filters?.project?.number || null,
    customerNumber: filters?.customer?.number || null,
    searchText: filters?.searchText || null,
    billable: filters?.billable || null,
    afeWoPo: filters?.afeWoPo || null,
    dateEqual: formattedDate(filters?.dateEqual || null),
    dateBefore: formattedDate(filters?.dateBefore || null),
    dateAfter: formattedDate(filters?.dateAfter || null),
  };

  const exportCsv = async () => {
    await downloadReport({
      setLoading,
      path: "project-charge-csv",
      fileName: `project-charges-${moment().format("MM-DD-YYYY")}`,
      body: filterBody,
      type: "text/csv;charset=utf-8;",
    });
    setOpen(false);
  };

  const exportManHoursMileageCsv = async () => {
    await downloadReport({
      setLoading,
      path: "man-hours-mileage-csv",
      fileName: `man-hours-mileage-${moment().format("MM-DD-YYYY")}`,
      body: filterBody,
      type: "text/csv;charset=utf-8;",
    });
    setOpen(false);
  };

  const exportPdf = async () => {
    await downloadReport({
      setLoading,
      path: "internal-billing-report",
      fileName: `project-charges-${moment().format("MM-DD-YYYY")}`,
      body: filterBody,
    });
    setOpen(false);
  };

  const items = [
    {
      label: "Excel Export",
      onClick: exportCsv,
      key: 1,
      dataItem: "excel-report",
    },
    {
      label: "PDF Report",
      onClick: exportPdf,
      key: 2,
      dataItem: "pdf-report",
    },
    {
      label: "Man Hours/Mileage",
      onClick: exportManHoursMileageCsv,
      key: 3,
      dataItem: "man-hours-mileage",
    },
  ];

  return (
    <ReportPopup
      {...{ items, loading, open, setOpen, label: "Reports" }}
      topMargin
    />
  );
};
