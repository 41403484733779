import React from "react";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import Card from "@material/react-card";
import "./FullPageCardLayout.scss";
import PageTitleRow from "~/page-title-row";
import { TwelveColumn } from "@material/react-layout-grid/dist/Cell";

export interface FullPageCardLayoutProps {
  title: string;
  actions?: React.ReactChild;
  className?: string;
  columns?: TwelveColumn;
}

const FullPageCardLayout: React.FC<FullPageCardLayoutProps> = ({
  children,
  title,
  actions,
  className,
  columns,
}) => {
  const cn = ["full-page-card-layout", className].filter((x) => x).join(" ");
  const columnOffset = (
    columns?.valueOf != null ? Math.floor((12 - columns) / 2) : 0
  ) as TwelveColumn;
  return (
    <Grid className={cn}>
      <PageTitleRow {...{ title, actions }} />
      <Row>
        <Cell columns={columnOffset}></Cell>
        <Cell columns={columns ?? 12} tabletColumns={8} phoneColumns={4}>
          <Card className="main-card">{children}</Card>
        </Cell>
        <Cell columns={columnOffset}></Cell>
      </Row>
    </Grid>
  );
};

export default FullPageCardLayout;
