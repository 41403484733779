import React, { useState, useEffect, useMemo } from "react";
import { useWorkDayApi } from "~/WorkDayApi";
import { useEffectiveExpenseRateSheetLazyQuery } from "~/queries/EffectiveExpenseRateSheetQuery.generated";
import { ExpenseRateSheet, IExpenseRate, Project } from "~/gql/types";
import { ExpenseRouter } from "~/visuals/organisms/Expenses/ExpenseRouter";
import { ExpenseFormType } from "~/visuals/organisms/Expenses/ExpenseForm/CommonTypes";

type NewExpenseEntryProps = {
  initialValues?: any;
};

const NewExpenseEntry: React.FC<NewExpenseEntryProps> = ({ initialValues }) => {
  const workDayApi = useWorkDayApi();
  const date = workDayApi.workDay.date;

  const [project, setProject] = useState<Project | null>(
    initialValues?.project ?? null
  );
  const [expenseRate, setExpenseRate] = useState<IExpenseRate | null>(
    initialValues?.expenseRate ?? null
  );

  const [getSheet, { data: rateSheetData, loading: rateSheetLoading }] =
    useEffectiveExpenseRateSheetLazyQuery();

  const rateSheet = useMemo<ExpenseRateSheet | null>(() => {
    if (!project) {
      return null;
    }
    return rateSheetData?.projects?.effectiveExpenseRateSheet || null;
  }, [rateSheetData, project]);

  useEffect(() => {
    if (project) {
      void getSheet({
        variables: {
          asOf: date,
          projectNumber: project.number,
        },
      });
    }
  }, [project, date, getSheet]);

  const createProps = {
    date: date,
    loading: rateSheetLoading,
    expenseRates: (rateSheet?.expenseRates ?? null) as IExpenseRate[] | null,
    project,
    setProject,
    expenseRate,
    setExpenseRate,
    initialValues,
    rateSheetName: rateSheet?.name,
    formType: "Create" as ExpenseFormType,
  };

  return <ExpenseRouter {...createProps} />;
};

export default NewExpenseEntry;
