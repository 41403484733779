import React, { useMemo } from "react";
import { Form } from "react-final-form";
import TimeEntryForm from "./TimeEntryForm";
import moment, { Duration } from "moment";
import CrewCodeRecord from "~/graphql/CrewCodeRecord";
import StateCounty from "~/graphql/StateCounty";
import createDecorator from "final-form-calculate";
import { Decorator } from "final-form";
import { FormEnterHandler } from "~/forms/FormEnterHandler";

type TimeEntryProps = {
  handleSubmit: (
    values: FormData
  ) => Promise<Record<string, unknown> | undefined>;
  initialValues?: FormData;
  type: "Create" | "Edit";
  date: string;
  loading: boolean;
  onDelete?: () => void;
  deleteError?: string;
};

export const initialNullValues = {
  crewCode: null,
  stateCounty: null,
  timeIn: null,
  lunchStart: null,
  timeOut: null,
  lunchEnd: null,
  adminNotes: null,
  copyToDate: false,
  copyToDates: false,
};

export type FormData = {
  crewCode: CrewCodeRecord | null;
  timeIn: Duration | null;
  timeOut: Duration | null;
  hoursWorked: Duration | null;
  lunchStart: Duration | null;
  lunchEnd: Duration | null;
  lunchHours: Duration | null;
  stateCounty: StateCounty | null;
  adminNotes: string | null;
  copyToDate: boolean;
  copyToDates: boolean;
};

const getDifference = (
  durationOne: Duration | null,
  durationTwo: Duration | null
) => {
  return moment.duration(
    durationOne && durationTwo
      ? durationOne.asMilliseconds() - durationTwo.asMilliseconds()
      : 0
  );
};

const TimeEntry: React.FC<TimeEntryProps> = ({
  handleSubmit,
  initialValues,
  type,
  date,
  loading,
  onDelete,
  deleteError,
}) => {
  const updateLunchHours = (value: any, allValues: any) => {
    value;
    const { lunchEnd, lunchStart } = allValues as FormData;
    return getDifference(lunchEnd, lunchStart);
  };

  const updateHoursWorked = (value: any, allValues: any) => {
    value;
    const { timeIn, timeOut, lunchHours } = allValues as FormData;
    const dayTotal = getDifference(timeOut, timeIn);
    return lunchHours ? getDifference(dayTotal, lunchHours) : dayTotal;
  };

  const calculator = useMemo(
    () =>
      createDecorator(
        {
          field: "lunchStart",
          updates: {
            lunchHours: updateLunchHours,
          },
        },
        {
          field: "lunchEnd",
          updates: {
            lunchHours: updateLunchHours,
          },
        },
        {
          field: "timeIn",
          updates: {
            hoursWorked: updateHoursWorked,
          },
        },
        {
          field: "timeOut",
          updates: {
            hoursWorked: updateHoursWorked,
          },
        },
        {
          field: "lunchHours",
          updates: {
            hoursWorked: updateHoursWorked,
          },
        }
      ) as Decorator<FormData, Record<string, unknown>>,
    []
  );

  return (
    <Form
      onSubmit={handleSubmit}
      decorators={[calculator]}
      initialValues={type === "Edit" ? initialValues : initialNullValues}
      render={({ handleSubmit, values, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TimeEntryForm
              hoursWorked={values.hoursWorked}
              lunchHours={values.lunchHours}
              timeIn={values.timeIn}
              timeOut={values.timeOut}
              crewCode={values.crewCode}
              type={type}
              stateCounty={values.stateCounty}
              errorMessage={submitError || deleteError}
              date={date}
              loading={loading}
              onDelete={onDelete}
            />
            <FormEnterHandler />
          </form>
        );
      }}
    />
  );
};

export default TimeEntry;
