import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { createSearchRegex } from "~/text-highlighter/createSearchRegex";
import { TextHighlighter } from "~/text-highlighter";
import { useWorkLocationsAsync } from "~/refdata2/workLocations";

const format = (item: string, searchText: string): React.ReactNode => {
  const searchRegex = createSearchRegex(searchText);

  return (
    <div className="work-location-picker-menuitem">
      <TextHighlighter text={item} search={searchRegex} />
    </div>
  );
};

const chipFormat = (item: string) => item;

const WorkLocatonPickerFormField: React.FC<FormFieldProps> = (props) => {
  const getLocations = useWorkLocationsAsync();

  const getData = async (searchText: string) => {
    if (!searchText) {
      return [];
    }
    const result = (await getLocations()) ?? [];
    return result.filter((l) =>
      l.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const args = {
    ...props,
    getData,
    format,
    chipFormat,
  } as TypeaheadProps<string>;

  return <TypeaheadFormField {...args} />;
};

export default WorkLocatonPickerFormField;
