import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditProjectTimeDailyMutationVariables = Types.Exact<{
  projectTimeId: Types.Scalars['String'];
  description: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  hours?: Types.InputMaybe<Array<Types.EditProjectTimeDailyCommandHoursInput> | Types.EditProjectTimeDailyCommandHoursInput>;
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EditProjectTimeDailyMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', editProjectTimeDaily?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const EditProjectTimeDailyDocument = gql`
    mutation EditProjectTimeDaily($projectTimeId: String!, $description: String!, $notes: String, $hours: [EditProjectTimeDailyCommandHoursInput!], $adminNotes: String) {
  timesheets {
    editProjectTimeDaily(
      projectTimeId: $projectTimeId
      description: $description
      notes: $notes
      hours: $hours
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditProjectTimeDailyMutationFn = Apollo.MutationFunction<EditProjectTimeDailyMutation, EditProjectTimeDailyMutationVariables>;

/**
 * __useEditProjectTimeDailyMutation__
 *
 * To run a mutation, you first call `useEditProjectTimeDailyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectTimeDailyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectTimeDailyMutation, { data, loading, error }] = useEditProjectTimeDailyMutation({
 *   variables: {
 *      projectTimeId: // value for 'projectTimeId'
 *      description: // value for 'description'
 *      notes: // value for 'notes'
 *      hours: // value for 'hours'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useEditProjectTimeDailyMutation(baseOptions?: Apollo.MutationHookOptions<EditProjectTimeDailyMutation, EditProjectTimeDailyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditProjectTimeDailyMutation, EditProjectTimeDailyMutationVariables>(EditProjectTimeDailyDocument, options);
      }
export type EditProjectTimeDailyMutationHookResult = ReturnType<typeof useEditProjectTimeDailyMutation>;
export type EditProjectTimeDailyMutationResult = Apollo.MutationResult<EditProjectTimeDailyMutation>;
export type EditProjectTimeDailyMutationOptions = Apollo.BaseMutationOptions<EditProjectTimeDailyMutation, EditProjectTimeDailyMutationVariables>;