import React from "react";

const ClickSwallower: React.FC<{ className?: string }> = ({
  className,
  children,
}) => (
  <div
    className={className}
    onClick={(e) => {
      if (e.currentTarget.closest("a")) {
        e.preventDefault();
      }
      e.stopPropagation();
    }}
  >
    {children}
  </div>
);

export default ClickSwallower;
