import React, { useMemo } from "react";
import { Division } from "~/refdata2/divisions";
import TextFormField from "~/text-form-field";
import FormBottomRow from "~/form-bottom-row";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import { useSnackBar } from "~/snackbar";
import { useUpdateDivisionMutation } from "./query.generated";
import Spinner from "~/spinner";
import { useRelativeNav } from "~/relative-nav";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { ValidationResponse } from "~/gql/types";

type DivisionFormProps = {
  division?: Division;
  refetch: React.DispatchWithoutAction;
};

type DivisionFormValues = {
  divisionId: string | null;
  name: string | null;
  isActive: boolean;
};

export const DivisionForm: React.FC<DivisionFormProps> = ({
  division,
  refetch,
}) => {
  const nav = useRelativeNav();

  const addAlert = useSnackBar();

  const initialValues: DivisionFormValues = useMemo(
    () =>
      division
        ? {
            divisionId: division.id,
            name: division.name,
            isActive: division.isActive,
          }
        : {
            divisionId: null,
            name: null,
            isActive: false,
          },
    [division]
  );

  const onSuccess = () => {
    addAlert({
      key: `${Math.random()}`,
      message: "Updated Division",
      isSuccess: true,
    });
    void refetch();
    nav("../");
  };

  const [doMutation, { loading: mutationPending }] =
    useUpdateDivisionMutation();

  const runMutation = async (values: DivisionFormValues) => {
    const variables = {
      divisionId: (division?.id ?? values.divisionId)!,
      name: values.name!,
      isActive: values.isActive,
    };

    const result = await doMutation({ variables });

    return result?.data?.divisions?.update as ValidationResponse;
  };

  return (
    <MutationForm
      initialValues={initialValues}
      onSuccess={onSuccess}
      runMutation={runMutation}
    >
      {!division && (
        <TextFormField
          formField="divisionId"
          label="Id"
          helperText="The id for the division"
          required
        />
      )}
      <TextFormField
        formField="name"
        label="Name"
        helperText="The division name"
        required
      />
      <BinaryPickerFormField
        formField="isActive"
        label="Is Active"
        helperText=""
      />

      <ErrorRenderer
        render={(error) => (
          <FormBottomRow
            buttonText="Save"
            onCancel={() => nav("../")}
            errorMessage={error}
          />
        )}
      />
      <Spinner open={mutationPending} />
    </MutationForm>
  );
};
