import React, { useEffect } from "react";
import { useForm } from "react-final-form";

export const FormSubmitter: React.FC<{ submitted: number }> = ({
  submitted,
}) => {
  const form = useForm();

  useEffect(() => {
    if (submitted > 0) {
      void form.submit();
    }
  }, [submitted]);

  return <></>;
};
