import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendHelpRequestMutationVariables = Types.Exact<{
  issue: Types.Scalars['String'];
  paths?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type SendHelpRequestMutation = { __typename?: 'MutationModel', helpRequest?: { __typename?: 'HelpRequestMutationModel', sendRequest?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const SendHelpRequestDocument = gql`
    mutation SendHelpRequest($issue: String!, $paths: [String!]) {
  helpRequest {
    sendRequest(issue: $issue, paths: $paths) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type SendHelpRequestMutationFn = Apollo.MutationFunction<SendHelpRequestMutation, SendHelpRequestMutationVariables>;

/**
 * __useSendHelpRequestMutation__
 *
 * To run a mutation, you first call `useSendHelpRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendHelpRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendHelpRequestMutation, { data, loading, error }] = useSendHelpRequestMutation({
 *   variables: {
 *      issue: // value for 'issue'
 *      paths: // value for 'paths'
 *   },
 * });
 */
export function useSendHelpRequestMutation(baseOptions?: Apollo.MutationHookOptions<SendHelpRequestMutation, SendHelpRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendHelpRequestMutation, SendHelpRequestMutationVariables>(SendHelpRequestDocument, options);
      }
export type SendHelpRequestMutationHookResult = ReturnType<typeof useSendHelpRequestMutation>;
export type SendHelpRequestMutationResult = Apollo.MutationResult<SendHelpRequestMutation>;
export type SendHelpRequestMutationOptions = Apollo.BaseMutationOptions<SendHelpRequestMutation, SendHelpRequestMutationVariables>;