import React, { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import { cloneTaskRateSheet, createTaskRateSheet } from "~/routes";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import Card from "@material/react-card";
import TaskRateSheets from "./TaskRateSheets";
import NewTaskRateSheet from "../NewTaskRateSheet";
import CloneTaskRateSheet from "../CloneTaskRateSheet";
import { useAllTaskRateSheetsQuery } from "./query.generated";
import { TaskRateSheet, ValidationError } from "~/gql/types";
import { useArchiveTaskRateSheetMutation } from "./archiveTaskSheet.generated";
import { RateSheetsContext } from "~/visuals/organisms/RateSheets/rateSheetsContext";
import { useCreateTaskRateSheetMutation } from "../NewTaskRateSheet/mutate.generated";
import { useCloneTaskRateSheetMutation } from "../CloneTaskRateSheet/mutate.generated";

type RouteProps = {
  rateSheets: TaskRateSheet[];
};

const Loading = () => (
  <Grid>
    <Row>
      <Cell columns={12}>
        <Card>loading...</Card>
      </Cell>
    </Row>
  </Grid>
);

export const TaskRateSheetsPageRoutes: React.FC<RouteProps> = ({
  rateSheets,
}) => {
  return (
    <Routes>
      <Route
        path={createTaskRateSheet.subPath}
        element={<NewTaskRateSheet />}
      />
      <Route path={cloneTaskRateSheet.subPath}>
        <Route path={":name"} element={<CloneTaskRateSheet />} />
      </Route>
      <Route path={"*"} element={<TaskRateSheets rateSheets={rateSheets} />} />
    </Routes>
  );
};

export default function TaskRateSheetsRouter(): ReactElement {
  const { data, loading, refetch: reload } = useAllTaskRateSheetsQuery();

  const rateSheets = (data && data?.taskRates?.sheets) ?? [];

  const [doArchive, { loading: archiveLoading }] =
    useArchiveTaskRateSheetMutation();

  const [doCreate, { loading: createLoading }] =
    useCreateTaskRateSheetMutation();
  const [doClone, { loading: cloneLoading }] = useCloneTaskRateSheetMutation();

  const create = async (name: string) => {
    const variables = { name };
    const result = await doCreate({ variables });

    return result;
  };

  const clone = async (newName: string, oldName: string) => {
    const variables = { newName, oldName };
    const result = await doClone({ variables });
    return result;
  };

  const archive = async (name: string) => {
    const variables = { rateSheetName: name };
    const result = await doArchive({ variables });

    return (result?.errors ??
      result?.data?.taskRates?.archiveRateSheet?.errors ??
      []) as ValidationError[];
  };

  if (data && rateSheets && !loading) {
    const api = {
      reload,
      archive,
      create,
      clone,
      mutationLoading: archiveLoading || createLoading || cloneLoading,
    };

    return (
      <RateSheetsContext.Provider value={api}>
        <TaskRateSheetsPageRoutes rateSheets={rateSheets as TaskRateSheet[]} />
      </RateSheetsContext.Provider>
    );
  }

  return <Loading />;
}
