import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StateOverheadCrewCodesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StateOverheadCrewCodesQuery = { __typename?: 'QueryModel', overheadCrewCodes?: { __typename?: 'OverheadCrewCodeQueryModel', stateCrewCodes?: Array<{ __typename?: 'StateOverheadCrewCode', state: string, crewCode: string } | null> | null } | null };


export const StateOverheadCrewCodesDocument = gql`
    query StateOverheadCrewCodes {
  overheadCrewCodes {
    stateCrewCodes {
      state
      crewCode
    }
  }
}
    `;

/**
 * __useStateOverheadCrewCodesQuery__
 *
 * To run a query within a React component, call `useStateOverheadCrewCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStateOverheadCrewCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStateOverheadCrewCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStateOverheadCrewCodesQuery(baseOptions?: Apollo.QueryHookOptions<StateOverheadCrewCodesQuery, StateOverheadCrewCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StateOverheadCrewCodesQuery, StateOverheadCrewCodesQueryVariables>(StateOverheadCrewCodesDocument, options);
      }
export function useStateOverheadCrewCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StateOverheadCrewCodesQuery, StateOverheadCrewCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StateOverheadCrewCodesQuery, StateOverheadCrewCodesQueryVariables>(StateOverheadCrewCodesDocument, options);
        }
export type StateOverheadCrewCodesQueryHookResult = ReturnType<typeof useStateOverheadCrewCodesQuery>;
export type StateOverheadCrewCodesLazyQueryHookResult = ReturnType<typeof useStateOverheadCrewCodesLazyQuery>;
export type StateOverheadCrewCodesQueryResult = Apollo.QueryResult<StateOverheadCrewCodesQuery, StateOverheadCrewCodesQueryVariables>;