import React from "react";
import { useEffect } from "react";
import { useTimesheetContext } from "./TimesheetContext";
import {
  CertifiedPayrollFilter,
  FilterOptions,
  ShowFutureFilter,
  Timesheet,
} from "./types";
import { Action } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { Moment } from "moment";
import {
  Employee,
  PayPeriod,
  TimesheetSearchReimbursementOption,
  TimesheetStatus,
} from "~/gql/types";
import { useApolloClient } from "@apollo/client";
import { GetTimesheetsDocument } from "./timesheetQuery.generated";

type LoadWeeksProps = {
  dispatch: React.Dispatch<Action<Timesheet, FilterOptions>>;
  tag: "DataLoaded" | "MoreDataLoaded";
  searchText: string | null;
  token: string | null;
  weekStart: Moment | null;
  dateBefore: Moment | null;
  dateAfter: Moment | null;
  workLocation: string | null;
  weekStatus: TimesheetStatus | null;
  reimbursement: TimesheetSearchReimbursementOption | null;
  payrollAdmin: Employee | null;
  payPeriod: PayPeriod | null;
  showFuture: ShowFutureFilter;
  certifiedPayroll: CertifiedPayrollFilter;
};

export const LoadWeeks = (props: LoadWeeksProps) => {
  const client = useApolloClient();

  const {
    dispatch,
    tag,
    searchText,
    weekStart,
    token,
    workLocation,
    weekStatus,
    dateBefore,
    dateAfter,
    reimbursement,
    payrollAdmin,
    payPeriod,
    showFuture,
    certifiedPayroll,
  } = props;

  const { mode, employee } = useTimesheetContext();

  const userPrincipalName =
    mode !== "AllTimesheets" ? employee?.userPrincipalName : null;

  useEffect(() => {
    void (async () => {
      const variables = {
        searchText,
        token,
        week: weekStart?.format("YYYY-MM-DD") ?? null,
        dateBefore: dateBefore?.format("YYYY-MM-DD") ?? null,
        dateAfter: dateAfter?.format("YYYY-MM-DD") ?? null,
        workLocation: workLocation,
        status: weekStatus,
        reimbursement,
        payrollAdmin: payrollAdmin?.userPrincipalName ?? null,
        payPeriod,
        userPrincipalName,
        showFuture: showFuture === "Yes",
        certifiedPayroll: certifiedPayroll === "Yes",
      };

      const result = await client.query({
        query: GetTimesheetsDocument,
        variables,
      });

      const newToken = result.data?.timesheets?.timesheetSearch?.token as
        | string
        | null;
      const newWeeks = result.data?.timesheets?.timesheetSearch
        ?.records as Timesheet[];
      dispatch({ tag, items: newWeeks, searchToken: newToken });
    })();
  }, []);

  return <></>;
};
