import React from "react";
import Employee from "~/graphql/Employee";
import { ImpersonationContext } from "./ImpersonationManager";
import { AuthenticationManagerContext } from "./AuthenticationManager/AuthenticationManagerDisplay";
import sign from "jwt-encode";
import { Action } from "./AuthenticationManager/stateMachine";
import { Authorizations, GetAuthorizationsAsync } from "./types";

export type MockUserProviderProps = {
  getAuthorizations?: GetAuthorizationsAsync;
  authorizations?: Partial<Authorizations>;
  impersonatedEmployee?: Employee;
  impersonatedAuths?: Authorizations;
  token?: string;
  dispatch?: React.Dispatch<Action>;
  setEmployee?: React.Dispatch<Employee | null>;
};

export const defaultMockUserEmail = "person@person.com";

const exampleToken = sign(
  { name: "Person Personson", email: defaultMockUserEmail },
  "secret"
);

export const defaultAuths = {
  approveCustomer: true,
  assignRateSheet: true,
  impersonate: true,
  manageInvoices: true,
  reviewWorkData: true,
  isMobileEmployee: true,
  usesWorkflowTimeEntry: true,
  reviewWorkWeeks: true,
  approveChargeAdjustments: true,
  editProjectCharges: true,
  editProject: true,
  manageFieldOffices: true,
  manageRateSheets: true,
  requestCustomer: true,
  viewBillables: true,
  viewCustomer: true,
  viewProjects: true,
  viewPayrollAdminData: true,
  approveThirdPartyInvoices: true,
  createThirdPartyInvoices: true,
} as Authorizations;

export const MockUserProvider: React.FC<MockUserProviderProps> = ({
  getAuthorizations,
  authorizations,
  token,
  impersonatedEmployee,
  impersonatedAuths,
  dispatch,
  setEmployee,
  children,
}) => {
  const allAuthorizations: Authorizations = {
    ...defaultAuths,
    ...(authorizations || {}),
  };

  return (
    <AuthenticationManagerContext.Provider
      value={{
        jwt: token ?? exampleToken,
        authorizations: allAuthorizations,
        dispatch: dispatch ?? (() => undefined),
      }}
    >
      <ImpersonationContext.Provider
        value={{
          getAuthorizations:
            getAuthorizations ?? (() => new Promise(() => allAuthorizations)),
          employee: impersonatedEmployee ?? null,
          setEmployee: setEmployee ?? (() => undefined),
          validating: false,
          setValidating: () => undefined,
          authorizations: impersonatedAuths ?? null,
          setAuthorizations: () => undefined,
        }}
      >
        {children}
      </ImpersonationContext.Provider>
    </AuthenticationManagerContext.Provider>
  );
};
