import React, { useState } from "react";
import ReceiptValuedForm from "~/visuals/organisms/Expenses/ExpenseForm/ReceiptValueTypeForm";
import { FORM_ERROR } from "final-form";
import { useWorkDayApi } from "~/WorkDayApi";
import {
  ReceiptValuedCategory,
  ReceiptValuedExpense,
  ReceiptValuedExpenseRate,
} from "~/gql/types";
import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";
import { ApiCallback } from "~/WorkDayApi/WorkDayApiType";

type NewReceiptValuedProps = BaseExpenseFormProps & {
  expenseRate: ReceiptValuedExpenseRate;
  expenseEntry?: ReceiptValuedExpense;
};

const NewReceiptValuedExpense: React.FC<NewReceiptValuedProps> = (props) => {
  const { project, expenseRate, initialValues, expenseEntry, date } = props;

  const [imageId, setImageId] = useState<string | null>(
    initialValues?.receiptImageId ?? null
  );

  const api = useWorkDayApi();

  const uniqueInitVals: UniqueInitVals = [
    {
      payerInformation: expenseEntry?.payerInformation,
      odometer: expenseEntry?.odometer,
      location: expenseEntry?.location ?? null,
      receiptTotal: expenseEntry?.receiptTotal,
      receiptImage: null,
      vehicleNumber: expenseEntry?.vehicleNumber,
    },
    {
      payerInformation: null,
      odometer: null,
      location: null,
      receiptTotal: null,
      receiptImage: null,
      vehicleNumber: null,
    },
  ];

  const parseNum = (val: any) => (val ? parseFloat(val) : null);

  const createExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc,
    callback?: ApiCallback
  ) => {
    const isLodging = expenseRate.category === ReceiptValuedCategory.Lodging;
    const location = isLodging ? values.location : project.location;

    if (imageId) {
      const res = await api.createReceiptValuedExpense(
        {
          ...defaultVals(values),
          date,
          payerInformation: values.payerInformation,
          odometer: parseNum(values.odometer),
          vehicleNumber: values.vehicleNumber,
          state: location.state,
          county: location.county,
          receiptTotal: parseNum(values.receiptTotal)!,
          receiptImageId: imageId,
        },
        callback
      );
      return res;
    }
    return { [FORM_ERROR]: "The image id cannot be null" };
  };

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        handleSubmit: createExpense,
        uniqueInitVals,
        expenseType: "Receipt Valued",
        formType: expenseEntry ? "Clone" : "Create",
      }}
    >
      <ReceiptValuedForm {...props} setImageId={setImageId} />
    </ExpensePageWrapper>
  );
};

export default NewReceiptValuedExpense;
