import React, { useContext } from "react";
import {
  BreadcrumbContext,
  Breadcrumb,
} from "~/main-layout/BreadcrumbProvider";
import { home } from "~/routes";
import "./BreadcrumbDisplay.scss";
import BreadcrumbItem from "~/breadcrumb-item";

const BreadcrumbDisplay: React.FC = () => {
  const context = useContext(BreadcrumbContext);

  if (!context) {
    throw new Error(
      "Do not use BreadcrumbDisplay outside of a BreadcrumbProvider"
    );
  }

  const { breadcrumbs } = context;

  return (
    <div className="breadcrumbs">
      {breadcrumbs.length > 0 && <BreadcrumbItem text="Home" to={home.path} />}
      {breadcrumbs &&
        breadcrumbs.map(({ text, to }: Breadcrumb) => (
          <BreadcrumbItem key={text} text={text} to={to} />
        ))}
    </div>
  );
};

export default BreadcrumbDisplay;
