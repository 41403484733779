import { ApolloClient } from "@apollo/client";
import createRefdataApi from "../createRefdataApi";
import {
  GetAllProductsQuery,
  GetAllProductsDocument,
} from "~/products/GetAllProducts.hotchoc.generated";
import { Product } from "~/gql/types2";

async function fetcher(client: ApolloClient<any>) {
  const result = await client.query<GetAllProductsQuery>({
    query: GetAllProductsDocument,
    context: { clientName: "graphql2" },
  });

  return result?.data?.products?.all as Product[];
}

const api = createRefdataApi(fetcher, "product");

export const ProductRefdataContext = api.Provider;
export const useProductsAsync = api.useDataAsync;
export const useProducts = api.useData;

export function useProductFromParam(): (
  code: string
) => Promise<Product | null> {
  const getProducts = useProductsAsync();

  return async (param: string) => {
    const result = await getProducts();
    const product = result?.find((x) => x.name === param);

    return product ?? null;
  };
}
