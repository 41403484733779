import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerSearchQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
  token?: Types.InputMaybe<Types.Scalars['String']>;
  projectNumber?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type CustomerSearchQuery = { __typename?: 'QueryModel', customers?: { __typename?: 'CustomersQueryModel', search?: { __typename?: 'ConnectionOfCustomer', token?: string | null, records?: Array<{ __typename?: 'Customer', status: Types.CustomerStatus, name: string, zip: string, country: string, state: string, address1: string, address2: string, city: string, fax: string, createCustomerRequestId: string, number?: string | null, simplifiedName: string, notes: string, phone: string, nmTaxable: boolean, electronicInvoicingSystem?: Types.ElectronicInvoicingSystem | null, stats?: { __typename?: 'CustomerStats', projectCount: number } | null } | null> | null } | null } | null };


export const CustomerSearchDocument = gql`
    query CustomerSearch($searchText: String, $token: String, $projectNumber: Int) {
  customers {
    search(searchText: $searchText, token: $token, projectNumber: $projectNumber) {
      token
      records {
        status
        name
        zip
        stats {
          projectCount
        }
        country
        state
        address1
        address2
        city
        fax
        createCustomerRequestId
        number
        simplifiedName
        notes
        phone
        nmTaxable
        electronicInvoicingSystem
      }
    }
  }
}
    `;

/**
 * __useCustomerSearchQuery__
 *
 * To run a query within a React component, call `useCustomerSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSearchQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      token: // value for 'token'
 *      projectNumber: // value for 'projectNumber'
 *   },
 * });
 */
export function useCustomerSearchQuery(baseOptions?: Apollo.QueryHookOptions<CustomerSearchQuery, CustomerSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerSearchQuery, CustomerSearchQueryVariables>(CustomerSearchDocument, options);
      }
export function useCustomerSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerSearchQuery, CustomerSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerSearchQuery, CustomerSearchQueryVariables>(CustomerSearchDocument, options);
        }
export type CustomerSearchQueryHookResult = ReturnType<typeof useCustomerSearchQuery>;
export type CustomerSearchLazyQueryHookResult = ReturnType<typeof useCustomerSearchLazyQuery>;
export type CustomerSearchQueryResult = Apollo.QueryResult<CustomerSearchQuery, CustomerSearchQueryVariables>;