import React from "react";
import { BulkActionWrapper } from "../BulkActions/BulkActionWrapper";
import ChangeChargeProjectForm from "~/charges-table/ChangeChargeProjectForm";
import { Action } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { ProjectCharge } from "~/gql/types";
import { ProjectChargesFilter } from "~/visuals/pages/ProjectCharges/types";

type BulkChangeProjectProps = {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  entries: ProjectCharge[];
  totalSelected: number;
  dispatch: React.Dispatch<Action<ProjectCharge, ProjectChargesFilter>>;
};

export const BulkChangeChargesProject: React.FC<BulkChangeProjectProps> = ({
  open,
  setOpen,
  entries,
  totalSelected,
  dispatch,
}) => {
  const className = "change-charges-project-dialog";
  const title = "Change Project for Selected Charges";

  return (
    <BulkActionWrapper {...{ open, setOpen, className, title }}>
      <ChangeChargeProjectForm
        {...{ entries, setOpen, totalSelected, dispatch }}
      />
    </BulkActionWrapper>
  );
};
