import React, { useState } from "react";
import Button from "~/button";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import Spinner from "~/spinner";

export const DownloadBundledPdf: React.FC<{ invoiceNumber: string }> = ({
  invoiceNumber,
}) => {
  const [loading, setLoading] = useState(false);

  const downloadReport = useReportFetcher();

  const getBundledPdf = async (includeNotes: boolean) => {
    await downloadReport({
      setLoading,
      path: "bundled-invoice",
      fileName: invoiceNumber,
      body: {
        invoiceNumbers: [invoiceNumber],
        includeNotes,
      },
    });
  };

  const getBundledWithNotes = async () => await getBundledPdf(true);
  const getBundledWithoutNotes = async () => await getBundledPdf(false);

  return (
    <>
      <Button onClick={getBundledWithNotes}>Bundled w/ Notes</Button>
      <Button onClick={getBundledWithoutNotes}>Bundled w/o Notes</Button>
      <Spinner open={loading} />
    </>
  );
};
