import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvoiceRollupQueryQueryVariables = Types.Exact<{
  invoiceNumber: Types.Scalars['String'];
}>;


export type InvoiceRollupQueryQuery = { __typename?: 'QueryModel', customRollupInvoices?: { __typename?: 'CustomRollupInvoiceQueryModel', single?: { __typename?: 'CustomRollupInvoice', invoiceNumber: string, projectGroupName: string, total: number, state: Types.InvoiceState, cancelledDate?: string | null, notes?: string | null, invoiceDate?: string | null, pdfDownloadUrl: string, lineItems?: Array<{ __typename?: 'CustomRollupInvoiceLineItem', total: number, invoice?: { __typename?: 'Invoice', invoiceNumber: string, state: Types.InvoiceState, total: number, project?: { __typename?: 'Project', number: number } | null } | null } | null> | null, projectGroup?: { __typename?: 'ProjectGroup', name: string, customer?: { __typename?: 'Customer', name: string, number?: string | null, canManageCri: boolean } | null, projects?: Array<{ __typename?: 'Project', number: number, name: string } | null> | null } | null } | null } | null };


export const InvoiceRollupQueryDocument = gql`
    query InvoiceRollupQuery($invoiceNumber: String!) {
  customRollupInvoices {
    single(invoiceNumber: $invoiceNumber) {
      lineItems {
        invoice {
          invoiceNumber
          project {
            number
          }
          state
          total
        }
        total
      }
      invoiceNumber
      projectGroupName
      projectGroup {
        customer {
          name
          number
          canManageCri
        }
        name
        projects {
          number
          name
        }
      }
      total
      state
      cancelledDate
      notes
      invoiceDate
      pdfDownloadUrl
    }
  }
}
    `;

/**
 * __useInvoiceRollupQueryQuery__
 *
 * To run a query within a React component, call `useInvoiceRollupQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceRollupQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceRollupQueryQuery({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *   },
 * });
 */
export function useInvoiceRollupQueryQuery(baseOptions: Apollo.QueryHookOptions<InvoiceRollupQueryQuery, InvoiceRollupQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceRollupQueryQuery, InvoiceRollupQueryQueryVariables>(InvoiceRollupQueryDocument, options);
      }
export function useInvoiceRollupQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceRollupQueryQuery, InvoiceRollupQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceRollupQueryQuery, InvoiceRollupQueryQueryVariables>(InvoiceRollupQueryDocument, options);
        }
export type InvoiceRollupQueryQueryHookResult = ReturnType<typeof useInvoiceRollupQueryQuery>;
export type InvoiceRollupQueryLazyQueryHookResult = ReturnType<typeof useInvoiceRollupQueryLazyQuery>;
export type InvoiceRollupQueryQueryResult = Apollo.QueryResult<InvoiceRollupQueryQuery, InvoiceRollupQueryQueryVariables>;