import { ValidationError } from "~/gql/types";

type HandleResultProps = {
  response: any;
  data: any;
  onError: (errors: ValidationError[]) => void;
  onSuccess: () => void;
};

export const handleMutationResult = (args: HandleResultProps): void => {
  const { response, onError, onSuccess, data } = args;

  const errors = (response?.errors ?? data?.errors ?? []) as ValidationError[];

  if (errors.length > 0) {
    onError(errors);
  } else {
    onSuccess();
  }
};
