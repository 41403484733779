import * as Types from '../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type ChangeProductStatusMutationVariables = Types.Exact<{
  input: Types.ChangeProductStatusCommand;
}>;


export type ChangeProductStatusMutation = { __typename?: 'MutationModel', products: { __typename?: 'ProductsMutationModel', changeStatus: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null }> } } };


export const ChangeProductStatusDocument = gql`
    mutation ChangeProductStatus($input: ChangeProductStatusCommand!) {
  products {
    changeStatus(input: $input) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type ChangeProductStatusMutationFn = Apollo.MutationFunction<ChangeProductStatusMutation, ChangeProductStatusMutationVariables>;

/**
 * __useChangeProductStatusMutation__
 *
 * To run a mutation, you first call `useChangeProductStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProductStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProductStatusMutation, { data, loading, error }] = useChangeProductStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeProductStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeProductStatusMutation, ChangeProductStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeProductStatusMutation, ChangeProductStatusMutationVariables>(ChangeProductStatusDocument, options);
      }
export type ChangeProductStatusMutationHookResult = ReturnType<typeof useChangeProductStatusMutation>;
export type ChangeProductStatusMutationResult = Apollo.MutationResult<ChangeProductStatusMutation>;
export type ChangeProductStatusMutationOptions = Apollo.BaseMutationOptions<ChangeProductStatusMutation, ChangeProductStatusMutationVariables>;