import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkReadNotificationMutationVariables = Types.Exact<{
  notificationId: Types.Scalars['String'];
}>;


export type MarkReadNotificationMutation = { __typename?: 'MutationModel', notifications?: { __typename?: 'AppNotificationMutationModel', markRead?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };

export type MarkAllReadNotificationMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type MarkAllReadNotificationMutation = { __typename?: 'MutationModel', notifications?: { __typename?: 'AppNotificationMutationModel', markAllRead?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const MarkReadNotificationDocument = gql`
    mutation MarkReadNotification($notificationId: String!) {
  notifications {
    markRead(notificationId: $notificationId) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type MarkReadNotificationMutationFn = Apollo.MutationFunction<MarkReadNotificationMutation, MarkReadNotificationMutationVariables>;

/**
 * __useMarkReadNotificationMutation__
 *
 * To run a mutation, you first call `useMarkReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markReadNotificationMutation, { data, loading, error }] = useMarkReadNotificationMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useMarkReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<MarkReadNotificationMutation, MarkReadNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkReadNotificationMutation, MarkReadNotificationMutationVariables>(MarkReadNotificationDocument, options);
      }
export type MarkReadNotificationMutationHookResult = ReturnType<typeof useMarkReadNotificationMutation>;
export type MarkReadNotificationMutationResult = Apollo.MutationResult<MarkReadNotificationMutation>;
export type MarkReadNotificationMutationOptions = Apollo.BaseMutationOptions<MarkReadNotificationMutation, MarkReadNotificationMutationVariables>;
export const MarkAllReadNotificationDocument = gql`
    mutation MarkAllReadNotification {
  notifications {
    markAllRead {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type MarkAllReadNotificationMutationFn = Apollo.MutationFunction<MarkAllReadNotificationMutation, MarkAllReadNotificationMutationVariables>;

/**
 * __useMarkAllReadNotificationMutation__
 *
 * To run a mutation, you first call `useMarkAllReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllReadNotificationMutation, { data, loading, error }] = useMarkAllReadNotificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<MarkAllReadNotificationMutation, MarkAllReadNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAllReadNotificationMutation, MarkAllReadNotificationMutationVariables>(MarkAllReadNotificationDocument, options);
      }
export type MarkAllReadNotificationMutationHookResult = ReturnType<typeof useMarkAllReadNotificationMutation>;
export type MarkAllReadNotificationMutationResult = Apollo.MutationResult<MarkAllReadNotificationMutation>;
export type MarkAllReadNotificationMutationOptions = Apollo.BaseMutationOptions<MarkAllReadNotificationMutation, MarkAllReadNotificationMutationVariables>;