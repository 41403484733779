import React, { useContext, useRef } from "react";
import { BreadcrumbContext } from "~/main-layout/BreadcrumbProvider";
import MaterialIcon from "@material/react-material-icon";
import TopAppBar, {
  TopAppBarIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from "@material/react-top-app-bar";
import "./TopNavBar.scss";
import TopBarActionMenu from "~/main-layout/TopBarActionMenu";
import BreadcrumbDisplay from "~/visuals/organisms/BreadcrumbDisplay";
import classnames from "classnames";
import { VisibilityListenerProvider } from "~/visibility-listener";
import { Notifications } from "~/notifications";
import { WolfkrowUser } from "~/Auth/WolfkrowUser";
import { GlobalSearch } from "~/main-layout/GlobalSearch";
export type TopNavBarProps = {
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<boolean>;
  children: React.ReactNode;
  actionMenuOpen: boolean;
  coordinates:
    | {
        x: number;
        y: number;
      }
    | undefined;
  user: WolfkrowUser;
  onActionMenuClick: (e: any) => void;
};

const TopNavBar: React.FC<TopNavBarProps> = (props) => {
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    children,
    actionMenuOpen,
    coordinates,
    user,
    onActionMenuClick,
  } = props;

  const { breadcrumbs } = useContext(BreadcrumbContext);
  const ref = useRef<HTMLElement>(null);

  const mainAreaClasses = classnames("main-layout", {
    "has-breadcrumbs": breadcrumbs && breadcrumbs.length > 0,
  });

  return (
    <>
      <TopAppBar>
        <TopAppBarRow>
          <TopAppBarSection align="start">
            <TopAppBarIcon navIcon tabIndex={0}>
              <MaterialIcon
                hasRipple
                icon="menu"
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              />
            </TopAppBarIcon>
            <TopAppBarTitle>Workflow</TopAppBarTitle>
          </TopAppBarSection>
          <TopAppBarSection>
            <GlobalSearch />
          </TopAppBarSection>
          <TopAppBarSection align="end">
            {user.isImpersonating ? (
              <>
                <TopAppBarIcon navIcon tabIndex={1}>
                  <MaterialIcon
                    role="end-impersonation"
                    hasRipple
                    className="end_impersonation mdc-menu-surface--anchor"
                    onClick={(e) => onActionMenuClick(e)}
                    icon="warning"
                  />
                </TopAppBarIcon>
                <TopBarActionMenu
                  open={actionMenuOpen}
                  coordinates={coordinates || { x: 0, y: 0 }}
                >
                  {children}
                </TopBarActionMenu>
              </>
            ) : null}
            <Notifications />
          </TopAppBarSection>
        </TopAppBarRow>
        <BreadcrumbDisplay />
      </TopAppBar>
      <main className={mainAreaClasses} ref={ref}>
        <VisibilityListenerProvider containerRef={ref}>
          {children}
        </VisibilityListenerProvider>
      </main>
    </>
  );
};

export default TopNavBar;
