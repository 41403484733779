import React, { useEffect, useState } from "react";
import Spinner from "~/spinner";
import { ChargeType, Employee } from "~/gql/types";
import { useSetRateAmount } from "~/project-charge-form/useSetRateAmount";
import { useSplitChargeFormField } from "./fieldUtils";

type RateLoaderProps = RateAmountFieldProps & {
  setRateAmount: React.Dispatch<string | null>;
  setLoading: React.Dispatch<boolean>;
};

const RateAmountSetter: React.FC<RateLoaderProps> = ({
  date,
  type,
  employee,
  setRateAmount,
  setLoading,
}) => {
  const project = useSplitChargeFormField("project").value;
  const { value: rateValue, initial: initialRate } =
    useSplitChargeFormField("rate");
  const { value: sheetValue, initial: initialSheet } =
    useSplitChargeFormField("rateSheet");

  const setAmount = useSetRateAmount();

  useEffect(() => {
    if (
      !project ||
      !rateValue ||
      (rateValue === initialRate && sheetValue === initialSheet)
    ) {
      return;
    }

    void setAmount({
      project,
      date,
      rate: rateValue,
      type,
      employee: employee ?? null,
      setRateAmount,
      setLoading,
    });
  }, [rateValue]);

  return <></>;
};

export type RateAmountFieldProps = {
  date: string;
  type: ChargeType;
  employee: Employee | null;
};

export const RateAmountField: React.FC<RateAmountFieldProps> = (props) => {
  const { onChange, value } = useSplitChargeFormField("rateAmount");

  const [loading, setLoading] = useState(false);
  const [rateAmount, setRateAmount] = useState<string | null>(value);

  useEffect(() => {
    onChange(rateAmount);
  }, [rateAmount]);

  useEffect(() => {
    if (value !== rateAmount) {
      setRateAmount(value);
    }
  }, [value]);

  return (
    <>
      <Spinner open={loading} />
      <RateAmountSetter {...{ setRateAmount, setLoading, ...props }} />
    </>
  );
};
