import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { EmployeeSortBy } from "~/gql/types";
import { SimplePicker } from "~/visuals/organisms/SimplePicker";
import { Suggestion } from "~/ultra-filter/types";

export const employeeSortOptions = [
  { value: EmployeeSortBy.LastName, text: "Last Name" },
  { value: EmployeeSortBy.FirstName, text: "First Name" },
  { value: EmployeeSortBy.IsTerminated, text: "Employment Status" },
  { value: EmployeeSortBy.WorkLocation, text: "Work Location" },
] as Suggestion[];

export const EmployeeSortPicker: React.FC<FormFieldProps> = (props) => (
  <SimplePicker {...{ ...props, options: employeeSortOptions }} />
);
