import React from "react";
import TitledCard from "~/titled-card";
import List, { ListItem } from "@material/react-list";
import { Link } from "react-router-dom";
import moment from "moment";
import { useUser } from "~/Auth/useUser";
import { myWorkDayRoute, weeklyProjectTasks } from "~/WorkData/routes";
import { myExpenses } from "~/routes";
import { createProjectTimeEntry } from "~/visuals/organisms/WorkDay/ProjectTimeCard";
import { createTimeEntry } from "~/visuals/organisms/WorkDay/TimeCard";
import { createTimeOffEntry } from "~/visuals/organisms/WorkDay/TimeOffCard";

const WorkLink: React.FC<{ to: string; text: string }> = ({ to, text }) => (
  <Link to={to}>
    <ListItem>{text}</ListItem>
  </Link>
);

export const MyWorkCard: React.FC = () => {
  const today = moment().startOf("day");
  const todayString = today.format("YYYY-MM-DD");

  const weekStart = today.startOf("week");
  const startString = weekStart.format("YYYY-MM-DD");

  const user = useUser();
  const upn = user.email?.toLowerCase();

  const myWorkDayPath = myWorkDayRoute.toRoute(upn, todayString);

  return (
    <TitledCard title="My Work">
      <List>
        <WorkLink
          to={myWorkDayRoute.toRoute(upn, todayString)}
          text="Current work day"
        />
        <WorkLink
          to={`my-work/${upn}/${weeklyProjectTasks}/${startString}`}
          text="Current week's weekly project tasks"
        />
        <WorkLink to={myExpenses.path} text="My expenses" />
        <WorkLink
          to={`${myWorkDayPath}/${createProjectTimeEntry}`}
          text="Add project time to current day"
        />
        <WorkLink
          to={`${myWorkDayPath}/${createTimeEntry}`}
          text="Add time entry to current day"
        />
        <WorkLink
          to={`${myWorkDayPath}/${createTimeOffEntry}`}
          text="Add time off to current day"
        />
      </List>
    </TitledCard>
  );
};
