import React, { useState } from "react";
import { BadDebtWriteOff, Customer as CustomerModel } from "~/gql/types";
import TitledCard from "~/titled-card";
import { AddBadDebt } from "../AddBadDebt";
import { Table, TableRow, TableCell } from "~/table";
import { currencyFormatter } from "~/currencyFormatter";

type BadDebtProps = {
  badDebtWriteOff: BadDebtWriteOff;
};

export const CustomerBadDebt: React.FC<{ customer: CustomerModel }> = ({
  customer,
}) => {
  const badDebts = (customer.badDebtWriteOffs ?? []) as BadDebtWriteOff[];
  const [open, setOpen] = useState<boolean>(false);

  return (
    <TitledCard
      title="Bad Debt"
      actions={
        <AddBadDebt {...{ open, setOpen, customerNumber: customer.number! }} />
      }
    >
      <Table {...{ columnCount: 3, columnWidths: "1fr 1fr" }}>
        <TableRow header>
          <TableCell text="Date" />
          <TableCell text="Amount" />
        </TableRow>
        {badDebts.map((d, idx) => (
          <BadDebtRow key={idx} badDebtWriteOff={d} />
        ))}
      </Table>
    </TitledCard>
  );
};

export const BadDebtRow: React.FC<BadDebtProps> = ({ badDebtWriteOff }) => {
  const { amount, date } = badDebtWriteOff;
  return (
    <TableRow className="customer-bad-debt-row">
      <TableCell className="badDebt" text={date} />
      <TableCell className="badDebt" text={currencyFormatter.format(amount)} />
    </TableRow>
  );
};
