import React, { useEffect } from "react";
import { Dialog, DialogTitle } from "~/dialog";
import TextFormField from "~/text-form-field";
import FormBottomRow from "~/form-bottom-row";
import { useSnackBar } from "~/snackbar";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { useUpdateServiceDescriptionMutation } from "./UpdateServiceDescription.hotchoc.generated";
import { useInvalidateServiceDescriptions } from "~/refdata2/serviceDescriptions";
import { ValidationResponse } from "~/gql/types";

export type EditedItemState = {
  industry: string;
  projectType: string;
  description: string;
  legacyWorkCode: string;
  previousDisplayName: string | null;
  dialogClosing: boolean;
};

export type ServiceDescriptionEditorProps = {
  editedItem: EditedItemState | null;
  onClose: React.DispatchWithoutAction;
  onLoading: React.Dispatch<boolean>;
};

export const ServiceDescriptionEditor: React.FC<
  ServiceDescriptionEditorProps
> = ({ editedItem, onClose, onLoading }) => {
  const [doMutation, { loading }] = useUpdateServiceDescriptionMutation();
  const invalidate = useInvalidateServiceDescriptions();

  const addAlert = useSnackBar();

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  const onSuccess = () => {
    addAlert({
      key: `${Math.random()}`,
      message: "Updated Service Description",
      isSuccess: true,
    });
    invalidate();
    onClose();
  };

  const runMutation = async (values: EditedItemState) => {
    const result = await doMutation({
      variables: {
        input: {
          industry: values.industry,
          projectType: values.projectType,
          description: values.description,
          legacyWorkCode: values.legacyWorkCode,
          previousDisplayName: values.previousDisplayName,
        },
      },
    });

    return result?.data?.serviceDescriptions?.update as ValidationResponse;
  };

  return (
    <Dialog open={!!editedItem && !editedItem.dialogClosing} onClose={onClose}>
      <DialogTitle>
        {editedItem?.previousDisplayName
          ? "Edit Service Description"
          : "Create Service Description"}
      </DialogTitle>
      <MutationForm
        initialValues={editedItem ?? ({} as EditedItemState)}
        onSuccess={onSuccess}
        runMutation={runMutation}
      >
        <TextFormField
          formField="industry"
          label="Industry"
          helperText="The industry that this kind of service pertains to"
          required
        />
        <TextFormField
          formField="projectType"
          label="Project Type"
          helperText="The project type"
          required
        />
        <TextFormField
          formField="description"
          label="Description"
          helperText="The service description"
          required
        />
        <TextFormField
          formField="legacyWorkCode"
          label="Legacy Work Code"
          helperText="The work code from the legacy Workflow system that corresponds to this type of job"
          required
        />

        <ErrorRenderer
          render={(error) => (
            <FormBottomRow
              buttonText="Save"
              onCancel={() => onClose()}
              errorMessage={error}
            />
          )}
        />
      </MutationForm>
    </Dialog>
  );
};
