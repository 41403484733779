import React, { useMemo } from "react";
import PageGrid from "~/visuals/templates/PageGrid";
import Button from "~/button";
import { Cell } from "@material/react-layout-grid";
import moment, { Moment } from "moment";
import "./WorkDay.scss";
import { useWorkDayApi } from "~/WorkDayApi";
import { TimeEntryRow, TimeCard } from "./TimeCard";
import { TimeOffRow, TimeOffCard } from "./TimeOffCard";
import { ProjectTimeCard } from "./ProjectTimeCard";
import { ExpensesCard } from "./ExpensesCard";
import { CopyWorkDay } from "./CopyWorkDay";
import { useRelativeNav } from "~/relative-nav";
import {
  myWorkDataRoute,
  reviewWorkDataRoute,
  weeklyProjectTasks,
} from "~/WorkData/routes";
import { useNavigate } from "react-router-dom";
import { Expense } from "~/WorkDayApi/expenseTypes";
import { ProjectTimeRow } from "./ProjectTimeCard/types";

export const durationDisplay = (d) => {
  if (!d) {
    return "";
  }
  const m = moment(d.asMilliseconds()).startOf("day").add(d);

  return m.format("HH:mm");
};

type ActionsProps = {
  editable: boolean;
  hasData: boolean;
};

export const NavigateButtons: React.FC = () => {
  const { upn, workDay, isAdmin } = useWorkDayApi();
  const nav = useRelativeNav();
  const navigate = useNavigate();

  const nextRoute = useMemo(
    () => `../${moment(workDay.date).add(1, "days").format("YYYY-MM-DD")}`,
    [workDay]
  );
  const prevRoute = useMemo(
    () => `../${moment(workDay.date).subtract(1, "days").format("YYYY-MM-DD")}`,
    [workDay]
  );
  const weekStart = moment(workDay.date).startOf("week").format("YYYY-MM-DD");

  const workDataRoute = isAdmin ? reviewWorkDataRoute : myWorkDataRoute;

  const showNext = moment(workDay.date).isBefore(
    moment().startOf("week").add(2, "weeks").subtract(1, "day")
  );

  return (
    <>
      <Button
        onClick={() =>
          navigate(
            `${workDataRoute.toRoute(upn)}/${weeklyProjectTasks}/${weekStart}`
          )
        }
      >
        Weekly Project Tasks
      </Button>
      <Button onClick={() => nav(prevRoute)}>Previous</Button>
      {showNext && <Button onClick={() => nav(nextRoute)}>Next</Button>}
    </>
  );
};

const Actions: React.FC<ActionsProps> = ({ hasData }) => {
  return (
    <>
      <CopyWorkDay {...{ hasData }} />
      <NavigateButtons />
    </>
  );
};

export type WorkDayProps = {
  date: Moment;
  time: TimeEntryRow[];
  timeOff: TimeOffRow[];
  projectTime: ProjectTimeRow[];
  expenses: Expense[];
  admin?: boolean;
};

export const WorkDay: React.FC<WorkDayProps> = ({
  date,
  time,
  timeOff,
  projectTime,
  expenses,
  admin = false,
}) => {
  const { workDay } = useWorkDayApi();
  const { editable, employee } = workDay;

  const hasData =
    !!time.length ||
    !!timeOff.length ||
    !!projectTime.length ||
    !!expenses.length;

  const actionsProps: ActionsProps = {
    editable,
    hasData,
  };

  const isMobile = employee?.isMobileEmployee;

  return (
    <PageGrid
      title={`${date.format("dddd")} - ${date.format("M/DD/YYYY")}`}
      actions={<Actions {...actionsProps} />}
      className="my-work-day"
    >
      <Cell columns={12}>
        {isMobile && <TimeCard {...{ time, date, admin, editable }} />}
        {isMobile && <TimeOffCard {...{ timeOff, admin, editable }} />}
        <ProjectTimeCard {...{ projectTime, editable }} />
        <ExpensesCard {...{ expenses, date, editable }} />
      </Cell>
    </PageGrid>
  );
};
