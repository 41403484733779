import React, { useState } from "react";
import { RevenueReportGroupingArgsInput } from "~/gql/types";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import Button from "~/button";
import { GroupForm } from "./GroupForm";

export type GroupDialogProps = {
  defaultValues: RevenueReportGroupingArgsInput;
  setOptions: (
    value: React.SetStateAction<RevenueReportGroupingArgsInput>
  ) => void;
};
export const GroupingDialog: React.FC<GroupDialogProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [formVals, setFormVals] = useState<RevenueReportGroupingArgsInput>(
    props.defaultValues
  );
  const onClose = () => {
    setOpen(false);
    setFormVals(props.defaultValues);
  };
  const onOpen = () => {
    setOpen(true);
    setFormVals(props.defaultValues);
  };
  return (
    <>
      <Button className="group-button" onClick={() => onOpen()} icon="add">
        Add Grouping
      </Button>
      <Dialog
        open={open}
        onClose={() => onClose()}
        className="group-control-dialog"
      >
        <DialogTitle>Group By</DialogTitle>
        <DialogContent>
          {open && (
            <GroupForm
              {...{ ...props, setOpen, formVals, setFormVals, onClose }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
