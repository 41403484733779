import React from "react";
import {
  EmployeeRole,
  useEmployeeFromParam,
  useEmployeeSearch,
} from "~/refdata/useEmployeeSearch";
import { EmployeePicker } from "~/visuals/organisms/EmployeePickerFormField";
import { FilterProviderProps } from "./types";
import { createProvider } from "./createFilterProvider";
import { Employee } from "~/gql/types";
import { FilterProvider } from "~/ultra-filter/types";

const empPrefixes = {
  Employee: "Employee",
  "Billing Admin": "BA",
  "Project Manager": "PM",
  "Project Supervisor": "PS",
  "Payroll Admin": "PA",
  Approver: "approver",
};

type EmployeeLabel = keyof typeof empPrefixes;

type CreateEmployeeProviderProps = {
  type: string;
  label: EmployeeLabel;
  documentationText: string;
};

function useCreateEmployeeProvider(): (
  props: CreateEmployeeProviderProps
) => FilterProvider {
  const empFromParam = useEmployeeFromParam();

  const getEmployees = useEmployeeSearch();

  const excluded = ["Employee", "Approver"];

  return (props: CreateEmployeeProviderProps) => {
    const prefix = empPrefixes[props.label];
    const { type, label, documentationText } = props;

    const roleName = (!excluded.includes(label) ? label : undefined) as
      | EmployeeRole
      | undefined;

    return createProvider({
      render: () => (
        <EmployeePicker
          formField={type}
          label={label}
          helperText=""
          roleName={roleName}
        />
      ),
      type,
      label,
      props: { documentationText },
      getItems: getEmployees,
      itemFromParam: empFromParam,
      shouldSuggestText: prefix,
      toSuggestions: (result, _) =>
        (result as Employee[]).map((e) => ({
          text: `${e.firstName} ${e.lastName}`,
          value: e,
        })),
      toLabelValue: (value: Employee) => `${value.firstName} ${value.lastName}`,
      toSearchArgs: (text: string) => ({ searchText: text, roleName }),
      parseResult: (result: any) => result.records,
      toParamValue: (value: Employee) => value.userPrincipalName,
    });
  };
}

export function useBillingAdminProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText } = props;
  const createEmpProvider = useCreateEmployeeProvider();
  return createEmpProvider({
    type: "billingAdmin",
    label: "Billing Admin",
    documentationText,
  });
}

export function useProjectManagerProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText } = props;
  const createEmpProvider = useCreateEmployeeProvider();
  return createEmpProvider({
    type: "projectManager",
    label: "Project Manager",
    documentationText,
  });
}

export function useProjectSupervisorProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText } = props;
  const createEmpProvider = useCreateEmployeeProvider();
  return createEmpProvider({
    type: "projectSupervisor",
    label: "Project Supervisor",
    documentationText,
  });
}

export function useEmployeeProvider(
  props: FilterProviderProps & { type?: string; label?: EmployeeLabel }
): FilterProvider {
  const { documentationText, type, label } = props;
  const createEmpProvider = useCreateEmployeeProvider();
  return createEmpProvider({
    type: type ?? "employee",
    label: label ?? "Employee",
    documentationText,
  });
}

export function usePayrollAdminProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText } = props;
  const createEmpProvider = useCreateEmployeeProvider();
  return createEmpProvider({
    type: "payrollAdmin",
    label: "Payroll Admin",
    documentationText,
  });
}
