import React from "react";
import { TableCell } from "~/table";
import { CheckBox } from "~/checkbox";
import ClickSwallower from "~/click-swallower";
import { ProjectCharge } from "~/gql/types";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { ProjectChargesFilter } from "~/visuals/pages/ProjectCharges/types";
import _ from "lodash";
import { Charge } from "./types";

type SelectAllChargesProps = {
  allCharges: Charge[];
  chargeFilter: (charge: Charge) => boolean;
  state: State<ProjectCharge, ProjectChargesFilter>;
  dispatch: React.Dispatch<Action<ProjectCharge, ProjectChargesFilter>>;
};

export const SelectAllCharges: React.FC<SelectAllChargesProps> = (props) => {
  const { allCharges, state, dispatch, chargeFilter } = props;

  const selectedCharges = state.selectedItems;
  const selectedIds = selectedCharges.map((x) => x.id);

  const filteredCharges = allCharges.filter(chargeFilter);

  const filteredIds = filteredCharges.map((x) => x.id);

  const onSelectCharges = (checked: boolean) => {
    let newCharges: Charge[];

    if (checked) {
      newCharges = filteredCharges.filter((x) => !selectedIds.includes(x.id));

      newCharges.forEach((c) =>
        dispatch({ tag: "SelectItem", item: c as ProjectCharge })
      );
    } else {
      filteredCharges.forEach((x) =>
        dispatch({ tag: "UnselectItem", id: x.id })
      );
    }
  };

  return (
    <TableCell>
      <ClickSwallower>
        <CheckBox
          onChange={onSelectCharges}
          checked={filteredIds.every((x) => selectedIds.includes(x))}
        />
      </ClickSwallower>
    </TableCell>
  );
};
