import React from "react";
import ButtonsPage from "./ButtonsPage";
import FuelTypeForm from "./FuelTypeForm";
import PerDiemTypeForm from "./PerDiemTypeForm";
import ReceiptValueTypeForm from "./ReceiptValueTypeForm";
import MileageTypeForm from "./MileageTypeForm";
import _ from "lodash";
import MobileAllowanceTypeForm from "./MobileAllowanceTypeForm";
import TcpAllowanceTypeForm from "./TcpAllowanceTypeForm";
import AtvTypeForm from "./AtvTypeForm";
import AdHocTypeForm from "./AdHocTypeForm";

export type FormProps = {
  type: "Create" | "Edit";
  rateSheetName: string;
  errorMessage?: string;
  loading: boolean;
  onDelete?: () => void;
};

type ExpenseRateFormRouterProps = {
  page: ExpenseRateFormPage;
  setPage: React.Dispatch<ExpenseRateFormPage>;
  formProps: FormProps;
};

export type ExpenseRateFormPage =
  | "Buttons"
  | "Fuel"
  | "PerDiem"
  | "ReceiptValue"
  | "MobileAllowance"
  | "Mileage"
  | "TcpAllowance"
  | "Atv"
  | "AdHoc";

const ExpenseRateFormRouter: React.FC<ExpenseRateFormRouterProps> = ({
  page,
  setPage,
  formProps,
}) => {
  if (page === "Buttons") {
    return <ButtonsPage {...{ ...formProps, setPage }} />;
  }
  if (page === "Fuel") {
    return <FuelTypeForm {...{ ...formProps, setPage }} />;
  }
  if (page === "PerDiem") {
    return <PerDiemTypeForm {...{ ...formProps, setPage }} />;
  }
  if (page === "ReceiptValue") {
    return <ReceiptValueTypeForm {...{ ...formProps, setPage }} />;
  }
  if (page === "MobileAllowance") {
    return <MobileAllowanceTypeForm {...{ ...formProps, setPage }} />;
  }
  if (page === "Mileage") {
    return <MileageTypeForm {...{ ...formProps, setPage }} />;
  }
  if (page === "TcpAllowance") {
    return <TcpAllowanceTypeForm {...{ ...formProps, setPage }} />;
  }
  if (page === "Atv") {
    return <AtvTypeForm {...{ ...formProps, setPage }} />;
  }
  if (page === "AdHoc") {
    return <AdHocTypeForm {...{ ...formProps, setPage }} />;
  }
  return null;
};

export default ExpenseRateFormRouter;
