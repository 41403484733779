import React, { useEffect, useState } from "react";
import { useLogout, useRawJwt } from "../AuthenticationManager";
import { isExpired as getIsExpired } from "../jwtUtils";

export const LogoutOnExpire: React.FC = ({ children }) => {
  const token = useRawJwt();
  const logout = useLogout();

  const [isExpired, setIsExpired] = useState(() => getIsExpired(token!));

  useEffect(() => {
    const interval = window.setInterval(() => {
      setIsExpired(getIsExpired(token!));
    }, 250);

    return () => window.clearInterval(interval);
  }, [token]);

  useEffect(() => {
    if (isExpired) {
      logout(false);
    }
  }, [isExpired]);

  return <>{!isExpired && children}</>;
};
