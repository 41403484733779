import { ApolloClient, useApolloClient } from "@apollo/client";
import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

export type ProviderProps<T> = {
  data?: T[];
};

export type RefdataContext<T> = {
  useDataAsync: () => () => Promise<T[]>;
  useData: () => T[] | null;
  useInvalidate: () => () => void;
  Provider: React.FC<ProviderProps<T>>;
};

export type FetchFn<T> = (client: ApolloClient<unknown>) => Promise<T[]>;

type ContextT<T> = {
  getPromise: () => Promise<T[]>;
  getCurrentValue: () => T[] | null;
  invalidate: () => void;
};

export default function createRefdataApi<T>(
  fetchFn: FetchFn<T>,
  providerType: string
): RefdataContext<T> {
  const Context = createContext<ContextT<T> | null>(null);
  function useRefdataContext() {
    const ctx = useContext(Context);

    if (!ctx) {
      throw new Error(
        `Do not use ${providerType} reference data outside of its corresponding context provider`
      );
    }

    return ctx;
  }

  function useDataAsync(): () => Promise<T[]> {
    const ctx = useRefdataContext();

    return ctx!.getPromise;
  }
  function useData(): T[] | null {
    const ctx = useRefdataContext();

    return ctx!.getCurrentValue();
  }
  function useInvalidate(): () => void {
    const ctx = useRefdataContext();

    return () => ctx!.invalidate();
  }
  const RealProvider: React.FC = ({ children }) => {
    const promiseRef = useRef<Promise<T[]>>();
    const [value, setValue] = useState<T[] | null>(null);
    const client = useApolloClient();

    const ensurePromise = () => {
      if (promiseRef.current) {
        return;
      }

      const p = new Promise<T[]>((resolve, reject) => {
        let retries = 2;

        const retry = () => {
          retries--;
          fetchData();
        };

        const fetchData = () => {
          fetchFn(client)
            .then((x) => {
              if (x && x.length > 0) {
                resolve(x);
              } else if (retries > 0) {
                retry();
              }
            })
            .catch((e) => {
              if (retries > 0) {
                retry();
              } else {
                reject(e);
              }
            });
        };

        fetchData();
      });

      promiseRef.current = p;
      void p.then((x) => setValue(x));
    };

    const getPromise = () => {
      ensurePromise();

      return promiseRef.current!;
    };

    const getCurrentValue = () => {
      ensurePromise();

      return value;
    };

    const invalidate = () => {
      promiseRef.current = undefined;
      setValue(null);
    };

    const contextValue = useMemo(
      () => ({ getPromise, getCurrentValue, invalidate }),
      [value] // using value as dependency to ensure that the component re-renders when the value changes
    );

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
  };

  const SyncProvider: React.FC<ProviderProps<T>> = ({ children, data }) => {
    const getPromise = useMemo(() => () => Promise.resolve(data!), [data]);
    const getCurrentValue = useMemo(() => () => data!, [data]);

    return (
      <Context.Provider value={{ getPromise, getCurrentValue }}>
        {children}
      </Context.Provider>
    );
  };

  const Provider: React.FC<ProviderProps<T>> = ({ children, data }) => {
    return (
      <>
        {data && <SyncProvider data={data}>{children}</SyncProvider>}
        {!data && <RealProvider>{children}</RealProvider>}
      </>
    );
  };

  return {
    useData,
    useDataAsync,
    useInvalidate,
    Provider,
  };
}
