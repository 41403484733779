import React, { createContext, useContext, useMemo } from "react";
import { createSearchRegex } from "./createSearchRegex";

const Context = createContext<RegExp[]>([]);

type TextHighlighterProviderProps = {
  searchText: string | null;
};

export const TextHighlighterProvider: React.FC<
  TextHighlighterProviderProps
> = ({ children, searchText }) => {
  const regexes = useMemo(
    () =>
      (searchText || "")
        .split(" ")
        .map((x) => x.trim())
        .filter((x) => x.length > 0)
        .map((x) => createSearchRegex(x) as RegExp),
    [searchText]
  );

  return <Context.Provider value={regexes}>{children}</Context.Provider>;
};

export const useTextHighlighterRegexes = () => {
  return useContext(Context);
};
