import React, { useState } from "react";
import { ImageUploadWrapper } from "~/ImageUploadWrapper";
import TextFormField from "~/text-form-field";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";
import PageTitleRow from "~/page-title-row";
import FormCard from "~/form-card";
import {
  CrewCodeRecord,
  Project,
  ThirdPartyInvoice,
  ValidationResponse,
} from "~/gql/types";
import ProjectPickerFormField from "~/visuals/organisms/ProjectPickerFormField";
import FormBottomRow from "~/form-bottom-row";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { useNavigate } from "react-router-dom";
import {
  thirdPartyInvoice as thirdPartyInvoiceRoute,
  thirdPartyInvoices,
} from "~/routes";
import "./ThirdPartyInvoiceForm.scss";
import { Moment } from "moment";
import moment from "moment";
import { Breadcrumb, useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { CrewCodePickerFormField } from "~/visuals/organisms/CrewCodePickerFormField";
import { useCrewCodes } from "~/refdata2/crewCodes";
export type FormValues = {
  date: Moment;
  amount: string;
  project: Project;
  documentImage: Blob | null;
  documentImageId: string | null;
  crewCode: CrewCodeRecord | null;
  notes: string | null;
};

type ThirdPartyInvoiceFormProps = {
  type: "Create" | "Edit";
  setImageId: React.Dispatch<string | null>;
  onSubmit: (value: any) => Promise<ValidationResponse>;
  onSuccess: (value: any) => void;
  thirdPartyInvoice?: ThirdPartyInvoice;
  initialValues?: FormValues;
  image?: any;
};

export const ThirdPartyInvoiceForm: React.FC<ThirdPartyInvoiceFormProps> = ({
  type,
  setImageId,
  thirdPartyInvoice,
  onSubmit,
  onSuccess,
  initialValues: initialFormValues,
  image,
}) => {
  const navigate = useNavigate();

  useBreadcrumbs(
    [
      { text: "Third Party Invoices", to: thirdPartyInvoices.path },
      type === "Create"
        ? { text: "Create " }
        : {
            text: thirdPartyInvoice?.invoiceNumber,
            to: thirdPartyInvoiceRoute.toRoute(thirdPartyInvoice!.invoiceNumber)
              .path,
          },
      type === "Edit" ? { text: "Edit" } : null,
    ].filter((x) => !!x) as Breadcrumb[],
    [thirdPartyInvoice, type]
  );

  const [imageUrl, setImageUrl] = useState<string | null>(
    thirdPartyInvoice?.documentUrl ?? null
  );

  const crewCodes = useCrewCodes();

  const initialCrewCode = thirdPartyInvoice
    ? crewCodes?.find((x) => x.crewCode === thirdPartyInvoice?.crewCode) ?? null
    : null;

  const initialValues = initialFormValues ?? {
    date: thirdPartyInvoice?.date
      ? moment(thirdPartyInvoice?.date, "YYYY-MM-DD")
      : null,
    amount: thirdPartyInvoice?.amount ?? "",
    project: thirdPartyInvoice?.project ?? null,
    documentImage: image && imageUrl ? image : null,
    crewCode: initialCrewCode,
    notes: thirdPartyInvoice?.notes ?? null,
  };

  return (
    <>
      <div className="third-party-title">
        <PageTitleRow
          title={`${
            type === "Create"
              ? "Create Third Party Invoice"
              : `Edit ${thirdPartyInvoice?.invoiceNumber}`
          }`}
        />
      </div>
      <FormCard>
        <MutationForm
          {...{
            initialValues,
            onSuccess,
            runMutation: onSubmit,
            allowPristineSubmit: true,
          }}
        >
          <DatePickFormField
            formField={"date"}
            label={"Date"}
            helperText={""}
            required
          />
          <ProjectPickerFormField
            formField="project"
            label="Project"
            helperText=""
            required
            date=""
            filter={(x) => x.allowThirdPartyInvoice}
          />
          <CrewCodePickerFormField
            formField={"crewCode"}
            label={"Crew Code"}
            helperText={""}
            required
          />
          <TextFormField
            label="Amount"
            helperText=""
            formField="amount"
            type="number"
            required
          />
          <TextFormField label="Notes" helperText="" formField="notes" />
          <ImageUploadWrapper
            {...{
              setImageId,
              label: "document",
              formField: "documentImage",
              imageUrl,
              setImageUrl,
              required: true,
            }}
          />
          <ErrorRenderer
            render={(errorMessage) => (
              <FormBottomRow
                {...{
                  errorMessage,
                  buttonText: type == "Create" ? "Create" : "Save",
                  onCancel: () =>
                    navigate(
                      type == "Create"
                        ? thirdPartyInvoices.path
                        : thirdPartyInvoiceRoute.toRoute(
                            thirdPartyInvoice!.invoiceNumber
                          ).path
                    ),
                }}
              ></FormBottomRow>
            )}
          />
        </MutationForm>
      </FormCard>
    </>
  );
};
