import React from "react";
import { FilterProviderProps } from "./types";
import { createProvider } from "./createFilterProvider";
import { FilterProvider, Suggestion } from "~/ultra-filter/types";

type SimpleProviderProps = FilterProviderProps & {
  type: string;
  label: string;
  options: Suggestion[];
  suggestText: string;
  render: () => React.ReactNode;
};

export function createSimpleProvider(
  props: SimpleProviderProps
): FilterProvider {
  const { type, label, options, render, suggestText } = props;

  const itemFromParam = (arg: string) =>
    Promise.resolve(options.find((x) => x.value === arg)?.value ?? null);

  return createProvider({
    render,
    type,
    label,
    props,
    getItems: () => Promise.resolve(options.map((x) => x.value) as any[]),
    itemFromParam,
    shouldSuggestText: suggestText,
    toSuggestions: (result, text) =>
      (result as string[])
        .filter(
          (s) =>
            s.toLowerCase().startsWith(text.toLowerCase()) ||
            options
              .find((x) => x.value === s)
              ?.text?.toLowerCase()
              .startsWith(text.toLowerCase())
        )
        .map((s) => ({
          text: options.find((x) => x.value === s)?.text ?? "",
          value: s,
        })),
    toLabelValue: (value: string) =>
      options.find((x) => x.value === value)?.text ?? "",
    toParamValue: (value: any) => value,
  });
}
