import React from "react";
import { useNavigate } from "react-router-dom";
import {
  expenseRateSheets,
  createExpenseRate,
  editExpenseRate,
  assignSuccessorExpenseRateSheet,
  editExpenseRateSheet,
} from "~/routes";
import TableCell from "~/old-table-cell";
import Table from "~/old-table";
import TableHeader from "~/old-table-header";
import TableContent from "~/old-table-content";
import PageTitleRow from "~/page-title-row";
import { Cell, Row } from "@material/react-layout-grid";
import TitledCard from "~/titled-card";
import {
  ExpenseRateSheet,
  PerDiemExpenseRate,
  ReceiptValuedExpenseRate,
  MileageExpenseRate,
  MobileAllowanceExpenseRate,
  TcpAllowanceExpenseRate,
  FuelExpenseRate,
  AtvExpenseRate,
  AdHocExpenseRate,
} from "~/graphql/ExpenseRateSheet";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import "./ExpenseRateSheet.scss";
import { PopupMenuListItem } from "~/popup-menu-list";
import { RateSheetTitle } from "~/rate-sheet-title";
import { ActionsMenu } from "~/actions-menu";
import { useUser } from "~/Auth/useUser";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const AtvExpenseRateItem: React.FC<{ expenseRate: AtvExpenseRate }> = ({
  expenseRate,
}) => {
  const { name, invoiceLabel } = expenseRate;

  const billableAmount = expenseRate.billableAmount
    ? formatter.format(expenseRate.billableAmount)
    : "";
  const reimbursableAmount = expenseRate.reimbursableAmount
    ? formatter.format(expenseRate.reimbursableAmount)
    : "";

  return (
    <>
      <TableCell text={name} />
      <TableCell text={invoiceLabel} />
      <TableCell text={billableAmount} />
      <TableCell text={reimbursableAmount} />
    </>
  );
};

const PerDiemExpenseRateItem: React.FC<{ expenseRate: PerDiemExpenseRate }> = ({
  expenseRate,
}) => {
  const { name, invoiceLabel, kind, quantity } = expenseRate;

  const billableOverride = expenseRate.billableOverride
    ? formatter.format(expenseRate.billableOverride)
    : "";

  return (
    <>
      <TableCell text={name} />
      <TableCell text={invoiceLabel} />
      <TableCell text={billableOverride} />
      <TableCell text={kind} />
      <TableCell text={`${quantity}`} />
    </>
  );
};

const ReceiptValuedExpenseRateItem: React.FC<{
  expenseRate: ReceiptValuedExpenseRate;
}> = ({ expenseRate }) => {
  const { name, invoiceLabel } = expenseRate;

  const taxable = expenseRate.payrollTaxable ? "Yes" : "No";

  return (
    <>
      <TableCell text={name} />
      <TableCell text={invoiceLabel} />
      <TableCell text={taxable} />
      <TableCell />
      <TableCell />
    </>
  );
};

const FuelExpenseRateItem: React.FC<{ expenseRate: FuelExpenseRate }> = ({
  expenseRate,
}) => {
  const { name, invoiceLabel } = expenseRate;

  return (
    <>
      <TableCell text={name} />
      <TableCell text={invoiceLabel} />
      <TableCell />
      <TableCell />
      <TableCell />
    </>
  );
};

const MobileAllowanceExpenseRateItem: React.FC<{
  expenseRate: MobileAllowanceExpenseRate;
}> = ({ expenseRate }) => {
  const { name, invoiceLabel, quantity } = expenseRate;

  const billableAmount = expenseRate.billableAmount
    ? formatter.format(expenseRate.billableAmount)
    : "";
  const reimbursableAmount = expenseRate.reimbursableAmount
    ? formatter.format(expenseRate.reimbursableAmount)
    : "";

  return (
    <>
      <TableCell text={name} />
      <TableCell text={invoiceLabel} />
      <TableCell text={billableAmount} />
      <TableCell text={reimbursableAmount} />
      <TableCell text={`${quantity}`} />
    </>
  );
};

const TcpAllowanceExpenseRateItem: React.FC<{
  expenseRate: TcpAllowanceExpenseRate;
}> = ({ expenseRate }) => {
  const { name, invoiceLabel } = expenseRate;

  const reimbursableAmount = expenseRate.reimbursableAmount
    ? formatter.format(expenseRate.reimbursableAmount)
    : "";

  return (
    <>
      <TableCell text={name} />
      <TableCell text={invoiceLabel} />
      <TableCell text={reimbursableAmount} />
      <TableCell />
      <TableCell />
    </>
  );
};

const MileageExpenseRateItem: React.FC<{ expenseRate: MileageExpenseRate }> = ({
  expenseRate,
}) => {
  const { name, invoiceLabel } = expenseRate;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const billableAmount = expenseRate.billableAmountPerMile
    ? formatter.format(expenseRate.billableAmountPerMile)
    : "";
  const reimbursableAmount = expenseRate.reimbursableAmountPerMile
    ? formatter.format(expenseRate.reimbursableAmountPerMile)
    : "";

  return (
    <>
      <TableCell text={name} />
      <TableCell text={invoiceLabel} />
      <TableCell text={billableAmount} />
      <TableCell text={reimbursableAmount} />
      <TableCell />
    </>
  );
};
const AdHocExpenseRateItem: React.FC<{ expenseRate: AdHocExpenseRate }> = ({
  expenseRate,
}) => {
  const { name, invoiceLabel, rate } = expenseRate;

  return (
    <>
      <TableCell text={name} />
      <TableCell text={invoiceLabel} />
      <TableCell text={`${rate ?? ""}`} />
      <TableCell />
      <TableCell />
    </>
  );
};
type RateSheetProps = {
  rateSheet: ExpenseRateSheet;
  useReload(): React.DispatchWithoutAction;
};

const RateSheet: React.FC<RateSheetProps> = ({ rateSheet }) => {
  const navigate = useNavigate();

  const user = useUser();
  const canEdit = user.authorizations.manageRateSheets && !rateSheet.archived;

  useBreadcrumbs(
    [
      { text: "Expense Rate Sheets", to: expenseRateSheets.path },
      { text: rateSheet?.name },
    ],
    [rateSheet]
  );

  const rates = rateSheet?.expenseRates || [];

  const atvRates = rates.filter((x) => x.__typename === "AtvExpenseRate");
  const perDiemRates = rates.filter(
    (x) => x.__typename === "PerDiemExpenseRate"
  );
  const receiptValuedRates = rates.filter(
    (x) => x.__typename === "ReceiptValuedExpenseRate"
  );
  const fuelRates = rates.filter((x) => x.__typename === "FuelExpenseRate");
  const mobileAllowanceRates = rates.filter(
    (x) => x.__typename === "MobileAllowanceExpenseRate"
  );
  const tcpAllowanceRates = rates.filter(
    (x) => x.__typename === "TcpAllowanceExpenseRate"
  );
  const mileageRates = rates.filter(
    (x) => x.__typename === "MileageExpenseRate"
  );
  const adHocRates = rates.filter((x) => x.__typename === "AdHocExpenseRate");
  const items = [
    {
      label: "Create Expense Rate",
      onClick: () => navigate(createExpenseRate.toRoute(rateSheet?.name).path),
      key: 1,
      dataItem: "create-rate",
    },
    {
      label: "Edit Rate Sheet",
      onClick: () =>
        navigate(editExpenseRateSheet.toRoute(rateSheet?.name).path),
      key: 2,
      dataItem: "update-assignment",
    },
    {
      label: "Assign Successor",
      onClick: () =>
        navigate(assignSuccessorExpenseRateSheet.toRoute(rateSheet?.name).path),
      key: 3,
      dataItem: "assign-successor",
    },
  ].filter((x) => x) as PopupMenuListItem[];

  const authWrap = (action) => (canEdit ? action : () => undefined);

  return (
    <div className="expense-rate-sheet-view">
      <PageTitleRow
        title={<RateSheetTitle {...{ rateSheet }} />}
        actions={canEdit ? <ActionsMenu {...{ items }} /> : <></>}
      />
      <Row>
        <Cell columns={12}>
          <TitledCard
            title="Ad Hoc Expense Rates"
            className="rates-card adHoc-card"
          >
            <Table>
              <TableHeader>
                <TableCell text="Name" />
                <TableCell text="Invoice Label" />
                <TableCell text="Rate" />
              </TableHeader>
              {adHocRates.map((expenseRate, idx) => (
                <TableContent
                  key={idx}
                  trailingSeparator={idx !== adHocRates.length - 1}
                  className="rate"
                  onClick={authWrap(() =>
                    navigate(
                      editExpenseRate.toRoute(rateSheet?.name, expenseRate.name)
                        .path
                    )
                  )}
                >
                  <AdHocExpenseRateItem
                    expenseRate={expenseRate as AdHocExpenseRate}
                  />
                </TableContent>
              ))}
            </Table>
          </TitledCard>
          <TitledCard title="ATV Expense Rates" className="rates-card atv-card">
            <Table>
              <TableHeader>
                <TableCell text="Name" />
                <TableCell text="Invoice Label" />
                <TableCell text="Billable Amount" />
                <TableCell text="Reimbursable Amount" />
              </TableHeader>
              {atvRates.map((expenseRate, idx) => (
                <TableContent
                  key={idx}
                  trailingSeparator={idx !== atvRates.length - 1}
                  className="rate"
                  onClick={authWrap(() =>
                    navigate(
                      editExpenseRate.toRoute(rateSheet?.name, expenseRate.name)
                        .path
                    )
                  )}
                >
                  <AtvExpenseRateItem
                    expenseRate={expenseRate as AtvExpenseRate}
                  />
                </TableContent>
              ))}
            </Table>
          </TitledCard>
          <TitledCard
            title="Fuel Expense Rates"
            className="rates-card fuel-card"
          >
            <Table>
              <TableHeader>
                <TableCell text="Name" />
                <TableCell text="Invoice Label" />
              </TableHeader>
              {fuelRates.map((expenseRate, idx) => (
                <TableContent
                  key={idx}
                  trailingSeparator={idx !== fuelRates.length - 1}
                  className="rate"
                  onClick={authWrap(() =>
                    navigate(
                      editExpenseRate.toRoute(rateSheet?.name, expenseRate.name)
                        .path
                    )
                  )}
                >
                  <FuelExpenseRateItem
                    expenseRate={expenseRate as FuelExpenseRate}
                  />
                </TableContent>
              ))}
            </Table>
          </TitledCard>
          <TitledCard
            title="Mileage Expense Rates"
            className="rates-card mileage-card"
          >
            <Table>
              <TableHeader>
                <TableCell text="Name" />
                <TableCell text="Invoice Label" />
                <TableCell text="Billable Amount" />
                <TableCell text="Reimbursable Amount" />
              </TableHeader>
              {mileageRates.map((expenseRate, idx) => (
                <TableContent
                  key={idx}
                  trailingSeparator={idx !== mileageRates.length - 1}
                  className="rate"
                  onClick={authWrap(() =>
                    navigate(
                      editExpenseRate.toRoute(rateSheet?.name, expenseRate.name)
                        .path
                    )
                  )}
                >
                  <MileageExpenseRateItem
                    expenseRate={expenseRate as MileageExpenseRate}
                  />
                </TableContent>
              ))}
            </Table>
          </TitledCard>
          <TitledCard
            title="Mobile Allowance Expense Rates"
            className="rates-card mobile-allowance-card"
          >
            <Table>
              <TableHeader>
                <TableCell text="Name" />
                <TableCell text="Invoice Label" />
                <TableCell text="Billable Amount" />
                <TableCell text="Reimbursable Amount" />
                <TableCell text="Qty" />
              </TableHeader>
              {mobileAllowanceRates.map((expenseRate, idx) => (
                <TableContent
                  key={idx}
                  trailingSeparator={idx !== mobileAllowanceRates.length - 1}
                  className="rate"
                  onClick={authWrap(() =>
                    navigate(
                      editExpenseRate.toRoute(rateSheet?.name, expenseRate.name)
                        .path
                    )
                  )}
                >
                  <MobileAllowanceExpenseRateItem
                    expenseRate={expenseRate as MobileAllowanceExpenseRate}
                  />
                </TableContent>
              ))}
            </Table>
          </TitledCard>
          <TitledCard
            title="Per Diem Expense Rates"
            className="rates-card per-diem-card"
          >
            <Table>
              <TableHeader>
                <TableCell text="Name" />
                <TableCell text="Invoice Label" />
                <TableCell text="Billable Override" />
                <TableCell text="Per Diem Kind" />
                <TableCell text="Qty" />
              </TableHeader>
              {perDiemRates.map((expenseRate, idx) => (
                <TableContent
                  key={idx}
                  trailingSeparator={idx !== perDiemRates.length - 1}
                  className="rate"
                  onClick={authWrap(() =>
                    navigate(
                      editExpenseRate.toRoute(rateSheet?.name, expenseRate.name)
                        .path
                    )
                  )}
                >
                  <PerDiemExpenseRateItem
                    expenseRate={expenseRate as PerDiemExpenseRate}
                  />
                </TableContent>
              ))}
            </Table>
          </TitledCard>
          <TitledCard
            title="Receipt Valued Expense Rates"
            className="rates-card receipt-value-card"
          >
            <Table>
              <TableHeader>
                <TableCell text="Name" />
                <TableCell text="Invoice Label" />
                <TableCell text="Taxable?" />
              </TableHeader>
              {receiptValuedRates.map((expenseRate, idx) => (
                <TableContent
                  key={idx}
                  trailingSeparator={idx !== receiptValuedRates.length - 1}
                  className="rate"
                  onClick={authWrap(() =>
                    navigate(
                      editExpenseRate.toRoute(rateSheet?.name, expenseRate.name)
                        .path
                    )
                  )}
                >
                  <ReceiptValuedExpenseRateItem
                    expenseRate={expenseRate as ReceiptValuedExpenseRate}
                  />
                </TableContent>
              ))}
            </Table>
          </TitledCard>
          <TitledCard
            title="TCP Allowance Expense Rates"
            className="rates-card tcp-allowance-card"
          >
            <Table>
              <TableHeader>
                <TableCell text="Name" />
                <TableCell text="Invoice Label" />
                <TableCell text="Reimbursable Amount" />
              </TableHeader>
              {tcpAllowanceRates.map((expenseRate, idx) => (
                <TableContent
                  key={idx}
                  trailingSeparator={idx !== tcpAllowanceRates.length - 1}
                  className="rate"
                  onClick={authWrap(() =>
                    navigate(
                      editExpenseRate.toRoute(rateSheet?.name, expenseRate.name)
                        .path
                    )
                  )}
                >
                  <TcpAllowanceExpenseRateItem
                    expenseRate={expenseRate as TcpAllowanceExpenseRate}
                  />
                </TableContent>
              ))}
            </Table>
          </TitledCard>
        </Cell>
      </Row>
    </div>
  );
};

export default RateSheet;
