import React, { createContext, useContext, useState } from "react";
import { useWorkDayApi } from "~/WorkDayApi";
import { myWorkDayRoute, reviewWorkDayRoute } from "~/WorkData/routes";
import { useNavigate } from "react-router-dom";
import {
  CopyExpenseToDate,
  CopyProjectTimeToDate,
  CopyTimeEntryToDate,
  CopyTimeOffToDate,
  ItemToDateProps,
} from "~/visuals/organisms/WorkDay/ItemToDate";
import {
  CopyExpenseToDates,
  CopyProjectTimeToDates,
  CopyTimeEntryToDates,
  CopyTimeOffToDates,
  CopyToDatesProps,
} from "~/visuals/organisms/WorkDay/CopyToDates";

export type WorkDayCopyContextType = {
  setCopyToDate: React.Dispatch<string | null>;
  setCopyToDates: React.Dispatch<string | null>;
};

export const defaultCopyContext: WorkDayCopyContextType = {
  setCopyToDate: () => undefined,
  setCopyToDates: () => undefined,
};

export type WorkDayCopyProviderProps = {
  Context: React.Context<WorkDayCopyContextType>;
  CopyToDatesComponent: React.FC<CopyToDatesProps>;
  CopyToDateComponent: React.FC<ItemToDateProps>;
};

export const WorkDayCopyProviderBase: React.FC<WorkDayCopyProviderProps> = ({
  children,
  Context,
  CopyToDateComponent,
  CopyToDatesComponent,
}) => {
  const [copyToDate, setCopyToDate] = useState<string | null>(null);
  const [copyToDates, setCopyToDates] = useState<string | null>(null);

  const {
    workDay: { date },
    mode,
    upn,
  } = useWorkDayApi();

  const api = {
    setCopyToDate,
    setCopyToDates,
  };

  const workDayPath =
    mode === "Review"
      ? reviewWorkDayRoute.toRoute(upn, date)
      : myWorkDayRoute.toRoute(upn, date);

  const navigate = useNavigate();

  const onSuccessFn = () => {
    setCopyToDate(null);
    setCopyToDates(null);
    navigate(workDayPath);
  };

  return (
    <Context.Provider value={api}>
      {children}

      {copyToDate && (
        <CopyToDateComponent
          open={!!copyToDate}
          onClosed={() => setCopyToDate(null)}
          oldDate={date}
          id={copyToDate!}
          successFn={onSuccessFn}
        />
      )}

      {copyToDates && (
        <CopyToDatesComponent
          open={!!copyToDates}
          onClosed={() => setCopyToDates(null)}
          oldDate={date}
          id={copyToDates!}
          successFn={onSuccessFn}
        />
      )}
    </Context.Provider>
  );
};

export const ProjectTimeCopyContext =
  createContext<WorkDayCopyContextType>(defaultCopyContext);

export const ProjectTimeCopyProvider: React.FC = (props) => (
  <WorkDayCopyProviderBase
    {...props}
    CopyToDatesComponent={CopyProjectTimeToDates}
    CopyToDateComponent={CopyProjectTimeToDate}
    Context={ProjectTimeCopyContext}
  />
);

export function useProjectTimeCopy(): WorkDayCopyContextType {
  const context = useContext(ProjectTimeCopyContext);
  return context;
}

export const ExpenseCopyContext =
  createContext<WorkDayCopyContextType>(defaultCopyContext);

export const ExpenseCopyProvider: React.FC = (props) => (
  <WorkDayCopyProviderBase
    {...props}
    CopyToDatesComponent={CopyExpenseToDates}
    CopyToDateComponent={CopyExpenseToDate}
    Context={ExpenseCopyContext}
  />
);

export function useExpenseCopy(): WorkDayCopyContextType {
  const context = useContext(ExpenseCopyContext);
  return context;
}

export const TimeCopyContext =
  createContext<WorkDayCopyContextType>(defaultCopyContext);

export const TimeCopyProvider: React.FC = (props) => (
  <WorkDayCopyProviderBase
    {...props}
    CopyToDatesComponent={CopyTimeEntryToDates}
    CopyToDateComponent={CopyTimeEntryToDate}
    Context={TimeCopyContext}
  />
);

export function useTimeCopy(): WorkDayCopyContextType {
  const context = useContext(TimeCopyContext);
  return context;
}

export const TimeOffCopyContext =
  createContext<WorkDayCopyContextType>(defaultCopyContext);

export const TimeOffCopyProvider: React.FC = (props) => (
  <WorkDayCopyProviderBase
    {...props}
    CopyToDatesComponent={CopyTimeOffToDates}
    CopyToDateComponent={CopyTimeOffToDate}
    Context={TimeOffCopyContext}
  />
);

export function useTimeOffCopy(): WorkDayCopyContextType {
  const context = useContext(TimeOffCopyContext);
  return context;
}

export const WorkDayCopyProvider: React.FC = ({ children }) => (
  <ProjectTimeCopyProvider>
    <ExpenseCopyProvider>
      <TimeCopyProvider>
        <TimeOffCopyProvider>{children}</TimeOffCopyProvider>
      </TimeCopyProvider>
    </ExpenseCopyProvider>
  </ProjectTimeCopyProvider>
);
