import React from "react";
import { project, invoice as invoiceRoute } from "~/routes";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { useInvoice } from "../InvoiceContext";

export const RebillBreadcrumbs: React.FC = () => {
  const invoice = useInvoice();

  const breadcrumbs = invoice
    ? [
        {
          text: `Project ${invoice.project?.number}: ${invoice.projectName}`,
          to: project.toRoute(invoice.project!.number).path,
        },
        {
          text: `Invoice ${invoice.invoiceNumber}`,
          to: invoiceRoute.toRoute(invoice.invoiceNumber).path,
        },
        {
          text: "Rebill",
        },
      ]
    : [{ text: "Loading..." }];

  useBreadcrumbs(breadcrumbs, [invoice]);
  return <></>;
};
