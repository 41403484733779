import React from "react";
import { useNavigate } from "react-router-dom";
import { pendingCustomers } from "~/routes";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import ApproveRejectFormCard from "~/visuals/organisms/ApproveRejectForm";
import Spinner from "~/spinner";
import { useApproveCustomerMutation } from "./mutation.generated";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { PendingCustomer, ValidationResponse } from "~/gql/types";

type ApproveCustomerForm = {
  number: string;
  subNumber: string;
};

const defaultFormValues: ApproveCustomerForm = {
  number: "",
  subNumber: "",
};

type ApproveCustomerProps = {
  customer: PendingCustomer;
  useReload(): React.DispatchWithoutAction;
};

const ApproveCustomer: React.FC<ApproveCustomerProps> = ({
  customer,
  useReload,
}) => {
  useBreadcrumbs(
    [
      { text: "Pending Customers", to: pendingCustomers.path },
      { text: `Approve Customer ${customer?.name}` },
    ],
    [customer]
  );

  const reloadPendingCustomers = useReload();
  const navigate = useNavigate();

  const { createCustomerRequestId, subAccountOf } = customer;

  const [doMutation, { loading }] = useApproveCustomerMutation();

  const onSuccess = () => {
    reloadPendingCustomers();
    navigate(pendingCustomers.path);
  };

  const approveCustomer = async (values: ApproveCustomerForm) => {
    const number = !subAccountOf ? values.number : subAccountOf;
    const subNumber = values.subNumber;

    const variables = {
      number,
      subNumber,
      createCustomerRequestId,
    };

    const result = await doMutation({ variables });

    return result?.data?.customers?.approveCustomer as ValidationResponse;
  };

  return (
    <>
      {loading && <Spinner open={true} />}
      <MutationForm
        onSuccess={onSuccess}
        runMutation={approveCustomer}
        initialValues={defaultFormValues}
      >
        <ErrorRenderer
          render={(error) => (
            <ApproveRejectFormCard
              customer={customer}
              errorMessage={error}
              kind="Approve"
            />
          )}
        />
      </MutationForm>
    </>
  );
};

export default ApproveCustomer;
