import React from "react";
import { parseDuration } from "./durationParser";
import { TimeInput } from "~/visuals/molecules/TimeInput";

export type DurationPickerProps = {
  formField: string;
  label: string;
  helperText: string;
  required?: boolean;
  disabled?: boolean;
};

const DurationPickFormField: React.FC<DurationPickerProps> = (props) => {
  const durationDisplay = (d) => {
    if (!d) {
      return "";
    }
    return `${d.asHours()}`;
  };

  return (
    <TimeInput
      {...{
        ...props,
        className: "duration-pick-form-field",
        focusedDurationDisplay: durationDisplay,
        durationDisplay,
        parseInputVal: parseDuration,
        maxInputLength: 5,
      }}
    />
  );
};

export default DurationPickFormField;
