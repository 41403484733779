import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditEmployeeRateMutationVariables = Types.Exact<{
  ultiProId: Types.Scalars['String'];
  effectiveDate: Types.Scalars['LocalDate'];
  rate: Types.Scalars['Decimal'];
  originalEffectiveDate: Types.Scalars['LocalDate'];
}>;


export type EditEmployeeRateMutation = { __typename?: 'MutationModel', employeeRates?: { __typename?: 'EmployeeRatesMutationModel', editRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const EditEmployeeRateDocument = gql`
    mutation EditEmployeeRate($ultiProId: String!, $effectiveDate: LocalDate!, $rate: Decimal!, $originalEffectiveDate: LocalDate!) {
  employeeRates {
    editRate(
      ultiProId: $ultiProId
      effectiveDate: $effectiveDate
      rate: $rate
      originalEffectiveDate: $originalEffectiveDate
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditEmployeeRateMutationFn = Apollo.MutationFunction<EditEmployeeRateMutation, EditEmployeeRateMutationVariables>;

/**
 * __useEditEmployeeRateMutation__
 *
 * To run a mutation, you first call `useEditEmployeeRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEmployeeRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEmployeeRateMutation, { data, loading, error }] = useEditEmployeeRateMutation({
 *   variables: {
 *      ultiProId: // value for 'ultiProId'
 *      effectiveDate: // value for 'effectiveDate'
 *      rate: // value for 'rate'
 *      originalEffectiveDate: // value for 'originalEffectiveDate'
 *   },
 * });
 */
export function useEditEmployeeRateMutation(baseOptions?: Apollo.MutationHookOptions<EditEmployeeRateMutation, EditEmployeeRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditEmployeeRateMutation, EditEmployeeRateMutationVariables>(EditEmployeeRateDocument, options);
      }
export type EditEmployeeRateMutationHookResult = ReturnType<typeof useEditEmployeeRateMutation>;
export type EditEmployeeRateMutationResult = Apollo.MutationResult<EditEmployeeRateMutation>;
export type EditEmployeeRateMutationOptions = Apollo.BaseMutationOptions<EditEmployeeRateMutation, EditEmployeeRateMutationVariables>;