import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EffectiveTaskRateSheetQueryVariables = Types.Exact<{
  projectNumber: Types.Scalars['Int'];
  asOf: Types.Scalars['LocalDate'];
}>;


export type EffectiveTaskRateSheetQuery = { __typename?: 'QueryModel', projects?: { __typename?: 'ProjectsQueryModel', effectiveTaskRateSheet?: { __typename?: 'TaskRateSheet', name: string, rates?: Array<{ __typename?: 'TaskRate', name: string, rateType: Types.TaskRateType, requiresDaily: boolean, unit: Types.TaskRateUnit, usesEmployeeRate: boolean, crewSize: number } | null> | null } | null } | null };


export const EffectiveTaskRateSheetDocument = gql`
    query EffectiveTaskRateSheet($projectNumber: Int!, $asOf: LocalDate!) {
  projects {
    effectiveTaskRateSheet(asOf: $asOf, projectNumber: $projectNumber) {
      name
      rates {
        name
        rateType
        requiresDaily
        unit
        usesEmployeeRate
        crewSize
      }
    }
  }
}
    `;

/**
 * __useEffectiveTaskRateSheetQuery__
 *
 * To run a query within a React component, call `useEffectiveTaskRateSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useEffectiveTaskRateSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEffectiveTaskRateSheetQuery({
 *   variables: {
 *      projectNumber: // value for 'projectNumber'
 *      asOf: // value for 'asOf'
 *   },
 * });
 */
export function useEffectiveTaskRateSheetQuery(baseOptions: Apollo.QueryHookOptions<EffectiveTaskRateSheetQuery, EffectiveTaskRateSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EffectiveTaskRateSheetQuery, EffectiveTaskRateSheetQueryVariables>(EffectiveTaskRateSheetDocument, options);
      }
export function useEffectiveTaskRateSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EffectiveTaskRateSheetQuery, EffectiveTaskRateSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EffectiveTaskRateSheetQuery, EffectiveTaskRateSheetQueryVariables>(EffectiveTaskRateSheetDocument, options);
        }
export type EffectiveTaskRateSheetQueryHookResult = ReturnType<typeof useEffectiveTaskRateSheetQuery>;
export type EffectiveTaskRateSheetLazyQueryHookResult = ReturnType<typeof useEffectiveTaskRateSheetLazyQuery>;
export type EffectiveTaskRateSheetQueryResult = Apollo.QueryResult<EffectiveTaskRateSheetQuery, EffectiveTaskRateSheetQueryVariables>;