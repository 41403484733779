import React, { useState } from "react";
import AdHocForm from "~/visuals/organisms/Expenses/ExpenseForm/AdHocTypeForm";
import { useWorkDayApi } from "~/WorkDayApi";
import Spinner from "~/spinner";
import { useLoadReceipt } from "../useLoadReceipt";
import { AdHocExpense, AdHocExpenseRate } from "~/gql/types";
import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";

type EditAdHocProps = BaseExpenseFormProps & {
  expenseRate: AdHocExpenseRate;
  expenseEntry: AdHocExpense;
};

const EditAdHocExpense: React.FC<EditAdHocProps> = (props) => {
  const { expenseEntry, project } = props;

  const [imageId, setImageId] = useState<string | null>(
    expenseEntry?.receiptImageId ?? null
  );

  const { editAdHocExpense } = useWorkDayApi();

  const [image, setImage] = useState<any>();

  useLoadReceipt({ image, setImage, expenseEntry });

  if (!image && expenseEntry.receiptImageId) {
    return <Spinner open={true} />;
  }

  const parseNum = (val: any) => (val ? parseFloat(val) : null);

  const editExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc
  ) => {
    const res = await editAdHocExpense({
      ...defaultVals(values),
      receiptImageId: imageId,
      quantity: parseNum(values?.quantity)!,
      rateAmount: parseNum(values?.rate)!,
      expenseId: expenseEntry.id,
      state: project?.location?.state!,
      county: project?.location?.county!,
      payableOverride: values?.payableOverride || null,
    });

    return res;
  };

  const uniqueInitVals: UniqueInitVals = [
    {
      rate: expenseEntry.rate,
      quantity: expenseEntry.quantity,
      receiptImage: image,
    },
  ];

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        uniqueInitVals,
        handleSubmit: editExpense,
        expenseType: "AdHoc",
        formType: "Edit",
      }}
    >
      <AdHocForm {...props} setImageId={setImageId} />
    </ExpensePageWrapper>
  );
};

export default EditAdHocExpense;
