import React, { createContext, useState } from "react";
import { Snackbar } from "@material/react-snackbar";
import "./SnackBar.scss";

type ContextType = React.Dispatch<Alert>;

type AlertAction = {
  label: string;
  action: React.DispatchWithoutAction;
};
export type Alert = {
  key: string;
  message: string;
  isSuccess: boolean;
  action?: AlertAction;
  timeout?: number;
};

export const SnackBarContext = createContext<ContextType>(() => undefined);

export const SnackBarProvider: React.FC = ({ children }) => {
  const [alert, setAlert] = useState<Alert | null>(null);
  const [timeout, setTimeout] = useState(4000);

  const [open, setOpen] = useState<boolean>(
    alert?.message != null &&
      alert?.message != undefined &&
      alert?.message != ""
  );

  const addAlert = (alert: Alert) => {
    setOpen(false);
    setAlert(alert);
    setOpen(true);
    setTimeout(alert.timeout ?? 4000);
  };

  const onClose = (reason) => {
    // the "action" string here is hard-coded into the snackbar component
    if (reason !== "action") {
      return;
    }

    const cb = alert?.action?.action;

    cb && cb();
  };

  return (
    <SnackBarContext.Provider value={addAlert}>
      {children}
      <Snackbar
        key={alert?.key}
        actionText={alert?.action?.label ?? "dismiss"}
        className={alert?.isSuccess ? "success" : "error"}
        open={open}
        aria-describedby="client-snackbar"
        message={alert?.message}
        onClose={onClose}
        timeoutMs={timeout}
      />
    </SnackBarContext.Provider>
  );
};

import { useContext } from "react";

export function useSnackBar(): React.Dispatch<Alert> {
  return useContext(SnackBarContext);
}

export const MockSnackBarProvider: React.FC<{
  addAlert?: (alert: Alert) => void;
}> = ({ addAlert, children }) => (
  <SnackBarContext.Provider value={addAlert ?? (() => undefined)}>
    {children}
  </SnackBarContext.Provider>
);
