import React from "react";
import { Body1 } from "@material/react-typography";
import { useNavigate, useParams } from "react-router-dom";
import * as invoiceRoutes from "../invoiceRoutes";
import TitledCard from "~/titled-card";
import { RebillBreadcrumbs } from "./RebillBreadcrumbs";
import AddCharges from "../AddCharges";
import { ChargeType } from "~/gql/types";
import { useInvoice, useRefreshInvoice } from "../InvoiceContext";

type SelectChargesRouteData = { projectNumber: string; targetInvoice?: string };

type SelectChargesProps = {
  AddChargesComponent?: typeof AddCharges;
};

export const SelectCharges: React.FC<SelectChargesProps> = (props) => {
  const invoice = useInvoice();
  const navigate = useNavigate();
  const refetch = useRefreshInvoice();
  const params = useParams<SelectChargesRouteData>();
  const projectNumber = params.projectNumber!;
  const targetInvoice = params.targetInvoice ?? null;

  const targetInvoiceText = targetInvoice ?? "a new draft invoice";
  const AddChargesComponent = props.AddChargesComponent ?? AddCharges;

  return (
    <TitledCard
      title="Rebill Charges - Select Charges"
      className="select-charges-page"
    >
      <RebillBreadcrumbs />
      <Body1>
        Select charges to rebill from invoice {invoice.invoiceNumber} to{" "}
        {targetInvoiceText}
      </Body1>
      <AddChargesComponent
        open={true}
        onSuccess={() => navigate(invoiceRoutes.home(invoice.invoiceNumber))}
        onClose={() =>
          navigate(
            invoiceRoutes.selectInvoice(invoice.invoiceNumber, projectNumber)
          )
        }
        projectNumber={+projectNumber}
        invoiceNumber={targetInvoice}
        refetch={refetch}
        preloadedCharges={invoice
          .lineItems!.filter(
            (x) =>
              !x?.rebill?.rebilledOnInvoice &&
              x?.projectCharge?.type != ("Tax" as ChargeType)
          )
          .map((x) => x!.projectCharge!)}
      />
    </TitledCard>
  );
};
