import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkPostChargesMutationVariables = Types.Exact<{
  addChargesArgs: Array<Types.AddChargesToInvoiceArgsInput> | Types.AddChargesToInvoiceArgsInput;
}>;


export type BulkPostChargesMutation = { __typename?: 'MutationModel', invoices?: { __typename?: 'InvoiceMutationModel', bulkAddCharges?: { __typename?: 'BulkAddChargesResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null, results?: Array<{ __typename?: 'AddChargesResult', invoiceNumber: string, projectId: number, chargeCount: number } | null> | null } | null } | null };


export const BulkPostChargesDocument = gql`
    mutation BulkPostCharges($addChargesArgs: [AddChargesToInvoiceArgsInput!]!) {
  invoices {
    bulkAddCharges(addChargesArgs: $addChargesArgs) {
      errors {
        argumentName
        message
      }
      results {
        invoiceNumber
        projectId
        chargeCount
      }
    }
  }
}
    `;
export type BulkPostChargesMutationFn = Apollo.MutationFunction<BulkPostChargesMutation, BulkPostChargesMutationVariables>;

/**
 * __useBulkPostChargesMutation__
 *
 * To run a mutation, you first call `useBulkPostChargesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkPostChargesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkPostChargesMutation, { data, loading, error }] = useBulkPostChargesMutation({
 *   variables: {
 *      addChargesArgs: // value for 'addChargesArgs'
 *   },
 * });
 */
export function useBulkPostChargesMutation(baseOptions?: Apollo.MutationHookOptions<BulkPostChargesMutation, BulkPostChargesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkPostChargesMutation, BulkPostChargesMutationVariables>(BulkPostChargesDocument, options);
      }
export type BulkPostChargesMutationHookResult = ReturnType<typeof useBulkPostChargesMutation>;
export type BulkPostChargesMutationResult = Apollo.MutationResult<BulkPostChargesMutation>;
export type BulkPostChargesMutationOptions = Apollo.BaseMutationOptions<BulkPostChargesMutation, BulkPostChargesMutationVariables>;