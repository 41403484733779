import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import {
  Customer,
  useCustomerFromParam,
  useCustomerSearch,
} from "~/refdata/useCustomerSearch";
import { CustomerPicker } from "~/visuals/organisms/CustomerPickerFormField";
import { createProvider } from "./createFilterProvider";

export function useCustomerProvider(
  props: FilterProviderProps
): FilterProvider {
  const customerFromParam = useCustomerFromParam();
  const getCustomers = useCustomerSearch();

  const type = "customer";
  const label = "Customer";

  return createProvider({
    render: () => (
      <CustomerPicker formField="customer" label="Customer" helperText="" />
    ),
    type,
    label,
    props,
    getItems: getCustomers,
    itemFromParam: customerFromParam,
    shouldSuggestText: "cust",
    toSuggestions: (result, _) =>
      (result as Customer[]).map((c) => ({
        text: `${c.name} (${c.number})`,
        value: c,
      })),
    toLabelValue: (value: Customer) => `${value.name} (${value.number})`,
    toSearchArgs: (text: string) => ({ searchText: text }),
    parseResult: (result: any) => result.records,
    toParamValue: (value: Customer) => value.number,
  });
}
