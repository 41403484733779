import React from "react";
import {
  useBillingAdminProvider,
  useProjectManagerProvider,
  useProjectSupervisorProvider,
} from "~/filterProviders/employeeProviders";
import { useCustomerProvider } from "~/filterProviders/useCustomerProvider";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import { useProjectTypeProvider } from "~/filterProviders/useProjectTypeProvider";
import { useCorpLocationProvider } from "~/filterProviders/useCorpLocationProvider";
import { useDivisionProvider } from "~/filterProviders/useDivisionProvider";
import { UltraFilter } from "~/ultra-filter";
import { useFieldOfficeProvider } from "~/filterProviders/useFieldOfficeProvider";
import FieldOffice from "~/graphql/FieldOffice";
import { useDateProvider } from "~/filterProviders/useDateProvider";
import { Moment } from "moment";
import { useLocationStateProvider } from "~/filterProviders/useLocationStateProvider";
import { useTextProvider } from "~/filterProviders/useTextProvider";
import {
  Customer,
  Employee,
  ProjectGroup,
  ProjectSearchStatus,
  ProjectSortBy,
  StateCounty,
} from "~/gql/types";
import { useProjectStatusProvider } from "~/visuals/pages/Projects/useProjectStatusProvider";
import { useStateCountyProvider } from "~/filterProviders/useStateCountyProvider";
import { ProjectRefData } from "~/refdata/sources";
import { useProjectSearchStats } from "./ProjectSearchStatsProvider";
import { useSearchParams } from "react-router-dom";
import { useProjectGroupProvider } from "~/filterProviders/useProjectGroupProvider";
import { useCertifiedPayrollProvider } from "~/filterProviders/yesNoProviders";
import { useServiceDescriptionProvider } from "~/filterProviders/useServiceDescriptionProvider";
import { YesNoFilter } from "~/filterProviders/YesNoPickerFormField";
import { useIndustryProvider } from "~/filterProviders/useIndustryProvider";

export type FilterOptions = {
  searchText: string | null;
  billingAdmin: Employee | null;
  projectSupervisor: Employee | null;
  projectManager: Employee | null;
  customer: Customer | null;
  projectType: string | null;
  division: ProjectRefData | null;
  corpLocation: ProjectRefData | null;
  officeCode: FieldOffice | null;
  dateInEqual: Moment | null;
  dateInBefore: Moment | null;
  dateInAfter: Moment | null;
  locationState: string | null;
  afeWoPo: string | null;
  clientContactName: string | null;
  status: ProjectSearchStatus | null;
  stateCounty: StateCounty | null;
  sortBy: ProjectSortBy;
  projectGroup: ProjectGroup | null;
  certifiedPayroll: YesNoFilter;
  industry: string | null;
  serviceDescription: string | null;
};

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<FilterOptions>;
};

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const [searchParams] = useSearchParams();
  const { statsText } = useProjectSearchStats();

  const providers = [
    useBillingAdminProvider({
      documentationText: "The billing admin assigned to the charge's project.",
    }),
    useProjectManagerProvider({
      documentationText:
        "The project manager assigned to the charge's project.",
    }),
    useProjectSupervisorProvider({
      documentationText:
        "The project supervisor assigned to the charge's project.",
    }),
    useCustomerProvider({
      documentationText: "The customer associated with the charge's project.",
    }),
    useProjectTypeProvider({ documentationText: "The project's type" }),
    useFieldOfficeProvider({ documentationText: "The project's field office" }),
    useCorpLocationProvider({
      documentationText: "The corporate location associated with the project",
    }),
    useDivisionProvider({ documentationText: "The project's division" }),
    useDateProvider({
      documentationText:
        "Search for projects with date in after this date (exclusive).",
      type: "dateInAfter",
      label: "After Date",
      suggestText: "aft",
    }),
    useDateProvider({
      documentationText:
        "Search for projects with date in before this date (exclusive).",
      type: "dateInBefore",
      label: "Before Date",
      suggestText: "bef",
    }),
    useDateProvider({
      documentationText: "Search for projects with this specific date in.",
      type: "dateInEqual",
      label: "Actual Date In",
      suggestText: "date",
    }),
    useLocationStateProvider({
      documentationText: "The project location's state.",
      suggestText: "state",
      label: "State",
    }),
    useStateCountyProvider({
      documentationText: "The project location's state and county.",
      suggestText: "sc",
      label: "State/County",
    }),
    useTextProvider({
      documentationText: "The project's AFE/PO/WO.",
      toParam: (x) => x?.value,
      suggestText: "afewopo",
      label: "AFE/WO/PO",
      type: "afeWoPo",
    }),
    useTextProvider({
      documentationText: "The project's client contact name",
      toParam: (x) => x?.value,
      suggestText: "clientContact",
      label: "Client Contact Name",
      type: "clientContactName",
    }),
    useProjectStatusProvider({ documentationText: "The project's status" }),
    useProjectGroupProvider({
      documentationText: "The project's customer project group",
    }),
    useServiceDescriptionProvider({
      documentationText: "The project's service description",
    }),
    useIndustryProvider({
      documentationText:
        "The industry associated with the project's service description",
    }),
    useCertifiedPayrollProvider({
      documentationText: "Show projects with certified payroll",
    }),
  ] as FilterProvider[];

  const onChanged = (filters: ActiveFilter[], searchText: string) => {
    searchText;

    const newFilters = {
      billingAdmin:
        filters.find((f) => f.type === "billingAdmin")?.value || null,
      projectSupervisor:
        filters.find((f) => f.type === "projectSupervisor")?.value || null,
      projectManager:
        filters.find((f) => f.type === "projectManager")?.value || null,
      searchText: searchText || null,
      customer: filters.find((f) => f.type === "customer")?.value || null,
      projectType: filters.find((f) => f.type === "projectType")?.value || null,
      division: filters.find((f) => f.type === "division")?.value || null,
      corpLocation:
        filters.find((f) => f.type === "corpLocation")?.value || null,
      officeCode: filters.find((f) => f.type === "officeCode")?.value || null,
      dateInEqual: filters.find((f) => f.type === "dateInEqual")?.value || null,
      dateInBefore:
        filters.find((f) => f.type === "dateInBefore")?.value || null,
      dateInAfter: filters.find((f) => f.type === "dateInAfter")?.value || null,
      locationState:
        filters.find((f) => f.type === "locationState")?.value || null,
      afeWoPo: filters.find((f) => f.type === "afeWoPo")?.value || null,
      clientContactName:
        filters.find((f) => f.type === "clientContactName")?.value || null,
      status: filters.find((f) => f.type === "status")?.value || null,
      stateCounty: filters.find((f) => f.type === "stateCounty")?.value || null,
      sortBy: (searchParams.get("sortBy") || "CustomerName") as ProjectSortBy,
      projectGroup:
        filters.find((f) => f.type === "projectGroup")?.value || null,
      certifiedPayroll:
        filters.find((f) => f.type === "certifiedPayroll")?.value || null,
      industry: filters.find((f) => f.type === "industry")?.value || null,
      serviceDescription:
        filters.find((f) => f.type === "serviceDescription")?.value
          .displayName || null,
    };

    onFiltersChanged(newFilters);
  };

  return (
    <div className="filter-form">
      <UltraFilter
        {...{
          providers,
          dialogTitle: "Projects Search",
          label: "Type to search projects",
          onFiltersChanged: onChanged,
          stats: statsText,
        }}
      />
    </div>
  );
};
