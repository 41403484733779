import React from "react";
import { useUpdateWorkWeekStatus } from "~/visuals/organisms/TimesheetDisplay/useUpdateWorkWeekStatus";
import { WorkWeekAction } from "./WorkWeekAction";
import { TimesheetAction, TimesheetState, Timesheet } from "./types";
import { RejectWorkWeek } from "./RejectWorkWeek";
import moment from "moment";
import Spinner from "~/spinner";
import ClickSwallower from "~/click-swallower";
import { Body1 } from "@material/react-typography";
import "./TimesheetActions.scss";
import { ReceiptReport } from "./ReceiptReport";
import { useTimesheetContext } from "./TimesheetContext";
import { WorkWeekProjectDailies } from "./WorkWeekProjectDailies";
import Button from "~/button";
import { weeklyProjectTasks } from "~/WorkData/routes";
import { useRelativeNav } from "~/relative-nav";
import { useApolloClient } from "@apollo/client";
import { GetTimesheetsDocument } from "./timesheetQuery.generated";
import { EmployeeFinalPayroll } from "../EmployeeFinalPayroll";
import { NextWeekDisplayer } from "./NextWeekDisplayer";

type TimesheetActionsProps = {
  row: Timesheet;
  state: TimesheetState;
  dispatch: TimesheetAction;
  setTimesheet: React.Dispatch<Timesheet>;
  FinalPayrollComponent?: typeof EmployeeFinalPayroll;
};

export const TimesheetActions: React.FC<TimesheetActionsProps> = (props) => {
  const { row, state, dispatch, setTimesheet, FinalPayrollComponent } = props;

  const { handleSubmit, handleApprove, handleReject, handleReset, loading } =
    useUpdateWorkWeekStatus({
      week: row,
      refetch: () =>
        dispatch({
          tag: "Reset",
          filterOptions: state.filterOptions,
        }),
    });

  const client = useApolloClient();

  const reset = async () => {
    const result = await client.query({
      query: GetTimesheetsDocument,
      variables: { timesheets: [row.timesheetId] },
    });

    const updated = result?.data?.timesheets?.timesheetSearch?.records;

    setTimesheet(updated && updated.length > 0 ? updated[0] : row);
  };

  const { mode } = useTimesheetContext();

  const weekStartFmt = moment(row.weekBeginning).format("M/DD/YYYY");

  const nameText = `${row.employee!.firstName} ${row.employee!.lastName}`;
  const dateText = `${moment(row.weekBeginning, "YYYY-MM-DD").format(
    "MM/DD/YYYY"
  )}`;

  const text = `Timesheet: ${nameText}, ${dateText}`;

  const nav = useRelativeNav();

  const weeklyTasksPath =
    mode === "AllTimesheets"
      ? `${row.employee!.userPrincipalName}/${weeklyProjectTasks}/${
          row.weekBeginning
        }`
      : `${weeklyProjectTasks}/${row.weekBeginning}`;

  const FinalPayroll = FinalPayrollComponent ?? EmployeeFinalPayroll;

  const isFutureWeek = moment(row.weekBeginning, "YYYY-MM-DD").isAfter(
    moment().startOf("week")
  );

  return (
    <ClickSwallower className="timesheet-actions">
      {loading && <Spinner open={loading} />}

      {mode !== "MyTimesheets" && <Body1>{text}</Body1>}

      {row.canBeSubmitted && (
        <WorkWeekAction
          {...{ action: handleSubmit, label: "Submit", outlined: true }}
        />
      )}

      {row.canBeApproved && (
        <WorkWeekAction
          {...{ action: handleApprove, label: "Approve", outlined: true }}
        />
      )}

      {row.canBeReset && (
        <WorkWeekAction
          {...{ action: handleReset, label: "Reset", outlined: true }}
        />
      )}

      {row.canBeRejected && (
        <RejectWorkWeek {...{ handleReject, date: weekStartFmt }} />
      )}

      {row.daySummaries?.some((x) => x?.hasReceipts) &&
        mode !== "MyTimesheets" && (
          <ReceiptReport
            {...{
              timesheetId: row.timesheetId,
              weekStart: row.weekBeginning,
            }}
          />
        )}

      <WorkWeekProjectDailies {...{ row }} />

      {!isFutureWeek && (
        <Button outlined onClick={() => nav(weeklyTasksPath)}>
          Weekly Project Tasks
        </Button>
      )}

      {row.employee?.isTerminated && (
        <FinalPayroll {...{ employee: row.employee, outlined: true, reset }} />
      )}

      <NextWeekDisplayer {...{ row, state, dispatch }} />
    </ClickSwallower>
  );
};
