import { RevenueReportGroupingArgsInput } from "~/gql/types";

export const headerLabels: Record<
  keyof RevenueReportGroupingArgsInput,
  string
> = {
  project: "Project",
  customer: "Customer",
  serviceDescription: "Service Description",
  projectType: "Project Type",
  projectManager: "Project Manager",
  industry: "Industry",
  officeCode: "Office",
};

export type ReportRowData = {
  total: number;
  percentage: number;
  industry?: string | null;
  officeCode?: string | null;
  projectType?: string | null;
  customer?: {
    name: string;
    number?: string | null;
  } | null;
  project?: { name: string; number: number } | null;
  projectManager?: {
    firstName: string;
    lastName: string;
    email: string;
  } | null;
  serviceDescription?: {
    industry: string;
    projectType: string;
    description: string;
    displayName: string;
  } | null;
};

export type ReportRowDataWithoutPercentage = Omit<ReportRowData, "percentage">;

export function addPercentage(
  rows: ReportRowDataWithoutPercentage[]
): ReportRowData[] {
  let total = 0;
  rows.forEach((row) => {
    total += row?.total || 0;
  });

  return rows.map((row) => ({
    ...row,
    percentage: row.total / total,
  }));
}

export function toLabelData(row: ReportRowData): (string | null | undefined)[] {
  const pmName = row.projectManager
    ? `${row.projectManager.firstName} ${row.projectManager.lastName}`
    : null;
  const labelData = [
    row.project && `${row.project.name} (${row.project.number})`,
    row.customer && `${row.customer.name} (${row.customer.number})`,
    row.serviceDescription && `${row.serviceDescription.displayName}`,
    row.projectType,
    pmName,
    row.industry,
    row.officeCode,
  ];

  return labelData;
}
