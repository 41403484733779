import React from "react";
import { UltraFilter } from "~/ultra-filter";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import { useProjectProvider } from "~/filterProviders/useProjectProvider";
import {
  useBillingAdminProvider,
  useProjectManagerProvider,
  useProjectSupervisorProvider,
} from "~/filterProviders/employeeProviders";
import { useCustomerProvider } from "~/filterProviders/useCustomerProvider";
import { useInvoiceStateProvider } from "~/filterProviders/itemStateProviders";
import { useDateProvider } from "~/filterProviders/useDateProvider";
import { useCurrencyProvider } from "~/filterProviders/useCurrencyProvider";
import { useFieldOfficeProvider } from "~/filterProviders/useFieldOfficeProvider";
import { useLocationStateProvider } from "~/filterProviders/useLocationStateProvider";
import { useTextProvider } from "~/filterProviders/useTextProvider";
import { useInvoiceSearchStats } from "./InvoiceSearchStatsProvider";
import { InvoiceSortBy } from "~/gql/types";
import { useSearchParams } from "react-router-dom";
import { FilterOptions } from "./types";
import { useProjectGroupProvider } from "~/filterProviders/useProjectGroupProvider";

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<FilterOptions>;
};

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const [searchParams] = useSearchParams();
  const { statsText } = useInvoiceSearchStats();

  const providers = [
    useBillingAdminProvider({
      documentationText: "The billing admin assigned to the invoice's project.",
    }),
    useProjectManagerProvider({
      documentationText:
        "The project manager assigned to the invoice's project.",
    }),
    useProjectSupervisorProvider({
      documentationText:
        "The project supervisor assigned to the invoice's project.",
    }),
    useProjectProvider({ documentationText: "The invoice's project." }),
    useCustomerProvider({
      documentationText: "The customer associated with the invoice's project.",
    }),
    useInvoiceStateProvider({
      documentationText: "The state that the invoice is in.",
    }),
    useDateProvider({
      documentationText:
        "Search for invoices with invoice date after this date (exclusive).",
      type: "afterDate",
      label: "After Date",
      suggestText: "aft",
    }),
    useDateProvider({
      documentationText:
        "Search for invoices with invoice date before this date (exclusive).",
      type: "beforeDate",
      label: "Before Date",
      suggestText: "bef",
    }),
    useDateProvider({
      documentationText: "Search for invoices with this specific invoice date.",
      type: "actualDate",
      label: "Actual Date",
      suggestText: "date",
    }),
    useDateProvider({
      documentationText:
        "Search for invoices with cancelled date after this date (exclusive).",
      type: "afterCancelledDate",
      label: "After Cancelled Date",
      suggestText: "aftCan",
    }),
    useDateProvider({
      documentationText:
        "Search for invoices with cancelled date before this date (exclusive).",
      type: "beforeCancelledDate",
      label: "Before Cancelled Date",
      suggestText: "befCan",
    }),
    useDateProvider({
      documentationText:
        "Search for invoices with this specific cancelled date.",
      type: "actualCancelledDate",
      label: "Actual Cancelled Date",
      suggestText: "dateCan",
    }),
    useCurrencyProvider({
      documentationText: "The invoice's total.",
      type: "amount",
      label: "Amount",
      suggestText: "amt",
    }),
    useFieldOfficeProvider({
      documentationText: "The field office of the invoice's project",
    }),
    useLocationStateProvider({
      documentationText: "The invoice project location's state.",
      suggestText: "usstate",
      label: "Location State",
    }),
    useTextProvider({
      documentationText: "The invoice project's AFE/PO/WO.",
      toParam: (x) => x?.value,
      suggestText: "afewopo",
      label: "AFE/WO/PO",
      type: "afeWoPo",
    }),
    useProjectGroupProvider({
      documentationText:
        "The project group associated to the invoice's project",
    }),
  ] as FilterProvider[];

  const onChanged = (filters: ActiveFilter[], searchText: string) => {
    searchText;

    const newFilters = {
      billingAdmin:
        filters.find((f) => f.type === "billingAdmin")?.value || null,
      projectSupervisor:
        filters.find((f) => f.type === "projectSupervisor")?.value || null,
      projectManager:
        filters.find((f) => f.type === "projectManager")?.value || null,
      searchText: searchText || null,
      project: filters.find((f) => f.type === "project")?.value || null,
      customer: filters.find((f) => f.type === "customer")?.value || null,
      state: filters.find((f) => f.type === "invoiceState")?.value || null,
      afterDate: filters.find((f) => f.type === "afterDate")?.value || null,
      beforeDate: filters.find((f) => f.type === "beforeDate")?.value || null,
      actualDate: filters.find((f) => f.type === "actualDate")?.value || null,
      afterCancelledDate:
        filters.find((f) => f.type === "afterCancelledDate")?.value || null,
      beforeCancelledDate:
        filters.find((f) => f.type === "beforeCancelledDate")?.value || null,
      actualCancelledDate:
        filters.find((f) => f.type === "actualCancelledDate")?.value || null,
      amount: filters.find((f) => f.type === "amount")?.value || null,
      officeCode: filters.find((f) => f.type === "officeCode")?.value || null,
      locationState:
        filters.find((f) => f.type === "locationState")?.value || null,
      afeWoPo: filters.find((f) => f.type === "afeWoPo")?.value || null,
      sortBy: (searchParams.get("sortBy") || "CustomerName") as InvoiceSortBy,
      projectGroup:
        filters.find((f) => f.type === "projectGroup")?.value || null,
    };

    onFiltersChanged(newFilters);
  };

  return (
    <div className="filter-form">
      <UltraFilter
        {...{
          providers,
          dialogTitle: "Invoices Search",
          label: "Type to search invoices",
          onFiltersChanged: onChanged,
          stats: statsText,
        }}
      />
    </div>
  );
};
