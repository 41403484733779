import React, { useEffect, useReducer } from "react";
import { Body1 } from "@material/react-typography";
import { Dialog, DialogContent } from "~/dialog";
import { useLogout, useRawJwt } from "../AuthenticationManager";
import { popupAuthRefresh as popupAuthRefreshRoute } from "~/routes";
import { useIsActive } from "../useIsActive";
import { Action, reducer, State } from "./stateMachine";
import Spinner from "~/spinner";
import { NearExpireManager } from "./NearExpireManager";
import { ReauthDialog } from "./ReauthDialog";
import { RefreshIframe } from "./RefreshIframe";

const AuthenticatingMessage: React.FC<{ open: boolean }> = ({ open }) => (
  <>
    <Spinner open={open} />
    <Dialog open={open} data-open={open} className="authenticating-message">
      <DialogContent>
        <Body1>
          Handling authentication in another window; please stand by...
        </Body1>
      </DialogContent>
    </Dialog>
  </>
);

const openPopup = (): void => {
  const newUrl = new URL(window.location.href);
  newUrl.pathname = popupAuthRefreshRoute.path;
  newUrl.hash = "";
  window.open(newUrl.href, undefined, "popup");
};

export const ReauthPrompt: React.FC = () => {
  const jwt = useRawJwt()!;
  const isActive = useIsActive();

  const logout = useLogout();

  const [state, dispatch] = useReducer((a: State, b: Action) => reducer(a, b), {
    tag: "Authenticated",
  } as State);

  useEffect(() => {
    if (state.tag === "BigPopup") {
      openPopup();
    }
  }, [state.tag]);

  return (
    <>
      <ReauthDialog {...{ state, dispatch, logout }} />
      <RefreshIframe {...{ state, dispatch }} />
      <AuthenticatingMessage {...{ open: state.tag === "BigPopup" }} />
      <NearExpireManager {...{ state, dispatch, jwt, isActive }} />
    </>
  );
};
