import React from "react";
import { useConfirmationPrompt } from "~/confirmation-prompt";
import { useSnackBar } from "~/snackbar";
import Button from "~/button";
import { useRecallInvoiceMutation } from "./recallInvoice.generated";
import Spinner from "~/spinner";
import { Invoice, ValidationError } from "~/gql/types";

type RecallInvoiceProps = {
  invoice: Invoice;
  promptFn?: () => void;
  refetch: React.DispatchWithoutAction;
};

const RecallInvoice: React.FC<RecallInvoiceProps> = ({
  invoice,
  promptFn,
  refetch,
}) => {
  const prompt = useConfirmationPrompt();
  const addAlert = useSnackBar();

  const [doMutation, { loading }] = useRecallInvoiceMutation();

  if (!invoice.canRecall) {
    return null;
  }

  const recallInvoice = async () => {
    const variables = { invoiceNumber: invoice.invoiceNumber };

    const result = await doMutation({ variables });
    const data = result?.data?.invoices?.recall;

    const errors = (result?.errors ?? data?.errors ?? []) as ValidationError[];

    if (errors.length > 0) {
      const formErrors: string[] = [];
      errors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          formErrors.push(`${argumentName}: ${message}`);
        } else if (message) {
          formErrors.push(message);
        }
      });

      addAlert({
        key: `${invoice.invoiceNumber}`,
        message: formErrors.join(" "),
        isSuccess: false,
      });
    } else {
      addAlert({
        key: `${invoice.invoiceNumber}`,
        message: `Invoice ${invoice.invoiceNumber} set back to 'Draft' state`,
        isSuccess: true,
      });

      refetch();
    }
  };

  const openPrompt = async () => {
    const promptResult = await prompt({
      cancelButtonText: "Don't do anything",
      confirmButtonText: "Yes, recall invoice",
      message: "Are you sure you want to recall this invoice?",
      title: `Recall Invoice ${invoice.invoiceNumber}`,
    });

    if (promptResult !== "Confirm") {
      return;
    }

    await recallInvoice();
  };

  const handlePrompt = promptFn ?? openPrompt;

  return (
    <>
      <Button primary onClick={handlePrompt}>
        Recall
      </Button>
      <Spinner open={loading} />
    </>
  );
};

export default RecallInvoice;
