import React, { createContext, useContext } from "react";
import Spinner from "~/spinner";
import FormCard from "~/form-card";
import ExpenseTitle, { ExpenseTypeName } from "../ExpenseTitle";
import { ExpenseFormType, FormProps } from "./CommonTypes";
import ExpenseFormBottom from "./ExpenseFormBottom";
import { AdminNotes } from "~/visuals/organisms/AdminNotes";
import { InactiveProjectWarning } from "~/visuals/organisms/InactiveProjectWarning";
import { Expense } from "~/WorkDayApi/expenseTypes";

type ExpenseFormWrapperProps = FormProps & {
  typeName: ExpenseTypeName;
  expenseEntry?: Expense;
};

export const ExpenseFormWrapper: React.FC<ExpenseFormWrapperProps> = (
  props
) => {
  const { typeName, expenseEntry, date, project, children } = props;

  const { type, loading, errorMessage } = useExpenseWrapper();

  return (
    <div className="expense-entry">
      <ExpenseTitle
        {...{ type }}
        expenseId={expenseEntry?.id}
        typeName={typeName}
      />
      <Spinner open={loading} />
      <FormCard>
        {children}
        <AdminNotes />

        <InactiveProjectWarning {...{ project }} />

        <ExpenseFormBottom
          {...{ errorMessage, type, expenseId: expenseEntry?.id, date }}
        />
      </FormCard>
    </div>
  );
};

export type ExpenseWrapperContext = {
  type: ExpenseFormType;
  errorMessage: string | null;
  loading: boolean;
};

export const ExpenseWrapperContext = createContext<ExpenseWrapperContext>({
  type: "Create",
  errorMessage: null,
  loading: false,
});

export function useExpenseWrapper(): ExpenseWrapperContext {
  const context = useContext(ExpenseWrapperContext);
  return context;
}
