import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckoutProjectRequestMutationVariables = Types.Exact<{
  requestId: Types.Scalars['String'];
  overrideCheckout: Types.Scalars['Boolean'];
}>;


export type CheckoutProjectRequestMutation = { __typename?: 'MutationModel', projectRequests?: { __typename?: 'ProjectRequestMutationModel', checkout?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CheckoutProjectRequestDocument = gql`
    mutation CheckoutProjectRequest($requestId: String!, $overrideCheckout: Boolean!) {
  projectRequests {
    checkout(requestId: $requestId, overrideCheckout: $overrideCheckout) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CheckoutProjectRequestMutationFn = Apollo.MutationFunction<CheckoutProjectRequestMutation, CheckoutProjectRequestMutationVariables>;

/**
 * __useCheckoutProjectRequestMutation__
 *
 * To run a mutation, you first call `useCheckoutProjectRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutProjectRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutProjectRequestMutation, { data, loading, error }] = useCheckoutProjectRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      overrideCheckout: // value for 'overrideCheckout'
 *   },
 * });
 */
export function useCheckoutProjectRequestMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutProjectRequestMutation, CheckoutProjectRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckoutProjectRequestMutation, CheckoutProjectRequestMutationVariables>(CheckoutProjectRequestDocument, options);
      }
export type CheckoutProjectRequestMutationHookResult = ReturnType<typeof useCheckoutProjectRequestMutation>;
export type CheckoutProjectRequestMutationResult = Apollo.MutationResult<CheckoutProjectRequestMutation>;
export type CheckoutProjectRequestMutationOptions = Apollo.BaseMutationOptions<CheckoutProjectRequestMutation, CheckoutProjectRequestMutationVariables>;