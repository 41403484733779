import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { viewExpenseRateSheet } from "~/routes";
import ExpenseRateFormRouter, {
  ExpenseRateFormPage,
} from "~/visuals/organisms/ExpenseRateForm/ExpenseRateFormRouter";
import { NewAdHocExpenseRate } from "./NewAdHocExpenseRate";
import { CreateExpenseRateFormProps } from "./types";
import { ErrorRenderer } from "~/forms/MutationForm";
import { NewAtvExpenseRate } from "./NewAtvExpenseRate";
import { NewFuelExpenseRate } from "./NewFuelExpenseRate";
import { NewMileageExpenseRate } from "./NewMileageExpenseRate";
import { NewMobileAllowanceExpenseRate } from "./NewMoblieAllowanceExpenseRate";
import { NewPerDiemExpenseRate } from "./NewPerDiemExpenseRate";
import { NewReceiptValuedExpenseRate } from "./NewReceiptValuedExpenseRate";
import { NewTcpAllowanceExpenseRate } from "./NewTcpAllowanceExpenseRate";

type NewExpenseRateProps = {
  rateSheetName: string;
  useReload(): React.DispatchWithoutAction;
};

type FormType = "Create" | "Edit";

export const parseNum = (val: any): number | null =>
  val ? parseFloat(val) : null;

const OuterRouter: React.FC<
  CreateExpenseRateFormProps & { page: ExpenseRateFormPage }
> = (props) => {
  const { page, children } = props;

  if (page === "AdHoc") {
    return <NewAdHocExpenseRate {...props} />;
  }
  if (page === "Atv") {
    return <NewAtvExpenseRate {...props} />;
  }
  if (page === "Fuel") {
    return <NewFuelExpenseRate {...props} />;
  }
  if (page === "Mileage") {
    return <NewMileageExpenseRate {...props} />;
  }
  if (page === "MobileAllowance") {
    return <NewMobileAllowanceExpenseRate {...props} />;
  }
  if (page === "PerDiem") {
    return <NewPerDiemExpenseRate {...props} />;
  }
  if (page === "ReceiptValue") {
    return <NewReceiptValuedExpenseRate {...props} />;
  }
  if (page === "TcpAllowance") {
    return <NewTcpAllowanceExpenseRate {...props} />;
  }

  return <>{children}</>;
};

const NewExpenseRate: React.FC<NewExpenseRateProps> = ({
  rateSheetName,
  useReload,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);

  const [page, setPage] = useState<ExpenseRateFormPage>("Buttons");

  const reloadRateSheet = useReload();

  if (redirect) {
    return <Navigate to={viewExpenseRateSheet.toRoute(rateSheetName).path} />;
  }

  const initialValues = {
    name: null,
    invoiceLabel: null,
    expenseAccount: null,
    glAccount: null,
    expenseRateType: null,
    billableOverride: null,
    billableAmount: null,
    billableAmountPerMile: null,
    reimbursableAmount: null,
    reimbursableAmountPerMile: null,
    kind: null,
    requiresLocation: false,
    category: null,
    billable: false,
    quantity: null,
    rate: null,
  };

  const outerProps = {
    setLoading,
    rateSheetName,
    initialValues,
    onSuccess: async () => {
      await reloadRateSheet();
      setRedirect(true);
    },
    page,
  };

  const innerProps = {
    type: "Create" as FormType,
    rateSheetName,
    loading,
  };

  return (
    <OuterRouter {...outerProps}>
      <ErrorRenderer
        render={(error) => (
          <ExpenseRateFormRouter
            {...{
              formProps: {
                ...innerProps,
                errorMessage: error,
              },
              page,
              setPage,
            }}
          />
        )}
      />
    </OuterRouter>
  );
};

export default NewExpenseRate;
