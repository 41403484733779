import { invoice as invoiceRoute } from "~/routes";

export const pathFragments = {
  rebillInvoiceSelect: "rebill-invoice-select",
  rebillProjectSelect: "rebill-project-select",
  rebillChargeSelect: "rebill-charge-select",
};

export function home(invoiceNumber: string): string {
  return invoiceRoute.toRoute(invoiceNumber).path;
}

export function selectInvoice(
  invoiceNumber: string,
  projectNumber: string
): string {
  return (
    home(invoiceNumber) +
    "/" +
    pathFragments.rebillInvoiceSelect +
    "/" +
    projectNumber
  );
}

export function selectCharges(
  invoiceNumber: string,
  projectNumber: string,
  targetInvoice?: string
): string {
  if (!targetInvoice) {
    return (
      home(invoiceNumber) +
      "/" +
      pathFragments.rebillChargeSelect +
      "/" +
      projectNumber
    );
  }

  return (
    home(invoiceNumber) +
    "/" +
    pathFragments.rebillChargeSelect +
    "/" +
    projectNumber +
    "/" +
    targetInvoice
  );
}

export function selectProject(invoiceNumber: string): string {
  return home(invoiceNumber) + "/" + pathFragments.rebillProjectSelect;
}
