import React from "react";
import WarningMessage from "~/warning-message";
import { Project, ProjectStatus } from "~/gql/types";
import "./InactiveProjectWarning.scss";

export const InactiveProjectWarning: React.FC<{ project: Project | null }> = ({
  project,
}) => {
  if (!project || project.status === ProjectStatus.Active) {
    return null;
  }

  return (
    <div className="inactive-project-warning">
      <WarningMessage
        {...{
          icon: "warning",
          message: `Warning: Project ${project!.number} is inactive`,
        }}
      />
    </div>
  );
};
