import React from "react";
import { Charge } from "../types";
import Button from "~/button";
import { useGetTpiByProjectChargeIdQuery } from "./GetTpiByProjectChargeId.generated";
import { InlineProgress } from "~/inline-progress";
import { thirdPartyInvoice } from "~/routes";
import ClickSwallower from "~/click-swallower";

type ThirdPartyInvoiceButtonProps = {
  entry: Charge;
  className?: string;
};

const ThirdPartyInvoiceButtonInner: React.FC<ThirdPartyInvoiceButtonProps> = ({
  entry,
  className,
}) => {
  const { data } = useGetTpiByProjectChargeIdQuery({
    variables: { id: entry.id },
  });

  if (!data) {
    return <InlineProgress size={"xsmall"} />;
  }

  const invoiceNumber = data?.thirdPartyInvoices?.single?.invoiceNumber;

  const path = thirdPartyInvoice.toRoute(invoiceNumber!).path;

  if (!entry.isThirdParty) {
    return null;
  }

  return (
    <ClickSwallower>
      <Button
        className={className}
        icon="description"
        title={`Go to Third Party Invoice ${invoiceNumber}`}
        href={path}
      />
    </ClickSwallower>
  );
};

export const ThirdPartyInvoiceButton: React.FC<ThirdPartyInvoiceButtonProps> = (
  props
) => {
  if (props.entry.isThirdParty) {
    return <ThirdPartyInvoiceButtonInner {...props} />;
  }

  return null;
};
