import React from "react";
import { GlobalSearchDisplay } from "./GlobalSearchDisplay";
import { useApolloClient } from "@apollo/client";
import { useProjectSearch } from "~/refdata/useProjectSearch";
import { Customer, Invoice, Project } from "~/gql/types";
import { InvoicesSearchDocument } from "~/invoice-search/query.generated";
import { useCustomerSearch } from "~/refdata/useCustomerSearch";

export const GlobalSearch: React.FC = () => {
  const client = useApolloClient();
  const searchProjects = useProjectSearch();
  const searchCustomers = useCustomerSearch();

  const getProjects = async (searchText: string): Promise<Project[]> => {
    const result = await searchProjects({ searchText });
    return (result?.records ?? []) as Project[];
  };

  const getInvoices = async (searchText: string): Promise<Invoice[]> => {
    const result = await client.query({
      query: InvoicesSearchDocument,
      variables: { searchText },
    });

    return (result?.data?.invoices?.search?.records ?? []) as Invoice[];
  };

  const getCustomers = async (searchText: string): Promise<Customer[]> => {
    const result = await searchCustomers({ searchText });
    return (result?.records ?? []) as Customer[];
  };

  return (
    <GlobalSearchDisplay {...{ getProjects, getInvoices, getCustomers }} />
  );
};
