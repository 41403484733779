import { ApolloClient } from "@apollo/client";
import createRefdataApi from "../createRefdataApi";
import { ProjectTypesQuery, ProjectTypesDocument } from "./query.generated";

async function projectTypeFetcher(client: ApolloClient<any>) {
  const result = await client.query<ProjectTypesQuery>({
    query: ProjectTypesDocument,
  });

  return result?.data?.projects?.projectTypes as string[];
}

const api = createRefdataApi(projectTypeFetcher, "project type");

export const ProjectTypeRefdataContext = api.Provider;
export const useProjectTypesAsync = api.useDataAsync;
export const useProjectTypes = api.useData;

export function useProjectTypeFromParam(): (
  name: string
) => Promise<string | null> {
  const getProjectTypes = useProjectTypesAsync();

  return async (name: string) => {
    const result = await getProjectTypes();
    const type = result?.find((x) => x === name);

    return type ?? null;
  };
}
