import { ActiveFilter } from "../types";
import { Action, RemoveFilter, SelectFilter, UpdateFilters } from "./actions";
import {
  FilteringError,
  FilteringLoading,
  FilteringSuggestions,
  FilteringText,
  Focused,
  State,
  Unfocused,
} from "./states";

export const defaultHelperText =
  "Enter search text. Follow the prompts to add filters.";
export const suggestionsVisibleText =
  "Use up/down arrows and press ENTER or click with mouse to make selection";

const filterFilters = (state: State, filters: ActiveFilter[]): ActiveFilter[] =>
  filters.filter((f) => {
    const provider = state.providers.find((p) => p.type === f.type);
    const hide = provider?.hide && provider?.hide(filters);
    return !hide;
  });

const removeFilter = (state: State, action: RemoveFilter): State => ({
  ...state,
  activeFilters: filterFilters(
    state,
    state.activeFilters.filter((f) => f.type !== action.filter.type)
  ),
});

const selectFilter = (state: State, action: SelectFilter): State => {
  const updatedFilters = [
    ...state.activeFilters.filter((f) => f.type !== action.filter.type),
    action.filter,
  ];

  const newState = {
    ...state,
    searchText: "",
    activeFilters: filterFilters(state, updatedFilters),
  };

  const unfocus = state.tag === "filtering-suggestions" && !state.hasInputFocus;

  if (state.tag !== "unfocused" && !unfocus) {
    newState.tag = "focused";
    newState.helperText = defaultHelperText;
  }

  if (unfocus) {
    newState.tag = "unfocused";
    newState.searchText = "";
    newState.helperText = "";
  }

  return newState;
};

const updateFilters = (state: State, action: UpdateFilters): State => ({
  ...state,
  activeFilters: filterFilters(state, action.filters),
});

const getMatchedType = (state: State, searchText: string) => {
  return state.providers
    .filter((p) => !state.activeFilters.find((f) => f.type === p.type))
    .filter((p) => !p.hide || !p.hide(state.activeFilters))
    .find((p) => searchText && p.shouldSuggest(searchText));
};

function reduceUnfocused(state: Unfocused, action: Action): State {
  if (action.tag === "focus") {
    return {
      ...state,
      tag: "focused",
      helperText: defaultHelperText,
    };
  }
  if (action.tag === "update-filters") {
    return updateFilters(state, action);
  }
  if (action.tag === "select-filter") {
    return selectFilter(state, action);
  }
  if (action.tag === "remove-filter") {
    return removeFilter(state, action);
  }

  return state;
}

function reduceFocused(state: Focused, action: Action): State {
  if (action.tag === "blur") {
    return {
      ...state,
      tag: "unfocused",
      helperText: "",
    };
  }
  if (action.tag === "activate-filter") {
    const matchedFilter = getMatchedType(state, state.searchText)!;

    return {
      ...state,
      tag: "filtering-text",
      selectedFilter: matchedFilter,
      canKeyFilter: false,
      searchText: "",
      helperText: "Enter search text. Follow the prompts to add filters.",
    };
  }
  if (action.tag === "update-filters") {
    return updateFilters(state, action);
  }
  if (action.tag === "select-filter") {
    return selectFilter(state, action);
  }
  if (action.tag === "remove-filter") {
    return removeFilter(state, action);
  }
  if (action.tag === "update-text") {
    const searchTypeMatch = getMatchedType(state, action.searchText);

    const helperText =
      searchTypeMatch && action.searchText
        ? `Press TAB to search for a specific ${searchTypeMatch.label}`
        : defaultHelperText;

    return {
      ...state,
      searchText: action.searchText,
      canKeyFilter: !!searchTypeMatch,
      helperText,
    };
  }
  return state;
}

function reduceFilteringText(state: FilteringText, action: Action): State {
  if (action.tag === "blur") {
    return {
      ...state,
      tag: "unfocused",
      searchText: "",
      helperText: "",
    };
  }
  if (action.tag === "update-text") {
    return {
      ...state,
      searchText: action.searchText,
    };
  }
  if (action.tag === "cancel-filter") {
    return {
      ...state,
      tag: "focused",
    };
  }
  if (action.tag === "load-suggestions") {
    return {
      ...state,
      tag: "filtering-loading",
    };
  }
  if (action.tag === "select-filter") {
    return selectFilter(state, action);
  }
  return state;
}

function reduceFilteringLoading(
  state: FilteringLoading,
  action: Action
): State {
  if (action.tag === "blur") {
    return {
      ...state,
      tag: "unfocused",
      searchText: "",
      helperText: "",
    };
  }
  if (action.tag === "suggestions-loaded") {
    const suggestions = action.suggestions;
    const isEmpty = !suggestions?.length || suggestions.length === 0;

    if (state.searchText && isEmpty) {
      return {
        ...state,
        tag: "filtering-error",
        errorMessage: `No ${state.selectedFilter.label} found matching "${state.searchText}"`,
      };
    }
    if (!state.searchText && isEmpty) {
      return {
        ...state,
        tag: "filtering-text",
        helperText: defaultHelperText,
      };
    }
    return {
      ...state,
      tag: "filtering-suggestions",
      suggestions: action.suggestions!,
      selectedIndex: 0,
      hasMenuMouse: false,
      helperText: suggestionsVisibleText,
      hasInputFocus: true,
    };
  }
  if (action.tag === "update-text") {
    return {
      ...state,
      tag: "filtering-text",
      searchText: action.searchText,
      helperText: defaultHelperText,
    };
  }
  return state;
}

function reduceFilteringError(state: FilteringError, action: Action): State {
  if (action.tag === "blur") {
    return {
      ...state,
      tag: "unfocused",
      searchText: "",
      helperText: "",
    };
  }
  if (action.tag === "update-text") {
    return {
      ...state,
      tag: "filtering-text",
      searchText: action.searchText,
      helperText: defaultHelperText,
    };
  }
  return state;
}

function reduceFilteringSuggestions(
  state: FilteringSuggestions,
  action: Action
): State {
  if (action.tag === "blur" && !state.hasMenuMouse) {
    return {
      ...state,
      tag: "unfocused",
      searchText: "",
      helperText: "",
    };
  }
  if (action.tag === "blur" && state.hasMenuMouse) {
    return {
      ...state,
      hasInputFocus: false,
    };
  }
  if (action.tag === "got-menu-mouse") {
    return {
      ...state,
      hasMenuMouse: true,
    };
  }
  if (action.tag === "lost-menu-mouse") {
    return {
      ...state,
      hasMenuMouse: false,
    };
  }
  if (action.tag === "update-text") {
    return {
      ...state,
      tag: "filtering-text",
      searchText: action.searchText,
      helperText: defaultHelperText,
    };
  }
  if (action.tag === "select-filter") {
    return selectFilter(state, action);
  }
  if (action.tag === "move-suggestions-up") {
    if (state.selectedIndex > 0) {
      return {
        ...state,
        selectedIndex: state.selectedIndex - 1,
      };
    }
    return state;
  }
  if (action.tag === "move-suggestions-down") {
    if (state.selectedIndex < state.suggestions.length - 1) {
      return {
        ...state,
        selectedIndex: state.selectedIndex + 1,
      };
    }
    return state;
  }
  return state;
}

export function reducer(state: State, action: Action): State {
  if (state.tag === "unfocused") {
    return reduceUnfocused(state, action);
  }
  if (state.tag === "focused") {
    return reduceFocused(state, action);
  }
  if (state.tag === "filtering-text") {
    return reduceFilteringText(state, action);
  }
  if (state.tag === "filtering-error") {
    return reduceFilteringError(state, action);
  }
  if (state.tag === "filtering-suggestions") {
    return reduceFilteringSuggestions(state, action);
  }
  if (state.tag === "filtering-loading") {
    return reduceFilteringLoading(state, action);
  }
  return state;
}
