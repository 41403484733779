import React from "react";
import { useSnackBar } from "~/snackbar";
import Button from "~/button";
import { useTextPrompt } from "~/confirmation-prompt";
import { ValidationError } from "~/gql/types";
import Spinner from "~/spinner";

type CancelInvoiceType = "Invoice" | "Custom Rollup Invoice";

type CancelInvoiceProps = {
  invoiceNumber: string;
  cancel: (notes: string) => Promise<ValidationError[]>;
  loading: boolean;
  type: CancelInvoiceType;
  reload: React.DispatchWithoutAction;
};

export const CancelInvoice: React.FC<CancelInvoiceProps> = (props) => {
  const { invoiceNumber, cancel, loading, type, reload } = props;

  const textPrompt = useTextPrompt();
  const addAlert = useSnackBar();

  const cancelInvoice = async (notes: string) => {
    const errors = await cancel(notes);

    if (errors.length > 0) {
      const formErrors: string[] = [];
      errors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          formErrors.push(`${argumentName}: ${message}`);
        } else if (message) {
          formErrors.push(message);
        }
      });

      addAlert({
        key: `${invoiceNumber}`,
        message: formErrors.join(" "),
        isSuccess: false,
      });
    } else {
      addAlert({
        key: `${invoiceNumber}`,
        message: `${type} ${invoiceNumber} cancelled`,
        isSuccess: true,
      });

      reload();
    }
  };

  const openPrompt = async () => {
    const result = await textPrompt({
      title: `Cancel ${type} ${invoiceNumber}`,
      message: `Are you sure you want to cancel this ${type.toLowerCase()}?`,
      fieldName: "Notes",
      helperText: `Explain the reason for cancelling this ${type.toLowerCase()}`,
      confirmButtonText: "Yes, cancel",
      cancelButtonText: "Don't cancel",
    });

    if (result == "Cancel") {
      return;
    }

    await cancelInvoice(result.message);
  };

  return (
    <>
      <Button onClick={openPrompt}>Cancel Invoice</Button>
      <Spinner open={loading} />
    </>
  );
};

export default CancelInvoice;
