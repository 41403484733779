import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkEditCorpLocationMutationVariables = Types.Exact<{
  corpLocationId: Types.Scalars['String'];
  projectNumbers: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type BulkEditCorpLocationMutation = { __typename?: 'MutationModel', projects?: { __typename?: 'ProjectsMutationModel', editCorpLocation?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const BulkEditCorpLocationDocument = gql`
    mutation BulkEditCorpLocation($corpLocationId: String!, $projectNumbers: [Int!]!) {
  projects {
    editCorpLocation(
      corpLocationId: $corpLocationId
      projectNumbers: $projectNumbers
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type BulkEditCorpLocationMutationFn = Apollo.MutationFunction<BulkEditCorpLocationMutation, BulkEditCorpLocationMutationVariables>;

/**
 * __useBulkEditCorpLocationMutation__
 *
 * To run a mutation, you first call `useBulkEditCorpLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditCorpLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditCorpLocationMutation, { data, loading, error }] = useBulkEditCorpLocationMutation({
 *   variables: {
 *      corpLocationId: // value for 'corpLocationId'
 *      projectNumbers: // value for 'projectNumbers'
 *   },
 * });
 */
export function useBulkEditCorpLocationMutation(baseOptions?: Apollo.MutationHookOptions<BulkEditCorpLocationMutation, BulkEditCorpLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkEditCorpLocationMutation, BulkEditCorpLocationMutationVariables>(BulkEditCorpLocationDocument, options);
      }
export type BulkEditCorpLocationMutationHookResult = ReturnType<typeof useBulkEditCorpLocationMutation>;
export type BulkEditCorpLocationMutationResult = Apollo.MutationResult<BulkEditCorpLocationMutation>;
export type BulkEditCorpLocationMutationOptions = Apollo.BaseMutationOptions<BulkEditCorpLocationMutation, BulkEditCorpLocationMutationVariables>;