import React, { useState, useEffect } from "react";
import { useLoadFile } from "~/file-loading/useLoadFile";

type ImageLoaderProps = {
  className: string;
  imageUrl: string | null;
};

const ImageLoader: React.FC<ImageLoaderProps> = ({ className, imageUrl }) => {
  const [imageData, setImageData] = useState<{
    img: string | null;
    pdf: string | null;
  }>({ img: null, pdf: null });
  const loadImage = useLoadFile();

  const handleImage = (type: string | null, fileUrl: string) => {
    if (type === "application/pdf") {
      setImageData({ img: null, pdf: fileUrl });
    } else {
      setImageData({ img: fileUrl, pdf: null });
    }
  };

  useEffect(() => {
    if (!imageUrl) {
      setImageData({ img: null, pdf: null });
      return;
    }

    void (async () => {
      const result = await loadImage({ imageUrl });
      const { type, url } = result;
      handleImage(type, url);
    })();
  }, [imageUrl]);

  if (!imageData.img && !imageData.pdf) {
    return null;
  }

  return (
    <>
      {imageData.img && (
        <img src={imageData.img} className={className} alt={""} />
      )}
      {imageData.pdf && <embed src={imageData.pdf} className={className} />}
    </>
  );
};

export default ImageLoader;
