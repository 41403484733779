import React from "react";
import { Cell, Grid } from "@material/react-layout-grid";
import "./PageGrid.scss";
import PageTitleRow from "~/page-title-row";

export interface PageGridProps {
  title: string;
  actions?: React.ReactChild;
  className?: string;
}

const PageGrid: React.FC<PageGridProps> = ({
  children,
  title,
  actions,
  className,
}) => {
  const cn = ["page-grid", className].filter((x) => x).join(" ");
  return (
    <Grid className={cn}>
      <Cell columns={12}>
        <PageTitleRow {...{ title, actions }} />
      </Cell>
      {children}
    </Grid>
  );
};

export default PageGrid;
