import { Body2 } from "@material/react-typography";
import React from "react";
import cn from "classnames";
import "./TableCell.scss";

type TableCellProps = {
  text?: string;
  className?: string;
  title?: string;
  onClick?: () => void;
  span?: number;
  align?: "right";
};

export const TableCell: React.FC<TableCellProps> = ({
  text,
  className,
  title,
  onClick,
  children,
  span,
  align,
}) => {
  const style = {
    gridColumn: `span ${span ?? 1}`,
  };

  const allClasses = cn(
    "new-table-cell",
    {
      "right-align": align === "right",
    },
    className
  );

  return (
    <>
      {text ? (
        <Body2
          onClick={onClick}
          className={allClasses}
          title={title}
          style={style}
          tag={"div"}
        >
          {text}
          {children}
        </Body2>
      ) : (
        <div
          onClick={onClick}
          className={allClasses}
          title={title}
          style={style}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default TableCell;
