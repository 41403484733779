import * as Types from '../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type GetAllCorpLocationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllCorpLocationsQuery = { __typename?: 'QueryModel', corpLocations: { __typename?: 'CorpLocationQueryModel', all: Array<{ __typename?: 'CorpLocation', id: string, name: string, companyName: string, isActive: boolean }> } };


export const GetAllCorpLocationsDocument = gql`
    query GetAllCorpLocations {
  corpLocations {
    all {
      id
      name
      companyName
      isActive
    }
  }
}
    `;

/**
 * __useGetAllCorpLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllCorpLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCorpLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCorpLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCorpLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCorpLocationsQuery, GetAllCorpLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCorpLocationsQuery, GetAllCorpLocationsQueryVariables>(GetAllCorpLocationsDocument, options);
      }
export function useGetAllCorpLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCorpLocationsQuery, GetAllCorpLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCorpLocationsQuery, GetAllCorpLocationsQueryVariables>(GetAllCorpLocationsDocument, options);
        }
export type GetAllCorpLocationsQueryHookResult = ReturnType<typeof useGetAllCorpLocationsQuery>;
export type GetAllCorpLocationsLazyQueryHookResult = ReturnType<typeof useGetAllCorpLocationsLazyQuery>;
export type GetAllCorpLocationsQueryResult = Apollo.QueryResult<GetAllCorpLocationsQuery, GetAllCorpLocationsQueryVariables>;