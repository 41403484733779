import * as Types from '../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type UpdateServiceDescriptionMutationVariables = Types.Exact<{
  input: Types.UpdateServiceDescriptionInput;
}>;


export type UpdateServiceDescriptionMutation = { __typename?: 'MutationModel', serviceDescriptions: { __typename?: 'ServiceDescriptionMutationModel', update: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string }> } } };


export const UpdateServiceDescriptionDocument = gql`
    mutation UpdateServiceDescription($input: UpdateServiceDescriptionInput!) {
  serviceDescriptions {
    update(input: $input) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type UpdateServiceDescriptionMutationFn = Apollo.MutationFunction<UpdateServiceDescriptionMutation, UpdateServiceDescriptionMutationVariables>;

/**
 * __useUpdateServiceDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateServiceDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceDescriptionMutation, { data, loading, error }] = useUpdateServiceDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServiceDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceDescriptionMutation, UpdateServiceDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceDescriptionMutation, UpdateServiceDescriptionMutationVariables>(UpdateServiceDescriptionDocument, options);
      }
export type UpdateServiceDescriptionMutationHookResult = ReturnType<typeof useUpdateServiceDescriptionMutation>;
export type UpdateServiceDescriptionMutationResult = Apollo.MutationResult<UpdateServiceDescriptionMutation>;
export type UpdateServiceDescriptionMutationOptions = Apollo.BaseMutationOptions<UpdateServiceDescriptionMutation, UpdateServiceDescriptionMutationVariables>;