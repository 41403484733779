import React from "react";
import Spinner from "~/spinner";
import List, { ListItem, ListItemText } from "~/list";
import { Body1 } from "@material/react-typography";
import { currencyFormatter } from "~/currencyFormatter";
import Button from "~/button";
import { useNavigate, useParams } from "react-router-dom";
import * as invoiceRoutes from "../invoiceRoutes";
import TitledCard from "~/titled-card";
import { BottomButtons } from "~/bottom-buttons";
import { RebillBreadcrumbs } from "./RebillBreadcrumbs";
import { useInvoice } from "../InvoiceContext";
import { useDraftInvoicesQueryQuery } from "~/invoice-search/draftInvoicesQuery.generated";

type TargetProjectRouteData = { projectNumber: string };

export const SelectInvoice: React.FC = () => {
  const invoice = useInvoice();
  const navigate = useNavigate();
  const params = useParams<TargetProjectRouteData>();
  const targetProject = params.projectNumber!;

  const { data, loading } = useDraftInvoicesQueryQuery({
    variables: {
      projectNumber: targetProject,
    },
  });

  const draftInvoices = data?.invoices?.draftInvoices || [];

  return (
    <TitledCard
      title="Rebill Charges - Select Invoice"
      className="select-invoice-page"
    >
      <RebillBreadcrumbs />
      <Spinner open={!data} />
      <Body1>
        Available draft invoices for project {targetProject} are displayed
        below. Select one, or choose &quot;Create New&quot; to create a new
        invoice to rebill charges onto.
      </Body1>
      {draftInvoices.length > 0 && (
        <List twoLine>
          {draftInvoices.map((inv) => (
            <ListItem
              key={inv?.invoiceNumber}
              href={invoiceRoutes.selectCharges(
                invoice.invoiceNumber,
                targetProject,
                inv!.invoiceNumber
              )}
            >
              <ListItemText
                primaryText={inv?.invoiceNumber}
                secondaryText={currencyFormatter.format(inv!.total)}
              />
            </ListItem>
          ))}
        </List>
      )}
      {!loading && draftInvoices.length === 0 && (
        <Body1>(no draft invoices found for project {targetProject})</Body1>
      )}
      <BottomButtons>
        <Button
          onClick={() =>
            navigate(
              invoiceRoutes.selectCharges(invoice.invoiceNumber, targetProject)
            )
          }
        >
          Create New
        </Button>
        <Button
          onClick={() => navigate(invoiceRoutes.home(invoice.invoiceNumber))}
        >
          Go Back
        </Button>
      </BottomButtons>
    </TitledCard>
  );
};
