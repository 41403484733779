import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EffectiveTaskRateSheetsQueryVariables = Types.Exact<{
  projectNumbers: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
  asOf: Types.Scalars['LocalDate'];
}>;


export type EffectiveTaskRateSheetsQuery = { __typename?: 'QueryModel', projects?: { __typename?: 'ProjectsQueryModel', effectiveTaskRateSheets?: Array<{ __typename?: 'EffectiveTaskRateSheetAssignment', projectNumber: number, taskRateSheet?: { __typename?: 'TaskRateSheet', name: string, rates?: Array<{ __typename?: 'TaskRate', name: string, billableAmount?: number | null, unit: Types.TaskRateUnit, glAccount?: string | null } | null> | null } | null } | null> | null } | null };


export const EffectiveTaskRateSheetsDocument = gql`
    query EffectiveTaskRateSheets($projectNumbers: [Int!]!, $asOf: LocalDate!) {
  projects {
    effectiveTaskRateSheets(projectNumbers: $projectNumbers, asOf: $asOf) {
      projectNumber
      taskRateSheet {
        name
        rates {
          name
          billableAmount
          unit
          glAccount
        }
      }
    }
  }
}
    `;

/**
 * __useEffectiveTaskRateSheetsQuery__
 *
 * To run a query within a React component, call `useEffectiveTaskRateSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEffectiveTaskRateSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEffectiveTaskRateSheetsQuery({
 *   variables: {
 *      projectNumbers: // value for 'projectNumbers'
 *      asOf: // value for 'asOf'
 *   },
 * });
 */
export function useEffectiveTaskRateSheetsQuery(baseOptions: Apollo.QueryHookOptions<EffectiveTaskRateSheetsQuery, EffectiveTaskRateSheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EffectiveTaskRateSheetsQuery, EffectiveTaskRateSheetsQueryVariables>(EffectiveTaskRateSheetsDocument, options);
      }
export function useEffectiveTaskRateSheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EffectiveTaskRateSheetsQuery, EffectiveTaskRateSheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EffectiveTaskRateSheetsQuery, EffectiveTaskRateSheetsQueryVariables>(EffectiveTaskRateSheetsDocument, options);
        }
export type EffectiveTaskRateSheetsQueryHookResult = ReturnType<typeof useEffectiveTaskRateSheetsQuery>;
export type EffectiveTaskRateSheetsLazyQueryHookResult = ReturnType<typeof useEffectiveTaskRateSheetsLazyQuery>;
export type EffectiveTaskRateSheetsQueryResult = Apollo.QueryResult<EffectiveTaskRateSheetsQuery, EffectiveTaskRateSheetsQueryVariables>;