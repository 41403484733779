import React, { useMemo } from "react";
import { URLSearchParamsInit } from "react-router-dom";
import { ClientForm } from "~/forms/ClientForm";
import Updater from "~/forms/Updater";
import { ValueRenderer } from "~/forms/ValueContext";
import EnumDropdownFormField from "~/visuals/organisms/EnumDropdownFormField";

export type SortFilterProps<TFilter extends { sortBy: string }> = {
  filters: TFilter | null;
  setFilters: React.Dispatch<TFilter>;
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined
  ) => void;
  type: string;
  required?: boolean;
  defaultSort?: string;
};

export const SearchSortBy = <TFilter extends { sortBy: string }>({
  filters,
  setFilters,
  searchParams,
  setSearchParams,
  type,
  required,
  defaultSort,
}: SortFilterProps<TFilter>): React.ReactElement => {
  const initialValues = useMemo(
    () => ({
      sortBy: searchParams.get("sortBy") || (defaultSort ?? "CustomerName"),
    }),
    []
  );

  const updateSortBy = (val: string | null) => {
    setFilters({ ...(filters ?? ({} as TFilter)), sortBy: val! });

    const newParams = {};
    Array.from(searchParams.entries()).forEach(([k, v]) => (newParams[k] = v));

    if (val) {
      newParams["sortBy"] = val;
    } else {
      delete newParams["sortBy"];
    }

    setSearchParams(newParams);
  };

  return (
    <div>
      <ClientForm initialValues={initialValues} onSuccess={() => undefined}>
        <EnumDropdownFormField
          label={"Sort By"}
          formField={"sortBy"}
          helperText={""}
          enumType={type}
          required={required}
        />
        <ValueRenderer
          render={(values) => (
            <Updater
              value={values.sortBy}
              callback={(val) => {
                if (filters && val !== filters.sortBy) {
                  updateSortBy(val);
                }
              }}
            />
          )}
        />
      </ClientForm>
    </div>
  );
};
