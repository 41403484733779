import React from "react";
import PageTitleRow from "~/page-title-row";
import Button from "~/button";
import "./TitleDeleteOption.scss";

type TitleDeleteRowProps = {
  title: string;
  onDelete?: () => void;
};

const TitleDeleteOption: React.FC<TitleDeleteRowProps> = ({
  title,
  onDelete,
  children,
}) => {
  return (
    <div className="title-delete-option">
      <PageTitleRow title={title} />
      <div>
        {onDelete && (
          <Button
            type="button"
            icon="delete"
            className="title-delete-button"
            onClick={onDelete}
          >
            Delete
          </Button>
        )}
        {children}
      </div>
    </div>
  );
};

export default TitleDeleteOption;
