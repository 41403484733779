import React, { useMemo } from "react";
import {
  InlineFieldsForm,
  InlineFieldsFormSubmitElement,
} from "~/inline-fields-form";
import { useSnackBar } from "~/snackbar";
import { useNavigate } from "react-router-dom";
import { home } from "~/routes";
import Spinner from "~/spinner";
import Card from "@material/react-card";
import PageTitleRow from "~/page-title-row";
import { DescriptionList } from "~/description-list";
import {
  EditCheckbox,
  EditCustomer,
  EditEmployee,
  EditEnumType,
  EditOfficeCode,
  EditProjectBudget,
  EditProjectOrigination,
  EditProjectTextField,
  EditServiceDescription,
  EditStateCounty,
} from "~/ProjectForm/EditProjectComponents";
import "../SingleRequest/ProjectRequest.scss";
import "./ProjectRequestForm.scss";
import { FormStateRenderer } from "~/inline-fields-form/FormStateContext";
import { createInitialFieldState } from "./createInitialFieldState";
import { useRelativeNav } from "~/relative-nav";
import { updater } from "./formStateUpdater";
import {
  CreateProjectRequestMutationResult,
  useCreateProjectRequestMutation,
} from "./create.generated";
import {
  EditProjectRequestMutationResult,
  EditProjectRequestMutationVariables,
  useEditProjectRequestMutation,
} from "./edit.generated";

import { projectRequests } from "../routes";
import Button from "~/button";
import { useUser } from "~/Auth/useUser";
import { useGetUserEmployeeRecordQuery } from "./userQuery.generated";
import _ from "lodash";
import { RequestField } from "./RequestField";
import { GroupCustomerForm } from "./GroupCustomerForm";
import { GroupOfficeForm } from "./GroupOfficeForm";
import { ProjectFormRequestProps, ProjectRequestFormData } from "./types";
import { toCustomerValues } from "./utils";
import { FormState } from "~/inline-fields-form/types";
import { BadDebtWarning } from "~/bad-debt-warning";
import { Customer } from "~/gql/types";

const defaultValues = {
  name: null,
  clientContactName: null,
  officeCode: null,
  location: null,
  projectManager: null,
  serviceDescription: null,
  customer: null,
  governmentFunding: null,
  taxableServices: false,
  jobsFolderRequired: false,
  budget: null,
  section: null,
  township: null,
  block: null,
  range: null,
  survey: null,
  geographicLocation: null,
  afe: null,
  wo: null,
  po: null,
  requisitioner: null,
  costCenter: null,
  glCode: null,
  contractNumber: null,
  coGo: null,
  projectInstructions: null,
  fieldInstructions: null,
  fieldMeetInfo: null,
  fieldContact: null,
  officeInstructions: null,
  invoiceSubmitEmail: null,
  invoiceContact: null,
  additionalComments: null,
  requestNewCustomer: false,
  customerName: "",
  customerPhone: "",
  customerFax: "",
  customerAddress1: "",
  customerAddress2: "",
  customerZip: "",
  customerCity: "",
  customerState: "",
  customerCountry: "",
  customerNotes: "",
  customerAdminNotes: null,
  customerAttn: null,
  isInternational: false,
  notes: null,
  scope: null,
  certifiedPayroll: false,
  projectOrigination: null,
};

const Form: React.FC<
  ProjectFormRequestProps & { defaultFormValues: ProjectRequestFormData }
> = (props) => {
  const {
    useReload,
    type,
    projectRequest,
    initialValues,
    navHook,
    defaultFormValues,
    otherRequests,
    actions,
    title,
  } = props;
  const addAlert = useSnackBar();
  const navHookFunc = navHook ?? useNavigate;

  const navigate = navHookFunc();

  const hasNewCustomer = !!projectRequest?.requestedCustomer;

  const initialFormValues = useMemo(
    () =>
      initialValues ??
      (projectRequest
        ? ({
            ...projectRequest,
            clientContactName: projectRequest.clientContactName,
            governmentFunding: {
              name: projectRequest.governmentFunding,
              description: "",
            },
            requestNewCustomer: hasNewCustomer,
            officeCode: {
              officeCode: projectRequest.officeCode,
              allowsProjects: true,
            },
            customer: hasNewCustomer
              ? null
              : projectRequest.customer ?? projectRequest.pendingCustomer,
            customerName: hasNewCustomer
              ? projectRequest.requestedCustomer!.name
              : "",
            customerPhone: hasNewCustomer
              ? projectRequest.requestedCustomer!.phone
              : "",
            customerFax: hasNewCustomer
              ? projectRequest.requestedCustomer!.fax
              : "",
            customerAddress1: hasNewCustomer
              ? projectRequest.requestedCustomer!.address1
              : "",
            customerAddress2: hasNewCustomer
              ? projectRequest.requestedCustomer!.address2
              : "",
            customerZip: hasNewCustomer
              ? projectRequest.requestedCustomer!.zip
              : "",
            customerCity: hasNewCustomer
              ? projectRequest.requestedCustomer!.city
              : "",
            customerState: hasNewCustomer
              ? projectRequest.requestedCustomer!.state
              : "",
            customerCountry: hasNewCustomer
              ? projectRequest.requestedCustomer!.country
              : "",
            customerNotes: hasNewCustomer
              ? projectRequest.requestedCustomer!.notes
              : "",
            customerAdminNotes: hasNewCustomer
              ? projectRequest.requestedCustomer!.adminNotes
              : null,
            customerAttn: hasNewCustomer
              ? projectRequest.requestedCustomer!.attn
              : null,
            isInternational: !!projectRequest.requestedCustomer?.country,
            notes: null,
            projectOrigination: {
              origination: projectRequest.projectOrigination,
              name: projectRequest.projectOriginationName || null,
            },
          } as ProjectRequestFormData)
        : defaultFormValues),
    [initialValues, defaultFormValues, projectRequest]
  );

  const parseNum = (val: any) => (val ? parseFloat(val) : null);

  const reloadRequest = (useReload ?? (() => () => undefined))();

  const isGroup = type === "Group";

  const mutationHook =
    projectRequest && (type === "Edit" || isGroup)
      ? useEditProjectRequestMutation
      : useCreateProjectRequestMutation;

  const [doMutation, { loading }] = mutationHook();

  const message =
    projectRequest && (type === "Edit" || isGroup)
      ? "Project request updated"
      : "New project request created";

  const onSuccess = async () => {
    addAlert({
      isSuccess: true,
      message,
      key: `${Math.random()}`,
    });
    await reloadRequest!();

    if (type !== "Group") {
      const path = projectRequest
        ? `${projectRequests}/${projectRequest.requestId}`
        : home.path;

      navigate(path);
    }
  };

  const onCreateCloneSuccess = async (data: { requestId: string }) => {
    addAlert({
      isSuccess: true,
      message,
      key: `${Math.random()}`,
    });
    await reloadRequest!();

    const path = `${projectRequests}/${data?.requestId}/clone`;

    navigate(path);
  };

  const getResult = async (formState: FormState) => {
    const values = {} as ProjectRequestFormData;

    Object.values(formState.fields).forEach(({ name, value }) => {
      values[name] = value;
    });

    const variables = {
      name: values.name,
      clientContactName: values.clientContactName,
      projectManager: values.projectManager!.userPrincipalName,
      officeCode: values.officeCode!.officeCode,
      serviceDescription: values.serviceDescription!.displayName,
      state: values.location!.state,
      county: values.location!.county,
      section: values.section || null,
      township: values.township || null,
      budget: parseNum(values.budget),
      range: values.range || null,
      block: values.block || null,
      survey: values.survey || null,
      geographicLocation: values.geographicLocation || null,
      governmentFunding: values.governmentFunding!.name,
      afe: values.afe || null,
      po: values.po || null,
      wo: values.wo || null,
      contractNumber: values.contractNumber || null,
      costCenter: values.costCenter || null,
      glCode: values.glCode || null,
      requisitioner: values.requisitioner || null,
      taxableServices: values.taxableServices,
      jobsFolderRequired: values.jobsFolderRequired,
      coGo: values.coGo || null,
      projectInstructions: values.projectInstructions || null,
      fieldInstructions: values.fieldInstructions || null,
      fieldMeetInfo: values.fieldMeetInfo || null,
      fieldContact: values.fieldContact || null,
      officeInstructions: values.officeInstructions || null,
      invoiceContact: values.invoiceContact || null,
      invoiceSubmitEmail: values.invoiceSubmitEmail || null,
      additionalComments: values.additionalComments || null,
      scope: values.scope || null,
      customer: !isGroup ? toCustomerValues(values) : null,
      certifiedPayroll: values.certifiedPayroll,
      projectOrigination: values.projectOrigination!.origination,
      projectOriginationName: values.projectOrigination!.name || null,
    } as EditProjectRequestMutationVariables;

    if (projectRequest && (type === "Edit" || isGroup)) {
      variables["requestId"] = projectRequest.requestId;
      variables["notes"] = values.notes!;
    }

    const result = await doMutation({ variables });

    return projectRequest && (type === "Edit" || isGroup)
      ? (result as EditProjectRequestMutationResult).data?.projectRequests?.edit
      : (result as CreateProjectRequestMutationResult).data?.projectRequests
          ?.create;
  };

  type FormResultProps = {
    data: any;
    onSuccess: (data?: any) => any;
  };

  const handleFormResult = ({
    data,
    onSuccess,
  }: FormResultProps): string | void => {
    if (data && data.errors.length > 0) {
      const messages: string[] = [];
      data.errors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          messages.push(`${argumentName}: ${message}`);
        } else if (message) {
          messages.push(message);
        }
      });
      return messages.join(" ");
    } else {
      onSuccess(data);
    }
  };

  const handleResponse = (response: void | string) => {
    if (response) {
      addAlert({
        isSuccess: false,
        message: response,
        key: `${Math.random()}`,
      });
    }
  };

  const runMutation = async (formState: FormState) => {
    const data = await getResult(formState);
    const errorResponse = handleFormResult({ data, onSuccess });
    handleResponse(errorResponse);
  };

  const createAndClone = async (formState: FormState) => {
    const data = await getResult(formState);

    const errorResponse = handleFormResult({
      data,
      onSuccess: onCreateCloneSuccess,
    });
    handleResponse(errorResponse);
  };

  const SubmitElement: InlineFieldsFormSubmitElement = ({
    onFormSubmit,
    formState,
  }) => {
    const nav = useRelativeNav();
    const onCancel = () => (type === "Group" ? nav("../../../") : nav("../"));

    const customer = Object.values(formState.fields).find(
      (x) => x.name === "customer"
    )?.value as Customer | null | undefined;

    return (
      <div className="submit-element">
        <BadDebtWarning {...{ customer }} />
        <Button primary onClick={() => onFormSubmit(runMutation)}>
          Save
        </Button>
        {["Create", "Clone"].includes(type) && (
          <Button onClick={() => onFormSubmit(createAndClone)}>
            {"Save & Clone"}
          </Button>
        )}
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    );
  };

  const initialFieldState = useMemo(
    () => createInitialFieldState(initialFormValues, type),
    [initialFormValues, type, projectRequest]
  );

  const diffIconMenuProps = { otherRequests };

  return (
    <DescriptionList className="project-request-page">
      <PageTitleRow
        title={title ?? `${type} Project Request`}
        actions={actions}
      />
      <Card>
        <InlineFieldsForm
          initialFieldState={initialFieldState}
          handleSubmit={runMutation}
          updater={updater}
          SubmitElement={SubmitElement}
          key={initialFormValues.name}
        >
          <FormStateRenderer
            render={(formState) => (
              <div className="project-request-info">
                <dl>
                  <RequestField
                    {...{
                      label: "Project Name",
                      name: "name",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="name" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Client Contact Name",
                      name: "clientContactName",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="clientContactName" />
                  </RequestField>

                  {type === "Group" ? (
                    <GroupOfficeForm
                      {...{
                        memberId: projectRequest!.requestId,
                        office: initialFormValues.officeCode!,
                      }}
                    />
                  ) : (
                    <RequestField
                      {...{
                        label: "Office Code",
                        name: "officeCode",
                        formState,
                      }}
                    >
                      <EditOfficeCode name="officeCode" />
                    </RequestField>
                  )}

                  <RequestField
                    {...{
                      label: "Project Manager",
                      name: "projectManager",
                      formState,
                      format: (x) => `${x.firstName} ${x.lastName}`,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditEmployee
                      name="projectManager"
                      roleName="Project Manager"
                    />
                  </RequestField>

                  {type === "Group" ? (
                    <GroupCustomerForm
                      initialFormValues={initialFormValues}
                      memberId={projectRequest!.requestId}
                    />
                  ) : (
                    <>
                      <dt>Request New Customer</dt>
                      <dd>
                        <EditCheckbox name="requestNewCustomer" />
                      </dd>
                      {!formState.requestNewCustomer?.value && (
                        <>
                          <RequestField
                            {...{
                              label: "Customer",
                              name: "customer",
                              formState,
                            }}
                          >
                            <EditCustomer
                              name="customer"
                              includePending={true}
                            />
                          </RequestField>
                        </>
                      )}

                      {formState.requestNewCustomer?.value && (
                        <>
                          <RequestField
                            {...{
                              label: "Customer Name",
                              name: "customerName",
                              formState,
                            }}
                          >
                            <EditProjectTextField name="customerName" />
                          </RequestField>

                          <RequestField
                            {...{
                              label: "Customer Address",
                              name: "customerAddress1",
                              formState,
                            }}
                          >
                            <EditProjectTextField name="customerAddress1" />
                          </RequestField>

                          <RequestField
                            {...{
                              label: "Address 2",
                              name: "customerAddress2",
                              formState,
                            }}
                          >
                            <EditProjectTextField name="customerAddress2" />
                          </RequestField>

                          <dt>Is International</dt>
                          <dd>
                            <EditCheckbox name="isInternational" />
                          </dd>

                          <RequestField
                            {...{
                              label: "City",
                              name: "customerCity",
                              formState,
                            }}
                          >
                            <EditProjectTextField name="customerCity" />
                          </RequestField>

                          {formState.isInternational?.value && (
                            <>
                              <RequestField
                                {...{
                                  label: "Country",
                                  name: "customerCountry",
                                  formState,
                                }}
                              >
                                <EditProjectTextField name="customerCountry" />
                              </RequestField>
                            </>
                          )}

                          {!formState.isInternational?.value && (
                            <>
                              <RequestField
                                {...{
                                  label: "State",
                                  name: "customerState",
                                  formState,
                                }}
                              >
                                <EditProjectTextField name="customerState" />
                              </RequestField>

                              <RequestField
                                {...{
                                  label: "Zip",
                                  name: "customerZip",
                                  formState,
                                }}
                              >
                                <EditProjectTextField name="customerZip" />
                              </RequestField>
                            </>
                          )}

                          <RequestField
                            {...{
                              label: "Customer Phone",
                              name: "customerPhone",
                              formState,
                            }}
                          >
                            <EditProjectTextField name="customerPhone" />
                          </RequestField>

                          <RequestField
                            {...{
                              label: "Customer Fax",
                              name: "customerFax",
                              formState,
                            }}
                          >
                            <EditProjectTextField name="customerFax" />
                          </RequestField>

                          <RequestField
                            {...{
                              label: "Customer Notes",
                              name: "customerNotes",
                              formState,
                            }}
                          >
                            <EditProjectTextField
                              name="customerNotes"
                              textarea={true}
                            />
                          </RequestField>

                          <RequestField
                            {...{
                              label: "Customer Internal Notes",
                              name: "customerAdminNotes",
                              formState,
                            }}
                          >
                            <EditProjectTextField
                              name="customerAdminNotes"
                              textarea={true}
                            />
                          </RequestField>

                          <RequestField
                            {...{
                              label: "Customer Attn",
                              name: "customerAttn",
                              formState,
                            }}
                          >
                            <EditProjectTextField
                              name="customerAttn"
                              textarea={true}
                            />
                          </RequestField>
                        </>
                      )}
                    </>
                  )}

                  <RequestField
                    {...{
                      label: "Government Funding",
                      name: "governmentFunding",
                      formState,
                      format: (x) => x.name,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditEnumType
                      {...{
                        name: "governmentFunding",
                        enumType: "GovernmentFundingType",
                      }}
                    />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Budget",
                      name: "budget",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectBudget {...{ name: "budget" }} />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Service Description",
                      name: "serviceDescription",
                      formState,
                      format: (x) => x.displayName,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditServiceDescription
                      {...{ name: "serviceDescription" }}
                    />
                  </RequestField>

                  <EditProjectOrigination
                    {...{
                      name: "projectOrigination",
                      requestFormState: formState,
                      ...diffIconMenuProps,
                    }}
                  />

                  <dt>Taxable Services</dt>
                  <dd>
                    <EditCheckbox name="taxableServices" />
                  </dd>

                  <dt>Jobs Folder Required</dt>
                  <dd>
                    <EditCheckbox name="jobsFolderRequired" />
                  </dd>

                  <dt>Certified Payroll</dt>
                  <dd>
                    <EditCheckbox name="certifiedPayroll" />
                  </dd>

                  <RequestField
                    {...{
                      label: "Scope",
                      name: "scope",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField
                      {...{ name: "scope", textarea: true }}
                    />
                  </RequestField>
                </dl>
                <dl>
                  <RequestField
                    {...{
                      label: "State/County",
                      name: "location",
                      formState,
                      format: (x) => `${x.county} - ${x.state}`,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditStateCounty name="location" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Section",
                      name: "section",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="section" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Township",
                      name: "township",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="township" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Range",
                      name: "range",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="range" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Block",
                      name: "block",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="block" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Survey",
                      name: "survey",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="survey" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Lat/Long",
                      name: "geographicLocation",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="geographicLocation" />
                  </RequestField>
                </dl>
                <dl>
                  <RequestField
                    {...{
                      label: "Cogo",
                      name: "coGo",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="coGo" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "AFE",
                      name: "afe",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="afe" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "PO",
                      name: "po",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="po" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "WO",
                      name: "wo",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="wo" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Contract Number",
                      name: "contractNumber",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="contractNumber" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Cost Center",
                      name: "costCenter",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="costCenter" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "GL Code",
                      name: "glCode",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="glCode" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Requisitioner",
                      name: "requisitioner",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="requisitioner" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Field Contact",
                      name: "fieldContact",
                      formState,
                    }}
                  >
                    <EditProjectTextField name="fieldContact" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Invoice Contact",
                      name: "invoiceContact",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="invoiceContact" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Invoice Submit Email",
                      name: "invoiceSubmitEmail",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField name="invoiceSubmitEmail" />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Field Instructions",
                      name: "fieldInstructions",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField
                      name="fieldInstructions"
                      textarea={true}
                    />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Field Meet Info",
                      name: "fieldMeetInfo",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField
                      name="fieldMeetInfo"
                      textarea={true}
                    />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Project Instructions",
                      name: "projectInstructions",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField
                      name="projectInstructions"
                      textarea={true}
                    />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Office Instructions",
                      name: "officeInstructions",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField
                      name="officeInstructions"
                      textarea={true}
                    />
                  </RequestField>

                  <RequestField
                    {...{
                      label: "Additional Comments",
                      name: "additionalComments",
                      formState,
                      ...diffIconMenuProps,
                    }}
                  >
                    <EditProjectTextField
                      name="additionalComments"
                      textarea={true}
                    />
                  </RequestField>

                  {projectRequest && (
                    <>
                      <RequestField
                        {...{ label: "Admin Notes", name: "notes", formState }}
                      >
                        <div className="admin-notes">
                          <EditProjectTextField name="notes" textarea={true} />
                        </div>
                      </RequestField>
                    </>
                  )}
                </dl>
              </div>
            )}
          />
        </InlineFieldsForm>
      </Card>
      <Spinner open={loading} />
    </DescriptionList>
  );
};

const CreateForm: React.FC<ProjectFormRequestProps> = (props) => {
  const user = useUser();
  const { data, loading } = useGetUserEmployeeRecordQuery({
    variables: { userPrincipalName: user?.email },
  });

  const userOffice = data?.employees?.single?.fieldOffice ?? null;

  const defaultFormValues: ProjectRequestFormData = useMemo(
    () => ({
      ...defaultValues,
      officeCode: userOffice?.allowsProjects ? userOffice : null,
    }),
    [data]
  );

  return (
    <>
      <Spinner open={loading} />
      {!loading && <Form {...{ ...props, defaultFormValues }} />}
    </>
  );
};

export const ProjectRequestForm: React.FC<ProjectFormRequestProps> = (
  props
) => {
  if (props.type === "Create") {
    return <CreateForm {...props} />;
  }

  return <Form {...{ ...props, defaultFormValues: defaultValues }} />;
};

export const GroupForm: React.FC<ProjectFormRequestProps> = (props) => {
  return (
    <Form {...{ ...props, defaultFormValues: defaultValues, type: "Group" }} />
  );
};
