import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { viewExpenseRateSheet } from "~/routes";
import ConfirmationDialog from "~/confirmation-dialog/ConfirmationDialog";
import ExpenseRateFormRouter, {
  ExpenseRateFormPage,
} from "~/visuals/organisms/ExpenseRateForm/ExpenseRateFormRouter";
import { ExpenseRateSheetRouteData } from "~/visuals/pages/ExpenseRates/ExpenseRateSheetRouter";
import {
  AdHocExpenseRate,
  AtvExpenseRate,
  ExpenseRateSheet,
  FuelExpenseRate,
  IExpenseRate,
  MileageExpenseRate,
  MobileAllowanceExpenseRate,
  PerDiemExpenseRate,
  ReceiptValuedExpenseRate,
  TcpAllowanceExpenseRate,
} from "~/gql/types";
import { useDeleteExpenseRateMutation } from "./queries.generated";
import { EditAtvExpenseRate } from "./EditAtvExpenseRate";
import { EditAdHocExpenseRate } from "./EditAdHocExpenseRate";
import { EditFuelExpenseRate } from "./EditFuelExpenseRate";
import { EditMileageExpenseRate } from "./EditMileageExpenseRate";
import { EditMobileAllowanceExpenseRate } from "./EditMobileAllowanceExpenseRate";
import { EditPerDiemExpenseRate } from "./EditPerDiemExpenseRate";
import { EditReceiptValuedExpenseRate } from "./EditReceiptValuedExpenseRate";
import { ErrorRenderer } from "~/forms/MutationForm";
import { EditTcpAllowanceExpenseRate } from "./EditTcpAllowanceExpenseRate";

type EditExpenseRateProps = {
  rateSheet: ExpenseRateSheet;
  useReload(): () => Promise<void>;
};

type FormType = "Create" | "Edit";

type FormProps = {
  setLoading: React.Dispatch<boolean>;
  rateSheet: ExpenseRateSheet;
  onSuccess: () => void;
};

export const parseNum = (val: string | null | undefined): number | null =>
  val ? parseFloat(val) : null;

type FormRouterProps = FormProps & {
  original: IExpenseRate;
  page: ExpenseRateFormPage;
};

const FormRouter: React.FC<FormRouterProps> = (props) => {
  const { original, page } = props;

  if (page === "AdHoc") {
    return (
      <EditAdHocExpenseRate
        {...props}
        original={original as AdHocExpenseRate}
      />
    );
  }
  if (page === "Atv") {
    return (
      <EditAtvExpenseRate {...props} original={original as AtvExpenseRate} />
    );
  }
  if (page === "Fuel") {
    return (
      <EditFuelExpenseRate {...props} original={original as FuelExpenseRate} />
    );
  }
  if (page === "Mileage") {
    return (
      <EditMileageExpenseRate
        {...props}
        original={original as MileageExpenseRate}
      />
    );
  }
  if (page === "MobileAllowance") {
    return (
      <EditMobileAllowanceExpenseRate
        {...props}
        original={original as MobileAllowanceExpenseRate}
      />
    );
  }
  if (page === "PerDiem") {
    return (
      <EditPerDiemExpenseRate
        {...props}
        original={original as PerDiemExpenseRate}
      />
    );
  }
  if (page === "ReceiptValue") {
    return (
      <EditReceiptValuedExpenseRate
        {...props}
        original={original as ReceiptValuedExpenseRate}
      />
    );
  }
  if (page === "TcpAllowance") {
    return (
      <EditTcpAllowanceExpenseRate
        {...props}
        original={original as TcpAllowanceExpenseRate}
      />
    );
  }
  return null;
};

const EditExpenseRate: React.FC<EditExpenseRateProps> = ({
  rateSheet,
  useReload,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState("");
  const params = useParams<ExpenseRateSheetRouteData>();
  const rateName = params.rateName ?? "";
  const original = rateSheet?.expenseRates?.find(
    (x) => x!.name === rateName
  ) as IExpenseRate & { __typename: string };
  const [rawDeleteMutation] = useDeleteExpenseRateMutation();

  const router = {
    ReceiptValuedExpenseRate: "ReceiptValue",
    PerDiemExpenseRate: "PerDiem",
    FuelExpenseRate: "Fuel",
    MobileAllowanceExpenseRate: "MobileAllowance",
    TcpAllowanceExpenseRate: "TcpAllowance",
    MileageExpenseRate: "Mileage",
    AtvExpenseRate: "Atv",
    AdHocExpenseRate: "AdHoc",
  };

  const [page, setPage] = useState<ExpenseRateFormPage>(
    original ? (router[original.__typename] as ExpenseRateFormPage) : "Atv"
  );

  const reloadRateSheet = useReload();

  const deleteRate = async () => {
    setLoading(true);
    const { data } = await rawDeleteMutation({
      variables: {
        rateSheetName: rateSheet.name,
        name: original?.name!,
      },
    });

    await reloadRateSheet();

    const dataErrors = data?.expenseRates?.deleteRate?.errors || [];

    if (data && dataErrors.length > 0) {
      let errors = "";

      dataErrors
        .map((x) => x!)
        .forEach(({ argumentName, message }) => {
          if (argumentName) {
            errors += ` ${argumentName}: ${message}`;
          } else if (message) {
            errors += ` ${message}`;
          }
        });
      setLoading(false);
      setDeleteError(errors);
    } else if (data) {
      setDialogOpen(false);
      setRedirect(true);
    }
  };

  const onEditSuccess = async () => {
    await reloadRateSheet();
    setRedirect(true);
  };

  const handleDelete = async () => {
    await deleteRate();
  };

  if (redirect || !original) {
    return <Navigate to={viewExpenseRateSheet.toRoute(rateSheet.name).path} />;
  }

  const baseFormProps = {
    setLoading,
    rateSheet,
    onSuccess: onEditSuccess,
    original,
    page,
  };

  const innerRouterProps = {
    type: "Edit" as FormType,
    rateSheetName: rateSheet.name,
    loading,
    onDelete: () => setDialogOpen(true),
  };

  return (
    <>
      {dialogOpen && (
        <ConfirmationDialog
          action="Delete"
          objectName="expense rate"
          onConfirmation={handleDelete}
          onCancel={() => setDialogOpen(false)}
        />
      )}
      <FormRouter {...baseFormProps}>
        <ErrorRenderer
          render={(submitError) => (
            <ExpenseRateFormRouter
              {...{
                formProps: {
                  ...innerRouterProps,
                  errorMessage: submitError || deleteError,
                },
                page,
                setPage,
              }}
            />
          )}
        />
      </FormRouter>
    </>
  );
};

export default EditExpenseRate;
