import React, { createContext, useContext, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogButton, DialogTitle } from "~/dialog";
import { DialogFooter } from "~/dialog-footer";
import TextFormField from "~/text-form-field";
import FormBottomRow from "~/form-bottom-row";
import CurrencyFormField from "~/visuals/organisms/CurrencyFormField";
import { ClientForm } from "~/forms/ClientForm";
import { ErrorRenderer } from "~/forms/MutationForm";

type PromptState = {
  resolver: React.Dispatch<ConfirmationPromptResult>;
} & Required<PromptArgs>;

export type PromptArgs =
  | {
      confirmButtonText?: string;
      cancelButtonText?: string;
      title?: string;
      message?: string | React.ReactElement;
    }
  | undefined;

export type TextPromptArgs = {
  fieldName: string;
  helperText: string;
  title?: string;
  message?: string | React.ReactElement;
  confirmButtonText?: string;
  cancelButtonText?: string;
  currency?: boolean;
};

type TextPromptState = {
  resolver: React.Dispatch<TextPromptResult>;
} & Required<TextPromptArgs>;

export type PromptFn = (args?: PromptArgs) => Promise<ConfirmationPromptResult>;
export type TextPromptFn = (args: TextPromptArgs) => Promise<TextPromptResult>;

type PromptContextType = {
  setPromptState: React.Dispatch<PromptState | null>;
  setTextPromptState: React.Dispatch<TextPromptState | null>;
};

const PromptContext = createContext<PromptContextType>(null!);

export const ConfirmationPromptProvider: React.FC = ({ children }) => {
  const [promptState, setPromptState] = useState<PromptState | null>(null);
  const [textPromptState, setTextPromptState] =
    useState<TextPromptState | null>(null);

  const contextData = {
    setPromptState,
    setTextPromptState,
  };

  const onClosing = (dialogAction) => {
    if (promptState === null) {
      return;
    }
    promptState.resolver(dialogAction.detail.action);
    setPromptState(null);
  };

  const closeTextPromptDialog = (result: TextPromptResult) => {
    if (textPromptState) {
      textPromptState.resolver(result);
    }
    setTextPromptState(null);
  };

  const confirmTextPromptDialog = (formData) => {
    closeTextPromptDialog({ message: formData.message });
  };

  return (
    <>
      <PromptContext.Provider value={contextData}>
        {children}
      </PromptContext.Provider>
      <Dialog
        open={promptState !== null}
        onClose={onClosing}
        data-open={promptState !== null}
        className="confirmation-prompt-dialog"
      >
        <DialogTitle>{promptState?.title}</DialogTitle>
        <DialogContent>{promptState?.message}</DialogContent>
        <DialogFooter>
          <DialogButton action="Confirm" isDefaultAction>
            {promptState?.confirmButtonText}
          </DialogButton>
          <DialogButton action="Cancel">
            {promptState?.cancelButtonText}
          </DialogButton>
        </DialogFooter>
      </Dialog>

      <Dialog
        open={textPromptState !== null}
        onClose={onClosing}
        data-open={textPromptState !== null}
        className="text-prompt-dialog"
      >
        <DialogTitle>{textPromptState?.title}</DialogTitle>
        <DialogContent>
          {textPromptState?.message}
          {textPromptState !== null && (
            <ClientForm
              onSuccess={confirmTextPromptDialog}
              initialValues={{ message: "" }}
              allowPristineSubmit={true}
            >
              {!textPromptState?.currency && (
                <TextFormField
                  formField="message"
                  label={textPromptState?.fieldName || ""}
                  helperText={textPromptState?.helperText || ""}
                  required
                />
              )}
              {textPromptState?.currency && (
                <CurrencyFormField
                  formField="message"
                  label={textPromptState?.fieldName || ""}
                  helperText={textPromptState?.helperText || ""}
                />
              )}

              <ErrorRenderer
                render={(submitError) => (
                  <FormBottomRow
                    errorMessage={submitError}
                    buttonText={
                      textPromptState !== null
                        ? textPromptState.confirmButtonText
                        : "xxxhiddenxxx"
                    }
                    cancelButtonText={
                      textPromptState !== null
                        ? textPromptState.cancelButtonText
                        : "xxxhiddenxxx"
                    }
                    onCancel={() => closeTextPromptDialog("Cancel")}
                    onSubmit={() => undefined}
                  />
                )}
              />
            </ClientForm>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export type ConfirmationPromptResult = "Confirm" | "Cancel";

export type TextPromptResult = "Cancel" | { message: string };

function usePromptContext(fn) {
  const context = useContext(PromptContext);

  if (!context) {
    throw new Error(
      `Cannot call ${fn} outside of a ConfirmationPromptProvider`
    );
  }

  return context;
}

export function useConfirmationPrompt(hookArgs?: PromptArgs): PromptFn {
  const { setPromptState } = usePromptContext("useConfirmationPrompt");
  const defaults = {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    title: "Are you sure?",
    message: "",
  };

  return (args?: PromptArgs) => {
    return new Promise((resolve) =>
      setPromptState({
        resolver: resolve,
        ...defaults,
        ...hookArgs,
        ...args,
      })
    );
  };
}

export function useTextPrompt(hookArgs?: TextPromptArgs): TextPromptFn {
  const { setTextPromptState } = usePromptContext("useTextPrompt");
  const defaults = {
    title: "Please Enter A Message",
    message: "",
    confirmButtonText: "Save",
    cancelButtonText: "Cancel",
    currency: false,
  };

  return (args: TextPromptArgs) => {
    return new Promise((resolve) =>
      setTextPromptState({
        resolver: resolve,
        ...defaults,
        ...hookArgs,
        ...args,
      })
    );
  };
}

export type MockPromptProviderProps = {
  result?: ConfirmationPromptResult;
  textPromptResult?: TextPromptResult;
};
export const MockConfirmationPromptProvider: React.FC<
  MockPromptProviderProps
> = ({ result = "Confirm", textPromptResult = "Cancel", children }) => {
  const [promptState, setPromptState] = useState<PromptState | null>(null);
  const [textPromptState, setTextPromptState] =
    useState<TextPromptState | null>(null);
  useEffect(() => {
    if (promptState === null) {
      return;
    }

    promptState.resolver(result);
    setPromptState(null);
  }, [promptState, result]);

  useEffect(() => {
    if (textPromptState === null) {
      return;
    }

    textPromptState.resolver(textPromptResult);
    setPromptState(null);
  }, [textPromptState, textPromptResult]);

  return (
    <PromptContext.Provider value={{ setTextPromptState, setPromptState }}>
      {children}
    </PromptContext.Provider>
  );
};
