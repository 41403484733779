import React, { useEffect } from "react";
import { useTimesheetContext } from "./TimesheetContext";
import { useParams } from "react-router-dom";
import { Employee } from "~/gql/types";
import { useSingleEmployeeQuery } from "./singleEmployee.generated";

export const EmployeeLoader: React.FC = () => {
  const { setEmployee } = useTimesheetContext();
  const { userPrincipalName } = useParams<{ userPrincipalName: string }>();

  const variables = { userPrincipalName: userPrincipalName! };

  const { data } = useSingleEmployeeQuery({ variables });

  useEffect(() => {
    if (data) {
      const employee = data?.employees?.single ?? null;
      setEmployee(employee as Employee | null);
    }
  }, [data]);
  return <></>;
};
