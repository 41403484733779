import React from "react";
import TextFormField from "~/text-form-field";
import FormCard from "~/form-card";
import PageTitleRow from "~/page-title-row";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import Spinner from "~/spinner";
import { taskRateSheets } from "~/routes";
import "./TaskRateSheetForm.scss";
import FormBottomRow from "~/form-bottom-row";

type TaskRateSheetFormProps = {
  errorMessage?: string;
  loading: boolean;
  oldSheetName?: string;
};

const TaskRateSheetForm: React.FC<TaskRateSheetFormProps> = ({
  errorMessage,
  loading,
  oldSheetName,
}) => {
  const title = oldSheetName
    ? `Clone Task Rate Sheet ${oldSheetName}`
    : "Create Task Rate Sheet";

  useBreadcrumbs(
    [{ text: "Task Rate Sheets", to: taskRateSheets.path }, { text: title }],
    []
  );

  return (
    <div className="task-rate-sheet-form">
      <PageTitleRow title={title} />
      <Spinner open={loading} />
      <FormCard>
        <TextFormField
          label="Name"
          helperText="Task rate sheet name"
          formField="name"
          required
        />
        <FormBottomRow
          errorMessage={errorMessage || null}
          buttonText={"Create"}
          route={taskRateSheets}
        />
      </FormCard>
    </div>
  );
};

export default TaskRateSheetForm;
