import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PayrollAdminInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PayrollAdminInfoQuery = { __typename?: 'QueryModel', employees?: { __typename?: 'EmployeesQueryModel', payrollAdminInfo?: { __typename?: 'PayrollAdminData', officeCodeData?: Array<{ __typename?: 'OfficeCodeEmployeeCount', officeCode: string, employeeCount: number } | null> | null } | null } | null };


export const PayrollAdminInfoDocument = gql`
    query PayrollAdminInfo {
  employees {
    payrollAdminInfo {
      officeCodeData {
        officeCode
        employeeCount
      }
    }
  }
}
    `;

/**
 * __usePayrollAdminInfoQuery__
 *
 * To run a query within a React component, call `usePayrollAdminInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollAdminInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollAdminInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayrollAdminInfoQuery(baseOptions?: Apollo.QueryHookOptions<PayrollAdminInfoQuery, PayrollAdminInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayrollAdminInfoQuery, PayrollAdminInfoQueryVariables>(PayrollAdminInfoDocument, options);
      }
export function usePayrollAdminInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayrollAdminInfoQuery, PayrollAdminInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayrollAdminInfoQuery, PayrollAdminInfoQueryVariables>(PayrollAdminInfoDocument, options);
        }
export type PayrollAdminInfoQueryHookResult = ReturnType<typeof usePayrollAdminInfoQuery>;
export type PayrollAdminInfoLazyQueryHookResult = ReturnType<typeof usePayrollAdminInfoLazyQuery>;
export type PayrollAdminInfoQueryResult = Apollo.QueryResult<PayrollAdminInfoQuery, PayrollAdminInfoQueryVariables>;