import React from "react";
import { CheckBox } from "~/checkbox";
import ClickSwallower from "~/click-swallower";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import styles from "./SelectAllCheckbox.module.scss";

type SelectAllCheckboxProps<T, TFilter> = {
  state: State<T, TFilter> | null;
  dispatch: React.Dispatch<Action<T, TFilter>>;
  children?: React.ReactNode;
  checkboxMargin?: string;
};

export const SelectAllCheckbox = <T, TFilter>({
  state,
  dispatch,
  children,
  checkboxMargin,
}: SelectAllCheckboxProps<T, TFilter>): React.ReactElement => {
  if (!state) {
    return <></>;
  }

  const allSelected =
    state.selectedItems.length >= state.items.length && state.items.length > 0;

  const onChange = () => {
    if (allSelected) {
      dispatch({ tag: "ClearSelected" });
    } else {
      dispatch({ tag: "SelectAll" });
    }
  };

  return (
    <div className={styles.SelectAllCheckbox}>
      <ClickSwallower>
        <CheckBox
          onChange={onChange}
          checked={allSelected}
          margin={checkboxMargin ?? "8px 0 0 16px"}
        />
        {children}
      </ClickSwallower>
    </div>
  );
};
