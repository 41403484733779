import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchProjectsToAssignSuccessorRateSheetQueryVariables = Types.Exact<{
  successorArgs: Types.RateSheetSuccessorArgsInput;
  token?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SearchProjectsToAssignSuccessorRateSheetQuery = { __typename?: 'QueryModel', projects?: { __typename?: 'ProjectsQueryModel', searchNumbers?: Array<number> | null, search?: { __typename?: 'ConnectionOfProject', token?: string | null, records?: Array<{ __typename?: 'Project', number: number, name: string, customer?: { __typename?: 'Customer', name: string, number?: string | null } | null } | null> | null } | null } | null };


export const SearchProjectsToAssignSuccessorRateSheetDocument = gql`
    query SearchProjectsToAssignSuccessorRateSheet($successorArgs: RateSheetSuccessorArgsInput!, $token: String) {
  projects {
    search(successorArgs: $successorArgs, token: $token) {
      token
      records {
        number
        name
        customer {
          name
          number
        }
      }
    }
    searchNumbers(successorArgs: $successorArgs)
  }
}
    `;

/**
 * __useSearchProjectsToAssignSuccessorRateSheetQuery__
 *
 * To run a query within a React component, call `useSearchProjectsToAssignSuccessorRateSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProjectsToAssignSuccessorRateSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProjectsToAssignSuccessorRateSheetQuery({
 *   variables: {
 *      successorArgs: // value for 'successorArgs'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSearchProjectsToAssignSuccessorRateSheetQuery(baseOptions: Apollo.QueryHookOptions<SearchProjectsToAssignSuccessorRateSheetQuery, SearchProjectsToAssignSuccessorRateSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProjectsToAssignSuccessorRateSheetQuery, SearchProjectsToAssignSuccessorRateSheetQueryVariables>(SearchProjectsToAssignSuccessorRateSheetDocument, options);
      }
export function useSearchProjectsToAssignSuccessorRateSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProjectsToAssignSuccessorRateSheetQuery, SearchProjectsToAssignSuccessorRateSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProjectsToAssignSuccessorRateSheetQuery, SearchProjectsToAssignSuccessorRateSheetQueryVariables>(SearchProjectsToAssignSuccessorRateSheetDocument, options);
        }
export type SearchProjectsToAssignSuccessorRateSheetQueryHookResult = ReturnType<typeof useSearchProjectsToAssignSuccessorRateSheetQuery>;
export type SearchProjectsToAssignSuccessorRateSheetLazyQueryHookResult = ReturnType<typeof useSearchProjectsToAssignSuccessorRateSheetLazyQuery>;
export type SearchProjectsToAssignSuccessorRateSheetQueryResult = Apollo.QueryResult<SearchProjectsToAssignSuccessorRateSheetQuery, SearchProjectsToAssignSuccessorRateSheetQueryVariables>;