import React from "react";
import { Action, State } from "./stateMachine";
import DailyHoursField from "./DailyHoursField";

type DailyFields = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

export const DailyFields: React.FC<DailyFields> = ({ state, dispatch }) => {
  const requiresDaily =
    state.taskRate?.requiresDaily &&
    state.dailyHours &&
    state.dailyHours.length > 0;

  if (!requiresDaily) {
    return null;
  }

  return <>{<DailyHoursField {...{ state, dispatch }} />}</>;
};
