import React, { useMemo } from "react";
import List from "@material/react-list";
import { Cell, Row } from "@material/react-layout-grid";
import { Headline6 } from "@material/react-typography";
import { WizardPage } from "./base";
import Button from "~/button";
import { State, Action } from "./stateMachine";
import _ from "lodash";
import "./LocationsPage.scss";
import { ExpandableState } from "./ExpandableState";

export const LocationsPage: React.FC<{
  state: State;
  dispatch: React.Dispatch<Action>;
}> = ({ state, dispatch }) => {
  const allCountiesByState = useMemo(
    () =>
      _.chain(state.allLocations)
        .groupBy((x) => x.state)
        .mapValues((counties) => new Set(counties.map((c) => c.county)))
        .value(),
    [state.allLocations]
  );
  const statesSorted = useMemo(
    () =>
      _.chain(allCountiesByState)
        .keys()
        .orderBy((x) => x)
        .value(),
    [allCountiesByState]
  );

  const selectionsByState = useMemo(
    () =>
      _.chain(state.selectedLocations)
        .groupBy((x) => x.state)
        .mapValues((counties) => new Set(counties.map((c) => c.county)))
        .value(),
    [state.selectedLocations]
  );

  const buttonAction: Action = { tag: "NextPage" };

  return (
    <WizardPage
      className="per-diem-rates__locations"
      buttonAction={buttonAction}
      state={state}
      dispatch={dispatch}
    >
      <Row className="two-column">
        <Cell columns={6} className="column">
          <Headline6>
            <Button
              style={{ float: "right" }}
              onClick={() =>
                statesSorted.forEach((state) =>
                  dispatch({ tag: "SelectState", state })
                )
              }
            >
              Select All
            </Button>
            Available
          </Headline6>
          <List>
            {statesSorted.map((state, idx) => (
              <ExpandableState
                state={state}
                key={state}
                leadingSeparator={idx === 0}
                allCounties={allCountiesByState[state] || new Set<string>([])}
                selectedCounties={
                  selectionsByState[state] || new Set<string>([])
                }
                showAllOption
                dispatch={dispatch}
              />
            ))}
          </List>
        </Cell>

        <Cell columns={6} className="column">
          <Headline6>
            <Button
              style={{ float: "right" }}
              onClick={() =>
                statesSorted.forEach((state) =>
                  dispatch({ tag: "DeselectState", state })
                )
              }
            >
              Remove All
            </Button>
            Selected
          </Headline6>
          <List>
            {statesSorted.map((state, idx) => (
              <ExpandableState
                state={state}
                key={state}
                leadingSeparator={idx === 0}
                allCounties={allCountiesByState[state] || new Set<string>([])}
                selectedCounties={
                  selectionsByState[state] || new Set<string>([])
                }
                removable
                showSelected
                showAllOption
                dispatch={dispatch}
              />
            ))}
          </List>
        </Cell>
      </Row>
    </WizardPage>
  );
};
