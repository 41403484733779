import {
  GetProjectGroupsDocument,
  GetProjectGroupsQuery,
} from "./query.generated";
import { useApolloClient } from "@apollo/client";
import { ProjectGroup } from "~/gql/types";

export const useProjectGroups = (): (() => Promise<ProjectGroup[]>) => {
  const client = useApolloClient();

  return async () => {
    const result = await client.query<GetProjectGroupsQuery>({
      query: GetProjectGroupsDocument,
      context: { clientName: "graphql" },
    });

    return result.data?.projectGroups?.all as ProjectGroup[];
  };
};

export function useProjectGroupFromParam(): (
  name: string,
  customerNumber: string
) => Promise<ProjectGroup | null> {
  const getProjectGroups = useProjectGroups();

  return async (name: string, customerNumber: string) => {
    const result = await getProjectGroups();
    const projectGroup = result?.find(
      (x) => x.name === name && x.customer!.number === customerNumber
    );

    return projectGroup ?? null;
  };
}
