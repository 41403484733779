import React from "react";
import { useEditFuelExpenseRateMutation } from "./queries.generated";
import { MutationForm } from "~/forms/MutationForm";
import { FormProps, FormData } from "./types";
import { FuelExpenseRate, ValidationResponse } from "~/gql/types";

export const EditFuelExpenseRate: React.FC<
  FormProps & { original: FuelExpenseRate }
> = (props) => {
  const { setLoading, original, rateSheet, onSuccess, children } = props;

  const [rawFuelMutation] = useEditFuelExpenseRateMutation();

  const initialValues = {
    name: original.name,
    invoiceLabel: original.invoiceLabel,
    expenseAccount: original.expenseAccount,
    glAccount: original.glAccount,
    billable: original.billable,
  };

  const handleSubmit = async (values: FormData) => {
    const variables = {
      oldName: original?.name!,
      name: values?.name!,
      invoiceLabel: values?.invoiceLabel || null,
      expenseAccount: values?.expenseAccount || null,
      glAccount: values?.glAccount || null,
      rateSheetName: rateSheet.name,
      billable: values?.billable!,
    };

    const result = await rawFuelMutation({ variables });

    return result?.data?.expenseRates?.editFuelRate as ValidationResponse;
  };

  return (
    <MutationForm
      setLoading={setLoading}
      initialValues={initialValues}
      runMutation={handleSubmit}
      onSuccess={onSuccess}
      allowPristineSubmit
    >
      {children}
    </MutationForm>
  );
};
