import React from "react";
import { useField } from "react-final-form";
import { useQuery } from "@apollo/client";
import "./EnumDropdownFormField.scss";
import _ from "lodash";
import { enumQuery } from "~/gql/enumQuery";
import { Dropdown, DropdownProps } from "~/dropdown";

type Enum = {
  name: string;
  description?: string;
};

export type OptionProps = {
  label: string;
  value: string;
};

export interface EnumDropdownProps {
  label: string;
  formField: string;
  helperText: string;
  required?: boolean;
  enumType: string;
  disabled?: boolean;
}

const EnumDropdownFormField: React.FC<EnumDropdownProps> = (props) => {
  const {
    label,
    formField,
    helperText,
    required = false,
    enumType,
    disabled = false,
  } = props;

  const { data, loading } = useQuery(enumQuery, { variables: { enumType } });

  const checkRequired = (value: string) =>
    value || !required ? undefined : `Please provide a valid ${label}`;

  const {
    meta: { invalid, error, submitError, touched },
    input: { value, onChange, onBlur },
  } = useField(formField, { validate: checkRequired });

  const hasError = (error || invalid || submitError) && touched;

  if (loading) {
    return <>(loading...)</>;
  }

  const items = _.orderBy(data?.__type?.enumValues, (x) => x.name) as Enum[];
  const selectedItem = items.find((x) => x.name === value) || null;

  const dropdownProps: DropdownProps<Enum> = {
    items: items,
    formatItem: (x) =>
      x?.description && x.description?.length > 0 ? x.description : x?.name,
    formatSelection: (x) =>
      x?.description && x.description?.length > 0
        ? x.description
        : x?.name || "",
    onSelected: (x) => onChange(x?.name || null),
    selectedItem,
    label,
    helperText: hasError ? error || submitError : helperText,
    disabled,
    required,
    isValid: !hasError,
    nullDisplay: "---",
    onBlur,
  };

  return <Dropdown {...dropdownProps} />;
};

export default EnumDropdownFormField;
