import { useApolloClient } from "@apollo/client";
import _ from "lodash";
import React from "react";
import { BulkPostToInvoice } from "~/bulk-post-to-invoice";
import { PostToInvoiceState } from "~/bulk-post-to-invoice/stateMachine";
import {
  AddInvoicesToRollupResult,
  BulkAddInvoicesResponse,
  CustomRollupInvoice,
  Invoice,
} from "~/gql/types";
import { Action } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import {
  LoadedDraftInvoice,
  PostToInvoicesArgs,
} from "~/bulk-post-to-invoice/types";
import { fetchUpdatedInvoices } from "./utils";
import { FilterOptions } from "./types";
import { BulkDraftRollupInvoicesDocument } from "./draftRollupsQuery.generated";
import { BulkAddToRollupInvoiceDocument } from "./bulkAddToRollup.generated";

type PostInvoicesToRollupsProps = {
  selected: Invoice[];
  dispatch: React.Dispatch<Action<Invoice, FilterOptions>>;
  setPostToRollupsOpen: React.Dispatch<boolean>;
};

export const createInitialState = (
  invoices: Invoice[]
): PostToInvoiceState<Invoice> => ({
  tag: "LoadingDraftInvoices",
  itemGroups: _.chain(invoices)
    .groupBy((x) => x.project!.projectGroup!.id)
    .mapValues((x) => ({
      groupId: x[0].project!.projectGroup!.id,
      items: x,
      draftInvoices: [],
    }))
    .values()
    .value(),
  loading: true,
});

export const PostInvoicesToRollups: React.FC<PostInvoicesToRollupsProps> = (
  props
) => {
  const { selected, dispatch, setPostToRollupsOpen } = props;
  const client = useApolloClient();

  const getDraftInvoices = async (
    groupIds: (string | number)[]
  ): Promise<LoadedDraftInvoice[]> => {
    const result = await client.query({
      query: BulkDraftRollupInvoicesDocument,
      variables: { groupIds: groupIds },
    });

    const invoices = (result?.data?.customRollupInvoices?.draftRollupInvoices ??
      []) as CustomRollupInvoice[];

    return invoices.map((x) => ({
      groupId: x.projectGroup!.id,
      invoice: x,
    }));
  };

  const postToInvoices = async (args: PostToInvoicesArgs<Invoice>[]) => {
    const addInvoicesArgs = args.map((x) => ({
      customRollupInvoiceNumber: x.invoiceNumber,
      invoiceNumbers: x.items.map((x) => x.invoiceNumber),
    }));

    const result = await client.mutate({
      mutation: BulkAddToRollupInvoiceDocument,
      variables: { addInvoicesArgs },
    });

    return result?.data?.customRollupInvoices?.bulkAddLineItems;
  };

  const initialState = createInitialState(selected);

  const onSuccess = async (response: BulkAddInvoicesResponse) => {
    const updatedInvoices = await fetchUpdatedInvoices(
      client,
      selected.map((x) => x.invoiceNumber)
    );

    dispatch({ tag: "UpdateItems", items: updatedInvoices });
    dispatch({ tag: "ClearSelected" });

    const results = (response?.results ?? []) as AddInvoicesToRollupResult[];

    return results
      .map(
        ({ invoiceNumber, projectGroup, invoiceCount }) =>
          `${invoiceCount} invoices from project group ${projectGroup} added to custom rollup invoice ${invoiceNumber}.\n`
      )
      .join(" ");
  };

  return (
    <BulkPostToInvoice
      {...{
        initialState,
        getDraftInvoices,
        postToInvoices,
        onSuccess,
        onClose: () => setPostToRollupsOpen(false),
      }}
    />
  );
};
