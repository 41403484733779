import React from "react";
import { Body1 } from "@material/react-typography";
import TitledCard from "~/titled-card";
import "./ThirdPartyInvoiceCard.scss";
import moment from "moment";
import { TextHighlighter } from "~/text-highlighter";
import { thirdPartyInvoice as invoiceRoute } from "~/routes";
import { currencyFormatter } from "~/currencyFormatter";
import { StateHelperButton } from "~/state-helper";
import { LinkClickHandler } from "~/link-click-handler";
import { Maybe, ThirdPartyInvoice } from "~/gql/types";
import { CardSelectCheckbox } from "~/search-page-wrapper/BulkCheckboxes/CardSelectCheckbox";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { FilterOptions } from "./FilterForm";

const InvoiceDetailCell: React.FC = ({ children }) => {
  return <div className="invoice-detail-cell">{children}</div>;
};

type InvoiceCardProps = {
  invoice: ThirdPartyInvoice;
  dispatch: React.Dispatch<Action<ThirdPartyInvoice, FilterOptions>>;
  state: State<ThirdPartyInvoice, FilterOptions>;
};

export const ThirdPartyInvoiceCard: React.FC<InvoiceCardProps> = ({
  invoice,
  state,
  dispatch,
}) => {
  const { date, invoiceNumber, project } = invoice;
  const Highlight = ({ text }) => <TextHighlighter {...{ text }} />;

  const customer = project!.customer!;

  const path = invoiceRoute.toRoute(invoice.invoiceNumber).path;

  const toDateText = (date?: Maybe<string> | undefined) =>
    date ? moment(date).format("MM/DD/YYYY") : "";

  const invoiceDateText = toDateText(date);

  return (
    <LinkClickHandler className={"third-party-invoice-search-card"} path={path}>
      <TitledCard
        title={
          <>
            <CardSelectCheckbox item={invoice} {...{ state, dispatch }} />
            <Highlight text={invoiceNumber} /> - {invoice.state}
            <StateHelperButton type="invoice" />
          </>
        }
        subtitle={invoiceDateText}
      >
        <div className="card-content">
          <InvoiceDetailCell>
            <Body1 title="Project">
              <Highlight text={`Project: ${project!.name}`} />
              <span className="item-number">
                (<Highlight text={`${project!.number}`} />)
              </span>
            </Body1>
          </InvoiceDetailCell>
          <InvoiceDetailCell>
            <Body1 title="Customer">
              <Highlight text={`Customer: ${customer.name}`} />
              <span className="item-number">
                (<Highlight text={`${customer.number}`} />)
              </span>
            </Body1>
          </InvoiceDetailCell>
          <InvoiceDetailCell>
            <Body1 title="Amount">
              Amount: {currencyFormatter.format(invoice.amount)}
            </Body1>
          </InvoiceDetailCell>
        </div>
      </TitledCard>
    </LinkClickHandler>
  );
};
