import React from "react";
import { PopupMenuList, PopupMenuListItem } from "~/popup-menu-list";
import * as States from "../states";
import * as Actions from "../actions";

type MenuProps<T> = {
  state: States.State<T>;
  dispatch: React.Dispatch<Actions.Action<T>>;
  onChange: (val: any) => void;
  children: React.ReactElement;
  format: (item: T, searchText: string) => React.ReactNode;
};

const SuggestionsMenu = <T,>({
  state,
  dispatch,
  children,
  format,
  onChange,
}: MenuProps<T>): React.ReactElement => {
  const actualState = state as States.SuggestionsVisible<T>;

  const transformItem = (item: T): PopupMenuListItem => ({
    label: format(item, state.text),
    onClick: () => {
      dispatch({ tag: "Select", selection: item, userSelected: true });
      onChange(item);
    },
    key: format(item, state.text),
    dataItem: item,
  });

  const suggestions = actualState.suggestions || [];

  const visible = States.isSuggestionsVisible(state) && suggestions.length > 0;

  const items = suggestions.map((x) => transformItem(x));

  return (
    <PopupMenuList
      {...{ visible, items }}
      onMenuMouseEnter={() => dispatch({ tag: "GotMenuMouse" })}
      onMenuMouseLeave={() => dispatch({ tag: "LostMenuMouse" })}
      focusOnOpen={false}
      selectedIndex={actualState.suggestionIndex}
      anchorCorner="bottomLeft"
    >
      {children}
    </PopupMenuList>
  );
};

export default SuggestionsMenu;
