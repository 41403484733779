import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { SimplePicker } from "../visuals/organisms/SimplePicker";
import { Suggestion } from "~/ultra-filter/types";

export const statusOptions = [
  { text: "Open", value: "Open" },
  { text: "Payroll (PR) Pending", value: "PrPending" },
  { text: "Rejected", value: "Rejected" },
  { text: "Payroll (PR) Approved", value: "PrApproved" },
] as Suggestion[];

export const WeekStatusPicker: React.FC<FormFieldProps> = (props) => (
  <SimplePicker {...{ ...props, options: statusOptions }} />
);
