import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopyProjectTimeToDateMutationVariables = Types.Exact<{
  projectTimeId: Types.Scalars['String'];
  newDate: Types.Scalars['LocalDate'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CopyProjectTimeToDateMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', copyProjectTimeToDate?: { __typename?: 'CreateProjectTimeResponse', projectTimeId?: string | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CopyExpenseToDateMutationVariables = Types.Exact<{
  expenseId: Types.Scalars['String'];
  newDate: Types.Scalars['LocalDate'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CopyExpenseToDateMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', copyExpenseToDate?: { __typename?: 'CreateExpenseResponse', expenseId?: string | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CopyTimeEntryToDateMutationVariables = Types.Exact<{
  timeEntryId: Types.Scalars['String'];
  newDate: Types.Scalars['LocalDate'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CopyTimeEntryToDateMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', copyTimeEntryToDate?: { __typename?: 'CreateTimeEntryResponse', timeEntryId?: string | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CopyTimeOffToDateMutationVariables = Types.Exact<{
  timeOffEntryId: Types.Scalars['String'];
  newDate: Types.Scalars['LocalDate'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CopyTimeOffToDateMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', copyTimeOffToDate?: { __typename?: 'CreateTimeOffEntryResponse', timeOffEntryId: string, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CopyProjectTimeToDateDocument = gql`
    mutation CopyProjectTimeToDate($projectTimeId: String!, $newDate: LocalDate!, $adminNotes: String) {
  timesheets {
    copyProjectTimeToDate(
      projectTimeId: $projectTimeId
      newDate: $newDate
      adminNotes: $adminNotes
    ) {
      projectTimeId
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CopyProjectTimeToDateMutationFn = Apollo.MutationFunction<CopyProjectTimeToDateMutation, CopyProjectTimeToDateMutationVariables>;

/**
 * __useCopyProjectTimeToDateMutation__
 *
 * To run a mutation, you first call `useCopyProjectTimeToDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyProjectTimeToDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyProjectTimeToDateMutation, { data, loading, error }] = useCopyProjectTimeToDateMutation({
 *   variables: {
 *      projectTimeId: // value for 'projectTimeId'
 *      newDate: // value for 'newDate'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCopyProjectTimeToDateMutation(baseOptions?: Apollo.MutationHookOptions<CopyProjectTimeToDateMutation, CopyProjectTimeToDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyProjectTimeToDateMutation, CopyProjectTimeToDateMutationVariables>(CopyProjectTimeToDateDocument, options);
      }
export type CopyProjectTimeToDateMutationHookResult = ReturnType<typeof useCopyProjectTimeToDateMutation>;
export type CopyProjectTimeToDateMutationResult = Apollo.MutationResult<CopyProjectTimeToDateMutation>;
export type CopyProjectTimeToDateMutationOptions = Apollo.BaseMutationOptions<CopyProjectTimeToDateMutation, CopyProjectTimeToDateMutationVariables>;
export const CopyExpenseToDateDocument = gql`
    mutation CopyExpenseToDate($expenseId: String!, $newDate: LocalDate!, $adminNotes: String) {
  timesheets {
    copyExpenseToDate(
      expenseId: $expenseId
      newDate: $newDate
      adminNotes: $adminNotes
    ) {
      expenseId
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CopyExpenseToDateMutationFn = Apollo.MutationFunction<CopyExpenseToDateMutation, CopyExpenseToDateMutationVariables>;

/**
 * __useCopyExpenseToDateMutation__
 *
 * To run a mutation, you first call `useCopyExpenseToDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyExpenseToDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyExpenseToDateMutation, { data, loading, error }] = useCopyExpenseToDateMutation({
 *   variables: {
 *      expenseId: // value for 'expenseId'
 *      newDate: // value for 'newDate'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCopyExpenseToDateMutation(baseOptions?: Apollo.MutationHookOptions<CopyExpenseToDateMutation, CopyExpenseToDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyExpenseToDateMutation, CopyExpenseToDateMutationVariables>(CopyExpenseToDateDocument, options);
      }
export type CopyExpenseToDateMutationHookResult = ReturnType<typeof useCopyExpenseToDateMutation>;
export type CopyExpenseToDateMutationResult = Apollo.MutationResult<CopyExpenseToDateMutation>;
export type CopyExpenseToDateMutationOptions = Apollo.BaseMutationOptions<CopyExpenseToDateMutation, CopyExpenseToDateMutationVariables>;
export const CopyTimeEntryToDateDocument = gql`
    mutation CopyTimeEntryToDate($timeEntryId: String!, $newDate: LocalDate!, $adminNotes: String) {
  timesheets {
    copyTimeEntryToDate(
      timeEntryId: $timeEntryId
      newDate: $newDate
      adminNotes: $adminNotes
    ) {
      timeEntryId
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CopyTimeEntryToDateMutationFn = Apollo.MutationFunction<CopyTimeEntryToDateMutation, CopyTimeEntryToDateMutationVariables>;

/**
 * __useCopyTimeEntryToDateMutation__
 *
 * To run a mutation, you first call `useCopyTimeEntryToDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTimeEntryToDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTimeEntryToDateMutation, { data, loading, error }] = useCopyTimeEntryToDateMutation({
 *   variables: {
 *      timeEntryId: // value for 'timeEntryId'
 *      newDate: // value for 'newDate'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCopyTimeEntryToDateMutation(baseOptions?: Apollo.MutationHookOptions<CopyTimeEntryToDateMutation, CopyTimeEntryToDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyTimeEntryToDateMutation, CopyTimeEntryToDateMutationVariables>(CopyTimeEntryToDateDocument, options);
      }
export type CopyTimeEntryToDateMutationHookResult = ReturnType<typeof useCopyTimeEntryToDateMutation>;
export type CopyTimeEntryToDateMutationResult = Apollo.MutationResult<CopyTimeEntryToDateMutation>;
export type CopyTimeEntryToDateMutationOptions = Apollo.BaseMutationOptions<CopyTimeEntryToDateMutation, CopyTimeEntryToDateMutationVariables>;
export const CopyTimeOffToDateDocument = gql`
    mutation CopyTimeOffToDate($timeOffEntryId: String!, $newDate: LocalDate!, $adminNotes: String) {
  timesheets {
    copyTimeOffToDate(
      timeOffEntryId: $timeOffEntryId
      newDate: $newDate
      adminNotes: $adminNotes
    ) {
      timeOffEntryId
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CopyTimeOffToDateMutationFn = Apollo.MutationFunction<CopyTimeOffToDateMutation, CopyTimeOffToDateMutationVariables>;

/**
 * __useCopyTimeOffToDateMutation__
 *
 * To run a mutation, you first call `useCopyTimeOffToDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTimeOffToDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTimeOffToDateMutation, { data, loading, error }] = useCopyTimeOffToDateMutation({
 *   variables: {
 *      timeOffEntryId: // value for 'timeOffEntryId'
 *      newDate: // value for 'newDate'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCopyTimeOffToDateMutation(baseOptions?: Apollo.MutationHookOptions<CopyTimeOffToDateMutation, CopyTimeOffToDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyTimeOffToDateMutation, CopyTimeOffToDateMutationVariables>(CopyTimeOffToDateDocument, options);
      }
export type CopyTimeOffToDateMutationHookResult = ReturnType<typeof useCopyTimeOffToDateMutation>;
export type CopyTimeOffToDateMutationResult = Apollo.MutationResult<CopyTimeOffToDateMutation>;
export type CopyTimeOffToDateMutationOptions = Apollo.BaseMutationOptions<CopyTimeOffToDateMutation, CopyTimeOffToDateMutationVariables>;