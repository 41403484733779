import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import React, { useState, useEffect } from "react";
import { FilterChip } from "~/filter-chip";
import { ActiveFilter, FilterProvider } from "../types";
import { Form } from "react-final-form";
import "./FilterDialog.scss";
import FormBottomRow from "~/form-bottom-row";
import ClickSwallower from "~/click-swallower";
import _ from "lodash";

export type FilterDialogArgs = {
  dialogTitle: string;
  providers: FilterProvider[];
  filters: ActiveFilter[];
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  onSubmit: (vals: ActiveFilter[]) => void;
};
export const FilterDialog: React.FC<FilterDialogArgs> = (props) => {
  const { dialogTitle, providers, filters, open, setOpen, onSubmit } = props;

  const [currentFilters, setCurrentFilters] = useState<ActiveFilter[]>(filters);

  useEffect(() => {
    setCurrentFilters(filters);
  }, [filters, open]);

  const initialValues = {};
  providers.forEach((p) => {
    initialValues[p.type] =
      currentFilters.find((f) => f.type === p.type)?.value || null;
  });

  const getFilterValues = (vals: Record<string, any>): ActiveFilter[] => {
    const newActiveFilters: ActiveFilter[] = [];
    for (const [key, value] of Object.entries(vals)) {
      if (value) {
        const newFilter = providers
          .find((p) => p.type === key)
          ?.toFilter(value);
        if (newFilter) {
          newActiveFilters.push(newFilter);
        }
      }
    }
    return newActiveFilters;
  };

  const removeFilter = (filter: ActiveFilter) =>
    setCurrentFilters(currentFilters.filter((f) => f.type !== filter.type));

  const handleSubmit = () => {
    onSubmit(
      currentFilters.filter((f) => {
        const provider = providers.find((p) => p.type === f.type);
        const hide = provider?.hide && provider?.hide(currentFilters);
        return !!f.value && !hide;
      })
    );
    setOpen(false);
  };

  return (
    <div className="filter-dialog">
      <ClickSwallower>
        <Dialog open={open} onClose={() => setOpen(false)} data-open={open}>
          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={({ handleSubmit, submitError, values }) => {
              // eslint-disable-next-line -- useEffect
              useEffect(() => {
                const filterValues = getFilterValues(values);
                if (!_.isEqual(currentFilters, filterValues)) {
                  setCurrentFilters(getFilterValues(values));
                }
              }, [values]);
              return (
                <form onSubmit={handleSubmit}>
                  <DialogTitle>{dialogTitle}</DialogTitle>
                  <DialogContent>
                    <div className="filter-dialog-active-filters">
                      {currentFilters
                        ?.filter((f) => !!f.value)
                        ?.map((f, idx) => (
                          <FilterChip
                            key={idx}
                            label={f.label}
                            onClick={() => removeFilter(f)}
                          />
                        ))}
                    </div>

                    <br />

                    <div className="filter-providers">
                      {providers
                        ?.filter((p) => !p.hide || !p.hide(currentFilters))
                        .map((p, idx) => (
                          <div key={idx} className="filter-dialog-provider">
                            <div className="filtering-component">
                              {p.render()}
                            </div>
                            <p>{p.documentationText}</p>
                          </div>
                        ))}
                    </div>
                  </DialogContent>
                  <FormBottomRow
                    errorMessage={submitError}
                    buttonText="Apply"
                    onCancel={() => setOpen(false)}
                  />
                </form>
              );
            }}
          />
        </Dialog>
      </ClickSwallower>
    </div>
  );
};
