import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTaskRateSheetMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type CreateTaskRateSheetMutation = { __typename?: 'MutationModel', taskRates?: { __typename?: 'TaskRatesMutationModel', createTaskRateSheet?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CreateTaskRateSheetDocument = gql`
    mutation CreateTaskRateSheet($name: String!) {
  taskRates {
    createTaskRateSheet(name: $name) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CreateTaskRateSheetMutationFn = Apollo.MutationFunction<CreateTaskRateSheetMutation, CreateTaskRateSheetMutationVariables>;

/**
 * __useCreateTaskRateSheetMutation__
 *
 * To run a mutation, you first call `useCreateTaskRateSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskRateSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskRateSheetMutation, { data, loading, error }] = useCreateTaskRateSheetMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateTaskRateSheetMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskRateSheetMutation, CreateTaskRateSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskRateSheetMutation, CreateTaskRateSheetMutationVariables>(CreateTaskRateSheetDocument, options);
      }
export type CreateTaskRateSheetMutationHookResult = ReturnType<typeof useCreateTaskRateSheetMutation>;
export type CreateTaskRateSheetMutationResult = Apollo.MutationResult<CreateTaskRateSheetMutation>;
export type CreateTaskRateSheetMutationOptions = Apollo.BaseMutationOptions<CreateTaskRateSheetMutation, CreateTaskRateSheetMutationVariables>;