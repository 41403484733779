import React, { useEffect, useState } from "react";
import { FilterForm, FilterOptions } from "./FilterForm";
import { ProjectCard } from "./ProjectCard";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { VisibilityListener } from "~/visibility-listener";
import useInfiniteScroll from "~/search-page-wrapper/infinite-scroll/useInfiniteScroll";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { TextHighlighterProvider } from "~/text-highlighter/TextHighlighterProvider";
import { SearchFilterContainer } from "~/search-filter-container";
import { BulkProjectsActions } from "./BulkActions/BulkProjectsActions";
import { ResetSearchItemsProvider } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import { Project } from "~/gql/types";
import {
  useProjectSearchQuery,
  useProjectSearchWithStatsQuery,
} from "./query.generated";
import { SelectAllCheckbox } from "~/search-page-wrapper/BulkCheckboxes/SelectAllCheckbox";
import { SearchTopActions } from "~/search-top-actions";
import { formatDate } from "~/utils/formatDate";
import {
  ProjectSearchStatsProvider,
  useProjectSearchStats,
} from "./ProjectSearchStatsProvider";
import { LoadAllProjects } from "./LoadAllProjects";
import { ProjectCsvExporter } from "./ProjectCsvExporter";
import { SearchSortBy } from "~/search-sort-by";
import { useSearchParams } from "react-router-dom";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";
import { useReviewItemsContext } from "~/review-items";

const Breadcrumbs: React.FC = () => {
  useBreadcrumbs([{ text: "Projects" }], []);

  return <></>;
};

type ProjectsPageProps = {
  FilterFormComponent?: typeof FilterForm;
};

export type ProjectsPageDisplayProps = {
  showSpinner: boolean;
  projects: Project[] | null;
  state: State<Project, FilterOptions>;
  dispatch: React.Dispatch<Action<Project, FilterOptions>>;
};

export const ProjectsPageDisplay: React.FC<ProjectsPageDisplayProps> = ({
  showSpinner,
  projects,
  state,
  dispatch,
}) => {
  return (
    <SearchListContent
      loading={showSpinner}
      loadingMore={state.tag === "LoadingMore"}
      actions={
        <SearchTopActions>
          <SelectAllCheckbox {...{ state, dispatch }}>
            <LoadAllProjects {...{ state, dispatch }} />
          </SelectAllCheckbox>
          <ProjectCsvExporter {...{ state }} />
        </SearchTopActions>
      }
    >
      <Breadcrumbs />

      {projects &&
        projects.map((e, idx) => (
          <ProjectCard key={idx} {...{ project: e as any, state, dispatch }} />
        ))}
      {state.showVisibility && (
        <VisibilityListener
          onShown={() => dispatch({ tag: "ScrolledToBottom" })}
          height={10}
        />
      )}
      <BulkProjectsActions {...{ state, dispatch }} />
    </SearchListContent>
  );
};

type LoadProjectsProps = {
  dispatch: React.Dispatch<Action<Project, FilterOptions>>;
  tag: "DataLoaded" | "MoreDataLoaded";
  state: State<Project, FilterOptions>;
};

const LoadProjects = ({ dispatch, tag, state }: LoadProjectsProps) => {
  const token = state.searchToken;
  const {
    searchText,
    billingAdmin,
    projectManager,
    projectSupervisor,
    officeCode,
    projectType,
    division,
    corpLocation,
    customer,
    dateInEqual,
    dateInBefore,
    dateInAfter,
    locationState,
    afeWoPo,
    status,
    stateCounty,
    clientContactName,
    sortBy,
    projectGroup,
    certifiedPayroll,
    industry,
    serviceDescription,
  } = state.filterOptions;

  const queryHook = token
    ? useProjectSearchQuery
    : useProjectSearchWithStatsQuery;

  const variables = {
    searchText: searchText || null,
    billingAdmin: billingAdmin?.userPrincipalName || null,
    projectManager: projectManager?.userPrincipalName || null,
    projectSupervisor: projectSupervisor?.userPrincipalName || null,
    officeCode: officeCode?.officeCode ?? null,
    projectType: projectType ?? null,
    division: division?.id ?? null,
    corpLocation: corpLocation?.id ?? null,
    customerNumber: customer?.number ?? null,
    dateInEqual: formatDate(dateInEqual),
    dateInBefore: formatDate(dateInBefore),
    dateInAfter: formatDate(dateInAfter),
    state: stateCounty ? stateCounty.state : locationState || null,
    afeWoPo: afeWoPo || null,
    token: token || null,
    status: status || null,
    county: stateCounty?.county || null,
    clientContactName: clientContactName || null,
    sortBy,
    projectGroupArgs: projectGroup
      ? {
          groupName: projectGroup.name,
          customerNumber: projectGroup.customer!.number!,
        }
      : null,
    certifiedPayroll: certifiedPayroll === "Yes",
    industry,
    serviceDescription,
  };

  const { setStats } = useProjectSearchStats();

  const { data } = queryHook({ variables });

  useEffect(() => {
    if (data) {
      const newToken = data.projects?.search?.token as string | null;
      const newProjects = data.projects?.search?.records as Project[];
      const stats = data?.projects?.["stats"];

      if (stats) {
        setStats(stats);
      }
      dispatch({ tag, items: newProjects, searchToken: newToken });
    }
  }, [data]);

  return <></>;
};

export const ProjectsPage: React.FC<ProjectsPageProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<FilterOptions | null>(null);
  const FilterFormComponent = props.FilterFormComponent || FilterForm;

  const setFilterOptions = (opts: FilterOptions) => setFilters(opts);

  return (
    <ProjectSearchStatsProvider>
      <SearchPageWrapper withSort>
        <SearchFilterContainer>
          <FilterFormComponent onFiltersChanged={setFilterOptions} />
          <SearchSortBy
            {...{
              filters,
              setFilters,
              searchParams,
              setSearchParams,
              type: "ProjectSortBy",
            }}
          />
        </SearchFilterContainer>
        {filters && <ProjectsResults {...{ ...props, filters }} />}
      </SearchPageWrapper>
    </ProjectSearchStatsProvider>
  );
};

export const ProjectsResults: React.FC<
  ProjectsPageProps & { filters: FilterOptions }
> = (props) => {
  const { filters } = props;

  const { setState } = useProjectSearchStats();
  const { setSelectedItems } = useReviewItemsContext();

  const initialState = {
    tag: "Loading",
    items: [],
    showVisibility: false,
    loading: true,
    filterOptions: filters,
    searchToken: null,
    selectedItems: [],
    selectIdKey: "number",
  } as State<Project, FilterOptions>;

  const { state, dispatch } = useInfiniteScroll<Project, FilterOptions>(
    initialState
  );

  useEffect(() => {
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: filters,
    });
  }, [filters]);

  useEffect(() => {
    setState(state);
  }, [state.items]);

  useEffect(() => {
    setSelectedItems(state.selectedItems);
  }, [state.selectedItems]);

  const reset = () => {
    dispatch({
      tag: "Reset",
      filterOptions: state.filterOptions,
    });
  };

  return (
    <ResetSearchItemsProvider reset={reset}>
      <TextHighlighterProvider searchText={state.filterOptions.searchText}>
        {state.tag === "Loading" && (
          <LoadProjects
            {...{
              dispatch,
              state: { ...state, searchToken: null },
              tag: "DataLoaded",
            }}
          />
        )}
        {state.tag === "LoadingMore" && (
          <LoadProjects
            {...{
              dispatch,
              tag: "MoreDataLoaded",
              state,
            }}
          />
        )}
        <ProjectsPageDisplay
          {...{
            projects: state.items,
            showSpinner: state.loading,
            state,
            dispatch,
          }}
        />
      </TextHighlighterProvider>
    </ResetSearchItemsProvider>
  );
};
