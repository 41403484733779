import React from "react";
import { Customer, Employee } from "~/gql/types";
import TitledCard from "~/titled-card";
import { Body2 } from "@material/react-typography";
import "./CustomerBillingAdmins.scss";
import { AddBillingAdmin } from "./AddBillingAdmin";
import { RemoveBillingAdmin } from "./RemoveBillingAdmin";

type CustomerBillingAdminsProps = {
  customer: Customer;
};

export const CustomerBillingAdmins: React.FC<CustomerBillingAdminsProps> = ({
  customer,
}) => {
  const billingAdmins = (customer.billingAdmins ?? []) as Employee[];
  return (
    <TitledCard
      title="Billing Admins"
      actions={<AddBillingAdmin {...{ customer }} />}
    >
      {billingAdmins.map((x, idx) => (
        <div key={idx} className="customer-billing-admin">
          <Body2>{`${x.firstName} ${x.lastName}`}</Body2>
          <RemoveBillingAdmin {...{ customer, billingAdmin: x }} />
        </div>
      ))}
    </TitledCard>
  );
};
