import React, { useState } from "react";
import { useWorkDayApi } from "~/WorkDayApi";
import { AdHocExpense, AdHocExpenseRate } from "~/gql/types";
import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";
import { ApiCallback } from "~/WorkDayApi/WorkDayApiType";
import AdHocTypeForm from "~/visuals/organisms/Expenses/ExpenseForm/AdHocTypeForm";

type NewAdHocProps = BaseExpenseFormProps & {
  expenseRate?: AdHocExpenseRate;
  expenseEntry?: AdHocExpense;
};

const NewAdHocExpense: React.FC<NewAdHocProps> = (props) => {
  const { project, initialValues, expenseEntry, date, expenseRate } = props;

  const api = useWorkDayApi();

  const [imageId, setImageId] = useState<string | null>(
    initialValues?.receiptImageId ?? null
  );

  const uniqueInitVals: UniqueInitVals = [
    {
      rate: expenseEntry?.rate,
      quantity: expenseEntry?.quantity,
      receiptImage: null,
    },
    {
      rate: null,
      quantity: null,
      receiptImage: null,
    },
  ];

  const parseNum = (val: any) => (val ? parseFloat(val) : null);

  const createExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc,
    callback?: ApiCallback
  ) => {
    const res = await api.createAdHocExpense(
      {
        ...defaultVals(values),
        date,
        rateAmount: parseNum(values?.rate),
        quantity: parseNum(values?.quantity)!,
        receiptImageId: imageId,
        state: project?.location?.state!,
        county: project?.location?.county!,
      },
      callback
    );
    return res;
  };

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        handleSubmit: createExpense,
        expenseType: "AdHoc",
        uniqueInitVals,
        formType: expenseEntry ? "Clone" : "Create",
      }}
    >
      <AdHocTypeForm
        {...props}
        setImageId={setImageId}
        expenseRate={expenseRate}
      />
    </ExpensePageWrapper>
  );
};

export default NewAdHocExpense;
