import React, { useContext } from "react";
import { CustomRollupInvoice } from "~/gql/types";

type RollupInvoiceContextData = {
  rollupInvoice: CustomRollupInvoice;
  reload: React.DispatchWithoutAction;
};

export const RollupInvoiceContext =
  React.createContext<RollupInvoiceContextData>(null!);

export const useRollupInvoice = (): CustomRollupInvoice => {
  const data = useRollupInvoiceContextData();

  return data.rollupInvoice;
};

export const useRefreshRollupInvoice = (): React.DispatchWithoutAction => {
  const data = useRollupInvoiceContextData();

  return data.reload;
};

export function useRollupInvoiceContextData(): RollupInvoiceContextData {
  const data = useContext(RollupInvoiceContext);

  if (!data) {
    throw new Error(
      "Cannot use useRollupInvoice outside an RollupInvoiceRouter"
    );
  }
  return data;
}

export type MockInvoiceProviderProps = {
  rollupInvoice: CustomRollupInvoice;
  reload: React.DispatchWithoutAction;
};

export const MockRollupInvoiceProvider: React.FC<MockInvoiceProviderProps> = ({
  rollupInvoice,
  reload,
  children,
}) => (
  <RollupInvoiceContext.Provider value={{ rollupInvoice, reload }}>
    {children}
  </RollupInvoiceContext.Provider>
);
