import React, { useState } from "react";
import { Employee } from "~/gql/types";
import { NotifierForm } from "~/forms/NotifierForm";
import { EmployeePicker } from "~/visuals/organisms/EmployeePickerFormField";
import { useUser } from "~/Auth/useUser";
import { useSingleEmployeeQuery } from "~/visuals/organisms/TimesheetDisplay/singleEmployee.generated";
import { InlineProgress } from "~/inline-progress";
import { Action } from "./stateMachine";

type EmployeeSelectProps = {
  setEmployee: React.Dispatch<Employee | null>;
  dispatch: React.Dispatch<Action>;
};

export const EmployeeSelect: React.FC<EmployeeSelectProps> = (props) => {
  const user = useUser();

  if (!user?.authorizations.viewPayrollAdminData) {
    return null;
  }
  return (
    <EmployeeSelectForm {...{ ...props, userPrincipalName: user.email }} />
  );
};

const EmployeeSelectForm: React.FC<
  EmployeeSelectProps & { userPrincipalName: string }
> = (props) => {
  const { userPrincipalName, setEmployee, dispatch } = props;

  const [initialLoaded, setInitialLoaded] = useState(false);

  const onChange = (values: { employee: Employee | null }) => {
    setEmployee(values.employee);

    if (initialLoaded && values.employee) {
      dispatch({ tag: "Reset" });
    }

    if (!initialLoaded && values.employee) {
      setInitialLoaded(true);
    }
  };

  const result = useSingleEmployeeQuery({ variables: { userPrincipalName } });
  const employee = result?.data?.employees?.single as Employee;

  if (!employee) {
    return <InlineProgress />;
  }

  return (
    <NotifierForm onChange={onChange} values={{ employee }}>
      <EmployeePicker
        formField="employee"
        label="Other Employee"
        helperText=""
      />
    </NotifierForm>
  );
};
