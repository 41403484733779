import React, { ReactNode, useRef, useState } from "react";
import MdcTextField, { Input } from "@material/react-text-field";
import { TextFieldProps } from "~/textfield-display";
import HelperTextLine from "~/HelperTextLine";
import "~/textfield-display/TextField.scss";
import { PopupMenuList } from "~/popup-menu-list";
import cn from "classnames";
import "./Dropdown.scss";
import MaterialIcon from "@material/react-material-icon";

const noop = () => undefined;

type CopiedProps = Omit<
  TextFieldProps,
  "value" | "onChange" | "textarea" | "type" | "testid" | "id"
>;

export type DropdownProps<TItem> = CopiedProps & {
  className?: string;
  formatSelection: (item: TItem) => string;
  formatItem: (item: TItem) => ReactNode;
  items: TItem[];
  selectedItem: TItem | null;
  onSelected: React.Dispatch<TItem | null>;
  nullDisplay?: string;
};

export function Dropdown<TItem>(
  props: DropdownProps<TItem>
): ReturnType<React.FC> {
  const [hasFocus, setHasFocus] = useState(false);

  const { selectNext, selectPrev, selectedIndex } = computeSelections({
    ...props,
    hasFocus,
  });

  const onKeyDown = (evt: React.KeyboardEvent) => {
    if (evt.key === "ArrowDown") {
      selectNext();
      return;
    }
    if (evt.key === "ArrowUp") {
      selectPrev();
      return;
    }
    if (evt.key === "Enter") {
      setHasFocus(false);
      return;
    }

    if (evt.key.length === 1) {
      const startIndex = selectedIndex > 0 ? selectedIndex : 0;
      const candidates = [
        ...props.items.slice(startIndex),
        ...props.items.slice(0, startIndex),
      ];
      const matchingItem = candidates.find((x) =>
        props.formatSelection(x).toLowerCase().startsWith(evt.key.toLowerCase())
      );

      if (matchingItem) {
        props.onSelected(matchingItem);
      }
    }
  };

  const displayProps: _DropdownDisplayProps<TItem> = {
    ...props,
    onFocus: () => setHasFocus(true),
    onBlur: () => setHasFocus(false),
    onChange: noop,
    onKeyDown,
    hasFocus,
    setHasFocus,
    isValid: props.isValid ?? true,
    nullDisplay: props.nullDisplay ?? "",
    selectedIndex,
  };

  return <_DropdownDisplay {...displayProps} />;
}

export type _DropdownDisplayProps<TItem> = {
  selectedItem: TItem | null;
  onSelected: React.Dispatch<TItem | null>;
  hasFocus: boolean;
  setHasFocus: React.Dispatch<boolean>;
  className?: string;
  onBlur: React.FocusEventHandler;
  onFocus: React.FocusEventHandler;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown: React.KeyboardEventHandler;
  items: TItem[];
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  name: string;
  label: string;
  helperText: string;
  isValid: boolean;
  formatSelection: (item: TItem) => string;
  formatItem: (item: TItem) => ReactNode;
  nullDisplay: string;
  selectedIndex: number | undefined;
};

export function _DropdownDisplay<TItem>(
  props: _DropdownDisplayProps<TItem>
): ReturnType<React.FC> {
  const {
    items,
    selectedItem,
    onSelected,
    className,
    onBlur,
    onFocus,
    onChange,
    required,
    disabled,
    autoFocus,
    name,
    label,
    helperText,
    isValid,
    formatItem,
    formatSelection,
    nullDisplay,
    hasFocus,
    setHasFocus,
    selectedIndex,
    onKeyDown,
  } = props;

  const inputRef = useRef<Input<HTMLInputElement>>();
  const selectItem = (x) => {
    onSelected(x);
    setMenuFocus(false);
    setHasFocus(false);
    inputRef.current?.inputElement?.blur();
  };

  const menuItems = items.map((x) => ({
    label: formatItem(x),
    key: formatItem(x),
    onClick: () => selectItem(x),
  }));

  if (!required) {
    menuItems.unshift({
      label: nullDisplay,
      key: nullDisplay,
      onClick: () => selectItem(null),
    });
  }

  const [hasMenuFocus, setMenuFocus] = useState(false);

  const menuVisible = hasFocus || hasMenuFocus;

  const handleFocus = (x: React.FocusEvent) => {
    // checking to see if the thing that just lost focus was the menu. If so, it just got clicked,
    // and we don't need to refocus and re-show the menu
    const menuSurface = x.relatedTarget?.closest(".mdc-menu-surface--anchor");

    if (
      inputRef.current?.inputElement?.closest(".mdc-menu-surface--anchor") ===
      menuSurface
    ) {
      return;
    }

    onFocus(x);
  };

  return (
    <PopupMenuList
      visible={menuVisible}
      items={menuItems}
      onMenuMouseEnter={() => setMenuFocus(true)}
      onMenuMouseLeave={() => setMenuFocus(false)}
      focusOnOpen={false}
      selectedIndex={selectedIndex}
    >
      <section className={cn("form-field", "wolfkrow-dropdown", className)}>
        <MdcTextField
          label={label + `${required ? " *" : ""}`}
          helperText={<HelperTextLine message={helperText} isValid={isValid} />}
          onBlur={onBlur}
          onFocus={handleFocus}
        >
          <Input
            name={name}
            value={selectedItem ? formatSelection(selectedItem) : nullDisplay}
            isValid={isValid}
            onChange={onChange}
            aria-label={label}
            autoComplete="new-password"
            disabled={disabled}
            type={"text"}
            autoFocus={autoFocus}
            onKeyDown={onKeyDown}
            ref={inputRef}
          />
        </MdcTextField>
        {!menuVisible && (
          <MaterialIcon
            icon="expand_more"
            role="button"
            onClick={() => setHasFocus(true)}
          />
        )}
        {menuVisible && (
          <MaterialIcon
            icon="expand_less"
            role="button"
            onClick={() => setHasFocus(false)}
          />
        )}
      </section>
    </PopupMenuList>
  );
}

function computeSelections({ items, hasFocus, selectedItem, onSelected }) {
  if (!hasFocus) {
    return {
      selectedIndex: undefined,
      selectNext: noop,
      selectPrev: noop,
    };
  }

  if (!selectedItem) {
    return {
      selectedIndex: 0,
      selectNext: () => onSelected(items[0]),
      selectPrev: noop,
    };
  }

  const selectedIndex = items.indexOf(selectedItem) + 1;

  const atEnd = selectedIndex === items.length;

  return {
    selectedIndex,
    selectNext: atEnd ? noop : () => onSelected(items[selectedIndex]),
    selectPrev: () => onSelected(items[selectedIndex - 2]),
  };
}
