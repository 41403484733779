import React from "react";
import { EditCustomerFormValues, FormComponentProps } from "./types";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { useRelativeNav } from "~/relative-nav";
import { Navigate } from "react-router-dom";
import { MutationForm } from "~/forms/MutationForm";
import { FormComponent } from "./FormComponent";
import { Customer, ValidationResponse } from "~/gql/types";
import { customerManagement, customer as customerRoute } from "~/routes";
import { useEditCustomerMutation } from "~/customer/EditCustomer/query.generated";
import { useSnackBar } from "~/snackbar";

type EditCustomerProps = FormComponentProps & {
  customer: Customer;
  initialValues: EditCustomerFormValues;
};

export const EditCustomerForm: React.FC<EditCustomerProps> = (props) => {
  const { customer, isInternational, initialValues } = props;

  useBreadcrumbs(
    [
      { text: "Customers", to: customerManagement.path },
      {
        text: `Customer ${customer.number}: ${customer.name}`,
        to: customerRoute.toRoute(customer.number!).path,
      },
      { text: "Edit Customer" },
    ],
    [customer]
  );

  const addAlert = useSnackBar();

  const successMessage = `Customer ${customer.number} updated.`;

  const [doMutation, { data }] = useEditCustomerMutation();

  const navigate = useRelativeNav();

  const onSuccess = ({ values }) => {
    addAlert({
      isSuccess: true,
      key: `${Math.random()}`,
      message: successMessage,
    });

    const path = `../../${values.mainNumber}${values.subNumber}`;

    navigate(path);
  };

  const runMutation = async (values: EditCustomerFormValues) => {
    const variables = {
      name: values.name || "",
      address1: values.address1 || "",
      address2: values.address2 || "",
      city: values.city || "",
      state: values.state || "",
      zip: values.zip || "",
      phone: values.phone || "",
      mainNumber: values.mainNumber || "",
      subNumber: values.subNumber || null,
      country: isInternational ? values.country : "",
      fax: values.fax || "",
      notes: values.notes || "",
      attn: values.attn || null,
      nmTaxable: values.nmTaxable,
      txTaxable: values.txTaxable,
      number: customer.number!,
      electronicInvoicingSystem: values.electronicInvoicingSystem || null,
    };

    const res = await doMutation({ variables });

    return res?.data?.customers?.editCustomer as ValidationResponse;
  };

  if (data && (data?.customers?.editCustomer?.errors?.length ?? 0) === 0) {
    return <Navigate to={customerManagement.path} />;
  }

  const defaultFormValues = {
    ...customer,
    mainNumber: customer.number!.slice(0, 6),
    subNumber: customer.number!.slice(6),
  };

  return (
    <MutationForm
      onSuccess={onSuccess}
      initialValues={initialValues ?? defaultFormValues}
      runMutation={runMutation}
    >
      <FormComponent {...props} />
    </MutationForm>
  );
};
