import { Dialog, DialogTitle, DialogContent } from "~/dialog";
import React from "react";
import FormBottomRow from "~/form-bottom-row";
import TextFormField from "~/text-form-field";
import Spinner from "~/spinner";
import { CustomRollupInvoice, Invoice, ValidationResponse } from "~/gql/types";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";

export type EditInvoiceProps = {
  editNotes: (value: FormValues) => Promise<ValidationResponse>;
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  refetch: React.DispatchWithoutAction;
  invoice: Invoice | CustomRollupInvoice;
  loading: boolean;
};
type FormValues = {
  notes: string;
};

const EditInvoiceNotes: React.FC<EditInvoiceProps> = ({
  open,
  setOpen,
  editNotes,
  refetch,
  invoice,
  loading,
}) => {
  const onSuccess = () => {
    refetch();
    setOpen(false);
  };

  const initialValues = {
    notes: invoice?.notes ?? "",
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      data-open={open}
      className="invoice-notes-form"
    >
      <DialogTitle>Edit Invoice Notes</DialogTitle>
      <DialogContent>
        <MutationForm
          onSuccess={onSuccess}
          initialValues={initialValues}
          runMutation={editNotes}
          allowPristineSubmit
        >
          <TextFormField
            label="Notes"
            helperText=""
            formField="notes"
            textarea
          />

          <ErrorRenderer
            render={(submitError) => (
              <FormBottomRow
                errorMessage={submitError}
                buttonText="Save"
                onCancel={() => setOpen(false)}
              />
            )}
          />
        </MutationForm>
      </DialogContent>
      <Spinner open={loading} />
    </Dialog>
  );
};

export default EditInvoiceNotes;
