import React from "react";
import { TaskRateSheet } from "~/gql/types";
import { EditRateSheet } from "~/visuals/organisms/EditRateSheet";

type EditTaskRateSheetProps = {
  useReload(): React.DispatchWithoutAction;
  rateSheet: TaskRateSheet;
};

export const EditTaskRateSheet: React.FC<EditTaskRateSheetProps> = ({
  rateSheet,
  useReload,
}) => <EditRateSheet {...{ rateSheet, useReload, type: "Task" }} />;
