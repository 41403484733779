import { useUser } from "~/Auth/useUser";
import { useRawJwt } from "~/Auth/AuthenticationManager";

export type LoadFileProps = {
  imageUrl: string;
};

export type ImageInfo = {
  url: string;
  type: string;
  dispose: () => void;
};

export function useLoadFile(): (args: LoadFileProps) => Promise<ImageInfo> {
  const token = useRawJwt();
  const user = useUser();

  return async ({ imageUrl }: LoadFileProps) => {
    const res = await window.fetch(
      imageUrl.startsWith("/") ? imageUrl : `/${imageUrl}`,
      {
        method: "get",
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          impersonation: user.isImpersonating ? user.email : "",
        },
      }
    );

    if (!res || res.status !== 200 || !res.headers.get("Content-Type")) {
      throw new Error("There was a problem loading the file");
    }
    const type = res.headers.get("Content-Type")!;
    const blob = await res.blob();
    const file = new Blob([blob], { type: type as string });
    const fileUrl = URL.createObjectURL(file);
    return { url: fileUrl, type, dispose: () => URL.revokeObjectURL(fileUrl) };
  };
}
