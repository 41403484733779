import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApproveableTimesheetsCountQueryVariables = Types.Exact<{
  weekStart: Types.Scalars['String'];
  dateAfter?: Types.InputMaybe<Types.Scalars['String']>;
  status?: Types.InputMaybe<Types.TimesheetStatus>;
  dateBefore?: Types.InputMaybe<Types.Scalars['String']>;
  payPeriod?: Types.InputMaybe<Types.PayPeriod>;
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
  reimbursement?: Types.InputMaybe<Types.TimesheetSearchReimbursementOption>;
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
  workLocation?: Types.InputMaybe<Types.Scalars['String']>;
  officeCode?: Types.InputMaybe<Types.Scalars['String']>;
  payrollAdmin?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ApproveableTimesheetsCountQuery = { __typename?: 'QueryModel', timesheets?: { __typename?: 'TimesheetsQueryModel', approveableTimesheetCount?: { __typename?: 'ApproveableTimesheetCountResponse', count?: number | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const ApproveableTimesheetsCountDocument = gql`
    query ApproveableTimesheetsCount($weekStart: String!, $dateAfter: String, $status: TimesheetStatus, $dateBefore: String, $payPeriod: PayPeriod, $searchText: String, $reimbursement: TimesheetSearchReimbursementOption, $userPrincipalName: String, $workLocation: String, $officeCode: String, $payrollAdmin: String) {
  timesheets {
    approveableTimesheetCount(
      weekStart: $weekStart
      dateAfter: $dateAfter
      status: $status
      dateBefore: $dateBefore
      payPeriod: $payPeriod
      searchText: $searchText
      reimbursement: $reimbursement
      userPrincipalName: $userPrincipalName
      workLocation: $workLocation
      officeCode: $officeCode
      payrollAdmin: $payrollAdmin
    ) {
      errors {
        argumentName
        message
      }
      count
    }
  }
}
    `;

/**
 * __useApproveableTimesheetsCountQuery__
 *
 * To run a query within a React component, call `useApproveableTimesheetsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useApproveableTimesheetsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApproveableTimesheetsCountQuery({
 *   variables: {
 *      weekStart: // value for 'weekStart'
 *      dateAfter: // value for 'dateAfter'
 *      status: // value for 'status'
 *      dateBefore: // value for 'dateBefore'
 *      payPeriod: // value for 'payPeriod'
 *      searchText: // value for 'searchText'
 *      reimbursement: // value for 'reimbursement'
 *      userPrincipalName: // value for 'userPrincipalName'
 *      workLocation: // value for 'workLocation'
 *      officeCode: // value for 'officeCode'
 *      payrollAdmin: // value for 'payrollAdmin'
 *   },
 * });
 */
export function useApproveableTimesheetsCountQuery(baseOptions: Apollo.QueryHookOptions<ApproveableTimesheetsCountQuery, ApproveableTimesheetsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApproveableTimesheetsCountQuery, ApproveableTimesheetsCountQueryVariables>(ApproveableTimesheetsCountDocument, options);
      }
export function useApproveableTimesheetsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApproveableTimesheetsCountQuery, ApproveableTimesheetsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApproveableTimesheetsCountQuery, ApproveableTimesheetsCountQueryVariables>(ApproveableTimesheetsCountDocument, options);
        }
export type ApproveableTimesheetsCountQueryHookResult = ReturnType<typeof useApproveableTimesheetsCountQuery>;
export type ApproveableTimesheetsCountLazyQueryHookResult = ReturnType<typeof useApproveableTimesheetsCountLazyQuery>;
export type ApproveableTimesheetsCountQueryResult = Apollo.QueryResult<ApproveableTimesheetsCountQuery, ApproveableTimesheetsCountQueryVariables>;