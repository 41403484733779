import React from "react";
import { useRemoveLineItemInvoiceMutation } from "./removeLineItem.generated";
import { InvoiceRemoveLineItem } from "~/invoice-remove-lineitem";

type RemoveRollupInvoiceProps = {
  invoiceNumber: string;
  customRollupInvoiceNumber: string;
  refetch: React.DispatchWithoutAction;
};

export const RemoveInvoice: React.FC<RemoveRollupInvoiceProps> = (props) => {
  const { invoiceNumber, customRollupInvoiceNumber, refetch } = props;
  const [doMutation] = useRemoveLineItemInvoiceMutation();

  const variables = {
    customRollupInvoiceNumber,
    invoiceNumber,
  };

  return (
    <InvoiceRemoveLineItem
      {...{
        refetch,
        className: "remove-invoice-button",
        remove: () => doMutation({ variables }),
      }}
    />
  );
};
