import React from "react";
import { useLocation, useParams } from "react-router-dom";
import PendingCustomer from "~/graphql/PendingCustomer";
import ApproveCustomer from "~/visuals/pages/PendingCustomersRouter/ApproveCustomer";
import RejectCustomer from "~/visuals/pages/PendingCustomersRouter/RejectCustomer";

type CustomerRouteData = {
  createCustomerRequestId: string;
};

type ApproveOrRejectRouterProps = {
  useReload(): React.DispatchWithoutAction;
  customers: PendingCustomer[];
};

const filterCustomers = (id: string, customers: PendingCustomer[] | null) => {
  if (customers && id) {
    return customers.find(
      ({ createCustomerRequestId }) => createCustomerRequestId === id
    );
  }
};

export const ApproveOrRejectRouter: React.FC<ApproveOrRejectRouterProps> = ({
  useReload,
  customers,
}) => {
  const params = useParams<CustomerRouteData>();
  const location = useLocation();

  const kind = location.pathname.includes("approve") ? "Approve" : "Reject";

  const customer = filterCustomers(params.createCustomerRequestId!, customers);

  return (
    <>
      {customer && kind === "Approve" && (
        <ApproveCustomer {...{ customer, useReload }} />
      )}
      {customer && kind === "Reject" && (
        <RejectCustomer {...{ customer, useReload }} />
      )}
    </>
  );
};
