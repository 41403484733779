import { ProjectCharge } from "../ProjectCharges";

export type ChargeItem = {
  index: number;
  charge: ProjectCharge;
  checked: boolean;
};

type CoreState = {
  items: ChargeItem[];
  submitting: boolean;
  loading: boolean;
};

type TaggedState<T extends string> = { tag: T } & CoreState;
type UnSubmitted<T extends string> = TaggedState<T> & { submitting: false };

export type Initial = UnSubmitted<"Initial"> & { loading: true };
export type Loaded = UnSubmitted<"Loaded"> & { loading: false };
export type AllSelected = UnSubmitted<"AllSelected">;
export type Submitting = TaggedState<"Submitting"> & {
  submitting: true;
  loading: true;
  queue: ChargeItem[];
};
export type Submitted = TaggedState<"Submitted"> & {
  submitting: false;
  loading: false;
};

type TaggedAction<T extends string> = { tag: T };
export type DataLoaded = TaggedAction<"DataLoaded"> & {
  items: ProjectCharge[];
};
export type UpdateItemChecked = TaggedAction<"UpdateItemChecked"> & {
  index: number;
};
export type SelectAll = TaggedAction<"SelectAll">;
export type DeselectAll = TaggedAction<"DeselectAll">;
export type Submit = TaggedAction<"Submit">;
export type AllSubmitted = TaggedAction<"AllSubmitted">;
export type Reset = TaggedAction<"Reset">;

export type State = Loaded | Submitted | Initial | AllSelected | Submitting;

export type Action =
  | DataLoaded
  | UpdateItemChecked
  | SelectAll
  | DeselectAll
  | Reset
  | Submit
  | AllSubmitted;

export const mapCharges = (charges: ProjectCharge[]): ChargeItem[] =>
  charges.map((x, i) => ({
    index: i,
    charge: x,
    checked: false,
    loading: false,
    submitted: false,
  }));

const reset = () => {
  const newState = {
    tag: "Initial",
    items: [],
    loading: true,
    submitting: false,
  };
  return newState as State;
};

function reduceInitial(state: Initial, action: Action) {
  if (action.tag === "DataLoaded") {
    const newState = {
      ...state,
      tag: "Loaded",
      loading: false,
      items:
        action.items && action.items.length > 0 ? mapCharges(action.items) : [],
    };
    return newState as State;
  }
  return state;
}

function reduceLoaded(state: Loaded, action: Action) {
  if (action.tag === "SelectAll") {
    const newState = {
      ...state,
      tag: "AllSelected",
      items: state.items.map((x) => ({ ...x, checked: true })),
    };
    return newState as State;
  }
  if (action.tag === "Submit") {
    const newState = {
      ...state,
      submitting: true,
      tag: "Submitting",
      loading: true,
      queue: state.items.filter((x) => x.checked),
    };
    return newState as State;
  }
  if (action.tag === "UpdateItemChecked") {
    const newState = {
      ...state,
      items: state.items.map((x) => {
        if (x.index === action.index) {
          return { ...x, checked: !x.checked };
        }
        return x;
      }),
    };
    return newState as State;
  }
  if (action.tag === "Reset") {
    return reset();
  }
  return state;
}

function reduceAllSelected(state: AllSelected, action: Action) {
  if (action.tag === "DeselectAll") {
    const newState = {
      ...state,
      tag: "Loaded",
      items: state.items.map((x) => ({ ...x, checked: false })),
    };
    return newState as State;
  }
  if (action.tag === "Submit") {
    const newState = {
      ...state,
      submitting: true,
      tag: "Submitting",
      loading: true,
      queue: state.items.filter((x) => x.checked),
    };
    return newState as State;
  }
  if (action.tag === "UpdateItemChecked") {
    const newState = {
      ...state,
      tag: "Loaded",
      items: state.items.map((x) => {
        if (x.index === action.index) {
          return { ...x, checked: false };
        }
        return x;
      }),
    };
    return newState as State;
  }
  if (action.tag === "Reset") {
    return reset();
  }
  return state;
}

function reduceSubmitting(state: Submitting, action: Action) {
  if (action.tag === "AllSubmitted") {
    const newState = {
      ...state,
      loading: false,
      tag: "Submitted",
      submitting: false,
      queue: [],
    };
    return newState as State;
  }

  return state;
}

function reduceSubmitted(state: Submitted, action: Action) {
  if (action.tag === "Reset") {
    return reset();
  }
  return state;
}

export function reducer(state: State, action: Action): State {
  if (state.tag === "Initial") {
    return reduceInitial(state, action);
  }
  if (state.tag === "Loaded") {
    return reduceLoaded(state, action);
  }
  if (state.tag === "AllSelected") {
    return reduceAllSelected(state, action);
  }
  if (state.tag === "Submitting") {
    return reduceSubmitting(state, action);
  }
  if (state.tag === "Submitted") {
    return reduceSubmitted(state, action);
  }
  return state;
}
