import React from "react";
import Button from "~/button";
import FormBottomRow from "~/form-bottom-row";
import { useRelativeNav } from "~/relative-nav";
import { ExpenseFormType } from "./CommonTypes";
import { SaveAndHandler } from "~/WorkDayApi/SaveAndHandler";

type ExpenseFormBottomProps = {
  type: ExpenseFormType;
  date: string;
  expenseId?: string;
  errorMessage: string | null;
};

const ExpenseFormBottom: React.FC<ExpenseFormBottomProps> = ({
  type,
  errorMessage,
}) => {
  const cancelPath = type === "Create" ? "../../" : "../../../";

  const relativeNav = useRelativeNav();

  return (
    <>
      <Button
        className="back-button"
        type="button"
        onClick={() => relativeNav("../")}
      >
        Back
      </Button>
      <FormBottomRow
        errorMessage={errorMessage}
        buttonText={type === "Create" ? "Create" : "Save"}
        onCancel={() => relativeNav(cancelPath)}
      >
        {type !== "Edit" && <SaveAndHandler />}
      </FormBottomRow>
    </>
  );
};

export default ExpenseFormBottom;
