import React from "react";
import { useCrewCodes, useInvalidateCrewCodes } from "~/refdata2/crewCodes";
import { CrewCodeOverridesDisplay } from "./CrewCodeOverridesDisplay";
import { UpdateCrewCodeFormValues } from "./types";
import { useUpdateCrewCodeMutation } from "./update.generated";
import Spinner from "~/spinner";
import { ValidationResponse } from "~/gql/types";

export const CrewCodeOverridesPage: React.FC = () => {
  const crewCodes = useCrewCodes();

  const invalidate = useInvalidateCrewCodes();
  const [doMutation, { loading }] = useUpdateCrewCodeMutation();

  const runMutation = async (values: UpdateCrewCodeFormValues) => {
    const variables = {
      crewCode: values.crewCode!.crewCode,
      overrideEmployeeId: values.overrideEmployeeId ?? null,
    };

    const result = await doMutation({ variables });
    return result?.data?.crewCodes?.updateCrewCode as ValidationResponse;
  };

  const filtered =
    crewCodes != null ? crewCodes.filter((x) => !!x.overrideEmployeeId) : [];

  return (
    <>
      <CrewCodeOverridesDisplay
        {...{
          crewCodes: filtered,
          refetch: invalidate,
          runMutation,
        }}
      />
      <Spinner open={crewCodes === null || loading} />
    </>
  );
};
