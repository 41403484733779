import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Authorizations, GetAuthorizationsAsync } from "./Auth/types";
import App from "./App";
import "./errorReporting";

export const defaultGetAuthorizationsAsync: GetAuthorizationsAsync = async (
  token,
  impersonatedEmployeeEmail?
) => {
  const res = await window.fetch("/api/authcheck", {
    headers: {
      Authorization: `Bearer ${token}`,
      Impersonation: impersonatedEmployeeEmail ?? "",
    },
  });
  if (res.status === 200) {
    return (await res.json()) as Authorizations;
  } else {
    return null;
  }
};

ReactDOM.render(
  <Router>
    <App getAuthorizationsAsync={defaultGetAuthorizationsAsync} />
  </Router>,
  document.getElementById("root")
);
