import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import { useServiceDescriptionsAsync } from "~/refdata2/serviceDescriptions";
import _ from "lodash";
import { IndustryPickerFormField } from "~/visuals/organisms/IndustryPickerFormField";

function useIndustryFromParam(): (name: string) => Promise<string | null> {
  const getServiceDescriptions = useServiceDescriptionsAsync();

  return async (name: string) => {
    const result = await getServiceDescriptions();
    const unique = _.uniqBy(result, "industry");
    const type = unique?.find((x) => x.industry === name)?.industry;

    return type ?? null;
  };
}

export function useIndustryProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText: origDocText } = props;
  const industryFromParam = useIndustryFromParam();
  const getIndustry = useServiceDescriptionsAsync();

  const documentationText = `${origDocText} Use "industry" for quick filtering.`;
  const type = "industry";
  const label = "Industry";

  return {
    render: () => (
      <IndustryPickerFormField formField={type} label={label} helperText="" />
    ),
    type,
    label,
    documentationText,
    getSuggestions: async (text: string) => {
      const result = await getIndustry();

      const industries = _.chain(result)
        .filter((t) =>
          text ? t.industry.toLowerCase().includes(text.toLowerCase()) : true
        )
        .uniqBy("industry")
        .map((t) => ({ text: t.industry, value: t.industry }))
        .value();

      if (!industries || industries.length === 0) {
        return [];
      }
      return industries;
    },
    shouldSuggest: (text: string) => text.toLowerCase().startsWith("industry"),
    toFilter: (value: string) => ({
      label: `${label}: ${value}`,
      type,
      value: value,
    }),
    filterFromParams: async (params: URLSearchParams) => {
      const paramIndustry = params.get(type) || null;
      const industry = paramIndustry
        ? await industryFromParam(paramIndustry)
        : null;

      return industry
        ? {
            label: `${label}: ${industry}`,
            value: industry,
            type,
          }
        : null;
    },
    toParam: (filter: ActiveFilter) => filter.value ?? "",
  };
}
