import React, { useEffect, useState } from "react";
import ExpensePickerFormField from "~/visuals/organisms/ExpensePickerFormField";
import TaskPickerFormField from "~/visuals/organisms/TaskPickerFormField";
import { ExpenseRate } from "~/graphql/ExpenseRateSheet";
import { ChargeType, IExpenseRate, TaskRate } from "~/gql/types";
import { InlineProgress } from "~/inline-progress";
import { useFormState } from "react-final-form";
import { useLoadRates } from "~/project-charge-form/useLoadRates";
import { useSplitChargeFormField } from "./fieldUtils";

type RateInfo = {
  rateSheet: string | null;
  rates: ExpenseRate[] | TaskRate[];
};

type RateSheetLoaderProps = {
  setRateInfo: React.Dispatch<RateInfo>;
  setLoading: React.Dispatch<boolean>;
  date: string;
  type: ChargeType;
};

const RateSheetLoader: React.FC<RateSheetLoaderProps> = ({
  setRateInfo,
  setLoading,
  date,
  type,
}) => {
  const loadRates = useLoadRates();

  const project = useSplitChargeFormField("project").value;
  const formState = useFormState();

  const { onChange: changeRate } = useSplitChargeFormField("rate");

  const clearRateInfo = () => {
    setRateInfo({ rateSheet: null, rates: [] });
    changeRate(null);
  };

  useEffect(() => {
    if (!formState.pristine) {
      clearRateInfo();
    }

    if (!project) {
      return;
    }

    void (async () => {
      await loadRates({
        date,
        projectNumber: project.number,
        setLoading,
        callback: (args) => setRateInfo(args),
        type,
      });
    })();
  }, [type, date, project]);

  return <></>;
};

type RateFieldProps = {
  type: ChargeType;
  date: string;
};

export const RateField: React.FC<RateFieldProps> = ({ type, date }) => {
  const project = useSplitChargeFormField("project");
  const rateSheet = useSplitChargeFormField("rateSheet").value;

  const [loading, setLoading] = useState(false);
  const [rateInfo, setRateInfo] = useState<RateInfo>({ rateSheet, rates: [] });

  const { onChange: rateSheetOnChange } = useSplitChargeFormField("rateSheet");

  useEffect(() => {
    rateSheetOnChange(rateInfo.rateSheet);
  }, [rateInfo]);

  const disabled = !project.value || !rateInfo.rates.length;

  return (
    <>
      {type === "Task" && (
        <TaskPickerFormField
          formField="rate"
          label="Rate"
          helperText={""}
          taskRates={(rateInfo.rates ?? []) as TaskRate[]}
          disabled={disabled}
          required
        />
      )}
      {type === "Expense" && (
        <ExpensePickerFormField
          formField="rate"
          label="Rate"
          helperText={""}
          expenseRates={(rateInfo.rates ?? []) as IExpenseRate[]}
          showInvoiceLabel={true}
          disabled={disabled}
          required
        />
      )}
      {loading && <InlineProgress />}
      <RateSheetLoader {...{ setRateInfo, setLoading, type, date }} />
    </>
  );
};
