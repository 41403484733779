import React, { useMemo } from "react";
import { Cell, Row } from "@material/react-layout-grid";
import "./CreateRateWizard.scss";
import List from "@material/react-list";
import { Headline6 } from "@material/react-typography";
import { State, Action } from "./stateMachine";
import Calendar from "react-calendar";
import { WizardPage } from "./base";
import { RatesPage } from "./RatesPage";
import { LocationsPage } from "./LocationsPage";
import _ from "lodash";
import moment from "moment";
import { ExpandableState } from "./ExpandableState";
import "./Calendar.scss";

export const WizardInner: React.FC<{
  state: State;
  dispatch: React.Dispatch<Action>;
  create: () => void;
}> = ({ state, dispatch, create }) => {
  if (state.page === "Confirm") {
    return <ConfirmPage {...{ state, dispatch, create }} />;
  }
  if (state.page === "Rates") {
    return <RatesPage {...{ state, dispatch }} />;
  }
  if (state.page === "Locations") {
    return <LocationsPage {...{ state, dispatch }} />;
  }
  return null;
};

export const ConfirmPage: React.FC<{
  state: State;
  dispatch: React.Dispatch<Action>;
  create: () => void;
}> = ({ state, dispatch, create }) => {
  const allCountiesByState = useMemo(
    () =>
      _.chain(state.allLocations)
        .groupBy((x) => x.state)
        .mapValues((counties) => new Set(counties.map((c) => c.county)))
        .value(),
    [state.allLocations]
  );
  const statesSorted = useMemo(
    () =>
      _.chain(allCountiesByState)
        .keys()
        .orderBy((x) => x)
        .value(),
    [allCountiesByState]
  );

  const selectionsByState = useMemo(
    () =>
      _.chain(state.selectedLocations)
        .groupBy((x) => x.state)
        .mapValues((counties) => new Set(counties.map((c) => c.county)))
        .value(),
    [state.selectedLocations]
  );

  const buttonAction: Action = { tag: "Submit", create };

  return (
    <WizardPage
      actionButtonIcon="check"
      actionButtonText="Done"
      buttonAction={buttonAction}
      state={state}
      dispatch={dispatch}
    >
      <Row className="two-column">
        <Cell columns={6} className="column">
          <Headline6>Selected Locations</Headline6>
          <List>
            {statesSorted.map((state, idx) => (
              <ExpandableState
                state={state}
                key={state}
                leadingSeparator={idx === 0}
                allCounties={allCountiesByState[state] || new Set<string>([])}
                selectedCounties={
                  selectionsByState[state] || new Set<string>([])
                }
                showAllOption={false}
                dispatch={dispatch}
                showSelected
              />
            ))}
          </List>
        </Cell>
        <Cell columns={6} className="column">
          <Headline6>Effective Date</Headline6>
          <Calendar
            className="wolfkrow"
            value={state.effectiveDate ? state.effectiveDate.toDate() : null}
            onChange={(date) =>
              dispatch({ tag: "SetEffectiveDate", effectiveDate: moment(date) })
            }
          />
        </Cell>
      </Row>
    </WizardPage>
  );
};
