import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import {
  ServiceDescription,
  useServiceDescriptionsAsync,
} from "~/refdata2/serviceDescriptions";
import { createSearchRegex } from "~/text-highlighter/createSearchRegex";
import { TextHighlighter } from "~/text-highlighter";

const format = (
  item: ServiceDescription,
  searchText: string
): React.ReactNode => {
  const searchRegex = createSearchRegex(searchText);

  return (
    <div className="service-description-picker-menuitem">
      <TextHighlighter text={item.displayName} search={searchRegex} />
    </div>
  );
};

const chipFormat = (item: ServiceDescription) => item.displayName;

const ServiceDescriptionPicker: React.FC<FormFieldProps> = (props) => {
  const getItems = useServiceDescriptionsAsync();

  const getData = async (searchText: string) => {
    const result = ((await getItems()) ?? []).filter((x) => x.isActive);

    if (!searchText) {
      return result;
    }

    return result.filter((t) =>
      t.displayName.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const args = {
    ...props,
    getData,
    format,
    chipFormat,
  } as TypeaheadProps<ServiceDescription>;

  return <TypeaheadFormField {...args} />;
};

export default ServiceDescriptionPicker;
