import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import moment, { Moment } from "moment";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";

type DateProviderProps = FilterProviderProps & {
  label: string;
  type: string;
  suggestText: string;
};

export function useDateProvider(props: DateProviderProps): FilterProvider {
  const {
    documentationText: origDocText,
    label,
    type,
    suggestText,
    hide,
  } = props;

  const formattedDate = (date: Moment) =>
    moment(date, "MM-DD-YYYY").format("MM/DD/YYYY");

  const itemFromParam = (arg: string) =>
    moment(arg, "YYYY-MM-DD").isValid()
      ? Promise.resolve(moment(arg, "YYYY-MM-DD"))
      : Promise.resolve(null);

  const toFilterValue = (arg: any) =>
    moment(arg, "MM-DD-YYYY").isValid() ? moment(arg, "MM-DD-YYYY") : null;

  const documentationText = `${origDocText} Use "${suggestText}" for quick filtering.`;

  return {
    render: () => (
      <DatePickFormField formField={type} label={label} helperText={""} />
    ),
    type,
    label,
    documentationText,
    getSuggestions: async (text: string) =>
      Promise.resolve([{ text, value: toFilterValue(text) }]),
    shouldSuggest: (text: string) => text.toLowerCase().startsWith(suggestText),
    toFilter: (value) => ({
      label: `${label}: ${formattedDate(value)}`,
      type,
      value: toFilterValue(value),
    }),
    toParam: (filter: ActiveFilter) =>
      filter?.value ? filter.value.format("YYYY-MM-DD") : "",
    plainText: true,
    filterFromParams: async (params: URLSearchParams) => {
      const paramVal = params.get(type) || null;
      const val = paramVal ? await itemFromParam(paramVal) : null;

      return val
        ? {
            label: `${label}: ${formattedDate(val)}`,
            value: toFilterValue(val),
            type,
          }
        : null;
    },
    hide,
  };
}
