import React from "react";
import Card from "@material/react-card";
import "./CustomRollupInvoiceCard.scss";
import { Body2 } from "@material/react-typography";
import { CustomRollupInvoice } from "~/gql/types";
import moment from "moment";
import { LinkClickHandler } from "~/link-click-handler";
import { customRollupInvoice } from "~/routes";
import { currencyFormatter } from "~/currencyFormatter";
import { TextHighlighter } from "~/text-highlighter";
import { CardSelectCheckbox } from "~/search-page-wrapper/BulkCheckboxes/CardSelectCheckbox";
import {
  CustomRollupInvoiceSearchDispatch,
  CustomRollupInvoiceSearchState,
} from "./types";

export type CustomRollupInvoiceCardProps = {
  invoice: CustomRollupInvoice;
  search?: string | null;
  state: CustomRollupInvoiceSearchState;
  dispatch: CustomRollupInvoiceSearchDispatch;
};

export const CustomRollupInvoiceCard: React.FC<
  CustomRollupInvoiceCardProps
> = ({ invoice, search, state, dispatch }) => {
  const getDate = (date?: string | null) =>
    date ? moment(date, "YYYY-MM-DD").format("MM/DD/YYYY") : "";

  const invoiceDate = getDate(invoice.invoiceDate);
  const cancelledDate = getDate(invoice.cancelledDate);
  const customer = invoice.projectGroup!.customer!;
  const projectGroup = invoice.projectGroup!;
  const lineItemCount = invoice.lineItems!.length;
  const projectCount = projectGroup.projects!.length;

  const searchText = search ? new RegExp(search) : null;

  return (
    <Card>
      <LinkClickHandler
        className="custom-rollup-invoice-card"
        path={customRollupInvoice.toRoute(invoice.invoiceNumber).path}
      >
        <CardSelectCheckbox {...{ state, dispatch }} item={invoice} />
        <Body2>
          <TextHighlighter
            text={`${invoice.invoiceNumber} - ${invoice.state}`}
            search={searchText}
          />
          {invoiceDate && (
            <span className="subscript">{getDate(invoice.invoiceDate)}</span>
          )}
          {cancelledDate && (
            <span className="subscript">{`Cancelled: ${cancelledDate}`}</span>
          )}
        </Body2>
        <Body2>
          {`Customer: ${customer!.name}`}{" "}
          <span className="subscript">
            <TextHighlighter text={customer.number!} search={searchText} />
          </span>
        </Body2>
        <Body2>
          {`Project Group: ${projectGroup.name}`}
          <span className="subscript">{`(${lineItemCount} of ${projectCount} projects)`}</span>
        </Body2>
        <Body2>{`Total: ${currencyFormatter.format(invoice.total)}`}</Body2>
      </LinkClickHandler>
    </Card>
  );
};
