import React from "react";
import List, { ListItem, ListItemText } from "~/list";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { ManageRefDataPage } from "~/manage-ref-data";
import { Division } from "~/refdata2/divisions";
import { useGetDivisionsQuery } from "~/refdata2/divisions/query.generated";
import { Route, Routes } from "react-router-dom";
import { useRelativeNav } from "~/relative-nav";
import { DivisionPage } from "./divisionSingle";
import { CreateDivisionPage } from "./createDivision";

type DivisionsPageProps = {
  loading: boolean;
  divisions: Division[];
};

const DivisionsPage: React.FC<DivisionsPageProps> = ({
  loading,
  divisions,
}) => {
  useBreadcrumbs([{ text: "Divisions" }], []);

  const nav = useRelativeNav();

  const title = `Showing ${divisions.length} Divisions`;

  const onAddNewClick = () => {
    nav("create");
  };

  const onEditClick = (item: Division) => {
    nav(item.id);
  };

  return (
    <ManageRefDataPage {...{ title, onAddNewClick, loading }}>
      <List twoLine>
        {divisions
          .sort((a, b) => (a.id > b.id ? 1 : -1))
          .map((item) => (
            <ListItem key={item?.id} onClick={() => onEditClick(item)}>
              <ListItemText
                primaryText={`${item?.id}${
                  !item?.isActive ? " - (inactive)" : ""
                }`}
                secondaryText={item?.name}
              />
            </ListItem>
          ))}
      </List>
    </ManageRefDataPage>
  );
};

export const Divisions: React.FC = () => {
  const { data, refetch, loading: queryLoading } = useGetDivisionsQuery();

  const divisions = (data?.divisions?.all || []) as Division[];

  return (
    <Routes>
      <Route path="create" element={<CreateDivisionPage {...{ refetch }} />} />
      <Route
        path=":id"
        element={<DivisionPage {...{ divisions, refetch }} />}
      />
      <Route
        index
        element={<DivisionsPage {...{ loading: queryLoading, divisions }} />}
      />
    </Routes>
  );
};
