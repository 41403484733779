import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddProjectRequestReviewerMutationVariables = Types.Exact<{
  userPrincipalName: Types.Scalars['String'];
  officeCode: Types.Scalars['String'];
}>;


export type AddProjectRequestReviewerMutation = { __typename?: 'MutationModel', fieldOffices?: { __typename?: 'FieldOfficeMutationModel', addProjectRequestReviewer?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const AddProjectRequestReviewerDocument = gql`
    mutation AddProjectRequestReviewer($userPrincipalName: String!, $officeCode: String!) {
  fieldOffices {
    addProjectRequestReviewer(
      userPrincipalName: $userPrincipalName
      officeCode: $officeCode
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type AddProjectRequestReviewerMutationFn = Apollo.MutationFunction<AddProjectRequestReviewerMutation, AddProjectRequestReviewerMutationVariables>;

/**
 * __useAddProjectRequestReviewerMutation__
 *
 * To run a mutation, you first call `useAddProjectRequestReviewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectRequestReviewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectRequestReviewerMutation, { data, loading, error }] = useAddProjectRequestReviewerMutation({
 *   variables: {
 *      userPrincipalName: // value for 'userPrincipalName'
 *      officeCode: // value for 'officeCode'
 *   },
 * });
 */
export function useAddProjectRequestReviewerMutation(baseOptions?: Apollo.MutationHookOptions<AddProjectRequestReviewerMutation, AddProjectRequestReviewerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProjectRequestReviewerMutation, AddProjectRequestReviewerMutationVariables>(AddProjectRequestReviewerDocument, options);
      }
export type AddProjectRequestReviewerMutationHookResult = ReturnType<typeof useAddProjectRequestReviewerMutation>;
export type AddProjectRequestReviewerMutationResult = Apollo.MutationResult<AddProjectRequestReviewerMutation>;
export type AddProjectRequestReviewerMutationOptions = Apollo.BaseMutationOptions<AddProjectRequestReviewerMutation, AddProjectRequestReviewerMutationVariables>;