import React from "react";
import { useEditAtvExpenseRateMutation } from "./queries.generated";
import { MutationForm } from "~/forms/MutationForm";
import { FormProps, FormData } from "./types";
import { AtvExpenseRate, ValidationResponse } from "~/gql/types";
import { parseNum } from ".";

export const EditAtvExpenseRate: React.FC<
  FormProps & { original: AtvExpenseRate }
> = (props) => {
  const { setLoading, original, rateSheet, onSuccess, children } = props;

  const [rawAtvMutation] = useEditAtvExpenseRateMutation();

  const initialValues = {
    name: original.name,
    invoiceLabel: original.invoiceLabel,
    expenseAccount: original.expenseAccount,
    glAccount: original.glAccount,
    billableAmount: original.billableAmount.toString(),
    reimbursableAmount: original.reimbursableAmount.toString(),
  };

  const handleSubmit = async (values: FormData) => {
    const variables = {
      oldName: original?.name!,
      name: values?.name!,
      rateSheetName: rateSheet.name,
      invoiceLabel: values?.invoiceLabel || null,
      expenseAccount: values?.expenseAccount || null,
      glAccount: values?.glAccount || null,
      billableAmount: parseNum(values?.billableAmount)!,
      reimbursableAmount: parseNum(values?.reimbursableAmount)!,
    };

    const result = await rawAtvMutation({ variables });

    return result?.data?.expenseRates?.editAtvRate as ValidationResponse;
  };

  return (
    <MutationForm
      setLoading={setLoading}
      initialValues={initialValues}
      runMutation={handleSubmit}
      onSuccess={onSuccess}
      allowPristineSubmit
    >
      {children}
    </MutationForm>
  );
};
