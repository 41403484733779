import React from "react";
import { ProjectCharge } from "~/gql/types";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { AddChargeToProject, ProjectChargesCard } from "./ProjectChargesCard";
import { ChargesTable } from "~/charges-table";
import { ProjectChargesFilter } from "./types";

export type ProjectPlaceholderProps = {
  state: State<ProjectCharge, ProjectChargesFilter>;
  dispatch: React.Dispatch<Action<ProjectCharge, ProjectChargesFilter>>;
  _TableComponent?: typeof ChargesTable;
  _FormComponent?: typeof AddChargeToProject;
};

const canShowPlaceholder = (
  state: State<ProjectCharge, ProjectChargesFilter>
) => {
  const { filterOptions, items } = state;

  return filterOptions.project != null && items.length === 0;
};

export const ProjectPlaceholder: React.FC<ProjectPlaceholderProps> = (
  props
) => {
  const { state, dispatch, _TableComponent, _FormComponent } = props;

  if (!canShowPlaceholder(state)) {
    return null;
  }

  return (
    <ProjectChargesCard
      {...{
        project: state.filterOptions.project!,
        state,
        dispatch,
        charges: [],
        _FormComponent,
        _TableComponent,
      }}
    />
  );
};
