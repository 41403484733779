type Tag = {
  tag:
    | "SuggestionsLoaded"
    | "LoadSuggestions"
    | "GotFocus"
    | "LostFocus"
    | "GotMenuMouse"
    | "LostMenuMouse"
    | "Select"
    | "ClearSelection"
    | "TextChanged"
    | "MoveHighlightUp"
    | "MoveHighlightDown";
};

export type SuggestionsLoaded<T> = Tag & {
  tag: "SuggestionsLoaded";
  data: T[];
};
export type LoadSuggestions = Tag & { tag: "LoadSuggestions" };
export type GotFocus = Tag & { tag: "GotFocus" };
export type LostFocus = Tag & { tag: "LostFocus" };
export type GotMenuMouse = Tag & { tag: "GotMenuMouse" };
export type LostMenuMouse = Tag & { tag: "LostMenuMouse" };
export type Select<T> = Tag & {
  tag: "Select";
  selection: T;
};
export type ClearSelection = Tag & { tag: "ClearSelection" };
export type TextChanged = Tag & {
  tag: "TextChanged";
  text: string;
};
export type MoveHighlightUp = Tag & { tag: "MoveHighlightUp" };
export type MoveHighlightDown = Tag & { tag: "MoveHighlightDown" };

export type Action<T> =
  | SuggestionsLoaded<T>
  | LoadSuggestions
  | GotFocus
  | LostFocus
  | GotMenuMouse
  | LostMenuMouse
  | Select<T>
  | ClearSelection
  | TextChanged
  | MoveHighlightUp
  | MoveHighlightDown;

export function isSuggestionsLoaded<T>(
  x: Action<T>
): x is SuggestionsLoaded<T> {
  return x.tag === "SuggestionsLoaded";
}
export function isLoadSuggestions<T>(x: Action<T>): x is LoadSuggestions {
  return x.tag === "LoadSuggestions";
}
export function isGotFocus<T>(x: Action<T>): x is GotFocus {
  return x.tag === "GotFocus";
}
export function isLostFocus<T>(x: Action<T>): x is LostFocus {
  return x.tag === "LostFocus";
}
export function isGotMenuMouse<T>(x: Action<T>): x is GotMenuMouse {
  return x.tag === "GotMenuMouse";
}
export function isLostMenuMouse<T>(x: Action<T>): x is LostMenuMouse {
  return x.tag === "LostMenuMouse";
}
export function isSelect<T>(x: Action<T>): x is Select<T> {
  return x.tag === "Select";
}
export function isClearSelection<T>(x: Action<T>): x is ClearSelection {
  return x.tag === "ClearSelection";
}
export function isTextChanged<T>(x: Action<T>): x is TextChanged {
  return x.tag === "TextChanged";
}
export function isMoveHighlightUp<T>(x: Action<T>): x is MoveHighlightUp {
  return x.tag === "MoveHighlightUp";
}
export function isMoveHighlightDown<T>(x: Action<T>): x is MoveHighlightDown {
  return x.tag === "MoveHighlightDown";
}
