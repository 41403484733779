import React from "react";
import Button from "~/button";
import { NotificationBadgeProps } from "./type";
import cn from "classnames";
import "./NotificationBadge.scss";
export const NotificationBadge: React.FC<NotificationBadgeProps> = ({
  notificationCount,
  setOpen,
}) => {
  const onOff = notificationCount > 0;

  return (
    <Button
      className={cn("notification-badge", { on: onOff })}
      icon="notifications"
      onClick={onOff ? () => setOpen(true) : undefined}
    >
      <span className="notification-number">
        {onOff ? (notificationCount > 10 ? "9+" : notificationCount) : ""}
      </span>
    </Button>
  );
};
