import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FieldOfficesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FieldOfficesQuery = { __typename?: 'QueryModel', fieldOffices?: { __typename?: 'FieldOfficesQueryModel', all?: Array<{ __typename?: 'FieldOffice', officeCode: string, allowsProjects: boolean } | null> | null } | null };


export const FieldOfficesDocument = gql`
    query FieldOffices {
  fieldOffices {
    all {
      officeCode
      allowsProjects
    }
  }
}
    `;

/**
 * __useFieldOfficesQuery__
 *
 * To run a query within a React component, call `useFieldOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldOfficesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFieldOfficesQuery(baseOptions?: Apollo.QueryHookOptions<FieldOfficesQuery, FieldOfficesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldOfficesQuery, FieldOfficesQueryVariables>(FieldOfficesDocument, options);
      }
export function useFieldOfficesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldOfficesQuery, FieldOfficesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldOfficesQuery, FieldOfficesQueryVariables>(FieldOfficesDocument, options);
        }
export type FieldOfficesQueryHookResult = ReturnType<typeof useFieldOfficesQuery>;
export type FieldOfficesLazyQueryHookResult = ReturnType<typeof useFieldOfficesLazyQuery>;
export type FieldOfficesQueryResult = Apollo.QueryResult<FieldOfficesQuery, FieldOfficesQueryVariables>;