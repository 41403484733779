import React, { useEffect, useState } from "react";
import { FormValues, ThirdPartyInvoiceForm } from "../ThirdPartyInvoiceForm";
import { useEditThirdPartyInvoiceMutation } from "./edit.generated";
import { ThirdPartyInvoice, ValidationResponse } from "~/gql/types";
import { useNavigate } from "react-router-dom";
import { thirdPartyInvoice as thirdPartyInvoiceRoute } from "~/routes";
import { useSnackBar } from "~/snackbar";
import Spinner from "~/spinner";
import { useLoadFile } from "~/file-loading/useLoadFile";

type EditThirdPartyInvoiceProps = {
  thirdPartyInvoice: ThirdPartyInvoice;
  refetch: () => void;
  mockInitialValues?: FormValues;
};
export const EditThirdPartyInvoice: React.FC<EditThirdPartyInvoiceProps> = ({
  thirdPartyInvoice,
  refetch,
  mockInitialValues,
}) => {
  const navigate = useNavigate();
  const addAlert = useSnackBar();

  const [doMutation] = useEditThirdPartyInvoiceMutation();
  const [image, setImage] = useState<any>(null);

  const loadImage = useLoadFile();

  const [imageId, setImageId] = useState<string | null>(
    thirdPartyInvoice.documentId ?? null
  );

  useEffect(() => {
    void (async () => {
      const documentImage = await loadImage({
        imageUrl: thirdPartyInvoice.documentUrl,
      });
      setImage(documentImage.url);
    })();
  }, []);

  const handleSubmit = async (values: FormValues) => {
    const variables = {
      invoiceNumber: thirdPartyInvoice.invoiceNumber,
      date: values.date!.format("YYYY-MM-DD"),
      amount: parseFloat(values.amount!),
      projectId: values.project.number!,
      documentImageId: imageId!,
      crewCode: values.crewCode!.crewCode,
      notes: values.notes || null,
    };
    const result = await doMutation({ variables });

    return result?.data?.thirdPartyInvoices?.edit as ValidationResponse;
  };

  const onSuccess = () => {
    addAlert({
      message: `${thirdPartyInvoice.invoiceNumber} updated`,
      isSuccess: true,
      key: `${Math.random()}`,
    });

    refetch();
    navigate(
      thirdPartyInvoiceRoute.toRoute(thirdPartyInvoice.invoiceNumber).path
    );
  };

  if (!image) {
    return <Spinner open={true} />;
  }

  return (
    <ThirdPartyInvoiceForm
      type="Edit"
      setImageId={setImageId}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      thirdPartyInvoice={thirdPartyInvoice}
      initialValues={mockInitialValues}
      image={image}
    />
  );
};
