import React from "react";
import PageTitleRow from "~/page-title-row";
import Button from "~/button";
import "./ExpenseTitle.scss";
import Spinner from "~/spinner";
import { useDeleteExpense } from "~/visuals/pages/EditExpenseEntry/useDeleteExpense";
import { ExpenseFormType } from "../ExpenseForm/CommonTypes";

export type ExpenseTypeName =
  | "Expense Entry"
  | "ATV Expense"
  | "Fuel Expense"
  | "Mileage Expense"
  | "Mobile Allowance Expense"
  | "Per Diem Expense"
  | "Receipt Valued Expense"
  | "TCP Allowance Expense"
  | "AdHoc Expense";

type ExpenseTitleProps = {
  expenseId?: string;
  type: ExpenseFormType;
  typeName: ExpenseTypeName;
};

const ExpenseTitle: React.FC<ExpenseTitleProps> = ({
  expenseId,
  type,
  typeName,
}) => {
  const titleMap = {
    Create: `Create New ${typeName}`,
    Edit: `Edit ${typeName} ${expenseId ?? ""}`,
    Clone: `Clone ${typeName} ${expenseId ?? ""}`,
  };

  const { deleteLoading, deleteExpenseEntry } = useDeleteExpense();

  return (
    <div className="expense-title-delete-option">
      <Spinner open={deleteLoading} />
      <PageTitleRow title={titleMap[type]} />
      {type === "Edit" && expenseId && (
        <Button
          type="button"
          icon="delete"
          onClick={() => deleteExpenseEntry(expenseId)}
        />
      )}
    </div>
  );
};

export default ExpenseTitle;
