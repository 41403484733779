import React from "react";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import { LineItemInvoicePicker } from "~/visuals/organisms/LineItemInvoicePicker";
import Button from "~/button";
import { useSnackBar } from "~/snackbar";
import { useAddLineItemMutation } from "./addLineItem.generated";
import FormBottomRow from "~/form-bottom-row";
import { Invoice, ValidationResponse } from "~/gql/types";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";

type AddRollupInvoiceProps = {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  projectNumber: number;
  customRollupInvoiceNumber: string;
  refetch: React.DispatchWithoutAction;
};
type AddInvoiceFormProps = Omit<AddRollupInvoiceProps, "open">;

type AddInvoiceFormValues = {
  invoice: Invoice | null;
};
export const AddInvoice: React.FC<AddRollupInvoiceProps> = (props) => {
  const { open, setOpen, projectNumber, customRollupInvoiceNumber, refetch } =
    props;
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        icon="add"
        className="add-invoice-button"
      >
        Add Invoice
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="add-invoice"
      >
        <DialogTitle>Select Invoice</DialogTitle>
        <DialogContent>
          {open && (
            <AddInvoiceForm
              {...{
                setOpen,
                projectNumber,
                customRollupInvoiceNumber,
                refetch,
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const AddInvoiceForm: React.FC<AddInvoiceFormProps> = ({
  setOpen,
  projectNumber,
  customRollupInvoiceNumber,
  refetch,
}) => {
  const [doMutation] = useAddLineItemMutation();
  const addAlert = useSnackBar();
  const onSuccess = () => {
    const message = "Invoice line item added";
    addAlert({
      isSuccess: true,
      message,
      key: `${Math.random()}`,
    });
    setOpen(false);
    refetch();
  };
  const handleSubmit = async (values: AddInvoiceFormValues) => {
    const variables = {
      customRollupInvoiceNumber,
      invoiceNumber: values.invoice?.invoiceNumber!,
    };
    const result = await doMutation({ variables });
    return result?.data?.customRollupInvoices
      ?.addLineItem as ValidationResponse;
  };

  return (
    <MutationForm
      initialValues={{ invoice: null }}
      onSuccess={onSuccess}
      runMutation={handleSubmit}
    >
      <LineItemInvoicePicker
        {...{
          formField: "invoice",
          label: "",
          helperText: "Select an invoice",
          projectNumber,
        }}
      />
      <ErrorRenderer
        render={(formError) => (
          <FormBottomRow
            errorMessage={formError}
            buttonText="Save"
            onCancel={() => setOpen(false)}
          />
        )}
      />
    </MutationForm>
  );
};
