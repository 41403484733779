import { NotificationMenuProps } from "./type";
import { PopupMenuList, PopupMenuListItem } from "~/popup-menu-list";
import React from "react";
import moment from "moment";
import "./NotificationMenu.scss";

export const NotificationMenu: React.FC<NotificationMenuProps> = ({
  open,
  setOpen,
  markRead,
  notifications,
  refetch,
  pathMap,
  clearAll,
  count,
}) => {
  const onClick = async (id) => {
    await markRead(id);
    setOpen(false);
    void refetch();
  };

  const subjectTypeString = (subjectId: string) => ({
    ProjectRequestCreated: "Project Request Created",
    NewCustomer: "New Customer Approved",
    BaAssigned: `Assigned as BA on project ${subjectId}`,
    CustomerRequestRejected: "Requested Customer Rejected",
    ProjectRequestCreatedWithBadDebt: "New Project Request with Bad Debt",
    ThirdPartyInvoiceCreated: "New Third Party Invoice Created",
  });

  const items: PopupMenuListItem[] = notifications.map(
    ({ subjectType, createdOn, id, subjectId }) => ({
      label: `${subjectTypeString(subjectId)[subjectType]} ${moment
        .utc(createdOn, "YYYY-MM-DDTHH:mm:ssZ")
        .local()
        .fromNow()}`,
      onClick: () => onClick(id),
      key: id,
      dataItem: id,
      href: pathMap[subjectType](subjectId),
      className:
        subjectType == "ProjectRequestCreatedWithBadDebt" ? "bad-debt" : "",
    })
  );

  const clearAllClick = async () => {
    await clearAll();
    setOpen(false);
    void refetch();
  };

  const clearAllItem: PopupMenuListItem = {
    label: `CLEAR${count == 1 ? "" : " ALL"} ${count} NOTIFICATION${
      count == 1 ? "" : "S"
    }`,
    onClick: () => clearAllClick(),
    key: "clear-all",
    dataItem: "clear-all",
    className: "clear-all",
  };

  return (
    <div className="notification-menu">
      <PopupMenuList
        {...{
          visible: open,
          items: notifications.length > 0 ? [clearAllItem, ...items] : items,
        }}
        onMenuMouseEnter={() => undefined}
        onMenuMouseLeave={() => undefined}
        onClose={() => setOpen(false)}
        focusOnOpen={false}
        selectedIndex={0}
      />
    </div>
  );
};
