import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchCustomRollupInvoicesQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
  state?: Types.InputMaybe<Types.InvoiceFilterState>;
  token?: Types.InputMaybe<Types.Scalars['String']>;
  customerNumber?: Types.InputMaybe<Types.Scalars['String']>;
  amount?: Types.InputMaybe<Types.Scalars['Decimal']>;
  billingAdmin?: Types.InputMaybe<Types.Scalars['String']>;
  invoiceNumbers?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.CustomRollupInvoiceSortBy>;
  projectGroupArgs?: Types.InputMaybe<Types.ProjectGroupArgsInput>;
}>;


export type SearchCustomRollupInvoicesQuery = { __typename?: 'QueryModel', customRollupInvoices?: { __typename?: 'CustomRollupInvoiceQueryModel', search?: { __typename?: 'ConnectionOfCustomRollupInvoice', token?: string | null, records?: Array<{ __typename?: 'CustomRollupInvoice', invoiceNumber: string, state: Types.InvoiceState, total: number, invoiceDate?: string | null, cancelledDate?: string | null, projectGroup?: { __typename?: 'ProjectGroup', name: string, customer?: { __typename?: 'Customer', name: string, number?: string | null } | null, projects?: Array<{ __typename?: 'Project', name: string, number: number } | null> | null } | null, lineItems?: Array<{ __typename?: 'CustomRollupInvoiceLineItem', total: number, invoice?: { __typename?: 'Invoice', projectNumber: number } | null } | null> | null } | null> | null } | null } | null };


export const SearchCustomRollupInvoicesDocument = gql`
    query SearchCustomRollupInvoices($searchText: String, $state: InvoiceFilterState, $token: String, $customerNumber: String, $amount: Decimal, $billingAdmin: String, $invoiceNumbers: [String!], $sortBy: CustomRollupInvoiceSortBy, $projectGroupArgs: ProjectGroupArgsInput) {
  customRollupInvoices {
    search(
      searchText: $searchText
      state: $state
      token: $token
      customerNumber: $customerNumber
      amount: $amount
      billingAdmin: $billingAdmin
      invoiceNumbers: $invoiceNumbers
      sortBy: $sortBy
      projectGroupArgs: $projectGroupArgs
    ) {
      token
      records {
        invoiceNumber
        state
        total
        invoiceDate
        cancelledDate
        projectGroup {
          name
          customer {
            name
            number
          }
          projects {
            name
            number
          }
        }
        lineItems {
          total
          invoice {
            projectNumber
          }
        }
        invoiceDate
      }
    }
  }
}
    `;

/**
 * __useSearchCustomRollupInvoicesQuery__
 *
 * To run a query within a React component, call `useSearchCustomRollupInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCustomRollupInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCustomRollupInvoicesQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      state: // value for 'state'
 *      token: // value for 'token'
 *      customerNumber: // value for 'customerNumber'
 *      amount: // value for 'amount'
 *      billingAdmin: // value for 'billingAdmin'
 *      invoiceNumbers: // value for 'invoiceNumbers'
 *      sortBy: // value for 'sortBy'
 *      projectGroupArgs: // value for 'projectGroupArgs'
 *   },
 * });
 */
export function useSearchCustomRollupInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<SearchCustomRollupInvoicesQuery, SearchCustomRollupInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCustomRollupInvoicesQuery, SearchCustomRollupInvoicesQueryVariables>(SearchCustomRollupInvoicesDocument, options);
      }
export function useSearchCustomRollupInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCustomRollupInvoicesQuery, SearchCustomRollupInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCustomRollupInvoicesQuery, SearchCustomRollupInvoicesQueryVariables>(SearchCustomRollupInvoicesDocument, options);
        }
export type SearchCustomRollupInvoicesQueryHookResult = ReturnType<typeof useSearchCustomRollupInvoicesQuery>;
export type SearchCustomRollupInvoicesLazyQueryHookResult = ReturnType<typeof useSearchCustomRollupInvoicesLazyQuery>;
export type SearchCustomRollupInvoicesQueryResult = Apollo.QueryResult<SearchCustomRollupInvoicesQuery, SearchCustomRollupInvoicesQueryVariables>;