import React from "react";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import ClickSwallower from "~/click-swallower";
import moment, { Moment } from "moment";
import { useSnackBar } from "~/snackbar";
import FormBottomRow from "~/form-bottom-row";
import DatePickFormField from "../DatePickFormField";
import { useWorkDayApi } from "~/WorkDayApi";
import Spinner from "~/spinner";
import { AdminNotes } from "../AdminNotes";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import {
  useMoveExpenseToDateMutation,
  useMoveProjectTimeToDateMutation,
  useMoveTimeEntryToDateMutation,
  useMoveTimeOffToDateMutation,
} from "./moveToDate.generated";
import {
  useCopyExpenseToDateMutation,
  useCopyProjectTimeToDateMutation,
  useCopyTimeEntryToDateMutation,
  useCopyTimeOffToDateMutation,
} from "./copyToDate.generated";
import { ValidationResponse } from "~/gql/types";

type FormValues = {
  newDate: Moment;
  adminNotes: string | null;
};

export type ItemToDateProps = {
  open: boolean;
  onClosed: React.DispatchWithoutAction;
  oldDate: string;
  id: string;
  successFn?: () => void;
};

type ComponentProps = ItemToDateProps & {
  successMessage: (id: string) => string;
  loading: boolean;
  handleSubmit: (values: FormValues) => Promise<ValidationResponse>;
  label: string;
};

export const CopyProjectTimeToDate: React.FC<ItemToDateProps> = (props) => {
  const { id } = props;

  const [copyProjectTime, { loading }] = useCopyProjectTimeToDateMutation();

  const handleSubmit = async (values: FormValues) => {
    const variables = {
      newDate: values.newDate?.format("YYYY-MM-DD"),
      adminNotes: values.adminNotes || null,
      projectTimeId: id,
    };

    const result = await copyProjectTime({ variables });

    return result?.data?.timesheets
      ?.copyProjectTimeToDate as ValidationResponse;
  };

  return (
    <ItemToDate
      {...{
        ...props,
        successMessage: (id: string) => `Project time entry ${id} copied`,
        loading,
        handleSubmit,
        label: "Copy",
      }}
    />
  );
};

export const MoveProjectTimeToDate: React.FC<ItemToDateProps> = (props) => {
  const { id } = props;

  const [moveProjectTime, { loading }] = useMoveProjectTimeToDateMutation();

  const handleSubmit = async (values: FormValues) => {
    const variables = {
      newDate: values.newDate?.format("YYYY-MM-DD"),
      adminNotes: values.adminNotes || null,
      projectTimeId: id,
    };

    const result = await moveProjectTime({ variables });

    return result?.data?.timesheets
      ?.moveProjectTimeToDate as ValidationResponse;
  };

  return (
    <ItemToDate
      {...{
        ...props,
        successMessage: (id: string) => `Project time entry ${id} moved`,
        loading,
        handleSubmit,
        label: "Move",
      }}
    />
  );
};

export const CopyTimeEntryToDate: React.FC<ItemToDateProps> = (props) => {
  const { id } = props;

  const [copyTime, { loading }] = useCopyTimeEntryToDateMutation();

  const handleSubmit = async (values: FormValues) => {
    const variables = {
      newDate: values.newDate?.format("YYYY-MM-DD"),
      adminNotes: values.adminNotes || null,
      timeEntryId: id,
    };

    const result = await copyTime({ variables });

    return result?.data?.timesheets?.copyTimeEntryToDate as ValidationResponse;
  };

  return (
    <ItemToDate
      {...{
        ...props,
        successMessage: (id: string) => `Time entry ${id} copied`,
        loading,
        handleSubmit,
        label: "Copy",
      }}
    />
  );
};

export const MoveTimeEntryToDate: React.FC<ItemToDateProps> = (props) => {
  const { id } = props;

  const [moveTime, { loading }] = useMoveTimeEntryToDateMutation();

  const handleSubmit = async (values: FormValues) => {
    const variables = {
      newDate: values.newDate?.format("YYYY-MM-DD"),
      adminNotes: values.adminNotes || null,
      timeEntryId: id,
    };

    const result = await moveTime({ variables });

    return result?.data?.timesheets?.moveTimeEntryToDate as ValidationResponse;
  };

  return (
    <ItemToDate
      {...{
        ...props,
        successMessage: (id: string) => `Time entry ${id} moved`,
        loading,
        handleSubmit,
        label: "Move",
      }}
    />
  );
};

export const CopyTimeOffToDate: React.FC<ItemToDateProps> = (props) => {
  const { id } = props;

  const [copyTimeOff, { loading }] = useCopyTimeOffToDateMutation();

  const handleSubmit = async (values: FormValues) => {
    const variables = {
      newDate: values.newDate?.format("YYYY-MM-DD"),
      adminNotes: values.adminNotes || null,
      timeOffEntryId: id,
    };

    const result = await copyTimeOff({ variables });

    return result?.data?.timesheets?.copyTimeOffToDate as ValidationResponse;
  };

  return (
    <ItemToDate
      {...{
        ...props,
        successMessage: (id: string) => `Time off entry ${id} copied`,
        loading,
        handleSubmit,
        label: "Copy",
      }}
    />
  );
};

export const MoveTimeOffToDate: React.FC<ItemToDateProps> = (props) => {
  const { id } = props;

  const [moveTimeOff, { loading }] = useMoveTimeOffToDateMutation();

  const handleSubmit = async (values: FormValues) => {
    const variables = {
      newDate: values.newDate?.format("YYYY-MM-DD"),
      adminNotes: values.adminNotes || null,
      timeOffEntryId: id,
    };

    const result = await moveTimeOff({ variables });

    return result?.data?.timesheets?.moveTimeOffToDate as ValidationResponse;
  };

  return (
    <ItemToDate
      {...{
        ...props,
        successMessage: (id: string) => `Time off entry ${id} moved`,
        extractor: "moveTimeOffToDate",
        loading,
        handleSubmit,
        label: "Move",
      }}
    />
  );
};

export const CopyExpenseToDate: React.FC<ItemToDateProps> = (props) => {
  const { id } = props;

  const [copyExpense, { loading }] = useCopyExpenseToDateMutation();

  const handleSubmit = async (values: FormValues) => {
    const variables = {
      newDate: values.newDate?.format("YYYY-MM-DD"),
      adminNotes: values.adminNotes || null,
      expenseId: id,
    };

    const result = await copyExpense({ variables });

    return result?.data?.timesheets?.copyExpenseToDate as ValidationResponse;
  };

  return (
    <ItemToDate
      {...{
        ...props,
        successMessage: (id: string) => `Expense ${id} copied`,
        loading,
        handleSubmit,
        label: "Copy",
      }}
    />
  );
};

export const MoveExpenseToDate: React.FC<ItemToDateProps> = (props) => {
  const { id } = props;

  const [moveExpense, { loading }] = useMoveExpenseToDateMutation();

  const handleSubmit = async (values: FormValues) => {
    const variables = {
      newDate: values.newDate?.format("YYYY-MM-DD"),
      adminNotes: values.adminNotes || null,
      expenseId: id,
    };

    const result = await moveExpense({ variables });

    return result?.data?.timesheets?.moveExpenseToDate as ValidationResponse;
  };

  return (
    <ItemToDate
      {...{
        ...props,
        successMessage: (id: string) => `Expense ${id} moved`,
        loading,
        handleSubmit,
        label: "Move",
      }}
    />
  );
};

const ItemToDate: React.FC<ComponentProps> = (props) => {
  const {
    oldDate,
    open,
    onClosed,
    id,
    successMessage,
    loading,
    handleSubmit,
    successFn,
    label,
  } = props;

  const addAlert = useSnackBar();
  const { reload } = useWorkDayApi();

  const initialValues = {
    newDate: moment(oldDate),
    adminNotes: null,
  };

  const onSuccess = ({ values }) => {
    addAlert({
      key: `${id}`,
      message: `${successMessage(id)} to date ${values.newDate.format(
        "MM/DD/YYYY"
      )}`,
      isSuccess: true,
    });
    onClosed();
    if (reload) {
      void reload();
    }

    successFn && successFn();
  };

  return (
    <ClickSwallower>
      <Dialog
        className="move-item-to-date-dialog"
        open={open}
        onClose={onClosed}
        data-open={open}
        portalize
      >
        <DialogTitle>{label} To Date</DialogTitle>
        <DialogContent>
          <Spinner open={loading} />
          {open && (
            <MutationForm
              onSuccess={onSuccess}
              runMutation={handleSubmit}
              initialValues={initialValues}
            >
              <ErrorRenderer
                render={(error) => (
                  <>
                    <DatePickFormField
                      formField="newDate"
                      label="New Date"
                      helperText="Choose a date today or earlier."
                      maxDate={moment().toDate()}
                    />
                    <AdminNotes />
                    <FormBottomRow
                      errorMessage={error}
                      buttonText="Save"
                      onCancel={onClosed}
                    />
                  </>
                )}
              />
            </MutationForm>
          )}
        </DialogContent>
      </Dialog>
    </ClickSwallower>
  );
};
