import React from "react";
import {
  useRollupInvoice,
  useRollupInvoiceContextData,
} from "./RollupInvoiceContext";
import { InvoiceState, ValidationError } from "~/gql/types";
import { BillInvoice } from "~/bill-invoice";
import { useBillRollupInvoiceMutation } from "./billRollup.generated";

export const BillRollupInvoice: React.FC = () => {
  const rollupInvoice = useRollupInvoice();

  if (
    rollupInvoice.state !== InvoiceState.Draft ||
    !rollupInvoice.lineItems!.every(
      (x) => x!.invoice!.state === InvoiceState.Billed
    )
  ) {
    return null;
  }

  return <BillRollupComponent />;
};

const BillRollupComponent: React.FC = () => {
  const { rollupInvoice, reload } = useRollupInvoiceContextData();

  const [doMutation, { loading }] = useBillRollupInvoiceMutation();

  const bill = async () => {
    const result = await doMutation({
      variables: { invoiceNumber: rollupInvoice.invoiceNumber },
    });

    return result?.data?.customRollupInvoices?.bill
      ?.errors as ValidationError[];
  };

  return (
    <BillInvoice
      {...{
        bill,
        invoiceNumber: rollupInvoice.invoiceNumber,
        loading,
        type: "Custom Rollup Invoice",
        reload,
      }}
    />
  );
};
