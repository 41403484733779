import _ from "lodash";
import React from "react";
import { Body1, Body2 } from "@material/react-typography";
import "./InvoiceRebillInfo.scss";
import { Invoice } from "~/gql/types";
import { invoice as invoiceRoute } from "~/routes";

type InvoiceRebillInfoProps = {
  invoice: Invoice;
  textOnly?: boolean;
};

export const InvoiceRebillInfo: React.FC<InvoiceRebillInfoProps> = ({
  invoice,
  textOnly,
}) => {
  const rebilledFrom = invoice
    .lineItems!.filter((x) => !!x?.rebill?.rebilledFromInvoice)
    .map((x) => x!.rebill!.rebilledFromInvoice!);

  const rebilledOn = invoice
    .lineItems!.filter((x) => !!x?.rebill?.rebilledOnInvoice)
    .map((x) => x!.rebill!.rebilledOnInvoice!);

  if (!rebilledFrom.length && !rebilledOn.length) {
    return null;
  }

  const rebilledInfoItems = (items: string[]) =>
    Array.from(new Set(items)).map((x, idx) => (
      <Body2 className="invoice-link" key={idx}>
        {!textOnly && <a href={invoiceRoute.toRoute(x).path}>{x}</a>}
        {textOnly && x}
      </Body2>
    ));

  return (
    <>
      {rebilledFrom.length > 0 && (
        <div className="invoice-rebill-info">
          <Body1>{"Rebilled From:"}</Body1>
          {rebilledInfoItems(rebilledFrom)}
        </div>
      )}
      {rebilledOn.length > 0 && (
        <div className="invoice-rebill-info">
          <Body1>{"Rebilled On:"}</Body1>
          {rebilledInfoItems(rebilledOn)}
        </div>
      )}
    </>
  );
};
