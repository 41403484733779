import React, { useEffect } from "react";
import ExpenseMruFormField from "~/visuals/organisms/ExpenseMruFormField";
import { IExpenseRate } from "~/gql/types";
import { ExpenseFormType } from "./CommonTypes";
import { useForm, useFormState } from "react-final-form";
import TextFormField from "~/text-form-field";

type ExpensePickerFieldProps = {
  projectNumber: number | null;
  expenseRate: IExpenseRate | null;
  expenseRates: IExpenseRate[] | null;
  type: ExpenseFormType;
  rateSheetName?: string;
};

export const ExpensePickerField: React.FC<ExpensePickerFieldProps> = (
  props
) => {
  const { projectNumber, expenseRate, type, expenseRates, rateSheetName } =
    props;

  const expenseHelperText =
    type === "Edit" && !expenseRate
      ? "The rate sheet has changed since this expense was last edited. Please select an expense rate."
      : "";

  const formApi = useForm();
  const formState = useFormState();

  const expenseValue = formState.values.expense;
  const project = formState.values.project;

  const clearExpense = () => formApi.change("expense", null);

  useEffect(() => {
    if (
      project &&
      expenseRates &&
      !expenseRates?.find((x) => x.name === expenseValue?.name)
    ) {
      clearExpense();
    }
  }, [expenseRates]);

  if (!projectNumber || !rateSheetName) {
    return (
      <TextFormField
        formField={"needs-project"}
        label={"Expense - Select a project"}
        helperText={expenseHelperText}
        disabled={true}
      />
    );
  }

  return (
    <ExpenseMruFormField
      formField="expense"
      label={"Expense"}
      helperText={expenseHelperText}
      expenseRates={(expenseRates ?? []) as IExpenseRate[]}
      required
      disabled={!projectNumber}
      rateSheetName={rateSheetName}
    />
  );
};
