import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEffectiveTaskRatesQueryVariables = Types.Exact<{
  asOf: Types.Scalars['LocalDate'];
  projectNumber: Types.Scalars['Int'];
}>;


export type GetEffectiveTaskRatesQuery = { __typename?: 'QueryModel', projects?: { __typename?: 'ProjectsQueryModel', effectiveTaskRateSheet?: { __typename?: 'TaskRateSheet', name: string, rates?: Array<{ __typename?: 'TaskRate', name: string, billableAmount?: number | null, unit: Types.TaskRateUnit, usesEmployeeRate: boolean, glAccount?: string | null } | null> | null } | null } | null };


export const GetEffectiveTaskRatesDocument = gql`
    query GetEffectiveTaskRates($asOf: LocalDate!, $projectNumber: Int!) {
  projects {
    effectiveTaskRateSheet(asOf: $asOf, projectNumber: $projectNumber) {
      name
      rates {
        name
        billableAmount
        unit
        usesEmployeeRate
        glAccount
      }
    }
  }
}
    `;

/**
 * __useGetEffectiveTaskRatesQuery__
 *
 * To run a query within a React component, call `useGetEffectiveTaskRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEffectiveTaskRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEffectiveTaskRatesQuery({
 *   variables: {
 *      asOf: // value for 'asOf'
 *      projectNumber: // value for 'projectNumber'
 *   },
 * });
 */
export function useGetEffectiveTaskRatesQuery(baseOptions: Apollo.QueryHookOptions<GetEffectiveTaskRatesQuery, GetEffectiveTaskRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEffectiveTaskRatesQuery, GetEffectiveTaskRatesQueryVariables>(GetEffectiveTaskRatesDocument, options);
      }
export function useGetEffectiveTaskRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEffectiveTaskRatesQuery, GetEffectiveTaskRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEffectiveTaskRatesQuery, GetEffectiveTaskRatesQueryVariables>(GetEffectiveTaskRatesDocument, options);
        }
export type GetEffectiveTaskRatesQueryHookResult = ReturnType<typeof useGetEffectiveTaskRatesQuery>;
export type GetEffectiveTaskRatesLazyQueryHookResult = ReturnType<typeof useGetEffectiveTaskRatesLazyQuery>;
export type GetEffectiveTaskRatesQueryResult = Apollo.QueryResult<GetEffectiveTaskRatesQuery, GetEffectiveTaskRatesQueryVariables>;