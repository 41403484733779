import React from "react";
import { ExpenseRateSheet } from "~/gql/types";
import { AssignSuccessorRateSheet } from "~/visuals/organisms/AssignSuccessor";

type AssignSuccessorExpenseRateSheetProps = {
  useReload(): React.DispatchWithoutAction;
  rateSheet: ExpenseRateSheet;
};

export const AssignSuccessorExpenseRateSheet: React.FC<
  AssignSuccessorExpenseRateSheetProps
> = (props) => (
  <AssignSuccessorRateSheet {...{ ...props, type: "expenseRateSheet" }} />
);
