import { Employee, ProjectTimeDaily } from "./gql/types";
import { employees } from "./mockEmployees";

export const mockDaily = {
  atv: false,
  description: "daily description",
  hours: [
    {
      asBuilt: "00:00:00",
      boundary: "04:00:00",
      clStake: "04:00:00",
      constRestake: "00:00:00",
      constStake: "00:00:00",
      employee: employees[1] as Employee,
      employeeId: 789,
      locate: "00:00:00",
      rainoutOther: "00:00:00",
      rerouteStake: "00:00:00",
      role: "FieldSupervisor",
      travelHours: "00:00:00",
    },
    {
      asBuilt: "00:00:00",
      boundary: "01:00:00",
      clStake: "02:45:00",
      constRestake: "00:00:00",
      constStake: "00:00:00",
      employee: employees[4] as Employee,
      employeeId: 789,
      locate: "00:00:00",
      rainoutOther: "00:00:00",
      rerouteStake: "00:00:00",
      role: "LeadPartyChief",
      travelHours: "00:00:00",
    },
  ],
  mileage: 100,
  notes: "daily notes",
  pdfDownloadUrl: "https://example.com",
  perDiemMA: false,
  totalClStaked: 100,
  vehicle: "abc123",
} as ProjectTimeDaily;
