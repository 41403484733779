import React, { forwardRef, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import MdcTextField from "@material/react-text-field";
import HelperTextLine from "~/HelperTextLine";
import { Input } from "@material/react-text-field";
import { useField } from "react-final-form";
import "./DatePickFormField.scss";
import moment, { Moment } from "moment";
import cn from "classnames";

type DatePickerProps = {
  formField: string;
  label: string;
  helperText: string;
  required?: boolean;
  disabled?: boolean;
  maxDate?: Date;
};

const DatePickFormField: React.FC<DatePickerProps> = (props) => {
  const {
    formField,
    label,
    helperText,
    required = false,
    maxDate,
    disabled = false,
  } = props;

  const checkRequired = (value: Moment) =>
    value || !required || disabled
      ? undefined
      : `Please provide a valid ${label}`;
  const {
    meta: { invalid, error, submitError, touched },
    input: { value, onChange, onBlur },
  } = useField(formField, { validate: checkRequired });

  const [selected, setSelected] = useState<Date | null>(
    value ? value.toDate() : null
  );

  const hasError = (error || invalid || submitError) && touched;

  type InputProps = React.HTMLProps<HTMLInputElement>;

  useEffect(() => {
    setSelected(value ? value.toDate() : null);
  }, [value]);

  const CustomInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const fieldClass = cn({
      "mdc-text-field--invalid": hasError,
      "has-value": !!value,
    });

    return (
      <MdcTextField
        className={fieldClass}
        helperText={
          <HelperTextLine
            message={hasError ? error || submitError : helperText}
            isValid={!hasError}
          />
        }
        label={label + `${required ? " *" : ""}`}
        onBlur={(evt) => onBlur(evt)}
      >
        <Input
          {...props}
          onChange={props.onChange}
          disabled={disabled}
          ref={() => ref}
        />
      </MdcTextField>
    );
  });

  CustomInput.displayName = "Custom Input";

  const onInputChange = (val: any, evt: any) => {
    if (val && evt) {
      handleEvent(val, evt);
    } else {
      setSelected(null);
      onChange(null);
    }
  };

  const handleEvent = (val: any, evt: any) => {
    if (evt.type === "click" && val instanceof Date) {
      setSelected(val);
      onChange(moment(val));
    } else if (evt.type === "change") {
      handleChange(evt);
    }
  };

  const handleChange = (evt: any) => {
    const target = evt.target as HTMLInputElement;
    const value = target.value as string;
    const parsed = moment(value, "MM/DD/YYYY");
    if (isValidValue(value, parsed)) {
      setSelected(parsed.toDate());
      onChange(parsed);
    }
  };

  const isValidValue = (value: any, parsed: Moment) =>
    value && value.length === 10 && parsed.isValid();

  return (
    <section className="form-field datepicker">
      <DatePicker
        id={formField}
        key={formField}
        name={formField}
        autoComplete="off"
        customInput={<CustomInput />}
        dateFormat="MM/dd/yyyy"
        onChange={onInputChange}
        selected={selected}
        disabled={disabled}
        todayButton="Today"
        maxDate={maxDate ?? null}
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "0px, 12px",
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport",
          },
        }}
      />
    </section>
  );
};

export default DatePickFormField;
