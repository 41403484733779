import React from "react";
import { MyPendingProjectRequests } from "./MyPendingRequests";
import { RoleStats } from "./RoleStats";
import "./HomePage.scss";
import { MyWorkCard } from "./MyWorkCard";
import { PayrollAdminInfo } from "./PayrollAdminInfo";
import { ThirdPartyInvoiceUnreviewedCount } from "./ThirdPartyInvoiceUnreviewed";

export const HomePage: React.FC = () => {
  return (
    <div className="home-page">
      <div>
        <RoleStats />
        <MyPendingProjectRequests />
        <ThirdPartyInvoiceUnreviewedCount />
        <PayrollAdminInfo />
      </div>
      <div>
        <MyWorkCard />
      </div>
    </div>
  );
};
