import React from "react";
import TableCell from "~/old-table-cell";
import TableContent from "~/old-table-content";
import TableHeader from "~/old-table-header";
import Table from "~/old-table";
import Button from "~/button";
import { Duration } from "moment";
import { useNavigate } from "react-router-dom";
import { useWorkDayApi } from "~/WorkDayApi";
import { WorkdayCard } from "../WorkdayCard";
import "./TimeOffCard.scss";
import { WorkDayItemActions } from "../WorkDayItemActions";
import { NotesDisplay } from "~/notes-display";
import { CopyTimeOffToDates } from "../CopyToDates";
import { CopyTimeOffToDate, MoveTimeOffToDate } from "../ItemToDate";

export type TimeOffRow = {
  hours: Duration;
  crewCode: string;
  type: string;
  id: string;
  date: string;
  lastAdminNotes: string | null;
};

export type TimeOffCardProps = {
  timeOff: TimeOffRow[];
  admin: boolean;
  editable: boolean;
};

export const TimeOffCard: React.FC<TimeOffCardProps> = ({
  timeOff,
  admin,
  editable,
}) => {
  const navigate = useNavigate();
  const { deleteTimeOffEntry } = useWorkDayApi();

  const actions = (
    <>
      {editable && (
        <Button onClick={() => navigate(createTimeOffEntry)}>
          Add time off
        </Button>
      )}
    </>
  );

  return (
    <WorkdayCard
      title={`${!admin ? "My " : ""}Time Off`}
      className="time-off-card"
      actions={actions}
      editable={editable}
      placeholderText="(no time off entries)"
      data={timeOff}
    >
      <Table>
        <TableHeader>
          <TableCell text="Crew Code" />
          <TableCell text="Hours" />
          <TableCell text="Type" />
          <TableCell text="Notes" />
          {editable && <TableCell text="Action" />}
        </TableHeader>
        {timeOff.map((timeEntry, idx) => (
          <TableContent trailingSeparator={false} key={idx}>
            <TableCell text={timeEntry.crewCode} />
            <TableCell text={`${timeEntry.hours.asHours().toFixed(2)}`} />
            <TableCell text={timeEntry.type} />
            <TableCell>
              <NotesDisplay
                {...{ notes: "", adminNotes: timeEntry.lastAdminNotes }}
              />
            </TableCell>
            <WorkDayItemActions
              {...{
                editable,
                onDelete: () =>
                  deleteTimeOffEntry({ timeOffEntryId: timeEntry.id }),
                onEdit: () => navigate(`${editTimeOffEntry}/${timeEntry.id}`),
                buttonsClassName: "time-off-actions-buttons",
                className: "time-off-actions",
                id: timeEntry.id,
                date: timeEntry.date,
                CopyToDate: CopyTimeOffToDate,
                MoveToDate: MoveTimeOffToDate,
                CopyToDates: CopyTimeOffToDates,
              }}
            />
          </TableContent>
        ))}
      </Table>
    </WorkdayCard>
  );
};

export const createTimeOffEntry = "create-time-off-entry";
export const editTimeOffEntry = "edit-time-off-entry";
