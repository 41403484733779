import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetExpenseRateSheetsQueryVariables = Types.Exact<{
  effectiveDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
}>;


export type GetExpenseRateSheetsQuery = { __typename?: 'QueryModel', expenseRates?: { __typename?: 'ExpenseRatesQueryModel', rateSheets?: Array<{ __typename?: 'ExpenseRateSheet', name: string } | null> | null } | null };


export const GetExpenseRateSheetsDocument = gql`
    query GetExpenseRateSheets($effectiveDate: LocalDate) {
  expenseRates {
    rateSheets(allowAssignment: true, effectiveDate: $effectiveDate) {
      name
    }
  }
}
    `;

/**
 * __useGetExpenseRateSheetsQuery__
 *
 * To run a query within a React component, call `useGetExpenseRateSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseRateSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseRateSheetsQuery({
 *   variables: {
 *      effectiveDate: // value for 'effectiveDate'
 *   },
 * });
 */
export function useGetExpenseRateSheetsQuery(baseOptions?: Apollo.QueryHookOptions<GetExpenseRateSheetsQuery, GetExpenseRateSheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExpenseRateSheetsQuery, GetExpenseRateSheetsQueryVariables>(GetExpenseRateSheetsDocument, options);
      }
export function useGetExpenseRateSheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExpenseRateSheetsQuery, GetExpenseRateSheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExpenseRateSheetsQuery, GetExpenseRateSheetsQueryVariables>(GetExpenseRateSheetsDocument, options);
        }
export type GetExpenseRateSheetsQueryHookResult = ReturnType<typeof useGetExpenseRateSheetsQuery>;
export type GetExpenseRateSheetsLazyQueryHookResult = ReturnType<typeof useGetExpenseRateSheetsLazyQuery>;
export type GetExpenseRateSheetsQueryResult = Apollo.QueryResult<GetExpenseRateSheetsQuery, GetExpenseRateSheetsQueryVariables>;