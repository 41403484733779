import React, { ReactElement, useState } from "react";
import {
  CustomerFormProps,
  EditCustomerFormValues,
  NewCustomerFormValues,
} from "./types";
import { NewCustomerForm } from "./NewCustomerForm";
import { EditCustomerForm } from "./EditCustomerForm";

export default function CustomerForm({
  initialValues,
  customer,
}: CustomerFormProps): ReactElement {
  const [isInternational, setIsInternational] = useState(false);

  const defaultProps = {
    isInternational,
    setIsInternational,
  };

  if (customer) {
    return (
      <EditCustomerForm
        {...{
          ...defaultProps,
          initialValues: initialValues as EditCustomerFormValues,
          customer,
        }}
      />
    );
  }

  return (
    <NewCustomerForm
      {...{
        ...defaultProps,
        initialValues: initialValues as NewCustomerFormValues,
      }}
    />
  );
}
