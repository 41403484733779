const allListeners: Record<string, FakeBroadcastChannel[]> = {};

type FakeMessageEvent = {
  data: any;
};

export class FakeBroadcastChannel {
  private channelName: string;
  onmessage: React.Dispatch<FakeMessageEvent> | null;

  constructor(channelName: string) {
    let listeners = allListeners[channelName];
    if (!listeners) {
      listeners = [];
      allListeners[channelName] = listeners;
    }

    listeners.push(this);
    this.onmessage = null;
    this.channelName = channelName;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  postMessage(message: any): void {
    const channelListeners = allListeners[this.channelName] || [];

    const evt: FakeMessageEvent = {
      data: message,
    };

    for (const listener of channelListeners) {
      if (listener.onmessage) {
        listener.onmessage(evt);
      }
    }
  }

  close(): void {
    const listeners = allListeners[this.channelName];

    const myIndex = listeners.indexOf(this);

    listeners.splice(myIndex, 1);
  }
}
