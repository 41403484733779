import { ApolloClient } from "@apollo/client";
import { CrewCodeRecord } from "~/gql/types";
import createRefdataApi from "../createRefdataApi";
import {
  GetAllCrewCodesDocument,
  GetAllCrewCodesQuery,
} from "./query.generated";

async function crewCodesFetcher(client: ApolloClient<unknown>) {
  const result = await client.query<GetAllCrewCodesQuery>({
    query: GetAllCrewCodesDocument,
  });

  return result?.data?.allCrewCodes as CrewCodeRecord[];
}

const api = createRefdataApi(crewCodesFetcher, "crewCode");

export const CrewCodeRefdataContext = api.Provider;
export const useCrewCodesAsync = api.useDataAsync;
export const useCrewCodes = api.useData;
export const useInvalidateCrewCodes = api.useInvalidate;

export function useCrewCodesFromParam(): (
  name: string
) => Promise<CrewCodeRecord | null> {
  const getCrewCodes = useCrewCodesAsync();

  return async (code: string) => {
    const result = await getCrewCodes();
    const crewCode = result?.find((x) => x.crewCode === code);

    return crewCode ?? null;
  };
}
