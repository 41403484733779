import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkProjectDraftInvoicesQueryVariables = Types.Exact<{
  projectNumbers: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type BulkProjectDraftInvoicesQuery = { __typename?: 'QueryModel', invoices?: { __typename?: 'InvoicesQueryModel', bulkDraftInvoices?: Array<{ __typename?: 'Invoice', invoiceNumber: string, total: number, projectNumber: number, lineItems?: Array<{ __typename?: 'InvoiceLineItem', label: string } | null> | null } | null> | null } | null };


export const BulkProjectDraftInvoicesDocument = gql`
    query BulkProjectDraftInvoices($projectNumbers: [Int!]!) {
  invoices {
    bulkDraftInvoices(projectNumbers: $projectNumbers) {
      invoiceNumber
      lineItems {
        label
      }
      total
      projectNumber
    }
  }
}
    `;

/**
 * __useBulkProjectDraftInvoicesQuery__
 *
 * To run a query within a React component, call `useBulkProjectDraftInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkProjectDraftInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkProjectDraftInvoicesQuery({
 *   variables: {
 *      projectNumbers: // value for 'projectNumbers'
 *   },
 * });
 */
export function useBulkProjectDraftInvoicesQuery(baseOptions: Apollo.QueryHookOptions<BulkProjectDraftInvoicesQuery, BulkProjectDraftInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkProjectDraftInvoicesQuery, BulkProjectDraftInvoicesQueryVariables>(BulkProjectDraftInvoicesDocument, options);
      }
export function useBulkProjectDraftInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkProjectDraftInvoicesQuery, BulkProjectDraftInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkProjectDraftInvoicesQuery, BulkProjectDraftInvoicesQueryVariables>(BulkProjectDraftInvoicesDocument, options);
        }
export type BulkProjectDraftInvoicesQueryHookResult = ReturnType<typeof useBulkProjectDraftInvoicesQuery>;
export type BulkProjectDraftInvoicesLazyQueryHookResult = ReturnType<typeof useBulkProjectDraftInvoicesLazyQuery>;
export type BulkProjectDraftInvoicesQueryResult = Apollo.QueryResult<BulkProjectDraftInvoicesQuery, BulkProjectDraftInvoicesQueryVariables>;