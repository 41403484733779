import { ApolloClient } from "@apollo/client";
import _ from "lodash";
import StateCounty from "~/graphql/StateCounty";
import createRefdataApi from "../createRefdataApi";
import { StateCountiesQuery, StateCountiesDocument } from "./query.generated";

async function stateCountyFetcher(client: ApolloClient<any>) {
  const result = await client.query<StateCountiesQuery>({
    query: StateCountiesDocument,
  });

  return result.data.stateCounties as StateCounty[];
}

const api = createRefdataApi(stateCountyFetcher, "state county");

export const StateCountyRefdataContext = api.Provider;
export const useStateCountiesAsync = api.useDataAsync;
export const useStateCounties = api.useData;
export const useInvalidateStateCounties = api.useInvalidate;

export function useLocationStateFromParam(): (
  name: string
) => Promise<string | null> {
  const getLocations = useStateCountiesAsync();

  return async (name: string) => {
    const result =
      _.uniqBy((await getLocations()) ?? [], "state")?.map((x) => x.state) ??
      [];
    const location = result?.find((x) => x === name);

    return location ?? null;
  };
}

export function useStateCountyFromParam(): (
  stateCounty: string
) => Promise<StateCounty | null> {
  const getLocations = useStateCountiesAsync();

  return async (stateCounty: string) => {
    const state = stateCounty.split("-")[0];
    const county = stateCounty.split("-")[1];

    const result = await getLocations();
    const location =
      result?.find((x) => x.state === state && x.county === county) ?? null;

    return location;
  };
}
