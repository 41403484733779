import React, { useState } from "react";
import { ReviewItemsContext } from "./ReviewItemsContext";

export const ReviewItemsProvider: React.FC = ({ children }) => {
  const [selectedItems, setSelectedItems] = useState<unknown[]>([]);
  return (
    <ReviewItemsContext.Provider value={{ selectedItems, setSelectedItems }}>
      {children}
    </ReviewItemsContext.Provider>
  );
};

export const MockReviewItemsProvider: React.FC<{ selected?: unknown[] }> = ({
  children,
  selected,
}) => {
  const [selectedItems, setSelectedItems] = useState<unknown[]>(selected || []);
  return (
    <ReviewItemsContext.Provider value={{ selectedItems, setSelectedItems }}>
      {children}
    </ReviewItemsContext.Provider>
  );
};
