import React from "react";
import { FilterForm } from "./FilterForm";
import { Route, Routes } from "react-router-dom";
import { SearchRestoreRouter } from "~/search-restorer";
import { ProjectsPage } from "./ProjectsPage";
import ProjectRouter from "~/visuals/pages/Project/ProjectRouter";
import { ProjectsReview } from "./ProjectsReview";
import { CreateProjectFormPage } from "~/ProjectForm";
import { ReviewItemsProvider } from "~/review-items";

export const ProjectsRouter: React.FC = () => {
  return (
    <ReviewItemsProvider>
      <SearchRestoreRouter
        searchPage={<ProjectsPage FilterFormComponent={FilterForm} />}
        nestedPages={
          <Routes>
            <Route path="review/*">
              <Route path=":projectNumber" element={<ProjectsReview />} />
            </Route>
            <Route path="create" element={<CreateProjectFormPage />} />
            <Route path=":projectNumber/*" element={<ProjectRouter />} />
          </Routes>
        }
      />
    </ReviewItemsProvider>
  );
};
