import { useEffect, useState } from "react";
import useIsMounted from "./useIsMounted";

export type AsyncEffectState = {
  loading: boolean;
};

export type CheckCancelFn = () => boolean;

export type EffectFn = (cancel: CheckCancelFn) => Promise<unknown>;

export default function useAsyncEffect(
  effect: EffectFn,
  dependencies: any[],
  exceptionHandler?: (ex: any) => void
): AsyncEffectState {
  const [loading, setLoading] = useState(true);
  const isMounted = useIsMounted();

  const effectRunner = async (cancel: CheckCancelFn) => {
    try {
      await effect(cancel);
    } catch (error) {
      if (exceptionHandler) {
        exceptionHandler(error);
      } else {
        throw error;
      }
    } finally {
      if (isMounted() && !cancel()) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    let canceled = false;

    setLoading(true);
    void effectRunner(() => canceled);

    return () => {
      canceled = true;
    };
  }, dependencies);

  return {
    loading,
  };
}
