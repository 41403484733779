type TaggedState<T extends string> = { tag: T };

export type Authenticated = TaggedState<"Authenticated">;
export type InactivePrompt = TaggedState<"InactivePrompt">;
export type IframeOpen = TaggedState<"IframeOpen">;
export type HavingProblem = TaggedState<"HavingProblem">;
export type BigPopup = TaggedState<"BigPopup">;

export type State =
  | Authenticated
  | InactivePrompt
  | IframeOpen
  | HavingProblem
  | BigPopup;

type TaggedAction<T extends string> = { tag: T };

type ActiveNearExpire = TaggedAction<"ActiveNearExpire">;
type InactiveNearExpire = TaggedAction<"InactiveNearExpire">;
type TokenSuccess = TaggedAction<"TokenSuccess">;
type Timeout = TaggedAction<"Timeout">;
type ConfirmPrompt = TaggedAction<"ConfirmPrompt">;

export type Action =
  | ActiveNearExpire
  | InactiveNearExpire
  | TokenSuccess
  | Timeout
  | ConfirmPrompt;

function reduceAuthenticated(state: Authenticated, action: Action): State {
  if (action.tag === "InactiveNearExpire") {
    return { tag: "InactivePrompt" };
  }
  if (action.tag === "ActiveNearExpire") {
    return { tag: "IframeOpen" };
  }

  return state;
}

function reduceContinueWorking(state: InactivePrompt, action: Action): State {
  if (action.tag === "ConfirmPrompt") {
    return { tag: "IframeOpen" };
  }

  return state;
}

function reduceRefreshIframe(state: IframeOpen, action: Action): State {
  if (action.tag === "TokenSuccess") {
    return { tag: "Authenticated" };
  }
  if (action.tag === "Timeout") {
    return { tag: "HavingProblem" };
  }

  return state;
}

function reduceHavingProblem(state: HavingProblem, action: Action): State {
  if (action.tag === "ConfirmPrompt") {
    return { tag: "BigPopup" };
  }

  return state;
}

function reduceBigPopup(state: BigPopup, action: Action): State {
  if (action.tag === "TokenSuccess") {
    return { tag: "Authenticated" };
  }

  return state;
}

export function reducer(state: State, action: Action): State {
  if (state.tag === "Authenticated") {
    return reduceAuthenticated(state, action);
  }
  if (state.tag === "InactivePrompt") {
    return reduceContinueWorking(state, action);
  }
  if (state.tag === "IframeOpen") {
    return reduceRefreshIframe(state, action);
  }
  if (state.tag === "HavingProblem") {
    return reduceHavingProblem(state, action);
  }
  if (state.tag === "BigPopup") {
    return reduceBigPopup(state, action);
  }

  return state;
}
