import React from "react";
import Button from "~/button";
import "./manage-ref-data.scss";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";
import { Body1 } from "@material/react-typography";
import Card from "@material/react-card";

type ManagRefDataPageProps = {
  title: string;
  onAddNewClick: () => void;
  loading: boolean;
};

export const ManageRefDataPage: React.FC<ManagRefDataPageProps> = (props) => {
  const { title, onAddNewClick, loading, children } = props;

  return (
    <SearchPageWrapper withFilters={false} className="manage-ref-data">
      <SearchListContent
        loading={loading}
        actions={
          <div className="top-items">
            <Body1>{title}</Body1>
            <Button primary onClick={onAddNewClick}>
              Add New
            </Button>
          </div>
        }
      >
        <Card>{children}</Card>
      </SearchListContent>
    </SearchPageWrapper>
  );
};
