import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import { ProjectRefData } from "~/refdata/sources";
import {
  useCorpLocationFromParam,
  useCorpLocationsAsync,
} from "~/refdata2/corpLocations";
import CorpLocationPickerFormField from "~/visuals/organisms/CorpLocationPickerFormField";
import { createProvider } from "./createFilterProvider";

export function useCorpLocationProvider(
  props: FilterProviderProps
): FilterProvider {
  const locationsFromParam = useCorpLocationFromParam();
  const getCorpLocations = useCorpLocationsAsync();

  const type = "corpLocation";
  const label = "Location";

  return createProvider({
    render: () => (
      <CorpLocationPickerFormField
        formField={type}
        label={label}
        helperText=""
      />
    ),
    type,
    label,
    props,
    getItems: getCorpLocations,
    itemFromParam: locationsFromParam,
    shouldSuggestText: "loc",
    toSuggestions: (result, text) =>
      (result as ProjectRefData[])
        .filter(
          (l) =>
            l.name.toLowerCase().includes(text.toLowerCase()) ||
            l.id.toLowerCase().startsWith(text.toLowerCase())
        )
        .map((l) => ({ text: l.name, value: l })),
    toLabelValue: (value: ProjectRefData) => value.name,
    toParamValue: (value: ProjectRefData) => value.name,
  });
}
