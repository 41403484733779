import React, { useEffect } from "react";
import TextFormField from "~/text-form-field";
import {
  MobileAllowanceExpenseRate,
  PerDiemExpenseRate,
  RateUnit,
} from "~/gql/types";
import { useChargeFormField } from "./fieldUtils";

export const QuantityField: React.FC = () => {
  const date = useChargeFormField("date").value;
  const project = useChargeFormField("project").value;
  const { value: rateValue, initial: initialRate } = useChargeFormField("rate");
  const { value: sheetValue, initial: initialSheet } =
    useChargeFormField("rateSheet");

  const { onChange } = useChargeFormField("quantity");

  useEffect(() => {
    const unchanged = rateValue === initialRate && sheetValue === initialSheet;
    const hasQuantity =
      rateValue?.__typename === "PerDiemExpenseRate" ||
      rateValue?.__typename === "MobileAllowanceExpenseRate";

    if (hasQuantity && !unchanged && rateValue) {
      onChange(
        `${
          (rateValue as PerDiemExpenseRate | MobileAllowanceExpenseRate)
            .quantity
        }`
      );
    }

    if (
      initialRate?.unit === RateUnit.Hourly &&
      rateValue &&
      rateValue?.unit !== RateUnit.Hourly
    ) {
      onChange(null);
    }
  }, [rateValue]);

  const disabled = !date || !project;

  return (
    <TextFormField
      label="Quantity"
      helperText=""
      formField="quantity"
      type="number"
      required
      disabled={disabled}
    />
  );
};
