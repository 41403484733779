import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetExpenseRatesForChargeFormQueryVariables = Types.Exact<{
  asOf: Types.Scalars['LocalDate'];
  projectNumber: Types.Scalars['Int'];
}>;


export type GetExpenseRatesForChargeFormQuery = { __typename?: 'QueryModel', projects?: { __typename?: 'ProjectsQueryModel', effectiveExpenseRateSheet?: { __typename?: 'ExpenseRateSheet', name: string, expenseRates?: Array<{ __typename: 'AdHocExpenseRate', name: string, unit?: Types.RateUnit | null, invoiceLabel?: string | null, glAccount?: string | null } | { __typename: 'AtvExpenseRate', billableAmount: number, name: string, unit?: Types.RateUnit | null, invoiceLabel?: string | null, glAccount?: string | null } | { __typename: 'FuelExpenseRate', billable: boolean, name: string, unit?: Types.RateUnit | null, invoiceLabel?: string | null, glAccount?: string | null } | { __typename: 'MileageExpenseRate', billableAmountPerMile: number, name: string, unit?: Types.RateUnit | null, invoiceLabel?: string | null, glAccount?: string | null } | { __typename: 'MobileAllowanceExpenseRate', billableAmount: number, quantity: number, name: string, unit?: Types.RateUnit | null, invoiceLabel?: string | null, glAccount?: string | null } | { __typename: 'PerDiemExpenseRate', kind: Types.PerDiemKind, billableOverride?: number | null, name: string, unit?: Types.RateUnit | null, invoiceLabel?: string | null, glAccount?: string | null } | { __typename: 'ReceiptValuedExpenseRate', billable: boolean, name: string, unit?: Types.RateUnit | null, invoiceLabel?: string | null, glAccount?: string | null } | { __typename: 'TcpAllowanceExpenseRate', name: string, unit?: Types.RateUnit | null, invoiceLabel?: string | null, glAccount?: string | null } | null> | null } | null } | null };


export const GetExpenseRatesForChargeFormDocument = gql`
    query GetExpenseRatesForChargeForm($asOf: LocalDate!, $projectNumber: Int!) {
  projects {
    effectiveExpenseRateSheet(asOf: $asOf, projectNumber: $projectNumber) {
      name
      expenseRates {
        name
        unit
        invoiceLabel
        glAccount
        __typename
        ... on ReceiptValuedExpenseRate {
          billable
        }
        ... on FuelExpenseRate {
          billable
        }
        ... on PerDiemExpenseRate {
          kind
          billableOverride
        }
        ... on MobileAllowanceExpenseRate {
          billableAmount
          quantity
        }
        ... on MileageExpenseRate {
          billableAmountPerMile
        }
        ... on AtvExpenseRate {
          billableAmount
        }
      }
    }
  }
}
    `;

/**
 * __useGetExpenseRatesForChargeFormQuery__
 *
 * To run a query within a React component, call `useGetExpenseRatesForChargeFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseRatesForChargeFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseRatesForChargeFormQuery({
 *   variables: {
 *      asOf: // value for 'asOf'
 *      projectNumber: // value for 'projectNumber'
 *   },
 * });
 */
export function useGetExpenseRatesForChargeFormQuery(baseOptions: Apollo.QueryHookOptions<GetExpenseRatesForChargeFormQuery, GetExpenseRatesForChargeFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExpenseRatesForChargeFormQuery, GetExpenseRatesForChargeFormQueryVariables>(GetExpenseRatesForChargeFormDocument, options);
      }
export function useGetExpenseRatesForChargeFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExpenseRatesForChargeFormQuery, GetExpenseRatesForChargeFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExpenseRatesForChargeFormQuery, GetExpenseRatesForChargeFormQueryVariables>(GetExpenseRatesForChargeFormDocument, options);
        }
export type GetExpenseRatesForChargeFormQueryHookResult = ReturnType<typeof useGetExpenseRatesForChargeFormQuery>;
export type GetExpenseRatesForChargeFormLazyQueryHookResult = ReturnType<typeof useGetExpenseRatesForChargeFormLazyQuery>;
export type GetExpenseRatesForChargeFormQueryResult = Apollo.QueryResult<GetExpenseRatesForChargeFormQuery, GetExpenseRatesForChargeFormQueryVariables>;