import React, { createContext, useContext, useState, useEffect } from "react";
import { MemoryRouter, useLocation } from "react-router-dom";

type ContextType = {
  paths: string[];
};

export const RouteHistoryContext = createContext<ContextType>({
  paths: [],
});

export const RouteHistoryProvider: React.FC = ({ children }) => {
  const [paths, setPaths] = useState<string[]>([]);

  const location = useLocation();

  useEffect(() => {
    setPaths((paths) => [`${location.pathname}${location.search}`, ...paths]);
  }, [location]);

  return (
    <RouteHistoryContext.Provider value={{ paths }}>
      {children}
    </RouteHistoryContext.Provider>
  );
};

export function useRouteHistory(): string[] {
  const context = useContext(RouteHistoryContext);

  if (!context) {
    throw new Error(
      "You must use useRouteHistory inside a RouteHistoryContext"
    );
  }

  return context.paths;
}

export const MockMemoryRouter: React.FC<{ initialEntries?: string[] }> = ({
  initialEntries,
  children,
}) => (
  <MemoryRouter initialEntries={initialEntries}>
    <RouteHistoryProvider>{children}</RouteHistoryProvider>
  </MemoryRouter>
);

export const MockRouteHistory: React.FC<{ paths?: string[] }> = ({
  paths,
  children,
}) => {
  const [mockPaths] = useState(paths);

  return (
    <RouteHistoryContext.Provider value={{ paths: mockPaths ?? [] }}>
      {children}
    </RouteHistoryContext.Provider>
  );
};
