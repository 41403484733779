import React from "react";
import { GroupParams, ReviewPageState } from "./types";
import Button from "~/button";
import { useCheckout } from "../useCheckout";
import { CheckoutButton } from "../CheckoutButton";
import "./GroupActions.scss";
import { Route } from "~/routeTypes";
import { useParams } from "react-router-dom";
import { Ungroup } from "./Ungroup";
import { requestGroupReview } from "../routes";
import { useGroupReviewContext } from "./groupReviewContext";

export type GroupActionsProps = {
  index: number;
  reviewPageState: ReviewPageState;
  setReviewPageState: React.Dispatch<React.SetStateAction<ReviewPageState>>;
  redirect: (page: number) => void;
};

export const GroupActions: React.FC<GroupActionsProps> = (props) => {
  const { index, reviewPageState, setReviewPageState, redirect } = props;

  const { viewed, page } = reviewPageState;

  const { projectRequests, refetch } = useGroupReviewContext();

  const params = useParams<GroupParams>();

  const updatePage = (page: number) => {
    const newViewed = new Set([...viewed, page]);
    setReviewPageState({
      page,
      viewed: Array.from(newViewed),
    });
    redirect(page);
  };

  const member = projectRequests[0];

  const { isCheckerOuter } = useCheckout({ projectRequest: member });

  const prevButton =
    index > 0 ? (
      <div className="nav-button">
        <Button onClick={() => updatePage(page - 1)}>Previous</Button>
      </div>
    ) : (
      <div className="nav-button" />
    );

  const nextButton =
    index < projectRequests.length - 1 ? (
      <div className="nav-button">
        <Button onClick={() => updatePage(page + 1)}>Next</Button>
      </div>
    ) : (
      <div className="nav-button" />
    );

  const allViewed = viewed.length === projectRequests.length;

  const canEdit = isCheckerOuter && !!member.checkedOutBy;

  const approveAllButton = canEdit ? (
    <div>
      <Button
        className="approve-all-button"
        primary={allViewed}
        route={
          allViewed
            ? new Route(`${requestGroupReview}/${params.groupId}/approve`)
            : undefined
        }
        title={
          !allViewed ? "All requests must be reviewed before approving all" : ""
        }
      >
        Approve All
      </Button>
    </div>
  ) : (
    <></>
  );

  const ungroupButton = canEdit ? <Ungroup /> : null;

  return (
    <div className="request-group-actions">
      {ungroupButton}
      {prevButton}
      {nextButton}
      {approveAllButton}
      <CheckoutButton {...{ projectRequest: member, refetch }} />
    </div>
  );
};
