import React, { createContext, useContext, useState } from "react";
import Button from "~/button";
import "./StateHelper.scss";
import ClickSwallower from "~/click-swallower";
import { Body1, Body2 } from "@material/react-typography";
import { Dialog } from "~/dialog";

type StateHelperType = "invoice" | "charge";

export type StateHelperProps = {
  type: StateHelperType;
};

type StateInfo = {
  state: string;
  description: string;
};

type StateHelper = {
  title: string;
  states: StateInfo[];
};

const chargeStateInfo: StateHelper = {
  title: "Project Charge States",
  states: [
    {
      state: "PR Pending",
      description:
        "The charge is connected to a timesheet that's not finished with payroll.",
    },
    {
      state: "PM Pending",
      description:
        "The charge is finished with payroll and awaiting project manager approval.",
    },
    {
      state: "PS Pending",
      description:
        "The charge was adjusted and is awaiting project supervisor approval.",
    },
    {
      state: "BA Pending",
      description: "The charge is awaiting billing admin approval.",
    },
    {
      state: "Archived",
      description:
        "The charge is not billable, has been approved, and cannot be posted to an invoice. Archived charges will change to Post Pending if billable is changed to true.",
    },
    {
      state: "Post Pending",
      description: "The charge is approved and is not posted to an invoice.",
    },
    {
      state: "Posted Not Billed",
      description:
        "The charge is posted to a Draft invoice; i.e. an invoice that has not yet been sent to the customer.",
    },
    {
      state: "Billed",
      description:
        "The charge is posted to an invoice that has been sent to the customer.",
    },
    {
      state: "Unbilled",
      description:
        "Applies to filters only. Refers to charges in all states except Billed, and also excludes charges marked non-billable.",
    },
  ],
};

const invoiceStateInfo: StateHelper = {
  title: "Invoice States",
  states: [
    {
      state: "Draft",
      description: "The invoice has never been sent to the customer.",
    },
    {
      state: "Billed",
      description: "The invoice was sent to the customer.",
    },
    {
      state: "Cancelled",
      description:
        "The invoice was sent to the customer and then cancelled." +
        " Line items can be re-added to a new invoice.",
    },
  ],
};

const stateInfoTypes: Record<StateHelperType, StateHelper> = {
  invoice: invoiceStateInfo,
  charge: chargeStateInfo,
};

export const StateHelperButton: React.FC<StateHelperProps> = ({ type }) => {
  const { setStateInfo } = useStateHelper();

  return (
    <div className="state-helper-button">
      <ClickSwallower>
        <Button
          icon="info"
          onClick={() => setStateInfo(stateInfoTypes[type])}
        />
      </ClickSwallower>
    </div>
  );
};

export const StateHelperDisplay: React.FC = () => {
  const { stateInfo, setStateInfo } = useStateHelper();

  return (
    <div className="state-helper-display">
      <Dialog
        open={!!stateInfo}
        onClose={() => setStateInfo(null)}
        data-open={!!stateInfo}
        portalize
      >
        {stateInfo && (
          <div className="state-helper-popup">
            <Body1>{stateInfo.title}</Body1>
            {stateInfo.states.map(({ state, description }, idx) => (
              <div key={idx} className="state-row">
                <Body2>{state}</Body2>
                <Body2>{description}</Body2>
              </div>
            ))}
            <Button
              className="state-helper-close"
              onClick={() => setStateInfo(null)}
            >
              Close
            </Button>
          </div>
        )}
      </Dialog>
    </div>
  );
};

type ContextType = {
  stateInfo: StateHelper | null;
  setStateInfo: React.Dispatch<StateHelper | null>;
};

export const StateHelperContext = createContext<ContextType>({
  stateInfo: null,
  setStateInfo: () => undefined,
});

export const StateHelperProvider: React.FC = ({ children }) => {
  const [stateInfo, setStateInfo] = useState<StateHelper | null>(null);

  return (
    <StateHelperContext.Provider value={{ stateInfo, setStateInfo }}>
      {children}
      <StateHelperDisplay />
    </StateHelperContext.Provider>
  );
};

export function useStateHelper() {
  const context = useContext(StateHelperContext);

  if (!context) {
    throw new Error(
      "Do not use useStateHelper outside of a StateHelperContext"
    );
  }

  return context;
}
