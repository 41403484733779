import React, { useState, useEffect } from "react";
import "./CheckBox.scss";
import cn from "classnames";

export type CheckBoxProps = {
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  margin?: string;
  className?: string;
  innerClassName?: string;
};
export const CheckBox: React.FC<CheckBoxProps> = ({
  onChange,
  checked,
  disabled,
  margin,
  className,
  innerClassName,
}) => {
  const [isChecked, setIsChecked] = useState(checked ?? false);

  const handleChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    if (checked !== null && checked !== undefined && checked !== isChecked) {
      setIsChecked(checked);
    }
  }, [checked]);

  const style = cn("new-checkbox", { checked: isChecked }, className);

  return (
    <div className={style} style={{ margin }}>
      <div className={cn("checkmark", innerClassName)}>
        <svg viewBox="0 0 24 24">
          <path
            fill="none"
            stroke="white"
            d="M1.73,12.91 8.1,19.28 22.79,4.59"
          />
        </svg>
      </div>
      <input
        type="checkbox"
        checked={isChecked}
        disabled={disabled}
        onClick={handleChange}
        onChange={() => undefined}
      />
    </div>
  );
};
