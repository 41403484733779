import moment from "moment";
import React, { useState } from "react";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import Spinner from "~/spinner";
import { ReportPopup } from "~/report-popup";
import {
  BulkActionsPopup,
  BulkItem,
} from "~/search-page-wrapper/BulkActionsPopup";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { useRelativeNav } from "~/relative-nav";
import { useLocation } from "react-router-dom";
import { PopupMenuListItem } from "~/popup-menu-list";
import { AddClerical } from "~/invoice-search/add-clerical";
import { Invoice } from "~/gql/types";
import { FilterOptions } from "./types";
import { PostInvoicesToRollups } from "./PostInvoicesToRollups";
import { INVOICE_FILTERS_KEY } from "./InvoiceReview";

type InvoiceReportProps = {
  dispatch: React.Dispatch<Action<Invoice, FilterOptions>>;
  state: State<Invoice, FilterOptions> | null;
  bulkSelect?: boolean;
};

export const toReportFilters = (
  state: State<Invoice, FilterOptions> | null
): Record<string, any> => {
  const filterOptions = state?.filterOptions;

  const selectedInvoices = state?.selectedItems;

  return {
    searchText: filterOptions?.searchText || null,
    state: filterOptions?.state || null,
    billingAdmin: filterOptions?.billingAdmin?.userPrincipalName || null,
    projectManager: filterOptions?.projectManager?.userPrincipalName || null,
    projectSupervisor:
      filterOptions?.projectSupervisor?.userPrincipalName || null,
    customerNumber: filterOptions?.customer?.number || null,
    projectNumber: filterOptions?.project?.number || null,
    actualDate: filterOptions?.actualDate?.format("YYYY-MM-DD") || null,
    startDate: filterOptions?.afterDate?.format("YYYY-MM-DD") || null,
    endDate: filterOptions?.beforeDate?.format("YYYY-MM-DD") || null,
    actualCancelledDate:
      filterOptions?.actualCancelledDate?.format("YYYY-MM-DD") || null,
    startCancelledDate:
      filterOptions?.afterCancelledDate?.format("YYYY-MM-DD") || null,
    endCancelledDate:
      filterOptions?.beforeCancelledDate?.format("YYYY-MM-DD") || null,
    amount: filterOptions?.amount ? parseFloat(filterOptions.amount) : null,
    officeCode: filterOptions?.officeCode?.officeCode || null,
    afeWoPo: filterOptions?.afeWoPo || null,
    sortBy: filterOptions?.sortBy || null,
    invoiceNumbers:
      state && selectedInvoices!.length > 0
        ? selectedInvoices!.map((x) => x.invoiceNumber)
        : null,
    groupName: filterOptions?.projectGroup?.name || null,
    groupCustomerNumber: filterOptions?.projectGroup?.customer?.number || null,
  };
};

export const InvoiceBulkOptions: React.FC<InvoiceReportProps> = ({
  state,
  dispatch,
  bulkSelect,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [addClericalOpen, setAddClericalOpen] = useState(false);
  const [postToRollupsOpen, setPostToRollupsOpen] = useState(false);

  const location = useLocation();

  const nav = useRelativeNav();

  const downloadReport = useReportFetcher();

  const selectedInvoices = state?.selectedItems;

  const filters = toReportFilters(state);

  const canPostToRollupInvoices = (selectedInvoices ?? []).filter(
    (x) => x.canPostToRollup
  );

  const getCsv = async () => {
    await downloadReport({
      setLoading,
      path: "invoice-csv",
      fileName: `invoices-${moment().format("MM-DD-YYYY")}`,
      body: {
        ...filters,
      },
      type: "text/csv;charset=utf-8;",
    });
    setOpen(false);
  };

  const getTotalsCsv = async () => {
    await downloadReport({
      setLoading,
      path: "invoice-totals-csv",
      fileName: `invoice-totals-${moment().format("MM-DD-YYYY")}`,
      body: {
        ...filters,
      },
      type: "text/csv;charset=utf-8;",
    });
    setOpen(false);
  };

  const getTotalsPdf = async () => {
    await downloadReport({
      setLoading,
      path: "invoice-totals-pdf",
      fileName: `invoice-totals-${moment().format("MM-DD-YYYY")}`,
      body: {
        ...filters,
      },
    });
    setOpen(false);
  };

  const getLineItemsCsv = async () => {
    await downloadReport({
      setLoading,
      path: "invoice-line-item-csv",
      fileName: `invoice-lineItems-${moment().format("MM-DD-YYYY")}`,
      body: {
        ...filters,
      },
      type: "text/csv;charset=utf-8;",
    });
    setOpen(false);
  };

  const getBackupReport = async (includeNotes: boolean) => {
    await downloadReport({
      setLoading,
      path: "invoice-backup-report",
      fileName: `invoices-backup-report${moment().format("MM-DD-YYYY")}`,
      body: {
        ...filters,
        includeNotes,
      },
    });
    setOpen(false);
  };

  const getBackupWithNotes = async () => await getBackupReport(true);
  const getBackupWithoutNotes = async () => await getBackupReport(false);

  const getInvoicesPdf = async () => {
    await downloadReport({
      setLoading,
      path: "invoices",
      fileName:
        selectedInvoices && selectedInvoices.length === 1
          ? selectedInvoices[0].invoiceNumber
          : `invoices-${moment().format("MM-DD-YYYY")}`,
      body: {
        ...filters,
      },
    });
    setOpen(false);
  };

  const getBundledPdf = async (includeNotes: boolean) => {
    await downloadReport({
      setLoading,
      path: "bundled-invoice",
      fileName: `bundled-invoices-report${moment().format("MM-DD-YYYY")}`,
      body: {
        ...filters,
        includeNotes,
      },
    });
    setOpen(false);
  };

  const getBundledWithNotes = async () => await getBundledPdf(true);
  const getBundledWithoutNotes = async () => await getBundledPdf(false);

  const items = [
    {
      label: "Excel Export",
      onClick: getCsv,
      key: 1,
      dataItem: "excel-report",
    },
    {
      label: "Totals CSV Report",
      onClick: getTotalsCsv,
      key: 2,
      dataItem: "totals-csv-report",
    },
    {
      label: "Totals PDF Report",
      onClick: getTotalsPdf,
      key: 3,
      dataItem: "totals-pdf-report",
    },
    {
      label: "Backup w/ Notes",
      onClick: getBackupWithNotes,
      key: 4,
      dataItem: "backup-with-notes",
    },
    {
      label: "Backup w/o Notes",
      onClick: getBackupWithoutNotes,
      key: 5,
      dataItem: "backout-without-notes",
    },
    {
      label: "Invoices PDF",
      onClick: getInvoicesPdf,
      key: 6,
      dataItem: "invoices-pdf",
    },
    {
      label: "Export Excel Line Detail",
      onClick: getLineItemsCsv,
      key: 7,
      dataItem: "invoices-line-item-csv",
    },
    {
      label: "Bundled w/ Notes",
      onClick: getBundledWithNotes,
      key: 8,
      dataItem: "bundled-with-notes",
    },
    {
      label: "Bundled w/o Notes",
      onClick: getBundledWithoutNotes,
      key: 9,
      dataItem: "bundled-without-notes",
    },
    state && selectedInvoices!.length
      ? {
          label: "Review",
          onClick: () => {
            window.sessionStorage.setItem(INVOICE_FILTERS_KEY, location.search);
            nav(`review/${selectedInvoices![0].invoiceNumber}`);
          },
          key: 10,
          dataItem: "review-invoices",
        }
      : null,
    state && selectedInvoices!.length
      ? {
          label: "Add Clerical",
          onClick: () => setAddClericalOpen(true),
          key: 11,
          dataItem: "add-clerical",
        }
      : null,
  ].filter((x) => x) as PopupMenuListItem[];

  return (
    <>
      {!bulkSelect && (
        <ReportPopup {...{ items, loading, open, setOpen, label: "Reports" }} />
      )}
      {bulkSelect && (
        <BulkActionsPopup
          {...{
            selectedItems: selectedInvoices!,
            onClear: () => dispatch!({ tag: "ClearSelected" }),
            type: "Invoice",
            buttonItems: items as unknown as BulkItem[],
          }}
        />
      )}
      {selectedInvoices!.length > 0 && (
        <AddClerical
          {...{
            invoices: selectedInvoices!,
            open: addClericalOpen,
            setOpen: setAddClericalOpen,
          }}
        />
      )}
      {postToRollupsOpen && (
        <PostInvoicesToRollups
          {...{
            setPostToRollupsOpen,
            dispatch,
            selected: canPostToRollupInvoices,
          }}
        />
      )}
      <Spinner open={loading} />
    </>
  );
};
