import { Employee } from "~/gql/types";
import { FieldState, FormState } from "~/inline-fields-form/types";

const updateState = (
  state: FormState,
  updatedFields: Record<string, FieldState>
) => ({
  ...state,
  fields: {
    ...state.fields,
    ...updatedFields,
  },
});

const updateRequired = (
  state: FormState,
  field: string,
  required: boolean
) => ({
  [field]: { ...state.fields[field], required, error: "" },
});

export const updater = {
  requestNewCustomer: (state: FormState, value: boolean): FormState =>
    updateState(state, {
      ...updateRequired(state, "customer", !value),
      ...updateRequired(state, "customerName", value),
      ...updateRequired(state, "customerAddress1", value),
      ...updateRequired(state, "customerCity", value),
      ...updateRequired(
        state,
        "customerCountry",
        value && state.fields.isInternational?.value
      ),
      ...updateRequired(
        state,
        "customerZip",
        value && !state.fields.isInternational?.value
      ),
      ...updateRequired(
        state,
        "customerState",
        value && !state.fields.isInternational?.value
      ),
      ...updateRequired(state, "customerPhone", value),
    }),
  isInternational: (state: FormState, value: boolean): FormState =>
    updateState(state, {
      ...updateRequired(state, "customerCountry", value),
      ...updateRequired(state, "customerZip", !value),
      ...updateRequired(state, "customerState", !value),
    }),
  projectManager: (state: FormState, value: Employee | null): FormState => {
    if (!value) {
      return state;
    }

    return {
      ...state,
      fields: {
        ...state.fields,
        officeCode: {
          ...state.fields.officeCode,
          value: value.fieldOffice || state.fields.officeCode.value,
        },
      },
    };
  },
};
