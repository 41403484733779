import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCustomerMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  address1: Types.Scalars['String'];
  address2: Types.Scalars['String'];
  city: Types.Scalars['String'];
  state: Types.Scalars['String'];
  zip: Types.Scalars['String'];
  country: Types.Scalars['String'];
  phone: Types.Scalars['String'];
  fax: Types.Scalars['String'];
  notes: Types.Scalars['String'];
  attn?: Types.InputMaybe<Types.Scalars['String']>;
  nmTaxable: Types.Scalars['Boolean'];
  txTaxable: Types.Scalars['Boolean'];
  subAccountOf?: Types.InputMaybe<Types.Scalars['String']>;
  electronicInvoicingSystem?: Types.InputMaybe<Types.ElectronicInvoicingSystem>;
}>;


export type CreateCustomerMutation = { __typename?: 'MutationModel', customers?: { __typename?: 'CustomerMutationModel', createCustomer?: { __typename?: 'CreateCustomerResponse', createCustomerRequestId: string, errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const CreateCustomerDocument = gql`
    mutation CreateCustomer($name: String!, $address1: String!, $address2: String!, $city: String!, $state: String!, $zip: String!, $country: String!, $phone: String!, $fax: String!, $notes: String!, $attn: String, $nmTaxable: Boolean!, $txTaxable: Boolean!, $subAccountOf: String, $electronicInvoicingSystem: ElectronicInvoicingSystem) {
  customers {
    createCustomer(
      name: $name
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      country: $country
      phone: $phone
      fax: $fax
      notes: $notes
      attn: $attn
      nmTaxable: $nmTaxable
      txTaxable: $txTaxable
      subAccountOf: $subAccountOf
      electronicInvoicingSystem: $electronicInvoicingSystem
    ) {
      errors {
        message
        argumentName
      }
      createCustomerRequestId
    }
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *      country: // value for 'country'
 *      phone: // value for 'phone'
 *      fax: // value for 'fax'
 *      notes: // value for 'notes'
 *      attn: // value for 'attn'
 *      nmTaxable: // value for 'nmTaxable'
 *      txTaxable: // value for 'txTaxable'
 *      subAccountOf: // value for 'subAccountOf'
 *      electronicInvoicingSystem: // value for 'electronicInvoicingSystem'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;