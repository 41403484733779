import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateInvoiceChargeMutationVariables = Types.Exact<{
  projectNumber: Types.Scalars['Int'];
  quantity: Types.Scalars['Decimal'];
  rate: Types.Scalars['Decimal'];
  label: Types.Scalars['String'];
  notes: Types.Scalars['String'];
  crewCode: Types.Scalars['String'];
  unit?: Types.InputMaybe<Types.RateUnit>;
  date: Types.Scalars['LocalDate'];
  rateSheetName?: Types.InputMaybe<Types.Scalars['String']>;
  type: Types.ChargeType;
  employee?: Types.InputMaybe<Types.Scalars['String']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  taxable?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type CreateInvoiceChargeMutation = { __typename?: 'MutationModel', projectCharges?: { __typename?: 'ProjectChargesMutationModel', createInvoiceCharge?: { __typename?: 'CreateProjectChargeResponse', projectChargeId?: number | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CreateInvoiceChargeDocument = gql`
    mutation CreateInvoiceCharge($projectNumber: Int!, $quantity: Decimal!, $rate: Decimal!, $label: String!, $notes: String!, $crewCode: String!, $unit: RateUnit, $date: LocalDate!, $rateSheetName: String, $type: ChargeType!, $employee: String, $glAccount: String, $taxable: Boolean) {
  projectCharges {
    createInvoiceCharge(
      projectNumber: $projectNumber
      quantity: $quantity
      rate: $rate
      label: $label
      notes: $notes
      crewCode: $crewCode
      unit: $unit
      date: $date
      rateSheetName: $rateSheetName
      type: $type
      employee: $employee
      glAccount: $glAccount
      taxable: $taxable
    ) {
      errors {
        argumentName
        message
      }
      projectChargeId
    }
  }
}
    `;
export type CreateInvoiceChargeMutationFn = Apollo.MutationFunction<CreateInvoiceChargeMutation, CreateInvoiceChargeMutationVariables>;

/**
 * __useCreateInvoiceChargeMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceChargeMutation, { data, loading, error }] = useCreateInvoiceChargeMutation({
 *   variables: {
 *      projectNumber: // value for 'projectNumber'
 *      quantity: // value for 'quantity'
 *      rate: // value for 'rate'
 *      label: // value for 'label'
 *      notes: // value for 'notes'
 *      crewCode: // value for 'crewCode'
 *      unit: // value for 'unit'
 *      date: // value for 'date'
 *      rateSheetName: // value for 'rateSheetName'
 *      type: // value for 'type'
 *      employee: // value for 'employee'
 *      glAccount: // value for 'glAccount'
 *      taxable: // value for 'taxable'
 *   },
 * });
 */
export function useCreateInvoiceChargeMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceChargeMutation, CreateInvoiceChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceChargeMutation, CreateInvoiceChargeMutationVariables>(CreateInvoiceChargeDocument, options);
      }
export type CreateInvoiceChargeMutationHookResult = ReturnType<typeof useCreateInvoiceChargeMutation>;
export type CreateInvoiceChargeMutationResult = Apollo.MutationResult<CreateInvoiceChargeMutation>;
export type CreateInvoiceChargeMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceChargeMutation, CreateInvoiceChargeMutationVariables>;