import React from "react";
import TextField, { Input } from "@material/react-text-field";
import {
  State,
  Action,
  EmployeeRole,
  calculateHours,
  HourType,
  DailyHours,
} from "./stateMachine";
import "./DailyHoursField.scss";
import { Table, TableRow, TableCell } from "~/table";

const roleMap: Record<EmployeeRole, string> = {
  fieldSupervisor: "FIELD SUPERVISOR",
  leadPartyChief: "LEAD PARTY CHIEF",
  partyChief: "PARTY CHIEF",
  instrumentTech: "INSTRUMENT TECH",
  rodMan: "ROD MAN",
};

const hours: HourType[] = [
  "asBuilt",
  "constRestake",
  "constStake",
  "locate",
  "boundary",
  "clStake",
  "rerouteStake",
  "rainoutOther",
  "travelHours",
];

type NumberInputProps = {
  handleChange: (role: EmployeeRole, hour: HourType, value: string) => void;
  role: EmployeeRole;
  hour: HourType;
  data: DailyHours;
};

const NumberInput: React.FC<NumberInputProps> = ({
  role,
  data,
  hour,
  handleChange,
}) => {
  return (
    <TableCell className="number-input">
      <TextField>
        <Input
          id={`${role}-${hour}`}
          type="number"
          value={data[hour] || ""}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(role, hour, evt.target.value)
          }
        />
      </TextField>
    </TableCell>
  );
};

type EmployeeDataRowProps = {
  role: EmployeeRole;
  data: DailyHours;
  state: State;
  dispatch: React.Dispatch<Action>;
};

const EmployeeDataRow: React.FC<EmployeeDataRowProps> = ({
  role,
  data,
  dispatch,
}) => {
  const handleChange = (role: EmployeeRole, hour: HourType, value: string) => {
    const newValue =
      !value || isNaN(parseFloat(value)) ? null : parseFloat(value);

    dispatch({
      tag: "UpdateDailyHours",
      hours: { role, employee: data.employee, [hour]: newValue },
    });
  };

  return (
    <TableRow className="employee-hours">
      <TableCell className="employee-role" text={roleMap[role]} />
      {data && (
        <>
          <TableCell
            className="employee-name"
            text={`${data.employee!.firstName.toUpperCase()} ${data.employee!.lastName.toUpperCase()}`}
          />
          {hours.map((hour) => (
            <NumberInput
              key={hour}
              {...{ role, data, handleChange }}
              hour={hour}
            />
          ))}
          <TableCell
            className={`employee-total ${role}-total`}
            text={calculateHours(data).toString()}
          />
        </>
      )}
    </TableRow>
  );
};

const DailyHoursField: React.FC<{
  state: State;
  dispatch: React.Dispatch<Action>;
}> = ({ state, dispatch }) => {
  const dailyHours = state.dailyHours!;

  const rows = Object.keys(roleMap)
    .map((x, idx) => {
      const data = dailyHours.find((h) => h.role === x);

      if (!data) {
        return null;
      }

      return (
        <EmployeeDataRow
          role={x as EmployeeRole}
          state={state}
          dispatch={dispatch}
          key={idx}
          data={data}
        />
      );
    })
    .filter((x) => !!x);

  const colummWidths = [
    "2fr",
    "2fr",
    ...[...new Array(10).keys()].map((_) => "1fr"),
  ].join(" ");

  return (
    <div className="employee-data">
      <Table
        columnWidths={colummWidths}
        columnCount={12}
        columnGap={"0px"}
        grid={true}
      >
        <TableRow header>
          <TableCell text="ROLE" />
          <TableCell text="NAME" />
          <TableCell text="ASBUILT" />
          <TableCell text="CONST. RESTAKE" />
          <TableCell text="CONST. STAKE" />
          <TableCell text="LOCATE" />
          <TableCell text="BOUNDARY" />
          <TableCell text="C/L STAKE" />
          <TableCell text="REROUTE STAKE" />
          <TableCell text="RAINOUT /OTHER" />
          <TableCell text="TRAVEL HOURS" />
          <TableCell text="TOTAL HOURS" />
        </TableRow>

        {rows}
      </Table>
    </div>
  );
};

export default DailyHoursField;
