import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import { createProvider } from "./createFilterProvider";
import CurrencyFormField from "~/visuals/organisms/CurrencyFormField";

type CurrencyProviderProps = FilterProviderProps & {
  type: string;
  label: string;
  suggestText: string;
};

const formattedAmount = (value: string) =>
  !isNaN(parseFloat(value)) ? `$${parseFloat(value).toFixed(2)}` : "";

export function useCurrencyProvider(
  props: CurrencyProviderProps
): FilterProvider {
  const { type, label, suggestText } = props;

  const getItems = (args?: any) =>
    !isNaN(parseFloat(args?.text))
      ? Promise.resolve([parseFloat(args.text)])
      : Promise.resolve([]);

  const itemFromParam = (arg: string) =>
    !isNaN(parseFloat(arg))
      ? Promise.resolve(parseFloat(arg))
      : Promise.resolve(null);

  return createProvider({
    render: () => (
      <CurrencyFormField formField={type} label={label} helperText={""} />
    ),
    type,
    label,
    props,
    getItems,
    itemFromParam,
    shouldSuggestText: suggestText,
    toSuggestions: (result, _) =>
      (result as string[]).map((n) => ({ text: formattedAmount(n), value: n })),
    toLabelValue: (value: string) => formattedAmount(value),
    toSearchArgs: (text: string) => ({ text }),
    toParamValue: (value: string) => value,
  });
}
