import React, { useState } from "react";
import Button from "~/button";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import FormBottomRow from "~/form-bottom-row";
import { CustomerPicker } from "~/visuals/organisms/CustomerPickerFormField";
import { useSnackBar } from "~/snackbar";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import { ValueRenderer } from "~/forms/ValueContext";
import TextFormField from "~/text-form-field";
import { Cell, Row } from "@material/react-layout-grid";
import { useEditGroupCustomerMutation } from "./editGroupCustomer.generated";
import { CustomerFormData, ProjectRequestFormData } from "./types";
import { useGroupReviewContext } from "../GroupReview/groupReviewContext";
import { toCustomerValues } from "./utils";
import { ValidationResponse } from "~/gql/types";

type GroupEditCustProps = {
  initialFormValues: Partial<ProjectRequestFormData>;
  memberId: string;
};

type FormData = CustomerFormData & {
  notes: string | null;
};

export const GroupCustomerForm: React.FC<GroupEditCustProps> = ({
  initialFormValues,
  memberId,
}) => {
  const [editCustomerOpen, setEditCustomerOpen] = useState(false);
  const [doMutation] = useEditGroupCustomerMutation();
  const addAlert = useSnackBar();
  const { refetch } = useGroupReviewContext();

  const onSuccess = () => {
    addAlert({
      isSuccess: true,
      message: "Customer Edited",
      key: `${Math.random()}`,
    });
    setEditCustomerOpen(false);
    void refetch();
  };

  const runMutation = async (values: FormData) => {
    const variables = {
      notes: values.notes!,
      memberId: memberId,
      customer: toCustomerValues(values),
    };
    const res = await doMutation({ variables });

    return res?.data?.projectRequests?.editGroupCustomer as ValidationResponse;
  };
  const customer = initialFormValues.customer;
  const customerName = customer?.name ?? initialFormValues.customerName;
  const customerNumber = customer?.number ?? customer?.status ?? "New";

  const initialValues: FormData = {
    customerAddress2: initialFormValues?.customerAddress2 ?? null,
    notes: initialFormValues.notes ?? null,
    customerFax: initialFormValues?.customerFax ?? null,
    customerNotes: initialFormValues?.customerNotes ?? null,
    customerAdminNotes: initialFormValues?.customerAdminNotes ?? null,
    customerAttn: initialFormValues?.customerAttn ?? null,
    customer: initialFormValues?.customer ?? null,
    customerName: initialFormValues?.customerName ?? null,
    customerState: initialFormValues?.customerState ?? null,
    customerZip: initialFormValues?.customerZip ?? null,
    customerCountry: initialFormValues?.customerCountry ?? null,
    customerAddress1: initialFormValues?.customerAddress1 ?? null,
    customerPhone: initialFormValues?.customerPhone ?? null,
    customerCity: initialFormValues?.customerCity ?? null,
    isInternational: !!initialFormValues?.customerCountry,
    requestNewCustomer: !!initialFormValues?.requestNewCustomer,
  };

  return (
    <>
      <dt className="request-field-label">Customer</dt>
      <dd>
        {`${customerName} (${customerNumber})`}
        <Button
          className="group-edit-button open-edit-cust-button"
          outlined
          onClick={() => setEditCustomerOpen(true)}
        >
          Edit
        </Button>
      </dd>

      <Dialog
        open={editCustomerOpen}
        onClose={() => setEditCustomerOpen(false)}
        data-open={editCustomerOpen}
        className="edit-group-dialog"
      >
        <DialogTitle>
          Editing the customer will apply to the entire group
        </DialogTitle>
        <DialogContent>
          {editCustomerOpen && (
            <MutationForm
              initialValues={initialValues}
              runMutation={runMutation}
              onSuccess={onSuccess}
            >
              <ValueRenderer
                render={(values) => (
                  <>
                    <Row>
                      <Cell columns={values.requestNewCustomer ? 6 : 12}>
                        <BinaryPickerFormField
                          formField="requestNewCustomer"
                          label="Request New Customer"
                          helperText=""
                        />
                        {!values.requestNewCustomer && (
                          <>
                            <CustomerPicker
                              formField="customer"
                              label="Customer"
                              helperText=""
                              required
                            />
                            <TextFormField
                              formField={"notes"}
                              label={"Admin Notes"}
                              type="string"
                              helperText={""}
                              required
                            />
                          </>
                        )}
                        {values.requestNewCustomer && (
                          <>
                            <TextFormField
                              formField={"customerName"}
                              label={"Customer Name"}
                              type="string"
                              helperText={""}
                              required
                            />

                            <TextFormField
                              formField={"customerAddress1"}
                              label={"Customer Address"}
                              type="string"
                              helperText={""}
                              required
                            />
                            <TextFormField
                              formField={"customerAddress2"}
                              label={"Address 2"}
                              type="string"
                              helperText={""}
                            />
                            <TextFormField
                              formField={"customerPhone"}
                              label={"Customer Phone"}
                              type="string"
                              helperText={""}
                              required
                            />
                            <TextFormField
                              formField={"customerFax"}
                              label={"Customer Fax"}
                              type="string"
                              helperText={""}
                            />
                            <TextFormField
                              formField={"customerAdminNotes"}
                              label={"Customer Internal Notes"}
                              type="string"
                              helperText={""}
                            />
                          </>
                        )}
                      </Cell>
                      {values.requestNewCustomer && (
                        <Cell columns={6}>
                          <BinaryPickerFormField
                            formField="isInternational"
                            label="is International"
                            helperText=""
                          />
                          <TextFormField
                            formField={"customerCity"}
                            label={"City"}
                            type="string"
                            helperText={""}
                            required
                          />
                          {values.isInternational && (
                            <TextFormField
                              formField={"customerCountry"}
                              label={"Country"}
                              type="string"
                              helperText={""}
                            />
                          )}
                          {!values.isInternational && (
                            <>
                              <TextFormField
                                formField={"customerState"}
                                label={"State"}
                                type="string"
                                helperText={""}
                              />
                              <TextFormField
                                formField={"customerZip"}
                                label={"Zip"}
                                type="string"
                                helperText={""}
                              />
                            </>
                          )}
                          <TextFormField
                            formField={"customerNotes"}
                            label={"Customer Notes"}
                            type="string"
                            helperText={""}
                          />
                          <TextFormField
                            formField={"notes"}
                            label={"Admin Notes"}
                            type="string"
                            helperText={""}
                            required
                          />
                          <TextFormField
                            formField={"attn"}
                            label={"Attn"}
                            type="string"
                            helperText={""}
                          />
                        </Cell>
                      )}
                    </Row>
                  </>
                )}
              />

              <ErrorRenderer
                render={(formError) => (
                  <FormBottomRow
                    errorMessage={formError}
                    buttonText="Submit"
                    onCancel={() => setEditCustomerOpen(false)}
                  />
                )}
              />
            </MutationForm>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
