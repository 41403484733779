import React, { useState } from "react";
import Button from "~/button";
import { PopupMenuList, PopupMenuListItem } from "~/popup-menu-list";
import "./ActionsMenu.scss";
import { Chevron } from "~/chevron";

type ActionsMenuProps = {
  items: PopupMenuListItem[];
};

export const ActionsMenu: React.FC<ActionsMenuProps> = ({ items }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="actions-menu">
      <Button onClick={() => setMenuOpen(!menuOpen)}>Actions</Button>
      <Chevron expanded={menuOpen} />
      <PopupMenuList
        {...{ visible: menuOpen, items }}
        onMenuMouseEnter={() => undefined}
        onMenuMouseLeave={() => undefined}
        onClose={() => setMenuOpen(false)}
        focusOnOpen={false}
        selectedIndex={0}
      />
    </div>
  );
};
