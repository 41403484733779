import { ApolloClient } from "@apollo/client";
import { Moment } from "moment";
import { Invoice } from "~/gql/types";
import { InvoicesSearchDocument } from "./query.generated";

export const formattedDate = (date: Moment | null) =>
  date ? date.format("YYYY-MM-DD") : null;

export const fetchUpdatedInvoices = async (
  client: ApolloClient<any>,
  invoiceNumbers: string[]
): Promise<Invoice[]> => {
  const result = await client.query({
    query: InvoicesSearchDocument,
    variables: { invoiceNumbers },
  });

  return result?.data?.invoices?.search?.records as Invoice[];
};
