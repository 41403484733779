import React, { useEffect, useMemo } from "react";
import { ClientForm } from "~/forms/ClientForm";
import { ValueRenderer } from "~/forms/ValueContext";
import TextFormField from "~/text-form-field";
import { TaskRate } from "~/gql/types";
import { ExpenseRate } from "~/graphql/ExpenseRateSheet";
import ProjectPickerFormField from "~/visuals/organisms/ProjectPickerFormField";
import { RateField } from "./fields/RateField";
import { SplitRowWrapper } from "~/split-row-wrapper";
import { currencyFormatter } from "~/currencyFormatter";
import { RateAmountField } from "./fields/RateAmountField";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import { FormSubmitter } from "./FormSubmitter";
import { SplitFormData, SplitFormRowProps } from "./types";

export const SplitFormRow: React.FC<SplitFormRowProps> = (props) => {
  const { charge, splitIndex } = props;

  return (
    <SplitFormRowComponent {...props}>
      {(splitIndex || charge.canMoveProject) && (
        <ProjectPickerFormField
          formField={"project"}
          label={"Project"}
          helperText={""}
          date={charge.date!}
          required
        />
      )}

      <RateField type={charge.type!} date={charge.date!} />

      <TextFormField
        formField={"quantity"}
        label={"Quantity"}
        helperText={""}
        required
      />

      <BinaryPickerFormField
        formField={"taxable"}
        label={"Taxable"}
        helperText={""}
      />

      <TextFormField
        formField={"notes"}
        label={"Notes"}
        helperText={""}
        textarea
        required
      />

      <RateAmountField
        {...{
          type: charge.type!,
          date: charge.date!,
          employee: charge.employee ?? null,
        }}
      />
    </SplitFormRowComponent>
  );
};

export const SplitFormRowComponent: React.FC<SplitFormRowProps> = (props) => {
  const { children, charge, submitted, splitIndex, onDelete } = props;

  const initialValues = useMemo(() => {
    const rate =
      charge.type === "Expense"
        ? ({ name: charge.label } as ExpenseRate)
        : ({ name: charge.label } as TaskRate);

    const vals: SplitFormData = {
      project: charge.project!,
      rate,
      rateSheet: charge.rateSheetName!,
      rateAmount: charge.rate!.toString(),
      notes: charge.notes!,
      quantity: null,
      taxable: charge.taxable!,
    };

    return vals;
  }, []);

  return (
    <ClientForm initialValues={initialValues} onSuccess={() => undefined}>
      <SplitRowWrapper
        {...{
          total: currencyFormatter.format(
            (charge.quantity ?? 0) * (charge.rate ?? 0)
          ),
          onDelete,
          splitIndex,
        }}
      >
        {children}
      </SplitRowWrapper>

      <ValueRenderer
        render={(values) => (
          <ValueListener {...{ values: values as SplitFormData, ...props }} />
        )}
      />

      <FormSubmitter {...{ submitted }} />
    </ClientForm>
  );
};

type ValueListenerProps = SplitFormRowProps & { values: SplitFormData };

const parseVal = (val: string | null): number | null =>
  val ? parseFloat(val) : null;

const ValueListener: React.FC<ValueListenerProps> = ({
  values,
  onChange,
  charge,
}) => {
  const formRateLabel =
    values.rate && "invoiceLabel" in values.rate && values.rate.invoiceLabel
      ? values.rate.invoiceLabel
      : values.rate?.name;

  useEffect(() => {
    if (values.notes != charge.notes) {
      onChange({ ...charge, notes: values.notes });
    }
    if (values.rateAmount != charge.rate?.toString()) {
      onChange({ ...charge, rate: parseVal(values.rateAmount) });
    }
    if (values.rateSheet != charge.rateSheetName) {
      onChange({ ...charge, rateSheetName: values.rateSheet });
    }
    if (values.quantity != charge.quantity) {
      onChange({ ...charge, quantity: parseVal(values.quantity) });
    }
    if (values.project?.number != charge.project?.number) {
      onChange({ ...charge, project: values.project });
    }
    if (formRateLabel != charge.label) {
      onChange({ ...charge, label: formRateLabel ?? null });
    }
    if (values.taxable != charge.taxable) {
      onChange({ ...charge, taxable: values.taxable });
    }
  }, [values]);
  return <></>;
};
