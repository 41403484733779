import React, { createContext, useContext } from "react";

export type CustomerContextApi = {
  reload: () => void;
};

export const CustomerContext = createContext<CustomerContextApi | null>(null);

type MockCustomerProviderProps = {
  reload?: () => void;
};

export const MockCustomerContextProvider: React.FC<
  MockCustomerProviderProps
> = ({ children, reload }) => {
  const api = {
    reload: reload || (() => undefined),
  };

  return (
    <CustomerContext.Provider value={api}>{children}</CustomerContext.Provider>
  );
};

export const useReload = (): React.DispatchWithoutAction => {
  const api = useContext(CustomerContext);

  if (!api) {
    throw new Error(
      "Do not use CustomerRouter.useReload outside of a CustomerRouter."
    );
  }

  return api.reload;
};
