import React, { ReactNode, useMemo } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import Spinner from "~/spinner";
import { invoice as invoiceRoute } from "~/routes";
import * as invoiceRoutes from "./invoiceRoutes";
import InvoicePage from ".";
import { Invoice } from "~/gql/types";
import { SelectInvoice } from "./Rebilling/SelectInvoice";
import { SelectCharges } from "./Rebilling/SelectCharges";
import { SelectProject } from "./Rebilling/SelectProject";
import { useInvoiceQueryQuery } from "./query.generated";
import { InvoiceContext } from "./InvoiceContext";

export const baseRoute = invoiceRoute;

export type InvoiceRouterPages = {
  InvoicePage?: ReactNode;
  RebillInvoiceSelectPage?: ReactNode;
  RebillProjectSelectPage?: ReactNode;
  RebillChargeSelectPage?: ReactNode;
};

export type InvoiceRouterProps = {
  pages?: InvoiceRouterPages;
};

type InvoiceRouteData = { invoiceNumber: string };

const pathFragments = invoiceRoutes.pathFragments;

export const InvoiceRouter: React.FC<InvoiceRouterProps> = ({ pages }) => {
  const params = useParams<InvoiceRouteData>();
  const { data, loading, refetch } = useInvoiceQueryQuery({
    variables: {
      invoiceNumber: params.invoiceNumber!,
    },
  });

  const contextData = useMemo(
    () => ({ invoice: data?.invoices?.invoice as Invoice, reload: refetch }),
    [data, refetch]
  );

  const InvoicePageComponent = pages?.InvoicePage || <InvoicePage />;
  const RebillProjectSelectComponent = pages?.RebillProjectSelectPage || (
    <SelectProject />
  );
  const RebillInvoiceSelectComponent = pages?.RebillInvoiceSelectPage || (
    <SelectInvoice />
  );
  const RebillChargeSelectComponent = pages?.RebillChargeSelectPage || (
    <SelectCharges />
  );

  return (
    <>
      <Spinner open={loading} />
      {data && (
        <InvoiceContext.Provider value={contextData}>
          <Routes>
            <Route
              path={pathFragments.rebillInvoiceSelect + "/:projectNumber"}
              element={RebillInvoiceSelectComponent}
            />
            <Route
              path={
                pathFragments.rebillChargeSelect +
                "/:projectNumber" +
                "/:targetInvoice"
              }
              element={RebillChargeSelectComponent}
            />
            <Route
              path={pathFragments.rebillChargeSelect + "/:projectNumber"}
              element={RebillChargeSelectComponent}
            />
            <Route
              path={pathFragments.rebillProjectSelect}
              element={RebillProjectSelectComponent}
            />
            <Route path="*" element={InvoicePageComponent} />
          </Routes>
        </InvoiceContext.Provider>
      )}
    </>
  );
};
