// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZnezKFOErYV3mdU3hrXIVg\\=\\= .new-table-cell:first-of-type{padding-left:8px}.ZnezKFOErYV3mdU3hrXIVg\\=\\= .new-table-cell:last-of-type{padding-right:8px}.ZnezKFOErYV3mdU3hrXIVg\\=\\= .oAzT45OyPcW8s79Ffg7X0Q\\=\\= .new-table-cell.mdc-typography{text-decoration:line-through}", "",{"version":3,"sources":["webpack://src/service-descriptions/ServiceDescriptions.module.scss"],"names":[],"mappings":"AACE,0DACE,gBAAA,CAGF,yDACE,iBAAA,CAIA,uFACE,4BAAA","sourcesContent":[".Table {\n  & :global(.new-table-cell):first-of-type {\n    padding-left: 8px;\n  }\n\n  & :global(.new-table-cell):last-of-type {\n    padding-right: 8px;\n  }\n\n  .Inactive {\n    & :global(.new-table-cell.mdc-typography) {\n      text-decoration: line-through;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Table": "ZnezKFOErYV3mdU3hrXIVg==",
	"Inactive": "oAzT45OyPcW8s79Ffg7X0Q=="
};
export default ___CSS_LOADER_EXPORT___;
