import React from "react";
import { Form } from "react-final-form";
import { ErrorContext } from "./MutationForm";
import { ValueContext } from "./ValueContext";
import { Decorator } from "final-form";

type OnChangeFormProps<TValues> = {
  values: TValues;
  onChange: React.FormEventHandler<HTMLFormElement>;
  children: React.ReactNode;
  decorators?: Decorator<any, Record<string, any>>[];
};

export const OnChangeForm = <TValues,>(
  props: OnChangeFormProps<TValues>
): React.ReactElement => {
  const { values, onChange, children } = props;

  return (
    <Form
      onSubmit={() => undefined}
      initialValues={values as any}
      decorators={props.decorators}
      render={({ handleSubmit, values, error, submitError }) => {
        return (
          <form onSubmit={handleSubmit} onChange={onChange}>
            <ErrorContext.Provider value={submitError || error || ""}>
              <ValueContext.Provider value={values}>
                {children}
              </ValueContext.Provider>
            </ErrorContext.Provider>
          </form>
        );
      }}
    />
  );
};
