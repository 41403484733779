import React from "react";
import FormCard from "~/form-card";
import PageTitleRow from "~/page-title-row";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import Spinner from "~/spinner";
import {
  expenseRateSheets,
  taskRateSheets,
  viewExpenseRateSheet,
  viewTaskRateSheet,
} from "~/routes";
import "./EditRateSheet.scss";
import FormBottomRow from "~/form-bottom-row";
import { ExpenseRateSheet, TaskRateSheet } from "~/gql/types";
import { Moment } from "moment";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";
import moment from "moment";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { useNavigate } from "react-router-dom";
import { useSnackBar } from "~/snackbar";
import { useEditExpenseRateSheetMutation } from "~/visuals/pages/ExpenseRates/ExpenseRateSheetRouter/editExpenseRateSheet.generated";
import { useEditTaskRateSheetMutationMutation } from "~/visuals/pages/TaskRates/TaskRateSheetRouter/editTaskRateSheet.generated";
import { MutationFunctionOptions } from "@apollo/client";

type EditSheetType = "Expense" | "Task";

type EditExpenseRateSheetProps = {
  useReload(): React.DispatchWithoutAction;
  rateSheet: ExpenseRateSheet | TaskRateSheet;
  type: EditSheetType;
};

type FormValues = {
  allowAssignment: boolean;
  endDate: Moment | null;
};

export const EditRateSheet: React.FC<EditExpenseRateSheetProps> = ({
  rateSheet,
  type,
  useReload,
}) => {
  const viewSheetRoute =
    type === "Expense"
      ? viewExpenseRateSheet.toRoute(rateSheet?.name)
      : viewTaskRateSheet.toRoute(rateSheet?.name);

  const navigate = useNavigate();
  const addAlert = useSnackBar();
  const reloadRateSheet = useReload();

  const rateSheetsRoute =
    type === "Expense" ? expenseRateSheets : taskRateSheets;
  const rateSheetsText = `${type} Rate Sheets`;

  useBreadcrumbs(
    [
      { text: rateSheetsText, to: rateSheetsRoute.path },
      { text: rateSheet?.name, to: viewSheetRoute.path },
      { text: "Edit" },
    ],
    [rateSheet]
  );

  const mutationHook =
    type === "Expense"
      ? useEditExpenseRateSheetMutation
      : useEditTaskRateSheetMutationMutation;

  const [doMutation, { loading }] = mutationHook();

  const onSuccess = () => {
    addAlert({
      isSuccess: true,
      message: `${rateSheet.name} updated`,
      key: `${Math.random()}`,
    });
    reloadRateSheet();
    navigate(viewSheetRoute.path);
  };

  const dataType = type === "Expense" ? "expenseRates" : "taskRates";
  const actionType = type === "Expense" ? "editRateSheet" : "editTaskRateSheet";

  const handleSubmit = async (values: FormValues) => {
    const defaultArgs = {
      allowAssignment: values.allowAssignment,
      endDate: values.endDate?.format("YYYY-MM-DD") ?? null,
    };

    const variables =
      type === "Expense"
        ? {
            ...defaultArgs,
            oldName: rateSheet?.name,
            newName: rateSheet?.name,
          }
        : {
            ...defaultArgs,
            name: rateSheet?.name,
          };

    const result = await doMutation({ variables } as MutationFunctionOptions<
      any,
      any
    >);

    return result?.data?.[dataType]?.[actionType];
  };

  const initialValues = {
    allowAssignment: rateSheet.allowAssignment,
    endDate: rateSheet.endDate ? moment(rateSheet.endDate) : null,
  };

  return (
    <div className="edit-expense-rate-sheet">
      <PageTitleRow title={`Edit ${rateSheet?.name}`} />
      <MutationForm
        initialValues={initialValues}
        runMutation={handleSubmit}
        onSuccess={onSuccess}
      >
        <ErrorRenderer
          render={(error) => (
            <FormCard>
              <BinaryPickerFormField
                formField="allowAssignment"
                label="Allow Assignment"
                helperText=""
              />
              <DatePickFormField
                formField="endDate"
                label="End Date"
                helperText="Select an expiration date for this rate sheet (optional)"
              />
              <FormBottomRow
                errorMessage={error}
                buttonText={"Save"}
                onCancel={() => navigate(viewSheetRoute.path)}
              />
            </FormCard>
          )}
        />
      </MutationForm>
      <Spinner open={loading} />
    </div>
  );
};
