import { FetchResult } from "@apollo/client";
import React from "react";
import { createContext, useContext } from "react";
import { ValidationError } from "~/gql/types";

export type RateSheetsContextApi = {
  reload: () => void;
  archive: (name: string) => Promise<ValidationError[]>;
  mutationLoading: boolean;
  create: (name: string) => Promise<FetchResult<any>>;
  clone: (newName: string, oldName: string) => Promise<FetchResult<any>>;
};

export const RateSheetsContext = createContext<RateSheetsContextApi | null>(
  null
);

export function useRateSheetsContext(): RateSheetsContextApi {
  const context = useContext(RateSheetsContext);

  if (!context) {
    throw new Error(
      "Do not use useRateSheetsContext outside of a RateSheetsContext provider."
    );
  }

  return context;
}

type MockProviderProps = {
  api?: Partial<RateSheetsContextApi>;
};

const defaultApi = {
  reload: () => undefined,
  archive: () => Promise.resolve([]),
  mutationLoading: false,
  create: () => Promise.resolve({}),
  clone: () => Promise.resolve({}),
};

export const MockRateSheetsContextProvider: React.FC<MockProviderProps> = ({
  api,
  children,
}) => (
  <RateSheetsContext.Provider
    value={
      api
        ? {
            ...defaultApi,
            ...api,
          }
        : defaultApi
    }
  >
    {children}
  </RateSheetsContext.Provider>
);
