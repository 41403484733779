import _ from "lodash";
import { useState } from "react";
import { useReloadProject } from "~/visuals/pages/Project/ProjectRouter";
import { useSnackBar } from "~/snackbar";
import { gql, useApolloClient } from "@apollo/client";
import { FieldState, FormState } from "~/inline-fields-form/types";

export type UseEditProjectProps = {
  formState: FormState;
  initialFieldState: FieldState[];
  projectNumber: number;
};

type UseEditReturn = {
  editProject: (props: UseEditProjectProps) => Promise<any>;
  loading: boolean;
};

export function useEditProject(): UseEditReturn {
  const client = useApolloClient();
  const addAlert = useSnackBar();
  const reloadProject = useReloadProject();
  const [loading, setLoading] = useState(false);

  const editProject = async (props: UseEditProjectProps) => {
    const { formState, initialFieldState, projectNumber } = props;

    let variableValues = {};
    let variableDeclarations: string[] = [];

    const mutations: string[] = [];
    Object.values(formState.fields).forEach(
      ({ name, value, mutation, toVariables, variables }) => {
        const initialValue = initialFieldState.find(
          (x) => x.name === name
        )?.value;

        if (!_.isEqual(initialValue, value)) {
          const fieldMutation = mutation!(value);
          mutations.push(fieldMutation);

          variableValues = { ...variableValues, ...toVariables!(value) };
          variableDeclarations = [...variableDeclarations, ...variables!];
        }
      }
    );

    if (mutations.length === 0) {
      return;
    }

    const variables =
      Object.keys(variableValues).length > 0 ? variableValues : undefined;
    const declarations =
      variableDeclarations.length > 0
        ? `(${variableDeclarations.join(", ")})`
        : "";

    const document = `
      mutation EditProject${declarations} {
        projects {
          ${mutations.join("\n")}
        }
      }
    `;

    setLoading(true);

    const result = await client.mutate({
      mutation: gql(document),
      variables,
    });

    setLoading(false);
    const data = result?.data?.projects;
    let errors: any[] = [];
    Object.values(data ?? {}).forEach((d: any) => {
      if (d?.errors && d.errors?.length > 0) {
        errors = [...errors, ...d.errors];
      }
    });

    if (errors.length > 0) {
      const errorMessages: string[] = [];
      errors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          errorMessages.push(`${argumentName}: ${message}`);
        } else if (message) {
          errorMessages.push(message);
        }
      });

      addAlert({
        key: `${Math.random()}`,
        message: errorMessages.join("; "),
        isSuccess: false,
      });
    } else {
      addAlert({
        key: `${Math.random()}`,
        message: `Project ${projectNumber} updated`,
        isSuccess: true,
      });
      reloadProject();
    }
  };

  return { editProject, loading };
}
