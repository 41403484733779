import React, { useEffect, useState } from "react";
import Spinner from "~/spinner";
import { useGetTpiUnreviewedQuery } from "./query.generated";
import { useUser } from "~/Auth/useUser";
import TitledCard from "~/titled-card";
import { Link } from "react-router-dom";
import List, { ListItem } from "@material/react-list";

const DataFetcher = ({ onChange }) => {
  const result = useGetTpiUnreviewedQuery();

  useEffect(() => {
    onChange(result);
  }, [result.loading, result.data]);

  return null;
};
type FetchResult = ReturnType<typeof useGetTpiUnreviewedQuery>;

export const ThirdPartyInvoiceUnreviewedCount: React.FC = () => {
  const user = useUser();

  const [{ data, loading }, setResult] = useState<FetchResult>({
    loading: true,
  } as any as FetchResult);
  if (!user.authorizations.approveThirdPartyInvoices) {
    return null;
  }
  const path = "/third-party-invoices?thirdPartyInvoiceState=Unreviewed";
  const text = `${data?.thirdPartyInvoices?.unreviewedCount?.count} Unreviewed Third Party Invoices`;
  return (
    <div>
      <DataFetcher onChange={setResult} />
      <TitledCard
        title="Unreviewed Third Party Invoices"
        className="unreviewed-tpi"
      >
        <List>
          <Link to={path}>
            <ListItem>{text}</ListItem>
          </Link>
        </List>
      </TitledCard>
      <Spinner open={loading} />
    </div>
  );
};
