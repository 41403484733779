import { timesheetReview } from "~/visuals/pages/TimesheetReview/routes";

const encode = (x: string) => encodeURIComponent(x);

export const reviewWorkDataRoute = {
  path: `${timesheetReview}/:userPrincipalName`,
  toRoute: (upn: string): string => `${timesheetReview}/${upn}`,
};

export const myWorkDataRoute = {
  path: "/my-work/:userPrincipalName",
  toRoute: (upn: string): string => `/my-work/${upn}`,
};

export const expenseRoutes = {
  atv: "atv",
  adHoc: "adHoc",
  fuel: "fuel",
  mileage: "mileage",
  mobileAllowance: "mobile-allowance",
  perDiem: "per-diem",
  receiptValued: "receipt-valued",
  tcpAllowance: "tcp-allowance",
};

export const myWorkDayRoute = {
  path: "/my-work-day/:userPrincipalName/:date",
  toRoute: (upn: string, date: string): string =>
    `/my-work-day/${upn}/${encode(date)}`,
};

export const reviewWorkDayRoute = {
  path: `${timesheetReview}/:userPrincipalName/:date`,
  toRoute: (upn: string, date: string): string =>
    `${timesheetReview}/${upn}/${encode(date)}`,
};

export const weeklyProjectTasks = "weekly-project-tasks";
