import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignTaskRateSheetToProjectMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  projectNumbers: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
  effectiveDate: Types.Scalars['LocalDate'];
}>;


export type AssignTaskRateSheetToProjectMutation = { __typename?: 'MutationModel', taskRates?: { __typename?: 'TaskRatesMutationModel', assignRateSheetToProject?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const AssignTaskRateSheetToProjectDocument = gql`
    mutation AssignTaskRateSheetToProject($rateSheetName: String!, $projectNumbers: [Int!]!, $effectiveDate: LocalDate!) {
  taskRates {
    assignRateSheetToProject(
      rateSheetName: $rateSheetName
      projectNumbers: $projectNumbers
      effectiveDate: $effectiveDate
    ) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type AssignTaskRateSheetToProjectMutationFn = Apollo.MutationFunction<AssignTaskRateSheetToProjectMutation, AssignTaskRateSheetToProjectMutationVariables>;

/**
 * __useAssignTaskRateSheetToProjectMutation__
 *
 * To run a mutation, you first call `useAssignTaskRateSheetToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTaskRateSheetToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTaskRateSheetToProjectMutation, { data, loading, error }] = useAssignTaskRateSheetToProjectMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      projectNumbers: // value for 'projectNumbers'
 *      effectiveDate: // value for 'effectiveDate'
 *   },
 * });
 */
export function useAssignTaskRateSheetToProjectMutation(baseOptions?: Apollo.MutationHookOptions<AssignTaskRateSheetToProjectMutation, AssignTaskRateSheetToProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignTaskRateSheetToProjectMutation, AssignTaskRateSheetToProjectMutationVariables>(AssignTaskRateSheetToProjectDocument, options);
      }
export type AssignTaskRateSheetToProjectMutationHookResult = ReturnType<typeof useAssignTaskRateSheetToProjectMutation>;
export type AssignTaskRateSheetToProjectMutationResult = Apollo.MutationResult<AssignTaskRateSheetToProjectMutation>;
export type AssignTaskRateSheetToProjectMutationOptions = Apollo.BaseMutationOptions<AssignTaskRateSheetToProjectMutation, AssignTaskRateSheetToProjectMutationVariables>;