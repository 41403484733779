import React from "react";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import { UltraFilter } from "~/ultra-filter";
import {
  Customer,
  Project,
  ThirdPartyInvoiceFilterState,
  ThirdPartyInvoiceSortBy,
} from "~/gql/types";
import { Moment } from "moment";
import { useDateProvider } from "~/filterProviders/useDateProvider";
import { useCustomerProvider } from "~/filterProviders/useCustomerProvider";
import { useProjectProvider } from "~/filterProviders/useProjectProvider";
import { useThirdPartyInvoiceStateProvider } from "~/filterProviders/itemStateProviders";
import { useSearchParams } from "react-router-dom";

export type FilterOptions = {
  searchText: string | null;
  state: ThirdPartyInvoiceFilterState | null;
  project: Project | null;
  customer: Customer | null;
  dateEqual: Moment | null;
  dateBefore: Moment | null;
  dateAfter: Moment | null;
  sortBy: ThirdPartyInvoiceSortBy;
};

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<FilterOptions>;
};

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const [searchParams] = useSearchParams();

  const providers = [
    useThirdPartyInvoiceStateProvider({
      documentationText: "The invoice's state",
    }),
    useProjectProvider({
      documentationText: "The invoice's project",
    }),
    useDateProvider({
      documentationText:
        "Search for invoices with date after this date (exclusive).",
      type: "dateAfter",
      label: "After Date",
      suggestText: "aft",
    }),
    useDateProvider({
      documentationText:
        "Search for invoices with date before this date (exclusive).",
      type: "dateBefore",
      label: "Before Date",
      suggestText: "bef",
    }),
    useDateProvider({
      documentationText: "Search for invoices with this specific date.",
      type: "dateEqual",
      label: "Actual Date",
      suggestText: "date",
    }),
    useCustomerProvider({
      documentationText: "The customer associated with the invoice",
    }),
  ] as FilterProvider[];

  const onChanged = (filters: ActiveFilter[], searchText: string) => {
    searchText;

    const newFilters = {
      searchText: searchText || null,
      project: filters.find((f) => f.type === "project")?.value || null,
      state:
        filters.find((f) => f.type === "thirdPartyInvoiceState")?.value || null,
      customer: filters.find((f) => f.type === "customer")?.value || null,
      dateAfter: filters.find((f) => f.type === "dateAfter")?.value || null,
      dateBefore: filters.find((f) => f.type === "dateBefore")?.value || null,
      dateEqual: filters.find((f) => f.type === "dateEqual")?.value || null,
      sortBy: (searchParams.get("sortBy") || "Date") as ThirdPartyInvoiceSortBy,
    };

    onFiltersChanged(newFilters);
  };

  return (
    <div className="filter-form">
      <UltraFilter
        {...{
          providers,
          dialogTitle: "Third Party Invoices Search",
          label: "Type to search invoices",
          onFiltersChanged: onChanged,
        }}
      />
    </div>
  );
};
