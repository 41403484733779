import React from "react";
import cn from "classnames";
import "./CardTopRow.scss";

type CardTopRowProps = {
  alignRight?: boolean;
};

const CardTopRow: React.FC<CardTopRowProps> = ({ alignRight, children }) => {
  const className = cn("card-top-row", { "align-right": alignRight });

  return <div className={className}>{children}</div>;
};

export default CardTopRow;
