import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvoicesSearchQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
  state?: Types.InputMaybe<Types.InvoiceFilterState>;
  billingAdmin?: Types.InputMaybe<Types.Scalars['String']>;
  projectManager?: Types.InputMaybe<Types.Scalars['String']>;
  projectSupervisor?: Types.InputMaybe<Types.Scalars['String']>;
  customerNumber?: Types.InputMaybe<Types.Scalars['String']>;
  projectNumber?: Types.InputMaybe<Types.Scalars['Int']>;
  startDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  endDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  actualDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  startCancelledDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  endCancelledDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  actualCancelledDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  amount?: Types.InputMaybe<Types.Scalars['Decimal']>;
  officeCode?: Types.InputMaybe<Types.Scalars['String']>;
  locationState?: Types.InputMaybe<Types.Scalars['String']>;
  afeWoPo?: Types.InputMaybe<Types.Scalars['String']>;
  token?: Types.InputMaybe<Types.Scalars['String']>;
  invoiceNumbers?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.InvoiceSortBy>;
  projectGroupArgs?: Types.InputMaybe<Types.ProjectGroupArgsInput>;
}>;


export type InvoicesSearchQuery = { __typename?: 'QueryModel', invoices?: { __typename?: 'InvoicesQueryModel', search?: { __typename?: 'ConnectionOfInvoice', token?: string | null, records?: Array<{ __typename?: 'Invoice', invoiceNumber: string, projectNumber: number, state: Types.InvoiceState, cancelledDate?: string | null, invoiceDate?: string | null, notes?: string | null, projectName: string, customerName: string, customerNumber: string, corpLocationId: string, divisionId: string, officeCode: string, total: number, taxDescription: string, canPostToRollup: boolean, lineItems?: Array<{ __typename?: 'InvoiceLineItem', invoiceNumber: string, projectChargeId: number, label: string, notes?: string | null, rate: number, quantity: number, billable: boolean, date: string, rebill?: { __typename?: 'LineItemRebillInfo', rebilledFromInvoice?: string | null, rebilledOnInvoice?: string | null } | null, projectCharge?: { __typename?: 'ProjectCharge', crewCode: string, employee?: { __typename?: 'Employee', ultiProId: string } | null } | null } | null> | null, project?: { __typename?: 'Project', number: number, projectGroup?: { __typename?: 'ProjectGroup', id: number, name: string } | null } | null, customRollupInvoice?: { __typename?: 'CustomRollupInvoice', invoiceNumber: string } | null } | null> | null } | null } | null };

export type InvoicesSearchWithStatsQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
  state?: Types.InputMaybe<Types.InvoiceFilterState>;
  billingAdmin?: Types.InputMaybe<Types.Scalars['String']>;
  projectManager?: Types.InputMaybe<Types.Scalars['String']>;
  projectSupervisor?: Types.InputMaybe<Types.Scalars['String']>;
  customerNumber?: Types.InputMaybe<Types.Scalars['String']>;
  projectNumber?: Types.InputMaybe<Types.Scalars['Int']>;
  startDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  endDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  actualDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  startCancelledDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  endCancelledDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  actualCancelledDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  amount?: Types.InputMaybe<Types.Scalars['Decimal']>;
  officeCode?: Types.InputMaybe<Types.Scalars['String']>;
  locationState?: Types.InputMaybe<Types.Scalars['String']>;
  afeWoPo?: Types.InputMaybe<Types.Scalars['String']>;
  token?: Types.InputMaybe<Types.Scalars['String']>;
  invoiceNumbers?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.InvoiceSortBy>;
  projectGroupArgs?: Types.InputMaybe<Types.ProjectGroupArgsInput>;
}>;


export type InvoicesSearchWithStatsQuery = { __typename?: 'QueryModel', invoices?: { __typename?: 'InvoicesQueryModel', search?: { __typename?: 'ConnectionOfInvoice', token?: string | null, records?: Array<{ __typename?: 'Invoice', invoiceNumber: string, projectNumber: number, state: Types.InvoiceState, invoiceDate?: string | null, notes?: string | null, cancelledDate?: string | null, projectName: string, customerName: string, customerNumber: string, corpLocationId: string, divisionId: string, officeCode: string, total: number, taxDescription: string, canPostToRollup: boolean, lineItems?: Array<{ __typename?: 'InvoiceLineItem', invoiceNumber: string, projectChargeId: number, label: string, notes?: string | null, rate: number, quantity: number, billable: boolean, date: string, rebill?: { __typename?: 'LineItemRebillInfo', rebilledFromInvoice?: string | null, rebilledOnInvoice?: string | null } | null, projectCharge?: { __typename?: 'ProjectCharge', crewCode: string, employee?: { __typename?: 'Employee', ultiProId: string } | null } | null } | null> | null, project?: { __typename?: 'Project', number: number, officeCode: string, projectGroup?: { __typename?: 'ProjectGroup', id: number, name: string } | null } | null, customRollupInvoice?: { __typename?: 'CustomRollupInvoice', invoiceNumber: string } | null } | null> | null } | null, stats?: { __typename?: 'InvoiceSearchStats', invoiceCount: number } | null } | null };


export const InvoicesSearchDocument = gql`
    query InvoicesSearch($searchText: String, $state: InvoiceFilterState, $billingAdmin: String, $projectManager: String, $projectSupervisor: String, $customerNumber: String, $projectNumber: Int, $startDate: LocalDate, $endDate: LocalDate, $actualDate: LocalDate, $startCancelledDate: LocalDate, $endCancelledDate: LocalDate, $actualCancelledDate: LocalDate, $amount: Decimal, $officeCode: String, $locationState: String, $afeWoPo: String, $token: String, $invoiceNumbers: [String!], $sortBy: InvoiceSortBy, $projectGroupArgs: ProjectGroupArgsInput) {
  invoices {
    search(
      searchText: $searchText
      state: $state
      projectNumber: $projectNumber
      startDate: $startDate
      actualDate: $actualDate
      endDate: $endDate
      startCancelledDate: $startCancelledDate
      actualCancelledDate: $actualCancelledDate
      endCancelledDate: $endCancelledDate
      billingAdmin: $billingAdmin
      projectManager: $projectManager
      projectSupervisor: $projectSupervisor
      customerNumber: $customerNumber
      amount: $amount
      locationState: $locationState
      afeWoPo: $afeWoPo
      officeCode: $officeCode
      token: $token
      invoiceNumbers: $invoiceNumbers
      sortBy: $sortBy
      projectGroupArgs: $projectGroupArgs
    ) {
      token
      records {
        invoiceNumber
        projectNumber
        state
        cancelledDate
        invoiceDate
        notes
        lineItems {
          rebill {
            rebilledFromInvoice
            rebilledOnInvoice
          }
          invoiceNumber
          projectChargeId
          label
          notes
          rate
          quantity
          billable
          date
          projectCharge {
            employee {
              ultiProId
            }
            crewCode
          }
        }
        projectName
        customerName
        customerNumber
        corpLocationId
        divisionId
        officeCode
        project {
          number
          projectGroup {
            id
            name
          }
        }
        total
        taxDescription
        canPostToRollup
        customRollupInvoice {
          invoiceNumber
        }
      }
    }
  }
}
    `;

/**
 * __useInvoicesSearchQuery__
 *
 * To run a query within a React component, call `useInvoicesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesSearchQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      state: // value for 'state'
 *      billingAdmin: // value for 'billingAdmin'
 *      projectManager: // value for 'projectManager'
 *      projectSupervisor: // value for 'projectSupervisor'
 *      customerNumber: // value for 'customerNumber'
 *      projectNumber: // value for 'projectNumber'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      actualDate: // value for 'actualDate'
 *      startCancelledDate: // value for 'startCancelledDate'
 *      endCancelledDate: // value for 'endCancelledDate'
 *      actualCancelledDate: // value for 'actualCancelledDate'
 *      amount: // value for 'amount'
 *      officeCode: // value for 'officeCode'
 *      locationState: // value for 'locationState'
 *      afeWoPo: // value for 'afeWoPo'
 *      token: // value for 'token'
 *      invoiceNumbers: // value for 'invoiceNumbers'
 *      sortBy: // value for 'sortBy'
 *      projectGroupArgs: // value for 'projectGroupArgs'
 *   },
 * });
 */
export function useInvoicesSearchQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesSearchQuery, InvoicesSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesSearchQuery, InvoicesSearchQueryVariables>(InvoicesSearchDocument, options);
      }
export function useInvoicesSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesSearchQuery, InvoicesSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesSearchQuery, InvoicesSearchQueryVariables>(InvoicesSearchDocument, options);
        }
export type InvoicesSearchQueryHookResult = ReturnType<typeof useInvoicesSearchQuery>;
export type InvoicesSearchLazyQueryHookResult = ReturnType<typeof useInvoicesSearchLazyQuery>;
export type InvoicesSearchQueryResult = Apollo.QueryResult<InvoicesSearchQuery, InvoicesSearchQueryVariables>;
export const InvoicesSearchWithStatsDocument = gql`
    query InvoicesSearchWithStats($searchText: String, $state: InvoiceFilterState, $billingAdmin: String, $projectManager: String, $projectSupervisor: String, $customerNumber: String, $projectNumber: Int, $startDate: LocalDate, $endDate: LocalDate, $actualDate: LocalDate, $startCancelledDate: LocalDate, $endCancelledDate: LocalDate, $actualCancelledDate: LocalDate, $amount: Decimal, $officeCode: String, $locationState: String, $afeWoPo: String, $token: String, $invoiceNumbers: [String!], $sortBy: InvoiceSortBy, $projectGroupArgs: ProjectGroupArgsInput) {
  invoices {
    search(
      searchText: $searchText
      state: $state
      projectNumber: $projectNumber
      startDate: $startDate
      actualDate: $actualDate
      endDate: $endDate
      startCancelledDate: $startCancelledDate
      actualCancelledDate: $actualCancelledDate
      endCancelledDate: $endCancelledDate
      billingAdmin: $billingAdmin
      projectManager: $projectManager
      projectSupervisor: $projectSupervisor
      customerNumber: $customerNumber
      amount: $amount
      locationState: $locationState
      afeWoPo: $afeWoPo
      officeCode: $officeCode
      token: $token
      invoiceNumbers: $invoiceNumbers
      sortBy: $sortBy
      projectGroupArgs: $projectGroupArgs
    ) {
      token
      records {
        invoiceNumber
        projectNumber
        state
        invoiceDate
        notes
        cancelledDate
        lineItems {
          invoiceNumber
          projectChargeId
          label
          notes
          rate
          quantity
          billable
          date
          rebill {
            rebilledFromInvoice
            rebilledOnInvoice
          }
          projectCharge {
            employee {
              ultiProId
            }
            crewCode
          }
        }
        projectName
        customerName
        customerNumber
        corpLocationId
        divisionId
        officeCode
        project {
          number
          officeCode
          projectGroup {
            id
            name
          }
        }
        total
        taxDescription
        canPostToRollup
        customRollupInvoice {
          invoiceNumber
        }
      }
    }
    stats(
      searchText: $searchText
      state: $state
      projectNumber: $projectNumber
      startDate: $startDate
      actualDate: $actualDate
      startCancelledDate: $startCancelledDate
      actualCancelledDate: $actualCancelledDate
      endCancelledDate: $endCancelledDate
      billingAdmin: $billingAdmin
      projectManager: $projectManager
      projectSupervisor: $projectSupervisor
      customerNumber: $customerNumber
      amount: $amount
      endDate: $endDate
      locationState: $locationState
      afeWoPo: $afeWoPo
      officeCode: $officeCode
    ) {
      invoiceCount
    }
  }
}
    `;

/**
 * __useInvoicesSearchWithStatsQuery__
 *
 * To run a query within a React component, call `useInvoicesSearchWithStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesSearchWithStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesSearchWithStatsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      state: // value for 'state'
 *      billingAdmin: // value for 'billingAdmin'
 *      projectManager: // value for 'projectManager'
 *      projectSupervisor: // value for 'projectSupervisor'
 *      customerNumber: // value for 'customerNumber'
 *      projectNumber: // value for 'projectNumber'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      actualDate: // value for 'actualDate'
 *      startCancelledDate: // value for 'startCancelledDate'
 *      endCancelledDate: // value for 'endCancelledDate'
 *      actualCancelledDate: // value for 'actualCancelledDate'
 *      amount: // value for 'amount'
 *      officeCode: // value for 'officeCode'
 *      locationState: // value for 'locationState'
 *      afeWoPo: // value for 'afeWoPo'
 *      token: // value for 'token'
 *      invoiceNumbers: // value for 'invoiceNumbers'
 *      sortBy: // value for 'sortBy'
 *      projectGroupArgs: // value for 'projectGroupArgs'
 *   },
 * });
 */
export function useInvoicesSearchWithStatsQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesSearchWithStatsQuery, InvoicesSearchWithStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesSearchWithStatsQuery, InvoicesSearchWithStatsQueryVariables>(InvoicesSearchWithStatsDocument, options);
      }
export function useInvoicesSearchWithStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesSearchWithStatsQuery, InvoicesSearchWithStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesSearchWithStatsQuery, InvoicesSearchWithStatsQueryVariables>(InvoicesSearchWithStatsDocument, options);
        }
export type InvoicesSearchWithStatsQueryHookResult = ReturnType<typeof useInvoicesSearchWithStatsQuery>;
export type InvoicesSearchWithStatsLazyQueryHookResult = ReturnType<typeof useInvoicesSearchWithStatsLazyQuery>;
export type InvoicesSearchWithStatsQueryResult = Apollo.QueryResult<InvoicesSearchWithStatsQuery, InvoicesSearchWithStatsQueryVariables>;