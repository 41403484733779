import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getExpiration } from "./jwtUtils";
import { useRawJwt } from "./AuthenticationManager";
import Card from "@material/react-card";
import { Body1 } from "@material/react-typography";
import { useClearActive, useIsActive } from "./useIsActive";
import "./TestAuth.scss";
import { ClientForm } from "~/forms/ClientForm";
import { ValueRenderer } from "~/forms/ValueContext";
import TextFormField from "~/text-form-field";
import Updater from "~/forms/Updater";
import { useDebounce } from "@react-hook/debounce";
import Button from "~/button";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";

export const TestAuth: React.FC = () => {
  const jwt = useRawJwt();
  const isActive = useIsActive();
  const clearActive = useClearActive();

  const [searchParams, setSearchParams] = useSearchParams();
  const [expireThreshold, setExpireThreshold] = useDebounce("600", 500);
  const [tokenDelay, setTokenDelay] = useState(false);

  const getExpiresIn = () => {
    const expireTime = getExpiration(jwt!).getTime();
    const nowTime = new Date().getTime();

    const nearExpireThreshold = parseInt(
      searchParams.get("nearExpireThreshold") || "600"
    );

    return Math.round((expireTime - nowTime) / 1000 - nearExpireThreshold);
  };

  const [expiresIn, setExpiresIn] = useState(getExpiresIn());

  useEffect(() => {
    const interval = setInterval(() => {
      setExpiresIn(getExpiresIn());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [searchParams, jwt]);

  useEffect(() => {
    setSearchParams({
      nearExpireThreshold: expireThreshold || "600",
      tokenDelay: tokenDelay ? "true" : "false",
    });
  }, [expireThreshold, tokenDelay]);

  return (
    <Card className="test-auth">
      <Body1>Near expire in: {expiresIn}s</Body1>

      <Body1>User active: {isActive ? "Yes" : "No"}</Body1>

      <ClientForm
        initialValues={{ threshold: "600", tokenDelay: false }}
        onSuccess={() => undefined}
      >
        <ValueRenderer
          render={(value) => (
            <>
              <TextFormField
                formField={"threshold"}
                label={"Near Expire Threshold"}
                helperText={""}
              />
              <BinaryPickerFormField
                formField={"delay"}
                label={"Delay Token Dispatch"}
                helperText={""}
              />
              <Updater
                value={value.threshold}
                callback={(val) => setExpireThreshold(val)}
              />
              <Updater
                value={value.delay}
                callback={(val) => setTokenDelay(val)}
              />
            </>
          )}
        />
      </ClientForm>

      <Button onClick={clearActive}>Clear Active</Button>
    </Card>
  );
};
