import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { createBroadcastChannel } from "~/broadcast-channel/createBroadcastChannel";
import { v4 } from "uuid";

export const activeInterval = 1000 * 20 * 60;
export const activeTrackerChannel = "active-tracking";
const message = "user active";
const originatorKey = v4();

declare global {
  interface Window {
    activityLoggingEnabled: boolean;
  }
}

type Context = {
  isActive: boolean;
  setIsActive: React.Dispatch<boolean>;
};

const UserActiveContext = createContext<Context>({
  isActive: true,
  setIsActive: () => false,
});

const logActivity = (message: string) => {
  if (window.activityLoggingEnabled) {
    console.log(message);
  }
};

export type ActiveMessage = {
  message: string;
  originator: string;
};

export const UserActiveProvider: React.FC = ({ children }) => {
  const [isActive, setIsActive] = useState(true);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const channel = createBroadcastChannel(activeTrackerChannel);

    const createTimeout = () =>
      setTimeout(() => {
        setIsActive(false);
        logActivity("user inactive");
      }, activeInterval);

    const resetActive = () => {
      setIsActive(true);

      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = createTimeout();
      }

      logActivity(message);
    };

    const onActivity = () => {
      resetActive();

      channel.postMessage({
        originator: originatorKey,
        message,
      });
    };

    timerRef.current = createTimeout();

    window.addEventListener("click", onActivity, { capture: true });
    window.addEventListener("keydown", onActivity, { capture: true });

    channel.onmessage = (msg) => {
      const payload = msg.data || msg;
      if (payload.originator === originatorKey) {
        return;
      }

      resetActive();
    };

    return () => {
      channel.close();

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      window.removeEventListener("click", onActivity, { capture: true });
      window.removeEventListener("keydown", onActivity, { capture: true });
    };
  }, []);

  return (
    <UserActiveContext.Provider value={{ isActive, setIsActive }}>
      {children}
    </UserActiveContext.Provider>
  );
};

export function useIsActive(): boolean {
  const { isActive } = useContext(UserActiveContext);

  return isActive;
}

export function useClearActive(): () => void {
  const { setIsActive } = useContext(UserActiveContext);

  return () => {
    setTimeout(() => {
      setIsActive(false);
      logActivity("user inactive");
    }, 50);
  };
}
