import moment from "moment";
import React, { useEffect, useReducer } from "react";
import Button from "~/button";
import { Placeholder } from "~/visuals/pages/Project/ProjectDetails/Placeholder";
import TableCell from "~/old-table-cell";
import TableContent from "~/old-table-content";
import TableHeader from "~/old-table-header";
import Spinner from "~/spinner";
import Table from "~/old-table";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import "./AddCharges.scss";
import { Action, ChargeItem, reducer, State } from "./addChargesStateMachine";
import { BottomButtons } from "~/bottom-buttons";
import { useSnackBar } from "~/snackbar";
import { useAddChargesToInvoiceMutation } from "~/project-charge-form/addCharges.generated";
import {
  AddChargesResponse,
  ProjectCharge,
  ProjectChargeFilterState,
  ValidationError,
} from "~/gql/types";
import { useChargesFromProjectLazyQuery } from "./chargesFromProject.generated";
import { OnChangeForm } from "~/forms/OnChangeForm";

export type AddChargesProps = {
  open: boolean;
  onClose: () => void;
  projectNumber: number;
  invoiceNumber: string | null;
  refetch: React.DispatchWithoutAction;
  preloadedCharges?: ProjectCharge[];
  onSuccess?: () => void;
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const AddChargeForm: React.FC<AddChargeRowProps> = ({ item, dispatch }) => {
  const handleChange = () => {
    dispatch({ tag: "UpdateItemChecked", index: item.index });
  };

  return (
    <OnChangeForm onChange={handleChange} values={{ addCharge: item.checked }}>
      <BinaryPickerFormField
        key={`${item.charge.id}`}
        formField="addCharge"
        label=""
        helperText=""
      />
    </OnChangeForm>
  );
};

const SelectAllForm: React.FC<{
  state: State;
  dispatch: React.Dispatch<Action>;
}> = ({ dispatch, state }) => {
  const handleChange = () => {
    if (state.tag !== "AllSelected") {
      dispatch({ tag: "SelectAll" });
    } else {
      dispatch({ tag: "DeselectAll" });
    }
  };
  return (
    <OnChangeForm
      onChange={handleChange}
      values={{ selectAll: state.tag === "AllSelected" }}
    >
      <BinaryPickerFormField
        key="select-all-charges"
        formField="selectAll"
        label=""
        helperText=""
      />
    </OnChangeForm>
  );
};

type AddChargeRowProps = {
  item: ChargeItem;
  dispatch: React.Dispatch<Action>;
};

const AddChargeRow: React.FC<AddChargeRowProps> = ({ item, dispatch }) => {
  const { charge } = item;

  return (
    <TableContent selectedIndex={[0]}>
      <TableCell>
        <AddChargeForm {...{ item, dispatch }} />
      </TableCell>
      <TableCell text={charge.label} />
      <TableCell text={moment(charge.date).format("MM/DD/YYYY")} />
      <TableCell className="notes" text={charge.notes ?? ""} />
      <TableCell text={formatter.format(charge.rate)} />
      <TableCell text={`${charge.quantity}`} />
      <TableCell text={formatter.format(charge.total ?? 0)} />
      <TableCell text={charge.billable ? "Yes" : "No"} />
    </TableContent>
  );
};

type LoadChargesProps = {
  dispatch: React.Dispatch<Action>;
  projectNumber: number;
  preloadedCharges?: ProjectCharge[];
};

const LoadCharges = ({
  dispatch,
  projectNumber,
  preloadedCharges,
}: LoadChargesProps) => {
  const [getSheet] = useChargesFromProjectLazyQuery();

  useEffect(() => {
    if (!preloadedCharges) {
      void (async () => {
        const variables = {
          state: ProjectChargeFilterState.Unbilled,
          projectNumber,
          allBillable: true,
        };

        const result = await getSheet({ variables });

        const chargesResult = result.data?.projects?.project?.charges
          ?.records as ProjectCharge[];

        dispatch({ tag: "DataLoaded", items: chargesResult });
      })();

      return;
    }

    dispatch({ tag: "DataLoaded", items: preloadedCharges });
  }, []);

  return <></>;
};

type ChargesDisplay = {
  state: State;
  dispatch: React.Dispatch<Action>;
  onClose: () => void;
};

const ChargesDisplay: React.FC<ChargesDisplay> = ({
  state,
  dispatch,
  onClose,
}) => {
  if (!state.items || state.items.length === 0) {
    return null;
  }

  const close = () => {
    dispatch({ tag: "Reset" });
    onClose();
  };

  return (
    <>
      <Table selectedIndex={[0]}>
        <TableHeader selectedIndex={[0]}>
          <TableCell>
            <SelectAllForm {...{ state, dispatch }} />
          </TableCell>
          <TableCell text="Label" />
          <TableCell text="Date" />
          <TableCell text="Notes" />
          <TableCell text="Rate" />
          <TableCell text="Qty" />
          <TableCell text="Total" />
          <TableCell text="Billable" />
        </TableHeader>
        {state.items.map((item) => (
          <AddChargeRow key={item.charge.id} {...{ item, dispatch }} />
        ))}
      </Table>
      <div className="bottom-row">
        <Button primary onClick={() => dispatch({ tag: "Submit" })}>
          Add
        </Button>
        <Button onClick={close}>Cancel</Button>
      </div>
    </>
  );
};

const AddCharges: React.FC<AddChargesProps> = ({
  open,
  onClose,
  projectNumber,
  invoiceNumber,
  refetch,
  preloadedCharges,
  onSuccess,
}) => {
  const initialState = {
    tag: "Initial",
    items: [],
    loading: true,
    submitting: false,
  } as State;

  const addAlert = useSnackBar();
  const [state, dispatch] = useReducer(
    (a: State, b: Action) => reducer(a, b),
    initialState
  );

  const [doMutation, { loading }] = useAddChargesToInvoiceMutation();

  const handleSubmit = async () => {
    if (state.tag === "Submitting") {
      const charges = state.queue.map((x) => x.charge.id);
      const projNum =
        projectNumber != state.queue[0].charge.projectId ? projectNumber : null;

      const variables = {
        charges,
        invoiceNumber,
        projectNumber: projNum,
      };

      const result = await doMutation({ variables });

      const data = result?.data?.invoices?.addCharges as AddChargesResponse;

      const resultInvoiceNumber = data?.invoiceNumber;

      const errors = (result?.errors ??
        data?.errors ??
        []) as ValidationError[];

      if (errors.length > 0) {
        const formErrors: string[] = [];
        errors.forEach(({ argumentName, message }) => {
          if (argumentName) {
            formErrors.push(`${argumentName}: ${message}`);
          } else if (message) {
            formErrors.push(message);
          }
        });

        addAlert({
          key: `${Math.random()}`,
          message: formErrors.join(" "),
          isSuccess: false,
        });
      } else if (!resultInvoiceNumber) {
        addAlert({
          key: `${Math.random()}`,
          message: "There was an error adding charges",
          isSuccess: false,
        });
      } else {
        addAlert({
          key: resultInvoiceNumber,
          message: `${charges.length} charge${
            charges.length === 1 ? "" : "s"
          } added to invoice ${resultInvoiceNumber}`,
          isSuccess: true,
        });
        dispatch({ tag: "AllSubmitted" });
      }
    }
  };

  useEffect(() => {
    if (state.tag === "Submitting") {
      void (async () => await handleSubmit())();
      return;
    }
    if (state.tag === "Submitted") {
      dispatch({ tag: "Reset" });
      refetch();

      onSuccess ? onSuccess() : onClose();
    }
  }, [state.tag]);

  const close = () => {
    dispatch({ tag: "Reset" });
    onClose();
  };

  return (
    <div className="add-invoice-charge">
      {state.tag === "Initial" && open && (
        <LoadCharges {...{ dispatch, projectNumber, preloadedCharges }} />
      )}
      <Spinner open={loading || (state.loading && open)} />

      <ChargesDisplay {...{ onClose, state, dispatch }} />

      {!state.loading && (!state.items || state.items.length === 0) && (
        <>
          <Placeholder>(no unbilled charges)</Placeholder>
          <BottomButtons>
            <Button onClick={close}>Go Back</Button>
          </BottomButtons>
        </>
      )}
    </div>
  );
};

export default AddCharges;
