import React, { useEffect, useMemo, useState } from "react";
import { useDataSource } from "~/refdata";
import { workLocations as workLocationsDataSource } from "~/refdata/sources";
import { useDebounce } from "@react-hook/debounce";
import DropdownFormField, {
  OptionProps,
} from "~/visuals/organisms/DropdownFormField";
import TextFormField from "~/text-form-field";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import { NotifierForm } from "~/forms/NotifierForm";

export type FilterOptions = {
  workLocation: string | null;
  searchText: string;
  lastName: string | null;
};

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<FilterOptions>;
};

const allSentinel = "all";
export const allLabel = "(All)";
export const searchTextLabel = "(search by name or email)";
export const debounceInterval = 500;

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const workLocations = useDataSource(workLocationsDataSource);
  const officeCodeOptions: OptionProps[] = useMemo(
    () => [
      { label: allLabel, value: allSentinel },
      ...(workLocations || []).map((x) => ({ label: x, value: x })),
    ],
    [workLocations]
  );

  const [formState, setFormState] = useState<FilterOptions>();
  const [searchText, setSearchText] = useDebounce<string>(
    searchParams.get("searchText") ?? "",
    debounceInterval
  );
  const lastName = searchParams.get("lastName") ?? null;

  useEffect(() => {
    if (formState) {
      setSearchParams({
        workLocation: formState.workLocation ?? "",
        searchText: searchText ?? "",
        lastName: lastName ?? "",
      });
      onFiltersChanged({ ...formState, searchText });
    }
  }, [formState, searchText]);

  const searchParamValueOrNull = (key: string) => {
    return searchParams.get(key)?.length ? searchParams.get(key) : null;
  };

  const initialValues = useMemo(
    () => ({
      searchText: searchParams.get("searchText") ?? "",
      workLocation: searchParamValueOrNull("workLocation") ?? "all",
    }),
    []
  );

  const onTextChange = (values: { searchText: string }) => {
    setSearchText(values.searchText);
  };

  const onWorkLocationChange = (values: { workLocation: string }) => {
    setFormState({
      searchText: "",
      workLocation:
        values.workLocation === allSentinel ? null : values.workLocation,
      lastName,
    });
  };

  return (
    <>
      <NotifierForm
        onChange={onTextChange}
        values={{ searchText: initialValues.searchText }}
      >
        <TextFormField
          label={searchTextLabel}
          formField="searchText"
          helperText=""
        />
      </NotifierForm>
      <NotifierForm
        onChange={onWorkLocationChange}
        values={{ workLocation: initialValues.workLocation }}
      >
        {workLocations && (
          <DropdownFormField
            label="Work Location"
            formField="workLocation"
            helperText=""
            options={officeCodeOptions}
          />
        )}
      </NotifierForm>
    </>
  );
};
