import moment, { Moment } from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageTitleRow from "~/page-title-row";
import { TableCell } from "~/table";
import { useWeeklyProjectTasksQuery } from "./query.generated";
import Card from "@material/react-card";
import { Body2 } from "@material/react-typography";
import "./WeeklyProjectTasks.scss";
import _ from "lodash";
import { durationDisplay } from "~/visuals/organisms/WorkDay";
import Spinner from "~/spinner";
import { useUser } from "~/Auth/useUser";
import { ProjectTimeEntry } from "~/gql/types";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { createWorkDayBreadcrumbs } from "~/WorkDayApi/WorkDayApi";
import { addDurations } from "~/visuals/organisms/TimesheetDisplay";
import Button from "~/button";
import MaterialIcon from "@material/react-material-icon";
import { myWorkDayRoute, reviewWorkDayRoute } from "~/WorkData/routes";
import {
  createProjectTimeEntry,
  editProjectTimeEntry,
} from "~/visuals/organisms/WorkDay/ProjectTimeCard";
import { useRelativeNav } from "~/relative-nav";
import { CopyPreviousWeekProjectTime } from "./CopyPreviousWeekProjectTime";

type WeeklyProjectTasksRouteData = {
  userPrincipalName: string;
  weekStart: string;
};

export const produceWeeklyWorkDays = (weekStart: string): Moment[] =>
  new Array(7)
    .fill(0)
    .map((_, idx) => moment(weekStart, "YYYY-MM-DD").add(idx, "days"));

const produceDateText = (weekStart: string) => {
  return `(Week of ${moment(weekStart).format("MM/DD/YYYY")})`;
};

type WeeklyProjectTasksTitleProps = {
  weekStart: string;
  firstName: string;
  lastName: string;
  timesheetId: string;
  userPrincipalName: string;
  currentCount: number;
  refetch: React.DispatchWithoutAction;
};

export const WeeklyProjectTasksTitle: React.FC<WeeklyProjectTasksTitleProps> = (
  props
) => {
  const {
    weekStart,
    firstName,
    lastName,
    currentCount,
    timesheetId,
    refetch,
    userPrincipalName,
  } = props;

  const dateText = produceDateText(weekStart);
  const nextWeek = moment(weekStart).utc().add(1, "week").format("YYYY-MM-DD");
  const prevWeek = moment(weekStart)
    .utc()
    .subtract(1, "week")
    .format("YYYY-MM-DD");
  const isCurrentWeek =
    moment().utc().startOf("week").format("YYYY-MM-DD") === weekStart;

  const nav = useRelativeNav();

  const title = `Weekly Project Tasks - ${firstName} ${lastName} - ${dateText}`;

  const actions = (
    <>
      {currentCount === 0 && (
        <CopyPreviousWeekProjectTime
          {...{
            refetch,
            timesheetId,
            prevWeek,
            userPrincipalName,
          }}
        />
      )}
      <Button onClick={() => nav(`../${prevWeek}`)}>Prev</Button>
      {!isCurrentWeek && (
        <Button onClick={() => nav(`../${nextWeek}`)}>Next</Button>
      )}
    </>
  );

  return (
    <>
      <PageTitleRow title={title} actions={actions} />
    </>
  );
};

export const HeaderDateCells: React.FC<{ weekStart: string }> = ({
  weekStart,
}) => {
  const days = produceWeeklyWorkDays(weekStart);

  const dayHeaderCells = days.map((d, idx) => {
    const dayOfWeek = d.format("ddd");
    const dateText = d.format("MM/DD/YYYY");

    return (
      <TableCell className="date" key={idx}>
        <Body2>{dayOfWeek}</Body2>
        <Body2>{dateText}</Body2>
      </TableCell>
    );
  });

  return <>{dayHeaderCells}</>;
};

export const WeeklyProjectTasksTotals: React.FC<{
  weekStart: string;
  projectTime: ProjectTimeEntry[];
}> = ({ weekStart, projectTime }) => {
  const days = produceWeeklyWorkDays(weekStart);

  const getDuration = (date: Moment) =>
    projectTime
      .filter((x) => moment(x.date, "YYYY-MM-DD").isSame(date))
      .map((x) => moment.duration(x.hours))
      .reduce((t1, t2) => addDurations(t1, t2), moment.duration("00:00"));

  const totals = days.map((d) => getDuration(d));

  return (
    <div className="row totals">
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell text="Totals" className="total-text" />
      {totals.map((t, idx) => (
        <TableCell className="hours" key={idx} text={durationDisplay(t)} />
      ))}
    </div>
  );
};

type WeeklyProjectsProps = {
  weekStart: string;
  projectTime: ProjectTimeEntry[];
  workDayRoute: (upn: string, date: string) => string;
  userPrincipalName: string;
  navigate: (path: string) => void;
  editable: boolean;
};

export const WeeklyProjectTasksData: React.FC<WeeklyProjectsProps> = (
  props
) => {
  const {
    weekStart,
    projectTime,
    workDayRoute,
    userPrincipalName,
    navigate,
    editable,
  } = props;

  const days = produceWeeklyWorkDays(weekStart);

  const sorted = _.orderBy(projectTime, [
    (x) => x.project!.number,
    (x) => x.taskName,
  ]);

  const timeWithDays = sorted.map((pt) => ({
    ...pt,
    time: days.map((d) => {
      const matched = moment(pt!.date, "YYYY-MM-DD").isSame(d);
      return {
        hours: matched ? durationDisplay(moment.duration(pt.hours)) : "",
        id: matched ? pt.id : null,
        date: d.format("YYYY-MM-DD"),
      };
    }),
  }));

  return (
    <>
      {timeWithDays.map((pt, idx) => (
        <div key={idx} className="row">
          <TableCell text={`${pt.project!.number}`} />
          <TableCell text={pt.project!.name} />
          <TableCell text={pt.project!.customer!.name} />
          <TableCell text={pt.taskName} />
          {pt.time.map((t, subIdx) => (
            <TableCell
              className="hours"
              key={`${idx}${subIdx}`}
              title={t.hours ? "Edit" : ""}
              text={t.hours}
              onClick={
                !editable
                  ? () => undefined
                  : t.id
                  ? () =>
                      navigate(
                        `${workDayRoute(
                          userPrincipalName!,
                          t.date!
                        )}/${editProjectTimeEntry}/${t.id}`
                      )
                  : () =>
                      navigate(
                        `${workDayRoute(
                          userPrincipalName!,
                          t.date!
                        )}/${createProjectTimeEntry}/${pt.project!.number}/${
                          pt.project!.name
                        }/${pt.taskName}`
                      )
              }
            >
              {!t.hours && editable && (
                <Body2 className="create-new">Create New</Body2>
              )}
              {t.hours && editable && <MaterialIcon icon="edit" />}
            </TableCell>
          ))}
        </div>
      ))}
    </>
  );
};

export const WeeklyProjectTasks: React.FC = () => {
  const params = useParams<WeeklyProjectTasksRouteData>();
  const weekStart = params?.weekStart!;
  const userPrincipalName = params?.userPrincipalName!;

  const variables = {
    userPrincipalName,
    weekBeginning: weekStart,
  };

  const user = useUser();
  const navigate = useNavigate();
  const isOwner =
    user.email?.toLowerCase() === userPrincipalName?.toLowerCase();
  const workDayRoute = isOwner
    ? myWorkDayRoute.toRoute
    : reviewWorkDayRoute.toRoute;

  const { data, loading, refetch } = useWeeklyProjectTasksQuery({ variables });
  const result = data?.timesheets?.weeklyProjectTasks;

  const projectTime = (result?.projectTime ?? []) as ProjectTimeEntry[];
  const firstName = result?.firstName!;
  const lastName = result?.lastName!;
  const editable = result?.editable!;
  const timesheetId = result?.timesheetId!;

  const breadcrumbs = createWorkDayBreadcrumbs({
    upn: userPrincipalName,
    mode: isOwner ? "Mine" : "Review",
    date: "",
    employee: { firstName, lastName },
  });

  const selectedBreadcrumbs = loading
    ? [{ text: "Loading " }]
    : isOwner
    ? [breadcrumbs[0]]
    : [...breadcrumbs.slice(0, 2)];

  useBreadcrumbs(
    [
      ...selectedBreadcrumbs,
      { text: `Weekly Project Tasks ${produceDateText(weekStart)}` },
    ],
    [user, result, params]
  );

  if (loading) {
    return <Spinner open={loading} />;
  }

  return (
    <div>
      <WeeklyProjectTasksTitle
        {...{
          weekStart: weekStart!,
          firstName,
          lastName,
          userPrincipalName,
          currentCount: projectTime.length,
          refetch,
          timesheetId,
        }}
      />
      <Card className="weekly-project-tasks">
        <div className="table">
          <div className="row header">
            <TableCell text="Project Number" />
            <TableCell text="Project Name" />
            <TableCell text="Customer Name" />
            <TableCell text="Task" />
            <HeaderDateCells {...{ weekStart }} />
          </div>
          <WeeklyProjectTasksData
            {...{
              weekStart,
              projectTime,
              workDayRoute,
              userPrincipalName,
              navigate,
              editable,
            }}
          />
          <WeeklyProjectTasksTotals {...{ projectTime, weekStart }} />
        </div>
      </Card>
    </div>
  );
};
