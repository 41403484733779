import React, { useMemo } from "react";
import { createEditFieldState } from "./createEditFieldState";
import { useEditProject } from "./useEditProject";
import { CreateProjectForm, ProjectForm } from "./ProjectForm";
import { Project } from "~/gql/types";
import { createNewFieldState } from "./createNewFieldState";
import { useCreateProject } from "./useCreateProject";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { projects } from "~/routes";
import { FormState } from "~/inline-fields-form/types";

export const EditProjectFormPage: React.FC<{ project: Project }> = ({
  project,
}) => {
  const initialFieldState = useMemo(
    () => createEditFieldState(project),
    [project]
  );

  const projectNumber = project.number;

  const { editProject, loading } = useEditProject();

  const handleSubmit = async (formState: FormState) => {
    await editProject({ formState, initialFieldState, projectNumber });
  };

  return (
    <ProjectForm
      {...{
        handleSubmit,
        initialFieldState,
        project,
        loading,
      }}
    />
  );
};

export const CreateProjectFormPage: React.FC = () => {
  const { create, loading } = useCreateProject();

  useBreadcrumbs(
    [{ text: "Projects", to: projects.path }, { text: "Create Project" }],
    []
  );

  return (
    <CreateProjectForm
      {...{
        handleSubmit: create,
        initialFieldState: createNewFieldState(),
        loading,
      }}
    />
  );
};
