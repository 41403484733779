import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { TextHighlighter, createSearchRegex } from "~/text-highlighter";
import _ from "lodash";
import { TaskRate } from "~/gql/types";

export type TaskPickerProps = FormFieldProps & {
  taskRates: TaskRate[];
};

const TaskPickerFormField: React.FC<TaskPickerProps> = (props) => {
  const { taskRates } = props;

  const format = (item: TaskRate, searchText: string): React.ReactNode => {
    const searchRegex = createSearchRegex(searchText);

    return (
      <div className="task-picker-menuitem">
        <TextHighlighter text={item.name} search={searchRegex} />
      </div>
    );
  };

  const chipFormat = (item: TaskRate) => item.name;

  const getData = async (searchText: string) => {
    if (!searchText) {
      return taskRates;
    }
    const result = taskRates?.filter((t) =>
      t.name.toLowerCase().includes(searchText.toLowerCase())
    );
    return result ?? [];
  };

  const args = {
    ...props,
    format,
    chipFormat,
    getData,
  } as TypeaheadProps<TaskRate>;

  return (
    <div className="task-picker">
      <TypeaheadFormField {...args} />
    </div>
  );
};

export default TaskPickerFormField;
