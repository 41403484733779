import React, { useState } from "react";
import Button from "~/button";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import { ClientForm } from "~/forms/ClientForm";
import DropdownFormField, {
  OptionProps,
} from "~/visuals/organisms/DropdownFormField";
import FormBottomRow from "~/form-bottom-row";
import Spinner from "~/spinner";
import "./ProjectDailies.scss";

type ProjectDailiesProps = {
  options: OptionProps[];
  handleSubmit: (
    values: { project: string | null },
    setLoading: React.Dispatch<boolean>
  ) => Promise<void>;
  outlined?: boolean;
};

export const ProjectDailies: React.FC<ProjectDailiesProps> = ({
  options,
  handleSubmit,
  outlined,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSucess = async (values: { project: string | null }) => {
    await handleSubmit(values, setLoading);
    setOpen(false);
  };

  return (
    <div className="project-dailies">
      <Button outlined={outlined} onClick={() => setOpen(true)}>
        Project Dailies
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        data-open={open}
        portalize
      >
        <DialogTitle>Download Project Dailies</DialogTitle>
        <DialogContent>
          <ClientForm
            onSuccess={onSucess}
            initialValues={{ project: null }}
            allowPristineSubmit
          >
            <DropdownFormField
              label="Project"
              formField="project"
              helperText="Select a project"
              options={options}
              required
            />
            <FormBottomRow
              errorMessage={null}
              buttonText={"Download Dailies"}
              onCancel={() => setOpen(false)}
            />
          </ClientForm>
        </DialogContent>
      </Dialog>
      <Spinner open={loading} />
    </div>
  );
};
