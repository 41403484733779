import React from "react";
import { useNavigate } from "react-router-dom";
import TitledCard from "~/titled-card";
import { RebillBreadcrumbs } from "./RebillBreadcrumbs";
import "./SelectProjectPage.scss";
import FormBottomRow from "~/form-bottom-row";
import * as invoiceRoutes from "../invoiceRoutes";
import { Project } from "~/visuals/pages/Project/ProjectDetails/types";
import ProjectPickerFormField from "~/visuals/organisms/ProjectPickerFormField";
import { ClientForm } from "~/forms/ClientForm";
import { useInvoice } from "../InvoiceContext";

type FormValues = {
  project: Project | null;
};

export const SelectProject: React.FC = () => {
  const invoice = useInvoice();
  const navigate = useNavigate();

  const onSuccess = ({ project }) => {
    navigate(
      invoiceRoutes.selectInvoice(invoice.invoiceNumber, `${project!.number}`)
    );
  };

  const initialValues: FormValues = {
    project: null,
  };

  return (
    <TitledCard
      title="Rebill Charges - Select Different Project"
      className="select-project-page"
    >
      <RebillBreadcrumbs />
      <ClientForm initialValues={initialValues} onSuccess={onSuccess}>
        <ProjectPickerFormField
          formField="project"
          label="Project"
          helperText="Select a different project to rebill charges to"
          date={invoice.invoiceDate!}
          required
        />
        <FormBottomRow
          errorMessage={null}
          buttonText="Next"
          onCancel={() => navigate(invoiceRoutes.home(invoice.invoiceNumber))}
        />
      </ClientForm>
    </TitledCard>
  );
};
