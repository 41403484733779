import React, { useEffect } from "react";
import { PostToInvoiceAction, PostToInvoiceState } from "./stateMachine";
import { Invoice, ProjectCharge } from "~/gql/types";
import { useConfirmationPrompt } from "~/confirmation-prompt";

type PromptForConfirmationProps<T> = {
  state: PostToInvoiceState<T>;
  dispatch: React.Dispatch<PostToInvoiceAction>;
  onClose: () => void;
};

export const PromptForConfirmation = <T,>(
  props: PromptForConfirmationProps<T>
): React.ReactElement => {
  const { state, dispatch, onClose } = props;
  const prompt = useConfirmationPrompt();

  useEffect(() => {
    if (state.tag === "PromptingForConfirmation") {
      void (async () => {
        const { newDraftCount, totalCount } = getItemData(state);

        const item = state.itemGroups[0].items[0] as Invoice | ProjectCharge;

        const itemType = "invoiceNumber" in item ? "invoices" : "charges";

        const invoiceType =
          itemType === "charges" ? "invoices" : "custom rollup invoices";

        const message = createMessage({
          totalCount,
          newDraftCount,
          itemType,
          invoiceType,
        });

        const title =
          "invoiceNumber" in item
            ? "Post invoices to new draft custom rollup invoices"
            : "Post charges to new draft invoices";

        const promptResult = await prompt({
          cancelButtonText: "Cancel",
          confirmButtonText: "Confirm",
          message,
          title,
        });

        if (promptResult !== "Confirm") {
          onClose();
          return;
        }

        dispatch({ tag: "PromptConfirmed" });
      })();
    }
  }, [state]);

  return <></>;
};

export const createMessage = (args: {
  newDraftCount: number;
  totalCount: number;
  itemType: "charges" | "invoices";
  invoiceType: "invoices" | "custom rollup invoices";
}): string => {
  const { itemType, newDraftCount, totalCount, invoiceType } = args;

  const isChargeType = itemType === "charges";
  const groupType = isChargeType ? "project" : "project group";

  const needsAddressedCount = totalCount - newDraftCount;

  const needsAddressedText =
    needsAddressedCount > 0
      ? ` ${needsAddressedCount} ${itemType} must be addressed a ${groupType} at a time.`
      : "";

  return (
    `${newDraftCount} of ${totalCount} ${itemType} can be posted to new ${invoiceType}.` +
    needsAddressedText
  );
};

function getItemData<T>(state: PostToInvoiceState<T>): {
  newDraftCount: number;
  totalCount: number;
} {
  const items = state.itemGroups
    .filter((x) => x.draftInvoices.length === 0)
    .flatMap((x) => x.items);

  const newDraftCount = items.length;
  const totalCount = state.itemGroups.flatMap((x) => x.items).length;

  return { newDraftCount, totalCount };
}
