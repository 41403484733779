import React from "react";
import { PageRoutesProps } from "./ExpenseRouter";
import NewReceiptValuedExpense from "~/visuals/pages/NewExpenseEntry/ReceiptValuedExpense";
import {
  AdHocExpense,
  AdHocExpenseRate,
  AtvExpense,
  AtvExpenseRate,
  FuelExpense,
  FuelExpenseRate,
  MileageExpense,
  MileageExpenseRate,
  MobileAllowanceExpense,
  MobileAllowanceExpenseRate,
  PerDiemExpense,
  PerDiemExpenseRate,
  ReceiptValuedExpense,
  ReceiptValuedExpenseRate,
  TcpAllowanceExpense,
  TcpAllowanceExpenseRate,
} from "~/gql/types";
import EditReceiptValuedExpense from "~/visuals/pages/EditExpenseEntry/ReceiptValuedExpense";
import { ExpenseType } from "./ExpenseForm/ExpensePageWrapper";
import NewAtvExpense from "~/visuals/pages/NewExpenseEntry/AtvExpense";
import EditAtvExpense from "~/visuals/pages/EditExpenseEntry/AtvExpense";
import NewFuelExpense from "~/visuals/pages/NewExpenseEntry/FuelExpense";
import EditFuelExpense from "~/visuals/pages/EditExpenseEntry/FuelExpense";
import NewMileageExpense from "~/visuals/pages/NewExpenseEntry/MileageExpense";
import EditMileageExpense from "~/visuals/pages/EditExpenseEntry/MileageExpense";
import NewMobileAllowanceExpense from "~/visuals/pages/NewExpenseEntry/MobileAllowanceExpense";
import EditMobileAllowanceExpense from "~/visuals/pages/EditExpenseEntry/MobileAllowanceExpense";
import NewPerDiemExpense from "~/visuals/pages/NewExpenseEntry/PerDiemExpense";
import EditPerDiemExpense from "~/visuals/pages/EditExpenseEntry/PerDiemExpense";
import NewTcpAllowanceExpense from "~/visuals/pages/NewExpenseEntry/TcpAllowanceExpense";
import EditTcpAllowanceExpense from "~/visuals/pages/EditExpenseEntry/TcpAllowanceExpense";
import NewAdHocExpense from "~/visuals/pages/NewExpenseEntry/AdHocExpense";
import EditAdHocExpense from "~/visuals/pages/EditExpenseEntry/AdHocExpense";

type ExpenseFormRouterProps = PageRoutesProps & {
  expenseType: ExpenseType;
};

export const ExpenseFormRouter: React.FC<ExpenseFormRouterProps> = (props) => {
  const { expenseRate, expenseEntry, formType, expenseType } = props;

  function createProps<TExpense, TRate>() {
    return {
      ...props,
      expenseRate: expenseRate as TRate,
      expenseEntry: expenseEntry as TExpense,
    };
  }

  const propMap = {
    atv: createProps<AtvExpense, AtvExpenseRate>(),
    adHoc: createProps<AdHocExpense, AdHocExpenseRate>(),
    fuel: createProps<FuelExpense, FuelExpenseRate>(),
    mileage: createProps<MileageExpense, MileageExpenseRate>(),
    mobileAllowance: createProps<
      MobileAllowanceExpense,
      MobileAllowanceExpenseRate
    >(),
    perDiem: createProps<PerDiemExpense, PerDiemExpenseRate>(),
    receiptValued: createProps<
      ReceiptValuedExpense,
      ReceiptValuedExpenseRate
    >(),
    tcp: createProps<TcpAllowanceExpense, TcpAllowanceExpenseRate>(),
  };

  const componentMap: Record<
    ExpenseType,
    {
      new: JSX.Element;
      edit: JSX.Element;
    }
  > = {
    ATV: {
      new: <NewAtvExpense {...propMap.atv} />,
      edit: <EditAtvExpense {...propMap.atv} />,
    },
    AdHoc: {
      new: <NewAdHocExpense {...propMap.adHoc} />,
      edit: <EditAdHocExpense {...propMap.adHoc} />,
    },
    Fuel: {
      new: <NewFuelExpense {...propMap.fuel} />,
      edit: <EditFuelExpense {...propMap.fuel} />,
    },
    Mileage: {
      new: <NewMileageExpense {...propMap.mileage} />,
      edit: <EditMileageExpense {...propMap.mileage} />,
    },
    "Mobile Allowance": {
      new: <NewMobileAllowanceExpense {...propMap.mobileAllowance} />,
      edit: <EditMobileAllowanceExpense {...propMap.mobileAllowance} />,
    },
    "Per Diem": {
      new: <NewPerDiemExpense {...propMap.perDiem} />,
      edit: <EditPerDiemExpense {...propMap.perDiem} />,
    },
    "Receipt Valued": {
      new: <NewReceiptValuedExpense {...propMap.receiptValued} />,
      edit: <EditReceiptValuedExpense {...propMap.receiptValued} />,
    },
    "TCP Allowance": {
      new: <NewTcpAllowanceExpense {...propMap.tcp} />,
      edit: <EditTcpAllowanceExpense {...propMap.tcp} />,
    },
  };

  const componentObject = componentMap[expenseType];

  return (
    <>{formType === "Edit" ? componentObject.edit : componentObject.new}</>
  );
};
