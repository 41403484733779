import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFinalPayrollMutationVariables = Types.Exact<{
  userPrincipalName: Types.Scalars['String'];
  finalPayroll?: Types.InputMaybe<Types.Scalars['LocalDate']>;
}>;


export type UpdateFinalPayrollMutation = { __typename?: 'MutationModel', employees?: { __typename?: 'EmployeeMutationModel', updateFinalPayroll?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const UpdateFinalPayrollDocument = gql`
    mutation UpdateFinalPayroll($userPrincipalName: String!, $finalPayroll: LocalDate) {
  employees {
    updateFinalPayroll(
      userPrincipalName: $userPrincipalName
      finalPayroll: $finalPayroll
    ) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type UpdateFinalPayrollMutationFn = Apollo.MutationFunction<UpdateFinalPayrollMutation, UpdateFinalPayrollMutationVariables>;

/**
 * __useUpdateFinalPayrollMutation__
 *
 * To run a mutation, you first call `useUpdateFinalPayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFinalPayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFinalPayrollMutation, { data, loading, error }] = useUpdateFinalPayrollMutation({
 *   variables: {
 *      userPrincipalName: // value for 'userPrincipalName'
 *      finalPayroll: // value for 'finalPayroll'
 *   },
 * });
 */
export function useUpdateFinalPayrollMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFinalPayrollMutation, UpdateFinalPayrollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFinalPayrollMutation, UpdateFinalPayrollMutationVariables>(UpdateFinalPayrollDocument, options);
      }
export type UpdateFinalPayrollMutationHookResult = ReturnType<typeof useUpdateFinalPayrollMutation>;
export type UpdateFinalPayrollMutationResult = Apollo.MutationResult<UpdateFinalPayrollMutation>;
export type UpdateFinalPayrollMutationOptions = Apollo.BaseMutationOptions<UpdateFinalPayrollMutation, UpdateFinalPayrollMutationVariables>;