import React from "react";
import TextFormField from "~/text-form-field";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import FormCard from "~/form-card";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import TitleDeleteOption from "~/title-delete-option";
import { viewExpenseRateSheet, expenseRateSheets } from "~/routes";
import "./ExpenseRateForm.scss";
import Spinner from "~/spinner";
import FormBottomRow from "~/form-bottom-row";
import { ExpenseRateFormPage, FormProps } from "./ExpenseRateFormRouter";
import Button from "~/button";
import EnumDropdownFormField from "../EnumDropdownFormField";

type RateFormProps = FormProps & {
  setPage: React.Dispatch<ExpenseRateFormPage>;
};

const ReceiptValueTypeForm: React.FC<RateFormProps> = ({
  type,
  rateSheetName,
  errorMessage,
  loading,
  onDelete,
  setPage,
}) => {
  useBreadcrumbs(
    [
      { text: "Expense Rate Sheets", to: expenseRateSheets.path },
      {
        text: rateSheetName,
        to: viewExpenseRateSheet.toRoute(rateSheetName).path,
      },
      {
        text: `${
          type === "Create" ? "Create Expense Rate" : `Edit ${rateSheetName}`
        }`,
      },
    ],
    [rateSheetName, type]
  );

  return (
    <div className="expense-rate-form">
      <TitleDeleteOption
        title={type + " Receipt Value Expense Rate"}
        onDelete={onDelete}
      />
      <Spinner open={loading} />

      <FormCard>
        <TextFormField
          label="Name"
          helperText="Expense rate name"
          formField="name"
          required
        />
        <TextFormField
          label="Invoice Label"
          helperText=""
          formField="invoiceLabel"
        />
        <TextFormField
          label="Expense Account"
          helperText=""
          formField="expenseAccount"
        />
        <TextFormField label="GL Account" helperText="" formField="glAccount" />
        <BinaryPickerFormField
          label="Billable"
          helperText=""
          formField="billable"
        />
        <BinaryPickerFormField
          label="Requires Location"
          helperText="Does this rate require a location for an expense?"
          formField="requiresLocation"
        />
        <EnumDropdownFormField
          label="Category"
          formField="category"
          helperText="Select category"
          enumType="ReceiptValuedCategory"
          required
        />
        {type !== "Edit" && (
          <Button
            className="back-button"
            type="button"
            onClick={() => setPage("Buttons")}
          >
            Back
          </Button>
        )}
        <FormBottomRow
          errorMessage={errorMessage || null}
          buttonText={type === "Create" ? "Create" : "Save"}
          route={viewExpenseRateSheet.toRoute(rateSheetName)}
        />
      </FormCard>
    </div>
  );
};

export default ReceiptValueTypeForm;
