import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllTaskRateSheetsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AllTaskRateSheetsQuery = { __typename?: 'QueryModel', taskRates?: { __typename?: 'TaskRatesQueryModel', sheets?: Array<{ __typename?: 'TaskRateSheet', name: string, allowAssignment: boolean, lastUsed?: string | null, archived: boolean } | null> | null } | null };


export const AllTaskRateSheetsDocument = gql`
    query AllTaskRateSheets {
  taskRates {
    sheets {
      name
      allowAssignment
      lastUsed
      archived
    }
  }
}
    `;

/**
 * __useAllTaskRateSheetsQuery__
 *
 * To run a query within a React component, call `useAllTaskRateSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTaskRateSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTaskRateSheetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTaskRateSheetsQuery(baseOptions?: Apollo.QueryHookOptions<AllTaskRateSheetsQuery, AllTaskRateSheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTaskRateSheetsQuery, AllTaskRateSheetsQueryVariables>(AllTaskRateSheetsDocument, options);
      }
export function useAllTaskRateSheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTaskRateSheetsQuery, AllTaskRateSheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTaskRateSheetsQuery, AllTaskRateSheetsQueryVariables>(AllTaskRateSheetsDocument, options);
        }
export type AllTaskRateSheetsQueryHookResult = ReturnType<typeof useAllTaskRateSheetsQuery>;
export type AllTaskRateSheetsLazyQueryHookResult = ReturnType<typeof useAllTaskRateSheetsLazyQuery>;
export type AllTaskRateSheetsQueryResult = Apollo.QueryResult<AllTaskRateSheetsQuery, AllTaskRateSheetsQueryVariables>;