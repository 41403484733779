import React from "react";
import { useUser } from "~/Auth/useUser";
import { Expense } from "~/WorkDayApi/expenseTypes";
import { ExpensePayableAmount } from ".";
import { Payable } from "~/gql/types";

type ExpensePayableColumns = {
  expense: Expense;
  refetch: () => void;
};

export const ExpensePayableColumns: React.FC<ExpensePayableColumns> = (
  props
) => {
  const { expense } = props;
  const user = useUser();
  const canPmApproveWorkData = user.authorizations.reviewWorkData;

  const isReimbursement = expense.payable === Payable.Reimbursement;
  const isPayroll = expense.payable === Payable.Payroll;
  const isNone = expense.payable === Payable.None;

  const baseProps = { ...props, canPmApproveWorkData };

  return (
    <>
      <ExpensePayableAmount
        {...{ ...baseProps, showAmount: isReimbursement }}
      />
      <ExpensePayableAmount {...{ ...baseProps, showAmount: isPayroll }} />
      <ExpensePayableAmount {...{ ...baseProps, showAmount: isNone }} />
    </>
  );
};
