import ClientOAuth2 from "client-oauth2";
import "~/Auth/Config";
import { googleAuthCallback, adAuthCallback } from "~/routes";
import pkceChallenge from "pkce-challenge";

const googleRedirectUri = `${window.location.origin}${googleAuthCallback.path}`;
const azureAdRedirectUri = `${window.location.origin}${adAuthCallback.path}`;
export const pkceVerifierKey = "pkce-verifier";
export const popupReauthState = "REAUTH_CHILD";
export const silentReauthState = "SILENT_REAUTH_CHILD";
export const delayedReauthState = "DELAYED_REAUTH_CHILD";

function createGoogleAuth(): ClientOAuth2 {
  const { googleAuthClientId, googleAuthClientSecret } = window.config;
  return new ClientOAuth2({
    clientId: googleAuthClientId,
    clientSecret: googleAuthClientSecret,
    authorizationUri: "https://accounts.google.com/o/oauth2/v2/auth",
    accessTokenUri: "https://oauth2.googleapis.com/token",
    redirectUri: googleRedirectUri,
    scopes: ["openid", "profile", "email"],
  });
}

function createAzureAuth(): ClientOAuth2 {
  const { azureAdAuthClientId, azureAdAuthClientSecret, azureAdAuthTenantId } =
    window.config;

  return new ClientOAuth2({
    clientId: azureAdAuthClientId,
    clientSecret: azureAdAuthClientSecret,
    authorizationUri: `https://login.microsoftonline.com/${azureAdAuthTenantId}/oauth2/v2.0/authorize`,
    accessTokenUri: `https://login.microsoftonline.com/${azureAdAuthTenantId}/oauth2/token`,
    redirectUri: azureAdRedirectUri,
    scopes: ["openid", "profile", "email"],
  });
}

let googleAuthClient: ClientOAuth2;
let azureAuthClient: ClientOAuth2;

export function getGoogleAuthClient(): ClientOAuth2 {
  if (!googleAuthClient) {
    googleAuthClient = createGoogleAuth();
  }

  return googleAuthClient;
}

export function getAzureAuthClient(): ClientOAuth2 {
  if (!azureAuthClient) {
    azureAuthClient = createAzureAuth();
  }

  return azureAuthClient;
}

export function doGoogleRedirect(): void {
  return doRedirect(createGoogleAuth(), window.location.pathname);
}
export function doAzureAdRedirect(): void {
  return doRedirect(createAzureAuth(), window.location.pathname);
}
export function doRedirect(auth: ClientOAuth2, state: string): void {
  const { code_challenge, code_verifier } = pkceChallenge();
  sessionStorage.setItem(pkceVerifierKey, code_verifier);
  const options = {
    query: {
      code_challenge,
      code_challenge_method: "S256",
    },
    state,
  };

  window.location.href = auth.code.getUri(options);
}
