import * as Types from '../../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type ServiceDescriptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ServiceDescriptionsQuery = { __typename?: 'QueryModel', serviceDescriptions: { __typename?: 'ServiceDescriptionQueryModel', all: Array<{ __typename?: 'ServiceDescription', industry: string, projectType: string, description: string, displayName: string, legacyWorkCode: string, isOverhead: boolean, isActive: boolean }> } };


export const ServiceDescriptionsDocument = gql`
    query ServiceDescriptions {
  serviceDescriptions {
    all {
      industry
      projectType
      description
      displayName
      legacyWorkCode
      isOverhead
      isActive
    }
  }
}
    `;

/**
 * __useServiceDescriptionsQuery__
 *
 * To run a query within a React component, call `useServiceDescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceDescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceDescriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceDescriptionsQuery(baseOptions?: Apollo.QueryHookOptions<ServiceDescriptionsQuery, ServiceDescriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceDescriptionsQuery, ServiceDescriptionsQueryVariables>(ServiceDescriptionsDocument, options);
      }
export function useServiceDescriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceDescriptionsQuery, ServiceDescriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceDescriptionsQuery, ServiceDescriptionsQueryVariables>(ServiceDescriptionsDocument, options);
        }
export type ServiceDescriptionsQueryHookResult = ReturnType<typeof useServiceDescriptionsQuery>;
export type ServiceDescriptionsLazyQueryHookResult = ReturnType<typeof useServiceDescriptionsLazyQuery>;
export type ServiceDescriptionsQueryResult = Apollo.QueryResult<ServiceDescriptionsQuery, ServiceDescriptionsQueryVariables>;