import React from "react";
import Button from "~/button";
import { stringify } from "csv-stringify/dist/esm/sync";
import { GetRevenueReportQuery } from "./getRevenueReport.generated";
import { useDownloadFile } from "~/file-loading/useDownloadFile";
import { addPercentage, headerLabels, toLabelData } from "./dataUtils";

type DownloadCsvButtonProps = {
  data: GetRevenueReportQuery;
};

export const DownloadCsvButton: React.FC<DownloadCsvButtonProps> = ({
  data,
}) => {
  const download = useDownloadFile();

  const onClick = () => {
    const keys = Object.keys(headerLabels);

    const headerRow = [
      ...keys.map((x) => headerLabels[x]),
      "Total",
      "Percentage",
    ];

    const rows = addPercentage(data.invoices?.revenueReport as any);

    const csvRows = [
      ...rows.map((row) => [...toLabelData(row), row.total, row.percentage]),
    ];

    const csvString = stringify(csvRows, { header: true, columns: headerRow });

    const blob = new Blob([csvString as string], {
      type: "text/csv;charset=utf-8;",
    });

    const blobUrl = URL.createObjectURL(blob);

    download({ filename: "revenue-report.csv", linkHref: blobUrl });
  };

  return (
    <div style={{ textAlign: "right" }}>
      <Button onClick={onClick}>Download CSV</Button>
    </div>
  );
};
