export type AtvTotal = {
  month: string;
  total: number;
};

type CoreState = {
  totals: AtvTotal[];
  loading: boolean;
  token: string | null;
};

type TaggedState<T extends string> = { tag: T } & CoreState;

type Loading = TaggedState<"Loading">;
type Loaded = TaggedState<"Loaded">;

export type State = Loading | Loaded;

type TaggedAction<T extends string> = { tag: T };

type LoadData = TaggedAction<"LoadData">;
type DataLoaded = TaggedAction<"DataLoaded"> & {
  data: AtvTotal[];
  token: string | null;
};
type Reset = TaggedAction<"Reset">;

export type Action = LoadData | DataLoaded | Reset;

export function reduceLoading(state: Loading, action: Action): State {
  if (action.tag === "DataLoaded") {
    return {
      ...state,
      tag: "Loaded",
      loading: false,
      totals: [...state.totals, ...action.data],
      token: action.token,
    };
  }
  return state;
}

export function reduceLoaded(state: Loaded, action: Action): State {
  if (action.tag === "LoadData") {
    return {
      ...state,
      tag: "Loading",
      loading: true,
    };
  }
  if (action.tag === "Reset") {
    return {
      ...state,
      totals: [],
      token: null,
      tag: "Loading",
      loading: true,
    };
  }
  return state;
}

export function reducer(state: State, action: Action): State {
  if (state.tag === "Loading") {
    return reduceLoading(state, action);
  }
  if (state.tag === "Loaded") {
    return reduceLoaded(state, action);
  }
  return state;
}
