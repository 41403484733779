import React, { useState } from "react";
import { useField } from "react-final-form";
import Select, { Option } from "@material/react-select";
import { HelperText } from "@material/react-text-field";
import classnames from "classnames";
import "./DropdownFormField.scss";

export type OptionProps = {
  label: string;
  value: string;
};

export interface DropdownProps {
  label: string;
  formField: string;
  helperText: string;
  required?: boolean;
  options: OptionProps[];
  loading?: boolean;
  disabled?: boolean;
}

const DropdownFormField: React.FC<DropdownProps> = (props) => {
  const {
    label,
    formField,
    helperText,
    required = false,
    options,
    loading,
    disabled,
  } = props;

  const checkRequired = (value: string) => {
    if (value || !required) {
      return;
    }
    return `Please provide a valid ${label}`;
  };

  const {
    meta: { error, submitError, touched },
    input: { value, onChange, onBlur },
  } = useField(formField, { validate: checkRequired });

  const errorMessage = error || submitError;
  const isValid = !touched || !errorMessage;

  const [hasFocus, setHasFocus] = useState({
    optionFocus: false,
    selectFocus: false,
    click: false,
  });
  const focused =
    hasFocus.optionFocus || hasFocus.selectFocus || hasFocus.click;

  const labelClass = classnames({
    "mdc-text-field--invalid": !isValid,
    "mdc-floating-label--float-above": focused && isValid,
  });
  const helperTextClass = classnames({
    "mdc-text-field-helper-text--validation-msg": !isValid,
  });

  const blur = () => {
    setHasFocus({ ...hasFocus, optionFocus: false });
  };

  const inputLabel = required ? label + " *" : label;

  const handleOptionKeyPress = (evt: React.KeyboardEvent, value) => {
    if (evt.key !== "Enter" && evt.key !== " ") {
      return;
    }

    onChange(value);
  };

  const selectOptions = options
    ? options.map(({ label, value }, idx) => (
        <Option
          key={idx}
          value={value}
          name={value}
          onClick={() => onChange(value)}
          onKeyDown={(evt) => handleOptionKeyPress(evt, value)}
          onFocus={() => setHasFocus({ ...hasFocus, optionFocus: true })}
          onBlur={(evt) => {
            blur();
            onBlur(evt);
          }}
          onMouseDown={() => setHasFocus({ ...hasFocus, click: true })}
          onMouseUp={() => setHasFocus({ ...hasFocus, click: false })}
        >
          {label}
        </Option>
      ))
    : [];

  return (
    <section className="form-field dropdown">
      <Select
        enhanced
        className={classnames("mdc-text-field", {
          "mdc-text-field--invalid": !isValid,
        })}
        label={loading ? "Loading..." : inputLabel}
        value={options?.find((x) => x.value == value)?.value}
        disabled={disabled}
        floatingLabelClassName={labelClass}
        onFocus={() => setHasFocus({ ...hasFocus, selectFocus: true })}
        onBlur={() => setHasFocus({ ...hasFocus, selectFocus: false })}
        helperText={
          <HelperText
            isValidationMessage={!isValid}
            persistent={
              !isValid ||
              hasFocus.optionFocus ||
              hasFocus.selectFocus ||
              hasFocus.click
            }
            className={helperTextClass}
          >
            {!isValid && errorMessage ? errorMessage : helperText}
          </HelperText>
        }
      >
        {selectOptions}
      </Select>
    </section>
  );
};

export default DropdownFormField;
