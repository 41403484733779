import ClientOAuth2 from "client-oauth2";
import jwt_decode from "jwt-decode";
import { useMemo } from "react";
import { getAzureAuthClient, getGoogleAuthClient } from "./authClient";
import { useRawJwt } from "./AuthenticationManager";

type JwtProps = {
  email?: string;
  upn?: string;
};

export function useAuthClient(): ClientOAuth2 | null {
  const authToken = useRawJwt();

  const auth = useMemo(() => {
    if (!authToken) {
      return null;
    }
    const { email, upn } = jwt_decode<JwtProps>(authToken || "");
    const emailOrEmpty = email || upn || "";
    if (!emailOrEmpty) {
      return null;
    }
    if (emailOrEmpty.endsWith("@canyon-trail.com")) {
      return getGoogleAuthClient();
    } else {
      return getAzureAuthClient();
    }
  }, [authToken]);

  return auth;
}
