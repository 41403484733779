import classnames from "classnames";
import React from "react";
import { TimesheetStatus } from "~/gql/types";
import { myWorkDayRoute, reviewWorkDayRoute } from "~/WorkData/routes";
import "./WorkWeekRow.scss";
import { TableRow } from "~/table";

type WorkWeekRowProps = {
  date: string;
  weekStatus: TimesheetStatus;
  userPrincipalName: string;
  review?: boolean;
  className?: string;
};

const WorkWeekRow: React.FC<WorkWeekRowProps> = ({
  weekStatus,
  date,
  review,
  className,
  userPrincipalName,
  children,
}) => {
  const weekOpen = weekStatus === TimesheetStatus.Open;
  const weekApproved = weekStatus === TimesheetStatus.PrApproved;

  const cn = classnames(`work-week-row ${className ?? ""}`, {
    grayed: (!review && !weekOpen) || weekApproved,
  });

  const route = review
    ? reviewWorkDayRoute.toRoute(userPrincipalName, date)
    : myWorkDayRoute.toRoute(userPrincipalName, date);

  return (
    <TableRow className={cn} link={route}>
      {children}
    </TableRow>
  );
};

export default WorkWeekRow;
