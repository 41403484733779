import React, { useEffect, useState } from "react";
import TableCell from "~/old-table-cell";
import Table from "~/old-table";
import TableHeader from "~/old-table-header";
import TableContent from "~/old-table-content";
import "./MyPendingRequests.scss";
import TitledCard from "~/titled-card";
import { projectRequests } from "~/project-requests/routes";
import { useGetMyPendingProjectRequestsQuery } from "./query.generated";
import { MyPendingRequests } from "~/gql/types";
import { InlineProgress } from "~/inline-progress";
import { Body2 } from "@material/react-typography";
import { useNavigate } from "react-router-dom";
import { getTimeSince } from "~/utils/getTimeSince";

const DataFetcher = ({ onChange }) => {
  const result = useGetMyPendingProjectRequestsQuery();

  useEffect(() => {
    onChange(result);
  }, [result.loading, result.data]);

  return null;
};

type FetchResult = ReturnType<typeof useGetMyPendingProjectRequestsQuery>;

export const MyPendingProjectRequests: React.FC = () => {
  const nav = useNavigate();

  const [{ data, loading }, setResult] = useState<FetchResult>({
    loading: true,
  } as any as FetchResult);

  const stats = (data?.projectRequests?.myPendingRequests ??
    []) as MyPendingRequests[];

  const onClick = (office: string) =>
    nav(`${projectRequests}?state=Pending&officeCode=${office}`);

  const getTimeText = (time: string) => {
    const { dateTime, elapsed } = getTimeSince(time);
    return `${dateTime} (${elapsed})`;
  };

  return (
    <div>
      <DataFetcher onChange={setResult} />
      <TitledCard
        title="Pending Project Requests"
        className="my-pending-requests"
      >
        {!loading && stats?.length > 0 && (
          <Table>
            <TableHeader>
              <TableCell text="Office Code" />
              <TableCell text="Requests" />
              <TableCell text="Max Age" />
            </TableHeader>
            {stats.map((s, idx) => (
              <TableContent
                key={idx}
                className="office-project-requests"
                onClick={() => onClick(s.officeCode)}
              >
                <TableCell text={s.officeCode} />
                <TableCell text={`${s.requests}`} />
                <TableCell text={getTimeText(s.maxAge)} />
              </TableContent>
            ))}
          </Table>
        )}

        {!loading && stats?.length === 0 && <Body2>No pending requests</Body2>}

        {loading && <InlineProgress />}
      </TitledCard>
    </div>
  );
};
