import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { TextHighlighter, createSearchRegex } from "~/text-highlighter";
import _ from "lodash";
import { IExpenseRate } from "~/gql/types";
import "./ExpensePickerFormField.scss";

export type ExpensePickerProps = FormFieldProps & {
  expenseRates: IExpenseRate[];
  showInvoiceLabel?: boolean;
};

const ExpensePickerFormField: React.FC<ExpensePickerProps> = (props) => {
  const { expenseRates, showInvoiceLabel } = props;

  const format = (item: IExpenseRate, searchText: string): React.ReactNode => {
    const searchRegex = createSearchRegex(searchText);

    const text =
      showInvoiceLabel && item.invoiceLabel
        ? `${item.name} - ${item.invoiceLabel}`
        : item.name;

    return (
      <div className="expense-picker-menuitem">
        <TextHighlighter text={text} search={searchRegex} />
      </div>
    );
  };

  const chipFormat = (item: IExpenseRate) =>
    showInvoiceLabel && item.invoiceLabel ? item.invoiceLabel : item.name;

  const getData = async (searchText: string) => {
    if (!searchText) {
      return expenseRates;
    }
    const result = expenseRates?.filter((t) =>
      t.name.toLowerCase().includes(searchText.toLowerCase())
    );
    return result ?? [];
  };

  const args = {
    ...props,
    format,
    chipFormat,
    getData,
  } as TypeaheadProps<IExpenseRate>;

  return (
    <div className="expense-picker">
      <TypeaheadFormField {...args} />
    </div>
  );
};

export default ExpensePickerFormField;
