import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PerDiemRatesByStateQueryVariables = Types.Exact<{
  state: Types.Scalars['String'];
}>;


export type PerDiemRatesByStateQuery = { __typename?: 'QueryModel', perDiemRates?: { __typename?: 'PerDiemRatesQueryModel', ratesByState?: Array<{ __typename?: 'PerDiemRate', state: string, county: string, effectiveDate: string, mealsBillable: number, mealsReimbursable: number, lodgingBillable: number, lodgingReimbursable: number, travelBillable: number, travelReimbursable: number } | null> | null } | null };


export const PerDiemRatesByStateDocument = gql`
    query PerDiemRatesByState($state: String!) {
  perDiemRates {
    ratesByState(state: $state) {
      state
      county
      effectiveDate
      mealsBillable
      mealsReimbursable
      lodgingBillable
      lodgingReimbursable
      travelBillable
      travelReimbursable
    }
  }
}
    `;

/**
 * __usePerDiemRatesByStateQuery__
 *
 * To run a query within a React component, call `usePerDiemRatesByStateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerDiemRatesByStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerDiemRatesByStateQuery({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function usePerDiemRatesByStateQuery(baseOptions: Apollo.QueryHookOptions<PerDiemRatesByStateQuery, PerDiemRatesByStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PerDiemRatesByStateQuery, PerDiemRatesByStateQueryVariables>(PerDiemRatesByStateDocument, options);
      }
export function usePerDiemRatesByStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PerDiemRatesByStateQuery, PerDiemRatesByStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PerDiemRatesByStateQuery, PerDiemRatesByStateQueryVariables>(PerDiemRatesByStateDocument, options);
        }
export type PerDiemRatesByStateQueryHookResult = ReturnType<typeof usePerDiemRatesByStateQuery>;
export type PerDiemRatesByStateLazyQueryHookResult = ReturnType<typeof usePerDiemRatesByStateLazyQuery>;
export type PerDiemRatesByStateQueryResult = Apollo.QueryResult<PerDiemRatesByStateQuery, PerDiemRatesByStateQueryVariables>;