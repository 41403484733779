import ClientOAuth2 from "client-oauth2";
import React from "react";
import { createBroadcastChannel } from "~/broadcast-channel/createBroadcastChannel";
import { Action, State } from "./stateMachine";
import { GetAuthorizationsAsync } from "../types";

export const loginChannelName = "wolfkrow-login";
export const logoutChannelName = "wolfkrow-logout";

export type AuthenticationManagerProps = {
  createAzureAuth?: () => ClientOAuth2;
  createGoogleAuth?: () => ClientOAuth2;
  getAuthorizations: GetAuthorizationsAsync;
};

export type AuthenticationManagerInnerProps =
  Required<AuthenticationManagerProps> & {
    state: State;
    dispatch: React.Dispatch<Action>;
  };

export const tokenStorageKey = "auth-token";
export const authsKey = "authorizations";

export function dispatchLogout(
  dispatch: React.Dispatch<Action>,
  preventPublishToOtherWindows?: boolean
): void {
  const action: Action = { tag: "Logout" };

  dispatch(action);

  if (!preventPublishToOtherWindows) {
    const channel = createBroadcastChannel(logoutChannelName);
    const ret = channel.postMessage(action) as any;
    if (ret?.then) {
      ret.then(() => channel.close());
    } else {
      channel.close();
    }
  }
}
