import React, { useState } from "react";
import { Dialog, DialogContent, DialogButton, DialogTitle } from "~/dialog";
import { DialogFooter } from "~/dialog-footer";
import "./ConfirmationDialog.scss";

export type ConfirmationDialogProps = {
  action: string;
  objectName: string;
  onConfirmation: () => void;
  onCancel: () => void;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
  const { action, objectName, onConfirmation, onCancel } = props;
  const [dialogIsOpen, setDialogIsOpen] = useState(true);

  const confirm = () => {
    onConfirmation();
    setDialogIsOpen(false);
  };

  const cancel = () => {
    onCancel();
    setDialogIsOpen(false);
  };

  const handleOnClosing = () => {
    setDialogIsOpen(false);
    onCancel();
  };

  return (
    <Dialog
      open={dialogIsOpen}
      className="confirmation-dialog"
      onClose={handleOnClosing}
    >
      <DialogTitle>{action}</DialogTitle>
      <DialogContent>
        <p>
          Are you sure you want to {action.toLowerCase()} this {objectName}?
        </p>
      </DialogContent>
      <DialogFooter>
        <DialogButton action="cancel" onClick={cancel}>
          Cancel
        </DialogButton>
        <DialogButton action={action} onClick={confirm} isDefaultAction>
          {action}
        </DialogButton>
      </DialogFooter>
    </Dialog>
  );
};

export default ConfirmationDialog;
