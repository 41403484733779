import React from "react";
import Card from "@material/react-card";
import Table from "~/old-table";
import TableCell from "~/old-table-cell";
import TableHeader from "~/old-table-header";
import "./OverheadCrewCodes.scss";
import PageTitleRow from "~/page-title-row";
import Spinner from "~/spinner";
import { OverheadCrewCodeRow } from "./OverheadCrewCodeRow";
import { AddOverheadCrewCode } from "./AddOverheadCrewCode";
import FieldOfficePickerFormField from "~/visuals/organisms/FieldOfficePickerFormField";
import StatePickerFormField from "~/visuals/organisms/StatePickerFormField";
import { useStateOverheadCrewCodesQuery } from "./stateOverheadCrewCodes.generated";
import { useOfficeOverheadCrewCodesQuery } from "./officeOverheadCrewCodes.generated";
import { OfficeOverheadCrewCode, StateOverheadCrewCode } from "~/gql/types";

export const OverheadCrewCodes: React.FC = () => {
  const {
    data: stateData,
    refetch: stateReload,
    loading: stateLoading,
  } = useStateOverheadCrewCodesQuery();
  const {
    data: officeData,
    refetch: officeReload,
    loading: officeLoading,
  } = useOfficeOverheadCrewCodesQuery();

  const stateCrewCodes = (stateData?.overheadCrewCodes?.stateCrewCodes ??
    []) as StateOverheadCrewCode[];
  const officeCrewCodes = (officeData?.overheadCrewCodes?.officeCrewCodes ??
    []) as OfficeOverheadCrewCode[];

  return (
    <>
      <PageTitleRow title="Overhead Crew Codes" />
      <div className="overhead-crewcodes">
        <Spinner open={stateLoading || officeLoading} />
        <Card className="overhead-crewcode-table">
          <AddOverheadCrewCode
            {...{
              refetch: stateReload,
              itemKey: "state",
              FormField: (
                <StatePickerFormField
                  formField="state"
                  label="State"
                  helperText=""
                  required
                />
              ),
            }}
          />
          <Table compact>
            <TableHeader>
              <TableCell text="State" />
              <TableCell text="Crew Code" />
            </TableHeader>
            {stateCrewCodes.map((cc, idx) => (
              <OverheadCrewCodeRow
                key={idx}
                {...{
                  crewCode: cc,
                  itemKey: "state",
                  refetch: stateReload,
                  trailingSeparator: idx !== stateCrewCodes.length - 1,
                }}
              />
            ))}
          </Table>
        </Card>
        <Card className="overhead-crewcode-table">
          <AddOverheadCrewCode
            {...{
              refetch: officeReload,
              itemKey: "fieldOffice",
              FormField: (
                <FieldOfficePickerFormField
                  formField="fieldOffice"
                  label="Office Code"
                  helperText=""
                  required
                />
              ),
            }}
          />
          <Table compact>
            <TableHeader>
              <TableCell text="Office Code" />
              <TableCell text="Crew Code" />
            </TableHeader>
            {officeCrewCodes.map((cc, idx) => (
              <OverheadCrewCodeRow
                key={idx}
                {...{
                  crewCode: cc,
                  itemKey: "fieldOffice",
                  refetch: officeReload,
                  trailingSeparator: idx !== officeCrewCodes.length - 1,
                }}
              />
            ))}
          </Table>
        </Card>
      </div>
    </>
  );
};
