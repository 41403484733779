import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { ThirdPartyInvoiceFilterState } from "~/gql/types";
import { Suggestion } from "~/ultra-filter/types";

export const thirdPartyInvoiceStates = [
  { value: "Any", text: "Any" },
  { value: "Unreviewed", text: "Unreviewed" },
  { value: "Approved", text: "Approved" },
  { value: "Rejected", text: "Rejected" },
  { value: "Billed", text: "Billed" },
] as Suggestion[];

const format = (item: ThirdPartyInvoiceFilterState) =>
  thirdPartyInvoiceStates.find((s) => s.value === item)?.text;

export const ThirdPartyInvoiceStatePicker: React.FC<FormFieldProps> = (
  props
) => {
  const getData = async (searchText: string) => {
    if (!searchText) {
      return thirdPartyInvoiceStates.map((s) => s.value);
    }
    return thirdPartyInvoiceStates
      .filter(
        (s) =>
          s.value.toLowerCase().startsWith(searchText.toLowerCase()) ||
          s.text.toLowerCase().startsWith(searchText.toLowerCase())
      )
      .map((s) => s.value);
  };

  const args = {
    ...props,
    getData,
    format,
  } as TypeaheadProps<ThirdPartyInvoiceFilterState>;

  return <TypeaheadFormField {...args} />;
};
