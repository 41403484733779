import React, { ReactElement } from "react";
import { Body1 } from "@material/react-typography";
import "./ErrorMessage.scss";

export interface ErrorMessageProps {
  errorMessage: string | null;
}

export default function ErrorMessage(props: ErrorMessageProps): ReactElement {
  return (
    <>
      {props.errorMessage && (
        <Body1 className="error" data-testid="error">
          {props.errorMessage}
        </Body1>
      )}
    </>
  );
}
