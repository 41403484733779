import React from "react";
import { GroupDialogProps } from "./GroupingDialog";
import { Body1 } from "@material/react-typography";
import { Checkbox } from "rmwc";
import { RevenueReportGroupingArgsInput } from "~/gql/types";
import FormBottomRow from "~/form-bottom-row";
import { fields } from "./fields";

type GroupFormProps = GroupDialogProps & {
  setOpen: React.Dispatch<boolean>;
  onClose: React.DispatchWithoutAction;
  setFormVals: (
    value: React.SetStateAction<RevenueReportGroupingArgsInput>
  ) => void;
  formVals: RevenueReportGroupingArgsInput;
};
export const GroupForm: React.FC<GroupFormProps> = ({
  setOpen,
  onClose,
  setOptions,
  setFormVals,
  formVals,
}) => {
  const onConfirm = () => {
    setOpen(false);
    setOptions(formVals);
  };
  return (
    <>
      <div className="fields-container">
        {fields.map(({ key, label }) => {
          return (
            <GroupFormField
              key={key}
              id={key}
              label={label}
              checked={formVals[key]!}
              onChange={() =>
                setFormVals((obj) => ({ ...obj, [key]: !obj[key] }))
              }
            />
          );
        })}
      </div>

      <FormBottomRow
        {...{
          onSubmit: onConfirm,
          errorMessage: "",
          buttonText: "Confirm",
          onCancel: onClose,
        }}
      />
    </>
  );
};

type GroupFormFieldProps = {
  label: string;
  id: string;
  checked: boolean;
  onChange: React.DispatchWithoutAction;
};
const GroupFormField: React.FC<GroupFormFieldProps> = ({
  label,
  onChange,
  checked,
  id,
}) => {
  return (
    <div className="group-field-container">
      <Body1>{label}</Body1>
      <Checkbox
        id={id}
        checked={checked || false}
        onChange={() => onChange()}
      />
    </div>
  );
};
