import React from "react";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import { UltraFilter } from "~/ultra-filter";
import { CompanyRole } from "~/role-accordion/RoleAccordion";
import { useCompanyRoleProvider } from "~/roles/useCompanyRoleProvider";

export type FilterOptions = {
  searchText: string | null;
  role: CompanyRole | null;
};

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<FilterOptions>;
};

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const providers = [
    useCompanyRoleProvider({
      documentationText: "The company role",
    }),
  ] as FilterProvider[];

  const onChanged = (filters: ActiveFilter[], searchText: string) => {
    searchText;

    const newFilters = {
      searchText: searchText || null,
      role: filters.find((f) => f.type === "role")?.value || null,
    };

    onFiltersChanged(newFilters);
  };

  return (
    <div className="filter-form">
      <UltraFilter
        {...{
          providers,
          dialogTitle: "Employee Roles Search",
          label: "Type to search employee roles",
          onFiltersChanged: onChanged,
        }}
      />
    </div>
  );
};
