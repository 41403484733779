import { useParams } from "react-router-dom";
import TitledCard from "~/titled-card";
import React from "react";
import { Division } from "~/refdata2/divisions";
import { DivisionForm } from "./divisionForm";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";

type DivisionParams = {
  id: string;
};

type DivisionsPageProps = {
  divisions: Division[];
  refetch: React.DispatchWithoutAction;
};

export const DivisionPage: React.FC<DivisionsPageProps> = ({
  divisions,
  refetch,
}) => {
  const { id } = useParams<DivisionParams>();

  useBreadcrumbs(
    [{ text: "Divisions", to: "/divisions" }, { text: id ?? "" }],
    [id]
  );

  const division = divisions.find((x) => x.id == id);

  return (
    <TitledCard
      title={`Edit ${division?.id}${
        !division?.isActive ? " - (inactive)" : ""
      }`}
      subtitle={division?.name}
    >
      <DivisionForm {...{ division, refetch }} />
    </TitledCard>
  );
};
