import React, { useMemo } from "react";
import { useSnackBar } from "~/snackbar";
import { VersionChecker } from "./VersionChecker";

export const actionLabel = "Update";
export const snackbarMessage = `There are some new updates available to the Workflow app. Click '${actionLabel}' to refresh your browser and get the latest updates.`;
export const snackbarKey = "VersionCheck";

export const intervalMs = 30 * 60 * 1000;

export type VersionCheckFactory = (
  fetchFn: typeof window.fetch,
  onVersionChanged: React.DispatchWithoutAction,
  interval: number
) => TVersionChecker;

type VersionCheckProps = {
  versionCheckFactory?: VersionCheckFactory;
};

export const VersionCheck: React.FC<VersionCheckProps> = ({
  versionCheckFactory,
}) => {
  const addAlert = useSnackBar();
  const onVersionChange = () =>
    addAlert({
      message: snackbarMessage,
      key: snackbarKey,
      isSuccess: true,
      action: {
        label: actionLabel,
        action: () => window.location.reload(),
      },
    });

  const versionCheckIntervalMs = parseInt(
    localStorage.getItem("versionCheckIntervalMs") ?? intervalMs + ""
  );

  // eslint-disable-next-line
  useMemo(() => {
    const factory =
      versionCheckFactory || ((...args) => new VersionChecker(...args));
    return factory(
      window.fetch.bind(window),
      onVersionChange,
      versionCheckIntervalMs
    );
  }, []);

  return null;
};

type TVersionChecker = Record<string, never>;
