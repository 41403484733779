import React, { useMemo } from "react";
import { RevenueReportGroupingArgsInput } from "~/gql/types";
import { useSearchParams } from "react-router-dom";

type UseGroupingOptionsFromParamsResult = [
  RevenueReportGroupingArgsInput,
  React.Dispatch<React.SetStateAction<RevenueReportGroupingArgsInput>>
];

export function useGroupingOptionsFromParams(): UseGroupingOptionsFromParamsResult {
  const [params, setParams] = useSearchParams();
  const groupByKey = "groupby";

  const groupingOptionsStr = params.get(groupByKey) || "";
  const options = useMemo(() => {
    const keys = groupingOptionsStr
      .split(",")
      .filter((x) => x.trim().length > 0);
    const result = {};
    keys.forEach((key) => {
      result[key] = true;
    });

    return result;
  }, [groupingOptionsStr]);

  const setOptions = (
    argOpts: React.SetStateAction<RevenueReportGroupingArgsInput>
  ) => {
    let opts: RevenueReportGroupingArgsInput;

    if (typeof argOpts === "function") {
      opts = argOpts(options);
    } else {
      opts = argOpts;
    }

    const keys: string[] = [];
    Object.keys(opts).forEach((key) => {
      if (opts[key]) {
        keys.push(key);
      }
    });

    const existingParams = [...params.entries()].filter(
      (x) => x[0] !== groupByKey
    );

    const newParams: [string, string][] = [
      ...existingParams,
      [groupByKey, keys.join(",")],
    ];

    setParams(newParams);
  };

  return [options, setOptions];
}
