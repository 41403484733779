import React, { createContext, useContext } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import Spinner from "~/spinner";
import { DescriptionList } from "~/description-list";
import "./ProjectRequest.scss";
import { currencyFormatter } from "~/currencyFormatter";
import Card from "@material/react-card";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { ProjectRequestForm } from "../ProjectRequestForm";
import { ApproveProjectRequest } from "../ApproveRequest";
import { RejectProjectRequest } from "../RejectRequest";
import PageTitleRow from "~/page-title-row";
import { ProjectRequest, ProjectRequestState } from "~/gql/types";
import { projectRequests } from "../routes";
import { useSingleProjectRequestQuery } from "./query.generated";
import { getCustomerInfo } from "../utils";
import { getCheckedOutBy } from "../getCheckedOutBy";
import { RequestButtons } from "./RequestButtons";
import { Body1 } from "@material/react-typography";
import WarningMessage from "~/warning-message";
import { ProjectRequestPageProps } from "./types";

type ProjectRequestRouteData = {
  requestId: string;
};

export const useReload = (): (() => Promise<unknown>) => {
  const api = useContext(ProjectRequestContext);

  if (!api) {
    throw new Error(
      "Do not use ProjectRequestRouter.useReload outside of a ProjectRequestRouter."
    );
  }

  return api.reload;
};

const PageRoutes: React.FC<{ projectRequest: ProjectRequest }> = ({
  projectRequest,
}) => (
  <Routes>
    <Route
      path="edit"
      element={
        <ProjectRequestForm {...{ projectRequest, useReload, type: "Edit" }} />
      }
    />

    <Route
      path="clone"
      element={
        <ProjectRequestForm {...{ projectRequest, useReload, type: "Clone" }} />
      }
    />
    <Route
      path="approve"
      element={<ApproveProjectRequest {...{ projectRequest }} />}
    />
    <Route
      path="reject"
      element={<RejectProjectRequest {...{ projectRequest }} />}
    />
    <Route
      index
      element={
        <ProjectRequestPage
          projectRequest={projectRequest}
          useReload={useReload}
        />
      }
    />
  </Routes>
);

type ProjectRequestContextApi = {
  reload: () => Promise<unknown>;
};

const ProjectRequestContext = createContext<ProjectRequestContextApi | null>(
  null
);

export const ProjectRequestPage: React.FC<ProjectRequestPageProps> = ({
  projectRequest,
  isGroupReview,
  actions,
  useReload,
}) => {
  useBreadcrumbs(
    [
      { text: "Project Requests", to: projectRequests },
      { text: projectRequest.name },
    ],
    [projectRequest]
  );

  const { customer, customerNumber } = getCustomerInfo(projectRequest);

  const requestState =
    projectRequest.state === ProjectRequestState.PendingCustomerApproved
      ? "Pending Customer Approval"
      : projectRequest.state;

  const reloadRequest = (
    useReload ?? (() => () => Promise.resolve())
  )() as () => Promise<void>;

  const pageActions = isGroupReview ? (
    actions
  ) : (
    <RequestButtons {...{ projectRequest, refetch: reloadRequest }} />
  );

  const isCheckedOut = !!projectRequest.checkedOutBy;

  return (
    <DescriptionList className="project-request-page">
      {projectRequest.state === ProjectRequestState.Pending &&
        !isCheckedOut && <PageTitleRow title={""} actions={pageActions} />}

      {![ProjectRequestState.Pending].includes(projectRequest.state) &&
        !isCheckedOut && (
          <PageTitleRow title={requestState} actions={pageActions} />
        )}

      {isCheckedOut && (
        <PageTitleRow
          title={`Checked out by ${getCheckedOutBy(projectRequest)}`}
          actions={pageActions}
        />
      )}
      <Card>
        {projectRequest.statusMessage && (
          <WarningMessage
            {...{
              icon: "warning",
              message: projectRequest.statusMessage,
            }}
          />
        )}
        <div className="project-request-info">
          <dl>
            <dt>Project Name</dt>
            <dd>{projectRequest.name}</dd>

            <dt>Client Contact Name</dt>
            <dd>{projectRequest.clientContactName}</dd>

            <dt>Office Code</dt>
            <dd>{projectRequest.officeCode}</dd>

            <dt>Customer</dt>
            <dd>
              {customerNumber}: {customer?.name}
            </dd>

            {!!customer && (
              <>
                <dt>Customer Address</dt>
                <dd>{customer.address1}</dd>
                {customer.address2 && (
                  <>
                    <dt></dt>
                    <dd>{customer.address2}</dd>
                  </>
                )}
                <dt></dt>
                {customer.country && (
                  <dd>{`${customer.city}, ${customer.country}`}</dd>
                )}
                {customer.state && (
                  <dd>{`${customer?.city}, ${customer?.state} ${customer?.zip}`}</dd>
                )}
              </>
            )}

            {customer?.phone && (
              <>
                <dt>Customer Phone</dt>
                <dd>{customer.phone}</dd>
              </>
            )}

            {customer?.fax && (
              <>
                <dt>Customer Fax</dt>
                <dd>{customer.fax}</dd>
              </>
            )}

            <dt>Government Funding</dt>
            <dd>{projectRequest.governmentFunding}</dd>

            <dt>Project Manager</dt>
            <dd>{`${projectRequest.projectManager?.firstName} ${projectRequest.projectManager?.lastName}`}</dd>

            <dt>Budget</dt>
            <dd>{currencyFormatter.format(projectRequest.budget || 0)}</dd>

            <dt>Service Description</dt>
            <dd>{projectRequest.serviceDescription!.displayName}</dd>

            <dt>Project Origination</dt>
            <dd>{projectRequest.projectOrigination}</dd>

            {projectRequest.projectOriginationName && (
              <>
                <dt>Origination Name</dt>
                <dd>{projectRequest.projectOriginationName}</dd>
              </>
            )}

            <dt>Taxable Services</dt>
            <dd>{projectRequest.taxableServices ? "Yes" : "No"}</dd>

            <dt>Jobs Folder Required</dt>
            <dd>{projectRequest.jobsFolderRequired ? "Yes" : "No"}</dd>

            <dt>Scope</dt>
            <dd>{projectRequest.scope}</dd>
          </dl>
          <dl>
            <dt>State/County</dt>
            <dd>{`${projectRequest.location!.state}-${
              projectRequest.location!.county
            }`}</dd>

            <dt>Section</dt>
            <dd>{projectRequest.section || ""}</dd>

            <dt>Township</dt>
            <dd>{projectRequest.township || ""}</dd>

            <dt>Range</dt>
            <dd>{projectRequest.range || ""}</dd>

            <dt>Block</dt>
            <dd>{projectRequest.block || ""}</dd>

            <dt>Survey</dt>
            <dd>{projectRequest.survey || ""}</dd>

            <dt>Lat/Long</dt>
            <dd>{projectRequest.geographicLocation || ""}</dd>
          </dl>
          <dl>
            <dt>Cogo</dt>
            <dd>{projectRequest.coGo || ""}</dd>

            <dt>AFE</dt>
            <dd>{projectRequest.afe || ""}</dd>

            <dt>PO</dt>
            <dd>{projectRequest.po || ""}</dd>

            <dt>WO</dt>
            <dd>{projectRequest.wo || ""}</dd>

            <dt>Contract Number</dt>
            <dd>{projectRequest.contractNumber || ""}</dd>

            <dt>Cost Center</dt>
            <dd>{projectRequest.costCenter || ""}</dd>

            <dt>GL Code </dt>
            <dd>{projectRequest.glCode || ""}</dd>

            <dt>Requisitioner</dt>
            <dd>{projectRequest.requisitioner || ""}</dd>

            <dt>Field Instructions</dt>
            <dd>{projectRequest.fieldInstructions || ""}</dd>

            <dt>Field Meet Info</dt>
            <dd>{projectRequest.fieldMeetInfo || ""}</dd>

            <dt>Field Contact</dt>
            <dd>{projectRequest.fieldContact || ""}</dd>

            <dt>Project Instructions</dt>
            <dd>{projectRequest.projectInstructions || ""}</dd>

            <dt>Office Instructions</dt>
            <dd>{projectRequest.projectInstructions || ""}</dd>

            <dt>Invoice Submit Email</dt>
            <dd>{projectRequest.invoiceSubmitEmail || ""}</dd>

            <dt>Invoice Contact</dt>
            <dd>{projectRequest.invoiceContact || ""}</dd>

            <dt>Additional Comments</dt>
            <dd>{projectRequest.additionalComments || ""}</dd>

            {projectRequest.approver && (
              <>
                <dt>Approved by</dt>
                <dd>{`${projectRequest.approver.firstName} ${projectRequest.approver.lastName}`}</dd>
              </>
            )}

            {projectRequest.rejectedReason && (
              <>
                <dt>Rejected Reason</dt>
                <dd>{projectRequest.rejectedReason}</dd>
              </>
            )}
          </dl>
        </div>
      </Card>
    </DescriptionList>
  );
};

export const ProjectRequestRouter: React.FC = () => {
  const params = useParams<ProjectRequestRouteData>();
  const variables = {
    requestId: params.requestId!,
  };

  const { data, loading, refetch } = useSingleProjectRequestQuery({
    variables,
  });
  const projectRequest = data?.projectRequests?.single as ProjectRequest;

  if (projectRequest && !loading) {
    const api = { reload: refetch };

    return (
      <ProjectRequestContext.Provider value={api}>
        <PageRoutes projectRequest={projectRequest} />
      </ProjectRequestContext.Provider>
    );
  }
  if (!projectRequest && !loading) {
    return <Body1>Project request not found</Body1>;
  }
  return <Spinner open={true} />;
};

export const MockProjectRequestContextProvider: React.FC = ({ children }) => {
  const api = {
    reload: () => Promise.resolve(null),
  };

  return (
    <ProjectRequestContext.Provider value={api}>
      {children}
    </ProjectRequestContext.Provider>
  );
};
