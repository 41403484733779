import moment, { Moment } from "moment";
import React from "react";
import { Project } from "~/gql/types";
import ProjectPickerFormField from "~/visuals/organisms/ProjectPickerFormField";
import { useChargeFormField } from "./fieldUtils";
import { ChargeFormData } from "../types";

export const ProjectField: React.FC = () => {
  const date = useChargeFormField("date").value;

  const momentDate = (date: string): Moment => moment(date, "YYYY-MM-DD");

  const validate = (val, allVals) => {
    const allValues = allVals as ChargeFormData;

    if (val && allValues.date) {
      const { actualStartDate, actualEndDate } = val as Project;
      const formattedDate = allValues.date.format("YYYY-MM-DD");

      if (
        actualStartDate &&
        momentDate(actualStartDate).isAfter(allValues.date)
      ) {
        return `Project ${val.name} (${val.number}) has a start date which is after ${formattedDate}`;
      }

      if (actualEndDate && momentDate(actualEndDate).isBefore(allValues.date)) {
        return `Project ${val.name} (${val.number}) has an end date which is before ${formattedDate}`;
      }
    }
  };

  const disabled = !date;

  return (
    <ProjectPickerFormField
      formField="project"
      label="Project"
      helperText=""
      disabled={disabled}
      required
      date={(date ?? moment()).format("YYYY-MM-DD")}
      validator={validate}
    />
  );
};
