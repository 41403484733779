import { ApiCallback } from "~/WorkDayApi/WorkDayApiType";
import { SaveAndAction } from "~/WorkDayApi/SaveAndHandler";
import { toCallback } from "~/WorkDayApi/utils";
import { useTimeCopy } from "~/WorkDayApi/WorkDayCopyProvider";
import { CreateTimeEntryResponse } from "~/WorkDayApi/createTimeEntryApi";

export function useTimeCallback(): (
  values: Record<string, any>
) => ApiCallback | undefined {
  const { setCopyToDate, setCopyToDates } = useTimeCopy();

  return (values: Record<string, any>) => {
    const copyToDateCallback = (response: CreateTimeEntryResponse) =>
      Promise.resolve(setCopyToDate(response.timeEntryId));

    const copyToDatesCallback = (response: CreateTimeEntryResponse) =>
      Promise.resolve(setCopyToDates(response.timeEntryId));

    const callbackMap: Partial<Record<SaveAndAction, ApiCallback>> = {
      copyToDate: copyToDateCallback,
      copyToDates: copyToDatesCallback,
    };

    return toCallback(callbackMap, values);
  };
}
