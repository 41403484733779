import React from "react";
import { CheckBox } from "~/checkbox";
import ClickSwallower from "~/click-swallower";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import styles from "./CardSelectCheckbox.module.scss";
import cn from "classnames";

type CardSelectCheckboxProps<T, TFilter> = {
  state: State<T, TFilter> | null;
  dispatch: React.Dispatch<Action<T, TFilter>>;
  item: T;
};

export const CardSelectCheckbox = <T, TFilter>({
  state,
  dispatch,
  item,
}: CardSelectCheckboxProps<T, TFilter>): React.ReactElement => {
  if (!state) {
    return <></>;
  }

  const onSelectChange = (checked: boolean) => {
    if (checked) {
      dispatch({ tag: "SelectItem", item });
    } else {
      dispatch({ tag: "UnselectItem", id: item[state.selectIdKey] });
    }
  };

  return (
    <div className={cn(styles.CardSelectCheckbox, "card-select-checkbox")}>
      <ClickSwallower>
        <CheckBox
          className={styles.CheckboxOuter}
          innerClassName={styles.CheckboxInner}
          onChange={onSelectChange}
          checked={state.selectedItems.some(
            (x) => x[state.selectIdKey] === item[state.selectIdKey]
          )}
        />
      </ClickSwallower>
    </div>
  );
};
