import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import { createProvider } from "./createFilterProvider";
import WorkLocationPickerFormField from "~/visuals/organisms/WorkLocationPickerFormField";
import {
  useWorkLocationFromParam,
  useWorkLocationsAsync,
} from "~/refdata2/workLocations";

export function useWorkLocationProvider(
  props: FilterProviderProps
): FilterProvider {
  const lcoationFromParam = useWorkLocationFromParam();
  const getLocations = useWorkLocationsAsync();

  const type = "workLocation";
  const label = "Work Location";

  return createProvider({
    render: () => (
      <WorkLocationPickerFormField
        formField={type}
        label={label}
        helperText=""
      />
    ),
    type,
    label,
    props,
    getItems: getLocations,
    itemFromParam: lcoationFromParam,
    shouldSuggestText: "work",
    toSuggestions: (result, text) =>
      (result as string[])
        .filter((l) => l.toLowerCase().includes(text.toLowerCase()))
        .map((l) => ({ text: l, value: l })),
    toLabelValue: (value: string) => value,
    toParamValue: (value: string) => value,
  });
}
