import React from "react";
import { useProjectManagerProvider } from "~/filterProviders/employeeProviders";
import { useCustomerProvider } from "~/filterProviders/useCustomerProvider";
import { useDateProvider } from "~/filterProviders/useDateProvider";
import { useFieldOfficeProvider } from "~/filterProviders/useFieldOfficeProvider";
import { useProjectProvider } from "~/filterProviders/useProjectProvider";
import { useProjectTypeProvider } from "~/filterProviders/useProjectTypeProvider";
import { RevenueReportFilteringArgsInput } from "~/gql/types";
import { UltraFilter } from "~/ultra-filter";
import { ActiveFilter } from "~/ultra-filter/types";

type FilteringControlsProps = {
  setFilters: (value: RevenueReportFilteringArgsInput) => void;
};
export const FilteringControls: React.FC<FilteringControlsProps> = ({
  setFilters,
}) => {
  const providers = [
    useDateProvider({
      documentationText: "Filter by date before this date (exclusive).",
      type: "before",
      label: "Before Date",
      suggestText: "bef",
    }),
    useDateProvider({
      documentationText: "Filter by date after this date (exclusive).",
      type: "after",
      label: "After Date",
      suggestText: "aft",
    }),
    useProjectManagerProvider({
      documentationText: "Filter by Project Manager.",
    }),
    useCustomerProvider({
      documentationText: "Filter by Customer.",
    }),
    useProjectProvider({
      documentationText: "Filter by project.",
    }),
    useFieldOfficeProvider({
      documentationText: "Filter by Office Code.",
    }),
    useProjectTypeProvider({
      documentationText: "Filter by Project Type.",
    }),
  ];
  const onChanged = (filters: ActiveFilter[]) => {
    const newFilters: RevenueReportFilteringArgsInput = {
      before: filters.find((f) => f.type === "before")?.value || null,
      after: filters.find((f) => f.type === "after")?.value || null,
      customerNumber:
        filters.find((f) => f.type === "customer")?.value.number || null,
      officeCode:
        filters.find((f) => f.type === "officeCode")?.value.officeCode || null,
      projectNumber:
        filters.find((f) => f.type === "project")?.value.number || null,
      projectManagerEmail:
        filters.find((f) => f.type === "projectManager")?.value
          .userPrincipalName || null,
      projectType: filters.find((f) => f.type == "projectType")?.value || null,
      industry: filters.find((f) => f.type == "industry")?.value || null,
      serviceDescriptionId:
        filters.find((f) => f.type == "serviceDescription")?.value.id || null,
    };
    setFilters(newFilters);
  };
  return (
    <div className="filter-controls">
      <UltraFilter
        {...{
          providers,
          dialogTitle: "Revenue Report Filters",
          label: "Revenue Report Filters",
          onFiltersChanged: onChanged,
          ignoreSearchText: true,
        }}
      />
    </div>
  );
};
