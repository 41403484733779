import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEmployeeRateMutationVariables = Types.Exact<{
  rate: Types.Scalars['Decimal'];
  ultiProId: Types.Scalars['String'];
  effectiveDate: Types.Scalars['LocalDate'];
}>;


export type CreateEmployeeRateMutation = { __typename?: 'MutationModel', employeeRates?: { __typename?: 'EmployeeRatesMutationModel', createRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CreateEmployeeRateDocument = gql`
    mutation CreateEmployeeRate($rate: Decimal!, $ultiProId: String!, $effectiveDate: LocalDate!) {
  employeeRates {
    createRate(rate: $rate, ultiProId: $ultiProId, effectiveDate: $effectiveDate) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CreateEmployeeRateMutationFn = Apollo.MutationFunction<CreateEmployeeRateMutation, CreateEmployeeRateMutationVariables>;

/**
 * __useCreateEmployeeRateMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeRateMutation, { data, loading, error }] = useCreateEmployeeRateMutation({
 *   variables: {
 *      rate: // value for 'rate'
 *      ultiProId: // value for 'ultiProId'
 *      effectiveDate: // value for 'effectiveDate'
 *   },
 * });
 */
export function useCreateEmployeeRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeRateMutation, CreateEmployeeRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeRateMutation, CreateEmployeeRateMutationVariables>(CreateEmployeeRateDocument, options);
      }
export type CreateEmployeeRateMutationHookResult = ReturnType<typeof useCreateEmployeeRateMutation>;
export type CreateEmployeeRateMutationResult = Apollo.MutationResult<CreateEmployeeRateMutation>;
export type CreateEmployeeRateMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeRateMutation, CreateEmployeeRateMutationVariables>;