import React from "react";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import Spinner from "~/spinner";
import { useSnackBar } from "~/snackbar";
import { BulkActionWrapper } from "~/visuals/organisms/BulkActions/BulkActionWrapper";
import { useResetSearchItems } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import FieldOfficePickerFormField from "~/visuals/organisms/FieldOfficePickerFormField";
import "./BulkProjectComponents.scss";
import FormBottomRow from "~/form-bottom-row";
import TextFormField from "~/text-form-field";
import { EmployeePicker } from "~/visuals/organisms/EmployeePickerFormField";
import CorpLocationPickerFormField from "~/visuals/organisms/CorpLocationPickerFormField";
import DivisionPickerFormField from "~/visuals/organisms/DivisionPickerFormField";
import ServiceDescriptionPicker from "~/visuals/organisms/ServiceDescriptionPicker";
import EnumDropdownFormField from "~/visuals/organisms/EnumDropdownFormField";
import { Project } from "~/gql/types";
import { MutationHookOptions, MutationTuple } from "@apollo/client";
import { useBulkEditProjectOfficeCodeMutation } from "./editOfficeCode.generated";
import { useBulkEditBillingAdminMutation } from "./editBillingAdmin.generated";
import { useBulkEditProjectManagerMutation } from "./editProjectManager.generated";
import { useBulkEditProjectSupervisorMutation } from "./editProjectSupervisor.generated";
import { useBulkEditCorpLocationMutation } from "./editCorpLocation.generated";
import { useBulkEditDivisionMutation } from "./editDivision.generated";
import { useBulkEditServiceDescriptionMutation } from "./editServiceDescription.generated";
import { useBulkEditProjectStatusMutation } from "./editStatus.generated";
import moment from "moment";
import { useBulkEditClientContactNameMutation } from "./editClientContactName.generated";

type BaseProps = {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  selectedProjects: Project[];
};

type BulkProjectActionProps = BaseProps & {
  type: string;
  title: string;
  mutation: string;
  mutationHook: (
    baseOptions?: MutationHookOptions<any, any>
  ) => MutationTuple<any, any>;
  args: (values: Record<string, any>) => Record<string, any>;
  initialValue: Record<string, any>;
  children: React.ReactNode;
};

export const BulkProjectActionWrapper: React.FC<BulkProjectActionProps> = (
  props
) => {
  const {
    type,
    initialValue,
    selectedProjects,
    mutation,
    mutationHook,
    args,
    ...rest
  } = props;

  const addAlert = useSnackBar();
  const reload = useResetSearchItems();

  const onSuccess = () => {
    addAlert({
      key: `${Math.random()}`,
      message: `${type} edited on ${selectedProjects.length} projects`,
      isSuccess: true,
    });
    reload();
    props.setOpen(false);
  };

  const [doMutation, { loading }] = mutationHook();

  const runMutation = async (values: Record<string, any>) => {
    const variables = {
      ...args(values),
      projectNumbers: selectedProjects.map((x) => x.number),
    };
    const result = await doMutation({ variables });

    return result?.data?.projects?.[mutation];
  };

  return (
    <BulkActionWrapper {...{ ...rest, className: "edit-project-bulk-action" }}>
      <MutationForm
        onSuccess={onSuccess}
        initialValues={initialValue}
        runMutation={runMutation}
      >
        <ErrorRenderer
          render={(error) => (
            <>
              {props.children}
              <FormBottomRow
                errorMessage={error}
                buttonText="Save"
                onCancel={() => props.setOpen(false)}
              />
            </>
          )}
        />
        <Spinner open={loading} />
      </MutationForm>
    </BulkActionWrapper>
  );
};

export const EditProjectOfficeCode: React.FC<BaseProps> = (props) => {
  return (
    <BulkProjectActionWrapper
      {...{
        ...props,
        title: "Edit Office Code",
        type: "Office Code",
        mutation: "editOfficeCode",
        mutationHook: useBulkEditProjectOfficeCodeMutation,
        args: (values: Record<string, any>) => ({
          officeCode: values.officeCode.officeCode,
        }),
        initialValue: { officeCode: null },
      }}
    >
      <FieldOfficePickerFormField
        formField="officeCode"
        label="Office Code"
        helperText=""
        required
      />
    </BulkProjectActionWrapper>
  );
};

export const EditProjectClientContact: React.FC<BaseProps> = (props) => {
  return (
    <BulkProjectActionWrapper
      {...{
        ...props,
        title: "Edit Client Contact Name",
        type: "Client Contact Name",
        mutation: "editClientContactName",
        mutationHook: useBulkEditClientContactNameMutation,
        args: (values: Record<string, any>) => ({
          clientContactName: values.clientContactName,
        }),
        initialValue: { clientContactName: null },
        operationName: "EditProjectClientContactName",
      }}
    >
      <TextFormField
        formField="clientContactName"
        label="Client Contact Name"
        helperText=""
        required
      />
    </BulkProjectActionWrapper>
  );
};

export const EditProjectBillingAdmin: React.FC<BaseProps> = (props) => {
  return (
    <BulkProjectActionWrapper
      {...{
        ...props,
        title: "Edit Billing Admin",
        type: "Billing Admin",
        mutation: "editBillingAdmin",
        mutationHook: useBulkEditBillingAdminMutation,
        args: (values: Record<string, any>) => ({
          userPrincipalName: values.billingAdmin.userPrincipalName,
        }),
        initialValue: { billingAdmin: null },
        operationName: "EditProjectBillingAdmin",
      }}
    >
      <EmployeePicker
        formField="billingAdmin"
        label="Billing Admin"
        helperText=""
        roleName="Billing Admin"
        activePayrollDate={moment().format("YYYY-MM-DD")}
        required
      />
    </BulkProjectActionWrapper>
  );
};

export const EditProjectManager: React.FC<BaseProps> = (props) => {
  return (
    <BulkProjectActionWrapper
      {...{
        ...props,
        title: "Edit Project Manager",
        type: "Project Manager",
        mutation: "editProjectManager",
        mutationHook: useBulkEditProjectManagerMutation,
        args: (values: Record<string, any>) => ({
          userPrincipalName: values.projectManager.userPrincipalName,
        }),
        initialValue: { projectManager: null },
        operationName: "EditProjectManager",
      }}
    >
      <EmployeePicker
        formField="projectManager"
        label="Project Manager"
        helperText=""
        roleName="Project Manager"
        activePayrollDate={moment().format("YYYY-MM-DD")}
        required
      />
    </BulkProjectActionWrapper>
  );
};

export const EditProjectSupervisor: React.FC<BaseProps> = (props) => {
  return (
    <BulkProjectActionWrapper
      {...{
        ...props,
        title: "Edit Project Supervisor",
        type: "Project Supervisor",
        mutation: "editProjectSupervisor",
        mutationHook: useBulkEditProjectSupervisorMutation,
        args: (values: Record<string, any>) => ({
          userPrincipalName: values.projectSupervisor.userPrincipalName,
        }),
        initialValue: { projectSupervisor: null },
        operationName: "EditProjectSupervisor",
      }}
    >
      <EmployeePicker
        formField="projectSupervisor"
        label="Project Supervisor"
        helperText=""
        roleName="Project Supervisor"
        activePayrollDate={moment().format("YYYY-MM-DD")}
        required
      />
    </BulkProjectActionWrapper>
  );
};

export const EditCorpLocation: React.FC<BaseProps> = (props) => {
  return (
    <BulkProjectActionWrapper
      {...{
        ...props,
        title: "Edit Location",
        type: "Location",
        mutation: "editCorpLocation",
        mutationHook: useBulkEditCorpLocationMutation,
        args: (values: Record<string, any>) => ({
          corpLocationId: values.corpLocation.id,
        }),
        initialValue: { corpLocation: null },
        operationName: "EditProjectCorpLocation",
      }}
    >
      <CorpLocationPickerFormField
        formField="corpLocation"
        label="Location"
        helperText=""
        required
      />
    </BulkProjectActionWrapper>
  );
};

export const EditDivision: React.FC<BaseProps> = (props) => {
  return (
    <BulkProjectActionWrapper
      {...{
        ...props,
        title: "Edit Division",
        type: "Division",
        mutation: "editDivision",
        mutationHook: useBulkEditDivisionMutation,
        args: (values: Record<string, any>) => ({
          divisionId: values.division.id,
        }),
        initialValue: { division: null },
        operationName: "EditProjectDivision",
      }}
    >
      <DivisionPickerFormField
        formField="division"
        label="Division"
        helperText=""
        required
      />
    </BulkProjectActionWrapper>
  );
};

export const EditServiceDescription: React.FC<BaseProps> = (props) => {
  return (
    <BulkProjectActionWrapper
      {...{
        ...props,
        title: "Edit Service Description",
        type: "Service Description",
        mutation: "editServiceDescription",
        mutationHook: useBulkEditServiceDescriptionMutation,
        args: (values: Record<string, any>) => ({
          serviceDescription: values.serviceDescription.displayName,
        }),
        initialValue: { serviceDescription: null },
        operationName: "EditProjectServiceDescription",
      }}
    >
      <ServiceDescriptionPicker
        formField="serviceDescription"
        label="Service Description"
        helperText=""
        required
      />
    </BulkProjectActionWrapper>
  );
};

export const EditStatus: React.FC<BaseProps> = (props) => {
  return (
    <BulkProjectActionWrapper
      {...{
        ...props,
        title: "Edit Status",
        type: "Status",
        mutation: "editStatus",
        mutationHook: useBulkEditProjectStatusMutation,
        args: (values: Record<string, any>) => ({ status: values.status }),
        initialValue: { status: null },
        operationName: "EditProjectStatus",
      }}
    >
      <EnumDropdownFormField
        formField="status"
        label="Status"
        helperText=""
        required
        enumType="ProjectStatus"
      />
    </BulkProjectActionWrapper>
  );
};
