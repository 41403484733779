import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveLineItemInvoiceMutationVariables = Types.Exact<{
  invoiceNumber: Types.Scalars['String'];
  customRollupInvoiceNumber: Types.Scalars['String'];
}>;


export type RemoveLineItemInvoiceMutation = { __typename?: 'MutationModel', customRollupInvoices?: { __typename?: 'CustomRollupInvoiceMutationModel', removeLineItem?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const RemoveLineItemInvoiceDocument = gql`
    mutation RemoveLineItemInvoice($invoiceNumber: String!, $customRollupInvoiceNumber: String!) {
  customRollupInvoices {
    removeLineItem(
      invoiceNumber: $invoiceNumber
      customRollupInvoiceNumber: $customRollupInvoiceNumber
    ) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type RemoveLineItemInvoiceMutationFn = Apollo.MutationFunction<RemoveLineItemInvoiceMutation, RemoveLineItemInvoiceMutationVariables>;

/**
 * __useRemoveLineItemInvoiceMutation__
 *
 * To run a mutation, you first call `useRemoveLineItemInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLineItemInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLineItemInvoiceMutation, { data, loading, error }] = useRemoveLineItemInvoiceMutation({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *      customRollupInvoiceNumber: // value for 'customRollupInvoiceNumber'
 *   },
 * });
 */
export function useRemoveLineItemInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLineItemInvoiceMutation, RemoveLineItemInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveLineItemInvoiceMutation, RemoveLineItemInvoiceMutationVariables>(RemoveLineItemInvoiceDocument, options);
      }
export type RemoveLineItemInvoiceMutationHookResult = ReturnType<typeof useRemoveLineItemInvoiceMutation>;
export type RemoveLineItemInvoiceMutationResult = Apollo.MutationResult<RemoveLineItemInvoiceMutation>;
export type RemoveLineItemInvoiceMutationOptions = Apollo.BaseMutationOptions<RemoveLineItemInvoiceMutation, RemoveLineItemInvoiceMutationVariables>;