import { getWebInstrumentations, initializeFaro } from "@grafana/faro-web-sdk";
import { getEmail, tokenStorageKey } from "~/Auth";

const unsetEmail = "unset@example.com";
let email: string = unsetEmail;

const errorsToIgnore = new Set<string>([
  "Response not successful: Received status code 401",
  "Failed to fetch",
]);

const apiKey = window.config.grafanaFrontendApiKey;
if (process?.env?.NODE_ENV !== "development" && !!apiKey) {
  initializeFaro({
    url: `https://faro-collector-prod-us-central-0.grafana.net/collect/${apiKey}`,
    app: {
      name: "Wolfkrow-Frontend",
      version: window.config.version,
      environment: window?.config?.errorReporting?.service
        ?.split("-")
        .slice(-1)[0],
    },
    instrumentations: [...getWebInstrumentations()],
    beforeSend: (item) => {
      item.meta.user = {
        email,
      };
      const payload: any = item.payload;

      if (errorsToIgnore.has(payload?.value)) {
        return null;
      }

      return item;
    },
  });

  setInterval(() => {
    const newEmail = getEmail(localStorage.getItem(tokenStorageKey) ?? "");
    if (newEmail) {
      email = newEmail.toLowerCase();
    }
  }, 5000);
}
