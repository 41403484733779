import moment, { Duration } from "moment";

export function parseDuration(value: string): Duration | null {
  if (!value.match(/^([0-9]+)?([.][0-9]+)?$/)) {
    return null;
  }

  const split = splitHoursMinutes(value);

  let { hours } = split;
  let { minutes } = split;

  if (
    hours < 0 ||
    hours > 24 ||
    minutes > 59 ||
    minutes < 0 ||
    (hours === 24 && minutes > 0)
  ) {
    return null;
  } else if (minutes <= 7) {
    minutes = 0;
  } else if (minutes > 52) {
    minutes = 0;
    hours += 1;
  } else if (minutes > 7 && minutes <= 22) {
    minutes = 15;
  } else if (minutes > 22 && minutes <= 37) {
    minutes = 30;
  } else if (minutes > 37 && minutes <= 52) {
    minutes = 45;
  }

  const totalMinutes = minutes + hours * 60;

  return moment.duration(totalMinutes, "minutes");
}

function isDecimal(value) {
  return value.match(/[.][0-9]+/);
}

function splitHoursMinutes(value) {
  if (isDecimal(value)) {
    return extractFromDecimal(value);
  }

  return { hours: parseInt(value), minutes: 0 };
}

export function extractFromDecimal(value: string): {
  hours: number;
  minutes: number;
} {
  const hoursMinutes = value.match(/([0-9]+)?(.[0-9]+)/)!;
  const hours = hoursMinutes[1] ? parseInt(hoursMinutes[1]) : 0;
  const minutes = Math.round(60 * parseFloat(hoursMinutes[2]));

  return { hours, minutes };
}
