import React from "react";
import "./ExpenseForm.scss";
import CurrencyFormField from "~/visuals/organisms/CurrencyFormField";
import TextFormField from "~/text-form-field";
import { ReceiptValuedExpenseRate } from "~/gql/types";
import { FormProps } from "./CommonTypes";
import { ReceiptValuedExpense } from "~/gql/types";
import ExpenseImageUploadWrapper from "./ExpenseImageUploadWrapper";
import { CrewCodePickerFormField } from "~/visuals/organisms/CrewCodePickerFormField";
import { ReceiptValuedCategory } from "~/gql/types";
import EnumDropdownFormField from "~/visuals/organisms/EnumDropdownFormField";
import { StateCountyPicker } from "~/visuals/organisms/StateCountyPicker";

type ReceiptValuedFormProps = FormProps & {
  setImageId: React.Dispatch<string | null>;
  expenseRate: ReceiptValuedExpenseRate;
  expenseEntry?: ReceiptValuedExpense;
};

const ReceiptValuedForm: React.FC<ReceiptValuedFormProps> = (props) => {
  const { expenseRate } = props;

  return (
    <ExpenseImageUploadWrapper
      typeName="Receipt Valued Expense"
      {...props}
      required
    >
      <CrewCodePickerFormField
        label="Crew Code"
        formField="crewCode"
        helperText="The crew code applicable to this work. (use up/down arrows and enter key to select suggestions)"
        required
      />
      <TextFormField
        label="Notes"
        helperText=""
        formField="notes"
        textarea
        required
      />
      <EnumDropdownFormField
        label="Payer Information"
        formField="payerInformation"
        helperText="Select payer information"
        enumType="PayerInformation"
        required
      />
      {expenseRate.category === ReceiptValuedCategory.Automotive && (
        <>
          <TextFormField
            label="Odometer reading"
            helperText=""
            formField="odometer"
            type="number"
            required
          />
          <TextFormField
            label="Tag number/Unit number"
            helperText=""
            formField="vehicleNumber"
            required
          />
        </>
      )}
      {expenseRate.category === ReceiptValuedCategory.Lodging && (
        <StateCountyPicker
          label="Location"
          helperText="The state and county where the expense was purchased. (use up/down arrows and enter key to select suggestions)"
          formField="location"
          required
        />
      )}
      <CurrencyFormField
        label="Receipt Total"
        helperText="Total amount spent"
        formField="receiptTotal"
        required
      />
    </ExpenseImageUploadWrapper>
  );
};

export default ReceiptValuedForm;
