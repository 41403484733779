type CoreState<T> = {
  text: string;
  selection: T | null;
  loading: boolean;
  errorMessage: string;
  required?: boolean;
  format: (item: T) => string;
  emptyResultsMessage?: string;
  hasInputFocus: boolean;
  hasMenuMouse: boolean;
};

export type Unfocused<T> = CoreState<T> & {
  tag: "Unfocused";
  hasInputFocus: false;
  hasMenuMouse: false;
};

export type Focused<T> = CoreState<T> & {
  tag: "Focused";
};

export type SuggestionsVisible<T> = CoreState<T> & {
  tag: "SuggestionsVisible";
  suggestionIndex: number;
  suggestions: T[];
};

export type SuggestionsLoading<T> = CoreState<T> & {
  tag: "SuggestionsLoading";
  loading: true;
};

export type State<T> =
  | Unfocused<T>
  | SuggestionsLoading<T>
  | Focused<T>
  | SuggestionsVisible<T>;

export function isFocused<T>(x: State<T>): x is Focused<T> {
  return x.tag === "Focused";
}
export function isUnfocused<T>(x: State<T>): x is Unfocused<T> {
  return x.tag === "Unfocused";
}
export function isSuggestionsVisible<T>(
  x: State<T>
): x is SuggestionsVisible<T> {
  return x.tag === "SuggestionsVisible";
}
export function isSuggestionsLoading<T>(
  x: State<T>
): x is SuggestionsVisible<T> {
  return x.tag === "SuggestionsLoading";
}
