import React from "react";
import { TableRow, TableCell } from "~/table";
import { CrewCodeRecord } from "~/gql/types";
import { EditedCrewCode } from "./types";

type CrewCodeOverrideRowProps = {
  crewCode: CrewCodeRecord;
  trailingSeperator: boolean;
  setEditedCrewCode: React.Dispatch<
    React.SetStateAction<EditedCrewCode | null>
  >;
};

export const CrewCodeOverrideRow: React.FC<CrewCodeOverrideRowProps> = (
  props
) => {
  const { crewCode, setEditedCrewCode } = props;

  const onClick = () => {
    setEditedCrewCode({
      crewCode: crewCode.crewCode,
      overrideEmployeeId: crewCode.overrideEmployeeId ?? null,
      dialogClosing: false,
    });
  };

  return (
    <>
      <TableRow onClick={onClick} className="pointer">
        <TableCell text={crewCode.crewCode} />
        <TableCell text={crewCode.overrideEmployeeId ?? ""} />
      </TableRow>
    </>
  );
};
