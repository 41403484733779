import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import TextFormField from "~/text-form-field";

type TextProviderProps = FilterProviderProps & {
  label: string;
  type: string;
  suggestText: string;
  toParam: (filter: ActiveFilter) => string;
};

export function useTextProvider(props: TextProviderProps): FilterProvider {
  const {
    documentationText: origDocText,
    label,
    type,
    suggestText,
    toParam,
  } = props;

  const documentationText = `${origDocText} Use "${suggestText}" for quick filtering.`;

  return {
    render: () => (
      <TextFormField formField={type} label={label} helperText="" />
    ),
    type,
    label,
    documentationText,
    getSuggestions: async (text: string) =>
      Promise.resolve([{ text, value: text }]),
    shouldSuggest: (text: string) => text.toLowerCase().startsWith(suggestText),
    toFilter: (x) => ({ type, label: `${label}: ${x}`, value: x }),
    toParam,
    plainText: true,
    filterFromParams: async (params: URLSearchParams) => {
      const paramVal = params.get(type) || null;
      const val = paramVal ? paramVal : null;

      return val
        ? {
            label: `${label}: ${val}`,
            value: val,
            type,
          }
        : null;
    },
  };
}
