import "./List.scss";

import List, {
  ListItem as BaseListItem,
  ListItemProps,
} from "@material/react-list";
import React from "react";
import { useLinkClickHandler } from "react-router-dom";
export * from "@material/react-list";

const RouterListItem: React.FC<ListItemProps> = (props) => {
  const linkHandler = useLinkClickHandler(props.href!);

  const onClick = (evt) => {
    props.onClick && props.onClick(evt);
    linkHandler(evt);
  };

  return <BaseListItem {...props} onClick={onClick} tag="a" />;
};

export const ListItem: React.FC<ListItemProps> = (props) => {
  const { href } = props;

  if (href) {
    return <RouterListItem {...props} />;
  }

  return <BaseListItem {...props} />;
};

export default List;
