import React from "react";
import TextFormField from "~/text-form-field";
import FormCard from "~/form-card";
import PageTitleRow from "~/page-title-row";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import Spinner from "~/spinner";
import { expenseRateSheets } from "~/routes";
import "./ExpenseRateSheetForm.scss";
import FormBottomRow from "~/form-bottom-row";

type ExpenseRateSheetFormProps = {
  errorMessage?: string;
  loading: boolean;
  oldSheetName?: string;
};

const ExpenseRateSheetForm: React.FC<ExpenseRateSheetFormProps> = ({
  errorMessage,
  loading,
  oldSheetName,
}) => {
  const title = oldSheetName
    ? `Clone Expense Rate Sheet ${oldSheetName}`
    : "Create Expense Rate Sheet";

  useBreadcrumbs(
    [
      { text: "Expense Rate Sheets", to: expenseRateSheets.path },
      { text: title },
    ],
    []
  );

  return (
    <div className="expense-rate-sheet-form">
      <PageTitleRow title={title} />
      <Spinner open={loading} />
      <FormCard>
        <TextFormField
          label="Name"
          helperText="Expense rate sheet name"
          formField="name"
          required
        />
        <FormBottomRow
          errorMessage={errorMessage || null}
          buttonText={"Create"}
          route={expenseRateSheets}
        />
      </FormCard>
    </div>
  );
};

export default ExpenseRateSheetForm;
