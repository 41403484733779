import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCrewCodeActiveMutationVariables = Types.Exact<{
  crewCode: Types.Scalars['String'];
  isActive: Types.Scalars['Boolean'];
}>;


export type UpdateCrewCodeActiveMutation = { __typename?: 'MutationModel', crewCodes?: { __typename?: 'CrewCodeMutationModel', updateActive?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const UpdateCrewCodeActiveDocument = gql`
    mutation UpdateCrewCodeActive($crewCode: String!, $isActive: Boolean!) {
  crewCodes {
    updateActive(crewCode: $crewCode, isActive: $isActive) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type UpdateCrewCodeActiveMutationFn = Apollo.MutationFunction<UpdateCrewCodeActiveMutation, UpdateCrewCodeActiveMutationVariables>;

/**
 * __useUpdateCrewCodeActiveMutation__
 *
 * To run a mutation, you first call `useUpdateCrewCodeActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrewCodeActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrewCodeActiveMutation, { data, loading, error }] = useUpdateCrewCodeActiveMutation({
 *   variables: {
 *      crewCode: // value for 'crewCode'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateCrewCodeActiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCrewCodeActiveMutation, UpdateCrewCodeActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCrewCodeActiveMutation, UpdateCrewCodeActiveMutationVariables>(UpdateCrewCodeActiveDocument, options);
      }
export type UpdateCrewCodeActiveMutationHookResult = ReturnType<typeof useUpdateCrewCodeActiveMutation>;
export type UpdateCrewCodeActiveMutationResult = Apollo.MutationResult<UpdateCrewCodeActiveMutation>;
export type UpdateCrewCodeActiveMutationOptions = Apollo.BaseMutationOptions<UpdateCrewCodeActiveMutation, UpdateCrewCodeActiveMutationVariables>;