import React, { useEffect, useState } from "react";
import { useUser } from "~/Auth/useUser";
import Button from "~/button";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { Route } from "~/routeTypes";
import { thirdPartyInvoices } from "~/routes";
import { FilterForm, FilterOptions } from "./FilterForm";
import { ThirdPartyInvoice, ThirdPartyInvoiceSortBy } from "~/gql/types";
import useInfiniteScroll from "~/search-page-wrapper/infinite-scroll/useInfiniteScroll";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { SearchTopActions } from "~/search-top-actions";
import { VisibilityListener } from "~/visibility-listener";
import { TextHighlighterProvider } from "~/text-highlighter/TextHighlighterProvider";
import { formattedDate } from "~/invoice-search/utils";
import { useThirdPartyInvoiceSearchQuery } from "./search.generated";
import { useSearchParams } from "react-router-dom";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";
import { SearchFilterContainer } from "~/search-filter-container";
import { SearchSortBy } from "~/search-sort-by";
import { ThirdPartyInvoiceCard } from "./ThirdPartyInvoiceCard";
import { SelectAllCheckbox } from "~/search-page-wrapper/BulkCheckboxes/SelectAllCheckbox";
import { BulkActionsPopup } from "~/search-page-wrapper/BulkActionsPopup";
import { TPI_FILTERS_KEY } from "./TpiReview";
import { useRelativeNav } from "~/relative-nav";
import { useReviewItemsContext } from "~/review-items";

const Breadcrumbs: React.FC = () => {
  useBreadcrumbs([{ text: "Third Party Invoices" }], []);

  return <></>;
};

type ThirdPartyInvoicesPageProps = {
  FilterFormComponent?: typeof FilterForm;
};

export type ThirdPartyInvoicesPageDisplayProps = {
  showSpinner: boolean;
  loadingMore: boolean;
  invoices: ThirdPartyInvoice[] | null;
  dispatch: React.Dispatch<Action<ThirdPartyInvoice, FilterOptions>>;
  state: State<ThirdPartyInvoice, FilterOptions>;
};

const ConfigButton: React.FC = () => {
  const user = useUser();

  if (!user.authorizations.manageThirdPartyInvoiceConfig) {
    return null;
  }

  return (
    <Button route={new Route(`${thirdPartyInvoices.path}/config`)}>
      Config
    </Button>
  );
};

const CreateButton: React.FC = () => {
  const user = useUser();

  if (!user.authorizations.createThirdPartyInvoices) {
    return null;
  }

  return (
    <Button route={new Route(`${thirdPartyInvoices.path}/create`)}>
      Create
    </Button>
  );
};

export const ThirdPartyInvoicesPageDisplay: React.FC<
  ThirdPartyInvoicesPageDisplayProps
> = ({ showSpinner, invoices, loadingMore, state, dispatch }) => {
  const nav = useRelativeNav();
  const { setSelectedItems: setReviewItems } = useReviewItemsContext();
  return (
    <SearchListContent
      loading={showSpinner}
      loadingMore={loadingMore}
      actions={
        <SearchTopActions>
          <SelectAllCheckbox {...{ state, dispatch }} />
          <div>
            <ConfigButton />
            <CreateButton />
          </div>
        </SearchTopActions>
      }
    >
      <Breadcrumbs />

      {invoices &&
        invoices.map((e, idx) => (
          <ThirdPartyInvoiceCard
            invoice={e}
            {...{ state, dispatch }}
            key={idx}
          />
        ))}
      {state.showVisibility && (
        <VisibilityListener
          onShown={() => dispatch({ tag: "ScrolledToBottom" })}
        />
      )}
      <BulkActionsPopup
        type="Invoice"
        selectedItems={state.selectedItems}
        buttonItems={[
          {
            label: "Review",
            onClick: () => {
              setReviewItems(state.selectedItems);
              window.sessionStorage.setItem(TPI_FILTERS_KEY, location.search);
              nav(`review/${state.selectedItems[0].invoiceNumber}`);
            },
          },
        ]}
        onClear={() => dispatch({ tag: "ClearSelected" })}
      />
    </SearchListContent>
  );
};

type LoadInvoicesProps = {
  dispatch: React.Dispatch<Action<ThirdPartyInvoice, FilterOptions>>;
  tag: "DataLoaded" | "MoreDataLoaded";
  token: string | null;
  filterOptions: FilterOptions;
};

const LoadInvoices = ({
  dispatch,
  tag,
  filterOptions,
  token,
}: LoadInvoicesProps) => {
  const {
    searchText,
    project,
    customer,
    state,
    dateEqual,
    dateBefore,
    dateAfter,
    sortBy,
  } = filterOptions;

  const variables = {
    searchText: searchText || null,
    state: state || null,
    customerNumber: customer?.number || null,
    projectNumber: project?.number || null,
    dateBefore: formattedDate(dateBefore),
    dateAfter: formattedDate(dateAfter),
    dateEqual: formattedDate(dateEqual),
    token,
    sortBy: sortBy as ThirdPartyInvoiceSortBy,
  };

  const { data } = useThirdPartyInvoiceSearchQuery({ variables });

  useEffect(() => {
    void (async () => {
      if (data) {
        const newToken = data?.thirdPartyInvoices?.search?.token as
          | string
          | null;
        const newInvoices = data?.thirdPartyInvoices?.search
          ?.records as ThirdPartyInvoice[];

        dispatch({ tag, items: newInvoices, searchToken: newToken });
      }
    })();
  }, [data]);

  return <></>;
};

type ThirdPartyInvoicesResultsProps = ThirdPartyInvoicesPageProps & {
  filters: FilterOptions;
};

export const ThirdPartyInvoices: React.FC<ThirdPartyInvoicesPageProps> = (
  props
) => {
  const [filters, setFilters] = useState<FilterOptions | null>(null);

  const FilterFormComponent = props.FilterFormComponent || FilterForm;

  const setFilterOptions = (opts: FilterOptions) => setFilters(opts);

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <SearchPageWrapper withSort>
      <SearchFilterContainer>
        <FilterFormComponent onFiltersChanged={setFilterOptions} />
        <SearchSortBy
          {...{
            filters,
            setFilters,
            searchParams,
            setSearchParams,
            type: "ThirdPartyInvoiceSortBy",
            defaultSort: "Date",
          }}
        />
      </SearchFilterContainer>
      {filters && <InvoicesResults {...{ ...props, filters }} />}
    </SearchPageWrapper>
  );
};

export const InvoicesResults: React.FC<ThirdPartyInvoicesResultsProps> = (
  props
) => {
  const { filters } = props;

  const initialState = {
    tag: "Loading",
    items: [],
    showVisibility: false,
    loading: true,
    filterOptions: filters,
    searchToken: null,
    selectedItems: [],
    selectIdKey: "invoiceNumber",
  };

  const { state, dispatch } = useInfiniteScroll<
    ThirdPartyInvoice,
    FilterOptions
  >(initialState);

  useEffect(() => {
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: filters,
    });
  }, [filters]);

  return (
    <TextHighlighterProvider searchText={state.filterOptions.searchText}>
      {state.tag === "Loading" && (
        <LoadInvoices
          {...{
            dispatch,
            tag: "DataLoaded",
            filterOptions: state.filterOptions,
            token: null,
          }}
        />
      )}
      {state.tag === "LoadingMore" && (
        <LoadInvoices
          {...{
            dispatch,
            tag: "MoreDataLoaded",
            token: state.searchToken,
            filterOptions: state.filterOptions,
          }}
        />
      )}
      <ThirdPartyInvoicesPageDisplay
        {...{
          invoices: state.items,
          loadingMore: state.tag === "LoadingMore",
          showSpinner: state.loading,
          state,
          dispatch,
        }}
      />
    </TextHighlighterProvider>
  );
};
