import React from "react";
import { useEditAdHocExpenseRateMutation } from "./queries.generated";
import { MutationForm } from "~/forms/MutationForm";
import { FormProps, FormData } from "./types";
import { AdHocExpenseRate, ValidationResponse } from "~/gql/types";
import { parseNum } from ".";

export const EditAdHocExpenseRate: React.FC<
  FormProps & { original: AdHocExpenseRate }
> = (props) => {
  const { setLoading, original, rateSheet, onSuccess, children } = props;

  const [rawAdHocMutation] = useEditAdHocExpenseRateMutation();

  const initialValues = {
    name: original.name,
    invoiceLabel: original.invoiceLabel,
    expenseAccount: original.expenseAccount,
    glAccount: original.glAccount,
    rate: original.rate?.toString() || null,
  };

  const handleSubmit = async (values: FormData) => {
    const variables = {
      oldName: original?.name!,
      name: values?.name!,
      invoiceLabel: values?.invoiceLabel || null,
      expenseAccount: values?.expenseAccount || null,
      glAccount: values?.glAccount || null,
      rateSheetName: rateSheet.name,
      rate: parseNum(values?.rate),
    };

    const result = await rawAdHocMutation({ variables });

    return result?.data?.expenseRates?.editAdHocRate as ValidationResponse;
  };

  return (
    <MutationForm
      setLoading={setLoading}
      initialValues={initialValues}
      runMutation={handleSubmit}
      onSuccess={onSuccess}
      allowPristineSubmit
    >
      {children}
    </MutationForm>
  );
};
