import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddChargesToInvoiceMutationVariables = Types.Exact<{
  charges: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
  invoiceNumber?: Types.InputMaybe<Types.Scalars['String']>;
  projectNumber?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type AddChargesToInvoiceMutation = { __typename?: 'MutationModel', invoices?: { __typename?: 'InvoiceMutationModel', addCharges?: { __typename?: 'AddChargesResponse', invoiceNumber?: string | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const AddChargesToInvoiceDocument = gql`
    mutation AddChargesToInvoice($charges: [Int!]!, $invoiceNumber: String, $projectNumber: Int) {
  invoices {
    addCharges(
      charges: $charges
      invoiceNumber: $invoiceNumber
      projectNumber: $projectNumber
    ) {
      errors {
        argumentName
        message
      }
      invoiceNumber
    }
  }
}
    `;
export type AddChargesToInvoiceMutationFn = Apollo.MutationFunction<AddChargesToInvoiceMutation, AddChargesToInvoiceMutationVariables>;

/**
 * __useAddChargesToInvoiceMutation__
 *
 * To run a mutation, you first call `useAddChargesToInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChargesToInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChargesToInvoiceMutation, { data, loading, error }] = useAddChargesToInvoiceMutation({
 *   variables: {
 *      charges: // value for 'charges'
 *      invoiceNumber: // value for 'invoiceNumber'
 *      projectNumber: // value for 'projectNumber'
 *   },
 * });
 */
export function useAddChargesToInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<AddChargesToInvoiceMutation, AddChargesToInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddChargesToInvoiceMutation, AddChargesToInvoiceMutationVariables>(AddChargesToInvoiceDocument, options);
      }
export type AddChargesToInvoiceMutationHookResult = ReturnType<typeof useAddChargesToInvoiceMutation>;
export type AddChargesToInvoiceMutationResult = Apollo.MutationResult<AddChargesToInvoiceMutation>;
export type AddChargesToInvoiceMutationOptions = Apollo.BaseMutationOptions<AddChargesToInvoiceMutation, AddChargesToInvoiceMutationVariables>;