import React from "react";
import { useField } from "react-final-form";
import Button from "~/button";
import { FormFieldProps } from "~/forms/FormFieldProps";
import HelperTextLine from "~/HelperTextLine";
import classnames from "classnames";
import "./FileUploadFormField.scss";
import { Body2 } from "@material/react-typography";

export type FileUploadProps = FormFieldProps & {
  validMessage: string;
  handleChange?: (file: any) => void;
  handleRemove?: () => void;
};

const FileUploadFormField: React.FC<FileUploadProps> = (
  props: FileUploadProps
) => {
  const {
    formField,
    label,
    helperText,
    required = false,
    disabled = false,
    validMessage,
    handleChange,
    handleRemove,
  } = props;

  const checkRequired = (value: any) => {
    if (value && value.name) {
      const fileType: string = value.name.split(".").pop();
      if (!["jpg", "jpeg", "png", "pdf"].includes(fileType.toLowerCase())) {
        return "Invalid file type. Accepted types: .jpg, .jpeg, .png, .pdf";
      }
    }
    if (value || !required || disabled) {
      return;
    }
    return validMessage;
  };

  const {
    meta: { error, submitError, touched },
    input,
  } = useField(formField, { validate: checkRequired });

  const errorMessage = error || submitError;
  const isValid = (!touched || !errorMessage) && !submitError;

  const handleFile = (file: any) => {
    input.onChange(file);
    if (handleChange) {
      handleChange(file);
    }
  };

  const onChange = (e: any) => {
    if (e.target.files.length) {
      handleFile(e.target.files[0]);
    }
  };

  const onDrop = (e: any) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      const file = e.dataTransfer.items[0].getAsFile();
      handleFile(file);
    }
  };

  const onDragOver = (e: any) => {
    e.preventDefault();
  };

  const uploadClass = classnames("file-upload", {
    invalid: !isValid,
  });

  const onDelete = () => {
    input.onChange(null);
    if (handleRemove) {
      handleRemove();
    }
  };
  return (
    <div className={uploadClass}>
      {!(input.value && isValid) && (
        <>
          <div
            onDrop={onDrop}
            onDragOver={onDragOver}
            className={`${label}-dropzone file-dropzone`}
          >
            <Body2>Drag {label} file here</Body2>
          </div>
          <Button type="button">
            <label htmlFor="upload">Upload {label}</label>
          </Button>
        </>
      )}

      {input.value && isValid && (
        <div className="filename-container">
          <Body2 className="filename">{input.value.name}</Body2>
          <Button type="button" icon="delete" onClick={onDelete} />
        </div>
      )}
      <input
        id="upload"
        name={formField}
        type="file"
        hidden
        onChange={onChange}
      />
      <HelperTextLine
        message={!isValid && errorMessage ? errorMessage : helperText}
        isValid={isValid}
        persistent={true}
      />
    </div>
  );
};

export default FileUploadFormField;
