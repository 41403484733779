import React, { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useConfirmationPrompt } from "~/confirmation-prompt";
import { useSnackBar } from "~/snackbar";
import { createWorkDayApi } from "./WorkDayApi";
import { defaultMockApi } from "./tests/DefaultMockApi";
import { WorkDay, WorkDayApi } from "./WorkDayApiType";
import { useApolloClient } from "@apollo/client";
import { useUser } from "~/Auth/useUser";
import { MockUserProvider } from "~/Auth/MockUserProvider";
import { useOverridePayable } from "./useOverridePayable";

const WorkDayApiContext = createContext<WorkDayApi | null>(null);

type WorkDayApiProviderProps = {
  workDay: WorkDay;
  useReload(): () => Promise<unknown>;
  upn: string;
};

const WorkDayApiProvider: React.FC<WorkDayApiProviderProps> = ({
  workDay,
  useReload,
  upn,
  children,
}) => {
  const client = useApolloClient();
  const addAlert = useSnackBar();
  const user = useUser();

  const navigate = useNavigate();
  const reload = useReload();
  const prompt = useConfirmationPrompt();
  const handlePayable = useOverridePayable();

  const mode =
    user.email.toLowerCase() !== upn.toLowerCase() ? "Review" : "Mine";

  const apiFunctions = {
    navigate,
    reload,
    addAlert,
    prompt,
    handlePayable,
  };

  const api = createWorkDayApi(workDay, upn, mode, client, apiFunctions);

  return (
    <WorkDayApiContext.Provider value={api}>
      {children}
    </WorkDayApiContext.Provider>
  );
};

export function useWorkDayApi(): WorkDayApi {
  const context = useContext(WorkDayApiContext);

  if (!context) {
    throw new Error("Do not use useWorkDayApi outside of a WorkDayApiProvider");
  }

  return context;
}

export const MockWorkDayApiProvider: React.FC<{
  api?: Partial<WorkDayApi>;
}> = ({ api, children }) => {
  return (
    <MockUserProvider>
      <WorkDayApiContext.Provider value={{ ...defaultMockApi, ...api }}>
        {children}
      </WorkDayApiContext.Provider>
    </MockUserProvider>
  );
};

export default WorkDayApiProvider;
