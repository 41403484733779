import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type ContextType = {
  container: HTMLElement | HTMLDivElement | null;
};

export const VisibilityListenerContext = createContext<ContextType>({
  container: null,
});

type ProviderProps = {
  containerRef: React.RefObject<HTMLElement | HTMLDivElement> | null;
};

export const VisibilityListenerProvider: React.FC<ProviderProps> = ({
  containerRef,
  children,
}) => {
  const [container, setContainer] = useState<
    HTMLElement | HTMLDivElement | null
  >(null);

  useEffect(() => {
    if (containerRef?.current) {
      setContainer(containerRef.current);
    }
  }, [containerRef]);

  return (
    <VisibilityListenerContext.Provider value={{ container }}>
      {children}
    </VisibilityListenerContext.Provider>
  );
};

type VisibilityListenerProps = {
  onShown: () => void;
  onHide?: () => void;
  height?: number;
};

export const VisibilityListener: React.FC<VisibilityListenerProps> = ({
  onShown,
  onHide,
  height,
}) => {
  const context = useContext(VisibilityListenerContext);
  const ref = useRef<HTMLDivElement>(null);

  if (!context) {
    throw new Error(
      "Do not use VisibilityListener outside of a VisibilityListenerProvider"
    );
  }

  const { container } = context;

  useEffect(() => {
    if (!container || !ref?.current) {
      return;
    }

    const options = {
      root: container,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        onShown();
      } else if (onHide) {
        onHide();
      }
    }, options);

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [container, ref, onShown, onHide]);

  return (
    <div
      ref={ref}
      className="visibility-listener"
      style={{ height: `${height ?? 1}px` }}
    />
  );
};

export const MockVisibilityProvider: React.FC = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref} className="visibility-listener">
      <VisibilityListenerProvider containerRef={ref}>
        {children}
      </VisibilityListenerProvider>
    </div>
  );
};
