import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnassignCustomerBaMutationVariables = Types.Exact<{
  customerNumber: Types.Scalars['String'];
  userPrincipalName: Types.Scalars['String'];
}>;


export type UnassignCustomerBaMutation = { __typename?: 'MutationModel', customers?: { __typename?: 'CustomerMutationModel', unassignBa?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const UnassignCustomerBaDocument = gql`
    mutation UnassignCustomerBa($customerNumber: String!, $userPrincipalName: String!) {
  customers {
    unassignBa(
      customerNumber: $customerNumber
      userPrincipalName: $userPrincipalName
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type UnassignCustomerBaMutationFn = Apollo.MutationFunction<UnassignCustomerBaMutation, UnassignCustomerBaMutationVariables>;

/**
 * __useUnassignCustomerBaMutation__
 *
 * To run a mutation, you first call `useUnassignCustomerBaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignCustomerBaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignCustomerBaMutation, { data, loading, error }] = useUnassignCustomerBaMutation({
 *   variables: {
 *      customerNumber: // value for 'customerNumber'
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useUnassignCustomerBaMutation(baseOptions?: Apollo.MutationHookOptions<UnassignCustomerBaMutation, UnassignCustomerBaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignCustomerBaMutation, UnassignCustomerBaMutationVariables>(UnassignCustomerBaDocument, options);
      }
export type UnassignCustomerBaMutationHookResult = ReturnType<typeof useUnassignCustomerBaMutation>;
export type UnassignCustomerBaMutationResult = Apollo.MutationResult<UnassignCustomerBaMutation>;
export type UnassignCustomerBaMutationOptions = Apollo.BaseMutationOptions<UnassignCustomerBaMutation, UnassignCustomerBaMutationVariables>;