import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import _ from "lodash";
import { Moment } from "moment";
import RateSheetPickerFormField from "../RateSheetPicker";

export type TaskRateSheetPickerProps = FormFieldProps & {
  effectiveDate: Moment | null;
};

const TaskRateSheetPickerFormField: React.FC<TaskRateSheetPickerProps> = (
  props
) => (
  <RateSheetPickerFormField
    {...{
      ...props,
      type: "task",
    }}
  />
);

export default TaskRateSheetPickerFormField;
