import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExpenseRatesQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ExpenseRatesQueryQuery = { __typename?: 'QueryModel', expenseRates?: { __typename?: 'ExpenseRatesQueryModel', rateSheets?: Array<{ __typename?: 'ExpenseRateSheet', name: string, allowAssignment: boolean, endDate?: string | null, lastUsed?: string | null, archived: boolean } | null> | null } | null };


export const ExpenseRatesQueryDocument = gql`
    query ExpenseRatesQuery {
  expenseRates {
    rateSheets {
      name
      allowAssignment
      endDate
      lastUsed
      archived
    }
  }
}
    `;

/**
 * __useExpenseRatesQueryQuery__
 *
 * To run a query within a React component, call `useExpenseRatesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseRatesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseRatesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useExpenseRatesQueryQuery(baseOptions?: Apollo.QueryHookOptions<ExpenseRatesQueryQuery, ExpenseRatesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpenseRatesQueryQuery, ExpenseRatesQueryQueryVariables>(ExpenseRatesQueryDocument, options);
      }
export function useExpenseRatesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpenseRatesQueryQuery, ExpenseRatesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpenseRatesQueryQuery, ExpenseRatesQueryQueryVariables>(ExpenseRatesQueryDocument, options);
        }
export type ExpenseRatesQueryQueryHookResult = ReturnType<typeof useExpenseRatesQueryQuery>;
export type ExpenseRatesQueryLazyQueryHookResult = ReturnType<typeof useExpenseRatesQueryLazyQuery>;
export type ExpenseRatesQueryQueryResult = Apollo.QueryResult<ExpenseRatesQueryQuery, ExpenseRatesQueryQueryVariables>;