import React from "react";
import GraphiqlReact from "graphiql";
import "graphiql/graphiql.min.css";
import { useRawJwt } from "./Auth/AuthenticationManager";

type GraphiqlProps = {
  path: string;
};

const Graphiql: React.FC<GraphiqlProps> = ({ path }) => {
  const token = useRawJwt();
  const fetcher = (graphQLParams: any) =>
    window
      .fetch(path, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
        body: JSON.stringify(graphQLParams),
      })
      .then((response) => response.json());
  return <GraphiqlReact fetcher={fetcher} />;
};

export default Graphiql;
