// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3-EAccpuJXmcGexkV\\+VXUQ\\=\\=.mdc-button.icon-only{min-width:unset}", "",{"version":3,"sources":["webpack://src/project-link-button/ProjectLinkButton.module.scss"],"names":[],"mappings":"AAEI,mDACE,eAAA","sourcesContent":[":global {\n  :local(.Button) {\n    &.mdc-button.icon-only {\n      min-width: unset;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Button": "_3-EAccpuJXmcGexkV+VXUQ=="
};
export default ___CSS_LOADER_EXPORT___;
