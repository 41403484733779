import React, { useEffect, useState } from "react";
import FormBottomRow from "~/form-bottom-row";
import "./SelectGroupInvoice.scss";
import { Body1, Body2 } from "@material/react-typography";
import { CustomRollupInvoice, Invoice, ProjectCharge } from "~/gql/types";
import { currencyFormatter } from "~/currencyFormatter";
import MaterialIcon from "@material/react-material-icon";
import { PostToInvoiceAction, PostToInvoiceState } from "./stateMachine";
import { BulkActionWrapper } from "~/visuals/organisms/BulkActions/BulkActionWrapper";

type ListItemProps = {
  value: string | null;
  setValue: React.Dispatch<string | null>;
};

const NewDraftListItem: React.FC<ListItemProps> = (props) => {
  const { value, setValue } = props;

  return (
    <div className="invoice-row-item" onClick={() => setValue(null)}>
      {value === null ? <MaterialIcon icon="check" /> : <div />}
      <Body2>{"(New draft invoice)"}</Body2>
      <div />
      <div />
    </div>
  );
};

const InvoiceListItem: React.FC<
  ListItemProps & { invoice: Invoice | CustomRollupInvoice }
> = (props) => {
  const { value, setValue, invoice } = props;

  return (
    <div
      className="invoice-row-item"
      onClick={() => setValue(invoice.invoiceNumber)}
    >
      {value === invoice.invoiceNumber ? (
        <MaterialIcon icon="check" />
      ) : (
        <div />
      )}
      <Body2>{invoice.invoiceNumber}</Body2>
      <Body2>{currencyFormatter.format(invoice.total)}</Body2>
      <Body2>{`${invoice.lineItems?.length} line items`}</Body2>
    </div>
  );
};

export type SelectGroupInvoiceProps<T> = {
  state: PostToInvoiceState<T>;
  dispatch: React.Dispatch<PostToInvoiceAction>;
  onClose: () => void;
};

export const SelectGroupInvoice = <T,>(
  props: SelectGroupInvoiceProps<T>
): React.ReactElement | null => {
  const { state, dispatch, onClose } = props;

  const [value, setValue] = useState<string | null>(null);
  const [open, setOpen] = useState(state.tag === "SelectingGroupInvoices");

  useEffect(() => {
    if (state.tag === "SelectingGroupInvoices") {
      setOpen(true);
    }
  }, [state.tag]);

  if (state.tag !== "SelectingGroupInvoices") {
    return null;
  }

  const currentGroup = state.itemGroups.find(
    (x) => x.groupId === state.currentGroupId
  )!;
  const item = currentGroup.items[0] as Invoice | ProjectCharge;

  const title =
    "invoiceNumber" in item
      ? `Project Group ${item.customerNumber} - ${
          item.project!.projectGroup!.name
        } invoices`
      : `Project ${item.projectId} charges`;

  const type = "invoiceNumber" in item ? "Custom Rollup Invoice" : "Invoice";

  const options = currentGroup.draftInvoices.map((invoice) => (
    <InvoiceListItem
      key={invoice!.invoiceNumber}
      {...{ invoice, value, setValue }}
    />
  ));

  const className = "post-to-invoice-dialog";

  const updateGroup = () => {
    dispatch({
      tag: "GroupInvoiceSelected",
      groupId: state.currentGroupId,
      invoiceNumber: value,
    });
    setValue(null);
  };

  const buttonText =
    state.itemGroups.filter((x) => x.selectedInvoice === undefined).length > 1
      ? "Next Group"
      : "Submit";

  return (
    <BulkActionWrapper {...{ open, setOpen, className, title }}>
      <div className="post-to-invoice-form">
        <Body1 className="select-text">Select a Draft {type}:</Body1>
        <div className="options-container">
          <NewDraftListItem {...{ value, setValue }} />
          {options}
        </div>

        <FormBottomRow
          errorMessage={null}
          buttonText={buttonText}
          onCancel={() => {
            onClose();
            setOpen(false);
          }}
          onSubmit={updateGroup}
        />
      </div>
    </BulkActionWrapper>
  );
};
