import _ from "lodash";

export function createSearchRegex(searchText: string | null): RegExp | null {
  if (!searchText || searchText === "") {
    return null;
  }

  const filteredText = searchText.replace("\\", "");

  return new RegExp(_.escapeRegExp(filteredText), "i");
}
