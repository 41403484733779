import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import {
  ServiceDescription,
  useServiceDescriptionsAsync,
} from "~/refdata2/serviceDescriptions";
import _ from "lodash";
import ServiceDescriptionPicker from "~/visuals/organisms/ServiceDescriptionPicker";

function useServiceDescriptionFromParam(): (
  name: string
) => Promise<ServiceDescription | null> {
  const getServiceDescriptions = useServiceDescriptionsAsync();

  return async (name: string) => {
    const result = await getServiceDescriptions();
    const unique = _.uniqBy(result, "displayName");
    const type = unique?.find((x) => x.displayName === name);

    return type ?? null;
  };
}

export function useServiceDescriptionProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText: origDocText } = props;
  const serviceDescriptionFromParam = useServiceDescriptionFromParam();
  const getServiceDescription = useServiceDescriptionsAsync();

  const documentationText = `${origDocText} Use "service" for quick filtering.`;
  const type = "serviceDescription";
  const label = "Service Description";

  return {
    render: () => (
      <ServiceDescriptionPicker formField={type} label={label} helperText="" />
    ),
    type,
    label,
    documentationText,
    getSuggestions: async (text: string) => {
      if (!text) {
        return [];
      }
      const result = await getServiceDescription();

      if (!result || result.length === 0) {
        return [];
      }
      return result
        .filter((t) => t.displayName.toLowerCase().includes(text.toLowerCase()))
        .map((t) => ({ text: t.displayName, value: t }));
    },
    shouldSuggest: (text: string) => text.toLowerCase().startsWith("service"),
    toFilter: (value: ServiceDescription) => ({
      label: `${label}: ${value.displayName}`,
      type,
      value: value,
    }),
    filterFromParams: async (params: URLSearchParams) => {
      const paramServiceDescription = params.get(type) || null;
      const serviceDescription = paramServiceDescription
        ? await serviceDescriptionFromParam(paramServiceDescription)
        : null;

      return serviceDescription
        ? {
            label: `${label}: ${serviceDescription.displayName}`,
            value: serviceDescription,
            type,
          }
        : null;
    },
    toParam: (filter: ActiveFilter) => filter.value?.displayName ?? "",
  };
}
