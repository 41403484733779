import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PreviousWorkDayQueryVariables = Types.Exact<{
  date: Types.Scalars['LocalDate'];
  userPrincipalName: Types.Scalars['String'];
}>;


export type PreviousWorkDayQuery = { __typename?: 'QueryModel', timesheets?: { __typename?: 'TimesheetsQueryModel', prevWorkDay?: { __typename?: 'PrevWorkDay', id?: string | null, date?: string | null, projectTimeCount: number, expenseCount: number, timeOffCount: number, timeEntryCount: number } | null } | null };


export const PreviousWorkDayDocument = gql`
    query PreviousWorkDay($date: LocalDate!, $userPrincipalName: String!) {
  timesheets {
    prevWorkDay(date: $date, userPrincipalName: $userPrincipalName) {
      id
      date
      projectTimeCount
      expenseCount
      timeOffCount
      timeEntryCount
    }
  }
}
    `;

/**
 * __usePreviousWorkDayQuery__
 *
 * To run a query within a React component, call `usePreviousWorkDayQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviousWorkDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviousWorkDayQuery({
 *   variables: {
 *      date: // value for 'date'
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function usePreviousWorkDayQuery(baseOptions: Apollo.QueryHookOptions<PreviousWorkDayQuery, PreviousWorkDayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviousWorkDayQuery, PreviousWorkDayQueryVariables>(PreviousWorkDayDocument, options);
      }
export function usePreviousWorkDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviousWorkDayQuery, PreviousWorkDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviousWorkDayQuery, PreviousWorkDayQueryVariables>(PreviousWorkDayDocument, options);
        }
export type PreviousWorkDayQueryHookResult = ReturnType<typeof usePreviousWorkDayQuery>;
export type PreviousWorkDayLazyQueryHookResult = ReturnType<typeof usePreviousWorkDayLazyQuery>;
export type PreviousWorkDayQueryResult = Apollo.QueryResult<PreviousWorkDayQuery, PreviousWorkDayQueryVariables>;