import React from "react";
import { UltraFilter } from "~/ultra-filter";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import { useSearchParams } from "react-router-dom";
import { useProjectProvider } from "~/filterProviders/useProjectProvider";
import {
  useEmployeeProvider,
  useBillingAdminProvider,
  useProjectManagerProvider,
  useProjectSupervisorProvider,
} from "~/filterProviders/employeeProviders";
import { useCustomerProvider } from "~/filterProviders/useCustomerProvider";
import { useChargeStateProvider } from "~/filterProviders/itemStateProviders";
import { useChargeBillableProvider } from "~/filterProviders/yesNoProviders";
import { useDateProvider } from "~/filterProviders/useDateProvider";
import { useSearchStats } from "./SearchStatsProvider";
import { useTextProvider } from "~/filterProviders/useTextProvider";
import { ProjectSortBy } from "~/gql/types";
import { ProjectChargesFilter } from "./types";
import { useFieldOfficeProvider } from "~/filterProviders/useFieldOfficeProvider";

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<ProjectChargesFilter>;
};

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const [searchParams] = useSearchParams();
  const { statsText } = useSearchStats();

  const providers = [
    useEmployeeProvider({
      documentationText: "The employee corresponding to the project charge.",
    }),
    useBillingAdminProvider({
      documentationText: "The billing admin assigned to the charge's project.",
    }),
    useProjectManagerProvider({
      documentationText:
        "The project manager assigned to the charge's project.",
    }),
    useProjectSupervisorProvider({
      documentationText:
        "The project supervisor assigned to the charge's project.",
    }),
    useProjectProvider({ documentationText: "The charge's project." }),
    useCustomerProvider({
      documentationText: "The customer associated with the charge's project.",
    }),
    useChargeStateProvider({
      documentationText: "The state that the charge is in.",
    }),
    useChargeBillableProvider({
      documentationText:
        "Indicating if the charge should be billable or non-billable",
    }),
    useDateProvider({
      documentationText:
        "Search for charges with date after this date (exclusive).",
      type: "dateAfter",
      label: "After Date",
      suggestText: "aft",
    }),
    useDateProvider({
      documentationText:
        "Search for charges with date before this date (exclusive).",
      type: "dateBefore",
      label: "Before Date",
      suggestText: "bef",
    }),
    useDateProvider({
      documentationText: "Search for charges with this specific date.",
      type: "dateEqual",
      label: "Actual Date",
      suggestText: "date",
    }),
    useTextProvider({
      documentationText: "The charges's project AFE/PO/WO.",
      toParam: (x) => x?.value,
      suggestText: "afewopo",
      label: "AFE/WO/PO",
      type: "afeWoPo",
    }),
    useFieldOfficeProvider({
      documentationText:
        "The office code of the project that this charge is assigned to.",
    }),
  ] as FilterProvider[];

  const onChanged = (filters: ActiveFilter[], searchText: string) => {
    searchText;

    const newFilters = {
      employee: filters.find((f) => f.type === "employee")?.value || null,
      billingAdmin:
        filters.find((f) => f.type === "billingAdmin")?.value || null,
      projectSupervisor:
        filters.find((f) => f.type === "projectSupervisor")?.value || null,
      projectManager:
        filters.find((f) => f.type === "projectManager")?.value || null,
      projectNumber: searchParams.get("lastProject") || null,
      searchText: searchText || null,
      project: filters.find((f) => f.type === "project")?.value || null,
      customer: filters.find((f) => f.type === "customer")?.value || null,
      state: filters.find((f) => f.type === "chargeState")?.value || null,
      billable: filters.find((f) => f.type === "billable")?.value || null,
      dateEqual: filters.find((f) => f.type === "dateEqual")?.value || null,
      dateBefore: filters.find((f) => f.type === "dateBefore")?.value || null,
      dateAfter: filters.find((f) => f.type === "dateAfter")?.value || null,
      afeWoPo: filters.find((f) => f.type === "afeWoPo")?.value || null,
      officeCode: filters.find((f) => f.type === "officeCode")?.value || null,
      sortBy: (searchParams.get("sortBy") || "CustomerName") as ProjectSortBy,
    };

    onFiltersChanged(newFilters);
  };

  return (
    <div className="filter-form">
      <UltraFilter
        {...{
          providers,
          dialogTitle: "Project Charges Search",
          label: "Type to search project charges",
          onFiltersChanged: onChanged,
          stats: statsText,
        }}
      />
    </div>
  );
};
