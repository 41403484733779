import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RejectThirdPartyInvoiceMutationVariables = Types.Exact<{
  invoiceNumber: Types.Scalars['String'];
  reason: Types.Scalars['String'];
}>;


export type RejectThirdPartyInvoiceMutation = { __typename?: 'MutationModel', thirdPartyInvoices?: { __typename?: 'ThirdPartyInvoicesMutationModel', reject?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const RejectThirdPartyInvoiceDocument = gql`
    mutation RejectThirdPartyInvoice($invoiceNumber: String!, $reason: String!) {
  thirdPartyInvoices {
    reject(invoiceNumber: $invoiceNumber, reason: $reason) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type RejectThirdPartyInvoiceMutationFn = Apollo.MutationFunction<RejectThirdPartyInvoiceMutation, RejectThirdPartyInvoiceMutationVariables>;

/**
 * __useRejectThirdPartyInvoiceMutation__
 *
 * To run a mutation, you first call `useRejectThirdPartyInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectThirdPartyInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectThirdPartyInvoiceMutation, { data, loading, error }] = useRejectThirdPartyInvoiceMutation({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useRejectThirdPartyInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<RejectThirdPartyInvoiceMutation, RejectThirdPartyInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectThirdPartyInvoiceMutation, RejectThirdPartyInvoiceMutationVariables>(RejectThirdPartyInvoiceDocument, options);
      }
export type RejectThirdPartyInvoiceMutationHookResult = ReturnType<typeof useRejectThirdPartyInvoiceMutation>;
export type RejectThirdPartyInvoiceMutationResult = Apollo.MutationResult<RejectThirdPartyInvoiceMutation>;
export type RejectThirdPartyInvoiceMutationOptions = Apollo.BaseMutationOptions<RejectThirdPartyInvoiceMutation, RejectThirdPartyInvoiceMutationVariables>;