import Employee from "./graphql/Employee";

export const employees: Employee[] = [
  {
    firstName: "Joe",
    isMobileEmployee: true,
    isTerminated: false,
    lastName: "Bob",
    ultiProId: "ABC123",
    userPrincipalName: "joe@topographic.com",
    email: "joe@topographic.com",
    workLocation: "CE",
    timeEntryMethod: "Workflow",
  },
  {
    firstName: "Sally",
    isMobileEmployee: true,
    isTerminated: false,
    lastName: "Field",
    ultiProId: "DEF456",
    userPrincipalName: "sally@topographic.com",
    email: "sally@topographic.com",
    workLocation: "OK",
    timeEntryMethod: "Workflow",
  },
  {
    firstName: "Ricky",
    isMobileEmployee: false,
    isTerminated: true,
    lastName: "Ricardo",
    ultiProId: "GHI789",
    userPrincipalName: "ricky@topographic.com",
    email: "ricky@topographic.com",
    workLocation: "NM",
    timeEntryMethod: "Workflow",
  },
  {
    firstName: "Tamika",
    isMobileEmployee: true,
    isTerminated: false,
    lastName: "Brown",
    ultiProId: "JKL321",
    userPrincipalName: "tamika@topographic.com",
    email: "tamika@topographic.com",
    workLocation: "MI",
    timeEntryMethod: "Workflow",
  },
  {
    firstName: "Test",
    isMobileEmployee: true,
    isTerminated: false,
    lastName: "User",
    ultiProId: "MNO654",
    userPrincipalName: "testuser@topographic.com",
    email: "testuser@topographic.com",
    workLocation: "MI",
    timeEntryMethod: "Workflow",
  },
];
