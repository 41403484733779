import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFieldOfficeMutationVariables = Types.Exact<{
  userPrincipalName: Types.Scalars['String'];
  fieldOffice?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateFieldOfficeMutation = { __typename?: 'MutationModel', employees?: { __typename?: 'EmployeeMutationModel', updateFieldOffice?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const UpdateFieldOfficeDocument = gql`
    mutation UpdateFieldOffice($userPrincipalName: String!, $fieldOffice: String) {
  employees {
    updateFieldOffice(
      userPrincipalName: $userPrincipalName
      fieldOffice: $fieldOffice
    ) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type UpdateFieldOfficeMutationFn = Apollo.MutationFunction<UpdateFieldOfficeMutation, UpdateFieldOfficeMutationVariables>;

/**
 * __useUpdateFieldOfficeMutation__
 *
 * To run a mutation, you first call `useUpdateFieldOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFieldOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFieldOfficeMutation, { data, loading, error }] = useUpdateFieldOfficeMutation({
 *   variables: {
 *      userPrincipalName: // value for 'userPrincipalName'
 *      fieldOffice: // value for 'fieldOffice'
 *   },
 * });
 */
export function useUpdateFieldOfficeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFieldOfficeMutation, UpdateFieldOfficeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFieldOfficeMutation, UpdateFieldOfficeMutationVariables>(UpdateFieldOfficeDocument, options);
      }
export type UpdateFieldOfficeMutationHookResult = ReturnType<typeof useUpdateFieldOfficeMutation>;
export type UpdateFieldOfficeMutationResult = Apollo.MutationResult<UpdateFieldOfficeMutation>;
export type UpdateFieldOfficeMutationOptions = Apollo.BaseMutationOptions<UpdateFieldOfficeMutation, UpdateFieldOfficeMutationVariables>;