import React, { useState } from "react";
import "./ToolTip.scss";
import { Body2 } from "@material/react-typography";

type ToolTipProps = {
  text: string;
};

export const ToolTip: React.FC<ToolTipProps> = ({ children, text }) => {
  const [show, setShow] = useState(false);

  const style = ["tooltip", show && text ? "show" : null]
    .filter((x) => x)
    .join(" ");

  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}
      <Body2 className={style}>{text}</Body2>
    </div>
  );
};
