import { WolfkrowUser } from "./WolfkrowUser";
import {
  useImpersonate,
  useImpersonationContext,
  useStopImpersonating,
} from "./ImpersonationManager";
import { getEmail, getName } from "./jwtUtils";
import {
  useAuthorizations,
  useLogout,
  useRawJwt,
} from "./AuthenticationManager";
import { useMemo } from "react";

export function useUser(): WolfkrowUser {
  const context = useImpersonationContext("useUser");
  const token = useRawJwt();
  const name = getName(token ?? "");
  const email = getEmail(token ?? "");
  const authorizations = useAuthorizations();
  const impersonate = useImpersonate();
  const stopImpersonating = useStopImpersonating();
  const logout = useLogout();

  if (!name || !email || !authorizations) {
    throw new Error("Something has gone wrong, please log in.");
  }

  const user = useMemo(() => {
    if (context.employee && context.authorizations) {
      return {
        name: `${context.employee.firstName} ${context.employee.lastName}`,
        email: context.employee.userPrincipalName,
        authorizations: context.authorizations!,
        originalAuthorizations: authorizations,
        isImpersonating: true,
        impersonate: () => undefined,
        stopImpersonating,
        logout,
      };
    }

    return {
      name,
      email,
      authorizations,
      originalAuthorizations: null,
      isImpersonating: false,
      impersonate,
      stopImpersonating,
      logout,
    };
  }, [token, context, authorizations]);

  return user;
}
