import React from "react";
import { Menu, MenuItem, MenuSurfaceAnchor, AnchorT } from "rmwc";
import "./PopupMenuList.scss";
import { ListItem } from "~/list";

export type PopupMenuListItem = {
  label: React.ReactNode;
  onClick: React.Dispatch<PopupMenuListItem>;
  dataItem?: any;
  key: any;
  disabled?: boolean;
  title?: string;
  href?: string;
  className?: string;
};

export type PopupMenuListProps = {
  visible: boolean;
  focusOnOpen?: boolean;
  items: PopupMenuListItem[];
  children?: React.ReactNode;
  anchorCorner?: AnchorT;
  selectedIndex?: number;
  onMenuMouseEnter: React.DispatchWithoutAction;
  onMenuMouseLeave: React.DispatchWithoutAction;
  onFocus?: React.DispatchWithoutAction;
  onBlur?: React.DispatchWithoutAction;
  onClose?: React.DispatchWithoutAction;
  className?: string;
};

const handleClick = (evt: React.BaseSyntheticEvent, item) => {
  evt.preventDefault();
  evt.stopPropagation();

  item.onClick(item);
};

const handleKeyDown = (evt: React.KeyboardEvent, item) => {
  if (evt.key === "Enter") {
    handleClick(evt, item);
  } else if (evt.key == "Escape") {
    evt.preventDefault();
    evt.stopPropagation();
  }
};

export const PopupMenuList = ({
  children,
  visible,
  items,
  onMenuMouseEnter,
  onMenuMouseLeave,
  anchorCorner,
  focusOnOpen,
  onBlur,
  onFocus,
  onClose,
  selectedIndex,
  className,
}: PopupMenuListProps): React.ReactElement => (
  <MenuSurfaceAnchor>
    <Menu
      open={visible}
      focusOnOpen={focusOnOpen}
      onBlur={onBlur}
      onFocus={onFocus}
      onClose={onClose}
      anchorCorner={anchorCorner ?? "bottomLeft"}
      onMouseEnter={onMenuMouseEnter}
      onMouseLeave={onMenuMouseLeave}
      className={className}
    >
      {items.map((item, idx) => (
        <React.Fragment key={`${item.key}${idx}`}>
          {item.href ? (
            <ListItem
              onClick={(evt) =>
                !item.disabled ? handleClick(evt, item) : undefined
              }
              onKeyDown={(evt) =>
                !item.disabled ? handleKeyDown(evt, item) : undefined
              }
              selected={selectedIndex === idx}
              disabled={item.disabled ?? false}
              title={item?.title ?? ""}
              href={item.href}
              className={item.className}
            >
              {item.label}
            </ListItem>
          ) : (
            <MenuItem
              onClick={(evt) =>
                !item.disabled ? handleClick(evt, item) : undefined
              }
              onKeyDown={(evt) =>
                !item.disabled ? handleKeyDown(evt, item) : undefined
              }
              selected={selectedIndex === idx}
              disabled={item.disabled ?? false}
              title={item?.title ?? ""}
              className={item.className}
            >
              {item.label}
            </MenuItem>
          )}
        </React.Fragment>
      ))}
    </Menu>
    {children}
  </MenuSurfaceAnchor>
);
