import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "~/filterProviders/types";
import { createSimpleProvider } from "~/filterProviders/createSimpleProvider";
import {
  PayPeriodPicker,
  payPeriodOptions,
} from "~/visuals/pages/TimesheetReview/PayPeriodPicker";

export function usePayPeriodProvider(
  props: FilterProviderProps
): FilterProvider {
  return createSimpleProvider({
    documentationText: props.documentationText,
    type: "payPeriod",
    label: "Pay Period",
    render: () => (
      <PayPeriodPicker formField="payPeriod" label="Pay Period" helperText="" />
    ),
    suggestText: "per",
    options: payPeriodOptions,
  });
}
