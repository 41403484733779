import React from "react";
import {
  FormStateRenderer,
  InlineFieldsForm,
  InlineFieldsFormSubmitElement,
} from "~/inline-fields-form";
import { DescriptionList } from "~/description-list";
import Spinner from "~/spinner";
import {
  EditProjectTextField,
  EditStateCounty,
  EditEmployee,
  EditOfficeCode,
  EditDate,
  EditCorpLocation,
  EditDivision,
  EditEnumType,
  EditProjectBudget,
  EditProjectNotes,
  EditServiceDescription,
  EditCustomer,
  EditCheckbox,
  EditProjectGroup,
  EditProjectOrigination,
} from "~/ProjectForm/EditProjectComponents";
import Button from "~/button";
import { Customer, Project } from "~/gql/types";
import { Route } from "~/routeTypes";
import { projectCharges as projectChargesRoute } from "~/routes";
import { useRelativeNav } from "~/relative-nav";
import "./ProjectForm.scss";
import Card from "@material/react-card";
import { ProjectStatsDisplay } from "~/ProjectForm/ProjectStatsDisplay";
import { RateSheetInfo } from "./RateSheets";
import { FieldState, FormState } from "~/inline-fields-form/types";
import { BadDebtWarning } from "~/bad-debt-warning";

const CopyToClipboard: React.FC<{ text: string }> = ({ text }) => {
  const handleClick = () => {
    void navigator.clipboard.writeText(text);
  };
  return (
    <Button
      icon="content_copy"
      className="edit-project-button copy-button"
      onClick={handleClick}
      title="Copy to clipboard"
    />
  );
};

const TextWithCopyButton: React.FC<{ text: string }> = ({ text }) => {
  return (
    <>
      {text} &nbsp;
      <CopyToClipboard text={text} />
    </>
  );
};

type ProjectFormProps = {
  initialFieldState: FieldState[];
  handleSubmit: (formState: FormState) => Promise<void>;
  loading: boolean;
  project?: Project;
};

const updater = {
  name: (state: FormState, value: string): FormState => ({
    ...state,
    fields: {
      ...state.fields,
      description: {
        ...state.fields.description,
        value,
      },
    },
  }),
  customer: (state: FormState, value: Customer): FormState => ({
    ...state,
    fields: {
      ...state.fields,
      projectGroup: {
        ...state.fields.projectGroup,
        disabled: !value,
      },
    },
  }),
};

export const CreateProjectForm: React.FC<ProjectFormProps> = (props) => (
  <Card className="create-project-form">
    <ProjectForm {...props} />
  </Card>
);

export const ProjectForm: React.FC<ProjectFormProps> = (props) => {
  const { initialFieldState, handleSubmit, loading, project } = props;
  const CreateSubmitElement: InlineFieldsFormSubmitElement = ({
    onFormSubmit,
    formState,
  }) => {
    const nav = useRelativeNav();
    const onCancel = () => nav("../");

    const customer = Object.values(formState.fields).find(
      (x) => x.name === "customer"
    )?.value as Customer | null | undefined;

    return (
      <div className="submit-element">
        <BadDebtWarning {...{ customer }} />
        <Button
          className="save-new-project"
          primary
          onClick={() => onFormSubmit(handleSubmit)}
        >
          Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    );
  };

  return (
    <div className="project-form-page">
      <InlineFieldsForm
        initialFieldState={initialFieldState}
        handleSubmit={handleSubmit}
        SubmitElement={project ? undefined : CreateSubmitElement}
        updater={updater}
      >
        <DescriptionList className="info-view">
          <Spinner open={loading} />

          <dl>
            <dt className="heading">Account Information</dt>

            <dt>Date In</dt>
            <dd>
              <EditDate {...{ name: "dateIn" }} />
            </dd>

            {project && (
              <>
                <dt>Project Number</dt>
                <dd>
                  <TextWithCopyButton text={project.number.toString()} />
                </dd>
              </>
            )}

            <dt>Office Code</dt>
            <dd>
              <EditOfficeCode {...{ name: "officeCode" }} />
            </dd>

            <dt>Customer</dt>
            <dd>
              <EditCustomer {...{ name: "customer" }} />
            </dd>

            {project && (
              <>
                <dt>Customer Address</dt>
                <dd>{project.customer!.address1}</dd>
                {project.customer!.address2 && (
                  <>
                    <dt></dt>
                    <dd>{project.customer!.address2}</dd>
                  </>
                )}
                <dt></dt>
                <dd>
                  {`${project.customer!.city}, ${project.customer!.state} ${
                    project.customer!.zip
                  }`}
                  &nbsp;
                  <CopyToClipboard
                    text={`${project.customer!.address1}\n${
                      project.customer!.address2
                    }\n${project.customer!.city}, ${project.customer!.state} ${
                      project.customer!.zip
                    }`}
                  />
                </dd>

                {project.customer!.phone && (
                  <>
                    <dt>Customer Phone</dt>
                    <dd>
                      <TextWithCopyButton text={project.customer!.phone} />
                    </dd>
                  </>
                )}

                {project.customer!.fax && (
                  <>
                    <dt>Customer Fax</dt>
                    <dd>
                      <TextWithCopyButton text={project.customer!.fax} />
                    </dd>
                  </>
                )}
              </>
            )}

            <dt>Client Contact Name</dt>
            <dd>
              <EditProjectTextField
                {...{
                  name: "clientContactName",
                }}
              />
            </dd>

            <dt>Manager</dt>
            <dd>
              <EditEmployee
                {...{
                  name: "projectManager",
                  roleName: "Project Manager",
                }}
              />
            </dd>

            <dt>Supervisor</dt>
            <dd>
              <EditEmployee
                {...{
                  name: "projectSupervisor",
                  roleName: "Project Supervisor",
                }}
              />
            </dd>

            <dt>Billing Admin</dt>
            <dd>
              <EditEmployee
                {...{
                  name: "billingAdmin",
                  roleName: "Billing Admin",
                }}
              />
            </dd>

            <dt>Certified Payroll</dt>
            <dd>
              <EditCheckbox
                {...{
                  name: "certifiedPayroll",
                }}
              />
            </dd>
            <dt>Allow 3rd Party Invoice</dt>
            <dd>
              <EditCheckbox
                {...{
                  name: "allowThirdPartyInvoice",
                }}
              />
            </dd>

            <dt>Project Group</dt>
            <dd>
              <EditProjectGroup
                {...{
                  name: "projectGroup",
                }}
              />
            </dd>

            <dt>AFE</dt>
            <dd>
              <EditProjectTextField {...{ name: "afe" }} />
            </dd>

            <dt>WO</dt>
            <dd>
              <EditProjectTextField {...{ name: "wo" }} />
            </dd>

            <dt>PO</dt>
            <dd>
              <EditProjectTextField {...{ name: "po" }} />
            </dd>

            <dt>Cost Center</dt>
            <dd>
              <EditProjectTextField {...{ name: "costCenter" }} />
            </dd>

            <dt>Contract Number</dt>
            <dd>
              <EditProjectTextField {...{ name: "contractNumber" }} />
            </dd>

            <dt>GL Code</dt>
            <dd>
              <EditProjectTextField {...{ name: "glCode" }} />
            </dd>

            <dt>Requisitioner</dt>
            <dd>
              <EditProjectTextField {...{ name: "requisitioner" }} />
            </dd>
          </dl>

          <dl>
            <dt className="heading">Job Information</dt>

            <dt>Name</dt>
            <dd>
              <EditProjectTextField
                {...{
                  name: "name",
                  required: true,
                }}
              />
            </dd>

            {project && (
              <>
                <dt>Simplified Name</dt>
                <dd>
                  <FormStateRenderer
                    render={(fields) => (
                      <>
                        {project.name !== fields["name"].value &&
                          "(will be recomputed upon save)"}
                        {project.name === fields["name"].value &&
                          project.simplifiedName}
                      </>
                    )}
                  />
                </dd>

                <dt>Previous Names</dt>
                <dd>
                  {project.previousNames!.map((x, idx) => (
                    <div key={idx}>{x!.name}</div>
                  ))}
                </dd>
              </>
            )}

            <dt>Status</dt>
            <dd>
              <EditEnumType
                {...{ name: "status", enumType: "ProjectStatus" }}
              />
            </dd>

            <dt>Estimated Start</dt>
            <dd>
              <EditDate {...{ name: "estimatedStartDate" }} />
            </dd>

            <dt>Estimated End</dt>
            <dd>
              <EditDate {...{ name: "estimatedEndDate" }} />
            </dd>

            <dt>Actual Start</dt>
            <dd>
              <EditDate {...{ name: "actualStartDate" }} />
            </dd>

            <dt>Actual End</dt>
            <dd>
              <EditDate {...{ name: "actualEndDate" }} />
            </dd>

            <dt>Service Description</dt>
            <dd>
              <EditServiceDescription {...{ name: "serviceDescription" }} />
            </dd>

            <dt>Location</dt>
            <dd>
              <EditCorpLocation {...{ name: "corpLocation" }} />
            </dd>

            <dt>Division</dt>
            <dd>
              <EditDivision {...{ name: "division" }} />
            </dd>

            <dt>Government Funding</dt>
            <dd>
              <EditEnumType
                {...{
                  name: "governmentFunding",
                  enumType: "GovernmentFundingType",
                }}
              />
            </dd>

            <dt>Description</dt>
            <dd>
              <EditProjectTextField
                {...{
                  name: "description",
                  required: true,
                }}
              />
            </dd>

            <dt>State/County</dt>
            <dd>
              <EditStateCounty
                {...{
                  name: "location",
                  required: true,
                }}
              />
            </dd>

            <dt>Section</dt>
            <dd>
              <EditProjectTextField {...{ name: "section" }} />
            </dd>

            <dt>Township</dt>
            <dd>
              <EditProjectTextField {...{ name: "township" }} />
            </dd>

            <dt>Range</dt>
            <dd>
              <EditProjectTextField {...{ name: "range" }} />
            </dd>

            <dt>Block</dt>
            <dd>
              <EditProjectTextField {...{ name: "block" }} />
            </dd>

            <dt>Survey</dt>
            <dd>
              <EditProjectTextField {...{ name: "survey" }} />
            </dd>

            <dt>Lat/Long</dt>
            <dd>
              <EditProjectTextField {...{ name: "geographicLocation" }} />
            </dd>

            <dt>Network Path</dt>
            <dd>
              <EditProjectTextField {...{ name: "networkPath" }} />
            </dd>

            <dt>Scope</dt>
            <dd>
              <EditProjectNotes {...{ name: "scope" }} />
            </dd>

            <dt>Internal Notes</dt>
            <dd>
              <EditProjectNotes {...{ name: "internalNotes" }} />
            </dd>

            <EditProjectOrigination name={"projectOrigination"} />
          </dl>

          <dl>
            <dt className="heading">Billing Information</dt>

            <dt>Billing Frequency</dt>
            <dd>
              <EditEnumType
                {...{
                  name: "billingFrequency",
                  enumType: "BillingFrequencyType",
                }}
              />
            </dd>

            <dt>Budget</dt>
            <dd className="currency">
              <EditProjectBudget {...{ name: "budget" }} />
            </dd>

            {project && <RateSheetInfo project={project} />}

            {project && <ProjectStatsDisplay {...{ project }} />}

            <dt>Invoice Notes</dt>
            <dd>
              <EditProjectNotes {...{ name: "invoiceNotes" }} />
            </dd>
          </dl>
        </DescriptionList>

        {project && (
          <div className="general-info-links">
            <BadDebtWarning {...{ customer: project.customer }} />
            <Button
              outlined
              className="info-charges"
              route={
                new Route(
                  projectChargesRoute.path + `?project=${project.number}`
                )
              }
            >
              View all charges for this project
            </Button>
            <br />
          </div>
        )}
      </InlineFieldsForm>
    </div>
  );
};
