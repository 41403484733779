import { ApolloClient } from "@apollo/client";
import { EditProjectTimeDailyDocument } from "./projectTimeDailyQueries.generated";
import { ProjectTimeDailyApi, ValidationResponse } from "./WorkDayApiType";

export function createProjectTimeDailyApi(
  client: ApolloClient<any>
): ProjectTimeDailyApi {
  return {
    editProjectTimeDaily: async (args, callback) => {
      const result = await client.mutate({
        mutation: EditProjectTimeDailyDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.editProjectTimeDaily as ValidationResponse,
        callback,
      };
    },
  };
}
