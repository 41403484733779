import React, { useState } from "react";
import TableCell from "~/old-table-cell";
import TableContent from "~/old-table-content";
import "./OfficeCrewCodeRow.scss";
import { OverheadCrewCodeForm } from "./OverheadCrewCodeForm";
import { useUpdateOverheadCrewCode } from "./useUpdateOverheadCrewCode";
import { useCrewCodes } from "~/refdata2/crewCodes";
import { ItemType } from "./types";

type OfficeCrewCodeRow = {
  trailingSeparator: boolean;
  crewCode: any;
  refetch: React.DispatchWithoutAction;
  itemKey: "fieldOffice" | "state";
};

export const OverheadCrewCodeRow: React.FC<OfficeCrewCodeRow> = (props) => {
  const { trailingSeparator, crewCode, refetch, itemKey } = props;

  const [open, setOpen] = useState(false);
  const crewCodes = useCrewCodes();

  const itemType: ItemType = itemKey === "fieldOffice" ? "office" : "state";

  const valueSelector = () => crewCode[itemKey];

  const initialValues = {
    crewCode: crewCodes?.find((x) => x.crewCode == crewCode.crewCode) ?? null,
    state: null,
    officeCode: null,
  };

  const { loading, handleSubmit, onSuccess } = useUpdateOverheadCrewCode({
    type: "updated",
    itemKey,
    refetch,
    valueSelector,
    setOpen,
  });

  const uniqueClass = `${itemType}-${crewCode[itemKey]}`.toLowerCase();

  return (
    <TableContent
      {...{ trailingSeparator }}
      className={`overhead-crewcode-row ${uniqueClass}`}
      onClick={() => setOpen(!open)}
    >
      <TableCell text={crewCode?.[itemKey]} />
      <TableCell text={crewCode?.crewCode} />
      <OverheadCrewCodeForm
        {...{
          handleSubmit,
          initialValues,
          title: `Update ${crewCode[itemKey]}`,
          loading,
          open,
          setOpen,
          onSuccess,
        }}
      />
    </TableContent>
  );
};
