import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPerDiemStatsQueryVariables = Types.Exact<{
  filterText?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetPerDiemStatsQuery = { __typename?: 'QueryModel', perDiemRates?: { __typename?: 'PerDiemRatesQueryModel', stats?: Array<{ __typename?: 'PerDiemStateStats', counties: number, records: number, totalRecords: number, state: string } | null> | null } | null };


export const GetPerDiemStatsDocument = gql`
    query getPerDiemStats($filterText: String) {
  perDiemRates {
    stats(filterText: $filterText) {
      counties
      records
      totalRecords
      state
    }
  }
}
    `;

/**
 * __useGetPerDiemStatsQuery__
 *
 * To run a query within a React component, call `useGetPerDiemStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerDiemStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerDiemStatsQuery({
 *   variables: {
 *      filterText: // value for 'filterText'
 *   },
 * });
 */
export function useGetPerDiemStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetPerDiemStatsQuery, GetPerDiemStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPerDiemStatsQuery, GetPerDiemStatsQueryVariables>(GetPerDiemStatsDocument, options);
      }
export function useGetPerDiemStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPerDiemStatsQuery, GetPerDiemStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPerDiemStatsQuery, GetPerDiemStatsQueryVariables>(GetPerDiemStatsDocument, options);
        }
export type GetPerDiemStatsQueryHookResult = ReturnType<typeof useGetPerDiemStatsQuery>;
export type GetPerDiemStatsLazyQueryHookResult = ReturnType<typeof useGetPerDiemStatsLazyQuery>;
export type GetPerDiemStatsQueryResult = Apollo.QueryResult<GetPerDiemStatsQuery, GetPerDiemStatsQueryVariables>;