import React, { ReactElement } from "react";
import Card from "@material/react-card";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import { Routes, Route, useParams } from "react-router-dom";
import CustomerDisplay from "./Customer";
import { useGetSingleCustomerQuery } from "./query.generated";
import EditCustomer from "./EditCustomer";
import { Customer } from "~/gql/types";
import { CustomerContext } from "./CustomerContext";

type CustomerRouteData = {
  customerNumber: string;
};

const Loading = () => (
  <Grid>
    <Row>
      <Cell columns={12}>
        <Card>loading...</Card>
      </Cell>
    </Row>
  </Grid>
);

const PageRoutes: React.FC<{ customer: Customer }> = ({ customer }) => (
  <Routes>
    <Route path="edit" element={<EditCustomer customer={customer} />} />
    <Route index element={<CustomerDisplay customer={customer} />} />
  </Routes>
);

export default function CustomerRouter(): ReactElement {
  const { customerNumber } = useParams<CustomerRouteData>();

  const { data, loading, refetch } = useGetSingleCustomerQuery({
    variables: { customerNumber: customerNumber! },
  });

  const customer = data?.customers?.single;

  if (customer && !loading) {
    const api = {
      reload: refetch,
    };

    return (
      <CustomerContext.Provider value={api}>
        <PageRoutes customer={customer as Customer} />
      </CustomerContext.Provider>
    );
  }

  return <Loading />;
}
