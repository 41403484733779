import React from "react";
import "./TableContent.scss";
import List, { ListItem } from "@material/react-list";
import cn from "classnames";

export type TableContentProps = {
  trailingSeparator?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  expandable?: boolean;
  selectedIndex?: number[];
};

const TableContent: React.FC<TableContentProps> = ({
  children,
  trailingSeparator = true,
  className,
  onClick,
  selectedIndex,
  expandable = false,
}) => {
  const style = cn(
    ["table-row table-content", className].filter((x) => x).join(" "),
    {
      expandable,
      clickable: !!onClick,
    }
  );

  return (
    <ListItem onClick={onClick}>
      <List className={style} nonInteractive selectedIndex={selectedIndex}>
        {children}
        {trailingSeparator && <div className="divider mdc-list-divider" />}
      </List>
    </ListItem>
  );
};
export default TableContent;
