import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPerDiemRateForChargeFormQueryVariables = Types.Exact<{
  effectiveDate: Types.Scalars['LocalDate'];
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
}>;


export type GetPerDiemRateForChargeFormQuery = { __typename?: 'QueryModel', perDiemRates?: { __typename?: 'PerDiemRatesQueryModel', rate?: { __typename?: 'PerDiemRate', mealsBillable: number, lodgingBillable: number, travelBillable: number } | null } | null };


export const GetPerDiemRateForChargeFormDocument = gql`
    query GetPerDiemRateForChargeForm($effectiveDate: LocalDate!, $state: String!, $county: String!) {
  perDiemRates {
    rate(effectiveDate: $effectiveDate, state: $state, county: $county) {
      mealsBillable
      lodgingBillable
      travelBillable
    }
  }
}
    `;

/**
 * __useGetPerDiemRateForChargeFormQuery__
 *
 * To run a query within a React component, call `useGetPerDiemRateForChargeFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerDiemRateForChargeFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerDiemRateForChargeFormQuery({
 *   variables: {
 *      effectiveDate: // value for 'effectiveDate'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *   },
 * });
 */
export function useGetPerDiemRateForChargeFormQuery(baseOptions: Apollo.QueryHookOptions<GetPerDiemRateForChargeFormQuery, GetPerDiemRateForChargeFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPerDiemRateForChargeFormQuery, GetPerDiemRateForChargeFormQueryVariables>(GetPerDiemRateForChargeFormDocument, options);
      }
export function useGetPerDiemRateForChargeFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPerDiemRateForChargeFormQuery, GetPerDiemRateForChargeFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPerDiemRateForChargeFormQuery, GetPerDiemRateForChargeFormQueryVariables>(GetPerDiemRateForChargeFormDocument, options);
        }
export type GetPerDiemRateForChargeFormQueryHookResult = ReturnType<typeof useGetPerDiemRateForChargeFormQuery>;
export type GetPerDiemRateForChargeFormLazyQueryHookResult = ReturnType<typeof useGetPerDiemRateForChargeFormLazyQuery>;
export type GetPerDiemRateForChargeFormQueryResult = Apollo.QueryResult<GetPerDiemRateForChargeFormQuery, GetPerDiemRateForChargeFormQueryVariables>;