import React from "react";
import AtvTypeForm from "~/visuals/organisms/Expenses/ExpenseForm/AtvTypeForm";
import { useWorkDayApi } from "~/WorkDayApi";
import { AtvExpense, AtvExpenseRate } from "~/gql/types";
import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";
import { ApiCallback } from "~/WorkDayApi/WorkDayApiType";

type NewAtvProps = BaseExpenseFormProps & {
  expenseRate: AtvExpenseRate;
  expenseEntry?: AtvExpense;
};

const NewAtvExpense: React.FC<NewAtvProps> = (props) => {
  const { project, expenseEntry, date } = props;

  const api = useWorkDayApi();

  const uniqueInitVals: UniqueInitVals = [
    {
      ownerInformation:
        expenseEntry?.payable === "Invoice" ? "Personal" : "Company",
    },
    {
      ownerInformation: null,
    },
  ];

  const createExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc,
    callback?: ApiCallback
  ) => {
    const res = await api.createAtvExpense(
      {
        ...defaultVals(values),
        date,
        state: project?.location?.state!,
        county: project?.location?.county!,
        ownerInformation: values?.ownerInformation!,
      },
      callback
    );
    return res;
  };

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        uniqueInitVals,
        handleSubmit: createExpense,
        expenseType: "ATV",
        formType: expenseEntry ? "Clone" : "Create",
      }}
    >
      <AtvTypeForm {...props} />
    </ExpensePageWrapper>
  );
};

export default NewAtvExpense;
