import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import { useDivisionFromParam, useDivisionsAsync } from "~/refdata2/divisions";
import { ProjectRefData } from "~/refdata/sources";
import DivisionPickerFormField from "~/visuals/organisms/DivisionPickerFormField";
import { createProvider } from "./createFilterProvider";

export function useDivisionProvider(
  props: FilterProviderProps
): FilterProvider {
  const divisionFromParam = useDivisionFromParam();
  const getDivisions = useDivisionsAsync();

  const type = "division";
  const label = "Division";

  return createProvider({
    render: () => (
      <DivisionPickerFormField formField={type} label={label} helperText="" />
    ),
    type,
    label,
    props,
    getItems: getDivisions,
    itemFromParam: divisionFromParam,
    shouldSuggestText: "div",
    toSuggestions: (result, text) =>
      (result as ProjectRefData[])
        .filter(
          (d) =>
            d.name.toLowerCase().includes(text.toLowerCase()) ||
            d.id.toLowerCase().startsWith(text.toLowerCase())
        )
        .map((d) => ({ text: d.name, value: d })),
    toLabelValue: (value: ProjectRefData) => value.name,
    toParamValue: (value: ProjectRefData) => value.name,
  });
}
