import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { SimplePicker } from "~/visuals/organisms/SimplePicker";
import { Suggestion } from "~/ultra-filter/types";

export const assignableOptions = [
  { value: "All", text: "All" },
  { value: "Assignable", text: "Assignable" },
  { value: "Unassignable", text: "Unassignable" },
] as Suggestion[];

export const AssignableFilterPicker: React.FC<FormFieldProps> = (props) => (
  <SimplePicker {...{ ...props, options: assignableOptions }} />
);
