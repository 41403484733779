import { useConfirmationPrompt } from "~/confirmation-prompt";
import { useSnackBar } from "~/snackbar";
import { useDeleteEmployeeRateMutation } from "./delete.generated";
import { EmployeeRate, ValidationError } from "~/gql/types";

type DeleteRateProps = {
  reset: () => void;
  rate: EmployeeRate;
};

type ReturnProps = {
  loading: boolean;
  deleteRate: (args: DeleteRateProps) => Promise<void>;
};

export function useDeleteEmployeeRate(): ReturnProps {
  const prompt = useConfirmationPrompt();
  const addAlert = useSnackBar();

  const openDeleteConfirmation = async ({ reset, rate }: DeleteRateProps) => {
    const promptResult = await prompt({
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes, delete rate",
      message: "Are you sure you want to remove this employee rate?",
      title: "Delete employee rate",
    });

    if (promptResult !== "Confirm") {
      return;
    }

    await removeRate({ reset, rate });
  };

  const [doMutation, { loading }] = useDeleteEmployeeRateMutation();

  const removeRate = async ({ reset, rate }: DeleteRateProps) => {
    const employee = rate.employee!;

    const variables = {
      ultiProId: employee.ultiProId,
      effectiveDate: rate.effectiveDate,
    };

    const result = await doMutation({ variables });

    const data = result?.data?.employeeRates?.deleteRate;

    const errors = (result?.errors ?? data?.errors ?? []) as ValidationError[];

    const key = `${employee.ultiProId}-${rate.effectiveDate}`;

    if (errors.length > 0) {
      const formErrors: string[] = [];
      errors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          formErrors.push(`${argumentName}: ${message}`);
        } else if (message) {
          formErrors.push(message);
        }
      });

      addAlert({
        key,
        message: formErrors.join(" "),
        isSuccess: false,
      });
    } else {
      addAlert({
        key,
        message: "Employee rate deleted",
        isSuccess: true,
      });
    }

    reset();
  };

  return { deleteRate: openDeleteConfirmation, loading };
}
