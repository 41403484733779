import React from "react";
import { ProjectGroup } from "~/gql/types";
import { InlineTypeahead, InlineTypeaheadProps } from "~/inline-typeahead";
import { useProjectGroups } from "~/visuals/organisms/ProjectGroupPicker/projectGroupsQueryApi";

const format = (item: ProjectGroup): React.ReactNode =>
  `${item.customer!.name} - ${item.name}`;

type InlineProjectGroupPickerProps = {
  name: string;
  value: ProjectGroup;
  onChange: (val: ProjectGroup | null) => void;
  customerNumber: string | null;
  onBlur?: React.FocusEventHandler<any>;
  required?: boolean;
  submitError?: string;
};

export const InlineProjectGroupPicker: React.FC<
  InlineProjectGroupPickerProps
> = (props) => {
  const { customerNumber } = props;

  const getProjectGroups = useProjectGroups();

  const getData = async (searchText: string) => {
    if (!customerNumber) {
      return [];
    }

    const result = ((await getProjectGroups()) ?? []).filter(
      (x) => x.customer!.number === customerNumber
    );

    if (!searchText) {
      return result;
    }

    const lcSearch = searchText.toLowerCase();

    return result.filter(
      (t) =>
        t.name.toLowerCase().includes(lcSearch) ||
        t.customer!.name.toLowerCase().includes(lcSearch)
    );
  };

  const args = {
    ...props,
    getData,
    format,
  } as InlineTypeaheadProps<ProjectGroup>;

  return <InlineTypeahead {...args} />;
};
