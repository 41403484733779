import React from "react";
import { useNavigate } from "react-router-dom";
import AssignRateSheet from "~/visuals/organisms/AssignRateSheet";
import {
  project as projectRoute,
  customer as customerRoute,
  customerManagement,
} from "~/routes";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { Moment } from "moment";
import Spinner from "~/spinner";
import { useReloadProject } from "~/visuals/pages/Project/ProjectRouter";
import { useAssignTaskRateSheetToProjectMutation } from "./query.generated";
import { ValueRenderer } from "~/forms/ValueContext";
import { Project, ValidationResponse } from "~/gql/types";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";

type FormData = {
  effectiveDate: Moment | null;
  rateSheetName: string | null;
};

type AssignProjectTaskRateSheetProps = {
  project: Project;
};

const AssignProjectTaskRateSheet: React.FC<AssignProjectTaskRateSheetProps> = ({
  project,
}) => {
  const defaultFormValues: FormData = {
    effectiveDate: null,
    rateSheetName: null,
  };

  useBreadcrumbs(
    [
      { text: "Customers", to: customerManagement.path },
      {
        text: `Customer ${project?.customer?.number}: ${project?.customer?.name}`,
        to: customerRoute.toRoute(project?.customer?.number!).path,
      },
      {
        text: `Project ${project?.number}: ${project?.name}`,
        to: projectRoute.toRoute(project?.number).path,
      },
      { text: "Assign Task Rate sheet" },
    ],
    [project]
  );

  const [doMutation, { loading }] = useAssignTaskRateSheetToProjectMutation();

  const reloadProject = useReloadProject();
  const navigate = useNavigate();

  const onSuccess = () => {
    reloadProject();
    navigate(projectRoute.toRoute(project?.number).path);
  };

  const runMutation = async (values: FormData) => {
    const variables = {
      effectiveDate: values?.effectiveDate?.format("YYYY-MM-DD")!,
      rateSheetName: values?.rateSheetName!,
      projectNumbers: [project?.number]!,
    };

    const res = await doMutation({ variables });

    return res?.data?.taskRates?.assignRateSheetToProject as ValidationResponse;
  };

  return (
    <>
      <Spinner open={loading} />
      <MutationForm
        onSuccess={onSuccess}
        initialValues={defaultFormValues}
        runMutation={runMutation}
      >
        <ErrorRenderer
          render={(error) => (
            <ValueRenderer
              render={(values) => (
                <AssignRateSheet
                  projectNumber={project?.number}
                  errorMessage={error}
                  loading={loading}
                  type="Task"
                  effectiveDate={values.effectiveDate}
                />
              )}
            />
          )}
        />
      </MutationForm>
    </>
  );
};

export default AssignProjectTaskRateSheet;
