import React from "react";
import { Navigate, useParams } from "react-router-dom";
import CloneProjectTimeEntry from "./CloneProjectTimeEntry";
import { useWorkDayApi } from "~/WorkDayApi";

const CloneProjectTime: React.FC<{ id?: string }> = ({ id }) => {
  const { projectTimeId, date } = useParams<{
    projectTimeId: string;
    date: string;
  }>();

  const workDayApi = useWorkDayApi();

  const entryId = id ?? projectTimeId;

  const projectTime = workDayApi.workDay.projectTime?.find(
    (entry) => entry!.id === entryId
  );

  if (!projectTime) {
    return <Navigate to={"../../"} />;
  }

  return <CloneProjectTimeEntry {...{ date: date!, projectTime }} />;
};

export default CloneProjectTime;
