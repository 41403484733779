import MaterialIcon from "@material/react-material-icon";
import _ from "lodash";
import React, { useState } from "react";
import { PopupMenuList, PopupMenuListItem } from "~/popup-menu-list";
import { ProjectRequest } from "~/gql/types";
import "./RequestField.scss";
import { FieldState } from "~/inline-fields-form/types";

type DifferencesIconProps = {
  otherRequests: ProjectRequest[];
  property: keyof ProjectRequest;
  format?: (x: any) => string;
};

type RequestFieldProps = {
  formState: Record<string, FieldState>;
  name: string;
  label: string;
  otherRequests?: ProjectRequest[];
  format?: (x: any) => string;
};

const DifferencesIcon: React.FC<DifferencesIconProps> = ({
  otherRequests,
  property,
  format,
}) => {
  const [open, setOpen] = useState(false);

  const items = otherRequests.map((x, idx) => {
    const val = format ? format(x[property]) : x[property];

    return {
      label: val,
      key: idx,
      dataItem: idx,
    } as PopupMenuListItem;
  });

  return (
    <div className="differences-icon">
      <MaterialIcon
        icon="info"
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      />
      <PopupMenuList
        {...{ visible: open, items }}
        onMenuMouseEnter={() => undefined}
        onMenuMouseLeave={() => undefined}
        onClose={() => setOpen(false)}
        focusOnOpen={false}
        selectedIndex={0}
        anchorCorner="bottomRight"
      />
    </div>
  );
};

export const RequestField: React.FC<RequestFieldProps> = ({
  formState,
  name,
  label,
  children,
  otherRequests,
  format,
}) => {
  const showDiffIcon =
    otherRequests &&
    otherRequests.some((x) => !_.isEqual(formState[name].value, x[name]));
  return (
    <>
      <dt className="request-field-label">
        {showDiffIcon && (
          <DifferencesIcon
            {...{
              otherRequests,
              format,
              property: name as keyof ProjectRequest,
            }}
          />
        )}
        {formState[name].required && <span>*</span>}
        {label}
      </dt>
      <dd>{children}</dd>
    </>
  );
};
