import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import React, { useState } from "react";
import { Form } from "react-final-form";
import Button from "~/button";
import FormBottomRow from "~/form-bottom-row";
import TextFormField from "~/text-form-field";
import cn from "classnames";
import "./RejectWorkWeek.scss";

type RejectWorkWeekProps = {
  handleReject: (reason: string) => Promise<void>;
  date: string;
};

export const RejectWorkWeek: React.FC<RejectWorkWeekProps> = ({
  handleReject,
  date,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleSubmit = async (values: { reason: string }) => {
    return await handleReject(values.reason);
  };

  const dialogClass = cn("reject-work-week", { open });

  return (
    <>
      <Button outlined onClick={() => setOpen(true)}>
        Reject
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        data-open={open}
        className={dialogClass}
        portalize
      >
        <DialogTitle>{`Reject Timesheet ${date}`}</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={handleSubmit}
            initialValues={{ reason: null }}
            render={({ handleSubmit, submitError }) => (
              <form onSubmit={handleSubmit}>
                <TextFormField
                  label="Rejection reason"
                  helperText=""
                  formField="reason"
                  required
                />
                <FormBottomRow
                  errorMessage={submitError}
                  buttonText="Save"
                  onCancel={() => setOpen(false)}
                />
              </form>
            )}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
