import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTaskRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
  billableAmount?: Types.InputMaybe<Types.Scalars['Decimal']>;
  requiresDaily: Types.Scalars['Boolean'];
  rateType: Types.TaskRateType;
  unit: Types.TaskRateUnit;
  usesEmployeeRate: Types.Scalars['Boolean'];
  crewSize: Types.Scalars['Int'];
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  certifiedPayroll: Types.Scalars['Boolean'];
}>;


export type CreateTaskRateMutation = { __typename?: 'MutationModel', taskRates?: { __typename?: 'TaskRatesMutationModel', addRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CreateTaskRateDocument = gql`
    mutation createTaskRate($rateSheetName: String!, $rateName: String!, $billableAmount: Decimal, $requiresDaily: Boolean!, $rateType: TaskRateType!, $unit: TaskRateUnit!, $usesEmployeeRate: Boolean!, $crewSize: Int!, $glAccount: String, $certifiedPayroll: Boolean!) {
  taskRates {
    addRate(
      rateSheetName: $rateSheetName
      rateName: $rateName
      billableAmount: $billableAmount
      requiresDaily: $requiresDaily
      rateType: $rateType
      unit: $unit
      usesEmployeeRate: $usesEmployeeRate
      crewSize: $crewSize
      glAccount: $glAccount
      certifiedPayroll: $certifiedPayroll
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CreateTaskRateMutationFn = Apollo.MutationFunction<CreateTaskRateMutation, CreateTaskRateMutationVariables>;

/**
 * __useCreateTaskRateMutation__
 *
 * To run a mutation, you first call `useCreateTaskRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskRateMutation, { data, loading, error }] = useCreateTaskRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      rateName: // value for 'rateName'
 *      billableAmount: // value for 'billableAmount'
 *      requiresDaily: // value for 'requiresDaily'
 *      rateType: // value for 'rateType'
 *      unit: // value for 'unit'
 *      usesEmployeeRate: // value for 'usesEmployeeRate'
 *      crewSize: // value for 'crewSize'
 *      glAccount: // value for 'glAccount'
 *      certifiedPayroll: // value for 'certifiedPayroll'
 *   },
 * });
 */
export function useCreateTaskRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskRateMutation, CreateTaskRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskRateMutation, CreateTaskRateMutationVariables>(CreateTaskRateDocument, options);
      }
export type CreateTaskRateMutationHookResult = ReturnType<typeof useCreateTaskRateMutation>;
export type CreateTaskRateMutationResult = Apollo.MutationResult<CreateTaskRateMutation>;
export type CreateTaskRateMutationOptions = Apollo.BaseMutationOptions<CreateTaskRateMutation, CreateTaskRateMutationVariables>;