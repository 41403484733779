import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillAndDraftInvoiceQueryQueryVariables = Types.Exact<{
  projectNumber: Types.Scalars['Int'];
}>;


export type BillAndDraftInvoiceQueryQuery = { __typename?: 'QueryModel', invoices?: { __typename?: 'InvoicesQueryModel', search?: { __typename?: 'ConnectionOfInvoice', token?: string | null, records?: Array<{ __typename?: 'Invoice', total: number, state: Types.InvoiceState, invoiceNumber: string, customRollupInvoice?: { __typename?: 'CustomRollupInvoice', state: Types.InvoiceState, invoiceNumber: string } | null } | null> | null } | null } | null };


export const BillAndDraftInvoiceQueryDocument = gql`
    query BillAndDraftInvoiceQuery($projectNumber: Int!) {
  invoices {
    search(projectNumber: $projectNumber) {
      records {
        total
        state
        invoiceNumber
        customRollupInvoice {
          state
          invoiceNumber
        }
      }
      token
    }
  }
}
    `;

/**
 * __useBillAndDraftInvoiceQueryQuery__
 *
 * To run a query within a React component, call `useBillAndDraftInvoiceQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillAndDraftInvoiceQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillAndDraftInvoiceQueryQuery({
 *   variables: {
 *      projectNumber: // value for 'projectNumber'
 *   },
 * });
 */
export function useBillAndDraftInvoiceQueryQuery(baseOptions: Apollo.QueryHookOptions<BillAndDraftInvoiceQueryQuery, BillAndDraftInvoiceQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillAndDraftInvoiceQueryQuery, BillAndDraftInvoiceQueryQueryVariables>(BillAndDraftInvoiceQueryDocument, options);
      }
export function useBillAndDraftInvoiceQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillAndDraftInvoiceQueryQuery, BillAndDraftInvoiceQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillAndDraftInvoiceQueryQuery, BillAndDraftInvoiceQueryQueryVariables>(BillAndDraftInvoiceQueryDocument, options);
        }
export type BillAndDraftInvoiceQueryQueryHookResult = ReturnType<typeof useBillAndDraftInvoiceQueryQuery>;
export type BillAndDraftInvoiceQueryLazyQueryHookResult = ReturnType<typeof useBillAndDraftInvoiceQueryLazyQuery>;
export type BillAndDraftInvoiceQueryQueryResult = Apollo.QueryResult<BillAndDraftInvoiceQueryQuery, BillAndDraftInvoiceQueryQueryVariables>;