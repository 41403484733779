import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { companyRoles } from "~/visuals/pages/Employees/EmployeeRoles";
import { SimplePicker } from "~/visuals/organisms/SimplePicker";
import { Suggestion } from "~/ultra-filter/types";

export const companyRoleValues = companyRoles.map((x) => ({
  text: x,
  value: x,
})) as Suggestion[];

export const CompanyRolePicker: React.FC<FormFieldProps> = (props) => (
  <SimplePicker {...{ ...props, options: companyRoleValues }} />
);
