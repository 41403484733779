import { ApolloClient } from "@apollo/client";
import createRefdataApi from "../createRefdataApi";
import { WorkLocationsQuery, WorkLocationsDocument } from "./query.generated";

async function fetcher(client: ApolloClient<any>) {
  const result = await client.query<WorkLocationsQuery>({
    query: WorkLocationsDocument,
  });

  return result.data?.employees?.workLocations as string[];
}

const api = createRefdataApi(fetcher, "work location");

export const WorkLocationRefdataContext = api.Provider;
export const useWorkLocationsAsync = api.useDataAsync;
export const useWorkLocations = api.useData;

export function useWorkLocationFromParam(): (
  code: string
) => Promise<string | null> {
  const getLocations = useWorkLocationsAsync();

  return async (arg: string) => {
    const result = await getLocations();
    const workLocation = result?.find((x) => x === arg);

    return workLocation ?? null;
  };
}
