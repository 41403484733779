import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WeeklyProjectTasksQueryVariables = Types.Exact<{
  weekBeginning: Types.Scalars['LocalDate'];
  userPrincipalName: Types.Scalars['String'];
}>;


export type WeeklyProjectTasksQuery = { __typename?: 'QueryModel', timesheets?: { __typename?: 'TimesheetsQueryModel', weeklyProjectTasks?: { __typename?: 'WeeklyProjectTasks', firstName: string, lastName: string, editable: boolean, timesheetId: string, projectTime?: Array<{ __typename?: 'ProjectTimeEntry', taskName: string, date: string, hours: string, id: string, project?: { __typename?: 'Project', name: string, number: number, customer?: { __typename?: 'Customer', name: string } | null } | null } | null> | null } | null } | null };


export const WeeklyProjectTasksDocument = gql`
    query WeeklyProjectTasks($weekBeginning: LocalDate!, $userPrincipalName: String!) {
  timesheets {
    weeklyProjectTasks(
      weekBeginning: $weekBeginning
      userPrincipalName: $userPrincipalName
    ) {
      projectTime {
        taskName
        project {
          name
          number
          customer {
            name
          }
        }
        date
        hours
        id
      }
      firstName
      lastName
      editable
      timesheetId
    }
  }
}
    `;

/**
 * __useWeeklyProjectTasksQuery__
 *
 * To run a query within a React component, call `useWeeklyProjectTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeeklyProjectTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeeklyProjectTasksQuery({
 *   variables: {
 *      weekBeginning: // value for 'weekBeginning'
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useWeeklyProjectTasksQuery(baseOptions: Apollo.QueryHookOptions<WeeklyProjectTasksQuery, WeeklyProjectTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WeeklyProjectTasksQuery, WeeklyProjectTasksQueryVariables>(WeeklyProjectTasksDocument, options);
      }
export function useWeeklyProjectTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeeklyProjectTasksQuery, WeeklyProjectTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WeeklyProjectTasksQuery, WeeklyProjectTasksQueryVariables>(WeeklyProjectTasksDocument, options);
        }
export type WeeklyProjectTasksQueryHookResult = ReturnType<typeof useWeeklyProjectTasksQuery>;
export type WeeklyProjectTasksLazyQueryHookResult = ReturnType<typeof useWeeklyProjectTasksLazyQuery>;
export type WeeklyProjectTasksQueryResult = Apollo.QueryResult<WeeklyProjectTasksQuery, WeeklyProjectTasksQueryVariables>;