import { useApolloClient } from "@apollo/client";
import { Employee, EmployeeSortBy, EmployeeStatus } from "~/gql/types";
import { EmployeesSearchHookDocument } from "./employeeSearch.generated";
import _ from "lodash";

export type EmployeeRole =
  | "Billing Admin"
  | "Project Supervisor"
  | "Project Manager"
  | "Payroll Admin";

type EmployeeSearchArgs = {
  status?: EmployeeStatus;
  searchText?: string;
  officeCode?: string;
  token?: string;
  sortBy?: EmployeeSortBy;
  roleName?: EmployeeRole;
  activePayrollDate?: string;
};

export function useEmployeeSearch(): (args: EmployeeSearchArgs) => Promise<{
  records: Employee[];
  token: string | null;
}> {
  const client = useApolloClient();

  return async (args: EmployeeSearchArgs) => {
    const {
      searchText,
      token,
      status,
      sortBy,
      officeCode,
      roleName,
      activePayrollDate,
    } = args;
    const variables = {
      searchText: searchText || null,
      token: token || null,
      status: status || null,
      sortBy: sortBy || null,
      officeCode: officeCode || null,
      roleName: roleName || null,
      activePayrollDate: activePayrollDate || null,
    };

    const result = await client.query({
      query: EmployeesSearchHookDocument,
      variables,
    });
    const newToken = result?.data?.employees?.search?.token as string | null;
    const records = result?.data?.employees?.search?.records as Employee[];

    return { records, token: newToken };
  };
}

export function useEmployeeFromParam(): (
  upn: string
) => Promise<Employee | null> {
  const getEmployees = useEmployeeSearch();

  return async (upn: string) => {
    const result = await getEmployees({ searchText: upn });
    if (!result?.records || result.records.length === 0) {
      return null;
    }

    if (result.records.length > 1) {
      return _.orderBy(result.records, (x) => (x.isTerminated ? 1 : 0))[0];
    }

    return result.records[0] as Employee;
  };
}
