import React from "react";
import { useNavigate } from "react-router-dom";
import { taskRateSheets } from "~/routes";
import TaskRateSheetForm from "~/visuals/organisms/TaskRateSheetForm";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { useRateSheetsContext } from "~/visuals/organisms/RateSheets/rateSheetsContext";

type FormData = {
  name: string | null;
};

const NewTaskRateSheet: React.FC = () => {
  const navigate = useNavigate();

  const { create, reload, mutationLoading } = useRateSheetsContext();

  const onSuccess = () => {
    navigate(taskRateSheets.path);
  };

  const createTaskRateSheet = async (values: FormData) => {
    const result = await create(values.name!);

    reload();

    return result?.data?.taskRates?.createTaskRateSheet;
  };

  return (
    <MutationForm
      initialValues={{ name: null }}
      onSuccess={onSuccess}
      runMutation={createTaskRateSheet}
    >
      <ErrorRenderer
        render={(error) => (
          <TaskRateSheetForm errorMessage={error} loading={mutationLoading} />
        )}
      />
    </MutationForm>
  );
};

export default NewTaskRateSheet;
