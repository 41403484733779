import React from "react";
import {
  useRollupInvoice,
  useRollupInvoiceContextData,
} from "./RollupInvoiceContext";
import { InvoiceState, ValidationError } from "~/gql/types";
import { useCancelRollupInvoiceMutation } from "./cancelRollup.generated";
import CancelInvoice from "~/cancel-invoice";

export const CancelRollupInvoice: React.FC = () => {
  const rollupInvoice = useRollupInvoice();

  if (rollupInvoice.state === InvoiceState.Cancelled) {
    return null;
  }

  return <CancelRollupComponent />;
};

const CancelRollupComponent: React.FC = () => {
  const { rollupInvoice, reload } = useRollupInvoiceContextData();

  const [doMutation, { loading }] = useCancelRollupInvoiceMutation();

  const cancel = async (notes: string) => {
    const result = await doMutation({
      variables: { invoiceNumber: rollupInvoice.invoiceNumber, notes },
    });

    return result?.data?.customRollupInvoices?.cancel
      ?.errors as ValidationError[];
  };

  return (
    <CancelInvoice
      {...{
        cancel,
        invoiceNumber: rollupInvoice.invoiceNumber,
        loading,
        type: "Custom Rollup Invoice",
        reload,
      }}
    />
  );
};
