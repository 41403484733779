import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteEmployeeRateMutationVariables = Types.Exact<{
  ultiProId: Types.Scalars['String'];
  effectiveDate: Types.Scalars['LocalDate'];
}>;


export type DeleteEmployeeRateMutation = { __typename?: 'MutationModel', employeeRates?: { __typename?: 'EmployeeRatesMutationModel', deleteRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const DeleteEmployeeRateDocument = gql`
    mutation DeleteEmployeeRate($ultiProId: String!, $effectiveDate: LocalDate!) {
  employeeRates {
    deleteRate(ultiProId: $ultiProId, effectiveDate: $effectiveDate) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type DeleteEmployeeRateMutationFn = Apollo.MutationFunction<DeleteEmployeeRateMutation, DeleteEmployeeRateMutationVariables>;

/**
 * __useDeleteEmployeeRateMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeRateMutation, { data, loading, error }] = useDeleteEmployeeRateMutation({
 *   variables: {
 *      ultiProId: // value for 'ultiProId'
 *      effectiveDate: // value for 'effectiveDate'
 *   },
 * });
 */
export function useDeleteEmployeeRateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeeRateMutation, DeleteEmployeeRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeeRateMutation, DeleteEmployeeRateMutationVariables>(DeleteEmployeeRateDocument, options);
      }
export type DeleteEmployeeRateMutationHookResult = ReturnType<typeof useDeleteEmployeeRateMutation>;
export type DeleteEmployeeRateMutationResult = Apollo.MutationResult<DeleteEmployeeRateMutation>;
export type DeleteEmployeeRateMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeeRateMutation, DeleteEmployeeRateMutationVariables>;