import { faro } from "@grafana/faro-web-sdk";

export function useErrorReporter(): (err: any) => void {
  if (!window?.config?.errorReporting) {
    return () => undefined;
  }

  return async (err) => {
    faro?.api?.pushError(err);
  };
}
