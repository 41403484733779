import { useApolloClient } from "@apollo/client";
import _ from "lodash";
import React from "react";
import { BulkPostToInvoice } from "~/bulk-post-to-invoice";
import { PostToInvoiceState } from "~/bulk-post-to-invoice/stateMachine";
import {
  AddChargesResult,
  BulkAddChargesResponse,
  Invoice,
  ProjectCharge,
} from "~/gql/types";
import { Action } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { ProjectChargesFilter } from "~/visuals/pages/ProjectCharges/types";
import { BulkProjectDraftInvoicesDocument } from "./projectDraftInvoices.generated";
import {
  LoadedDraftInvoice,
  PostToInvoicesArgs,
} from "~/bulk-post-to-invoice/types";
import { BulkPostChargesDocument } from "./bulkPostToDraftInvoices.generated";
import { fetchUpdatedCharges } from "./utils";

type PostChargesToInvoicesProps = {
  selected: ProjectCharge[];
  dispatch: React.Dispatch<Action<ProjectCharge, ProjectChargesFilter>>;
  setPostToInvoiceOpen: React.Dispatch<boolean>;
};

export const toSuccessMessage = (response: BulkAddChargesResponse): string => {
  const results = (response.results ?? []) as AddChargesResult[];

  return results
    .map(
      ({ chargeCount, invoiceNumber, projectId }) =>
        `${chargeCount} charges for project ${projectId} added to invoice ${invoiceNumber}.\n`
    )
    .join(" ");
};

export const createInitialState = (
  charges: ProjectCharge[]
): PostToInvoiceState<ProjectCharge> => ({
  tag: "LoadingDraftInvoices",
  itemGroups: _.chain(charges)
    .groupBy((x) => x.projectId)
    .mapValues((x) => ({
      groupId: x[0].projectId,
      items: x,
      draftInvoices: [],
    }))
    .values()
    .value(),
  loading: true,
});

export const PostChargesToInvoices: React.FC<PostChargesToInvoicesProps> = (
  props
) => {
  const { selected, dispatch, setPostToInvoiceOpen } = props;
  const client = useApolloClient();

  const getDraftInvoices = async (
    groupIds: (string | number)[]
  ): Promise<LoadedDraftInvoice[]> => {
    const result = await client.query({
      query: BulkProjectDraftInvoicesDocument,
      variables: { projectNumbers: groupIds },
    });

    const invoices = (result?.data?.invoices?.bulkDraftInvoices ??
      []) as Invoice[];

    return invoices.map((x) => ({
      groupId: x.projectNumber,
      invoice: x,
    }));
  };

  const postToInvoices = async (args: PostToInvoicesArgs<ProjectCharge>[]) => {
    const addChargesArgs = args.map((x) => ({
      invoiceNumber: x.invoiceNumber,
      charges: x.items.map((x) => x.id),
    }));

    const result = await client.mutate({
      mutation: BulkPostChargesDocument,
      variables: { addChargesArgs },
    });

    return result?.data?.invoices?.bulkAddCharges;
  };

  const initialState = createInitialState(selected);

  const onSuccess = async (response: BulkAddChargesResponse) => {
    const updatedCharges = await fetchUpdatedCharges(
      client,
      selected.map((x) => x.id)
    );
    dispatch({ tag: "UpdateItems", items: updatedCharges });
    dispatch({ tag: "ClearSelected" });

    return toSuccessMessage(response);
  };

  return (
    <BulkPostToInvoice
      {...{
        initialState,
        getDraftInvoices,
        postToInvoices,
        onSuccess,
        onClose: () => setPostToInvoiceOpen(false),
      }}
    />
  );
};
