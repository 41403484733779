import React, { useState } from "react";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import Button from "~/button";
import Spinner from "~/spinner";
import { State } from "./stateMachine";

export type ProjectsCsvExporterProps = {
  state: State;
};

export const ProjectsCsvExporter: React.FC<ProjectsCsvExporterProps> = ({
  state,
}) => {
  const [loading, setLoading] = useState(false);
  const download = useReportFetcher();

  const exportCsv = async () => {
    await download({
      setLoading,
      path: "assigned-projects-csv",
      fileName: `${state.rateSheet}-assigned-projects`,
      body: {
        projectNumbers: state.projectNumbers,
      },
      type: "text/csv;charset=utf-8;",
    });
  };

  return (
    <>
      <Button outlined onClick={exportCsv} type="button">
        Export
      </Button>
      <Spinner open={loading} />
    </>
  );
};
