import React from "react";
import { useSnackBar } from "~/snackbar";
import FormBottomRow from "~/form-bottom-row";
import CrewCodeRecord from "~/graphql/CrewCodeRecord";
import TextFormField from "~/text-form-field";
import { CrewCodePickerFormField } from "~/visuals/organisms/CrewCodePickerFormField";
import { useEditChargesCrewCodeMutation } from "./editCrewCode.generated";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { ValidationResponse } from "~/gql/types";

type ChangeChargeCrewCodeFormProps = {
  setOpen: React.Dispatch<boolean>;
  entries: number[];
  resetCharges: (ids: number[]) => Promise<void>;
};

type FormValues = {
  crewCode: CrewCodeRecord | null;
  notes: string | null;
};

export const ChangeChargeCrewCodeForm: React.FC<
  ChangeChargeCrewCodeFormProps
> = ({ setOpen, entries, resetCharges }) => {
  const addAlert = useSnackBar();

  const initialValues = {
    crewCode: null,
    notes: null,
  };

  const [doMutation] = useEditChargesCrewCodeMutation();

  const onSuccess = ({ values }) => {
    setOpen(false);
    addAlert({
      key: `${Math.random()}`,
      message: `Charge${entries?.length ? "s" : ""} crew code changed to ${
        values.crewCode.crewCode
      }`,
      isSuccess: true,
    });
    void resetCharges(entries);
  };

  const handleSubmit = async (values: FormValues) => {
    const variables = {
      projectCharges: entries,
      crewCode: values.crewCode!.crewCode,
      editNotes: values.notes!,
    };

    const result = await doMutation({ variables });
    return result?.data?.projectCharges?.editCrewCode as ValidationResponse;
  };

  return (
    <MutationForm
      onSuccess={onSuccess}
      runMutation={handleSubmit}
      initialValues={initialValues}
    >
      <CrewCodePickerFormField
        formField="crewCode"
        label="Crew Code"
        helperText=""
      />
      <TextFormField
        formField="notes"
        label="Notes"
        helperText="Please explain the reason for the change"
        required
      />
      <ErrorRenderer
        render={(error) => (
          <FormBottomRow
            errorMessage={error}
            buttonText="Save"
            onCancel={() => setOpen(false)}
          />
        )}
      />
    </MutationForm>
  );
};
