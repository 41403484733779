import React, { useEffect } from "react";
import EnumDropdownFormField from "~/visuals/organisms/EnumDropdownFormField";
import { useChargeFormField } from "./fieldUtils";

export const UnitField: React.FC = () => {
  const date = useChargeFormField("date").value;
  const project = useChargeFormField("project").value;
  const useOtherLabel = useChargeFormField("useOtherLabel").value;
  const { value: rateValue, initial: initialRate } = useChargeFormField("rate");
  const { value: sheetValue, initial: initialSheet } =
    useChargeFormField("rateSheet");

  const { onChange } = useChargeFormField("unit");

  useEffect(() => {
    if (
      !(rateValue === initialRate && sheetValue === initialSheet) &&
      rateValue
    ) {
      onChange(rateValue.unit);
    }
  }, [rateValue]);

  const disabled = !date || !project;

  if (!useOtherLabel) {
    return <></>;
  }

  return (
    <EnumDropdownFormField
      label="Rate Unit"
      formField="unit"
      helperText="Select the rate unit type"
      enumType="RateUnit"
      required
      disabled={disabled}
    />
  );
};
