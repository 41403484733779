import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { useCustomerSearch } from "~/refdata/useCustomerSearch";
import { Customer } from "~/gql/types";

const format = (item: Customer) => `${item.name} (${item.number})`;

export type CustomerPickerProps = FormFieldProps;

export const CustomerPicker: React.FC<CustomerPickerProps> = (props) => {
  const getCustomers = useCustomerSearch();

  const getData = async (searchText: string) => {
    if (!searchText) {
      return [];
    }
    const result = await getCustomers({ searchText });
    return result?.records ?? [];
  };

  const args = {
    ...props,
    getData,
    format,
  } as TypeaheadProps<Customer>;

  return <TypeaheadFormField {...args} />;
};
