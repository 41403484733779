import { stringify, Input, Options } from "csv-stringify/dist/esm/sync";

type ExporterProps = {
  rows: Input;
  columns: string[];
  toCsvString?: (input: Input, options?: Options | undefined) => string;
};

export function useCsvCreator(): (props: ExporterProps) => Blob {
  return ({ rows, columns, toCsvString }: ExporterProps) => {
    const makeCsvString = toCsvString ?? stringify;
    const csv = makeCsvString(rows, { header: true, columns });

    return new Blob([csv as string], { type: "text/csv;charset=utf-8;" });
  };
}
