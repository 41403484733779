import { ApolloClient } from "@apollo/client";
import { ChargesDocument } from "./charges.generated";
import { ProjectCharge } from "~/gql/types";
import { GetSingleChargeDocument } from "~/charges-table/singleChargeQuery.generated";

export const fetchUpdatedCharge = async (
  client: ApolloClient<any>,
  id: number
): Promise<ProjectCharge> => {
  const result = await client.query({
    query: GetSingleChargeDocument,
    variables: { id },
  });
  return result?.data?.projectCharges?.single as ProjectCharge;
};

export const fetchUpdatedCharges = async (
  client: ApolloClient<any>,
  ids: number[]
): Promise<ProjectCharge[]> => {
  const result = await client.query({
    query: ChargesDocument,
    variables: { projectCharges: ids },
  });
  return result?.data?.projectCharges?.charges as ProjectCharge[];
};

export function formatApplicabilityMessage(
  applicableCount: number,
  totalCount: number,
  actionType: string
): string {
  return `${applicableCount} of ${totalCount} selected charges can be ${actionType}`;
}
