import React from "react";
import { FilterChip } from "~/filter-chip";
import { GroupingDialog } from "./GroupingDialog";
import { RevenueReportGroupingArgsInput } from "~/gql/types";
import { fields } from "./fields";

export type GroupingControlsProps = {
  options: RevenueReportGroupingArgsInput;
  setOptions: (
    value: React.SetStateAction<RevenueReportGroupingArgsInput>
  ) => void;
};
export const GroupingControls: React.FC<GroupingControlsProps> = ({
  options,
  setOptions,
}) => {
  return (
    <div className="grouping-controls">
      {fields.length && <>Grouped by:</>}

      <div className="group-chips-container">
        {fields.map(({ key, label }) => {
          return options[key] ? (
            <FilterChip
              key={key}
              label={label}
              onClick={() => setOptions((o) => ({ ...o, [key]: false }))}
            />
          ) : null;
        })}
      </div>
      <GroupingDialog defaultValues={options} setOptions={setOptions} />
    </div>
  );
};
