import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditTaskRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  oldName: Types.Scalars['String'];
  newName: Types.Scalars['String'];
  billableAmount?: Types.InputMaybe<Types.Scalars['Decimal']>;
  requiresDaily: Types.Scalars['Boolean'];
  rateType: Types.TaskRateType;
  unit: Types.TaskRateUnit;
  usesEmployeeRate: Types.Scalars['Boolean'];
  crewSize: Types.Scalars['Int'];
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
  certifiedPayroll: Types.Scalars['Boolean'];
}>;


export type EditTaskRateMutation = { __typename?: 'MutationModel', taskRates?: { __typename?: 'TaskRatesMutationModel', editRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type DeleteTaskRateMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  rateName: Types.Scalars['String'];
}>;


export type DeleteTaskRateMutation = { __typename?: 'MutationModel', taskRates?: { __typename?: 'TaskRatesMutationModel', deleteRate?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const EditTaskRateDocument = gql`
    mutation editTaskRate($rateSheetName: String!, $oldName: String!, $newName: String!, $billableAmount: Decimal, $requiresDaily: Boolean!, $rateType: TaskRateType!, $unit: TaskRateUnit!, $usesEmployeeRate: Boolean!, $crewSize: Int!, $glAccount: String, $certifiedPayroll: Boolean!) {
  taskRates {
    editRate(
      rateSheetName: $rateSheetName
      oldName: $oldName
      newName: $newName
      billableAmount: $billableAmount
      requiresDaily: $requiresDaily
      rateType: $rateType
      unit: $unit
      usesEmployeeRate: $usesEmployeeRate
      crewSize: $crewSize
      glAccount: $glAccount
      certifiedPayroll: $certifiedPayroll
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditTaskRateMutationFn = Apollo.MutationFunction<EditTaskRateMutation, EditTaskRateMutationVariables>;

/**
 * __useEditTaskRateMutation__
 *
 * To run a mutation, you first call `useEditTaskRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskRateMutation, { data, loading, error }] = useEditTaskRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      oldName: // value for 'oldName'
 *      newName: // value for 'newName'
 *      billableAmount: // value for 'billableAmount'
 *      requiresDaily: // value for 'requiresDaily'
 *      rateType: // value for 'rateType'
 *      unit: // value for 'unit'
 *      usesEmployeeRate: // value for 'usesEmployeeRate'
 *      crewSize: // value for 'crewSize'
 *      glAccount: // value for 'glAccount'
 *      certifiedPayroll: // value for 'certifiedPayroll'
 *   },
 * });
 */
export function useEditTaskRateMutation(baseOptions?: Apollo.MutationHookOptions<EditTaskRateMutation, EditTaskRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTaskRateMutation, EditTaskRateMutationVariables>(EditTaskRateDocument, options);
      }
export type EditTaskRateMutationHookResult = ReturnType<typeof useEditTaskRateMutation>;
export type EditTaskRateMutationResult = Apollo.MutationResult<EditTaskRateMutation>;
export type EditTaskRateMutationOptions = Apollo.BaseMutationOptions<EditTaskRateMutation, EditTaskRateMutationVariables>;
export const DeleteTaskRateDocument = gql`
    mutation deleteTaskRate($rateSheetName: String!, $rateName: String!) {
  taskRates {
    deleteRate(rateSheetName: $rateSheetName, rateName: $rateName) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type DeleteTaskRateMutationFn = Apollo.MutationFunction<DeleteTaskRateMutation, DeleteTaskRateMutationVariables>;

/**
 * __useDeleteTaskRateMutation__
 *
 * To run a mutation, you first call `useDeleteTaskRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskRateMutation, { data, loading, error }] = useDeleteTaskRateMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      rateName: // value for 'rateName'
 *   },
 * });
 */
export function useDeleteTaskRateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskRateMutation, DeleteTaskRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskRateMutation, DeleteTaskRateMutationVariables>(DeleteTaskRateDocument, options);
      }
export type DeleteTaskRateMutationHookResult = ReturnType<typeof useDeleteTaskRateMutation>;
export type DeleteTaskRateMutationResult = Apollo.MutationResult<DeleteTaskRateMutation>;
export type DeleteTaskRateMutationOptions = Apollo.BaseMutationOptions<DeleteTaskRateMutation, DeleteTaskRateMutationVariables>;