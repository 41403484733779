import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillInvoiceMutationVariables = Types.Exact<{
  invoiceNumber: Types.Scalars['String'];
}>;


export type BillInvoiceMutation = { __typename?: 'MutationModel', invoices?: { __typename?: 'InvoiceMutationModel', bill?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const BillInvoiceDocument = gql`
    mutation BillInvoice($invoiceNumber: String!) {
  invoices {
    bill(invoiceNumber: $invoiceNumber) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type BillInvoiceMutationFn = Apollo.MutationFunction<BillInvoiceMutation, BillInvoiceMutationVariables>;

/**
 * __useBillInvoiceMutation__
 *
 * To run a mutation, you first call `useBillInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billInvoiceMutation, { data, loading, error }] = useBillInvoiceMutation({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *   },
 * });
 */
export function useBillInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<BillInvoiceMutation, BillInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BillInvoiceMutation, BillInvoiceMutationVariables>(BillInvoiceDocument, options);
      }
export type BillInvoiceMutationHookResult = ReturnType<typeof useBillInvoiceMutation>;
export type BillInvoiceMutationResult = Apollo.MutationResult<BillInvoiceMutation>;
export type BillInvoiceMutationOptions = Apollo.BaseMutationOptions<BillInvoiceMutation, BillInvoiceMutationVariables>;