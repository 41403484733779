import React from "react";
import { TaskRateSheet } from "~/gql/types";
import { AssignSuccessorRateSheet } from "~/visuals/organisms/AssignSuccessor";

type AssignSuccessorTaskRateSheetProps = {
  useReload(): React.DispatchWithoutAction;
  rateSheet: TaskRateSheet;
};

export const AssignSuccessorTaskRateSheet: React.FC<
  AssignSuccessorTaskRateSheetProps
> = (props) => (
  <AssignSuccessorRateSheet {...{ ...props, type: "taskRateSheet" }} />
);
