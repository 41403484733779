import { useState } from "react";
import { NotificationDisplayProps } from "./type";
import { NotificationBadge } from "./NotificationBadge";
import { NotificationMenu } from "./NotificationMenu";
import React from "react";

export const AppNotificationDisplay: React.FC<NotificationDisplayProps> = ({
  notifications,
  markRead,
  refetch,
  pathMap,
  clearAll,
  count,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <NotificationBadge
        {...{ notificationCount: notifications.length, setOpen }}
      />
      <NotificationMenu
        {...{
          open,
          setOpen,
          markRead,
          notifications,
          refetch,
          pathMap,
          clearAll,
          count,
        }}
      />
    </>
  );
};
