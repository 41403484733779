import React from "react";
import { EmployeeRole, useEmployeeSearch } from "~/refdata/useEmployeeSearch";
import { InlineTypeahead, InlineTypeaheadProps } from "~/inline-typeahead";
import { Employee } from "~/gql/types";

const format = (item: Employee) => `${item.firstName} ${item.lastName}`;

export type InlineEmployeePickerProps = {
  value: Employee | null;
  name: string;
  onChange: (value: Employee | null) => void;
  onBlur?: React.FocusEventHandler<any>;
  required?: boolean;
  roleName?: EmployeeRole;
  activePayrollDate?: string;
  submitError?: string;
};

export const InlineEmployeePicker: React.FC<InlineEmployeePickerProps> = (
  props
) => {
  const { roleName, activePayrollDate, ...rest } = props;

  const getEmployees = useEmployeeSearch();

  const getData = async (searchText: string) => {
    if (!searchText && !props.roleName) {
      return [];
    }
    const result = await getEmployees({
      searchText: searchText || undefined,
      roleName,
      activePayrollDate,
    });
    return result?.records ?? [];
  };

  const args = {
    ...rest,
    getData,
    format,
  } as InlineTypeaheadProps<Employee>;

  return <InlineTypeahead {...args} />;
};
