import { Duration } from "moment";
import StateCounty from "~/graphql/StateCounty";
import { Project } from "../../pages/Project/ProjectDetails/types";
import {
  CrewCodeRecord,
  CrewRole,
  Employee,
  ProjectTimeEntry,
  TaskRate,
} from "~/gql/types";
import { FormErrors } from "~/WorkDayApi/WorkDayApiType";
import { FORM_ERROR } from "final-form";

export type FormData = {
  hours: Duration | null;
  crewCode: CrewCodeRecord | null;
  project: Project | null;
  taskRate: TaskRate | null;
  taskName: string | null;
  notes: string | null;
  crewSize: string | null;
  myRole: CrewRole | null;
  otherCrewOne: Employee | null;
  otherCrewTwo: Employee | null;
  otherRole1: CrewRole | null;
  otherRole2: CrewRole | null;
  location: StateCounty | null;
  adminNotes: string | null;
  rateSheetName: string | null;
  saveAndClone: boolean;
  copyToDate: boolean;
  copyToDates: boolean;
  dailyHours: DailyHours[] | null;
  formErrors: FormErrors | null;
  employee: Employee;
  fieldSupervisor;
  leadPartyChief;
};

export type EmployeeRole =
  | "partyChief"
  | "instrumentTech"
  | "rodMan"
  | "fieldSupervisor"
  | "leadPartyChief";

export type HourType =
  | "asBuilt"
  | "constRestake"
  | "constStake"
  | "locate"
  | "boundary"
  | "clStake"
  | "rerouteStake"
  | "rainoutOther"
  | "travelHours";

export type DailyHours = {
  employee: Employee | null;
  role: EmployeeRole;
  asBuilt?: number;
  constRestake?: number;
  constStake?: number;
  locate?: number;
  boundary?: number;
  clStake?: number;
  rerouteStake?: number;
  rainoutOther?: number;
  travelHours?: number;
};

export type State = FormData & {
  taskRates: TaskRate[];
  loading: boolean;
  entry?: ProjectTimeEntry;
};

type TaggedAction<T extends string> = { tag: T };
type UpdateField = TaggedAction<"UpdateField"> & {
  fieldName: string;
  value: any;
};
type UpdateCrewCode = TaggedAction<"UpdateCrewCode"> & {
  crewCode: CrewCodeRecord;
};
type LoadData = TaggedAction<"LoadData">;
type TaskRatesLoaded = TaggedAction<"TaskRatesLoaded"> & {
  taskRates: TaskRate[];
  rateSheetName: string;
};
type UpdateDailyHours = TaggedAction<"UpdateDailyHours"> & {
  hours: DailyHours;
};
type Submitted = TaggedAction<"Submitted">;

export type Action =
  | UpdateField
  | UpdateCrewCode
  | LoadData
  | TaskRatesLoaded
  | UpdateDailyHours
  | Submitted;

const clearCrewTwo = {
  otherCrewTwo: null,
  otherRole2: null,
};

const clearCrew = {
  ...clearCrewTwo,
  otherCrewOne: null,
  otherRole1: null,
};

const clearTask = {
  taskRate: null,
  taskName: null,
};

export function reducer(state: State, action: Action): State {
  if (action.tag === "LoadData") {
    return { ...state, loading: true };
  }

  if (action.tag === "TaskRatesLoaded") {
    const matchedRate = action.taskRates.find(
      (x) => x?.name === state.taskName
    );

    const taskInfo =
      state.rateSheetName && action.rateSheetName != state.rateSheetName
        ? clearTask
        : { taskRate: matchedRate ?? null };

    return {
      ...state,
      ...taskInfo,
      loading: false,
      taskRates: action.taskRates,
      rateSheetName: action.rateSheetName,
    };
  }

  if (action.tag === "UpdateDailyHours") {
    const newDailyHours = [
      ...state.dailyHours!.filter((x) => x.role !== action.hours.role),
      {
        ...(state.dailyHours!.find(
          (x) => x.role === action.hours.role
        ) as DailyHours),
        ...action.hours,
      },
    ];

    return {
      ...state,
      dailyHours: newDailyHours,
      formErrors: validateHours(newDailyHours, state),
    };
  }

  if (action.tag === "UpdateCrewCode") {
    return { ...state, crewCode: action.crewCode };
  }

  if (action.tag === "Submitted") {
    return { ...state, loading: false };
  }

  if (action.tag === "UpdateField") {
    if (action.fieldName === "project") {
      if (!action.value) {
        return updateDailyHourRoles({
          ...state,
          project: null,
        });
      } else {
        const isOverhead = action.value.serviceDescription.isOverhead;
        return updateDailyHourRoles({
          ...state,
          project: action.value,
          location: isOverhead ? null : action.value.location,
        });
      }
    }

    if (action.fieldName === "taskRate") {
      if (!action.value) {
        return updateDailyHourRoles({
          ...state,
          ...clearTask,
        });
      } else {
        const requiresDaily = (action.value as TaskRate).requiresDaily;

        const crewMap = {
          1: {
            crewSize: 1,
            ...clearCrew,
            myRole: requiresDaily ? CrewRole.CrewChief : null,
          },
          2: {
            crewSize: 2,
            ...clearCrewTwo,
          },
          3: {
            crewSize: 3,
          },
        };

        const newState = {
          ...state,
          taskRate: action.value,
          taskName: action.value.name,
          ...crewMap[action.value.crewSize],
        };

        return updateDailyHourRoles(newState);
      }
    }

    const newState = {
      ...state,
      [action.fieldName]: action.value ?? null,
    };

    return updateDailyHourRoles(newState);
  }

  return state;
}

export function updateDailyHourRoles(state: State): State {
  if (state.dailyHours === null) {
    return state;
  }

  const otherCrew = [
    { employee: state.employee, role: state.myRole },
    { employee: state.otherCrewOne, role: state.otherRole1 },
    { employee: state.otherCrewTwo, role: state.otherRole2 },
  ].filter((x) => x.role && x.employee);

  const roleInfo = [
    {
      role: "partyChief",
      employee: otherCrew.find((x) => x.role === CrewRole.CrewChief)?.employee,
    },
    {
      role: "instrumentTech",
      employee: otherCrew.find((x) => x.role === CrewRole.IMan)?.employee,
    },
    {
      role: "rodMan",
      employee: otherCrew.find((x) => x.role === CrewRole.Rodman)?.employee,
    },
  ];

  const updatedHours = state.dailyHours
    .map((x) => {
      const newRoleInfo = roleInfo.find((r) => r.role === x.role);
      const employee = newRoleInfo?.employee;
      return employee ? { ...x, employee } : null;
    })
    .filter((x) => !!x) as DailyHours[];

  const newHours = roleInfo
    .filter((x) => !!x.employee)
    .filter(
      (x) => !updatedHours.find((h) => h.role === x.role)
    ) as DailyHours[];

  updatedHours.push(...newHours);

  return {
    ...state,
    dailyHours: updatedHours,
    formErrors: validateHours(updatedHours, state),
  };
}

export const calculateHours = (data: DailyHours): number =>
  (data.asBuilt || 0.0) +
  (data.constRestake || 0.0) +
  (data.constStake || 0.0) +
  (data.locate || 0.0) +
  (data.boundary || 0.0) +
  (data.clStake || 0.0) +
  (data.rerouteStake || 0.0) +
  (data.rainoutOther || 0.0) +
  (data.travelHours || 0.0);

const validateHours = (
  dailyHours: DailyHours[] | null,
  state: State
): FormErrors | null => {
  const requiresDaily = state.taskRate?.requiresDaily;

  if (!requiresDaily || !dailyHours) {
    return null;
  }

  const formErrors: string[] = [];

  dailyHours.forEach((h) => {
    const calculatedHours = calculateHours(h);

    if (calculatedHours === 0) {
      const label = h.role.replace(/([A-Z])/g, " $1").toLowerCase();
      formErrors.push(`You must specify hours for ${label}.`);
    }
  });

  return formErrors.length > 0 ? { [FORM_ERROR]: formErrors.join(" ") } : null;
};
