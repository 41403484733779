import React, { createContext, useContext, useState } from "react";
import { State } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import _ from "lodash";
import { FilterOptions, InvoiceSearchStats } from "./types";
import { Invoice } from "~/gql/types";

type ContextType = {
  state: State<Invoice, FilterOptions> | null;
  setState: React.Dispatch<State<Invoice, FilterOptions> | null>;
  stats: InvoiceSearchStats | null;
  setStats: React.Dispatch<InvoiceSearchStats | null>;
  statsText: string;
};

export const InvoiceSearchStatsContext = createContext<ContextType>({
  state: null,
  setState: () => null,
  stats: null,
  setStats: () => null,
  statsText: "",
});

export const InvoiceSearchStatsProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<State<Invoice, FilterOptions> | null>(
    null
  );
  const [stats, setStats] = useState<InvoiceSearchStats | null>(null);

  let statsText = "";

  if (state && stats) {
    const invoicesLoaded = state?.items.length ?? 0;
    statsText = `${invoicesLoaded} of ${stats.invoiceCount} invoices loaded`;
  }

  return (
    <InvoiceSearchStatsContext.Provider
      value={{ state, setState, stats, setStats, statsText }}
    >
      {children}
    </InvoiceSearchStatsContext.Provider>
  );
};

export function useInvoiceSearchStats() {
  const context = useContext(InvoiceSearchStatsContext);
  return context;
}
