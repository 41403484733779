import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkEditClientContactNameMutationVariables = Types.Exact<{
  clientContactName: Types.Scalars['String'];
  projectNumbers: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type BulkEditClientContactNameMutation = { __typename?: 'MutationModel', projects?: { __typename?: 'ProjectsMutationModel', editClientContactName?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const BulkEditClientContactNameDocument = gql`
    mutation BulkEditClientContactName($clientContactName: String!, $projectNumbers: [Int!]!) {
  projects {
    editClientContactName(
      clientContactName: $clientContactName
      projectNumbers: $projectNumbers
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type BulkEditClientContactNameMutationFn = Apollo.MutationFunction<BulkEditClientContactNameMutation, BulkEditClientContactNameMutationVariables>;

/**
 * __useBulkEditClientContactNameMutation__
 *
 * To run a mutation, you first call `useBulkEditClientContactNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditClientContactNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditClientContactNameMutation, { data, loading, error }] = useBulkEditClientContactNameMutation({
 *   variables: {
 *      clientContactName: // value for 'clientContactName'
 *      projectNumbers: // value for 'projectNumbers'
 *   },
 * });
 */
export function useBulkEditClientContactNameMutation(baseOptions?: Apollo.MutationHookOptions<BulkEditClientContactNameMutation, BulkEditClientContactNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkEditClientContactNameMutation, BulkEditClientContactNameMutationVariables>(BulkEditClientContactNameDocument, options);
      }
export type BulkEditClientContactNameMutationHookResult = ReturnType<typeof useBulkEditClientContactNameMutation>;
export type BulkEditClientContactNameMutationResult = Apollo.MutationResult<BulkEditClientContactNameMutation>;
export type BulkEditClientContactNameMutationOptions = Apollo.BaseMutationOptions<BulkEditClientContactNameMutation, BulkEditClientContactNameMutationVariables>;