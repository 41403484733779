import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTpiUnreviewedQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTpiUnreviewedQuery = { __typename?: 'QueryModel', thirdPartyInvoices?: { __typename?: 'ThirdPartyInvoicesQueryModel', unreviewedCount?: { __typename?: 'ThirdPartyInvoiceUnreviewedCount', count: number } | null } | null };


export const GetTpiUnreviewedDocument = gql`
    query getTpiUnreviewed {
  thirdPartyInvoices {
    unreviewedCount {
      count
    }
  }
}
    `;

/**
 * __useGetTpiUnreviewedQuery__
 *
 * To run a query within a React component, call `useGetTpiUnreviewedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTpiUnreviewedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTpiUnreviewedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTpiUnreviewedQuery(baseOptions?: Apollo.QueryHookOptions<GetTpiUnreviewedQuery, GetTpiUnreviewedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTpiUnreviewedQuery, GetTpiUnreviewedQueryVariables>(GetTpiUnreviewedDocument, options);
      }
export function useGetTpiUnreviewedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTpiUnreviewedQuery, GetTpiUnreviewedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTpiUnreviewedQuery, GetTpiUnreviewedQueryVariables>(GetTpiUnreviewedDocument, options);
        }
export type GetTpiUnreviewedQueryHookResult = ReturnType<typeof useGetTpiUnreviewedQuery>;
export type GetTpiUnreviewedLazyQueryHookResult = ReturnType<typeof useGetTpiUnreviewedLazyQuery>;
export type GetTpiUnreviewedQueryResult = Apollo.QueryResult<GetTpiUnreviewedQuery, GetTpiUnreviewedQueryVariables>;