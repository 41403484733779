import React from "react";
import { Form } from "react-final-form";
import { FormApi } from "final-form";
import _ from "lodash";
import { ErrorContext } from "./MutationForm";
import { ValueContext } from "./ValueContext";

type ClientFormProps<TFormData> = {
  initialValues: TFormData;
  children: React.ReactNode;
  onSuccess: React.Dispatch<TFormData>;
  allowPristineSubmit?: boolean;
};

type ClientFormFunc = <TFormData>(
  props: ClientFormProps<TFormData>
) => React.ReactElement;

export const ClientForm: ClientFormFunc = <TFormData,>(
  props: ClientFormProps<TFormData>
) => {
  const { children, initialValues, onSuccess, allowPristineSubmit } = props;

  const internalOnSubmit = async (values, form: FormApi) => {
    const state = form.getState();

    if (state.pristine && !allowPristineSubmit) {
      return;
    }

    onSuccess(values);
  };

  return (
    <Form
      onSubmit={internalOnSubmit}
      initialValues={initialValues as any}
      render={({ handleSubmit, error, submitError, values }) => (
        <form onSubmit={handleSubmit}>
          <ErrorContext.Provider value={submitError || error || ""}>
            <ValueContext.Provider value={values}>
              {children}
            </ValueContext.Provider>
          </ErrorContext.Provider>
        </form>
      )}
    />
  );
};
