import React from "react";
import CurrencyFormField from "~/visuals/organisms/CurrencyFormField";
import "./ExpenseForm.scss";
import TextFormField from "~/text-form-field";
import { FormProps } from "./CommonTypes";
import DropdownFormField from "~/visuals/organisms/DropdownFormField";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import { FuelExpense } from "~/gql/types";
import ExpenseImageUploadWrapper from "./ExpenseImageUploadWrapper";
import { CrewCodePickerFormField } from "~/visuals/organisms/CrewCodePickerFormField";

type FuelFormProps = FormProps & {
  setImageId: React.Dispatch<string | null>;
  expenseEntry?: FuelExpense;
};

const FuelTypeForm: React.FC<FuelFormProps> = (props) => {
  return (
    <ExpenseImageUploadWrapper typeName="Fuel Expense" {...props}>
      <CrewCodePickerFormField
        label="Crew Code"
        formField="crewCode"
        helperText="The crew code applicable to this work. (use up/down arrows and enter key to select suggestions)"
        required
      />
      <TextFormField
        label="Odometer reading"
        helperText=""
        formField="odometer"
        type="number"
        required
      />
      <TextFormField
        label="Tag number/Unit number"
        helperText=""
        formField="vehicleNumber"
        required
      />
      <TextFormField
        label="Quantity"
        helperText="amount in gallons"
        formField="quantity"
        type="number"
        required
      />
      <CurrencyFormField
        label="Receipt Total"
        helperText="Total amount spent"
        formField="receiptTotal"
        required
      />
      <DropdownFormField
        formField="payerInformation"
        label="Payer Information"
        helperText="Select option"
        required
        options={[
          { label: "Company paid", value: "Company" },
          { label: "Employee paid", value: "Employee" },
        ]}
      />
      <BinaryPickerFormField
        label="Company Vehicle"
        helperText=""
        formField="companyVehicle"
      />
      <TextFormField label="Notes" helperText="" formField="notes" textarea />
    </ExpenseImageUploadWrapper>
  );
};

export default FuelTypeForm;
