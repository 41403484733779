import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllFilteredInvoicesQueryQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
  state?: Types.InputMaybe<Types.InvoiceFilterState>;
  billingAdmin?: Types.InputMaybe<Types.Scalars['String']>;
  projectManager?: Types.InputMaybe<Types.Scalars['String']>;
  projectSupervisor?: Types.InputMaybe<Types.Scalars['String']>;
  customerNumber?: Types.InputMaybe<Types.Scalars['String']>;
  projectNumber?: Types.InputMaybe<Types.Scalars['Int']>;
  startDate?: Types.InputMaybe<Types.Scalars['String']>;
  endDate?: Types.InputMaybe<Types.Scalars['String']>;
  actualDate?: Types.InputMaybe<Types.Scalars['String']>;
  startCancelledDate?: Types.InputMaybe<Types.Scalars['String']>;
  endCancelledDate?: Types.InputMaybe<Types.Scalars['String']>;
  actualCancelledDate?: Types.InputMaybe<Types.Scalars['String']>;
  amount?: Types.InputMaybe<Types.Scalars['Decimal']>;
  officeCode?: Types.InputMaybe<Types.Scalars['String']>;
  locationState?: Types.InputMaybe<Types.Scalars['String']>;
  afeWoPo?: Types.InputMaybe<Types.Scalars['String']>;
  invoiceNumbers?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.InvoiceSortBy>;
  groupName?: Types.InputMaybe<Types.Scalars['String']>;
  groupCustomerNumber?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AllFilteredInvoicesQueryQuery = { __typename?: 'QueryModel', invoices?: { __typename?: 'InvoicesQueryModel', allFilteredInvoices?: Array<{ __typename?: 'Invoice', invoiceNumber: string, projectNumber: number, state: Types.InvoiceState, notes?: string | null, invoiceDate?: string | null, projectName: string, customerName: string, customerNumber: string, corpLocationId: string, divisionId: string, officeCode: string, total: number, taxDescription: string, canPostToRollup: boolean, lineItems?: Array<{ __typename?: 'InvoiceLineItem', invoiceNumber: string, projectChargeId: number, label: string, notes?: string | null, rate: number, quantity: number, billable: boolean, date: string, projectCharge?: { __typename?: 'ProjectCharge', crewCode: string, employee?: { __typename?: 'Employee', ultiProId: string } | null } | null } | null> | null, project?: { __typename?: 'Project', number: number, projectGroup?: { __typename?: 'ProjectGroup', name: string, id: number } | null } | null } | null> | null } | null };


export const AllFilteredInvoicesQueryDocument = gql`
    query AllFilteredInvoicesQuery($searchText: String, $state: InvoiceFilterState, $billingAdmin: String, $projectManager: String, $projectSupervisor: String, $customerNumber: String, $projectNumber: Int, $startDate: String, $endDate: String, $actualDate: String, $startCancelledDate: String, $endCancelledDate: String, $actualCancelledDate: String, $amount: Decimal, $officeCode: String, $locationState: String, $afeWoPo: String, $invoiceNumbers: [String!], $sortBy: InvoiceSortBy, $groupName: String, $groupCustomerNumber: String) {
  invoices {
    allFilteredInvoices(
      searchText: $searchText
      state: $state
      projectNumber: $projectNumber
      startDate: $startDate
      actualDate: $actualDate
      startCancelledDate: $startCancelledDate
      actualCancelledDate: $actualCancelledDate
      billingAdmin: $billingAdmin
      projectManager: $projectManager
      projectSupervisor: $projectSupervisor
      customerNumber: $customerNumber
      amount: $amount
      endDate: $endDate
      endCancelledDate: $endCancelledDate
      locationState: $locationState
      afeWoPo: $afeWoPo
      officeCode: $officeCode
      invoiceNumbers: $invoiceNumbers
      sortBy: $sortBy
      groupName: $groupName
      groupCustomerNumber: $groupCustomerNumber
    ) {
      invoiceNumber
      projectNumber
      state
      notes
      invoiceDate
      lineItems {
        invoiceNumber
        projectChargeId
        label
        notes
        rate
        quantity
        billable
        date
        projectCharge {
          employee {
            ultiProId
          }
          crewCode
        }
      }
      projectName
      customerName
      customerNumber
      corpLocationId
      divisionId
      officeCode
      project {
        number
        projectGroup {
          name
          id
        }
      }
      total
      taxDescription
      canPostToRollup
    }
  }
}
    `;

/**
 * __useAllFilteredInvoicesQueryQuery__
 *
 * To run a query within a React component, call `useAllFilteredInvoicesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFilteredInvoicesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFilteredInvoicesQueryQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      state: // value for 'state'
 *      billingAdmin: // value for 'billingAdmin'
 *      projectManager: // value for 'projectManager'
 *      projectSupervisor: // value for 'projectSupervisor'
 *      customerNumber: // value for 'customerNumber'
 *      projectNumber: // value for 'projectNumber'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      actualDate: // value for 'actualDate'
 *      startCancelledDate: // value for 'startCancelledDate'
 *      endCancelledDate: // value for 'endCancelledDate'
 *      actualCancelledDate: // value for 'actualCancelledDate'
 *      amount: // value for 'amount'
 *      officeCode: // value for 'officeCode'
 *      locationState: // value for 'locationState'
 *      afeWoPo: // value for 'afeWoPo'
 *      invoiceNumbers: // value for 'invoiceNumbers'
 *      sortBy: // value for 'sortBy'
 *      groupName: // value for 'groupName'
 *      groupCustomerNumber: // value for 'groupCustomerNumber'
 *   },
 * });
 */
export function useAllFilteredInvoicesQueryQuery(baseOptions?: Apollo.QueryHookOptions<AllFilteredInvoicesQueryQuery, AllFilteredInvoicesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllFilteredInvoicesQueryQuery, AllFilteredInvoicesQueryQueryVariables>(AllFilteredInvoicesQueryDocument, options);
      }
export function useAllFilteredInvoicesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllFilteredInvoicesQueryQuery, AllFilteredInvoicesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllFilteredInvoicesQueryQuery, AllFilteredInvoicesQueryQueryVariables>(AllFilteredInvoicesQueryDocument, options);
        }
export type AllFilteredInvoicesQueryQueryHookResult = ReturnType<typeof useAllFilteredInvoicesQueryQuery>;
export type AllFilteredInvoicesQueryLazyQueryHookResult = ReturnType<typeof useAllFilteredInvoicesQueryLazyQuery>;
export type AllFilteredInvoicesQueryQueryResult = Apollo.QueryResult<AllFilteredInvoicesQueryQuery, AllFilteredInvoicesQueryQueryVariables>;