import { Body1, Body2 } from "@material/react-typography";
import React from "react";
import Button from "~/button";
import cn from "classnames";
import "./SplitRowWrapper.scss";

export type SplitRowWrapperProps = {
  total: string;
  splitIndex?: number;
  onDelete?: () => void;
};

export const SplitRowWrapper: React.FC<SplitRowWrapperProps> = ({
  total,
  splitIndex,
  onDelete,
  children,
}) => {
  const label = splitIndex ? `Split ${splitIndex}` : "Original Charge";

  return (
    <div className="split-row-wrapper">
      <div className="split-row-info">
        <Body1 className={"text"}>{label}</Body1>
        <Body2 className="total-text">Total: {total}</Body2>
      </div>

      <div className="border">
        <div className={cn("fields", { original: !splitIndex })}>
          {children}

          {onDelete && (
            <Button
              className="delete-button"
              icon="delete"
              onClick={onDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
};
