import React, { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import { cloneExpenseRateSheet, createExpenseRateSheet } from "~/routes";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import Card from "@material/react-card";
import ExpenseRateSheets from "./ExpenseRateSheets";
import NewExpenseRateSheet from "../NewExpenseRateSheet";
import CloneExpenseRateSheet from "../CloneExpenseRateSheet";
import { ExpenseRateSheet, ValidationError } from "~/gql/types";
import { useExpenseRatesQueryQuery } from "./query.generated";
import { RateSheetsContext } from "~/visuals/organisms/RateSheets/rateSheetsContext";
import { useArchiveExpenseRateSheetMutation } from "./archiveExpenseSheet.generated";
import { useCreateExpenseRateSheetMutation } from "./mutation.generated";
import { useCloneExpenseRateSheetMutation } from "./clone.generated";

type RouteProps = {
  rateSheets: ExpenseRateSheet[];
};

const Loading = () => (
  <Grid>
    <Row>
      <Cell columns={12}>
        <Card>loading...</Card>
      </Cell>
    </Row>
  </Grid>
);

export const ExpenseSheetsPageRoutes: React.FC<RouteProps> = ({
  rateSheets,
}) => {
  return (
    <Routes>
      <Route
        path={createExpenseRateSheet.subPath}
        element={<NewExpenseRateSheet />}
      />
      <Route path={cloneExpenseRateSheet.subPath}>
        <Route path={":name"} element={<CloneExpenseRateSheet />} />
      </Route>
      <Route
        path={"*"}
        element={<ExpenseRateSheets rateSheets={rateSheets} />}
      />
    </Routes>
  );
};

export default function ExpenseRateSheetsRouter(): ReactElement {
  const { data, loading, refetch: reload } = useExpenseRatesQueryQuery();

  const rateSheets = (data && data?.expenseRates?.rateSheets) ?? [];

  const [doArchive, { loading: archiveLoading }] =
    useArchiveExpenseRateSheetMutation();

  const [doCreate, { loading: createLoading }] =
    useCreateExpenseRateSheetMutation();
  const [doClone, { loading: cloneLoading }] =
    useCloneExpenseRateSheetMutation();

  const create = async (name: string) => {
    const variables = { name };
    const result = await doCreate({ variables });

    return result;
  };

  const clone = async (newName: string, oldName: string) => {
    const variables = { newName, oldName };
    const result = await doClone({ variables });
    return result;
  };

  const archive = async (name: string) => {
    const variables = { rateSheetName: name };
    const result = await doArchive({ variables });

    return (result?.errors ??
      result?.data?.expenseRates?.archiveRateSheet?.errors ??
      []) as ValidationError[];
  };

  if (data && rateSheets && !loading) {
    const api = {
      reload,
      archive,
      create,
      clone,
      mutationLoading: archiveLoading || createLoading || cloneLoading,
    };

    return (
      <RateSheetsContext.Provider value={api}>
        <ExpenseSheetsPageRoutes
          rateSheets={rateSheets as ExpenseRateSheet[]}
        />
      </RateSheetsContext.Provider>
    );
  }

  return <Loading />;
}
