import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSingleCustomerQueryVariables = Types.Exact<{
  customerNumber: Types.Scalars['String'];
}>;


export type GetSingleCustomerQuery = { __typename?: 'QueryModel', customers?: { __typename?: 'CustomersQueryModel', single?: { __typename?: 'Customer', name: string, number?: string | null, address1: string, address2: string, city: string, state: string, zip: string, country: string, phone: string, fax: string, notes: string, subAccountOf?: string | null, attn?: string | null, nmTaxable: boolean, txTaxable: boolean, canManageCri: boolean, electronicInvoicingSystem?: Types.ElectronicInvoicingSystem | null, requester?: { __typename?: 'Employee', firstName: string, lastName: string, userPrincipalName: string } | null, projectGroups?: Array<{ __typename?: 'ProjectGroup', name: string } | null> | null, billingAdmins?: Array<{ __typename?: 'Employee', firstName: string, lastName: string, userPrincipalName: string } | null> | null, badDebtWriteOffs?: Array<{ __typename?: 'BadDebtWriteOff', date: string, amount: number } | null> | null } | null } | null };


export const GetSingleCustomerDocument = gql`
    query getSingleCustomer($customerNumber: String!) {
  customers {
    single(customerNumber: $customerNumber) {
      name
      number
      address1
      address2
      city
      state
      zip
      country
      phone
      fax
      notes
      subAccountOf
      requester {
        firstName
        lastName
        userPrincipalName
      }
      attn
      nmTaxable
      txTaxable
      projectGroups {
        name
      }
      canManageCri
      billingAdmins {
        firstName
        lastName
        userPrincipalName
      }
      badDebtWriteOffs {
        date
        amount
      }
      electronicInvoicingSystem
    }
  }
}
    `;

/**
 * __useGetSingleCustomerQuery__
 *
 * To run a query within a React component, call `useGetSingleCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleCustomerQuery({
 *   variables: {
 *      customerNumber: // value for 'customerNumber'
 *   },
 * });
 */
export function useGetSingleCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetSingleCustomerQuery, GetSingleCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleCustomerQuery, GetSingleCustomerQueryVariables>(GetSingleCustomerDocument, options);
      }
export function useGetSingleCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleCustomerQuery, GetSingleCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleCustomerQuery, GetSingleCustomerQueryVariables>(GetSingleCustomerDocument, options);
        }
export type GetSingleCustomerQueryHookResult = ReturnType<typeof useGetSingleCustomerQuery>;
export type GetSingleCustomerLazyQueryHookResult = ReturnType<typeof useGetSingleCustomerLazyQuery>;
export type GetSingleCustomerQueryResult = Apollo.QueryResult<GetSingleCustomerQuery, GetSingleCustomerQueryVariables>;