import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubmitWorkWeekMutationVariables = Types.Exact<{
  weekBeginning: Types.Scalars['LocalDate'];
  userPrincipalName: Types.Scalars['String'];
}>;


export type SubmitWorkWeekMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', submitWorkWeek?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type RejectWorkWeekMutationVariables = Types.Exact<{
  weekBeginning: Types.Scalars['LocalDate'];
  userPrincipalName: Types.Scalars['String'];
  reason: Types.Scalars['String'];
}>;


export type RejectWorkWeekMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', rejectWorkWeek?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type ApproveWorkWeekMutationVariables = Types.Exact<{
  weekBeginning: Types.Scalars['LocalDate'];
  userPrincipalName: Types.Scalars['String'];
}>;


export type ApproveWorkWeekMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', approveWorkWeek?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type ResetWorkWeekMutationVariables = Types.Exact<{
  weekBeginning: Types.Scalars['LocalDate'];
  userPrincipalName: Types.Scalars['String'];
}>;


export type ResetWorkWeekMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', resetWorkWeek?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const SubmitWorkWeekDocument = gql`
    mutation SubmitWorkWeek($weekBeginning: LocalDate!, $userPrincipalName: String!) {
  timesheets {
    submitWorkWeek(
      weekBeginning: $weekBeginning
      userPrincipalName: $userPrincipalName
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type SubmitWorkWeekMutationFn = Apollo.MutationFunction<SubmitWorkWeekMutation, SubmitWorkWeekMutationVariables>;

/**
 * __useSubmitWorkWeekMutation__
 *
 * To run a mutation, you first call `useSubmitWorkWeekMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitWorkWeekMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitWorkWeekMutation, { data, loading, error }] = useSubmitWorkWeekMutation({
 *   variables: {
 *      weekBeginning: // value for 'weekBeginning'
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useSubmitWorkWeekMutation(baseOptions?: Apollo.MutationHookOptions<SubmitWorkWeekMutation, SubmitWorkWeekMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitWorkWeekMutation, SubmitWorkWeekMutationVariables>(SubmitWorkWeekDocument, options);
      }
export type SubmitWorkWeekMutationHookResult = ReturnType<typeof useSubmitWorkWeekMutation>;
export type SubmitWorkWeekMutationResult = Apollo.MutationResult<SubmitWorkWeekMutation>;
export type SubmitWorkWeekMutationOptions = Apollo.BaseMutationOptions<SubmitWorkWeekMutation, SubmitWorkWeekMutationVariables>;
export const RejectWorkWeekDocument = gql`
    mutation RejectWorkWeek($weekBeginning: LocalDate!, $userPrincipalName: String!, $reason: String!) {
  timesheets {
    rejectWorkWeek(
      weekBeginning: $weekBeginning
      userPrincipalName: $userPrincipalName
      reason: $reason
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type RejectWorkWeekMutationFn = Apollo.MutationFunction<RejectWorkWeekMutation, RejectWorkWeekMutationVariables>;

/**
 * __useRejectWorkWeekMutation__
 *
 * To run a mutation, you first call `useRejectWorkWeekMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectWorkWeekMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectWorkWeekMutation, { data, loading, error }] = useRejectWorkWeekMutation({
 *   variables: {
 *      weekBeginning: // value for 'weekBeginning'
 *      userPrincipalName: // value for 'userPrincipalName'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useRejectWorkWeekMutation(baseOptions?: Apollo.MutationHookOptions<RejectWorkWeekMutation, RejectWorkWeekMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectWorkWeekMutation, RejectWorkWeekMutationVariables>(RejectWorkWeekDocument, options);
      }
export type RejectWorkWeekMutationHookResult = ReturnType<typeof useRejectWorkWeekMutation>;
export type RejectWorkWeekMutationResult = Apollo.MutationResult<RejectWorkWeekMutation>;
export type RejectWorkWeekMutationOptions = Apollo.BaseMutationOptions<RejectWorkWeekMutation, RejectWorkWeekMutationVariables>;
export const ApproveWorkWeekDocument = gql`
    mutation ApproveWorkWeek($weekBeginning: LocalDate!, $userPrincipalName: String!) {
  timesheets {
    approveWorkWeek(
      weekBeginning: $weekBeginning
      userPrincipalName: $userPrincipalName
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type ApproveWorkWeekMutationFn = Apollo.MutationFunction<ApproveWorkWeekMutation, ApproveWorkWeekMutationVariables>;

/**
 * __useApproveWorkWeekMutation__
 *
 * To run a mutation, you first call `useApproveWorkWeekMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveWorkWeekMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveWorkWeekMutation, { data, loading, error }] = useApproveWorkWeekMutation({
 *   variables: {
 *      weekBeginning: // value for 'weekBeginning'
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useApproveWorkWeekMutation(baseOptions?: Apollo.MutationHookOptions<ApproveWorkWeekMutation, ApproveWorkWeekMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveWorkWeekMutation, ApproveWorkWeekMutationVariables>(ApproveWorkWeekDocument, options);
      }
export type ApproveWorkWeekMutationHookResult = ReturnType<typeof useApproveWorkWeekMutation>;
export type ApproveWorkWeekMutationResult = Apollo.MutationResult<ApproveWorkWeekMutation>;
export type ApproveWorkWeekMutationOptions = Apollo.BaseMutationOptions<ApproveWorkWeekMutation, ApproveWorkWeekMutationVariables>;
export const ResetWorkWeekDocument = gql`
    mutation ResetWorkWeek($weekBeginning: LocalDate!, $userPrincipalName: String!) {
  timesheets {
    resetWorkWeek(
      weekBeginning: $weekBeginning
      userPrincipalName: $userPrincipalName
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type ResetWorkWeekMutationFn = Apollo.MutationFunction<ResetWorkWeekMutation, ResetWorkWeekMutationVariables>;

/**
 * __useResetWorkWeekMutation__
 *
 * To run a mutation, you first call `useResetWorkWeekMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetWorkWeekMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetWorkWeekMutation, { data, loading, error }] = useResetWorkWeekMutation({
 *   variables: {
 *      weekBeginning: // value for 'weekBeginning'
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useResetWorkWeekMutation(baseOptions?: Apollo.MutationHookOptions<ResetWorkWeekMutation, ResetWorkWeekMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetWorkWeekMutation, ResetWorkWeekMutationVariables>(ResetWorkWeekDocument, options);
      }
export type ResetWorkWeekMutationHookResult = ReturnType<typeof useResetWorkWeekMutation>;
export type ResetWorkWeekMutationResult = Apollo.MutationResult<ResetWorkWeekMutation>;
export type ResetWorkWeekMutationOptions = Apollo.BaseMutationOptions<ResetWorkWeekMutation, ResetWorkWeekMutationVariables>;