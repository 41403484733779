import React from "react";
import { ProjectOrigination, ProjectRequest } from "~/gql/types";
import { BaseProps, useGetProps } from "../EditProjectComponents";
import { FieldState } from "~/inline-fields-form/types";
import { useEffect } from "react";
import { RequestField } from "~/project-requests/ProjectRequestForm/RequestField";
import {
  EnumType,
  InlineEnumPicker,
} from "~/visuals/organisms/InlineEnumPicker";
import { InlineTextField } from "~/inline-text-field";

type EditProjectOriginationProps = BaseProps & {
  requestFormState?: Record<string, FieldState>;
  otherRequests?: ProjectRequest[];
};

export type ProjectOriginationValue = {
  origination: ProjectOrigination | null;
  name: string | null;
};

export const EditProjectOrigination: React.FC<EditProjectOriginationProps> = (
  props
) => {
  const { error, onChange, onBlur, value, required, disabled, setError } =
    useGetProps<ProjectOriginationValue>(props);

  const { requestFormState, otherRequests, name } = props;

  const fieldValue = value as ProjectOriginationValue;

  const nameLabelMap = {
    [ProjectOrigination.Event]: "Event",
    [ProjectOrigination.BidPublicationService]: "Service",
    [ProjectOrigination.Referral]: "Referral",
  };

  const requiresName =
    fieldValue?.origination &&
    [
      ProjectOrigination.Event,
      ProjectOrigination.BidPublicationService,
      ProjectOrigination.Referral,
    ].includes(fieldValue.origination);

  const nameLabel = requiresName
    ? `${nameLabelMap[fieldValue!.origination!]} Name`
    : "";

  const handleUpdate = () => {
    if (!fieldValue) {
      return;
    }

    if (fieldValue && !fieldValue.origination) {
      onChange(null);
    }

    if (fieldValue?.name && (!fieldValue.origination || !requiresName)) {
      onChange({
        origination: fieldValue.origination || null,
        name: null,
      });
    }

    const originationName = fieldValue.name;

    if (
      requiresName &&
      (originationName === null ||
        originationName === undefined ||
        originationName === "")
    ) {
      setError("projectOrigination", "Required");
    }
  };

  const onNameBlur = () => {
    handleUpdate();
  };

  useEffect(() => {
    handleUpdate();
  }, [fieldValue]);

  const originationProps = {
    ...props,
    enumType: "ProjectOrigination",
    disabled,
    required,
    value: value ? { name: value.origination, description: "" } : null,
    onChange: (value: EnumType | null) =>
      onChange({
        ...fieldValue,
        origination: (value?.name as ProjectOrigination) ?? null,
      }),
    submitError: error ?? "",
    onBlur,
  };

  return (
    <>
      {requestFormState && (
        <>
          <RequestField
            {...{
              name,
              label: "Project Origination",
              otherRequests,
              formState: requestFormState,
            }}
          >
            <InlineEnumPicker {...originationProps} />
          </RequestField>
        </>
      )}

      {!requestFormState && (
        <>
          <dt>Project Origination</dt>
          <dd>
            <InlineEnumPicker {...originationProps} />
          </dd>
        </>
      )}

      {requiresName && (
        <>
          <dt>{nameLabel}</dt>
          <dd>
            <InlineTextField
              {...{
                disabled,
                name,
                required: true,
                value: value ? value.name || "" : "",
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange({
                    ...fieldValue,
                    name: e.target.value,
                  });
                },
                errorMessage: error ?? "",
                onBlur: onNameBlur,
              }}
            />
          </dd>
        </>
      )}
    </>
  );
};
