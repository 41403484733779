import { Moment } from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormBottomRow from "~/form-bottom-row";
import FormCard from "~/form-card";
import { ClientForm } from "~/forms/ClientForm";
import { ErrorRenderer } from "~/forms/MutationForm";
import PageTitleRow from "~/page-title-row";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import Spinner from "~/spinner";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";

export const PerformanceStats: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const downloadReport = useReportFetcher();
  const navigate = useNavigate();

  const getPdf = async (date: Moment) => {
    await downloadReport({
      setLoading,
      path: "performance-stats",
      fileName: `performance-stats-${date.format("MM-DD-YYYY")}`,
      body: { date: date.format("YYYY-MM-DD") },
    });
  };

  const onSuccess = async (values: { date: Moment | null }) => {
    await getPdf(values.date!);
  };

  return (
    <div>
      <PageTitleRow title="Performance Stats Report" />
      <FormCard>
        <ClientForm
          {...{
            initialValues: { date: null },
            onSuccess,
          }}
        >
          <DatePickFormField
            formField={"date"}
            label={"Date"}
            helperText={""}
            required
          />
          <ErrorRenderer
            render={(error) => (
              <FormBottomRow
                errorMessage={error}
                buttonText={"Get Report"}
                onCancel={() => navigate("/")}
              />
            )}
          />
        </ClientForm>
      </FormCard>
      <Spinner open={loading} />
    </div>
  );
};
