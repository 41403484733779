import React from "react";
import "./SearchListContent.scss";
import cn from "classnames";
import { InlineProgress } from "~/inline-progress";

export type SearchListPageProps = {
  loading: boolean;
  loadingMore?: boolean;
  className?: string;
  actions?: React.ReactNode;
};

export const SearchListContent: React.FC<SearchListPageProps> = ({
  children,
  loading,
  loadingMore,
  className,
  actions,
}) => {
  return (
    <div
      className={cn(
        "search-list-content",
        { "with-actions": !!actions },
        className
      )}
    >
      {actions && <div>{actions}</div>}
      <div className="content">
        <div
          className={cn("loading", { visible: loading })}
          data-visible={loading ? "true" : "false"}
        >
          <InlineProgress />
        </div>

        {children}

        <div
          className={cn("loading-more", { visible: loadingMore })}
          data-visible={loadingMore ? "true" : "false"}
        >
          <InlineProgress />
        </div>
      </div>

      <div className={cn("overlay", { visible: loading || loadingMore })} />
    </div>
  );
};

export default SearchListContent;
