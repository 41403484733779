import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditProjectRequestMutationVariables = Types.Exact<{
  notes: Types.Scalars['String'];
  requestId: Types.Scalars['String'];
  name: Types.Scalars['String'];
  clientContactName: Types.Scalars['String'];
  projectManager: Types.Scalars['String'];
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  officeCode: Types.Scalars['String'];
  serviceDescription: Types.Scalars['String'];
  governmentFunding: Types.GovernmentFundingType;
  taxableServices: Types.Scalars['Boolean'];
  jobsFolderRequired: Types.Scalars['Boolean'];
  section?: Types.InputMaybe<Types.Scalars['String']>;
  township?: Types.InputMaybe<Types.Scalars['String']>;
  range?: Types.InputMaybe<Types.Scalars['String']>;
  block?: Types.InputMaybe<Types.Scalars['String']>;
  survey?: Types.InputMaybe<Types.Scalars['String']>;
  geographicLocation?: Types.InputMaybe<Types.Scalars['String']>;
  afe?: Types.InputMaybe<Types.Scalars['String']>;
  po?: Types.InputMaybe<Types.Scalars['String']>;
  wo?: Types.InputMaybe<Types.Scalars['String']>;
  costCenter?: Types.InputMaybe<Types.Scalars['String']>;
  glCode?: Types.InputMaybe<Types.Scalars['String']>;
  contractNumber?: Types.InputMaybe<Types.Scalars['String']>;
  requisitioner?: Types.InputMaybe<Types.Scalars['String']>;
  budget?: Types.InputMaybe<Types.Scalars['Decimal']>;
  projectInstructions?: Types.InputMaybe<Types.Scalars['String']>;
  coGo?: Types.InputMaybe<Types.Scalars['String']>;
  fieldInstructions?: Types.InputMaybe<Types.Scalars['String']>;
  fieldMeetInfo?: Types.InputMaybe<Types.Scalars['String']>;
  fieldContact?: Types.InputMaybe<Types.Scalars['String']>;
  officeInstructions?: Types.InputMaybe<Types.Scalars['String']>;
  invoiceSubmitEmail?: Types.InputMaybe<Types.Scalars['String']>;
  invoiceContact?: Types.InputMaybe<Types.Scalars['String']>;
  additionalComments?: Types.InputMaybe<Types.Scalars['String']>;
  scope: Types.Scalars['String'];
  customer?: Types.InputMaybe<Types.ProjectRequestCustomerArgsInput>;
  certifiedPayroll?: Types.InputMaybe<Types.Scalars['Boolean']>;
  projectOrigination: Types.ProjectOrigination;
  projectOriginationName?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EditProjectRequestMutation = { __typename?: 'MutationModel', projectRequests?: { __typename?: 'ProjectRequestMutationModel', edit?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const EditProjectRequestDocument = gql`
    mutation EditProjectRequest($notes: String!, $requestId: String!, $name: String!, $clientContactName: String!, $projectManager: String!, $state: String!, $county: String!, $officeCode: String!, $serviceDescription: String!, $governmentFunding: GovernmentFundingType!, $taxableServices: Boolean!, $jobsFolderRequired: Boolean!, $section: String, $township: String, $range: String, $block: String, $survey: String, $geographicLocation: String, $afe: String, $po: String, $wo: String, $costCenter: String, $glCode: String, $contractNumber: String, $requisitioner: String, $budget: Decimal, $projectInstructions: String, $coGo: String, $fieldInstructions: String, $fieldMeetInfo: String, $fieldContact: String, $officeInstructions: String, $invoiceSubmitEmail: String, $invoiceContact: String, $additionalComments: String, $scope: String!, $customer: ProjectRequestCustomerArgsInput, $certifiedPayroll: Boolean, $projectOrigination: ProjectOrigination!, $projectOriginationName: String) {
  projectRequests {
    edit(
      notes: $notes
      requestId: $requestId
      name: $name
      clientContactName: $clientContactName
      projectManager: $projectManager
      state: $state
      county: $county
      officeCode: $officeCode
      serviceDescription: $serviceDescription
      governmentFunding: $governmentFunding
      taxableServices: $taxableServices
      jobsFolderRequired: $jobsFolderRequired
      section: $section
      township: $township
      range: $range
      block: $block
      survey: $survey
      geographicLocation: $geographicLocation
      afe: $afe
      po: $po
      wo: $wo
      costCenter: $costCenter
      glCode: $glCode
      contractNumber: $contractNumber
      requisitioner: $requisitioner
      budget: $budget
      projectInstructions: $projectInstructions
      coGo: $coGo
      fieldInstructions: $fieldInstructions
      fieldMeetInfo: $fieldMeetInfo
      fieldContact: $fieldContact
      officeInstructions: $officeInstructions
      invoiceSubmitEmail: $invoiceSubmitEmail
      invoiceContact: $invoiceContact
      additionalComments: $additionalComments
      scope: $scope
      customer: $customer
      certifiedPayroll: $certifiedPayroll
      projectOrigination: $projectOrigination
      projectOriginationName: $projectOriginationName
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditProjectRequestMutationFn = Apollo.MutationFunction<EditProjectRequestMutation, EditProjectRequestMutationVariables>;

/**
 * __useEditProjectRequestMutation__
 *
 * To run a mutation, you first call `useEditProjectRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectRequestMutation, { data, loading, error }] = useEditProjectRequestMutation({
 *   variables: {
 *      notes: // value for 'notes'
 *      requestId: // value for 'requestId'
 *      name: // value for 'name'
 *      clientContactName: // value for 'clientContactName'
 *      projectManager: // value for 'projectManager'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      officeCode: // value for 'officeCode'
 *      serviceDescription: // value for 'serviceDescription'
 *      governmentFunding: // value for 'governmentFunding'
 *      taxableServices: // value for 'taxableServices'
 *      jobsFolderRequired: // value for 'jobsFolderRequired'
 *      section: // value for 'section'
 *      township: // value for 'township'
 *      range: // value for 'range'
 *      block: // value for 'block'
 *      survey: // value for 'survey'
 *      geographicLocation: // value for 'geographicLocation'
 *      afe: // value for 'afe'
 *      po: // value for 'po'
 *      wo: // value for 'wo'
 *      costCenter: // value for 'costCenter'
 *      glCode: // value for 'glCode'
 *      contractNumber: // value for 'contractNumber'
 *      requisitioner: // value for 'requisitioner'
 *      budget: // value for 'budget'
 *      projectInstructions: // value for 'projectInstructions'
 *      coGo: // value for 'coGo'
 *      fieldInstructions: // value for 'fieldInstructions'
 *      fieldMeetInfo: // value for 'fieldMeetInfo'
 *      fieldContact: // value for 'fieldContact'
 *      officeInstructions: // value for 'officeInstructions'
 *      invoiceSubmitEmail: // value for 'invoiceSubmitEmail'
 *      invoiceContact: // value for 'invoiceContact'
 *      additionalComments: // value for 'additionalComments'
 *      scope: // value for 'scope'
 *      customer: // value for 'customer'
 *      certifiedPayroll: // value for 'certifiedPayroll'
 *      projectOrigination: // value for 'projectOrigination'
 *      projectOriginationName: // value for 'projectOriginationName'
 *   },
 * });
 */
export function useEditProjectRequestMutation(baseOptions?: Apollo.MutationHookOptions<EditProjectRequestMutation, EditProjectRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditProjectRequestMutation, EditProjectRequestMutationVariables>(EditProjectRequestDocument, options);
      }
export type EditProjectRequestMutationHookResult = ReturnType<typeof useEditProjectRequestMutation>;
export type EditProjectRequestMutationResult = Apollo.MutationResult<EditProjectRequestMutation>;
export type EditProjectRequestMutationOptions = Apollo.BaseMutationOptions<EditProjectRequestMutation, EditProjectRequestMutationVariables>;