import React, { useEffect } from "react";
import { Action, State } from "../stateMachine";
import { silentPopupAuthRefresh } from "~/routes";

type RefreshIframe = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

export const RefreshIframe: React.FC<RefreshIframe> = ({ state, dispatch }) => {
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (state.tag === "IframeOpen") {
      timer = setTimeout(() => {
        dispatch({ tag: "Timeout" });
      }, 1000 * 30);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [state.tag]);

  const newUrl = new URL(window.location.href);
  newUrl.pathname = silentPopupAuthRefresh.path;
  newUrl.hash = "";

  return (
    <>
      {state.tag === "IframeOpen" && window.self == window.parent && (
        <iframe
          width={0}
          height={0}
          id="refresh-iframe"
          src={newUrl.href}
          style={{ margin: 0, padding: 0, position: "absolute" }}
        />
      )}
    </>
  );
};
