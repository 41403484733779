import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { FilterForm, FilterOptions } from "./FilterForm";
import { VisibilityListener } from "~/visibility-listener";
import { SearchFilterContainer } from "~/search-filter-container";
import useInfiniteScroll from "~/search-page-wrapper/infinite-scroll/useInfiniteScroll";
import { ResetSearchItemsProvider } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import { TextHighlighterProvider } from "~/text-highlighter/TextHighlighterProvider";
import { RoleAccordion, RoleAssignment } from "~/role-accordion/RoleAccordion";
import { ClientForm } from "~/forms/ClientForm";
import { EmployeePicker } from "~/visuals/organisms/EmployeePickerFormField";
import { ValueRenderer } from "~/forms/ValueContext";
import Updater from "~/forms/Updater";
import Spinner from "~/spinner";
import { useRolesSearchQuery } from "./query.generated";
import { useAddRole } from "./useAddRole";
import { useRemoveRole } from "./useRemoveRole";
import { Employee } from "~/gql/types";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";

export type RolesSearchDisplayProps = {
  showSpinner: boolean;
  roles: RoleAssignment[] | null;
  state: State<RoleAssignment, FilterOptions>;
  dispatch: React.Dispatch<Action<RoleAssignment, FilterOptions>>;
};

export const RoleSearchPageDisplay: React.FC<RolesSearchDisplayProps> = (
  props
) => {
  const { showSpinner, roles, state, dispatch } = props;

  const { removeRole, loading } = useRemoveRole();

  return (
    <SearchListContent
      loading={showSpinner || loading}
      loadingMore={state.tag === "LoadingMore"}
    >
      {roles &&
        roles.map((role, idx) => (
          <RoleAccordion
            {...{
              role,
              removeRole,
              AddEmployeeComponent,
            }}
            key={idx}
          />
        ))}
      {state.showVisibility && (
        <VisibilityListener
          onShown={() => dispatch({ tag: "ScrolledToBottom" })}
        />
      )}
    </SearchListContent>
  );
};

export const AddEmployeeComponent: React.FC<{ role: RoleAssignment }> = ({
  role,
}) => {
  const { roleName, employees } = role;
  const { addRole, loading } = useAddRole(roleName);

  const [employee, setEmployee] = useState<Employee | null>(null);

  useEffect(() => {
    if (
      employee &&
      !employees.find(
        (x) =>
          x.userPrincipalName.toLowerCase() ==
          employee.userPrincipalName.toLowerCase()
      )
    ) {
      void addRole(employee);
    }
  }, [employee]);

  return (
    <ClientForm onSuccess={() => undefined} initialValues={{ employee: null }}>
      <EmployeePicker
        formField={"employee"}
        label={"Employee"}
        helperText={""}
      />
      <ValueRenderer
        render={(values) => (
          <Updater value={values.employee} callback={setEmployee} />
        )}
      />
      <Spinner open={loading} />
    </ClientForm>
  );
};

type LoadRolesProps = {
  dispatch: React.Dispatch<Action<RoleAssignment, FilterOptions>>;
  tag: "DataLoaded" | "MoreDataLoaded";
  state: State<RoleAssignment, FilterOptions>;
};

const LoadRoles = ({ dispatch, tag, state }: LoadRolesProps) => {
  const token = state.searchToken;

  const { searchText, role } = state.filterOptions;

  const variables = {
    searchText: searchText || null,
    roleName: role || null,
    token: token || null,
  };

  const { data } = useRolesSearchQuery({ variables });

  useEffect(() => {
    if (data) {
      const newToken = data?.employees?.roleSearch?.token as string | null;
      const newRoles = data?.employees?.roleSearch?.records as RoleAssignment[];
      dispatch({ tag, items: newRoles, searchToken: newToken });
    }
  }, [data]);

  return <></>;
};

type RolesPageProps = {
  FilterFormComponent?: typeof FilterForm;
};

export const RolesPage: React.FC<RolesPageProps> = (props) => {
  const [filters, setFilters] = useState<FilterOptions | null>(null);
  const FilterFormComponent = props.FilterFormComponent || FilterForm;

  const setFilterOptions = (opts: FilterOptions) => setFilters(opts);

  return (
    <SearchPageWrapper>
      <SearchFilterContainer>
        <FilterFormComponent onFiltersChanged={setFilterOptions} />
      </SearchFilterContainer>
      {filters && <RolesResults {...{ ...props, filters }} />}
    </SearchPageWrapper>
  );
};

export const RolesResults: React.FC<
  RolesPageProps & { filters: FilterOptions }
> = (props) => {
  useBreadcrumbs([{ text: "Employee Roles" }], []);

  const { filters } = props;

  const initialState = {
    tag: "Loading",
    items: [],
    showVisibility: false,
    loading: true,
    filterOptions: filters,
    searchToken: null,
    selectIdKey: "roleName",
    selectedItems: [],
  } as State<RoleAssignment, FilterOptions>;

  const { state, dispatch } = useInfiniteScroll<RoleAssignment, FilterOptions>(
    initialState
  );

  useEffect(() => {
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: filters,
    });
  }, [filters]);

  const reset = () => {
    dispatch({
      tag: "Reset",
      filterOptions: state.filterOptions,
    });
  };

  return (
    <ResetSearchItemsProvider reset={reset}>
      <TextHighlighterProvider searchText={state.filterOptions.searchText}>
        {state.tag === "Loading" && (
          <LoadRoles
            {...{
              dispatch,
              state: { ...state, searchToken: null },
              tag: "DataLoaded",
            }}
          />
        )}
        {state.tag === "LoadingMore" && (
          <LoadRoles
            {...{
              dispatch,
              tag: "MoreDataLoaded",
              state,
            }}
          />
        )}
        <RoleSearchPageDisplay
          {...{
            roles: state.items,
            showSpinner: state.loading,
            state,
            dispatch,
          }}
        />
      </TextHighlighterProvider>
    </ResetSearchItemsProvider>
  );
};
