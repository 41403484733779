import React, { useEffect } from "react";
import { useSnackBar } from "~/snackbar";
import uncaught from "uncaught";
import { useErrorReporter } from "./useErrorReporter";
import _ from "lodash";

export const GlobalErrorHandler: React.FC = () => {
  const addAlert = useSnackBar();
  const reporter = useErrorReporter();

  useEffect(() => {
    const listener = (err) => {
      // known issue with React :-|
      // https://github.com/facebook/react/issues/11499
      if (err.encaughtificated) {
        return;
      }

      if (err.stack && (err.stack as string).indexOf("debug-evaluate") >= 0) {
        return;
      }

      if (!_.isString(err)) {
        err.encaughtificated = true;
      }

      addAlert({
        key: "global-error-handler",
        message: "Whoops! Something went wrong; please try again.",
        isSuccess: false,
      });
      reporter(err);

      return true;
    };

    uncaught.start();
    uncaught.addListener(listener);

    return () => uncaught.flush();
  }, []);
  return null;
};
