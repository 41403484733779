import React from "react";
import WarningMessage from "~/warning-message";
import { Invoice } from "~/gql/types";

export const TaxableWarning: React.FC<{ invoice: Invoice }> = ({ invoice }) => {
  const nonTaxable = invoice
    .lineItems!.filter((x) => !x!.taxable)
    .filter((x) => x!.projectCharge!.type !== "Tax");

  const taxable = invoice
    .lineItems!.filter((x) => x!.taxable)
    .filter((x) => x!.projectCharge!.type !== "Tax");

  const state = invoice.locationState;

  const isNm = state == "NM";
  const isTx = state == "TX";

  if (!isNm && !isTx) {
    return null;
  }

  const custNmTaxable = invoice.project!.customer!.nmTaxable;
  const custTxTaxable = invoice.project!.customer!.txTaxable;

  const custTaxable = (isNm && custNmTaxable) || (isTx && custTxTaxable);

  if (custTaxable && nonTaxable.length > 0 && isNm) {
    const chargeText = nonTaxable.length === 1 ? "charge is" : "charges are";

    return (
      <WarningMessage
        icon="warning"
        message={`WARNING: ${nonTaxable.length} ${chargeText} marked non-taxable`}
      />
    );
  }

  if (!custTaxable && taxable.length > 0) {
    const chargeText = taxable.length === 1 ? "charge is" : "charges are";

    return (
      <WarningMessage
        icon="warning"
        message={`WARNING: ${taxable.length} ${chargeText} marked taxable`}
      />
    );
  }

  return null;
};
