import {
  ChargeFormField,
  useProjectChargeFormField,
} from "~/project-charge-form/fields/fieldUtils";
import { SplitFormData } from "../types";

export function useSplitChargeFormField(
  field: keyof SplitFormData
): ChargeFormField {
  return useProjectChargeFormField(field);
}
