import React from "react";
import { pendingCustomers } from "~/routes";
import TextFormField from "~/text-form-field";
import { Body1 } from "@material/react-typography";
import FormCard from "~/form-card";
import PageTitleRow from "~/page-title-row";
import "./ApproveRejectForm.scss";
import FormBottomRow from "~/form-bottom-row";
import { PendingCustomer } from "~/gql/types";

export type ApproveOrRejectProps = {
  customer: PendingCustomer;
  errorMessage?: string | undefined;
  kind: "Approve" | "Reject";
};

const ApproveRejectFormCard: React.FC<ApproveOrRejectProps> = ({
  customer,
  errorMessage,
  kind,
}) => {
  const { name, address1, address2, city, state, zip, country, subAccountOf } =
    customer;

  return (
    <div className="approve-reject-form">
      <PageTitleRow title={`${kind} Customer`} />
      <FormCard>
        <Body1>
          {name}
          <br />
          {address1}
          <br />
          {address2}
          <br />
          {city}
          {country.length == 0 && (
            <>
              , {state} {zip}
            </>
          )}
          {country?.length && (
            <>
              <br />
              {country}
            </>
          )}
        </Body1>

        {(kind !== "Approve" || !subAccountOf) && (
          <TextFormField
            label={
              kind === "Approve" ? "New Customer Number" : "Rejection Reason"
            }
            helperText={
              kind === "Approve"
                ? "The id number of the new customer. Must be less than or equal to 6 digits"
                : "Enter the reason for rejecting customer."
            }
            formField={kind === "Approve" ? "number" : "reason"}
            required
          />
        )}

        {kind === "Approve" && !!subAccountOf && (
          <Body1>Sub account of {subAccountOf}</Body1>
        )}

        {kind === "Approve" && (
          <TextFormField
            label="Sub Account Number"
            helperText={
              'The sub account number of the new customer. Use "00" if there is not a sub account.'
            }
            formField="subNumber"
            required
          />
        )}

        <FormBottomRow
          errorMessage={errorMessage || null}
          buttonText="Submit"
          route={pendingCustomers}
        />
      </FormCard>
    </div>
  );
};

export default ApproveRejectFormCard;
