import { Dialog, DialogTitle, DialogContent } from "~/dialog";
import React, { useState } from "react";
import ClickSwallower from "~/click-swallower";
import Button from "~/button";
import { ChargeHistory, ChargeHistoryItem } from "./ChargeHistory";
import { SplitFormDialog } from "~/split-charge-form";
import { ProjectCharge } from "~/gql/types";
import cn from "classnames";
import styles from "./ChargeHistoryModal.module.scss";

type ChargeHistoryModalProps = {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  setEditOpen: React.Dispatch<boolean>;
  entry: ProjectCharge;
  addCharge: (charge: ProjectCharge) => void;
  updateCharge: (charge: ProjectCharge) => void;
};

export const ChargeHistoryModal: React.FC<ChargeHistoryModalProps> = ({
  open,
  setOpen,
  setEditOpen,
  entry,
  addCharge,
  updateCharge,
}) => {
  const [splitFormOpen, setSplitFormOpen] = useState(false);

  const outerClassName = cn(styles.ChargeHistoryModal, {
    [styles.SplitFormOpen]: splitFormOpen,
  });

  return (
    <ClickSwallower className="charge-history-modal-container">
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        data-open={open}
        className={outerClassName}
        portalize
      >
        <DialogTitle>Charge History</DialogTitle>
        <DialogContent>
          {open && (
            <ChargeHistory history={entry.history as ChargeHistoryItem[]} />
          )}
          <div className={styles.BottomRow}>
            {entry.canAdjust && (
              <Button
                primary
                onClick={() => {
                  setEditOpen(true);
                }}
              >
                Adjust
              </Button>
            )}
            {entry.canSplit && (
              <SplitFormDialog
                {...{
                  charge: entry,
                  addCharge,
                  updateCharge,
                  open: splitFormOpen,
                  setOpen: setSplitFormOpen,
                }}
              />
            )}
            <Button onClick={() => setOpen(false)}>Close</Button>
          </div>
        </DialogContent>
      </Dialog>
    </ClickSwallower>
  );
};
