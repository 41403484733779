import { useSnackBar } from "~/snackbar";
import { CompanyRole } from "~/role-accordion/RoleAccordion";
import { ValidationError } from "~/gql/types";
import { useResetSearchItems } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import { useRemoveEmployeeRoleMutation } from "./mutations.generated";

type UseRemoveRoleReturn = {
  removeRole: (
    roleName: CompanyRole,
    userPrincipalName: string
  ) => Promise<void>;
  loading: boolean;
};

export function useRemoveRole(): UseRemoveRoleReturn {
  const reset = useResetSearchItems();
  const addAlert = useSnackBar();

  const [remove, { loading }] = useRemoveEmployeeRoleMutation();

  const removeRole = async (
    roleName: CompanyRole,
    userPrincipalName: string
  ) => {
    const variables = { role: roleName, userPrincipalName };

    const response = await remove({ variables });

    const errors = (response?.errors ??
      response?.data?.employees?.removeRole?.errors ??
      []) as ValidationError[];

    if (errors.length > 0) {
      addAlert({
        isSuccess: false,
        message: errors.map((x) => x.message).join(" "),
        key: `${Math.random()}`,
      });
    } else {
      addAlert({
        isSuccess: true,
        message: `Role ${roleName} removed from ${userPrincipalName}`,
        key: `${Math.random()}`,
      });

      reset();
    }
  };

  return { removeRole, loading };
}
