import React from "react";
import { UltraFilter } from "~/ultra-filter";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import { useEmployeeProvider } from "~/filterProviders/employeeProviders";
import { useLocationStateProvider } from "~/filterProviders/useLocationStateProvider";
import { CrewCodesFilter } from "./types";

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<CrewCodesFilter>;
};

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const providers = [
    useEmployeeProvider({
      documentationText: "The crew code's employee",
    }),
    useLocationStateProvider({
      documentationText: "The invoice project location's state.",
      suggestText: "usstate",
      label: "Location State",
    }),
  ] as FilterProvider[];

  const onChanged = (filters: ActiveFilter[], searchText: string) => {
    searchText;

    const newFilters = {
      searchText: searchText || null,
      employee: filters.find((f) => f.type === "employee")?.value || null,
      state: filters.find((f) => f.type === "locationState")?.value || null,
    };

    onFiltersChanged(newFilters);
  };

  return (
    <div className="filter-form">
      <UltraFilter
        {...{
          providers,
          dialogTitle: "Crew Codes Search",
          label: "Type to search crew codes",
          onFiltersChanged: onChanged,
        }}
      />
    </div>
  );
};
