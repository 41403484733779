import React from "react";
import { Dialog, DialogTitle, DialogContent } from "~/dialog";
import FormBottomRow from "~/form-bottom-row";
import ClickSwallower from "~/click-swallower";
import Spinner from "~/spinner";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { CrewCodePickerFormField } from "~/visuals/organisms/CrewCodePickerFormField";
import "./OverheadCrewCodeForm.scss";

type OverheadCrewCodeFormProps = {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  title: string;
  initialValues: Record<string, any>;
  handleSubmit: (args: any) => Promise<any>;
  loading: boolean;
  onSuccess: () => void;
};

export const OverheadCrewCodeForm: React.FC<OverheadCrewCodeFormProps> = (
  props
) => {
  const {
    open,
    setOpen,
    title,
    initialValues,
    handleSubmit,
    loading,
    children,
    onSuccess,
  } = props;

  return (
    <ClickSwallower>
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          data-open={open}
          className="overhead-crewcode-form"
          portalize
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <MutationForm
              runMutation={handleSubmit}
              onSuccess={onSuccess}
              initialValues={initialValues}
            >
              <CrewCodePickerFormField
                formField="crewCode"
                label="Crew Code"
                helperText=""
                required
              />
              {children}
              <ErrorRenderer
                render={(error) => (
                  <FormBottomRow
                    errorMessage={error}
                    buttonText="Save"
                    onCancel={() => {
                      setOpen(false);
                    }}
                  />
                )}
              />
            </MutationForm>
          </DialogContent>
        </Dialog>
      )}
      <Spinner open={loading} />
    </ClickSwallower>
  );
};
