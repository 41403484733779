import React, { useEffect, useState } from "react";
import { FilterForm, FilterOptions } from "./FilterForm";
import { EmployeeCard } from "./EmployeeCard";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import _ from "lodash";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import useInfiniteScroll from "~/search-page-wrapper/infinite-scroll/useInfiniteScroll";
import { VisibilityListener } from "~/visibility-listener";
import { TextHighlighterProvider } from "~/text-highlighter/TextHighlighterProvider";
import { SearchFilterContainer } from "~/search-filter-container";
import { ResetSearchItemsProvider } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import { Employee, EmployeeSortBy, EmployeeStatus } from "~/gql/types";
import { useEmployeeSearchQuery } from "./query.generated";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";

const Breadcrumbs: React.FC = () => {
  useBreadcrumbs([{ text: "Employees" }], []);

  return <></>;
};

type EmployeesPageProps = {
  FilterFormComponent?: typeof FilterForm;
};

export type EmployeesPageDisplayProps = {
  showSpinner: boolean;
  employees: Employee[] | null;
  loadingMore: boolean;
  dispatch: React.Dispatch<Action<Employee, FilterOptions>>;
  state: State<Employee, FilterOptions>;
};

export const _EmployeesPageDisplay: React.FC<EmployeesPageDisplayProps> = ({
  showSpinner,
  employees,
  loadingMore,
  dispatch,
  state,
}) => {
  return (
    <SearchListContent loading={showSpinner} loadingMore={loadingMore}>
      <Breadcrumbs />
      {employees &&
        employees.map((e, idx) => (
          <EmployeeCard employee={e as any} key={idx} />
        ))}
      {state.showVisibility && (
        <VisibilityListener
          onShown={() => dispatch({ tag: "ScrolledToBottom" })}
        />
      )}
    </SearchListContent>
  );
};

type LoadEmployeesProps = {
  dispatch: React.Dispatch<Action<Employee, FilterOptions>>;
  tag: "DataLoaded" | "MoreDataLoaded";
  searchText: string | null;
  token: string | null;
  status: EmployeeStatus | null;
  workLocation: string | null;
  sortBy: EmployeeSortBy | null;
};

const LoadEmployees = ({
  dispatch,
  tag,
  searchText,
  token,
  status,
  workLocation,
  sortBy,
}: LoadEmployeesProps) => {
  const variables = {
    status,
    workLocation,
    searchText,
    sortBy,
    token,
  };

  const { data } = useEmployeeSearchQuery({ variables });

  useEffect(() => {
    if (data) {
      const newToken = data.employees?.search?.token as string | null;
      const newInvoices = data.employees?.search?.records as Employee[];
      dispatch({ tag, items: newInvoices, searchToken: newToken });
    }
  }, [data]);

  return <></>;
};

export const EmployeesPage: React.FC<EmployeesPageProps> = (props) => {
  const [filters, setFilters] = useState<FilterOptions | null>(null);
  const FilterFormComponent = props.FilterFormComponent || FilterForm;

  const setFilterOptions = (opts: FilterOptions) => setFilters(opts);

  return (
    <SearchPageWrapper>
      <SearchFilterContainer>
        <FilterFormComponent onFiltersChanged={setFilterOptions} />
      </SearchFilterContainer>
      {filters && <EmployeesResults {...{ ...props, filters }} />}
    </SearchPageWrapper>
  );
};

export const EmployeesResults: React.FC<
  EmployeesPageProps & { filters: FilterOptions }
> = (props) => {
  const { filters } = props;

  const initialState = {
    tag: "Loading",
    items: [],
    showVisibility: false,
    loading: true,
    filterOptions: filters,
    searchToken: null,
    selectIdKey: "userPrincipalName",
    selectedItems: [],
  } as State<Employee, FilterOptions>;
  const { state, dispatch } = useInfiniteScroll<Employee, FilterOptions>(
    initialState
  );

  useEffect(() => {
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: filters,
    });
  }, [filters]);

  const loadProps = {
    dispatch,
    searchText: state.filterOptions.searchText || null,
    status: state.filterOptions.status || null,
    sortBy: state.filterOptions.sortBy || null,
    workLocation: state.filterOptions.workLocation || null,
  };

  const reset = () => {
    dispatch({
      tag: "Reset",
      filterOptions: state.filterOptions,
    });
  };

  return (
    <ResetSearchItemsProvider reset={reset}>
      <TextHighlighterProvider searchText={state.filterOptions.searchText}>
        {state.tag === "Loading" && (
          <LoadEmployees
            {...{
              ...loadProps,
              tag: "DataLoaded",
              token: null,
            }}
          />
        )}
        {state.tag === "LoadingMore" && (
          <LoadEmployees
            {...{
              ...loadProps,
              tag: "MoreDataLoaded",
              token: state.searchToken,
            }}
          />
        )}

        <_EmployeesPageDisplay
          {...{
            employees: state.items,
            showSpinner: state.loading,
            loadingMore: state.tag === "LoadingMore",
            dispatch,
            state,
          }}
        />
      </TextHighlighterProvider>
    </ResetSearchItemsProvider>
  );
};
