import React from "react";
import "./DescriptionList.scss";

type DescriptionListProps = {
  className?: string;
};

export const DescriptionList: React.FC<DescriptionListProps> = ({
  className,
  children,
}) => (
  <div className={["description-list", className].filter((x) => x).join(" ")}>
    {children}
  </div>
);
