import React from "react";
import { WorkDay as WorkDayComponent } from "~/visuals/organisms/WorkDay";
import moment from "moment";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import Spinner from "~/spinner";
import { parseWorkData } from "../parseWorkData";
import { useWorkDayApi } from "~/WorkDayApi";

const ReviewWorkDay: React.FC = () => {
  const { workDay, breadcrumbs } = useWorkDayApi();
  const { date, employee } = workDay;

  const crumbs = [...breadcrumbs.slice(0, 2)];

  if (breadcrumbs[2]) {
    crumbs.push({ text: breadcrumbs[2].text ?? "" });
  }

  useBreadcrumbs(crumbs, [breadcrumbs]);

  if (!employee) {
    return <Spinner open={true} />;
  }
  const { timeEntryData, timeOffEntryData, projectTimeData, expenseData } =
    parseWorkData(workDay);

  return (
    <>
      <WorkDayComponent
        date={moment(date)}
        time={timeEntryData}
        timeOff={timeOffEntryData}
        projectTime={projectTimeData}
        expenses={expenseData}
        admin={true}
      />
    </>
  );
};

export default ReviewWorkDay;
