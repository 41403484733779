import React from "react";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import { UltraFilter } from "~/ultra-filter";
import { useFieldOfficeProvider } from "~/filterProviders/useFieldOfficeProvider";
import {
  Customer,
  Employee,
  FieldOffice,
  ProjectRequestsFilterState,
} from "~/gql/types";
import { useProjectRequestStateProvider } from "~/project-requests/PendingRequests/useProjectRequestStateProvider";
import { Moment } from "moment";
import { useTextProvider } from "~/filterProviders/useTextProvider";
import { useDateProvider } from "~/filterProviders/useDateProvider";
import { useCustomerProvider } from "~/filterProviders/useCustomerProvider";
import {
  useEmployeeProvider,
  useProjectManagerProvider,
} from "~/filterProviders/employeeProviders";

export type FilterOptions = {
  searchText: string | null;
  state: ProjectRequestsFilterState | null;
  officeCode: FieldOffice | null;
  locationState: string | null;
  projectManager: Employee | null;
  date: Moment | null;
  approver: Employee | null;
  customer: Customer | null;
};

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<FilterOptions>;
};

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const providers = [
    useProjectRequestStateProvider({
      documentationText:
        "The project request's state (Approved, Pending, Rejected)",
    }),
    useFieldOfficeProvider({
      documentationText: "The project request's field office",
    }),
    useTextProvider({
      documentationText: "The project request's US state",
      toParam: (x) => x?.value,
      suggestText: "usstate",
      label: "US State",
      type: "locationState",
    }),
    useProjectManagerProvider({
      documentationText: "The project manager assigned to the project request",
    }),
    useDateProvider({
      documentationText: "Search for project requests created on this date",
      type: "date",
      label: "Date",
      suggestText: "date",
    }),
    useEmployeeProvider({
      documentationText: "The employee who approved the request, if approved",
      type: "approver",
      label: "Approver",
    }),
    useCustomerProvider({
      documentationText: "The customer associated with the project request",
    }),
  ] as FilterProvider[];

  const onChanged = (filters: ActiveFilter[], searchText: string) => {
    searchText;

    const newFilters = {
      searchText: searchText || null,
      officeCode: filters.find((f) => f.type === "officeCode")?.value || null,
      state: filters.find((f) => f.type === "state")?.value || null,
      locationState:
        filters.find((f) => f.type === "locationState")?.value || null,
      projectManager:
        filters.find((f) => f.type === "projectManager")?.value || null,
      date: filters.find((f) => f.type === "date")?.value || null,
      approver: filters.find((f) => f.type === "approver")?.value || null,
      customer: filters.find((f) => f.type === "customer")?.value || null,
    };

    onFiltersChanged(newFilters);
  };

  return (
    <div className="filter-form">
      <UltraFilter
        {...{
          providers,
          dialogTitle: "Project Requests Search",
          label: "Type to search project requests",
          onFiltersChanged: onChanged,
        }}
      />
    </div>
  );
};
