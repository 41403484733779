import React from "react";
import * as States from "../states";
import * as Actions from "../actions";
import { InlineTextDisplay } from "./InlineTextDisplay";
import SuggestionsMenu from "~/typeahead/TypeaheadDisplay/SuggestionsMenu";
import { InlineInput } from "~/inline-text-field";

export interface TypeaheadDisplayProps<T> {
  name: string;
  errorMessage: string | null;
  className?: string;
  state: States.State<T>;
  format: (item: T) => string;
  dispatch: React.Dispatch<Actions.Action<T>>;
  onChange: (val: any) => void;
  onBlur?: React.FocusEventHandler<InlineInput>;
  loading?: boolean;
  required?: boolean;
  disabled?: boolean;
}

function getSelectedText<T>(
  state: States.State<T>,
  format: (item: T) => string
) {
  if (state.selection) {
    return format(state.selection);
  }

  return null;
}

const InlineTypeaheadDisplay = <T,>(
  props: TypeaheadDisplayProps<T>
): React.ReactElement => {
  const {
    name,
    errorMessage,
    format,
    state,
    dispatch,
    loading,
    onChange,
    onBlur,
    disabled,
    required,
  } = props;

  const handleFocus = () => {
    dispatch({ tag: "GotFocus" });
  };
  const handleBlur = (e) => {
    if (States.isSuggestionsVisible(state) && state.suggestions.length === 1) {
      onChange(state.suggestions[0]);
    }
    dispatch({ tag: "LostFocus" });
    onBlur && onBlur(e);
  };

  const handleKeyDown = (evt: React.KeyboardEvent) => {
    if (evt.key === "ArrowUp") {
      dispatch({ tag: "MoveHighlightUp" });
    } else if (evt.key === "ArrowDown") {
      dispatch({ tag: "MoveHighlightDown" });
    } else if (evt.key === "Enter") {
      if (States.isSuggestionsVisible(state) && state.suggestions.length > 0) {
        dispatch({
          tag: "Select",
          selection: state.suggestions[state.suggestionIndex],
        });
        onChange(state.suggestions[state.suggestionIndex]);
      }
    } else {
      return;
    }

    evt.preventDefault();
    evt.stopPropagation();
  };

  return (
    <SuggestionsMenu {...{ state, dispatch, format, onChange }}>
      <InlineTextDisplay
        name={name}
        suggestionText={state.text}
        errorMessage={errorMessage ?? ""}
        selectedText={getSelectedText(state, format)}
        onSuggestionTextChanged={(newText) =>
          dispatch({ tag: "TextChanged", text: newText })
        }
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        loading={loading}
        disabled={disabled}
        required={required}
      />
    </SuggestionsMenu>
  );
};

export default InlineTypeaheadDisplay;
