import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "~/filterProviders/types";
import { createSimpleProvider } from "~/filterProviders/createSimpleProvider";
import {
  ReimbursementPicker,
  reimbursementsOptions,
} from "~/visuals/pages/TimesheetReview/ReimbursementPicker";

export function useReimbursementProvider(
  props: FilterProviderProps
): FilterProvider {
  return createSimpleProvider({
    documentationText: props.documentationText,
    type: "reimbursement",
    label: "Reimbursements",
    render: () => (
      <ReimbursementPicker
        formField="reimbursement"
        label="Reimbursements"
        helperText=""
      />
    ),
    suggestText: "reim",
    options: reimbursementsOptions,
  });
}
