import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "~/filterProviders/types";
import { createSimpleProvider } from "~/filterProviders/createSimpleProvider";
import {
  projectStatusOptions,
  ProjectStatusPicker,
} from "~/visuals/pages/Projects/ProjectStatusPicker";

export function useProjectStatusProvider(
  props: FilterProviderProps
): FilterProvider {
  return createSimpleProvider({
    documentationText: props.documentationText,
    type: "status",
    label: "Status",
    render: () => (
      <ProjectStatusPicker formField="status" label="Status" helperText="" />
    ),
    suggestText: "status",
    options: projectStatusOptions,
  });
}
