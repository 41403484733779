import React from "react";
import Button from "~/button";
import "./AddChargeButton.scss";
import cn from "classnames";

type AddChargeButtonProps = {
  setOpen: React.Dispatch<boolean>;
  text?: string;
};

export const AddChargeButton: React.FC<AddChargeButtonProps> = ({
  setOpen,
  text,
}) => (
  <Button
    className={cn("add-charge-button", { text })}
    outlined
    onClick={() => setOpen(true)}
    icon="add"
    title="add charge to project"
  >
    {text ?? ""}
  </Button>
);
