import React, { useEffect, useMemo, useState } from "react";
import { AppNotificationDisplay } from "./NotificationDisplay";
import { GetNotificationDocument } from "./query.generated";
import {
  useMarkAllReadNotificationMutation,
  useMarkReadNotificationMutation,
} from "./mutation.generated";
import { AppNotification, NotificationPathMap } from "./type";
import { projectRequests } from "~/project-requests/routes";
import { customer, project, thirdPartyInvoice } from "~/routes";
import { createBroadcastChannel } from "~/broadcast-channel/createBroadcastChannel";
import { v4 } from "uuid";
import { useApolloClient } from "@apollo/client";

const pathMap: NotificationPathMap = {
  ProjectRequestCreated: (id: string) => `${projectRequests}/${id}`,
  NewCustomer: (id: string) => customer.toRoute(id).path,
  BaAssigned: (id: string) => project.toRoute(+id).path,
  CustomerRequestRejected: (id: string) => `${projectRequests}/${id}`,
  ProjectRequestCreatedWithBadDebt: (id: string) => `${projectRequests}/${id}`,
  ThirdPartyInvoiceCreated: (id: string) => thirdPartyInvoice.toRoute(id).path,
};

export const notificationTrackerChannel = "notification-tracking";
const originatorKey = v4();

type NotificationState = {
  notifications: AppNotification[] | null;
  originator: string;
  count: number;
};

export const Notifications: React.FC = () => {
  const [notificationState, setNotificationState] = useState<NotificationState>(
    {
      notifications: null,
      originator: originatorKey,
      count: 0,
    }
  );

  const [doMutation] = useMarkReadNotificationMutation();
  const [clearAll] = useMarkAllReadNotificationMutation();

  const client = useApolloClient();

  const channel = useMemo(
    () => createBroadcastChannel(notificationTrackerChannel),
    []
  );

  const fetchData = async (): Promise<NotificationState> => {
    const response = await client.query({
      query: GetNotificationDocument,
    });

    const data = response?.data?.notifications?.getNotifications;

    const newState = {
      ...notificationState,
      notifications: (data?.notifications ?? []) as AppNotification[],
      count: data?.count,
    };

    setNotificationState(newState);

    return newState;
  };

  useEffect(() => {
    if (notificationState.notifications === null) {
      void fetchData();
    }

    const timer = setInterval(fetchData, 1000 * 60);

    channel.onmessage = (msg) => {
      const payload = (msg.data || msg) as NotificationState;

      if (payload.originator === originatorKey) {
        return;
      }

      setNotificationState((state) => ({
        ...state,
        notifications: payload.notifications,
        count: payload.count,
      }));
    };

    return () => {
      channel.close();
      clearInterval(timer);
    };
  }, [channel]);

  const markRead = async (id: string) => {
    const res = await doMutation({ variables: { notificationId: id } });
    return res;
  };

  const markAllRead = async () => {
    const res = await clearAll({ variables: {} });
    return res;
  };

  const innerRefetch = async () => {
    const newState = await fetchData();
    channel.postMessage(newState);
  };

  return (
    <AppNotificationDisplay
      notifications={notificationState.notifications ?? []}
      markRead={markRead}
      refetch={innerRefetch}
      pathMap={pathMap}
      clearAll={markAllRead}
      count={notificationState.count}
    />
  );
};
