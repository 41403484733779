import React, { useRef } from "react";
import * as States from "../states";
import * as Actions from "../actions";
import { Input } from "@material/react-text-field";
import SuggestionsMenu from "./SuggestionsMenu";
import { PickerWidget } from "~/picker-widget";

type Formatter<T> = (item: T, searchText: string) => React.ReactNode;
type ChipFormatter<T> = (item: T) => string;

export interface TypeaheadDisplayProps<T> {
  label: string;
  helperText: string;
  errorMessage: string | null;
  className?: string;
  state: States.State<T>;
  format: Formatter<T>;
  chipFormat?: ChipFormatter<T>;
  dispatch: React.Dispatch<Actions.Action<T>>;
  isValid: boolean;
  onChange: (val: any) => void;
  loading?: boolean;
  onBlur?: React.DispatchWithoutAction;
  onFocus?: React.DispatchWithoutAction;
  isEmpty?: boolean;
  disabled?: boolean;
}

function getSelectedText<T>(state: States.State<T>, format: Formatter<T>) {
  if (States.isSelected(state)) {
    return format(state.selection, "");
  }

  return null;
}

const TypeaheadDisplay = <T,>(
  props: TypeaheadDisplayProps<T>
): React.ReactElement => {
  const {
    label,
    helperText,
    errorMessage,
    isValid,
    format,
    chipFormat,
    state,
    dispatch,
    loading,
    onBlur,
    onFocus,
    onChange,
    isEmpty,
    disabled,
  } = props;

  const handleFocus = () => {
    dispatch({ tag: "GotFocus" });
    onFocus && onFocus();
  };
  const handleBlur = () => {
    if (
      States.isSuggestionsVisible(state) &&
      state.suggestions.length === 1 &&
      state.text
    ) {
      onChange(state.suggestions[0]);
    }
    dispatch({ tag: "LostFocus" });
    onBlur && onBlur();
  };

  const ref = useRef<Input<HTMLInputElement>>(null);

  const handleKeyDown = (evt: React.KeyboardEvent) => {
    const selectedText = getSelectedText(state, chipFormat ?? format);

    if (evt.key === "ArrowUp") {
      dispatch({ tag: "MoveHighlightUp" });
    } else if (evt.key === "ArrowDown") {
      dispatch({ tag: "MoveHighlightDown" });
    } else if (
      selectedText != null &&
      ["Backspace", "Delete"].includes(evt.key)
    ) {
      dispatch({ tag: "ClearSelection" });
      onChange(null);
      handleFocus();
    } else if (evt.key === "Enter") {
      if (States.isSuggestionsVisible(state) && state.suggestions.length > 0) {
        dispatch({
          tag: "Select",
          selection: state.suggestions[state.suggestionIndex],
          userSelected: true,
        });
        onChange(state.suggestions[state.suggestionIndex]);

        if (ref.current !== null && ref.current.inputElement !== null) {
          ref.current.inputElement.blur();
        }
      }
    } else {
      return;
    }

    evt.preventDefault();
    evt.stopPropagation();
  };

  const handleClear = () => {
    dispatch({ tag: "ClearSelection" });
    onChange(null);
  };

  return (
    <SuggestionsMenu {...{ state, dispatch, format, onChange }}>
      <PickerWidget
        label={label}
        suggestionText={state.text}
        helperText={
          (!isValid || isEmpty) && errorMessage ? errorMessage : helperText
        }
        isValid={isValid}
        selectedText={getSelectedText(state, chipFormat ?? format)}
        onCleared={handleClear}
        onSuggestionTextChanged={(newText) =>
          dispatch({ tag: "TextChanged", text: newText })
        }
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        loading={loading}
        inputRef={ref}
        isEmpty={isEmpty}
        disabled={disabled}
      />
    </SuggestionsMenu>
  );
};

export default TypeaheadDisplay;
