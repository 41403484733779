import { v4 } from "uuid";
import { Authorizations } from "../types";
import { getExpiration } from "../jwtUtils";

type Tagged<T extends string> = { tag: T };

type RemoteValidating = Tagged<"ValidatingGoogle"> | Tagged<"ValidatingAzure">;

type ValidatingLocal = Tagged<"ValidatingLocal"> & { token: string };

export type State =
  | Tagged<"Unauthenticated">
  | Tagged<"Error">
  | RemoteValidating
  | ValidatingLocal
  | (Tagged<"Authenticated"> & {
      token: string;
      authorizations: Authorizations;
      originator: string;
    });

export type Action =
  | Tagged<"Logout">
  | (Tagged<"Token"> & { token: string })
  | (Tagged<"Authorized"> & { authorizations: Authorizations })
  | Tagged<"Problem">
  | (Tagged<"OtherWindowLoggedIn"> & {
      token: string;
      authorizations: Authorizations;
      originator: string;
    });

export function reducer(state: State, action: Action): State {
  if (action.tag === "OtherWindowLoggedIn") {
    if (state.tag === "Authenticated") {
      const localExp = getExpiration(state.token);
      const remoteExp = getExpiration(action.token);

      if (remoteExp.getTime() <= localExp.getTime()) {
        return state;
      }
    }

    return {
      tag: "Authenticated",
      token: action.token,
      authorizations: action.authorizations,
      originator: action.originator,
    };
  }
  if (action.tag === "Logout") {
    return { tag: "Unauthenticated" };
  }

  if (state.tag === "ValidatingGoogle") {
    return reduceRemoteValidating(state, action);
  }
  if (state.tag === "ValidatingAzure") {
    return reduceRemoteValidating(state, action);
  }
  if (state.tag === "ValidatingLocal") {
    return reduceValidatingLocal(state, action);
  }
  return state;
}

function reduceRemoteValidating(
  state: RemoteValidating,
  action: Action
): State {
  if (action.tag === "Token") {
    return { tag: "ValidatingLocal", token: action.token };
  }
  if (action.tag === "Problem") {
    return { tag: "Error" };
  }
  return state;
}

// this prevents infinite loops of sending messages; we don't publish an OtherWindowLoggedIn if we're not the originator
export const originatorKey = v4();

function reduceValidatingLocal(state: ValidatingLocal, action: Action): State {
  if (action.tag === "Authorized") {
    return {
      tag: "Authenticated",
      token: state.token,
      authorizations: action.authorizations,
      originator: originatorKey,
    };
  }
  return state;
}
