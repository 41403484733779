import React from "react";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import moment, { Moment } from "moment";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";
import CurrencyFormField from "~/visuals/organisms/CurrencyFormField";
import FormBottomRow from "~/form-bottom-row";
import { useResetSearchItems } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import { useSnackBar } from "~/snackbar";
import Spinner from "~/spinner";
import { useEditEmployeeRateMutation } from "./edit.generated";
import { EmployeeRate, ValidationResponse } from "~/gql/types";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";

type EditEmployeeRateProps = {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  rate: EmployeeRate;
};

export const EditEmployeeRate: React.FC<EditEmployeeRateProps> = ({
  open,
  setOpen,
  rate,
}) => {
  const addAlert = useSnackBar();

  const initialValues = {
    effectiveDate: moment(rate.effectiveDate),
    rate: rate.rate.toString(),
  };

  const resetRates = useResetSearchItems();

  const [doMutation, { loading }] = useEditEmployeeRateMutation();

  const handleSubmit = async (values: {
    rate: string;
    effectiveDate: Moment;
  }) => {
    const employee = rate.employee!;

    const variables = {
      ultiProId: employee.ultiProId,
      effectiveDate: values.effectiveDate.format("YYYY-MM-DD"),
      originalEffectiveDate: moment(rate.effectiveDate).format("YYYY-MM-DD"),
      rate: parseFloat(values.rate),
    };

    const result = await doMutation({ variables });
    return result?.data?.employeeRates?.editRate as ValidationResponse;
  };

  const onSuccess = ({ values }) => {
    const employee = rate.employee!;

    setOpen(false);
    addAlert({
      key: `${values.effectiveDate.format("YYYY-MM-DD")}-${employee.ultiProId}`,
      message: `Employee rate edited for ${employee.firstName} ${
        employee.lastName
      } effective ${values.effectiveDate.format("MM/DD/YYYY")}`,
      isSuccess: true,
    });
    resetRates();
  };

  return (
    <Dialog
      className="edit-employee-rate"
      open={open}
      onClose={() => setOpen(false)}
      data-open={open}
      portalize
    >
      <DialogTitle>Edit Employee Rate</DialogTitle>
      <DialogContent>
        <Spinner open={loading} />
        <MutationForm
          onSuccess={onSuccess}
          runMutation={handleSubmit}
          initialValues={initialValues}
          allowPristineSubmit
        >
          <DatePickFormField
            formField="effectiveDate"
            label="Effective Date"
            helperText=""
            required
          />
          <CurrencyFormField
            formField="rate"
            label="Rate"
            helperText=""
            required
          />
          <ErrorRenderer
            render={(submitError) => (
              <FormBottomRow
                errorMessage={submitError}
                buttonText="Save"
                onCancel={() => setOpen(false)}
              />
            )}
          />
        </MutationForm>
      </DialogContent>
    </Dialog>
  );
};
