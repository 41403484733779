import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerSearchHookQueryVariables = Types.Exact<{
  token?: Types.InputMaybe<Types.Scalars['String']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
  includePending?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type CustomerSearchHookQuery = { __typename?: 'QueryModel', customers?: { __typename?: 'CustomersQueryModel', search?: { __typename?: 'ConnectionOfCustomer', token?: string | null, records?: Array<{ __typename?: 'Customer', name: string, number?: string | null, status: Types.CustomerStatus, createCustomerRequestId: string, badDebtWriteOffs?: Array<{ __typename?: 'BadDebtWriteOff', date: string, amount: number } | null> | null } | null> | null } | null } | null };


export const CustomerSearchHookDocument = gql`
    query CustomerSearchHook($token: String, $searchText: String, $includePending: Boolean) {
  customers {
    search(searchText: $searchText, token: $token, includePending: $includePending) {
      token
      records {
        name
        number
        status
        createCustomerRequestId
        badDebtWriteOffs {
          date
          amount
        }
      }
    }
  }
}
    `;

/**
 * __useCustomerSearchHookQuery__
 *
 * To run a query within a React component, call `useCustomerSearchHookQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSearchHookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSearchHookQuery({
 *   variables: {
 *      token: // value for 'token'
 *      searchText: // value for 'searchText'
 *      includePending: // value for 'includePending'
 *   },
 * });
 */
export function useCustomerSearchHookQuery(baseOptions?: Apollo.QueryHookOptions<CustomerSearchHookQuery, CustomerSearchHookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerSearchHookQuery, CustomerSearchHookQueryVariables>(CustomerSearchHookDocument, options);
      }
export function useCustomerSearchHookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerSearchHookQuery, CustomerSearchHookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerSearchHookQuery, CustomerSearchHookQueryVariables>(CustomerSearchHookDocument, options);
        }
export type CustomerSearchHookQueryHookResult = ReturnType<typeof useCustomerSearchHookQuery>;
export type CustomerSearchHookLazyQueryHookResult = ReturnType<typeof useCustomerSearchHookLazyQuery>;
export type CustomerSearchHookQueryResult = Apollo.QueryResult<CustomerSearchHookQuery, CustomerSearchHookQueryVariables>;