export class VersionChecker {
  constructor(
    private fetchFn: typeof window.fetch,
    private onVersionChanged: React.DispatchWithoutAction,
    private interval: number
  ) {
    this.scheduleCheck();
  }
  private scheduleCheck(): void {
    setTimeout(async () => {
      try {
        const serverVersion = await this.getVersion();
        const localVersion = window.config.version;
        if (localVersion !== serverVersion) {
          this.onVersionChanged();
        }
        // eslint-disable-next-line no-empty
      } catch {
      } finally {
        this.scheduleCheck();
      }
    }, this.interval);
  }
  private async getVersion(): Promise<string> {
    const response = await this.fetchFn("/api/version");
    return await response.text();
  }
}
