import { useApolloClient } from "@apollo/client";
import { Customer } from "~/gql/types";
import { CustomerSearchHookDocument } from "./customerSearch.generated";

type CustomerSearchArgs = {
  searchText?: string;
  token?: string;
  includePending?: boolean;
};

export function useCustomerSearch(): (args: CustomerSearchArgs) => Promise<{
  records: Customer[];
  token: string | null;
}> {
  const client = useApolloClient();

  return async (args: CustomerSearchArgs) => {
    const { searchText, token, includePending } = args;

    const variables = {
      searchText,
      token: token || null,
      includePending: includePending || null,
    };

    const result = await client.query({
      query: CustomerSearchHookDocument,
      variables,
    });

    const newToken = result.data?.customers?.search?.token as string | null;
    const records = result.data?.customers?.search?.records as Customer[];

    return { records, token: newToken };
  };
}

export function useCustomerFromParam(): (
  number: string
) => Promise<Customer | null> {
  const getCustomers = useCustomerSearch();

  return async (number: string) => {
    const result = await getCustomers({ searchText: number });
    if (!result?.records || result.records.length === 0) {
      return null;
    }

    return result.records[0] as Customer;
  };
}
