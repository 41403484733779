import React from "react";
import WarningMessage from "~/warning-message";
import Button from "~/button";
import Spinner from "~/spinner";

type LoadAllButtonProps = {
  allSelected: boolean;
  type: string;
  totalCount: number;
  selectedCount: number;
  loading: boolean;
  loadAll: () => Promise<void>;
};

export const LoadAllButton: React.FC<LoadAllButtonProps> = ({
  allSelected,
  type,
  totalCount,
  selectedCount,
  loading,
  loadAll,
}) => (
  <>
    {allSelected && (
      <Button onClick={loadAll}>
        <WarningMessage
          message={`All ${selectedCount} loaded ${type} are selected. Click here to select all ${totalCount} ${type}.`}
          icon={"warning"}
        />
      </Button>
    )}
    <Spinner open={loading} />
  </>
);
