import React, { useState } from "react";
import { ReviewPageState, SingleRequestReviewProps } from "./types";
import { useNavigate, useParams } from "react-router-dom";
import { useCheckout } from "../useCheckout";
import { GroupActions } from "./GroupActions";
import { ProjectRequestForm } from "../ProjectRequestForm";
import { ProjectRequestPage } from "../SingleRequest";
import { requestGroupReview } from "../routes";
import { useGroupReviewContext } from "./groupReviewContext";

type SingleRequestParams = {
  groupId: string;
  index: string;
};

export const SingleRequestReview: React.FC<SingleRequestReviewProps> = (
  props
) => {
  const { _FormComponent, _SinglePageComponent } = props;

  const params = useParams<SingleRequestParams>();

  const paramIndex = params!.index!;

  const [reviewPageState, setReviewPageState] = useState<ReviewPageState>({
    page: +paramIndex - 1,
    viewed: [0],
  });

  const { projectRequests, refetch } = useGroupReviewContext();

  const navigate = useNavigate();

  const actualIndex = +paramIndex - 1;

  const projectRequest = projectRequests[actualIndex];

  const { isCheckerOuter } = useCheckout({ projectRequest });

  if (!projectRequest) {
    return null;
  }

  const RequestFormComponent = _FormComponent ?? ProjectRequestForm;
  const SingleRequestComponent = _SinglePageComponent ?? ProjectRequestPage;

  const redirect = (newPage: number) =>
    navigate(`${requestGroupReview}/${params!.groupId}/${newPage + 1}`);

  const actions = (
    <GroupActions
      {...{
        projectRequests,
        reviewPageState,
        setReviewPageState,
        index: actualIndex,
        refetch: () => Promise.resolve(),
        redirect,
      }}
    />
  );

  const title = `Request ${paramIndex} of ${projectRequests.length}`;

  if (!!projectRequest.checkedOutBy && isCheckerOuter) {
    return (
      <RequestFormComponent
        {...{
          type: "Group",
          projectRequest,
          actions,
          title,
          useReload: () => refetch,
        }}
      />
    );
  }

  return (
    <SingleRequestComponent
      {...{
        projectRequest,
        isGroupReview: true,
        actions,
        title,
        useReload: () => refetch,
      }}
    />
  );
};
