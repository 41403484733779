import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { ProjectChargeSearchFilterState } from "~/gql/types";
import { Suggestion } from "~/ultra-filter/types";

export const chargeStates = [
  { value: "Any", text: "Any" },
  { value: "Archived", text: "Archived" },
  { value: "PrPending", text: "PR Pending" },
  { value: "PmPending", text: "PM Pending" },
  { value: "PsPending", text: "PS Pending" },
  { value: "BaPending", text: "BA Pending" },
  { value: "PostPending", text: "Post Pending" },
  { value: "PostedNotBilled", text: "Posted Not Billed" },
  { value: "Billed", text: "Billed" },
  { value: "Unbilled", text: "Unbilled" },
] as Suggestion[];

const format = (item: ProjectChargeSearchFilterState) =>
  chargeStates.find((s) => s.value === item)?.text;

export const ProjectChargeStatePicker: React.FC<FormFieldProps> = (props) => {
  const getData = async (searchText: string) => {
    if (!searchText) {
      return chargeStates.map((s) => s.value);
    }
    return chargeStates
      .filter(
        (s) =>
          s.value.toLowerCase().startsWith(searchText.toLowerCase()) ||
          s.text.toLowerCase().startsWith(searchText.toLowerCase())
      )
      .map((s) => s.value);
  };

  const args = {
    ...props,
    getData,
    format,
  } as TypeaheadProps<ProjectChargeSearchFilterState>;

  return <TypeaheadFormField {...args} />;
};
