import { useConfirmationPrompt } from "~/confirmation-prompt";
import { formatApplicabilityMessage } from "./utils";
import { ProjectCharge } from "~/gql/types";

export type PromptProps = {
  title: string;
  items: ProjectCharge[];
  selectedCharges: ProjectCharge[];
  actionType: string;
  action: () => void;
};

export function useOpenPrompt(): (props: PromptProps) => Promise<void> {
  const prompt = useConfirmationPrompt();

  return async (props: PromptProps) => {
    const { title, items, actionType, action, selectedCharges } = props;

    const promptResult = await prompt({
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      message: formatApplicabilityMessage(
        items.length,
        selectedCharges.length,
        actionType
      ),
      title,
    });

    if (promptResult !== "Confirm") {
      return;
    }

    await action();
  };
}
