import React from "react";
import { ExpenseRateSheet } from "~/gql/types";
import { EditRateSheet } from "~/visuals/organisms/EditRateSheet";

type EditExpenseRateSheetProps = {
  useReload(): React.DispatchWithoutAction;
  rateSheet: ExpenseRateSheet;
};

export const EditExpenseRateSheet: React.FC<EditExpenseRateSheetProps> = ({
  rateSheet,
  useReload,
}) => <EditRateSheet {...{ rateSheet, useReload, type: "Expense" }} />;
