import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkEditProjectStatusMutationVariables = Types.Exact<{
  status: Types.ProjectStatus;
  projectNumbers: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type BulkEditProjectStatusMutation = { __typename?: 'MutationModel', projects?: { __typename?: 'ProjectsMutationModel', editStatus?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const BulkEditProjectStatusDocument = gql`
    mutation BulkEditProjectStatus($status: ProjectStatus!, $projectNumbers: [Int!]!) {
  projects {
    editStatus(status: $status, projectNumbers: $projectNumbers) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type BulkEditProjectStatusMutationFn = Apollo.MutationFunction<BulkEditProjectStatusMutation, BulkEditProjectStatusMutationVariables>;

/**
 * __useBulkEditProjectStatusMutation__
 *
 * To run a mutation, you first call `useBulkEditProjectStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditProjectStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditProjectStatusMutation, { data, loading, error }] = useBulkEditProjectStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      projectNumbers: // value for 'projectNumbers'
 *   },
 * });
 */
export function useBulkEditProjectStatusMutation(baseOptions?: Apollo.MutationHookOptions<BulkEditProjectStatusMutation, BulkEditProjectStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkEditProjectStatusMutation, BulkEditProjectStatusMutationVariables>(BulkEditProjectStatusDocument, options);
      }
export type BulkEditProjectStatusMutationHookResult = ReturnType<typeof useBulkEditProjectStatusMutation>;
export type BulkEditProjectStatusMutationResult = Apollo.MutationResult<BulkEditProjectStatusMutation>;
export type BulkEditProjectStatusMutationOptions = Apollo.BaseMutationOptions<BulkEditProjectStatusMutation, BulkEditProjectStatusMutationVariables>;