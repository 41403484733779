import React, { useEffect } from "react";
import { Action, State } from "./stateMachine";
import ProjectTimeForm from "./ProjectTimeForm";
import { FormErrors } from "~/WorkDayApi/WorkDayApiType";
import { FormData } from "./stateMachine";
import _ from "lodash";
import { Form } from "react-final-form";
import { FormEnterHandler } from "~/forms/FormEnterHandler";

type ProjectTimeProps = {
  handleSubmit: (values: FormData) => Promise<FormErrors> | undefined;
  type: "Create" | "Edit" | "Clone";
  date: string;
  onDelete?: () => void;
  state: State;
  dispatch: React.Dispatch<Action>;
  id?: string;
};

export const initialNullValues = {
  crewCode: null,
  project: null,
  hours: null,
  taskName: null,
  taskRate: null,
  notes: null,
  crewSize: null,
  myRole: null,
  otherCrewOne: null,
  otherCrewTwo: null,
  otherRole1: null,
  otherRole2: null,
  location: null,
  adminNotes: null,
  rateSheetName: null,
  saveAndClone: false,
  copyToDate: false,
  copyToDates: false,
};

type SpyProps = {
  values: Record<string, any>;
  state: State;
  dispatch: React.Dispatch<Action>;
};

export const ProjectTimeFormSpy: React.FC<SpyProps> = ({
  state,
  values,
  dispatch,
}) => {
  useEffect(() => {
    for (const [key, value] of Object.entries(values)) {
      if (!_.isEqual(value, state[key])) {
        dispatch({ tag: "UpdateField", fieldName: key, value });
      }
    }
  }, [values]);
  return <></>;
};

export const ProjectTimeEntry: React.FC<ProjectTimeProps> = ({
  handleSubmit,
  type,
  date,
  onDelete,
  state,
  dispatch,
  id,
}) => {
  const {
    crewCode,
    project,
    hours,
    taskName,
    taskRate,
    notes,
    crewSize,
    myRole,
    otherCrewOne,
    otherCrewTwo,
    otherRole1,
    otherRole2,
    location,
    rateSheetName,
    fieldSupervisor,
    leadPartyChief,
  } = state;
  const initialValues = {
    crewCode,
    project,
    hours,
    taskName,
    taskRate,
    notes,
    crewSize,
    myRole,
    otherCrewOne,
    otherCrewTwo,
    otherRole1,
    otherRole2,
    location,
    adminNotes: null,
    rateSheetName,
    saveAndClone: false,
    copyToDate: false,
    copyToDates: false,
    fieldSupervisor,
    leadPartyChief,
  };

  const onSubmit = async (values: FormData) => {
    if (values.taskName) {
      return await handleSubmit(values);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitError, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <ProjectTimeForm
              type={type}
              errorMessage={submitError}
              date={date}
              onDelete={onDelete}
              state={state}
              dispatch={dispatch}
              id={id}
            />
            <ProjectTimeFormSpy {...{ state, values, dispatch }} />
            <FormEnterHandler />
          </form>
        );
      }}
    />
  );
};

export default ProjectTimeEntry;
/* eslint-disable react/display-name */
/* eslint-enable react/display-name */
