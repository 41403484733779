import React, { createContext, useContext, ReactElement } from "react";
import Card from "@material/react-card";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import { Routes, Route, useParams } from "react-router-dom";
import {
  assignProjectExpenseRateSheet,
  assignProjectTaskRateSheet,
} from "~/routes";
import ProjectDisplay from "~/visuals/pages/Project";
import AssignProjectExpenseRateSheet from "~/components/AssignProjectExpenseRateSheet";
import AssignProjectTaskRateSheet from "~/components/AssignProjectTaskRateSheet";
import { useSingleProjectQuery } from "./projectQuery.generated";
import { Project } from "~/gql/types";

type ProjectRouteData = {
  projectNumber: string;
};

const Loading = () => (
  <Grid>
    <Row>
      <Cell columns={12}>
        <Card>loading...</Card>
      </Cell>
    </Row>
  </Grid>
);

const PageRoutes: React.FC<{ project: Project }> = ({ project }) => (
  <Routes>
    <Route
      path={assignProjectExpenseRateSheet.subPath}
      element={<AssignProjectExpenseRateSheet project={project} />}
    />
    <Route
      path={assignProjectTaskRateSheet.subPath}
      element={<AssignProjectTaskRateSheet project={project} />}
    />
    <Route index element={<ProjectDisplay project={project} />} />
  </Routes>
);

type ProjectContextApi = {
  reload: () => void;
};

const ProjectContext = createContext<ProjectContextApi | null>(null);

export default function ProjectRouter(): ReactElement {
  const params = useParams<ProjectRouteData>();
  const variables = {
    projectNumber: parseInt(params.projectNumber!),
  };

  const { data, loading, refetch } = useSingleProjectQuery({ variables });

  if (data && !loading) {
    const api = {
      reload: refetch,
    };
    return (
      <ProjectContext.Provider value={api}>
        <PageRoutes project={data!.projects!.project as Project} />
      </ProjectContext.Provider>
    );
  }

  return <Loading />;
}

export function useReloadProject(): React.DispatchWithoutAction {
  const api = useContext(ProjectContext);

  if (!api) {
    throw new Error(
      "Do not use ProjectRouter.useReload outside of a ProjectRouter."
    );
  }

  return api.reload;
}

type MockRouterProps = {
  reloadProject?: () => any;
};

export const MockProjectRouter: React.FC<MockRouterProps> = ({
  children,
  reloadProject,
}) => {
  const api = {
    reload: reloadProject ?? (() => undefined),
  };

  return (
    <ProjectContext.Provider value={api}>{children}</ProjectContext.Provider>
  );
};
