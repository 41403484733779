import React, { useReducer } from "react";
import {
  PostToInvoiceAction,
  PostToInvoiceState,
  reducer,
} from "./stateMachine";
import { SelectGroupInvoice } from "./SelectGroupInvoice";
import { LoadedDraftInvoice, PostToInvoicesArgs } from "./types";
import { PromptForConfirmation } from "./PromptForConfirmation";
import { DraftInvoicesLoader } from "./DraftInvoicesLoader";
import { FinalSubmitHandler } from "./FinalSubmitHandler";
import Spinner from "~/spinner";

type BulkPostToInvoiceProps<T, TResponse> = {
  initialState: PostToInvoiceState<T>;
  getDraftInvoices: (
    groupIds: (string | number)[]
  ) => Promise<LoadedDraftInvoice[]>;
  postToInvoices: (args: PostToInvoicesArgs<T>[]) => Promise<TResponse>;
  onSuccess: (response: TResponse) => Promise<string>;
  onClose: () => void;
};

export const BulkPostToInvoice = <T, TResponse>(
  props: BulkPostToInvoiceProps<T, TResponse>
): React.ReactElement => {
  const { initialState, getDraftInvoices, postToInvoices, onSuccess, onClose } =
    props;

  const [state, dispatch] = useReducer(
    (a: PostToInvoiceState<T>, b: PostToInvoiceAction) => reducer(a, b),
    initialState
  );

  return (
    <>
      <DraftInvoicesLoader {...{ state, dispatch, getDraftInvoices }} />
      <PromptForConfirmation {...{ state, dispatch, onClose }} />
      <SelectGroupInvoice {...{ state, dispatch, onClose }} />
      <FinalSubmitHandler
        {...{ state, dispatch, postToInvoices, onSuccess, onClose }}
      />
      <Spinner open={state.loading} />
    </>
  );
};
