import React, { ReactElement } from "react";
import MdcButton, {
  ButtonProps as MdcButtonProps,
} from "@material/react-button";
import { Route } from "~/routeTypes";
import { useNavigate } from "react-router-dom";
import "./Button.scss";
import MaterialIcon from "@material/react-material-icon";
import classnames from "classnames";

export type ButtonTypes = HTMLAnchorElement | HTMLButtonElement;

type BaseButtonProps = Omit<
  Omit<MdcButtonProps<ButtonTypes>, "icon">,
  "trailingIcon"
>;

export interface ButtonProps extends BaseButtonProps {
  primary?: boolean;
  outlined?: boolean;
  dense?: boolean;
  route?: Route;
  icon?: string;
  trailingIcon?: string;
}

const ProxyButton = ({
  primary,
  className,
  icon,
  trailingIcon,
  ...otherProps
}: ButtonProps) => {
  const cn = classnames(className, {
    "accent-color": primary,
    "icon-only": icon && React.Children.count(otherProps.children) === 0,
  });

  const iconElem = icon ? (
    <MaterialIcon icon={icon} className="mdc-button__icon" />
  ) : undefined;

  const trailingIconElem = trailingIcon ? (
    <MaterialIcon icon={trailingIcon} className="mdc-button__icon" />
  ) : undefined;

  return (
    <MdcButton
      {...otherProps}
      className={cn}
      icon={iconElem}
      trailingIcon={trailingIconElem}
    />
  );
};

const RouteButton = ({ route, ...otherProps }: ButtonProps) => {
  const nav = useNavigate();
  const to = route?.path || "";

  const handleClick = (evt: React.MouseEvent) => {
    if (evt.altKey || evt.ctrlKey || evt.metaKey || evt.button !== 0) {
      return;
    }
    evt.preventDefault();
    nav(to);
  };

  return <ProxyButton {...otherProps} onClick={handleClick} href={to} />;
};

const Button = (allProps: ButtonProps): ReactElement => {
  if (allProps.route) {
    return <RouteButton {...allProps} />;
  } else {
    return <ProxyButton {...allProps} />;
  }
};

export default Button;
