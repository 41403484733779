export class Route {
  readonly path: string;
  readonly subPath?: string;

  constructor(path: string, subPath?: string) {
    this.path = path;
    this.subPath = `/${subPath}`;
  }

  childRoute(path: string): Route {
    return new Route(`${this.path}/${path}`, path);
  }
}

export class RouteTemplate {
  readonly path: string;
  readonly subPath?: string;
  readonly subSubPath?: string;
  private readonly toRouteFn: (x: string) => string;

  constructor(
    path: string,
    toRouteFn: (x: string) => string,
    subPath?: string,
    subSubPath?: string
  ) {
    this.path = path;
    this.toRouteFn = toRouteFn;
    this.subPath = `/${subPath}`;
    this.subSubPath = subSubPath;
  }

  toRoute(x: string, subPath?: string): Route {
    return new Route(this.toRouteFn(encodeURIComponent(x)), subPath);
  }

  childRoute(path: string): RouteTemplate {
    return new RouteTemplate(
      `${this.path}/${path}`,
      (x) => `${this.toRouteFn(x)}/${path}`,
      path
    );
  }
}

export class TieredRouteTemplate {
  readonly path: string;
  readonly subPath?: string;
  private readonly toRouteFn: (x: string, y?: string) => string;

  constructor(
    path: string,
    toRouteFn: (x: string, y?: string) => string,
    subPath?: string
  ) {
    this.path = path;
    this.toRouteFn = toRouteFn;
    this.subPath = `/${subPath}`;
  }

  toRoute(first: string, second?: string): Route {
    return new Route(
      this.toRouteFn(
        encodeURIComponent(first),
        encodeURIComponent(second ?? "")
      )
    );
  }

  childRoute(path: string): TieredRouteTemplate {
    return new TieredRouteTemplate(
      `${this.path}/${path}`,
      (x, y) => `${this.toRouteFn(x, y)}/${path}`,
      path
    );
  }
}

export class RouteTemplate2 {
  readonly path: string;
  readonly subPath?: string;
  private readonly toRouteFn: (x: string, y?: string) => string;

  constructor(
    path: string,
    toRouteFn: (x: string, y?: string) => string,
    subPath?: string
  ) {
    this.path = path;
    this.toRouteFn = toRouteFn;
    this.subPath = `/${subPath}`;
  }

  toRoute(first: string, second?: string, subPath?: string): Route {
    return new Route(
      this.toRouteFn(
        encodeURIComponent(first),
        encodeURIComponent(second ?? "")
      ),
      subPath
    );
  }

  childRoute(path: string): RouteTemplate2 {
    return new RouteTemplate2(
      `${this.path}/${path}`,
      (x, y) => `${this.toRouteFn(x, y)}/${path}`,
      path
    );
  }
}

export class RouteNumberTemplate {
  readonly path: string;
  readonly subPath?: string;
  private readonly toRouteFn: (x: number) => string;

  constructor(
    path: string,
    toRouteFn: (x: number) => string,
    subPath?: string
  ) {
    this.path = path;
    this.toRouteFn = toRouteFn;
    this.subPath = `/${subPath}`;
  }

  toRoute(x: number): Route {
    return new Route(this.toRouteFn(x));
  }

  childRoute(path: string): RouteNumberTemplate {
    return new RouteNumberTemplate(
      `${this.path}/${path}`,
      (x) => `${this.toRouteFn(x)}/${path}`,
      path
    );
  }
}
