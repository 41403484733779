import React from "react";
import TimePickerFormField from "../TimePickerFormField";
import { CrewCodePickerFormField } from "../CrewCodePickerFormField";
import moment, { Duration } from "moment";
import { Body1 } from "@material/react-typography";
import { Cell, Row } from "@material/react-layout-grid";
import CrewCodeRecord from "~/graphql/CrewCodeRecord";
import StateCounty from "~/graphql/StateCounty";
import "./TimeEntryForm.scss";
import TitleDeleteOption from "~/title-delete-option";
import Spinner from "~/spinner";
import FormBottomRow from "~/form-bottom-row";
import FormCard from "~/form-card";
import WarningMessage from "~/warning-message";
import { useWorkDayApi } from "~/WorkDayApi";
import TextFormField from "~/text-form-field";
import { useRelativeNav } from "~/relative-nav";
import { SaveAndHandler } from "~/WorkDayApi/SaveAndHandler";
import { StateCountyPicker } from "~/visuals/organisms/StateCountyPicker";

export type TimeEntryFormProps = {
  timeIn: Duration | null;
  timeOut: Duration | null;
  hoursWorked: Duration | null;
  lunchHours: Duration | null;
  type: "Create" | "Edit";
  crewCode: CrewCodeRecord | null;
  stateCounty: StateCounty | null;
  errorMessage: string | null;
  date: string;
  loading: boolean;
  onDelete?: () => void;
};

const TimeEntryForm: React.FC<TimeEntryFormProps> = ({
  timeIn,
  timeOut,
  hoursWorked,
  lunchHours,
  type,
  crewCode,
  stateCounty,
  errorMessage,
  loading,
  onDelete,
}) => {
  const durationDisplay = (d) => {
    if (!d) {
      return "0:00";
    }
    const m = moment(d.asMilliseconds()).startOf("day").add(d);

    return m.format("HH:mm");
  };

  const crewCodeLocationMismatch =
    crewCode && stateCounty?.state && crewCode.state !== stateCounty?.state;

  const { isAdmin } = useWorkDayApi();
  const nav = useRelativeNav();

  const cancelPath = type === "Edit" ? "../../" : "../";

  return (
    <div className="time-entry-form">
      <TitleDeleteOption
        title={`${type === "Edit" ? "Edit" : "Create New"} Time Entry`}
        onDelete={onDelete}
      />
      <Spinner open={loading} />
      <FormCard className="">
        {crewCodeLocationMismatch && (
          <WarningMessage
            icon="warning"
            message={`WARNING: The State (${crewCode?.state}) associated with CrewCode ${crewCode?.crewCode} does not match the State provided for this Time Entry (${stateCounty?.state}).`}
          />
        )}
        <Row>
          <Cell columns={6}>
            <CrewCodePickerFormField
              label="Crew Code"
              formField="crewCode"
              helperText="The crew code applicable to this work. (use up/down arrows and enter key to select suggestions)"
              required
            />
          </Cell>
          <Cell columns={6}>
            <StateCountyPicker
              label="State/County"
              helperText="The state and county where the work was performed. (use up/down arrows and enter key to select suggestions)"
              formField="stateCounty"
              required
            />
          </Cell>
        </Row>
        <Row>
          <Cell columns={6}>
            <TimePickerFormField
              formField="timeIn"
              label="Time In"
              helperText="The time the work began."
              required
            />
          </Cell>
          <Cell columns={6}>
            <TimePickerFormField
              formField="timeOut"
              label="Time Out"
              helperText="The time the work ended."
              required
            />
          </Cell>
        </Row>
        <Row>
          <Cell columns={6}>
            <TimePickerFormField
              formField="lunchStart"
              label="Lunch Start"
              helperText="The time starting lunch."
              disabled={!timeIn || !timeOut}
              required={false}
            />
          </Cell>
          <Cell columns={6}>
            <TimePickerFormField
              formField="lunchEnd"
              label="Lunch End"
              helperText="The time returning from lunch."
              disabled={!timeIn || !timeOut}
            />
          </Cell>
        </Row>
        {isAdmin && (
          <Row>
            <Cell columns={6}>
              <TextFormField
                formField="adminNotes"
                label="Admin Notes"
                helperText="Enter the reason for the change"
                textarea
                required
              />
            </Cell>
          </Row>
        )}
        <Row>
          <Cell columns={3}>
            <Body1>Hours Worked: {durationDisplay(hoursWorked)}</Body1>
          </Cell>
          <Cell columns={3}>
            <Body1>Lunch Hours: {durationDisplay(lunchHours)}</Body1>
          </Cell>
        </Row>
        <FormBottomRow
          errorMessage={errorMessage}
          buttonText={type === "Create" ? "Create" : "Save"}
          onCancel={() => nav(cancelPath)}
        >
          {type !== "Edit" && <SaveAndHandler includeClone={false} />}
        </FormBottomRow>
      </FormCard>
    </div>
  );
};

export default TimeEntryForm;
