import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { SimplePicker } from "~/visuals/organisms/SimplePicker";
import { Suggestion } from "~/ultra-filter/types";

export const requestStates = [
  { value: "Approved", text: "Approved" },
  { value: "Pending", text: "Pending" },
  { value: "PendingCustomerApproved", text: "Pending Customer" },
  { value: "Rejected", text: "Rejected" },
] as Suggestion[];

export const ProjectRequestStatePicker: React.FC<FormFieldProps> = (props) => (
  <SimplePicker {...{ ...props, options: requestStates }} />
);
