import React from "react";
import { useEditReceiptValueExpenseRateMutation } from "./queries.generated";
import { MutationForm } from "~/forms/MutationForm";
import { FormProps, FormData } from "./types";
import { ReceiptValuedExpenseRate, ValidationResponse } from "~/gql/types";

export const EditReceiptValuedExpenseRate: React.FC<
  FormProps & { original: ReceiptValuedExpenseRate }
> = (props) => {
  const { setLoading, original, rateSheet, onSuccess, children } = props;

  const [rawReceiptValuedMutation] = useEditReceiptValueExpenseRateMutation();

  const initialValues = {
    name: original.name,
    invoiceLabel: original.invoiceLabel,
    expenseAccount: original.expenseAccount,
    glAccount: original.glAccount,
    requiresLocation: original.requiresLocation,
    category: original.category,
    billable: original.billable,
  };

  const handleSubmit = async (values: FormData) => {
    const variables = {
      oldName: original?.name!,
      name: values?.name!,
      rateSheetName: rateSheet.name,
      invoiceLabel: values?.invoiceLabel || null,
      expenseAccount: values?.expenseAccount || null,
      glAccount: values?.glAccount || null,
      requiresLocation: values?.requiresLocation!,
      category: values?.category!,
      billable: values?.billable!,
    };

    const result = await rawReceiptValuedMutation({ variables });

    return result?.data?.expenseRates
      ?.editReceiptValuedRate as ValidationResponse;
  };

  return (
    <MutationForm
      setLoading={setLoading}
      initialValues={initialValues}
      runMutation={handleSubmit}
      onSuccess={onSuccess}
      allowPristineSubmit
    >
      {children}
    </MutationForm>
  );
};
