import React from "react";
import "./TableCell.scss";
import { ListItem, ListItemText } from "@material/react-list";
import classnames from "classnames";

type TableCellProps = {
  text?: string;
  className?: string;
  onClick?: () => void;
};

const TableCell: React.FC<TableCellProps> = ({
  text,
  className,
  onClick,
  children,
}) => {
  const cn = classnames("table-cell", className);
  if (text != undefined) {
    return (
      <ListItem className={cn} title={text ?? ""} onClick={onClick}>
        <ListItemText primaryText={text} className="table-cell-text" />
      </ListItem>
    );
  } else {
    return (
      <ListItem className={cn} onClick={onClick}>
        {children}
      </ListItem>
    );
  }
};

export default TableCell;
