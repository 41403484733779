import React, { useEffect, useMemo, useState } from "react";
import { CustomerSearchProps, CustomersFilter, State } from "./types";
import {
  newCustomer as newCustomerRoute,
  customer as customerRoute,
} from "~/routes";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import useInfiniteScroll from "~/search-page-wrapper/infinite-scroll/useInfiniteScroll";
import { useNavigate } from "react-router-dom";
import { TextHighlighter } from "~/text-highlighter";
import { TextHighlighterProvider } from "~/text-highlighter/TextHighlighterProvider";
import TitledCard from "~/titled-card";
import useAsyncEffect from "~/hooks/useAsyncEffect";
import "./Customers.scss";
import { VisibilityListener } from "~/visibility-listener";
import { SearchFilterContainer } from "~/search-filter-container";
import { FilterForm } from "./FilterForm";
import Button from "~/button";
import { TopActionButtons } from "~/top-action-buttons";
import { ExportCsv } from "./ExportCsv";
import { CustomerSearchDocument } from "./query.generated";
import { useApolloClient } from "@apollo/client";
import { Customer } from "~/gql/types";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";
import { BadDebtCsv } from "./BadDebtsCsv";
const CustomerListItem: React.FC<{ customer: Customer }> = ({ customer }) => {
  const navigate = useNavigate();
  const { name, number, city, state, country, address1, address2, phone, zip } =
    customer;
  const route = customerRoute.toRoute(number!);
  const subtitle = `${address1}, ${address2 ? `${address2} ` : ""}${city}, ${
    country.length > 0 ? country : `${state} ${zip}`
  }${phone ? `, ${phone}` : ""}`;
  return (
    <TitledCard
      className="customer-card"
      handleClick={() => navigate(route.path)}
      title={<TextHighlighter text={`${name} (${number})`} />}
      subtitle={subtitle}
      isEmpty={false}
      emptyMessage=""
      actions={
        <>
          <span className="stat">(TBD) projects</span>
          <span className="stat">last invoiced: (TBD)</span>
        </>
      }
    >
      <span></span>
    </TitledCard>
  );
};
export const CustomerSearchDisplay: React.FC<CustomerSearchProps> = ({
  state,
  dispatch,
}) => {
  return (
    <TextHighlighterProvider searchText={state.filterOptions.searchText}>
      <SearchListContent
        className="customers-search"
        loading={state.loading}
        loadingMore={state.tag === "LoadingMore"}
        actions={
          <TopActionButtons>
            <ExportCsv {...{ state }} />
            <BadDebtCsv {...{ state }} />
          </TopActionButtons>
        }
      >
        <div className="results">
          {state.items.map((customer) => (
            <CustomerListItem customer={customer} key={customer.number} />
          ))}
        </div>
        {state.showVisibility && (
          <VisibilityListener
            onShown={() => dispatch({ tag: "ScrolledToBottom" })}
          />
        )}
      </SearchListContent>
    </TextHighlighterProvider>
  );
};

const CustomerReactor: React.FC<CustomerSearchProps> = ({
  state,
  dispatch,
}) => {
  const client = useApolloClient();

  useAsyncEffect(async () => {
    if (state.tag === "Loading" || state.tag === "LoadingMore") {
      const variables = {
        searchText: state.filterOptions.searchText,
        projectNumber: state.filterOptions.project?.number ?? null,
        token: state.tag === "Loading" ? null : state.searchToken,
      };
      const { data } = await client.query({
        variables,
        query: CustomerSearchDocument,
      });

      if (state.tag === "Loading") {
        dispatch({
          items: data.customers?.search?.records,
          searchToken: data.customers?.search?.token,
          tag: "DataLoaded",
        });
      } else {
        dispatch({
          items: data.customers?.search?.records,
          searchToken: data.customers?.search?.token,
          tag: "MoreDataLoaded",
        });
      }
    }
  }, [state, dispatch]);

  return <></>;
};

type CustomersPageProps = {
  FilterFormComponent?: typeof FilterForm;
};

export const Customers: React.FC<CustomersPageProps> = (props) => {
  const [filters, setFilters] = useState<CustomersFilter | null>(null);
  const FilterFormComponent = props.FilterFormComponent || FilterForm;

  const setFilterOptions = (opts: CustomersFilter) => setFilters(opts);

  return (
    <SearchPageWrapper>
      <SearchFilterContainer>
        <FilterFormComponent onFiltersChanged={setFilterOptions} />
        <div className="customers-add-new-button">
          <Button raised primary route={newCustomerRoute}>
            Add Customer
          </Button>
        </div>
      </SearchFilterContainer>
      {filters && <CustomersResults {...{ ...props, filters }} />}
    </SearchPageWrapper>
  );
};

export const CustomersResults: React.FC<{ filters: CustomersFilter }> = ({
  filters,
}) => {
  const initialState: State = useMemo(
    () => ({
      selectIdKey: "number",
      selectedItems: [],
      tag: "Loading",
      loading: true,
      filterOptions: filters,
      items: [],
      searchToken: null,
      showVisibility: false,
    }),
    []
  ) as State;

  useBreadcrumbs([{ text: "Customers" }], []);

  const { state, dispatch } = useInfiniteScroll<Customer, CustomersFilter>(
    initialState
  );

  useEffect(() => {
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: filters,
    });
  }, [filters]);

  return (
    <>
      <CustomerSearchDisplay {...{ state, dispatch }} />
      <CustomerReactor {...{ state, dispatch }} />
    </>
  );
};
