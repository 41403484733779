import React, { useEffect, useState } from "react";
import { Body2 } from "@material/react-typography";

import { projectRequest as projectRequestRoute } from "~/routes";
import _ from "lodash";
import "./ProjectRequests.scss";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { FilterForm, FilterOptions } from "./FilterForm";
import { ProjectRequest, ProjectRequestGroup } from "~/gql/types";
import { VisibilityListener } from "~/visibility-listener";
import { useProjectRequestsGroupSearchQuery } from "./query.generated";
import { SearchFilterContainer } from "~/search-filter-container";
import useInfiniteScroll from "~/search-page-wrapper/infinite-scroll/useInfiniteScroll";
import { ResetSearchItemsProvider } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import { TextHighlighterProvider } from "~/text-highlighter/TextHighlighterProvider";
import moment from "moment";
import { LinkClickHandler } from "~/link-click-handler";
import { getCustomerInfo } from "../utils";
import Card from "@material/react-card";
import { requestGroupReview } from "../routes";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";

type ProjectRequestGroupProps = {
  group: ProjectRequestGroup;
};

export const getGroupCardPath = (group: ProjectRequestGroup): string => {
  const requests = group.requests! as ProjectRequest[];

  const projectRequest = requests[0];

  const { requestId } = projectRequest;

  return requests.length > 1
    ? `${requestGroupReview}/${group.groupId}/1`
    : projectRequestRoute.toRoute(requestId!).path;
};

const ProjectRequestGroupCard: React.FC<ProjectRequestGroupProps> = ({
  group,
}) => {
  const requests = group.requests! as ProjectRequest[];

  const projectRequest = requests[0];

  const { createdOn, requester } = projectRequest;

  const requestedBy = `${requester!.firstName} ${requester!.lastName}`;

  const path = getGroupCardPath(group);

  const { customer, customerNumber } = getCustomerInfo(projectRequest);

  return (
    <LinkClickHandler path={path} className={"project-request-card"}>
      <Card>
        <div className="title">
          <span>
            <Body2>
              Customer: {customer?.name}
              <span className="customer-number">
                {customerNumber ?? "New Customer"}
              </span>
            </Body2>
          </span>
          <span>
            <Body2>Requested By: {requestedBy}</Body2>
          </span>
          <span>
            <Body2>
              Date: {moment.utc(createdOn).local().format("MM/DD/yyyy")}
            </Body2>
          </span>
          <span>
            <Body2>Requests: {requests.length}</Body2>
          </span>
        </div>
      </Card>
    </LinkClickHandler>
  );
};

export type ProjectRequestsDisplayProps = {
  showSpinner: boolean;
  groups: ProjectRequestGroup[] | null;
  state: State<ProjectRequestGroup, FilterOptions>;
  dispatch: React.Dispatch<Action<ProjectRequestGroup, FilterOptions>>;
};

export const ProjectRequestsPageDisplay: React.FC<
  ProjectRequestsDisplayProps
> = (props) => {
  const { showSpinner, groups, state, dispatch } = props;

  return (
    <SearchListContent
      loading={showSpinner}
      loadingMore={state.tag === "LoadingMore"}
    >
      {groups &&
        groups.map((group, idx) => (
          <ProjectRequestGroupCard group={group} key={idx} />
        ))}
      {state.showVisibility && (
        <VisibilityListener
          onShown={() => dispatch({ tag: "ScrolledToBottom" })}
        />
      )}
    </SearchListContent>
  );
};

type LoadProjectRequestProps = {
  dispatch: React.Dispatch<Action<ProjectRequestGroup, FilterOptions>>;
  tag: "DataLoaded" | "MoreDataLoaded";
  state: State<ProjectRequestGroup, FilterOptions>;
};

const LoadProjectRequests = ({
  dispatch,
  tag,
  state,
}: LoadProjectRequestProps) => {
  const token = state.searchToken;

  const {
    searchText,
    officeCode,
    state: requestState,
    locationState,
    approver,
    customer,
    projectManager,
    date,
  } = state.filterOptions;

  const variables = {
    searchText: searchText || null,
    officeCode: officeCode?.officeCode || null,
    state: requestState || null,
    token: token || null,
    date: date ? date.format("YYYY-MM-DD") : null,
    locationState: locationState || null,
    approver: approver?.userPrincipalName || null,
    customerNumber: customer?.number || null,
    projectManager: projectManager?.userPrincipalName || null,
  };

  const { data } = useProjectRequestsGroupSearchQuery({ variables });

  useEffect(() => {
    if (data) {
      const newToken = data?.projectRequests?.groupSearch?.token as
        | string
        | null;
      const newGroups = data?.projectRequests?.groupSearch
        ?.records as ProjectRequestGroup[];
      dispatch({ tag, items: newGroups, searchToken: newToken });
    }
  }, [data]);

  return <></>;
};

type ProjectRequestsPageProps = {
  FilterFormComponent?: typeof FilterForm;
};

export const ProjectRequestsPage: React.FC<ProjectRequestsPageProps> = (
  props
) => {
  const [filters, setFilters] = useState<FilterOptions | null>(null);
  const FilterFormComponent = props.FilterFormComponent || FilterForm;

  const setFilterOptions = (opts: FilterOptions) => setFilters(opts);

  return (
    <SearchPageWrapper>
      <SearchFilterContainer>
        <FilterFormComponent onFiltersChanged={setFilterOptions} />
      </SearchFilterContainer>
      {filters && <ProjectRequestResults {...{ ...props, filters }} />}
    </SearchPageWrapper>
  );
};

export const ProjectRequestResults: React.FC<
  ProjectRequestsPageProps & { filters: FilterOptions }
> = (props) => {
  useBreadcrumbs([{ text: "Project Requests" }], []);

  const { filters } = props;

  const initialState = {
    tag: "Loading",
    items: [],
    showVisibility: false,
    loading: true,
    filterOptions: filters,
    searchToken: null,
    selectIdKey: "requestId",
    selectedItems: [],
  } as State<ProjectRequestGroup, FilterOptions>;

  const { state, dispatch } = useInfiniteScroll<
    ProjectRequestGroup,
    FilterOptions
  >(initialState);

  useEffect(() => {
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: filters,
    });
  }, [filters]);

  const reset = () => {
    dispatch({
      tag: "Reset",
      filterOptions: state.filterOptions,
    });
  };

  return (
    <ResetSearchItemsProvider reset={reset}>
      <TextHighlighterProvider searchText={state.filterOptions.searchText}>
        {state.tag === "Loading" && (
          <LoadProjectRequests
            {...{
              dispatch,
              state: { ...state, searchToken: null },
              tag: "DataLoaded",
            }}
          />
        )}
        {state.tag === "LoadingMore" && (
          <LoadProjectRequests
            {...{
              dispatch,
              tag: "MoreDataLoaded",
              state,
            }}
          />
        )}
        <ProjectRequestsPageDisplay
          {...{
            groups: state.items,
            showSpinner: state.loading,
            state,
            dispatch,
          }}
        />
      </TextHighlighterProvider>
    </ResetSearchItemsProvider>
  );
};
