import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import StateCounty from "~/graphql/StateCounty";
import _ from "lodash";
import { useStateCountiesAsync } from "~/refdata2";
import { createSearchRegex } from "~/text-highlighter/createSearchRegex";
import { TextHighlighter } from "~/text-highlighter";

const format = (item: StateCounty, searchText: string): React.ReactNode => {
  const searchRegex = createSearchRegex(searchText);

  return (
    <div className="division-picker-menuitem">
      <TextHighlighter
        text={`${item.state}-${item.county}`}
        search={searchRegex}
      />
    </div>
  );
};

const chipFormat = (item: StateCounty) => `${item.state}-${item.county}`;

export const StateCountyPicker: React.FC<FormFieldProps> = (props) => {
  const getStateCounties = useStateCountiesAsync();

  const getData = async (searchText: string) => {
    const result = (await getStateCounties()) ?? [];

    if (!searchText) {
      return [];
    }

    const ucText = searchText.toUpperCase();

    return _.chain(result)
      .map(({ state, county }) => ({
        state,
        county,
        text: `${state}-${county}`,
      }))
      .filter((x) => x.text.startsWith(ucText) || x.county.startsWith(ucText))
      .orderBy((x) => x.text)
      .map(({ state, county }) => ({ state, county }))
      .value();
  };

  const args = {
    ...props,
    getData,
    format,
    chipFormat,
  } as TypeaheadProps<StateCounty>;

  return <TypeaheadFormField {...args} />;
};
