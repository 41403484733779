import React, { useEffect, useState } from "react";
import { useForm, useFormState } from "react-final-form";
import { SaveAnd, SaveAndItem } from "~/save-and";
import { useWorkDayApi } from "..";
import { showCopyToDates } from "~/visuals/organisms/WorkDay/CopyToDates";

export type SaveAndAction = "saveAndClone" | "copyToDates" | "copyToDate";

export type SaveAndHandlerItem = {
  action: SaveAndAction;
  text: string;
  description: string;
};

export type SaveAndHandlerProps = {
  includeClone?: boolean;
};

export const saveAndItems = (
  includeClone: boolean,
  includeCopyToDates: boolean
): SaveAndHandlerItem[] =>
  [
    includeClone
      ? {
          text: "Clone",
          description: "Make a copy with editable fields",
          action: "saveAndClone",
        }
      : null,
    {
      text: "Copy to date",
      description: "Copy to another date on or before today",
      action: "copyToDate",
    },
    includeCopyToDates
      ? {
          text: "Copy to dates",
          description:
            "Copy to other dates on this timesheet (on or before today)",
          action: "copyToDates",
        }
      : null,
  ].filter((x) => x) as SaveAndHandlerItem[];

export const SaveAndHandler: React.FC<SaveAndHandlerProps> = ({
  includeClone = true,
}) => {
  const {
    workDay: { date },
  } = useWorkDayApi();

  const includeCopyToDates = showCopyToDates(date);

  const items = saveAndItems(includeClone, includeCopyToDates);

  const form = useForm();
  const formValues = useFormState().values;

  const [submitted, setSubmitted] = useState(false);

  const actions = items.map((x) => x.action);

  const actionValues = Object.entries(formValues)
    .filter(([k, _]) => actions.includes(k as any))
    .map(([_, v]) => v as boolean);

  useEffect(() => {
    if (actionValues.some((x) => x) && !submitted) {
      void (async () => {
        setSubmitted(true);
        await form.submit();
        actions.forEach((a) => form.change(a, false));
        setSubmitted(false);
      })();
    }
  }, [actionValues]);

  const menuItems: SaveAndItem[] = items.map(
    ({ action, text, description }) => ({
      text,
      description,
      onClick: () => form.change(action, true),
    })
  );

  return <SaveAnd items={menuItems} />;
};
