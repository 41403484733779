import React, { useState } from "react";
import PerDiemForm from "~/visuals/organisms/Expenses/ExpenseForm/PerDiemTypeForm";
import { useWorkDayApi } from "~/WorkDayApi";
import { PerDiemExpense, PerDiemExpenseRate } from "~/gql/types";
import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";
import { ApiCallback } from "~/WorkDayApi/WorkDayApiType";

type NewPerDiemProps = BaseExpenseFormProps & {
  expenseRate: PerDiemExpenseRate;
  expenseEntry?: PerDiemExpense;
};

const NewPerDiemExpense: React.FC<NewPerDiemProps> = (props) => {
  const { initialValues, expenseEntry, date } = props;

  const api = useWorkDayApi();

  const [imageId, setImageId] = useState<string | null>(
    initialValues?.receiptImageId ?? null
  );

  const uniqueInitVals: UniqueInitVals = [
    {
      location: expenseEntry?.location ?? null,
      receiptImage: null,
    },
    {
      location: null,
      receiptImage: null,
    },
  ];

  const createExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc,
    callback?: ApiCallback
  ) => {
    const res = await api.createPerDiemExpense(
      {
        ...defaultVals(values),
        date,
        state: values?.location?.state,
        county: values?.location?.county,
        receiptImageId: imageId,
      },
      callback
    );
    return res;
  };

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        handleSubmit: createExpense,
        expenseType: "Per Diem",
        uniqueInitVals,
        formType: expenseEntry ? "Clone" : "Create",
      }}
    >
      <PerDiemForm {...props} setImageId={setImageId} />
    </ExpensePageWrapper>
  );
};

export default NewPerDiemExpense;
