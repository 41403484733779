import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import {
  employeeSortOptions,
  EmployeeSortPicker,
} from "~/filterProviders/EmployeeSortPicker";
import { createSimpleProvider } from "./createSimpleProvider";

export function useEmployeeSortProvider(
  props: FilterProviderProps
): FilterProvider {
  return createSimpleProvider({
    documentationText: props.documentationText,
    type: "sortBy",
    label: "Sort",
    render: () => (
      <EmployeeSortPicker formField="sortBy" label="Sort" helperText="" />
    ),
    suggestText: "sort",
    options: employeeSortOptions,
  });
}
