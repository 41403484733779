import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditCustomerMutationVariables = Types.Exact<{
  number: Types.Scalars['String'];
  name: Types.Scalars['String'];
  address1: Types.Scalars['String'];
  address2: Types.Scalars['String'];
  city: Types.Scalars['String'];
  state: Types.Scalars['String'];
  zip: Types.Scalars['String'];
  country: Types.Scalars['String'];
  phone: Types.Scalars['String'];
  fax: Types.Scalars['String'];
  mainNumber?: Types.InputMaybe<Types.Scalars['String']>;
  subNumber?: Types.InputMaybe<Types.Scalars['String']>;
  notes: Types.Scalars['String'];
  attn?: Types.InputMaybe<Types.Scalars['String']>;
  nmTaxable: Types.Scalars['Boolean'];
  txTaxable: Types.Scalars['Boolean'];
  electronicInvoicingSystem?: Types.InputMaybe<Types.ElectronicInvoicingSystem>;
}>;


export type EditCustomerMutation = { __typename?: 'MutationModel', customers?: { __typename?: 'CustomerMutationModel', editCustomer?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const EditCustomerDocument = gql`
    mutation EditCustomer($number: String!, $name: String!, $address1: String!, $address2: String!, $city: String!, $state: String!, $zip: String!, $country: String!, $phone: String!, $fax: String!, $mainNumber: String, $subNumber: String, $notes: String!, $attn: String, $nmTaxable: Boolean!, $txTaxable: Boolean!, $electronicInvoicingSystem: ElectronicInvoicingSystem) {
  customers {
    editCustomer(
      number: $number
      name: $name
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      country: $country
      phone: $phone
      fax: $fax
      notes: $notes
      mainNumber: $mainNumber
      subNumber: $subNumber
      attn: $attn
      nmTaxable: $nmTaxable
      txTaxable: $txTaxable
      electronicInvoicingSystem: $electronicInvoicingSystem
    ) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type EditCustomerMutationFn = Apollo.MutationFunction<EditCustomerMutation, EditCustomerMutationVariables>;

/**
 * __useEditCustomerMutation__
 *
 * To run a mutation, you first call `useEditCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomerMutation, { data, loading, error }] = useEditCustomerMutation({
 *   variables: {
 *      number: // value for 'number'
 *      name: // value for 'name'
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *      country: // value for 'country'
 *      phone: // value for 'phone'
 *      fax: // value for 'fax'
 *      mainNumber: // value for 'mainNumber'
 *      subNumber: // value for 'subNumber'
 *      notes: // value for 'notes'
 *      attn: // value for 'attn'
 *      nmTaxable: // value for 'nmTaxable'
 *      txTaxable: // value for 'txTaxable'
 *      electronicInvoicingSystem: // value for 'electronicInvoicingSystem'
 *   },
 * });
 */
export function useEditCustomerMutation(baseOptions?: Apollo.MutationHookOptions<EditCustomerMutation, EditCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCustomerMutation, EditCustomerMutationVariables>(EditCustomerDocument, options);
      }
export type EditCustomerMutationHookResult = ReturnType<typeof useEditCustomerMutation>;
export type EditCustomerMutationResult = Apollo.MutationResult<EditCustomerMutation>;
export type EditCustomerMutationOptions = Apollo.BaseMutationOptions<EditCustomerMutation, EditCustomerMutationVariables>;