import React from "react";
import { useCreateMobileAllowanceExpenseRateMutation } from "./queries.generated";
import { MutationForm } from "~/forms/MutationForm";
import { CreateExpenseRateFormData, CreateExpenseRateFormProps } from "./types";
import { parseNum } from ".";
import { ValidationResponse } from "~/gql/types";

export const NewMobileAllowanceExpenseRate: React.FC<
  CreateExpenseRateFormProps
> = (props) => {
  const { setLoading, onSuccess, initialValues, rateSheetName, children } =
    props;

  const [rawMaMutation] = useCreateMobileAllowanceExpenseRateMutation();

  const handleSubmit = async (values: CreateExpenseRateFormData) => {
    const variables = {
      name: values?.name!,
      invoiceLabel: values?.invoiceLabel || null,
      expenseAccount: values?.expenseAccount || null,
      glAccount: values?.glAccount || null,
      rateSheetName: rateSheetName,
      billableAmount: parseNum(values?.billableAmount)!,
      reimbursableAmount: parseNum(values?.reimbursableAmount)!,
      quantity: parseNum(values?.quantity!)!,
    };

    const result = await rawMaMutation({ variables });

    return result?.data?.expenseRates
      ?.addMobileAllowanceRate as ValidationResponse;
  };

  return (
    <MutationForm
      setLoading={setLoading}
      initialValues={initialValues}
      runMutation={handleSubmit}
      onSuccess={onSuccess}
      allowPristineSubmit
    >
      {children}
    </MutationForm>
  );
};
