import React from "react";
import Button from "~/button";
import { Cell, Row } from "@material/react-layout-grid";
import Card from "@material/react-card";
import classnames from "classnames";
import "./base.scss";
import { State, Action } from "./stateMachine";
import ErrorMessage from "~/error-message";

export const WizardPage: React.FC<WizardPageProps> = (props) => (
  <Row>
    <Cell columns={2} />
    <Cell columns={8}>
      <WizardCard {...props} />
    </Cell>
  </Row>
);

type WizardPageProps = {
  actionButtonText?: string;
  actionButtonIcon?: string;
  canGoBack?: boolean;
  className?: string;
  buttonAction: Action;
  dispatch: React.Dispatch<Action>;
  state?: State;
};

const WizardCard: React.FC<WizardPageProps> = ({
  children,
  actionButtonText = "Next",
  actionButtonIcon = "chevron_right",
  canGoBack = true,
  className,
  buttonAction,
  dispatch,
  state,
}) => {
  return (
    <Card className={classnames("wizard-card", className)}>
      {children}

      <div className="wizard-actions">
        {canGoBack ? (
          <Button
            icon="chevron_left"
            onClick={() => dispatch({ tag: "PrevPage" })}
          >
            Back
          </Button>
        ) : (
          <span />
        )}

        {state?.errorMessage && (
          <ErrorMessage errorMessage={state.errorMessage} />
        )}

        <Button
          primary
          trailingIcon={actionButtonIcon}
          onClick={() => dispatch(buttonAction)}
        >
          {actionButtonText}
        </Button>
      </div>
    </Card>
  );
};
