import React from "react";
import { OptionProps } from "~/visuals/organisms/DropdownFormField";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import { ProjectDailies } from "../ProjectDailies";
import { Timesheet } from "./types";

type WorkWeekProjectDailiesProps = {
  row: Timesheet;
};

export const WorkWeekProjectDailies: React.FC<WorkWeekProjectDailiesProps> = ({
  row,
}) => {
  const downloadPdf = useReportFetcher();

  const projects = row?.projectsWithDailies ?? [];

  if (projects.length === 0) {
    return null;
  }

  const options = projects.map((x) => ({
    label: `${x!.name} (${x!.number})`,
    value: `${x!.number}`,
  })) as OptionProps[];

  const employee = row.employee!;

  const handleSubmit = async (
    values: { project: string | null },
    setLoading: React.Dispatch<boolean>
  ) => {
    const fileName = `${employee.firstName.toUpperCase()} ${employee.lastName.toUpperCase()} DAILY_${
      values.project
    }_${row.weekBeginning}`;
    void downloadPdf({
      setLoading,
      path: "workweek-project-dailies",
      fileName,
      body: {
        timesheetId: row.timesheetId,
        projectId: +values.project!,
      },
    });
  };

  return <ProjectDailies {...{ options, handleSubmit, outlined: true }} />;
};
