import moment from "moment";
import { ProjectTimeRow } from "~/visuals/organisms/WorkDay/ProjectTimeCard/types";
import { TimeEntryRow } from "~/visuals/organisms/WorkDay/TimeCard";
import { TimeOffRow } from "~/visuals/organisms/WorkDay/TimeOffCard";
import { Expense } from "~/WorkDayApi/expenseTypes";
import { WorkDay } from "~/WorkDayApi/WorkDayApiType";

type WorkData = {
  timeEntryData: TimeEntryRow[];
  timeOffEntryData: TimeOffRow[];
  projectTimeData: ProjectTimeRow[];
  expenseData: Expense[];
};

export const parseWorkData = (workDay: WorkDay): WorkData => {
  const dur = (str: string | undefined) => moment.duration(str);

  const { timeEntries, timeOff, projectTime, expenses } = workDay;

  const timeEntryData = (
    timeEntries
      ? timeEntries.map((time) => ({
          ...time,
          timeIn: dur(time!.timeIn),
          timeOut: dur(time!.timeOut),
          hoursWorked: dur(time!.hoursWorked),
          lunchHours: dur(time!.lunchHours as string | undefined),
          date: time!.date,
        }))
      : []
  ) as TimeEntryRow[];

  const timeOffEntryData = (
    timeOff
      ? timeOff.map((timeOff) => ({
          ...timeOff,
          hours: dur(timeOff!.hours),
          type: timeOff!.timeOffType,
        }))
      : []
  ) as TimeOffRow[];

  const projectTimeData = (
    projectTime
      ? projectTime.map((time) => ({
          ...time,
          hours: dur(time!.hours),
        }))
      : []
  ) as ProjectTimeRow[];

  const expenseData = (
    expenses
      ? expenses.map((expense) => ({
          ...expense,
        }))
      : []
  ) as Expense[];

  return { timeEntryData, timeOffEntryData, projectTimeData, expenseData };
};
