import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApproveProjectRequestMutationVariables = Types.Exact<{
  memberId: Types.Scalars['String'];
  billingAdmin: Types.Scalars['String'];
  projectSupervisor: Types.Scalars['String'];
  billingFrequency: Types.BillingFrequencyType;
  invoiceNotes?: Types.InputMaybe<Types.Scalars['String']>;
  assignExpenseRateSheet?: Types.InputMaybe<Types.AssignRateSheetArgsInput>;
  assignTaskRateSheet?: Types.InputMaybe<Types.AssignRateSheetArgsInput>;
  corpLocation: Types.Scalars['String'];
  division: Types.Scalars['String'];
  customerSubAccountOf?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ApproveProjectRequestMutation = { __typename?: 'MutationModel', projectRequests?: { __typename?: 'ProjectRequestMutationModel', approveGroup?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const ApproveProjectRequestDocument = gql`
    mutation ApproveProjectRequest($memberId: String!, $billingAdmin: String!, $projectSupervisor: String!, $billingFrequency: BillingFrequencyType!, $invoiceNotes: String, $assignExpenseRateSheet: AssignRateSheetArgsInput, $assignTaskRateSheet: AssignRateSheetArgsInput, $corpLocation: String!, $division: String!, $customerSubAccountOf: String) {
  projectRequests {
    approveGroup(
      memberId: $memberId
      billingAdmin: $billingAdmin
      projectSupervisor: $projectSupervisor
      billingFrequency: $billingFrequency
      invoiceNotes: $invoiceNotes
      assignExpenseRateSheet: $assignExpenseRateSheet
      assignTaskRateSheet: $assignTaskRateSheet
      corpLocation: $corpLocation
      division: $division
      customerSubAccountOf: $customerSubAccountOf
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type ApproveProjectRequestMutationFn = Apollo.MutationFunction<ApproveProjectRequestMutation, ApproveProjectRequestMutationVariables>;

/**
 * __useApproveProjectRequestMutation__
 *
 * To run a mutation, you first call `useApproveProjectRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveProjectRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveProjectRequestMutation, { data, loading, error }] = useApproveProjectRequestMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      billingAdmin: // value for 'billingAdmin'
 *      projectSupervisor: // value for 'projectSupervisor'
 *      billingFrequency: // value for 'billingFrequency'
 *      invoiceNotes: // value for 'invoiceNotes'
 *      assignExpenseRateSheet: // value for 'assignExpenseRateSheet'
 *      assignTaskRateSheet: // value for 'assignTaskRateSheet'
 *      corpLocation: // value for 'corpLocation'
 *      division: // value for 'division'
 *      customerSubAccountOf: // value for 'customerSubAccountOf'
 *   },
 * });
 */
export function useApproveProjectRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveProjectRequestMutation, ApproveProjectRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveProjectRequestMutation, ApproveProjectRequestMutationVariables>(ApproveProjectRequestDocument, options);
      }
export type ApproveProjectRequestMutationHookResult = ReturnType<typeof useApproveProjectRequestMutation>;
export type ApproveProjectRequestMutationResult = Apollo.MutationResult<ApproveProjectRequestMutation>;
export type ApproveProjectRequestMutationOptions = Apollo.BaseMutationOptions<ApproveProjectRequestMutation, ApproveProjectRequestMutationVariables>;