import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import { createProvider } from "./createFilterProvider";
import {
  useLocationStateFromParam,
  useStateCountiesAsync,
} from "~/refdata2/stateCounties";
import StatePickerFormField from "~/visuals/organisms/StatePickerFormField";
import StateCounty from "~/graphql/StateCounty";
import _ from "lodash";

type LocationStateProviderProps = FilterProviderProps & {
  suggestText: string;
  label: string;
};

export function useLocationStateProvider(
  props: LocationStateProviderProps
): FilterProvider {
  const { suggestText, label } = props;
  const locationStateFromParam = useLocationStateFromParam();
  const getLocations = useStateCountiesAsync();

  const type = "locationState";

  return createProvider({
    render: () => (
      <StatePickerFormField formField={type} label={label} helperText="" />
    ),
    type,
    label,
    props,
    getItems: getLocations,
    itemFromParam: locationStateFromParam,
    shouldSuggestText: suggestText,
    toSuggestions: (result, text) =>
      _.uniqBy((result as StateCounty[]) ?? [], "state")
        .filter((s) => s.state.toLowerCase().includes(text.toLowerCase()))
        .map((s) => ({ text: s.state, value: s.state })),
    toLabelValue: (value: string) => value,
    toParamValue: (value: string) => value,
  });
}
