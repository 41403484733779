import { Maybe, ProjectTimeDailyHours } from "~/gql/types";
import { DailyHours } from "./stateMachine";
import moment from "moment";

type PopulatedDailyValues = {
  dailyHours: DailyHours[] | null;
};

export function toPopulatedDailyValues(
  dailyHours: Maybe<ProjectTimeDailyHours>[] | null
): PopulatedDailyValues {
  if (!dailyHours) {
    return {
      dailyHours: null,
    };
  }

  const toNumber = (hour: string) => moment.duration(hour).asHours();

  const convertedHours = dailyHours.map((x) => ({
    asBuilt: toNumber(x!.asBuilt),
    boundary: toNumber(x!.boundary),
    clStake: toNumber(x!.clStake),
    constRestake: toNumber(x!.constRestake),
    constStake: toNumber(x!.constStake),
    locate: toNumber(x!.locate),
    rainoutOther: toNumber(x!.rainoutOther),
    rerouteStake: toNumber(x!.rerouteStake),
    travelHours: toNumber(x!.travelHours),
    role: x!.role[0].toLowerCase() + x!.role.slice(1),
    employee: x!.employee,
  })) as DailyHours[];

  return {
    dailyHours: convertedHours,
  };
}
