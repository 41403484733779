import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeChargesProjectMutationVariables = Types.Exact<{
  newProjectId: Types.Scalars['Int'];
  editNotes: Types.Scalars['String'];
  projectCharges: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type ChangeChargesProjectMutation = { __typename?: 'MutationModel', projectCharges?: { __typename?: 'ProjectChargesMutationModel', changeProject?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const ChangeChargesProjectDocument = gql`
    mutation ChangeChargesProject($newProjectId: Int!, $editNotes: String!, $projectCharges: [Int!]!) {
  projectCharges {
    changeProject(
      newProjectId: $newProjectId
      editNotes: $editNotes
      projectCharges: $projectCharges
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type ChangeChargesProjectMutationFn = Apollo.MutationFunction<ChangeChargesProjectMutation, ChangeChargesProjectMutationVariables>;

/**
 * __useChangeChargesProjectMutation__
 *
 * To run a mutation, you first call `useChangeChargesProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeChargesProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeChargesProjectMutation, { data, loading, error }] = useChangeChargesProjectMutation({
 *   variables: {
 *      newProjectId: // value for 'newProjectId'
 *      editNotes: // value for 'editNotes'
 *      projectCharges: // value for 'projectCharges'
 *   },
 * });
 */
export function useChangeChargesProjectMutation(baseOptions?: Apollo.MutationHookOptions<ChangeChargesProjectMutation, ChangeChargesProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeChargesProjectMutation, ChangeChargesProjectMutationVariables>(ChangeChargesProjectDocument, options);
      }
export type ChangeChargesProjectMutationHookResult = ReturnType<typeof useChangeChargesProjectMutation>;
export type ChangeChargesProjectMutationResult = Apollo.MutationResult<ChangeChargesProjectMutation>;
export type ChangeChargesProjectMutationOptions = Apollo.BaseMutationOptions<ChangeChargesProjectMutation, ChangeChargesProjectMutationVariables>;