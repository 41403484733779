import { FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import {
  assignableOptions,
  AssignableFilterPicker,
} from "~/filterProviders/RatesheetAssignableProvider";
import { createSimpleProvider } from "./createSimpleProvider";

export function useRatesheetAssignableProvider(
  props: FilterProviderProps
): FilterProvider {
  return createSimpleProvider({
    documentationText: props.documentationText,
    type: "assignable",
    label: "Assignable",
    render: () => (
      <AssignableFilterPicker
        formField="assignable"
        label="Assignable"
        helperText=""
      />
    ),
    suggestText: "as",
    options: assignableOptions,
  });
}
