import React, { useReducer, useEffect, useState } from "react";
import StateCounty from "~/graphql/StateCounty";
import { WizardInner } from "~/perdiem-rate-wizard/WizardInner";
import Spinner from "~/spinner";
import {
  State,
  Action,
  reducer,
  createInitialState,
} from "~/perdiem-rate-wizard/stateMachine";
import { useSnackBar } from "~/snackbar";
import { Navigate } from "react-router-dom";
import { perDiemRates } from "~/routes";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import _ from "lodash";
import { useStateCounties } from "~/refdata2";
import { useCreatePerDiemRateMutation } from "./query.generated";

const PerDiemRateWizardWrapper: React.FC = () => {
  useBreadcrumbs(
    [{ text: "Per Diem Rates", to: perDiemRates.path }, { text: "Set Rates" }],
    []
  );

  const locations = useStateCounties();

  if (!locations) {
    return <Spinner open={true} />;
  }

  return <PerDiemRateWizard locations={locations!} />;
};

const PerDiemRateWizard: React.FC<{ locations: StateCounty[] }> = ({
  locations,
}) => {
  const [redirect, setRedirect] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const addAlert = useSnackBar();

  const [doMutation, { loading }] = useCreatePerDiemRateMutation();
  const initialState = createInitialState(locations);

  const [state, dispatch] = useReducer(
    (a: State, b: Action) => reducer(a, b),
    initialState as State
  );

  const parseVal = (val: any) => parseFloat(val);

  const createPerDiemRecords = async () => {
    const { data, errors } = await doMutation({
      variables: {
        mealsReimbursable: parseVal(state.mealsReimbursable),
        mealsBillable: parseVal(state.mealsBillable),
        lodgingReimbursable: parseVal(state.lodgingReimbursable),
        lodgingBillable: parseVal(state.lodgingBillable),
        travelReimbursable: parseVal(state.travelReimbursable),
        travelBillable: parseVal(state.travelBillable),
        effectiveDate: state.effectiveDate?.format("YYYY-MM-DD"),
        locations: state.selectedLocations.map((x) => ({
          state: x.state,
          county: x.county,
        })),
      },
    });

    const messages: string[] = [];
    if (data && data.perDiemRates?.create?.errors?.length > 0) {
      let errorMessage = "";
      data.perDiemRates?.create?.errors.forEach(({ message }) => {
        messages.push(message);
        errorMessage = messages.join(", ");
      });

      setErrorMessage(errorMessage);
    } else if (errors) {
      const messages: string[] = [];
      let errorMessage = "";
      errors.forEach(({ message }) => {
        messages.push(message);
        errorMessage = messages.join(", ");
      });

      setErrorMessage(errorMessage);
    } else if (data && (data.perDiemRates?.create?.errors.length ?? 0) === 0) {
      setSuccess(true);
    }
  };

  useEffect(() => {
    if (success && !redirect) {
      const numCounties = state?.selectedLocations?.length || 0;

      const states = new Set(state.selectedLocations.map((x) => x.state));
      addAlert({
        key: "Per Diem Rate Set",
        message:
          `Per diem rate set for ${numCounties}` +
          ` ${numCounties === 1 ? "county" : "counties"}` +
          ` in ${states.size} ${states.size === 1 ? "state" : "states"}`,
        isSuccess: true,
      });
      setRedirect(true);
    }
  }, [success, addAlert, redirect, state?.selectedLocations]);

  useEffect(() => {
    if (errorMessage) {
      dispatch({ tag: "Submitted", message: errorMessage });
    }
  }, [errorMessage]);

  if (redirect) {
    return <Navigate to={perDiemRates.path} />;
  }

  return (
    <>
      <WizardInner
        state={state}
        dispatch={dispatch}
        create={createPerDiemRecords}
      />
      <Spinner open={loading} />
    </>
  );
};

export default PerDiemRateWizardWrapper;
