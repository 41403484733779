import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import _ from "lodash";
import { TaskRate } from "~/gql/types";
import ExpenseTaskMruFormField from "../../ExpenseTaskMruPicker";

export type TaskMruPickerProps = FormFieldProps & {
  taskRates: TaskRate[];
  rateSheetName: string;
};

export const RECENT_TASKS_KEY = "recent-tasks";

const TaskMruFormField: React.FC<TaskMruPickerProps> = (props) => {
  const { taskRates } = props;

  return (
    <ExpenseTaskMruFormField
      {...{
        ...props,
        rates: taskRates as (TaskRate & { __typename: string })[],
        className: "task-picker",
        storageKey: RECENT_TASKS_KEY,
        menuClass: "task-picker-menuitem",
      }}
    />
  );
};

export default TaskMruFormField;
