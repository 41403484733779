import React from "react";
import { ProjectCharge } from "~/gql/types";
import { SingleChargeLoader } from "~/single-charge-loader";

type SplitChargeReloaderProps = {
  originalId: number;
  splitIds: number[];
  addCharge: (charge: ProjectCharge) => void;
  updateCharge: (charge: ProjectCharge) => void;
  onComplete: () => void;
};

export const SplitChargeReloader: React.FC<SplitChargeReloaderProps> = (
  props
) => {
  const { originalId, splitIds, onComplete, addCharge, updateCharge } = props;

  return (
    <>
      <SingleChargeLoader
        {...{
          dependency: splitIds.length > 0,
          callback: (charge: ProjectCharge) => {
            updateCharge(charge);
          },
          id: originalId,
        }}
      />
      {splitIds.map((id, idx) => (
        <SingleChargeLoader
          key={idx}
          {...{
            dependency: splitIds.length > 0,
            callback: (charge: ProjectCharge) => {
              addCharge(charge);

              if (idx === splitIds.length - 1) {
                onComplete();
              }
            },
            id,
          }}
        />
      ))}
    </>
  );
};
