import React, { createContext, useContext } from "react";
import "./Table.scss";
import cn from "classnames";

export type TableProps = {
  columnWidths: string;
  columnCount: number;
  grid?: boolean;
  selectable?: boolean;
  rowMinHeight?: number;
  className?: string;
  allSelected?: boolean;
  onSelectAll?: () => void;
  columnGap?: string;
};

export const Table: React.FC<TableProps> = (props) => {
  const { columnWidths, grid, children, className, columnGap } = props;

  const tableStyle = cn("wolfkrow-table", className, {
    grid,
  });

  return (
    <TableContext.Provider value={props}>
      <div
        className={tableStyle}
        style={{
          gridTemplateColumns: columnWidths,
          columnGap: columnGap ?? "16px",
        }}
      >
        {children}
      </div>
    </TableContext.Provider>
  );
};

export const TableContext = createContext<TableProps>({
  columnCount: 0,
  columnWidths: "",
});

export function useTableContext(): TableProps {
  const context = useContext(TableContext);

  if (!context) {
    throw new Error("useTableContext can only be used inside a Table");
  }

  const { selectable, allSelected, onSelectAll } = context;

  if (!selectable && (allSelected || onSelectAll)) {
    throw new Error(
      "Cannot use allSelected or onSelectAll when table is not selectable"
    );
  }

  return context;
}
