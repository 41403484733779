import React from "react";
import { Link } from "react-router-dom";
import { projects } from "~/routes";
import { Role } from "~/visuals/pages/Home/RoleStats";
import {
  MyRoleSubMenuItem,
  SubMenu,
  SubmenuListItem,
  SubmenuNavItem,
  roles,
  useSubmenuContext,
} from "./submenu";
import { AuthMenuItemProps, NavMenuProps } from "./types";

const activeFilter = "status=Active";
const searchFilter = () => activeFilter;
const path = projects.path;

export const MyProjectsMenuItem: React.FC<{ role: Role }> = ({ role }) => {
  return (
    <MyRoleSubMenuItem
      {...{
        role,
        toText: (role: Role) => `My Projects (${role.toUpperCase()})`,
        path,
        searchFilter,
        className: "my-projects",
      }}
    />
  );
};

const ProjectsSubMenu: React.FC = () => {
  const { closeNav } = useSubmenuContext();

  return (
    <SubMenu multiplier={4}>
      <Link to={`${projects.path}?${activeFilter}`} onClick={closeNav}>
        <SubmenuListItem text="All" className="project-submenu-item" />
      </Link>
      {roles.map((role, index) => (
        <MyProjectsMenuItem {...{ role }} key={index} />
      ))}
    </SubMenu>
  );
};

export const ProjectsNavItem: React.FC<AuthMenuItemProps & NavMenuProps> = (
  props
) => {
  if (!props.isVisible) {
    return null;
  }

  return (
    <SubmenuNavItem {...props}>
      <ProjectsSubMenu />
    </SubmenuNavItem>
  );
};
