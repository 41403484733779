import TitledCard from "~/titled-card";
import React from "react";
import { DivisionForm } from "./divisionForm";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";

type DivisionsPageProps = {
  refetch: React.DispatchWithoutAction;
};

export const CreateDivisionPage: React.FC<DivisionsPageProps> = ({
  refetch,
}) => {
  useBreadcrumbs(
    [{ text: "Divisions", to: "/divisions" }, { text: "Create" }],
    []
  );

  return (
    <TitledCard title={"Create Division"}>
      <DivisionForm {...{ refetch }} />
    </TitledCard>
  );
};
