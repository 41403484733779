import { Moment } from "moment";
import React from "react";
import {
  useBillingAdminProvider,
  useProjectManagerProvider,
} from "~/filterProviders/employeeProviders";
import { useCustomerProvider } from "~/filterProviders/useCustomerProvider";
import { useDateProvider } from "~/filterProviders/useDateProvider";
import { UltraFilter } from "~/ultra-filter";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<FilterOptions>;
};

export type FilterOptions = {
  searchText: string | null;
  startDate: Moment | null;
  endDate: Moment | null;
  projectManager: string | null;
  billingAdmin: string | null;
  customerNumber: string | null;
};

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const providers = [
    useBillingAdminProvider({
      documentationText: "The billing admin assigned to the charge's project.",
    }),
    useProjectManagerProvider({
      documentationText:
        "The project manager assigned to the charge's project.",
    }),
    useCustomerProvider({
      documentationText: "The customer associated with the charge's project.",
    }),
    useDateProvider({
      documentationText:
        "Search for projects with date in before this date (exclusive).",
      type: "startDate",
      label: "Start Date",
      suggestText: "sd",
    }),
    useDateProvider({
      documentationText:
        "Search for projects with date in after this date (exclusive).",
      type: "endDate",
      label: "End Date",
      suggestText: "ed",
    }),
  ] as FilterProvider[];
  const onChanged = (filters: ActiveFilter[], searchText: string) => {
    searchText;
    const newFilters = {
      searchText: searchText || null,
      billingAdmin:
        filters.find((f) => f.type === "billingAdmin")?.value
          .userPrincipalName || null,
      projectManager:
        filters.find((f) => f.type === "projectManager")?.value
          .userPrincipalName || null,
      customerNumber:
        filters.find((f) => f.type === "customer")?.value.number || null,
      startDate: filters.find((f) => f.type === "startDate")?.value || null,
      endDate: filters.find((f) => f.type === "endDate")?.value || null,
    };
    onFiltersChanged(newFilters);
  };
  return (
    <div className="filter-form">
      <UltraFilter
        {...{
          providers,
          dialogTitle: "Projects Status Search",
          label: "Type to search projects",
          onFiltersChanged: onChanged,
        }}
      />
    </div>
  );
};
