import React from "react";
import { OfficeBillingReport } from "./OfficeBillingReport";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import { useGetFieldOfficesQuery } from "~/visuals/pages/FieldOffices/query.generated";
import { FieldOffice } from "~/gql/types";
import Spinner from "~/spinner";

export const OfficeBillingReportPage: React.FC = () => {
  const { data, loading } = useGetFieldOfficesQuery();

  const fieldOffices = ((data && data.fieldOffices?.all) ??
    []) as FieldOffice[];

  const download = useReportFetcher();

  const downloadReport = async (
    setLoading: React.Dispatch<boolean>,
    year: number,
    offices: string[]
  ) => {
    await download({
      setLoading,
      fileName: `office-billing-${year}`,
      body: {
        year,
        offices,
      },
      path: "office-billing-csv",
      type: "text/csv;charset=utf-8;",
    });
  };

  return (
    <>
      <OfficeBillingReport
        {...{
          downloadReport,
          offices: fieldOffices.map((x) => x.officeCode),
        }}
      />
      <Spinner open={loading} />
    </>
  );
};
