import React from "react";
import ClickSwallower from "~/click-swallower";
import { useSnackBar } from "~/snackbar";
import { ValidationResponse } from "~/mockGraphqlServer/validationResult";
import { InlineProgress } from "~/inline-progress";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import { useMarkChargeTaxableMutation } from "./mutation.generated";
import { NotifierForm } from "~/forms/NotifierForm";

type EditChargeTaxableProps = {
  id: number;
  currentTaxable: boolean;
  refetch: React.DispatchWithoutAction;
};

export const EditChargeTaxable: React.FC<EditChargeTaxableProps> = ({
  id,
  currentTaxable,
  refetch,
}) => {
  const addAlert = useSnackBar();

  const [doMutation, { loading }] = useMarkChargeTaxableMutation();

  const handleSubmit = async (values: { taxable: boolean }) => {
    if (values.taxable === currentTaxable) {
      return;
    }

    const variables = {
      projectCharges: [id],
      taxable: values.taxable,
    };

    const result = await doMutation({ variables });

    const data = result?.data?.projectCharges
      ?.markTaxable as ValidationResponse;

    refetch();

    if (data && data.errors?.length > 0) {
      const errorMessages: string[] = [];
      data.errors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          errorMessages.push(`${argumentName}: ${message}`);
        } else if (message) {
          errorMessages.push(message);
        }
      });

      addAlert({
        key: `${id}`,
        isSuccess: false,
        message: errorMessages.join(" "),
      });
    }
  };

  return (
    <ClickSwallower>
      <NotifierForm
        onChange={handleSubmit}
        values={{ taxable: currentTaxable }}
      >
        {loading ? (
          <InlineProgress />
        ) : (
          <BinaryPickerFormField
            key={`${id}`}
            helperText=""
            label=""
            formField="taxable"
          />
        )}
      </NotifierForm>
    </ClickSwallower>
  );
};
