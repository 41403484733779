import * as Types from '../../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TaskRateSheetQueryVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type TaskRateSheetQuery = { __typename?: 'QueryModel', taskRates?: { __typename?: 'TaskRatesQueryModel', sheet?: { __typename?: 'TaskRateSheet', name: string, allowAssignment: boolean, endDate?: string | null, archived: boolean, rates?: Array<{ __typename?: 'TaskRate', name: string, billableAmount?: number | null, requiresDaily: boolean, rateType: Types.TaskRateType, unit: Types.TaskRateUnit, usesEmployeeRate: boolean, crewSize: number, glAccount?: string | null, certifiedPayroll: boolean } | null> | null } | null } | null };


export const TaskRateSheetDocument = gql`
    query TaskRateSheet($name: String!) {
  taskRates {
    sheet(name: $name) {
      name
      allowAssignment
      endDate
      rates {
        name
        billableAmount
        requiresDaily
        rateType
        unit
        usesEmployeeRate
        crewSize
        glAccount
        certifiedPayroll
      }
      archived
    }
  }
}
    `;

/**
 * __useTaskRateSheetQuery__
 *
 * To run a query within a React component, call `useTaskRateSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskRateSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskRateSheetQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTaskRateSheetQuery(baseOptions: Apollo.QueryHookOptions<TaskRateSheetQuery, TaskRateSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskRateSheetQuery, TaskRateSheetQueryVariables>(TaskRateSheetDocument, options);
      }
export function useTaskRateSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskRateSheetQuery, TaskRateSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskRateSheetQuery, TaskRateSheetQueryVariables>(TaskRateSheetDocument, options);
        }
export type TaskRateSheetQueryHookResult = ReturnType<typeof useTaskRateSheetQuery>;
export type TaskRateSheetLazyQueryHookResult = ReturnType<typeof useTaskRateSheetLazyQuery>;
export type TaskRateSheetQueryResult = Apollo.QueryResult<TaskRateSheetQuery, TaskRateSheetQueryVariables>;