import React from "react";
import { InlineTypeahead, InlineTypeaheadProps } from "~/inline-typeahead";
import { ProjectRefData } from "~/refdata/sources";
import { Division, useDivisionsAsync } from "~/refdata2/divisions";

const format = (item: Division): React.ReactNode => item.name;

type InlineDivisionPickerProps = {
  name: string;
  value: ProjectRefData;
  onChange: (val: Division | null) => void;
  onBlur?: React.FocusEventHandler<any>;
  required?: boolean;
  submitError?: string;
};

export const InlineDivisionPicker: React.FC<InlineDivisionPickerProps> = (
  props
) => {
  const getDivisions = useDivisionsAsync();

  const getData = async (searchText: string) => {
    const result = (await getDivisions()) ?? [];

    if (!searchText) {
      return result.filter((x) => x.isActive);
    }

    return result
      .filter((x) => x.isActive)
      .filter((t) => t.name.toLowerCase().includes(searchText.toLowerCase()));
  };

  const args = {
    ...props,
    getData,
    format,
  } as InlineTypeaheadProps<Division>;

  return <InlineTypeahead {...args} />;
};
