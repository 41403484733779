import React, { useEffect } from "react";
import { FilterForm, FilterFormProps, FilterOptions } from "./FilterForm";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import useInfiniteScroll from "~/search-page-wrapper/infinite-scroll/useInfiniteScroll";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import { LoadUntil } from "~/scrollTo/LoadUntil";
import { ScrollToProvider } from "~/scrollTo";
import { TextHighlighterProvider } from "~/text-highlighter/TextHighlighterProvider";
import { EmployeeRatesTable } from "./EmployeeRatesTable";
import { ResetSearchItemsProvider } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import { AddEmployeeRate } from "./AddEmployeeRate";
import { EmployeeRate } from "~/gql/types";
import { useApolloClient } from "@apollo/client";
import { EmployeeRatesDocument } from "./query.generated";
import { EmployeeRatesFilter } from "./types";
import { SearchFilterContainer } from "~/search-filter-container";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";

const Breadcrumbs: React.FC = () => {
  useBreadcrumbs([{ text: "Employee Rates" }], []);

  return <></>;
};

type EmployeeRatesPageProps = {
  FilterFormComponent?: typeof FilterForm;
  ScrollToProvider?: typeof ScrollToProvider;
};

export type EmployeeRatesPageDisplayProps = {
  employeeRates: EmployeeRate[] | null;
  setFilterOptions: React.Dispatch<FilterOptions>;
  FilterFormComponent: React.FC<FilterFormProps>;
  state: State<EmployeeRate, EmployeeRatesFilter>;
  dispatch: React.Dispatch<Action<EmployeeRate, EmployeeRatesFilter>>;
};

export const EmployeeRatesPageDisplay: React.FC<
  EmployeeRatesPageDisplayProps
> = ({
  FilterFormComponent,
  employeeRates,
  setFilterOptions,
  state,
  dispatch,
}) => {
  const interceptFilterOptions = (opts) => {
    setFilterOptions(opts);
  };

  return (
    <SearchPageWrapper>
      <SearchFilterContainer>
        <FilterFormComponent onFiltersChanged={interceptFilterOptions} />
      </SearchFilterContainer>
      <SearchListContent
        loading={state.tag === "Loading"}
        loadingMore={state.tag === "LoadingMore"}
        actions={<AddEmployeeRate />}
      >
        <Breadcrumbs />
        <EmployeeRatesTable {...{ employeeRates, state, dispatch }} />
      </SearchListContent>
    </SearchPageWrapper>
  );
};

type LoadEmployeeRatesProps = {
  dispatch: React.Dispatch<Action<EmployeeRate, EmployeeRatesFilter>>;
  tag: "DataLoaded" | "MoreDataLoaded";
  token: string | null;
  searchText: string | null;
  workLocation: string | null;
};

const LoadEmployeeRates = ({
  dispatch,
  tag,
  token,
  searchText,
  workLocation,
}: LoadEmployeeRatesProps) => {
  const client = useApolloClient();

  useEffect(() => {
    void (async () => {
      const variables = {
        token,
        searchText,
        workLocation,
      };

      const result = await client.query({
        query: EmployeeRatesDocument,
        variables,
      });

      const newToken = result.data?.employeeRates?.search?.token as
        | string
        | null;
      const newRates = result.data?.employeeRates?.search
        ?.records as EmployeeRate[];
      dispatch({ tag, items: newRates, searchToken: newToken });
    })();
  }, []);

  return <></>;
};

export type LoadRatesUntilProps = {
  dispatch: React.Dispatch<Action<EmployeeRate, EmployeeRatesFilter>>;
  searchText: string | null;
  workLocation: string | null;
  lastName: string;
};

export const LoadRatesUntil = ({
  dispatch,
  searchText,
  workLocation,
  lastName,
}: LoadRatesUntilProps): JSX.Element => {
  const client = useApolloClient();

  const getResults = async (token: string | null) => {
    const variables = {
      token: token === "" ? null : token,
      searchText,
      workLocation,
    };

    const result = await client.query({
      query: EmployeeRatesDocument,
      variables,
    });

    const newToken = result.data?.employeeRates?.search?.token as string | null;
    const newItems = result.data?.employeeRates?.search
      ?.records as EmployeeRate[];

    return { newToken, newItems };
  };

  const getIsLoaded = (rates: EmployeeRate[]): boolean => {
    const rate = rates.length > 0 ? rates[rates.length - 1] : null;
    const lastLastName = rate?.employee?.lastName ?? "";
    return lastLastName >= lastName;
  };

  return (
    <LoadUntil
      {...{ dispatch, currentFilter: lastName, getIsLoaded, getResults }}
    />
  );
};

export const EmployeeRates: React.FC<EmployeeRatesPageProps> = (props) => {
  const [searchParams] = useSearchParams();

  const searchParamValueOrNull = (key: string) => {
    return searchParams.get(key)?.length ? searchParams.get(key) : null;
  };

  const initialState = {
    tag: "Loading",
    items: [],
    showVisibility: false,
    loading: true,
    filterOptions: {
      searchText: searchParams.get("searchText") ?? "",
      workLocation: searchParamValueOrNull("workLocation"),
      lastName: searchParams.get("lastName") ?? null,
    },
    searchToken: null,
  };

  const { state, dispatch } = useInfiniteScroll<
    EmployeeRate,
    EmployeeRatesFilter
  >(initialState);

  const FilterFormComponent = props.FilterFormComponent || FilterForm;
  const ScrollProvider = props.ScrollToProvider || ScrollToProvider;

  const setFilterOptions = (opts: FilterOptions) =>
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: opts,
    });

  const loadProps = {
    dispatch,
    searchText: state.filterOptions.searchText,
    workLocation: state.filterOptions.workLocation,
  };

  const reset = () => {
    const params = new URLSearchParams(location.search);
    dispatch({
      tag: "Reset",
      filterOptions: {
        ...state.filterOptions,
        lastName: params.get("lastName"),
      },
    });
  };

  return (
    <ResetSearchItemsProvider reset={reset}>
      <ScrollProvider>
        <TextHighlighterProvider searchText={state.filterOptions.searchText}>
          {state.tag === "Loading" &&
            (state.filterOptions.lastName && !state.items.length ? (
              <LoadRatesUntil
                {...{
                  ...loadProps,
                  lastName: state.filterOptions.lastName,
                }}
              />
            ) : (
              <LoadEmployeeRates
                {...{
                  tag: "DataLoaded",
                  token: null,
                  ...loadProps,
                }}
              />
            ))}

          {state.tag === "LoadingMore" && (
            <LoadEmployeeRates
              {...{
                tag: "MoreDataLoaded",
                token: state.searchToken,
                ...loadProps,
              }}
            />
          )}

          <EmployeeRatesPageDisplay
            {...{
              FilterFormComponent,
              employeeRates: state.items,
              setFilterOptions,
              state,
              dispatch,
            }}
          />
        </TextHighlighterProvider>
      </ScrollProvider>
    </ResetSearchItemsProvider>
  );
};
