import React, { ReactElement } from "react";
import { Customer } from "~/gql/types";
import CustomerForm from "~/visuals/organisms/CustomerForm";

type EditCustomerProps = {
  customer: Customer;
};

export default function EditCustomer({
  customer,
}: EditCustomerProps): ReactElement {
  return <CustomerForm {...{ customer }} />;
}
