import { SaveAndAction } from "./SaveAndHandler";
import { ApiCallback } from "./WorkDayApiType";

export function toCallback(
  callbackMap: Partial<Record<SaveAndAction, ApiCallback>>,
  values: Record<string, any>
): ApiCallback | undefined {
  const actions: SaveAndAction[] = [
    "saveAndClone",
    "copyToDate",
    "copyToDates",
  ];

  const actionToExecute = Object.entries(values).find(
    ([k, v]) => actions.includes(k as any) && v
  );

  const callback = actionToExecute
    ? callbackMap[actionToExecute[0]]
    : undefined;

  return callback;
}
