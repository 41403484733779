import { useApolloClient } from "@apollo/client";
import { Project } from "~/gql/types";
import { ProjectSearchDocument } from "~/visuals/pages/Projects/query.generated";

type ProjectSearchArgs = {
  searchText?: string;
  token?: string;
  asOf?: string;
  projectNumbers?: number[];
};

export function useProjectSearch(): (args: ProjectSearchArgs) => Promise<{
  records: Project[];
  token: string | null;
}> {
  const client = useApolloClient();

  return async (args: ProjectSearchArgs) => {
    const { searchText, token, asOf, projectNumbers } = args;

    const result = await client.query({
      query: ProjectSearchDocument,
      variables: {
        searchText: searchText || null,
        token: token || null,
        asOf: asOf || null,
        projectNumbers: projectNumbers || null,
      },
    });

    const newToken = result.data?.projects?.search?.token as string | null;
    const records = result.data?.projects?.search?.records as Project[];

    return { records, token: newToken };
  };
}

export function useProjectFromParam(): (
  number: string
) => Promise<Project | null> {
  const getProjects = useProjectSearch();

  return async (number: string) => {
    const result = await getProjects({ projectNumbers: [+number] });
    if (!result?.records || result.records.length === 0) {
      return null;
    }

    return result.records[0] as Project;
  };
}
