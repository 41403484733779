import moment from "moment";
import React, { useEffect } from "react";
import { getExpiration } from "~/Auth/jwtUtils";
import { useRawJwt } from "~/Auth/AuthenticationManager";
import { useLogSessionDataMutation } from "./logData.generated";

export const sessionStart = "workflow-session-start";

export const formatDate = (time: Date): string =>
  moment.utc(time).format("YYYY-MM-DDTHH:mm:ss") + "Z";

export const durationFormat = (d): string => {
  const m = moment(d.asMilliseconds()).startOf("day").add(d);

  return m.format("HH:mm:ss");
};

export const SessionDataLogger: React.FC = () => {
  const [logData] = useLogSessionDataMutation();
  const token = useRawJwt();

  useEffect(() => {
    if (!window.sessionStorage.getItem(sessionStart)) {
      window.sessionStorage.setItem(sessionStart, new Date().toISOString());
    }

    const interval = 1000 * 60 * 5;

    const start = window.sessionStorage.getItem(sessionStart);

    const logDataFunction = async () => {
      const expiration = getExpiration(token!);

      const variables = {
        expirationTimestamp: formatDate(expiration),
        sessionLength: durationFormat(
          moment.duration(moment(new Date().toISOString()).diff(moment(start)))
        ),
        version: window.config.version,
      };

      const response = await logData({ variables });

      const result = response?.data?.users?.logSessionData;

      if (!response?.errors && result && result.errors!.length === 0) {
        console.log("Session data logged");
      }
    };

    const intervalFunc = setInterval(logDataFunction, interval);

    return () => {
      clearInterval(intervalFunc);
    };
  }, [token]);

  return <></>;
};
