import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateProjectGroupMutationVariables = Types.Exact<{
  customerNumber: Types.Scalars['String'];
  name: Types.Scalars['String'];
}>;


export type CreateProjectGroupMutation = { __typename?: 'MutationModel', projectGroups?: { __typename?: 'ProjectGroupMutationModel', create?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const CreateProjectGroupDocument = gql`
    mutation CreateProjectGroup($customerNumber: String!, $name: String!) {
  projectGroups {
    create(customerNumber: $customerNumber, name: $name) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type CreateProjectGroupMutationFn = Apollo.MutationFunction<CreateProjectGroupMutation, CreateProjectGroupMutationVariables>;

/**
 * __useCreateProjectGroupMutation__
 *
 * To run a mutation, you first call `useCreateProjectGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectGroupMutation, { data, loading, error }] = useCreateProjectGroupMutation({
 *   variables: {
 *      customerNumber: // value for 'customerNumber'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProjectGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectGroupMutation, CreateProjectGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectGroupMutation, CreateProjectGroupMutationVariables>(CreateProjectGroupDocument, options);
      }
export type CreateProjectGroupMutationHookResult = ReturnType<typeof useCreateProjectGroupMutation>;
export type CreateProjectGroupMutationResult = Apollo.MutationResult<CreateProjectGroupMutation>;
export type CreateProjectGroupMutationOptions = Apollo.BaseMutationOptions<CreateProjectGroupMutation, CreateProjectGroupMutationVariables>;