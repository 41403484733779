import React from "react";
import { useParams, Navigate } from "react-router-dom";
import EditTimeEntry from "./EditTime";
import { useWorkDayApi } from "~/WorkDayApi";

const EditTime: React.FC = () => {
  const { timeEntryId, date } = useParams<{
    timeEntryId: string;
    date: string;
  }>();
  const workDayApi = useWorkDayApi();

  const timeEntry = workDayApi.workDay.timeEntries.find(
    (entry) => entry.id === timeEntryId
  );

  if (!timeEntry) {
    return <Navigate to={"../../"} />;
  }

  return <EditTimeEntry {...{ timeEntry, date }} />;
};

export default EditTime;
