import {
  ExpenseRate,
  ExpenseRateSheetHistoryItem,
} from "~/graphql/ExpenseRateSheet";
import { ExpenseRateSheet, ReceiptValuedCategory } from "./gql/types";

const expenseRates = [
  {
    name: "Gatorade",
    invoiceLabel: "Gatorade Label",
    expenseAccount: "Account One",
    glAccount: "40000",
    requiresLocation: true,
    category: ReceiptValuedCategory.None,
    billable: false,
    __typename: "ReceiptValuedExpenseRate",
  },
  {
    name: "Gloves",
    invoiceLabel: "Gloves Label",
    expenseAccount: "Account One",
    glAccount: "40000",
    requiresLocation: false,
    category: ReceiptValuedCategory.None,
    billable: false,
    __typename: "ReceiptValuedExpenseRate",
  },
  {
    name: "Hotel",
    kind: "Lodging",
    billableOverride: 100,
    invoiceLabel: "Hotel Label",
    expenseAccount: "Account One",
    glAccount: "40000",
    __typename: "PerDiemExpenseRate",
    quantity: 1,
  },
  {
    name: "Lunch",
    kind: "Meals",
    billableOverride: 40,
    __typename: "PerDiemExpenseRate",
    invoiceLabel: "Lunch Label",
    expenseAccount: "Account One",
    glAccount: "40000",
    quantity: 1,
  },
  {
    name: "Gas",
    __typename: "FuelExpenseRate",
    invoiceLabel: "Gasoline",
    expenseAccount: "Account One",
    glAccount: "40000",
    billable: false,
  },
  {
    name: "Mobile Rate",
    __typename: "MobileAllowanceExpenseRate",
    invoiceLabel: "Mobile Label",
    expenseAccount: "Account One",
    glAccount: "40000",
    billableAmount: 100,
    reimbursableAmount: 75,
    quantity: 1,
  },
  {
    name: "Tcp Rate",
    __typename: "TcpAllowanceExpenseRate",
    invoiceLabel: "Tcp Label",
    expenseAccount: "Account One",
    glAccount: "40000",
    reimbursableAmount: 75,
  },
  {
    name: "Mileage Rate",
    __typename: "MileageExpenseRate",
    invoiceLabel: "Mileage Label",
    expenseAccount: "Account One",
    glAccount: "40000",
    billableAmountPerMile: 100,
    reimbursableAmountPerMile: 75,
  },
  {
    name: "Tires",
    invoiceLabel: "Tires",
    requiresLocation: true,
    expenseAccount: "Account One",
    glAccount: "40000",
    category: ReceiptValuedCategory.Automotive,
    __typename: "ReceiptValuedExpenseRate",
  },
  {
    name: "Atv Rate",
    __typename: "AtvExpenseRate",
    invoiceLabel: "Atv Label",
    expenseAccount: "Account One",
    glAccount: "40000",
    billableAmount: 100,
    reimbursableAmount: 75,
  },
  {
    __typename: "AdHocExpenseRate",
    rateSheetName: "Sheet One",
    name: "adHoc",
    invoiceLabel: "AdHoc Label",
    expenseAccount: "Account One",
    glAccount: "40000",
    rate: 75,
  },
] as ExpenseRate[];

const history = (sheetNum: string) =>
  [
    {
      changedBy: "Joe Bob",
      changedOn: "2021-01-10 15:30:00",
      title: "Created",
      details: [`Name: Sheet ${sheetNum}`],
    },
    {
      changedBy: "Sue Rodriquez",
      changedOn: "2021-02-10 18:30:00",
      title: "Edited",
      details: [
        `Old Name: Sheet ${sheetNum} Old`,
        `New Name: Sheet ${sheetNum}`,
      ],
    },
  ] as ExpenseRateSheetHistoryItem[];

export const expenseRateSheets = [
  {
    name: "Sheet One",
    allowAssignment: true,
    expenseRates: expenseRates,
    history: history("One"),
    archived: false,
  },
  {
    name: "Sheet Two",
    allowAssignment: false,
    expenseRates: expenseRates,
    history: history("Two"),
    archived: false,
  },
  {
    name: "Sheet Three",
    allowAssignment: true,
    expenseRates: expenseRates,
    history: history("Three"),
    archived: false,
  },
  {
    name: "Sheet Four",
    allowAssignment: true,
    expenseRates: expenseRates,
    history: history("Four"),
    archived: true,
  },
  {
    name: "Sheet Five",
    allowAssignment: false,
    expenseRates: expenseRates,
    history: history("Five"),
    archived: true,
  },
] as ExpenseRateSheet[];
