import React, { useState } from "react";
import { TabBar, Tab } from "~/visuals/pages/Project/ProjectDetails/tabs";
import "./ProjectDetails.scss";
import Card from "@material/react-card";
import _ from "lodash";
import { RateSheets } from "./RateSheets";
import { Page, ProjectDetailsProps } from "./types";
import { InvoicesTab } from "./Invoices";
import { EditProjectFormPage } from "~/ProjectForm";

const tabs: Page[] = ["project-info", "rate-sheets", "invoices"];

export const ProjectDetails: React.FC<ProjectDetailsProps> = ({ project }) => {
  const [selectedPage, setPage] = useState<Page>("project-info");

  return (
    <div className="project-details">
      <TabBar
        activeTabIndex={_.findIndex(tabs, (x) => x === selectedPage)}
        onActivate={(evt) => {
          setPage(tabs[evt.detail.index]);
        }}
      >
        <Tab key={0}>Project Info</Tab>
        <Tab key={1}>Rate Sheets</Tab>
        {project.canViewInvoices && <Tab key={2}>Invoices</Tab>}
      </TabBar>
      <Card>
        {selectedPage === "project-info" && (
          <EditProjectFormPage project={project} />
        )}
        {selectedPage === "rate-sheets" && <RateSheets project={project} />}
        {selectedPage === "invoices" && <InvoicesTab {...{ project }} />}
      </Card>
    </div>
  );
};
