import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditChargesCrewCodeMutationVariables = Types.Exact<{
  crewCode: Types.Scalars['String'];
  editNotes: Types.Scalars['String'];
  projectCharges?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
}>;


export type EditChargesCrewCodeMutation = { __typename?: 'MutationModel', projectCharges?: { __typename?: 'ProjectChargesMutationModel', editCrewCode?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const EditChargesCrewCodeDocument = gql`
    mutation EditChargesCrewCode($crewCode: String!, $editNotes: String!, $projectCharges: [Int!]) {
  projectCharges {
    editCrewCode(
      crewCode: $crewCode
      editNotes: $editNotes
      projectCharges: $projectCharges
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditChargesCrewCodeMutationFn = Apollo.MutationFunction<EditChargesCrewCodeMutation, EditChargesCrewCodeMutationVariables>;

/**
 * __useEditChargesCrewCodeMutation__
 *
 * To run a mutation, you first call `useEditChargesCrewCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChargesCrewCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChargesCrewCodeMutation, { data, loading, error }] = useEditChargesCrewCodeMutation({
 *   variables: {
 *      crewCode: // value for 'crewCode'
 *      editNotes: // value for 'editNotes'
 *      projectCharges: // value for 'projectCharges'
 *   },
 * });
 */
export function useEditChargesCrewCodeMutation(baseOptions?: Apollo.MutationHookOptions<EditChargesCrewCodeMutation, EditChargesCrewCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditChargesCrewCodeMutation, EditChargesCrewCodeMutationVariables>(EditChargesCrewCodeDocument, options);
      }
export type EditChargesCrewCodeMutationHookResult = ReturnType<typeof useEditChargesCrewCodeMutation>;
export type EditChargesCrewCodeMutationResult = Apollo.MutationResult<EditChargesCrewCodeMutation>;
export type EditChargesCrewCodeMutationOptions = Apollo.BaseMutationOptions<EditChargesCrewCodeMutation, EditChargesCrewCodeMutationVariables>;