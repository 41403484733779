import { useApolloClient } from "@apollo/client";
import moment from "moment";
import { ChargeType } from "~/gql/types";
import { ExpenseRate } from "~/graphql/ExpenseRateSheet";
import useIsMounted from "~/hooks/useIsMounted";
import { GetExpenseRatesForChargeFormDocument } from "./expenseRates.generated";
import { executeQuery } from "./fields/fieldUtils";
import { TaskRate } from "~/gql/types";
import { GetEffectiveTaskRatesDocument } from "./taskRates.generated";

type LoadRatesArgs = {
  date: string;
  projectNumber: number;
  type: ChargeType;
  callback: (args: {
    rateSheet: string;
    rates: ExpenseRate[] | TaskRate[];
  }) => void;
  setLoading: React.Dispatch<boolean>;
};

export function useLoadRates(): (args: LoadRatesArgs) => Promise<void> {
  const client = useApolloClient();
  const getIsMounted = useIsMounted();

  return async (args) => {
    const { date, projectNumber, type, callback, setLoading } = args;

    const asOf = moment(date).format("YYYY-MM-DD");
    const variables = { asOf, projectNumber };

    if (type === "Expense") {
      await executeQuery(async () => {
        const result = await client.query({
          query: GetExpenseRatesForChargeFormDocument,
          variables,
        });

        const rateSheetName =
          result?.data?.projects?.effectiveExpenseRateSheet?.name!;
        const rates = (result?.data?.projects?.effectiveExpenseRateSheet
          ?.expenseRates ?? []) as ExpenseRate[];

        if (getIsMounted()) {
          callback({ rateSheet: rateSheetName, rates });
        }
      }, setLoading);
    } else {
      await executeQuery(async () => {
        const result = await client.query({
          query: GetEffectiveTaskRatesDocument,
          variables,
        });

        const rateSheetName =
          result?.data?.projects?.effectiveTaskRateSheet?.name!;
        const rates = (result?.data?.projects?.effectiveTaskRateSheet?.rates ??
          []) as TaskRate[];

        if (getIsMounted()) {
          callback({ rateSheet: rateSheetName, rates });
        }
      }, setLoading);
    }
  };
}
