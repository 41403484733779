import { ApiCallback } from "~/WorkDayApi/WorkDayApiType";
import { useRelativeNav } from "~/relative-nav";
import { SaveAndAction } from "~/WorkDayApi/SaveAndHandler";
import { toCallback } from "~/WorkDayApi/utils";
import { ExpenseFormType } from "./CommonTypes";
import { CreateExpenseResponse } from "~/WorkDayApi/createExpenseApi";
import { useExpenseCopy } from "~/WorkDayApi/WorkDayCopyProvider";
import { cloneExpenseEntry } from "../expenseRoutes";

export function useExpenseCallback(): (
  values: Record<string, any>,
  type: ExpenseFormType
) => ApiCallback | undefined {
  const nav = useRelativeNav();

  const { setCopyToDate, setCopyToDates } = useExpenseCopy();

  return (values: Record<string, any>, type: ExpenseFormType) => {
    const relativePath = type === "Clone" ? "../../../" : "../../";

    const cloneCallback = (response: CreateExpenseResponse) =>
      Promise.resolve(
        nav(`${relativePath}${cloneExpenseEntry}/${response.expenseId}`)
      );

    const copyToDateCallback = (response: CreateExpenseResponse) =>
      Promise.resolve(setCopyToDate(response.expenseId));

    const copyToDatesCallback = (response: CreateExpenseResponse) =>
      Promise.resolve(setCopyToDates(response.expenseId));

    const callbackMap: Record<SaveAndAction, ApiCallback> = {
      saveAndClone: cloneCallback,
      copyToDate: copyToDateCallback,
      copyToDates: copyToDatesCallback,
    };

    return toCallback(callbackMap, values);
  };
}
