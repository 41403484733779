import React from "react";
import { InlineTypeahead, InlineTypeaheadProps } from "~/inline-typeahead";
import { CorpLocation, useCorpLocationsAsync } from "~/refdata2/corpLocations";

const format = (item: CorpLocation): React.ReactNode => item.name;

type InlineCorpLocationPickerProps = {
  name: string;
  value: CorpLocation;
  onChange: (val: CorpLocation | null) => void;
  onBlur?: React.FocusEventHandler<any>;
  required?: boolean;
  submitError?: string;
};

export const InlineCorpLocationPicker: React.FC<
  InlineCorpLocationPickerProps
> = (props) => {
  const getCorpLocations = useCorpLocationsAsync();

  const getData = async (searchText: string) => {
    const result = (await getCorpLocations()) ?? [];

    if (!searchText) {
      return result.filter((x) => x.isActive);
    }

    return result
      .filter((x) => x.isActive)
      .filter((t) => t.name.toLowerCase().includes(searchText.toLowerCase()));
  };

  const args = {
    ...props,
    getData,
    format,
  } as InlineTypeaheadProps<CorpLocation>;

  return <InlineTypeahead {...args} />;
};
