import React from "react";
import { InlineTypeahead, InlineTypeaheadProps } from "~/inline-typeahead";
import {
  ServiceDescription,
  useServiceDescriptionsAsync,
} from "~/refdata2/serviceDescriptions";
import "./InlineServiceDescriptionPicker.scss";

const format = (item: ServiceDescription): React.ReactNode => item.displayName;

type InlineServiceDescriptionPickerProps = {
  name: string;
  value: ServiceDescription;
  onChange: (val: ServiceDescription | null) => void;
  onBlur?: React.FocusEventHandler<any>;
  submitError?: string;
  required?: boolean;
};

export const InlineServiceDescriptionPicker: React.FC<
  InlineServiceDescriptionPickerProps
> = (props) => {
  const getServiceDescriptions = useServiceDescriptionsAsync();

  const getData = async (searchText: string) => {
    const result = ((await getServiceDescriptions()) ?? []).filter(
      (x) => x.isActive
    );

    if (!searchText) {
      return result;
    }

    return result.filter((t) =>
      t.displayName.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const args = {
    ...props,
    getData,
    format,
  } as InlineTypeaheadProps<ServiceDescription>;

  return (
    <div className="inline-service-description-picker">
      <InlineTypeahead {...args} />
    </div>
  );
};
