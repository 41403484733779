import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Button from "~/button";
import { TopActionButtons } from "~/top-action-buttons";
import { Body1 } from "@material/react-typography";
import styles from "./ReviewWrapper.module.scss";

type ReviewWrapperProps<T> = {
  selectedItems: T[];
  children: React.ReactNode;
  path: string;
  type: string;
  navFunc: (x: T) => string;
  filtersKey: string;
};

type WrapperProps<T> = ReviewWrapperProps<T> & {
  current: number;
  setCurrent: React.Dispatch<number>;
};

const Wrapper = <T,>(props: WrapperProps<T>) => {
  const {
    current,
    setCurrent,
    navFunc,
    selectedItems,
    type,
    filtersKey,
    path,
    children,
  } = props;

  const nav = useNavigate();

  const goNext = () => {
    nav(navFunc(selectedItems[current + 1]));
    setCurrent(current + 1);
  };

  const goPrev = () => {
    nav(navFunc(selectedItems[current - 1]));
    setCurrent(current - 1);
  };

  const goBack = () => {
    nav(`${path}${window.sessionStorage.getItem(filtersKey)}`);
  };

  return (
    <div>
      <div className={styles.ReviewWrapperTop}>
        <Body1>{`Reviewing ${current + 1} of ${
          selectedItems.length
        } selected ${type}`}</Body1>
        <TopActionButtons>
          <Button disabled={current === 0} onClick={goPrev}>
            Prev
          </Button>
          <Button
            disabled={current === selectedItems.length - 1}
            onClick={goNext}
          >
            Next
          </Button>
          <Button onClick={goBack}>Done</Button>
        </TopActionButtons>
      </div>
      {children}
    </div>
  );
};

export const ReviewWrapper = <T,>(
  props: ReviewWrapperProps<T>
): React.ReactElement => {
  const { children, selectedItems, path } = props;
  const [current, setCurrent] = useState(0);

  if (!selectedItems || selectedItems.length === 0) {
    return <Navigate to={path} />;
  }

  return <Wrapper {...{ ...props, current, setCurrent }}>{children}</Wrapper>;
};
