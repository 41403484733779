import React, { useState } from "react";
import Button from "~/button";
import { PopupMenuList } from "~/popup-menu-list";
import "./Reports.scss";
import { TimesheetAction, TimesheetState } from "./types";
import Spinner from "~/spinner";
import { useTimesheetCsvExporter } from "./TimesheetCsvExporter";
import { usePdfReportExporter } from "./PdfReportExporter";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import moment from "moment";
import { getTimeRangeBegin, getTimeRangeEnd, getDateSuffix } from "./utils";
import {
  BulkActionsPopup,
  BulkItem,
} from "~/search-page-wrapper/BulkActionsPopup";

type ReportsProps = {
  state: TimesheetState;
  dispatch?: TimesheetAction;
  outlined?: boolean;
};

function formatTimeSummaryFilename(startM, endM, inclusive) {
  const start = startM ? moment(startM).format("MM_DD_YY") : null;
  const end = endM ? moment(endM).format("MM_DD_YY") : null;

  if (inclusive) {
    return `Employee Time Summary ${start} - ${end}`;
  }

  const startPart = start ? `After ${start}` : "";
  const endPart = end ? `After ${end}` : "";

  const suffix = [startPart, endPart].filter((x) => x.length).join(" - ");

  return `Employee Time Summary - ${suffix}`;
}

export const Reports: React.FC<ReportsProps> = ({
  state,
  dispatch,
  outlined = false,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    searchText,
    weekStart,
    workLocation,
    weekStatus,
    reimbursement,
    payrollAdmin,
    payPeriod,
    showFuture,
    certifiedPayroll,
  } = state.filterOptions;

  const getReport = useReportFetcher();
  const pdfReportExporter = usePdfReportExporter();
  const csv = useTimesheetCsvExporter();

  const selectedWeeks = state.selectedItems;

  const hasSelected = selectedWeeks.length > 0;

  const exportCsv = async () => {
    setOpen(false);
    await csv({ state, setLoading, selectedWeeks });
  };

  const defaultProps = { state, setLoading, selectedWeeks };
  const { dateBefore, dateAfter } = state.filterOptions;

  const start = getTimeRangeBegin(state, selectedWeeks);
  const end = getTimeRangeEnd(state, selectedWeeks);

  const exportExpenseReport = async () => {
    setOpen(false);
    await pdfReportExporter({
      ...defaultProps,
      path: "timesheet-expense-report",
      useReimbursement: true,
    });
  };

  const exportProjectTimeReport = async () => {
    setOpen(false);
    await pdfReportExporter({ ...defaultProps, path: "project-time-report" });
  };

  const exportTimeEntryReport = async () => {
    setOpen(false);
    await pdfReportExporter({ ...defaultProps, path: "time-entry-report" });
  };

  const exportSignatureReport = async () => {
    setOpen(false);
    await pdfReportExporter({
      ...defaultProps,
      path: "timesheet-signature-report",
    });
  };

  const exportDailiesReport = async () => {
    setOpen(false);
    await pdfReportExporter({ ...defaultProps, path: "dailies" });
  };

  const exportReport = async (
    getReport: (body: Record<string, any>) => Promise<void>
  ) => {
    const body = {
      weekStart: weekStart?.format("YYYY-MM-DD") ?? null,
      dateBefore: state.filterOptions.dateBefore?.format("YYYY-MM-DD") ?? null,
      dateAfter: state.filterOptions.dateAfter?.format("YYYY-MM-DD") ?? null,
      searchText,
      workLocation,
      status: weekStatus,
      payrollAdmin: payrollAdmin?.userPrincipalName ?? null,
      payPeriod,
      reimbursement,
      timesheets: hasSelected ? selectedWeeks.map((x) => x.timesheetId) : null,
      showFuture: showFuture === "Yes",
      certifiedPayroll: certifiedPayroll === "Yes",
    };

    await getReport(body);
    setOpen(false);
  };

  const exportEmployeeTimeSummary = async () => {
    await exportReport(
      async (body) =>
        await getReport({
          setLoading,
          path: "employee-time-summary",
          fileName: formatTimeSummaryFilename(
            start,
            end,
            !(dateBefore || dateAfter)
          ),
          body,
          type: "text/csv;charset=utf-8;",
        })
    );
  };

  const exportProjectTimeCsvReport = async () => {
    await exportReport(
      async (body) =>
        await getReport({
          setLoading,
          path: "project-time-csv",
          fileName: `project-time-csv-${getDateSuffix(state, selectedWeeks)}`,
          body,
          type: "text/csv;charset=utf-8;",
        })
    );
  };

  const hasDateSpecified =
    state.filterOptions.weekStart || hasSelected || dateBefore || dateAfter;

  const needsDisabled = !hasDateSpecified;

  const defaultOptions = {
    disabled: needsDisabled,
    title: needsDisabled
      ? "Please select a week start in order to generate reports"
      : "",
  };

  const items = [
    {
      label: "Expense Report",
      onClick: exportExpenseReport,
      key: 1,
      dataItem: "expense-report",
      ...defaultOptions,
    },
    {
      label: "Time Entry Report",
      onClick: exportTimeEntryReport,
      key: 2,
      dataItem: "time-entry-report",
      ...defaultOptions,
    },
    {
      label: "Project Time PDF",
      onClick: exportProjectTimeReport,
      key: 3,
      dataItem: "project-time-report",
      ...defaultOptions,
    },
    {
      label: "Project Time CSV",
      onClick: exportProjectTimeCsvReport,
      key: 4,
      dataItem: "project-time-report",
      ...defaultOptions,
    },
    {
      label: "Excel Export",
      onClick: exportCsv,
      key: 5,
      dataItem: "excel-report",
      ...defaultOptions,
    },
    {
      label: "Employee Time Summary",
      onClick: exportEmployeeTimeSummary,
      key: 6,
      dataItem: "employee-time-summary",
      ...defaultOptions,
    },
    {
      label: "Project Time Dailies",
      onClick: exportDailiesReport,
      key: 7,
      dataItem: "dailies-report",
      ...defaultOptions,
    },
    {
      label: "Timesheet Signature",
      onClick: exportSignatureReport,
      key: 8,
      dataItem: "signature-report",
      ...defaultOptions,
    },
  ];

  return (
    <>
      {!hasSelected && !dispatch && (
        <div className="timesheet-reports-popup">
          <Spinner open={loading} />
          <Button onClick={() => setOpen(!open)} outlined={outlined}>
            Reports
          </Button>
          <PopupMenuList
            {...{ visible: open, items }}
            onMenuMouseEnter={() => undefined}
            onMenuMouseLeave={() => undefined}
            onClose={() => setOpen(false)}
            focusOnOpen={false}
            selectedIndex={0}
            anchorCorner="bottomRight"
          />
        </div>
      )}
      {hasSelected && dispatch && (
        <BulkActionsPopup
          {...{
            selectedItems: selectedWeeks!,
            onClear: () => dispatch({ tag: "ClearSelected" }),
            type: "Timesheet",
            buttonItems: items as BulkItem[],
          }}
        />
      )}
    </>
  );
};
