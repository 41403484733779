import React from "react";
import "./ExpenseForm.scss";
import TextFormField from "~/text-form-field";
import { FormProps } from "./CommonTypes";

import { AdHocExpense, AdHocExpenseRate } from "~/gql/types";
import ExpenseImageUploadWrapper from "./ExpenseImageUploadWrapper";
import { CrewCodePickerFormField } from "~/visuals/organisms/CrewCodePickerFormField";

type AdHocFormProps = FormProps & {
  setImageId: React.Dispatch<string | null>;
  expenseEntry?: AdHocExpense;
  expenseRate: AdHocExpenseRate;
};

const AdHocTypeForm: React.FC<AdHocFormProps> = (props) => {
  return (
    <ExpenseImageUploadWrapper typeName="AdHoc Expense" {...props}>
      <CrewCodePickerFormField
        label="Crew Code"
        formField="crewCode"
        helperText="The crew code applicable to this work. (use up/down arrows and enter key to select suggestions)"
        required
      />
      {props.expenseRate?.rate ? null : (
        <TextFormField
          label="Rate"
          helperText=""
          formField="rate"
          type="number"
          required
        />
      )}
      <TextFormField
        label="Quantity"
        helperText=""
        formField="quantity"
        type="number"
        required
      />
      <TextFormField label="Notes" helperText="" formField="notes" textarea />
    </ExpenseImageUploadWrapper>
  );
};

export default AdHocTypeForm;
