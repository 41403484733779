import React from "react";
import MdcTextField, { Input } from "@material/react-text-field";
import HelperTextLine from "~/HelperTextLine";
import "./CurrencyField.scss";
import classnames from "classnames";

export type CurrencyFieldProps = {
  label: string;
  name: string;
  value: string;
  helperText: string;
  disabled?: boolean;
  onChange: (string) => void;
  required?: boolean;
  testid?: string;
  isValid?: boolean;
  id: string;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
};

export const CurrencyFieldDisplay: React.FC<CurrencyFieldProps> = (
  props: CurrencyFieldProps
) => {
  const {
    label,
    id,
    name,
    value,
    helperText,
    disabled,
    onChange,
    required,
    isValid = true,
    testid,
    onBlur,
  } = props;

  const iconClass = classnames("material-icons", {
    "icon-invalid": !isValid,
  });

  return (
    <section className={"currency-form-field"} data-testid={testid}>
      <MdcTextField
        label={label + `${required ? " *" : ""}`}
        leadingIcon={<i className={iconClass}>attach_money</i>}
        helperText={<HelperTextLine message={helperText} isValid={isValid} />}
        onBlur={(event) => (onBlur ? onBlur(event) : undefined)}
      >
        <Input
          name={name}
          type={"text"}
          step=".01"
          min={0}
          id={id}
          value={value}
          isValid={isValid}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            onChange(evt.target.value)
          }
          aria-label={label}
          autoComplete="new-password"
          disabled={disabled}
        />
      </MdcTextField>
    </section>
  );
};

export default CurrencyFieldDisplay;
