import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditInvoiceNotesMutationVariables = Types.Exact<{
  invoiceNumber: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EditInvoiceNotesMutation = { __typename?: 'MutationModel', invoices?: { __typename?: 'InvoiceMutationModel', editNotes?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const EditInvoiceNotesDocument = gql`
    mutation EditInvoiceNotes($invoiceNumber: String!, $notes: String) {
  invoices {
    editNotes(invoiceNumber: $invoiceNumber, notes: $notes) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditInvoiceNotesMutationFn = Apollo.MutationFunction<EditInvoiceNotesMutation, EditInvoiceNotesMutationVariables>;

/**
 * __useEditInvoiceNotesMutation__
 *
 * To run a mutation, you first call `useEditInvoiceNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInvoiceNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInvoiceNotesMutation, { data, loading, error }] = useEditInvoiceNotesMutation({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useEditInvoiceNotesMutation(baseOptions?: Apollo.MutationHookOptions<EditInvoiceNotesMutation, EditInvoiceNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditInvoiceNotesMutation, EditInvoiceNotesMutationVariables>(EditInvoiceNotesDocument, options);
      }
export type EditInvoiceNotesMutationHookResult = ReturnType<typeof useEditInvoiceNotesMutation>;
export type EditInvoiceNotesMutationResult = Apollo.MutationResult<EditInvoiceNotesMutation>;
export type EditInvoiceNotesMutationOptions = Apollo.BaseMutationOptions<EditInvoiceNotesMutation, EditInvoiceNotesMutationVariables>;