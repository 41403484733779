import React, { FunctionComponent } from "react";
import MdcDrawer, {
  DrawerHeader,
  DrawerSubtitle,
  DrawerTitle,
  DrawerContent,
} from "@material/react-drawer";
import NavMenu from "./NavMenu";
import { useUser } from "~/Auth/useUser";
import { useLocation } from "react-router-dom";

interface DrawerProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
}

const Drawer: FunctionComponent<DrawerProps> = ({
  isOpen,
  setIsOpen,
  children,
}) => {
  const user = useUser();

  const location = useLocation();
  const isHome = location.pathname === "/";

  return (
    <>
      <MdcDrawer dismissible open={isOpen || isHome}>
        <DrawerHeader>
          <DrawerTitle tag="h2" className="mdc-theme--secondary">
            WorkFlow
          </DrawerTitle>
          <DrawerSubtitle>
            {user.name} ({user.email})
          </DrawerSubtitle>
        </DrawerHeader>

        <DrawerContent>
          <NavMenu
            {...{ navOpen: isOpen || isHome, closeNav: () => setIsOpen(false) }}
          />
        </DrawerContent>
      </MdcDrawer>
      <div
        className="mdc-drawer-app-content"
        onClick={() => isOpen && setIsOpen(false)}
      >
        {children}
      </div>
    </>
  );
};

export default Drawer;
