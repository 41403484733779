import { ApolloClient } from "@apollo/client";
import {
  CreateAdHocExpenseDocument,
  CreateAtvExpenseDocument,
  CreateFuelExpenseDocument,
  CreateMileageExpenseDocument,
  CreateMobileAllowanceExpenseDocument,
  CreatePerDiemExpenseDocument,
  CreateReceiptValuedExpenseDocument,
  CreateTcpAllowanceExpenseDocument,
  DeleteExpenseDocument,
  EditAdHocExpenseDocument,
  EditAtvExpenseDocument,
  EditFuelExpenseDocument,
  EditMileageExpenseDocument,
  EditMobileAllowanceExpenseDocument,
  EditPerDiemExpenseDocument,
  EditReceiptValuedExpenseDocument,
  EditTcpAllowanceExpenseDocument,
} from "./expenseQueries.generated";
import {
  CreateAtvExpenseArgs,
  CreateReceiptValuedExpenseArgs,
  EditAtvExpenseArgs,
  EditReceiptValuedExpenseArgs,
  CreateMobileAllowanceExpenseArgs,
  EditMobileAllowanceExpenseArgs,
  CreateMileageExpenseArgs,
  EditMileageExpenseArgs,
  CreateFuelExpenseArgs,
  EditFuelExpenseArgs,
  CreateTcpAllowanceExpenseArgs,
  EditTcpAllowanceExpenseArgs,
  CreatePerDiemExpenseArgs,
  EditPerDiemExpenseArgs,
  CreateAdHocExpenseArgs,
  EditAdHocExpenseArgs,
  DeleteExpenseArgs,
} from "./expenseTypes";
import {
  MutationAndResponseFn,
  MutationFn,
  ValidationResponse,
} from "./WorkDayApiType";

export type CreateExpenseResponse = ValidationResponse & { expenseId: string };

export type RawExpenseApi = {
  createAdHocExpense: MutationAndResponseFn<
    CreateAdHocExpenseArgs,
    CreateExpenseResponse
  >;
  editAdHocExpense: MutationFn<EditAdHocExpenseArgs>;
  createAtvExpense: MutationAndResponseFn<
    CreateAtvExpenseArgs,
    CreateExpenseResponse
  >;
  editAtvExpense: MutationFn<EditAtvExpenseArgs>;
  createReceiptValuedExpense: MutationAndResponseFn<
    CreateReceiptValuedExpenseArgs,
    CreateExpenseResponse
  >;
  editReceiptValuedExpense: MutationFn<EditReceiptValuedExpenseArgs>;
  createMobileAllowanceExpense: MutationAndResponseFn<
    CreateMobileAllowanceExpenseArgs,
    CreateExpenseResponse
  >;
  editMobileAllowanceExpense: MutationFn<EditMobileAllowanceExpenseArgs>;
  createMileageExpense: MutationAndResponseFn<
    CreateMileageExpenseArgs,
    CreateExpenseResponse
  >;
  editMileageExpense: MutationFn<EditMileageExpenseArgs>;
  createFuelExpense: MutationAndResponseFn<
    CreateFuelExpenseArgs,
    CreateExpenseResponse
  >;
  editFuelExpense: MutationFn<EditFuelExpenseArgs>;
  createTcpAllowanceExpense: MutationAndResponseFn<
    CreateTcpAllowanceExpenseArgs,
    CreateExpenseResponse
  >;
  editTcpAllowanceExpense: MutationFn<EditTcpAllowanceExpenseArgs>;
  createPerDiemExpense: MutationAndResponseFn<
    CreatePerDiemExpenseArgs,
    CreateExpenseResponse
  >;
  editPerDiemExpense: MutationFn<EditPerDiemExpenseArgs>;
  deleteExpense: MutationFn<DeleteExpenseArgs>;
};

export function createExpenseApi(client: ApolloClient<any>): RawExpenseApi {
  return {
    createAtvExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: CreateAtvExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.createAtvExpense as CreateExpenseResponse,
        callback,
      };
    },
    editAtvExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: EditAtvExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets?.editAtvExpense as ValidationResponse,
        callback,
      };
    },
    createFuelExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: CreateFuelExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.createFuelExpense as CreateExpenseResponse,
        callback,
      };
    },
    editFuelExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: EditFuelExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.editFuelExpense as ValidationResponse,
        callback,
      };
    },
    createMileageExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: CreateMileageExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.createMileageExpense as CreateExpenseResponse,
        callback,
      };
    },
    editMileageExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: EditMileageExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.editMileageExpense as ValidationResponse,
        callback,
      };
    },
    createMobileAllowanceExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: CreateMobileAllowanceExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.createMobileAllowanceExpense as CreateExpenseResponse,
        callback,
      };
    },
    editMobileAllowanceExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: EditMobileAllowanceExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.editMobileAllowanceExpense as ValidationResponse,
        callback,
      };
    },
    createPerDiemExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: CreatePerDiemExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.createPerDiemExpense as CreateExpenseResponse,
        callback,
      };
    },
    editPerDiemExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: EditPerDiemExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.editPerDiemExpense as ValidationResponse,
        callback,
      };
    },
    createReceiptValuedExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: CreateReceiptValuedExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.createReceiptValuedExpense as CreateExpenseResponse,
        callback,
      };
    },
    editReceiptValuedExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: EditReceiptValuedExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.editReceiptValuedExpense as ValidationResponse,
        callback,
      };
    },
    createTcpAllowanceExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: CreateTcpAllowanceExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.createTcpAllowanceExpense as CreateExpenseResponse,
        callback,
      };
    },
    editTcpAllowanceExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: EditTcpAllowanceExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.editTcpAllowanceExpense as ValidationResponse,
        callback,
      };
    },
    createAdHocExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: CreateAdHocExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.createAdHocExpense as CreateExpenseResponse,
        callback,
      };
    },
    editAdHocExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: EditAdHocExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets
          ?.editAdHocExpense as ValidationResponse,
        callback,
      };
    },
    deleteExpense: async (args, callback) => {
      const result = await client.mutate({
        mutation: DeleteExpenseDocument,
        variables: args,
      });

      return {
        response: result.data?.timesheets?.deleteExpense as ValidationResponse,
        callback,
      };
    },
  };
}
