import ClientOAuth2 from "client-oauth2";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  delayedReauthState,
  doRedirect,
  popupReauthState,
  silentReauthState,
} from "./authClient";
import { useAuthClient } from "./hooks";

type PopupRefreshProps = {
  silent?: boolean;
  _doRedirectFunc?: (auth: ClientOAuth2, state: string) => void;
};

export const PopupRefresh: React.FC<PopupRefreshProps> = ({
  silent,
  _doRedirectFunc,
}) => {
  const auth = useAuthClient();

  const [searchParams] = useSearchParams();
  const delayed = searchParams.get("tokenDelay") === "true";

  const authState = silent
    ? delayed
      ? delayedReauthState
      : silentReauthState
    : popupReauthState;

  const redirect = _doRedirectFunc ?? doRedirect;

  useEffect(() => {
    if (!auth) {
      return;
    }
    redirect(auth, authState);
  }, [auth]);
  return null;
};
