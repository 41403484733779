import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EffectiveExpenseRateSheetQueryVariables = Types.Exact<{
  projectNumber: Types.Scalars['Int'];
  asOf: Types.Scalars['LocalDate'];
}>;


export type EffectiveExpenseRateSheetQuery = { __typename?: 'QueryModel', projects?: { __typename?: 'ProjectsQueryModel', effectiveExpenseRateSheet?: { __typename?: 'ExpenseRateSheet', name: string, allowAssignment: boolean, expenseRates?: Array<{ __typename?: 'AdHocExpenseRate', rate?: number | null, name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | { __typename?: 'AtvExpenseRate', billableAmount: number, reimbursableAmount: number, name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | { __typename?: 'FuelExpenseRate', name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | { __typename?: 'MileageExpenseRate', billableAmountPerMile: number, reimbursableAmountPerMile: number, name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | { __typename?: 'MobileAllowanceExpenseRate', billableAmount: number, reimbursableAmount: number, name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | { __typename?: 'PerDiemExpenseRate', kind: Types.PerDiemKind, billableOverride?: number | null, name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | { __typename?: 'ReceiptValuedExpenseRate', requiresLocation: boolean, category: Types.ReceiptValuedCategory, name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | { __typename?: 'TcpAllowanceExpenseRate', reimbursableAmount: number, name: string, invoiceLabel?: string | null, expenseAccount?: string | null } | null> | null } | null } | null };


export const EffectiveExpenseRateSheetDocument = gql`
    query EffectiveExpenseRateSheet($projectNumber: Int!, $asOf: LocalDate!) {
  projects {
    effectiveExpenseRateSheet(asOf: $asOf, projectNumber: $projectNumber) {
      name
      allowAssignment
      expenseRates {
        name
        invoiceLabel
        expenseAccount
        ... on PerDiemExpenseRate {
          kind
          billableOverride
        }
        ... on ReceiptValuedExpenseRate {
          requiresLocation
          category
        }
        ... on MobileAllowanceExpenseRate {
          billableAmount
          reimbursableAmount
        }
        ... on TcpAllowanceExpenseRate {
          reimbursableAmount
        }
        ... on MileageExpenseRate {
          billableAmountPerMile
          reimbursableAmountPerMile
        }
        ... on AtvExpenseRate {
          billableAmount
          reimbursableAmount
        }
        ... on AdHocExpenseRate {
          rate
        }
      }
    }
  }
}
    `;

/**
 * __useEffectiveExpenseRateSheetQuery__
 *
 * To run a query within a React component, call `useEffectiveExpenseRateSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useEffectiveExpenseRateSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEffectiveExpenseRateSheetQuery({
 *   variables: {
 *      projectNumber: // value for 'projectNumber'
 *      asOf: // value for 'asOf'
 *   },
 * });
 */
export function useEffectiveExpenseRateSheetQuery(baseOptions: Apollo.QueryHookOptions<EffectiveExpenseRateSheetQuery, EffectiveExpenseRateSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EffectiveExpenseRateSheetQuery, EffectiveExpenseRateSheetQueryVariables>(EffectiveExpenseRateSheetDocument, options);
      }
export function useEffectiveExpenseRateSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EffectiveExpenseRateSheetQuery, EffectiveExpenseRateSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EffectiveExpenseRateSheetQuery, EffectiveExpenseRateSheetQueryVariables>(EffectiveExpenseRateSheetDocument, options);
        }
export type EffectiveExpenseRateSheetQueryHookResult = ReturnType<typeof useEffectiveExpenseRateSheetQuery>;
export type EffectiveExpenseRateSheetLazyQueryHookResult = ReturnType<typeof useEffectiveExpenseRateSheetLazyQuery>;
export type EffectiveExpenseRateSheetQueryResult = Apollo.QueryResult<EffectiveExpenseRateSheetQuery, EffectiveExpenseRateSheetQueryVariables>;