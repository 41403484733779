import React, { useEffect, useReducer, useState } from "react";
import { FilteredSearchBox } from "./FilteredSearchBox";
import { FilterDialog } from "./FilterDialog";
import { ActiveFilter, FilterProvider, Suggestion } from "./types";
import { State } from "./FilteredSearchBox/states";
import { reducer } from "./FilteredSearchBox/stateMachine";
import { Action } from "./FilteredSearchBox/actions";
import { useSearchParams } from "react-router-dom";
import Spinner from "~/spinner";

export type UltraFilterProps = {
  providers: FilterProvider[];
  dialogTitle: string;
  label: string;
  onFiltersChanged: (filters: ActiveFilter[], searchText: string) => void;
  FilterComponent?: typeof UltraFilterComponent;
  stats?: string;
  ignoreSearchText?: boolean;
};

export const UltraFilter: React.FC<UltraFilterProps> = (props) => {
  const {
    providers,
    FilterComponent = UltraFilterComponent,
    ignoreSearchText,
  } = props;
  const [searchParams] = useSearchParams();
  const [initialFilters, setInitialFilters] = useState<{
    activeFilters: ActiveFilter[];
    searchText: string;
  } | null>(null);

  useEffect(() => {
    void (async () => {
      const filters = await Promise.all(
        providers.map(async (p) => await p.filterFromParams(searchParams))
      );

      setInitialFilters({
        activeFilters: filters.filter((f) => !!f?.value) as ActiveFilter[],
        searchText: searchParams.get("searchText") ?? "",
      });
    })();
  }, []);

  if (initialFilters === null) {
    return <Spinner open={true} />;
  }

  return (
    <FilterComponent
      {...{
        ...props,
        activeFilters: initialFilters.activeFilters,
        searchText: ignoreSearchText ? "" : initialFilters.searchText,
      }}
    />
  );
};

export type UltraFilterComponentProps = UltraFilterProps & {
  activeFilters: ActiveFilter[];
  searchText: string;
  updateParams?: () => void;
};

const getCurrentParams = (
  params: URLSearchParams,
  providers: FilterProvider[],
  state: State
) => {
  const newParams = {};
  for (const [key, value] of params.entries()) {
    if (
      providers.find(
        (p) =>
          p.type === key && !state.activeFilters.find((f) => f.type === key)
      )
    ) {
      continue;
    }
    newParams[key] = value;
  }
  return newParams;
};

export const UltraFilterComponent: React.FC<UltraFilterComponentProps> = (
  props
) => {
  const {
    providers,
    dialogTitle,
    searchText,
    label,
    activeFilters,
    onFiltersChanged,
    stats,
    ignoreSearchText,
  } = props;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchInputText, setSearchInputText] = useState<string>(searchText);
  const [searchParamText, setSearchParamText] = useState<string>(searchText);

  const initialState = {
    providers,
    searchText,
    tag: "unfocused",
    activeFilters,
  } as State;

  const [state, dispatch] = useReducer(
    (a: State, b: Action) => reducer(a, b),
    initialState
  );
  const onDialogSubmit = (filters: ActiveFilter[]) =>
    dispatch({ tag: "update-filters", filters });

  const onSuggestionClick = (suggestion: Suggestion) => {
    if (state.tag === "filtering-suggestions") {
      const provider = state.selectedFilter;
      const newFilter = provider.toFilter(suggestion.value);
      dispatch({ tag: "select-filter", filter: newFilter });
    }
  };

  const doUpdate = () => {
    onFiltersChanged(state.activeFilters, searchParamText);
    const newParams = getCurrentParams(searchParams, providers, state);

    if (searchParamText) {
      newParams["searchText"] = searchParamText;
    } else {
      delete newParams["searchText"];
    }

    state.activeFilters.forEach((f) => {
      newParams[f.type] = providers.find((p) => p.type === f.type)?.toParam(f);
    });

    setSearchParams(newParams, { replace: true });
  };

  useEffect(() => {
    const listener = (evt: KeyboardEvent) => {
      if (evt.key === "Tab" && state.canKeyFilter) {
        evt.preventDefault();
        dispatch({ tag: "activate-filter" });
      }

      if (state.tag === "focused" && evt.key === "Enter" && !ignoreSearchText) {
        evt.preventDefault();
        setSearchParamText(searchInputText);
      }

      if (state.tag === "filtering-text" && state.selectedFilter.plainText) {
        if (evt.key === "Enter") {
          dispatch({
            tag: "select-filter",
            filter: state.selectedFilter.toFilter(state.searchText),
          });
        }
      }

      if (state.tag === "filtering-suggestions") {
        if (evt.key === "Enter") {
          const suggestion = state.suggestions[state.selectedIndex];
          const newFilter = state.selectedFilter.toFilter(suggestion.value);
          dispatch({ tag: "select-filter", filter: newFilter });
        }

        if (evt.key === "ArrowUp") {
          dispatch({ tag: "move-suggestions-up" });
        }

        if (evt.key === "ArrowDown") {
          dispatch({ tag: "move-suggestions-down" });
        }
      }

      if (
        state.tag === "focused" &&
        ["Backspace", "Delete"].includes(evt.key) &&
        !state.searchText
      ) {
        dispatch({
          tag: "remove-filter",
          filter: state.activeFilters[state.activeFilters.length - 1],
        });
      }

      if (
        ["Backspace", "Delete"].includes(evt.key) &&
        state.tag === "filtering-text" &&
        !state.searchText
      ) {
        dispatch({ tag: "cancel-filter" });
      }
    };
    document.addEventListener("keydown", listener);

    return () => document.removeEventListener("keydown", listener);
  }, [state]);

  const hasSelectedFilter =
    state.tag === "filtering-error" ||
    state.tag === "filtering-loading" ||
    state.tag === "filtering-suggestions" ||
    state.tag === "filtering-text";

  const onChange = async (text: string) => {
    dispatch({ tag: "update-text", searchText: text });
    setSearchInputText(text);
  };

  const helperTextMessage =
    state.tag === "filtering-error" ? state.errorMessage : state.helperText;

  useEffect(() => {
    if (
      !(hasSelectedFilter && state.selectedFilter.plainText) &&
      state.tag !== "focused"
    ) {
      dispatch({ tag: "load-suggestions" });
    }
  }, [searchInputText]);

  useEffect(() => {
    if (state.tag === "filtering-loading") {
      void (async () => {
        const result = await state.selectedFilter.getSuggestions(
          state.searchText
        );
        dispatch({ tag: "suggestions-loaded", suggestions: result });
      })();
    }
    if (hasSelectedFilter && searchParamText) {
      setSearchParamText("");
    }
  }, [state.tag]);

  useEffect(() => {
    doUpdate();
  }, [state.activeFilters, searchParamText]);

  return (
    <>
      <FilterDialog
        {...{
          open: dialogOpen,
          setOpen: setDialogOpen,
          providers: state.providers,
          filters: state.activeFilters,
          dialogTitle,
          onSubmit: onDialogSubmit,
        }}
      />
      <FilteredSearchBox
        {...{
          value: state.searchText,
          onChange,
          label,
          helperTextMessage,
          filters: state.activeFilters,
          filterPrompt: hasSelectedFilter ? state.selectedFilter.label : "",
          isError: state.tag === "filtering-error",
          loading: state.tag === "filtering-loading",
          suggestions:
            state.tag === "filtering-suggestions" ? state.suggestions : [],
          dialogOpen,
          setDialogOpen,
          dispatch,
          selectedIndex:
            state.tag === "filtering-suggestions" ? state.selectedIndex : 0,
          onSuggestionClick,
          stats,
        }}
      />
    </>
  );
};
