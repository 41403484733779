import React, { useState } from "react";
import FuelForm from "~/visuals/organisms/Expenses/ExpenseForm/FuelTypeForm";
import { useWorkDayApi } from "~/WorkDayApi";
import { FuelExpense, FuelExpenseRate } from "~/gql/types";
import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";
import { ApiCallback } from "~/WorkDayApi/WorkDayApiType";

type NewFuelProps = BaseExpenseFormProps & {
  expenseRate: FuelExpenseRate;
  expenseEntry?: FuelExpense;
};

const NewFuelExpense: React.FC<NewFuelProps> = (props) => {
  const { project, initialValues, expenseEntry, date } = props;

  const api = useWorkDayApi();

  const [imageId, setImageId] = useState<string | null>(
    initialValues?.receiptImageId ?? null
  );

  const uniqueInitVals: UniqueInitVals = [
    {
      payerInformation: expenseEntry?.payerInformation,
      odometer: expenseEntry?.odometer,
      receiptTotal: expenseEntry?.receiptTotal,
      vehicleNumber: expenseEntry?.vehicleNumber,
      quantity: expenseEntry?.quantity,
      companyVehicle: expenseEntry?.companyVehicle ?? false,
      receiptImage: null,
    },
    {
      payerInformation: null,
      odometer: null,
      receiptTotal: null,
      vehicleNumber: null,
      quantity: null,
      companyVehicle: false,
      receiptImage: null,
    },
  ];

  const parseNum = (val: any) => (val ? parseFloat(val) : null);

  const createExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc,
    callback?: ApiCallback
  ) => {
    const res = await api.createFuelExpense(
      {
        ...defaultVals(values),
        date,
        payerInformation: values?.payerInformation,
        odometer: parseNum(values?.odometer)!,
        receiptTotal: parseNum(values?.receiptTotal)!,
        companyVehicle: values?.companyVehicle,
        quantity: parseNum(values?.quantity)!,
        vehicleNumber: values?.vehicleNumber,
        receiptImageId: imageId,
        state: project?.location?.state!,
        county: project?.location?.county!,
      },
      callback
    );
    return res;
  };

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        handleSubmit: createExpense,
        expenseType: "Fuel",
        uniqueInitVals,
        formType: expenseEntry ? "Clone" : "Create",
      }}
    >
      <FuelForm {...props} setImageId={setImageId} />
    </ExpensePageWrapper>
  );
};

export default NewFuelExpense;
