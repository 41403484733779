import moment, { Moment } from "moment";

export function parseDate(value: string): Moment | null {
  if (!value.match(/^([0,1])?[0-9]{1}\/([0,1,2,3]{1})?[0-9]{1}\/[0-9]{4}$/)) {
    return null;
  }

  const momentValue = moment(value, "M/D/YYYY");

  if (!momentValue.isValid()) {
    return null;
  }

  return momentValue;
}
