import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  ReactElement,
} from "react";
import StateCounty from "~/graphql/StateCounty";
import FieldOffice from "~/graphql/FieldOffice";
import Employee from "~/graphql/Employee";
import CrewCodeRecord from "~/graphql/CrewCodeRecord";
import { DataSource, ProjectRefData } from "./sources";
import { useApolloClient } from "@apollo/client";
import { Project } from "~/gql/types";

type ReferenceDataProviderProps = {
  stateCounties?: StateCounty[];
  fieldOffices?: FieldOffice[];
  officeCodes?: string[];
  children: React.ReactNode;
  billingAdmins?: Employee[];
  payrollAdmins?: Employee[];
  projectSupervisors?: Employee[];
  projectManagers?: Employee[];
  allEmployees?: Employee[];
  crewCodes?: CrewCodeRecord[];
  projectTypes?: string[];
  divisions?: ProjectRefData[];
  corpLocations?: ProjectRefData[];
  activeProjects?: Project[];
};

type State = Record<string, any>;

type ContextType = {
  contextData: State;
  setContextData: React.Dispatch<React.SetStateAction<State>>;
};

type DataSourceState<TData> = {
  key: string;
  data?: TData[];
};

const ReferenceDataContext = createContext<ContextType>({
  contextData: {},
  setContextData: () => null,
});

function ReferenceDataProvider({
  children,
  ...props
}: ReferenceDataProviderProps): ReactElement {
  const propState = {};

  Object.entries(props).forEach(([key, value]) => {
    if (value) {
      propState[key] = { key, data: value };
    }
  });

  const [contextData, setContextData] = useState<State>(propState || {});

  return (
    <ReferenceDataContext.Provider
      value={{
        contextData,
        setContextData,
      }}
    >
      {children}
    </ReferenceDataContext.Provider>
  );
}

export function useDataSource<TData>(
  source: DataSource<TData>,
  variables?: Partial<Record<string, any>>
): TData[] | null {
  const client = useApolloClient();
  const context = useContext(ReferenceDataContext);

  if (!context) {
    throw new Error(
      "Do not use useDataSource outside of a ReferenceDataProvider."
    );
  }

  const { contextData, setContextData } = context;
  const spotSource = source.kind === "Spot" ? contextData[source.key] : null;

  const [dataSource, setDataSource] = useState<TData[] | null>(
    spotSource?.data ?? null
  );

  const state =
    source.kind === "Reference Data" && source.key
      ? (contextData[source.key] as DataSourceState<TData> | undefined)
      : null;

  useEffect(() => {
    if (state || source.kind !== "Reference Data" || !source.key) {
      return;
    }

    const key = source.key;

    setContextData((newContextData) => {
      if (newContextData[key]) {
        return newContextData;
      }

      let retries = 2;

      const setData = (data: TData[]) => {
        setContextData((newerContextData) => ({
          ...newerContextData,
          [key]: {
            ...newerContextData[key],
            data,
          },
        }));
      };

      const loadData = async () => {
        try {
          const data = await source.load(client);
          setData(data);
        } catch (e) {
          if (retries > 0) {
            retries--;
            await loadData();
          }
        }
      };

      void loadData();

      return {
        ...newContextData,
        [key]: { key },
      };
    });
  }, [setContextData, client, source, state]);

  useEffect(() => {
    if (source.kind != "Spot" || contextData[source.key]) {
      return;
    }

    let retries = 2;

    const getData = async () => {
      try {
        const data = await source.load(client, variables);
        setDataSource(data);
      } catch (e) {
        if (retries > 0) {
          retries--;
          await getData();
        }
      }
    };

    void getData();
  }, []);

  if (source.kind === "Reference Data") {
    return state?.data && state.data?.length >= 0 ? state.data : null;
  }

  if (source.kind === "Spot") {
    return dataSource && dataSource?.length >= 0 ? dataSource : null;
  }

  if (source.kind === "Preloaded") {
    return source.data || null;
  }

  return null;
}

export default ReferenceDataProvider;
