import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { SimplePicker } from "~/visuals/organisms/SimplePicker";
import { Suggestion } from "~/ultra-filter/types";

export const reimbursementsOptions = [
  { text: "Reimbursements only", value: "ReimbursementsOnly" },
  { text: "No Reimbursements", value: "NoReimbursements" },
  { text: "(All)", value: "All" },
] as Suggestion[];

export const ReimbursementPicker: React.FC<FormFieldProps> = (props) => (
  <SimplePicker {...{ ...props, options: reimbursementsOptions }} />
);
