import React, { useContext, useReducer } from "react";
import { getAzureAuthClient, getGoogleAuthClient } from "../authClient";
import { adAuthCallback, googleAuthCallback } from "~/routes";
import { State, Action, reducer } from "./stateMachine";
import { useLocation } from "react-router-dom";
import {
  dispatchLogout,
  AuthenticationManagerProps,
  tokenStorageKey,
} from "./common";
import { AuthenticationManagerReactor } from "./AuthenticationManagerReactor";
import {
  AuthenticationManagerContext,
  AuthenticationManagerContextData,
  AuthenticationManagerDisplay,
} from "./AuthenticationManagerDisplay";
import { isExpired } from "../jwtUtils";
import "./Auth.scss";
import { UserActiveProvider } from "../useIsActive";
import { Authorizations } from "../types";

export const AuthenticationManager: React.FC<AuthenticationManagerProps> = ({
  createAzureAuth = getAzureAuthClient,
  createGoogleAuth = getGoogleAuthClient,
  children,
  getAuthorizations,
}) => {
  const location = useLocation();

  const [state, dispatch] = useReducer<React.Reducer<State, Action>, any>(
    reducer,
    undefined,
    () => {
      const existingToken = localStorage.getItem(tokenStorageKey);

      if (location.pathname === googleAuthCallback.path) {
        return { tag: "ValidatingGoogle" };
      }

      if (location.pathname === adAuthCallback.path) {
        return { tag: "ValidatingAzure" };
      }

      if (existingToken && !isExpired(existingToken)) {
        return { tag: "ValidatingLocal", token: existingToken };
      }

      return { tag: "Unauthenticated" };
    }
  );

  return (
    <UserActiveProvider>
      <AuthenticationManagerDisplay
        {...{ state, dispatch, createAzureAuth, createGoogleAuth, children }}
      />
      <AuthenticationManagerReactor
        {...{
          state,
          dispatch,
          createAzureAuth,
          createGoogleAuth,
          getAuthorizations,
        }}
      />
    </UserActiveProvider>
  );
};

function useAuthenticationManagerContext(
  caller: string
): AuthenticationManagerContextData {
  const ctx = useContext(AuthenticationManagerContext);

  if (ctx === null) {
    throw new Error(`Do not call ${caller} outside of AuthenticationManager`);
  }

  return ctx;
}

export function useRawJwt(): string | null {
  const ctx = useAuthenticationManagerContext("useRawJwt");

  return ctx?.jwt;
}

export function useAuthorizations(): Authorizations | null {
  const ctx = useAuthenticationManagerContext("useAuthorizations");

  return ctx?.authorizations;
}

export function useLogout(): (preventPublishToOtherWindows?: boolean) => void {
  const ctx = useAuthenticationManagerContext("useLogout");

  return (preventPublishToOtherWindows?: boolean) => {
    dispatchLogout(ctx.dispatch, preventPublishToOtherWindows);
  };
}

export { tokenStorageKey } from "./common";
