import { Body2 } from "@material/react-typography";
import React from "react";
import LineBreaker from "~/line-breaker";
import "./NotesDisplay.scss";

type NotesDisplayProps = {
  notes: string;
  adminNotes: string | null;
};

export const NotesDisplay: React.FC<NotesDisplayProps> = ({
  notes,
  adminNotes,
}) => {
  const chargeNotes = [
    notes ?? "",
    adminNotes ? `Edit Notes - ${adminNotes}` : "",
  ]
    .filter((x) => x)
    .join("\n");

  return (
    <Body2 className="notes-display">
      <LineBreaker text={chargeNotes} />
    </Body2>
  );
};
