import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignExpenseRateSheetToProjectMutationVariables = Types.Exact<{
  rateSheetName: Types.Scalars['String'];
  projectNumbers: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
  effectiveDate: Types.Scalars['LocalDate'];
}>;


export type AssignExpenseRateSheetToProjectMutation = { __typename?: 'MutationModel', expenseRates?: { __typename?: 'ExpenseRatesMutationModel', assignRateSheetToProject?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const AssignExpenseRateSheetToProjectDocument = gql`
    mutation AssignExpenseRateSheetToProject($rateSheetName: String!, $projectNumbers: [Int!]!, $effectiveDate: LocalDate!) {
  expenseRates {
    assignRateSheetToProject(
      rateSheetName: $rateSheetName
      projectNumbers: $projectNumbers
      effectiveDate: $effectiveDate
    ) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type AssignExpenseRateSheetToProjectMutationFn = Apollo.MutationFunction<AssignExpenseRateSheetToProjectMutation, AssignExpenseRateSheetToProjectMutationVariables>;

/**
 * __useAssignExpenseRateSheetToProjectMutation__
 *
 * To run a mutation, you first call `useAssignExpenseRateSheetToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignExpenseRateSheetToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignExpenseRateSheetToProjectMutation, { data, loading, error }] = useAssignExpenseRateSheetToProjectMutation({
 *   variables: {
 *      rateSheetName: // value for 'rateSheetName'
 *      projectNumbers: // value for 'projectNumbers'
 *      effectiveDate: // value for 'effectiveDate'
 *   },
 * });
 */
export function useAssignExpenseRateSheetToProjectMutation(baseOptions?: Apollo.MutationHookOptions<AssignExpenseRateSheetToProjectMutation, AssignExpenseRateSheetToProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignExpenseRateSheetToProjectMutation, AssignExpenseRateSheetToProjectMutationVariables>(AssignExpenseRateSheetToProjectDocument, options);
      }
export type AssignExpenseRateSheetToProjectMutationHookResult = ReturnType<typeof useAssignExpenseRateSheetToProjectMutation>;
export type AssignExpenseRateSheetToProjectMutationResult = Apollo.MutationResult<AssignExpenseRateSheetToProjectMutation>;
export type AssignExpenseRateSheetToProjectMutationOptions = Apollo.BaseMutationOptions<AssignExpenseRateSheetToProjectMutation, AssignExpenseRateSheetToProjectMutationVariables>;