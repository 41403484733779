import React, { useEffect, useState } from "react";
import { FilterForm } from "./FilterForm";
import { ProjectChargesCard } from "./ProjectChargesCard";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { VisibilityListener } from "~/visibility-listener";
import useInfiniteScroll from "~/search-page-wrapper/infinite-scroll/useInfiniteScroll";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import { LoadUntil } from "~/scrollTo/LoadUntil";
import { ScrollToProvider } from "~/scrollTo";
import { ResetSearchItemsProvider } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import { ChargesReports } from "./ChargesReports";
import { SearchFilterContainer } from "~/search-filter-container";
import { ChargeBulkActions } from "~/visuals/organisms/ChargeBulkActions";
import { SearchStatsProvider, useSearchStats } from "./SearchStatsProvider";
import { SelectAllCheckbox } from "~/search-page-wrapper/BulkCheckboxes/SelectAllCheckbox";
import { LoadAllCharges } from "./LoadAllCharges";
import { Project, ProjectCharge, ProjectSortBy } from "~/gql/types";
import {
  ProjectChargesSearchWithStatsDocument,
  useProjectChargesSearchQuery,
  useProjectChargesSearchWithStatsQuery,
} from "./query.generated";
import { useApolloClient } from "@apollo/client";
import { SortCharges } from "./SortCharges";
import { ProjectCountReactor } from "./ProjectCountReactor";
import { ProjectPlaceholder } from "./ProjectPlaceholder";
import { ProjectChargesFilter } from "./types";
import { formattedDate } from "./utils";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";

const Breadcrumbs: React.FC = () => {
  useBreadcrumbs([{ text: "Project Charges" }], []);

  return <></>;
};

type ProjectChargesPageProps = {
  FilterFormComponent?: typeof FilterForm;
  ScrollToProvider?: typeof ScrollToProvider;
};

export type ProjectChargesPageDisplayProps = {
  showSpinner: boolean;
  loadingMore: boolean;
  projectCharges: ProjectCharge[] | null;
  state: State<ProjectCharge, ProjectChargesFilter>;
  dispatch: React.Dispatch<Action<ProjectCharge, ProjectChargesFilter>>;
};

export const getOrderer = (sortBy: ProjectSortBy) => {
  if (sortBy === "ProjectId") {
    return [
      (x) => x.project!.number,
      (x) => x.project!.customer!.name.toLowerCase(),
    ];
  }

  if (sortBy === "ProjectName") {
    return [
      (x) => x.project!.name.toLowerCase(),
      (x) => x.project!.customer!.name.toLowerCase(),
    ];
  }

  return [
    (x) => x.project!.customer!.name.toLowerCase(),
    (x) => x.project!.number,
  ];
};

export const arrangeCharges = (
  charges: ProjectCharge[] | null,
  orderer: ((x: any) => any)[]
) => {
  const projects = _.chain(charges)
    .groupBy((x) => x.project!.number)
    .mapValues((vals) => ({
      project: vals[0].project,
      charges: vals,
    }))
    .values()
    .value();

  const sortedProjects = _.orderBy(projects, orderer);

  return sortedProjects;
};

export const ProjectChargesPageDisplay: React.FC<
  ProjectChargesPageDisplayProps
> = ({ showSpinner, loadingMore, projectCharges, state, dispatch }) => {
  const projects = arrangeCharges(
    projectCharges,
    getOrderer(state.filterOptions.sortBy)
  ) as { project: Project; charges: ProjectCharge[] }[];

  return (
    <SearchListContent
      loadingMore={loadingMore}
      loading={showSpinner}
      actions={
        <SelectAllCheckbox
          {...{ state, dispatch, checkboxMargin: "8px 0 0 13px" }}
        >
          <LoadAllCharges {...{ state, dispatch }} />
        </SelectAllCheckbox>
      }
    >
      <Breadcrumbs />
      <div className="project-charges-display">
        {projects &&
          projects.map((proj, idx) => (
            <ProjectChargesCard {...{ ...proj, state, dispatch }} key={idx} />
          ))}

        <ProjectPlaceholder {...{ state, dispatch }} />

        {state.showVisibility && (
          <VisibilityListener
            onShown={() => {
              dispatch({ tag: "ScrolledToBottom" });
            }}
            height={10}
          />
        )}
        <ChargeBulkActions
          {...{
            state,
            dispatch,
            loading: showSpinner || loadingMore,
          }}
        />
      </div>
    </SearchListContent>
  );
};

type LoadProjectChargesProps = {
  dispatch: React.Dispatch<Action<ProjectCharge, ProjectChargesFilter>>;
  tag: "DataLoaded" | "MoreDataLoaded";
  token: string | null;
  filters: ProjectChargesFilter;
};

const LoadProjectCharges = ({
  dispatch,
  tag,
  token,
  filters: {
    employee,
    billingAdmin,
    projectManager,
    projectSupervisor,
    searchText,
    project,
    customer,
    state,
    billable,
    dateAfter,
    dateBefore,
    dateEqual,
    afeWoPo,
    officeCode,
    sortBy,
  },
}: LoadProjectChargesProps) => {
  const { setStats } = useSearchStats();

  const queryHook = token
    ? useProjectChargesSearchQuery
    : useProjectChargesSearchWithStatsQuery;

  const variables = {
    token,
    employee: employee?.userPrincipalName ?? null,
    billingAdmin: billingAdmin?.userPrincipalName ?? null,
    projectSupervisor: projectSupervisor?.userPrincipalName ?? null,
    projectManager: projectManager?.userPrincipalName ?? null,
    searchText: searchText || null,
    projectNumber: project?.number || null,
    customerNumber: customer?.number || null,
    state: state || null,
    billable: billable || null,
    dateEqual: formattedDate(dateEqual),
    dateBefore: formattedDate(dateBefore),
    dateAfter: formattedDate(dateAfter),
    afeWoPo: afeWoPo || null,
    officeCode: officeCode?.officeCode ?? null,
    sortBy: sortBy as ProjectSortBy,
  };

  const { data } = queryHook({ variables });

  useEffect(() => {
    if (data) {
      const newToken = data?.projectCharges?.search?.token as string | null;
      const newProjectCharges = data?.projectCharges?.search
        ?.records as ProjectCharge[];
      const stats = data?.projectCharges?.["stats"];
      if (stats) {
        setStats(stats);
      }
      dispatch({ tag, items: newProjectCharges, searchToken: newToken });
    }
  }, [data]);

  return <></>;
};

export type LoadChargesUntilProps = {
  dispatch: React.Dispatch<Action<ProjectCharge, ProjectChargesFilter>>;
  filters: ProjectChargesFilter;
};

export const LoadChargesUntil = ({
  dispatch,
  filters: {
    employee,
    billingAdmin,
    projectManager,
    projectSupervisor,
    searchText,
    project,
    customer,
    state,
    billable,
    dateAfter,
    dateBefore,
    dateEqual,
    afeWoPo,
    sortBy,
    officeCode,
    projectNumber,
  },
}: LoadChargesUntilProps): JSX.Element => {
  const { setStats } = useSearchStats();

  const client = useApolloClient();
  const [searchParams] = useSearchParams();

  const variables = {
    employee: employee?.userPrincipalName ?? null,
    billingAdmin: billingAdmin?.userPrincipalName ?? null,
    projectSupervisor: projectSupervisor?.userPrincipalName ?? null,
    projectManager: projectManager?.userPrincipalName ?? null,
    searchText: searchText || null,
    projectNumber: project?.number || null,
    customerNumber: customer?.number || null,
    state: state || null,
    billable: billable || null,
    dateEqual: formattedDate(dateEqual),
    dateBefore: formattedDate(dateBefore),
    dateAfter: formattedDate(dateAfter),
    afeWoPo: afeWoPo || null,
    officeCode: officeCode?.officeCode ?? null,
    sortBy,
  };

  const getResults = async (token: string | null) => {
    const { data } = await client.query({
      query: ProjectChargesSearchWithStatsDocument,
      variables: { ...variables, token: token || null },
    });
    const newToken = data?.projectCharges?.search?.token as string | null;
    const newItems = data?.projectCharges?.search?.records as any[];
    const stats = data?.projectCharges?.stats;
    if (stats) {
      setStats(stats);
    }

    return { newToken, newItems };
  };

  const getCountExceeded = (items: ProjectCharge[]): boolean => {
    const projectCount = searchParams.get("projectCount");

    const countExceeded =
      projectCount !== null &&
      projectCount !== "" &&
      new Set(items.map((x) => x.projectId)).size > +projectCount * 1.01;

    return countExceeded;
  };

  const getIsLoaded = (items: ProjectCharge[]): boolean => {
    return (
      items.some((x) => x.projectId === +projectNumber!) ||
      getCountExceeded(items)
    );
  };

  return (
    <LoadUntil
      {...{
        dispatch,
        currentFilter: projectNumber!,
        getIsLoaded,
        getResults,
        getCountExceeded,
      }}
    />
  );
};

export const ProjectChargesPage: React.FC<ProjectChargesPageProps> = (
  props
) => {
  const [filters, setFilters] = useState<ProjectChargesFilter | null>(null);
  const FilterFormComponent = props.FilterFormComponent || FilterForm;
  const [searchParams, setSearchParams] = useSearchParams();

  const setFilterOptions = (opts: ProjectChargesFilter) => setFilters(opts);

  return (
    <SearchStatsProvider>
      <SearchPageWrapper withSort withPadding>
        <SearchFilterContainer>
          <FilterFormComponent onFiltersChanged={setFilterOptions} />
          <SortCharges
            {...{
              filters,
              setFilters,
              searchParams,
              setSearchParams,
              type: "ProjectSortBy",
            }}
          />
          <ChargesReports {...{ filters }} />
        </SearchFilterContainer>
        {filters && <ProjectChargesResults {...{ ...props, filters }} />}
      </SearchPageWrapper>
    </SearchStatsProvider>
  );
};

const ProjectChargesResults: React.FC<
  ProjectChargesPageProps & { filters: ProjectChargesFilter }
> = (props) => {
  const [searchParams] = useSearchParams();
  const { filters } = props;
  const { setState } = useSearchStats();

  const initialState = {
    tag: "Loading",
    items: [],
    showVisibility: false,
    loading: true,
    filterOptions: filters,
    searchToken: null,
    selectIdKey: "id",
    selectedItems: [],
  } as State<ProjectCharge, ProjectChargesFilter>;

  const { state, dispatch } = useInfiniteScroll<
    ProjectCharge,
    ProjectChargesFilter
  >(initialState);

  const defaultLoadProps = {
    filters: state.filterOptions,
    dispatch,
  };

  const ScrollProvider = props.ScrollToProvider || ScrollToProvider;

  useEffect(() => {
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: filters,
    });
  }, [filters]);

  useEffect(() => {
    setState(state);
  }, [state.items]);

  return (
    <ResetSearchItemsProvider
      reset={() =>
        dispatch({
          tag: "Reset",
          filterOptions: {
            ...state.filterOptions,
            projectNumber: searchParams.get("lastProject"),
          },
        })
      }
    >
      <ScrollProvider>
        {state.tag === "Loading" &&
          (state.filterOptions.projectNumber && !state.items?.length ? (
            <LoadChargesUntil
              {...{
                ...defaultLoadProps,
              }}
            />
          ) : (
            <LoadProjectCharges
              {...{
                tag: "DataLoaded",
                token: null,
                ...defaultLoadProps,
              }}
            />
          ))}

        {state.tag === "LoadingMore" && (
          <LoadProjectCharges
            {...{
              tag: "MoreDataLoaded",
              token: state.searchToken,
              ...defaultLoadProps,
            }}
          />
        )}

        <ProjectChargesPageDisplay
          {...{
            projectCharges: state.items,
            loadingMore: state.tag === "LoadingMore",
            showSpinner: state.tag === "Loading",
            state,
            dispatch,
          }}
        />
        <ProjectCountReactor {...{ state }} />
      </ScrollProvider>
    </ResetSearchItemsProvider>
  );
};
