import React from "react";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import { UltraFilter } from "~/ultra-filter";
import { useRatesheetAssignableProvider } from "~/filterProviders/useRatesheetAssignableProvider";
import { useShowArchivedProvider } from "~/filterProviders/yesNoProviders";
import { FilterOptions } from "./types";

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<FilterOptions>;
};

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const providers = [
    useRatesheetAssignableProvider({
      documentationText: "The ratesheet's assignable filter.",
    }),
    useShowArchivedProvider({
      documentationText: "Whether to show archived ratesheets.",
    }),
  ] as FilterProvider[];

  const onChanged = (filters: ActiveFilter[], searchText: string) => {
    searchText;

    const newFilters = {
      searchText: searchText || null,
      assignable:
        filters.find((x) => x.type === "assignable")?.value || "Assignable",
      showArchived:
        filters.find((x) => x.type === "showArchived")?.value || "No",
    };
    onFiltersChanged(newFilters);
  };

  return (
    <div className="filter-form">
      <UltraFilter
        {...{
          providers,
          onFiltersChanged: onChanged,
          dialogTitle: "Expense Ratesheet Search",
          label: "Type to search expense ratesheets",
        }}
      />
    </div>
  );
};
