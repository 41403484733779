import React from "react";
import { Cell, Row } from "@material/react-layout-grid";
import Card from "@material/react-card";
import { TwelveColumn } from "@material/react-layout-grid/dist/Cell";
import "./FormCard.scss";

export interface FormCardProps {
  className?: string;
  columns?: TwelveColumn;
}

const FormCard: React.FC<FormCardProps> = ({
  children,
  className,
  columns,
}) => {
  const cn = ["form-card-wrapper", className].filter((x) => x).join(" ");
  if (columns === undefined) {
    return (
      <Row className={cn}>
        <Cell columns={6} tabletColumns={8} phoneColumns={4}>
          <Card className="card form-card">{children}</Card>
        </Cell>
      </Row>
    );
  }
  return (
    <Cell columns={columns ?? 12} className={cn}>
      <Card className="card form-card">{children}</Card>
    </Cell>
  );
};

export default FormCard;
