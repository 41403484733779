import React from "react";
import { SearchRestoreRouter } from "~/search-restorer";
import { InvoicesPage } from "./InvoicesPage";
import { InvoiceRouter } from "../visuals/pages/Invoice/InvoiceRouter";
import { Route, Routes } from "react-router-dom";
import { InvoiceReview } from "./InvoiceReview";
import { ReviewItemsProvider } from "~/review-items";

export const InvoicesRouter: React.FC = () => {
  return (
    <ReviewItemsProvider>
      <SearchRestoreRouter
        searchPage={<InvoicesPage />}
        nestedPages={
          <Routes>
            <Route path="review/*">
              <Route path=":invoiceNumber" element={<InvoiceReview />} />
            </Route>
            <Route path=":invoiceNumber/*" element={<InvoiceRouter />} />
          </Routes>
        }
      />
    </ReviewItemsProvider>
  );
};
