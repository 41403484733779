import React from "react";
import { Input, Options } from "csv-stringify/dist/esm/sync";
import moment from "moment";
import { State } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { addDurations, formatDuration, formatter } from ".";
import { useCsvCreator } from "~/visuals/organisms/TimesheetDisplay/useCsvExporter";
import { useDownloadFile } from "~/file-loading/useDownloadFile";
import { getDateSuffix } from "./utils";
import { FilterOptions, SelectedWeek, Timesheet } from "./types";
import { useApolloClient } from "@apollo/client";
import { GetTimesheetsDocument } from "./timesheetQuery.generated";

export type StringifyArgs = {
  input: Input;
  options?: Options | undefined;
};

export type CsvExporterProps = {
  state: State<Timesheet, FilterOptions>;
  setLoading: React.Dispatch<boolean>;
  toCsvString?: (input: Input, options?: Options | undefined) => string;
  selectedWeeks?: SelectedWeek[];
};

export function useTimesheetCsvExporter(): (
  props: CsvExporterProps
) => Promise<void> {
  const client = useApolloClient();
  const getCsvFile = useCsvCreator();
  const download = useDownloadFile();

  return async ({
    state,
    toCsvString,
    setLoading,
    selectedWeeks,
  }: CsvExporterProps) => {
    const {
      searchText,
      weekStart,
      dateBefore,
      dateAfter,
      workLocation,
      weekStatus,
      reimbursement,
      payrollAdmin,
      payPeriod,
      certifiedPayroll,
      showFuture,
    } = state.filterOptions;
    const hasSelectedWeeks = selectedWeeks && selectedWeeks.length > 0;
    let rows = hasSelectedWeeks ? [] : state.items;

    if (state.tag !== "EndOfData") {
      let token = hasSelectedWeeks ? undefined : state.searchToken;
      setLoading(true);
      while (token !== null) {
        const variables = {
          token: token || null,
          week: weekStart?.format("YYYY-MM-DD"),
          dateBefore: dateBefore?.format("YYYY-MM-DD") ?? null,
          dateAfter: dateAfter?.format("YYYY-MM-DD") ?? null,
          searchText: searchText || null,
          workLocation,
          status: weekStatus,
          reimbursement,
          payrollAdmin: payrollAdmin?.userPrincipalName ?? null,
          payPeriod,
          certifiedPayroll: certifiedPayroll === "Yes",
          showFuture: showFuture === "Yes",
          timesheets: selectedWeeks
            ? selectedWeeks.map((x) => x.timesheetId)
            : null,
        };

        const result = await client.query({
          query: GetTimesheetsDocument,
          variables,
        });

        token = result.data?.timesheets?.timesheetSearch?.token as
          | string
          | null;
        const newWeeks = result.data?.timesheets?.timesheetSearch
          ?.records as Timesheet[];
        rows = [...rows, ...newWeeks];
      }
      setLoading(false);
    }

    const csvRows =
      rows?.map((row) => {
        const daySummaries = row?.daySummaries ?? [];
        const reduceInitialVal = moment.duration("00:00");

        const workHours = daySummaries
          .map((x) => moment.duration(x!.time))
          .reduce((t1, t2) => addDurations(t1, t2), reduceInitialVal);

        const timeOffHours = daySummaries
          .map((x) => moment.duration(x!.timeOff))
          .reduce((t1, t2) => addDurations(t1, t2), reduceInitialVal);

        const totalHours = addDurations(workHours, timeOffHours);
        return {
          "Timesheet Start": row.weekBeginning,
          "Last Name": row.employee!.lastName,
          "First Name": row.employee!.firstName,
          "Work Location": row.employee!.workLocation,
          "Work Hours": formatDuration(workHours),
          "Time Off": formatDuration(timeOffHours),
          "Total Hours": formatDuration(totalHours),
          Reimbursable: formatter.format(row.expensesReimbursable),
          "Company Paid": formatter.format(row.companyPaid),
          "Total Expenses": formatter.format(row.totalExpensesPayable),
          Status: row.status,
        };
      }) ?? [];

    const columns = [
      "Timesheet Start",
      "Last Name",
      "First Name",
      "Work Location",
      "Work Hours",
      "Time Off",
      "Total Hours",
      "Reimbursable",
      "Company Paid",
      "Total Expenses",
      "Status",
    ];

    const filename = getDateSuffix(state, selectedWeeks);

    const file = getCsvFile({ toCsvString, rows: csvRows, columns });
    const fileURL = URL.createObjectURL(file);
    download({ linkHref: fileURL, filename });
  };
}
