import React, { useEffect, useState } from "react";
import { NotifierForm } from "~/forms/NotifierForm";
import DropdownFormField from "~/visuals/organisms/DropdownFormField";
import "./GlobalSearchDisplay.scss";
import TextFormField from "~/text-form-field";
import { LinkClickHandler } from "~/link-click-handler";
import { PopupMenuList } from "~/popup-menu-list";
import { Customer, Invoice, Project } from "~/gql/types";
import { customer, invoice, project } from "~/routes";
import { useDebounce } from "@react-hook/debounce";
import { InlineProgress } from "~/inline-progress";

type GlobalSearchDisplayProps = {
  getProjects: (searchText: string) => Promise<Project[]>;
  getInvoices: (searchText: string) => Promise<Invoice[]>;
  getCustomers: (searchText: string) => Promise<Customer[]>;
};

type GlobalSearchType = "Customers" | "Invoices" | "Projects";

type Result = {
  label: string;
  link: string;
};

export const GlobalSearchDisplay: React.FC<GlobalSearchDisplayProps> = (
  props
) => {
  const { getProjects, getInvoices, getCustomers } = props;

  const [type, setType] = useState<GlobalSearchType>("Customers");
  const [searchText, setSearchText] = useDebounce("", 500);
  const [results, setResults] = useState<Result[]>([]);
  const [loading, setLoading] = useState(false);

  const onTypeChange = (vals: { type: GlobalSearchType }) => setType(vals.type);

  const options: GlobalSearchType[] = ["Customers", "Invoices", "Projects"];

  const menuOptions = options.map((x) => ({
    label: x,
    value: x,
  }));

  const onSubmit = async () => {
    setLoading(true);

    if (type === "Customers") {
      const response = await getCustomers(searchText);
      setResults(
        response.map((x) => ({
          label: `${x.name} (${x.number})`,
          link: `${customer.toRoute(x.number!).path}`,
        }))
      );
    }
    if (type === "Projects") {
      const response = await getProjects(searchText);
      setResults(
        response.map((x) => ({
          label: `${x.name} (${x.number})`,
          link: `${project.toRoute(x.number!).path}`,
        }))
      );
    }
    if (type === "Invoices") {
      const response = await getInvoices(searchText);
      setResults(
        response.map((x) => ({
          label: `${x.invoiceNumber} (${x.projectNumber})`,
          link: `${invoice.toRoute(x.invoiceNumber!).path}`,
        }))
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    if (searchText) {
      void onSubmit();
    } else {
      setResults([]);
    }
  }, [searchText]);

  const links = results.map((r, idx) => (
    <LinkClickHandler
      key={idx}
      path={r.link}
      onClick={() => {
        setResults([]);
        setSearchText("");
      }}
    >
      {r.label}
    </LinkClickHandler>
  ));

  const linkItems = links.map((link, index) => ({
    key: index,
    label: link,
    onClick: () => undefined,
  }));

  return (
    <div className="global-search">
      <div className="fields">
        <NotifierForm values={{ type }} onChange={onTypeChange}>
          <DropdownFormField
            label={"Search Type"}
            formField={"type"}
            helperText={""}
            options={menuOptions}
          />
        </NotifierForm>

        <NotifierForm
          values={{ searchText }}
          onChange={(vals) => setSearchText(vals.searchText)}
        >
          <TextFormField
            formField={"searchText"}
            label={`Search ${type}`}
            helperText={""}
          />
          {loading && <InlineProgress />}
        </NotifierForm>
      </div>
      <PopupMenuList
        {...{ visible: results.length > 0, items: linkItems }}
        onMenuMouseEnter={() => undefined}
        onMenuMouseLeave={() => undefined}
        focusOnOpen={false}
        selectedIndex={0}
        anchorCorner="bottomRight"
        className="global-search-results"
      />
    </div>
  );
};
