import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import _ from "lodash";
import { Moment } from "moment";
import RateSheetPickerFormField from "../RateSheetPicker";

export type ExpenseRateSheetPickerProps = FormFieldProps & {
  effectiveDate: Moment | null;
};

const ExpenseRateSheetPickerFormField: React.FC<ExpenseRateSheetPickerProps> = (
  props
) => (
  <RateSheetPickerFormField
    {...{
      ...props,
      type: "expense",
    }}
  />
);

export default ExpenseRateSheetPickerFormField;
