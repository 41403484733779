import React from "react";
import { Route, Routes } from "react-router-dom";
import { ThirdPartyInvoicesConfig } from "./ThirdPartyInvoicesConfig";
import { ThirdPartyInvoices } from "./ThirdPartyInvoices";
import { RestrictedPage } from "~/router";
import { useUser } from "~/Auth/useUser";
import { CreateThirdPartyInvoice } from "./CreateThirdPartyInvoice";
import { SingleThirdPartyInvoiceRouter } from "./Single";
import { ReviewItemsProvider } from "~/review-items";
import { SearchRestoreRouter } from "~/search-restorer";
import { TpiReview } from "./TpiReview";

export const ThirdPartyInvoicesRouter: React.FC = () => {
  const auths = useUser().authorizations;

  return (
    <Routes>
      <Route
        path="config"
        element={
          <RestrictedPage auth={auths.manageThirdPartyInvoiceConfig}>
            <ThirdPartyInvoicesConfig />
          </RestrictedPage>
        }
      />

      <Route
        path="create"
        element={
          <RestrictedPage auth={auths.createThirdPartyInvoices}>
            <CreateThirdPartyInvoice />
          </RestrictedPage>
        }
      />

      <Route
        path="*"
        element={
          <ReviewItemsProvider>
            <SearchRestoreRouter
              searchPage={<ThirdPartyInvoices />}
              nestedPages={
                <Routes>
                  <Route path="review/*">
                    <Route path=":invoiceNumber" element={<TpiReview />} />
                  </Route>

                  <Route
                    path=":invoiceNumber/*"
                    element={<SingleThirdPartyInvoiceRouter />}
                  />
                </Routes>
              }
            />
          </ReviewItemsProvider>
        }
      />
    </Routes>
  );
};
