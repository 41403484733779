import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopyProjectTimeToDatesMutationVariables = Types.Exact<{
  dates: Array<Types.Scalars['LocalDate']> | Types.Scalars['LocalDate'];
  projectTimeId: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CopyProjectTimeToDatesMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', copyProjectTimeToDates?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CopyExpenseToDatesMutationVariables = Types.Exact<{
  dates: Array<Types.Scalars['LocalDate']> | Types.Scalars['LocalDate'];
  expenseId: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CopyExpenseToDatesMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', copyExpenseToDates?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CopyTimeEntryToDatesMutationVariables = Types.Exact<{
  dates: Array<Types.Scalars['LocalDate']> | Types.Scalars['LocalDate'];
  timeEntryId: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CopyTimeEntryToDatesMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', copyTimeEntryToDates?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type CopyTimeOffToDatesMutationVariables = Types.Exact<{
  dates: Array<Types.Scalars['LocalDate']> | Types.Scalars['LocalDate'];
  timeOffEntryId: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CopyTimeOffToDatesMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', copyTimeOffToDates?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CopyProjectTimeToDatesDocument = gql`
    mutation CopyProjectTimeToDates($dates: [LocalDate!]!, $projectTimeId: String!, $adminNotes: String) {
  timesheets {
    copyProjectTimeToDates(
      dates: $dates
      projectTimeId: $projectTimeId
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CopyProjectTimeToDatesMutationFn = Apollo.MutationFunction<CopyProjectTimeToDatesMutation, CopyProjectTimeToDatesMutationVariables>;

/**
 * __useCopyProjectTimeToDatesMutation__
 *
 * To run a mutation, you first call `useCopyProjectTimeToDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyProjectTimeToDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyProjectTimeToDatesMutation, { data, loading, error }] = useCopyProjectTimeToDatesMutation({
 *   variables: {
 *      dates: // value for 'dates'
 *      projectTimeId: // value for 'projectTimeId'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCopyProjectTimeToDatesMutation(baseOptions?: Apollo.MutationHookOptions<CopyProjectTimeToDatesMutation, CopyProjectTimeToDatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyProjectTimeToDatesMutation, CopyProjectTimeToDatesMutationVariables>(CopyProjectTimeToDatesDocument, options);
      }
export type CopyProjectTimeToDatesMutationHookResult = ReturnType<typeof useCopyProjectTimeToDatesMutation>;
export type CopyProjectTimeToDatesMutationResult = Apollo.MutationResult<CopyProjectTimeToDatesMutation>;
export type CopyProjectTimeToDatesMutationOptions = Apollo.BaseMutationOptions<CopyProjectTimeToDatesMutation, CopyProjectTimeToDatesMutationVariables>;
export const CopyExpenseToDatesDocument = gql`
    mutation CopyExpenseToDates($dates: [LocalDate!]!, $expenseId: String!, $adminNotes: String) {
  timesheets {
    copyExpenseToDates(
      dates: $dates
      expenseId: $expenseId
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CopyExpenseToDatesMutationFn = Apollo.MutationFunction<CopyExpenseToDatesMutation, CopyExpenseToDatesMutationVariables>;

/**
 * __useCopyExpenseToDatesMutation__
 *
 * To run a mutation, you first call `useCopyExpenseToDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyExpenseToDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyExpenseToDatesMutation, { data, loading, error }] = useCopyExpenseToDatesMutation({
 *   variables: {
 *      dates: // value for 'dates'
 *      expenseId: // value for 'expenseId'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCopyExpenseToDatesMutation(baseOptions?: Apollo.MutationHookOptions<CopyExpenseToDatesMutation, CopyExpenseToDatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyExpenseToDatesMutation, CopyExpenseToDatesMutationVariables>(CopyExpenseToDatesDocument, options);
      }
export type CopyExpenseToDatesMutationHookResult = ReturnType<typeof useCopyExpenseToDatesMutation>;
export type CopyExpenseToDatesMutationResult = Apollo.MutationResult<CopyExpenseToDatesMutation>;
export type CopyExpenseToDatesMutationOptions = Apollo.BaseMutationOptions<CopyExpenseToDatesMutation, CopyExpenseToDatesMutationVariables>;
export const CopyTimeEntryToDatesDocument = gql`
    mutation CopyTimeEntryToDates($dates: [LocalDate!]!, $timeEntryId: String!, $adminNotes: String) {
  timesheets {
    copyTimeEntryToDates(
      dates: $dates
      timeEntryId: $timeEntryId
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CopyTimeEntryToDatesMutationFn = Apollo.MutationFunction<CopyTimeEntryToDatesMutation, CopyTimeEntryToDatesMutationVariables>;

/**
 * __useCopyTimeEntryToDatesMutation__
 *
 * To run a mutation, you first call `useCopyTimeEntryToDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTimeEntryToDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTimeEntryToDatesMutation, { data, loading, error }] = useCopyTimeEntryToDatesMutation({
 *   variables: {
 *      dates: // value for 'dates'
 *      timeEntryId: // value for 'timeEntryId'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCopyTimeEntryToDatesMutation(baseOptions?: Apollo.MutationHookOptions<CopyTimeEntryToDatesMutation, CopyTimeEntryToDatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyTimeEntryToDatesMutation, CopyTimeEntryToDatesMutationVariables>(CopyTimeEntryToDatesDocument, options);
      }
export type CopyTimeEntryToDatesMutationHookResult = ReturnType<typeof useCopyTimeEntryToDatesMutation>;
export type CopyTimeEntryToDatesMutationResult = Apollo.MutationResult<CopyTimeEntryToDatesMutation>;
export type CopyTimeEntryToDatesMutationOptions = Apollo.BaseMutationOptions<CopyTimeEntryToDatesMutation, CopyTimeEntryToDatesMutationVariables>;
export const CopyTimeOffToDatesDocument = gql`
    mutation CopyTimeOffToDates($dates: [LocalDate!]!, $timeOffEntryId: String!, $adminNotes: String) {
  timesheets {
    copyTimeOffToDates(
      dates: $dates
      timeOffEntryId: $timeOffEntryId
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CopyTimeOffToDatesMutationFn = Apollo.MutationFunction<CopyTimeOffToDatesMutation, CopyTimeOffToDatesMutationVariables>;

/**
 * __useCopyTimeOffToDatesMutation__
 *
 * To run a mutation, you first call `useCopyTimeOffToDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTimeOffToDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTimeOffToDatesMutation, { data, loading, error }] = useCopyTimeOffToDatesMutation({
 *   variables: {
 *      dates: // value for 'dates'
 *      timeOffEntryId: // value for 'timeOffEntryId'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCopyTimeOffToDatesMutation(baseOptions?: Apollo.MutationHookOptions<CopyTimeOffToDatesMutation, CopyTimeOffToDatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyTimeOffToDatesMutation, CopyTimeOffToDatesMutationVariables>(CopyTimeOffToDatesDocument, options);
      }
export type CopyTimeOffToDatesMutationHookResult = ReturnType<typeof useCopyTimeOffToDatesMutation>;
export type CopyTimeOffToDatesMutationResult = Apollo.MutationResult<CopyTimeOffToDatesMutation>;
export type CopyTimeOffToDatesMutationOptions = Apollo.BaseMutationOptions<CopyTimeOffToDatesMutation, CopyTimeOffToDatesMutationVariables>;