import React from "react";
import Button from "~/button";
import { Project } from "~/gql/types";
import styles from "./ProjectLinkButton.module.scss";

export type ProjectLinkButtonProps = {
  project: Project;
};

export const ProjectLinkButton: React.FC<ProjectLinkButtonProps> = ({
  project,
}) => {
  const { name, number } = project;

  return (
    <Button
      className={styles.Button}
      href={`/projects/${number}`}
      icon="open_in_new"
      title={`Go to ${name} (${number})`}
    />
  );
};
