import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { SimplePicker } from "~/visuals/organisms/SimplePicker";
import { Suggestion } from "~/ultra-filter/types";

export const activeStates = [
  { value: "Active", text: "Active" },
  { value: "Terminated", text: "Inactive" },
] as Suggestion[];

export const EmployeeStatusPicker: React.FC<FormFieldProps> = (props) => (
  <SimplePicker {...{ ...props, options: activeStates }} />
);
