import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCorpLocationMutationVariables = Types.Exact<{
  corpLocationId: Types.Scalars['String'];
  name: Types.Scalars['String'];
  companyName: Types.Scalars['String'];
  isActive: Types.Scalars['Boolean'];
}>;


export type UpdateCorpLocationMutation = { __typename?: 'MutationModel', corpLocations?: { __typename?: 'CorpLocationMutationModel', update?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const UpdateCorpLocationDocument = gql`
    mutation UpdateCorpLocation($corpLocationId: String!, $name: String!, $companyName: String!, $isActive: Boolean!) {
  corpLocations {
    update(
      corpLocationId: $corpLocationId
      name: $name
      companyName: $companyName
      isActive: $isActive
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type UpdateCorpLocationMutationFn = Apollo.MutationFunction<UpdateCorpLocationMutation, UpdateCorpLocationMutationVariables>;

/**
 * __useUpdateCorpLocationMutation__
 *
 * To run a mutation, you first call `useUpdateCorpLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCorpLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCorpLocationMutation, { data, loading, error }] = useUpdateCorpLocationMutation({
 *   variables: {
 *      corpLocationId: // value for 'corpLocationId'
 *      name: // value for 'name'
 *      companyName: // value for 'companyName'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateCorpLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCorpLocationMutation, UpdateCorpLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCorpLocationMutation, UpdateCorpLocationMutationVariables>(UpdateCorpLocationDocument, options);
      }
export type UpdateCorpLocationMutationHookResult = ReturnType<typeof useUpdateCorpLocationMutation>;
export type UpdateCorpLocationMutationResult = Apollo.MutationResult<UpdateCorpLocationMutation>;
export type UpdateCorpLocationMutationOptions = Apollo.BaseMutationOptions<UpdateCorpLocationMutation, UpdateCorpLocationMutationVariables>;