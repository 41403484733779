import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TaskRateSheetsQueryVariables = Types.Exact<{
  effectiveDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
}>;


export type TaskRateSheetsQuery = { __typename?: 'QueryModel', taskRates?: { __typename?: 'TaskRatesQueryModel', sheets?: Array<{ __typename?: 'TaskRateSheet', name: string, allowAssignment: boolean } | null> | null } | null };


export const TaskRateSheetsDocument = gql`
    query TaskRateSheets($effectiveDate: LocalDate) {
  taskRates {
    sheets(allowAssignment: true, effectiveDate: $effectiveDate) {
      name
      allowAssignment
    }
  }
}
    `;

/**
 * __useTaskRateSheetsQuery__
 *
 * To run a query within a React component, call `useTaskRateSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskRateSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskRateSheetsQuery({
 *   variables: {
 *      effectiveDate: // value for 'effectiveDate'
 *   },
 * });
 */
export function useTaskRateSheetsQuery(baseOptions?: Apollo.QueryHookOptions<TaskRateSheetsQuery, TaskRateSheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskRateSheetsQuery, TaskRateSheetsQueryVariables>(TaskRateSheetsDocument, options);
      }
export function useTaskRateSheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskRateSheetsQuery, TaskRateSheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskRateSheetsQuery, TaskRateSheetsQueryVariables>(TaskRateSheetsDocument, options);
        }
export type TaskRateSheetsQueryHookResult = ReturnType<typeof useTaskRateSheetsQuery>;
export type TaskRateSheetsLazyQueryHookResult = ReturnType<typeof useTaskRateSheetsLazyQuery>;
export type TaskRateSheetsQueryResult = Apollo.QueryResult<TaskRateSheetsQuery, TaskRateSheetsQueryVariables>;