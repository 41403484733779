import React from "react";
import { useField } from "react-final-form";
import _ from "lodash";
import { Dropdown, DropdownProps } from "~/dropdown";
import {
  CustomRollupInvoice,
  InvoiceFilterState,
  ProjectGroup,
} from "~/gql/types";
import { useSearchCustomRollupInvoicesQuery } from "~/custom-rollup-invoices/SearchPage/search.generated";

export type RollupInvoicePickerProps = {
  label: string;
  formField: string;
  helperText: string;
  projectGroup: ProjectGroup;
  excludedProject?: number;
  disabled?: boolean;
  required?: boolean;
};

export const filterResult = (
  result: CustomRollupInvoice[],
  excludedProject?: number
): CustomRollupInvoice[] =>
  result.filter(
    (x) =>
      !excludedProject ||
      !x.lineItems!.some((x) => x!.invoice!.projectNumber === excludedProject)
  );

export const RollupInvoicePicker: React.FC<RollupInvoicePickerProps> = (
  props
) => {
  const { projectGroup, excludedProject } = props;

  const { data, loading } = useSearchCustomRollupInvoicesQuery({
    variables: {
      state: InvoiceFilterState.Draft,
      projectGroupArgs: {
        customerNumber: projectGroup.customer!.number!,
        groupName: projectGroup.name,
      },
    },
  });

  const result = (data?.customRollupInvoices?.search?.records ??
    []) as CustomRollupInvoice[];

  return (
    <RollupInvoicePickerDisplay
      {...{
        ...props,
        data: filterResult(result, excludedProject),
        loading,
      }}
    />
  );
};

type RollupInvoicePickerDisplayProps = Omit<
  RollupInvoicePickerProps,
  "projectGroup"
> & {
  data: CustomRollupInvoice[];
  loading: boolean;
};

export const RollupInvoicePickerDisplay: React.FC<
  RollupInvoicePickerDisplayProps
> = (props) => {
  const {
    label,
    formField,
    helperText,
    data,
    loading,
    disabled = false,
    required = false,
  } = props;

  const {
    meta: { invalid, error, submitError, touched },
    input: { value, onChange, onBlur },
  } = useField(formField);

  const hasError = (error || invalid || submitError) && touched;

  if (loading) {
    return <>(loading...)</>;
  }

  const items = _.orderBy(data, (x) => x.invoiceNumber);
  const selectedItem =
    items.find((x) => x.invoiceNumber === value?.invoiceNumber) || null;

  const dropdownProps: DropdownProps<CustomRollupInvoice> = {
    items: items,
    formatItem: (x) => x.invoiceNumber,
    formatSelection: (x) => x?.invoiceNumber || "",
    onSelected: (x) => onChange(x || null),
    selectedItem,
    label,
    name: formField,
    helperText: hasError ? error || submitError : helperText,
    disabled,
    isValid: !hasError,
    nullDisplay: "(Select new draft)",
    onBlur,
    required,
  };

  return <Dropdown {...dropdownProps} />;
};
