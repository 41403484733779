import React, { createContext, useContext } from "react";

export type ActFn = {
  (workFn: () => void): void;
  (workFn: () => Promise<void | undefined>): Promise<void>;
};

const Context = createContext<ActFn | null>(null);

export const AsyncActorContext: React.FC<{ act: ActFn }> = ({
  act,
  children,
}) => {
  return <Context.Provider value={act}>{children}</Context.Provider>;
};

const nopAct: ActFn = (workFn) => {
  const result = workFn();

  if (result && result.then) {
    return result.then(() => undefined);
  }
};

export function useAct(): ActFn {
  const contextActFn = useContext(Context);

  return contextActFn ?? nopAct;
}
