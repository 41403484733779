import React, { useState } from "react";
import TimeEntryForm, { FormData } from "~/visuals/organisms/TimeEntryForm";
import { TimeEntry } from "~/graphql/MyWork";
import moment from "moment";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { useWorkDayApi } from "~/WorkDayApi";
import { useCrewCodes } from "~/refdata2/crewCodes";

type EditTimeEntryProps = {
  timeEntry: TimeEntry;
  date: string;
};

const EditTimeEntry: React.FC<EditTimeEntryProps> = ({ timeEntry, date }) => {
  const { editTimeEntry, deleteTimeEntry, breadcrumbs } = useWorkDayApi();

  useBreadcrumbs(
    [...breadcrumbs, { text: `Edit Time Entry ${timeEntry.id ?? ""}` }],
    [breadcrumbs, timeEntry]
  );

  const [loading, setLoading] = useState(false);

  const crewCodes = useCrewCodes();
  const selectedCrewCode =
    crewCodes?.find((x) => x.crewCode === timeEntry.crewCode) ?? null;

  const durationFormat = (d) => {
    if (!d) {
      return null;
    }
    const m = moment(d.asMilliseconds()).startOf("day").add(d);

    return m.format("HH:mm:ss");
  };

  const editTime = async (values: FormData) => {
    setLoading(true);
    const res = await editTimeEntry({
      timeEntryId: timeEntry.id,
      timeIn: durationFormat(values.timeIn),
      timeOut: durationFormat(values.timeOut),
      hoursWorked: durationFormat(values.hoursWorked),
      lunchStart: durationFormat(values.lunchStart),
      lunchEnd: durationFormat(values.lunchEnd),
      lunchHours: durationFormat(values.lunchHours),
      state: values.stateCounty?.state!,
      county: values.stateCounty?.county!,
      crewCode: values.crewCode?.crewCode!,
      adminNotes: values.adminNotes || null,
    });
    setLoading(false);

    return res;
  };

  const initialValues = {
    crewCode: selectedCrewCode,
    stateCounty: timeEntry.location,
    lunchStart: timeEntry.lunchStart
      ? moment.duration(timeEntry.lunchStart)
      : null,
    lunchEnd: timeEntry.lunchEnd ? moment.duration(timeEntry.lunchEnd) : null,
    lunchHours: timeEntry.lunchHours
      ? moment.duration(timeEntry.lunchHours)
      : null,
    hoursWorked: moment.duration(timeEntry.hoursWorked),
    timeIn: moment.duration(timeEntry.timeIn),
    timeOut: moment.duration(timeEntry.timeOut),
    adminNotes: null,
  };

  const deleteTime = async () => {
    setLoading(true);
    const res = await deleteTimeEntry({
      timeEntryId: timeEntry.id,
    });
    setLoading(false);

    return res;
  };

  return (
    <>
      <TimeEntryForm
        type="Edit"
        handleSubmit={editTime}
        initialValues={initialValues}
        date={date}
        loading={loading}
        onDelete={deleteTime}
      />
    </>
  );
};

export default EditTimeEntry;
