import React from "react";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import { useSnackBar } from "~/snackbar";
import { useCreateProjectGroupMutation } from "./mutation.generated";
import TextFormField from "~/text-form-field";
import FormBottomRow from "~/form-bottom-row";
import Button from "~/button";
import { useReload } from "../CustomerContext";
import { ValidationResponse } from "~/gql/types";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";

type AddProjectGroupProps = {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  customerNumber: string;
};

type AddProjectGroupFormValues = {
  name: string | null;
};

type AddProjectGroupFormProps = Omit<AddProjectGroupProps, "open">;
export const AddProjectGroup: React.FC<AddProjectGroupProps> = (props) => {
  const { open, setOpen } = props;
  return (
    <>
      <Button
        icon="add"
        className="customer-add-item-button"
        onClick={() => setOpen(true)}
      >
        Add
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="add-project-group"
      >
        <DialogTitle>Add Project Group</DialogTitle>
        <DialogContent>
          {open && <AddProjectGroupForm {...props} />}
        </DialogContent>
      </Dialog>
    </>
  );
};

export const AddProjectGroupForm: React.FC<AddProjectGroupFormProps> = ({
  setOpen,
  customerNumber,
}) => {
  const [doMutation] = useCreateProjectGroupMutation();

  const addAlert = useSnackBar();
  const reload = useReload();

  const onSuccess = () => {
    const message = "New Project Group added";
    addAlert({
      isSuccess: true,
      message,
      key: `${Math.random()}`,
    });
    setOpen(false);
    reload();
  };
  const handleSubmit = async (values: AddProjectGroupFormValues) => {
    const variables = {
      customerNumber: customerNumber,
      name: values.name!,
    };
    const result = await doMutation({ variables });
    return result?.data?.projectGroups?.create as ValidationResponse;
  };

  const initialValues = {
    name: null,
  };
  return (
    <MutationForm
      initialValues={initialValues}
      onSuccess={onSuccess}
      runMutation={handleSubmit}
    >
      <TextFormField
        formField={"name"}
        label={"Name"}
        helperText={""}
        required
      />
      <ErrorRenderer
        render={(formError) => (
          <FormBottomRow
            errorMessage={formError}
            buttonText="Save"
            onCancel={() => setOpen(false)}
          />
        )}
      />
    </MutationForm>
  );
};
