import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopyTimesheetProjectTimeMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type CopyTimesheetProjectTimeMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', copyProjectTime?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CopyTimesheetProjectTimeDocument = gql`
    mutation CopyTimesheetProjectTime($id: String!) {
  timesheets {
    copyProjectTime(id: $id) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CopyTimesheetProjectTimeMutationFn = Apollo.MutationFunction<CopyTimesheetProjectTimeMutation, CopyTimesheetProjectTimeMutationVariables>;

/**
 * __useCopyTimesheetProjectTimeMutation__
 *
 * To run a mutation, you first call `useCopyTimesheetProjectTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTimesheetProjectTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTimesheetProjectTimeMutation, { data, loading, error }] = useCopyTimesheetProjectTimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCopyTimesheetProjectTimeMutation(baseOptions?: Apollo.MutationHookOptions<CopyTimesheetProjectTimeMutation, CopyTimesheetProjectTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyTimesheetProjectTimeMutation, CopyTimesheetProjectTimeMutationVariables>(CopyTimesheetProjectTimeDocument, options);
      }
export type CopyTimesheetProjectTimeMutationHookResult = ReturnType<typeof useCopyTimesheetProjectTimeMutation>;
export type CopyTimesheetProjectTimeMutationResult = Apollo.MutationResult<CopyTimesheetProjectTimeMutation>;
export type CopyTimesheetProjectTimeMutationOptions = Apollo.BaseMutationOptions<CopyTimesheetProjectTimeMutation, CopyTimesheetProjectTimeMutationVariables>;