import { ApolloClient } from "@apollo/client";
import createRefdataApi from "../createRefdataApi";
import { FieldOfficesQuery, FieldOfficesDocument } from "./query.generated";
import { FieldOffice } from "~/gql/types";

async function fetcher(client: ApolloClient<any>) {
  const result = await client.query<FieldOfficesQuery>({
    query: FieldOfficesDocument,
  });

  return result?.data?.fieldOffices?.all as FieldOffice[];
}

const api = createRefdataApi(fetcher, "field office");

export const FieldOfficeRefdataContext = api.Provider;
export const useFieldOfficesAsync = api.useDataAsync;
export const useFieldOffices = api.useData;

export function useOfficeFromParam(): (
  code: string
) => Promise<FieldOffice | null> {
  const getOffices = useFieldOfficesAsync();

  return async (code: string) => {
    const result = await getOffices();
    const officeCode = result?.find((x) => x.officeCode === code);

    return officeCode ?? null;
  };
}
