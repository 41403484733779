import _ from "lodash";
import moment from "moment";
import { Moment } from "moment";
import { State } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { Timesheet } from "~/gql/types";
import { FilterOptions } from "~/visuals/pages/TimesheetReview/FilterForm";

export function getTimeRangeBegin(
  state: State<Timesheet, FilterOptions>,
  selectedWeeks?: Timesheet[]
): Moment | null {
  const { weekStart, dateAfter } = state.filterOptions;

  const minSelectedWeek = _.chain(selectedWeeks || [])
    .orderBy((x) => x.weekBeginning)
    .map((x) => moment(x.weekBeginning))
    .take(1)
    .value();

  return minSelectedWeek[0] || weekStart || dateAfter;
}

export function getTimeRangeEnd(
  state: State<Timesheet, FilterOptions>,
  selectedWeeks?: Timesheet[]
): Moment | null {
  const { weekStart, dateBefore } = state.filterOptions;

  const maxSelectedWeek = _.chain(selectedWeeks || [])
    .orderBy((x) => x.weekBeginning, "desc")
    .map((x) => moment(x.weekBeginning))
    .take(1)
    .value();

  return maxSelectedWeek[0] || weekStart || dateBefore;
}

export function getDateSuffix(
  state: State<Timesheet, FilterOptions>,
  selectedWeeks?: Timesheet[]
): string {
  const startOfWeek = getTimeRangeBegin(state, selectedWeeks);
  const endOfRange = getTimeRangeEnd(state, selectedWeeks);

  const suffix = startOfWeek
    ? startOfWeek.format("YYYY-MM-DD")
    : `before-${endOfRange?.format("YYYY-MM-DD")}`;

  return suffix;
}
