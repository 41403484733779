import React, { useEffect, useState } from "react";
import { FilterForm } from "./FilterForm";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { VisibilityListener } from "~/visibility-listener";
import useInfiniteScroll from "~/search-page-wrapper/infinite-scroll/useInfiniteScroll";
import { TextHighlighterProvider } from "~/text-highlighter/TextHighlighterProvider";
import { SearchFilterContainer } from "~/search-filter-container";
import { CustomRollupInvoice, CustomRollupInvoiceSortBy } from "~/gql/types";
import { useSearchParams } from "react-router-dom";
import { SearchSortBy } from "~/search-sort-by";
import {
  CustomRollupInvoiceFilterOptions,
  CustomRollupInvoiceSearchDispatch,
  CustomRollupInvoiceSearchState,
} from "./types";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";
import { CustomRollupInvoiceCard } from "./CustomRollupInvoiceCard";
import { useSearchCustomRollupInvoicesQuery } from "./search.generated";
import { BulkActionsPopup } from "~/search-page-wrapper/BulkActionsPopup";
import { useRelativeNav } from "~/relative-nav";
import { CRI_FILTERS_KEY } from "../CriReview";
import { useReviewItemsContext } from "~/review-items";
import { SelectAllCheckbox } from "~/search-page-wrapper/BulkCheckboxes/SelectAllCheckbox";

const Breadcrumbs: React.FC = () => {
  useBreadcrumbs([{ text: "Custom Rollup Invoices" }], []);

  return <></>;
};

type CustomRollupInvoicesPageProps = {
  FilterFormComponent?: typeof FilterForm;
};

export type CustomRollupInvoicesPageDisplayProps = {
  dispatch: CustomRollupInvoiceSearchDispatch;
  state: CustomRollupInvoiceSearchState;
};

export const CustomRollupInvoicesPageDisplay: React.FC<
  CustomRollupInvoicesPageDisplayProps
> = ({ state, dispatch }) => {
  const nav = useRelativeNav();
  const { setSelectedItems } = useReviewItemsContext();

  useEffect(() => {
    setSelectedItems(state.selectedItems);
  }, [state.selectedItems]);

  const invoices = state.items;
  return (
    <>
      <SelectAllCheckbox {...{ state, dispatch }} />
      <SearchListContent
        loading={state.loading}
        loadingMore={state.tag === "LoadingMore"}
      >
        <Breadcrumbs />

        {invoices &&
          invoices.map((invoice, idx) => (
            <CustomRollupInvoiceCard
              {...{
                invoice,
                search: state.filterOptions.searchText || null,
                state,
                dispatch,
              }}
              key={idx}
            />
          ))}
        {state.showVisibility && (
          <VisibilityListener
            onShown={() => dispatch({ tag: "ScrolledToBottom" })}
          />
        )}
        <BulkActionsPopup
          type="Custom Rollup Invoice"
          buttonItems={[
            {
              label: "Review",
              onClick: () => {
                window.sessionStorage.setItem(CRI_FILTERS_KEY, location.search);
                nav(`review/${state.selectedItems![0].invoiceNumber}`);
              },
            },
          ]}
          onClear={() => dispatch({ tag: "ClearSelected" })}
          selectedItems={state.selectedItems}
        />
      </SearchListContent>
    </>
  );
};

type LoadInvoicesProps = {
  dispatch: CustomRollupInvoiceSearchDispatch;
  tag: "DataLoaded" | "MoreDataLoaded";
  token: string | null;
  filterOptions: CustomRollupInvoiceFilterOptions;
};

const LoadInvoices = ({
  dispatch,
  tag,
  filterOptions,
  token,
}: LoadInvoicesProps) => {
  const {
    searchText,
    customer,
    state,
    amount,
    sortBy,
    projectGroup,
    billingAdmin,
  } = filterOptions;

  const variables = {
    searchText: searchText || null,
    state: state || null,
    customerNumber: customer?.number || null,
    amount: amount ? parseFloat(amount) : null,
    billingAdmin: billingAdmin || null,
    token,
    sortBy: sortBy as CustomRollupInvoiceSortBy,
    projectGroupArgs: projectGroup
      ? {
          groupName: projectGroup.name,
          customerNumber: projectGroup.customer!.number!,
        }
      : null,
  };

  const { data } = useSearchCustomRollupInvoicesQuery({ variables });

  useEffect(() => {
    void (async () => {
      if (data) {
        const newToken = data?.customRollupInvoices?.search?.token as
          | string
          | null;
        const newInvoices = data?.customRollupInvoices?.search
          ?.records as CustomRollupInvoice[];

        dispatch({ tag, items: newInvoices, searchToken: newToken });
      }
    })();
  }, [data]);

  return <></>;
};

export const CustomRollupInvoicesPage: React.FC<
  CustomRollupInvoicesPageProps
> = (props) => {
  const [filters, setFilters] =
    useState<CustomRollupInvoiceFilterOptions | null>(null);

  const FilterFormComponent = props.FilterFormComponent || FilterForm;

  const setFilterOptions = (opts: CustomRollupInvoiceFilterOptions) =>
    setFilters(opts);

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <SearchPageWrapper withSort>
      <SearchFilterContainer>
        <FilterFormComponent onFiltersChanged={setFilterOptions} />
        <SearchSortBy
          {...{
            filters,
            setFilters,
            searchParams,
            setSearchParams,
            type: "CustomRollupInvoiceSortBy",
          }}
        />
      </SearchFilterContainer>
      {filters && <CustomRollupInvoicesResults {...{ ...props, filters }} />}
    </SearchPageWrapper>
  );
};

type InvoicesResultsProps = CustomRollupInvoicesPageProps & {
  filters: CustomRollupInvoiceFilterOptions;
};

export const CustomRollupInvoicesResults: React.FC<InvoicesResultsProps> = (
  props
) => {
  const { filters } = props;

  const initialState = {
    tag: "Loading",
    items: [],
    showVisibility: false,
    loading: true,
    filterOptions: filters,
    searchToken: null,
    selectedItems: [],
    selectIdKey: "invoiceNumber",
  };

  const { state, dispatch } = useInfiniteScroll<
    CustomRollupInvoice,
    CustomRollupInvoiceFilterOptions
  >(initialState);

  useEffect(() => {
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: filters,
    });
  }, [filters]);

  return (
    <TextHighlighterProvider searchText={state.filterOptions.searchText}>
      {state.tag === "Loading" && (
        <LoadInvoices
          {...{
            dispatch,
            tag: "DataLoaded",
            filterOptions: state.filterOptions,
            token: null,
          }}
        />
      )}
      {state.tag === "LoadingMore" && (
        <LoadInvoices
          {...{
            dispatch,
            tag: "MoreDataLoaded",
            token: state.searchToken,
            filterOptions: state.filterOptions,
          }}
        />
      )}
      <CustomRollupInvoicesPageDisplay
        {...{
          state,
          dispatch,
        }}
      />
    </TextHighlighterProvider>
  );
};
