import React, { useEffect } from "react";

export const FormEnterHandler: React.FC = () => {
  useEffect(() => {
    const swallowEnter = (evt: KeyboardEvent) => {
      if (
        evt.key === "Enter" &&
        document.activeElement?.tagName !== "TEXTAREA"
      ) {
        evt.preventDefault();
      }
    };

    document.addEventListener("keydown", swallowEnter);

    return () => {
      document.removeEventListener("keydown", swallowEnter);
    };
  }, []);

  return <></>;
};
