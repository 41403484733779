import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { useThirdPartyInvoiceQueryQuery } from "./query.generated";
import { EditThirdPartyInvoice } from "../EditThirdPartyInvoice";
import { ThirdPartyInvoice } from "~/gql/types";
import Spinner from "~/spinner";
import { ThirdPartyInvoicePage } from "./ThirdPartyInvoicePage";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { thirdPartyInvoices } from "~/routes";

type ThirdPartyInvoiceParams = {
  invoiceNumber: string;
};

export const SingleThirdPartyInvoiceRouter: React.FC = () => {
  const params = useParams<ThirdPartyInvoiceParams>();

  const variables = { invoiceNumber: params.invoiceNumber! };

  useBreadcrumbs(
    [
      { text: "Third Party Invoices", to: thirdPartyInvoices.path },
      { text: params.invoiceNumber ?? "" },
    ],
    [params.invoiceNumber]
  );

  const { data, refetch } = useThirdPartyInvoiceQueryQuery({ variables });

  const thirdPartyInvoice = data?.thirdPartyInvoices
    ?.single as ThirdPartyInvoice;

  if (!thirdPartyInvoice) {
    return <Spinner open={true} />;
  }

  return (
    <Routes>
      <Route
        path="edit"
        element={
          <EditThirdPartyInvoice
            thirdPartyInvoice={thirdPartyInvoice!}
            refetch={refetch}
          />
        }
      />
      <Route
        index
        element={
          <ThirdPartyInvoicePage
            thirdPartyInvoice={thirdPartyInvoice}
            refetch={refetch}
          />
        }
      />
    </Routes>
  );
};
