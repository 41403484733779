import { Moment } from "moment";
import React, { useEffect, useState } from "react";
import FormBottomRow from "~/form-bottom-row";
import Spinner from "~/spinner";
import { ValueRenderer } from "~/forms/ValueContext";
import {
  ChargeType,
  CrewCodeRecord,
  EffectiveTaskRateSheetAssignment,
  Invoice,
  RateUnit,
  TaskRateSheet,
  ValidationResponse,
} from "~/gql/types";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";
import TextFormField from "~/text-form-field";
import { CrewCodePickerFormField } from "~/visuals/organisms/CrewCodePickerFormField";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import { useCreateChargeForInvoicesMutation } from "./mutation.generated";
import { useSnackBar } from "~/snackbar";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import "./AddClerical.scss";
import { useApolloClient } from "@apollo/client";
import { EffectiveTaskRateSheetsDocument } from "./rateSheets.generated";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";

type AddClericalProps = {
  invoices: Invoice[];
  open: boolean;
  setOpen: React.Dispatch<boolean>;
};

type AddClericalFormValues = {
  date: Moment | null;
  notes: string | null;
  crewCode: CrewCodeRecord | null;
  quantity: string | null;
  taxable: boolean;
};

export const AddClerical: React.FC<AddClericalProps> = (props) => {
  const { open, setOpen } = props;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      data-open={open}
      className="add-clerical"
    >
      <DialogTitle>Add Clerical</DialogTitle>
      <DialogContent>{open && <AddClericalForm {...props} />}</DialogContent>
    </Dialog>
  );
};

type AddClericalFormProps = Omit<AddClericalProps, "open">;

const getClerical = (rateSheet: TaskRateSheet) =>
  rateSheet.rates?.find((x) => x?.name.toLowerCase() === "clerical");

export const AddClericalForm: React.FC<AddClericalFormProps> = ({
  invoices,
  setOpen,
}) => {
  const [rateSheet, setRateSheet] = useState<TaskRateSheet | null>(null);
  const [error, setError] = useState<string | null>(null);
  const addAlert = useSnackBar();

  const [doMutation, { loading }] = useCreateChargeForInvoicesMutation();

  const onSuccess = ({ response }) => {
    const skippedInvoices = response?.skippedInvoices ?? [];

    let message = "Clerical charge added to invoices.";

    if (skippedInvoices.length > 0) {
      message += ` The following invoices already have a clerical charge: ${skippedInvoices.join(
        ","
      )}`;
    }

    addAlert({
      isSuccess: true,
      message,
      key: `${Math.random()}`,
    });
    setOpen(false);
  };

  const handleSubmit = async (values: AddClericalFormValues) => {
    if (error) {
      return { errors: [{ message: error }] };
    }

    const clericalTask = getClerical(rateSheet!)!;

    const variables = {
      date: values.date!.format("YYYY-MM-DD"),
      notes: values.notes!,
      quantity: parseFloat(values.quantity!),
      taxable: values.taxable,
      crewCode: values.crewCode!.crewCode,
      rateSheetName: rateSheet!.name,
      label: clericalTask.name,
      type: ChargeType.Clerical,
      unit: clericalTask.unit as unknown as RateUnit,
      rate: clericalTask.billableAmount || 0,
      invoices: invoices.map((x) => x.invoiceNumber),
      glAccount: clericalTask.glAccount || null,
    };

    const result = await doMutation({ variables });

    return result?.data?.invoices
      ?.createChargeForInvoices as ValidationResponse;
  };

  const initialValues = {
    date: null,
    notes: null,
    quantity: null,
    taxable: false,
    crewCode: null,
  };

  return (
    <MutationForm
      initialValues={initialValues}
      onSuccess={onSuccess}
      runMutation={handleSubmit}
    >
      <DatePickFormField
        formField={"date"}
        label={"Date"}
        helperText={""}
        required
      />

      <CrewCodePickerFormField
        formField={"crewCode"}
        label={"Crew Code"}
        helperText={""}
        required
      />

      <TextFormField
        formField={"quantity"}
        label={"Quantity"}
        helperText={""}
        required
        type="number"
      />

      <BinaryPickerFormField
        formField={"taxable"}
        label={"Taxable"}
        helperText={""}
      />

      <TextFormField
        formField={"notes"}
        label={"Notes"}
        helperText={""}
        textarea
        required
      />

      <ValueRenderer
        render={(values) => (
          <RateSheetQueryer
            {...{
              date: values.date?.format("YYYY-MM-DD") ?? null,
              invoices,
              setRateSheet,
              setError,
            }}
          />
        )}
      />

      <ErrorRenderer
        render={(formError) => (
          <FormBottomRow
            errorMessage={formError}
            buttonText="Save"
            onCancel={() => setOpen(false)}
          />
        )}
      />

      <Spinner open={loading} />
    </MutationForm>
  );
};

export type RateSheetQueryerProps = {
  invoices: Invoice[];
  setRateSheet: React.Dispatch<TaskRateSheet>;
  setError: React.Dispatch<string | null>;
  date: string | null;
};

export const RateSheetQueryer: React.FC<RateSheetQueryerProps> = (props) => {
  const { invoices, setRateSheet, setError, date } = props;
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();

  useEffect(() => {
    if (!date) {
      return;
    }

    void (async () => {
      setLoading(true);

      const response = await client.query({
        query: EffectiveTaskRateSheetsDocument,
        variables: {
          projectNumbers: Array.from(
            new Set(invoices.map((x) => x.projectNumber))
          ),
          asOf: date,
        },
      });

      setLoading(false);

      const assignments = (response?.data?.projects?.effectiveTaskRateSheets ??
        []) as EffectiveTaskRateSheetAssignment[];

      const sheets = assignments.map(
        (x) => x.taskRateSheet
      ) as (TaskRateSheet | null)[];

      if (sheets.some((x) => !x)) {
        setError(
          "Not all selected invoices have an effective rate sheet for the selected date."
        );
      } else if (new Set(sheets.map((x) => x!.name)).size > 1) {
        setError(
          "More than one rate sheet was found for the selected invoices."
        );
      } else if (!getClerical(sheets[0]!)) {
        setError("No Clerical rate was found for the effective rate sheet.");
      } else {
        setError(null);
        setRateSheet(sheets[0]!);
      }
    })();
  }, [invoices, date, setRateSheet, setError]);

  return <Spinner open={loading} />;
};
