import React, { useEffect, useReducer } from "react";
import * as States from "./states";
import * as Actions from "./actions";
import { reducer } from "./stateMachine";
import InlineTypeaheadDisplay from "./InlineTypeaheadDisplay";
import { useDebounce } from "@react-hook/debounce";
import "./InlineTypeahead.scss";

export type InlineTypeaheadProps<TItem> = {
  getData: (searchText: string) => Promise<TItem[]>;
  name: string;
  value: TItem | null;
  format: (item: TItem) => string;
  onChange: React.Dispatch<TItem | null>;
  emptyResultsMessage?: string;
  submitError?: string | null;
  required?: boolean;
  disabled?: boolean;
};

export const InlineTypeahead = <TItem,>({
  getData,
  onChange,
  format,
  value,
  name,
  required,
  emptyResultsMessage,
  submitError,
  disabled = false,
}: InlineTypeaheadProps<TItem>): React.ReactElement => {
  const initialState = {
    tag: "Unfocused",
    text: value ? format(value) : "",
    selection: value,
    hasInputFocus: false,
    hasMenuMouse: false,
    required,
    errorMessage: "",
    emptyResultsMessage,
    format: format,
  } as States.State<TItem>;

  const [state, rawDispatch] = useReducer(
    (a: States.State<TItem>, b: Actions.Action<TItem>) => reducer(a, b),
    initialState
  );
  const [searchInputText, setSearchInputText] = useDebounce<string>("", 500);

  useEffect(() => {
    if (value === null) {
      rawDispatch({ tag: "ClearSelection" });
    } else {
      rawDispatch({ tag: "Select", selection: value });
    }
  }, [value]);

  useEffect(() => {
    setSearchInputText(state.text);
  }, [state.text]);

  useEffect(() => {
    if (!state.selection && value) {
      onChange(null);
    }
  }, [state.selection]);

  useEffect(() => {
    if (!state.selection) {
      rawDispatch({ tag: "LoadSuggestions" });
    }
  }, [searchInputText]);

  useEffect(() => {
    if (States.isSuggestionsLoading(state)) {
      void (async () => {
        const data = await getData(searchInputText);
        rawDispatch({ tag: "SuggestionsLoaded", data: data ?? [] });
      })();
    }
  }, [state.tag]);

  return (
    <div className="inline-typeahead">
      <InlineTypeaheadDisplay
        {...{
          name,
          state,
          dispatch: rawDispatch,
          format: format,
          loading: state.loading,
          errorMessage: state.errorMessage || submitError || "",
          disabled,
          onChange,
          required,
        }}
      />
    </div>
  );
};
