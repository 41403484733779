import { Body2 } from "@material/react-typography";
import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import FormBottomRow from "~/form-bottom-row";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import {
  Customer,
  ElectronicInvoicingSystem,
  ValidationResponse,
} from "~/gql/types";
import TitledCard from "~/titled-card";
import { useEditElectronicInvoicingMutation } from "./editElectronicInvoicing.generated";
import { useSnackBar } from "~/snackbar";
import { useReload } from "../CustomerContext";
import Button from "~/button";
import EnumDropdownFormField from "~/visuals/organisms/EnumDropdownFormField";
import { useUser } from "~/Auth/useUser";

type ElectronicInvoicingProps = {
  customer: Customer;
};

export const ElectronicInvoicing: React.FC<ElectronicInvoicingProps> = ({
  customer,
}) => {
  const user = useUser();

  if (!user.authorizations.editCustomerElectronicInvoicing) {
    return null;
  }

  return (
    <TitledCard
      title="Electronic Invoicing System"
      actions={<EditElectronicInvoicing {...{ customer }} />}
    >
      <Body2>{customer.electronicInvoicingSystem ?? "Not set"}</Body2>
    </TitledCard>
  );
};

const EditElectronicInvoicing: React.FC<ElectronicInvoicingProps> = ({
  customer,
}) => {
  const [doMutation] = useEditElectronicInvoicingMutation();
  const [open, setOpen] = useState(false);

  const addAlert = useSnackBar();
  const reload = useReload();

  const onSuccess = () => {
    const message = "Electronic invoicing system updated";
    addAlert({
      isSuccess: true,
      message,
      key: `${Math.random()}`,
    });
    setOpen(false);
    reload();
  };

  const handleSubmit = async (values: {
    electronicInvoicingSystem: ElectronicInvoicingSystem | null;
  }) => {
    const variables = {
      customerNumber: customer.number!,
      electronicInvoicingSystem: values.electronicInvoicingSystem,
    };
    const result = await doMutation({ variables });
    return result?.data?.customers
      ?.editElectronicInvoicing as ValidationResponse;
  };

  const initialValues = {
    electronicInvoicingSystem: customer.electronicInvoicingSystem ?? null,
  };

  return (
    <>
      <Button
        className="edit-electronic-invoicing-button"
        onClick={() => setOpen(true)}
      >
        Edit
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="add-bad-debt"
      >
        <DialogTitle>Edit Electronic Invoicing System</DialogTitle>
        <DialogContent>
          {open && (
            <MutationForm
              initialValues={initialValues}
              onSuccess={onSuccess}
              runMutation={handleSubmit}
            >
              <EnumDropdownFormField
                formField={"electronicInvoicingSystem"}
                label={"Electronic Invoicing System"}
                helperText={""}
                enumType="ElectronicInvoicingSystem"
              />
              <ErrorRenderer
                render={(formError) => (
                  <FormBottomRow
                    errorMessage={formError}
                    buttonText="Save"
                    onCancel={() => setOpen(false)}
                  />
                )}
              />
            </MutationForm>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
