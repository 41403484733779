import React, { useState, useContext, createContext, useEffect } from "react";

type BreadcrumbProviderProps = {
  children: React.ReactNode;
  setBreadcrumbs?: React.Dispatch<Breadcrumb[]>;
};

export type Breadcrumb = {
  text: string;
  to?: string;
};

type ContextType = {
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<Breadcrumb[]>>;
};

export const BreadcrumbContext = createContext<ContextType>({
  breadcrumbs: [],
  setBreadcrumbs: () => [],
});

const BreadcrumbProvider: React.FC<BreadcrumbProviderProps> = ({
  children,
  setBreadcrumbs: interceptSetBreadcrumbs,
}) => {
  const [breadcrumbs, setBreadcrumbsInner] = useState<Breadcrumb[]>([]);

  const setBreadcrumbs = interceptSetBreadcrumbs
    ? (x) => {
        interceptSetBreadcrumbs(x);
        setBreadcrumbsInner(x);
      }
    : setBreadcrumbsInner;

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumbs,
        setBreadcrumbs,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};

export default BreadcrumbProvider;

export function useBreadcrumbs(
  crumbs: Breadcrumb[],
  dependencies: any[]
): void {
  const context = useContext(BreadcrumbContext);

  if (!context) {
    throw new Error(
      "Do not use useBreadcrumbs outside of a BreadcrumbProvider."
    );
  }

  const { setBreadcrumbs } = context;

  useEffect(() => {
    setBreadcrumbs(crumbs);

    return () => {
      setBreadcrumbs([]);
    };
  }, dependencies);
}
