import React, { useEffect } from "react";
import { useRawJwt } from "~/Auth/AuthenticationManager";
import {
  FuelExpense,
  ReceiptValuedExpense,
  PerDiemExpense,
  AdHocExpense,
} from "~/gql/types";

type UseLoadReceiptProps = {
  image: any;
  expenseEntry:
    | ReceiptValuedExpense
    | FuelExpense
    | PerDiemExpense
    | AdHocExpense;
  setImage: React.Dispatch<any>;
};

export function useLoadReceipt({
  image,
  expenseEntry,
  setImage,
}: UseLoadReceiptProps): void {
  const token = useRawJwt();

  useEffect(() => {
    if (!expenseEntry.receiptImageUrl) {
      return;
    }

    if (!image) {
      const url = expenseEntry.receiptImageUrl;

      void window
        .fetch(url.startsWith("/") ? url : `/${url}`, {
          method: "get",
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        })
        .then(async (res) => {
          if (res && res.headers.get("Content-Type")) {
            const type = res.headers.get("Content-Type");
            const blob = await res.blob();
            const file = new Blob([blob], { type: type as string });
            if (setImage) {
              setImage(file);
            }
          }
        });
    }
  }, [expenseEntry, image, token]);
}
