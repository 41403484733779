import React, { useState } from "react";
import { ProjectGroup } from "~/gql/types";
import { EditProjectGroup } from "../EditProjectGroup";
import "./ProjectGroup.scss";
import { LinkClickHandler } from "~/link-click-handler";
import { projects } from "~/routes";
import { Body2 } from "@material/react-typography";
type ProjectGroupProps = {
  projectGroup: ProjectGroup;
  customerNumber: string;
};

export const ProjectGroupRow: React.FC<ProjectGroupProps> = ({
  projectGroup,
  customerNumber,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const path = `${projects.path}?projectGroup=${customerNumber}__${projectGroup.name}`;
  return (
    <div className="project-group-row">
      <LinkClickHandler path={path}>
        <Body2 className="project-group">{projectGroup.name}</Body2>
      </LinkClickHandler>
      <EditProjectGroup
        {...{
          open,
          setOpen,
          customerNumber,
          projectGroupName: projectGroup.name,
        }}
      />
    </div>
  );
};
