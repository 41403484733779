import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTimeOffEntryMutationVariables = Types.Exact<{
  hours: Types.Scalars['Duration'];
  timeOffType: Types.TimeOffType;
  crewCode: Types.Scalars['String'];
  date: Types.Scalars['LocalDate'];
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateTimeOffEntryMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', createTimeOffEntry?: { __typename?: 'CreateTimeOffEntryResponse', timeOffEntryId: string, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditTimeOffEntryMutationVariables = Types.Exact<{
  timeOffEntryId: Types.Scalars['String'];
  hours: Types.Scalars['Duration'];
  timeOffType: Types.TimeOffType;
  crewCode: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EditTimeOffEntryMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', editTimeOffEntry?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type DeleteTimeOffEntryMutationVariables = Types.Exact<{
  timeOffEntryId: Types.Scalars['String'];
}>;


export type DeleteTimeOffEntryMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', deleteTimeOffEntry?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CreateTimeOffEntryDocument = gql`
    mutation CreateTimeOffEntry($hours: Duration!, $timeOffType: TimeOffType!, $crewCode: String!, $date: LocalDate!, $userPrincipalName: String, $adminNotes: String) {
  timesheets {
    createTimeOffEntry(
      hours: $hours
      timeOffType: $timeOffType
      crewCode: $crewCode
      date: $date
      userPrincipalName: $userPrincipalName
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
      timeOffEntryId
    }
  }
}
    `;
export type CreateTimeOffEntryMutationFn = Apollo.MutationFunction<CreateTimeOffEntryMutation, CreateTimeOffEntryMutationVariables>;

/**
 * __useCreateTimeOffEntryMutation__
 *
 * To run a mutation, you first call `useCreateTimeOffEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeOffEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeOffEntryMutation, { data, loading, error }] = useCreateTimeOffEntryMutation({
 *   variables: {
 *      hours: // value for 'hours'
 *      timeOffType: // value for 'timeOffType'
 *      crewCode: // value for 'crewCode'
 *      date: // value for 'date'
 *      userPrincipalName: // value for 'userPrincipalName'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCreateTimeOffEntryMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeOffEntryMutation, CreateTimeOffEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeOffEntryMutation, CreateTimeOffEntryMutationVariables>(CreateTimeOffEntryDocument, options);
      }
export type CreateTimeOffEntryMutationHookResult = ReturnType<typeof useCreateTimeOffEntryMutation>;
export type CreateTimeOffEntryMutationResult = Apollo.MutationResult<CreateTimeOffEntryMutation>;
export type CreateTimeOffEntryMutationOptions = Apollo.BaseMutationOptions<CreateTimeOffEntryMutation, CreateTimeOffEntryMutationVariables>;
export const EditTimeOffEntryDocument = gql`
    mutation EditTimeOffEntry($timeOffEntryId: String!, $hours: Duration!, $timeOffType: TimeOffType!, $crewCode: String!, $adminNotes: String) {
  timesheets {
    editTimeOffEntry(
      timeOffEntryId: $timeOffEntryId
      hours: $hours
      timeOffType: $timeOffType
      crewCode: $crewCode
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditTimeOffEntryMutationFn = Apollo.MutationFunction<EditTimeOffEntryMutation, EditTimeOffEntryMutationVariables>;

/**
 * __useEditTimeOffEntryMutation__
 *
 * To run a mutation, you first call `useEditTimeOffEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTimeOffEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTimeOffEntryMutation, { data, loading, error }] = useEditTimeOffEntryMutation({
 *   variables: {
 *      timeOffEntryId: // value for 'timeOffEntryId'
 *      hours: // value for 'hours'
 *      timeOffType: // value for 'timeOffType'
 *      crewCode: // value for 'crewCode'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useEditTimeOffEntryMutation(baseOptions?: Apollo.MutationHookOptions<EditTimeOffEntryMutation, EditTimeOffEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTimeOffEntryMutation, EditTimeOffEntryMutationVariables>(EditTimeOffEntryDocument, options);
      }
export type EditTimeOffEntryMutationHookResult = ReturnType<typeof useEditTimeOffEntryMutation>;
export type EditTimeOffEntryMutationResult = Apollo.MutationResult<EditTimeOffEntryMutation>;
export type EditTimeOffEntryMutationOptions = Apollo.BaseMutationOptions<EditTimeOffEntryMutation, EditTimeOffEntryMutationVariables>;
export const DeleteTimeOffEntryDocument = gql`
    mutation DeleteTimeOffEntry($timeOffEntryId: String!) {
  timesheets {
    deleteTimeOffEntry(timeOffEntryId: $timeOffEntryId) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type DeleteTimeOffEntryMutationFn = Apollo.MutationFunction<DeleteTimeOffEntryMutation, DeleteTimeOffEntryMutationVariables>;

/**
 * __useDeleteTimeOffEntryMutation__
 *
 * To run a mutation, you first call `useDeleteTimeOffEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeOffEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeOffEntryMutation, { data, loading, error }] = useDeleteTimeOffEntryMutation({
 *   variables: {
 *      timeOffEntryId: // value for 'timeOffEntryId'
 *   },
 * });
 */
export function useDeleteTimeOffEntryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeOffEntryMutation, DeleteTimeOffEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeOffEntryMutation, DeleteTimeOffEntryMutationVariables>(DeleteTimeOffEntryDocument, options);
      }
export type DeleteTimeOffEntryMutationHookResult = ReturnType<typeof useDeleteTimeOffEntryMutation>;
export type DeleteTimeOffEntryMutationResult = Apollo.MutationResult<DeleteTimeOffEntryMutation>;
export type DeleteTimeOffEntryMutationOptions = Apollo.BaseMutationOptions<DeleteTimeOffEntryMutation, DeleteTimeOffEntryMutationVariables>;