import { FieldState } from "~/inline-fields-form/types";
import { ProjectRequestFormData, ProjectRequestFormType } from "./types";

export const createInitialFieldState = (
  initialFormValues: ProjectRequestFormData,
  type: ProjectRequestFormType
): FieldState[] => [
  {
    name: "name",
    required: true,
    value: initialFormValues.name,
  },
  {
    name: "notes",
    required: type === "Edit" || type === "Group",
    value: initialFormValues.notes,
  },
  {
    name: "clientContactName",
    required: true,
    value: initialFormValues.clientContactName,
  },
  {
    name: "officeCode",
    required: true,
    value: initialFormValues.officeCode,
  },
  {
    name: "projectManager",
    required: true,
    value: initialFormValues.projectManager,
  },
  {
    name: "customer",
    required: !initialFormValues.requestNewCustomer,
    value: initialFormValues.customer,
  },
  {
    name: "requestNewCustomer",
    required: true,
    value: initialFormValues.requestNewCustomer,
  },
  {
    name: "isInternational",
    required: true,
    value: initialFormValues.isInternational,
  },
  {
    name: "customerName",
    required: initialFormValues.requestNewCustomer,
    value: initialFormValues.customerName,
  },
  {
    name: "customerAddress1",
    required: initialFormValues.requestNewCustomer,
    value: initialFormValues.customerAddress1,
  },
  {
    name: "customerAddress2",
    required: false,
    value: initialFormValues.customerAddress2,
  },
  {
    name: "customerCity",
    required: initialFormValues.requestNewCustomer,
    value: initialFormValues.customerCity,
  },
  {
    name: "customerState",
    required:
      initialFormValues.requestNewCustomer &&
      !initialFormValues.isInternational,
    value: initialFormValues.customerState,
  },
  {
    name: "customerZip",
    required:
      initialFormValues.requestNewCustomer &&
      !initialFormValues.isInternational,
    value: initialFormValues.customerZip,
  },
  {
    name: "customerPhone",
    required: initialFormValues.requestNewCustomer,
    value: initialFormValues.customerPhone,
  },
  {
    name: "customerFax",
    required: false,
    value: initialFormValues.customerFax,
  },
  {
    name: "customerNotes",
    required: false,
    value: initialFormValues.customerNotes,
  },
  {
    name: "customerAdminNotes",
    required: false,
    value: initialFormValues.customerAdminNotes,
  },
  {
    name: "customerAttn",
    required: false,
    value: initialFormValues.customerAttn,
  },
  {
    name: "customerCountry",
    required:
      initialFormValues.requestNewCustomer && initialFormValues.isInternational,
    value: initialFormValues.customerCountry,
  },
  {
    name: "governmentFunding",
    required: true,
    value: initialFormValues.governmentFunding,
  },
  {
    name: "afe",
    required: false,
    value: initialFormValues.afe,
  },
  {
    name: "po",
    required: false,
    value: initialFormValues.po,
  },
  {
    name: "wo",
    required: false,
    value: initialFormValues.wo,
  },
  {
    name: "costCenter",
    required: false,
    value: initialFormValues.costCenter,
  },
  {
    name: "contractNumber",
    required: false,
    value: initialFormValues.contractNumber,
  },
  {
    name: "glCode",
    required: false,
    value: initialFormValues.glCode,
  },
  {
    name: "requisitioner",
    required: false,
    value: initialFormValues.requisitioner,
  },
  {
    name: "budget",
    required: false,
    value: initialFormValues.budget,
  },
  {
    name: "serviceDescription",
    required: true,
    value: initialFormValues.serviceDescription,
  },
  {
    name: "taxableServices",
    required: true,
    value: initialFormValues.taxableServices,
  },
  {
    name: "taxableServices",
    required: true,
    value: initialFormValues.taxableServices,
  },
  {
    name: "jobsFolderRequired",
    required: true,
    value: initialFormValues.jobsFolderRequired,
  },
  {
    name: "location",
    required: true,
    value: initialFormValues.location,
  },
  {
    name: "section",
    required: false,
    value: initialFormValues.section,
  },
  {
    name: "township",
    required: false,
    value: initialFormValues.township,
  },
  {
    name: "range",
    required: false,
    value: initialFormValues.range,
  },
  {
    name: "block",
    required: false,
    value: initialFormValues.block,
  },
  {
    name: "survey",
    required: false,
    value: initialFormValues.survey,
  },
  {
    name: "geographicLocation",
    required: false,
    value: initialFormValues.geographicLocation,
  },
  {
    name: "geographicLocation",
    required: false,
    value: initialFormValues.geographicLocation,
  },
  {
    name: "coGo",
    required: false,
    value: initialFormValues.coGo,
  },
  {
    name: "fieldInstructions",
    required: false,
    value: initialFormValues.fieldInstructions,
  },
  {
    name: "fieldMeetInfo",
    required: false,
    value: initialFormValues.fieldMeetInfo,
  },
  {
    name: "fieldContact",
    required: false,
    value: initialFormValues.fieldContact,
  },
  {
    name: "projectInstructions",
    required: false,
    value: initialFormValues.projectInstructions,
  },
  {
    name: "officeInstructions",
    required: false,
    value: initialFormValues.officeInstructions,
  },
  {
    name: "invoiceSubmitEmail",
    required: false,
    value: initialFormValues.invoiceSubmitEmail,
  },
  {
    name: "invoiceContact",
    required: false,
    value: initialFormValues.invoiceContact,
  },
  {
    name: "additionalComments",
    required: false,
    value: initialFormValues.additionalComments,
  },
  {
    name: "scope",
    required: true,
    value: initialFormValues.scope,
  },
  {
    name: "certifiedPayroll",
    required: false,
    value: initialFormValues.certifiedPayroll,
  },
  {
    name: "projectOrigination",
    required: true,
    value: initialFormValues.projectOrigination,
  },
];
