import moment from "moment";
import React, { useState } from "react";
import Button from "~/button";
import Spinner from "~/spinner";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import { State } from "./types";

export const ExportCsv: React.FC<{ state: State }> = ({ state }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const downloadReport = useReportFetcher();

  const filters = {
    searchText: state.filterOptions?.searchText || null,
    projectNumber: state.filterOptions?.project?.number || null,
  };

  const getCsv = async () => {
    await downloadReport({
      setLoading,
      path: "customers-csv",
      fileName: `customers-${moment().format("MM-DD-YYYY")}`,
      body: {
        ...filters,
      },
      type: "text/csv;charset=utf-8;",
    });
  };

  return (
    <>
      <Button onClick={getCsv}>Export Excel</Button>
      <Spinner open={loading} />
    </>
  );
};
