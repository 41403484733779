import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { createSearchRegex } from "~/text-highlighter/createSearchRegex";
import { TextHighlighter } from "~/text-highlighter";
import { useStateCountiesAsync } from "~/refdata2";
import _ from "lodash";

const format = (item: string, searchText: string): React.ReactNode => {
  const searchRegex = createSearchRegex(searchText);

  return (
    <div className="state-picker-menuitem">
      <TextHighlighter text={item} search={searchRegex} />
    </div>
  );
};

const chipFormat = (item: string) => item;

const StatePickerFormField: React.FC<FormFieldProps> = (props) => {
  const getLocations = useStateCountiesAsync();

  const getData = async (searchText: string) => {
    if (!searchText) {
      return [];
    }
    const result =
      _.uniqBy((await getLocations()) ?? [], "state")?.map((x) => x.state) ??
      [];
    return result.filter((s) =>
      s.toLowerCase().startsWith(searchText.toLowerCase())
    );
  };

  const args = {
    ...props,
    getData,
    format,
    chipFormat,
  } as TypeaheadProps<string>;

  return (
    <div className="state-picker">
      <TypeaheadFormField {...args} />
    </div>
  );
};

export default StatePickerFormField;
