import React from "react";
import Button from "~/button";
import MaterialIcon from "@material/react-material-icon";
import { PopupMenuList, PopupMenuListItem } from "~/popup-menu-list";
import Spinner from "~/spinner";
import cn from "classnames";
import "./ReportPopup.scss";

type ReportPopupProps = {
  loading: boolean;
  setOpen: React.Dispatch<boolean>;
  open: boolean;
  items: PopupMenuListItem[];
  label: string;
  outlined?: boolean;
  topMargin?: boolean;
};
export const ReportPopup: React.FC<ReportPopupProps> = (props) => {
  const { loading, setOpen, open, items, label, outlined, topMargin } = props;

  return (
    <div className={cn("report-popup", { "top-margin": topMargin })}>
      <Spinner open={loading} />
      <Button onClick={() => setOpen(!open)} outlined={outlined}>
        {label}
        <MaterialIcon icon="expand_more" />
      </Button>
      <PopupMenuList
        {...{ visible: open, items }}
        onMenuMouseEnter={() => undefined}
        onMenuMouseLeave={() => undefined}
        onClose={() => setOpen(false)}
        focusOnOpen={false}
        selectedIndex={0}
        anchorCorner="bottomRight"
      />
    </div>
  );
};
