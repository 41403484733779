import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateStateOverheadCrewCodeMutationVariables = Types.Exact<{
  crewCode: Types.Scalars['String'];
  state: Types.Scalars['String'];
}>;


export type UpdateStateOverheadCrewCodeMutation = { __typename?: 'MutationModel', crewCodes?: { __typename?: 'CrewCodeMutationModel', updateStateOverheadCrewCode?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const UpdateStateOverheadCrewCodeDocument = gql`
    mutation UpdateStateOverheadCrewCode($crewCode: String!, $state: String!) {
  crewCodes {
    updateStateOverheadCrewCode(crewCode: $crewCode, state: $state) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type UpdateStateOverheadCrewCodeMutationFn = Apollo.MutationFunction<UpdateStateOverheadCrewCodeMutation, UpdateStateOverheadCrewCodeMutationVariables>;

/**
 * __useUpdateStateOverheadCrewCodeMutation__
 *
 * To run a mutation, you first call `useUpdateStateOverheadCrewCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStateOverheadCrewCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStateOverheadCrewCodeMutation, { data, loading, error }] = useUpdateStateOverheadCrewCodeMutation({
 *   variables: {
 *      crewCode: // value for 'crewCode'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateStateOverheadCrewCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStateOverheadCrewCodeMutation, UpdateStateOverheadCrewCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStateOverheadCrewCodeMutation, UpdateStateOverheadCrewCodeMutationVariables>(UpdateStateOverheadCrewCodeDocument, options);
      }
export type UpdateStateOverheadCrewCodeMutationHookResult = ReturnType<typeof useUpdateStateOverheadCrewCodeMutation>;
export type UpdateStateOverheadCrewCodeMutationResult = Apollo.MutationResult<UpdateStateOverheadCrewCodeMutation>;
export type UpdateStateOverheadCrewCodeMutationOptions = Apollo.BaseMutationOptions<UpdateStateOverheadCrewCodeMutation, UpdateStateOverheadCrewCodeMutationVariables>;