import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateDivisionMutationVariables = Types.Exact<{
  divisionId: Types.Scalars['String'];
  name: Types.Scalars['String'];
  isActive: Types.Scalars['Boolean'];
}>;


export type UpdateDivisionMutation = { __typename?: 'MutationModel', divisions?: { __typename?: 'DivisionMutationModel', update?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const UpdateDivisionDocument = gql`
    mutation UpdateDivision($divisionId: String!, $name: String!, $isActive: Boolean!) {
  divisions {
    update(divisionId: $divisionId, name: $name, isActive: $isActive) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type UpdateDivisionMutationFn = Apollo.MutationFunction<UpdateDivisionMutation, UpdateDivisionMutationVariables>;

/**
 * __useUpdateDivisionMutation__
 *
 * To run a mutation, you first call `useUpdateDivisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDivisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDivisionMutation, { data, loading, error }] = useUpdateDivisionMutation({
 *   variables: {
 *      divisionId: // value for 'divisionId'
 *      name: // value for 'name'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateDivisionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDivisionMutation, UpdateDivisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDivisionMutation, UpdateDivisionMutationVariables>(UpdateDivisionDocument, options);
      }
export type UpdateDivisionMutationHookResult = ReturnType<typeof useUpdateDivisionMutation>;
export type UpdateDivisionMutationResult = Apollo.MutationResult<UpdateDivisionMutation>;
export type UpdateDivisionMutationOptions = Apollo.BaseMutationOptions<UpdateDivisionMutation, UpdateDivisionMutationVariables>;