import { useSearchParams } from "react-router-dom";

type UpdateParamProps = {
  param: string;
  value: string;
  _setSearchParams?: (args: Record<string, any>) => void;
};

export function useUpdateSearchParam(): (props: UpdateParamProps) => void {
  const [searchParams, setSearchParams] = useSearchParams();

  return (props: UpdateParamProps) => {
    const { param, value, _setSearchParams } = props;

    const newParams = {};
    Array.from(searchParams.entries()).forEach(([k, v]) => (newParams[k] = v));

    const setParams = _setSearchParams ?? setSearchParams;

    newParams[param] = value;
    setParams(newParams);
  };
}
