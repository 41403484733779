import React from "react";
import { WorkDay as WorkDayComponent } from "~/visuals/organisms/WorkDay";
import moment from "moment";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { parseWorkData } from "../parseWorkData";
import { useWorkDayApi } from "~/WorkDayApi";

const MyWorkDayWrapper: React.FC = () => {
  const { workDay, breadcrumbs } = useWorkDayApi();
  const { date } = workDay;

  useBreadcrumbs(
    [breadcrumbs[0], { text: breadcrumbs[1].text }],
    [breadcrumbs]
  );

  const { timeEntryData, timeOffEntryData, projectTimeData, expenseData } =
    parseWorkData(workDay);

  return (
    <WorkDayComponent
      date={moment(date)}
      time={timeEntryData}
      timeOff={timeOffEntryData}
      projectTime={projectTimeData}
      expenses={expenseData}
      workDayId={workDay.workDayId}
      notes={[]}
      admin={false}
    />
  );
};

export default MyWorkDayWrapper;
