import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { createSearchRegex } from "~/text-highlighter/createSearchRegex";
import { TextHighlighter } from "~/text-highlighter";
import { ProjectGroup } from "~/gql/types";
import { useProjectGroups } from "./projectGroupsQueryApi";

const format = (item: ProjectGroup, searchText: string): React.ReactNode => {
  const searchRegex = createSearchRegex(searchText);

  return (
    <div className="project-group-picker-menuitem">
      <TextHighlighter
        text={`${item.customer!.name} - ${item.name}`}
        search={searchRegex}
      />
    </div>
  );
};

const chipFormat = (item: ProjectGroup) =>
  `${item.customer?.name} - ${item.name}`;

const ProjectGroupPickerFormField: React.FC<FormFieldProps> = (props) => {
  const getProjectGroups = useProjectGroups();

  const getData = async (searchText: string) => {
    const result = (await getProjectGroups()) ?? [];

    if (!searchText) {
      return result;
    }

    const lcSearch = searchText.toLowerCase();

    return result.filter(
      (t) =>
        t.name.toLowerCase().includes(lcSearch) ||
        t.customer!.name.toLowerCase().includes(lcSearch)
    );
  };

  const args = {
    ...props,
    getData,
    format,
    chipFormat,
  } as TypeaheadProps<ProjectGroup>;

  return <TypeaheadFormField {...args} />;
};

export default ProjectGroupPickerFormField;
