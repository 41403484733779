import { ApiCallback } from "~/WorkDayApi/WorkDayApiType";
import { FormData } from "./stateMachine";
import { useRelativeNav } from "~/relative-nav";
import { CreateProjectTimeResponse } from "~/WorkDayApi/createProjectTimeApi";
import { cloneProjectTimeEntry } from "../WorkDay/ProjectTimeCard";
import { ProjectTimeFormType } from "./ProjectTimeForm";
import { SaveAndAction } from "~/WorkDayApi/SaveAndHandler";
import { toCallback } from "~/WorkDayApi/utils";
import { useProjectTimeCopy } from "~/WorkDayApi/WorkDayCopyProvider";

export function useProjectTimeCallback(): (
  values: FormData,
  type: ProjectTimeFormType
) => ApiCallback | undefined {
  const nav = useRelativeNav();

  const { setCopyToDate, setCopyToDates } = useProjectTimeCopy();

  return (values: FormData, type: ProjectTimeFormType) => {
    const relativePath = type === "Clone" ? "../../" : "../";

    const cloneCallback = (response: CreateProjectTimeResponse) =>
      Promise.resolve(
        nav(`${relativePath}${cloneProjectTimeEntry}/${response.projectTimeId}`)
      );

    const copyToDateCallback = (response: CreateProjectTimeResponse) =>
      Promise.resolve(setCopyToDate(response.projectTimeId));

    const copyToDatesCallback = (response: CreateProjectTimeResponse) =>
      Promise.resolve(setCopyToDates(response.projectTimeId));

    const callbackMap: Record<SaveAndAction, ApiCallback> = {
      saveAndClone: cloneCallback,
      copyToDate: copyToDateCallback,
      copyToDates: copyToDatesCallback,
    };

    return toCallback(callbackMap, values);
  };
}
