import { projectFieldMap } from "./defaultProjectFieldState";
import moment from "moment";
import { FieldState } from "~/inline-fields-form/types";
import { ProjectRefData } from "~/refdata/sources";

export type ProjectStatus = "Active" | "Closed";

export function createNewFieldState(): FieldState[] {
  const fieldStates = Object.values(projectFieldMap).map((x) => ({
    ...x,
    value:
      x.name === "budget"
        ? 0
        : x.name === "dateIn"
        ? moment()
        : x.name === "certifiedPayroll"
        ? false
        : x.name === "allowThirdPartyInvoice"
        ? true
        : null,
    disabled: x.name === "projectGroup",
    toVariables:
      x.name === "corpLocation"
        ? (value: ProjectRefData) => ({ corpLocation: value.id })
        : x.name === "division"
        ? (value: ProjectRefData) => ({ division: value.id })
        : x.toVariables,
  }));

  return fieldStates;
}
