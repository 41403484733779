import React from "react";
import { useCreateReceiptValuedExpenseRateMutation } from "./queries.generated";
import { MutationForm } from "~/forms/MutationForm";
import { CreateExpenseRateFormData, CreateExpenseRateFormProps } from "./types";
import { ValidationResponse } from "~/gql/types";

export const NewReceiptValuedExpenseRate: React.FC<
  CreateExpenseRateFormProps
> = (props) => {
  const { setLoading, onSuccess, initialValues, rateSheetName, children } =
    props;

  const [rawRvMutation] = useCreateReceiptValuedExpenseRateMutation();

  const handleSubmit = async (values: CreateExpenseRateFormData) => {
    const variables = {
      name: values?.name!,
      invoiceLabel: values?.invoiceLabel || null,
      expenseAccount: values?.expenseAccount || null,
      glAccount: values?.glAccount || null,
      rateSheetName: rateSheetName,
      requiresLocation: values?.requiresLocation,
      category: values?.category!,
      billable: values?.billable,
    };

    const result = await rawRvMutation({ variables });
    return result?.data?.expenseRates
      ?.addReceiptValuedRate as ValidationResponse;
  };

  return (
    <MutationForm
      setLoading={setLoading}
      initialValues={initialValues}
      runMutation={handleSubmit}
      onSuccess={onSuccess}
      allowPristineSubmit
    >
      {children}
    </MutationForm>
  );
};
