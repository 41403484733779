import React from "react";
import { useWeeklyProjectTasksQuery } from "./query.generated";
import { ProjectTimeEntry, ValidationError } from "~/gql/types";
import Spinner from "~/spinner";
import Button from "~/button";
import { useCopyTimesheetProjectTimeMutation } from "./copyPreviousProjectTime.generated";
import { useSnackBar } from "~/snackbar";
import { useConfirmationPrompt } from "~/confirmation-prompt";

type CopyProjectTimeProps = {
  userPrincipalName: string;
  prevWeek: string;
  timesheetId: string;
  refetch: React.DispatchWithoutAction;
};

export const CopyPreviousWeekProjectTime: React.FC<CopyProjectTimeProps> = ({
  userPrincipalName,
  prevWeek,
  timesheetId,
  refetch,
}) => {
  const variables = {
    userPrincipalName,
    weekBeginning: prevWeek,
  };

  const { data, loading } = useWeeklyProjectTasksQuery({ variables });
  const addAlert = useSnackBar();
  const prompt = useConfirmationPrompt();

  const result = data?.timesheets?.weeklyProjectTasks;
  const previousWeekTime = (result?.projectTime ?? []) as ProjectTimeEntry[];

  const [doMutation, { loading: mutationLoading }] =
    useCopyTimesheetProjectTimeMutation();

  if (!previousWeekTime.length) {
    return null;
  }

  const handleSubmit = async () => {
    const copyVariables = {
      id: timesheetId,
    };

    const result = await doMutation({ variables: copyVariables });

    const data = result?.data?.timesheets?.copyProjectTime;

    const errors = data?.errors as ValidationError[];

    const key = `${Math.random()}`;

    if (data && errors && errors.length > 0) {
      const errorMessages: string[] = [];
      errors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          errorMessages.push(`${argumentName}: ${message}`);
        } else if (message) {
          errorMessages.push(message);
        }
      });

      addAlert({
        key,
        isSuccess: false,
        message: errorMessages.join(" "),
      });
    } else {
      addAlert({
        key,
        message: "Project time copied from last week",
        isSuccess: true,
      });

      refetch();
    }
  };

  const openPrompt = async () => {
    const promptResult = await prompt({
      cancelButtonText: "Don't copy",
      confirmButtonText: "Yes, copy",
      message: `Are you sure you want to copy ${
        previousWeekTime.length
      } entrie${previousWeekTime.length > 0 ? "s" : ""} from last week?`,
      title: "Copy Previous Week Project Time",
    });

    if (promptResult !== "Confirm") {
      return;
    }

    await handleSubmit();
  };

  return (
    <>
      {!loading && <Button onClick={openPrompt}>Copy Previous</Button>}
      <Spinner open={loading || mutationLoading} />
    </>
  );
};
