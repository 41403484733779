import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignCustomerBaMutationVariables = Types.Exact<{
  customerNumber: Types.Scalars['String'];
  userPrincipalName: Types.Scalars['String'];
}>;


export type AssignCustomerBaMutation = { __typename?: 'MutationModel', customers?: { __typename?: 'CustomerMutationModel', assignBa?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const AssignCustomerBaDocument = gql`
    mutation AssignCustomerBa($customerNumber: String!, $userPrincipalName: String!) {
  customers {
    assignBa(customerNumber: $customerNumber, userPrincipalName: $userPrincipalName) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type AssignCustomerBaMutationFn = Apollo.MutationFunction<AssignCustomerBaMutation, AssignCustomerBaMutationVariables>;

/**
 * __useAssignCustomerBaMutation__
 *
 * To run a mutation, you first call `useAssignCustomerBaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCustomerBaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCustomerBaMutation, { data, loading, error }] = useAssignCustomerBaMutation({
 *   variables: {
 *      customerNumber: // value for 'customerNumber'
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useAssignCustomerBaMutation(baseOptions?: Apollo.MutationHookOptions<AssignCustomerBaMutation, AssignCustomerBaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignCustomerBaMutation, AssignCustomerBaMutationVariables>(AssignCustomerBaDocument, options);
      }
export type AssignCustomerBaMutationHookResult = ReturnType<typeof useAssignCustomerBaMutation>;
export type AssignCustomerBaMutationResult = Apollo.MutationResult<AssignCustomerBaMutation>;
export type AssignCustomerBaMutationOptions = Apollo.BaseMutationOptions<AssignCustomerBaMutation, AssignCustomerBaMutationVariables>;