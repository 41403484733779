import React, { useState } from "react";
import Button from "~/button";
import "./HelpWidget.scss";
import HelpWidgetForm from "./HelpWidgetForm";

const HelpWidget: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className="help-widget"
        icon="help_outline"
      />
      <HelpWidgetForm {...{ open, setOpen }} />
    </>
  );
};

export default HelpWidget;
