import React, { useState } from "react";
import { useUser } from "~/Auth/useUser";
import TopNavBar from "./TopNavBar";

interface TopBarProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<boolean>;
  children: React.ReactNode;
}

const TopBar: React.FC<TopBarProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  children,
}: TopBarProps) => {
  const user = useUser();
  const [actionMenuOpen, setActionMenuOpen] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<{ x: number; y: number }>();

  const onActionMenuClick = (e) => {
    setActionMenuOpen(!actionMenuOpen);
    setCoordinates({ x: e.clientX, y: e.clientY });
    e.preventDefault();
  };

  return (
    <TopNavBar
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      actionMenuOpen={actionMenuOpen}
      coordinates={coordinates}
      user={user}
      onActionMenuClick={onActionMenuClick}
    >
      {children}
    </TopNavBar>
  );
};

export default TopBar;
