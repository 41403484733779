import React from "react";
import "./LineBreaker.scss";

const LineBreaker: React.FC<{ text: string }> = ({ text }) => {
  if (!text) {
    return <></>;
  }

  const lines = text.split(/\n/);
  return (
    <span className="line-breaker">
      {lines.map((line, idx) => (
        <span key={idx}>
          {idx > 0 && <br />}
          {line}
        </span>
      ))}
    </span>
  );
};

export default LineBreaker;
