import React, { useReducer } from "react";
import { State, Action, reducer } from "./stateMachine";

type InfiniteScrollReducer<T, TFilter> = {
  state: State<T, TFilter>;
  dispatch: React.Dispatch<Action<T, TFilter>>;
};

type InitialState<TFilter> = Record<string, any> & {
  filterOptions: TFilter;
};
export default function useInfiniteScroll<T, TFilter>(
  initialState: InitialState<TFilter>
): InfiniteScrollReducer<T, TFilter> {
  const defaultFilter = initialState.filterOptions;
  const [state, dispatch] = useReducer(
    (a: State<T, TFilter>, b: Action<T, TFilter>) =>
      reducer(a, b, defaultFilter),
    initialState as State<T, TFilter>
  );

  return { state, dispatch };
}
